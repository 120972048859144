import {Component, Input, OnInit} from '@angular/core';
import {ViewCell} from 'ng2-smart-table';
import {WrapBuyer} from './buyer-utils';
import {StringUtils} from '../../../../shared/common/string-utils';

@Component({
  template: `
    {{renderValue}}
  `,
  styles: [
  ],
})
export class BuyerListAddressCellComponent implements ViewCell, OnInit {

  renderValue: string;

  @Input() value: string | number;
  @Input() rowData: WrapBuyer;

  StringUtils = StringUtils;

  ngOnInit() {
    this.setValue();
  }

  setValue(): any {
    if (!this.rowData) {
      this.renderValue = this.value ? this.value.toString() : '';
      return;
    }
    switch (this.rowData.type) {
      case 'ITEM':
        const prospect = this.rowData.data?.prospect;
        if (prospect) {
          // this.renderValue = `${prospect?.address?.unit ? prospect?.address?.unit + '/' : ''}`
          //   + `${prospect?.address?.number ? prospect?.address?.number + ', ' : ''}`
          //   + `${prospect?.address?.street?.name}`;
          this.renderValue = `${StringUtils.getShortAddress(prospect?.address)}`;
        }
        break;
    }
  }

}
