import {Component, Input, OnInit} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Constant} from '../../../../../shared/common/constant';
import {FormService} from '../../../../../shared/services/form.service';
import {CommonService} from '../../../../../shared/services/common.service';
import {District} from '../../../../../shared/models/response/address/district';
import {SuburbService} from '../../../../../shared/services/suburb.service';
import {City} from '../../../../../shared/models/response/address/city';
import {ComponentPermission} from '../../../../../component.permission';

@Component({
  selector: 'ngx-district-update',
  templateUrl: './district-update.component.html',
  styleUrls: ['./district-update.component.scss'],
})
export class DistrictUpdateComponent implements OnInit {
  ComponentPermission = ComponentPermission;
  form: FormGroup;
  isSubmitted = false;

  @Input()
  data: District;

  @Input()
  cities: City[];

  constructor(protected ref: NbDialogRef<DistrictUpdateComponent>,
              private suburbService: SuburbService,
              private commonService: CommonService,
              private formService: FormService) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.form = new FormGroup({
      districtId: new FormControl(this.data.districtId, [
        Validators.required,
      ]),
      name: new FormControl(this.data.name, [
        Validators.required,
      ]),
      code: new FormControl(this.data.code, [
        Validators.required,
      ]),
      // cityId: new FormControl(this.data?.city?.cityId + '', [
      //   Validators.required,
      // ]),
      color: new FormControl(''),
      createdDate: new FormControl(this.data.createdDate ? new Date(this.data.createdDate) : ''),
      status: new FormControl(this.data.status, [
        Validators.required,
      ]),
    });
  }

  submit() {
    const self = this;
    const form = this.form.value;
    this.formService.extractErrorMessage(this.form);
    if (this.form.invalid) {
      this.isSubmitted = true;
      return;
    }
    form.createdDate = this.commonService.parseDate(form.createdDate, 'DD/MM/YYYY');
    this.suburbService.update(form).subscribe(
      result => {
        this.commonService.info('Update success.');
        this.ref.close(result);
      },
      error => {
        this.commonService.warningHtml(error.message);
      },
    );
  }

  cancel() {
    this.ref.close();
  }


  get STATUS() {
    return Constant.STATUS;
  }

  showError(name: string): string {
    return this.form.controls[name].errors
    && (this.form.controls[name].dirty
      || this.form.controls[name].touched
      || this.isSubmitted) ? 'has-error' : '';
  }

  colorChange(colorValue: string) {
    this.form.controls.color.setValue(colorValue);
  }
}
