import { Component, OnInit, ViewChild } from '@angular/core';
import { ComponentPermission } from '../../../../component.permission';
import { LayoutComponent } from '../../layout';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'ngx-dob-overview',
  templateUrl: './dob-overview.component.html',
  styleUrls: ['./dob-overview.component.scss']
})
export class DobOverviewComponent implements OnInit {

  ComponentPermission = ComponentPermission;
  currentTab = 'CLIENT';
  birthdayToday: boolean;
  param = {};
  tabs = {
    CLIENT: 'CLIENT',
    CUSTOMER: 'CUSTOMER',
  };

  constructor(private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params['birthdayToday']) {
        this.birthdayToday = Boolean(params['birthdayToday']);
      }
    });
    this.param = { ...this.route.snapshot.queryParams };
    const selectedTab = this.route.snapshot?.queryParamMap?.get('tab');
    if (selectedTab && selectedTab !== '') {
      this.currentTab = selectedTab;
    }
  }


  onChangeTab(event) {
    if (event.tabTitle !== this.currentTab) {
      delete this.param['selectedMonth'];
      delete this.param['selectedDay'];
      delete this.param['cityId'];
      delete this.param['districtId'];
      delete this.param['streetId'];
      delete this.param['selectedStreetId'];
      delete this.param['selectedSProspectId'];
      delete this.param['selectedBuyerId'];
      delete this.param['year'];
      delete this.param['month'];
      delete this.param['day'];
      delete this.param['fromCallbackDate'];
      delete this.param['toCallbackDate'];
      delete this.param['tab'];
    }
    this.currentTab = event.tabTitle;
    this.router.navigate([], { queryParams: { ...this.param, tab: this.currentTab } });
  }
}
