<nb-card>
  <nb-card-header>Export Address</nb-card-header>

  <nb-card-body class='vw-100'>
    <div>
      <label class='label'>Data</label>
      <div *ngIf='!data || data.length === 0'>
        <div class='text-center'>No Data !</div>
      </div>
      <textarea nbInput fullWidth readonly rows='10' *ngIf='data && data.length > 0' [(ngModel)]='dataString'></textarea>
      <div class='d-flex justify-content-center mt-1'>
        <button nbButton size='tiny' [cdkCopyToClipboard]='dataString' *ngIf='dataString !== ""' nbTooltip='Copied to clipboard'
                [nbTooltipTrigger]='trigger' [nbTooltipPlacement]='position'>Copy to clipboard
        </button>
      </div>
    </div>
  </nb-card-body>

  <nb-card-footer>
    <button nbButton size='tiny' status='danger' (click)='cancel()' class='fa-pull-right mr-2'>Cancel</button>
  </nb-card-footer>
</nb-card>
