<div class="dateview-container" *appPermissionCheck="ComponentPermission.DASHBOARD_PURCHASE">
  <h5>
    Purchase Date View
  </h5>
  <div class="row">
    <div class="col-md-6 col-sm-12 left-panel table-container"
         [ngClass]="{'popup-hide': isDisplay}">
      <nb-card>
        <nb-card-body>
          <ngx-prospect-list-filter-purchasedate
            [districts]="filteredDistricts"
            [districtId]="searchRequest?.districtId"
            [streets]="filteredStreets"
            [streetId]="searchRequest?.streetId"
            [cities]="cities"
            [cityId]="searchRequest?.cityId"
            [total]="groupWrapper?.totalItems"
            [farm]="selectedProspect?.address?.street?.farm?.name"
            [zone]="selectedProspect?.address?.street?.district?.zone?.name"
            [selectedMonth]="selectedMonth"
            [selectedDay]='selectedDay'
            [rate]='searchRequest?.rate'
            [postcode]='searchRequest.postcode'
            (onSuburbChange)="onSuburbChange($event)"
            (onStreetChange)="onStreetChange($event)"
            (onCityChange)="onCityChange($event)"
            (onMonthChange)="onMonthChange($event)"
            (onDayChange)="onDayChange($event)"
            (onRateChange)='onRateChange($event)'
            (onSearchByPostcode)='onSearchByPostcode($event)'
          >
          </ngx-prospect-list-filter-purchasedate>
          <div class='row mt-2'>
            <div class='col-8 ml-2'>
              <nb-form-field>
                <input type='text' nbInput fullWidth fieldSize='tiny' placeholder='Text' [(ngModel)]='log'>
                <button nbButton nbSuffix size='tiny' hero status='primary' class='mr-4' (click)='saveBulk()'>Save
                </button>
              </nb-form-field>
            </div>
            <div class='mt-2 ml-2 margin-top-sm'>
              <button nbButton size='tiny' hero status='primary' class='fa-pull-right'
                      (click)='exportEmail()'><i class='fa fa-download mr-1'></i> Email
              </button>
              <button nbButton size='tiny' hero status='primary' class='fa-pull-right mr-1' (click)='exportMobile()'>
                <i class='fa fa-download mr-1'></i>
                Mobile
              </button>
            </div>
          </div>
          <div class="mt-2">
            <ng2-smart-table *ngIf="!viewMap"
                             [settings]="settings"
                             [source]="source"
                             (userRowSelect)="selectRecord($event, false, true); selectRow($event, prospectDialog)"
            >
            </ng2-smart-table>
          </div>
        </nb-card-body>
      </nb-card>
    </div>
    <div class="col-md-6 col-sm-12 right-panel table-container"
         [ngClass]="{
       'popup-detail': isDisplay,
       'hide-detail': !isDisplay
       }">
      <ngx-prospect-dialog
        [selectedProspect]="selectedProspect"
        [prospects]="selectedProspects"
        [companies]="companies"
        [taskTypes]="taskTypes"
        [taskStatuses]="taskStatuses"
        (editDone)="editDone($event)"
        (navigateProspectEvent)="navigateProspect($event)"
      ></ngx-prospect-dialog>
    </div>
    <div class='d-flex col-12'>
      <button *ngIf='isDialog'
              nbButton size='tiny' hero status='primary' class='fa-pull-right ml-2'
              (click)='closeDialog()'>Cancel
      </button>
    </div>
    <div class='scroll-mobile left-54' *ngIf='selectedProspect && isDisplay'>
      <button (click)='cancel()' size='tiny' nbButton status='warning'>Cancel</button>
    </div>
  </div>
</div>
