import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { User } from '../../../../shared/models/response/user';
import { ProspectStatistic } from '../../../../shared/models/response/prospect-statistic';
import { StringUtils } from '../../../../shared/common/string-utils';
import { CommonService } from '../../../../shared/services/common.service';
import { AuthService } from '../../../../shared/services/auth.service';
import { OfficeProperty } from '../../../../shared/models/response/office-property';
import { DateUtils } from '../../../../shared/common/date-utils';
import { RestResult } from '../../../../shared/models/response/rest-result';
import { DashboardService } from '../../../../shared/services/dashboard.service';
import { Category } from '../../../../shared/models/response/category';
import { NbTrigger } from '@nebular/theme';

@Component({
  selector: 'ngx-ratio',
  templateUrl: './ratio.component.html',
  styleUrls: ['./ratio.component.scss'],
})
export class RatioComponent implements OnInit, OnChanges {

  @Input() selectedUsers: User[];
  @Input() selectedCategories: Category[];
  lastMonthStats: ProspectStatistic;
  stringUtils = StringUtils;
  currentMonth = new Date().toLocaleString('en', { month: 'long' });

  SCOnSA: number;
  SNOnSC: number;
  SAOnSP: number;
  SPOnS4: number;
  S4OnO4: number;

  BCOnBA: number;
  BAOnBI: number;
  BIOnB4: number;
  B4OnB5: number;
  B5OnB6: number;
  BNOnBC: number;

  s4: number;
  sp: number;
  sa: number;
  sc: number;
  sn: number;

  b5: number;
  b4: number;
  bi: number;
  ba: number;
  bc: number;
  bn: number;

  desiredListings: number;
  _desiredListings: number;
  desiredSales: number;
  _desiredSales: number;
  showGoal = false;
  currentUser: User;
  defaultRatio: OfficeProperty;
  dateFrom: number;
  dateTo: number;
  types = ['Month', 'Quarter', 'Year'];
  selectedType = 'Month';
  nums = [];
  selectedNum = 1;
  selectedYear: number;
  years = [];

  constructor(private commonService: CommonService,
              private authService: AuthService,
              private dashboardService: DashboardService) {
  }

  ngOnInit(): void {
    this.currentUser = this.authService.currentUser;
    this.defaultRatio = this.currentUser.office.officeProperty;
    this.selectedYear = new Date().getFullYear();
    for (let i = 1; i <= 12; i++) {
      this.nums.push(i);
    }
    for (let i = 2000; i <= 3000; i++) {
      this.years.push(i);
    }
    this.selectedYear = new Date().getFullYear();
    this.selectedNum = new Date().getMonth();
    if (this.selectedNum === 0) {
      this.selectedNum = 12;
      --this.selectedYear;
    }
    this.generateDate(this.selectedNum);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.currentUser = this.authService.currentUser;
    if (changes.selectedUsers) {
      this.selectedUsers = changes.selectedUsers.currentValue;
      this.selectedType = 'Month';
      this.selectedNum = new Date().getMonth();
      if (this.selectedNum === 0) {
        this.selectedNum = 12;
        --this.selectedYear;
      }
      this.generateDate(this.selectedNum);
    }
  }

  getAllRatio() {
    this.SCOnSA = this.calculateRatio(this.lastMonthStats.totalSC, this.lastMonthStats.totalSA);
    this.SAOnSP = this.calculateRatio(this.lastMonthStats.totalSA, this.lastMonthStats.totalSP);
    this.SPOnS4 = this.calculateRatio(this.lastMonthStats.totalSP, this.lastMonthStats.totalS4);
    this.S4OnO4 = this.calculateRatio(this.lastMonthStats.totalS4, this.lastMonthStats.totalO4);
    this.BCOnBA = this.calculateRatio(this.lastMonthStats.totalBC, this.lastMonthStats.totalBA);
    this.BAOnBI = this.calculateRatio(this.lastMonthStats.totalBA, this.lastMonthStats.totalBI);
    this.BIOnB4 = this.calculateRatio(this.lastMonthStats.totalBI, this.lastMonthStats.totalB4);
    this.B4OnB5 = this.calculateRatio(this.lastMonthStats.totalB4, this.lastMonthStats.totalB5);
    this.B5OnB6 = this.calculateRatio(this.lastMonthStats.totalB5, this.lastMonthStats.totalB6);
    this.BNOnBC = this.calculateRatio(this.lastMonthStats.totalBN, this.lastMonthStats.totalBC);
    this.SNOnSC = this.calculateRatio(this.lastMonthStats.totalSN, this.lastMonthStats.totalSC);
  }

  calculateRatio(x: number, y: number): number {
    let ratio = x / y;
    ratio = Math.round(ratio * 10) / 10;
    return ratio;
  }

  reducedRatio(x: number, y: number): string {
    if (y === 0) {
      return x + ' : ' + y;
    }
    const gcd = this.findGCD(x, y);
    if (gcd !== 1) {
      x /= gcd;
      y /= gcd;
    }
    return x + ' : ' + y;
  }

  precisionRatio(ratio: number): string {
    if (!isFinite(ratio) || ratio === 0) {
      return 'N/A';
    } else {
      return ratio + ' : 1';
    }
  }

  findGCD(x: number, y: number): number {
    while (y) {
      const t = y;
      y = x % y;
      x = t;
    }
    return x;
  }

  calculateGoal() {
    if (!this.desiredSales || !this.desiredListings) {
      this.commonService.warning('Please fill in both fields !');
      return;
    }
    this.showGoal = true;
    this._desiredSales = this.desiredSales;
    this._desiredListings = this.desiredListings;
    this.desiredSales = null;
    this.desiredListings = null;
    if (this.precisionRatio(this.S4OnO4) !== 'N/A') {
      this.s4 = Math.round(this._desiredListings * this.S4OnO4);
    } else {
      if (!this.defaultRatio.s4OnO4) {
        this.defaultRatio.s4OnO4 = 10;
      }
      this.s4 = Math.round(this._desiredListings * this.defaultRatio.s4OnO4);
    }
    if (this.precisionRatio(this.SPOnS4) !== 'N/A') {
      this.sp = Math.round(this.s4 * this.SPOnS4);
    } else {
      if (!this.defaultRatio.spOnS4) {
        this.defaultRatio.spOnS4 = 10;
      }
      this.sp = Math.round(this.s4 * this.defaultRatio.spOnS4);
    }
    if (this.precisionRatio(this.SAOnSP) !== 'N/A') {
      this.sa = Math.round(this.sp * this.SAOnSP);
    } else {
      if (!this.defaultRatio.saOnSp) {
        this.defaultRatio.saOnSp = 10;
      }
      this.sa = Math.round(this.sp * this.defaultRatio.saOnSp);
    }
    if (this.precisionRatio(this.SCOnSA) !== 'N/A') {
      this.sc = Math.round(this.sa * this.SCOnSA);
    } else {
      if (!this.defaultRatio.saOnSp) {
        this.defaultRatio.saOnSp = 10;
      }
      this.sc = Math.round(this.sa * this.defaultRatio.scOnSa);
    }
    if (this.precisionRatio(this.SNOnSC) !== 'N/A') {
      this.sn = Math.round(this._desiredListings * this.SNOnSC);
    } else {
      if (!this.defaultRatio.snOnSc) {
        this.defaultRatio.snOnSc = 10;
      }
      this.sn = Math.round(this.sc * this.defaultRatio.snOnSc);
    }
    if (this.precisionRatio(this.B5OnB6) !== 'N/A') {
      this.b5 = Math.round(this._desiredSales * this.B5OnB6);
    } else {
      if (!this.defaultRatio.b5OnB6) {
        this.defaultRatio.b5OnB6 = 10;
      }
      this.b5 = Math.round(this._desiredSales * this.defaultRatio.b5OnB6);
    }
    if (this.precisionRatio(this.B4OnB5) !== 'N/A') {
      this.b4 = Math.round(this.b5 * this.B4OnB5);
    } else {
      if (!this.defaultRatio.b4OnB5) {
        this.defaultRatio.b4OnB5 = 10;
      }
      this.b4 = Math.round(this.b5 * this.defaultRatio.b4OnB5);
    }
    if (this.precisionRatio(this.BIOnB4) !== 'N/A') {
      this.bi = Math.round(this.b4 * this.BIOnB4);
    } else {
      if (!this.defaultRatio.biOnB4) {
        this.defaultRatio.biOnB4 = 10;
      }
      this.bi = Math.round(this.b4 * this.defaultRatio.biOnB4);
    }
    if (this.precisionRatio(this.BAOnBI) !== 'N/A') {
      this.ba = Math.round(this.bi * this.BAOnBI);
    } else {
      if (!this.defaultRatio.baOnBi) {
        this.defaultRatio.baOnBi = 10;
      }
      this.ba = Math.round(this.bi * this.defaultRatio.baOnBi);
    }
    if (this.precisionRatio(this.BCOnBA) !== 'N/A') {
      this.bc = Math.round(this.ba * this.BCOnBA);
    } else {
      if (!this.defaultRatio.bcOnBa) {
        this.defaultRatio.bcOnBa = 10;
      }
      this.bc = Math.round(this.ba * this.defaultRatio.bcOnBa);
    }
    if (this.precisionRatio(this.BNOnBC) !== 'N/A') {
      this.bn = Math.round(this._desiredSales * this.BNOnBC);
    } else {
      if (!this.defaultRatio.bnOnBc) {
        this.defaultRatio.bnOnBc = 10;
      }
      this.bn = Math.round(this._desiredSales * this.defaultRatio.bnOnBc);
    }
  }


  onTypeChange(value: string) {
    this.selectedType = value;
    this.selectedNum = 1;
    this.generateNum();
    this.generateDate(1);
  }

  onNumChange(value: number) {
    this.selectedNum = value;
    this.generateDate(value);
  }

  onYearChange(value: number) {
    this.selectedYear = value;
    this.generateDate(this.selectedNum);
  }

  generateNum() {
    this.nums = [];
    switch (this.selectedType) {
      case 'Month':
        for (let i = 1; i <= 12; i++) {
          this.nums.push(i);
        }
        break;
      case 'Quarter':
        for (let i = 1; i <= 4; i++) {
          this.nums.push(i);
        }
        break;
    }
  }

  generateDate(value: number) {
    this.showGoal = false;
    switch (this.selectedType) {
      case 'Month':
        this.dateFrom = DateUtils.getFirstDayOfMonth(value - 1, this.selectedYear).startOf('day').valueOf();
        this.dateTo = DateUtils.getLastDayOfMonth(value - 1, this.selectedYear).endOf('day').valueOf();
        this.fetchRatio();
        break;
      case 'Quarter':
        const startWeek = 1 + (value - 1) * 13;
        const endWeek = 13 + (value - 1) * 13;
        this.dateFrom = DateUtils.getFirstDayOfWeek(startWeek, this.selectedYear).startOf('day').valueOf();
        this.dateTo = DateUtils.getLastDayOfWeek(endWeek, this.selectedYear).endOf('day').valueOf();
        this.fetchRatio();
        break;
      case 'Year':
        this.dateFrom = DateUtils.getFirstDayOfYear(this.selectedYear).startOf('day').valueOf();
        this.dateTo = DateUtils.getLastDayOfYear(this.selectedYear).endOf('day').valueOf();
        this.fetchRatio();
        break;
    }
  }

  fetchRatio() {
    const categoryIds = this.selectedCategories.map((category) => category.categoryId);
    const userIds = this.selectedUsers.map((user) => user.userId);
    this.dashboardService.fetchProspectStatistic(this.currentUser.office?.officeId,
      this.dateFrom,
      this.dateTo,
      userIds,
      categoryIds).subscribe((result: RestResult) => {
      if (result && result.data) {
        this.lastMonthStats = result.data;
        this.getAllRatio();
      }
    });
  }

  checkS4OnO4(): number {
    if (!isFinite(this.S4OnO4) || this.S4OnO4 === 0) {
      return this.defaultRatio.s4OnO4;
    } else {
      return this.S4OnO4;
    }
  }

  checkSpOnS4(): number {
    if (!isFinite(this.SPOnS4) || this.SPOnS4 === 0) {
      return this.defaultRatio.spOnS4;
    } else {
      return this.SPOnS4;
    }
  }

  checkSaOnSp(): number {
    if (!isFinite(this.SAOnSP) || this.SAOnSP === 0) {
      return this.defaultRatio.saOnSp;
    } else {
      return this.SAOnSP;
    }
  }

  checkScOnSa(): number {
    if (!isFinite(this.SCOnSA) || this.SCOnSA === 0) {
      return this.defaultRatio.scOnSa;
    } else {
      return this.SCOnSA;
    }
  }

  checkB5OnB6(): number {
    if (!isFinite(this.B5OnB6) || this.B5OnB6 === 0) {
      return this.defaultRatio.b5OnB6;
    } else {
      return this.B5OnB6;
    }
  }

  checkB4OnB5(): number {
    if (!isFinite(this.B4OnB5) || this.B4OnB5 === 0) {
      return this.defaultRatio.b4OnB5;
    } else {
      return this.B4OnB5;
    }
  }

  checkBiOnB4(): number {
    if (!isFinite(this.BIOnB4) || this.BIOnB4 === 0) {
      return this.defaultRatio.biOnB4;
    } else {
      return this.BIOnB4;
    }
  }

  checkBaOnBi(): number {
    if (!isFinite(this.BAOnBI) || this.BAOnBI === 0) {
      return this.defaultRatio.baOnBi;
    } else {
      return this.BAOnBI;
    }
  }

  checkBcOnBa(): number {
    if (!isFinite(this.BCOnBA) || this.BCOnBA === 0) {
      return this.defaultRatio.bcOnBa;
    } else {
      return this.BCOnBA;
    }
  }

  checkSnOnSc(): number {
    if (!isFinite(this.SNOnSC) || this.SNOnSC === 0) {
      return this.defaultRatio.snOnSc;
    } else {
      return this.SNOnSC;
    }
  }

  checkBnOnBc(): number {
    if (!isFinite(this.BNOnBC) || this.BNOnBC === 0) {
      return this.defaultRatio.bnOnBc;
    } else {
      return this.BNOnBC;
    }
  }

  checkS4OnO4Tooltip(): string {
    if (!isFinite(this.S4OnO4) || this.S4OnO4 === 0) {
      return 'Default value';
    } else {
      return null;
    }
  }

  checkSpOnS4Tooltip(): string {
    if (!isFinite(this.SPOnS4) || this.SPOnS4 === 0) {
      return 'Default value';
    } else {
      return null;
    }
  }

  checkSaOnSpTooltip(): string {
    if (!isFinite(this.SAOnSP) || this.SAOnSP === 0) {
      return 'Default value';
    } else {
      return null;
    }
  }

  checkScOnSaTooltip(): string {
    if (!isFinite(this.SCOnSA) || this.SCOnSA === 0) {
      return 'Default value';
    } else {
      return null;
    }
  }

  checkB5OnB6Tooltip(): string {
    if (!isFinite(this.B5OnB6) || this.B5OnB6 === 0) {
      return 'Default value';
    } else {
      return null;
    }
  }

  checkB4OnB5Tooltip(): string {
    if (!isFinite(this.B4OnB5) || this.B4OnB5 === 0) {
      return 'Default value';
    } else {
      return null;
    }
  }

  checkBiOnB4Tooltip(): string {
    if (!isFinite(this.BIOnB4) || this.BIOnB4 === 0) {
      return 'Default value';
    } else {
      return null;
    }
  }

  checkBaOnBiTooltip(): string {
    if (!isFinite(this.BAOnBI) || this.BAOnBI === 0) {
      return 'Default value';
    } else {
      return null;
    }
  }

  checkBcOnBaTooltip(): string {
    if (!isFinite(this.BCOnBA) || this.BCOnBA === 0) {
      return 'Default value';
    } else {
      return null;
    }
  }

  checkSnOnScTooltip(): string {
    if (!isFinite(this.SNOnSC) || this.SNOnSC === 0) {
      return 'Default value';
    } else {
      return null;
    }
  }

  checkBnOnBcTooltip(): string {
    if (!isFinite(this.BNOnBC) || this.BNOnBC === 0) {
      return 'Default value';
    } else {
      return null;
    }
  }

  checkS4OnO4TooltipStatus(): NbTrigger {
    if (!isFinite(this.S4OnO4) || this.S4OnO4 === 0) {
      return NbTrigger.HOVER;
    } else {
      return NbTrigger.NOOP;
    }
  }

  checkSpOnS4TooltipStatus(): NbTrigger {
    if (!isFinite(this.SPOnS4) || this.SPOnS4 === 0) {
      return NbTrigger.HOVER;
    } else {
      return NbTrigger.NOOP;
    }
  }

  checkSaOnSpTooltipStatus(): NbTrigger {
    if (!isFinite(this.SAOnSP) || this.SAOnSP === 0) {
      return NbTrigger.HOVER;
    } else {
      return NbTrigger.NOOP;
    }
  }

  checkScOnSaTooltipStatus(): NbTrigger {
    if (!isFinite(this.SCOnSA) || this.SCOnSA === 0) {
      return NbTrigger.HOVER;
    } else {
      return NbTrigger.NOOP;
    }
  }

  checkB5OnB6TooltipStatus(): NbTrigger {
    if (!isFinite(this.B5OnB6) || this.B5OnB6 === 0) {
      return NbTrigger.HOVER;
    } else {
      return NbTrigger.NOOP;
    }
  }

  checkB4OnB5TooltipStatus(): NbTrigger {
    if (!isFinite(this.B4OnB5) || this.B4OnB5 === 0) {
      return NbTrigger.HOVER;
    } else {
      return NbTrigger.NOOP;
    }
  }

  checkBiOnB4TooltipStatus(): NbTrigger {
    if (!isFinite(this.BIOnB4) || this.BIOnB4 === 0) {
      return NbTrigger.HOVER;
    } else {
      return NbTrigger.NOOP;
    }
  }

  checkBaOnBiTooltipStatus(): NbTrigger {
    if (!isFinite(this.BAOnBI) || this.BAOnBI === 0) {
      return NbTrigger.HOVER;
    } else {
      return NbTrigger.NOOP;
    }
  }

  checkBcOnBaTooltipStatus(): NbTrigger {
    if (!isFinite(this.BCOnBA) || this.BCOnBA === 0) {
      return NbTrigger.HOVER;
    } else {
      return NbTrigger.NOOP;
    }
  }

  checkSnOnScTooltipStatus(): NbTrigger {
    if (!isFinite(this.SNOnSC) || this.SNOnSC === 0) {
      return NbTrigger.HOVER;
    } else {
      return NbTrigger.NOOP;
    }
  }

  checkBnOnBcTooltipStatus(): NbTrigger {
    if (!isFinite(this.BNOnBC) || this.BNOnBC === 0) {
      return NbTrigger.HOVER;
    } else {
      return NbTrigger.NOOP;
    }
  }
}
