import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NbDialogService} from '@nebular/theme';
import {ToastService} from '../../../../shared/services/toast.service';
import {TaskService} from '../../../../shared/services/task.service';
import {CalendarEvent, CalendarEventTimesChangedEvent, CalendarView, DAYS_OF_WEEK} from 'angular-calendar';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {Task} from '../../../../shared/models/response/task';
import {Constant} from '../../../../shared/common/constant';
import {User} from '../../../../shared/models/response/user';
import {SearchRequest} from '../../../../shared/models/request/search-request';
import {map} from 'rxjs/operators';
import * as moment from 'moment';
import {autocomplete, CommonService} from '../../../../shared/services/common.service';
import {UserService} from '../../../../shared/services/user.service';
import {DateUtils} from '../../../../shared/common/date-utils';
import {TaskType} from '../../../../shared/models/response/task-type';
import {TaskTypeService} from '../../../../shared/services/task-type.service';
import {RestResult} from '../../../../shared/models/response/rest-result';
import {TaskMiniDialogComponent} from '../task-mini-dialog/task-mini-dialog.component';
import {TaskStatus} from '../../../../shared/models/response/task-status';
import {TaskStatusService} from '../../../../shared/services/task-status.service';
import {TaskSearchRequest} from '../../../../shared/models/request/task-search-request';
import {TaskStatistic} from '../../../../shared/models/response/task-statistic';
import {Prospect} from '../../../../shared/models/response/prospect/prospect';
import {WeekStatisticRequest, WeekStatisticResponse} from '../common/custom-model';
import {ComponentPermission} from '../../../../component.permission';
import {TaskTypeGroup} from '../../../../shared/models/response/task-type-group';
import {CompanyService} from '../../../../shared/services/company.service';
import {Company} from '../../../../shared/models/response/prospect/company';
import {TaskRequest} from '../../../../shared/models/request/task-request';
import {ConfirmDialogComponent} from '../../confirm-dialog/confirm-dialog.component';
import {City} from '../../../../shared/models/response/address/city';
import {LocalService} from '../../../../shared/services/local.service';
import {StringUtils} from '../../../../shared/common/string-utils';
import { AuthService } from '../../../../shared/services/auth.service';

@Component({
  selector: 'ngx-task-calendar',
  templateUrl: './task-calendar.component.html',
  styleUrls: ['./task-calendar.component.scss'],
})
export class TaskCalendarComponent implements OnInit {
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  ComponentPermission = ComponentPermission;
  view: CalendarView = CalendarView.Month;
  weekStartOn = DAYS_OF_WEEK.WEDNESDAY;

  CalendarView = CalendarView;
  viewDate: Date = new Date();
  refresh: Subject<any> = new Subject();
  events: Observable<CalendarEvent[]>;
  activeDayIsOpen: boolean = true;
  tasks: Task[];
  currentUser: User;

  filterUser: User;
  autoCompleteBehavior = new BehaviorSubject<string>('');
  filteredUsers: Observable<{
    data: User[],
    keyword: string,
  }> = this.autoCompleteBehavior.pipe(
    autocomplete(500, (keyword => this.searchUser(keyword))),
  );
  filterStatus = {};
  filterType = {};
  taskTypes: TaskType[];
  taskStatuses: TaskStatus[];
  taskStatistics: TaskStatistic[];
  taskStatisticsFrom: number = null;
  taskStatisticsTo: number = null;
  weekStatistics: WeekStatisticResponse[] = [];
  taskTypeWrappers: TaskTypeWrapper[];
  companies: Company[];
  isSp: boolean;
  isS4: boolean;
  onlyFilterOfficeStaff = false;
  onlyFilterMySelf = false;
  cities: City[];
  isDisplay = false;
  StringUtils = StringUtils;

  constructor(private dialogService: NbDialogService,
              private toastService: ToastService,
              private taskService: TaskService,
              private commonService: CommonService,
              private userService: UserService,
              private taskTypeService: TaskTypeService,
              private taskStatusService: TaskStatusService,
              private companyService: CompanyService,
              private localService: LocalService,
              private authService: AuthService) {
  }

  ngOnInit(): void {
    this.currentUser = this.authService.currentUser;
    this.updateStatisticOnHeader();
    this.searchTask();
    this.getTaskTypes();
    this.getTaskStatus();
    this.getCompanies();
    this.localService.cities().subscribe((result: City[]) => {
      this.cities = result;
    });
    const configDate = DateUtils.getConfigDate();
    this.weekStartOn = DAYS_OF_WEEK[configDate];
  }

  get CheckPermission() {
    return ComponentPermission;
  }

  getTaskTypes() {
    const taskTypeSearchRequest: SearchRequest = new SearchRequest();
    taskTypeSearchRequest.orders = [];
    taskTypeSearchRequest.orders.push(
      {
        left: 'name',
        right: 'asc',

      },
    );
    this.taskTypeService.search(taskTypeSearchRequest).subscribe((result: RestResult) => {
      this.taskTypes = result.data;
      this.extractTaskTypeWrapper();
    });
  }

  extractTaskTypeWrapper() {
    if (!this.taskTypes) {
      return;
    }
    this.taskTypeWrappers = [];
    for (const taskType of this.taskTypes) {
      let group = this.taskTypeWrappers.find(x => x?.group?.taskTypeGroupId === taskType?.group?.taskTypeGroupId);
      if (! group) {
        group = {
          group: taskType.group,
          types: [],
        };
        this.taskTypeWrappers.push(group);
      }
      group.types.push(taskType);
    }
  }

  getTaskStatus() {
    const taskStatusSearchRequest: SearchRequest = new SearchRequest();
    taskStatusSearchRequest.orders = [];
    taskStatusSearchRequest.orders.push(
      {
        left: 'name',
        right: 'asc',

      },
    );
    this.taskStatusService.search(taskStatusSearchRequest).subscribe((result: RestResult) => {
      this.taskStatuses = result.data;
    });
  }

  getCompanies() {
    const searchRequest = new SearchRequest();
    searchRequest.offset = 0;
    searchRequest.limit = 10000;
    searchRequest.orders = new Array();
    searchRequest.orders.push(
      {
        left: 'name',
        right: 'asc',

      },
    );
    this.companyService.search(searchRequest).subscribe((result: RestResult) => {
      this.companies = result?.data;
    });
  }

  getTaskStatistic() {
    const searchRequest: TaskSearchRequest = this.prepareTaskSearchRequest();
    searchRequest.fromDate = this.taskStatisticsFrom;
    searchRequest.toDate = this.taskStatisticsTo;
    if (this.filterUser?.userId) {
      searchRequest.toUserId = this.filterUser?.userId;
    } else if (this.onlyFilterOfficeStaff) {
      searchRequest.filterForOfficers = true;
    } else if (this.onlyFilterMySelf && this.currentUser) {
      searchRequest.toUserId = this.currentUser.userId;
    }
    this.taskService.getStatistic(searchRequest).subscribe((result: RestResult) => {
      if (result.data) {
        this.taskStatistics = result.data;
        for (const statistic of this.taskStatistics) {
          const type = this.taskTypes?.find(x => x.taskTypeId === statistic.taskTypeId);
          if (type) {
            statistic['name'] = type.name;
          }
        }
      }
    });
  }

  getTaskStatisticByTimeRange(value: {
    fromDate: number,
    toDate: number,
  }) {
    this.taskStatisticsFrom = value.fromDate;
    this.taskStatisticsTo = value.toDate;
    this.getTaskStatistic();
  }

  getTaskStatisticByDay() {
    const start = new Date();
    const end = new Date();
    start.setTime(this.viewDate.getTime());
    end.setTime(this.viewDate.getTime());
    start?.setHours(0, 0, 0, 0);
    end?.setHours(23, 59, 59, 999);
    this.getTaskStatisticByTimeRange({
      fromDate: start.getTime(),
      toDate: end.getTime(),
    });
  }

  getTaskStatisticByMonth() {
    const start = new Date();
    const end = new Date();
    // const startOfMonth = DateUtils.getFirstDayOfMonth(this.viewDate.getMonth(), this.viewDate.getFullYear());
    // const endOfMonth = DateUtils.getLastDayOfMonth(this.viewDate.getMonth(), this.viewDate.getFullYear());
    const startOfMonth = new Date(this.viewDate.getFullYear(), this.viewDate.getMonth(), 1);
    const endOfMonth = new Date(this.viewDate.getFullYear(), this.viewDate.getMonth() + 1, 0);
    start.setTime(startOfMonth.valueOf());
    end.setTime(endOfMonth.valueOf());
    start?.setHours(0, 0, 0, 0);
    end?.setHours(23, 59, 59, 999);
    this.getTaskStatisticByTimeRange({
      fromDate: start.getTime(),
      toDate: end.getTime(),
    });
  }

  getTaskStatisticByWeek() {
    const start = new Date();
    const end = new Date();
    const currentWeek = DateUtils.getCurrentWeekFromTimestamp(this.viewDate.getTime());
    const startOfWeek = DateUtils.getFirstDayOfWeek(currentWeek, this.viewDate.getFullYear());
    const endOfWeek = DateUtils.getLastDayOfWeek(currentWeek, this.viewDate.getFullYear());
    start.setTime(startOfWeek.valueOf());
    end.setTime(endOfWeek.valueOf());
    start?.setHours(0, 0, 0, 0);
    end?.setHours(23, 59, 59, 999);
    this.getTaskStatisticByTimeRange({
      fromDate: start.getTime(),
      toDate: end.getTime(),
    });
  }

  updateStatisticOnHeader() {
    switch (this.view) {
      case CalendarView.Day:
        this.getTaskStatisticByDay();
        break;
      case CalendarView.Month:
        this.getTaskStatisticByMonth();
        break;
      case CalendarView.Week:
        this.getTaskStatisticByWeek();
        break;
    }
  }

  searchTask() {
    const searchRequest: TaskSearchRequest = this.prepareTaskSearchRequest();
    this.events = this.taskService.getList(searchRequest).pipe(
      map((result) => {
        if (result.data) {
          this.tasks = result.data;
          return this.tasks.map((task) => {
            return task.toDate ? {
              start: new Date(task.fromDate),
              end: new Date(task.toDate),
              title: task.name,
              meta: task,
              draggable: false,
            } : {
              start: new Date(task.fromDate),
              title: task.name,
              meta: task,
              draggable: false,
            };
          });
        }
      }),
    );
  }

  eventTimesChanged({event, newStart, newEnd}: CalendarEventTimesChangedEvent): void {
    this.dialogService.open(ConfirmDialogComponent, {
      context: {
        content: 'Are you sure?',
      },
    })
    .onClose.subscribe(res => {
      if (res === ConfirmDialogComponent.confirmOk) {
        const taskRequest: TaskRequest = {...event.meta};
        taskRequest.fromDate = newStart.getTime();
        taskRequest.toDate = newEnd.getTime();
        this.taskService.update(taskRequest).subscribe(
          result => {
            this.commonService.info('Update task success.');
            this.searchTask();
          },
          error => {
            this.commonService.warningHtml(error.message);
          },
        );
      }
    });
  }

  prepareTaskSearchRequest() {
    const searchRequest: TaskSearchRequest = new TaskSearchRequest();
    searchRequest.offset = 0;
    searchRequest.limit = 100000;
    if (this.filterUser?.userId) {
      searchRequest.toUserId = this.filterUser?.userId;
    } else if (this.onlyFilterOfficeStaff) {
      searchRequest.filterForOfficers = true;
    } else if (this.onlyFilterMySelf && this.currentUser) {
      searchRequest.toUserId = this.currentUser.userId;
    }
    const statusArray = new Array();
    for (const key of Object.keys(this.filterStatus)) {
      if (this.filterStatus[key] === true) {
        statusArray.push(key);
      }
    }
    const typeArray = new Array();
    for (const key of Object.keys(this.filterType)) {
      if (this.filterType[key] === true) {
        try {
          typeArray.push(Number(key));
        } catch (ignore) {}
      }
    }
    searchRequest.statuses = statusArray;
    searchRequest.taskTypeIds = typeArray;
    if (this.viewDate) {
      searchRequest.fromDate = this.taskStatisticsFrom;
      searchRequest.toDate   = this.taskStatisticsTo;
    }
    searchRequest.officeId = this.currentUser.office?.officeId;
    return searchRequest;
  }

  setView(view: CalendarView) {
    this.view = view;
    this.updateStatisticOnHeader();
    this.searchTask();
  }

  closeOpenMonthViewDay() {
    this.updateStatisticOnHeader();
    this.searchTask();
    this.activeDayIsOpen = false;
    this.weekStatistics = [];
  }

  // create new task
  openTaskDialog(date: Date): void {
    this.isDisplay = true;
    const defaultDate = this.prepareDefaultDate(date?.getTime());
    this.dialogService.open(TaskMiniDialogComponent, { hasScroll: true,
      context: {
        taskTypes: this.taskTypes,
        taskStatuses: this.taskStatuses,
        companies: this.companies,
      },
    })
    .onClose.subscribe(res => {
      this.isDisplay = false;
      if (res) {
        this.searchTask();
      }
    });
  }

  editTask(event: CalendarEvent | any): void {
    this.isDisplay = true;
    if (!event || !event.meta) {
      return;
    }
    const task: Task = event.meta;
    this.dialogService.open(TaskMiniDialogComponent, { hasScroll: true,
      context: {
        defaultData: {
          task,
        },
        taskTypes: this.taskTypes,
        taskStatuses: this.taskStatuses,
        companies: this.companies,
        cities: this.cities,
      },
    })
    .onClose.subscribe(res => {
      this.isDisplay = false;
      if (res) {
        this.searchTask();
      }
    });
  }

  prepareDefaultDate(time: number) {
    if (!time) {
      return {
        date: null,
        hours: '00',
        minutes:  '00',
      };
    }
    const hours = moment(time).get('hours');
    const minutes = moment(time).get('minutes');
    return {
      date: new Date(time),
      hours: `${hours < 10 ? '0' : '' }${hours.toString()}`,
      minutes:  `${minutes < 10 ? '0' : '' }${minutes.toString()}`,
    };
  }

  searchUser(keyword: string): Observable<{
    data: User[],
    keyword: string,
  }> {
    const searchRequest = new SearchRequest();
    searchRequest.conditions = new Array();
    searchRequest.conditions.push(
      {
        left: 'email',
        middle: 'like',
        right: keyword,
      },
    );
    searchRequest.conditions.push(
      {
        left: 'username',
        middle: 'like',
        right: keyword,
      },
    );
    searchRequest.conditionType = Constant.CONDITION_TYPE.OR;
    searchRequest.offset = 0;
    searchRequest.limit = 10;
    return this.userService.search(searchRequest).pipe(
      map(result => {
        return {
          data: result.data,
          keyword,
        };
      }),
    );
  }

  onUserFilterSelectionChange($event) {
    if (!$event || $event === '' || !this.filteredUsers) {
      return;
    }
    this.onlyFilterOfficeStaff = false;
    this.onlyFilterMySelf = false;
    this.filteredUsers.subscribe(data => {
      const users = data?.data;
      const keyword = data?.keyword;
      if (!keyword || keyword === '') {
        this.filterUser = null;
      } else if ($event && $event !== '') {
        this.filterUser = users.find(x => x.username === $event);
      }
      if (this.filterUser || !keyword || keyword === '') {
        this.filterTasks();
      }
    });
  }

  filterTasks() {
    this.updateStatisticOnHeader();
    this.searchTask();
  }

  clearFilter() {
    this.filterUser = null;
    this.viewDate = new Date();
    for (const key of Object.keys(this.filterStatus)) {
      this.filterStatus[key] = false;
    }
    this.searchTask();
  }

  getNoWeek(date: any) {
    try {
      const dateMoment = moment(date.getTime());
      return 'W' + DateUtils.getCurrentWeek(dateMoment);
    } catch (ignore) {}
    return '';
  }

  statusCheckboxChanged(status: string, value: boolean) {
    this.filterStatus[status] = value;
  }

  typeCheckboxChanged(taskTypeId: number, value: boolean) {
    this.filterType[taskTypeId] = value;
  }

  // load week static
  getWeekStatistic(week: WeekStatisticRequest) {
    if (!week.fromDate || !week.toDate) {
      return;
    }
    const startOfMonth = moment(this.viewDate.getTime()).startOf('month').valueOf();
    const endOfMonth   = moment(this.viewDate.getTime()).endOf('month').valueOf();
    const searchRequest: TaskSearchRequest = this.prepareTaskSearchRequest();
    searchRequest.fromDate = week.fromDate;
    searchRequest.toDate = week.toDate;
    if (searchRequest.fromDate < startOfMonth) {
      searchRequest.fromDate = startOfMonth;
    }
    if (searchRequest.toDate > endOfMonth) {
      searchRequest.toDate = endOfMonth;
    }
    this.taskService.getStatistic(searchRequest).subscribe((result: RestResult) => {
      if (result.data) {
        this.weekStatistics.push({
          rowIndex: week.rowIndex,
          taskStatistics: result.data,
        });
      }
    });
  }

  getAddressText(prospect: Prospect): string {
    if (!prospect) {
      return '';
    }
    let result = '';
    result += StringUtils.getFullAddress(prospect?.address);

    // if (prospect?.address?.unit) {
    //   result += prospect?.address?.unit + ' ';
    // }
    // result += prospect?.address?.number + ' ';
    // result += prospect?.address?.street?.name + ' ';
    // result += prospect?.address?.street?.district?.name + ' ';
    // result += prospect?.address?.street?.district?.city?.name;
    return result;
  }

  getAddressShortText(prospect: Prospect): string {
    if (!prospect) {
      return '';
    }
    let result = '';
    // TODO change address
    result += StringUtils.getFullAddress(prospect?.address);
    // if (prospect?.address?.unit) {
    //   result += prospect?.address?.unit + ', ';
    // }
    // result += prospect?.address?.number + ', ';
    // result += prospect?.address?.street?.name + ', ';
    // result += prospect?.address?.street?.district?.name + ' ';
    return result;
  }

  shortenUserName(user: User) {
    if (!user) {
      return '';
    }
    if (user.firstName || user.lastName) {
      return user?.firstName[0]?.toUpperCase() + user?.lastName[0]?.toUpperCase();
    }
    if (user.name) {
      return this.getShortName(user.name);
    }
    return this.getShortName(user.username);
  }

  getShortName(name: string) {
    const items: string[] = name?.replace(/[\s]+/g, ' ').trim()?.split(' ');
    let result = '';
    for (const item of items) {
      result += item[0]?.toUpperCase();
    }
    return result;
  }

  isSpOrS4(task: Task | any) {
    return (task?.isSp && this.isSp) || (task?.isS4 && this.isS4);
  }

  setOnlyFilterOfficeStaff() {
    this.onlyFilterOfficeStaff = !this.onlyFilterOfficeStaff;
    this.onlyFilterMySelf = false;
    this.filterUser = null;
    this.searchTask();
  }

  setOnlyFilterMySelf() {
    this.onlyFilterMySelf = !this.onlyFilterMySelf;
    this.onlyFilterOfficeStaff = false;
    this.filterUser = null;
    this.searchTask();
  }

  updateTaskSPS4(event, task: Task, updateType: 'sp' | 's4') {
    event.stopPropagation();
    const request: TaskRequest = new TaskRequest();
    request.taskId = task.taskId;
    switch (updateType) {
      case 's4':
        request.isS4 = !task.isS4;
        break;
      case 'sp':
        request.isSp = !task.isSp;
        break;
    }
    this.taskService.update(request).subscribe(
      result => {
        this.commonService.info('Update task success.');
        this.searchTask();
      },
      error => {
        this.commonService.warningHtml(error.message);
      },
    );
  }

  checkToHideS4Options(task: Task): boolean {
    return task?.type.category?.name !== 'CLIENT'
      || !task?.prospect
      || task?.prospect.rate < 3;
  }

  updateTaskBiB4(event, task: Task, updateType: 'bi' | 'b4') {
    event.stopPropagation();
    const request: TaskRequest = new TaskRequest();
    request.taskId = task.taskId;
    switch (updateType) {
      case 'bi':
        request.isBi = !task.isBi;
        break;
      case 'b4':
        request.isB4 = !task.isB4;
        break;
    }
    this.taskService.update(request).subscribe(
      result => {
        this.commonService.info('Update task success.');
        this.searchTask();
      },
      error => {
        this.commonService.warningHtml(error.message);
      },
    );
  }

  checkToHideSPOptions(task: Task): boolean {
    return task?.type.category?.name !== 'CLIENT';
  }
  checkToHideBOptions(task: Task): boolean {
    return task?.type.category?.name !== 'CUSTOMER';
  }
}

class TaskTypeWrapper {
  group: TaskTypeGroup;
  types: TaskType[];
}
