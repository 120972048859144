import {Component, EventEmitter, OnInit, Optional, Output, ViewChild} from '@angular/core';
import {RestResult} from '../../../../shared/models/response/rest-result';
import {CityService} from '../../../../shared/services/city.service';
import {District} from '../../../../shared/models/response/address/district';
import {Street} from '../../../../shared/models/response/address/street';
import {City} from '../../../../shared/models/response/address/city';
import {SearchRequest} from '../../../../shared/models/request/search-request';
import {SuburbService} from '../../../../shared/services/suburb.service';
import {StreetService} from '../../../../shared/services/street.service';
import {Farm} from '../../../../shared/models/response/address/farm';
import {Zone} from '../../../../shared/models/response/address/zone';
import {Company} from '../../../../shared/models/response/prospect/company';
import {TaskTypeService} from '../../../../shared/services/task-type.service';
import {CompanyService} from '../../../../shared/services/company.service';
import {BuyerListComponent} from '../buyer-list/buyer-list.component';
import {BuyerService} from '../../../../shared/services/buyer.service';
import {CustomerBoardResponse} from '../../../../shared/models/response/buyer/customer-board-response';
import {Constant} from '../../../../shared/common/constant';
import {CustomerBoard} from '../../../../shared/models/response/buyer/customer-board';
import {CommonService} from '../../../../shared/services/common.service';
import {CustomerBoardSearchRequest} from '../../../../shared/models/request/buyer/customer-board-search-request';
import {LocalService} from '../../../../shared/services/local.service';
import {BuyerSearchRequest} from '../../../../shared/models/request/buyer/buyer-search-request';
import {AuthService} from '../../../../shared/services/auth.service';
import {User} from '../../../../shared/models/response/user';
import {UserUtils} from '../../../../shared/common/user-utils';
import {ComponentPermission} from '../../../../component.permission';
import {DateUtils} from '../../../../shared/common/date-utils';
import {ProspectService} from '../../../../shared/services/prospect.service';
import {UserDailyGoal} from '../../../../shared/models/response/user-daily-goal';
import {ActivatedRoute, Router} from '@angular/router';
import {NbDialogRef} from '@nebular/theme';

@Component({
  selector: 'app-customer-board',
  templateUrl: './customer-board.component.html',
  styleUrls: ['./customer-board.component.scss'],
})
export class CustomerBoardComponent implements OnInit {
  @ViewChild(BuyerListComponent) buyerListComponent: BuyerListComponent;
  @Output() emailGroupRequest = new EventEmitter();
  ComponentPermission = ComponentPermission;
  districts: District[];
  streets: Street[];
  cities: City[];
  farms: Farm[];
  zones: Zone[];
  companies: Company[];
  isDisplayed = false;
  isDialog: boolean;

  customerBoardResponse: CustomerBoardResponse;
  searchRequest = new CustomerBoardSearchRequest();

  levelLabels: string[] = [];
  houseTypeLabels = Constant.BUYER_TYPE;
  priceTypeLabels = Constant.PRICE_TYPE;
  filteredDistricts: District[] = [];
  filteredZones: Zone[] = [];
  filteredStreets: Street[] = [];
  filteredFarms: Farm[] = [];
  filteredPostcodes: string[] = [];
  categoryIds: number[];
  currentUser: User;
  userUtils = UserUtils;
  passDailyGoal = null;
  daily: UserDailyGoal;
  remainingCall: number;
  haveDailyGoalOrPermission = null;
  param = {};
  isFiltered = false;

  constructor(private cityService: CityService,
              private buyerService: BuyerService,
              private commonService: CommonService,
              private suburbService: SuburbService,
              private localService: LocalService,
              private taskTypeService: TaskTypeService,
              private streetService: StreetService,
              private companyService: CompanyService,
              private authService: AuthService,
              private prospectService: ProspectService,
              private route: ActivatedRoute,
              private router: Router,
              @Optional() protected ref: NbDialogRef<CustomerBoardComponent>) {
  }

  ngOnInit(): void {
    this.param = this.route.snapshot.queryParams;
    this.currentUser = this.authService.currentUser;
    if (!this.userUtils.userIsAdmin(this.currentUser)) {
      this.onSelectUser(this.currentUser.userId);
    }
    const date = new Date();
    const fromDate = DateUtils.getStartOfDate(date.getDate(), date.getMonth(), date.getFullYear()).valueOf();
    const toDate = DateUtils.getEndOfDate(date.getDate(), date.getMonth(), date.getFullYear()).valueOf();
    const userId = [];
    userId.push(this.currentUser.userId);
    if (UserUtils.userOverrideDailyGoal(this.currentUser) === false) {
      if (typeof this.currentUser.dailyGoal === 'undefined') {
        this.haveDailyGoalOrPermission = false;
      } else {
        this.haveDailyGoalOrPermission = true;
        this.prospectService.countCurrentDailyGoal(fromDate, toDate, userId).subscribe(result => {
          this.daily = UserUtils.setDailyGoal(result.data);
          if (this.daily.isCompleted) {
            this.initCustomerBoard();
          } else {
            this.passDailyGoal = false;
            this.remainingCall = this.currentUser.dailyGoal - this.daily.totalCall;
          }
        });
      }
    } else {
      this.initCustomerBoard();
    }
  }

  initCustomerBoard() {
    this.preLoadAddressForFilter();
    this.passDailyGoal = true;
    this.initCategory();
    this.initFilter();
    this.search();
    this.initHorizontalLabel();
  }

  initCategory() {
    this.categoryIds = [];
    if (localStorage.getItem(Constant.CUSTOMER_CATEGORY_LIST)) {
      const categories = JSON.parse(localStorage.getItem(Constant.CUSTOMER_CATEGORY_LIST));
      categories.forEach(category => {
        this.categoryIds.push(category.categoryId);
      });
    }
  }


  initHorizontalLabel() {
    this.levelLabels.push('SSN');
    this.levelLabels.push('SS');
    this.levelLabels.push('S');
    this.levelLabels.push('C');
    this.levelLabels.push('A');
    this.levelLabels.push('N');
    this.levelLabels.push('1');
    this.levelLabels.push('CA');
    this.levelLabels.push('AN');
    this.levelLabels.push('CN');
    this.levelLabels.push('2');
    this.levelLabels.push('CAN(3)');
    this.levelLabels.push('D');
    this.levelLabels.push('E');
    this.levelLabels.push('LV6');
    this.levelLabels.push('LV7');
  }

  initFilter() {
    const searchRequest = new SearchRequest();
    searchRequest.offset = 0;
    searchRequest.limit = 10000;
    searchRequest.orders = new Array();
    searchRequest.orders.push(
      {
        left: 'name',
        right: 'asc',

      },
    );
    this.companyService.search(searchRequest).subscribe((result: RestResult) => {
      this.companies = result?.data;
    });
    searchRequest.conditionType = 'AND';
    searchRequest.conditions = [
      {
        left: 'status',
        middle: '=',
        right: 'ACTIVE',
      },
    ];
    this.cityService.search(searchRequest).subscribe((result: RestResult) => {
      this.cities = result.data;
    });
  }

  onCityChange(cityId: number) {
    this.searchRequest.cityId = cityId;
    this.searchRequest.zoneId = undefined;
    this.searchRequest.districtId = undefined;
    this.searchRequest.farmId = undefined;
    this.searchRequest.streetId = undefined;
    if (cityId) {
      this.localService.districts(cityId).subscribe(result => {
        this.filteredDistricts = result;
        this.filteredPostcodes = this.localService.postcodes(this.filteredDistricts);
      });
      // this.localService.zones(cityId).subscribe(result => {
      //   this.filteredZones = result;
      // });
    }
  }

  onZoneChange(zoneId: number) {
    this.searchRequest.zoneId = zoneId;
    this.searchRequest.streetId = undefined;
    this.searchRequest.farmId = undefined;
    this.searchRequest.districtId = undefined;
    // reset filter
    if (zoneId) {
      this.localService.districtsByZone(zoneId).subscribe(result => {
        this.filteredDistricts = result;
      });
    } else {
      this.localService.districts(this.searchRequest.cityId).subscribe(result => {
        this.filteredDistricts = result;
      });
    }
  }

  onPostcodeChange(postcode: number) {
    this.searchRequest.postcode = Number(postcode);
    this.searchRequest.districtId = undefined;
    this.searchRequest.streetId = undefined;
    this.searchRequest.farmId = undefined;
    if (postcode) {
      this.localService.districtByPostcode(postcode).subscribe(result => {
        this.filteredDistricts = result;
      });
    } else {
      this.localService.districts(this.searchRequest.cityId).subscribe(result => {
        this.filteredDistricts = result;
      });
    }
  }

  onSuburbChange(districtId: number) {
    this.searchRequest.districtId = districtId;
    this.searchRequest.streetId = undefined;
    this.searchRequest.farmId = undefined;
    if (districtId) {
      this.localService.streets(districtId).subscribe(result => {
        this.filteredStreets = result;
      });
      // this.localService.farms(districtId).subscribe(result => {
      //   this.filteredFarms = result;
      // });
    }
  }


  /**
   * not used
   */
  onFarmChange(farmId: number) {

  }

  /**
   * not used
   */
  onStreetChange(streetId: number) {

  }

  onRateChange(rate: number) {
    this.searchRequest.rate = rate;
  }

  onSearchButtonClick() {
    this.isFiltered = true;
    if (this.param['i']) {
      delete this.param['i'];
    }
    if (this.param['type']) {
      delete this.param['type'];
    }
    this.appendFilterToParameter();
    this.navigateWithParam();
    this.search();
  }

  search() {
    if (this.isFiltered === false) {
      this.appendParameterToSearchRequest();
    }
    if (this.searchRequest.start) {
      this.searchRequest.startDate = this.commonService.parseDate(this.searchRequest.start, 'DD/MM/YYYY');
    }
    if (this.searchRequest.end) {
      this.searchRequest.endDate = this.commonService.parseDate(this.searchRequest.end, 'DD/MM/YYYY');
    }
    if (this.categoryIds) {
      this.searchRequest.categoryIds = this.categoryIds;
    }
    this.searchRequest.officeId = this.currentUser.office?.officeId;
    const self = this;
    this.buyerService.searchCustomerBoard(this.searchRequest).subscribe((result: RestResult) => {
      if (result && result.data) {
        self.customerBoardResponse = result.data;
        this.restoreSelectedState();
      }
    });
    this.isDisplayed = false;
  }

  getValueSRFI(srfiCode: string, index: number) {
    const srfiSCode = this.customerBoardResponse.srfiSCode;
    const srfiBCode = this.customerBoardResponse.srfiBCode;
    let value;
    let array;
    if (index === 1) {
      array = srfiSCode.filter(
        element =>
          element.has_district_id &&
          element.has_street_id &&
          element.has_number_id);
    } else if (index === 2) {
      array = srfiSCode.filter(
        element =>
          element.has_district_id &&
          element.has_street_id &&
          !element.has_number_id);
    } else if (index === 3) {
      array = srfiSCode.filter(
        element =>
          element.has_district_id &&
          !element.has_street_id &&
          !element.has_number_id);
    } else if (index === 4) {
      array = srfiBCode.filter(element =>
        element.rate === 1 &&
        element.has_common &&
        !element.has_address &&
        !element.has_number);
    } else if (index === 5) {
      array = srfiBCode.filter(element =>
        element.rate === 1 &&
        element.has_address &&
        !element.has_common &&
        !element.has_number);
    } else if (index === 6) {
      array = srfiBCode.filter(element =>
        element.rate === 1 &&
        element.has_number &&
        !element.has_address &&
        !element.has_common);
    } else if (index === 7) {
      value = new CustomerBoard();
      value.total = 0;
      const array1 = srfiBCode.filter(element => element.rate === 1 && element.has_common);
      const value1 = this.getValue(srfiCode, array1);
      if (value1) {
        value.total += value1.total;
      }
      const array2 = srfiBCode.filter(element => element.rate === 1 && element.has_address);
      const value2 = this.getValue(srfiCode, array2);
      if (value2) {
        value.total += value2.total;
      }
      const array3 = srfiBCode.filter(element => element.rate === 1 && element.has_number);
      const value3 = this.getValue(srfiCode, array3);
      if (value3) {
        value.total += value3.total;
      }
    }
    // rate lv 2
    if (index === 8) {
      array = srfiBCode.filter(element =>
        element.rate === 2 && element.has_common && element.has_address && !element.has_number);
    } else if (index === 9) {
      array = srfiBCode.filter(element =>
        element.rate === 2 && element.has_address && element.has_number && !element.has_common);
    } else if (index === 10) {
      array = srfiBCode.filter(element =>
        element.rate === 2 && element.has_common && element.has_number && !element.has_address);
    } else if (index === 11) {
      value = new CustomerBoard();
      value.total = 0;
      const array1 = srfiBCode.filter(element => element.rate === 2 && element.has_common && element.has_address);
      const value1 = this.getValue(srfiCode, array1);
      if (value1) {
        value.total += value1.total;
      }
      const array2 = srfiBCode.filter(element => element.rate === 2 && element.has_address && element.has_number);
      const value2 = this.getValue(srfiCode, array2);
      if (value2) {
        value.total += value2.total;
      }
      const array3 = srfiBCode.filter(element => element.rate === 2 && element.has_common && element.has_number);
      const value3 = this.getValue(srfiCode, array3);
      if (value3) {
        value.total += value3.total;
      }
    }
    // rate lv3
    if (index === 12) {
      array = srfiBCode.filter(element => element.rate === 3);
    }

    // rate lv 4 5 6 7
    if (index === 13) {
      array = srfiBCode.filter(element => element.rate === 4);
    } else if (index === 14) {
      array = srfiBCode.filter(element => element.rate === 5);
    } else if (index === 15) {
      array = srfiBCode.filter(element => element.rate === 6);
    } else if (index === 16) {
      array = srfiBCode.filter(element => element.rate === 7);
    }

    if (index !== 7 && index !== 11) {
      value = this.getValue(srfiCode, array);
    }
    return value && value.total !== 0 ? value.total : '';
  }

  getValue(srfiCode: string, array: CustomerBoard[]): CustomerBoard {
    let temp;
    if (array) {
      if (srfiCode === 'S' || srfiCode === 'R') {
        temp = array.filter(element => element.is_active === 'INACTIVE');
      } else {
        temp = array.filter(element => element.is_active === 'ACTIVE');
      }

      if (srfiCode === 'S' || srfiCode === 'F') {
        temp = temp.filter(element => element.has_first_home);
      } else {
        temp = temp.filter(element => element.has_investor);
      }
    }
    const value = new CustomerBoard();
    value.total = 0;
    temp.forEach(sub => {
      value.total += sub.total ? sub.total : 0;
    });
    return value;
  }

  getValueHouseType(houseType: string, index: number): any {
    const houseTypeSCode = this.customerBoardResponse.houseTypeSCode;
    const houseTypeBCode = this.customerBoardResponse.houseTypeBCode;
    let value;
    // S and T
    if (index === 1) {
      value = houseTypeSCode.find(element => element.houseType === houseType &&
        element.has_district_id && element.has_street_id && element.has_number_id);
    } else if (index === 2) {
      value = houseTypeSCode.find(element => element.houseType === houseType &&
        element.has_district_id && element.has_street_id && !element.has_number_id);
    } else if (index === 3) {
      value = houseTypeSCode.find(element => element.houseType === houseType &&
        element.has_district_id && !element.has_street_id && !element.has_number_id);
    }

    // B and VB
    // rate lv 1
    if (index === 4) {
      value = houseTypeBCode.find(element =>
        element.houseType === houseType &&
        element.rate === 1 &&
        element.has_common &&
        !element.has_address &&
        !element.has_number);
    } else if (index === 5) {
      value = houseTypeBCode.find(element =>
        element.houseType === houseType &&
        element.rate === 1 &&
        element.has_address &&
        !element.has_common &&
        !element.has_number);
    } else if (index === 6) {
      value = houseTypeBCode.find(element =>
        element.houseType === houseType &&
        element.rate === 1 &&
        element.has_number &&
        !element.has_address &&
        !element.has_common);
    } else if (index === 7) {
      value = new CustomerBoard();
      value.total = 0;
      const value1 = houseTypeBCode.find(element => element.houseType === houseType &&
        element.rate === 1 &&
        element.has_common &&
        !element.has_address &&
        !element.has_number);
      if (value1) {
        value.total += value1.total;
      }
      const value2 = houseTypeBCode.find(element => element.houseType === houseType &&
        element.rate === 1 &&
        element.has_address &&
        !element.has_common &&
        !element.has_number);
      if (value2) {
        value.total += value2.total;
      }
      const value3 = houseTypeBCode.find(element =>
        element.houseType === houseType &&
        element.rate === 1 &&
        element.has_number &&
        !element.has_address &&
        !element.has_common);
      if (value3) {
        value.total += value3.total;
      }
    }
    // rate lv 2
    if (index === 8) {
      value = houseTypeBCode.find(element => element.houseType === houseType &&
        element.rate === 2 && element.has_common && element.has_address && !element.has_number);
    } else if (index === 9) {
      value = houseTypeBCode.find(element => element.houseType === houseType &&
        element.rate === 2 && element.has_address && element.has_number && !element.has_common);
    } else if (index === 10) {
      value = houseTypeBCode.find(element => element.houseType === houseType &&
        element.rate === 2 && element.has_common && element.has_number && !element.has_number);
    } else if (index === 11) {
      value = new CustomerBoard();
      value.total = 0;
      const value1 = houseTypeBCode.find(element => element.houseType === houseType &&
        element.rate === 2 && element.has_common && element.has_address && !element.has_number);
      if (value1) {
        value.total += value1.total;
      }
      const value2 = houseTypeBCode.find(element => element.houseType === houseType &&
        element.rate === 2 && element.has_address && element.has_number && !element.has_common);
      if (value2) {
        value.total += value2.total;
      }
      const value3 = houseTypeBCode.find(element => element.houseType === houseType &&
        element.rate === 2 && element.has_common && element.has_number && !element.has_address);
      if (value3) {
        value.total += value3.total;
      }
    }
    // rate lv3
    if (index === 12) {
      value = houseTypeBCode.find(element => element.houseType === houseType && element.rate === 3);
    }
    // rate lv 4 5 6 7
    if (index === 13) {
      value = houseTypeBCode.find(element => element.houseType === houseType &&
        element.rate === 4);
    } else if (index === 14) {
      value = houseTypeBCode.find(element => element.houseType === houseType &&
        element.rate === 5);
    } else if (index === 15) {
      value = houseTypeBCode.find(element => element.houseType === houseType &&
        element.rate === 6);
    } else if (index === 16) {
      value = houseTypeBCode.find(element => element.houseType === houseType &&
        element.rate === 7);
    }

    return value ? value.total : '';
  }

  getValuePriceType(priceType: number, index: number): any {
    const priceSCode = this.customerBoardResponse.priceTypeSCode;
    const priceBCode = this.customerBoardResponse.priceTypeBCode;
    let value;
    // S and T
    if (index === 1) {
      value = priceSCode.find(element => element.price === priceType &&
        element.has_district_id && element.has_street_id && element.has_number_id);
    } else if (index === 2) {
      value = priceSCode.find(element => element.price === priceType &&
        element.has_district_id && element.has_street_id && !element.has_number_id);
    } else if (index === 3) {
      value = priceSCode.find(element => element.price === priceType &&
        element.has_district_id && !element.has_street_id && !element.has_number_id);
    }

    // B and VB
    // rate lv 1
    if (index === 4) {
      value = priceBCode.find(element => element.price === priceType &&
        element.rate === 1 &&
        element.has_common &&
        !element.has_address &&
        !element.has_number);
    } else if (index === 5) {
      value = priceBCode.find(element => element.price === priceType &&
        element.rate === 1 &&
        element.has_address &&
        !element.has_common &&
        !element.has_number);
    } else if (index === 6) {
      value = priceBCode.find(element => element.price === priceType &&
        element.rate === 1 &&
        element.has_number &&
        !element.has_common &&
        !element.has_address);
    } else if (index === 7) {
      value = new CustomerBoard();
      value.total = 0;
      const value1 = priceBCode.find(element => element.price === priceType &&
        element.rate === 1 &&
        element.has_common &&
        !element.has_address &&
        !element.has_number);
      if (value1) {
        value.total += value1.total;
      }
      const value2 = priceBCode.find(element => element.price === priceType &&
        element.rate === 1 &&
        element.has_address &&
        !element.has_common &&
        !element.has_number);
      if (value2) {
        value.total += value2.total;
      }
      const value3 = priceBCode.find(element => element.price === priceType &&
        element.rate === 1 &&
        element.has_number &&
        !element.has_common &&
        !element.has_address);
      if (value3) {
        value.total += value3.total;
      }
    }
    // rate lv 2
    if (index === 8) {
      value = priceBCode.find(element => element.price === priceType &&
        element.rate === 2 && element.has_common && element.has_address && !element.has_number);
    } else if (index === 9) {
      value = priceBCode.find(element => element.price === priceType &&
        element.rate === 2 && element.has_address && element.has_number && !element.has_common);
    } else if (index === 10) {
      value = priceBCode.find(element => element.price === priceType &&
        element.rate === 2 && element.has_common && element.has_number && !element.has_address);
    } else if (index === 11) {
      value = new CustomerBoard();
      value.total = 0;
      const value1 = priceBCode.find(element => element.price === priceType &&
        element.rate === 2 && element.has_common && element.has_address && !element.has_number);
      if (value1) {
        value.total += value1.total;
      }
      const value2 = priceBCode.find(element => element.price === priceType &&
        element.rate === 2 && element.has_address && element.has_number && !element.has_common);
      if (value2) {
        value.total += value2.total;
      }
      const value3 = priceBCode.find(element => element.price === priceType &&
        element.rate === 2 && element.has_common && element.has_number && !element.has_address);
      if (value3) {
        value.total += value3.total;
      }
    }
    // rate lv3
    if (index === 12) {
      value = priceBCode.find(element => element.price === priceType && element.rate === 3);
    }
    // rate lv 4 5 6 7
    if (index === 13) {
      value = priceBCode.find(element => element.price === priceType &&
        element.rate === 4);
    } else if (index === 14) {
      value = priceBCode.find(element => element.price === priceType &&
        element.rate === 5);
    } else if (index === 15) {
      value = priceBCode.find(element => element.price === priceType &&
        element.rate === 6);
    } else if (index === 17) {
      value = priceBCode.find(element => element.price === priceType &&
        element.rate === 7);
    }

    return value && value.total !== 0 ? value.total : '';
  }

  loadBuyer(type: string, key: any, index: number) {
    if (!ComponentPermission.isAccess(ComponentPermission.CUSTOMER_BOARD_CLICK)) {
      this.commonService.warning('You do not have permission to perform this action');
      return;
    }
    this.appendSelectedCellToParameter(type, key, index);
    if (this.isDialog === false) {
      this.navigateWithParam();
    }
    this.isDisplayed = true;
    const searchRequest = new BuyerSearchRequest();
    searchRequest.categoryIds = [];
    if (this.categoryIds) {
      searchRequest.categoryIds = this.categoryIds;
    }

    if (this.searchRequest.userId) {
      searchRequest.userId = this.searchRequest.userId;
    }

    if (this.searchRequest.start) {
      searchRequest.fromEnqDate = this.commonService.parseDate(this.searchRequest.start, 'DD/MM/YYYY');
    }
    if (this.searchRequest.end) {
      searchRequest.toEnqDate = this.commonService.parseDate(this.searchRequest.end, 'DD/MM/YYYY');
    }
    searchRequest.cityId = this.searchRequest.cityId;
    searchRequest.districtId = this.searchRequest.districtId;
    searchRequest.zoneId = this.searchRequest.zoneId;


    searchRequest.limit = 20;
    searchRequest.offset = 0;
    // if (index <= 3) {
    //   searchRequest.isSMode = true;
    // } else {
    searchRequest.isBMode = true;
    // }

    if (index === 1) {
      searchRequest.hasDistrictId = true;
      searchRequest.hasStreetId = true;
      searchRequest.hasNumberId = true;
    } else if (index === 2) {
      searchRequest.hasDistrictId = true;
      searchRequest.hasStreetId = true;
      searchRequest.hasNumberId = false;
    } else if (index === 3) {
      searchRequest.hasDistrictId = true;
      searchRequest.hasStreetId = false;
      searchRequest.hasNumberId = false;
    }

    // rate lv 1
    if (index === 4) {
      searchRequest.isCommon = true;
      searchRequest.isAddress = false;
      searchRequest.isNumber = false;
      searchRequest.rate = 1;
    } else if (index === 5) {
      searchRequest.isCommon = false;
      searchRequest.isAddress = true;
      searchRequest.isNumber = false;
      searchRequest.rate = 1;
    } else if (index === 6) {
      searchRequest.isCommon = false;
      searchRequest.isAddress = false;
      searchRequest.isNumber = true;
      searchRequest.rate = 1;
    } else if (index === 7) {
      // isNumber or isCommon or isAddress true
      searchRequest.isCAN1 = true;
      searchRequest.rate = 1;
    }
    if (index === 8) {
      // rate lv 2
      searchRequest.rate = 2;
      searchRequest.isCommon = true;
      searchRequest.isAddress = true;
      searchRequest.isNumber = false;
    } else if (index === 9) {
      // rate lv 2
      searchRequest.rate = 2;
      searchRequest.isCommon = false;
      searchRequest.isAddress = true;
      searchRequest.isNumber = true;
    } else if (index === 10) {
      // rate lv 2
      searchRequest.rate = 2;
      searchRequest.isCommon = true;
      searchRequest.isAddress = false;
      searchRequest.isNumber = true;
    } else if (index === 11) {
      // (isCommon and isNumber) or (isCommon and isAddress) or (isNumber and isAddress)
      searchRequest.isCAN2 = true;
      searchRequest.isCAN2 = true;
      searchRequest.rate = 2;
    }
    // rate lv3
    if (index === 12) {
      searchRequest.rate = 3;
    }
    // rate lv 4 5 6 7
    if (index === 13) {
      searchRequest.rate = 4;
    } else if (index === 14) {
      searchRequest.rate = 5;
    } else if (index === 15) {
      searchRequest.rate = 6;
    } else if (index === 16) {
      searchRequest.rate = 7;
    }

    if (type === 'SRFI') {
      if (key === 'S' || key === 'R') {
        searchRequest.isActive = 'INACTIVE';
      } else {
        searchRequest.isActive = 'ACTIVE';
      }
      if (key === 'S' || key === 'F') {
        searchRequest.isFirstHome = true;
      } else {
        searchRequest.isInvestor = true;
      }
    } else if (type === 'HOUSE') {
      searchRequest.propertyType = key;
    } else if (type === 'PRICE') {
      const noPrice = this.priceTypeLabels.findIndex(price => price.key === key);
      if (noPrice === (this.priceTypeLabels.length - 1)) {
        searchRequest.fromMinBuyPrice = 0;
        searchRequest.toMinBuyPrice = null;
      } else {
        searchRequest.fromMinBuyPrice = key;
        if (noPrice !== (this.priceTypeLabels.length - 2)) {
          searchRequest.toMinBuyPrice = this.priceTypeLabels[noPrice + 1].key;
        }
      }
    }
    if (this.isDialog === true) {
      this.ref.close(JSON.stringify(searchRequest));
      return;
    }
    this.buyerListComponent.filterCondition(searchRequest);

  }

  onRemoveCategory(categoryId: number) {
    if (!this.categoryIds) {
      this.categoryIds = [];
    }
    const index = this.categoryIds.indexOf(categoryId);
    if (index !== -1) {
      this.categoryIds.splice(index, 1);
    }
  }

  onSelectCategory(categoryId: number) {
    if (!this.categoryIds) {
      this.categoryIds = [];
    }
    const index = this.categoryIds.indexOf(categoryId);
    if (index === -1) {
      this.categoryIds.push(categoryId);
    }
  }

  onRemoveUser(userId: number) {
    this.searchRequest.userId = null;
  }

  onSelectUser(userId: number) {
    this.searchRequest.userId = userId;
  }

  preLoadAddressForFilter() {
    if (this.route.snapshot.queryParamMap.keys.length === 0) {
      return;
    }
    const postcode = Number(this.route.snapshot.queryParamMap.get('postcode'));
    if (postcode) {
      this.localService.districtByPostcode(postcode).subscribe(r => {
        this.filteredDistricts = r;
      });
    }
  }

  appendFilterToParameter() {
    // if (this.searchRequest.cityId) {
    //   this.param = { ...this.param, cityId: this.searchRequest.cityId };
    // } else {
    //   delete this.param['cityId'];
    // delete this.param['zoneId'];
    // delete this.param['postcode'];
    //   delete this.param['districtId'];
    // }
    // if (this.searchRequest.zoneId) {
    //   this.param = { ...this.param, zoneId: this.searchRequest.zoneId };
    // } else {
    //   delete this.param['zoneId'];
    //   delete this.param['districtId'];
    // }
    if (this.searchRequest.postcode) {
      this.param = {...this.param, postcode: this.searchRequest.postcode};
    } else {
      delete this.param['postcode'];
      delete this.param['districtId'];
    }
    if (this.searchRequest.districtId) {
      this.param = {...this.param, districtId: this.searchRequest.districtId};
    } else {
      delete this.param['districtId'];
    }
    if (this.searchRequest.categoryIds && this.searchRequest.categoryIds.length > 0) {
      this.param = {...this.param, categoryIds: this.searchRequest.categoryIds};
    } else {
      delete this.param['categoryIds'];
    }
    if (this.searchRequest.userId) {
      this.param = {...this.param, userId: this.searchRequest.userId};
    } else {
      delete this.param['userId'];
    }
    if (this.searchRequest.rate) {
      this.param = {...this.param, rate: this.searchRequest.rate};
    } else {
      delete this.param['rate'];
    }
    if (this.searchRequest.startDate) {
      this.param = {...this.param, startDate: this.searchRequest.startDate};
    } else {
      delete this.param['startDate'];
    }
    if (this.searchRequest.endDate) {
      this.param = {...this.param, endDate: this.searchRequest.endDate};
    } else {
      delete this.param['endDate'];
    }
  }

  //  push param to current url
  navigateWithParam() {
    this.router.navigate([], {
      queryParams: this.param,
    });
  }

  appendParameterToSearchRequest() {
    const params = this.route.snapshot.queryParams;
    if (params['cityId']) {
      this.searchRequest.cityId = Number(params['cityId']);
    }
    if (params['zoneId']) {
      this.searchRequest.zoneId = Number(params['zoneId']);
    }
    if (params['postcode']) {
      this.searchRequest.postcode = params['postcode'];
    }
    if (params['districtId']) {
      this.searchRequest.districtId = Number(params['districtId']);
    }
    if (params['categoryIds']) {
      const categoryIds = params['categoryIds'];
      if (typeof categoryIds === 'string') {
        this.searchRequest.categoryIds = [];
        this.searchRequest.categoryIds.push(Number(categoryIds));
      } else {
        this.searchRequest.categoryIds = categoryIds.map(id => {
          return Number(id);
        });
      }
    }
    if (params['userId']) {
      this.searchRequest.userId = Number(params['userId']);
    }
    if (params['fromPurchaseDate']) {
      this.searchRequest.startDate = Number(params['startDate']);
    }
    if (params['toPurchaseDate']) {
      this.searchRequest.endDate = Number(params['endDate']);
    }
    if (params['rate']) {
      this.searchRequest.rate = Number(params['rate']);
    }
  }

  appendSelectedCellToParameter(type: string, key: any, index: number) {
    this.param = {...this.param, type: type};
    this.param = {...this.param, key: key};
    this.param = {...this.param, index: index};
  }

  restoreSelectedState() {
    const param = this.route.snapshot.queryParams;
    if (param['type'] && param['key'] && param['index']) {
      if (param['type'] !== 'PRICE') {
        this.loadBuyer(param['type'], param['key'], Number(param['index']));
      } else {
        this.loadBuyer(param['type'], Number(param['key']), Number(param['index']));
      }
    }
  }

  onSearchByPostcode(value: number) {
    this.searchRequest.postcode = Number(value);
    this.searchRequest.districtId = undefined;
    this.searchRequest.streetId = undefined;
    if (value && value !== 0) {
      this.localService.districtByPostcode(value).subscribe(result => {
        this.filteredDistricts = result;
      });
    }
  }
}
