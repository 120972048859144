import {Component, Input, OnInit} from '@angular/core';
import {ViewCell} from 'ng2-smart-table';
import {ProspectListCustomCellComponent} from '../../prospect/common/prospect-list-custom-cell-component';
import {StringUtils} from '../../../../shared/common/string-utils';

@Component({
  template: `
    {{renderValue}}
  `,
})
export class NestPropertyCustomCellComponent implements ViewCell, OnInit {

  renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;
  _propertyPaths: string[];
  _valueType: string;
  _separator: string;
  public static VALUE_TYPES = {
    DEFAULT: 'DEFAULT',
    DATE: 'DATE',
    CURRENCY: 'CURRENCY',
  };

  set config(value: {
    propertyPaths: string[],
    valueType: string,
    separator: string,
  }) {
    if (!value.propertyPaths) {
      return;
    }
    if (!value.valueType) {
      this._valueType = ProspectListCustomCellComponent.VALUE_TYPES.DEFAULT;
    }
    this._propertyPaths = value.propertyPaths;
    this._valueType = value.valueType;
    this._separator = value.separator ? value.separator : ' ';
    this.renderValue = this.getValue();
  }

  ngOnInit() {
    if (this._propertyPaths) {
      this.renderValue = this.getValue();
    } else {
      this.renderValue = this.value ? this.value.toString() : '';
    }
  }

  getValue(): any {
    let result = '';
    this._propertyPaths.forEach(propertyPath => {
      const value = this.extractValueFromData(propertyPath);
      if (value && value !== '') {
        result = `${result}${this._separator}${value}`;
      }
    });
    return result.trim().replace(this._separator, '');
  }

  extractValueFromData(propertyPath: string): any {
    let value = '';
    value = StringUtils.getNestedByPath(this.rowData, propertyPath);
    value = StringUtils.getFormattedValue(this._valueType, value);
    return value;
  }

}
