import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-prospect',
  template: `<router-outlet></router-outlet>`,
  styles: [':host {height: 100%;}'],
})
export class ProspectComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
