import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Buyer } from '../../../../shared/models/response/buyer/buyer';
import { NbDialogService } from '@nebular/theme';
import { BuyerService } from '../../../../shared/services/buyer.service';
import { BuyerSearchRequest } from '../../../../shared/models/request/buyer/buyer-search-request';
import { BuyerCreateComponent } from '../../../../shared/module/buyer-create/buyer-create.component';
import { CommonService } from '../../../../shared/services/common.service';
import { Customer } from '../../../../shared/models/response/prospect/customer';
import { BuyerRequest } from '../../../../shared/models/request/buyer/buyer-request';
import { Prospect } from '../../../../shared/models/response/prospect/prospect';
import { ProspectPickComponent } from '../../../../shared/module/buyer-create/prospect-pick/prospect-pick.component';
import { City } from '../../../../shared/models/response/address/city';
import { SearchRequest } from '../../../../shared/models/request/search-request';
import { LocalService } from '../../../../shared/services/local.service';
import { StringUtils } from '../../../../shared/common/string-utils';
import { ActivatedRoute, Router } from '@angular/router';
import { ProspectService } from '../../../../shared/services/prospect.service';
import { CustomerPickComponent } from '../../../../shared/module/buyer-create/customer-pick/customer-pick.component';
import { Property } from '../../../../shared/models/response/prospect/property';
import { AuthService } from '../../../../shared/services/auth.service';
import { User } from '../../../../shared/models/response/user';

@Component({
  selector: 'ngx-open-home',
  templateUrl: './open-home.component.html',
  styleUrls: ['./open-home.component.scss'],
})
export class OpenHomeComponent implements OnInit {
  forms: FormGroup[] = [];
  time: number = Date.now();
  selectedProspect: Prospect;
  address: string;
  cities: City[];
  toBeSavedBuyers: BuyerRequest[];
  currentUser: User;

  constructor(private buyerService: BuyerService,
              private commonService: CommonService,
              private dialogService: NbDialogService,
              private localService: LocalService,
              private route: ActivatedRoute,
              private router: Router,
              private prospectService: ProspectService,
              private authService: AuthService) {
  }

  ngOnInit(): void {
    this.currentUser = this.authService.currentUser;
    if (this.route.snapshot.queryParams['prospectId']) {
      const id = Number(this.route.snapshot.queryParams['prospectId']);
      this.prospectService.getOne(id).subscribe(result => {
        this.selectedProspect = result.data;
        this.address = StringUtils.getFullAddress(this.selectedProspect.address);
      });
    }
    //  pre add 5 customers
    for (let i = 1; i <= 5; i++) {
      this.addCustomer();
    }
    this.loadForFilter();
  }

  loadForFilter() {
    const searchRequest = new SearchRequest();
    searchRequest.offset = 0;
    searchRequest.limit = 10000;
    searchRequest.orders = [];
    searchRequest.orders.push(
      {
        left: 'name',
        right: 'asc',

      },
    );
    this.localService.cities().subscribe((result: City[]) => {
      this.cities = result;
    });
  }

  searchBuyer(form: FormGroup, index: number) {
    if (!form.value.phone || form.value.phone === '') {
      this.commonService.warning('Please enter phone number');
      return;
    }
    const value = form.value;
    const searchRequest = new BuyerSearchRequest();
    searchRequest.phone = value.phone;
    searchRequest.limit = 1000;
    searchRequest.offset = 0;
    this.buyerService.searchItems(searchRequest).subscribe(result => {
      if (result.data && result.data.length > 0) {
        this.dialogService.open(CustomerPickComponent, {
          context: {
            data: result.data,
          },
        }).onClose.subscribe(r => {
          if (r) {
            r.prospect = this.selectedProspect;
            r.prospectId = this.selectedProspect.prospectId;
            form.controls.name.setValue(r.customer.name);
            form.controls.surname.setValue(r.customer.surname);
            form.controls.phone.setValue(r.customer.mobilePhone);
            form.controls.showDetails.setValue(true);
            form.controls.selectedBuyer.setValue(r);
          }
        });
      } else {
        form.controls.showDetails.setValue(true);
        form.controls.selectedBuyer.setValue(new Buyer());
        this.commonService.info('Customer does not exist');
      }
    });
  }

  setData(form: FormGroup, buyer: Buyer) {
    buyer.buyerCode = 'B';
    buyer.prospect = this.selectedProspect;
    buyer.prospectId = this.selectedProspect.prospectId;
    if (!buyer.property) {
      buyer.property = new Property();
    }
    buyer.customer = new Customer();
    buyer.customer.name = form.value.name;
    buyer.customer.surname = form.value.surname;
    buyer.customer.mobilePhone = form.value.phone;
    buyer.property.buyer = true;
    buyer.property.enqDate = this.time;
    buyer.addressId = this.selectedProspect.addressId;
    buyer.property.type = this.selectedProspect.property.type;
    buyer.property.bed = this.selectedProspect.property.bed;
    buyer.property.bath = this.selectedProspect.property.bath;
    buyer.property.car = this.selectedProspect.property.car;
    buyer.property.land = this.selectedProspect.property.land;
    buyer.property.minBuyPrice = this.currentUser.office.officeProperty.minBuyPrice;
    buyer.property.maxBuyPrice = this.currentUser.office.officeProperty.maxBuyPrice;
  }

  showBuyerDetails(buyer: Buyer, form: FormGroup) {
    this.setData(form, buyer);
    this.dialogService.open(BuyerCreateComponent, {
      context: {
        isDialog: true,
        data: buyer,
      },
    }).onClose.subscribe((result: Buyer) => {
      if (result) {
        form.controls.name.setValue(result.customer.name);
        form.controls.surname.setValue(result.customer.surname);
        form.controls.phone.setValue(result.customer.mobilePhone);
        form.controls.selectedBuyer.setValue(result);
      }
    });
  }


  addCustomer() {
    const form = new FormGroup({
      name: new FormControl(''),
      surname: new FormControl(''),
      phone: new FormControl('', [Validators.required]),
      showDetails: new FormControl(false),
      selectedBuyer: new FormControl(null),
    });
    this.forms.push(form);
  }

  removeCustomer(index: number) {
    this.forms.splice(index, 1);
  }

  saveAll() {
    this.toBeSavedBuyers = [];
    //  TODO: Save all cause detached entity exception
    this.forms.forEach(f => {
      if (f.invalid && f.dirty && !f.untouched) {
        this.commonService.warning('Please input the required fields');
        return;
      }
      const value = f.value;
      if (value.selectedBuyer !== null) {
        this.checkChangeFormValue(f);
        this.toBeSavedBuyers.push(value.selectedBuyer);
      } else {
        if (value.name !== '' && value.phone !== '') {
          const buyer = new Buyer();
          this.setData(f, buyer);
          f.value.selectedBuyer = buyer;
          this.toBeSavedBuyers.push(f.value.selectedBuyer);
        }
      }
    });
    this.buyerService.saveOpenHome(this.toBeSavedBuyers).subscribe(result => {
      if (result) {
        for (let i = 0; i < result.data.length; i++) {
          this.forms[i].controls.selectedBuyer.setValue(result.data[i]);
          this.forms[i].value.showDetails = true;
        }
        this.commonService.info('Saved all customer');
      }
    });
  }

  selectAddress() {
    this.dialogService.open(ProspectPickComponent, {
      context: {
        cities: this.cities,
        isOpenHome: true,
      },
    }).onClose.subscribe(result => {
      if (result) {
        this.selectedProspect = result;
        this.address = StringUtils.getFullAddress(this.selectedProspect.address);
        this.router.navigate([], { queryParams: { prospectId: this.selectedProspect.prospectId } });
      }
    });
  }

  checkChangeFormValue(form: FormGroup) {
    const value = form.value;
    if (value.name !== value.selectedBuyer.customer.name) {
      value.selectedBuyer.customer.name = value.name;
    }
    if (value.surname !== value.selectedBuyer.customer.surname) {
      value.selectedBuyer.customer.surname = value.surname;
    }
    if (value.phone !== value.selectedBuyer.customer.mobilePhone) {
      value.selectedBuyer.customer.mobilePhone = value.phone;
    }
  }
}
