import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {ViewCell} from 'ng2-smart-table';
import {WrapProspect} from './prospect-utils';

@Component({
  template: `
    <nb-checkbox [(ngModel)]="rowData.selected" (click)="handleClick($event)"
                 (ngModelChange)="handleSelectChange()"></nb-checkbox>
  `,
  styles: [
  ],
})
export class ProspectSelectComponent implements ViewCell, OnInit {

  @Input() value: string | number;
  @Input() rowData: WrapProspect;
  onSelectProspect = new EventEmitter<any>();

  ngOnInit() {
  }

  handleSelectChange() {
    if (this.onSelectProspect) {
      this.onSelectProspect.emit(this.rowData);
    }
  }

  handleClick($event) {
    $event.stopPropagation();
  }
}
