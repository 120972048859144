<nb-card class="dialog-container">
  <nb-card-header>
    <span *ngIf="!isUpdate">Add new user</span>
    <span *ngIf="isUpdate">Update user</span>
  </nb-card-header>
  <nb-card-body>
    <form>
      <div class="form-group">
        <label class="label">User name</label>
        <input type="text" nbInput fullWidth [(ngModel)]="username" name="username" [disabled]="isUpdate">
      </div>
      <div class="form-group">
        <label class="label">Name</label>
        <input type="text" nbInput fullWidth [(ngModel)]="name" name="name">
      </div>
      <div class="form-group">
        <label class="label">Phone</label>
        <input type="number" nbInput fullWidth [(ngModel)]="phone" name="phone">
      </div>
      <div class="form-group">
        <label class="label">Email address</label>
        <input type="email" nbInput fullWidth [(ngModel)]="email" name="email">
      </div>
    </form>
  </nb-card-body>
  <nb-card-footer class="dialog-footer">
    <button class="cancel" nbButton status="danger" (click)="cancel()">Cancel</button>
    <button nbButton status="success" (click)="submit()">Save</button>
  </nb-card-footer>
</nb-card>
