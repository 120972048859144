import {Component, Input, OnInit} from '@angular/core';
import {ViewCell} from 'ng2-smart-table';
import {WrapProspect} from './prospect-utils';
import {StringUtils} from '../../../../shared/common/string-utils';

@Component({
  template: `
    {{renderValue}}
  `,
  styles: [],
})
export class VisualBoardClosingGapComponent implements ViewCell, OnInit {

  renderValue: string;

  @Input() value: any;
  @Input() rowData: WrapProspect;
  StringUtils = StringUtils;

  ngOnInit() {
    if (this.rowData?.data.property?.askingPrice || this.rowData?.data?.property?.agentEstimatePriceFrom
      && this.rowData?.data?.property?.agentEstimatePriceTo) {
      this.setValue();
    }
  }

  setValue(): any {
    if (!this.rowData) {
      this.renderValue = this.value ? this.value.toString() : '';
      return;
    }
    const property = this.rowData?.data?.property;
    const askPrice = property.askingPrice;
    if (!property.agentEstimatePriceFrom || !property.agentEstimatePriceTo || !askPrice) {
      this.renderValue = '';
      // return askPrice;
    }
    const avePrice = (property.agentEstimatePriceFrom + property.agentEstimatePriceTo) / 2;
    const closingGap = askPrice - avePrice;
    this.renderValue = closingGap.toString();
  }
}
