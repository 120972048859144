import {Component, OnInit} from '@angular/core';
import {CommonService} from '../../../services/common.service';
import {NbDialogRef, NbDialogService} from '@nebular/theme';
import {RestResult} from '../../../models/response/rest-result';
import {SearchRequest} from '../../../models/request/search-request';
import {LocalDataSource} from 'ng2-smart-table';
import {BuyerService} from '../../../services/buyer.service';
import {BuyerUtils, WrapBuyer} from '../../../../modules/pages/buyer/common/buyer-utils';
import {ConfirmDialogComponent} from '../../../../modules/pages/confirm-dialog/confirm-dialog.component';
import {MultipleSelectedRequest} from '../../../models/common/multiple-selected-request';
import {Buyer} from '../../../models/response/buyer/buyer';


@Component({
  selector: 'ngx-merge-customers',
  templateUrl: './merge-customers.component.html',
  styleUrls: ['./merge-customers.component.scss'],
})

export class MergeCustomersComponent implements OnInit {
  multipleSelectedRequest: MultipleSelectedRequest;

  searchRequest = new SearchRequest();
  change: any;
  source: LocalDataSource = new LocalDataSource();
  pageSize = 10;
  currentPage = 1;
  buyers: Buyer[];

  columnsDefinition = {
    buyerName: {
      enable: true,
      value: BuyerUtils.createPickColumn('Name', ['customer.name']),
      name: 'Name',
    },
    buyerSurname: {
      enable: true,
      value: BuyerUtils.createPickColumn('SName', ['customer.surname']),
      name: 'SName',
    },
    buyerPhone: {
      enable: true,
      value: BuyerUtils.createPickColumn('Phone', ['customer.mobilePhone']),
      name: 'Phone',
    },
    classification: {
      enable: true,
      value: BuyerUtils.createPickColumn('Class', ['cellClass']),
      name: 'Class',
    },
    buyerStatus: {
      enable: true,
      value: BuyerUtils.createPickColumn('Qualify', ['cellStatus']),
      name: 'Qualification',
    },
    buyerAddress: {
      enable: true,
      value: BuyerUtils.createPickColumn('Address', ['cellAddress']),
      name: 'Address',
    },
    buyerMinPrice: {
      enable: true,
      value: BuyerUtils.createPickColumn('Min price', ['property.minBuyPrice']),
      name: 'Min price',
    },
    buyerMaxPrice: {
      enable: true,
      value: BuyerUtils.createPickColumn('Max price', ['property.maxBuyPrice']),
      name: 'Max price',
    },
  };

  settings = {
    mode: 'external',
    hideSubHeader: true,
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    pager: {
      display: true,
      perPage: this.pageSize,
    },
    columns: BuyerUtils.createPickColumns(this.columnsDefinition),
  };

  constructor(private dialogService: NbDialogService,
              protected ref: NbDialogRef<MergeCustomersComponent>,
              private commonService: CommonService,
              private buyerService: BuyerService) {

  }

  ngOnInit() {
    this.source.onChanged().subscribe((change) => {
      this.change = change;
      if (change.action === 'refresh') {
        this.loadRequest(1);
      }
      if (change.action === 'page') {
        this.loadRequest(change.paging.page);
      }
      if (change.action === 'sort') {
        this.source.load(new Array());
        this.loadRequest(1);
      }
      if (change.action === 'filter') {
        this.source.load(new Array());
        this.loadRequest(1);
      }
    });
  }

  loadRequest(pageIndex) {
    if (!this.multipleSelectedRequest || !this.multipleSelectedRequest.selectedIds) {
      return;
    }
    this.currentPage = pageIndex;
    this.searchRequest.offset = (pageIndex - 1) * this.pageSize;
    if (this.searchRequest.offset < 0) {
      this.searchRequest.offset = 0;
    }
    this.searchRequest.limit = this.pageSize;
    this.prepareRequest();
    this.buyerService.search(this.searchRequest).subscribe((result: RestResult) => {
      this.buyers = result.data;
      this.source.load(result.data);
    });
  }

  prepareRequest() {
    // init conditions
    if (!this.searchRequest.conditions) {
      this.searchRequest.conditions = new Array();
    }
    this.searchRequest.conditions = new Array();
    this.searchRequest.conditions.push(
      {
        left: 'customerId',
        middle: 'in',
        right: this.multipleSelectedRequest.selectedIds,
        dataType: 'LONG',
      }
    );
    // init orders
    this.searchRequest.orders = new Array();
    this.searchRequest.orders.push(
      {
        left: 'buyerId',
        right: 'desc'
      }
    );
  }

  selectRecord(event: any, openEditDialog: boolean, addToSelected: boolean) {
    const customer: WrapBuyer = event.data;
    this.select(customer);
  }

  select(event: any) {
    this.dialogService.open(ConfirmDialogComponent, {
      context: {
        content: 'Do you really want to merge?',
      },
    })
    .onClose.subscribe(res => {
      if (res === ConfirmDialogComponent.confirmOk) {
        const request = new MultipleSelectedRequest();
        request.chooseId = event.buyerId;
        request.selectedIds = this.buyers.map(buyer => buyer.buyerId);
        this.buyerService.mergeCustomers(request).subscribe(
          () => {
            this.commonService.info('Merge successfully');
            this.multipleSelectedRequest = new MultipleSelectedRequest();
            this.ref.close(event);
          }, error => {
            this.commonService.warning(error.message);
          });
      }
    });
  }

  cancel() {
    this.ref.close();
  }

}
