import { Component, OnInit, ViewChild } from '@angular/core';
import { District } from '../../../../shared/models/response/address/district';
import { Street } from '../../../../shared/models/response/address/street';
import { City } from '../../../../shared/models/response/address/city';
import { CityService } from '../../../../shared/services/city.service';
import { SuburbService } from '../../../../shared/services/suburb.service';
import { StreetService } from '../../../../shared/services/street.service';
import { SearchRequest } from '../../../../shared/models/request/search-request';
import { RestResult } from '../../../../shared/models/response/rest-result';
import { Prospect } from '../../../../shared/models/response/prospect/prospect';
import { Company } from '../../../../shared/models/response/prospect/company';
import { CompanyService } from '../../../../shared/services/company.service';
import { TaskType } from '../../../../shared/models/response/task-type';
import { TaskTypeService } from '../../../../shared/services/task-type.service';
import { TaskStatus } from '../../../../shared/models/response/task-status';
import { TaskStatusService } from '../../../../shared/services/task-status.service';
import { LocalService } from '../../../../shared/services/local.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ComponentPermission } from '../../../../component.permission';
import * as moment from 'moment';
import { AuthService } from '../../../../shared/services/auth.service';
import { User } from '../../../../shared/models/response/user';
import { ProspectService } from '../../../../shared/services/prospect.service';
import { NbPopoverDirective, NbTrigger } from '@nebular/theme';
import { ProspectListAdvanceComponent } from '../prospect-list-advance/prospect-list-advance.component';
import { ProspectListBasicComponent } from '../prospect-list-basic/prospect-list-basic.component';
import { ProspectListMarketComponent } from '../prospect-list-market/prospect-list-market.component';

@Component({
  selector: 'ngx-prospect-list',
  templateUrl: './prospect-list.component.html',
  styleUrls: ['./prospect-list.component.scss'],
})
export class ProspectListComponent implements OnInit {
  ComponentPermission = ComponentPermission;

  districts: District[];
  streets: Street[];
  cities: City[];
  prospects: Prospect[];
  companies: Company[];
  taskTypes: TaskType[];
  taskStatuses: TaskStatus[];

  tabs = {
    ALL: 'ALL',
    INDOOR: 'INDOOR',
    OUTDOOR: 'OUTDOOR',
    MARKET: 'MARKET',
  };

  currentTab = '';
  showFloat = false;
  dailyStatistic: any;
  currentUser: User;
  dailyMenuIsOpen = false;
  noop = NbTrigger.NOOP;
  @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;
  @ViewChild(ProspectListAdvanceComponent) prospectListAdvance: ProspectListAdvanceComponent;
  @ViewChild(ProspectListBasicComponent) prospectListBasic: ProspectListBasicComponent;
  @ViewChild(ProspectListMarketComponent) prospectListMarket: ProspectListMarketComponent;

  constructor(private cityService: CityService,
              private suburbService: SuburbService,
              private streetService: StreetService,
              private companyService: CompanyService,
              private taskTypeService: TaskTypeService,
              private taskStatusService: TaskStatusService,
              private localService: LocalService,
              private route: ActivatedRoute,
              private router: Router,
              private authService: AuthService,
              private prospectService: ProspectService) {
  }

  ngOnInit(): void {
    this.currentUser = this.authService.currentUser;
    const selectedTab = this.route.snapshot?.queryParamMap?.get('tab');
    if (selectedTab && selectedTab !== '') {
      this.currentTab = selectedTab;
    } else {
      if (ComponentPermission.isAccess(ComponentPermission.CLIENT_OUTDOOR)) {
        this.currentTab = this.tabs.OUTDOOR;
      } else if (ComponentPermission.isAccess(ComponentPermission.CLIENT_MARKET)) {
        this.currentTab = this.tabs.MARKET;
      } else if (ComponentPermission.isAccess(ComponentPermission.CLIENT_ALL)) {
        this.currentTab = this.tabs.ALL;
      }
    }
    this.loadForFilter();
    this.getTaskTypes();
    this.getTaskStatus();
  }

  loadForFilter() {
    const searchRequest = new SearchRequest();
    searchRequest.offset = 0;
    searchRequest.limit = 10000;
    searchRequest.orders = new Array();
    searchRequest.orders.push(
      {
        left: 'name',
        right: 'asc',

      },
    );
    this.localService.cities().subscribe((result: City[]) => {
      this.cities = result;
    });
    this.companyService.search(searchRequest).subscribe((result: RestResult) => {
      this.companies = result?.data;
    });
  }

  loadStreets() {
  }

  getTaskTypes() {
    const taskTypeSearchRequest: SearchRequest = new SearchRequest();
    taskTypeSearchRequest.orders = [];
    taskTypeSearchRequest.orders.push(
      {
        left: 'name',
        right: 'asc',
      },
    );
    this.taskTypeService.search(taskTypeSearchRequest).subscribe((result: RestResult) => {
      this.taskTypes = result.data;
    });
  }

  getTaskStatus() {
    const taskStatusSearchRequest: SearchRequest = new SearchRequest();
    taskStatusSearchRequest.orders = [];
    taskStatusSearchRequest.orders.push(
      {
        left: 'name',
        right: 'asc',

      },
    );
    this.taskStatusService.search(taskStatusSearchRequest).subscribe((result: RestResult) => {
      this.taskStatuses = result.data;
    });
  }

  onChangeTab(event) {
    this.currentTab = event.tabTitle;
    this.router.navigate(['/pages/prospect/list'], { queryParams: { tab: this.currentTab } });
  }

  getDailyStatistic() {
    if (this.dailyMenuIsOpen === false) {
      const from = moment().startOf('day').valueOf();
      const to = moment().endOf('day').valueOf();
      const multipleUser = [];
      multipleUser.push(this.currentUser.userId);
      this.prospectService.getDailyStatistic(from,
        to,
        multipleUser).subscribe(result => {
        this.dailyStatistic = result.data;
      });
      this.dailyMenuIsOpen = true;
      this.popover.show();
    } else {
      this.popover.hide();
      this.dailyMenuIsOpen = false;
    }
  }
}
