import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'ngx-company-board-filter-advance',
  templateUrl: './company-board-filter-advance.component.html',
  styleUrls: ['./company-board-filter-advance.component.scss'],
})
export class CompanyBoardFilterAdvanceComponent implements OnInit {
  // @Input() districts: District[];
  // @Input() streets: Street[];
  // @Input() cities: City[];
  // @Input() farms: Farm[];
  // @Input() zones: Zone[];
  @Input() rentTab: boolean;
  @Input() otmTab: boolean;
  // @Output() onSuburbChange = new EventEmitter();
  // @Output() onCityChange = new EventEmitter();
  // @Output() onZoneChange = new EventEmitter();
  // @Output() onFarmChange = new EventEmitter();
  // @Output() onStreetChange = new EventEmitter();
  // @Output() onSearch = new EventEmitter();
  @Output() onRent = new EventEmitter();
  @Output() onOTM = new EventEmitter();

  // @Input() searchRequest: ClientBoardSearchRequest;

  constructor() {
  }

  ngOnInit(): void {
  }

  // onSuburbSelectChange() {
  //   this.onSuburbChange.emit(this.searchRequest.districtId);
  // }
  //
  // onCitySelectChange() {
  //   this.onCityChange.emit(this.searchRequest.cityId);
  // }
  //
  // onFarmSelectChange() {
  //   this.onFarmChange.emit(this.searchRequest.farmId);
  // }
  //
  // onZoneSelectChange() {
  //   this.onZoneChange.emit(this.searchRequest.zoneId);
  // }
  //
  // onStreetSelectChange() {
  //   this.onStreetChange.emit(this.searchRequest.streetId);
  // }
  //
  rent() {
    this.onRent.emit();
  }

  otm() {
    this.onOTM.emit();
  }
}
