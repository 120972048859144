export class VendorRequest {
  vendorId: number;
  name: string;
  surname: string;
  signature: boolean;
  type: string;
  idNumber: string;
  prospectId: number;
  createdDate: number;
  createdBy: string;
  isNew?: boolean;
}
