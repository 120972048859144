<h1 id="title" class="title">Login</h1>
<p class="sub-title">Hello! Log in with your email.</p>

<form (ngSubmit)="login()" aria-labelledby="title">

  <div class="form-control-group">
    <label class="label" for="input-email">Email address:</label>
    <input nbInput
           fullWidth
           [(ngModel)]="form.username"
           name="email"
           id="input-email"
           placeholder="Username"
           fieldSize="large"
           autofocus>
  </div>

  <div class="form-control-group">
    <span class="label-with-link">
      <label class="label" for="input-password">Password:</label>
      <a class="forgot-password caption-2" (click)="routeForgot()">Forgot Password?</a>
    </span>
    <input nbInput
           fullWidth
           [(ngModel)]="form.password"
           name="password"
           type="password"
           id="input-password"
           placeholder="Password"
           fieldSize="large">
  </div>

  <button nbButton
          fullWidth
          status="primary"
          size="large">
    Log In
  </button>
</form>
