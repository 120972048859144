export class CustomRow {
  isSelected = false;
  selected = false;
  data: any;

  constructor(data: any, isSelected?: boolean, selected?: boolean) {
    if (isSelected) {
      this.isSelected = isSelected;
    }
    if (selected) {
      this.selected = selected;
    }
    this.data = data;
  }
}
