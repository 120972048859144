<div class='row m-0'>
  <div class='col-md-6 col-sm-12 left-panel table-container'
       [ngClass]="{'popup-hide': isDisplay}">
    <nb-card>
      <nb-card-body>
        <ngx-buyer-list-filter-dob
          [selectedMonth]='selectedMonth'
          [selectedDay]='selectedDay'
          [districts]='filteredDistricts'
          [districtId]='searchRequest?.districtId'
          [streets]='filteredStreets'
          [streetId]='searchRequest?.streetId'
          [cities]='cities'
          [cityId]='searchRequest?.cityId'
          [total]='groupWrapper?.totalItems'
          [farm]='selectedBuyer?.address?.street?.farm?.name'
          [zone]='selectedBuyer?.address?.street?.district?.zone?.name'
          [postcode]='searchRequest.postcode'
          (onSuburbChange)='onSuburbChange($event)'
          (onStreetChange)='onStreetChange($event)'
          (onCityChange)='onCityChange($event)'
          (onMonthChange)='onMonthChange($event)'
          (onDayChange)='onDayChange($event)'
          (onSearchByPostcode)='onSearchByPostcode($event)'
        >
        </ngx-buyer-list-filter-dob>
        <div class='col-12 mt-2'>
<!--          <div class='col-8 ml-2'>-->
<!--            <nb-form-field>-->
<!--              <input type='text' nbInput fullWidth fieldSize='tiny' placeholder='Text' [(ngModel)]='log'>-->
<!--              <button nbButton nbSuffix size='tiny' hero status='primary' class='mr-4' (click)='saveBulk(log)'>Save-->
<!--              </button>-->
<!--            </nb-form-field>-->
<!--          </div>-->
          <div class='mt-1'>
            <button nbButton size='tiny' hero status='primary' class='fa-pull-right'
                    (click)='exportEmail()'>Export Email
            </button>
            <button nbButton size='tiny' hero status='primary' class='fa-pull-right mr-1' (click)='exportMobile()'>
              Export
              Mobile
            </button>
          </div>
        </div>
        <div class='mt-2'>
          <ng2-smart-table
            [settings]='settings'
            [source]='source'
            (userRowSelect)='selectRecord($event); selectRow($event, buyerDialog)'
          >
          </ng2-smart-table>
        </div>
      </nb-card-body>
    </nb-card>
  </div>
  <div class='col-md-6 col-sm-12 right-panel table-container'
       [ngClass]="{
  'popup-detail': isDisplay,
  'hide-detail': !isDisplay
  }">
    <ngx-buyer-create
    ></ngx-buyer-create>
  </div>
  <div class='d-flex col-12'>
    <button *ngIf='isDialog'
            nbButton size='tiny' hero status='primary' class='fa-pull-right ml-2'
            (click)='closeDialog()'>Cancel
    </button>
  </div>
  <div class='scroll-mobile left-54' *ngIf='selectedBuyer && isDisplay'>
    <button (click)='cancel()' size='tiny' nbButton status='warning'>Cancel</button>
  </div>
</div>
