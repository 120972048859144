<nb-card *appPermissionCheck='ComponentPermission.CLIENT_LIST'>
  <nb-card-body class='scroll-advance'>
    <div class='row'>
      <div class='col-12'>
        <ngx-prospect-list-filter-advance class='sticky-filter'
                                          [columnsDefinition]='columnsDefinition'
                                          [columnGroup]='columnGroup'
                                          [districts]='filteredDistricts'
                                          [streets]='filteredStreets'
                                          [cities]='cities'
                                          [sortTypesText]='sortTypesText'
                                          [searchRequest]='searchRequest'
                                          [farm]='selectedProspect?.address?.street?.farm?.name'
                                          [zone]='selectedProspect?.address?.street?.district?.zone?.name'
                                          [total]='groupWrapper?.totalItems'
                                          [totalOtm]='groupWrapper?.totalOtm'
                                          [totalRent]='groupWrapper?.totalRent'
                                          [postcode]='searchRequest.postcode'
                                          (onColumnDisplayOptionChange)='onColumnDisplayOptionCheckedChange($event)'
                                          (onSuburbChange)='onSuburbChange($event)'
                                          (onCityChange)='onCityChange($event)'
                                          (onAddNewProspect)='addRecord()'
                                          (onOpenShortcut)='openShortcut()'
                                          (onSearch)='search()'
                                          (onSearchByPostcode)='onSearchByPostcode($event)'
                                          *appPermissionCheck='ComponentPermission.CLIENT_SEARCH'
        >
        </ngx-prospect-list-filter-advance>
        <div class='freeze-body'>
          <div class='mt-2' *appPermissionCheck='ComponentPermission.CLIENT_DELETE'>
            <button nbButton size='tiny' hero status='danger'
                    [hidden]='willBeDeletedProspectIds.length <= 0 && willBeDeletedGroupIds.length <= 0'
                    (click)='removeSelected()'>Remove selected
            </button>
          </div>
          <div class='mt-2'>
            <ng2-smart-table [settings]='settings'
                             [source]='source'
                             (userRowSelect)='selectRecord($event, true, false)'
            >
            </ng2-smart-table>
          </div>
        </div>
      </div>
    </div>
  </nb-card-body>
</nb-card>

