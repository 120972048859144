import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {ViewCell} from 'ng2-smart-table';

@Component({
  template: `
      <button *ngIf="rowData.type === 'ITEM'"
              nbButton size="tiny" status="primary" (click)="handleAddButtonClick($event)">
        <nb-icon icon="plus-outline"></nb-icon>
      </button>
      <p>{{rowData?.data?.rate}}</p>
      <p *ngIf="displayOtm" style="margin-top: 5px;">
        <span class="{{rowData?.data?.otmDayDiff < 90 ? 'active' : 'expired'}}">
          {{rowData?.data?.otmDayDiff ? rowData?.data?.otmDayDiff + " d" : ""}}
        </span>
      </p>
  `,
  styles: ['.active {color: green} .expired {color: #7d4439}'],
})
export class ProspectActionCellComponent implements ViewCell, OnInit {

  @Input() value: string | number;
  @Input() rowData: any;

  onAddProspect = new EventEmitter<any>();
  displayOtm = true;

  ngOnInit() {
  }

  handleAddButtonClick($event) {
    $event.stopPropagation();
    if (this.onAddProspect) {
      this.onAddProspect.emit(this.rowData);
    }
  }
}
