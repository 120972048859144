<div class='app-body'>
  <div class="app-header">
    <div class='title-blog'>
      <div class='RedEarthTitle'>
        RedEarth
        <!--        col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6-->
      </div>
      <div class='cms mt-2'>
        <a href="home" [routerLink]="['/']" class="nav-link active">CMS</a>
      </div>
    </div>
  </div>
  <div class='container-fluid'>
    <nb-layout>
      <nb-layout-column class='one-column-content'>
        <router-outlet></router-outlet>
      </nb-layout-column>
    </nb-layout>
  </div>
</div>
