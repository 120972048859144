<nb-card>
  <nb-card-header>
    Sales Details
  </nb-card-header>

  <nb-card-body>
    <div class='d-flex mb-2'>
      <div class='col-6'>
        <nb-select fullWidth size='tiny' nbTooltip='Agreement Type' [(ngModel)]='data.property.agreementType'>
          <nb-option [value]='null'>Agreement Type</nb-option>
          <nb-option *ngFor='let type of AGREEMENT_TYPE' [value]='type'>{{type}}</nb-option>
        </nb-select>
<!--        <nb-select fullWidth size='tiny' nbTooltip='Listing Status' [(ngModel)]='data.property.listingStatus'>-->
<!--          <nb-option [value]='null'>Listing Status</nb-option>-->
<!--          <nb-option *ngFor='let status of LISTING_STATUS' [value]='status'>{{status}}</nb-option>-->
<!--        </nb-select>-->
      </div>
<!--      <div class='col-6'>-->
<!--        -->
<!--      </div>-->
    </div>
    <nb-accordion multi>
      <nb-accordion-item>
        <nb-accordion-item-header>Sales Agency</nb-accordion-item-header>
        <nb-accordion-item-body>
          <div class='row mb-2 justify-content-center' *ngIf='vendorList?.length === 0'>
            <div class='text-center'>
              No vendors
            </div>
          </div>
          <div class='row mb-2' *ngFor='let vendor of vendorList, index as index'>
            <div class='col-4'>
              <div class='col-12 property-type-container'>
                <input nbInput fieldSize='tiny' fullWidth placeholder='First Name' nbTooltip='Vendor first name'
                       [(ngModel)]='vendor.name'>
              </div>
              <div class='col-12 property-type-container mt-2'>
                <input nbInput fieldSize='tiny' fullWidth placeholder='Last Name' nbTooltip='Vendor last name'
                       [(ngModel)]='vendor.surname'>
              </div>
            </div>
            <div class='col-4'>
              <div class='col-12 property-type-container'>
                <input nbInput fieldSize='tiny' fullWidth placeholder='ID Number' nbTooltip='Vendor ID number'
                       [(ngModel)]='vendor.idNumber'>
              </div>
              <div class='col-12 property-spec-select mt-2'>
                <nb-select fullWidth size='tiny' [(ngModel)]='vendor.type'>
                  <nb-option [value]=''>Vendor type</nb-option>
                  <nb-option *ngFor='let key of VENDOR_TYPE' [value]='key'>{{key}}</nb-option>
                </nb-select>
              </div>
            </div>
            <div class='col-4'>
              <div class='row'>
                <div class='col-12 property-type-container pl-2'>
                  <nb-checkbox [(ngModel)]='vendor.signature'>Signature?</nb-checkbox>
                  <i class='fa fa-times pull-right' (click)='deleteVendor(vendor, index)'></i>
                </div>
              </div>
            </div>
          </div>
          <div class='col-12'>
            <button nbButton status='primary' size='tiny' class='fa-pull-right' (click)='addVendor()'>Add Vendor
            </button>
          </div>
          <hr class='tab-break'>
          <div class='mb-2'>
            <div class='w-100 justify-content-center'>
              <div class='text-center'><h5>Listing Representative Info</h5></div>
            </div>
            <div class='row mt-2' [formGroup]='listingRepresentativeForm'>
              <div class='col-3 property-type-container'>
                <input nbInput fieldSize='tiny' fullWidth placeholder='ABN/CAN Number' nbTooltip='ABN/CAN Number'
                       formControlName='abnNumber'>
              </div>
              <div class='col-3 property-type-container'>
                <input nbInput fieldSize='tiny' fullWidth placeholder='Phone' nbTooltip='Phone' formControlName='phone'>
              </div>
              <div class='col-3 property-type-container'>
                <input nbInput fieldSize='tiny' fullWidth placeholder='Mobile Phone' nbTooltip='Mobile Phone'
                       formControlName='mobilePhone'>
              </div>
              <div class='col-3 property-type-container'>
                <input nbInput fieldSize='tiny' fullWidth placeholder='Email' nbTooltip='Email' formControlName='email'>
              </div>
            </div>
          </div>
          <hr>
          <div>
            <div class='row'>
              <div class='col-12'>
            <textarea nbInput fullWidth placeholder='Inclusions' nbTooltip='Inclusions'
                      [(ngModel)]='data.property.inclusions'></textarea>
              </div>
            </div>
            <div class='row mt-2'>
              <div class='col-4'><h6>Agent Estimated Price: </h6></div>
              <div class='col-8'>
                <div class='col-12 property-type-container'>
                  <nb-form-field>
                    <input nbInput fieldSize='tiny' fullWidth placeholder='From' nbTooltip='Price From'
                           [(ngModel)]='data.property.agentEstimatePriceFrom'>
                    <span nbSuffix>{{defaultCurrencyIcon}}</span>
                  </nb-form-field>
                </div>
                <div class='col-12 property-type-container mt-2'>
                  <nb-form-field>
                    <input nbInput fieldSize='tiny' fullWidth placeholder='To' nbTooltip='Price To'
                           [(ngModel)]='data.property.agentEstimatePriceTo'>
                    <span nbSuffix>{{defaultCurrencyIcon}}</span>
                  </nb-form-field>
                </div>
              </div>
            </div>
            <div class='row mt-2'>
              <div class='col-4'><h6>Asking Price: </h6></div>
              <div class='col-8'>
                <div class='col-12 property-type-container'>
                  <nb-form-field>
                    <input nbInput fieldSize='tiny' fullWidth placeholder='From' nbTooltip='Price From'
                           [(ngModel)]='data.property.askingPriceFrom'>
                    <span nbSuffix>{{defaultCurrencyIcon}}</span>
                  </nb-form-field>
                </div>
                <div class='col-12 property-type-container mt-2'>
                  <nb-form-field>
                    <input nbInput fieldSize='tiny' fullWidth placeholder='To' nbTooltip='Price To'
                           [(ngModel)]='data.property.askingPriceTo'>
                    <span nbSuffix>{{defaultCurrencyIcon}}</span>
                  </nb-form-field>
                </div>
              </div>
            </div>
            <div class='row mt-2'>
              <div class='col-4'><h6>% Asking Price/Agent Price: </h6></div>
              <div class='col-8' nbTooltip='% Asking Price/Agent Price'>
                <nb-form-field>
                  <input class='override-disabled' nbInput fieldSize='tiny' fullWidth
                         placeholder='% Asking Price/Agent Price'
                         disabled [value]='pricePercentage'>
                  <span nbSuffix>%</span>
                </nb-form-field>
              </div>
            </div>
            <div class='row mt-1'>
              <div class='col-12'>
                <button class='fa-pull-right' nbButton size='tiny' (click)='calculatePricePercentage()'
                        nbTooltip='Recalculate price percentage'>Calculate
                </button>
              </div>
            </div>
            <div class='row mt-2'>
              <div class='col-6'>
                <nb-select fullWidth size='tiny' [(ngModel)]='data.property.methodOfSale'>
                  <nb-option [value]=''>Method of Sale</nb-option>
                  <nb-option *ngFor='let method of METHOD_OF_SALE' [value]='method'>{{method}}</nb-option>
                </nb-select>
              </div>
              <div class='col-6'>
                <input [nbDatepicker]='agreementDateDP' nbInput fieldSize='tiny' placeholder='Agency Agreement Date'
                       fullWidth [(ngModel)]='agreementDate' nbTooltip='Agency Agreement Date'>
                <nb-datepicker #agreementDateDP></nb-datepicker>
              </div>
            </div>
            <div class='row mt-2'>
              <div class='col-6'>
                <input [nbDatepicker]='periodStart' nbInput fieldSize='tiny' placeholder='Agency Period Start Date'
                       fullWidth [(ngModel)]='agencyPeriodFrom' nbTooltip='Agency Period Start Date'>
                <nb-datepicker #periodStart></nb-datepicker>
              </div>
              <div class='col-6'>
                <input [nbDatepicker]='periodEnd' nbInput fieldSize='tiny' placeholder='Agency Period End Date'
                       fullWidth
                       [(ngModel)]='agencyPeriodTo' nbTooltip='Agency Period End Date'>
                <nb-datepicker #periodEnd></nb-datepicker>
              </div>
            </div>
            <div class='row mt-2'>
              <div class='col-12'>
                <nb-form-field>
                  <input nbInput fullWidth fieldSize='tiny' placeholder='Commission Rate'
                         [(ngModel)]='data.property.commissionRate'>
                  <span nbSuffix>%</span>
                </nb-form-field>
              </div>
            </div>
            <div class='row mt-2'>
              <div class='col-4'><h6>VPA: </h6></div>
              <div class='col-8 d-flex'>
                <div class='col-6' nbTooltip='Total'>
                  <nb-form-field>
                    <input nbInput placeholder='Total' fieldSize='tiny' fullWidth [(ngModel)]='data.property.vpaTotal'>
                    <span nbSuffix>{{defaultCurrencyIcon}}</span>
                  </nb-form-field>
                </div>
                <div class='col-6' nbTooltip='Remain'>
                  <nb-form-field>
                    <input class='override-disabled' nbInput placeholder='Remain' fieldSize='tiny' disabled fullWidth
                           [value]='vpaRemain'>
                    <span nbSuffix>{{defaultCurrencyIcon}}</span>
                  </nb-form-field>
                </div>
              </div>
            </div>
            <div class='row mt-2 justify-content-center' *ngIf='vpaList?.length === 0'>
              <div class='text-center'>
                No VPA
              </div>
            </div>
            <div class='row mt-2' *ngFor='let vpa of vpaList, index as index'>
              <div class='col-4'>
                <div class='col-12 property-type-container'>
                  <input nbInput fieldSize='tiny' fullWidth placeholder='Type' nbTooltip='VPA type'
                         [(ngModel)]='vpa.type'>
                </div>
              </div>
              <div class='col-4'>
                <div class='col-12 property-type-container'>
                  <nb-form-field>
                    <input nbInput fieldSize='tiny' fullWidth placeholder='Value' nbTooltip='VPA Value'
                           [(ngModel)]='vpa.value'>
                    <span nbSuffix>{{defaultCurrencyIcon}}</span>
                  </nb-form-field>
                </div>
              </div>
              <div class='col-4'>
                <div class='row'>
                  <div class='col-12 property-type-container'>
                    <input nbInput [nbDatepicker]='vpaDate' fieldSize='tiny' fullWidth placeholder='Date'
                           [(ngModel)]='vpa.date'>
                    <nb-datepicker #vpaDate></nb-datepicker>
                    <i class='fa fa-times pull-right' (click)='deleteVpa(vpa, index)'></i>
                  </div>
                </div>
              </div>
            </div>
            <div class='row mt-1'>
              <div class='col-12'>
                <button nbButton status='primary' size='tiny' class='fa-pull-right' (click)='addVPA()'>Add VPA</button>
                <button class='fa-pull-right mr-2' nbButton size='tiny' (click)='recalculateVpa()'
                        nbTooltip='Recalculate VPA'>Calculate
                </button>
              </div>
            </div>
          </div>
        </nb-accordion-item-body>
      </nb-accordion-item>
      <nb-accordion-item>
        <nb-accordion-item-header>Contract</nb-accordion-item-header>
        <nb-accordion-item-body>
          <div class='row mb-2 justify-content-center'>
            <div class='text-center'>
              <h5>Solicitor Details</h5>
            </div>
          </div>
          <div class='row'>
            <div class='col-3'>
              <select class='select-custom' size='tiny' [(ngModel)]='selectedCompany.companyId'
                      (change)='onCompanyChange()'>
                <option [value]='null' disabled>Select Company</option>
                <option *ngFor='let company of solicitorCompany' [value]='company.companyId'>{{company.name}}</option>
              </select>
              <a href='/pages/prospect/company/list' target='_blank'>Create new</a>
            </div>
            <div class='col-3' nbTooltip='Phone'>
              <input class='override-disabled' nbInput fieldSize='tiny' fullWidth placeholder='Phone' disabled
                     [(ngModel)]='selectedCompany.phone'>
            </div>
            <div class='col-3' nbTooltip='Email'>
              <input class='override-disabled' nbInput fieldSize='tiny' fullWidth placeholder='Email' disabled
                     [(ngModel)]='selectedCompany.email'>
            </div>
            <div class='col-3' nbTooltip='Service count'>
              <input class='override-disabled' nbInput fieldSize='tiny' fullWidth placeholder='Service Count' disabled
                     [(ngModel)]='selectedCompany.serviceCount'>
            </div>
          </div>
          <div class='row mt-2'>
            <div class='col-12'>
          <textarea nbInput fullWidth placeholder='History Note' nbTooltip='History Note'
                    [(ngModel)]='data.historyNote'></textarea>
            </div>
          </div>
          <div class='row mt-2'>
            <div class='col-6'>
              <input nbInput fullWidth fieldSize='tiny' placeholder='Contract Request Date'
                     nbTooltip='Contract Request Date' [nbDatepicker]='requestDate'
                     [(ngModel)]='data.contractRequestDate'>
              <nb-datepicker #requestDate></nb-datepicker>
            </div>
            <div class='col-6'>
              <input nbInput fullWidth fieldSize='tiny' placeholder='Contract Received Date'
                     nbTooltip='Contract Received Date' [nbDatepicker]='receivedDate'
                     [(ngModel)]='data.contractReceivedDate'>
              <nb-datepicker #receivedDate></nb-datepicker>
            </div>
          </div>
          <div class='row mt-2 justify-content-center' *ngIf='contractChecklist.length === 0'>
            <div class='text-center'>No items</div>
          </div>
          <div class='row mt-2'>
            <div class='col-12 mt-1' *ngFor='let item of contractChecklist, index as index'>
              <div class='d-inline-flex'>
                <nb-checkbox [checked]='item.isDone' (checkedChange)='item.isDone = !item.isDone'
                             *ngIf='item.isEditing === false'></nb-checkbox>
                <span class='checkbox-label' (click)='toggleEditItem(item)'
                      *ngIf='item.isEditing === false'>{{item.description}}</span>
              </div>
              <nb-form-field *ngIf='item.isEditing === true'>
                <input nbInput fullWidth fieldSize='tiny' [(ngModel)]='item.description'>
                <button class='mr-5' nbButton nbSuffix size='tiny' status='primary' (click)='toggleEditItem(item)'>Done
                </button>
              </nb-form-field>
              <i class='fa fa-times pull-right' (click)='deleteChecklistItem(item, index)'></i>
            </div>
            <div class='col-12 mt-2 d-flex justify-content-center align-items-center'>
              <button class='fa-pull-right' nbButton size='tiny' status='primary' (click)='addChecklistItem()'>Add item
              </button>
            </div>
          </div>
        </nb-accordion-item-body>
      </nb-accordion-item>
      <nb-accordion-item>
        <nb-accordion-item-header>Marketing</nb-accordion-item-header>
        <nb-accordion-item-body>
          <div [formGroup]='marketingForm'>
            <div class='row'>
              <div class='col-6'>
                <input nbInput fullWidth fieldSize='tiny' [nbDatepicker]='photoDate' placeholder='Photo Date'
                       nbTooltip='Photo Date' formControlName='photoTime'>
                <nb-datepicker #photoDate></nb-datepicker>
              </div>
              <div class='col-6'>
                <input nbInput fullWidth fieldSize='tiny' [nbDatepicker]='videoDate' placeholder='Video Date'
                       nbTooltip='Video Date' formControlName='videoTime'>
                <nb-datepicker #videoDate></nb-datepicker>
              </div>
            </div>
            <div class='row mt-2'>
              <div class='col-12'>
            <textarea nbInput fullWidth placeholder='Advertisement Note' nbTooltip='Advertisement Note'
                      formControlName='adNote'></textarea>
              </div>
            </div>
          </div>
          <div class='row mt-2'>
            <div class='col-2'>
              <h6>Spec:</h6>
            </div>
            <div class='col-10 d-flex'>
              <div class='col-3' nbTooltip='Bed'>
                <input class='override-disabled' nbInput fullWidth fieldSize='tiny' [value]='data.property.bed' disabled
                       placeholder='Bed'>
              </div>
              <div class='col-3' nbTooltip='Bath'>
                <input class='override-disabled' nbInput fullWidth fieldSize='tiny' [value]='data.property.bath'
                       disabled
                       placeholder='Bath'>
              </div>
              <div class='col-3' nbTooltip='Car'>
                <input class='override-disabled' nbInput fullWidth fieldSize='tiny' [value]='data.property.car' disabled
                       placeholder='Car'>
              </div>
              <div class='col-3' nbTooltip='Land'>
                <input class='override-disabled' nbInput fullWidth fieldSize='tiny' [value]='data.property.land'
                       disabled
                       placeholder='Land'>
              </div>
            </div>
          </div>
          <div class='row mt-2 justify-content-center' *ngIf='noteList.length === 0'>
            <div class='text-center'>No notes</div>
          </div>
          <div class='row mt-2' *ngFor='let note of noteList, index as index'>
            <div class='col-9'>
              <textarea nbInput fullWidth placeholder='Note' nbTooltip='Note' [(ngModel)]='note.description'></textarea>
            </div>
            <div class='col-3'>
              <input nbInput fullWidth fieldSize='tiny' placeholder='Date' nbTooltip='Date' [nbDatepicker]='noteDate'
                     [(ngModel)]='note.date'>
              <nb-datepicker #noteDate></nb-datepicker>
            </div>
            <i class='fa fa-times fa-pull-right' (click)='deleteNote(note, index)'></i>
          </div>
          <div class='row mt-2'>
            <div class='col-12 d-flex justify-content-center align-items-center'>
              <button nbButton size='tiny' status='primary' (click)='addNote()'>Add note</button>
            </div>
          </div>
        </nb-accordion-item-body>
      </nb-accordion-item>
      <nb-accordion-item>
        <nb-accordion-item-header>Agents</nb-accordion-item-header>
        <nb-accordion-item-body>
          <div class='row'>
            <div class='col-6' nbTooltip='Agent Price'>
              <nb-form-field>
                <input class='override-disabled' nbInput fieldSize='tiny' fullWidth placeholder='Agent Price'
                       [(ngModel)]='agentPrice' disabled>
                <span nbSuffix>{{defaultCurrencyIcon}}</span>
              </nb-form-field>
            </div>
            <div class='col-6' nbTooltip='Fee'>
              <nb-form-field>
                <input class='override-disabled' nbInput fieldSize='tiny' fullWidth placeholder='Fee' [(ngModel)]='fee'
                       disabled>
                <span nbSuffix>{{defaultCurrencyIcon}}</span>
              </nb-form-field>
            </div>
          </div>
          <div class='row mt-2'>
            <div class='col-6' nbTooltip='Adjustment'>
              <nb-form-field>
                <input nbInput fieldSize='tiny' fullWidth placeholder='Adjustment'
                       [(ngModel)]='data.property.adjustment'>
                <span nbSuffix>{{defaultCurrencyIcon}}</span>
              </nb-form-field>
            </div>
            <div class='col-6' nbTooltip='Split Commission'>
              <nb-form-field>
                <input class='override-disabled' nbInput fieldSize='tiny' fullWidth placeholder='Split Commission'
                       [(ngModel)]='splitCommission' disabled>
                <span nbSuffix>{{defaultCurrencyIcon}}</span>
              </nb-form-field>
            </div>
          </div>
          <div class='row mt-2 justify-content-center'>
            <div class='text-center'>
              <button nbButton size='tiny' class='' (click)='calculateAgentPrice()'>Calculate
              </button>
            </div>
          </div>
          <div class='row mt-2 justify-content-center' *ngIf='agentGroupList.length === 0 '>
            <div class='text-center'>
              No Agents
            </div>
          </div>
          <div class='row mt-2' *ngFor='let group of agentGroupList, index as index'>
            <hr class='agent-create-hr' *ngIf='index > 0'>
            <div class='d-flex w-100'>
              <div class='col-3'>
                <h6>{{group.title}}</h6>
              </div>
              <div class='col-4'>
                <nb-form-field>
                  <input nbInput fullWidth fieldSize='tiny' [(ngModel)]='group.split' type='number' min='1' max='100'
                         step='1'>
                  <span nbSuffix>%</span>
                </nb-form-field>
              </div>
            </div>
            <div class='d-flex w-100 mt-2' *ngFor='let agent of group.agents, index as index'>
              <div class='col-3'>
                <nb-form-field>
                  <input class='override-disabled' nbInput fieldSize='tiny' fullWidth
                         [value]='calculateSplit(group.split, agent.agentSplit)' disabled>
                  <span nbSuffix>{{defaultCurrencyIcon}}</span>
                </nb-form-field>
              </div>
              <div class='col-4'>
                <nb-form-field>
                  <input nbInput fieldSize='tiny' fullWidth
                         [(ngModel)]='agent.agentSplit' type='number' min='1' max='100' step='1' pattern='[0-9]'
                  >
                  <span nbSuffix>%</span>
                </nb-form-field>
              </div>
              <div class='col-5 pr-3'>
                {{ agent.user && agent.user.name ? agent.user.name + ' (' + stringUtils.shortenUserName(agent.user) + ')' : '' }}
                <button nbButton status='success' size='tiny' class='fa-pull-right'
                        (click)='deleteAgent(agent, group, index)'>
                  Delete
                </button>
              </div>
            </div>
          </div>
          <div class='row mt-2' *ngIf='isEditing === false'>
            <div class='col-12'>
              <button nbButton status='primary' size='tiny' class='fa-pull-right' (click)='createNewAgent()'>Add Agent
              </button>
            </div>
          </div>
          <div class='row mt-2' *ngIf='isEditing === true'>
            <hr class='agent-create-hr'>
            <div class='new-agent'>
              <div class='row'>
                <div class='col-4'>
                  <nb-select size='tiny' fullWidth [(ngModel)]='selectedTitle'>
                    <nb-option [value]='null'>Title</nb-option>
                    <nb-option *ngFor='let title of AGENT_TITLE' [value]='title'>{{title}}</nb-option>
                  </nb-select>
                </div>
                <div class='col-4'>
                  <nb-form-field>
                    <input nbInput fieldSize='tiny' fullWidth placeholder='Agent split'
                           [(ngModel)]='newAgent.agentSplit' type='number' min='1' max='100' step='1'
                           nbTooltip='Split in % of this agent in this group, leave blank if this group has only 1 agent'>
                    <span nbSuffix>%</span>
                  </nb-form-field>
                </div>
                <div class='col-4'>
                  <input [(ngModel)]='agentName'
                         [ngModelOptions]='{standalone: true}'
                         [typeahead]='filterUser$'
                         [typeaheadAsync]='true'
                         (typeaheadOnSelect)='selectUser($event)'
                         typeaheadOptionField='name'
                         typeaheadWaitMs='1000'
                         placeholder='Search User'
                         nbInput fieldSize='tiny' fullWidth>
                </div>
              </div>
              <div class='row mt-2'>
                <div class='col-12'>
                  <button class='fa-pull-right' nbButton status='primary' size='tiny' (click)='editDone()'>Done</button>
                  <button class='fa-pull-right mr-2' nbButton status='basic' size='tiny' (click)='cancelEdit()'>Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </nb-accordion-item-body>
      </nb-accordion-item>
    </nb-accordion>
  </nb-card-body>
  <nb-card-footer>
    <div class='row'>
      <div class='col-12'>
        <button nbButton size='tiny' status='primary' class='fa-pull-right' (click)='save()'>Save</button>
        <button nbButton size='tiny' status='primary' class='fa-pull-right mr-2' (click)='close()'>Close</button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
