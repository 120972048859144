import {Customer} from '../../../../shared/models/response/prospect/customer';
import {CustomRow} from '../../../../shared/models/common/custom-row';
import {WrapBuyerType} from './buyer-utils';
import {SmartTableCustomCellComponent} from './smart-table-custom-cell-component';

export class SmartTableUtils {

  // support for table
  static createColumn(title: string, propertyPaths: string[],
                      valueType?: string, separator?: string,
                      valueReplacement?: any, renderComponent?: any) {
    return {
      title: title,
      type: 'custom',
      filter: false,
      renderComponent: renderComponent ? renderComponent : SmartTableCustomCellComponent,
      onComponentInitFunction: (instance: any) => {
        if (instance instanceof SmartTableCustomCellComponent) {
          instance.config = {
            propertyPaths: propertyPaths,
            valueType: valueType,
            valueReplacement,
            separator,
          };
        }
      },
    };
  }

  static createColumns(columnsDefinition) {
    const columns = {};
    for (const key of Object.keys(columnsDefinition)) {
      if (columnsDefinition[key].enable === true) {
        columns[key] = columnsDefinition[key].value;
      }
    }
    return columns;
  }

  static highlightRow(data: any) {
    return '';
  }
}
