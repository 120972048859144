<nb-card class="dialog-container">
  <nb-card-header>
    <span>Add new task</span>
  </nb-card-header>
  <nb-card-body>
    <div class="col-12 flex">
      <div class="{{selectedType?.category?.name === 'CLIENT' || selectedType?.category?.name === 'CUSTOMER' ? 'col-md-6 col-lg-6 col-xs-12' : 'col-12'}}">
        <form [formGroup]="form">
          <div class="form-group">
            <div>
              <nb-select formControlName="taskTypeId" fullWidth placeholder="Task type" [ngClass]="showErrorInput('taskTypeId')">
                <nb-option [value]="" (click)="selectType(null)">Empty</nb-option>
                <nb-option *ngFor="let item of taskTypes" [value]="item.taskTypeId" (click)="selectType(item)">{{item.name}}</nb-option>
              </nb-select>
              <a href="/pages/task/type/list">Create new</a>
            </div>
            <div>
              <nb-form-field [ngClass]="showErrorInput('title')">
                <input nbInput fullWidth
                       placeholder="Title"
                       formControlName="title">
              </nb-form-field>
            </div>
          </div>
          <div class="form-group">
            <label class="label">Assign to</label>
            <input #autoInput
                   nbInput
                   fullWidth
                   type="text"
                   (input)="autoCompleteBehavior.next(autoInput.value)"
                   placeholder="Search user by username"
                   [nbAutocomplete]="auto"
                   [value]="selectedUser?.username"
                   [ngClass]="showErrorInput('toUserId')"
            />

            <nb-autocomplete #auto (selectedChange)="onUserFilterSelectionChange($event)">

              <nb-option *ngFor="let user of filteredUsers | async" [value]="user.username">
                {{ user.username + (user.name ? ' - ' + StringUtils.shortenUserName(user) : '') }}
              </nb-option>

            </nb-autocomplete>
          </div>
          <div class="form-group">
            <nb-form-field [ngClass]="showErrorInput('startDate')">
              <input [nbDatepicker]="datepickerStartDate"
                     nbInput fullWidth
                     placeholder="Start date"
                     formControlName="startDate">
              <button nbSuffix nbButton ghost>
                <nb-icon [icon]="'calendar-outline'"
                         pack="eva">
                </nb-icon>
              </button>
            </nb-form-field>
            <nb-datepicker #datepickerStartDate></nb-datepicker>
          </div>
          <div class="row mt-3 ml-1 mr-1">
            <div class="{{selectedType?.category?.name === 'CLIENT' ? 'col-md-12 col-lg-12 col-xs-12' : 'col-12'}}">
              <div class="row">
                <div class="col-4">
                  <nb-select class='col-4 mw-100'
                             style="margin-left:5px" formControlName="startHour"
                             placeholder="Hour" [ngClass]="showErrorInput('startHour')">
                    <nb-option *ngFor="let item of timeRange" [value]="item">{{item}}</nb-option>
                  </nb-select>
                </div>
                <div class="col-4">
                  <nb-select class='col-4 mw-100' style="margin-left:3px" formControlName="startMinute"
                             placeholder="Minute" [ngClass]="showErrorInput('startMinute')">
                    <nb-option *ngFor="let item of minuteRange" [value]="item">{{item}}</nb-option>
                  </nb-select>
                </div>
                <div class="col-4">
                  <nb-select class='col-4 mw-100'  formControlName="hourType"
                             [ngClass]="showErrorInput('hourType')">
                    <nb-option *ngFor="let item of hourTypes" [value]="item">{{item}}</nb-option>
                  </nb-select>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3 ml-1 mb-2 mr-1">
            <div class="{{selectedType?.category?.name === 'CLIENT' ? 'col-md-12 col-lg-12 col-xs-12' : 'col-12'}}">
              <div class="row">
                <div class="col-6">
                  <nb-select class='col-6 mw-100' placeholder="Duration"
                             formControlName="duration" [ngClass]="showErrorInput('duration')">
                    <nb-option *ngFor="let item of durations" [value]="item">
                      {{item}}
                    </nb-option>
                  </nb-select>
                </div>
                <div class="col-6">
                  <nb-select class='col-6 mw-100' formControlName="durationType"
                             [ngClass]="showErrorInput('durationType')">
                    <nb-option *ngFor="let item of durationTypes" [value]="item">
                      {{item}}
                    </nb-option>
                  </nb-select>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group mt-3" [hidden]="!this.defaultData?.task">
            <div>
              <nb-select formControlName="status" fullWidth placeholder="Status">
                <nb-option [value]="">Empty</nb-option>
                <nb-option *ngFor="let item of taskStatuses" [value]="item.name">{{item.name}}</nb-option>
              </nb-select>
              <a href="/pages/task/status/list">Create new</a>
            </div>
          </div>

          <div class="form-group">
            <nb-checkbox formControlName="sendEmail">
              Send notification email
            </nb-checkbox>
            <nb-checkbox class="ml-4" formControlName="isSp"
                         [hidden]="this.selectedType?.category?.name !== 'CLIENT'">
              SP
            </nb-checkbox>
            <nb-checkbox class="ml-4" formControlName="isS4"
                         [hidden]="this.selectedType?.category?.name !== 'CLIENT'">
              S4
            </nb-checkbox>
            <nb-checkbox class="ml-4" formControlName="isBi"
                         [hidden]="this.selectedType?.category?.name !== 'CUSTOMER'">
              BI
            </nb-checkbox>
            <nb-checkbox class="ml-4" formControlName="isB4"
                         [hidden]="this.selectedType?.category?.name !== 'CUSTOMER'">
              B4
            </nb-checkbox>
          </div>

          <div class="form-group">
            <label class="label">Message</label>
            <textarea rows="5" nbInput fullWidth shape="square" formControlName="message"
                      placeholder="Message"></textarea>
          </div>

          <div class="form-group">
            <label class="label">Note</label>
            <textarea rows="5" nbInput fullWidth shape="square" formControlName="note"
                      placeholder="Note"></textarea>
          </div>

          <div class='form-group' *ngIf='isMarketingSchedule'>
            <div class='col-12 d-flex justify-content-center'>
              <!--              <nb-toggle labelPosition='start' [checked]='!event.isManual' nbTooltip='Automatic Sending?'></nb-toggle>-->
              <button nbButton status='primary' size='tiny' (click)='exportMobile()'>
                <i class='fa fa-download mr-1'></i> Mobile
              </button>
              <button nbButton status='primary' size='tiny' (click)='exportEmail()'>
                <i class='fa fa-download mr-1'></i> Email
              </button>
            </div>
          </div>

          <div class="form-group" [hidden]="selectedType?.category?.name !== 'CLIENT'">
            <label class="label">Client</label>
            <div class="row">
              <div class="col-6">
                <input nbInput
                       fullWidth
                       type="text"
                       placeholder="Phone number"
                       [value]="prospectPhone"
                       (change)="setProspectPhone($event)"
                />
              </div>
              <div class="col-2">
                <button nbButton status="info" class="ml-2" (click)="searchProspect(newSearch)">Search</button>
              </div>
            </div>
          </div>

          <nb-list-item [hidden]="!selectedProspect">
            <b style = 'width: 180px'>
              {{StringUtils.getFullAddress(selectedProspect?.address)}}
<!--              {{ selectedProspect?.address?.unit ? selectedProspect?.address?.unit + "-" : "" }}-->
<!--              {{ selectedProspect?.address?.number }}-->
<!--              - {{ selectedProspect?.address?.street?.name }}-->
<!--              - {{ selectedProspect?.address?.street?.district?.name }}-->
<!--              - {{ selectedProspect?.address?.street?.district?.city?.name }}-->
            </b>
            <b style='width: 120px' *ngIf="selectedProspect?.customer?.name">
              {{ " | " + selectedProspect?.customer?.name }}
            </b>
          </nb-list-item>

          <nb-card size="medium" [hidden]="!filteredProspects || filteredProspects.length <= 0 || isHidden">
            <nb-list
              nbInfiniteList
              [threshold]="20"
              (bottomThreshold)="searchProspect()"
            >
              <nb-list-item
                    *ngFor="let item of filteredProspects"
                    class="prospect-item"
                    (click)="selectProspect(item)"
                    [ngClass]="selectedProspect?.prospectId === item?.prospectId ? 'selected-prospect' : ''"
              >
                {{StringUtils.getFullAddress(item?.address)}}
<!--                {{ item?.address?.unit ? item?.address?.unit + "-" : "" }}-->
<!--                {{ item?.address?.number }}-->
<!--                - {{ item?.address?.street?.name }}-->
<!--                - {{ item?.address?.street?.district?.name }}-->
<!--                - {{ item?.address?.street?.district?.city?.name }}-->
                <b *ngIf="item?.customer?.name">
                  {{ " | " + item?.customer?.name }}
                </b>
              </nb-list-item>
            </nb-list>
          </nb-card>
        </form>
      </div>
      <div class="col-md-6 col-lg-6 col-xs-12 prospect-detail">
        <ngx-prospect-dialog
          #prospectForm
          [selectedProspect]="selectedProspect"
          [companies]="companies"
          [taskTypes]="taskTypes"
          [taskStatuses]="taskStatuses"
          [showBottomButtons]="false"
          [hidden]="selectedType?.category?.name !== 'CLIENT'"
        ></ngx-prospect-dialog>
        <ngx-buyer-create
          #buyerForm
          [cities]="cities"
          [taskTypes]="taskTypes"
          [taskStatuses]="taskStatuses"
          [hidden]="selectedType?.category?.name !== 'CUSTOMER'"
          [isInjected]="true"
        ></ngx-buyer-create>
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer class="dialog-footer">
    <button class="cancel" nbButton (click)="cancel()">Cancel</button>
    <button nbButton status="success" class="ml-2" (click)="submit()" *appPermissionCheck='ComponentPermission.TASK_EDIT'>Save</button>
    <button nbButton status="danger" class="ml-2" (click)="removeTask()" *appPermissionCheck='ComponentPermission.TASK_DELETE'>Delete</button>
    <button [hidden]="selectedType?.category?.name !== 'CLIENT'" nbButton status="success" class="mr-0 fa-pull-right" (click)="saveProspect()">Save client</button>
  </nb-card-footer>
</nb-card>
