import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef, NbPosition, NbTrigger } from '@nebular/theme';
import { City } from '../../../../shared/models/response/address/city';
import { TemplateService } from '../../../../shared/services/template.service';
import { SearchRequest } from '../../../../shared/models/request/search-request';
import { Template } from '../../../../shared/models/response/template';
import { ProspectMobileWrapper } from '../../../../shared/models/response/prospect/prospect-mobile-wrapper';
import { Constant } from '../../../../shared/common/constant';
import { BuyerMobileWrapper } from '../../../../shared/models/response/buyer/buyer-mobile-wrapper';
import { StringUtils } from '../../../../shared/common/string-utils';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DeviceDetectionService } from '../../../../shared/services/device-detection.service';

@Component({
  selector: 'ngx-export-dialog',
  templateUrl: './export-dialog.component.html',
  styleUrls: ['./export-dialog.component.scss'],
})
export class ExportDialogComponent implements OnInit {
  static confirmOk: string = 'CONFIRM_OK';

  @Input() data: string;
  prospectMobile: ProspectMobileWrapper[];
  buyerMobile: BuyerMobileWrapper[];
  adminNumber: string;
  templates: Template[] = [];
  selectedTemplate: Template;
  trigger = NbTrigger.CLICK;
  position = NbPosition.END;
  sendToTenant = false;
  exportType: string;

  constructor(
    protected ref: NbDialogRef<ExportDialogComponent>,
    private sanitizer: DomSanitizer,
    private deviceDetection: DeviceDetectionService,
  ) {
  }

  ngOnInit(): void {
    if (this.templates) {
      if (!this.selectedTemplate) {
        this.selectedTemplate = this.templates[0];
      }
    }
    if (this.prospectMobile) {
      this.generateMobileString('prospect');
    }
    if (this.buyerMobile) {
      this.generateMobileString('buyer');
    }
  }

  submit() {
    if (this.sendToTenant) {
      this.ref.close();
      return;
    }
    const ids = [];
    if (this.prospectMobile) {
      for (const wrapper of this.prospectMobile) {
        if (wrapper.mobilePhone !== null) {
          ids.push(wrapper.prospectId);
        }
      }
    }
    if (this.buyerMobile) {
      for (const wrapper of this.buyerMobile) {
        if (wrapper.mobilePhone !== null) {
          ids.push(wrapper.buyerId);
        }
      }
    }
    this.ref.close({ ids: ids, log: this.selectedTemplate.message });
  }

  cancel() {
    this.ref.close();
  }

  createTemplate() {
    window.open(
      '/pages/other/template',
      '_blank',
    );
  }

  onSelectTemplate(event: any) {
    this.templates.find(temp => {
      if (temp.templateId === Number(event)) {
        this.selectedTemplate = temp;
      }
    });
  }

  generateMobileString(type: string) {
    const filtered = [];
    if (this.adminNumber) {
      filtered.unshift(this.adminNumber);
    } else {
      filtered.unshift(Constant.ADMIN_NUMBER);
    }
    if (type === 'prospect') {
      for (const wrapper of this.prospectMobile) {
        if (wrapper !== null && wrapper.mobilePhone !== null && wrapper.mobilePhone !== '') {
          filtered.push(wrapper.mobilePhone.trim().replace(/\s/g, ''));
        }
      }
    }
    if (type === 'buyer') {
      for (const wrapper of this.buyerMobile) {
        if (wrapper !== null && wrapper.mobilePhone !== null && wrapper.mobilePhone !== '') {
          filtered.push(wrapper.mobilePhone.trim().replace(/\s/g, ''));
        }
      }
    }
    const remainingFiltered = [];
    filtered.filter(f => {
      if (remainingFiltered.indexOf(f) === -1) {
        remainingFiltered.push(f);
      }
    });
    this.data = remainingFiltered.join(', ');
  }

  generateHref(): SafeUrl {
    if (!StringUtils.isNotEmpty(this.data)) {
      return;
    }
    let href;
    const phone = this.data.replace(/\s/g, '');
    if (this.deviceDetection.isAndroid()) {
      href = 'sms:' + phone + '?body=' + encodeURIComponent(this.selectedTemplate.message);
    } else {
      href = 'sms://open?addresses=' + phone + '&body=' + encodeURIComponent(this.selectedTemplate?.message);
    }
    return this.sanitizer.bypassSecurityTrustUrl(href);
  }
}
