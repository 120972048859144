import { User } from '../user';

export class Agent {
  agentId?: number;
  userId?: number;
  agentGroupId?: number;
  agentSplit?: number;
  createdBy?: string;
  createdDate?: number;
  user?: User;
  isNew?: boolean;
}
