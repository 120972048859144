<div class='col-12 d-flex mt-2 row'>
  <div class='col-lg-3 col-12 override-width override-width-md mt-2'>
    <nb-select fullWidth size='tiny' [(ngModel)]='searchRequest.sortType'>
      <nb-option *ngFor='let item of sortTypesText | keyvalue'
                 value='{{item.key}}'>{{item.value}}</nb-option>
    </nb-select>
  </div>
  <div class='col-lg-3 col-12 override-width override-width-md mt-2'>
    <nb-select fullWidth size='tiny'
               placeholder='All streets'
               [(ngModel)]='searchRequest.streetId'
               [disabled]='!searchRequest?.districtId'
    >
      <nb-option [value]='undefined'>All streets</nb-option>
      <nb-option *ngFor='let key of streets'
                 [ngClass]="key.status === 'DEACTIVE' ? 'deactive-status' : ''"
                 [value]='key.streetId'>{{key.name}}</nb-option>
    </nb-select>
  </div>
  <div class='col-lg-3 col-12 override-width override-width-md mt-2'>
    <nb-select fullWidth size='tiny'
               placeholder='All suburbs'
               [(ngModel)]='searchRequest.districtId'
               [disabled]='!filterByPostcode'>
      <nb-option [value]='undefined' (click)='onSuburbSelectChange()'>All suburbs</nb-option>
      <nb-option *ngFor='let key of districts'
                 [ngClass]="key.status === 'DEACTIVE' ? 'deactive-status' : ''"
                 [value]='key.districtId'
                 (click)='onSuburbSelectChange()'
      >{{key.name}}</nb-option>
    </nb-select>
  </div>
  <div class='col-lg-3 col-12 override-width override-width-md mt-2'>
<!--    <nb-select fullWidth size='tiny'-->
<!--               placeholder='All cities'-->
<!--               [(ngModel)]='searchRequest.cityId'>-->
<!--      <nb-option [value]='undefined' (click)='onCitySelectChange()'>All cities</nb-option>-->
<!--      <nb-option *ngFor='let key of cities'-->
<!--                 [ngClass]="key.status === 'DEACTIVE' ? 'deactive-status' : ''"-->
<!--                 [value]='key.cityId'-->
<!--                 (click)='onCitySelectChange()'-->
<!--      >{{key.name}}</nb-option>-->
<!--    </nb-select>-->
    <nb-form-field>
      <input nbInput fullWidth placeholder='Postcode' fieldSize='tiny' class='override-nbinput-height'
             [(ngModel)]='postcode'
             [typeahead]='filterPostcode$'
             [ngModelOptions]='{standalone: true}'
             [typeaheadAsync]='true'
             (typeaheadOnSelect)='selectPostcode($event)'
             typeaheadOptionField='code'
             typeaheadMinLength='3'
             typeaheadWaitMs='1000'>
<!--      <span nbSuffix (click)='searchSuburbByPostcode()'><i class='fa fa-search'></i></span>-->
    </nb-form-field>
  </div>
</div>
<div class='col-12 d-flex mt-2 row'>
  <div class='col-lg-3 col-6 override-width override-width-md mt-2'>
    <input type='text' nbInput fieldSize='tiny' fullWidth name='name' placeholder='Name'
           [(ngModel)]='searchRequest.name' />
  </div>
  <div class='col-lg-3 col-6 override-width override-width-md mt-2'>
    <input type='text' nbInput fieldSize='tiny' fullWidth name='surname' placeholder='Surname'
           [(ngModel)]='searchRequest.surname' />
  </div>
  <div class='col-lg-3 col-6 override-width override-width-md mt-2'>
    <input type='text' nbInput fieldSize='tiny' fullWidth name='phone' placeholder='Phone / Mobile'
           [(ngModel)]='searchRequest.phone' />
  </div>
  <div class='col-lg-3 col-6 override-width override-width-md mt-2'>
    <input type='text' nbInput fieldSize='tiny' fullWidth name='email' placeholder='Email'
           [(ngModel)]='searchRequest.email' />
  </div>
</div>
<div class='col-12 d-flex mt-2 row'>
  <div class='col-6 col-lg-3 mt-2'>
    <nb-select fullWidth size='tiny' [(ngModel)]='searchRequest.rate' (selectedChange)='onSelectRate($event)'>
      <nb-option [value]='undefined'>All rates</nb-option>
      <nb-option *ngFor='let item of rateOptions'
                 [value]='item.value'>{{item.label}}</nb-option>
    </nb-select>
  </div>
</div>
<div class='col-12 d-flex mt-2'>
  <div class='col-12'>
    <button nbButton size='tiny' hero status='info' class='fa-pull-right mr-4' (click)='search()'>Search</button>
    <button nbButton size='tiny' hero status='primary' class='fa-pull-right mr-1' (click)='addNewProspect()'
            *appPermissionCheck='ComponentPermission.CLIENT_CREATE'>New
    </button>
    <button nbButton size='tiny' hero status='primary' class='fa-pull-right mr-1' (click)='openShortcut()'
            *appPermissionCheck='ComponentPermission.CLIENT_CREATE_SHORTCUT'>Shortcut
    </button>
  </div>
</div>
<div class='col-12 mt-2'>
    <nb-accordion>
      <nb-accordion-item class='col-12 d-flex'>
        <nb-accordion-item-header>Display options</nb-accordion-item-header>
        <nb-accordion-item-body>
          <div class='row'>
            <div class='col-sm-6'>
              <div class='form-group'>
                <p class='label'>Address</p>
                <nb-checkbox
                  *ngFor='let item of columnsDefinition | keyvalue'
                  [hidden]='item?.value?.group !== columnGroup.ADDRESS'
                  class='diplay-option mr-1 mb-2'
                  [checked]='item?.value?.enable'
                  (checkedChange)='onColumnDisplayOptionCheckedChange($event, item.key)'
                >{{item?.value?.name}}</nb-checkbox>
              </div>
            </div>
            <div class='col-sm-6'>
              <div class='form-group'>
                <p class='label'>Property</p>
                <nb-checkbox
                  class='diplay-option mr-1 mb-2'
                  *ngFor='let item of columnsDefinition | keyvalue'
                  [hidden]='item?.value?.group !== columnGroup.PROPERTY'
                  [checked]='item?.value?.enable'
                  (checkedChange)='onColumnDisplayOptionCheckedChange($event, item.key)'
                >{{item?.value?.name}}</nb-checkbox>
              </div>
            </div>
          </div>
          <div class='row'>
            <div class='col-sm-6'>
              <div class='form-group'>
                <p class='label'>Customer</p>
                <nb-checkbox
                  class='diplay-option mr-1 mb-2'
                  *ngFor='let item of columnsDefinition | keyvalue'
                  [hidden]='item?.value?.group !== columnGroup.CUSTOMER'
                  [checked]='item?.value?.enable'
                  (checkedChange)='onColumnDisplayOptionCheckedChange($event, item.key)'
                >{{item?.value?.name}}</nb-checkbox>
              </div>
            </div>
            <div class='col-sm-6'>
              <div class='form-group'>
                <p class='label'>Contact</p>
                <nb-checkbox
                  class='diplay-option mr-1 mb-2'
                  *ngFor='let item of columnsDefinition | keyvalue'
                  [hidden]='item?.value?.group !== columnGroup.CONTACT'
                  [checked]='item?.value?.enable'
                  (checkedChange)='onColumnDisplayOptionCheckedChange($event, item.key)'
                >{{item?.value?.name}}</nb-checkbox>
              </div>
            </div>
          </div>
          <div class='row'>
            <div class='col-sm-12'>
              <div class='form-group'>
                <p class='label'>Others</p>
                <nb-checkbox
                  class='diplay-option mr-1'
                  *ngFor='let item of columnsDefinition | keyvalue'
                  [hidden]='item?.value?.group !== columnGroup.OTHERS'
                  [checked]='item?.value?.enable'
                  (checkedChange)='onColumnDisplayOptionCheckedChange($event, item.key)'
                >{{item?.value?.name}}</nb-checkbox>
              </div>
            </div>
          </div>
          <div class='row'>
            <div class='col-sm-12'>
              <button nbButton size='tiny' hero status='primary' class='fa-pull-right'
                      (click)='applyColumnDisplayOptionChange()'>Apply
              </button>
            </div>
          </div>
        </nb-accordion-item-body>
      </nb-accordion-item>
    </nb-accordion>
</div>
<div class='col-12 d-flex mt-3 pl-4 pr-4'>
  <div class='col-4'>
    <p class='label'>Total</p>
    <p>{{total}}</p>
  </div>
  <div class='col-8'>
    <div class='form-group fa-pull-right mr-1'>
      <p class='label'>O</p>
      <p>{{totalOtm}}</p>
    </div>
<!--    <div class='form-group fa-pull-right mr-5'>-->
<!--      <p class='label'>V</p>-->
<!--      <p>{{totalVac}}</p>-->
<!--    </div>-->
    <div class='form-group fa-pull-right mr-5'>
      <p class='label'>R</p>
      <p>{{totalRent}}</p>
    </div>
  </div>
</div>
