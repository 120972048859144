import { Component, OnInit } from '@angular/core';
import { Prospect } from '../../../models/response/prospect/prospect';
import { StringUtils } from '../../../common/string-utils';
import { Constant } from '../../../common/constant';
import { Address } from '../../../models/response/address/address';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { FormControl, FormGroup } from '@angular/forms';
import { Tenant } from '../../../models/response/prospect/tenant';
import { CommonService } from '../../../services/common.service';
import { ExportDialogComponent } from '../../../../modules/pages/buyer/export-dialog/export-dialog.component';
import { SearchRequest } from '../../../models/request/search-request';
import { TemplateService } from '../../../services/template.service';
import { ProspectMobileWrapper } from '../../../models/response/prospect/prospect-mobile-wrapper';
import { AgentGroup } from '../../../models/response/prospect/agent-group';
import { Agent } from '../../../models/response/prospect/agent';
import { ConfirmDialogComponent } from '../../../../modules/pages/confirm-dialog/confirm-dialog.component';
import { AgentService } from '../../../services/agent.service';
import { AgentGroupService } from '../../../services/agent.group.service';
import { Observable } from 'rxjs';
import { User } from '../../../models/response/user';
import { map, mergeMap } from 'rxjs/operators';
import { UserService } from '../../../services/user.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import {ComponentPermission} from '../../../../component.permission';

@Component({
  selector: 'ngx-rental-details',
  templateUrl: './rental-details.component.html',
  styleUrls: ['./rental-details.component.scss']
})
export class RentalDetailsComponent implements OnInit {

  data: Prospect;
  stringUtil = StringUtils;
  agentName = Constant.AGENT_NAME;
  licenseNumber = Constant.LICENSE_NUMBER;
  abn = Constant.ABN_NUMBER;
  officeName = Constant.OFFICE_NAME;
  officeAddress = Constant.OFFICE_ADDRESS;
  officeFax = Constant.OFFICE_FAX;
  officeEmail = Constant.OFFICE_EMAIL;
  defaultCurrencyIcon = Constant.DEFAULT_CURRENCY_ICON;
  agentGroupList: AgentGroup[] = [];
  stringUtils = StringUtils;
  isEditing = false;
  newAgent: Agent = new Agent();
  selectedTitle: string = null;
  filterUser$: Observable<User[]>;
  newAgentName: string;
  ComponentPermission = ComponentPermission;
  disable: boolean;

  constructor(private ref: NbDialogRef<RentalDetailsComponent>,
              private commonService: CommonService,
              private dialogService: NbDialogService,
              private templateService: TemplateService,
              private agentService: AgentService,
              private agentGroupService: AgentGroupService,
              private userService: UserService) { }

  ngOnInit(): void {
    if (ComponentPermission.isAccess(ComponentPermission.CLIENT_RENTAL_DETAILS)) {
      this.disable = false;
    } else {
      this.disable = true;
    }
    if (!this.data.tenant) {
      this.data.tenant = new Tenant();
    }
    if (this.data.property.agentGroups) {
      this.agentGroupList = this.data.property.agentGroups.filter(g => g.type === 'RENT');
    }
    this.filterUser$ = new Observable((observer: any) => {
      observer.next(this.newAgentName);
    }).pipe(
      mergeMap((query: string) => this.searchUser(query)),
    );
  }

  searchUser(keyword: string): Observable<User[]> {
    // this.refreshUserFilter();
    const searchRequest = new SearchRequest();
    searchRequest.conditions = new Array();
    searchRequest.conditions.push(
      {
        left: 'email',
        middle: 'like',
        right: keyword,
      },
    );
    searchRequest.conditions.push(
      {
        left: 'username',
        middle: 'like',
        right: keyword,
      },
    );
    searchRequest.conditions.push(
      {
        left: 'name',
        middle: 'like',
        right: keyword,
      },
    );
    searchRequest.conditionType = Constant.CONDITION_TYPE.OR;
    searchRequest.offset = 0;
    searchRequest.limit = 10;
    return this.userService.search(searchRequest).pipe(
      map(result => result.data.filter(user => user.status === 'ACTIVE')),
    );
  }

  selectUser(type: TypeaheadMatch) {
    const user = type.item;
    this.newAgent.user = user;
    this.newAgent.userId = user.userId;
  }

  save() {
    if (this.agentGroupList.length !== 0) {
      this.data.property.agentGroups.push(...this.agentGroupList);
    }
    this.ref.close(this.data);
  }

  close() {
    this.ref.close();
  }

  isCar() {
    return !(!this.data.property.car || this.data.property.car === 0);
  }

  get ARREAR_OPTION() {
    return Constant.ARREAR_OPTION;
  }

  calculateArrearAmount() {
    if (!this.data?.tenant?.arrearWeekCount) {
      return;
    }
    this.data.tenant.arrearAmount = this.data.tenant.arrearWeekCount * this.data.property.rentPrice;
  }

  checkArrearStatus() {
    if (this.data.tenant.isArrear === false) {
      this.data.tenant.arrearWeekCount = null;
      this.data.tenant.arrearAmount = null;
      this.data.tenant.notificationSent = null;
    }
  }

  sendSMS() {
    if (!this.data.tenant.mobilePhone || this.data.tenant.mobilePhone.trim() === '') {
      this.commonService.warning('Tenant have no mobile phone number');
      return;
    }
    const searchRequest = new SearchRequest();
    searchRequest.conditions = [];
    searchRequest.conditions.push({
      left: 'type',
      middle: '=',
      right: 'SMS',
    });
    const prospectMobile = [];
    const mobileWrapper = new ProspectMobileWrapper();
    mobileWrapper.mobilePhone = this.data.tenant.mobilePhone;
    prospectMobile.push(mobileWrapper);
    this.templateService.search(searchRequest).subscribe(r => {
      this.dialogService.open(ExportDialogComponent, {
        context: {
          prospectMobile: prospectMobile,
          templates: r.data,
          sendToTenant: true,
        },
      }).onClose.subscribe(
        onCloseRes => {
          if (this.data.tenant.isArrear) {
            this.data.tenant.notificationSent = true;
          }
        },
      );
    });
  }

  createNewAgent() {
    // if (!this.data.property.agentEstimatePriceFrom || !this.data.property.agentEstimatePriceTo) {
    //   this.commonService.warning('Please input Agent Estimated Price in Agent Agreement block');
    //   return;
    // }
    // if (!this.data.property.commissionRate) {
    //   this.commonService.warning('Please input Commission Rate in Agent Agreement block');
    //   return;
    // }
    this.isEditing = true;
    this.newAgent = new Agent();
    this.newAgent.isNew = true;
  }

  deleteAgent(agent: Agent, group: AgentGroup, index: number) {
    if (agent.isNew) {
      group.agents.splice(index, 1);
      if (group.agents.length === 0) {
        this.deleteAgentGroup(group, true);
      }
      return;
    }
    this.dialogService.open(ConfirmDialogComponent, {
      context: {
        content: 'Do you really want to delete?',
      },
    })
      .onClose.subscribe(res => {
      if (res === ConfirmDialogComponent.confirmOk) {
        this.agentService.remove(agent.agentId).subscribe(
          result => {
            group.agents.splice(index, 1);
            if (group.agents.length === 0) {
              this.deleteAgentGroup(group);
            }
          }, error => {
            this.commonService.warning(error.message);
          });
      }
    });
  }

  deleteAgentGroup(group: AgentGroup, isNew?: boolean) {
    let index;
    if (isNew) {
      index = this.agentGroupList.findIndex(g => g.title === group.title);
      if (index !== -1) {
        this.agentGroupList.splice(index, 1);
      }
      return;
    }
    index = this.agentGroupList.findIndex(g => g.agentGroupId === group.agentGroupId);
    this.agentGroupService.remove(group.agentGroupId).subscribe(r => {
      this.agentGroupList.splice(index, 1);
    });
  }

  get AGENT_TITLE() {
    return Constant.AGENT_TITLE_RENT;
  }

  editDone() {
    // if (!this.validateAgentSplit(this.newAgent)) {
    //   this.commonService.warning('Split must be from 1% to 100%');
    //   return;
    // }
    if (this.selectedTitle === null) {
      this.commonService.warning('Please select a title');
      return;
    }
    const temp = this.agentGroupList.find(group => (group.title === this.selectedTitle));
    if (temp === undefined) {
      if (!this.newAgent.agentSplit) {
        this.newAgent.agentSplit = 100;
      }
      const newGroup = new AgentGroup();
      newGroup.type = 'RENT';
      newGroup.title = this.selectedTitle;
      newGroup.propertyId = this.data.property.propertyId;
      newGroup.split = 100;
      newGroup.agents = [];
      newGroup.agents.push(this.newAgent);
      this.agentGroupList.push(newGroup);
      this.isEditing = false;
      this.newAgentName = '';
    } else {
      // if (!this.validateAgentSplit(this.newAgent, temp.agents)) {
      //   this.commonService.warning('Please adjust ' + temp.title + ' agent split');
      //   return;
      // } else {
        temp.agents.push(this.newAgent);
        this.isEditing = false;
        this.newAgentName = '';
      // }
    }
    this.selectedTitle = null;
  }

  cancelEdit() {
    this.isEditing = false;
    this.selectedTitle = null;
    this.newAgentName = '';
  }
}
