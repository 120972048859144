import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from './auth.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {
  }

  canActivate(root: ActivatedRouteSnapshot, snapshot: RouterStateSnapshot): boolean {
    if (!this.auth.accessToken) {
      this.auth.clearAccessToken();
      if (snapshot.url !== '/') {
        localStorage.setItem('previousUrl', snapshot.url);
      }
      this.router.navigate(['/auth/login']);
      return false;
    }

    // if (!this.hasPermission(root, snapshot)) {
    //   this.redirectDeniedAccess();
    //   return false;
    // }

    return true;
  }

  private redirectDeniedAccess(): void {
    this.router.navigate(['/error'], {queryParams: {code: 403}});
  }

  private hasPermission(root: ActivatedRouteSnapshot, snapshot: RouterStateSnapshot) {
    const permissions: string | any[] = root.data.auth;
    if (!permissions) {
      return true;
    }
    return this.auth.checkPermission(permissions);
  }
}
