import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-user-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  static confirmOk: string = 'CONFIRM_OK';

  @Input()
  title: string = 'Confirmation';

  @Input()
  content: string;

  constructor(
    protected ref: NbDialogRef<ConfirmDialogComponent>,
  ) {
  }

  ngOnInit(): void {
  }

  submit() {
    this.ref.close(ConfirmDialogComponent.confirmOk);
  }

  cancel() {
    this.ref.close();
  }
}
