import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {ViewCell} from 'ng2-smart-table';
import {WrapBuyer} from './buyer-utils';

@Component({
  template: `
    <nb-checkbox *ngIf="rowData.type === 'ITEM'" [(ngModel)]="rowData.selected" (click)="handleClick($event)"
                 (ngModelChange)="handleSelectChange()"></nb-checkbox>
  `,
  styles: [
  ],
})
export class BuyerSelectComponent implements ViewCell, OnInit {

  @Input() value: string | number;
  @Input() rowData: WrapBuyer;
  onSelectBuyer = new EventEmitter<any>();

  ngOnInit() {
  }

  handleSelectChange() {
    if (this.onSelectBuyer) {
      this.onSelectBuyer.emit(this.rowData);
    }
  }

  handleClick($event) {
    $event.stopPropagation();
  }
}
