export class RentalStatistic {
  totalRM?: number;
  totalRN?: number;
  totalRP?: number;
  totalRC?: number;
  totalRA?: number;
  totalR4?: number;
  totalV4?: number;
  totalT4?: number;
  totalTI?: number;
  totalTA?: number;
  totalTC?: number;
  totalTN?: number;
  totalTM?: number;

  constructor() {
    this.totalRM = 0;
    this.totalRN = 0;
    this.totalRP = 0;
    this.totalRC = 0;
    this.totalRA = 0;
    this.totalR4 = 0;
    this.totalV4 = 0;
    this.totalT4 = 0;
    this.totalTI = 0;
    this.totalTA = 0;
    this.totalTC = 0;
    this.totalTN = 0;
    this.totalTM = 0;
  }
}
