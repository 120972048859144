<nb-card *appPermissionCheck="ComponentPermission.DASHBOARD_DUPLICATE">
  <nb-card-header>
    Duplicated Phone Number In Database
  </nb-card-header>
  <nb-card-body>
    <div class='row m-0'>
      <div class="col-md-4 col-12">
        <nb-select fullWidth size="tiny"
                   placeholder="All streets"
                   [(ngModel)]="searchRequest.streetId"
                   [disabled]="!searchRequest?.districtId"
        >
          <nb-option [value]="undefined" (click)='onStreetChange()'>All streets</nb-option>
          <nb-option *ngFor="let key of streets"
                     [ngClass]="key.status === 'DEACTIVE' ? 'deactive-status' : ''"
                     [value]="key.streetId"
                     (click)='onStreetChange()'
          >{{key.name}}</nb-option>
        </nb-select>
      </div>
<!--      <div class="col-md-3 col-12">-->
<!--        <nb-select fullWidth size="tiny"-->
<!--                   placeholder="All farms"-->
<!--                   [(ngModel)]="searchRequest.farmId"-->
<!--                   [disabled]="!searchRequest?.districtId">-->
<!--          <nb-option [value]="undefined" (click)="onFarmSelectChange()">All farms</nb-option>-->
<!--          <nb-option *ngFor="let key of farms"-->
<!--                     [ngClass]="key.status === 'DEACTIVE' ? 'deactive-status' : ''"-->
<!--                     [value]="key.farmId"-->
<!--                     (click)="onFarmSelectChange()"-->
<!--          >{{key.name}}</nb-option>-->
<!--        </nb-select>-->
<!--      </div>-->
      <div class="col-md-4 col-12">
        <nb-select fullWidth size="tiny"
                   placeholder="All suburbs"
                   [(ngModel)]="searchRequest.districtId"
                   [disabled]="!postcode">
          <nb-option [value]="undefined" (click)="onSuburbSelectChange()">All suburbs</nb-option>
          <nb-option *ngFor="let key of districts"
                     [ngClass]="key.status === 'DEACTIVE' ? 'deactive-status' : ''"
                     [value]="key.districtId"
                     (click)="onSuburbSelectChange()"
          >{{key.name}}</nb-option>
        </nb-select>
      </div>
      <div class="col-md-4 col-12">
        <nb-form-field>
          <input nbInput fullWidth placeholder='Postcode' fieldSize='tiny' class='override-nbinput-height'
                 [(ngModel)]='postcode'
                 [typeahead]='filterPostcode$'
                 [ngModelOptions]='{standalone: true}'
                 [typeaheadAsync]='true'
                 (typeaheadOnSelect)='selectPostcode($event)'
                 typeaheadOptionField='code'
                 typeaheadMinLength='3'
                 typeaheadWaitMs='1000'>
          <!--      <span nbSuffix (click)='searchSuburbByPostcode()'><i class='fa fa-search'></i></span>-->
        </nb-form-field>
      </div>
    </div>
    <div class='row ml-0 mr-0 mt-2 mb-2'>
      <div class='col-12'>
        <button nbButton status='primary' size='tiny' class='pull-right' (click)='filter()'>Search</button>
        <button nbButton status='primary' size='tiny' class='pull-right mr-2' (click)='reset()'>Reset</button>
      </div>
    </div>
    <ng2-smart-table [settings]='settings'
                     [source]='source'
                     (userRowSelect)='selectRecord($event)'
    >
    </ng2-smart-table>
  </nb-card-body>
</nb-card>

