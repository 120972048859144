import { ProspectStatisticRequest } from '../prospect/prospect-statistic-request';

export class BuyerSearchRequest {
  fromEnqDate?: number;
  toEnqDate?: number;


  // === for search group items
  day?: number;
  month?: number;
  year?: number;
  week?: number;

  phone?: string;
  code?: string;
  fromMinBuyPrice?: number;
  toMinBuyPrice?: number;
  fromMaxBuyPrice?: number;
  toMaxBuyPrice?: number;
  propertyType?: string;
  bed?: number;
  bath?: number;
  car?: number;

  streetId?: number;
  districtId?: number;
  cityId?: number;
  zoneId?: number;
  postcode?: number;
  userId?: number;

  isBuyerMode?: boolean;
  isTenantMode?: boolean;
  hasCallbackDate?: boolean;

  fromCallbackDate?: number;
  toCallbackDate?: number;

  isActive?: string;
  customerId?: number;

  // === offset, limit of group
  offset?: number;
  limit?: number;
  // === for statistic
  statisticRequest?: ProspectStatisticRequest;

  sell: boolean;

  rent: boolean;
  isFirstHome: boolean;
  isInvestor: boolean;
  isCommon: boolean;
  isAddress: boolean;
  isNumber: boolean;
  isCAN1: boolean;
  isCAN2: boolean;
  hasDistrictId: boolean;
  hasStreetId: boolean;
  hasNumberId: boolean;
  rate: number;
  // isSMode?: boolean;
  isBMode?: boolean;
  categoryIds = [];
  log: string;
  multipleUser: number[];
  hasDob: boolean;
  dobMonth: number;
  dobDay: number;

  // === Filter by office
  officeId: number;

  //  support for bulk save
  buyerIds: number[];

  // support for export email/phone
  customerIds: number[];
}
