import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {CommonService} from '../../../services/common.service';
import {NbDialogRef, NbDialogService} from '@nebular/theme';
import {RestResult} from '../../../models/response/rest-result';
import {SearchRequest} from '../../../models/request/search-request';
import {BuyerService} from '../../../services/buyer.service';
import {MultipleSelectedRequest} from '../../../models/common/multiple-selected-request';
import {Buyer} from '../../../models/response/buyer/buyer';
import {Prospect} from '../../../models/response/prospect/prospect';
import {City} from '../../../models/response/address/city';
import {Company} from '../../../models/response/prospect/company';
import {TaskType} from '../../../models/response/task-type';
import {TaskStatus} from '../../../models/response/task-status';
import {ProspectService} from '../../../services/prospect.service';
import {BuyerCreateComponent} from '../buyer-create.component';
import {ProspectDialogComponent} from '../../prospect-dialog/prospect-dialog.component';
import {CustomerRequest} from '../../../models/request/prospect/customer-request';
import {Customer} from '../../../models/response/prospect/customer';
import { TaskTypeService } from '../../../services/task-type.service';
import { TaskStatusService } from '../../../services/task-status.service';
import { LocalService } from '../../../services/local.service';
import { CompanyService } from '../../../services/company.service';


@Component({
  selector: 'ngx-copy-customers',
  templateUrl: './copy-customers.component.html',
  styleUrls: ['./copy-customers.component.scss'],
})

export class CopyCustomersComponent implements OnInit {
  @ViewChildren('buyerChild') buyerChildList: QueryList<BuyerCreateComponent>;
  @ViewChildren('prospectChild') prospectChildList: QueryList<ProspectDialogComponent>;
  multipleSelectedRequest: MultipleSelectedRequest;
  static SAVE_SUCCESS: string = 'SAVE_SUCCESS';

  cities: City[];
  companies: Company[];
  taskTypes: TaskType[];
  taskStatuses: TaskStatus[];
  searchRequest = new SearchRequest();
  buyers: Buyer[];
  prospects: Prospect[];


  constructor(private dialogService: NbDialogService,
              protected ref: NbDialogRef<CopyCustomersComponent>,
              private commonService: CommonService,
              private prospectService: ProspectService,
              private buyerService: BuyerService,
              private taskTypeService: TaskTypeService,
              private taskStatusService: TaskStatusService,
              private localService: LocalService,
              private companyService: CompanyService) {

  }

  ngOnInit() {
    this.loadRequest(1);
    this.getTaskTypes();
    this.getTaskStatus();
    this.loadForFilter();
  }

  loadRequest(pageIndex) {
    if (!this.multipleSelectedRequest || !this.multipleSelectedRequest.selectedIds) {
      return;
    }
    this.searchRequest.offset = 0;
    this.searchRequest.limit = this.multipleSelectedRequest.selectedIds.length;
    this.prepareRequest();
    this.buyerService.search(this.searchRequest).subscribe((result: RestResult) => {
      this.buyers = result.data;
    });
    this.prospectService.searchDefault(this.searchRequest).subscribe((result: RestResult) => {
      this.prospects = result.data;
    });
  }

  prepareRequest() {
    // init conditions
    if (!this.searchRequest.conditions) {
      this.searchRequest.conditions = new Array();
    }
    this.searchRequest.conditions = new Array();
    this.searchRequest.conditions.push(
      {
        left: 'customerId',
        middle: 'in',
        right: this.multipleSelectedRequest.selectedIds,
        dataType: 'LONG',
      }
    );
    // init orders
    this.searchRequest.orders = new Array();
    this.searchRequest.orders.push(
      {
        left: 'createdDate',
        right: 'desc'
      }
    );
  }

  getTaskTypes() {
    const taskTypeSearchRequest: SearchRequest = new SearchRequest();
    taskTypeSearchRequest.orders = [];
    taskTypeSearchRequest.orders.push(
      {
        left: 'name',
        right: 'asc',
      },
    );
    this.taskTypeService.search(taskTypeSearchRequest).subscribe((result: RestResult) => {
      this.taskTypes = result.data;
    });
  }

  getTaskStatus() {
    const taskStatusSearchRequest: SearchRequest = new SearchRequest();
    taskStatusSearchRequest.orders = [];
    taskStatusSearchRequest.orders.push(
      {
        left: 'name',
        right: 'asc',

      },
    );
    this.taskStatusService.search(taskStatusSearchRequest).subscribe((result: RestResult) => {
      this.taskStatuses = result.data;
    });
  }

  loadForFilter() {
    const searchRequest = new SearchRequest();
    searchRequest.offset = 0;
    searchRequest.limit = 10000;
    searchRequest.orders = new Array();
    searchRequest.orders.push(
      {
        left: 'name',
        right: 'asc',

      },
    );
    this.localService.cities().subscribe((result: City[]) => {
      this.cities = result;
    });
    this.companyService.search(searchRequest).subscribe((result: RestResult) => {
      this.companies = result?.data;
    });
  }

  cancel() {
    this.ref.close();
  }

  save() {
    if (this.buyers) {
      this.buyerChildList.forEach(item => {
        item.submit();
      });
    }
    if (this.prospects) {
      this.prospectChildList.forEach(item => {
        item.submit();
      });
    }
    this.ref.close(CopyCustomersComponent.SAVE_SUCCESS);
  }

  // navigateProspect(value: { currentBuyerId: number, forward: boolean }) {
  // }

  editDone(result: any) {
  }

  newDone(result: any) {
  }

  reserveBuyer(buyer: Buyer) {
  }

  ownSet(buyer: Buyer) {
  }

  copyThisToAll(selectedId: number, customerId: number, type: string) {
    let buyerRequest;
    let prospectRequest;
    let customerRequest;
    // let propertyRequest;
    if (type === 'BUYER' && this.buyers) {
      this.buyerChildList.forEach(item => {
        if (item.buyerId === selectedId) {
          buyerRequest = item.getRequest();
          customerRequest = buyerRequest.customer;
        }
      });
    } else {
      this.prospectChildList.forEach(item => {
        if (item.prospectId === selectedId) {
          prospectRequest = item.getRequest();
          customerRequest = prospectRequest.customer;
        }
      });
    }
    if (this.buyers) {
      this.buyerChildList.forEach(item => {
        if (item.data.buyerId !== selectedId) {
          // get buyer appropriate with child component
          const buyer = this.buyers.find(x => x.buyerId === item.buyerId);
          Customer.copyFromRequest(customerRequest, buyer.customer);
          this.copyOtherData(buyerRequest ? buyerRequest : prospectRequest, buyer);
          item.setBuyer(buyer);
        }
      });
    }
    if (this.prospects) {
      this.prospectChildList.forEach(item => {
        if (item.prospectId !== selectedId) {
          // get buyer appropriate with child component
          const prospect = this.prospects.find(x => x.prospectId === item.prospectId);
          Customer.copyFromRequest(customerRequest, prospect.customer);
          this.copyOtherData(buyerRequest ? buyerRequest : prospectRequest, prospect);
          prospect.isCopy = true;
          item.setProspect(prospect);
        }
      });
    }
  }

  copyOtherData(request: any, entity: any) {
    entity.call = request.call;
    entity.sms = request.sms;
    entity.door = request.door;
    entity.mail = request.mail;
  }

}
