import {Task} from '../task';

export class BuyerTask {
  buyerTaskId: number;
  buyerId: number;
  taskId: number;
  rate: number;
  price: number;
  isOff: boolean;
  isDep: boolean;
  isExc: boolean;
  task: Task;

}
