import {Component, Input, OnInit} from '@angular/core';
import {ViewCell} from 'ng2-smart-table';

@Component({
  template: `
    <nb-checkbox [ngModel]="rowData?.selected"></nb-checkbox>
  `,
})
export class CheckboxCustomCellComponent implements ViewCell, OnInit {
  @Input() value: string | number;
  @Input() rowData: any;

  ngOnInit() {
  }

}
