import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Path} from '../common/path';
import {RestResult} from '../models/response/rest-result';
import {CommonService} from './common.service';
import {SearchRequest} from '../models/request/search-request';
import {BlogRequest} from '../models/request/sos/blog-request';

@Injectable()
export class BlogService {

  constructor(protected http: HttpClient,
              protected commonService: CommonService) {
  }

  getOne(blogId: number): Observable<RestResult> {
    return this.http.get<any>(
      Path.API_ENDPOINT +
      Path.BLOG +
      Path.BLOG_ID.replace(':blogId', blogId.toString())
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError)
    );
  }

  search(request: SearchRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.BLOG +
      Path.SEARCH, request
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError)
    );
  }

  create(request: BlogRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.BLOG, request).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError)
    );
  }

  remove(blogId: number): Observable<RestResult> {
    return this.http.delete<any>(
      Path.API_ENDPOINT +
      Path.BLOG +
      Path.BLOG_ID.replace(':blogId', blogId.toString())
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError)
    );
  }

  update(request: BlogRequest): Observable<RestResult> {
    return this.http.put<any>(
      Path.API_ENDPOINT +
      Path.BLOG, request).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError)
    );
  }
}
