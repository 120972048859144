import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NbDialogRef} from '@nebular/theme';
import {CommonService} from '../../../../../shared/services/common.service';
import {FormService} from '../../../../../shared/services/form.service';
import {Company} from '../../../../../shared/models/response/prospect/company';
import {CompanyService} from '../../../../../shared/services/company.service';
import { ComponentPermission } from '../../../../../component.permission';
import { Constant } from '../../../../../shared/common/constant';

@Component({
  selector: 'ngx-prospect-company-update',
  templateUrl: './prospect-company-update.component.html',
  styleUrls: ['./prospect-company-update.component.scss'],
})
export class ProspectCompanyUpdateComponent implements OnInit {

  ComponentPermission = ComponentPermission;
  form: FormGroup;
  isSubmitted = false;

  @Input()
  data: Company;

  constructor(protected ref: NbDialogRef<ProspectCompanyUpdateComponent>,
              private companyService: CompanyService,
              private commonService: CommonService,
              private formService: FormService) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.form = new FormGroup({
      companyId: new FormControl(this.data.companyId, [
        Validators.required,
      ]),
      name: new FormControl(this.data.name, [
        Validators.required,
      ]),
      address: new FormControl(this.data.address),
      phone: new FormControl(this.data.phone),
      email: new FormControl(this.data.email),
      type: new FormControl(this.data.type),
    });
  }

  submit() {
    const self = this;
    const form = this.form.value;
    this.formService.extractErrorMessage(this.form);
    if (this.form.invalid) {
      this.isSubmitted = true;
      return;
    }
    this.companyService.update(form).subscribe(
      result => {
        this.commonService.info('Update success.');
        this.ref.close(result);
      },
      error => {
        this.commonService.warningHtml(error.message);
      },
    );
  }

  cancel() {
    this.ref.close();
  }

  showError(name: string): string {
    return this.form.controls[name]
    && this.form.controls[name].errors
    && (this.form.controls[name].dirty
      || this.form.controls[name].touched
      || this.isSubmitted) ? 'has-error' : '';
  }

  get COMPANY_TYPE() {
    return Constant.COMPANY_TYPE;
  }
}
