<nb-card>
  <nb-card-header>
    Adjust Time Open Home
  </nb-card-header>
  <nb-card-body>
    <div [formGroup]='form'>
      <div class='row m-0 mt-2'>
        <div class='col-12'>
          <input nbInput fullWidth fieldSize='small' fullWidth placeholder='Date' formControlName='time' [nbDatepicker]='eTime'>
          <nb-datepicker #eTime [filter]='filterFn'></nb-datepicker>
        </div>
      </div>
      <div class='row m-0 mt-2'>
        <div class='col-4'>
          <nb-select class='col-4' formControlName='hour'
                     placeholder='Hour' [ngClass]="showErrorInput('sendHour')">
            <nb-option *ngFor='let item of timeRange' [value]='item'>{{item}}</nb-option>
          </nb-select>
        </div>
        <div class='col-4'>
          <nb-select formControlName='minute'
                     placeholder='Minute' [ngClass]="showErrorInput('sendMinute')">
            <nb-option *ngFor='let item of minuteRange' [value]='item'>{{item}}</nb-option>
          </nb-select>
        </div>
        <div class='col-4'>
          <nb-select formControlName='hourType'
                     placeholder='Type'
                     [ngClass]="showErrorInput('hourType')">
            <nb-option *ngFor='let item of hourTypes' [value]='item'>{{item}}</nb-option>
          </nb-select>
        </div>
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer class='dialog-footer'>
    <button class='cancel fa-pull-right' nbButton status='danger' size='tiny' (click)='cancel()'>Cancel</button>
    <button nbButton class='fa-pull-right mr-2' status='success' size='tiny' (click)='submit()'>Save</button>
  </nb-card-footer>
</nb-card>
