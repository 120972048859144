import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from './common.service';
import { Observable } from 'rxjs';
import { RestResult } from '../models/response/rest-result';
import { Path } from '../common/path';
import { catchError, map } from 'rxjs/operators';
import { SearchRequest } from '../models/request/search-request';
import { VendorRequest } from '../models/request/prospect/vendor-request';

@Injectable()
export class VendorService {
  constructor(protected http: HttpClient,
              protected commonService: CommonService) {
  }

  getOne(vendorId: number): Observable<RestResult> {
    return this.http.get<any>(
      Path.API_ENDPOINT +
      Path.VENDOR +
      Path.VENDOR_ID.replace(':vendorId', vendorId.toString()),
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }


  search(request: SearchRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.VENDOR +
      Path.SEARCH, request,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  register(request: VendorRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.VENDOR +
      Path.REGISTER, request).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  remove(vendorId: number): Observable<RestResult> {
    return this.http.delete<any>(
      Path.API_ENDPOINT +
      Path.VENDOR +
      Path.VENDOR_ID.replace(':vendorId', vendorId.toString()),
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }
}
