import {AfterViewChecked, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProspectUtils, WrapProspect, WrapProspectType} from '../../prospect/common/prospect-utils';
import {ProspectDatasource} from '../../prospect/common/prospect-datasource';
import {ProspectSearchRequest} from '../../../../shared/models/request/prospect/prospect-search-request';
import {Constant} from '../../../../shared/common/constant';
import {DataSourceUtil} from '../../../../shared/models/utils/datasource-util';
import {ProspectService} from '../../../../shared/services/prospect.service';
import {VisualBoardAgentCellComponent} from '../../prospect/common/visual-board-agent-cell-component';
import {VisualBoardPriceCellComponent} from '../../prospect/common/visual-board-price-cell-component';
import {VisualBoardOtmDateCellComponent} from '../../prospect/common/visual-board-otm-date-cell-component';

import {City} from '../../../../shared/models/response/address/city';
import {District} from '../../../../shared/models/response/address/district';
import {LocalService} from '../../../../shared/services/local.service';
import {Prospect} from '../../../../shared/models/response/prospect/prospect';
import {ProspectDialogComponent} from '../../../../shared/module/prospect-dialog/prospect-dialog.component';
import {NbDialogRef, NbDialogService} from '@nebular/theme';
import {VisualBoardOpenHomeComponent} from '../../prospect/common/visual-board-open-home-component';
import {VisualBoardClosingGapComponent} from '../../prospect/common/visual-board-closing-gap-component';
import {VisualBoardAddressCellComponent} from '../../prospect/common/visual-board-address-cell-component';
import {SearchRequest} from '../../../../shared/models/request/search-request';
import {RestResult} from '../../../../shared/models/response/rest-result';
import {CompanyService} from '../../../../shared/services/company.service';
import {TaskTypeService} from '../../../../shared/services/task-type.service';
import {TaskStatusService} from '../../../../shared/services/task-status.service';
import {TaskType} from '../../../../shared/models/response/task-type';
import {Company} from '../../../../shared/models/response/prospect/company';
import {TaskStatus} from '../../../../shared/models/response/task-status';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {Postcode} from '../../../../shared/models/response/postcode';
import {PostcodeService} from '../../../../shared/services/postcode.service';
import {map} from 'rxjs/operators';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead';
import {StringUtils} from '../../../../shared/common/string-utils';
import {Street} from '../../../../shared/models/response/address/street';

@Component({
  selector: 'ngx-visual-board',
  templateUrl: './visual-board.component.html',
  styleUrls: ['./visual-board.component.scss'],
})
export class VisualBoardComponent implements OnInit, AfterViewChecked {
  searchRequest = new ProspectSearchRequest();
  pageSize = Constant.PAGE_SIZE;
  currentPage = 1;
  change: any;
  isFilter = false;
  cities: City[] = [];
  districts: District[] = [];
  streets: Street[] = [];
  selectedProspect: Prospect;
  param = {};
  dialogFormRef: NbDialogRef<ProspectDialogComponent>;
  source = new ProspectDatasource();
  visualBoardButtons = [
    {value: 'S4', selected: false},
    {value: 'O4', selected: false},
    {value: 'R4', selected: false},
    {value: 'T10-S3', selected: false},
    {value: '1M', selected: false},
    {value: '3M', selected: false},
    {value: '6M', selected: false},
    {value: 'S3', selected: false},
    {value: 'SO3', selected: false},
    {value: 'S2M', selected: false},
    {value: 'SO2M', selected: false},
    {value: 'S2H', selected: false},
    {value: 'SO2H', selected: false},
    {value: 'SO1', selected: false},
    {value: 'SR3', selected: false},
    {value: 'SRV3', selected: false},
    {value: 'SRV4', selected: false},
  ];
  propertyTypeReplacement = {
    LAND_ONLY: 'L',
    UNIT: 'U',
    VILLA: 'V',
    PENTHOUSE: 'P',
    TOWN_HOUSE: 'T',
    HOUSE: 'H',
    HOUSE_GF: 'HG',
    DUPLEX: 'D',
    HOUSE_COM: 'HC',
  };
  columnsDefinition = {
    address: {
      enable: true,
      value: ProspectUtils.createColumn('Address',
        null, null, ', ', null, VisualBoardAddressCellComponent),
      name: 'Address',
    },
    type: {
      enable: true,
      value: ProspectUtils.createCustomColumn('PT', ['property.type'], null, null, this.propertyTypeReplacement),
      name: 'PT',
    },
    bed: {
      enable: true,
      value: ProspectUtils.createCustomColumn('Bed', ['property.bed'], null, null),
      name: 'Bed',
    },
    bath: {
      enable: true,
      value: ProspectUtils.createCustomColumn('Bath', ['property.bath'], null, null),
      name: 'Bath',
    },
    car: {
      enable: true,
      value: ProspectUtils.createCustomColumn('Car', ['property.car'], null, null),
      name: 'Car',
    },
    land: {
      enable: true,
      value: ProspectUtils.createColumn('Land', ['property.land'], null, null),
      name: 'Land',
    },
    openHome: {
      enable: false,
      value: ProspectUtils.createColumn('Open Home', ['open'], null, null, null, VisualBoardOpenHomeComponent),
      name: 'Open Home',
    },
    askingPrice: {
      enable: true,
      value: ProspectUtils.createColumn('Asking Price', ['property.askingPrice'], null, null),
      name: 'Asking Price',
    },
    rentPrice: {
      enable: false,
      value: ProspectUtils.createColumn('Rent Price', ['property.rentPrice'], 'CURRENCY', null),
      name: 'Rent Price',
    },
    managementFee: {
      enable: false,
      value: ProspectUtils.createColumn('Management Fee', ['property.managementFee'], 'CURRENCY', null),
      name: 'Management Fee',
    },
    weeklyCommission: {
      enable: false,
      value: (ProspectUtils.createColumn('Weekly Commission', ['property.weeklyCommission'], 'CURRENCY', null)),
      name: 'Weekly Com',
    },
    monthlyCommission: {
      enable: false,
      value: (ProspectUtils.createColumn('Monthly Commission', ['property.monthlyCommission'], 'CURRENCY', null)),
      name: 'Monthly Com',
    },
    yearlyCommission: {
      enable: false,
      value: (ProspectUtils.createColumn('Yearly Commission', ['property.yearlyCommission'], 'CURRENCY', null)),
      name: 'Yearly Com',
    },
    rentRollValue: {
      enable: false,
      value: (ProspectUtils.createColumn('Rent Roll Value', ['property.rentRollValue'], 'CURRENCY', null)),
      name: 'Rent Roll Value',
    },
    commissionPrice: {
      enable: false,
      value: ProspectUtils.createColumn('Commission Price', null, null, null, null, VisualBoardPriceCellComponent),
      name: 'Commission Price',
    },
    closingGap: {
      enable: true,
      value: ProspectUtils.createColumn('Closing Gap', null, null, null, null, VisualBoardClosingGapComponent),
      name: 'Closing Gap',
    },
    offerPrice: {
      enable: true,
      value: ProspectUtils.createColumn('Offer Price', null, null, null, null, VisualBoardPriceCellComponent),
      name: 'Offer Price',
    },
    commissionRate: {
      enable: true,
      value: ProspectUtils.createColumn('Commission Rate', ['property.commissionRate'], null, null),
      name: 'Commission Rate',
    },
    vpa: {
      enable: true,
      value: ProspectUtils.createColumn('VPA', ['property.vpaTotal'], null, null),
      name: 'VPA',
    },
    leadGeneration: {
      enable: true,
      value: ProspectUtils.createColumn('Lead Generation', null, null, null, null, VisualBoardAgentCellComponent),
      name: 'Lead Generation',
    },
    bdm: {
      enable: false,
      value: ProspectUtils.createColumn('BDM', null, null, null, null, VisualBoardAgentCellComponent),
      name: 'BDM',
    },
    listingAgent: {
      enable: true,
      value: ProspectUtils.createColumn('Listing Agent', null, null, null, null, VisualBoardAgentCellComponent),
      name: 'Listing Agent',
    },
    saleAgent: {
      enable: true,
      value: ProspectUtils.createColumn('Sale Agent', null, null, null, null, VisualBoardAgentCellComponent),
      name: 'Sale Agent',
    },
    propertyManager: {
      enable: false,
      value: ProspectUtils.createColumn('PM', null, null, null, null, VisualBoardAgentCellComponent),
      name: 'PM',
    },
    otmDate: {
      enable: false,
      value: ProspectUtils.createColumn('Otm Date', null, null, null, null, VisualBoardOtmDateCellComponent),
      name: 'Otm Date',
    },
  };

  settings = {
    mode: 'external',
    hideSubHeader: true,
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    pager: {
      display: true,
      perPage: 10000,
    },
    columns: this.createColumns(),
    rowClassFunction: (row) => {
      return this.highlightRow(row.data);
    },
  };

  createColumns() {
    const columns = {};
    for (const key of Object.keys(this.columnsDefinition)) {
      if (this.columnsDefinition[key].enable === true) {
        columns[key] = this.columnsDefinition[key].value;
      }
    }
    return columns;
  }

  taskTypes: TaskType[];
  companies: Company[];
  taskStatuses: TaskStatus[];
  @Input() postcode: number;
  @Output() onSearchByPostcode = new EventEmitter();
  filterByPostcode = false;
  filterPostcode$: Observable<Postcode[]>;
  isR4 = false;
  isAvg = false;

  constructor(private prospectService: ProspectService,
              private localService: LocalService,
              private dialogService: NbDialogService,
              private companyService: CompanyService,
              private taskTypeService: TaskTypeService,
              private taskStatusService: TaskStatusService,
              private postcodeService: PostcodeService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    if (this.visualBoardButtons) {
      if (this.route.snapshot.queryParamMap.get('selectedBoard')) {
        if (this.route.snapshot.queryParamMap.get('isR4')) {
          if (this.route.snapshot.queryParamMap.get('isR4') === 'true') {
            this.isR4 = true;
          }
        }
        const title = this.route.snapshot.queryParamMap.get('selectedBoard');
        const button = this.visualBoardButtons.find(b => b.value === title);
        this.onSelectBoard(button);
      } else {
        this.onSelectBoard(this.visualBoardButtons[0]);
      }
    }
    this.source.onChanged().subscribe((change) => {
      this.change = change;
      if (change.action === 'refresh') {
        this.pageChange(1, this.isR4);
      }
      if (change.action === 'page') {
        this.pageChange(change.paging.page, this.isR4);
      }
    });
    this.localService.cities().subscribe(result => {
      this.cities = result;
    });
    this.loadForFilter();
    this.getTaskTypes();
    this.getTaskStatus();
    if (this.route.snapshot.queryParams['postcode']) {
      this.filterByPostcode = true;
    }
    this.filterPostcode$ = new Observable((observer: any) => {
      observer.next(this.postcode);
    }).pipe(
      map((query: string) => {
        this.postcode = Number(query);
        this.searchSuburbByPostcode();
        return null;
      }),
    );
  }

  ngAfterViewChecked() {
    document.getElementsByClassName('customPT').item(0).innerHTML = '<i class="fa fa-home label-icon">';
    document.getElementsByClassName('customBed').item(0).innerHTML = '<i class="fa fa-bed label-icon">';
    document.getElementsByClassName('customBath').item(0).innerHTML = '<i class="fa fa-bath label-icon">';
    document.getElementsByClassName('customCar').item(0).innerHTML = '<i class="fa fa-car label-icon">';
  }

  onSelectBoard(button: any) {
    this.visualBoardButtons.forEach(b => {
      b.selected = b.value === button.value;
    });
    if (button.value === 'S4' || button.value === 'O4' || button.value === 'R4' || button.value === 'SRV4') {
      this.handleToggleLevel4Columns(button.value);
    }
    if (button.value === 'SO1' || button.value === 'SO2H' || button.value === 'SO2M' || button.value === 'SO3'
      || button.value === 'S2M' || button.value === 'S2H') {
      this.handleToggleOtmDateColumn(button.value);
    }
    if (button.value === 'T10-S3' || button.value === '1M'
      || button.value === '3M' || button.value === '6M' || button.value === 'S3'
      || button.value === 'SO3' || button.value === 'SR3' || button.value === 'SRV3') {
      this.handleToggleLevel3Columns(button.value);
    }
    this.prepareSearchRequest(button.value);

    this.router.navigate([], {queryParams: {selectedBoard: button.value, isR4: this.isR4}});
  }

  prepareSearchRequest(board: string) {
    this.resetSearchRequest();
    switch (board) {
      case 'S4':
        this.searchRequest.isS4Board = true;
        this.isR4 = false;
        break;
      case 'O4':
        this.searchRequest.isO4Board = true;
        this.searchRequest.sortType = 'ASC';
        this.isR4 = false;
        break;
      case 'R4':
        this.searchRequest.isR4Board = true;
        this.isR4 = true;
        break;
      case 'S3':
        this.searchRequest.sell = true;
        this.searchRequest.rate = 3;
        this.isR4 = false;
        break;
      case 'T10-S3':
        this.searchRequest.sell = true;
        this.searchRequest.rate = 3;
        this.searchRequest.categoryIds = [1];
        this.isR4 = false;
        break;
      case '1M':
        this.searchRequest.categoryIds = [5];
        this.isR4 = false;
        break;
      case '3M':
        this.searchRequest.categoryIds = [6];
        this.isR4 = false;
        break;
      case '6M':
        this.searchRequest.categoryIds = [7];
        this.isR4 = false;
        break;
      case 'S2H':
        this.searchRequest.rate = 2;
        this.searchRequest.sell = true;
        this.searchRequest.hasPhone = true;
        this.isR4 = false;
        break;
      case 'S2M':
        this.searchRequest.rate = 2;
        this.searchRequest.sell = true;
        this.searchRequest.hasMobilePhone = true;
        this.isR4 = false;
        break;
      case 'SO2H':
        this.searchRequest.rate = 2;
        this.searchRequest.sell = true;
        this.searchRequest.otm = true;
        this.searchRequest.hasPhone = true;
        this.isR4 = false;
        break;
      case 'SO2M':
        this.searchRequest.rate = 2;
        this.searchRequest.sell = true;
        this.searchRequest.otm = true;
        this.searchRequest.hasMobilePhone = true;
        this.isR4 = false;
        break;
      case 'SO1':
        this.searchRequest.rate = 1;
        this.searchRequest.sell = true;
        this.searchRequest.otm = true;
        this.isR4 = false;
        break;
      case 'SO3':
        this.searchRequest.rate = 3;
        this.searchRequest.sell = true;
        this.searchRequest.otm = true;
        this.isR4 = false;
        break;
      case 'SR3':
        this.searchRequest.rate = 3;
        this.searchRequest.sell = true;
        this.searchRequest.rent = true;
        this.isR4 = false;
        break;
      case 'SRV3':
        this.searchRequest.rate = 3;
        this.searchRequest.sell = true;
        this.searchRequest.rent = true;
        this.searchRequest.vac = true;
        this.isR4 = false;
        break;
      case 'SRV4':
        this.searchRequest.rate = 4;
        this.searchRequest.sell = true;
        this.searchRequest.rent = true;
        this.searchRequest.vac = true;
        this.isR4 = false;
        break;
    }
    this.pageChange(1, this.isR4);
  }

  pageChange(pageIndex, isR4?: boolean) {
    this.currentPage = pageIndex;
    this.searchRequest.offset = (pageIndex - 1) * this.pageSize;
    if (this.searchRequest.offset < 0) {
      this.searchRequest.offset = 0;
    }
    let tempData: Prospect[] = [];
    if (isR4 && isR4 === true) {
      this.searchRequest.limit = 10000;
    }
    this.prospectService.getVisualBoard(this.searchRequest).subscribe(result => {
      let newData: any = {
        property: {
          rentPrice: 0,
          managementFee: 0,
          weeklyCommission: 0,
          monthlyCommission: 0,
          yearlyCommission: 0,
          rentRollValue: 0,
        }
      };
      if (isR4 && isR4 === true) {
        tempData = result.data;
        let totalWeeklyCom = 0;
        let totalMonthlyCom = 0;
        let totalYearlyCom = 0;
        let totalRentRollValue = 0;
        let totalRentPrice = 0;
        let totalManagementFee = 0;
        let total = 0;
        for (let i = 0; i < tempData?.length; i++) {
          const x = tempData[i];
          if (x.property.managementFee === null || isNaN(x.property.managementFee)
            || x.property.rentPrice === null || isNaN(x.property.rentPrice)) {
            continue;
          } else {
            totalRentPrice += x.property.rentPrice;
            totalManagementFee += x.property.managementFee;
            totalWeeklyCom += x.property.weeklyCommission;
            totalMonthlyCom += x.property.monthlyCommission;
            totalYearlyCom += x.property.yearlyCommission;
            totalRentRollValue += x.property.rentRollValue;
            total += 1;
          }
        }
        const property = {
          rentPrice: Number((totalRentPrice / total).toFixed(2)),
          managementFee: Number((totalManagementFee / total).toFixed(2)),
          weeklyCommission: totalWeeklyCom.toFixed(2),
          monthlyCommission: totalMonthlyCom.toFixed(2),
          yearlyCommission: totalYearlyCom.toFixed(2),
          rentRollValue: totalRentRollValue.toFixed(2),
        };
        newData.property = property;
      } else {
        newData = null;
      }
      if (isR4 === true && pageIndex === 1) {
        this.searchRequest.limit = this.pageSize;
        this.prospectService.getVisualBoard(this.searchRequest).subscribe(r => {
          DataSourceUtil.reloadDataProspectListing(this.source, pageIndex, this.pageSize, r, newData);
        });
      }
    });
    if (!isR4 || pageIndex !== 1) {
      this.searchRequest.limit = this.pageSize;
      this.prospectService.getVisualBoard(this.searchRequest).subscribe(result => {
        DataSourceUtil.reloadDataProspectListing(this.source, pageIndex, this.pageSize, result);
      });
    }
  }

  resetSearchRequest() {
    this.searchRequest.sell = null;
    this.searchRequest.otm = null;
    this.searchRequest.categoryIds = null;
    this.searchRequest.rate = null;
    this.searchRequest.hasPhone = null;
    this.searchRequest.hasMobilePhone = null;
    this.searchRequest.cityId = null;
    this.searchRequest.districtId = null;
    this.searchRequest.isO4Board = null;
    this.searchRequest.isS4Board = null;
    this.searchRequest.isR4Board = null;
  }

  handleToggleLevel4Columns(value: string) {
    if (value === 'S4' || value === 'O4' || value === 'R4' || value === 'SRV4') {
      this.columnsDefinition.offerPrice.enable = true;
      this.columnsDefinition.commissionRate.enable = true;
      this.columnsDefinition.vpa.enable = true;
      this.columnsDefinition.leadGeneration.enable = true;
      this.columnsDefinition.listingAgent.enable = true;
      this.columnsDefinition.saleAgent.enable = true;
      this.columnsDefinition.closingGap.enable = true;
      this.columnsDefinition.openHome.enable = false;
      this.columnsDefinition.rentPrice.enable = false;
      this.columnsDefinition.askingPrice.enable = true;
      this.columnsDefinition.offerPrice.enable = true;
      this.columnsDefinition.managementFee.enable = false;
      this.columnsDefinition.weeklyCommission.enable = false;
      this.columnsDefinition.monthlyCommission.enable = false;
      this.columnsDefinition.yearlyCommission.enable = false;
      this.columnsDefinition.rentRollValue.enable = false;
      this.columnsDefinition.propertyManager.enable = false;
      this.columnsDefinition.bdm.enable = false;
      if (value === 'O4') {
        this.columnsDefinition.openHome.enable = true;
        this.columnsDefinition.managementFee.enable = false;
        this.columnsDefinition.weeklyCommission.enable = false;
        this.columnsDefinition.monthlyCommission.enable = false;
        this.columnsDefinition.yearlyCommission.enable = false;
        this.columnsDefinition.rentRollValue.enable = false;
        this.columnsDefinition.propertyManager.enable = false;
        this.columnsDefinition.bdm.enable = false;
      }
      if (value === 'R4') {
        this.columnsDefinition.rentPrice.enable = true;
        this.columnsDefinition.askingPrice.enable = false;
        this.columnsDefinition.closingGap.enable = false;
        this.columnsDefinition.offerPrice.enable = false;
        this.columnsDefinition.commissionRate.enable = false;
        this.columnsDefinition.commissionPrice.enable = false;
        this.columnsDefinition.otmDate.enable = false;
        this.columnsDefinition.vpa.enable = false;
        this.columnsDefinition.managementFee.enable = true;
        this.columnsDefinition.weeklyCommission.enable = true;
        this.columnsDefinition.monthlyCommission.enable = true;
        this.columnsDefinition.yearlyCommission.enable = true;
        this.columnsDefinition.rentRollValue.enable = true;
        this.columnsDefinition.listingAgent.enable = false;
        this.columnsDefinition.leadGeneration.enable = false;
        this.columnsDefinition.bdm.enable = true;
        this.columnsDefinition.propertyManager.enable = true;
        this.columnsDefinition.saleAgent.enable = false;
      }
      this.isFilter = false;
    } else {
      this.columnsDefinition.offerPrice.enable = false;
      this.columnsDefinition.commissionRate.enable = false;
      this.columnsDefinition.vpa.enable = false;
      this.columnsDefinition.leadGeneration.enable = false;
      this.columnsDefinition.listingAgent.enable = false;
      this.columnsDefinition.saleAgent.enable = false;
      this.columnsDefinition.closingGap.enable = false;
      this.isFilter = true;
      this.columnsDefinition.openHome.enable = false;
      this.columnsDefinition.managementFee.enable = false;
      this.columnsDefinition.weeklyCommission.enable = false;
      this.columnsDefinition.monthlyCommission.enable = false;
      this.columnsDefinition.yearlyCommission.enable = false;
      this.columnsDefinition.rentRollValue.enable = false;
      this.columnsDefinition.propertyManager.enable = false;
      this.columnsDefinition.bdm.enable = false;
    }
    this.searchRequest.streetId = undefined;
    this.searchRequest.districtId = undefined;
    this.searchRequest.postcode = undefined;
    this.postcode = undefined;
    const settings = {...this.settings};
    settings.columns = this.createColumns();
    this.settings = settings;
  }

  handleToggleOtmDateColumn(value: string) {
    this.columnsDefinition.otmDate.enable = value === 'SO1' || value === 'SO2H' || value === 'SO2M' || value === 'SO3' || value === 'S2M' || value === 'S2H';
    this.columnsDefinition.askingPrice.enable = true;
    this.columnsDefinition.offerPrice.enable = true;
    this.columnsDefinition.closingGap.enable = true;
    this.columnsDefinition.rentPrice.enable = false;
    this.columnsDefinition.managementFee.enable = false;
    this.columnsDefinition.weeklyCommission.enable = false;
    this.columnsDefinition.monthlyCommission.enable = false;
    this.columnsDefinition.yearlyCommission.enable = false;
    this.columnsDefinition.rentRollValue.enable = false;
    this.columnsDefinition.propertyManager.enable = false;
    this.columnsDefinition.bdm.enable = false;
    const settings = {...this.settings};
    settings.columns = this.createColumns();
    this.settings = settings;
  }

  handleToggleLevel3Columns(value: string) {
    this.columnsDefinition.commissionPrice.enable = value === 'T10-S3' || value === '1M'
      || value === '3M' || value === '6M' || value === 'S3' || value === 'SO3' || value === 'SR3' || value === 'SRV3';
    this.columnsDefinition.rentPrice.enable = false;
    this.columnsDefinition.askingPrice.enable = true;
    this.columnsDefinition.offerPrice.enable = true;
    this.columnsDefinition.closingGap.enable = true;
    this.columnsDefinition.managementFee.enable = false;
    this.columnsDefinition.weeklyCommission.enable = false;
    this.columnsDefinition.monthlyCommission.enable = false;
    this.columnsDefinition.yearlyCommission.enable = false;
    this.columnsDefinition.rentRollValue.enable = false;
    this.columnsDefinition.bdm.enable = false;
    this.columnsDefinition.propertyManager.enable = false;
    const settings = {...this.settings};
    settings.columns = this.createColumns();
    this.settings = settings;
  }

  onCitySelectChange() {
    const cityId = this.searchRequest.cityId;
    this.searchRequest.districtId = null;
    if (cityId) {
      this.localService.districts(cityId).subscribe(result => {
        this.districts = result;
      });
    }
  }

  highlightRow(data: any) {
    let result = '';
    const prospect = data.data;
    if (prospect?.otmDayDiff > 90 && prospect?.otmStatus === 'EXPIRED') {
      result += ' otm-expired ';
    }
    if (prospect?.property?.sell === true && (prospect?.rate === 5 || prospect?.rate === 6)) {
      result += ' s5 ';
    }
    return result;
  }

  selectRecord(event: any) {
    if (event.data.data.prospectId) {
      this.selectedProspect = event.data.data;
      this.editRecord(this.selectedProspect);
    }
  }

  editRecord(prospect: Prospect) {
    this.dialogFormRef = this.dialogService.open(ProspectDialogComponent, {
      context: {
        selectedProspect: prospect,
        isModal: true,
        taskTypes: this.taskTypes,
        taskStatuses: this.taskStatuses,
        companies: this.companies,
        onClose: (result) => {
          if (this.dialogFormRef) {
            this.dialogFormRef.close(result);
          }
        },
      },
      dialogClass: 'custom-visual-size',
    });
  }

  loadForFilter() {
    const searchRequest = new SearchRequest();
    searchRequest.offset = 0;
    searchRequest.limit = 10000;
    searchRequest.orders = new Array();
    searchRequest.orders.push(
      {
        left: 'name',
        right: 'asc',

      },
    );
    this.localService.cities().subscribe((result: City[]) => {
      this.cities = result;
    });
    this.companyService.search(searchRequest).subscribe((result: RestResult) => {
      this.companies = result?.data;
    });
  }

  getTaskTypes() {
    const taskTypeSearchRequest: SearchRequest = new SearchRequest();
    taskTypeSearchRequest.orders = [];
    taskTypeSearchRequest.orders.push(
      {
        left: 'name',
        right: 'asc',
      },
    );
    this.taskTypeService.search(taskTypeSearchRequest).subscribe((result: RestResult) => {
      this.taskTypes = result.data;
    });
  }

  getTaskStatus() {
    const taskStatusSearchRequest: SearchRequest = new SearchRequest();
    taskStatusSearchRequest.orders = [];
    taskStatusSearchRequest.orders.push(
      {
        left: 'name',
        right: 'asc',

      },
    );
    this.taskStatusService.search(taskStatusSearchRequest).subscribe((result: RestResult) => {
      this.taskStatuses = result.data;
    });
  }

  searchPostcode(keyword
                   :
                   string
  ):
    Observable<Postcode[]> {
    const searchRequest = new SearchRequest();
    searchRequest.conditions = [];
    searchRequest.orders = [];
    searchRequest.conditions.push(
      {
        left: 'code',
        middle: '=',
        right: keyword,
      },
    );
    searchRequest.orders.push(
      {
        left: 'code',
        right: 'asc'
      }
    );
    searchRequest.offset = 0;
    searchRequest.limit = 10;
    return this.postcodeService.search(searchRequest).pipe(
      map(result => result.data),
    );
  }

  selectPostcode(type
                   :
                   TypeaheadMatch
  ) {
    this.postcode = Number(type.item.code);
    this.searchSuburbByPostcode();
  }

  searchSuburbByPostcode() {
    if (this.postcode === null) {
      return;
    }
    this.filterByPostcode = true;
    if (this.postcode === 0) {
      this.searchRequest.postcode = undefined;
      this.searchRequest.districtId = undefined;
      this.searchRequest.streetId = undefined;
    } else {
      this.searchRequest.postcode = Number(this.postcode);
    }
    this.localService.districtByPostcode(this.postcode).subscribe(result => {
      this.districts = result;
    });
  }

  onSuburbChange(districtId) {
    this.searchRequest.districtId = districtId;
    this.searchRequest.streetId = undefined;
    if (districtId) {
      this.localService.streets(districtId).subscribe(result => {
        this.streets = result;
      });
    }
  }
}
