<div class='col-12 row d-flex mt-2'>
  <div class='col-xl-4 col-lg-4 col-sm-3 col-12 override-width override-width-md mt-2'>
    <nb-select placeholder='All streets' fullWidth size='tiny' [(ngModel)]='searchRequest.streetId'
               [disabled]='!searchRequest?.districtId'>
      <nb-option [value]='undefined' (click)='onStreetSelectChange()'>All streets</nb-option>
      <nb-option *ngFor='let key of streets'
                 [value]='key.streetId'
                 [ngClass]="key.status === 'DEACTIVE' ? 'deactive-status' : ''"
                 (click)='onStreetSelectChange()'>{{key.name}}</nb-option>
    </nb-select>
  </div>
<!--  <div class='col-xl-3 col-lg-3 col-sm-3 col-12 override-width override-width-md mt-2'>-->
<!--    <nb-select placeholder='All farms' fullWidth size='tiny' [(ngModel)]='searchRequest.farmId'-->
<!--               [disabled]='!searchRequest?.districtId'>-->
<!--      <nb-option [value]='undefined' (click)='onFarmSelectChange()'>All farms</nb-option>-->
<!--      <nb-option *ngFor='let key of farms'-->
<!--                 [value]='key.farmId'-->
<!--                 [ngClass]="key.status === 'DEACTIVE' ? 'deactive-status' : ''"-->
<!--                 (click)='onFarmSelectChange()'>{{key.name}}</nb-option>-->
<!--    </nb-select>-->
<!--  </div>-->
  <div class='col-xl-4 col-lg-4 col-sm-3 col-12 override-width override-width-md mt-2'>
    <nb-select placeholder='All suburbs' fullWidth size='tiny' [(ngModel)]='searchRequest.districtId'
               [disabled]='!postcode'>
      <nb-option [value]='undefined' (click)='onSuburbSelectChange()'>All suburbs</nb-option>
      <nb-option *ngFor='let key of districts'
                 [value]='key.districtId'
                 [ngClass]="key.status === 'DEACTIVE' ? 'deactive-status' : ''"
                 (click)='onSuburbSelectChange()'>{{key.name}}</nb-option>
    </nb-select>
  </div>
  <div class='col-xl-4 col-lg-4 col-sm-3 col-12 override-width override-width-md mt-2'>
    <nb-form-field>
      <input nbInput fullWidth placeholder='Postcode' fieldSize='tiny' class='override-nbinput-height'
             [(ngModel)]='postcode'
             [typeahead]='filterPostcode$'
             [ngModelOptions]='{standalone: true}'
             [typeaheadAsync]='true'
             (typeaheadOnSelect)='selectPostcode($event)'
             typeaheadOptionField='code'
             typeaheadMinLength='0'
             typeaheadWaitMs='1000'>
      <!--      <span nbSuffix (click)='searchSuburbByPostcode()'><i class='fa fa-search'></i></span>-->
    </nb-form-field>
  </div>
<!--  <div class='col-xl-2 col-lg-2 col-sm-2 col-12 override-width override-width-md mt-2'>-->
<!--    <nb-select placeholder='All cities' fullWidth size='tiny' [(ngModel)]='searchRequest.cityId'>-->
<!--      <nb-option [value]='undefined' (click)='onCitySelectChange()'>All cities</nb-option>-->
<!--      <nb-option *ngFor='let key of cities'-->
<!--                 [value]='key.cityId'-->
<!--                 [ngClass]="key.status === 'DEACTIVE' ? 'deactive-status' : ''"-->
<!--                 (click)='onCitySelectChange()'>{{key.name}}</nb-option>-->
<!--    </nb-select>-->
<!--  </div>-->
</div>
<div class='d-flex row col-12'>
    <div class='col-xl-2 col-lg-2 col-md-2 col-sm-4 col-12 override-width override-width-md mt-1 property-type-container'>
      <button nbButton ghost fullWidth class='date-picker-button p-0 border-0'
              (click)='triggerDatePicker(datepickerFromPurchaseDate)'>
        <input
          [nbDatepicker]='datepickerFromPurchaseDate'
          nbInput fieldSize='tiny'
          fullWidth
          class = "mt-1"
          [(ngModel)]='fromPurchaseDate'
          (ngModelChange)='onFromPurchaseDateSelectChange()'
          placeholder='From Purchase date'
        />
      </button>
      <nb-datepicker #datepickerFromPurchaseDate></nb-datepicker>
    </div>
    <div class='col-xl-2 col-lg-2 col-md-2 col-sm-4 col-12 override-width override-width-md mt-1 property-type-container'>
      <button nbButton ghost fullWidth class='date-picker-button p-0 border-0'
              (click)='triggerDatePicker(datepickerToPurchaseDate)'>
        <input
          [nbDatepicker]='datepickerToPurchaseDate'
          nbInput fieldSize='tiny'
          fullWidth
          class = "mt-1"
          [(ngModel)]='toPurchaseDate'
          (ngModelChange)='onToPurchaseDateSelectChange()'
          placeholder='To Purchase date'
        />
      </button>
      <nb-datepicker #datepickerToPurchaseDate></nb-datepicker>
    </div>
    <div class='col-xl-2 col-lg-2 col-md-2 col-sm-4 col-4 override-width override-width-md mt-1 property-type-container'>
      <input [(ngModel)]='age'
             type='number'
             placeholder='Age'
             nbInput fieldSize='tiny'
             class = "mt-1"
             fullWidth>
    </div>
    <div class='col-xl-2 col-lg-2 col-md-4 col-sm-4 col-8 override-width override-width-md mt-1 property-type-container'>
      <nb-radio-group [(ngModel)]='ageIsOver'>
        <nb-radio [value]='true'>Over</nb-radio>
        <nb-radio [value]='false'>Under</nb-radio>
      </nb-radio-group>
    </div>
    <div class='col-xl-2 col-lg-2 col-md-2 col-sm-4 col-8 override-width override-width-md mt-1 property-type-container mt-1'></div>
  </div>
<div class='d-flex row col-12'>
  <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12 override-width override-width-md mt-1 property-type-container'>
    <nb-select placeholder='Customer Motivate' fullWidth size='small' [(ngModel)]='motivateType'>
      <nb-option [value]='' (click)='onMotiveSelectChange()'>Customer Motivate</nb-option>
      <nb-option *ngFor='let key of customerMotivate'
                 [value]='key'
                 [ngClass]='key'
                 (click)='onMotiveSelectChange()'>{{key}}</nb-option>
    </nb-select>
  </div>
  <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-6 override-width override-width-md mt-1 property-type-container'>
    <nb-select placeholder='Religion' fullWidth size='small' [(ngModel)]='religion'>
      <nb-option [value]='' (click)='onReligionSelectChange()'>Religion</nb-option>
      <nb-option *ngFor='let key of RELIGIONS'
                 [value]='key.name'
                 [ngClass]='key'
                 (click)='onReligionSelectChange()'>{{key.name}}</nb-option>
    </nb-select>
  </div>
  <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-6 override-width override-width-md mt-1 property-type-container'>
    <nb-select placeholder='Nationality' fullWidth size='small' [(ngModel)]='nationality'>
      <nb-option [value]='' (click)='onNationalitySelectChange()'>Nationality</nb-option>
      <nb-option *ngFor='let key of COUNTRIES'
                 [value]='key.country_name'
                 [ngClass]='key'
                 (click)='onNationalitySelectChange()'>{{key.country_name}}</nb-option>
    </nb-select>
  </div>
  <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12 override-width override-width-md mt-1 property-type-container'>
    <input
      fullWidth
      [(ngModel)]='categoryName'
      [ngModelOptions]='{standalone: true}'
      [typeahead]='filterCategory$'
      [typeaheadAsync]='true'
      (typeaheadOnSelect)='selectCategory($event)'
      typeaheadOptionField='name'
      typeaheadWaitMs='1000'
      placeholder='Tag'
      class='mr-2'
      nbInput fieldSize='tiny'>
    <div *ngFor='let category of selectedCategories' class='category-block'>
      {{category.name}}
      <button class='btn btn-danger btn-sm' (click)='removeCategory(category)'>
        <i class='fa fa-times' aria-hidden='true'></i>
      </button>
    </div>
    <div class='mt-1'>
      <button *ngFor='let button of prospectTagButtons' nbButton status='primary' size='tiny' value='{{button.value}}'
              (click)='selectProspectTag(button.value)' class='mr-1' [hidden]='button.selected === true'>{{button.key}}
      </button>
    </div>
  </div>
  <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12 override-width override-width-md mt-1 property-type-container mw-100 w-100'>
    <input
      *appPermissionCheck='ComponentPermission.GLOBAL_FILTER_USER'
      [(ngModel)]='userName'
      [ngModelOptions]='{standalone: true}'
      [typeahead]='filterUser$'
      [typeaheadAsync]='true'
      (typeaheadOnSelect)='selectUser($event)'
      class='mr-2 mw-100 w-100'
      typeaheadOptionField='name'
      typeaheadWaitMs='1000'
      placeholder='Search User'
      nbInput fieldSize='tiny'>
    <button
      type='button' class='btn btn-info btn-sm mr-2' (click)='selectSelf()'>
      Select Self
      <i class='fa fa-plus' aria-hidden='true'></i>
    </button>

    <div class='category-block' *ngIf='selectedUser'>
      {{ selectedUser && selectedUser.name ? selectedUser.name + ' (' + stringUtils.shortenUserName(selectedUser) + ')' : '' }}
      <button class='btn btn-danger btn-sm' (click)='removeUser()'>
        <i class='fa fa-times' aria-hidden='true'></i>
      </button>
    </div>
  </div>
</div>
<div class='col-12 d-flex mt-2'>
  <div class='col-12'>
    <button nbButton size='tiny' class='fa-pull-right' hero status='info' (click)='search()'>Search</button>
  </div>
</div>
