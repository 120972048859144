import {Customer} from './customer';
import {Property} from './property';
import {Task} from '../task';
import {ProspectLog} from './prospect-log';
import {Category} from '../category';
import {NationalityNote} from '../nationality-note';
import {Office} from '../office';
import {Vendor} from './vendor';
import {ListingRepresentative} from './listing-representative';
import {Company} from './company';
import {ContractChecklist} from '../contract-checklist';
import {Marketing} from './marketing';
import {Tenant} from './tenant';
import {Address} from '../address/address';

export class Prospect {
  prospectId?: number;
  note?: string;
  motiveNote?: string;
  call?: boolean;
  sms?: boolean;
  door?: boolean;
  mail?: boolean;
  rate?: number;
  createdBy?: string;
  createdDate?: number;
  customer?: Customer;
  property?: Property;
  tasks?: Task[];
  logs: ProspectLog[];
  lastCalledPhoneNumber?: string;
  lastSmsPhoneNumber?: string;
  lastCalledDate?: number;
  lastSmsDate?: number;
  lastDoorNockedDate?: number;
  otmStatus?: string;
  otmDayDiff?: number;
  vacStatus?: string;
  vacDayDiff?: number;
  categories: Category[];
  nationalityNote?: NationalityNote;
  office?: Office;
  vendors?: Vendor[];
  listingRepresentative?: ListingRepresentative;
  solicitorCompany?: Company;
  historyNote?: string;
  contractRequestDate?: number;
  contractReceivedDate?: number;
  contractChecklist?: ContractChecklist[];
  marketing?: Marketing;
  tenant?: Tenant;
  hasNoteAfterCallOrDoornock?: boolean;
  isCopy?: boolean;

  totalUnit?: number;
  unitProspectIds?: number[];
  addressId?: number;
  address?: Address;
}
