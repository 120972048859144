<div class="dateview-container">
  <div class="row">
    <div class="col-md-6 col-sm-12 left-panel table-container"
         [ngClass]="{'popup-hide': isDisplay}">
      <nb-card>
        <nb-card-body>
          <ngx-prospect-list-filter-callbackdate class="z-index"
            [districts]="filteredDistricts"
            [districtId]="searchRequest?.districtId"
            [streets]="filteredStreets"
            [streetId]="searchRequest?.streetId"
            [cities]="cities"
            [cityId]="searchRequest?.cityId"
            [total]="groupWrapper?.totalItems"
            [farm]="selectedProspect?.address?.street?.farm?.name"
            [zone]="selectedProspect?.address?.street?.district?.zone?.name"
            [selectedYear]='selectedYear'
            [selectedMonth]='selectedMonth'
            [selectedDay]='selectedDay'
            [postcode]='searchRequest.postcode'
            (onSuburbChange)="onSuburbChange($event)"
            (onStreetChange)="onStreetChange($event)"
            (onCityChange)="onCityChange($event)"
            (onYearChange)="onYearChange($event)"
            (onMonthChange)="onMonthChange($event)"
            (onDayChange)="onDayChange($event)"
            (onSearchByPostcode)='onSearchByPostcode($event)'
          >
          </ngx-prospect-list-filter-callbackdate>
          <div class="mt-2">
            <button nbButton size="tiny" hero status="danger"
                    [hidden]="selectedProspectIds.length <= 0 && selectedProspectIds.length <= 0"
                    (click)="markSelectedAsCalled()">Mark as Called
            </button>
          </div>
          <div class="mt-2 full-height">
            <ng2-smart-table *ngIf="!viewMap"
                             [settings]="settings"
                             [source]="source"
                             (userRowSelect)='selectRecord($event, false, true); selectRow($event, prospectDialog)'
            >
            </ng2-smart-table>
          </div>
        </nb-card-body>
      </nb-card>
    </div>
    <div class="col-md-6 col-sm-12 right-panel table-container"
         [ngClass]="{
       'popup-detail': isDisplay,
       'hide-detail': !isDisplay
       }">
      <ngx-prospect-dialog
        [selectedProspect]="selectedProspect"
        [prospects]="selectedProspects"
        [companies]="companies"
        [taskTypes]="taskTypes"
        [taskStatuses]="taskStatuses"
        (editDone)="editDone($event)"
        (navigateProspectEvent)="navigateProspect($event)"
      ></ngx-prospect-dialog>
    </div>
    <div class='d-flex col-12'>
      <button *ngIf='isDialog'
              nbButton size='tiny' hero status='primary' class='fa-pull-right ml-2'
              (click)='closeDialog()'>Cancel
      </button>
    </div>
    <div class='scroll-mobile left-54' *ngIf='selectedProspect && isDisplay'>
      <button (click)='cancel()' size='tiny' nbButton status='warning'>Cancel</button>
    </div>
  </div>
</div>
