import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { LoadingService } from '../services/loading.service';
import { Path } from '../common/path';
import { Endpoint } from '../common/endpoint';
import { CommonService } from '../services/common.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  endpoints = Endpoint.endpoint;
  isSupported = false;

  constructor(private authService: AuthService,
              private loadingService: LoadingService,
              private commonService: CommonService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.handleHostName();
    if (!this.isSupported) {
      this.commonService.warning('Domain not supported');
      return EMPTY;
    }
    this.loadingService.show();
    if (this.authService.accessToken) {
      request = request.clone({
        setHeaders: { Authorization: `Bearer ${this.authService.accessToken}` },
      });
    }
    return next.handle(request);
  }

  handleHostName() {
    const domain = window.location.hostname;
    for (const endpoint of this.endpoints) {
      if (domain === endpoint.domain) {
        Path.API_ENDPOINT = endpoint.api;
        Path.PARTNER_NAME = endpoint.siteName;
        this.isSupported = true;
        break;
      } else if (domain === 'localhost' || domain === '192.168.1.211' || domain === '192.168.1.115'
        || domain === '192.168.0.102' || domain === '192.168.1.100' || domain === '192.168.1.30' || domain === '192.168.1.2' || domain === '192.168.1.4') {
        this.isSupported = true;
      }
    }
  }
}
