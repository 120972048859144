export class Tenant {
  tenantId: number;
  name: string;
  surname: string;
  phone: string;
  mobilePhone: string;
  email: string;
  agreement: string;
  createdDate: number;
  createdBy: string;
  isArrear: boolean;
  arrearWeekCount: number;
  arrearAmount: number;
  isMaintenance: boolean;
  maintenanceNote: string;
  notificationSent: boolean;
  startDate: number;
  endDate: number;
}
