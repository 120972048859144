import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'ngx-color-palette',
  templateUrl: './color-palette.component.html',
  styleUrls: ['./color-palette.component.scss'],
})
export class ColorPaletteComponent implements OnInit {

  @Input() defaultColor: string;
  @Output() colorChange = new EventEmitter<string>();

  row1: string[] = ['#70c5a4', '#8ed0b4', '#addac6', '#c6e5d6', '#dff0e8'];
  row2: string[] = ['#3fb9ea', '#74c2e6', '#95d1f3', '#b6def7', '#dcecf9'];
  row3: string[] = ['#4170b6', '#6a82c2', '#879bce', '#acb6da', '#d4d6ed'];
  row4: string[] = ['#5a53a3', '#756bb0', '#9087be', '#b0a9d2', '#d3cfe8'];
  row5: string[] = ['#85509e', '#9a6dae', '#ad8bbe', '#c5acd3', '#ded2e8'];
  row6: string[] = ['#d2499b', '#d671ad', '#e68fba', '#e6b3d2', '#f3d6e8'];
  row7: string[] = ['#eb1879', '#f15f8e', '#fb85a7', '#f7aec1', '#fbd6de'];
  row8: string[] = ['#ed1e24', '#ee5d3e', '#f38667', '#faa98c', '#ffcdbb'];
  row9: string[] = ['#fba618', '#fbb74a', '#fec679', '#ffd8a1', '#ffeaca'];
  row10: string[] = ['#f4eb22', '#f3ec67', '#f9f18c', '#faf5b3', '#fdfad1'];
  row11: string[] = ['#9acb34', '#aed361', '#c3dc88', '#d4e5ae', '#e7f2d4'];
  row12: string[] = ['#72c053', '#89c772', '#abd595', '#c0dfb6', '#e0edd9'];
  row13: string[] = ['#343d46', '#4f5b66', '#65737e', '#a7adba', '#c0c5ce'];
  colors: string[][] = [];

  constructor() {
    for (let i = 0; i < 13; i++) {
      this.colors.push(this['row' + (i + 1)]);
    }
  }

  ngOnInit(): void {
  }

  selectColor(color: string) {
    this.colorChange.emit(color);
  }

}
