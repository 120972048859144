import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Path} from '../common/path';
import {RestResult} from '../models/response/rest-result';
import {CommonService} from './common.service';
import {SearchRequest} from '../models/request/search-request';
import {ProspectRequest} from '../models/request/prospect/prospect-request';
import {Zone} from '../models/response/address/zone';
import {Constant} from '../common/constant';
import { ZoneOfficeRequest } from '../models/request/address/zone-office-request';
import { CityRequest } from '../models/request/address/city-request';

@Injectable()
export class ZoneService {

  constructor(protected http: HttpClient,
              protected commonService: CommonService) {
  }

  getOne(zoneId: number): Observable<RestResult> {
    return this.http.get<any>(
      Path.API_ENDPOINT +
      Path.ZONE +
      Path.ZONE_ID.replace(':zoneId', zoneId.toString()),
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }


  search(request: SearchRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.ZONE +
      Path.SEARCH, request,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  create(request: ProspectRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.ZONE, request).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  remove(zoneId: number): Observable<RestResult> {
    return this.http.delete<any>(
      Path.API_ENDPOINT +
      Path.ZONE +
      Path.ZONE_ID.replace(':zoneId', zoneId.toString()),
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  update(request: ProspectRequest): Observable<RestResult> {
    return this.http.put<any>(
      Path.API_ENDPOINT +
      Path.ZONE, request).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  // ===== support for autocomplete ============
  searchByKeyword(keyword: string): Observable<Zone[]> {
    const searchRequest = new SearchRequest();
    searchRequest.conditions = new Array();
    searchRequest.conditions.push(
      {
        left: 'name',
        middle: 'like',
        right: keyword,
      },
    );
    searchRequest.conditions.push(
      {
        left: 'code',
        middle: '=',
        right: keyword,
      },
    );
    searchRequest.conditionType = Constant.CONDITION_TYPE.OR;
    searchRequest.offset = 0;
    searchRequest.limit = 10;
    return this.search(searchRequest).pipe(
      map(result => result.data),
    );
  }

  createOrDeleteZoneOffice(request: ZoneOfficeRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.ZONE + Path.ASSIGN, request).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  fetchZoneBySelectedOffice(officeId: number): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.ZONE + Path.ASSIGNED, officeId).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  fetchByParentId(request: CityRequest[]): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.ZONE +
      Path.PARENT, request,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }
}
