import { Injectable } from '@angular/core';
import { EmailGroupRequest } from '../models/request/email-group-request';
import { Observable } from 'rxjs';
import { RestResult } from '../models/response/rest-result';
import { Path } from '../common/path';
import { catchError, map } from 'rxjs/operators';
import { SearchRequest } from '../models/request/search-request';
import { HttpClient } from '@angular/common/http';
import { CommonService } from './common.service';

@Injectable()
export class EmailGroupService {

  constructor(protected http: HttpClient,
              protected commonService: CommonService) { }

  create(request: EmailGroupRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.EMAIL_GROUP, request).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError)
    );
  }


  update(request: EmailGroupRequest): Observable<RestResult> {
    return this.http.put<any>(
      Path.API_ENDPOINT +
      Path.EMAIL_GROUP, request).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError)
    );
  }

  getOne(emailGroupId: number): Observable<RestResult> {
    return this.http.get<any>(
      Path.API_ENDPOINT +
      Path.EMAIL_GROUP +
      Path.EMAIL_GROUP_ID.replace(':emailGroupId', emailGroupId.toString()),
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }


  search(request: SearchRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.EMAIL_GROUP +
      Path.SEARCH, request,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  remove(emailGroupId: number): Observable<RestResult> {
    return this.http.delete<any>(
      Path.API_ENDPOINT +
      Path.EMAIL_GROUP +
      Path.EMAIL_GROUP_ID.replace(':emailGroupId', emailGroupId.toString()),
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }
}
