import { Component, OnInit } from '@angular/core';
import { SearchRequest } from '../../../../shared/models/request/search-request';
import { LocalDataSource } from 'ng2-smart-table';
import { NbDialogService } from '@nebular/theme';
import { CommonService } from '../../../../shared/services/common.service';
import { ComponentPermission } from '../../../../component.permission';
import { RestResult } from '../../../../shared/models/response/rest-result';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { TaskTypeService } from '../../../../shared/services/task-type.service';
import { TaskTypeCreateComponent } from './create/task-type-create.component';
import { TaskTypeUpdateComponent } from './update/task-type-update.component';
import { TaskTypeCategory } from '../../../../shared/models/response/task-type-category';
import { TaskCellComponent } from '../common/task-cell-component';
import { Constant } from '../../../../shared/common/constant';

@Component({
  selector: 'ngx-task-type',
  templateUrl: './task-type.component.html',
  styleUrls: ['./task-type.component.scss'],
})
export class TaskTypeComponent implements OnInit {
  ComponentPermission = ComponentPermission;
  searchRequest: SearchRequest = new SearchRequest();
  source: LocalDataSource = new LocalDataSource();
  change: any;
  pageSize = Constant.PAGE_SIZE;

  settings = {
    mode: 'external',
    actions: {
      add: ComponentPermission.isAccess(ComponentPermission.TASK_TYPE_CREATE),
      edit: ComponentPermission.isAccess(ComponentPermission.TASK_TYPE_EDIT),
      delete: ComponentPermission.isAccess(ComponentPermission.TASK_TYPE_DELETE),
    },
    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
    },
    pager: {
      display: true,
      perPage: this.pageSize,
    },
    columns: {
      taskTypeId: {
        title: 'ID',
        type: 'number',
      },
      name: {
        title: 'Name',
        type: 'string',
      },
      description: {
        title: 'Description',
        type: 'string',
      },
      category: {
        title: 'Category',
        type: 'custom',
        renderComponent: TaskCellComponent,
        onComponentInitFunction: (instance: TaskCellComponent) => {
          instance.config = {
            propertyPaths: ['category.name'],
            valueType: TaskCellComponent.VALUE_TYPES.DEFAULT,
            separator: '',
          };
        },
        filter: false,
      },
    },
  };

  categories: TaskTypeCategory[];

  constructor(private dialogService: NbDialogService,
              private commonService: CommonService,
              private taskTypeService: TaskTypeService) {

  }

  ngOnInit() {
    this.source.onChanged().subscribe((change) => {
      this.change = change;
      if (change.action === 'refresh') {
        this.loadRequest(1);
        // this.pageChange(change.paging.page);
      }
      if (change.action === 'page') {
        // this.source.load(new Array());
        this.loadRequest(change.paging.page);
      }
      if (change.action === 'sort') {
        this.source.load(new Array());
        this.loadRequest(1);
      }
      if (change.action === 'filter') {
        this.source.load(new Array());
        this.loadRequest(1);
      }
    });
    this.taskTypeService.getAllTaskTypeCategory().subscribe((result: RestResult) => {
      this.categories = result?.data;
    });
  }

  get CheckPermission() {
    return ComponentPermission;
  }

  search() {
    this.loadRequest(1);
  }

  loadRequest(pageIndex) {
    this.prepareRequest();
    this.taskTypeService.search(this.searchRequest).subscribe((result: RestResult) => {
      this.source.load(result.data);
    });
  }

  prepareRequest() {
    // init conditions
    if (!this.searchRequest.conditions) {
      this.searchRequest.conditions = new Array();
    }
    this.searchRequest.conditions = new Array();
    if (this.change.filter && this.change.filter.filters.length > 0) {
      this.change.filter.filters.forEach(filter => {
        if (filter.search && filter.search.trim() !== '') {
          if (filter.field.includes('Id') && isFinite(filter.search)) {
            this.searchRequest.conditions.push(
              {
                left: filter.field,
                middle: '=',
                right: Number(filter.search),
              },
            );
          } else if (filter.field === 'category') {
            let categoryId: number;
            switch (filter.search.toLowerCase()) {
              case 'customer':
                categoryId = 1;
                break;
              case 'general':
                categoryId = 2;
                break;
              case 'client':
                categoryId = 3;
                break;
              default:
                categoryId = Number.MAX_VALUE;
                break;
            }
            this.searchRequest.conditions.push(
              {
                left: 'taskTypeCategoryId',
                middle: '=',
                right: categoryId,
              },
            );
          } else {
            this.searchRequest.conditions.push(
              {
                left: filter.field,
                middle: 'like',
                right: filter.search,
              },
            );
          }
        }
      });
    }

    // init orders
    this.searchRequest.orders = new Array();
    if (this.change.sort && this.change.sort.length > 0) {
      this.change.sort.forEach(sort => {
        this.searchRequest.orders.push(
          {
            left: sort.field,
            right: sort.direction,
          },
        );
      });
    } else {
      this.searchRequest.orders.push(
        {
          left: 'taskTypeId',
          right: 'desc',

        },
      );
    }
  }

  deleteRecord(event: any) {
    this.dialogService.open(ConfirmDialogComponent, {
      context: {
        content: 'Do you really want to delete?',
      },
    })
      .onClose.subscribe(res => {
      if (res === ConfirmDialogComponent.confirmOk) {
        this.taskTypeService.remove(event.data.taskTypeId).subscribe(
          result => {
            this.commonService.info('Delete successfully');
            this.loadRequest(this.change.paging.page);
          }, error => {
            this.commonService.warning(error.message);
          });
      }
    });
  }


  addRecord(event: any) {
    this.dialogService.open(TaskTypeCreateComponent, {
      context: {
        categories: this.categories,
      },
    })
      .onClose.subscribe(result => {
      if (result) {
        this.loadRequest(this.change.paging.page);
      }
    });
  }

  editRecord(event: any) {
    this.dialogService.open(TaskTypeUpdateComponent, {
      context: {
        data: event.data,
        categories: this.categories,
      },
    })
      .onClose.subscribe(result => {
      if (result) {
        this.loadRequest(this.change.paging.page);
      }
    });
  }

}
