<div class='col-12'>
  <div class='col-12 text-center mb-2'>
      <span>{{farm}} {{zone}}</span>
  </div>
  <div class='row mt-2'>
    <div class='col-md-4 col-12 margin-top-sm'>
      <select fullWidth size='tiny'
              [(ngModel)]='streetId'
              [disabled]='!districtId'
              class='full-width'
              (change)='onStreetSelectChange()'
      >
        <option [value]='undefined'>All streets</option>
        <option *ngFor='let key of streets'
                [ngClass]="key.status === 'DEACTIVE' ? 'deactive-status' : ''"
                [value]='key.streetId'
        >{{key.name}}</option>
      </select>
    </div>
    <div class='col-md-4 col-12 margin-top-sm'>
      <select size='tiny'
              [(ngModel)]='districtId'
              [disabled]='!filterByPostcode'
              class='full-width'
              (change)='onSuburbSelectChange()'
      >
        <option [value]='undefined'>All suburbs</option>
        <option *ngFor='let key of districts'
                [ngClass]="key.status === 'DEACTIVE' ? 'deactive-status' : ''"
                [value]='key.districtId'
        >{{key.name}}</option>
      </select>
    </div>
    <div class='col-md-4 col-12 margin-top-sm'>
<!--      <select size='tiny'-->
<!--              [(ngModel)]='cityId'-->
<!--              class='full-width'-->
<!--              (change)='onCitySelectChange()'-->
<!--      >-->
<!--        <option [value]='undefined'>All cities</option>-->
<!--        <option *ngFor='let key of cities'-->
<!--                [ngClass]="key.status === 'DEACTIVE' ? 'deactive-status' : ''"-->
<!--                [value]='key.cityId'-->
<!--        >{{key.name}}</option>-->
<!--      </select>-->
      <nb-form-field>
        <input nbInput fullWidth placeholder='Postcode' fieldSize='tiny' class='override-nbinput-height'
               [(ngModel)]='postcode'
               [typeahead]='filterPostcode$'
               [ngModelOptions]='{standalone: true}'
               [typeaheadAsync]='true'
               (typeaheadOnSelect)='selectPostcode($event)'
               typeaheadOptionField='code'
               typeaheadMinLength='3'
               typeaheadWaitMs='1000'>
        <!--      <span nbSuffix (click)='searchSuburbByPostcode()'><i class='fa fa-search'></i></span>-->
      </nb-form-field>
    </div>
  </div>
  <div class='row mt-2'>
    <div class='col-4'>
      {{total}}
    </div>
  </div>

  <div class='row mt-2'>
    <div class='col-3 col-sm-2'>
      <select fullWidth size='tiny'
              [(ngModel)]='selectedDay'
              class='full-width'
              [disabled]='!selectedMonth'
              (change)='onDaySelectChange()'
      >
        <option [value]='undefined'>Day</option>
        <option *ngFor='let item of days'
                [value]='item'
        >{{item}}</option>
      </select>
    </div>
    <div class='col-3 col-sm-2'>
      <select fullWidth size='tiny'
              [(ngModel)]='selectedMonth'
              class='full-width'
              (change)='onMonthSelectChange()'
      >
        <option [value]='undefined'>Month</option>
        <option *ngFor='let item of months'
                [value]='item + 1'
        >{{item + 1}}</option>
      </select>
    </div>
    <div class='col-3 col-sm-2'>
      <select size='tiny'
              [(ngModel)]='rate'
              class='full-width'
              (change)='onRateSelectChange()'>
        <option [value]='undefined'>Rate</option>
        <option *ngFor='let item of rateValue'
                [value]='item'>{{item}}</option>
      </select>
    </div>
  </div>
</div>
