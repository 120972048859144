<div class='col-12 row change mt-2'>
  <!--  <div class="col-2">-->
  <!--    <nb-select placeholder="All streets" fullWidth size="tiny" [(ngModel)]="searchRequest.streetId"-->
  <!--               [disabled]="!searchRequest?.districtId && !searchRequest?.farmId">-->
  <!--      <nb-option [value]="" (click)="onStreetSelectChange()">All streets</nb-option>-->
  <!--      <nb-option *ngFor="let key of streets"-->
  <!--                 [value]="key.streetId"-->
  <!--                 (click)="onStreetSelectChange()">{{key.name}}</nb-option>-->
  <!--    </nb-select>-->
  <!--  </div>-->
  <!--  <div class="col-2">-->
  <!--    <nb-select placeholder="All farms" fullWidth size="tiny" [(ngModel)]="searchRequest.farmId"-->
  <!--               [disabled]="!searchRequest?.districtId">-->
  <!--      <nb-option [value]="" (click)="onFarmSelectChange()">All farms</nb-option>-->
  <!--      <nb-option *ngFor="let key of farms"-->
  <!--                 [value]="key.farmId"-->
  <!--                 (click)="onFarmSelectChange()">{{key.name}}</nb-option>-->
  <!--    </nb-select>-->
  <!--  </div>-->
  <div class='col-xl-3 col-lg-3 col-sm-3 col-12 override-width override-width-md mt-2'>
    <nb-select placeholder='All suburbs' fullWidth size='small' [(ngModel)]='searchRequest.districtId'
               [disabled]='!filterByPostcode'>
      <nb-option [value]='undefined' (click)='onSuburbSelectChange()'>All suburbs</nb-option>
      <nb-option *ngFor='let key of districts'
                 [ngClass]="key.status === 'DEACTIVE' ? 'deactive-status' : ''"
                 [value]='key.districtId'
                 (click)='onSuburbSelectChange()'>{{key.name}}</nb-option>
    </nb-select>
  </div>
  <div class='col-xl-3 col-lg-3 col-sm-3 col-12 override-width override-width-md mt-2'>
    <!--    <nb-select placeholder='All Postcodes' fullWidth size='tiny' [(ngModel)]='searchRequest.postcode'-->
    <!--               [disabled]='!searchRequest?.cityId'>-->
    <!--      <nb-option [value]='undefined' (click)='onPostcodeSelectChange()'>All postcodes</nb-option>-->
    <!--      <nb-option *ngFor='let key of postcodes'-->
    <!--                 [value]='key'-->
    <!--                 (click)='onPostcodeSelectChange()'>{{key}}</nb-option>-->
    <!--    </nb-select>-->
    <nb-form-field>
      <input nbInput fullWidth placeholder='Postcode' fieldSize='tiny' class='override-nbinput-height'
             [(ngModel)]='postcode'
             [typeahead]='filterPostcode$'
             [ngModelOptions]='{standalone: true}'
             [typeaheadAsync]='true'
             (typeaheadOnSelect)='selectPostcode($event)'
             typeaheadOptionField='code'
             typeaheadMinLength='3'
             typeaheadWaitMs='1000'>
      <!--      <span nbSuffix (click)='searchSuburbByPostcode()'><i class='fa fa-search'></i></span>-->
    </nb-form-field>
  </div>
  <!--  <div class="col-xl-2 col-lg-2 col-sm-2 col-12 override-width override-width-md mt-2">-->
  <!--    <nb-select placeholder="All cities" fullWidth size="tiny" [(ngModel)]="searchRequest.cityId">-->
  <!--      <nb-option [value]="undefined" (click)="onCitySelectChange()">All cities</nb-option>-->
  <!--      <nb-option *ngFor="let key of cities"-->
  <!--                 [ngClass]="key.status === 'DEACTIVE' ? 'deactive-status' : ''"-->
  <!--                 [value]="key.cityId"-->
  <!--                 (click)="onCitySelectChange()">{{key.name}}</nb-option>-->
  <!--    </nb-select>-->
  <!--  </div>-->
  <div class='col-xl-3 col-lg-3 col-sm-3 col-6 override-width override-width-md mt-2'>
    <input nbInput
           fullWidth fieldSize='tiny'
           placeholder='Start Date'
           [(ngModel)]='searchRequest.start'
           [nbDatepicker]='ngStart'>
    <nb-datepicker #ngStart></nb-datepicker>
  </div>
  <div class='col-xl-3 col-lg-3 col-sm-3 col-6 override-width override-width-md mt-2'>
    <input nbInput
           fullWidth fieldSize='tiny'
           placeholder='End Date'
           [(ngModel)]='searchRequest.end'
           [nbDatepicker]='ngEnd'>
    <nb-datepicker #ngEnd></nb-datepicker>
  </div>
</div>

<div class='col-12 row d-flex mt-2'>
  <div class='mt-1 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 property-type-container'>
    <input
      [(ngModel)]='categoryName'
      [ngModelOptions]='{standalone: true}'
      [typeahead]='filterCategory$'
      [typeaheadAsync]='true'
      (typeaheadOnSelect)='selectCategory($event)'
      typeaheadOptionField='name'
      typeaheadWaitMs='1000'
      placeholder='Tag'
      class='mr-2 w-100 mw-100'
      nbInput fieldSize='tiny'>
    <div *ngFor='let category of selectedCategories' class='category-block'>
      {{category.name}}
      <button class='btn btn-danger btn-sm' (click)='removeCategory(category)'>
        <i class='fa fa-times' aria-hidden='true'></i>
      </button>
    </div>
    <div class='mt-1'>
      <button *ngFor='let button of buyerTagButtons' nbButton status='primary' size='tiny' value='{{button.value}}'
              (click)='selectBuyerTag(button.value)' class='mr-1' [hidden]='button.selected'>{{button.key}}
      </button>
    </div>
  </div>
  <div class='mt-1 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 property-type-container'>
    <input
      *appPermissionCheck='ComponentPermission.GLOBAL_FILTER_USER'
      [(ngModel)]='userName'
      [ngModelOptions]='{standalone: true}'
      [typeahead]='filterUser$'
      [typeaheadAsync]='true'
      (typeaheadOnSelect)='selectUser($event)'
      class='mr-2 w-100 mw-100'
      typeaheadOptionField='name'
      typeaheadWaitMs='1000'
      placeholder='Search User'
      nbInput fieldSize='tiny'>
    <button
      type='button' class='btn btn-info btn-sm mr-2 mt-1' (click)='selectSelf()'>
      Select Self
      <i class='fa fa-plus' aria-hidden='true'></i>
    </button>

    <div class='category-block' *ngIf='selectedUser'>
      {{ selectedUser && selectedUser.name ? selectedUser.name + ' (' + stringUtils.shortenUserName(selectedUser) + ')' : '' }}
      <button class='btn btn-danger btn-sm' (click)='removeUser()'>
        <i class='fa fa-times' aria-hidden='true'></i>
      </button>
    </div>
  </div>
</div>
<div class='col-12 row d-flex mt-2'>
  <div class='col-xl-2 col-lg-2 col-sm-2 col-12 mt-2 override-width'>
    <nb-select fullWidth size='tiny' [(ngModel)]='searchRequest.rate'>
      <nb-option [value]='' (click)='onRateSelectChange()'>Rates</nb-option>
      <nb-option *ngFor='let item of rateOptions'
                 [value]='item.value' (click)='onRateSelectChange()'>{{item.label}}</nb-option>
    </nb-select>
  </div>
  <div class='col-xl-2 col-lg-2 col-sm-2 col-6 mt-2 override-width'></div>
  <div class='col-xl-2 col-lg-2 col-sm-2 col-6 mt-2 override-width'></div>
  <div class='col-xl-2 col-lg-2 col-sm-2 col-6 mt-2 override-width'></div>
  <div class='col-xl-2 col-lg-2 col-sm-2 col-6 mt-2 override-width'></div>
</div>
<div class='col-12 d-flex mt-2'>
  <div class='col-12'>
    <button nbButton size='tiny' class='fa-pull-right' hero status='info' (click)='search()'>
      Search
    </button>
  </div>
</div>
