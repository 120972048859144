import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { City } from '../../../../../shared/models/response/address/city';
import { District } from '../../../../../shared/models/response/address/district';
import { Street } from '../../../../../shared/models/response/address/street';
import { Company } from '../../../../../shared/models/response/prospect/company';
import { Constant } from '../../../../../shared/common/constant';
import { DateUtils } from '../../../../../shared/common/date-utils';
import { ComponentPermission } from '../../../../../component.permission';
import { CommonService } from '../../../../../shared/services/common.service';
import { Observable } from 'rxjs';
import { Postcode } from '../../../../../shared/models/response/postcode';
import { SearchRequest } from '../../../../../shared/models/request/search-request';
import { map, mergeMap } from 'rxjs/operators';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { PostcodeService } from '../../../../../shared/services/postcode.service';
import { ActivatedRoute } from '@angular/router';
import { StringUtils } from '../../../../../shared/common/string-utils';

@Component({
  selector: 'ngx-prospect-list-filter-market',
  templateUrl: './prospect-list-filter-market.component.html',
  styleUrls: ['./prospect-list-filter-market.component.scss'],
})
export class ProspectListFilterMarketComponent implements OnInit {
  @Input() districts: District[];
  @Input() districtId: number;
  @Input() streets: Street[];
  @Input() streetId: number;
  @Input() cities: City[];
  @Input() cityId: number;
  @Input() sortTypesText: any;
  @Input() sortType: string;
  @Input() total: number;
  @Input() totalOtm: number;
  @Input() totalVac: number;
  @Input() totalOtmExpired: number;
  @Input() totalOtmActive: number;
  @Input() totalVacExpired: number;
  @Input() totalVacActive: number;
  @Input() isOTMMode: boolean = true;
  @Input() isVACMode: boolean = false;
  @Input() farm: string;
  @Input() zone: string;
  @Input() otmStatuses: any;
  @Input() otmStatus: any;
  @Input() companies: Company[];
  @Input() sellCompanyId: any;
  @Input() rentCompanyId: any;
  @Input() fromSellPrice: number;
  @Input() toSellPrice: number;
  @Input() fromRentPrice: number;
  @Input() toRentPrice: number;
  @Input() propertyType: string;
  @Input() bed: number;
  @Input() bath: number;
  @Input() car: number;
  @Output() onSuburbChange = new EventEmitter();
  @Output() onStreetChange = new EventEmitter();
  @Output() onCityChange = new EventEmitter();
  @Output() onSortTypeChange = new EventEmitter();
  @Output() onAddNewProspect = new EventEmitter();
  @Output() onOTMVACMode = new EventEmitter<{
    isVACMode: boolean;
    isOTMMode: boolean;
  }>();
  @Output() onOTMModeChange = new EventEmitter();
  @Output() onVACModeChange = new EventEmitter();
  @Output() onOpenShortcut = new EventEmitter();
  @Output() onOtmStatusChange = new EventEmitter();
  @Output() onSellCompanyChange = new EventEmitter();
  @Output() onRentCompanyChange = new EventEmitter();
  @Output() onFromSellPriceChange = new EventEmitter();
  @Output() onToSellPriceChange = new EventEmitter();
  @Output() onFromRentPriceChange = new EventEmitter();
  @Output() onToRentPriceChange = new EventEmitter();
  @Output() onPropertyTypeChange = new EventEmitter();
  @Output() onBedChange = new EventEmitter();
  @Output() onBathChange = new EventEmitter();
  @Output() onCarChange = new EventEmitter();
  @Output() onSearch = new EventEmitter();
  @Output() onYearChange = new EventEmitter();
  @Output() onMonthChange = new EventEmitter();
  @Output() onDayChange = new EventEmitter();
  @Output() exportMobile = new EventEmitter();
  @Output() exportMail = new EventEmitter();
  @Output() onSelectRate = new EventEmitter();
  @Output() bulkSave = new EventEmitter();

  propertyTypes = Constant.PROPERTY_TYPE;
  bedValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  years: number[];
  months = DateUtils.MONTHS;
  days: number[];
  @Input() selectedYear: number;
  @Input() selectedMonth: number;
  @Input() selectedDay: number;
  @Input() rate: number;
  @Input() phone: string;
  rateOptions = [
    { value: 0, label: '0' },
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
  ];
  log: string = null;
  ComponentPermission = ComponentPermission;
  @Input() postcode: number;
  @Output() onSearchByPostcode = new EventEmitter();
  filterPostcode$: Observable<Postcode[]>;
  filterByPostcode = false;

  constructor(private commonService: CommonService,
              private postcodeService: PostcodeService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.years = [];
    for (let i = 2000; i <= 3000; i++) {
      this.years.push(i);
    }
    this.filterPostcode$ = new Observable((observer: any) => {
      observer.next(this.postcode);
    }).pipe(
      map((query: string) => {
        this.postcode = Number(query);
        this.searchSuburbByPostcode();
        return null;
      }),
    );
    if (this.route.snapshot.queryParams['postcode']) {
      this.filterByPostcode = true;
    }
  }

  onSuburbSelectChange() {
    this.onSuburbChange.emit(isNaN(Number(this.districtId)) ? undefined : Number(this.districtId));
  }

  onStreetSelectChange() {
    this.onStreetChange.emit(isNaN(Number(this.streetId)) ? undefined : Number(this.streetId));
  }

  onCitySelectChange() {
    this.onCityChange.emit(isNaN(Number(this.cityId)) ? undefined : Number(this.cityId));
  }

  onSortTypeSelectChange() {
    this.onSortTypeChange.emit(this.sortType);
  }

  addNewProspect() {
    this.onAddNewProspect.emit();
  }

  onVACModeActive() {
    // this.onOTMVACMode.emit({
    //   isVACMode: !this.isVACMode,
    //   isOTMMode: this.isVACMode,
    // });
    this.isVACMode = true;
    this.isOTMMode = false;
    this.onVACModeChange.emit(true);
  }

  onOTMModeActive() {
    // this.onOTMVACMode.emit({
    //   isOTMMode: !this.isOTMMode,
    //   isVACMode: this.isOTMMode,
    // });
    this.isOTMMode = true;
    this.isVACMode = false;
    this.onOTMModeChange.emit(true);
  }

  openShortcut() {
    this.onOpenShortcut.emit();
  }

  onOtmStatusSelectChange() {
    this.onOtmStatusChange.emit(this.otmStatus);
  }

  onSellCompanySelectChange() {
    this.onSellCompanyChange.emit(this.sellCompanyId);
  }

  onRentCompanySelectChange() {
    this.onRentCompanyChange.emit(this.rentCompanyId);
  }

  onFromSellPriceInputChange() {
    this.onFromSellPriceChange.emit(this.fromSellPrice);
  }

  onToSellPriceInputChange() {
    this.onToSellPriceChange.emit(this.toSellPrice);
  }

  onFromRentPriceInputChange() {
    this.onFromRentPriceChange.emit(this.fromRentPrice);
  }

  onToRentPriceInputChange() {
    this.onToRentPriceChange.emit(this.toRentPrice);
  }

  onPropertyTypeSelectChange() {
    this.onPropertyTypeChange.emit(this.propertyType);
  }

  onBedSelectChange() {
    this.onBedChange.emit(this.bed);
  }

  onBathSelectChange() {
    this.onBathChange.emit(this.bath);
  }

  onCarSelectChange() {
    this.onCarChange.emit(this.car);
  }

  onSearchButtonClick() {
    if (!this.validatePriceBeforeFilter()) {
      this.commonService.warning('Price must not be negative');
      return;
    }
    this.onSearch.emit(true);
  }

  onYearSelectChange() {
    this.onYearChange.emit(this.selectedYear);
  }

  onMonthSelectChange() {
    const startDay = DateUtils.getFirstDayOfMonth(this.selectedMonth - 1, this.selectedYear).valueOf();
    const endDay = DateUtils.getLastDayOfMonth(this.selectedMonth - 1, this.selectedYear).valueOf();
    this.days = [];
    for (let i = startDay; i <= endDay; i += 86400000) {
      const day = new Date(i).getDate();
      this.days.push(day);
    }
    this.onMonthChange.emit(this.selectedMonth);
  }

  onDaySelectChange() {
    this.onDayChange.emit(this.selectedDay);
  }

  // =======
  keepOrder(a, b) {
    return 1;
  }

  onExportEmail() {
    this.exportMail.emit();
  }

  onExportMobile() {
    this.exportMobile.emit();
  }

  onRateSelectChange(event: any) {
    if (event === null) {
      this.rate = undefined;
    } else {
      this.rate = Number(event);
    }
    this.onSelectRate.emit(this.rate);
  }

  onBulkSave() {
    this.bulkSave.emit(this.log);
  }

  validatePriceBeforeFilter() {
    if (!this.fromSellPrice && !this.toSellPrice && this.isOTMMode) {
      return true;
    }
    if (!this.fromRentPrice && !this.toRentPrice && this.isVACMode) {
      return true;
    }
    if (this.fromSellPrice && !this.toSellPrice) {
      if (Number(this.fromSellPrice) < 0) {
        return false;
      }
    }
    if (!this.fromSellPrice && this.toSellPrice) {
      if (Number(this.toSellPrice) < 0) {
        return false;
      }
    }
    if (this.fromRentPrice && !this.toRentPrice) {
      if (Number(this.fromRentPrice) < 0) {
        return false;
      }
    }
    if (!this.fromRentPrice && this.toRentPrice) {
      if (Number(this.toRentPrice) < 0) {
        return false;
      }
    }
    return (Number(this.fromSellPrice) > 0 && Number(this.toSellPrice)) > 0 || (Number(this.fromRentPrice) > 0 && Number(this.toRentPrice) > 0);
  }

  searchPostcode(keyword: string): Observable<Postcode[]> {
    const searchRequest = new SearchRequest();
    searchRequest.conditions = [];
    searchRequest.orders = [];
    searchRequest.conditions.push(
      {
        left: 'code',
        middle: '=',
        right: keyword,
      },
    );
    searchRequest.orders.push(
      {
        left: 'code',
        right: 'asc'
      }
    );
    searchRequest.offset = 0;
    searchRequest.limit = 10;
    return this.postcodeService.search(searchRequest).pipe(
      map(result => result.data),
    );
  }

  selectPostcode(type: TypeaheadMatch) {
    this.postcode = Number(type.item.code);
    this.searchSuburbByPostcode();
  }

  searchSuburbByPostcode() {
    if (this.postcode === null) {
      return;
    }
    this.filterByPostcode = true;
    this.onSearchByPostcode.emit(this.postcode);
  }
}
