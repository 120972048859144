import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { CommonService } from '../../../../../shared/services/common.service';
import { BuyerService } from '../../../../../shared/services/buyer.service';
import { BuyerSearchRequest } from '../../../../../shared/models/request/buyer/buyer-search-request';
import { BuyerDatasource } from '../../common/buyer-datasource';
import { RestResult } from '../../../../../shared/models/response/rest-result';
import { BuyerGroupWrapper } from '../../../../../shared/models/response/buyer/buyer-group-wrapper';
import { BuyerUtils, WrapBuyer, WrapBuyerType } from '../../common/buyer-utils';
import { DateUtils } from '../../../../../shared/common/date-utils';
import { Buyer } from '../../../../../shared/models/response/buyer/buyer';
import * as moment from 'moment';
import { District } from '../../../../../shared/models/response/address/district';
import { City } from '../../../../../shared/models/response/address/city';
import { BuyerListStatusCellComponent } from '../../common/buyer-list-status-cell-component';
import { LocalService } from '../../../../../shared/services/local.service';
import { BuyerListAddressCellComponent } from '../../common/buyer-list-address-cell-component';
import { BuyerListCustomCellComponent } from '../../common/buyer-list-custom-cell-component';
import { BuyerListClassificationCellComponent } from '../../common/buyer-list-classification-cell-component';
import { Zone } from '../../../../../shared/models/response/address/zone';
import { BuyerListCalledDateCellComponent } from '../../common/buyer-list-called-date-cell-component';
import { BuyerSelectComponent } from '../../common/buyer-select-component';
import {
  MergeCustomersComponent,
} from '../../../../../shared/module/buyer-create/merge-customers/merge-customers.component';
import { ConfirmDialogComponent } from '../../../confirm-dialog/confirm-dialog.component';
import { Street } from '../../../../../shared/models/response/address/street';
import { ExportDialogComponent } from '../../export-dialog/export-dialog.component';
import { CategoryService } from '../../../../../shared/services/category.service';
import { Constant } from '../../../../../shared/common/constant';
import { AuthService } from '../../../../../shared/services/auth.service';
import { User } from '../../../../../shared/models/response/user';
import { MultipleSelectedRequest } from '../../../../../shared/models/common/multiple-selected-request';
import { CustomRow } from '../../../../../shared/models/common/custom-row';
import { ComponentPermission } from '../../../../../component.permission';
import { UserUtils } from '../../../../../shared/common/user-utils';
import { ProspectStatisticRequest } from '../../../../../shared/models/request/prospect/prospect-statistic-request';
import { TemplateService } from '../../../../../shared/services/template.service';
import { SearchRequest } from '../../../../../shared/models/request/search-request';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'ngx-buyer-list-advance',
  templateUrl: './buyer-list-advance.component.html',
  styleUrls: ['./buyer-list-advance.component.scss'],
})
export class BuyerListAdvanceComponent implements OnInit {
  @Output() onSelectedBuyerChange = new EventEmitter();
  @Output() onSelectedBuyersChange = new EventEmitter();
  @Output() onSelectedCustomerIds = new EventEmitter();
  @Output() onDisplayDetail = new EventEmitter();
  @Input() cities: City[];
  @Input() oneOnOneCall = false;
  filteredStreets: Street[];
  filteredDistricts: District[];
  filteredZones: Zone[];
  filteredPostcodes: string[];
  isFilter: boolean;
  ComponentPermission = ComponentPermission;
  wrapProspects: WrapBuyer[];

  columnsDefinition = {
    checkbox: {
      enable: true,
      value: this.createSelectColumn(''),
    },
    buyerName: {
      enable: true,
      value: BuyerUtils.createColumn('Name', ['customer.name']),
      name: 'Name',
    },
    buyerSurname: {
      enable: true,
      value: BuyerUtils.createColumn('SName', ['customer.surname']),
      name: 'SName',
    },
    classification: {
      enable: true,
      value: BuyerUtils.createColumn('Class',
        null, null, null, null, BuyerListClassificationCellComponent),
      name: 'Class',
    },
    buyerStatus: {
      enable: true,
      value: BuyerUtils.createColumn('Qualify', null,
        null, ', ', null, BuyerListStatusCellComponent),
      name: 'Qualification',
    },
    buyerAddress: {
      enable: true,
      value: BuyerUtils.createColumn('Address',
        null, null, ', ', null, BuyerListAddressCellComponent),
      name: 'Address',
    },
    lastCalledDate: {
      enable: true,
      value: BuyerUtils.createColumn('Last called date',
        null, null, null, null, BuyerListCalledDateCellComponent),
      name: 'Last called date',
    },
    buyerMinPrice: {
      enable: true,
      value: BuyerUtils.createColumn('Min price', ['property.minBuyPrice'],
        BuyerListCustomCellComponent.VALUE_TYPES.FORMATTED_NUMBER),
      name: 'Min price',
    },
    buyerMaxPrice: {
      enable: true,
      value: BuyerUtils.createColumn('Max price', ['property.maxBuyPrice'],
        BuyerListCustomCellComponent.VALUE_TYPES.FORMATTED_NUMBER),
      name: 'Max price',
    },
  };

  settings = {
    mode: 'external',
    hideSubHeader: true,
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    pager: {
      display: true,
      perPage: 10000,
    },
    columns: BuyerUtils.createColumns(this.columnsDefinition),
    rowClassFunction: (row) => {
      return BuyerUtils.highlightProspectRow(row?.data);
    },
  };

  pageSize = Constant.PAGE_SIZE;
  currentPage = 1;
  searchRequest = new BuyerSearchRequest();
  change: any;
  data: any;
  source: BuyerDatasource;
  totalPage = 0;
  groupWrapper: BuyerGroupWrapper;
  selectedBuyer: Buyer;
  selectedBuyers: Buyer[];
  // filter
  selectedYear: number;
  selectedQuarter: number;
  selectedMonth: number;
  selectedWeek: number;
  selectedDay: number;
  viewDate: Date = new Date();
  currentUser: User;
  log: string = null;
  adminNumber: string;
  userUtils = UserUtils;
  showFloat = false;
  dailyBuyerStatistic: any;
  @Input() searchRequestFromDashboard: BuyerSearchRequest;
  @Input() isDialog = false;

  multipleSelectedRequest: MultipleSelectedRequest;
  param = {};
  isFiltered = false;
  @Input() isLoad: boolean;
  isSelected: boolean;

  createSelectColumn(title: string) {
    return {
      title: title,
      type: 'custom',
      filter: false,
      renderComponent: BuyerSelectComponent,
      onComponentInitFunction: (instance: BuyerSelectComponent) => {
        instance.onSelectBuyer.subscribe((value: WrapBuyer) => {
          this.pushToSelectedRequest(value);
        });
      },
    };
  }

  constructor(private dialogService: NbDialogService,
              private commonService: CommonService,
              private buyerService: BuyerService,
              private categoryService: CategoryService,
              private localService: LocalService,
              private authService: AuthService,
              private templateService: TemplateService,
              private route: ActivatedRoute,
              private router: Router) {
    this.source = new BuyerDatasource();
  }

  ngOnInit() {
    this.param = { ...this.route.snapshot.queryParams };
    this.multipleSelectedRequest = new MultipleSelectedRequest();
    this.currentUser = this.authService.currentUser;
    this.isFilter = true;
    this.source.onChanged().subscribe((change) => {
      this.change = change;
      if (change.action === 'refresh' && this.isLoad === true) {
        if (this.param['page']) {
          this.pageChange(Number(this.param['page']));
        } else {
          this.pageChange(1);
        }
      }
      if (change.action === 'page') {
        if (this.isFilter === true) {
          delete this.param['selectedBuyerId'];
          delete this.param['year'];
          delete this.param['month'];
          delete this.param['day'];
          this.param = { ...this.param, page: change.paging.page };
          this.navigateWithParam();
        }
        this.pageChange(change.paging.page);
      }
    });
    if (this.currentUser.office.phone) {
      this.adminNumber = this.currentUser.office.phone;
    }
    if (this.oneOnOneCall) {
      if (!this.userUtils.userIsAdmin(this.currentUser)) {
        this.searchRequest.multipleUser = [];
        this.searchRequest.multipleUser.push(this.currentUser.userId);
      }
      this.searchRequest.categoryIds.push(2);
      this.isFilter = false;
      this.pageChange(1);
    }
    if (this.isDialog) {
      this.filterCondition(this.searchRequestFromDashboard);
    }
    this.preLoadAddressForFilter();
    this.preLoadSelectedEnqTime();
  }

  // table
  pageChange(pageIndex, isNextPage?: boolean, data?: any) {
    this.clearSelected();
    this.currentPage = pageIndex;
    this.searchRequest.offset = (pageIndex - 1) * this.pageSize;
    if (this.searchRequest.offset < 0) {
      this.searchRequest.offset = 0;
    }
    this.searchRequest.limit = this.pageSize;
    this.searchRequest.officeId = this.currentUser.office?.officeId;
    if (!this.isFiltered) {
      this.appendParameterToSearchRequest();
    }
    this.buyerService.searchGroups(this.searchRequest).subscribe((result: RestResult) => {
      this.groupWrapper = result.data;
      this.totalPage = Math.ceil(this.groupWrapper.totalGroups / this.pageSize);
      if (isNextPage) {
        const convertResult = BuyerUtils.convertGroupToWrapperData(this.groupWrapper.groups);
        const group = convertResult[0];
        const newParam = {
          day: group.groupData.day,
          month: group.groupData.month,
          year: group.groupData.year,
          page: this.currentPage,
        };
        this.router.navigate([], { queryParams: newParam });
        const itemSearchRequest = new BuyerSearchRequest();
        itemSearchRequest.year = group.groupData.year;
        itemSearchRequest.month = group.groupData.month;
        itemSearchRequest.day = group.groupData.day;
        this.buyerService.searchItemsSimple(itemSearchRequest).subscribe(r => {
          this.selectedBuyer = r?.data[0];
          this.buildData(this.pageSize, convertResult, () => {
            this.restoreState();
          });
          this.onSelectedBuyerChange.emit(this.selectedBuyer);
          this.param = { ...newParam, selectedBuyerId: this.selectedBuyer.buyerId };
          this.navigateWithParam();
          this.reloadList();
        });
      } else {
        let buyerId = Number(this.route.snapshot.queryParams['selectedBuyerId']);
        let year = Number(this.route.snapshot.queryParams['year']);
        let month = Number(this.route.snapshot.queryParams['month']);
        let day = Number(this.route.snapshot.queryParams['day']);
        const week = Number(this.route.snapshot.queryParamMap['week']);
        if (data) {
          buyerId = data.buyerId;
          year = new Date(data.createdDate).getFullYear();
          month = new Date(data.createdDate).getMonth() + 1;
          day = new Date(data.createdDate).getDate();
        }
        if (buyerId) {
          this.buyerService.getOne(buyerId).subscribe(r => {
            this.selectedBuyer = r.data;
            this.onSelectedBuyerChange.emit(this.selectedBuyer);
            const newParam = {
              day: day,
              month: month,
              year: year,
              page: this.currentPage,
              selectedBuyerId: buyerId,
            };
            this.router.navigate([], { queryParams: newParam });
            const convertResult = BuyerUtils.convertGroupToWrapperData(this.groupWrapper.groups);
            this.buildData(this.pageSize, convertResult, () => {
              this.restoreState();
            });
          });
        } else {
          const convertResult = BuyerUtils.convertGroupToWrapperData(this.groupWrapper.groups);
          this.buildData(this.pageSize, convertResult, () => {
            this.restoreState();
          });
          if (year && month && day && week) {
            const expand = convertResult.find(r => r.groupData.year === year && r.groupData.month === month && r.groupData.day === day
              && r.groupData.week === week);
            this.expandGroup(expand);
          }
        }
      }
    });
  }

  protected buildData(pageSize: number, realData: WrapBuyer[], callback?: any) {
    this.source.total = this.totalPage * pageSize;
    this.source.setPaging(this.currentPage, pageSize, false);
    const data = [];
    for (let i = 0; i <= (this.currentPage - 1) * pageSize - 1; i++) {
      data.push({});
    }
    realData.forEach(x => {
      data.push(x);
    });
    this.source.load(data).then(() => {
      if (callback) {
        callback();
      }
    });
  }

  public expandGroup(group: WrapBuyer, callback?: any) {
    group.isExpanded = !group.isExpanded;
    this.source.getAll()
      .then(d => {
        let wrapProspects: WrapBuyer[] = d;
        const groupIndex = wrapProspects.findIndex(x => x.groupData?.year === group.groupData.year
          && x.groupData?.month === group.groupData.month
          && x.groupData?.day === group.groupData.day);
        // && x.groupData?.week === group.groupData.week);
        const firstPart: WrapBuyer[] = wrapProspects.slice(0, groupIndex + 1);
        if (group.isExpanded) {
          const itemSearchRequest = { ...this.searchRequest };
          itemSearchRequest.year = group.groupData.year;
          itemSearchRequest.month = group.groupData.month;
          // itemSearchRequest.week = group.groupData.week;
          itemSearchRequest.day = group.groupData.day;
          this.buyerService.searchItemsSimple(itemSearchRequest)
            .subscribe((result: RestResult) => {
              const itemWrappers = BuyerUtils.convertGroupItemToWrapperData(result.data);
              const secondPart: WrapBuyer[] = wrapProspects.slice(groupIndex + 1);
              wrapProspects = [...firstPart, ...itemWrappers, ...secondPart];
              const realData = wrapProspects.filter(x => x.type);
              this.buildData(realData.length, realData, () => {
                if (callback) {
                  callback();
                }
              });
            });
        } else {
          const secondPart: WrapBuyer[] = wrapProspects.slice(groupIndex + 1 + group.groupData.totalItems);
          wrapProspects = [...firstPart, ...secondPart];
          const realData = wrapProspects.filter(x => x.type);
          this.buildData(realData.length, realData, () => {
            if (callback) {
              callback();
            }
          });
        }
      });
  }

  selectRecord(event: any) {
    const wrapProspect: WrapBuyer = event.data;
    if (wrapProspect?.type === WrapBuyerType.ITEM) {
      this.selectedBuyer = wrapProspect.data;
      this.highlightRow(this.selectedBuyer.buyerId);
      this.onSelectedBuyerChange.emit(this.selectedBuyer);
      const param = this.route.snapshot.queryParams;
      this.param = { ...param, selectedBuyerId: this.selectedBuyer.buyerId };
      if (this.isFilter) {
        this.navigateWithParam();
      }
    } else if (wrapProspect?.type === WrapBuyerType.GROUP) {
      if (this.param['year'] && this.param['month'] && this.param['day'] && this.param['week']) {
        const y = this.param['year'];
        const m = this.param['month'];
        const d = this.param['day'];
        const w = this.param['week'];
        if (y !== wrapProspect.groupData.year && m !== wrapProspect.groupData.month && d !== wrapProspect.groupData.day
          && w !== wrapProspect.groupData.week) {
          delete this.param['selectedBuyerId'];
        }
      }
      this.param = { ...this.param, year: wrapProspect.groupData.year };
      this.param = { ...this.param, month: wrapProspect.groupData.month };
      this.param = { ...this.param, day: wrapProspect.groupData.day };
      this.param = { ...this.param, week: wrapProspect.groupData.week };
      if (this.isFilter) {
        this.navigateWithParam();
      }
      this.expandGroup(wrapProspect);
    }

    // set selected buyers
    this.source.getAll().then(d => {
      const wrapProspects: WrapBuyer[] = d;
      this.selectedBuyers = wrapProspects.filter(x => {
        return x.type === WrapBuyerType.ITEM;
      })?.map(x => x.data);
      this.onSelectedBuyersChange.emit(this.selectedBuyers);
    });
  }

  getDailyStatistic() {
    const from = moment().startOf('day').valueOf();
    const to = moment().endOf('day').valueOf();
    const multipleUser = [];
    multipleUser.push(this.currentUser.userId);
    this.buyerService.getBuyerDailyStatistic(from,
      to,
      multipleUser).subscribe(result => {
      this.dailyBuyerStatistic = result.data;
    });
  }

  public highlightRow(buyerId: number) {
    this.source.getAll().then(d => {
      const wrapProspects: WrapBuyer[] = d;
      for (const wrapProspect of wrapProspects) {
        if (wrapProspect.data?.buyerId === buyerId) {
          wrapProspect.isSelected = true;
        } else {
          wrapProspect.isSelected = false;
        }
      }
      this.source.load(wrapProspects);
    });
  }

  navigateProspect(value: { currentBuyerId: number, forward: boolean }) {
    if (!value) {
      return;
    }
    this.selectedBuyer = this.selectedBuyers?.find(x => x.buyerId === value.currentBuyerId);
    this.highlightRow(value.currentBuyerId);
  }

  // filter
  onYearChange(value: number) {
    this.selectedYear = value;
    if (value) {
      const firstDay = DateUtils.getFirstDayOfYear(Number(value));
      const lastDay = DateUtils.getLastDayOfYear(Number(value));
      this.searchRequest.fromEnqDate = firstDay.valueOf();
      this.searchRequest.toEnqDate = lastDay.valueOf();
      this.param = { ...this.param, selectedYear: this.selectedYear };
    } else {
      this.searchRequest.fromEnqDate = null;
      this.searchRequest.toEnqDate = null;
      if (this.param['fromEnqDate']) {
        delete this.param['fromEnqDate'];
      }
      if (this.param['toEnqDate']) {
        delete this.param['toEnqDate'];
      }
      if (this.param['selectedYear']) {
        delete this.param['selectedYear'];
      }
      if (this.param['selectedQuarter']) {
        delete this.param['selectedQuarter'];
      }
      if (this.param['selectedMonth']) {
        delete this.param['selectedMonth'];
      }
      if (this.param['selectedWeek']) {
        delete this.param['selectedWeek'];
      }
      if (this.param['selectedDay']) {
        delete this.param['selectedDay'];
      }
    }
  }

  onMonthChange(value: number) {
    if (value && (!this.selectedYear || !this.selectedQuarter)) {
      return;
    }
    if (value) {
      this.selectedMonth = value;
      const firstDay = DateUtils.getFirstDayOfMonth(value - 1, this.selectedYear);
      const lastDay = DateUtils.getLastDayOfMonth(value - 1, this.selectedYear);
      this.searchRequest.fromEnqDate = firstDay.valueOf();
      this.searchRequest.toEnqDate = lastDay.valueOf();
      this.param = { ...this.param, selectedMonth: this.selectedMonth };
    } else if (this.selectedQuarter) {
      this.onQuarterChange(this.selectedQuarter);
      return;
    } else if (this.selectedYear) {
      this.onYearChange(this.selectedYear);
      return;
    }
  }

  onQuarterChange(value: number) {
    if (value && !this.selectedYear) {
      return;
    }
    if (value) {
      this.selectedQuarter = value;
      const firstDay = DateUtils.getFirstDayOfQuarter(value - 1, this.selectedYear);
      const lastDay = DateUtils.getLastDayOfQuarter(value - 1, this.selectedYear);
      this.searchRequest.fromEnqDate = firstDay.valueOf();
      this.searchRequest.toEnqDate = lastDay.valueOf();
      this.param = { ...this.param, selectedQuarter: this.selectedQuarter };
    } else if (this.selectedYear) {
      this.onYearChange(this.selectedYear);
      return;
    }
  }

  onWeekChange(value: number) {
    if (value && !this.selectedYear) {
      return;
    }
    if (value) {
      this.selectedWeek = value;
      const firstDay = DateUtils.getFirstDayOfWeek(value, this.selectedYear);
      const lastDay = DateUtils.getLastDayOfWeek(value, this.selectedYear);
      this.searchRequest.fromEnqDate = firstDay.valueOf();
      this.searchRequest.toEnqDate = lastDay.valueOf();
      this.param = { ...this.param, selectedWeek: this.selectedWeek };
    } else if (this.selectedMonth) {
      this.onMonthChange(this.selectedMonth);
      return;
    } else if (this.selectedQuarter) {
      this.onQuarterChange(this.selectedQuarter);
      return;
    } else if (this.selectedYear) {
      this.onYearChange(this.selectedYear);
      return;
    }
  }

  onDayChange(value: number) {
    if (value && !this.selectedWeek) {
      return;
    }
    if (value) {
      this.selectedDay = value;
      const start = new Date();
      const end = new Date();
      const startOfDay = moment().date(this.selectedDay).month(this.selectedMonth - 1);
      const endOfDay = moment().date(this.selectedDay).month(this.selectedMonth - 1);
      start.setTime(startOfDay.valueOf());
      end.setTime(endOfDay.valueOf());
      start?.setHours(0, 0, 0, 0);
      end?.setHours(23, 59, 59, 999);
      this.searchRequest.fromEnqDate = start.valueOf();
      this.searchRequest.toEnqDate = end.valueOf();
      // console.log(start);
      // console.log(end);
      this.param = { ...this.param, selectedDay: this.selectedDay };
    } else if (this.selectedWeek) {
      this.onWeekChange(this.selectedWeek);
      return;
    } else if (this.selectedMonth) {
      this.onMonthChange(this.selectedMonth);
      return;
    } else if (this.selectedQuarter) {
      this.onQuarterChange(this.selectedQuarter);
      return;
    } else if (this.selectedYear) {
      this.onYearChange(this.selectedYear);
      return;
    }
  }

  reloadList(data?: any) {
    this.pageChange(this.currentPage, null, data);
  }

  restoreState() {
    if (!this.selectedBuyer) {
      return;
    }
    this.source.getAll().then(d => {
      const wrapProspects: WrapBuyer[] = d;
      const group = wrapProspects.find(x => {
        return x?.groupData?.year === moment(this.selectedBuyer?.property?.enqDate).year()
          && x?.groupData?.month === moment(this.selectedBuyer?.property?.enqDate).month() + 1
          && x?.groupData?.day === moment(this.selectedBuyer?.property?.enqDate).date();
      });
      if (group) {
        group.isExpanded = false;
        this.expandGroup(group, () => {
          this.source.getAll().then(d1 => {
            const newWrapProspects: WrapBuyer[] = d1;
            const selectBuyerId = this.selectedBuyer?.buyerId;
            this.selectedBuyer = newWrapProspects.find(x => x?.data?.buyerId === selectBuyerId)?.data;
            this.highlightRow(selectBuyerId);
          });
        });
      }
    });
  }

  onSuburbChange(districtId) {
    this.searchRequest.districtId = districtId;
    this.searchRequest.streetId = undefined;
    if (districtId) {
      this.localService.streets(districtId).subscribe(result => {
        this.filteredStreets = result;
      });
    }
  }

  onStreetChange(streetId) {
    this.searchRequest.streetId = streetId;
  }

  onZoneChange(zoneId) {
    this.searchRequest.zoneId = zoneId;
    this.searchRequest.districtId = undefined;
    this.searchRequest.streetId = undefined;
    if (zoneId) {
      this.localService.districtsByZone(zoneId).subscribe(result => {
        this.filteredDistricts = result;
      });
    }
  }

  onCityChange(cityId) {
    this.searchRequest.cityId = cityId;
    this.searchRequest.districtId = undefined;
    this.searchRequest.zoneId = undefined;
    this.searchRequest.streetId = undefined;
    if (cityId) {
      this.localService.districts(cityId).subscribe(result => {
        this.filteredDistricts = result;
        this.filteredPostcodes = this.localService.postcodes(this.filteredDistricts);
      });
      // this.localService.zones(cityId).subscribe(result => {
      //   this.filteredZones = result;
      // });
    }
  }

  onPostcodeChange(postcode) {
    postcode = postcode && postcode === 'undefined' ? undefined : postcode;
    this.searchRequest.postcode = postcode;
    this.searchRequest.districtId = undefined;
    this.searchRequest.streetId = undefined;
    if (postcode) {
      this.localService.districtByPostcode(postcode).subscribe(result => {
        this.filteredDistricts = result;
      });
    } else {
      this.localService.districts(this.searchRequest.cityId).subscribe(result => {
        this.filteredDistricts = result;
      });
    }
  }

  onPhoneChange(value) {
    this.searchRequest.phone = value;
  }

  onRateChange(value) {
    this.searchRequest.rate = value;
  }

  onCodeChange(value) {
    if (value && value !== 'undefined') {
      this.searchRequest.code = value;
    } else {
      this.searchRequest.code = undefined;
    }
  }

  onFromMinBuyPriceChange(value) {
    this.searchRequest.fromMinBuyPrice = value;
  }

  onToMinBuyPriceChange(value) {
    this.searchRequest.toMinBuyPrice = value;
  }

  onFromMaxBuyPriceChange(value) {
    this.searchRequest.fromMaxBuyPrice = value;
  }

  onToMaxBuyPriceChange(value) {
    this.searchRequest.toMaxBuyPrice = value;
    // this.pageChange(1);
  }

  // for dashboard filtering
  filterCondition(searchRequest: BuyerSearchRequest) {
    this.searchRequest = searchRequest;
    this.isFilter = false;
    this.pageChange(1);
  }

  onBuyerModeChange(enable?: boolean) {
    this.isFiltered = true;
    this.searchRequest.isBuyerMode = enable;
    if (enable) {
      if (this.searchRequest.isTenantMode === true) {
        this.searchRequest.isTenantMode = false;
      }
      this.param = { ...this.param, isBuyerMode: this.searchRequest.isBuyerMode };
    } else {
      if (this.param['isBuyerMode']) {
        delete this.param['isBuyerMode'];
      }
    }
    if (this.param['isTenantMode']) {
      delete this.param['isTenantMode'];
    }
    this.navigateWithParam();
    this.pageChange(1);
  }

  onTenantModeChange(enable?: boolean) {
    this.isFiltered = true;
    this.searchRequest.isTenantMode = enable;
    if (enable) {
      if (this.searchRequest.isBuyerMode === true) {
        this.searchRequest.isBuyerMode = false;
      }
      this.param = { ...this.param, isTenantMode: this.searchRequest.isTenantMode };
    } else {
      if (this.param['isTenantMode']) {
        delete this.param['isTenantMode'];
      }
    }
    if (this.param['isBuyerMode']) {
      delete this.param['isBuyerMode'];
    }
    this.navigateWithParam();
    this.pageChange(1);
  }

  onStatusChange(status?: string) {
    this.searchRequest.isActive = status;
  }

  onPropertyTypeChange(value?: string) {
    if (value === 'undefined') {
      this.searchRequest.propertyType = undefined;
    } else {
      this.searchRequest.propertyType = value;
    }
  }

  onBedChange(value?: number) {
    this.searchRequest.bed = value;
  }

  onBathChange(value?: number) {
    this.searchRequest.bath = value;
  }

  onCarChange(value?: number) {
    this.searchRequest.car = value;
  }

  onSearchButtonClick() {
    this.selectedBuyer = null;
    this.param = {page: this.currentPage};
    this.isFiltered = true;
    this.appendFilterToParameter();
    this.navigateWithParam();
    this.pageChange(1);
  }

  pushToSelectedRequest(value: CustomRow) {
    const customerId = value.data?.customerId;
    if (value.selected === true) {
      this.multipleSelectedRequest.selectedIds.push(customerId);
    } else {
      const buyerIdIndex = this.multipleSelectedRequest.selectedIds.findIndex(x => x === customerId);
      if (buyerIdIndex > -1) {
        this.multipleSelectedRequest.selectedIds.splice(buyerIdIndex, 1);
      }
    }
    this.isSelected = this.multipleSelectedRequest.selectedIds.length > 0;
  }

  openMergePopup() {
    this.dialogService.open(MergeCustomersComponent, {
      context: {
        multipleSelectedRequest: this.multipleSelectedRequest,
      },
    }).onClose.subscribe((result: Buyer) => {
      if (result) {
        this.multipleSelectedRequest = new MultipleSelectedRequest();
        this.reloadList();
        this.selectedBuyer = this.selectedBuyers?.find(x => x.buyerId === result.buyerId);
        this.highlightRow(result.buyerId);
      }
    });
  }

  exportEmail() {
    const buyerSearchRequest = JSON.parse(JSON.stringify(this.searchRequest));
    buyerSearchRequest.offset = (this.currentPage - 1) * this.pageSize;
    if (this.multipleSelectedRequest.selectedIds.length > 0) {
      buyerSearchRequest.offset = 0;
    }
    buyerSearchRequest.limit = this.pageSize;
    if (this.multipleSelectedRequest.selectedIds.length >= 1) {
      buyerSearchRequest.customerIds = this.multipleSelectedRequest.selectedIds;
      // this.onSelectedCustomerIds.emit(buyerSearchRequest.customerIds);
    }
    if (this.multipleSelectedRequest.selectedIds.length === 0) {
      if (!buyerSearchRequest.fromEnqDate || !buyerSearchRequest.toEnqDate) {
        if (this.groupWrapper) {
          const end = this.groupWrapper.groups[0];
          const start = this.groupWrapper.groups[this.groupWrapper.groups.length - 1];
          const lastDay = DateUtils.getEndOfDate(end.day, end.month - 1, end.year);
          const firstDay = DateUtils.getStartOfDate(start.day, start.month - 1, start.year);
          buyerSearchRequest.toEnqDate = lastDay.valueOf();
          buyerSearchRequest.fromEnqDate = firstDay.valueOf();
        }
      }
    }

    this.buyerService.getEmail(buyerSearchRequest).subscribe((result: RestResult) => {
      if (result.data) {
        const filtered = result.data.filter(function(el) {
          return el != null;
        });
        const searchRequest = new SearchRequest();
        searchRequest.conditions = [];
        searchRequest.conditions.push({
          left: 'type',
          middle: '=',
          right: 'EMAIL',
        });
        this.templateService.search(searchRequest).subscribe(r => {
          this.dialogService.open(ExportDialogComponent, {
            context: {
              data: filtered.join(';'),
              templates: r.data,
              exportType: 'EMAIL',
            },
          }).onClose.subscribe(onCloseRes => {
            if (onCloseRes) {
              this.saveBulk(onCloseRes);
            }
          });
        });
      }
    });
  }

  exportMobile() {
    const buyerSearchRequest = JSON.parse(JSON.stringify(this.searchRequest));
    buyerSearchRequest.offset = (this.currentPage - 1) * this.pageSize;
    if (this.multipleSelectedRequest.selectedIds.length > 0) {
      buyerSearchRequest.offset = 0;
    }
    buyerSearchRequest.limit = this.pageSize;
    if (this.multipleSelectedRequest.selectedIds.length >= 1) {
      buyerSearchRequest.customerIds = this.multipleSelectedRequest.selectedIds;
      // this.onSelectedCustomerIds.emit(buyerSearchRequest.customerIds);
    }
    this.buyerService.getPhone(buyerSearchRequest).subscribe((result: RestResult) => {
      if (result.data) {
        const searchRequest = new SearchRequest();
        searchRequest.conditions = [];
        searchRequest.conditions.push({
          left: 'type',
          middle: '=',
          right: 'SMS',
        });
        this.templateService.search(searchRequest).subscribe(r => {
          this.dialogService.open(ExportDialogComponent, {
            context: {
              buyerMobile: result.data,
              templates: r.data,
              adminNumber: this.adminNumber,
              exportType: 'MOBILE',
            },
          }).onClose.subscribe(onCloseRes => {
            if (onCloseRes) {
              this.onDisplayDetail.emit();
              this.saveBulk(onCloseRes.log, onCloseRes.ids);
            }
          });
        });
      }
    });
  }

  clearSelected() {
    this.multipleSelectedRequest.selectedIds = [];
    this.source.getAll().then(d => {
      const wrapProspects: WrapBuyer[] = d;
      for (const wrapProspect of wrapProspects) {
        wrapProspect.selected = false;
      }
      this.source.load(wrapProspects);
    });
    this.isSelected = false;
  }

  deleteSelected() {
    if (this.multipleSelectedRequest.selectedIds.length <= 0) {
      return;
    }
    this.dialogService.open(ConfirmDialogComponent, {
      context: {
        content: 'Do you really want to delete this buyers?',
      },
    })
      .onClose.subscribe(res => {
      if (res === ConfirmDialogComponent.confirmOk) {
        const request = this.multipleSelectedRequest.selectedIds;
        this.buyerService.removeList(request)
          .subscribe(
            result => {
              this.commonService.info('Remove buyer list successfully');
              this.router.navigate([]);
              this.pageChange(this.currentPage);
            }, error => {
              this.commonService.warning(error.message);
            });
      }
    });
  }

  onRemoveCategory(categoryId: number) {
    if (!this.searchRequest.categoryIds) {
      this.searchRequest.categoryIds = [];
    }
    const index = this.searchRequest.categoryIds.indexOf(categoryId);
    if (index !== -1) {
      this.searchRequest.categoryIds.splice(index, 1);
    }
  }

  onSelectCategory(categoryId: number) {
    if (!this.searchRequest.categoryIds) {
      this.searchRequest.categoryIds = [];
    }
    const index = this.searchRequest.categoryIds.indexOf(categoryId);
    if (index === -1) {
      this.searchRequest.categoryIds.push(categoryId);
    }
  }

  onRemoveUser(userId: number) {
    this.searchRequest.userId = null;
  }

  onSelectUser(userId: number) {
    this.searchRequest.userId = userId;
  }

  saveBulk(log: string, buyerIds?: number[]) {
    if (log === null) {
      this.commonService.warning('Log must not be empty');
    } else {
      if (buyerIds) {
        this.searchRequest.buyerIds = buyerIds;
      }
      this.searchRequest.log = log;
      this.buyerService.bulkSaveBuyerLog(this.searchRequest).subscribe(
        result => {
          this.commonService.info('Bulk add log success');
          log = null;
          this.pageChange(1);
        },
        error => {
          this.commonService.warning(error.message);
        },
      );
    }
  }

  appendFilterToParameter() {
    //  Temporary disable append to parameter in overview dashboard
    if (this.oneOnOneCall) {
      return;
    }
    if (this.searchRequest.cityId) {
      this.param = { ...this.param, cityId: this.searchRequest.cityId };
    } else {
      delete this.param['cityId'];
      delete this.param['postcode'];
      delete this.param['districtId'];
    }
    if (this.searchRequest.postcode) {
      this.param = { ...this.param, postcode: this.searchRequest.postcode };
    } else {
      delete this.param['postcode'];
      delete this.param['districtId'];
      delete this.param['streetId'];
    }
    if (this.searchRequest.zoneId) {
      this.param = { ...this.param, zoneId: this.searchRequest.zoneId };
    } else {
      delete this.param['districtId'];
      delete this.param['streetId'];
    }
    if (this.searchRequest.districtId) {
      this.param = { ...this.param, districtId: this.searchRequest.districtId };
    } else {
      delete this.param['districtId'];
      delete this.param['streetId'];
    }
    if (this.searchRequest.streetId) {
      this.param = { ...this.param, streetId: this.searchRequest.streetId };
    } else {
      delete this.param['streetId'];
    }
    if (this.searchRequest.rate) {
      this.param = { ...this.param, rate: this.searchRequest.rate };
    } else {
      delete this.param['rate'];
    }
    if (this.searchRequest.categoryIds) {
      this.param['categoryIds'] = this.searchRequest.categoryIds;
    } else {
      delete this.param['categoryIds'];
    }
    if (this.searchRequest.userId) {
      this.param = { ...this.param, userId: this.searchRequest.userId };
    } else {
      delete this.param['userId'];
    }
    if (this.searchRequest.fromMinBuyPrice) {
      this.param = { ...this.param, fromMinBuyPrice: this.searchRequest.fromMinBuyPrice };
    } else {
      delete this.param['fromMinBuyPrice'];
    }
    if (this.searchRequest.toMaxBuyPrice) {
      this.param = { ...this.param, toMaxBuyPrice: this.searchRequest.toMaxBuyPrice };
    } else {
      delete this.param['toMaxBuyPrice'];
    }
    if (this.searchRequest.isActive) {
      this.param = { ...this.param, isActive: this.searchRequest.isActive };
    } else {
      delete this.param['isActive'];
    }
    if (this.searchRequest.code) {
      this.param = { ...this.param, code: this.searchRequest.code };
    } else {
      delete this.param['code'];
    }
    if (this.searchRequest.propertyType) {
      this.param = { ...this.param, propertyType: this.searchRequest.propertyType };
    } else {
      delete this.param['propertyType'];
    }
    if (this.searchRequest.bed) {
      this.param = { ...this.param, bed: this.searchRequest.bed };
    } else {
      delete this.param['bed'];
    }
    if (this.searchRequest.bath) {
      this.param = { ...this.param, bath: this.searchRequest.bath };
    } else {
      delete this.param['bath'];
    }
    if (this.searchRequest.car) {
      this.param = { ...this.param, car: this.searchRequest.car };
    } else {
      delete this.param['car'];
    }
    if (this.searchRequest.phone) {
      this.param = { ...this.param, phone: this.searchRequest.phone };
    } else {
      delete this.param['phone'];
    }
    if (this.searchRequest.fromEnqDate) {
      this.param = { ...this.param, fromEnqDate: this.searchRequest.fromEnqDate };
    } else {
      delete this.param['fromEnqDate'];
    }
    if (this.searchRequest.toEnqDate) {
      this.param = { ...this.param, toEnqDate: this.searchRequest.toEnqDate };
    } else {
      delete this.param['toEnqDate'];
    }
  }

  appendParameterToSearchRequest() {
    const params = this.route.snapshot.queryParams;
    if (params['cityId']) {
      this.searchRequest.cityId = Number(params['cityId']);
    }
    if (params['zoneId']) {
      this.searchRequest.zoneId = Number(params['zoneId']);
    }
    if (params['postcode']) {
      this.searchRequest.postcode = params['postcode'];
    }
    if (params['districtId']) {
      this.searchRequest.districtId = Number(params['districtId']);
    }
    if (params['streetId']) {
      this.searchRequest.streetId = Number(params['streetId']);
    }
    if (params['rate']) {
      this.searchRequest.rate = Number(params['rate']);
    }
    if (params['categoryIds']) {
      const categoryIds = params['categoryIds'];
      if (typeof categoryIds === 'string') {
        this.searchRequest.categoryIds = [];
        this.searchRequest.categoryIds.push(Number(categoryIds));
      } else {
        this.searchRequest.categoryIds = categoryIds.map(id => {
          return Number(id);
        });
      }
    }
    if (params['userId']) {
      this.searchRequest.userId = Number(params['userId']);
    }
    if (params['phone']) {
      this.searchRequest.phone = params['phone'];
    }
    if (params['fromMinBuyPrice']) {
      this.searchRequest.fromMinBuyPrice = Number(params['fromMinBuyPrice']);
    }
    if (params['toMaxBuyPrice']) {
      this.searchRequest.toMaxBuyPrice = Number(params['toMaxBuyPrice']);
    }
    if (params['isActive']) {
      this.searchRequest.isActive = params['isActive'];
    }
    if (params['code']) {
      this.searchRequest.code = params['code'];
    }
    if (params['propertyType']) {
      this.searchRequest.propertyType = params['propertyType'];
    }
    if (params['bed']) {
      this.searchRequest.bed = Number(params['bed']);
    }
    if (params['bath']) {
      this.searchRequest.bath = Number(params['bath']);
    }
    if (params['car']) {
      this.searchRequest.car = Number(params['car']);
    }
    if (params['fromEnqDate']) {
      this.searchRequest.fromEnqDate = Number(params['fromEnqDate']);
    }
    if (params['toEnqDate']) {
      this.searchRequest.toEnqDate = Number(params['toEnqDate']);
    }
    if (params['isBuyerMode']) {
      this.searchRequest.isBuyerMode = Boolean(params['isBuyerMode']);
    }
    if (params['isTenantMode']) {
      this.searchRequest.isTenantMode = Boolean(params['isTenantMode']);
    }
  }

  //  push param to current url
  navigateWithParam() {
    this.router.navigate([], {
      queryParams: this.param,
    });
  }

  preLoadAddressForFilter() {
    if (this.route.snapshot.queryParamMap.keys.length === 0) {
      return;
    }
    const postcode = Number(this.route.snapshot.queryParamMap.get('postcode'));
    const districtId = this.route.snapshot.queryParamMap.get('districtId');
    if (postcode) {
      this.localService.districtByPostcode(postcode).subscribe(r => {
        this.filteredDistricts = r;
      });
    }
    if (districtId) {
      this.localService.streets(Number(districtId)).subscribe(result => {
        this.filteredStreets = result;
      });
    }
  }

  preLoadSelectedEnqTime() {
    if (this.route.snapshot.queryParamMap.keys.length === 0) {
      return;
    }
    const selectedYear = this.route.snapshot.queryParamMap.get('selectedYear');
    const selectedQuarter = this.route.snapshot.queryParamMap.get('selectedQuarter');
    const selectedMonth = this.route.snapshot.queryParamMap.get('selectedMonth');
    const selectedWeek = this.route.snapshot.queryParamMap.get('selectedWeek');
    const selectedDay = this.route.snapshot.queryParamMap.get('selectedDay');
    if (selectedYear) {
      this.selectedYear = Number(selectedYear);
    }
    if (selectedQuarter) {
      this.selectedQuarter = Number(selectedQuarter);
    }
    if (selectedMonth) {
      this.selectedMonth = Number(selectedMonth);
    }
    if (selectedWeek) {
      this.selectedWeek = Number(selectedWeek);
    }
    if (selectedDay) {
      this.selectedDay = Number(selectedDay);
    }
  }

  onSearchByPostcode(value: number) {
    this.searchRequest.postcode = Number(value);
    this.searchRequest.districtId = undefined;
    this.searchRequest.streetId = undefined;
    if (value && value !== 0) {
      this.localService.districtByPostcode(value).subscribe(result => {
        this.filteredDistricts = result;
      });
    }
  }
}
