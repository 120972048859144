import { Component, OnInit } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { ToastService } from '../../../../shared/services/toast.service';
import { ProspectService } from '../../../../shared/services/prospect.service';
import { ProspectListCustomCellComponent } from '../common/prospect-list-custom-cell-component';
import { City } from '../../../../shared/models/response/address/city';
import { Street } from '../../../../shared/models/response/address/street';
import { ProspectActionCellComponent } from '../common/prospect-action-cell-component';
import { CommonService } from '../../../../shared/services/common.service';
import { ProspectUtils, WrapProspect } from '../common/prospect-utils';
import { ProspectSelectComponent } from '../common/prospect-select-component';
import { ProspectListCommonComponent } from '../common/prospect-list-common.component';
import { LocalService } from '../../../../shared/services/local.service';
import { AuthService } from '../../../../shared/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import {TemplateService} from '../../../../shared/services/template.service';

@Component({
  selector: 'ngx-prospect-list-advance',
  templateUrl: './prospect-list-advance.component.html',
  styleUrls: ['./prospect-list-advance.component.scss'],
})
export class ProspectListAdvanceComponent extends ProspectListCommonComponent implements OnInit {

  columnGroup = {
    PROPERTY: 'Property',
    CUSTOMER: 'Customer',
    CONTACT: 'Contact configuration',
    ADDRESS: 'Address',
    OTHERS: 'Others',
  };

  indoorColumnsDefinition = {
    checkbox: {
      enable: true,
      value: this.createSelectColumn(''),
    },
    unit: {
      enable: true,
      value: ProspectUtils.createColumn('Unit', ['address.unit']),
      group: this.columnGroup.ADDRESS,
      name: 'Unit',
    },
    number: {
      enable: true,
      value: ProspectUtils.createColumn('Number', ['address.numberFrom']),
      group: this.columnGroup.ADDRESS,
      name: 'Number',
    },
    street: {
      enable: true,
      value: ProspectUtils.createColumn('Street', ['address.street.name']),
      group: this.columnGroup.ADDRESS,
      name: 'Street',
    },
    farm: {
      enable: false,
      value: ProspectUtils.createColumn('Farm', ['address.street.farm.name']),
      group: this.columnGroup.ADDRESS,
      name: 'Farm',
    },
    suburb: {
      enable: true,
      value: ProspectUtils.createColumn('Suburb', ['address.street.district.name']),
      group: this.columnGroup.ADDRESS,
      name: 'Suburb',
    },
    zone: {
      enable: false,
      value: ProspectUtils.createColumn('Zone', ['address.street.district.zone.name']),
      group: this.columnGroup.ADDRESS,
      name: 'Zone',
    },
    // city: {
    //   enable: true,
    //   value: ProspectUtils.createColumn('City', ['address?.street.district.city.name']),
    //   group: this.columnGroup.ADDRESS,
    //   name: 'City',
    // },
    propertyType: {
      enable: false,
      value: ProspectUtils.createColumn('Type', ['property.type']),
      group: this.columnGroup.PROPERTY,
      name: 'Type',
    },
    propertyBed: {
      enable: false,
      value: ProspectUtils.createColumn('Bed', ['property.bed']),
      group: this.columnGroup.PROPERTY,
      name: 'Bed',
    },
    propertyBath: {
      enable: false,
      value: ProspectUtils.createColumn('Bath', ['property.bath']),
      group: this.columnGroup.PROPERTY,
      name: 'Bath',
    },
    propertyCar: {
      enable: false,
      value: ProspectUtils.createColumn('Car', ['property.car']),
      group: this.columnGroup.PROPERTY,
      name: 'Car',
    },
    propertyLand: {
      enable: false,
      value: ProspectUtils.createColumn('Land', ['property.land']),
      group: this.columnGroup.PROPERTY,
      name: 'Land',
    },
    propertySell: {
      enable: true,
      value: ProspectUtils.createColumn('Sell', ['property.sell']),
      group: this.columnGroup.PROPERTY,
      name: 'Sell',
    },
    propertySellPrice: {
      enable: true,
      value: ProspectUtils.createColumn('Sell Price', ['property.sellPrice'],
        ProspectListCustomCellComponent.VALUE_TYPES.CURRENCY),
      group: this.columnGroup.PROPERTY,
      name: 'Sell Price',
    },
    propertyRent: {
      enable: true,
      value: ProspectUtils.createColumn('Rent', ['property.rent']),
      group: this.columnGroup.PROPERTY,
      name: 'Rent',
    },
    propertyRentPrice: {
      enable: true,
      value: ProspectUtils.createColumn('Rent Price', ['property.rentPrice'],
        ProspectListCustomCellComponent.VALUE_TYPES.CURRENCY),
      group: this.columnGroup.PROPERTY,
      name: 'Rent Price',
    },
    propertyOtm: {
      enable: true,
      value: ProspectUtils.createColumn('Otm', ['property.otm']),
      group: this.columnGroup.PROPERTY,
      name: 'Otm',
    },
    propertyOtmDate: {
      enable: true,
      value: ProspectUtils.createColumn('Otm Date', ['property.otmDate'],
        ProspectListCustomCellComponent.VALUE_TYPES.DATE),
      group: this.columnGroup.PROPERTY,
      name: 'Otm Date',
    },
    propertyVac: {
      enable: true,
      value: ProspectUtils.createColumn('Vac', ['property.vac']),
      group: this.columnGroup.PROPERTY,
      name: 'Vac',
    },
    propertyVacDate: {
      enable: true,
      value: ProspectUtils.createColumn('Vac Date', ['property.vacDate'],
        ProspectListCustomCellComponent.VALUE_TYPES.DATE),
      group: this.columnGroup.PROPERTY,
      name: 'Vac Date',
    },
    customerName: {
      enable: true,
      value: ProspectUtils.createColumn('Name', ['customer.name']),
      group: this.columnGroup.CUSTOMER,
      name: 'Name',
    },
    customerSName: {
      enable: true,
      value: ProspectUtils.createColumn('Surname', ['customer.surname']),
      group: this.columnGroup.CUSTOMER,
      name: 'Surname',
    },
    phoneInfo: {
      enable: true,
      value: ProspectUtils.createColumn('Phone', ['customer.phone']),
      group: this.columnGroup.CONTACT,
      name: 'Phone',
    },
    mPhoneInfo: {
      enable: true,
      value: ProspectUtils.createColumn('Mobile Phone', ['customer.mobilePhone']),
      group: this.columnGroup.CONTACT,
      name: 'Mobile Phone',
    },
    callAllow: {
      enable: true,
      value: ProspectUtils.createColumn('Call', ['call']),
      group: this.columnGroup.CONTACT,
      name: 'Call',
    },
    smsAllow: {
      enable: true,
      value: ProspectUtils.createColumn('SMS', ['sms']),
      group: this.columnGroup.CONTACT,
      name: 'Sms',
    },
    doorAllow: {
      enable: true,
      value: ProspectUtils.createColumn('Door', ['door']),
      group: this.columnGroup.CONTACT,
      name: 'Door',
    },
    mailAllow: {
      enable: true,
      value: ProspectUtils.createColumn('Mail', ['mail']),
      group: this.columnGroup.CONTACT,
      name: 'Mail',
    },
    rate: {
      enable: true,
      value: ProspectUtils.createColumn('Rate', ['rate']),
      group: this.columnGroup.OTHERS,
      name: 'Rate',
    },
    action: {
      enable: true,
      value: this.createActionColumn('Action'),
      name: 'Action',
    },
  };

  columnsDefinition: any = this.indoorColumnsDefinition;

  settings = {
    mode: 'external',
    hideSubHeader: true,
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    pager: {
      display: true,
      perPage: this.pageSize,
    },
    columns: this.createColumns(),
    rowClassFunction: (row) => {
      return ProspectUtils.highlightProspectRow(row?.data);
    },
  };

  createActionColumn(title: string) {
    return {
      title: title,
      type: 'custom',
      filter: false,
      renderComponent: ProspectActionCellComponent,
      onComponentInitFunction: (instance: ProspectActionCellComponent) => {
        instance.onAddProspect.subscribe(value => {
          this.addProspectsByUnitRange(value);
        });
      },
    };
  }

  createSelectColumn(title: string) {
    return {
      title: title,
      type: 'custom',
      filter: false,
      renderComponent: ProspectSelectComponent,
      onComponentInitFunction: (instance: ProspectSelectComponent) => {
        instance.onSelectProspect.subscribe((value: WrapProspect) => {
          this.checkProspectToRemove(value);
        });
      },
    };
  }

  createColumns() {
    const columns = {};
    for (const key of Object.keys(this.columnsDefinition)) {
      if (this.columnsDefinition[key].enable === true) {
        columns[key] = this.columnsDefinition[key].value;
      }
    }
    return columns;
  }

  constructor(private _dialogService: NbDialogService,
              private _toastService: ToastService,
              private _prospectService: ProspectService,
              private _commonService: CommonService,
              private _localService: LocalService,
              private _authService: AuthService,
              private _templateService: TemplateService,
              private _router: Router,
              private _route: ActivatedRoute) {
    super(_dialogService, _toastService, _prospectService, _commonService, _localService, _authService, _router, _route, _templateService);
  }

  //
  ngOnInit() {
    this.param = this.route.snapshot.queryParams;
    this.currentUser = this.authService.currentUser;
    this.source.onChanged().subscribe((change) => {
      this.change = change;
      if (change.action === 'refresh') {
        this.pageChange(1);
      }
      if (change.action === 'page') {
        delete this.param['selectedProspectId'];
        delete this.param['selectedStreetId'];
        this.param = { ...this.param, page: change.paging.page };
        this.navigateWithParam();
        this.pageChange(change.paging.page);
      }
    });
    this.param = { ...this.param, tab: this._selectedMode };
    this.preLoadAddressForFilter();
  }

  // ==== table ====

  onColumnDisplayOptionCheckedChange(columnsDefinition: any) {
    if (columnsDefinition) {
      this.columnsDefinition = columnsDefinition;
      const setting = { ...this.settings };
      setting.columns = this.createColumns();
      this.settings = setting;
    }
  }

  search() {
    this.isFiltered = true;
    this.pageChange(1);
  }

}
