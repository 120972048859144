import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { City } from '../../../../../shared/models/response/address/city';
import { District } from '../../../../../shared/models/response/address/district';
import { Street } from '../../../../../shared/models/response/address/street';
import { ProspectSearchRequest } from '../../../../../shared/models/request/prospect/prospect-search-request';
import { ComponentPermission } from '../../../../../component.permission';
import { Observable } from 'rxjs';
import { Postcode } from '../../../../../shared/models/response/postcode';
import { map, mergeMap } from 'rxjs/operators';
import { SearchRequest } from '../../../../../shared/models/request/search-request';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { PostcodeService } from '../../../../../shared/services/postcode.service';
import { ActivatedRoute } from '@angular/router';
import { StringUtils } from '../../../../../shared/common/string-utils';

@Component({
  selector: 'ngx-prospect-list-filter-advance',
  templateUrl: './prospect-list-filter-advance.component.html',
  styleUrls: ['./prospect-list-filter-advance.component.scss'],
})
export class ProspectListFilterAdvanceComponent implements OnInit {
  @Input() columnsDefinition: any;
  @Input() columnGroup: any;
  @Input() districts: District[];
  @Input() streets: Street[];
  @Input() cities: City[];
  @Input() sortTypesText: any;
  @Input() farm: string;
  @Input() zone: string;
  @Output() onColumnDisplayOptionChange = new EventEmitter();
  @Output() onSuburbChange = new EventEmitter();
  @Output() onCityChange = new EventEmitter();
  @Output() onAddNewProspect = new EventEmitter();
  @Output() onOpenShortcut = new EventEmitter();
  @Output() onSearch = new EventEmitter();
  @Output() bulkSave = new EventEmitter();
  @Input() total: number;
  @Input() totalOtm: number;
  @Input() totalVac: number;
  @Input() totalRent: number;

  @Input() searchRequest: ProspectSearchRequest;
  @Input() postcode: number;
  @Output() onSearchByPostcode = new EventEmitter();
  filterByPostcode = false;
  filterPostcode$: Observable<Postcode[]>;

  rateOptions = [
    { value: 0, label: '0' },
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
  ];
  ComponentPermission = ComponentPermission;

  constructor(private postcodeService: PostcodeService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.filterPostcode$ = new Observable((observer: any) => {
      observer.next(this.postcode);
    }).pipe(
      map((query: string) => {
        this.postcode = Number(query);
        this.searchSuburbByPostcode();
        return null;
      }),
    );
    if (this.route.snapshot.queryParams['postcode']) {
      this.filterByPostcode = true;
    }
  }

  onColumnDisplayOptionCheckedChange(checked: boolean, columnName: string) {
    if (this.columnsDefinition[columnName]) {
      this.columnsDefinition[columnName].enable = checked;
    }
  }

  onSuburbSelectChange() {
    this.onSuburbChange.emit(this.searchRequest.districtId);
  }

  onCitySelectChange() {
    this.onCityChange.emit(this.searchRequest.cityId);
  }

  addNewProspect() {
    this.onAddNewProspect.emit();
  }

  openShortcut() {
    this.onOpenShortcut.emit();
  }

  applyColumnDisplayOptionChange() {
    this.onColumnDisplayOptionChange.emit(this.columnsDefinition);
  }

  search() {
    this.onSearch.emit();
  }

  onBulkSave() {
    this.bulkSave.emit();
  }

  searchPostcode(keyword: string): Observable<Postcode[]> {
    const searchRequest = new SearchRequest();
    searchRequest.conditions = [];
    searchRequest.orders = [];
    searchRequest.conditions.push(
      {
        left: 'code',
        middle: '=',
        right: keyword,
      },
    );
    searchRequest.orders.push(
      {
        left: 'code',
        right: 'asc'
      }
    );
    searchRequest.offset = 0;
    searchRequest.limit = 10;
    return this.postcodeService.search(searchRequest).pipe(
      map(result => result.data),
    );
  }

  selectPostcode(type: TypeaheadMatch) {
    this.postcode = Number(type.item.code);
    this.searchSuburbByPostcode();
  }

  searchSuburbByPostcode() {
    if (this.postcode === null) {
      return;
    }
    this.filterByPostcode = true;
    this.onSearchByPostcode.emit(this.postcode);
  }

  onSelectRate(event: any) {
    if (event === null) {
      this.searchRequest.rate = undefined;
    } else {
      this.searchRequest.rate = Number(event);
    }
  }
}
