import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Path } from '../common/path';
import { RestResult } from '../models/response/rest-result';
import { CommonService } from './common.service';
import { SearchRequest } from '../models/request/search-request';
import { ProspectRequest } from '../models/request/prospect/prospect-request';
import { City } from '../models/response/address/city';
import { Constant } from '../common/constant';
import { CityOfficeRequest } from '../models/request/address/city-office-request';

@Injectable()
export class CityService {

  constructor(protected http: HttpClient,
              protected commonService: CommonService) {
  }

  getOne(cityId: number): Observable<RestResult> {
    return this.http.get<any>(
      Path.API_ENDPOINT +
      Path.CITY +
      Path.CITY_ID.replace(':cityId', cityId.toString()),
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }


  search(request: SearchRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.CITY +
      Path.SEARCH, request,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  create(request: ProspectRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.CITY, request).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  remove(cityId: number): Observable<RestResult> {
    return this.http.delete<any>(
      Path.API_ENDPOINT +
      Path.CITY +
      Path.CITY_ID.replace(':cityId', cityId.toString()),
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  update(request: ProspectRequest): Observable<RestResult> {
    return this.http.put<any>(
      Path.API_ENDPOINT +
      Path.CITY, request).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  // ===== support for autocomplete ============
  searchByKeyword(keyword: string): Observable<City[]> {
    const searchRequest = new SearchRequest();
    searchRequest.conditions = new Array();
    searchRequest.conditions.push(
      {
        left: 'name',
        middle: 'like',
        right: keyword,
      },
    );
    searchRequest.conditions.push(
      {
        left: 'code',
        middle: '=',
        right: keyword,
      },
    );
    searchRequest.conditionType = Constant.CONDITION_TYPE.OR;
    searchRequest.offset = 0;
    searchRequest.limit = 10;
    return this.search(searchRequest).pipe(
      map(result => result.data),
    );
  }

  createOrDeleteCityOffice(request: CityOfficeRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.CITY + Path.ASSIGN, request).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  fetchCityBySelectedOffice(officeId: number): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.CITY + Path.ASSIGNED, officeId).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }
}
