export class ContractChecklistRequest {
  contractChecklistId: number;
  isDone: boolean;
  description: string;
  prospectId: number;
  createdBy: string;
  createdDate: number;
  isNew?: boolean;
  isEditing?: boolean;
}
