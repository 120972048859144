import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NbDialogRef} from '@nebular/theme';
import {CommonService} from '../../../../../shared/services/common.service';
import {FormService} from '../../../../../shared/services/form.service';
import {TaskType} from '../../../../../shared/models/response/task-type';
import {TaskTypeService} from '../../../../../shared/services/task-type.service';
import {TaskTypeCategory} from '../../../../../shared/models/response/task-type-category';
import { ComponentPermission } from '../../../../../component.permission';

@Component({
  selector: 'ngx-task-type-update',
  templateUrl: './task-type-update.component.html',
  styleUrls: ['./task-type-update.component.scss'],
})
export class TaskTypeUpdateComponent implements OnInit {

  ComponentPermission = ComponentPermission;
  form: FormGroup;
  isSubmitted = false;
  categories: TaskTypeCategory[];

  @Input()
  data: TaskType;

  constructor(protected ref: NbDialogRef<TaskTypeUpdateComponent>,
              private taskTypeService: TaskTypeService,
              private commonService: CommonService,
              private formService: FormService) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.form = new FormGroup({
      taskTypeId: new FormControl(this.data.taskTypeId, [
        Validators.required,
      ]),
      name: new FormControl(this.data.name, [
        Validators.required,
      ]),
      description: new FormControl(this.data.description),
      color: new FormControl(this.data.color),
      taskTypeCategoryId: new FormControl(this.data?.category?.taskTypeCategoryId),
    });
  }

  submit() {
    const form = this.form.value;
    this.formService.extractErrorMessage(this.form);
    if (this.form.invalid) {
      this.isSubmitted = true;
      return;
    }
    this.taskTypeService.update(form).subscribe(
      result => {
        this.commonService.info('Update success.');
        this.ref.close(result);
      },
      error => {
        this.commonService.warningHtml(error.message);
      },
    );
  }

  cancel() {
    this.ref.close();
  }

  showError(name: string): string {
    return this.form.controls[name]
    && this.form.controls[name].errors
    && (this.form.controls[name].dirty
      || this.form.controls[name].touched
      || this.isSubmitted) ? 'has-error' : '';
  }

  colorChange(colorValue: string) {
    this.form.controls.color.setValue(colorValue);
  }

}
