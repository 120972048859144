import { HttpClient, HttpParams } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Path} from '../common/path';
import {RestResult} from '../models/response/rest-result';
import {CommonService} from './common.service';
import {SearchRequest} from '../models/request/search-request';
import {BuyerRequest} from '../models/request/buyer/buyer-request';
import {BuyerSearchRequest} from '../models/request/buyer/buyer-search-request';
import {BuyerContactRequest} from '../models/request/buyer/buyer-contact-request';
import {CustomerBoardSearchRequest} from '../models/request/buyer/customer-board-search-request';
import {MergeBuyersRequest} from '../models/request/buyer/merge-buyers-request';
import {MultipleSelectedRequest} from '../models/common/multiple-selected-request';
import { ProspectStatisticRequest } from '../models/request/prospect/prospect-statistic-request';

@Injectable()
export class BuyerService {

  constructor(protected http: HttpClient,
              protected commonService: CommonService) {
  }

  getOne(buyerId: number): Observable<RestResult> {
    return this.http.get<any>(
      Path.API_ENDPOINT +
      Path.BUYER +
      Path.BUYER_ID.replace(':buyerId', buyerId.toString()),
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  getByCustomerId(customerId: number): Observable<RestResult> {
    return this.http.get<any>(
      Path.API_ENDPOINT +
      Path.BUYER +
      Path.CUSTOMER +
      Path.CUSTOMER_ID.replace(':customerId', customerId.toString()),
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  getByAddressId(addressId: number): Observable<RestResult> {
    return this.http.get<any>(
      Path.API_ENDPOINT +
      Path.BUYER +
      Path.ADDRESS +
      Path.ADDRESS_ID.replace(':addressId', addressId.toString()),
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  search(request: SearchRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.BUYER +
      Path.SEARCH, request,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  create(request: BuyerRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.BUYER, request).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  remove(buyerId: number): Observable<RestResult> {
    return this.http.delete<any>(
      Path.API_ENDPOINT +
      Path.BUYER +
      Path.BUYER_ID.replace(':buyerId', buyerId.toString()),
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }
  removeList(customerIds: number[], streetIds?: number[]): Observable<RestResult> {
    let params: HttpParams = new HttpParams();
    if (customerIds && customerIds.length > 0) {
      params = params.append('customerIds', customerIds.join(','));
    }
    if (streetIds && streetIds.length > 0) {
      params = params.append('streetIds', streetIds.join(','));
    }
    return this.http.delete<any>(
      Path.API_ENDPOINT + Path.BUYER + Path.LIST,
      { params },
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  update(request: BuyerRequest): Observable<RestResult> {
    return this.http.put<any>(
      Path.API_ENDPOINT +
      Path.BUYER, request).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  searchGroups(request: BuyerSearchRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.BUYER_GROUP_SEARCH, request,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  searchItems(request: BuyerSearchRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.BUYER_SEARCH_ITEM,
      request,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  searchItemsSimple(request: BuyerSearchRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.BUYER_SEARCH_ITEM + Path.SIMPLE,
      request,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  logContactStatus(buyerId: number, request: BuyerContactRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.BUYER_CONTACT_LOG.replace(':buyerId', buyerId.toString()), request).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  searchCustomerBoard(request: CustomerBoardSearchRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.BUYER +
      Path.CUSTOMER_BOARD +
      Path.SEARCH, request,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  mergeCustomers(request: MultipleSelectedRequest): Observable<RestResult> {
    const requestCopy = {...request};
    const buyerIdIndex = requestCopy.selectedIds.findIndex(x => x === requestCopy.chooseId);
    if (buyerIdIndex > -1) {
      requestCopy.selectedIds.splice(buyerIdIndex, 1);
    }
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.MERGE_BUYERS,
      requestCopy,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  getEmail(request: BuyerSearchRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.BUYER_SEARCH_EMAIL,
      request,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  getPhone(request: BuyerSearchRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.BUYER_SEARCH_PHONE,
      request,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  bulkSaveBuyerLog(request: BuyerSearchRequest) {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.BUYER + Path.LOG + Path.MULTIPLE, request).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  getBuyerDailyStatistic(fromDate: number, toDate: number,
                         userIds?: number[]): Observable<RestResult> {
    let params: HttpParams = new HttpParams();
    params = params.append('fromDate', fromDate + '');
    params = params.append('toDate', toDate + '');
    if (userIds) {
      params = params.append('userId', userIds.join(', '));
    }
    return this.http.get<any>(
      Path.API_ENDPOINT +
      Path.BUYER + Path.STATISTIC + Path.DAILY, { params }).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  updateAll(request: BuyerRequest[]): Observable<RestResult> {
    return this.http.put<any>(
      Path.API_ENDPOINT +
      Path.BUYER + Path.LIST, request).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  saveOpenHome(request: BuyerRequest[]): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.BUYER + Path.OPEN_HOME, request).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }
}
