<nb-card *appPermissionCheck="ComponentPermission.CUSTOMER_BOARD">
  <nb-card-header *ngIf='passDailyGoal === true'>
    <ngx-customer-board-filter-advance
      [districts]="filteredDistricts"
      [streets]="filteredStreets"
      [cities]="cities"
      [zones]="filteredZones"
      [farms]="filteredFarms"
      [postcodes]='filteredPostcodes'
      [searchRequest]="searchRequest"
      [postcode]='searchRequest.postcode'
      (onSuburbChange)="onSuburbChange($event)"
      (onCityChange)="onCityChange($event)"
      (onFarmChange)="onFarmChange($event)"
      (onZoneChange)="onZoneChange($event)"
      (onStreetChange)="onStreetChange($event)"
      (onPostcodeChange)='onPostcodeChange($event)'
      (onSelectCategory)="onSelectCategory($event)"
      (onRemoveCategory)="onRemoveCategory($event)"
      (onSelectUser)="onSelectUser($event)"
      (onRemoveUser)="onRemoveUser($event)"
      (onRateChange)='onRateChange($event)'
      (onSearch)="onSearchButtonClick()"
      (onSearchByPostcode)='onSearchByPostcode($event)'
    ></ngx-customer-board-filter-advance>
  </nb-card-header>
  <nb-card-body>
    <table *ngIf="customerBoardResponse != null && passDailyGoal === true">
      <!-- SRFI LABEL -->
      <tr>
        <td class="no-border"></td>
        <td class="no-border" style="width:20px"></td>
        <td>SSN</td>
        <td>SS</td>
        <td>S</td>
      </tr>
      <!--Inactive-->
      <tr>
        <td rowspan="2" class="board-gray1">Inactive</td>
        <td class="board-blue1">S</td>
        <td class="board-gray1 load-prospect" *ngFor="let i of [1,2,3]" (click)="loadBuyer('SRFI', 'S', i)">
          {{getValueSRFI('S', i)}}
        </td>
      </tr>
      <tr>
        <td class="board-green1">R</td>
        <td class="board-gray1 load-prospect" *ngFor="let i of [1,2,3]" (click)="loadBuyer('SRFI', 'R', i)">
          {{getValueSRFI('R', i)}}
        </td>
      </tr>
      <!--Active-->
      <tr>
        <td rowspan="2">Active</td>
        <td style="width:20px">F</td>
        <td class="load-prospect"
            [ngClass]="{
                    'board-gray1': i <= 3 ,
                    'board-blue1': i >= 4 && i < 7,
                    'board-blue2': i === 7,
                    'board-orange1': i >= 8 && i < 11,
                    'board-orange2': i === 11,
                    'board-green1': i === 12,
                    'board-yellow': i >= 13
                    }"
            *ngFor="let i of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16]"
            (click)="loadBuyer('SRFI', 'F', i)"
        >
          {{getValueSRFI('F', i)}}
        </td>
      </tr>
      <tr>
        <td style="width:20px">I</td>
        <td class="load-prospect"
            [ngClass]="{
                    'board-gray1': i <= 3 ,
                    'board-blue1': i >= 4 && i < 7,
                    'board-blue2': i === 7,
                    'board-orange1': i >= 8 && i < 11,
                    'board-orange2': i === 11,
                    'board-green1': i === 12,
                    'board-yellow': i >= 13
                    }"
            *ngFor="let i of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16]"
            (click)="loadBuyer('SRFI', 'I', i)"
        >{{getValueSRFI('I', i)}}</td>
      </tr>
      <!-- LABEL -->
      <tr class="no-border-left">
        <td class="no-border"></td>
        <td class="no-border" style="width:20px"></td>
        <td class="tdLabel2"
            [ngClass]="{
                    'title-gray1': i <= 2 ,
                    'title-blue1': i >= 3 && i < 6,
                    'title-blue2': i === 6,
                    'title-orange1': i >= 7 && i < 10,
                    'title-orange2': i === 10,
                    'title-green1': i >= 11,
                    'title-yellow': i >= 12
                    }"
            *ngFor="let levelLabel of levelLabels; let i = index" [attr.data-index]="i">
          {{levelLabel}}
        </td>
      </tr>
      <!-- HOUSE TYPE -->
      <tr *ngFor="let houseType of houseTypeLabels; let n = index" [attr.data-index]="i">
        <td
          [ngClass]="{
                    'house-col1': n <= 3 ,
                    'house-col2': n > 3 && n <= 6,
                    'house-col3': n === 7,
                    'house-col4': n > 7
                    }"
        >{{houseType.value}}</td>
        <td rowspan="10" *ngIf="n == 0" class="rotate-label" style="width:20px">
          <span>Common</span>
        </td>
        <td class="load-prospect"
            [ngClass]="{
                    'board-gray1': i <= 3 ,
                    'board-blue1': i >= 4 && i < 7,
                    'board-blue2': i === 7,
                    'board-orange1': i >= 8 && i < 11,
                    'board-orange2': i === 11,
                    'board-green1': i === 12,
                    'board-yellow': i >= 13
                    }"
            *ngFor="let i of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16]"
            (click)="loadBuyer('HOUSE', houseType.key, i)"
        >{{getValueHouseType(houseType.key, i)}}</td>
      </tr>
      <!-- LABEL -->
      <tr class="no-border-left">
        <td class="no-border"></td>
        <td class="no-border" style="width:20px"></td>
        <td class="tdLabel2"
            [ngClass]="{
                    'title-gray1': i <= 2 ,
                    'title-blue1': i >= 3 && i < 6,
                    'title-blue2': i === 6,
                    'title-orange1': i >= 7 && i < 10,
                    'title-orange2': i === 10,
                    'title-green1': i >= 11 && i < 12,
                    'title-green2': i === 12,
                    'title-yellow': i >= 13
                    }"
            *ngFor="let levelLabel of levelLabels; let i = index"
            [attr.data-index]="i">{{levelLabel}}</td>
      </tr>
      <!-- PRICE TYPE -->
      <tr *ngFor="let priceType of priceTypeLabels; let n = index" [attr.data-index]="i">
        <td class="priceLabel"
            [ngClass]="{
                    'price-col1': n === 5 || n ===  12 || n === 20
                    }"
        >{{priceType.value}}</td>
        <td rowspan="21" *ngIf="n == 0" class="rotate-label" style="width:20px">
          <span>Cash</span>
        </td>
        <td class="load-prospect"
            [ngClass]="{
                    'board-gray1': i <= 3 ,
                    'board-blue1': i >= 4 && i < 7,
                    'board-blue2': i === 7,
                    'board-orange1': i >= 8 && i < 11,
                    'board-orange2': i === 11,
                    'board-green1': i === 12,
                    'board-yellow': i >= 13
                    }"
            *ngFor="let i of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16]"
            (click)="loadBuyer('PRICE', priceType.key, i)"
        >{{getValuePriceType(priceType.key, i)}}</td>
      </tr>
    </table>
    <div class='row' *ngIf='passDailyGoal === false'>
      <div class='col-12'>
        <div class='text-center permission-required'>
          You have to make {{remainingCall}} more call/SMS/DK to unlock this feature
        </div>
      </div>
    </div>
    <div class='row' *ngIf='haveDailyGoalOrPermission === true'>
      <div class='col-12 permission-required'>
        <div class='text-center'>Neither your daily goal has been set nor you have permission to access this menu</div>
      </div>
    </div>
  </nb-card-body>
</nb-card>

<div class="fake-tab content-active" [hidden]="!isDisplayed">
  <ngx-buyer-list [isCustomerBoard]='true'
  ></ngx-buyer-list>
</div>
