import { RestResult } from '../response/rest-result';
import { CustomDatasource } from './custom-datasource';
import { SearchRequest } from '../request/search-request';
import { Pair } from '../request/pair';
import { Triple } from '../request/triple';
import { WrapBuyerType } from '../../../modules/pages/buyer/common/buyer-utils';
import { CustomRow } from '../common/custom-row';
import { ProspectDatasource } from '../../../modules/pages/prospect/common/prospect-datasource';
import { WrapProspectType } from '../../../modules/pages/prospect/common/prospect-utils';
import * as moment from 'moment';
import { DateUtils } from '../../common/date-utils';
import { Prospect } from '../response/prospect/prospect';

export class DataSourceUtil {

  static reloadDataCustomRow(source: CustomDatasource, pageIndex: number, pageSize: number, result: RestResult) {
    const data = [];
    for (let i = 0; i <= (pageIndex - 1) * pageSize - 1; i++) {
      data.push({});
    }
    source.total = result.metaData.total;
    source.setPaging(pageIndex, pageSize, false);
    result.data.forEach(x => {
      data.push(
        new CustomRow(x, false, false),
      );
    });
    source.load(data);
  }

  static reloadData(source: CustomDatasource, pageIndex: number, pageSize: number, result: RestResult) {
    const data = [];
    for (let i = 0; i <= (pageIndex - 1) * pageSize - 1; i++) {
      data.push({});
    }
    source.total = result.metaData.total;
    source.setPaging(pageIndex, pageSize, false);
    result.data.forEach(x => {
      data.push(x);
    });
    source.load(data);
  }

  static reloadDataProspectListing(source: ProspectDatasource, pageIndex: number, pageSize: number, result: RestResult, newData?: any) {
    const data = [];
    for (let i = 0; i <= (pageIndex - 1) * pageSize - 1; i++) {
      data.push({});
    }
    if (newData != null) {
      data.push({ type: WrapProspectType.ITEM, data: newData });
      pageSize += 1;
      source.total = Number(result.metaData.total) + 1;
    } else {
      source.total = result.metaData.total;
    }
    source.setPaging(pageIndex, pageSize, false);
    result.data.forEach(x => {
      data.push({ type: WrapProspectType.ITEM, data: x });
    });
    source.load(data);
  }

  static preparePagination(searchRequest: SearchRequest, pageIndex: number, pageSize: number) {
    searchRequest.offset = (pageIndex - 1) * pageSize;
    if (searchRequest.offset < 0) {
      searchRequest.offset = 0;
    }
    searchRequest.limit = pageSize;
  }

  static prepareRequestOrder(searchRequest: SearchRequest, change: any, defaultOrder?: Pair) {
    // init orders
    searchRequest.orders = new Array();
    if (change.sort && change.sort.length > 0) {
      change.sort.forEach(sort => {
        searchRequest.orders.push(
          {
            left: sort.field,
            right: sort.direction,

          },
        );
      });
    } else {
      searchRequest.orders.push(defaultOrder);
    }
  }

  static prepareRequestConditions(searchRequest: SearchRequest, change: any, conditions?: Triple[], isDistrict?: boolean) {

    // init conditions
    searchRequest.conditions = new Array();
    if (conditions && conditions.length > 0) {
      conditions.forEach(param => {
        searchRequest.conditions.push(param);
      });
    }
    if (change.filter && change.filter.filters.length > 0) {
      change.filter.filters.forEach(filter => {
        if (filter.search && filter.search.trim() !== '') {
          if (filter.field.includes('Id') && isFinite(filter.search)) {
            searchRequest.conditions.push(
              {
                left: filter.field,
                middle: '=',
                right: Number(filter.search),
              },
            );
          } else if (filter.field === 'createdDate') {
            if (moment(filter.search, 'DD/MM/YYYY', true).isValid()) {
              const date = new Date(moment(filter.search, 'DD/MM/YYYY', true).valueOf());
              const from = DateUtils.getStartOfDate(date.getDate(), date.getMonth(), date.getFullYear()).valueOf();
              const to = DateUtils.getEndOfDate(date.getDate(), date.getMonth(), date.getFullYear()).valueOf();
              searchRequest.conditions.push(
                {
                  left: filter.field,
                  middle: '>=',
                  right: from,
                },
                {
                  left: filter.field,
                  middle: '<=',
                  right: to,
                },
              );
            }
            // console.log(moment(filter.search, 'DD/MM/YYYY', true).isValid());
          } else if (filter.field === 'code') {
            if (isDistrict) {
              searchRequest.conditions.push(
                {
                  left: filter.field,
                  middle: '=',
                  right: Number(filter.search),
                },
              );
            } else {
              searchRequest.conditions.push({
                left: filter.field,
                middle: 'like',
                right: filter.search,
              });
            }
            searchRequest.conditionType = 'OR';
          } else {
            searchRequest.conditions.push(
              {
                left: filter.field,
                middle: 'like',
                right: filter.search,
              },
            );

          }
        }
      });
    }
  }
}
