import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Path} from '../common/path';
import {RestResult} from '../models/response/rest-result';
import {CommonService} from './common.service';
import {SearchRequest} from '../models/request/search-request';
import {TaskRequest} from '../models/request/task-request';
import {TaskSearchRequest} from '../models/request/task-search-request';

@Injectable()
export class TaskService {

  constructor(protected http: HttpClient,
              protected commonService: CommonService) {
  }

  getOne(taskId: number): Observable<RestResult> {
    return this.http.get<any>(
      Path.API_ENDPOINT +
      Path.TASK +
      Path.TASK_ID.replace(':taskId', taskId.toString()),
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }


  search(request: SearchRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.TASK +
      Path.SEARCH, request,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  create(request: TaskRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.TASK, request).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  remove(taskId: number): Observable<RestResult> {
    return this.http.delete<any>(
      Path.API_ENDPOINT +
      Path.TASK +
      Path.TASK_ID.replace(':taskId', taskId.toString()),
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  update(request: TaskRequest): Observable<RestResult> {
    return this.http.put<any>(
      Path.API_ENDPOINT +
      Path.TASK, request).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  getList(request: TaskSearchRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.TASK +
      Path.LIST, request,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  getStatistic(request: TaskSearchRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.TASK +
      Path.STATISTIC, request,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  getEmailEventOfTask(taskId: number): Observable<RestResult> {
    return this.http.get<any>(
      Path.API_ENDPOINT +
      Path.TASK +
      Path.TASK_ID.replace(':taskId', taskId.toString()) + Path.EMAIL_EVENT,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }
}
