import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RestResult } from '../../../../shared/models/response/rest-result';
import { User } from '../../../../shared/models/response/user';
import { UserService } from '../../../../shared/services/user.service';
import { AuthService } from '../../../../shared/services/auth.service';
import { CompanyService } from '../../../../shared/services/company.service';
import { TaskStatusService } from '../../../../shared/services/task-status.service';
import { CustomerService } from '../../../../shared/services/customer.service';
import { Constant } from '../../../../shared/common/constant';
import { CustomerSearchRequest } from '../../../../shared/models/request/prospect/customer-search-request';
import { LocalDataSource } from 'ng2-smart-table';
import { ComponentPermission } from '../../../../component.permission';
import { City } from '../../../../shared/models/response/address/city';
import { Street } from '../../../../shared/models/response/address/street';
import { District } from '../../../../shared/models/response/address/district';
import { LocalService } from '../../../../shared/services/local.service';
import { Zone } from '../../../../shared/models/response/address/zone';
import { Farm } from '../../../../shared/models/response/address/farm';
import { CustomDatasource } from '../../../../shared/models/utils/custom-datasource';
import { DataSourceUtil } from '../../../../shared/models/utils/datasource-util';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Postcode } from '../../../../shared/models/response/postcode';
import { PostcodeService } from '../../../../shared/services/postcode.service';
import { map, mergeMap } from 'rxjs/operators';
import { SearchRequest } from '../../../../shared/models/request/search-request';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { StringUtils } from '../../../../shared/common/string-utils';

@Component({
  selector: 'ngx-search-customer',
  templateUrl: './duplicate-phone.component.html',
  styleUrls: ['./duplicate-phone.component.scss'],
})
export class DuplicatePhoneComponent implements OnInit {

  currentUser: User;
  pageSize = 20;
  currentPage = 1;
  searchRequest = new CustomerSearchRequest();
  change: any;
  source: CustomDatasource = new CustomDatasource();
  phone: string;
  ComponentPermission = ComponentPermission;
  cities: City[];
  streets: Street[] = [];
  districts: District[] = [];
  zones: Zone[] = [];
  farms: Farm[] = [];
  param = {};
  @Input() postcode: number;
  @Output() onSearchByPostcode = new EventEmitter();
  filterByPostcode = false;
  filterPostcode$: Observable<Postcode[]>;

  constructor(private userService: UserService,
              private authService: AuthService,
              private companyService: CompanyService,
              private taskStatusService: TaskStatusService,
              private customerService: CustomerService,
              private localService: LocalService,
              private route: ActivatedRoute,
              private postcodeService: PostcodeService,
              private router: Router,
  ) {
    this.currentUser = this.authService.currentUser;
  }

  columnsDefinition = {
    phone: {
      title: 'Phone number',
      type: 'string',
    },
    total: {
      title: 'Count',
      type: 'number',
    },
  };

  settings = {
    mode: 'external',
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    pager: {
      display: true,
      perPage: this.pageSize,
    },
    columns: this.columnsDefinition,
    rowClassFunction: (row) => {
      return this.highlightRow(row?.data);
    },
  };

  ngOnInit(): void {
    this.currentUser = this.authService.currentUser;
    this.param = { ...this.route.snapshot.queryParams };
    this.source.onChanged().subscribe((change) => {
      this.change = change;
      if (change.action === 'refresh') {
        const params = this.route.snapshot.queryParams;
        if (params['page']) {
          this.loadRequest(Number(params['page']));
        } else {
          this.loadRequest(1);
        }
      }
      if (change.action === 'page') {
        this.param = { ...this.param, page: change.paging.page };
        this.navigateWithParam();
        this.loadRequest(change.paging.page);
      }
      if (change.action === 'filter') {
        this.loadRequest(1);
      }
    });
    // this.localService.cities().subscribe((result: City[]) => {
    //   this.cities = result;
    // });
    this.preLoadAddressForFilter();
    this.appendParameterToSearchRequest();
    if (this.route.snapshot.queryParams['postcode']) {
      this.filterByPostcode = true;
      this.postcode = this.searchRequest.postcode;
    }
    this.filterPostcode$ = new Observable((observer: any) => {
      observer.next(this.postcode);
    }).pipe(
      map((query: string) => {
        this.postcode = Number(query);
        this.searchSuburbByPostcode();
        return null;
      }),
    );
  }

  loadRequest(pageIndex) {
    if (this.currentUser.office.officeId !== null && this.currentUser.office.officeId !== 1) {
      this.searchRequest.officeId = this.currentUser.office.officeId;
    }
    this.searchRequest.offset = (pageIndex - 1) * this.pageSize;
    if (this.searchRequest.offset < 0) {
      this.searchRequest.offset = 0;
    }
    this.searchRequest.limit = this.pageSize;
    this.searchRequest.isDuplicate = true;
    if (this.change) {
      if (this.change.filter && this.change.filter.filters.length > 0) {
        this.change.filter.filters.forEach(filter => {
          if (filter.field !== 'phone') {
            return;
          } else {
            if (filter.search !== '') {
              this.searchRequest.phone = filter.search;
              this.searchRequest.isMatched = true;
            } else {
              this.searchRequest.phone = null;
              this.searchRequest.isMatched = null;
            }
          }
        });
      }
    }
    this.customerService.searchDuplicatePhone(this.searchRequest).subscribe((result: RestResult) => {
      DataSourceUtil.reloadData(this.source, pageIndex, this.pageSize, result);
    });
  }

  selectRecord(event: any) {
    this.phone = event.data.phone;
    window.open(
      '/pages/dashboard/search/phone?phone=' + this.phone + '&isMatched=true',
      '_blank',
    );
  }

  highlightRow(data: any) {
    let result = '';
    if (!data.isLinked) {
      return result;
    } else {
      result += ' linked';
    }
    return result;
  }

  onCitySelectChange() {
    this.searchRequest.zoneId = undefined;
    this.searchRequest.districtId = undefined;
    this.searchRequest.farmId = undefined;
    this.searchRequest.streetId = undefined;
    if (this.searchRequest.cityId) {
      delete this.param['districtId'];
      delete this.param['farmId'];
      delete this.param['zoneId'];
      delete this.param['streetId'];
      this.param = { ...this.param, cityId: this.searchRequest.cityId };
      this.localService.districts(this.searchRequest.cityId).subscribe(result => {
        this.districts = result;
      });
      this.localService.zones(this.searchRequest.cityId).subscribe(result => {
        this.zones = result;
      });
    } else {
      delete this.param['cityId'];
      delete this.param['districtId'];
      delete this.param['farmId'];
      delete this.param['zoneId'];
      delete this.param['streetId'];
    }
    this.navigateWithParam();
  }

  onSuburbSelectChange() {
    this.searchRequest.farmId = undefined;
    this.searchRequest.streetId = undefined;
    if (this.searchRequest.districtId) {
      delete this.param['farmId'];
      delete this.param['streetId'];
      this.param = { ...this.param, districtId: this.searchRequest.districtId };
      this.localService.streets(this.searchRequest.districtId).subscribe(result => {
        this.streets = result;
      });
      this.localService.farms(this.searchRequest.districtId).subscribe(result => {
        this.farms = result;
      });
    } else {
      delete this.param['districtId'];
      delete this.param['farmId'];
      delete this.param['streetId'];
    }
    this.navigateWithParam();
  }

  filter() {
    this.searchRequest.filterByAddress = true;
    this.loadRequest(1);
  }

  reset() {
    this.param = {};
    this.searchRequest.filterByAddress = undefined;
    this.searchRequest.cityId = undefined;
    this.searchRequest.zoneId = undefined;
    this.searchRequest.districtId = undefined;
    this.searchRequest.farmId = undefined;
    this.searchRequest.streetId = undefined;
    this.searchRequest.postcode = undefined;
    this.navigateWithParam();
    this.loadRequest(1);
  }

  onZoneSelectChange() {
    this.searchRequest.districtId = undefined;
    this.searchRequest.farmId = undefined;
    this.searchRequest.streetId = undefined;
    if (this.searchRequest.zoneId) {
      delete this.param['farmId'];
      delete this.param['districtId'];
      delete this.param['streetId'];
      this.param = { ...this.param, zoneId: this.searchRequest.zoneId };
      this.localService.districtsByZone(this.searchRequest.zoneId).subscribe(result => {
        this.districts = result;
      });
    } else {
      delete this.param['zoneId'];
      delete this.param['farmId'];
      delete this.param['districtId'];
      delete this.param['streetId'];
    }
    this.navigateWithParam();
  }

  onFarmSelectChange() {
    this.searchRequest.streetId = undefined;
    if (this.searchRequest.farmId) {
      delete this.param['streetId'];
      this.param = { ...this.param, farmId: this.searchRequest.farmId };
      this.localService.streetsByFarm(this.searchRequest.farmId).subscribe(result => {
        this.streets = result;
      });
    } else {
      delete this.param['farmId'];
      delete this.param['streetId'];
    }
    this.navigateWithParam();
  }

  onStreetChange() {
    if (this.searchRequest.streetId) {
      this.param = { ...this.param, streetId: this.searchRequest.streetId };
    } else {
      delete this.param['streetId'];
    }
    this.navigateWithParam();
  }

  navigateWithParam() {
    this.router.navigate([], {
      queryParams: this.param,
    });
  }

  preLoadAddressForFilter() {
    if (this.route.snapshot.queryParamMap.keys.length === 0) {
      return;
    }
    const postcode = Number(this.route.snapshot.queryParamMap.get('postcode'));
    const districtId = this.route.snapshot.queryParamMap.get('districtId');
    const farmId = this.route.snapshot.queryParamMap.get('farmId');
    const zoneId = this.route.snapshot.queryParamMap.get('zoneId');
    const streetId = this.route.snapshot.queryParamMap.get('streetId');
    if (postcode) {
      this.localService.districtByPostcode(postcode).subscribe(result => {
        this.districts = result;
      });
    }
    if (districtId) {
      if (farmId) {
        this.localService.streetsByFarm(Number(farmId)).subscribe(result => {
          this.streets = result;
        });
      } else {
        this.localService.streets(Number(districtId)).subscribe(result => {
          this.streets = result;
        });
      }
      this.localService.farms(Number(districtId)).subscribe(result => {
        this.farms = result;
      });
    }
  }

  appendParameterToSearchRequest() {
    const params = this.route.snapshot.queryParams;
    if (params['cityId']) {
      this.searchRequest.cityId = Number(params['cityId']);
    }
    if (params['zoneId']) {
      this.searchRequest.zoneId = Number(params['zoneId']);
    }
    if (params['districtId']) {
      this.searchRequest.districtId = Number(params['districtId']);
    }
    if (params['farmId']) {
      this.searchRequest.farmId = Number(params['farmId']);
    }
    if (params['streetId']) {
      this.searchRequest.streetId = Number(params['streetId']);
    }
    if (params['postcode']) {
      this.searchRequest.postcode = params['postcode'];
    }
    this.filter();
  }

  searchPostcode(keyword: string): Observable<Postcode[]> {
    const searchRequest = new SearchRequest();
    searchRequest.conditions = [];
    searchRequest.orders = [];
    searchRequest.conditions.push(
      {
        left: 'code',
        middle: '=',
        right: keyword,
      },
    );
    searchRequest.orders.push(
      {
        left: 'code',
        right: 'asc'
      }
    );
    searchRequest.offset = 0;
    searchRequest.limit = 10;
    return this.postcodeService.search(searchRequest).pipe(
      map(result => result.data),
    );
  }

  selectPostcode(type: TypeaheadMatch) {
    this.postcode = Number(type.item.code);
    this.searchSuburbByPostcode();
  }

  searchSuburbByPostcode() {
    if (this.postcode === null) {
      return;
    }
    this.filterByPostcode = true;
    this.searchRequest.postcode = Number(this.postcode);
    if (this.searchRequest.postcode) {
      this.param = { ...this.param, postcode: this.searchRequest.postcode };
    } else {
      delete this.param['postcode'];
    }
    this.navigateWithParam();
    this.localService.districtByPostcode(this.postcode).subscribe(result => {
      this.districts = result;
    });
    // this.filter();
  }
}
