import {Component, OnInit} from '@angular/core';
import {CommonService} from '../../../services/common.service';
import {NbDialogRef, NbDialogService} from '@nebular/theme';
import {RestResult} from '../../../models/response/rest-result';
import {SearchRequest} from '../../../models/request/search-request';
import {LocalDataSource} from 'ng2-smart-table';
import {ZoneService} from '../../../services/zone.service';
import {CityService} from '../../../services/city.service';
import {Router} from '@angular/router';
import {Constant} from '../../../common/constant';


@Component({
  selector: 'ngx-zone-pick',
  templateUrl: './zone-pick.component.html',
  styleUrls: ['./zone-pick.component.scss'],
})

export class ZonePickComponent implements OnInit {
  cityId = null;

  searchRequest = new SearchRequest();
  source: LocalDataSource = new LocalDataSource();
  change: any;
  pageSize = Constant.PAGE_SIZE;

  settings = {
    mode: 'external',
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    pager: {
      display: true,
      perPage: this.pageSize,
    },
    columns: {
      zoneId: {
        title: 'ID',
        type: 'number',
      },
      name: {
        title: 'Name',
        type: 'string',
      },
      code: {
        title: 'code',
        type: 'string',
      },
    },
  };

  constructor(protected ref: NbDialogRef<ZonePickComponent>,
              private dialogService: NbDialogService,
              private commonService: CommonService,
              private zoneService: ZoneService,
              private cityService: CityService,
              private router: Router) {

  }

  ngOnInit() {
    this.loadParent();
    this.source.onChanged().subscribe((change) => {
      this.change = change;
      if (change.action === 'refresh') {
        this.loadRequest(1);
        // this.pageChange(change.paging.page);
      }
      if (change.action === 'page') {
        // this.source.load(new Array());
        this.loadRequest(change.paging.page);
      }
      if (change.action === 'sort') {
        this.source.load(new Array());
        this.loadRequest(1);
      }
      if (change.action === 'filter') {
        this.source.load(new Array());
        this.loadRequest(1);
      }
    });
  }

  loadParent() {
    const searchRequest = new SearchRequest();
    searchRequest.offset = 0;
    searchRequest.limit = 100;
    searchRequest.orders = new Array();
    searchRequest.orders = [{
      left: 'name',
      right: 'asc',
    }];
  }

  search() {
    this.loadRequest(1);
  }

  loadRequest(pageIndex) {
    this.prepareRequest();
    this.zoneService.search(this.searchRequest).subscribe((result: RestResult) => {
      this.source.load(result.data);
    });
  }

  prepareRequest() {
    // init conditions
    if (!this.searchRequest.conditions) {
      this.searchRequest.conditions = new Array();
    }
    this.searchRequest.conditions = new Array();
    if (this.cityId) {
      this.searchRequest.conditions.push({
        left: 'cityId',
        middle: '=',
        right: this.cityId,
      });
    }
    if (this.change.filter && this.change.filter.filters.length > 0) {
      this.change.filter.filters.forEach(filter => {
        if (filter.search && filter.search.trim() !== '') {
          this.searchRequest.conditions.push(
            {
              left: filter.field,
              middle: 'like',
              right: filter.search,
            },
          );
        }
      });
    }

    // init orders
    this.searchRequest.orders = new Array();
    if (this.change.sort && this.change.sort.length > 0) {
      this.change.sort.forEach(sort => {
        this.searchRequest.orders.push(
          {
            left: sort.field,
            right: sort.direction,

          },
        );
      });
    } else {
      this.searchRequest.orders.push(
        {
          left: 'zoneId',
          right: 'desc',
        },
      );
    }
  }

  select(event: any) {
    this.ref.close(event.data);
  }
}
