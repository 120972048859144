import * as moment from 'moment';

export class BuyerDateUtils {
  static getFirstDayOfYear(i: number): any {
    return moment().year(i).startOf('year');
  }

  static getLastDayOfYear(i: number): any {
    return moment().year(i).endOf('year');
  }
}
