import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { StringUtils } from '../../common/string-utils';
import { Prospect } from '../../models/response/prospect/prospect';
import { ProspectService } from '../../services/prospect.service';
import { CommonService } from '../../services/common.service';
import { AttachmentComponent } from '../attachment/attachment.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Constant } from '../../common/constant';
import { User } from '../../models/response/user';
import { Task } from '../../models/response/task';
import { Observable, of } from 'rxjs';
import { Category } from '../../models/response/category';
import { CustomerLinkGroup } from '../../models/response/customer-link-group';
import { ListingDetailsRequest } from '../../models/request/prospect/listing-details-request';
import { NationalityNote } from '../../models/response/nationality-note';
import { Office } from '../../models/response/office';
import { CustomerDuplicatePhone } from '../../models/response/prospect/customer-duplicate-phone';
import { CustomerAdditionalInfo } from '../../models/response/prospect/customer-additional-info';
import { Company } from '../../models/response/prospect/company';
import { TaskType } from '../../models/response/task-type';
import { TaskStatus } from '../../models/response/task-status';
import { ToastService } from '../../services/toast.service';
import { CategoryService } from '../../services/category.service';
import { FormService } from '../../services/form.service';
import { AuthService } from '../../services/auth.service';
import { NbDatepickerComponent, NbDialogService } from '@nebular/theme';
import { NationalityNoteService } from '../../services/nationality.note.service';
import { OfficeService } from '../../services/office.service';
import { CustomerService } from '../../services/customer.service';
import { CompanyService } from '../../services/company.service';
import { Router } from '@angular/router';
import * as moment from 'moment/moment';
import 'moment-precise-range-plugin';
import { SearchRequest } from '../../models/request/search-request';
import { Triple } from '../../models/request/triple';
import { CustomerSearchRequest } from '../../models/request/prospect/customer-search-request';
import { ComponentPermission } from '../../../component.permission';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { TaskMiniDialogComponent } from '../../../modules/pages/task/task-mini-dialog/task-mini-dialog.component';
import { RestResult } from '../../models/response/rest-result';
import { ProspectLog } from '../../models/response/prospect/prospect-log';
import { ProspectContactRequest } from '../../models/request/prospect/prospect-contact-request';
import { ProspectRequest } from '../../models/request/prospect/prospect-request';
import { PropertyRequest } from '../../models/request/prospect/property-request';
import { CustomerRequest } from '../../models/request/prospect/customer-request';
import { AddressRequest } from '../../models/request/address/address-request';
import { map, mergeMap } from 'rxjs/operators';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { VCard } from 'ngx-vcard';
import { resultList, RxSpeechRecognitionService } from '@kamiazya/ngx-speech-recognition';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EditingDialogComponent } from './editing-dialog/editing-dialog.component';
import { StatusUtils } from '../../../modules/pages/buyer/common/status-utils';
import { TemplateService } from '../../services/template.service';
import {SalesDetailsComponent} from '../prospect-dialog/sales-details/sales-details.component';

@Component({
  selector: 'ngx-prospect-dialog-v2',
  templateUrl: './prospect-dialog-v2.component.html',
  styleUrls: ['./prospect-dialog-v2.component.scss'],
})
export class ProspectDialogV2Component implements OnInit {
  @Output() editDone = new EventEmitter();
  @Output() navigateProspectEvent = new EventEmitter();
  @Input() companies: Company[];
  @Input() taskTypes: TaskType[];
  @Input() taskStatuses: TaskStatus[];
  @Input() prospects: Prospect[];
  @Input() showBottomButtons = true;
  @Input() showFooter?: boolean;
  @Input() isModal = false;
  @Input() isEdit: boolean;
  onClose: any;
  defaultProspect: Prospect;
  // isMotive = false;
  // showTenant = false;
  showDKBar = false;
  showProperty = false;
  showContact = false;
  showMotive = false;
  showHistory = false;
  showMainView = true;

  prospectForm: FormGroup;
  nationalityNoteForm: FormGroup;

  customerRelationShip = Constant.CUSTOMER_RELATIONSHIP;
  customerMotivate = Constant.CUSTOMER_MOTIVATE;
  propertyType = Constant.PROPERTY_TYPE;
  propertyDefaultSpec = Constant.PROPERTY_DEFAULT_SPEC;
  currentUser: User;

  rateOptions = [
    { value: 0, label: '0' },
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
  ];
  defaultCurrencyIcon = Constant.DEFAULT_CURRENCY_ICON;
  data: Prospect;
  prospectId: number;
  tasks: Task[];
  filterCategory$: Observable<Category[]>;
  categoryName: string;
  selectedCategories: Category[] = [];
  linkedGroup: CustomerLinkGroup[];
  totalLinked = 0;
  listingRequest: ListingDetailsRequest;
  formattedAddress: string[];


  setProspect(value) {
    if (value) {
      if (!value.isCopy) {
        this.prospectService.getGroupItemDetails(value.prospectId).subscribe(result => {
          const prospect = result.data;
          // this.setProspect(result.data);
          this.setProspectData(prospect);
        }, error => {
          this.commonService.warning(error.message);
        });
      } else {
        this.setProspectData(value);
      }
    }
    if (this.attachmentComponent && value?.prospectId) {
      this.attachmentComponent.reloadImages(value.prospectId);
    }
  }

  @Input()
  set selectedProspect(value) {
    if (value) {
      this.prospectService.getGroupItemDetails(value.prospectId).subscribe(result => {
        const prospect = result.data;
        this.setProspectData(prospect);
      }, error => {
        this.commonService.warning(error.message);
      });
    }
    if (this.attachmentComponent && value?.prospectId) {
      this.attachmentComponent.reloadImages(value.prospectId);
    }
  }

  otmDays: any;
  vacDays: any;
  purchaseDays: any;
  purchaseDurationText: string;
  vacDurationText: string;
  otmDurationText: string;
  dateFormat = 'DD/MM/YYYY';
  address = '';
  street: number;
  isSubmitted = false;
  isUpdating = false;
  bedValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  contactLogType = {
    NO_ANSWER: 'NO_ANSWER',
    CALLED: 'CALLED',
    SMS: 'SMS',
    DOOR_NOCKED: 'DOOR_NOCKED',
  };
  lastCalledPhoneNumber = '';
  isNewNote = false;
  dobText = '';
  languages = Constant.LANGUAGES;
  religions = Constant.RELIGIONS;
  nationalities = Constant.COUNTRIES;
  currentNationalityNote: NationalityNote;
  filterOffice$: Observable<Office[]>;
  officeName: string;
  selectedOffice: Office;
  isEditing = true;
  ComponentPermission = ComponentPermission;
  phoneDuplicate: CustomerDuplicatePhone;
  mobiPhoneDuplicate: CustomerDuplicatePhone;
  tenantForm: FormGroup;
  infoVisible;
  @ViewChild(AttachmentComponent) attachmentComponent: AttachmentComponent;
  additionalPhone: CustomerAdditionalInfo[] = [];
  additionalMobilePhone: CustomerAdditionalInfo[] = [];
  @Output() onCloseDialog = new EventEmitter();
  stringUtils = StringUtils;
  fullAddress: string;

  constructor(private toastService: ToastService,
              private prospectService: ProspectService,
              private categoryService: CategoryService,
              private formService: FormService,
              private commonService: CommonService,
              private authService: AuthService,
              private dialogService: NbDialogService,
              public service: RxSpeechRecognitionService,
              private nationalityNoteService: NationalityNoteService,
              private officeService: OfficeService,
              private customerService: CustomerService,
              private companyService: CompanyService,
              private router: Router,
              private sanitizer: DomSanitizer,
              private dialog: MatDialog,
              private templateService: TemplateService) {
  }

  ngOnInit(): void {
    this.currentUser = this.authService.currentUser;
    this.infoVisible = this.currentUser.office?.officeProperty?.infoVisible;
    this.initForm();
    this.filterCategory$ = new Observable((observer: any) => {
      observer.next(this.categoryName);
    }).pipe(
      mergeMap((query: string) => this.searchCategory(query)),
    );
    this.initNationalityNote();
    this.initAutoComplete();
    this.fetchAllLinkedCustomer(this.data?.customer?.customerId);
  }

  listening = false;
  subscription;

  listen(): void {
    this.listening = true;

    this.subscription = this.service
      .listen()
      .pipe(resultList)
      .subscribe((list: SpeechRecognitionResultList) => {
        this.listening = false;

        let message = list.item(0).item(0).transcript;

        if (message === 'apagar' || message === 'limpar') {
          message = '';
        }
        if (this.prospectForm.controls.note.value == null) {
          this.prospectForm.controls.note.setValue('');
        }
        this.prospectForm.controls.note.setValue(this.prospectForm.controls.note.value + ' ' + message);
      });
  }

  navigateProspect(forward: boolean) {
    this.navigateProspectEvent.emit(forward);
  }

  initAutoComplete() {
    this.filterOffice$ = new Observable((observer: any) => {
      observer.next(this.officeName);
    }).pipe(
      mergeMap((query: string) => this.searchOffice(query)),
    );
  }

  searchOffice(keyword: String): Observable<Office[]> {
    const searchRequest = new SearchRequest();
    searchRequest.conditions = [];
    searchRequest.conditions.push(
      {
        left: 'name',
        middle: 'like',
        right: keyword,
      },
    );
    searchRequest.offset = 0;
    searchRequest.limit = 10;
    return this.officeService.search(searchRequest).pipe(
      map(result => result.data),
    );
  }

  searchAddress() {
    const key = 'realestate';
    if (this.data.address) {
      let urlAddress = StringUtils.getFullAddress(this.data.address);
      urlAddress = urlAddress.replace(' ', '+').replace('/', '%2F').replace('&', '%26');
      urlAddress = urlAddress + '+' + key;
      window.open('https://www.google.com/search?q=' + urlAddress, '_blank');
    }
  }

  setProspectData(prospect) {
    this.isEditing = !prospect.property?.link;
    this.data = prospect;
    this.fullAddress = StringUtils.getShortAddress(this.data?.address);
    // const adr = this.stringUtils.getShortAddress(this.data?.address);
    // const arr = adr.split(',');
    // arr.splice(0, 1);
    // for (let i = 0; i < arr.length; i++) {
    //   this.streetWithoutNumberFrom += ',' + arr[i] + ' ';
    // }
    this.prospectId = prospect.prospectId;
    // this.hideProspectInfo();
    this.selectedCategories = prospect.categories;
    this.initForm();
    this.initNationalityNote();
    if (prospect.customer) {
      this.fetchAllLinkedCustomer(prospect.customer?.customerId);
      this.lookupPhone(this.data?.customer?.phone, 'PHONE');
      this.lookupPhone(this.data?.customer?.mobilePhone, 'MPHONE');
    }
    this.initAdditionalPhoneLists();
  }

  public vCard: VCard;

  initForm() {
    this.lastCalledPhoneNumber = this.data?.customer?.phone;
    this.isSubmitted = false;
    this.isNewNote = false;
    this.formattedAddress = StringUtils.formatAddress(this.data?.address);
    this.tasks = this.data?.tasks;
    this.prospectForm = new FormGroup({
      call: new FormControl(this.data?.call),
      sms: new FormControl(this.data?.sms),
      door: new FormControl(this.data?.door),
      mail: new FormControl(this.data?.mail),
      // ===
      sell: new FormControl(this.data?.property?.sell),
      rent: new FormControl(this.data?.property?.rent),
      otm: new FormControl(this.data?.property?.otm),
      vac: new FormControl(this.data?.property?.vac),
      // ===
      isDirector: new FormControl(this.data?.customer?.isDirector),
      isInfluencer: new FormControl(this.data?.customer?.isInfluencer),
      isSocialble: new FormControl(this.data?.customer?.isSocialble),
      isConcensious: new FormControl(this.data?.customer?.isConcensious),
      // ===
      motivateFinancialUp: new FormControl(this.data?.customer?.motivateFinancialUp),
      motivateFinancialDown: new FormControl(this.data?.customer?.motivateFinancialDown),
      motivateLifestyleUp: new FormControl(this.data?.customer?.motivateLifestyleUp),
      motivateLifestyleDown: new FormControl(this.data?.customer?.motivateLifestyleDown),
      // ===
      phone: new FormControl(this.data?.customer?.phone, [
        Validators.maxLength(20),
      ]),
      mobilePhone: new FormControl(this.data?.customer?.mobilePhone, [
        Validators.maxLength(20),
      ]),
      email: new FormControl(this.data?.customer?.email, [
        Validators.maxLength(200),
      ]),
      facebook: new FormControl(this.data?.customer?.facebook, [
        Validators.maxLength(500),
      ]),
      instagram: new FormControl(this.data?.customer?.instagram, [
        Validators.maxLength(500),
      ]),
      // ===
      relationship: new FormControl(this.data?.customer?.relationship ? this.data?.customer?.relationship : null, [
        Validators.maxLength(1),
      ]),
      motivate: new FormControl(this.data?.customer?.motivate ? this.data?.customer?.motivate : null, [
        Validators.maxLength(1),
      ]),
      bed: new FormControl(this.data?.property?.bed, [
        Validators.required,
      ]),
      bath: new FormControl(this.data?.property?.bath, [
        Validators.required,
      ]),
      car: new FormControl(this.data?.property?.car, [
        Validators.required,
      ]),
      land: new FormControl(this.data?.property?.land, [
        Validators.required,
      ]),
      type: new FormControl(this.data?.property?.type, [
        Validators.required,
      ]),
      sellPrice: new FormControl(this.data?.property?.sellPrice),
      rentPrice: new FormControl(this.data?.property?.rentPrice),
      askingPrice: new FormControl(this.data?.property?.askingPrice),
      otmDate: new FormControl(this.data?.property?.otmDate ? new Date(this.data?.property?.otmDate) : ''),
      vacDate: new FormControl(this.data?.property?.vacDate ? new Date(this.data?.property?.vacDate) : ''),
      purchaseDate:
        new FormControl(this.data?.property?.purchaseDate ? new Date(this.data?.property?.purchaseDate) : ''),
      sellCompanyId: new FormControl(this.data?.property?.sellCompany?.companyId),
      rentCompanyId: new FormControl(this.data?.property?.rentCompany?.companyId),
      name: new FormControl(this.data?.customer?.name, [
        Validators.maxLength(200),
      ]),
      surname: new FormControl(this.data?.customer?.surname, [
        Validators.maxLength(200),
      ]),
      // ===
      number: new FormControl(this.data?.address?.number),
      numberFrom: new FormControl(this.data?.address?.numberFrom),
      numberFromLetter: new FormControl(this.data?.address?.numberFromLetter),
      numberTo: new FormControl(this.data?.address?.numberTo),
      numberToLetter: new FormControl(this.data?.address?.numberToLetter),
      numberUnit: new FormControl(this.data?.address?.numberUnit),
      fromNumber: new FormControl(null),
      toNumber: new FormControl(null),
      unit: new FormControl(this.data?.address?.unit),
      additionalInfo: new FormControl(this.data?.address?.additionalInfo),
      cityId: new FormControl(this.data?.address?.street?.district?.city?.cityId),
      zoneId: new FormControl(this.data?.address?.street?.district?.zone?.zoneId),
      districtId: new FormControl(this.data?.address?.street?.district?.districtId),
      farmId: new FormControl(this.data?.address?.street?.farm?.farmId),
      streetId: new FormControl(this.data?.address?.street?.streetId),
      addressOfficeId: new FormControl(this.data?.address?.office?.officeId),
      propertyOfficeId: new FormControl(this.data?.property?.office?.officeId),
      // ===
      rate: new FormControl(typeof this.data?.rate !== 'undefined' && this.data?.rate !== null ? this.data?.rate : 1),
      // ===
      note: new FormControl(null, [
        Validators.maxLength(2000),
      ]),
      motiveNote: new FormControl(this.data?.motiveNote, [
        Validators.maxLength(2000),
      ]),
      dob: new FormControl(this.data?.customer?.dob ? new Date(this.data?.customer?.dob) : ''),
      callbackDate: new FormControl(''),
      sold: new FormControl(this.data?.property?.sold),
      underContract: new FormControl(this.data?.property?.underContract),
      leased: new FormControl(this.data?.property?.leased),
      anniversaryDay:
        new FormControl(this.data?.customer?.anniversaryDay ? new Date(this.data?.customer?.anniversaryDay) : ''),
      nationality: new FormControl(this.data?.customer?.nationality),
      customerNote: new FormControl(this.data?.customer?.note),
      religion: new FormControl(this.data?.customer?.religion),
      profesion: new FormControl(this.data?.customer?.profesion),
      language: new FormControl(this.data?.customer?.language),
      nationalityNote: new FormControl(this.data?.nationalityNote),
      officeId: new FormControl(this.data?.office?.officeId),
      link: new FormControl(this.data?.property?.link),
    });
    if (this.data?.customer?.motivate === 0) {
      this.prospectForm.controls.motivate.setValue(null);
    }
    if (this.data?.customer?.relationship === 0) {
      this.prospectForm.controls.relationship.setValue(null);
    }
    // this.autoSetProspectRate();
    if (this.prospectForm.controls.mail.value == null) {
      this.prospectForm.controls.mail.setValue(false);
    }
    if (this.prospectForm.controls.door.value == null) {
      this.prospectForm.controls.door.setValue(false);
    }
    if (this.prospectForm.controls.sms.value == null) {
      this.prospectForm.controls.sms.setValue(false);
    }
    if (this.prospectForm.controls.call.value == null) {
      this.prospectForm.controls.call.setValue(false);
    }
    if (this.prospectForm.controls.isSocialble.value == null) {
      this.prospectForm.controls.isSocialble.setValue(true);
    }
    this.otmDays = this.getDayDiff(this.prospectForm.controls.otmDate.value);
    this.vacDays = this.getDayDiff(this.prospectForm.controls.vacDate.value);
    this.purchaseDays = this.getDayDiff(this.prospectForm.controls.purchaseDate.value);
    this.dobText = this.getDobText(this.prospectForm.controls.dob.value);
    this.updateDurationText();
    // auto set prospect level
    this.prospectForm.controls.type.valueChanges.subscribe(value => {
      this.autoSetProspectRate();
    });
    this.prospectForm.controls.name.valueChanges.subscribe(value => {
      this.generateVCard();
    });
    this.prospectForm.controls.surname.valueChanges.subscribe(value => {
      this.generateVCard();
    });
    this.prospectForm.controls.email.valueChanges.subscribe(value => {
      this.generateVCard();
    });
    this.prospectForm.controls.phone.valueChanges.subscribe(value => {
      this.autoSetProspectRate();
      this.generateVCard();
    });
    this.prospectForm.controls.mobilePhone.valueChanges.subscribe(value => {
      this.autoSetProspectRate();
      this.generateVCard();
    });
    this.prospectForm.controls.relationship.valueChanges.subscribe(value => {
      this.autoSetProspectRate();
    });
    this.prospectForm.controls.motivate.valueChanges.subscribe(value => {
      this.autoSetProspectRate();
    });

    // calculate duration
    this.prospectForm.controls.otmDate.valueChanges.subscribe(value => {
      this.otmDays = this.getDayDiff(value);
      this.updateDurationText();
    });

    this.prospectForm.controls.vacDate.valueChanges.subscribe(value => {
      this.vacDays = this.getDayDiff(value);
      this.updateDurationText();
    });

    this.prospectForm.controls.dob.valueChanges.subscribe(value => {
      this.dobText = this.getDobText(value);
      this.generateVCard();
    });

    this.prospectForm.controls.purchaseDate.valueChanges.subscribe(value => {
      this.purchaseDays = this.getDayDiff(value);
      this.updateDurationText();
    });
    this.officeName = this.data?.office?.name;
    if (this.data?.property?.link) {
      this.isEditing = false;
    }
    this.generateVCard();
  }

  generateVCard() {
    this.vCard = {
      name: {
        firstNames: this.prospectForm.controls.name.value,
        lastNames: this.prospectForm.controls.surname.value,
      },
      birthday: this.prospectForm.controls.dob.value,
      email: [this.prospectForm.controls.email.value],
      telephone: [
        this.prospectForm.controls.mobilePhone.value,
        this.prospectForm.controls.phone.value,
      ],
      address: [
        {
          extendedAddress: StringUtils.getShortAddress(this.data?.address),
          // street: this.data?.address.streetName,
          // postalCode: this.data?.address.postCode,
          // region: this.data?.address.districtName,
          countryName: 'Australia',
        },
      ],
    };
  }

  openGoogleMap() {
    // let code: string;
    // if (this.data.address.street.district.code) {
    //   const fullCode = this.data.address.street.district.code;
    //   code = fullCode.substr(0, 4);
    // }
    let url = StringUtils.getShortAddress(this.data?.address);
    url = url.replace(' ', '+').replace('/', '%2F').replace('&', '%26');
    // url += '+' + code;
    window.open('https://www.google.com/maps/search/' + url, '_blank');
  }

  getDayDiff(value: Date): number {
    if (!value) {
      return null;
    }
    const from = moment(value);
    const to = moment();
    return moment.preciseDiff(from, to, true);
  }

  searchCategory(name: string): Observable<Category[]> {
    if (!name || name === '') {
      return of([]);
    }
    const searchRequest = new SearchRequest();
    searchRequest.conditions = new Array();
    searchRequest.conditions.push(new Triple('name', 'like', name));
    const subCon = new SearchRequest();
    subCon.conditionType = 'OR';
    subCon.conditions = [];
    subCon.conditions.push(new Triple('type', '=', 'PROSPECT'));
    subCon.conditions.push(new Triple('type', '=', 'BOTH'));
    searchRequest.subConditions.push(subCon);
    return this.categoryService.search(searchRequest).pipe(
      map(result => result.data),
    );
  }

  getDobText(value: any): string {
    if (!value || value === '') {
      return '';
    }
    let time = 0;
    if (value instanceof Date) {
      time = (value as Date).getTime();
    } else {
      time = this.commonService.parseDate(value, this.dateFormat);
    }
    // calculate years
    const yearText = this.getTimeDiffText(time, new Date().getTime());
    // calculate how long to dob date in this year
    const remainingText = this.getTimeDiffText(new Date().getTime(),
      moment(time).year(new Date().getFullYear()).valueOf());
    const result = [];
    if (yearText) {
      result.push(yearText);
    }
    if (remainingText) {
      result.push(remainingText);
    }
    return result.join(' - ');
  }

  getTimeDiffText(from: number, to: number) {
    const years = moment(to).diff(moment(from), 'years');
    const months = moment(to).diff(moment(from), 'months');
    const remainingMonths = moment(to).subtract(years, 'years').diff(moment(from), 'months');
    const remainingDays = moment(to).subtract(months, 'months').diff(moment(from), 'days');
    let result = '';
    if (years > 0) {
      result += years + ' years' + ' ';
    }
    if (remainingMonths > 0) {
      result += remainingMonths + ' months' + ' ';
    }
    if (remainingDays > 0) {
      result += remainingDays + ' days' + ' ';
    }
    return result;
  }

  updateDurationText() {
    if (this.purchaseDays) {
      this.purchaseDurationText = (this.purchaseDays['years'] > 0 ? this.purchaseDays['years'] + 'y' : '')
        + (this.purchaseDays['months'] > 0 ? this.purchaseDays['months'] + 'm' : ''
          + (this.purchaseDays['days'] > 0 ? this.purchaseDays['days'] + 'd' : ''));
    } else {
      this.purchaseDurationText = null;
    }
    if (this.otmDays !== null) {
      this.otmDurationText = (this.otmDays['years'] > 0 ? this.otmDays['years'] + 'y' : '') + (this.otmDays['months'] > 0 ? this.otmDays['months'] + 'm' : ''
        + (this.otmDays['days'] >= 0 ? this.otmDays['days'] + 'd' : ''));
    } else {
      this.otmDurationText = null;
    }
    if (this.vacDays !== null) {
      this.vacDurationText = (this.vacDays['years'] > 0 ? this.vacDays['years'] + 'y' : '') + (this.vacDays['months'] > 0 ? this.vacDays['months'] + 'm' : ''
        + (this.vacDays['days'] >= 0 ? this.vacDays['days'] + 'd' : ''));
    } else {
      this.vacDurationText = null;
    }
  }

  autoSetProspectRate() {
    const phone = this.prospectForm.controls.phone.value;
    const mobilePhone = this.prospectForm.controls.mobilePhone.value;
    const type = this.prospectForm.controls.type.value;
    let relationship = Number(this.prospectForm.controls.relationship.value);
    let motivate = Number(this.prospectForm.controls.motivate.value);
    if (type === 'HOUSE_COM') {
      this.prospectForm.controls.rate.setValue(0);
      return;
    }

    if (this.tasks && this.tasks.length > 0) {
      if (relationship < 3) {
        this.prospectForm.controls.relationship.setValue(3);
        relationship = Number(this.prospectForm.controls.relationship.value);
      }
      if (motivate < 3) {
        this.prospectForm.controls.motivate.setValue(3);
        motivate = Number(this.prospectForm.controls.motivate.value);
      }
    }

    if (
      ((phone && phone !== '') || (mobilePhone && mobilePhone !== ''))
      && relationship >= 3
      && motivate >= 3
    ) {
      const temp = Math.ceil((relationship + motivate) / 2);
      this.prospectForm.controls.rate.setValue(temp > 7 ? 7 : temp);
      return;
    }

    if ((phone && phone !== '') || (mobilePhone && mobilePhone !== '')) {
      this.prospectForm.controls.rate.setValue(2);
      return;
    } else {
      this.prospectForm.controls.rate.setValue(1);
      return;
    }
  }

  initNationalityNote() {
    if (!this.prospectForm.value.nationality || this.prospectForm.value.nationality === '') {
      return;
    }
    const currentCountry = this.nationalities
      .find(nationality => nationality.country_name === this.prospectForm.value.nationality);
    if (!currentCountry) {
      return;
    }
    const sr = new SearchRequest;
    sr.conditions = [];
    sr.conditions.push(new Triple('countryCodeName', '=', currentCountry.country_code_name));
    this.nationalityNoteService.search(sr).subscribe(result => {
      this.currentNationalityNote = result.data[0];
      this.prospectForm.controls.nationalityNote.patchValue(this.currentNationalityNote.note);
      this.nationalityNoteForm = new FormGroup({
        nationalityNoteId: new FormControl(this.currentNationalityNote.nationalityNoteId),
        note: new FormControl(this.currentNationalityNote.note),
      });
    });
  }

  fetchAllLinkedCustomer(customerId: number) {
    if (customerId) {
      this.customerService.fetchAllLinkedGroup(customerId).subscribe(
        result => {
          if (result.data) {
            this.linkedGroup = result.data;
            this.setTotalLinked();
          }
        }, error => {
          this.commonService.warning(error.message);
        },
      );
    }
  }

  lookupPhone(phone: string, type: string) {
    if (type === 'PHONE') {
      this.phoneDuplicate = null;
    } else {
      this.mobiPhoneDuplicate = null;
    }
    if (phone) {
      const customerSearchRequest = new CustomerSearchRequest();
      customerSearchRequest.phone = phone;
      customerSearchRequest.isMatched = true;
      this.customerService.searchDuplicatePhone(customerSearchRequest).subscribe(
        result => {
          if (result.data && result.data.length > 0) {
            if (type === 'PHONE') {
              this.phoneDuplicate = new CustomerDuplicatePhone();
              result.data.forEach(response => {
                this.phoneDuplicate.phone = response.phone;
                if (response.tableType === 'BUYER') {
                  this.phoneDuplicate.totalCustomer += response.total;
                } else {
                  this.phoneDuplicate.totalClient += response.total;
                }
              });
            } else {
              this.mobiPhoneDuplicate = new CustomerDuplicatePhone();
              result.data.forEach(response => {
                this.mobiPhoneDuplicate.phone = response.phone;
                if (response.tableType === 'BUYER') {
                  this.mobiPhoneDuplicate.totalCustomer += response.total;
                } else {
                  this.mobiPhoneDuplicate.totalClient += response.total;
                }
              });
            }
          }
        }, error => {
          this.commonService.warning(error.message);
        },
      );
    }
  }

  initAdditionalPhoneLists() {
    this.additionalPhone = [];
    this.additionalMobilePhone = [];
    if (this.data?.customer?.customerAdditionalInfo && this.data?.customer?.customerAdditionalInfo?.length > 0) {
      this.additionalPhone = this.data.customer.customerAdditionalInfo.filter(info => info.phoneNumberType === 'PHONE');
      this.additionalMobilePhone = this.data.customer.customerAdditionalInfo.filter(info => info.phoneNumberType === 'MPHONE');
    }
  }

  setTotalLinked() {
    this.totalLinked = 0;
    this.linkedGroup.forEach(group => {
      if (group.prospects) {
        this.totalLinked += group.prospects.length;
      }
      if (group.buyers) {
        this.totalLinked += group.buyers.length;
      }
    });
  }

  getAge(input: number): string {
    if (!input) {
      return null;
    }
    const year = new Date(Date.now()).getFullYear();
    const temp = new Date(input).getFullYear();
    return '(' + (year - temp) + 'y/o)';
  }

  getCallbackDate(input: number): string {
    if (!input) {
      return null;
    }
    const dayDiff = this.getDayDiff(new Date(input));
    return '(' + dayDiff['days'] + 'd)';
  }

  triggerDatePicker(datePickerComponent: NbDatepickerComponent<any>) {
    datePickerComponent?.show();
  }

  showError(name: string): string {
    return this.prospectForm.controls[name].errors
    && (this.prospectForm.controls[name].dirty
      || this.prospectForm.controls[name].touched
      || this.isSubmitted) ? 'input-error' : '';
  }

  // showError(name: string) {
  // }

  selectPhone(type: string) {
    let phone;
    if (type === 'PHONE') {
      phone = this.prospectForm.value.phone;
    } else {
      phone = this.prospectForm.value.mobilePhone;
    }
    window.open(
      '/pages/dashboard/search/phone?phone=' + phone + '&isMatched=true',
      '_blank',
    );
  }

  addPhoneNumber(type: string) {
    const info = new CustomerAdditionalInfo();
    info.phoneNumberType = type;
    info.customerId = this.data.customer.customerId;
    if (type === 'PHONE') {
      this.additionalPhone.push(info);
    } else {
      this.additionalMobilePhone.push(info);
    }
  }

  searchSameCustomer(type: string) {
    if (type === 'PHONE') {
      this.lookupPhone(this.prospectForm.value.phone, type);
    } else {
      this.lookupPhone(this.prospectForm.value.mobilePhone, type);
    }
  }

  removePhoneNumber(info: CustomerAdditionalInfo, index: number) {
    if (info.customerAdditionalInfoId) {
      info.isDeleted = true;
    } else {
      if (info.phoneNumberType === 'PHONE') {
        this.additionalPhone.splice(index, 1);
      } else {
        this.additionalMobilePhone.splice(index, 1);
      }
    }
  }

  setPrimary(info: CustomerAdditionalInfo, index: number) {
    const name = this.prospectForm.controls.name.value;
    const surname = this.prospectForm.controls.surname.value;
    if (info.name && info.name.trim() !== '') {
      const nameParts = info.name.trim()?.split(' ');
      if (nameParts.length > 1) {
        this.prospectForm.controls.name.patchValue(nameParts[0]);
        this.prospectForm.controls.surname.patchValue(nameParts[1]);
      } else {
        this.prospectForm.controls.name.patchValue(nameParts[0]);
        this.prospectForm.controls.surname.patchValue(null);
      }
    } else {
      this.prospectForm.controls.name.patchValue(null);
      this.prospectForm.controls.surname.patchValue(null);
    }
    if (!name && !surname) {
      info.name = null;
    } else {
      info.name = (name + ' ' + surname).trim();
    }
    if (info.phoneNumberType === 'PHONE') {
      const phone = this.prospectForm.controls.phone.value;
      this.prospectForm.controls.phone.patchValue(info.phoneNumber);
      if (StringUtils.isNotEmpty(phone)) {
        info.phoneNumber = phone;
      } else {
        this.removePhoneNumber(info, index);
      }
      if (StringUtils.isNotEmpty(this.prospectForm.controls.mobilePhone.value)) {
        const temp = this.prospectForm.controls.mobilePhone.value;
        this.prospectForm.controls.mobilePhone.patchValue(null);
        const newInfo = new CustomerAdditionalInfo();
        newInfo.name = (name + ' ' + surname).trim();
        newInfo.phoneNumber = temp;
        newInfo.phoneNumberType = 'MPHONE';
        newInfo.customerId = this.data.customer.customerId;
        this.additionalMobilePhone.push(newInfo);
      }
    } else {
      const phone = this.prospectForm.controls.mobilePhone.value;
      this.prospectForm.controls.mobilePhone.patchValue(info.phoneNumber);
      if (StringUtils.isNotEmpty(phone)) {
        info.phoneNumber = phone;
      } else {
        this.removePhoneNumber(info, index);
      }
      if (StringUtils.isNotEmpty(this.prospectForm.controls.phone.value)) {
        const temp = this.prospectForm.controls.phone.value;
        this.prospectForm.controls.phone.patchValue(null);
        const newInfo = new CustomerAdditionalInfo();
        newInfo.name = (name + ' ' + surname).trim();
        newInfo.phoneNumber = temp;
        newInfo.phoneNumberType = 'PHONE';
        newInfo.customerId = this.data.customer.customerId;
        this.additionalPhone.push(newInfo);
      }
    }
  }

  selectCategory(type: TypeaheadMatch) {
    this.categoryName = '';
    const selectedCategory: Category = type.item;
    if (this.selectedCategories.filter(category => category.categoryId === selectedCategory.categoryId).length > 0) {
      return;
    }
    this.selectedCategories.push(selectedCategory);
  }

  removeCategory(removedCategory: Category) {
    if (removedCategory) {
      this.selectedCategories = this.selectedCategories.filter
      (category => category.categoryId !== removedCategory.categoryId);
    }
  }

  onNationalityChange() {
    // this.prospectForm.controls.otmDate
    if (this.prospectForm.value.nationality) {
      const currentCountry = this.nationalities
        .find(nationality => nationality.country_name === this.prospectForm.value.nationality);
      this.initNationalityNote();
      if (currentCountry && currentCountry.lang_code) {
        this.prospectForm.controls.language.setValue(currentCountry.lang_code);
      } else {
        this.prospectForm.controls.language.setValue(null);
      }
      if (this.currentNationalityNote && this.currentNationalityNote.note) {
        this.prospectForm.controls.nationalityNote.patchValue(this.currentNationalityNote.note);
      } else {
        this.prospectForm.controls.nationalityNote.patchValue(null);
      }
    }
  }

  selectOffice(type: TypeaheadMatch) {
    this.officeName = type.item.name;
    this.selectedOffice = type.item;
    // this.prospectForm.controls.officeId.patchValue(type.item.officeId);
  }

  createTask(type: 'BIRTHDAY_CALL' | 'NEXT_CALL' | 'APPOINTMENT') {
    const defaultData = {
      description: `Make phone call to ${this.data?.customer?.name}, `
        + `phone number: ${this.data?.customer?.phone} or ${this.data?.customer?.mobilePhone}`,
      isCallback: type === 'NEXT_CALL',
      isDob: type === 'BIRTHDAY_CALL',
      isAppointment: type === 'APPOINTMENT',
      callbackDate: this.prospectForm.controls.callbackDate.value,
      dob: this.prospectForm.controls.dob.value,
    };
    this.dialogService.open(TaskMiniDialogComponent, {
      hasScroll: true,
      context: {
        defaultData,
        taskTypes: this.taskTypes,
        taskStatuses: this.taskStatuses,
        selectedProspect: this.data,
      },
    })
      .onClose.subscribe(res => {
      if (this.isSuccessResponse(res)) {
        this.close(res);
      }
    });
  }

  newNote() {
    this.isNewNote = true;
    this.prospectForm.controls.note.setValue('');
  }

  cancelNewNote() {
    this.isNewNote = false;
    this.prospectForm.controls.note.setValue(this.data?.note);
  }

  private isSuccessResponse(response) {
    return response && (response.status === 'success');
  }

  close(result?: RestResult, next?: boolean) {
    if (this.onClose) {
      this.onClose(result);
    } else {
      this.editDone.emit({ result: result, next: next });
    }
  }

  sortHistoryList() {
    return this.data?.logs?.sort((a: ProspectLog, b: ProspectLog) => {
      return b.createdDate - a.createdDate;
    });
  }

  formatLog(log: ProspectLog) {
    if (!log.fullname) {
      log.fullname = 'Vu Le';
    }
    let result = '';
    result = `<b>${this.commonService.formatDate(log.createdDate, 'DD/MM/YYYY HH:mm')}</b>`
      + `: ${this.getShortName(log.fullname)}: ${log.actionType}:\n`;
    if (log.actionType === 'BULK_LOG') {
      result += log.description;
    } else {
      try {
        const desObj = JSON.parse(log?.description?.trim());
        if (desObj) {
          for (const key of Object.keys(desObj)) {
            const keyArr = key?.split(':');
            result += `- ${keyArr.length === 2 ? keyArr[1] : key}: ${desObj[key]} \n`;
          }
        }
      } catch (ignore) {
      }
    }
    return result.trim();
  }

  toggleDisplayDKBar() {
    this.showDKBar = !this.showDKBar;
    // if (this.showDKBar) {
    //   this.showProperty = false;
    //   this.showContact = false;
    //   this.showMotive = false;
    //   this.showHistory = false;
    //   this.showMainView = true;
    // } else {
    //   this.showMainView = true;
    // }
  }

  toggleDisplayProperty() {
    if (!this.data) {
      return;
    }
    if (this.showProperty) {
      this.showProperty = false;
      this.showMainView = true;
    } else {
      this.showContact = false;
      this.showMotive = false;
      this.showHistory = false;
      this.showMainView = false;
      this.showProperty = true;
    }
  }

  toggleDisplayContact() {
    if (!this.data) {
      return;
    }
    if (this.showContact) {
      this.showContact = false;
      this.showMainView = true;
    } else {
      this.showProperty = false;
      this.showMotive = false;
      this.showHistory = false;
      this.showMainView = false;
      this.showContact = true;
    }
  }

  toggleDisplayMotive() {
    if (!this.data) {
      return;
    }
    if (this.showMotive) {
      this.showMotive = false;
      this.showMainView = true;
    } else {
      this.showProperty = false;
      this.showContact = false;
      this.showHistory = false;
      this.showMainView = false;
      this.showMotive = true;
    }
  }

  toggleDisplayHistory() {
    if (!this.data) {
      return;
    }
    if (this.showHistory) {
      this.showHistory = false;
      this.showMainView = true;
    } else {
      this.showProperty = false;
      this.showContact = false;
      this.showMotive = false;
      this.showMainView = false;
      this.showHistory = true;
    }
  }

  keepOrder(a, b) {
    return 1;
  }

  getShortName(name: string) {
    const items: string[] = name?.replace(/[\s]+/g, ' ').trim()?.split(' ');
    let result = '';
    for (const item of items) {
      result += item[0]?.toUpperCase();
    }
    return result;
  }

  logContactStatus(type: string) {
    const contactLogRequest: ProspectContactRequest = new ProspectContactRequest();
    switch (type) {
      case this.contactLogType.CALLED:
        contactLogRequest.called = true;
        contactLogRequest.calledPhoneNumber = this.lastCalledPhoneNumber;
        break;
      case this.contactLogType.SMS:
        contactLogRequest.sms = true;
        contactLogRequest.smsPhoneNumber = this.data?.customer?.mobilePhone;
        break;
      case this.contactLogType.DOOR_NOCKED:
        contactLogRequest.doorNocked = true;
        break;
      case this.contactLogType.NO_ANSWER:
        contactLogRequest.noAnswer = true;
        break;
    }
    contactLogRequest.note = this.prospectForm.controls.note?.value?.trim();
    if ((type === this.contactLogType.CALLED || type === this.contactLogType.DOOR_NOCKED)
      && (!contactLogRequest.note || contactLogRequest.note === '')) {
      this.commonService.warning('Please fill in note');
      return;
    }
    this.submit((submitResult) => {
      this.prospectService.logContactStatus(this.data.prospectId, contactLogRequest).subscribe(
        result => {
          this.commonService.info('Logged success.');
          if (
            type === this.contactLogType.NO_ANSWER
            || type === this.contactLogType.CALLED
            || type === this.contactLogType.DOOR_NOCKED
            || type === this.contactLogType.SMS
          ) {
            // this.navigateProspect(true);
            this.close(result, true);
          } else if (!this.onClose) {
            this.close(submitResult);
          }
        },
        error => {
          this.commonService.warningHtml(error.message);
        },
      );
    });
  }

  submit(callback?: any) {
    this.isUpdating = true;
    this.formService.extractErrorMessage(this.prospectForm);
    if (this.prospectForm.invalid) {
      this.isSubmitted = true;
      this.isUpdating = false;
      return;
    }
    const prospectRequest = this.getRequest();
    if (this.data && this.data.prospectId) {
      prospectRequest.prospectId = this.data.prospectId;
      prospectRequest.userId = this.currentUser.userId;
      if (this.nationalityNoteForm) {
        this.nationalityNoteForm.controls.note.patchValue(this.prospectForm.value.nationalityNote);
      }
      this.prospectService.update(prospectRequest).subscribe(
        result => {
          this.formattedAddress = StringUtils.formatAddress(result.data?.address);
          if (this.currentNationalityNote) {
            this.nationalityNoteService.update(this.nationalityNoteForm.value).subscribe();
          }
          // this.attachmentComponent.uploadBatch(this.data.prospectId, Constant.ATTACHMENT_TYPE.PROSPECT, true);
          this.commonService.info('Updated prospect success.');
          this.isUpdating = false;
          if (callback) {
            callback(result);
          } else {
            this.close(result, false);
          }
        },
        error => {
          this.isUpdating = false;
          this.commonService.warningHtml(error.message);
        },
      );
    }
  }

  getRequest(): ProspectRequest {
    if (this.prospectForm.controls.otmDate.value && this.prospectForm.controls.otmDate.value !== ''
      && isNaN(Number(this.prospectForm.controls.otmDate.value))) {
      this.prospectForm.controls.otmDate
        .setValue(this.commonService.parseDate(this.prospectForm.controls.otmDate.value, this.dateFormat));
    }
    if (this.prospectForm.controls.vacDate.value && this.prospectForm.controls.vacDate.value !== ''
      && isNaN(Number(this.prospectForm.controls.vacDate.value))
    ) {
      this.prospectForm.controls.vacDate
        .setValue(this.commonService.parseDate(this.prospectForm.controls.vacDate.value, this.dateFormat));
    }
    if (this.prospectForm.controls.dob.value && this.prospectForm.controls.dob.value !== ''
      && isNaN(Number(this.prospectForm.controls.dob.value))
    ) {
      this.prospectForm.controls.dob
        .setValue(this.commonService.parseDate(this.prospectForm.controls.dob.value, this.dateFormat));
    }
    if (this.prospectForm.controls.otmDate.value && this.prospectForm.controls.otmDate.value !== ''
      && isNaN(Number(this.prospectForm.controls.otmDate.value))) {
      this.prospectForm.controls.otmDate
        .setValue(this.commonService.parseDate(this.prospectForm.controls.otmDate.value, this.dateFormat));
    }
    if (this.prospectForm.controls.vacDate.value && this.prospectForm.controls.vacDate.value !== ''
      && isNaN(Number(this.prospectForm.controls.vacDate.value))
    ) {
      this.prospectForm.controls.vacDate
        .setValue(this.commonService.parseDate(this.prospectForm.controls.vacDate.value, this.dateFormat));
    }
    if (this.prospectForm.controls.dob.value && this.prospectForm.controls.dob.value !== ''
      && isNaN(Number(this.prospectForm.controls.dob.value))
    ) {
      this.prospectForm.controls.dob
        .setValue(this.commonService.parseDate(this.prospectForm.controls.dob.value, this.dateFormat));
    }
    let additionalProspectRequest = new ProspectRequest();
    let additionalPropertyRequest = new PropertyRequest();
    if (this.listingRequest) {
      additionalProspectRequest = this.listingRequest.prospectRequest;
      additionalPropertyRequest = this.listingRequest.propertyRequest;
    }
    const prospectRequest: ProspectRequest = {
      ...this.data,
      ...this.prospectForm.value,
      ...additionalProspectRequest,
    };
    prospectRequest.customer = { ...prospectRequest.customer, ...this.prospectForm.value };
    prospectRequest.customer.customerAdditionalInfo = [...this.additionalPhone, ...this.additionalMobilePhone];
    prospectRequest.property = { ...prospectRequest.property, ...this.prospectForm.value, ...additionalPropertyRequest };
    prospectRequest.address = {
      ...prospectRequest.address,
      ...this.prospectForm.value,
    };
    this.trimObject(prospectRequest, Object.keys(ProspectRequest.getDummy()));
    this.trimObject(prospectRequest.customer, Object.keys(CustomerRequest.getDummy()));
    this.trimObject(prospectRequest.property, Object.keys(PropertyRequest.getDummy()));
    this.trimObject(prospectRequest.address, Object.keys(AddressRequest.getDummy()));
    if (prospectRequest.customer) {
      prospectRequest.customer.note = this.prospectForm.controls.customerNote.value;
    }
    if (this.selectedCategories.length > 0) {
      prospectRequest.categoryIds = this.selectedCategories.map((category) => category.categoryId);
    }
    if (this.selectedOffice) {
      prospectRequest.officeId = this.selectedOffice.officeId;
      prospectRequest.property.officeId = this.selectedOffice.officeId;
      prospectRequest.address.officeId = this.selectedOffice.officeId;
    } else {
      prospectRequest.officeId = this.currentUser.office?.officeId;
      prospectRequest.property.officeId = this.currentUser.office?.officeId;
      prospectRequest.address.officeId = this.currentUser.office?.officeId;
    }
    // if (prospectRequest.rate === 2) {
    //   prospectRequest.customer.motivate = 2;
    //   prospectRequest.customer.relationship = 2;
    // }
    return prospectRequest;
  }

  trimObject(obj: any, properties: string[]): any {
    const curentProperties: string[] = Object.keys(obj);
    for (const name of curentProperties) {
      if (properties.indexOf(name) === -1) {
        delete obj[name];
      }
    }
  }

  setSquareValue(controlName: string) {
    if (this.prospectForm.get(controlName).value !== true) {
      this.prospectForm.get(controlName).setValue(true);
    } else {
      this.prospectForm.get(controlName).setValue(false);
    }
    this.autoSetSORV();
  }

  autoSetSORV() {
    const sell = this.prospectForm.controls.sell.value;
    const rent = this.prospectForm.controls.rent.value;
    const otm = this.prospectForm.controls.otm.value;
    const vac = this.prospectForm.controls.vac.value;
    if (!sell && !rent && !otm && !vac) {
      this.prospectForm.controls.type.setValue('HOUSE_COM');
      this.onPropertyTypeChange();
      return;
    }
    if (vac) {
      this.prospectForm.controls.rent.setValue(true);
      this.prospectForm.controls.vacDate.setValue(new Date());
      return;
    }
  }

  onPropertyTypeChange() {
    const type = this.prospectForm.controls.type.value;
    if (type) {
      const defaultSpec = this.propertyDefaultSpec[type];
      if (defaultSpec) {
        if (!this.prospectForm.controls.bed.value) {
          this.prospectForm.controls.bed.setValue(defaultSpec.bed);
        }
        if (!this.prospectForm.controls.bath.value) {
          this.prospectForm.controls.bath.setValue(defaultSpec.bath);
        }
        if (!this.prospectForm.controls.car.value) {
          this.prospectForm.controls.car.setValue(defaultSpec.car);
        }
        if (!this.prospectForm.controls.land.value) {
          this.prospectForm.controls.land.setValue(defaultSpec.land);
        }
      }
      if (type === 'HOUSE_COM') {
        this.prospectForm.controls.sell.setValue(false);
        this.prospectForm.controls.rent.setValue(false);
        this.prospectForm.controls.otm.setValue(false);
        this.prospectForm.controls.vac.setValue(false);
        this.autoSetProspectRate();
      } else if (type) {
        this.prospectForm.controls.sell.setValue(true);
      }
    }
  }

  formatMoney(value: number, isRent?: boolean): string {
    let result = '$';
    if (!value) {
      result += '0';
      return result;
    }
    if (value > 1000000) {
      const number = (value / 1000000).toFixed(1);
      result += number + 'M';
    } else if (value > 1000) {
      const number = (value / 1000).toFixed(0);
      result += number + 'k';
    } else {
      result += value;
    }
    if (isRent) {
      result += ' pw';
    }
    return result;
  }

  cancel() {
    this.onCloseDialog.emit(true);
  }

  edit() {
    this.isEdit = true;
  }

  quickEditDone() {
    this.isEdit = false;

    const prospectRequest: Prospect = {
      ...this.data,
      ...this.prospectForm.value,
    };
    prospectRequest.address = {
      ...prospectRequest.address,
      ...this.prospectForm.value,
    };
    this.fullAddress = StringUtils.getShortAddress(prospectRequest.address);
  }


  openDialog(data: any) {
    const searchRequest = new SearchRequest();
    searchRequest.conditions = [];
    searchRequest.conditions.push({
      left: 'type',
      middle: '=',
      right: 'SMS',
    });
    this.templateService.search(searchRequest).subscribe(r => {
      this.dialogService.open(EditingDialogComponent, {
        context: {
          prospect: data,
          templates: r.data
        },
      });
    });
    // const targetAttr = event.target.getBoundingClientRect();
    // const dialogConfig = new MatDialogConfig();
    //
    // dialogConfig.autoFocus = true;
    //
    // dialogConfig.position = {
    //   top: targetAttr.y + targetAttr.height + 10 + 'px',
    //   left: targetAttr.x - targetAttr.width - 20 + 'px',
    // };
    // dialogConfig.data = {
    //   prospect: this.data,
    // };
    // dialogConfig.panelClass = ['my-panel'];
    //
    //
    // const dialogRef = this.dialog.open(EditingDialogComponent, dialogConfig);
  }

  displaySalesDetails() {
    this.dialogService.open(SalesDetailsComponent, {
      context: {
        data: this.data,
      },
    }).onClose.subscribe(res => {
      if (res) {
        this.listingRequest = res;
        if (this.listingRequest.propertyRequest.askingPriceFrom && this.listingRequest.propertyRequest.askingPriceTo) {
          const from = Number(this.listingRequest.propertyRequest.askingPriceFrom);
          const to = Number(this.listingRequest.propertyRequest.askingPriceTo);
          this.prospectForm.controls.askingPrice.patchValue((from + to) / 2);
          this.data.property.askingPrice = (from + to) / 2;
        }
        this.submit();
      }
    });
  }
}
