import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Path } from '../common/path';
import { RestResult } from '../models/response/rest-result';
import { CommonService } from './common.service';
import { ProspectRequest } from '../models/request/prospect/prospect-request';
import { ProspectSearchRequest } from '../models/request/prospect/prospect-search-request';
import { ClientBoardSearchRequest } from '../models/request/prospect/client-board-search-request';
import { ProspectContactRequest } from '../models/request/prospect/prospect-contact-request';
import { CompanyBoardSearchRequest } from '../models/request/prospect/company-board-search-request';
import { SearchRequest } from '../models/request/search-request';
import { ProspectImportSearchRequest } from '../models/request/prospect/prospect-import-search-request';
import {CustomerRequest} from '../models/request/prospect/customer-request';
import { ProspectStatisticRequest } from '../models/request/prospect/prospect-statistic-request';
import { PropertyRequest } from '../models/request/prospect/property-request';

@Injectable()
export class ProspectService {

  constructor(protected http: HttpClient,
              protected commonService: CommonService) {
  }

  getOne(prospectId: number): Observable<RestResult> {
    return this.http.get<any>(
      Path.API_ENDPOINT +
      Path.PROSPECT +
      Path.PROSPECT_ID.replace(':prospectId', prospectId.toString()),
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  getByCustomerId(customerId: number): Observable<RestResult> {
    return this.http.get<any>(
      Path.API_ENDPOINT +
      Path.PROSPECT +
      Path.CUSTOMER +
      Path.CUSTOMER_ID.replace(':customerId', customerId.toString()),
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  getByAddressId(addressId: number): Observable<RestResult> {
    return this.http.get<any>(
      Path.API_ENDPOINT +
      Path.PROSPECT +
      Path.ADDRESS +
      Path.ADDRESS_ID.replace(':addressId', addressId.toString()),
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  create(request: ProspectRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.PROSPECT, request).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  createCustomer(prospectId: number, request: CustomerRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.PROSPECT +
      Path.PROSPECT_ID.replace(':prospectId', prospectId.toString()) +
      Path.CUSTOMER, request).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  remove(prospectId: number): Observable<RestResult> {
    return this.http.delete<any>(
      Path.API_ENDPOINT +
      Path.PROSPECT +
      Path.PROSPECT_ID.replace(':prospectId', prospectId.toString()),
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  update(request: ProspectRequest): Observable<RestResult> {
    return this.http.put<any>(
      Path.API_ENDPOINT +
      Path.PROSPECT, request).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  searchClientBoard(request: ClientBoardSearchRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.PROSPECT +
      Path.CLIENT_BOARD +
      Path.SEARCH, request,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  searchCompanyBoard(request: CompanyBoardSearchRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.PROSPECT +
      Path.COMPANY_BOARD +
      Path.SEARCH, request,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  searchGroups(request: ProspectSearchRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.PROSPECT_GROUP_SEARCH, request,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  searchGroupForPhone(request: ProspectSearchRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.PROSPECT_GROUP_SEARCH_PHONE, request,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  searchGroupForEmail(request: ProspectSearchRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.PROSPECT_GROUP_SEARCH_EMAIL, request,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  getGroupItems(streetId: number, request: ProspectSearchRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.PROSPECT_GROUP_ITEM.replace(':streetId', streetId.toString()),
      request,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  getGroupItemsSimple(request: ProspectSearchRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.PROSPECT_SEARCH_ITEM + Path.SIMPLE,
      request,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  getGroupItemDetails(prospectId: number): Observable<RestResult> {
    return this.http.get<any>(
      Path.API_ENDPOINT + Path.PROSPECT_GROUP_ITEM_DETAILS.replace(':prospectId', prospectId.toString()),
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  searchItems(request: ProspectSearchRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.PROSPECT_SEARCH_ITEM,
      request,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  removeList(prospectIds: number[], streetIds: number[]): Observable<RestResult> {
    let params: HttpParams = new HttpParams();
    if (prospectIds && prospectIds.length > 0) {
      params = params.append('prospectIds', prospectIds.join(','));
    }
    if (streetIds && streetIds.length > 0) {
      params = params.append('streetIds', streetIds.join(','));
    }
    return this.http.delete<any>(
      Path.API_ENDPOINT + Path.PROSPECT + Path.LIST,
      { params },
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }
  removeProspectList(prospectIds: number[]): Observable<RestResult> {
    let params: HttpParams = new HttpParams();
    if (prospectIds && prospectIds.length > 0) {
      params = params.append('prospectIds', prospectIds.join(','));
    }
    return this.http.delete<any>(
      Path.API_ENDPOINT + Path.PROSPECT + Path.PROSPECT_LIST,
      { params },
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  logContactStatus(prospectId: number, request: ProspectContactRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.PROSPECT_CONTACT_LOG.replace(':prospectId', prospectId.toString()), request).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  search(request: ProspectSearchRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.PROSPECT_SEARCH,
      request,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  searchDefault(request: SearchRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.PROSPECT +
      Path.SEARCH, request,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  searchByAddress(request: ProspectSearchRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.PROSPECT +
      Path.ADDRESS +
      Path.SEARCH, request,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  shortcutUpdate(request: ProspectRequest): Observable<RestResult> {
    return this.http.put<any>(
      Path.API_ENDPOINT +
      Path.PROSPECT_SHORTCUT_UPDATE, request).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  markProspectsAsCalled(prospectIds: number[]): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.PROSPECT_MARK_AS_CALLED, prospectIds).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  bulkSaveProspectLog(request: ProspectSearchRequest) {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.PROSPECT + Path.LOG + Path.MULTIPLE, request).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  getTotalImport(): Observable<RestResult> {
    return this.http.get<any>(
      Path.API_ENDPOINT +
      Path.PROSPECT +
      Path.IMPORT +
      Path.TOTAL,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  getMarketImportByDate(request: ProspectImportSearchRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.PROSPECT + Path.IMPORT + Path.MARKET, request).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  getTotalSm(request: ProspectSearchRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.PROSPECT + Path.BULK_SMS, request).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  getDailyStatistic( fromDate: number, toDate: number,
                     userIds?: number[]): Observable<RestResult> {
    let params: HttpParams = new HttpParams();
    params = params.append('fromDate', fromDate + '');
    params = params.append('toDate', toDate + '');
    if (userIds) {
      params = params.append('userId', userIds.join(', '));
    }
    return this.http.get<any>(
      Path.API_ENDPOINT +
      Path.PROSPECT + Path.STATISTIC + Path.DAILY, { params }).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  getDailySms(fromDate: number, toDate: number,
                     userIds?: number[]): Observable<RestResult> {
    let params: HttpParams = new HttpParams();
    params = params.append('fromDate', fromDate + '');
    params = params.append('toDate', toDate + '');
    if (userIds) {
      params = params.append('userId', userIds.join(', '));
    }
    return this.http.get<any>(
      Path.API_ENDPOINT +
      Path.PROSPECT + Path.STATISTIC + Path.SMS, { params }).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  countCurrentDailyGoal(fromDate: number, toDate: number,
              userIds?: number[]): Observable<RestResult> {
    let params: HttpParams = new HttpParams();
    params = params.append('fromDate', fromDate + '');
    params = params.append('toDate', toDate + '');
    if (userIds) {
      params = params.append('userId', userIds.join(', '));
    }
    return this.http.get<any>(
      Path.API_ENDPOINT +
      Path.PROSPECT + Path.STATISTIC + Path.DAILY_GOAL, { params }).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  getPotentialCommission(request: ProspectSearchRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.PROSPECT + Path.MONEY, request,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  getPotentialRentalValue(request: ProspectSearchRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.PROSPECT + Path.MONEY + Path.RENTAL, request,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  getVisualBoard(request: ProspectSearchRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.PROSPECT + Path.VISUAL_BOARD, request,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  updateOpenHomeTime(request: PropertyRequest): Observable<RestResult> {
    return this.http.put<any>(
      Path.API_ENDPOINT +
      Path.PROSPECT + Path.OPEN_HOME + Path.TIME, request,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  getAllEmailOfEvent(emailEventId: number): Observable<RestResult> {
    return this.http.get<any>(
      Path.API_ENDPOINT +
      Path.PROSPECT + Path.EMAIL_EVENT + Path.EMAIL +
      Path.EMAIL_EVENT_ID.replace(':emailEventId', emailEventId.toString()),
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  getAllPhoneOfEvent(emailEventId: number): Observable<RestResult> {
    return this.http.get<any>(
      Path.API_ENDPOINT +
      Path.PROSPECT + Path.EMAIL_EVENT + Path.PHONE +
      Path.EMAIL_EVENT_ID.replace(':emailEventId', emailEventId.toString()),
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  getClientList(request: ProspectSearchRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.PROSPECT + Path.LIST + '/v2',
      request,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  getAllByPhoneOrMPhone(request: ProspectSearchRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.PROSPECT_SEARCH_ITEM + Path.PHONE,
      request,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }
}
