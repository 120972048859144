<nb-card>
  <nb-card-header>
    Visual Boards
  </nb-card-header>
  <nb-card-body>
    <!--    Filter start -->
    <div class='mb-3'>
      <div class='row'>
        <div class='col-4'>
          <nb-select size='tiny' fullWidth placeholder='All Streets' [(ngModel)]='searchRequest.streetId'
                     [disabled]='!searchRequest.districtId'>
            <nb-option [value]='null'>
              All Streets
            </nb-option>
            <nb-option *ngFor='let key of streets' [value]='key.streetId'>{{key.name}}</nb-option>
          </nb-select>
        </div>
        <div class='col-4'>
          <nb-select size='tiny' fullWidth placeholder='All Suburbs' [(ngModel)]='searchRequest.districtId'
                     [disabled]='!postcode'>
            <nb-option [value]='null'>
              All Suburbs
            </nb-option>
            <nb-option *ngFor='let key of districts' [value]='key.districtId'
                       (click)='onSuburbChange(key.districtId)'>{{key.name}}</nb-option>
          </nb-select>
        </div>
        <div class='col-4'>
          <!--          <nb-select size='tiny' fullWidth placeholder='All Cities' [(ngModel)]='searchRequest.cityId'>-->
          <!--            <nb-option [value]='null' (click)='onCitySelectChange()'>-->
          <!--              All Cities-->
          <!--            </nb-option>-->
          <!--            <nb-option *ngFor='let key of cities' [value]='key.cityId'-->
          <!--                       (click)='onCitySelectChange()'>{{key.name}}</nb-option>-->
          <!--          </nb-select>-->
          <nb-form-field>
            <input nbInput fullWidth placeholder='Postcode' fieldSize='tiny' class='override-nbinput-height'
                   [(ngModel)]='postcode'
                   [typeahead]='filterPostcode$'
                   [ngModelOptions]='{standalone: true}'
                   [typeaheadAsync]='true'
                   (typeaheadOnSelect)='selectPostcode($event)'
                   typeaheadOptionField='code'
                   typeaheadMinLength='3'
                   typeaheadWaitMs='1000'>
            <!--      <span nbSuffix (click)='searchSuburbByPostcode()'><i class='fa fa-search'></i></span>-->
          </nb-form-field>
        </div>
      </div>
      <div class='row mt-2'>
        <div class='col-12'>
          <button nbButton size='tiny' status='primary' (click)='pageChange(1)' class='fa-pull-right'>Search</button>
        </div>
      </div>
    </div>
    <!--    Filter end-->
    <div class='row'>
      <div class='col-12 d-flex'>
        <button class='ml-1' nbButton size='tiny' [status]='button.selected? "primary" : "basic"'
                *ngFor='let button of visualBoardButtons' (click)='onSelectBoard(button)'>{{button.value}}</button>
      </div>
    </div>
    <div class='row mt-3'>
      <div class='col-12'>
        <ng2-smart-table [settings]='settings'
                         [source]='source'
                         (userRowSelect)='selectRecord($event)'
        ></ng2-smart-table>
      </div>
    </div>
  </nb-card-body>
</nb-card>
