import {Injectable} from '@angular/core';
import 'rxjs/add/operator/do';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {LoadingService} from '../services/loading.service';
import {CommonService} from '../services/common.service';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService,
              private loadingService: LoadingService,
              private commonService: CommonService,
              private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.loadingService.hide();
          }
        },
        (err: any) => {
          this.loadingService.hide();
          if (err instanceof HttpErrorResponse) {
            this.loadingService.hide();
            if (err.status === 401) {
              this.authService.clearAccessToken();
              if (err.url.indexOf('/auth/login') < 0) {
                this.router.navigate(['/auth/login']);
              }
              this.loadingService.hide();
            }
            if (err.status === 403) {
              this.router.navigate(['/error/vuot-quyen']);
              this.loadingService.hide();
            }
            if (err.status === 404) {
              this.router.navigate(['/error/khong-tim-thay']);
              this.loadingService.hide();
            }
            if (err.status === 500) {
              this.router.navigate(['/error/loi-he-thong']);
              this.loadingService.hide();
            }
            if (err.status === 0) {
              this.router.navigate(['/error/he-thong-nang-cap']);
              this.loadingService.hide();
            }
          } else {
            this.loadingService.hide();
          }
        },
        () => {
          // TODO: do something when complete request
          // this.loadingService.hide();
        })
    );
  }

}
