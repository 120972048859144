<nb-card>
  <nb-card-header *ngIf='!noData'>
    Total SMS
  </nb-card-header>
  <nb-card-body>
    <div class='d-flex justify-content-center' *ngIf='noData'>
      <div class='text-center'>
        No Data!
      </div>
    </div>
    <div class='row mb-2' *ngFor='let log of data, index as index'>
      <div class='col-12'>
        <div class='log-info'>
          Time: {{getDateByTimestamp(log.log.createdDate)}}
          <br>
          Content: {{log.log.description}}
          <br>
          Details:
          <br>
        </div>
        <div *ngFor='let city of log.cities'>
          <nb-accordion>
            <nb-accordion-item>
              <nb-accordion-item-header>
                {{city.name}} - Total SMS: {{city.totalSms}}
              </nb-accordion-item-header>
              <nb-accordion-item-body>
                <table class='table'>
                  <tr>
                    <th>Name</th>
                    <th>Total SMS</th>
                  </tr>
                  <tr *ngFor='let district of city.districts'>
                    <td>{{district.name}}</td>
                    <td><span class='trace-back' nbTooltip='Trace back this message?'
                              (click)='traceBackSM(log.log)'>{{district.totalSms}}</span></td>
                  </tr>
                </table>
              </nb-accordion-item-body>
            </nb-accordion-item>
          </nb-accordion>
        </div>
      </div>
      <hr *ngIf='index !== (data.length - 1)'>
    </div>
  </nb-card-body>
</nb-card>
