import {Component, Input, OnInit} from '@angular/core';
import {ViewCell} from 'ng2-smart-table';

@Component({
  template: `
    <div>
      <p *ngIf="isVACMode && rowData.data">{{rowData.data?.property?.rentPrice}}</p>
      <p *ngIf="isOTMMode && rowData.data">{{rowData.data?.property?.sellPrice}}</p>
    </div>
  `,
})
export class ProspectMarketPriceComponent implements ViewCell, OnInit {

  isOTMMode: boolean = true;
  isVACMode: boolean = false;

  @Input() value: string | number;
  @Input() rowData: any;

  ngOnInit() {
  }

}
