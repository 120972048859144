export class Company {
  companyId?: number;

  name?: string;

  address?: string;

  color?: string;

  textColor?: string;
  phone?: string;
  email?: string;
  type?: string;
  serviceCount?: number;
}
