import { Component, OnInit } from '@angular/core';
import {ɵo} from 'angular-calendar';

@Component({
  selector: 'ngx-custom-month-view-header',
  templateUrl: './custom-month-view-header.component.html',
  styleUrls: ['./custom-month-view-header.component.scss'],
})
export class CustomMonthViewHeaderComponent extends ɵo implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

}
