export class Endpoint {

  static endpoint = [
    {
      domain: 'dev.propertycrm.com.au',
      api: 'https://api.dev.propertycrm.com.au',
      siteName: 'Development'
    },
    {
      domain: 'sydney.propertycrm.com.au',
      api: 'https://api.sydney.propertycrm.com.au',
      siteName: 'Sydney'
    },
    {
      domain: '2020.propertycrm.com.au',
      api: 'https://api.2020.propertycrm.com.au',
      siteName: '2020'
    },
  ];
}
