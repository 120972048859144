import {AddressRequest} from '../address/address-request';
import { VpaRequest } from './vpa-request';
import { UserRequest } from '../user-request';
import { AgentGroupRequest } from './agent-group-request';

export class PropertyRequest {
  propertyId?: number;
  type?: string;
  bed?: number;
  bath?: number;
  car?: number;
  land?: number;
  sell?: boolean;
  rent?: boolean;
  otm?: boolean;
  vac?: boolean;
  sold?: boolean;
  underContract?: boolean;
  leased?: boolean;
  sellPrice?: number;
  askingPrice?: number;
  rentPrice?: number;
  otmDate?: number;
  vacDate?: number;
  sellCompanyId?: number;
  rentCompanyId?: number;
  link?: string;
  purchaseDate?: number;
  yearIn?: number;
  monthIn?: number;
  weekOut?: number;
  dayOut?: number;
  officeId?: number;
  askingPriceFrom?: number;
  askingPriceTo?: number;
  agentEstimatePriceFrom?: number;
  agentEstimatePriceTo?: number;
  inclusions?: string;
  methodOfSale?: string;
  agreementDate?: number;
  agencyPeriodFrom?: number;
  agencyPeriodTo?: number;
  commissionRate?: string;
  vpaTotal?: number;
  vpaList?: VpaRequest[];
  listingStatus?: string;
  agreementType?: string;
  adjustment?: number;
  agentGroups?: AgentGroupRequest[];
  openHomeTime?: number;
  beingFurnished?: boolean;
  managementFee?: number;
  administrationFee?: number;


  static getDummy = () => {
    return {
      propertyId: '',
      addressId: '',
      type: '',
      bed: '',
      bath: '',
      car: '',
      land: '',
      sell: '',
      rent: '',
      otm: '',
      tenant: '',
      buyer: '',
      enqDate: '',
      vac: '',
      sold: '',
      underContract: '',
      leased: '',
      sellPrice: '',
      rentPrice: '',
      askingPrice: '',
      otmDate: '',
      vacDate: '',
      sellCompanyId: '',
      rentCompanyId: '',
      link: '',
      address: '',
      purchaseDate: '',
      askingPriceFrom: '',
      askingPriceTo: '',
      agentEstimatePriceFrom: '',
      agentEstimatePriceTo: '',
      inclusions: '',
      methodOfSale: '',
      agreementDate: '',
      agencyPeriodFrom: '',
      agencyPeriodTo: '',
      commissionRate: '',
      vpaTotal: '',
      vpaList: '',
      listingStatus: '',
      agreementType: '',
      adjustment: '',
      agentGroups: '',
      openHomeTime: '',
      beingFurnished: '',
      managementFee: '',
      administrationFee: '',
    };
  }
}
