import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormService } from '../../../../../shared/services/form.service';
import { NbDialogRef } from '@nebular/theme';
import { Prospect } from '../../../../../shared/models/response/prospect/prospect';

@Component({
  selector: 'ngx-adjust-time',
  templateUrl: './adjust-time.component.html',
  styleUrls: ['./adjust-time.component.scss'],
})
export class AdjustTimeComponent implements OnInit {
  form: FormGroup;
  data: Prospect;
  startFrom: number;
  timeRange = [];
  minuteRange = [];
  hourTypes = ['AM', 'PM'];
  isSubmitted = false;

  constructor(private formService: FormService,
              private ref: NbDialogRef<AdjustTimeComponent>) {
  }

  ngOnInit(): void {
    this.startFrom = new Date().getTime();
    for (let i = 1; i <= 12; i++) {
      this.timeRange.push(i < 10 ? `0${i}` : i);
    }
    for (let i = 0; i < 60; i += 5) {
      this.minuteRange.push(i < 10 ? `0${i}` : i);
    }
    this.initForm();
  }

  initForm() {
    this.form = new FormGroup({
      hour: new FormControl('', [Validators.required]),
      minute: new FormControl('', [Validators.required]),
      hourType: new FormControl('', [Validators.required]),
      time: new FormControl(this.data?.property?.openHomeTime ? new Date(this.data.property.openHomeTime) : '', [Validators.required]),
    });
    if (this.data.property.openHomeTime) {
      const time = new Date(this.data.property.openHomeTime);
      let hour = time.getHours();
      const minute = time.getMinutes();
      if (hour === 0) {
        hour = 12;
        this.form.controls.hourType.patchValue('AM');
      } else {
        if (hour >= 12) {
          this.form.controls.hourType.patchValue('PM');
          hour -= 12;
        } else {
          this.form.controls.hourType.patchValue('AM');
        }
      }
      this.form.controls.hour.patchValue(hour < 10 ? `0${hour}` : hour);
      this.form.controls.minute.patchValue(minute < 10 ? `0${minute}` : minute);
    }
  }

  showErrorInput(name: string): string {
    // return this.form.controls[name].errors
    // && (this.form.controls[name].dirty
    //   || this.form.controls[name].touched
    //   || this.isSubmitted) ? 'input-error' : '';
    return null;
  }

  cancel() {
    this.ref.close();
  }

  submit() {
    const form = this.form.value;
    this.formService.extractErrorMessage(this.form);
    if (this.form.invalid) {
      this.isSubmitted = true;
      return;
    }
    try {
      const hour = Number(form.hour);
      if (form.hourType === 'PM' && hour < 12) {
        form.time.setHours(hour + 12);
      } else if (form.hourType === 'PM' && hour === 12) {
        form.time.setHours(12);
      } else if (form.hourType === 'AM' && hour < 12) {
        form.time.setHours(hour);
      } else if (form.hourType === 'AM' && hour === 12) {
        form.time.setHours(0);
      }
      form.time.setMinutes(Number(form.minute));
    } catch (ignore) {
    }
    this.ref.close(form.time);
  }

  filterFn = (date) => date.getTime() >= this.startFrom;
}
