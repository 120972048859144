export class TaskSearchRequest {
  toUserId?: number;
  keyword?: string;
  offset?: number;
  limit?: number;
  taskTypeIds?: number[];
  statuses?: string[];
  fromDate?: number;
  toDate?: number;
  filterForOfficers?: boolean;
  toUserIds?: number[];
  officeId: number;
}
