import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { RestResult } from '../models/response/rest-result';

@Injectable()
export class ErrorService {
  constructor() { }

  handleError(httpError: HttpErrorResponse) {
    if (httpError.error && httpError.error.status) {
      return throwError(httpError.error);
    }

    const error = new RestResult();
    error.status = 'error';
    switch (httpError.status) {
      case 500:
        // errorMsg = errorMsg ? errorMsg : 'Lỗi không mong muốn';
        error.message = 'Lỗi không mong muốn' + ', xin vui lòng thử lại sau.';
        break;
      case 403:
        error.message = 'Bạn không có quyền truy cập dữ liệu này.';
        break;
      case 401:
        error.message = 'Xin vui lòng đăng nhập trước khi sử dụng hệ thống.';
        break;
      case 400:
        error.message = 'Xin kiểm tra lại yêu cầu đến hệ thống.';
        break;
      default:
        error.message = 'Lỗi không mong muốn. Xin vui lòng thử lại sau.';
        break;
    }

    return throwError(error);
  }

  handleRestResultError(result: RestResult) {
    const errors = this.returnRestResultError(result);
    console.error('Alert about errors:' + JSON.stringify(errors));
    // TODO: show common error popup or toarst or notification
  }

  returnRestResultError(result: RestResult): string[] {
    if (result) {
      if (!result.messages) {
        result.messages = [];
      }
      if (result.message) {
        result.messages.push(result.message);
      }
      return result.messages;
    }
    return ['Lỗi không mong muốn. Xin vui lòng thử lại sau.'];
  }
}
