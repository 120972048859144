import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Constant } from '../../../common/constant';
import { VendorRequest } from '../../../models/request/prospect/vendor-request';
import { Prospect } from '../../../models/response/prospect/prospect';
import { VpaRequest } from '../../../models/request/prospect/vpa-request';
import { FormControl, FormGroup } from '@angular/forms';
import { CompanyService } from '../../../services/company.service';
import { SearchRequest } from '../../../models/request/search-request';
import { Company } from '../../../models/response/prospect/company';
import { ContractChecklistRequest } from '../../../models/request/contract-checklist-request';
import { MarketingNoteRequest } from '../../../models/request/prospect/marketing-note-request';
import { MarketingRequest } from '../../../models/request/prospect/marketing-request';
import { ConfirmDialogComponent } from '../../../../modules/pages/confirm-dialog/confirm-dialog.component';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { VendorService } from '../../../services/vendor.service';
import { CommonService } from '../../../services/common.service';
import { VpaService } from '../../../services/vpa.service';
import { ProspectService } from '../../../services/prospect.service';
import { ContractChecklistService } from '../../../services/contract-checklist.service';
import { ListingDetailsRequest } from '../../../models/request/prospect/listing-details-request';
import { MarketingService } from '../../../services/marketing.service';
import { StringUtils } from '../../../common/string-utils';
import { User } from '../../../models/response/user';
import { UserRequest } from '../../../models/request/user-request';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { UserService } from '../../../services/user.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { AgentGroup } from '../../../models/response/prospect/agent-group';
import { Agent } from '../../../models/response/prospect/agent';
import { CompanyRequest } from '../../../models/request/prospect/company-request';
import { AgentService } from '../../../services/agent.service';
import { AgentGroupService } from '../../../services/agent.group.service';

@Component({
  selector: 'ngx-sales-details',
  templateUrl: './sales-details.component.html',
  styleUrls: ['./sales-details.component.scss'],
})
export class SalesDetailsComponent implements OnInit, OnChanges {

  @Input() data: Prospect;
  vendorList: VendorRequest[] = [];
  vpaList: VpaRequest[] = [];
  prospectId: number;
  propertyId: number;
  listingRepresentativeForm: FormGroup;
  agreementDate: Date;
  agencyPeriodFrom: Date;
  agencyPeriodTo: Date;
  vpaRemain: number = null;
  pricePercentage: number = null;
  solicitorCompany: Company[];
  selectedCompany: Company;
  contractChecklist: ContractChecklistRequest[] = [];
  noteList: MarketingNoteRequest[] = [];
  marketingForm: FormGroup;
  agentPrice: number;
  fee: number;
  splitCommission: number;
  commissionRate: number;
  defaultCurrencyIcon = Constant.DEFAULT_CURRENCY_ICON;
  stringUtils = StringUtils;
  agentGroupList: AgentGroup[] = [];
  agentName: string;
  filterUser$: Observable<User[]>;
  isEditing = false;
  newAgent: Agent = new Agent();
  selectedTitle: string = null;
  cacheCompany: Company;
  tenantForm: FormGroup;

  constructor(private companyService: CompanyService,
              private dialogService: NbDialogService,
              private vendorService: VendorService,
              private commonService: CommonService,
              private vpaService: VpaService,
              private prospectService: ProspectService,
              private contractChecklistService: ContractChecklistService,
              private marketingService: MarketingService,
              private userService: UserService,
              private agentService: AgentService,
              private agentGroupService: AgentGroupService,
              protected ref: NbDialogRef<SalesDetailsComponent>,
  ) {
  }

  ngOnInit(): void {
    if (this.data) {
      this.getSolicitorCompany();
      this.bindData();
    }
    this.filterUser$ = new Observable((observer: any) => {
      observer.next(this.agentName);
    }).pipe(
      mergeMap((query: string) => this.searchUser(query)),
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    this.data = changes.data.currentValue;
    this.bindData();
  }

  bindData() {
    this.initForm();
    this.calculateVpaRemain();
    this.calculatePricePercentage();
    this.calculateAgentPrice();
    this.data.property.listingStatus = 'For sale';
    this.prospectId = this.data.prospectId;
    this.propertyId = this.data.property.propertyId;
    this.vendorList = this.data.vendors;
    this.agentGroupList = this.data.property.agentGroups.filter(g => g.type === 'SALE');
    this.vpaList = this.data.property.vpaList;
    this.contractChecklist = this.data.contractChecklist;
    this.contractChecklist.forEach(contract => {
      contract.isEditing = false;
    });
    if (this.data.marketing?.notes) {
      this.noteList = this.data.marketing?.notes;
    }
    if (this.data.property.agreementDate) {
      this.agreementDate = new Date(this.data.property.agreementDate);
    } else {
      this.agreementDate = null;
    }
    if (this.data.property.agencyPeriodFrom) {
      this.agencyPeriodFrom = new Date(this.data.property.agencyPeriodFrom);
    } else {
      this.agencyPeriodFrom = null;
    }
    if (this.data.property.agencyPeriodTo) {
      this.agencyPeriodTo = new Date(this.data.property.agencyPeriodTo);
    } else {
      this.agencyPeriodTo = null;
    }
    if (this.data.solicitorCompany) {
      this.selectedCompany = this.data.solicitorCompany;
      this.cacheCompany = this.data.solicitorCompany;
    } else {
      this.selectedCompany = new Company();
    }
    if (this.agentGroupList.length > 0) {
      this.customSortAgentList();
    }
  }


  reloadData() {
    this.prospectService.getOne(this.data.prospectId).subscribe(result => {
      this.data = result.data;
    });
  }

  getSolicitorCompany() {
    const searchRequest = new SearchRequest();
    searchRequest.offset = 0;
    searchRequest.limit = 10000;
    searchRequest.orders = [];
    searchRequest.orders.push({ left: 'serviceCount', right: 'desc' });
    searchRequest.conditions = [];
    searchRequest.conditions.push({ left: 'type', middle: '=', right: 'SOLICITOR' });
    this.companyService.search(searchRequest).subscribe(result => {
      this.solicitorCompany = result.data;
    });
  }

  initForm() {
    this.listingRepresentativeForm = new FormGroup({
      listingRepresentativeId: new FormControl(this.data?.listingRepresentative?.listingRepresentativeId),
      abnNumber: new FormControl(this.data?.listingRepresentative?.abnNumber),
      phone: new FormControl(this.data?.listingRepresentative?.phone),
      mobilePhone: new FormControl(this.data?.listingRepresentative?.mobilePhone),
      email: new FormControl(this.data?.listingRepresentative?.email),
    });

    this.marketingForm = new FormGroup({
      marketingId: new FormControl(this.data?.marketing?.marketingId),
      photoTime: new FormControl(this.data?.marketing?.photoTime ? new Date(this.data?.marketing?.photoTime) : ''),
      videoTime: new FormControl(this.data?.marketing?.videoTime ? new Date(this.data?.marketing?.videoTime) : ''),
      adNote: new FormControl(this.data?.marketing?.adNote),
    });
  }

  save() {
    if (!this.validateBeforeSave()) {
      return;
    }
    if (this.agentGroupList.length > 0 && !this.validateAgentGroupSplit()) {
      this.commonService.warning('Invalid agent groups split');
      return;
    }
    const result = new ListingDetailsRequest();
    result.prospectRequest = this.data;
    result.propertyRequest = this.getAdditionalPropertyRequest();
    result.prospectRequest.listingRepresentative = this.listingRepresentativeForm.value;
    result.prospectRequest.solicitorCompanyId = this.selectedCompany.companyId;
    result.prospectRequest.contractChecklist = this.contractChecklist;
    result.prospectRequest.marketing = this.getMarketingRequest();
    if (this.cacheCompany) {
      if (this.cacheCompany.companyId !== this.selectedCompany.companyId) {
        this.companyService.increaseServiceCount(this.selectedCompany).subscribe();
      }
    } else {
      if (this.selectedCompany.companyId) {
        this.companyService.increaseServiceCount(this.selectedCompany).subscribe();
      }
    }
    this.ref.close(result);
  }

  close() {
    this.ref.close();
  }

  searchUser(keyword: string): Observable<User[]> {
    // this.refreshUserFilter();
    const searchRequest = new SearchRequest();
    searchRequest.conditions = new Array();
    searchRequest.conditions.push(
      {
        left: 'email',
        middle: 'like',
        right: keyword,
      },
    );
    searchRequest.conditions.push(
      {
        left: 'username',
        middle: 'like',
        right: keyword,
      },
    );
    searchRequest.conditions.push(
      {
        left: 'name',
        middle: 'like',
        right: keyword,
      },
    );
    searchRequest.conditionType = Constant.CONDITION_TYPE.OR;
    searchRequest.offset = 0;
    searchRequest.limit = 10;
    return this.userService.search(searchRequest).pipe(
      map(result => result.data.filter(user => user.status === 'ACTIVE')),
    );
  }

  get VENDOR_TYPE() {
    return Constant.VENDOR_TYPE;
  }

  get LISTING_STATUS() {
    return Constant.LISTING_STATUS;
  }

  get AGREEMENT_TYPE() {
    return Constant.AGREEMENT_TYPE;
  }

  addVendor() {
    const request = new VendorRequest();
    request.isNew = true;
    request.prospectId = this.prospectId;
    this.vendorList.push(request);
  }

  deleteVendor(vendor: VendorRequest, index: number) {
    if (this.vendorList[index].isNew === true) {
      this.vendorList.splice(index, 1);
    } else {
      this.dialogService.open(ConfirmDialogComponent, {
        context: {
          content: 'Do you really want to delete?',
        },
      })
        .onClose.subscribe(res => {
        if (res === ConfirmDialogComponent.confirmOk) {
          this.vendorService.remove(vendor.vendorId).subscribe(
            result => {
              this.commonService.info('Delete successfully');
              this.vendorList.splice(index, 1);
            }, error => {
              this.commonService.warning(error.message);
            });
        }
      });
    }
  }

  get METHOD_OF_SALE() {
    return Constant.METHOD_OF_SALE;
  }

  addVPA() {
    const request = new VpaRequest();
    request.isNew = true;
    request.propertyId = this.propertyId;
    this.vpaList.push(request);
  }

  deleteVpa(vpa: VpaRequest, index: number) {
    if (this.vpaList[index].isNew === true) {
      this.vpaList.splice(index, 1);
    } else {
      this.dialogService.open(ConfirmDialogComponent, {
        context: {
          content: 'Do you really want to delete?',
        },
      })
        .onClose.subscribe(res => {
        if (res === ConfirmDialogComponent.confirmOk) {
          this.vpaService.remove(vpa.vpaId).subscribe(
            result => {
              this.commonService.info('Delete successfully');
              this.vpaList.splice(index, 1);
            }, error => {
              this.commonService.warning(error.message);
            });
        }
      });
    }
  }

  getAdditionalPropertyRequest() {
    this.data.property.listingStatus = 'For sale';
    this.data.property.agreementDate = this.agreementDate?.valueOf();
    this.data.property.agencyPeriodFrom = this.agencyPeriodFrom?.valueOf();
    this.data.property.agencyPeriodTo = this.agencyPeriodTo?.valueOf();
    this.data.property.agentGroups = this.agentGroupList;
    return this.data.property;
  }

  calculateVpaRemain() {
    if (this.data.property.vpaTotal) {
      this.vpaRemain = this.data.property.vpaTotal;
      if (this.data.property.vpaList) {
        this.data.property.vpaList.forEach(vpa => {
          this.vpaRemain -= vpa.value;
        });
      }
    } else {
      this.vpaRemain = null;
    }
  }

  calculatePricePercentage() {
    if (this.data.property.askingPriceFrom && this.data.property.askingPriceTo &&
      this.data.property.agentEstimatePriceFrom && this.data.property.agentEstimatePriceTo) {
      const askingPrice = this.average(this.data.property.askingPriceFrom, this.data.property.askingPriceTo);
      const agentPrice = this.average(this.data.property.agentEstimatePriceFrom, this.data.property.agentEstimatePriceTo);
      this.pricePercentage = Math.round((askingPrice / agentPrice) * 100);
    } else {
      this.pricePercentage = null;
    }
  }

  addChecklistItem() {
    const request = new ContractChecklistRequest();
    request.isNew = true;
    request.isEditing = true;
    request.isDone = false;
    request.prospectId = this.data.prospectId;
    this.contractChecklist.push(request);
  }

  deleteChecklistItem(item: ContractChecklistRequest, index: number) {
    if (this.contractChecklist[index].isNew === true) {
      this.contractChecklist.splice(index, 1);
    } else {
      this.dialogService.open(ConfirmDialogComponent, {
        context: {
          content: 'Do you really want to delete?',
        },
      })
        .onClose.subscribe(res => {
        if (res === ConfirmDialogComponent.confirmOk) {
          this.contractChecklistService.remove(item.contractChecklistId).subscribe(
            result => {
              this.commonService.info('Delete successfully');
              this.contractChecklist.splice(index, 1);
            }, error => {
              this.commonService.warning(error.message);
            });
        }
      });
    }
  }

  toggleEditItem(item: ContractChecklistRequest) {
    if (item.isEditing === false || item.isEditing === null || item.isEditing === undefined) {
      item.isEditing = true;
    } else if (item.isEditing === true) {
      item.isEditing = false;
    }
  }

  recalculateVpa() {
    this.vpaRemain = this.data.property.vpaTotal;
    this.vpaList.forEach(vpa => {
      this.vpaRemain -= vpa.value;
    });
  }

  addNote() {
    const request = new MarketingNoteRequest();
    if (this.data.marketing?.marketingId) {
      request.marketingId = this.data.marketing.marketingId;
    }
    request.isNew = true;
    this.noteList.push(request);
  }

  deleteNote(note: MarketingNoteRequest, index: number) {
    if (this.noteList[index].isNew === true) {
      this.noteList.splice(index, 1);
    } else {
      this.dialogService.open(ConfirmDialogComponent, {
        context: {
          content: 'Do you really want to delete?',
        },
      })
        .onClose.subscribe(res => {
        if (res === ConfirmDialogComponent.confirmOk) {
          this.marketingService.deleteNote(note.marketingNoteId).subscribe(
            result => {
              this.commonService.info('Delete successfully');
              this.noteList.splice(index, 1);
            }, error => {
              this.commonService.warning(error.message);
            });
        }
      });
    }
  }

  getMarketingRequest() {
    let request: MarketingRequest;
    request = this.marketingForm.value;
    request.notes = this.noteList;
    return request;
  }

  calculateAgentPrice() {
    if (this.data.property.agentEstimatePriceFrom && this.data.property.agentEstimatePriceTo) {
      this.agentPrice = this.average(this.data.property.agentEstimatePriceFrom, this.data.property.agentEstimatePriceTo);
    } else {
      this.agentPrice = null;
    }
    if (this.data.property.commissionRate && this.agentPrice !== null) {
      this.extractCommissionRate(this.data.property.commissionRate);
      this.fee = this.commissionRate * this.agentPrice / 100;
    } else {
      this.fee = null;
    }
    if (this.agentPrice !== null && this.fee !== null) {
      this.splitCommission = this.fee - this.data.property.adjustment;
    } else {
      this.splitCommission = null;
    }
  }

  calculateSplit(groupSplit: number, agentSplit: number): number {
    return Math.round(this.splitCommission * groupSplit / 100 * agentSplit / 100);
  }

  createNewAgent() {
    if (!this.data.property.agentEstimatePriceFrom || !this.data.property.agentEstimatePriceTo) {
      this.commonService.warning('Please input Agent Estimated Price in Agent Agreement block');
      return;
    }
    if (!this.data.property.commissionRate) {
      this.commonService.warning('Please input Commission Rate in Agent Agreement block');
      return;
    }
    this.isEditing = true;
    this.newAgent = new Agent();
    this.newAgent.isNew = true;
  }

  editDone() {
    if (!this.validateAgentSplit(this.newAgent)) {
      this.commonService.warning('Split must be from 1% to 100%');
      return;
    }
    if (this.selectedTitle === null) {
      this.commonService.warning('Please select a title');
      return;
    }
    const temp = this.agentGroupList.find(group => (group.title === this.selectedTitle));
    if (temp === undefined) {
      if (!this.newAgent.agentSplit) {
        this.newAgent.agentSplit = 100;
      }
      const newGroup = new AgentGroup();
      newGroup.type = 'SALE';
      newGroup.title = this.selectedTitle;
      newGroup.propertyId = this.propertyId;
      newGroup.split = 100;
      newGroup.agents = [];
      newGroup.agents.push(this.newAgent);
      this.agentGroupList.push(newGroup);
      this.isEditing = false;
      this.agentName = '';
    } else {
      if (!this.validateAgentSplit(this.newAgent, temp.agents)) {
        this.commonService.warning('Please adjust ' + temp.title + ' agent split');
        return;
      } else {
        temp.agents.push(this.newAgent);
        this.isEditing = false;
        this.agentName = '';
      }
    }
    this.selectedTitle = null;
  }

  cancelEdit() {
    this.isEditing = false;
    this.selectedTitle = null;
    this.agentName = '';
  }

  deleteAgent(agent: Agent, group: AgentGroup, index: number) {
    if (agent.isNew) {
      group.agents.splice(index, 1);
      if (group.agents.length === 0) {
        this.deleteAgentGroup(group, true);
      }
      return;
    }
    this.dialogService.open(ConfirmDialogComponent, {
      context: {
        content: 'Do you really want to delete?',
      },
    })
      .onClose.subscribe(res => {
      if (res === ConfirmDialogComponent.confirmOk) {
        this.agentService.remove(agent.agentId).subscribe(
          result => {
            group.agents.splice(index, 1);
            if (group.agents.length === 0) {
              this.deleteAgentGroup(group);
            }
          }, error => {
            this.commonService.warning(error.message);
          });
      }
    });
  }

  deleteAgentGroup(group: AgentGroup, isNew?: boolean) {
    let index;
    if (isNew) {
      index = this.agentGroupList.findIndex(g => g.title === group.title);
      if (index !== -1) {
        this.agentGroupList.splice(index, 1);
      }
      return;
    }
    index = this.agentGroupList.findIndex(g => g.agentGroupId === group.agentGroupId);
    this.agentGroupService.remove(group.agentGroupId).subscribe(r => {
      this.agentGroupList.splice(index, 1);
    });
  }

  // editAgentDone(agent: Agent, currentGroup: Agent[]) {
  //   if (!this.validateAgentSplit(agent)) {
  //     this.commonService.warning('Split must be from 1% to 100%');
  //     return;
  //   }
  //   if (!this.validateAgentSplit(agent, currentGroup)) {
  //     this.commonService.warning('Invalid split of each agent in this group');
  //     return;
  //   }
  // }

  selectUser(type: TypeaheadMatch) {
    const user = type.item;
    this.newAgent.user = user;
    this.newAgent.userId = user.userId;
  }

  get AGENT_TITLE() {
    return Constant.AGENT_TITLE_SALE;
  }

  onCompanyChange() {
    const id = Number(this.selectedCompany.companyId);
    this.selectedCompany = this.solicitorCompany.find(company => company.companyId === id);
  }

  // PRIVATE METHODS

  protected average(a: number, b: number): number {
    a = Number(a);
    b = Number(b);
    return ((a + b) / 2);
  }

  protected extractCommissionRate(s: string) {
    if (this.validateCommissionRate(s)) {
      const split = s.split('%');
      this.commissionRate = Number(split[0]);
    }
  }

  protected validateCommissionRate(s: string): boolean {
    const split = s.split('%');
    return split.length > 0;
  }

  protected validateAgentSplit(newAgent: Agent, currentAgents?: Agent[]): boolean {
    if (newAgent.agentSplit <= 0 || newAgent.agentSplit > 100) {
      return false;
    }
    if (currentAgents && currentAgents.length > 0) {
      let total: number = 0;
      currentAgents.forEach(agent => {
        total += Number(agent.agentSplit);
      });
      if (total + Number(newAgent.agentSplit) > 100) {
        return false;
      }
    }
    return true;
  }

  protected validateAgentGroupSplit(): boolean {
    let total = 0;
    this.agentGroupList.forEach(group => {
      total += Number(group.split);
    });
    return total <= 100;
  }

  protected validateBeforeSave(): boolean {
    let valid: boolean = true;
    this.agentGroupList.forEach(group => {
      if (group.split <= 0 || group.split > 100) {
        this.commonService.warning('Split must be from 1% to 100%');
        valid = false;
      }
      let total = 0;
      group.agents.forEach(agent => {
        total += Number(agent.agentSplit);
      });
      if (total > 100) {
        this.commonService.warning('Invalid agent split in ' + group.title);
        valid = false;
      }
    });
    return valid;
  }

  protected customSortAgentList() {
    this.agentGroupList.sort(function(a, b) {
      const x = a.title.toLowerCase();
      const y = b.title.toLowerCase();
      if (x > y) {
        return 1;
      }
      if (x < y) {
        return -1;
      }
      return 0;
    });
  }
}
