import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {District} from '../../../../models/response/address/district';
import {Street} from '../../../../models/response/address/street';
import {City} from '../../../../models/response/address/city';
import {ProspectSearchRequest} from '../../../../models/request/prospect/prospect-search-request';
import { Observable } from 'rxjs';
import { Postcode } from '../../../../models/response/postcode';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { map } from 'rxjs/operators';

@Component({
  selector: 'ngx-prospect-pick-filter-advance',
  templateUrl: './prospect-pick-filter-advance.component.html',
  styleUrls: ['./prospect-pick-filter-advance.component.scss'],
})
export class ProspectPickFilterAdvanceComponent implements OnInit {
  @Input() columnsDefinition: any;
  @Input() columnGroup: any;
  @Input() districts: District[];
  @Input() streets: Street[];
  @Input() cities: City[];
  // @Input() sortTypesText: any;
  @Input() farm: string;
  @Input() zone: string;
  @Output() onColumnDisplayOptionChange = new EventEmitter();
  @Output() onSuburbChange = new EventEmitter();
  @Output() onCityChange = new EventEmitter();
  @Output() onSearch = new EventEmitter();
  @Input() total: number;

  @Input() searchRequest: ProspectSearchRequest;

  rateOptions = [
    {value: 0, label: '0'},
    {value: 1, label: '1'},
    {value: 2, label: '2'},
    {value: 3, label: '3'},
    {value: 4, label: '4'},
    {value: 5, label: '5'},
    {value: 6, label: '6'},
    {value: 7, label: '7'},
  ];
  @Input() postcode: number;
  filterPostcode$: Observable<Postcode[]>;
  @Output() onSearchByPostcode = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
    this.filterPostcode$ = new Observable((observer: any) => {
      observer.next(this.postcode);
    }).pipe(
      map((query: string) => {
        this.postcode = Number(query);
        this.searchSuburbByPostcode();
        return null;
      }),
    );
  }

  onColumnDisplayOptionCheckedChange(checked: boolean, columnName: string) {
    if (this.columnsDefinition[columnName]) {
      this.columnsDefinition[columnName].enable = checked;
    }
  }

  onSuburbSelectChange() {
    this.onSuburbChange.emit(this.searchRequest.districtId);
  }

  onCitySelectChange() {
    this.onCityChange.emit(this.searchRequest.cityId);
  }

  applyColumnDisplayOptionChange() {
    this.onColumnDisplayOptionChange.emit(this.columnsDefinition);
  }

  search() {
    this.onSearch.emit();
  }

  selectPostcode(type: TypeaheadMatch) {
    this.postcode = Number(type.item.code);
    this.searchSuburbByPostcode();
  }

  searchSuburbByPostcode() {
    this.onSearchByPostcode.emit(this.postcode);
  }
}
