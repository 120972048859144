<div class='form-group col-12 col-lg-12'
     [ngClass]="isModal ? 'attachment-block' : ''"
>
  <label class='label' [hidden]='displayLabel === false'>Images</label>
  <div class="col-12 d-flex">
    <div class='col-2 mb-1' *ngFor='let image of images, index as index'>
      <img class='attachment-image' (click)="openModal(template, index)"
           src='{{image.path}}'>
      <i class='fa fa-times attachment-remove' (click)='deleteImage(image.attachmentId, index)'></i>
    </div>
  </div>

  <ngx-dropzone class='upload-file'
                (change)='onSelect($event)'
                [maxFileSize]='50000000'>
    <ngx-dropzone-label>Upload Image</ngx-dropzone-label>
    <ngx-dropzone-preview class='preview-file' *ngFor='let f of files' [removable]='true'
                          (removed)='onRemove(f)'>
      <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
    </ngx-dropzone-preview>
  </ngx-dropzone>

  <p (click)='submit()' class='sos-submit no-print mr-3' *ngIf="isModal">Upload</p>
</div>

<ng-template #template>
  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">×</span>
  </button>
  <div class="modal-body">
    <div class="image-slide">
      <carousel [noWrap]="false" [showIndicators]="true" [activeSlide]="index">
        <slide *ngFor="let slide of images; let index=index">
          <img [src]="slide.path" alt="image slide" style="display: block; width: 100%;">
        </slide>
      </carousel>
    </div>
  </div>
</ng-template>
