<nb-card *appPermissionCheck="ComponentPermission.TASK_STATUS_LIST">
  <nb-card-header>
    Task Status Management
  </nb-card-header>
  <nb-card-body>
    <ng2-smart-table [settings]="settings"
                     [source]="source"
                     (delete)="deleteRecord($event)"
                     (edit)="editRecord($event)"
                     (create)="addRecord($event)">
    </ng2-smart-table>
  </nb-card-body>
</nb-card>
