import {Component, Input, OnInit} from '@angular/core';
import {ViewCell} from 'ng2-smart-table';
import * as moment from 'moment';
import {Constant} from '../../../../shared/common/constant';
import {StringUtils} from '../../../../shared/common/string-utils';

@Component({
  template: `
    {{renderValue}}
  `,
})
export class BuyerListCustomCellComponent implements ViewCell, OnInit {

  renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;
  _valueReplacement?: any;
  _propertyPaths: string[];
  _valueType: string;
  _separator: string;
  public static VALUE_TYPES = {
    DEFAULT: 'DEFAULT',
    DATE: 'DATE',
    CURRENCY: 'CURRENCY',
    FORMATTED_NUMBER: 'FORMATTED_NUMBER',
  };

  groupColumns = ['address?.street.name'];

  set config(value: {
    valueReplacement?: any,  // {key : value}
    propertyPaths: string[],
    valueType: string,
    separator: string,
  }) {
    if (!value.propertyPaths) {
      return;
    }
    if (!value.valueType) {
      this._valueType = BuyerListCustomCellComponent.VALUE_TYPES.DEFAULT;
    }
    this._propertyPaths = value.propertyPaths;
    this._valueType = value.valueType;
    this._separator = value.separator ? value.separator : ' ';
    this._valueReplacement = value.valueReplacement;
    this.renderValue = this.getValue();
  }

  ngOnInit() {
    if (this._propertyPaths) {
      this.renderValue = this.getValue();
    } else {
      this.renderValue = this.value ? this.value.toString() : '';
    }
  }

  getValue(): any {
    let result = '';
    this._propertyPaths.forEach(propertyPath => {
      const value = this.extractValueFromData(propertyPath);
      if (value && value !== '') {
        result = `${result}${this._separator}${value}`;
      }
    });
    return result.trim().replace(this._separator, '');
  }

  extractValueFromData(propertyPath: string): any {
    if (!this.rowData) {
      return '';
    }
    if (this.rowData.type === 'GROUP' && propertyPath === 'customer.name') {
      return `(${this.rowData?.groupData?.totalItems})
      ${this.rowData?.groupData?.day} / ${this.rowData?.groupData?.month} / ${this.rowData?.groupData?.year}`;
    }
    if (propertyPath && propertyPath.length > 0 && this.rowData) {
      const properties: string[] = propertyPath.split('.');
      if (properties && properties.length > 0) {
        let value = null;
        for (const x of properties) {
          if (!value) {
            if (this.rowData.type === 'GROUP') {
              if (this.groupColumns.indexOf(propertyPath) > -1) {
                value = this.rowData?.groupData[x];
              } else {
                value = '';
                break;
              }
            } else {
              if (this.rowData?.data) {
                value = this.rowData?.data[x];
              }
            }
          } else if (value[x]) {
            value = value[x];
          } else {
            value = '';
            break;
          }
        }
        if (value && value !== '') {
          switch (this._valueType) {
            case BuyerListCustomCellComponent.VALUE_TYPES.CURRENCY:
              value = `${value}${Constant.DEFAULT_CURRENCY_ICON}`;
              break;
            case BuyerListCustomCellComponent.VALUE_TYPES.DATE:
              value = moment(Number(value)).format('DD/MM/YYYY');
              break;
            case BuyerListCustomCellComponent.VALUE_TYPES.FORMATTED_NUMBER:
              value = StringUtils.formatNumber(Number(value));
              break;
            default:
              break;
          }
        }
        if (this._valueReplacement) {
          value = this._valueReplacement[value];
        }
        return value;
      }
    }
  }

}
