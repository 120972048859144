import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {AuthGuardService} from '../../shared/services/auth.guard.service';
import {OverviewComponent} from './overview/overview.component';
import {LayoutComponent} from './layout';

const routes: Routes = [{
  path: '',
  component: LayoutComponent,
  canActivate: [AuthGuardService],
  children: [
    {
      path: 'dashboard',
      loadChildren: () => import('./overview/overview.module')
      .then(m => m.OverviewModule),
      canActivate: [AuthGuardService],
    },
    {
      path: 'user-management',
      loadChildren: () => import('./user-management/user-management.module')
      .then(m => m.UserManagementModule),
      canActivate: [AuthGuardService],
    },
    {
      path: 'sos',
      loadChildren: () => import('./sos/sos.module')
      .then(m => m.SosModule),
      canActivate: [AuthGuardService],
    },
    {
      path: 'task',
      loadChildren: () => import('./task/task.module')
      .then(m => m.TaskModule),
      canActivate: [AuthGuardService],
    },
    {
      path: 'prospect',
      loadChildren: () => import('./prospect/prospect.module')
      .then(m => m.ProspectModule),
      canActivate: [AuthGuardService],
    },
    {
      path: 'buyer',
      loadChildren: () => import('./buyer/buyer.module')
      .then(m => m.BuyerModule),
      canActivate: [AuthGuardService],
    },
    {
      path: 'address',
      loadChildren: () => import('./address/address.module')
      .then(m => m.AddressModule),
      canActivate: [AuthGuardService],
    },
    {
      path: 'other',
      loadChildren: () => import('./other/other.module')
      .then(m => m.OtherModule),
      canActivate: [AuthGuardService],
    },
    {
      path: 'management',
      loadChildren: () => import('./management/management.module')
        .then(m => m.ManagementModule),
      canActivate: [AuthGuardService],
    },
    {
      path: '',
      redirectTo: 'dashboard/index',
      pathMatch: 'full',
    },
  ],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {
}
