import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateUtils } from '../../../../../../shared/common/date-utils';
import { District } from '../../../../../../shared/models/response/address/district';
import { City } from '../../../../../../shared/models/response/address/city';
import { Zone } from '../../../../../../shared/models/response/address/zone';
import { Constant } from '../../../../../../shared/common/constant';
import { Observable } from 'rxjs';
import { Postcode } from '../../../../../../shared/models/response/postcode';
import { SearchRequest } from '../../../../../../shared/models/request/search-request';
import { map, mergeMap } from 'rxjs/operators';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { StringUtils } from '../../../../../../shared/common/string-utils';
import { PostcodeService } from '../../../../../../shared/services/postcode.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ngx-buyer-list-filter-callbackdate',
  templateUrl: './buyer-list-filter-callbackdate.component.html',
  styleUrls: ['./buyer-list-filter-callbackdate.component.scss'],
})
export class BuyerListFilterCallbackdateComponent implements OnInit {
  @Input() total: number;
  @Input() zones: Zone[];
  @Input() selectedZoneId: number;
  @Input() districts: District[];
  @Input() selectedDistrictId: number;
  @Input() cities: City[];
  @Input() selectedCityId: number;

  years: number[];
  quarters: number[];
  months: number[];
  weeks: number[];

  @Input() selectedYear: number;
  @Input() selectedQuarter: number;
  @Input() selectedMonth: number;
  @Input() selectedWeek: number;

  @Output() onYearChange = new EventEmitter();
  @Output() onMonthChange = new EventEmitter();
  @Output() onWeekChange = new EventEmitter();
  @Output() onQuarterChange = new EventEmitter();
  @Output() onFarmChange = new EventEmitter();
  @Output() onZoneChange = new EventEmitter();
  @Output() onSuburbChange = new EventEmitter();
  @Output() onCityChange = new EventEmitter();

  classes = [
    'F', 'I', 'VB', 'D',
  ];
  @Input() postcode: number;
  @Output() onSearchByPostcode = new EventEmitter();
  filterPostcode$: Observable<Postcode[]>;
  filterByPostcode = false;

  constructor(private postcodeService: PostcodeService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    // this.selectedYear = null;
    // this.selectedQuarter = null;
    // this.selectedMonth = null;
    // this.selectedWeek = null;
    this.quarters = [];
    this.years = [];
    for (let i = 1; i <= 4; i++) {
      this.quarters.push(i);
    }
    for (let i = 2000; i <= 3000; i++) {
      this.years.push(i);
    }
    this.initCallbackDate();
    this.filterPostcode$ = new Observable((observer: any) => {
      observer.next(this.postcode);
    }).pipe(
      map((query: string) => {
        this.postcode = Number(query);
        this.searchSuburbByPostcode();
        return null;
      }),
    );
    if (this.route.snapshot.queryParams['postcode']) {
      this.filterByPostcode = true;
    }
  }

  onYearSelectChange() {
    this.selectedQuarter = undefined;
    this.selectedMonth = undefined;
    this.selectedWeek = undefined;
    this.onYearChange.emit(this.selectedYear);
  }

  onQuarterSelectChange() {
    this.selectedMonth = undefined;
    this.selectedWeek = undefined;
    this.months = [];
    if (this.selectedQuarter) {
      for (let i = 1; i <= 3; i++) {
        this.months.push(3 * (this.selectedQuarter - 1) + i); // 1 2 3 4 5 6
      }
    }
    this.onQuarterChange.emit(this.selectedQuarter);
  }

  onMonthSelectChange() {
    this.selectedWeek = undefined;
    this.weeks = [];
    if (this.selectedMonth) {
      const firstWeek = DateUtils.getFirstWeekOfMonth(this.selectedMonth - 1, this.selectedYear);
      const lastWeek = DateUtils.getLastWeekOfMonth(this.selectedMonth - 1, this.selectedYear);
      for (let i = firstWeek; i <= lastWeek; i++) {
        this.weeks.push(i);
      }
    }
    this.onMonthChange.emit(this.selectedMonth);
  }

  onWeekSelectChange() {
    this.onWeekChange.emit(this.selectedWeek);
  }

  onSuburbSelectChange() {
    this.onSuburbChange.emit(isNaN(Number(this.selectedDistrictId)) ? undefined : Number(this.selectedDistrictId));
  }

  onZoneSelectChange() {
    this.onZoneChange.emit(isNaN(Number(this.selectedZoneId)) ? undefined : Number(this.selectedZoneId));
  }

  onCitySelectChange() {
    this.onCityChange.emit(isNaN(Number(this.selectedCityId)) ? undefined : Number(this.selectedCityId));
  }

  BUYER_PRICE() {
    return Constant.MIN_PRICE;
  }

  initCallbackDate() {
    if (this.selectedQuarter) {
      this.months = [];
      if (this.selectedQuarter) {
        for (let i = 1; i <= 3; i++) {
          this.months.push(3 * (this.selectedQuarter - 1) + i); // 1 2 3 4 5 6
        }
      }
    }
    if (this.selectedMonth) {
      this.weeks = [];
      if (this.selectedMonth) {
        const firstWeek = DateUtils.getFirstWeekOfMonth(this.selectedMonth - 1, this.selectedYear);
        const lastWeek = DateUtils.getLastWeekOfMonth(this.selectedMonth - 1, this.selectedYear);
        for (let i = firstWeek; i <= lastWeek; i++) {
          this.weeks.push(i);
        }
      }
    }
  }

  searchPostcode(keyword: string): Observable<Postcode[]> {
    const searchRequest = new SearchRequest();
    searchRequest.conditions = [];
    searchRequest.orders = [];
    searchRequest.conditions.push(
      {
        left: 'code',
        middle: '=',
        right: keyword,
      },
    );
    searchRequest.orders.push(
      {
        left: '=',
        right: 'asc'
      }
    );
    searchRequest.offset = 0;
    searchRequest.limit = 10;
    return this.postcodeService.search(searchRequest).pipe(
      map(result => result.data),
    );
  }

  selectPostcode(type: TypeaheadMatch) {
    this.postcode = Number(type.item.code);
    this.searchSuburbByPostcode();
  }

  searchSuburbByPostcode() {
    if (this.postcode === null) {
      return;
    }
    this.filterByPostcode = true;
    this.onSearchByPostcode.emit(this.postcode);
  }
}
