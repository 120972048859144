import {Component, OnInit, Optional, ViewChild} from '@angular/core';
import {NbDialogRef, NbDialogService} from '@nebular/theme';
import {CommonService} from '../../../../../shared/services/common.service';
import {BuyerService} from '../../../../../shared/services/buyer.service';
import {BuyerSearchRequest} from '../../../../../shared/models/request/buyer/buyer-search-request';
import {BuyerDatasource} from '../../common/buyer-datasource';
import {RestResult} from '../../../../../shared/models/response/rest-result';
import {BuyerGroupWrapper} from '../../../../../shared/models/response/buyer/buyer-group-wrapper';
import {BuyerUtils, WrapBuyer, WrapBuyerType} from '../../common/buyer-utils';
import {DateUtils} from '../../../../../shared/common/date-utils';
import {Buyer} from '../../../../../shared/models/response/buyer/buyer';
import * as moment from 'moment';
import {District} from '../../../../../shared/models/response/address/district';
import {City} from '../../../../../shared/models/response/address/city';
import {LocalService} from '../../../../../shared/services/local.service';
import {BuyerListAddressCellComponent} from '../../common/buyer-list-address-cell-component';
import {Zone} from '../../../../../shared/models/response/address/zone';
import {BuyerListCalledDateCellComponent} from '../../common/buyer-list-called-date-cell-component';
import {SearchRequest} from '../../../../../shared/models/request/search-request';
import {Company} from '../../../../../shared/models/response/prospect/company';
import {TaskType} from '../../../../../shared/models/response/task-type';
import {TaskStatus} from '../../../../../shared/models/response/task-status';
import {BuyerCreateComponent} from '../../../../../shared/module/buyer-create/buyer-create.component';
import {CityService} from '../../../../../shared/services/city.service';
import {TaskStatusService} from '../../../../../shared/services/task-status.service';
import {SuburbService} from '../../../../../shared/services/suburb.service';
import {StreetService} from '../../../../../shared/services/street.service';
import {CompanyService} from '../../../../../shared/services/company.service';
import {TaskTypeService} from '../../../../../shared/services/task-type.service';
import {Constant} from '../../../../../shared/common/constant';
import {User} from '../../../../../shared/models/response/user';
import {AuthService} from '../../../../../shared/services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {StringUtils} from '../../../../../shared/common/string-utils';
import {StatusUtils} from '../../common/status-utils';

@Component({
  selector: 'ngx-buyer-list-callbackdate',
  templateUrl: './buyer-list-callbackdate.component.html',
  styleUrls: ['./buyer-list-callbackdate.component.scss'],
})
export class BuyerListCallbackdateComponent implements OnInit {
  cities: City[];
  filteredDistricts: District[];
  filteredZones: Zone[];

  columnsDefinition = {
    buyerName: {
      enable: true,
      value: BuyerUtils.createColumn('Name', ['customer.name']),
      name: 'Name',
    },
    buyerSurname: {
      enable: true,
      value: BuyerUtils.createColumn('SName', ['customer.surname']),
      name: 'SName',
    },
    buyerAddress: {
      enable: true,
      value: BuyerUtils.createColumn('Address',
        null, null, ', ', null, BuyerListAddressCellComponent),
      name: 'Address',
    },
    lastCalledDate: {
      enable: true,
      value: BuyerUtils.createColumn('Last called date',
        null, null, null, null, BuyerListCalledDateCellComponent),
      name: 'Last called date',
    },
  };

  settings = {
    mode: 'external',
    hideSubHeader: true,
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    pager: {
      display: true,
      perPage: 10000,
    },
    columns: BuyerUtils.createColumns(this.columnsDefinition),
    rowClassFunction: (row) => {
      return BuyerUtils.highlightProspectRow(row?.data);
    },
  };

  pageSize = Constant.PAGE_SIZE;
  currentPage = 1;
  searchRequest = new BuyerSearchRequest();
  change: any;
  source: BuyerDatasource;
  totalPage = 0;
  isDialog = false;
  isDisplay: boolean;
  groupWrapper: BuyerGroupWrapper;
  selectedBuyer: Buyer;
  selectedBuyers: Buyer[];
  // filter
  selectedYear: number;
  selectedQuarter: number;
  selectedMonth: number;
  selectedWeek: number;
  @ViewChild('buyerForm') buyerDialog: any;
  buyers: Buyer[];
  companies: Company[];
  taskTypes: TaskType[];
  taskStatuses: TaskStatus[];
  currentUser: User;
  param = {};
  isFiltered: boolean;
  selectedBuyerId: number;

  @ViewChild(BuyerCreateComponent) buyerCreate: BuyerCreateComponent;

  constructor(@Optional() private ref: NbDialogRef<BuyerCreateComponent>,
              private dialogService: NbDialogService,
              private commonService: CommonService,
              private buyerService: BuyerService,
              private localService: LocalService,
              private cityService: CityService,
              private taskStatusService: TaskStatusService,
              private suburbService: SuburbService,
              private streetService: StreetService,
              private companyService: CompanyService,
              private taskTypeService: TaskTypeService,
              private authService: AuthService,
              private route: ActivatedRoute,
              private router: Router) {
    this.source = new BuyerDatasource();
  }

  ngOnInit() {
    this.param = {...this.route.snapshot.queryParams};
    this.currentUser = this.authService.currentUser;
    this.isFiltered = false;
    this.source.onChanged().subscribe((change) => {
      this.change = change;
      if (change.action === 'refresh') {
        this.pageChange(1);
      }
      if (change.action === 'page') {
        delete this.param['selectedBuyerId'];
        delete this.param['year'];
        delete this.param['month'];
        delete this.param['day'];
        this.param = {...this.param, page: change.paging.page};
        this.navigateWithParam();
        this.pageChange(change.paging.page);
      }
    });
    this.loadForFilter();
    this.getTaskTypes();
    this.getTaskStatus();
    this.preLoadAddressForFilter();
    this.preLoadSelectedEnqTime();
  }

  // table
  pageChange(pageIndex) {
    this.currentPage = pageIndex;
    this.searchRequest.offset = (pageIndex - 1) * this.pageSize;
    if (this.searchRequest.offset < 0) {
      this.searchRequest.offset = 0;
    }
    this.searchRequest.limit = this.pageSize;
    this.searchRequest.hasCallbackDate = true;
    this.searchRequest.officeId = this.currentUser.office?.officeId;
    if (!this.isFiltered) {
      this.appendParameterToSearchRequest();
    }
    this.buyerService.searchGroups(this.searchRequest).subscribe((result: RestResult) => {
      this.groupWrapper = result.data;
      this.totalPage = Math.ceil(this.groupWrapper.totalGroups / this.pageSize);
      const buyerId = Number(this.route.snapshot.queryParams['selectedBuyerId']);
      const year = Number(this.route.snapshot.queryParams['year']);
      const month = Number(this.route.snapshot.queryParams['month']);
      const day = Number(this.route.snapshot.queryParams['day']);
      if (buyerId) {
        this.buyerService.getOne(buyerId).subscribe(r => {
          this.selectedBuyer = r.data;
          this.buyerCreate.setBuyer(this.selectedBuyer);
          const convertResult = BuyerUtils.convertGroupToWrapperData(this.groupWrapper.groups);
          this.buildData(this.pageSize, convertResult, () => {
            this.restoreState();
          });
        });
      } else {
        const convertResult = BuyerUtils.convertGroupToWrapperData(this.groupWrapper.groups);
        this.buildData(this.pageSize, convertResult, () => {
          this.restoreState();
        });
        if (year && month && day) {
          const expand = convertResult.find(r => r.groupData.year === year && r.groupData.month === month && r.groupData.day === day);
          this.expandGroup(expand);
        }
      }
    });
  }

  protected buildData(pageSize: number, realData: WrapBuyer[], callback?: any) {
    this.source.total = this.totalPage * pageSize;
    this.source.setPaging(this.currentPage, pageSize, false);
    const data = [];
    for (let i = 0; i <= (this.currentPage - 1) * pageSize - 1; i++) {
      data.push({});
    }
    realData.forEach(x => {
      data.push(x);
    });
    this.source.load(data).then(() => {
      if (callback) {
        callback();
      }
    });
  }

  protected expandGroup(group: WrapBuyer, callback?: any) {
    group.isExpanded = !group.isExpanded;
    this.source.getAll()
      .then(d => {
        let wrapProspects: WrapBuyer[] = d;
        const groupIndex = wrapProspects.findIndex(x => x.groupData?.year === group.groupData.year
          && x.groupData?.month === group.groupData.month
          && x.groupData?.day === group.groupData.day);
        const firstPart: WrapBuyer[] = wrapProspects.slice(0, groupIndex + 1);
        if (group.isExpanded) {
          const itemSearchRequest = {...this.searchRequest};
          itemSearchRequest.year = group.groupData.year;
          itemSearchRequest.month = group.groupData.month;
          itemSearchRequest.day = group.groupData.day;
          this.buyerService.searchItems(itemSearchRequest)
            .subscribe((result: RestResult) => {
              const itemWrappers = BuyerUtils.convertGroupItemToWrapperData(result.data);
              const secondPart: WrapBuyer[] = wrapProspects.slice(groupIndex + 1);
              wrapProspects = [...firstPart, ...itemWrappers, ...secondPart];
              const realData = wrapProspects.filter(x => x.type);
              this.buildData(realData.length, realData, () => {
                if (callback) {
                  callback();
                }
              });
            });
        } else {
          const secondPart: WrapBuyer[] = wrapProspects.slice(groupIndex + 1 + group.groupData.totalItems);
          wrapProspects = [...firstPart, ...secondPart];
          const realData = wrapProspects.filter(x => x.type);
          this.buildData(realData.length, realData, () => {
            if (callback) {
              callback();
            }
          });
        }
      });
  }

  selectRecord(event: any) {
    const wrapProspect: WrapBuyer = event.data;
    this.isFiltered = true;
    if (this.param['selectedBuyerId']) {
      this.buyerService.getOne((Number)(this.param['selectedBuyerId'])).subscribe(result => {
        this.selectedBuyer = result.data;
      });
    }
    if (wrapProspect?.type === WrapBuyerType.ITEM) {
      this.selectedBuyer = wrapProspect.data;
      this.highlightRow(this.selectedBuyer.buyerId);
      this.buyerCreate.setBuyer(this.selectedBuyer);
      this.param = {...this.param, selectedBuyerId: this.selectedBuyer.buyerId};
      if (this.isFiltered) {
        this.navigateWithParam();
      }
    } else if (wrapProspect?.type === WrapBuyerType.GROUP) {
      if (this.param['year'] && this.param['month'] && this.param['day']) {
        const y = this.param['year'];
        const m = this.param['month'];
        const d = this.param['day'];
        if (y !== wrapProspect.groupData.year && m !== wrapProspect.groupData.month && d !== wrapProspect.groupData.day) {
          delete this.param['selectedBuyerId'];
        }
      }
      this.param = {...this.param, year: wrapProspect.groupData.year};
      this.param = {...this.param, month: wrapProspect.groupData.month};
      this.param = {...this.param, day: wrapProspect.groupData.day};
      if (this.isFiltered) {
        this.navigateWithParam();
      }
      this.expandGroup(wrapProspect);
    }

    // set selected buyers
    this.source.getAll().then(d => {
      const wrapProspects: WrapBuyer[] = d;
      this.selectedBuyers = wrapProspects.filter(x => {
        return x.type === WrapBuyerType.ITEM;
      })?.map(x => x.data);
      this.buyerCreate.setBuyers(this.selectedBuyers);
    });
  }

  public highlightRow(buyerId: number) {
    this.source.getAll().then(d => {
      const wrapProspects: WrapBuyer[] = d;
      for (const wrapProspect of wrapProspects) {
        if (wrapProspect.data?.buyerId === buyerId) {
          wrapProspect.isSelected = true;
        } else {
          wrapProspect.isSelected = false;
        }
      }
      this.source.load(wrapProspects);
    });
  }

  // filter
  onYearChange(value: number) {
    this.selectedYear = value;
    if (value) {
      this.isFiltered = true;
      const firstDay = DateUtils.getFirstDayOfYear(value);
      const lastDay = DateUtils.getLastDayOfYear(value);
      this.searchRequest.fromCallbackDate = firstDay.valueOf();
      this.searchRequest.toCallbackDate = lastDay.valueOf();
      if (this.searchRequest.fromCallbackDate) {
        this.param = {...this.param, fromCallbackDate: this.searchRequest.fromCallbackDate};
      } else {
        delete this.param['fromCallbackDate'];
      }
      if (this.searchRequest.toCallbackDate) {
        this.param = {...this.param, toCallbackDate: this.searchRequest.toCallbackDate};
      } else {
        delete this.param['toCallbackDate'];
      }
      this.param = {...this.param, selectedYear: this.selectedYear};
      this.navigateWithParam();
      this.pageChange(1);
    } else {
      this.searchRequest.fromCallbackDate = null;
      this.searchRequest.toCallbackDate = null;
    }
    if (this.param['fromCallbackDate']) {
      delete this.param['fromCallbackDate'];
    }
    if (this.param['toCallbackDate']) {
      delete this.param['toCallbackDate'];
    }
    if (this.param['selectedYear']) {
      delete this.param['selectedYear'];
    }
    if (this.param['selectedQuarter']) {
      delete this.param['selectedQuarter'];
    }
    if (this.param['selectedMonth']) {
      delete this.param['selectedMonth'];
    }
    if (this.param['selectedWeek']) {
      delete this.param['selectedWeek'];
    }
  }

  onMonthChange(value: number) {
    if (value && (!this.selectedYear || !this.selectedQuarter)) {
      return;
    }
    if (value) {
      this.isFiltered = true;
      this.selectedMonth = value;
      const firstDay = DateUtils.getFirstDayOfMonth(value - 1, this.selectedYear);
      const lastDay = DateUtils.getLastDayOfMonth(value - 1, this.selectedYear);
      this.searchRequest.fromCallbackDate = firstDay.valueOf();
      this.searchRequest.toCallbackDate = lastDay.valueOf();
      if (this.searchRequest.fromCallbackDate) {
        this.param = {...this.param, fromCallbackDate: this.searchRequest.fromCallbackDate};
      } else {
        delete this.param['fromCallbackDate'];
      }
      if (this.searchRequest.toCallbackDate) {
        this.param = {...this.param, toCallbackDate: this.searchRequest.toCallbackDate};
      } else {
        delete this.param['toCallbackDate'];
      }
      this.param = {...this.param, selectedMonth: this.selectedMonth};
      this.navigateWithParam();
      if (this.selectedMonth === undefined) {
        delete this.param['selectedMonth'];
      }
      this.pageChange(1);
    } else if (this.selectedQuarter) {
      this.onQuarterChange(this.selectedQuarter);
      return;
    } else if (this.selectedYear) {
      this.onYearChange(this.selectedYear);
      return;
    } else {
      this.pageChange(1);
    }
  }

  onQuarterChange(value: number) {
    if (value && !this.selectedYear) {
      return;
    }
    if (value) {
      this.selectedQuarter = value;
      const firstDay = DateUtils.getFirstDayOfQuarter(value - 1, this.selectedYear);
      const lastDay = DateUtils.getLastDayOfQuarter(value - 1, this.selectedYear);
      this.searchRequest.fromCallbackDate = firstDay.valueOf();
      this.searchRequest.toCallbackDate = lastDay.valueOf();
      if (this.searchRequest.fromCallbackDate) {
        this.param = {...this.param, fromCallbackDate: this.searchRequest.fromCallbackDate};
      } else {
        delete this.param['fromCallbackDate'];
      }
      if (this.searchRequest.toCallbackDate) {
        this.param = {...this.param, toCallbackDate: this.searchRequest.toCallbackDate};
      } else {
        delete this.param['toCallbackDate'];
      }
      this.param = {...this.param, selectedQuarter: this.selectedQuarter, selectedYear: this.selectedYear};
      this.isFiltered = true;
      this.navigateWithParam();
      if (this.selectedQuarter === undefined) {
        delete this.param['selectedQuarter'];
      }
      this.pageChange(1);
    } else if (this.selectedYear) {
      this.onYearChange(this.selectedYear);
      return;
    } else {
      this.pageChange(1);
    }
  }

  onWeekChange(value: number) {
    if (value && !this.selectedYear) {
      return;
    }
    if (value) {
      this.selectedWeek = value;
      const firstDay = DateUtils.getFirstDayOfWeek(value, this.selectedYear);
      const lastDay = DateUtils.getLastDayOfWeek(value, this.selectedYear);
      this.searchRequest.fromCallbackDate = firstDay.valueOf();
      this.searchRequest.toCallbackDate = lastDay.valueOf();
      if (this.searchRequest.fromCallbackDate) {
        this.param = {...this.param, fromCallbackDate: this.searchRequest.fromCallbackDate};
      } else {
        delete this.param['fromCallbackDate'];
      }
      if (this.searchRequest.toCallbackDate) {
        this.param = {...this.param, toCallbackDate: this.searchRequest.toCallbackDate};
      } else {
        delete this.param['toCallbackDate'];
      }
      this.param = {...this.param, selectedWeek: this.selectedWeek};
      this.isFiltered = true;
      this.navigateWithParam();
      if (this.selectedWeek === undefined) {
        delete this.param['selectedWeek'];
      }
      this.pageChange(1);
    } else if (this.selectedMonth) {
      this.onMonthChange(this.selectedMonth);
      return;
    } else if (this.selectedQuarter) {
      this.onQuarterChange(this.selectedQuarter);
      return;
    } else if (this.selectedYear) {
      this.onYearChange(this.selectedYear);
      return;
    } else {
      this.isFiltered = true;
      this.pageChange(1);
    }
  }

  reloadList() {
    this.pageChange(this.currentPage);
  }

  restoreState() {
    if (!this.selectedBuyer) {
      return;
    }
    this.source.getAll().then(d => {
      const wrapProspects: WrapBuyer[] = d;
      const group = wrapProspects.find(x => {
        return x?.groupData?.year === moment(this.selectedBuyer?.property?.enqDate).year()
          && x?.groupData?.month === moment(this.selectedBuyer?.property?.enqDate).month() + 1
          && x?.groupData?.day === moment(this.selectedBuyer?.property?.enqDate).date();
      });
      if (group) {
        group.isExpanded = false;
        this.expandGroup(group, () => {
          this.source.getAll().then(d1 => {
            const newWrapProspects: WrapBuyer[] = d1;
            const selectBuyerId = this.selectedBuyer?.buyerId;
            this.selectedBuyer = newWrapProspects.find(x => x?.data?.buyerId === selectBuyerId)?.data;
            this.highlightRow(selectBuyerId);
          });
        });
      }
    });
  }

  onSuburbChange(districtId) {
    this.searchRequest.districtId = districtId;
    this.isFiltered = true;
    if (this.searchRequest.districtId) {
      this.param = {...this.param, districtId: this.searchRequest.districtId};
    } else {
      delete this.param['districtId'];
    }
    this.navigateWithParam();
    if (this.searchRequest.districtId === undefined) {
      delete this.param['districtId'];
    }
    this.pageChange(1);
  }

  onZoneChange(zoneId) {
    this.searchRequest.zoneId = zoneId;
    this.searchRequest.districtId = undefined;
    if (this.searchRequest.zoneId) {
      this.param = {...this.param, zoneId: this.searchRequest.zoneId};
    } else {
      delete this.param['zoneId'];
    }
    this.navigateWithParam();
    if (this.searchRequest.zoneId === undefined) {
      delete this.param['zoneId'];
    }
    if (zoneId) {
      this.localService.districtsByZone(zoneId).subscribe(result => {
        this.filteredDistricts = result;
      });
    }
    this.pageChange(1);
  }

  onCityChange(cityId) {
    this.searchRequest.cityId = cityId;
    this.searchRequest.districtId = undefined;
    this.searchRequest.zoneId = undefined;
    this.isFiltered = true;
    if (this.searchRequest.cityId) {
      this.param = {...this.param, cityId: this.searchRequest.cityId};
    } else {
      delete this.param['cityId'];
    }
    this.navigateWithParam();
    if (this.searchRequest.cityId === undefined) {
      delete this.param['cityId'];
    }
    if (cityId) {
      this.localService.districts(cityId).subscribe(result => {
        this.filteredDistricts = result;
      });
      this.localService.zones(cityId).subscribe(result => {
        this.filteredZones = result;
      });
    }
    this.pageChange(1);
  }

  loadForFilter() {
    const searchRequest = new SearchRequest();
    searchRequest.offset = 0;
    searchRequest.limit = 10000;
    searchRequest.orders = new Array();
    searchRequest.orders.push(
      {
        left: 'name',
        right: 'asc',

      },
    );
    this.localService.cities().subscribe((result: City[]) => {
      this.cities = result;
    });
    this.companyService.search(searchRequest).subscribe((result: RestResult) => {
      this.companies = result?.data;
    });
  }

  getTaskTypes() {
    const taskTypeSearchRequest: SearchRequest = new SearchRequest();
    taskTypeSearchRequest.orders = [];
    taskTypeSearchRequest.orders.push(
      {
        left: 'name',
        right: 'asc',

      },
    );
    this.taskTypeService.search(taskTypeSearchRequest).subscribe((result: RestResult) => {
      this.taskTypes = result.data;
    });
  }

  handleSelectedBuyerChange(value: Buyer) {
    this.selectedBuyer = value;
    this.buyerCreate.setBuyer(this.selectedBuyer);
  }

  navigateProspect(value: { currentBuyerId: number, forward: boolean }) {
    if (!value) {
      return;
    }
    this.selectedBuyer = this.selectedBuyers?.find(x => x.buyerId === value.currentBuyerId);
    this.buyerCreate.setBuyer(this.selectedBuyer);
    // this.selectedBuyer = this.selectedBuyer;
    this.highlightRow(value.currentBuyerId);
  }

  getTaskStatus() {
    const taskStatusSearchRequest: SearchRequest = new SearchRequest();
    taskStatusSearchRequest.orders = [];
    taskStatusSearchRequest.orders.push(
      {
        left: 'name',
        right: 'asc',

      },
    );
    this.taskStatusService.search(taskStatusSearchRequest).subscribe((result: RestResult) => {
      this.taskStatuses = result.data;
    });
  }

  editDone(result: any) {
    if (StatusUtils.isSuccessResponse(result)) {
      this.reloadList();
    }
  }

  newDone(result: any) {
    if (StatusUtils.isSuccessResponse(result)) {
      this.reloadList();
      this.selectedBuyer = result.data;
      this.restoreState();
    }
  }

  ownSet(buyer: Buyer) {
    this.handleSelectedBuyerChange(buyer);
    // remove selectedBuyer from buyerList
    this.selectedBuyer = buyer;
    this.highlightRow(buyer ? buyer.buyerId : null);
  }

  reserveBuyer(buyer: Buyer) {
    // unset selected buyer, add default buyer to buyerCreate
    this.selectedBuyer = null;
    this.buyerCreate.setBuyer(buyer);
    // unset select buyer, unset highlightRow
    this.selectedBuyer = null;
    this.highlightRow(null);
  }

  appendFilterToParameter() {
    //  Temporary disable append to parameter in overview dashboard
    if (this.searchRequest.cityId) {
      this.param = {...this.param, cityId: this.searchRequest.cityId};
    } else {
      delete this.param['cityId'];
      delete this.param['districtId'];
    }
    if (this.searchRequest.zoneId) {
      this.param = {...this.param, zoneId: this.searchRequest.zoneId};
    } else {
      delete this.param['districtId'];
    }
    if (this.searchRequest.districtId) {
      this.param = {...this.param, districtId: this.searchRequest.districtId};
    } else {
      delete this.param['districtId'];
    }
    if (this.selectedYear) {
      this.param = {...this.param, selectedYear: this.selectedYear};
    } else {
      delete this.param['selectedYear'];
    }
    if (this.selectedMonth) {
      this.param = {...this.param, selectedMonth: this.selectedMonth};
    } else {
      delete this.param['selectedMonth'];
    }
    if (this.selectedQuarter) {
      this.param = {...this.param, selectedQuarter: this.selectedQuarter};
    } else {
      delete this.param['selectedQuarter'];
    }
    if (this.selectedWeek) {
      this.param = {...this.param, selectedWeek: this.selectedWeek};
    } else {
      delete this.param['selectedWeek'];
    }

  }

  appendParameterToSearchRequest() {
    const params = this.route.snapshot.queryParams;
    if (params['cityId']) {
      this.searchRequest.cityId = Number(params['cityId']);
    }
    if (params['zoneId']) {
      this.searchRequest.zoneId = Number(params['zoneId']);
    }
    if (params['districtId']) {
      this.searchRequest.districtId = Number(params['districtId']);
    }
    if (params['postcode']) {
      this.searchRequest.postcode = params['postcode'];
    }
    if (params['fromCallbackDate']) {
      this.searchRequest.fromCallbackDate = Number(params['fromCallbackDate']);
      this.searchRequest.hasCallbackDate = true;
    }
    if (params['toCallbackDate']) {
      this.searchRequest.toCallbackDate = Number(params['toCallbackDate']);
      this.searchRequest.hasCallbackDate = true;
    }
  }

  //  push param to current url
  navigateWithParam() {
    this.router.navigate([], {
      queryParams: this.param,
    });
  }

  preLoadAddressForFilter() {
    if (this.route.snapshot.queryParamMap.keys.length === 0) {
      return;
    }
    const postcode = Number(this.route.snapshot.queryParamMap.get('postcode'));
    if (postcode) {
      this.localService.districtByPostcode(postcode).subscribe(result => {
        this.filteredDistricts = result;
      });
    }
  }

  preLoadSelectedEnqTime() {
    if (this.route.snapshot.queryParamMap.keys.length === 0) {
      return;
    }
    const selectedYear = this.route.snapshot.queryParamMap.get('selectedYear');
    const selectedQuarter = this.route.snapshot.queryParamMap.get('selectedQuarter');
    const selectedMonth = this.route.snapshot.queryParamMap.get('selectedMonth');
    const selectedWeek = this.route.snapshot.queryParamMap.get('selectedWeek');
    if (selectedYear) {
      this.selectedYear = Number(selectedYear);
    }
    if (selectedQuarter) {
      this.selectedQuarter = Number(selectedQuarter);
    }
    if (selectedMonth) {
      this.selectedMonth = Number(selectedMonth);
    }
    if (selectedWeek) {
      this.selectedWeek = Number(selectedWeek);
    }
  }

  closeDialog() {
    this.ref.close();
  }

  cancel() {
    this.isDisplay = false;
  }

  selectRow(event: any, el: HTMLElement) {
    const wrapBuyer: WrapBuyer = event.data;
    if (wrapBuyer?.type === WrapBuyerType.ITEM) {
      this.isDisplay = true;
    }
  }

  onSearchByPostcode(value: number) {
    this.searchRequest.postcode = Number(value);
    this.searchRequest.districtId = undefined;
    this.searchRequest.streetId = undefined;
    if (value && value !== 0) {
      this.localService.districtByPostcode(value).subscribe(result => {
        this.filteredDistricts = result;
      });
    }
    if (this.searchRequest.postcode) {
      this.param = {...this.param, postcode: this.searchRequest.postcode};
    } else {
      delete this.param['postcode'];
    }
    this.navigateWithParam();
    if (this.searchRequest.postcode === null) {
      delete this.param['postcode'];
    }
    this.pageChange(1);
  }
}
