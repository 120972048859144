import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
// Import Containers
import {NbAuthComponent} from '@nebular/auth';
import {LoginComponent} from './modules/pages/login/login.component';
import {ForgotComponent} from './modules/pages/forgot/forgot.component';
import {ErrorComponent} from './modules/pages/error/error.component';
import { TaskMiniDialogComponent } from './modules/pages/task/task-mini-dialog/task-mini-dialog.component';

const routes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./modules/pages/pages.module')
    .then(m => m.PagesModule),
  },
  {
    path: 'client',
    loadChildren: () => import('./modules/client/client.module')
      .then(m => m.ClientModule),
  },
  {
    path: 'auth',
    component: NbAuthComponent,
    children: [
      {
        path: '',
        component: LoginComponent,
      },
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'forgot',
        component: ForgotComponent,
      },
    ],
  },
  {path: 'error/:ma-loi', component: ErrorComponent},
  // {path: 'pages/task/create-task', component: TaskMiniDialogComponent, pathMatch: 'full'},
  {path: '**', redirectTo: '/auth/login', pathMatch: 'full'},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
