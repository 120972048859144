import {Component, OnInit} from '@angular/core';
import {ComponentPermission} from '../../../../component.permission';

@Component({
  selector: 'ngx-common-overview',
  templateUrl: './common-overview.component.html',
  styleUrls: ['./common-overview.component.scss'],
})
export class CommonOverviewComponent implements OnInit {

  ComponentPermission = ComponentPermission;

  constructor() {
  }

  ngOnInit(): void {
  }
}
