import {environment} from '../../../environments/environment';

export class Path {

  static API_ENDPOINT = environment.API_ENDPOINT;
  static PARTNER_NAME = environment.PARTNER_NAME;

  // module
  static PASSWORD = '/password';
  static FILE = '/file';
  static CODE = '/code';
  static COUPON = '/couponCode';
  static USER = '/user';
  static BLOG = '/blog';
  static SOS_QUESTION = '/sos/question';
  static SOS_ANSWER = '/sos/answer';
  static TASK = '/task';
  static PROSPECT = '/prospect';
  static BUYER = '/buyer';
  static CUSTOMER = '/customer';
  static ADDRESS = '/address';
  static BUYER_TASK = '/buyerTask';
  static BUYER_DEPOSIT = '/buyerDeposit';
  static INTEREST_DISTRICT = '/interestDistrict';
  static INTEREST_ZONE = '/interestZone';
  static PHONE = '/phone';
  static LOG = '/log';
  static LINK = '/link';
  static ASSIGN = '/assign';
  static UNASSIGN = '/unassign';
  static ASSIGNED = '/assigned';
  static PARENT = '/parent';
  static IMPORT = '/import';
  static TOTAL = '/total';
  static MARKET = '/market';
  static VENDOR = '/vendor';
  static VPA = '/vpa';
  static CONTRACT_CHECKLIST = '/contractChecklist';
  static MARKETING = '/marketing';
  static AGENT = '/agent';
  static AGENT_GROUP = '/agent/group';
  static ATTACHMENT = '/attachment';
  static COUNT = '/count';
  static MONEY = '/money';
  static VISUAL_BOARD = '/visualBoard';
  static POSTCODE = '/postcode';

  static STREET = Path.PROSPECT + '/street';
  static FARM = Path.PROSPECT + '/farm';
  static SUBURB = Path.PROSPECT + '/suburb';
  static ZONE = Path.PROSPECT + '/zone';
  static CITY = Path.PROSPECT + '/city';

  static COMPANY = Path.PROSPECT + '/company';
  static CLIENT_BOARD = '/clientBoard';
  static COMPANY_BOARD = '/companyBoard';
  static CUSTOMER_BOARD = '/customerBoard';
  static PROSPECT_GROUP_SEARCH = Path.PROSPECT + '/group/search';
  static PROSPECT_GROUP_SEARCH_PHONE = Path.PROSPECT + '/group/search/phone';
  static PROSPECT_GROUP_SEARCH_EMAIL = Path.PROSPECT + '/group/search/email';
  static PROSPECT_GROUP_ITEM = Path.PROSPECT + '/group/:streetId/item';
  static PROSPECT_SEARCH_ITEM = Path.PROSPECT + '/group/item';
  static PROSPECT_CONTACT_LOG = Path.PROSPECT + '/:prospectId/contact/log';
  static PROSPECT_SEARCH = Path.PROSPECT + '/item/search';
  static PROSPECT_SHORTCUT_UPDATE = Path.PROSPECT + '/shortcut';
  static PROSPECT_MARK_AS_CALLED = Path.PROSPECT + '/called';
  static PROSPECT_GROUP_ITEM_DETAILS = Path.PROSPECT + '/group/item/:prospectId';
  static SERVICE_COUNT = '/serviceCount';
  static EMAIL = '/email';

  static BUYER_GROUP_SEARCH = Path.BUYER + '/group/search';
  static BUYER_GROUP_ITEM = Path.BUYER + '/group/:streetId/item';
  static BUYER_SEARCH_ITEM = Path.BUYER + '/group/item';
  static BUYER_SEARCH_EMAIL = Path.BUYER + '/group/search/email';
  static BUYER_SEARCH_PHONE = Path.BUYER + '/group/search/phone';
  static BUYER_CONTACT_LOG = Path.BUYER + '/:buyerId/contact/log';
  static TASK_TYPE = Path.TASK + '/type';
  static TASK_TYPE_CATEGORY = Path.TASK + '/type/category';
  static TASK_STATUS = Path.TASK + '/status';
  static MERGE_BUYERS = Path.BUYER + '/merge';
  static CATEGORY = '/category';
  static NOTE = '/note';
  static TEMPLATE = '/template';
  static OFFICE = '/office';
  static OFFICE_PROPERTY = '/officeProperty';
  static SIMPLE = '/simple';
  static BULK_SMS = '/bulkSms';
  static SMS = '/sms';
  static DAILY = '/daily';
  static DAILY_GOAL = '/dailyGoal';
  static RENTAL = '/rental';
  static OPEN_HOME = '/openHome';
  static TIME = '/time';
  static NAME = '/name';

  // id
  static USER_ID = '/:userId';
  static CUSTOMER_ID = '/:customerId';
  static TASK_ID = '/:taskId';
  static SOS_QUESTION_ID = '/:sosQuestionId';
  static BLOG_ID = '/:blogId';
  static CATEGORY_ID = '/:categoryId';
  static SOS_ANSWER_ID = '/:sosAnswerId';
  static PROSPECT_ID = '/:prospectId';
  static BUYER_ID = '/:buyerId';
  static STREET_ID = '/:streetId';
  static FARM_ID = '/:farmId';
  static SUBURB_ID = '/:suburbId';
  static ZONE_ID = '/:zoneId';
  static CITY_ID = '/:cityId';
  static COMPANY_ID = '/:companyId';
  static TASK_TYPE_ID = '/:taskTypeId';
  static TASK_STATUS_ID = '/:taskStatusId';
  static BUYER_TASK_ID = '/:buyerTaskId';
  static BUYER_DEPOSIT_ID = '/:buyerDepositId';
  static INTEREST_ZONE_ID = '/:interestZoneId';
  static INTEREST_DISTRICT_ID = '/:interestDistrictId';
  static NATIONALITY_NOTE_ID = '/:nationalityNoteId';
  static TEMPLATE_ID = '/:templateId';
  static ADDRESS_ID = '/:addressId';
  static OFFICE_ID = '/:officeId';
  static OFFICE_PROPERTY_ID = '/:officePropertyId';
  static VENDOR_ID = '/:vendorId';
  static VPA_ID = '/:vpaId';
  static CONTRACT_CHECKLIST_ID = '/:contractChecklistId';
  static MARKETING_ID = '/:marketingId';
  static MARKETING_NOTE_ID = '/:marketingNoteId';
  static AGENT_ID = '/:agentId';
  static AGENT_GROUP_ID = '/:agentGroupId';
  static ATTACHMENT_ID = '/:attachmentId';
  static LOG_ID = '/:logId';
  static EMAIL_EVENT_ID = '/:emailEventId';
  static EMAIL_GROUP_ID = '/:emailGroupId';

  // feature
  static SEARCH = '/search';
  static UPLOAD = '/upload';
  static REGISTER = '/register';
  static MULTIPLE = '/multiple';
  static DELETE = '/delete';

  // permission
  static ACL = '/acl';
  static PERMISSION = '/permission';
  static ACTION = '/action';
  static GROUP = '/group';
  static COMPONENT = '/component';
  static LIST = '/list';
  static PROSPECT_LIST = '/prospectList';
  static STATISTIC = '/statistic';

  // dashboard
  static DASHBOARD = '/dashboard';
  static PROSPECT_STATISTIC = Path.DASHBOARD + '/prospect';
  static RENTAL_STATISTIC = Path.DASHBOARD + '/rental';
  static PROSPECT_STATISTIC_TEAM = Path.PROSPECT_STATISTIC + '/team';
  static RENTAL_STATISTIC_TEAM = Path.RENTAL_STATISTIC + '/team';
  static BIRTHDAY = Path.DASHBOARD + '/birthday';

  // MARKETING SCHEDULE
  static EMAIL_EVENT = '/emailEvent';
  static EMAIL_GROUP = '/emailGroup';
}
