import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  ViewChild,
  ViewRef
} from '@angular/core';
import {NbDialogRef, NbDialogService} from '@nebular/theme';
import {ToastService} from '../../../../shared/services/toast.service';
import {ProspectService} from '../../../../shared/services/prospect.service';
import {ProspectListAddressCellComponent} from '../common/prospect-list-address-cell-component';
import {ProspectActionCellComponent} from '../common/prospect-action-cell-component';
import {CommonService} from '../../../../shared/services/common.service';
import {ProspectUtils, WrapProspect, WrapProspectType} from '../common/prospect-utils';
import {ProspectListCommonComponent} from '../common/prospect-list-common.component';
import {ProspectSelectComponent} from '../common/prospect-select-component';
import {Prospect} from '../../../../shared/models/response/prospect/prospect';
import {RestResult} from '../../../../shared/models/response/rest-result';
import {ProspectSearchRequest} from '../../../../shared/models/request/prospect/prospect-search-request';
import {LocalService} from '../../../../shared/services/local.service';
import {AuthService} from '../../../../shared/services/auth.service';
import {UserUtils} from '../../../../shared/common/user-utils';
import {ProspectStatisticRequest} from '../../../../shared/models/request/prospect/prospect-statistic-request';
import {TemplateService} from '../../../../shared/services/template.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BulkLog} from '../../../../shared/models/response/bulk-log';
import {BulkLogService} from '../../../../shared/services/bulk.log.service';
import {ProspectDialogComponent} from '../../../../shared/module/prospect-dialog/prospect-dialog.component';

@Component({
  selector: 'ngx-prospect-list-basic',
  templateUrl: './prospect-list-basic.component.html',
  styleUrls: ['./prospect-list-basic.component.scss'],
})
export class ProspectListBasicComponent extends ProspectListCommonComponent implements OnInit {
  @ViewChild('pd', {read: ProspectDialogComponent}) pd: ProspectDialogComponent;
  @Input() isFilter: boolean;
  @Input() isLoad: boolean;
  @Output() emitDisplay = new EventEmitter();
  isDisplay: boolean;
  userUtils = UserUtils;
  childViewRef: ViewRef;
  contactOptionReplacement = (replace: string) => {
    return {
      true: replace,
      false: '',
    };
  }

  columnsDefinition = {
    checkbox: {
      enable: true,
      value: this.createSelectColumn(''),
    },
    propertyType: {
      enable: true,
      value: ProspectUtils.createColumn('', ['property.type'],
        null, null, this.propertyTypeReplacement),
      name: 'Type',
    },
    address: {
      enable: true,
      value: ProspectUtils.createColumn('',
        null, null, ', ', null, ProspectListAddressCellComponent),
      name: 'Address',
    },
    mail: {
      enable: true,
      value: ProspectUtils.createColumn('', ['mail'], null, null, this.contactOptionReplacement('M')),
      name: 'M',
    },
    door: {
      enable: true,
      value: ProspectUtils.createColumn('', ['door'], null, null, this.contactOptionReplacement('D')),
      name: 'D',
    },
    call: {
      enable: true,
      value: ProspectUtils.createColumn('', ['call'], null, null, this.contactOptionReplacement('C')),
      name: 'C',
    },
    sms: {
      enable: true,
      value: ProspectUtils.createColumn('', ['sms'], null, null, this.contactOptionReplacement('S')),
      name: 'S',
    },
    action: {
      enable: true,
      value: this.createActionColumn('Action'),
      name: 'Action',
    },
  };

  settings = {
    mode: 'external',
    hideSubHeader: true,
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    pager: {
      display: true,
      perPage: 10000,
    },
    columns: this.createColumns(),
    rowClassFunction: (row) => {
      return ProspectUtils.highlightProspectRow(row?.data);
    },
  };

  createActionColumn(title: string) {
    return {
      title: title,
      type: 'custom',
      filter: false,
      renderComponent: ProspectActionCellComponent,
      onComponentInitFunction: (instance: ProspectActionCellComponent) => {
        instance.onAddProspect.subscribe(value => {
          this.addProspectsByUnitRange(value);
        });
      },
    };
  }

  createColumns() {
    const columns = {};
    for (const key of Object.keys(this.columnsDefinition)) {
      if (this.columnsDefinition[key].enable === true) {
        columns[key] = this.columnsDefinition[key].value;
      }
    }
    return columns;
  }

  createSelectColumn(title: string) {
    return {
      title: title,
      type: 'custom',
      filter: false,
      renderComponent: ProspectSelectComponent,
      onComponentInitFunction: (instance: ProspectSelectComponent) => {
        instance.onSelectProspect.subscribe((value: WrapProspect) => {
          this.checkProspectToRemove(value);
        });
      },
    };
  }

  viewMap = false;
  prospects: Prospect[];
  selectedMarket: string;
  markets = ['S', 'R', 'O', 'V'];
  marketRequest = {
    S: 'sell',
    R: 'rent',
    O: 'otm',
    V: 'vac',
  };
  adminNumber: string;
  isDialog = false;
  statisticRequest: ProspectStatisticRequest;
  categoryIds: number[];
  bulkLog: BulkLog;
  @Input() potentialCommission: number;
  @Input() potentialRentalValue: number;

  @Input() rentalTooltip: string;

  constructor(@Optional() private ref: NbDialogRef<ProspectListBasicComponent>,
              private _dialogService: NbDialogService,
              private _toastService: ToastService,
              private _prospectService: ProspectService,
              private _commonService: CommonService,
              private _localService: LocalService,
              private _authService: AuthService,
              private _templateService: TemplateService,
              private _router: Router,
              private _route: ActivatedRoute,
              private bulkLogService: BulkLogService,
              private rf: ChangeDetectorRef) {
    super(_dialogService, _toastService, _prospectService, _commonService, _localService, _authService, _router, _route, _templateService);
  }

  ngOnInit() {
    if (this.isFilter === false) {
      this.noFilter = true;
    }
    this.param = this.route.snapshot.queryParams;
    this.currentUser = this.authService.currentUser;
    this.source.onChanged().subscribe((change) => {
      this.change = change;
      if (change.action === 'refresh' && this.isLoad) {
        this.pageChange(1);
      }
      if (change.action === 'page') {
        if (this.noFilter === false) {
          delete this.param['selectedProspectId'];
          delete this.param['selectedStreetId'];
          this.param = {...this.param, page: change.paging.page};
          this.navigateWithParam();
        }
        this.pageChange(change.paging.page, true);
      }
    });
    this.param = {...this.param, tab: this._selectedMode};
    if (this.currentUser.office.phone) {
      this.adminNumber = this.currentUser.office.phone;
    }
    if (this.oneOnOneCall) {
      if (!this.userUtils.userIsAdmin(this.currentUser)) {
        this.searchRequest.multipleUser = [];
        this.searchRequest.multipleUser.push(this.currentUser.userId);
      }
      if (this.userUtils.userIsAdmin(this.currentUser)) {
        if (this.param['multipleUser']) {
          this.searchRequest.multipleUser = [];
          const multipleUser = this.param['multipleUser'];
          for (let i = 0; i < multipleUser.length; i++) {
            this.searchRequest.multipleUser.push(multipleUser[i]);
          }
        }
      }
      this.searchRequest.categoryIds = [];
      this.searchRequest.categoryIds.push(1);
      this.pageChange(1);
    }
    if (this.isDialog) {
      if (this.statisticRequest) {
        this.searchRequest.statisticRequest = this.statisticRequest;
        this.searchRequest.mode = this._selectedMode;
        this.searchRequest.categoryIds = this.categoryIds;
      }
      if (this.bulkLog) {
        const ids = [];
        for (const id of this.bulkLog.ids.split(';')) {
          if (id !== '') {
            ids.push(Number(id));
          }
        }
        this.searchRequest.prospectIds = ids;
      }
      this.pageChange(1);
    }
    this.preLoadAddressForFilter();
    this.appendParameterToSelectedMarket();
  }


  onCaModeChange(enable?: boolean) {
    this.searchRequest.isCaMode = enable;
    this.pageChange(1);
  }

  onDkModeChange(enable?: boolean) {
    this.searchRequest.isDkMode = enable;
    this.pageChange(1);
  }

  onMarketChange() {
    if (this.selectedMarket && this.selectedMarket !== '') {
      for (const key of Object.keys(this.marketRequest)) {
        this.searchRequest[this.marketRequest[key]] = null;
      }
      this.searchRequest[this.marketRequest[this.selectedMarket]] = true;
    }
    if (this.viewMap === true) {
      this.switchToMapView();
      setTimeout(() => {
        this.switchToMapView();
      }, 500);
    }
  }

  switchToMapView() {
    if (this.selectedMarket === undefined) {
      alert('Please choose at least one market class to display on Map');
      return;
    }
    if (!this.viewMap) {
      this.prospectService.searchItems(this.searchRequest).subscribe((result: RestResult) => {
        this.prospects = result.data;
        this.selectedProspects = result.data;
        this.viewMap = true;
      });
    } else {
      this.viewMap = false;
    }
  }

  /**
   * used for filter client boards
   */
  filterCondition(searchRequest: ProspectSearchRequest) {
    this.searchRequest = searchRequest;
    this.pageChange(1);
  }

  selectRow(event: any, el: HTMLElement) {
    const wrapProspect: WrapProspect = event.data;
    if (wrapProspect?.type === WrapProspectType.ITEM) {
      this.isDisplay = true;
      this.emitDisplay.emit(this.isDisplay);
    }
  }

  closeDialog() {
    this.ref.close();
  }

  cancel() {
    this.isDisplay = false;
    this.emitDisplay.emit(this.isDisplay);
  }

  onRemoveCategory(categoryId: number) {
    if (!this.searchRequest.categoryIds) {
      this.searchRequest.categoryIds = [];
    }
    const index = this.searchRequest.categoryIds.indexOf(categoryId);
    if (index !== -1) {
      this.searchRequest.categoryIds.splice(index, 1);
      this.isFiltered = true;
      this.pageChange(1);
    }
  }

  onSelectCategory(categoryId: number) {
    if (!this.searchRequest.categoryIds) {
      this.searchRequest.categoryIds = [];
    }
    const index = this.searchRequest.categoryIds.indexOf(categoryId);
    if (index === -1) {
      this.searchRequest.categoryIds.push(categoryId);
    }
    this.isFiltered = true;
    this.pageChange(1);
  }

  onRemoveUser(userId: number) {
    this.searchRequest.userId = null;
    this.isFiltered = true;
    this.pageChange(1);
  }

  onSelectUser(userId: number) {
    this.searchRequest.userId = userId;
    this.isFiltered = true;
    this.pageChange(1);
  }

  onSearch(filtered: boolean) {
    this.isFiltered = filtered;
    if (this.param['selectedStreetId']) {
      delete this.param['selectedStreetId'];
    }
    if (this.param['selectedProspectId']) {
      delete this.param['selectedProspectId'];
    }
    if (this.param['page']) {
      delete this.param['page'];
    }
    this.pageChange(1);
  }

  appendParameterToSelectedMarket() {
    if (this.route.snapshot.queryParamMap.keys.length === 0) {
      return;
    }
    if (this.route.snapshot.queryParamMap.get('sell')) {
      this.selectedMarket = 'S';
    }
    if (this.route.snapshot.queryParamMap.get('otm')) {
      this.selectedMarket = 'O';
    }
    if (this.route.snapshot.queryParamMap.get('rent')) {
      this.selectedMarket = 'R';
    }
    if (this.route.snapshot.queryParamMap.get('vac')) {
      this.selectedMarket = 'V';
    }
  }

  editDone(value: { result: any; next: boolean }) {
    if (value?.result?.data?.prospectId) {
      this.pageChange(this.currentPage);
      super.editDone(value);
    }
    if (!value?.result && !value?.next) {
      delete this.param['selectedProspectId'];
      delete this.param['selectedStreetId'];
      this.selectedProspect = null;
      this.navigateWithParam();
      this.pageChange(1);
      this.pd.selectedProspect = null;
      this.pd.data = null;
      this.pd.ngOnInit();
    }
  }

  onPhoneNumberChange(phone: string) {
    if (phone && phone !== '') {
      this.searchRequest.phone = phone;
    } else {
      this.searchRequest.phone = null;
    }
  }
}
