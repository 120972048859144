import { ProspectListCustomCellComponent } from './prospect-list-custom-cell-component';
import { Prospect } from '../../../../shared/models/response/prospect/prospect';
import { ProspectGroup } from '../../../../shared/models/response/prospect/prospect-group';
import { VisualBoardAgentCellComponent } from './visual-board-agent-cell-component';
import { VisualBoardOpenHomeComponent } from './visual-board-open-home-component';
import { VisualBoardPriceCellComponent } from './visual-board-price-cell-component';

export enum WrapProspectType {
  GROUP = 'GROUP',
  ITEM = 'ITEM',
}

export class WrapProspect {
  type: WrapProspectType;
  isExpanded?: boolean;
  isSelected = false; // for user click to select
  selected = false; // for using checkbox
  data: Prospect;
  groupData: ProspectGroup;
}

export class ProspectUtils {
  static sortTypes = {
    ASC: 'ASC',
    DESC: 'DESC',
    ODD_ASC: 'ODD_ASC',
    ODD_DESC: 'ODD_DESC',
    EVEN_ASC: 'EVEN_ASC',
    EVEN_DESC: 'EVEN_DESC',
  };

  // support for table
  static createColumn(title: string, propertyPaths: string[],
                      valueType?: string, separator?: string,
                      valueReplacement?: any, renderComponent?: any) {
    if (propertyPaths === ['open']) {
      return VisualBoardOpenHomeComponent;
    }
    if (propertyPaths === ['visualBoardCustomPT']) {
      return {
        title: '<i class="fa fa-house">',
        type: 'custom',
        filter: false,
        renderComponent: ProspectListCustomCellComponent,
        onComponentInitFunction: (instance: any) => {
          instance.config = {
            valueType: valueType,
            valueReplacement,
            separator,
          };
        },
      };
    }
    return {
      title: title,
      type: 'custom',
      filter: false,
      renderComponent: renderComponent ? renderComponent : ProspectListCustomCellComponent,
      onComponentInitFunction: (instance: any) => {
        if (instance instanceof ProspectListCustomCellComponent) {
          instance.config = {
            propertyPaths: propertyPaths,
            valueType: valueType,
            valueReplacement,
            separator,
          };
        } else if (instance instanceof VisualBoardAgentCellComponent) {
          instance.groupTitle = title;
        } else if (instance instanceof VisualBoardPriceCellComponent) {
          instance.isCommissionPrice = title === 'Commission Price';
        }
      },
    };
  }

  static createCustomColumn(title: string, propertyPaths: string[],
                            valueType?: string, separator?: string,
                            valueReplacement?: any, renderComponent?: any) {
    let className;
    switch (title) {
      case 'PT':
        className = 'customPT';
        break;
      case 'Bed':
        className = 'customBed';
        break;
      case 'Bath':
        className = 'customBath';
        break;
      case 'Car':
        className = 'customCar';
        break;
    }
    return {
      title: title,
      width: '10px',
      class: className,
      type: 'custom',
      filter: false,
      renderComponent: renderComponent ? renderComponent : ProspectListCustomCellComponent,
      onComponentInitFunction: (instance: any) => {
        if (instance instanceof ProspectListCustomCellComponent) {
          instance.config = {
            propertyPaths: propertyPaths,
            valueType: valueType,
            valueReplacement,
            separator,
          };
        }
      },
    };
  }

  static convertGroupItemToWrapperData(items: Prospect[]): WrapProspect[] {
    if (!items) {
      return [];
    }
    const wrapProspects = [];
    for (const item of items) {
      wrapProspects.push({
        type: WrapProspectType.ITEM,
        data: item,
      });
    }
    return wrapProspects;
  }


  static convertGroupToWrapperData(groups: ProspectGroup[]): WrapProspect[] {
    if (!groups) {
      return [];
    }
    const wrapProspects = [];
    for (const group of groups) {
      wrapProspects.push({
        type: WrapProspectType.GROUP,
        groupData: group,
        isExpanded: false,
      });
    }
    return wrapProspects;
  }

  static convertItemToWrapperData(item: Prospect): any {
    return {
      type: WrapProspectType.ITEM,
      data: item,
      isSelected: true,
    };
  }

  static isNeverCallBefore(prospect: Prospect): boolean {
    return !prospect || (!prospect.lastCalledDate && !prospect.lastDoorNockedDate);
  }

  static isCalled(prospect: Prospect): boolean {
    if (!prospect) {
      return false;
    }
    if (!this.isCalledExpired(prospect) && this.hasNoteAfterCallOrDoornock(prospect)) {
      return true;
    }
    return false;
  }

  static isCalledExpired(prospect: Prospect): boolean {
    const maxTime = Math.max(prospect.lastCalledDate, prospect.lastDoorNockedDate);
    if (!maxTime) {
      return true;
    }
    const currentTime = new Date().getTime();
    const days = Math.floor((currentTime - maxTime) / (1000 * 3600 * 24));
    return days > 4 * 7;
  }

  static hasNoteAfterCallOrDoornock(prospect: Prospect): boolean {
    // const logs = prospect.logs;
    // if (!logs || logs.length <= 0) {
    //   return false;
    // }
    // let maxTime = -1;
    // let logHasMaxTime = null;
    // for (const log of logs) {
    //   if ((log.actionType === 'CALL' || log.actionType === 'DOOR_NOCK')
    //     && log.createdDate > maxTime) {
    //     maxTime = log.createdDate;
    //     logHasMaxTime = log;
    //   }
    // }
    // // count if having note when called or door nocked
    // const logHasMaxTimeDesc = JSON.parse(logHasMaxTime.description);
    // if (logHasMaxTimeDesc['note']
    //   && logHasMaxTimeDesc['note'] !== '') {
    //   return true;
    // }
    // // count if having log after called or door nocked or before it but not over 1 day
    // for (const log of logs) {
    //   if (log.actionType === 'UPDATE'
    //     && log.description
    //     && log.description.indexOf('ProspectResponse:note') > -1
    //     && (log.createdDate > maxTime || Math.abs(log.createdDate - maxTime) <= 86400000)
    //   ) {
    //     return true;
    //   }
    // }
    // return false;
    return prospect.hasNoteAfterCallOrDoornock;
  }

  // highlight by condition
  static highlightProspectRow(data: WrapProspect) {
    let result = '';
    if (data.type === WrapProspectType.GROUP) {
      result += ' row-type-group';
    }
    if (data.isSelected === true) {
      result += ' is-selected';
    }
    if (data?.data?.property?.type === 'HOUSE_COM') {
      result += ' no-value';
    } else if (data?.data?.property?.otm === true) {
      result += ' otm';
    } else if (data?.data?.property?.vac === true || data?.data?.property?.rent === true) {
      result += ' vac-rent';
    }
    if (ProspectUtils.isNeverCallBefore(data?.data)) {
      result += ' never-called';
    } else if (ProspectUtils.isCalled(data?.data)) {
      result += ' called';
    } else {
      result += ' called-expired';
    }
    return result;
  }
}
