<div class="buyer-container" *appPermissionCheck="ComponentPermission.CUSTOMER_LIST">
  <nb-tab tabTitle="ALL" [active]="true">
    <div class="row" *ngIf='passDailyGoal === true'>
      <div class="col-md-6 col-sm-12 left-panel mb-0 table-container"
           [ngClass]="{'popup-hide': isDisplay}"
      >
        <nb-card>
          <nb-card-body>
            <ngx-buyer-list-advance
              [oneOnOneCall]='oneOnOneCall'
              [cities]="cities"
              (onSelectedBuyerChange)="handleSelectedBuyerChange($event)"
              (onSelectedBuyersChange)="handleSelectedBuyersChange($event)"
              [isDialog] = isDialog
              [searchRequestFromDashboard]='searchRequest'
              [isLoad]='isLoad'
              (onDisplayDetail)="handleDisplayDetail()"
            ></ngx-buyer-list-advance>
          </nb-card-body>
        </nb-card>
      </div>
      <div class="col-md-6 col-sm-12 right-panel"
           [ngClass]="{
       'popup-detail': isDisplay,
       'hide-detail': !isDisplay
       }"
      >
        <ngx-buyer-create
          [cities]="cities"
          [taskTypes]="taskTypes"
          [taskStatuses]="taskStatuses"
          (navigateBuyerEvent)="navigateProspect($event)"
          (editDone)="editDone($event)"
          (newDone)="newDone($event)"
          (reserveBuyer)="reserveBuyer($event)"
          (ownSet)="ownSet($event)"
        ></ngx-buyer-create>
      </div>
    </div>
    <div class='row' *ngIf='passDailyGoal === false'>
      <div class='col-12 permission-required'>
        <div class='text-center'>You have to make {{remainingCall}} more call/SMS/DK to unlock this feature</div>
      </div>
    </div>
    <div class='row' *ngIf='haveDailyGoalOrPermission === false'>
      <div class='col-12 permission-required'>
        <div class='text-center'>Neither your daily goal has been set nor you have permission to access this menu</div>
      </div>
    </div>
    <div class="scroll-mobile" *ngIf="isDisplay">
      <button (click)="cancel()" size="tiny" nbButton status="warning">Cancel</button>
    </div>
<!--    <button class="float-button float-new" nbButton status="info"-->
<!--            (click)="new()">New-->
<!--    </button>-->
  </nb-tab>
</div>

