import {Prospect} from '../../../../shared/models/response/prospect/prospect';
import {ProspectUtils} from './prospect-utils';

export class WrapItem {
  index: number;
  parentIndex: number;
  isGrouped = false;
  isExpanded = false;
  isSelected = false; // for user click to select
  selected = false; // for using checkbox
  data: Prospect;
  totalChilds: number;
  childs: WrapItem[];
  childIds: number[];
}

export class WrapperUtils {
  static convertToNewList(items: Prospect[], parentIndex?: number): WrapItem[] {
    if (!items) {
      return [];
    }
    const wrapProspects = [];

    let index = 0;
    for (const item of items) {
      const wrapItem = new WrapItem();
      wrapItem.data = item;
      wrapItem.index = index;
      index++;
      if (parentIndex) {
        wrapItem.parentIndex = parentIndex;
      }
      if (item.totalUnit > 1) {
        wrapItem.isGrouped = true;
        wrapItem.childIds = item.unitProspectIds;
        wrapItem.totalChilds = item.totalUnit;
      }
      wrapProspects.push(wrapItem);
    }
    return wrapProspects;
  }

  // highlight by condition
  static highlightProspectRow(data: WrapItem) {
    let result = '';
    if (data.isGrouped) {
      result += ' row-type-group';
    }
    if (data.isSelected === true) {
      result += ' is-selected';
    }
    if (data?.data?.property?.type === 'HOUSE_COM') {
      result += ' no-value';
    } else if (data?.data?.property?.otm === true) {
      result += ' otm';
    } else if (data?.data?.property?.vac === true || data?.data?.property?.rent === true) {
      result += ' vac-rent';
    }
    if (ProspectUtils.isNeverCallBefore(data?.data)) {
      result += ' never-called';
    } else if (ProspectUtils.isCalled(data?.data)) {
      result += ' called';
    } else {
      result += ' called-expired';
    }
    return result;
  }
}
