import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

import {IconSetService} from '@coreui/icons-angular';
import {freeSet} from '@coreui/icons';
import {LoadingService} from './shared/services/loading.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'ngx-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [IconSetService],
})
export class AppComponent implements OnInit {
  loading = true;

  constructor(private router: Router,
              public iconSet: IconSetService,
              translate: TranslateService,
              private loadingService: LoadingService,) {
    // iconSet singleton
    iconSet.icons = {...freeSet};
    translate.setDefaultLang('en');
    translate.use('en');
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.loadingService.loadingChange.subscribe((result: boolean) => {
      setTimeout(() => {
        this.loading = result;
      });
    });
  }
}
