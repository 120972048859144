import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-phone-dialog',
  templateUrl: './phone-dialog.component.html',
  styleUrls: ['./phone-dialog.component.scss'],
})
export class PhoneDialogComponent implements OnInit {
  static confirmOk: string = 'CONFIRM_OK';

  @Input()
  title: string = 'Confirmation';

  @Input()
  content: string;

  constructor(
    protected ref: NbDialogRef<PhoneDialogComponent>,
  ) {
  }

  ngOnInit(): void {
  }

  submit() {
    this.ref.close(PhoneDialogComponent.confirmOk);
  }

  cancel() {
    this.ref.close();
  }
}
