<nb-card>
  <nb-card-header>
    Search Customer
  </nb-card-header>

  <nb-card-body>
    <ng2-smart-table [settings]="settings"
                     [source]="source"
                     (userRowSelect)="selectRecord($event, true, false)"
    >
    </ng2-smart-table>
  </nb-card-body>
</nb-card>
