<ng-template
  #defaultTemplate
  let-days="days"
  let-locale="locale">
  <div class="cal-cell-row cal-header">
    <div class="week-no">
      Week no
    </div>
    <div
      class="cal-cell"
      *ngFor="let day of days; trackBy:trackByWeekDayHeaderDate"
      [class.cal-past]="day.isPast"
      [class.cal-today]="day.isToday"
      [class.cal-future]="day.isFuture"
      [class.cal-weekend]="day.isWeekend"
      [ngClass]="day.cssClass">
      {{ day.date | calendarDate:'monthViewColumnHeader':locale }}
    </div>
  </div>
</ng-template>
<ng-template
  [ngTemplateOutlet]="customTemplate || defaultTemplate"
  [ngTemplateOutletContext]="{days: days, locale: locale}">
</ng-template>
