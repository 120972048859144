<nb-card class="container">
  <nb-card-body>
    <div class="row mt-2">
      <div class="col-3">
        <input type="text" nbInput fieldSize="tiny" fullWidth name="name" placeholder="Name" [(ngModel)]="filter.name"/>
      </div>
      <div class="col-3">
        <input type="text" nbInput fieldSize="tiny" fullWidth name="code" placeholder="Code" [(ngModel)]="filter.code"/>
      </div>
      <div class="col-3">
        <input type="text" nbInput fieldSize="tiny" fullWidth name="city" placeholder="City" [(ngModel)]="filter.city"/>
      </div>
      <div class="col-3">
        <input type="text" nbInput fieldSize="tiny" fullWidth name="zone" placeholder="Zone" [(ngModel)]="filter.zone"/>
      </div>
    </div>
    <div class="row mt-2 mb-2">
      <div class="col-12">
        <button nbButton size="tiny" hero status="info" class="fa-pull-right" (click)="loadRequest(0)">Search</button>
      </div>
    </div>
    <ng2-smart-table [settings]="settings"
                     [source]="source"
                     (userRowSelect)="select($event)"
    >
    </ng2-smart-table>
  </nb-card-body>
</nb-card>
