import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {TaskListComponent} from './task-list/task-list.component';
import {TaskListDialogComponent} from './task-list/dialog/task-list.dialog.component';
import {TaskComponent} from './task.component';
import {TaskCalendarComponent} from './task-calendar/task-calendar.component';
import {TaskChildsComponent} from './task-childs/task-childs.component';
import {TaskCalendarStatusEditorComponent} from './task-calendar/custom/task-calendar-status-editor.component';
import {TaskMiniDialogComponent} from './task-mini-dialog/task-mini-dialog.component';
import {TaskTypeComponent} from './task-type/task-type.component';
import {TaskTypeCreateComponent} from './task-type/create/task-type-create.component';
import {TaskTypeUpdateComponent} from './task-type/update/task-type-update.component';
import {TaskCellComponent} from './common/task-cell-component';
import {TaskStatusComponent} from './task-status/task-status.component';
import {TaskStatusCreateComponent} from './task-status/create/task-status-create.component';
import {TaskStatusUpdateComponent} from './task-status/update/task-status-update.component';
import {CustomMonthViewComponent} from './task-calendar/custom/custom-month-view/custom-month-view.component';
import {
  CustomMonthViewHeaderComponent,
} from './task-calendar/custom/custom-month-view-header/custom-month-view-header.component';
import {CustomWeekViewComponent} from './task-calendar/custom/custom-week-view/custom-week-view.component';

const routes: Routes = [{
  path: '',
  component: TaskComponent,
  children: [
    {
      path: 'list',
      component: TaskListComponent,
    },
    {
      path: 'list/new',
      component: TaskListDialogComponent,
    },
    {
      path: 'calendar',
      component: TaskCalendarComponent,
    },
    {
      path: 'type/list',
      component: TaskTypeComponent,
    },
    {
      path: 'status/list',
      component: TaskStatusComponent,
    },
    {
      path: 'create-task',
      component: TaskMiniDialogComponent,
    }
  ],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TaskRoutingModule {
}

export const routedComponents = [
  TaskListComponent,
  TaskListDialogComponent,
  TaskComponent,
  TaskCalendarComponent,
  TaskChildsComponent,
  TaskCalendarStatusEditorComponent,
  TaskMiniDialogComponent,
  TaskTypeComponent,
  TaskTypeCreateComponent,
  TaskTypeUpdateComponent,
  TaskCellComponent,
  TaskStatusComponent,
  TaskStatusCreateComponent,
  TaskStatusUpdateComponent,
  CustomMonthViewComponent,
  CustomMonthViewHeaderComponent,
  CustomWeekViewComponent,
];
