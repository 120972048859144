import {Component, OnInit, ViewChild} from '@angular/core';
import {RestResult} from '../../../../shared/models/response/rest-result';
import {User} from '../../../../shared/models/response/user';
import {SearchRequest} from '../../../../shared/models/request/search-request';
import {UserService} from '../../../../shared/services/user.service';
import {AuthService} from '../../../../shared/services/auth.service';
import {Company} from '../../../../shared/models/response/prospect/company';
import {TaskType} from '../../../../shared/models/response/task-type';
import {TaskTypeService} from '../../../../shared/services/task-type.service';
import {CompanyService} from '../../../../shared/services/company.service';
import {StringUtils} from '../../../../shared/common/string-utils';
import {ActivatedRoute} from '@angular/router';
import {TaskStatus} from '../../../../shared/models/response/task-status';
import {TaskStatusService} from '../../../../shared/services/task-status.service';
import {City} from '../../../../shared/models/response/address/city';
import {Buyer} from '../../../../shared/models/response/buyer/buyer';
import {BuyerCreateComponent} from '../../../../shared/module/buyer-create/buyer-create.component';
import {ProspectDialogComponent} from '../../../../shared/module/prospect-dialog/prospect-dialog.component';
import {Prospect} from '../../../../shared/models/response/prospect/prospect';
import {LocalService} from '../../../../shared/services/local.service';
import {CustomerService} from '../../../../shared/services/customer.service';
import {Customer} from '../../../../shared/models/response/prospect/customer';
import {BuyerService} from '../../../../shared/services/buyer.service';
import {ProspectService} from '../../../../shared/services/prospect.service';
import {Constant} from '../../../../shared/common/constant';
import {CustomDatasource} from '../../../../shared/models/utils/custom-datasource';
import {DataSourceUtil} from '../../../../shared/models/utils/datasource-util';
import {Pair} from '../../../../shared/models/request/pair';
import {Triple} from '../../../../shared/models/request/triple';
import {MergeCustomersComponent} from '../../../../shared/module/buyer-create/merge-customers/merge-customers.component';
import {NbDialogService} from '@nebular/theme';
import {CommonService} from '../../../../shared/services/common.service';
import {SmartTableCustomSelectComponent} from '../../buyer/common/smart-table-custom-select-component';
import {CustomRow} from '../../../../shared/models/common/custom-row';
import {SmartTableUtils} from '../../buyer/common/smart-table-utils';
import {MultipleSelectedRequest} from '../../../../shared/models/common/multiple-selected-request';
import {MoveCustomersComponent} from '../../../../shared/module/buyer-create/move-customers/move-customers.component';
import {ComponentPermission} from '../../../../component.permission';
import {ConfirmDialogComponent} from '../../confirm-dialog/confirm-dialog.component';
import {CustomerLinkRequest} from '../../../../shared/models/request/prospect/customer-link-request';
import {CopyCustomersComponent} from '../../../../shared/module/buyer-create/copy-customers/copy-customers.component';
import {CustomerRequest} from '../../../../shared/models/request/prospect/customer-request';
import {Street} from '../../../../shared/models/response/address/street';
import {District} from '../../../../shared/models/response/address/district';
import {CustomerSearchRequest} from '../../../../shared/models/request/prospect/customer-search-request';
import {Zone} from '../../../../shared/models/response/address/zone';
import {Farm} from '../../../../shared/models/response/address/farm';


@Component({
  selector: 'ngx-search-customer',
  templateUrl: './search-customer.component.html',
  styleUrls: ['./search-customer.component.scss'],
})
export class SearchCustomerComponent implements OnInit {
  ComponentPermission = ComponentPermission;

  currentUser: User;
  companies: Company[];
  taskTypes: TaskType[];
  taskStatuses: TaskStatus[];
  @ViewChild(ProspectDialogComponent) prospectCreate: ProspectDialogComponent;
  @ViewChild(BuyerCreateComponent) buyerCreate: BuyerCreateComponent;

  stringUtils = StringUtils;
  selectedBuyer: Buyer;
  selectedProspect: Prospect;
  selectedProspects: Prospect[];

  type: string;
  parameter: string;
  isMatched: boolean;
  pageSize = Constant.PAGE_SIZE;
  currentPage = 1;
  searchRequest = new CustomerSearchRequest();
  change: any;
  source: CustomDatasource = new CustomDatasource();
  isDisplay = false;
  multipleSelectedRequest: MultipleSelectedRequest;
  cities: City[];
  streets: Street[] = [];
  districts: District[] = [];
  zones: Zone[] = [];
  farms: Farm[] = [];

  constructor(private userService: UserService,
              private dialogService: NbDialogService,
              private commonService: CommonService,
              private authService: AuthService,
              private companyService: CompanyService,
              private taskStatusService: TaskStatusService,
              private customerService: CustomerService,
              private buyerService: BuyerService,
              private prospectService: ProspectService,
              private localService: LocalService,
              private taskTypeService: TaskTypeService,
              private route: ActivatedRoute) {
    this.currentUser = this.authService.currentUser;
  }

  columnsDefinition = {
    checkbox: {
      enable: true,
      value: this.createSelectColumn(''),
    },
    name: {
      enable: true,
      value: SmartTableUtils.createColumn('Name', ['name']),
    },
    surname: {
      enable: true,
      value: SmartTableUtils.createColumn('SName', ['surname']),
    },
    mobilePhone: {
      enable: true,
      value: SmartTableUtils.createColumn('Mobile', ['mobilePhone']),
    },
    phone: {
      enable: true,
      value: SmartTableUtils.createColumn('Phone', ['phone']),
    },
    email: {
      enable: true,
      value: SmartTableUtils.createColumn('Email', ['email']),
    },
    dob: {
      enable: true,
      value: SmartTableUtils.createColumn('DOB', ['dob']),
    },
  };

  settings = {
    selectedRowIndex: -1,
    mode: 'external',
    hideSubHeader: true,
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    pager: {
      display: true,
      perPage: this.pageSize,
    },
    columns: SmartTableUtils.createColumns(this.columnsDefinition),
    rowClassFunction: (row) => {
      return this.highlightRow(row?.data);

    },
  };

  highlightRow(data: any) {
    let result = '';
    if (data.data.parentType === 'PROSPECT') {
      result += ' prospect-row';
    }
    if (data.data.parentType === 'BUYER') {
      result += ' buyer-row';
    }
    if (!data.data.parentType) {
      result += ' wot';
    }
    return result;
  }

  ngOnInit(): void {
    this.currentUser = this.authService.currentUser;
    this.loadForFilter();
    this.route.queryParams.subscribe(params => {
      this.parameter = params['phone'];
      this.isMatched = params['isMatched'];
    });
    this.source.onChanged().subscribe((change) => {
      this.change = change;
      if (change.action === 'refresh') {
        this.loadRequest(1);
      }
      if (change.action === 'page') {
        this.loadRequest(change.paging.page);
      }
    });

    this.multipleSelectedRequest = new MultipleSelectedRequest();
  }

  loadRequest(pageIndex) {
    this.prepareRequest(pageIndex);
    this.customerService.searchCustom(this.searchRequest).subscribe((result: RestResult) => {
      DataSourceUtil.reloadDataCustomRow(this.source, pageIndex, this.pageSize, result);
    });
  }

  prepareRequest(pageIndex: number) {
    // pagination setting
    this.searchRequest.offset = (pageIndex - 1) * this.pageSize;
    if (this.searchRequest.offset < 0) {
      this.searchRequest.offset = 0;
    }
    this.searchRequest.limit = this.pageSize;

    this.searchRequest.officeId = this.currentUser.office.officeId;
    this.searchRequest.phone = this.parameter;
    if (this.isMatched) {
      this.searchRequest.isMatched = this.isMatched;
    }
  }


  selectRecord(event: any) {
    this.selectedBuyer = null;
    this.selectedProspect = null;
    const customer: Customer = event.data.data;
    this.isDisplay = true;
    this.buyerService.getByCustomerId(customer.customerId).subscribe((buyerResult: RestResult) => {
      if (!buyerResult.data) {
        this.prospectService.getByCustomerId(customer.customerId).subscribe((prospectResult: RestResult) => {
          this.selectedProspect = prospectResult.data;
        });
      } else {
        this.selectedBuyer = buyerResult.data;
        this.buyerCreate.setBuyer(this.selectedBuyer);
      }
    });
  }

  createSelectColumn(title: string) {
    return {
      title: title,
      type: 'custom',
      filter: false,
      renderComponent: SmartTableCustomSelectComponent,
      onComponentInitFunction: (instance: SmartTableCustomSelectComponent) => {
        instance.onSelectRow.subscribe((value: CustomRow) => {
          this.pushToSelectedRequest(value);
        });
      },
    };
  }

  pushToSelectedRequest(value: CustomRow) {
    const customerId = value.data?.customerId;
    if (value.selected === true) {
      this.multipleSelectedRequest.selectedIds.push(customerId);
      if (value.data.parentType === Customer.PROSPECT) {
        this.multipleSelectedRequest.prospect += 1;
      }
    } else {
      const customerIdIndex = this.multipleSelectedRequest.selectedIds.findIndex(x => x === customerId);
      if (customerIdIndex > -1) {
        this.multipleSelectedRequest.selectedIds.splice(customerIdIndex, 1);
        if (value.data.parentType === Customer.PROSPECT) {
          this.multipleSelectedRequest.prospect -= 1;
        }
      }
    }
  }

  openMergePopup() {
    this.dialogService.open(MergeCustomersComponent, {
      context: {
        multipleSelectedRequest: this.multipleSelectedRequest,
      },
    }).onClose.subscribe((result: Buyer) => {
      if (result) {
        this.multipleSelectedRequest = new MultipleSelectedRequest();
        this.loadRequest(1);
      }
    });
  }

  openMovePopup() {
    this.dialogService.open(MoveCustomersComponent, {
      context: {
        multipleSelectedRequest: this.multipleSelectedRequest,
      },
    }).onClose.subscribe(res => {
      if (res === MoveCustomersComponent.SAVE_SUCCESS) {
        this.multipleSelectedRequest = new MultipleSelectedRequest();
        this.loadRequest(1);
      }
    });
  }

  openCopyPopup() {
    this.dialogService.open(CopyCustomersComponent, {
      context: {
        multipleSelectedRequest: this.multipleSelectedRequest,
      },
    }).onClose.subscribe(res => {
      if (res === CopyCustomersComponent.SAVE_SUCCESS) {
        this.multipleSelectedRequest = new MultipleSelectedRequest();
        this.loadRequest(1);
      }
    });
  }

  openLinkPopup() {
    this.dialogService.open(ConfirmDialogComponent, {
      context: {
        content: 'Do you really want to link?',
      },
    }).onClose.subscribe(res => {
      if (res === ConfirmDialogComponent.confirmOk) {
        const customerLinkRequest = new CustomerLinkRequest();
        let ids: string = ';';
        ids += this.multipleSelectedRequest.selectedIds.join(';');
        ids += ';';
        customerLinkRequest.linkedIds = ids;
        this.customerService.linkCustomer(customerLinkRequest).subscribe(
          () => {
            this.commonService.info('Linked successfully');
            this.multipleSelectedRequest = new MultipleSelectedRequest();
            this.loadRequest(1);
          }
        );
      }
    });
  }

  cancel() {
    this.isDisplay = false;
  }

  loadForFilter() {
    const searchRequest = new SearchRequest();
    searchRequest.offset = 0;
    searchRequest.limit = 10000;
    searchRequest.orders = new Array();
    searchRequest.orders.push(
      {
        left: 'name',
        right: 'asc',

      },
    );
    this.taskTypeService.search(searchRequest).subscribe((result: RestResult) => {
      this.taskTypes = result.data;
    });
    this.companyService.search(searchRequest).subscribe((result: RestResult) => {
      this.companies = result?.data;
    });
    this.taskStatusService.search(searchRequest).subscribe((result: RestResult) => {
      this.taskStatuses = result.data;
    });
    this.localService.cities().subscribe((result: City[]) => {
      this.cities = result;
    });
  }

  // navigateProspect(value: { currentBuyerId: number, forward: boolean }) {
  //   if (!value) {
  //     return;
  //   }
  //   // this.selectedBuyer = this.selectedBuyers?.find(x => x.buyerId === value.currentBuyerId);
  //   // this.buyerCreate.setBuyer(this.selectedBuyer);
  //   // if (this.buyerList) {
  //   //   this.buyerList.selectedBuyer = this.selectedBuyer;
  //   //   this.buyerList.highlightRow(value.currentBuyerId);
  //   // }
  // }

  editDone(result: any) {
    // if (StatusUtils.isSuccessResponse(result)) {
    //   this.buyerList.reloadList();
    // }
  }

  newDone(result: any) {
    // if (StatusUtils.isSuccessResponse(result)) {
    //   this.buyerList.reloadList();
    //   this.buyerList.selectedBuyer = result.data;
    //   this.buyerList.restoreState();
    // }
  }

  reserveBuyer(buyer: Buyer) {
    // // unset selected buyer, add default buyer to buyerCreate
    // this.selectedBuyer = null;
    // this.buyerCreate.setBuyer(buyer);
    // // unset select buyer, unset highlightRow
    // this.buyerList.selectedBuyer = null;
    // this.buyerList.highlightRow(null);
  }

  ownSet(buyer: Buyer) {
    // this.isDisplay = true;
    // this.handleSelectedBuyerChange(buyer);
    // // remove selectedBuyer from buyerList
    // this.buyerList.selectedBuyer = buyer;
    // this.buyerList.highlightRow(buyer ? buyer.buyerId : null);
  }

  onCitySelectChange() {
    this.searchRequest.districtId = null;
    this.searchRequest.streetId = null;
    this.localService.districts(this.searchRequest.cityId).subscribe(result => {
      this.districts = result;
    });
    this.localService.zones(this.searchRequest.cityId).subscribe(result => {
      this.zones = result;
    });
  }

  onZoneSelectChange() {
    this.searchRequest.districtId = null;
    this.searchRequest.streetId = null;
    this.localService.districtsByZone(this.searchRequest.zoneId).subscribe(result => {
      this.districts = result;
    });
  }

  onSuburbSelectChange() {
    this.searchRequest.streetId = null;
    this.localService.streets(this.searchRequest.districtId).subscribe(result => {
      this.streets = result;
    });
    this.localService.farms(this.searchRequest.districtId).subscribe(result => {
      this.farms = result;
    });
  }

  onFarmSelectChange() {
    this.searchRequest.streetId = null;
    this.localService.streetsByFarm(this.searchRequest.farmId).subscribe(result => {
      this.streets = result;
    });
  }

  filter() {
    this.searchRequest.filterByAddress = true;
    this.loadRequest(1);
  }

  reset() {
    this.searchRequest.filterByAddress = null;
    this.searchRequest.cityId = null;
    this.searchRequest.districtId = null;
    this.searchRequest.streetId = null;
    this.loadRequest(1);
  }
}
