export class VpaRequest {
  vpaId: number;
  type: string;
  value: number;
  date: number;
  createdBy: string;
  createdDate: number;
  propertyId: number;
  isNew?: boolean;
}
