import { Component, Input, OnInit, Optional } from '@angular/core';
import { ProspectListCommonComponent } from '../common/prospect-list-common.component';
import { ProspectUtils, WrapProspect, WrapProspectType } from '../common/prospect-utils';
import { ProspectListAddressCellComponent } from '../common/prospect-list-address-cell-component';
import { Prospect } from '../../../../shared/models/response/prospect/prospect';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { ToastService } from '../../../../shared/services/toast.service';
import { ProspectService } from '../../../../shared/services/prospect.service';
import { CommonService } from '../../../../shared/services/common.service';
import { RestResult } from '../../../../shared/models/response/rest-result';
import { LocalService } from '../../../../shared/services/local.service';
import { ProspectListCustomCellComponent } from '../common/prospect-list-custom-cell-component';
import { ComponentPermission } from '../../../../component.permission';
import { ProspectDobAgeCellComponent } from '../common/prospect-dob-age-cell-component';
import { SearchRequest } from '../../../../shared/models/request/search-request';
import { City } from '../../../../shared/models/response/address/city';
import { AuthService } from '../../../../shared/services/auth.service';
import { TemplateService } from '../../../../shared/services/template.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProspectListBasicComponent } from '../prospect-list-basic/prospect-list-basic.component';
import { DateUtils } from '../../../../shared/common/date-utils';
import { StringUtils } from '../../../../shared/common/string-utils';

@Component({
  selector: 'ngx-prospect-list-dob',
  templateUrl: './prospect-list-dob.component.html',
  styleUrls: ['./prospect-list-dob.component.scss'],
})
export class ProspectListDobComponent extends ProspectListCommonComponent implements OnInit {
  ComponentPermission = ComponentPermission;
  isDialog = false;
  contactOptionReplacement = (replace: string) => {
    return {
      true: replace,
      false: '',
    };
  }

  columnsDefinition = {
    address: {
      enable: true,
      value: ProspectUtils.createColumn('',
        null, null, ', ', null, ProspectListAddressCellComponent),
      name: 'Address',
    },
    name: {
      enable: true,
      value: ProspectUtils.createColumn('Name', ['customer.name', 'customer.surname'], null, null, null),
      name: 'Name',
    },
    dob: {
      enable: true,
      value: ProspectUtils.createColumn('DoB', ['customer.dob'],
        ProspectListCustomCellComponent.VALUE_TYPES.DATE, null, null),
      name: 'DoB',
    },
    age: {
      enable: true,
      value: this.createAgeColumn('Age'),
      name: 'Age',
    },
  };

  settings = {
    mode: 'external',
    hideSubHeader: true,
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    pager: {
      display: true,
      perPage: 10000,
    },
    columns: this.createColumns(),
    rowClassFunction: (row) => {
      return ProspectUtils.highlightProspectRow(row?.data);
    },
  };

  createColumns() {
    const columns = {};
    for (const key of Object.keys(this.columnsDefinition)) {
      if (this.columnsDefinition[key].enable === true) {
        columns[key] = this.columnsDefinition[key].value;
      }
    }
    return columns;
  }

  createAgeColumn(title: string) {
    return {
      title: title,
      type: 'custom',
      filter: false,
      renderComponent: ProspectDobAgeCellComponent,
    };
  }

  viewMap = false;
  prospects: Prospect[];
  selectedMonth: number;
  selectedDay: number;
  adminNumber: string;
  currentTab = 'CLIENT';

  get CheckPermission() {
    return ComponentPermission;
  }

  @Input() birthdayToday: boolean;

  constructor(@Optional() private ref: NbDialogRef<ProspectListBasicComponent>,
              private _dialogService: NbDialogService,
              private _toastService: ToastService,
              private _prospectService: ProspectService,
              private _commonService: CommonService,
              private _localService: LocalService,
              private _authService: AuthService,
              private _templateService: TemplateService,
              private _router: Router,
              private _route: ActivatedRoute) {
    super(_dialogService, _toastService, _prospectService, _commonService, _localService, _authService, _router, _route, _templateService);
  }

  ngOnInit() {
    this.reducedMode = true;
    this.currentUser = this.authService.currentUser;
    this.param = { ...this.route.snapshot.queryParams };
    this.source.onChanged().subscribe((change) => {
      this.change = change;
      if (change.action === 'refresh') {
        this.pageChange(1);
      }
      if (change.action === 'page') {
        this.param = { ...this.param, page: change.paging.page };
        this.navigateWithParam();
        this.pageChange(change.paging.page);
      }
    });
    if (this.currentUser.office.phone) {
      this.adminNumber = this.currentUser.office.phone;
    }
    this.loadForFilter();
    this.preLoadSelectedEnqTime();
    this.preLoadAddressForFilter();
  }

  loadForFilter() {
    const searchRequest = new SearchRequest();
    searchRequest.offset = 0;
    searchRequest.limit = 10000;
    searchRequest.orders = new Array();
    searchRequest.orders.push(
      {
        left: 'name',
        right: 'asc',

      },
    );
    this.localService.cities().subscribe((result: City[]) => {
      this.cities = result;
    });
  }

  pageChange(pageIndex) {
    this.willBeDeletedProspectIds = [];
    this.willBeDeletedGroupIds = [];
    this.currentPage = pageIndex;
    this.searchRequest.mode = this._selectedMode;
    this.searchRequest.offset = (pageIndex - 1) * this.pageSize;
    if (this.searchRequest.offset < 0) {
      this.searchRequest.offset = 0;
    }
    this.searchRequest.limit = this.pageSize;
    this.searchRequest.officeId = this.currentUser.office?.officeId;
    this.searchRequest.loadOtmExtra = true;
    this.searchRequest.disableItemCountEachGroup = true;
    this.searchRequest.hasDob = true;
    this.searchRequest.sortType = 'DOB_DATE_DESC';
    if (!this.isFiltered) {
      this.appendParameterToSearchRequest();
    }
    if (this.birthdayToday === true) {
      const now = new Date();
      this.searchRequest.dobDay = now.getDate();
      this.searchRequest.dobMonth = now.getMonth() + 1;
    }
    this.prospectService.searchGroups(this.searchRequest).subscribe((result: RestResult) => {
      this.groupWrapper = result.data;
      this.totalPage = Math.ceil(this.groupWrapper.totalGroups / this.pageSize);
      const prospectId = Number(this.route.snapshot.queryParams['selectedProspectId']);
      if (prospectId) {
        this.prospectService.getOne(prospectId).subscribe(r => {
          this.totalPage = Math.ceil(this.groupWrapper.totalGroups / this.pageSize);
          if (prospectId) {
            this.prospectService.getOne(prospectId).subscribe(p => {
              this.selectedProspect = p.data;
              this.getData(pageIndex, this.pageSize);
            });
          }
        });
      }
    });
    this.getData(pageIndex, this.pageSize);

    // this.prospectService.getGroupItemsSimple(this.searchRequest).subscribe((result: RestResult) => {
    //   // const searchResult: SearchResult<Prospect> = result.data;
    //   // this.totalPage = Math.ceil(searchResult.recordsTotal / this.pageSize);
    //   this.selectedProspects = result.data;
    //   if (this.selectedProspects && this.selectedProspects.length !== 0) {
    //     const wrapProspectItem = ProspectUtils.convertItemToWrapperData(this.selectedProspects[0]);
    //     this.wrapProspects = ProspectUtils.convertGroupItemToWrapperData(result.data);
    //     this.selectRecord({data: wrapProspectItem}, false, false);
    //   }
    //   // const convertResult = ProspectUtils.convertGroupItemToWrapperData(searchResult.data);
    //   // this.buildData(this.pageSize, convertResult, () => {
    //   //   this.restoreState();
    //   // });
    //   // this.onProspectChange(this.prospectChange);
    //   DataSourceUtil.reloadDataProspectListing(this.source, pageIndex, this.pageSize, result);
    // });
  }

  onSearch() {
    this.pageChange(1);
  }

  onMonthChange(value: number) {
    if (value !== 0) {
      this.selectedMonth = value;
      this.isFiltered = true;
      delete this.param['selectedMonth'];
      delete this.param['selectedDay'];
      delete this.param['selectedProspectId'];
      this.param = { ...this.param, selectedMonth: this.selectedMonth };
      this.searchRequest.dobMonth = value;
      this.searchRequest.dobDay = null;
    } else {
      this.searchRequest.dobMonth = null;
      delete this.param['selectedMonth'];
      delete this.param['selectedDay'];
      delete this.param['selectedProspectId'];
    }
    this.navigateWithParam();
    this.pageChange(1);
  }

  onDayChange(value: number) {
    if (value && !this.selectedMonth) {
      return;
    }
    if (value !== 0) {
      this.searchRequest.dobDay = value;
      this.selectedDay = value;
      this.isFiltered = true;
      delete this.param['selectedDay'];
      delete this.param['selectedProspectId'];
      this.param = { ...this.param, selectedDay: this.selectedDay };
    } else {
      this.searchRequest.dobDay = null;
      delete this.param['selectedDay'];
      delete this.param['selectedProspectId'];
    }
    this.navigateWithParam();
    this.pageChange(1);
  }

  selectRow(event: any, el: HTMLElement) {
    const wrapProspect: WrapProspect = event.data;
    if (wrapProspect?.type === WrapProspectType.ITEM) {
      this.isDisplay = true;
    }
  }

  closeDialog() {
    this.ref.close();
  }

  onCityChange(cityId) {
    super.onCityChange(cityId);
    this.isFiltered = true;
    delete this.param['districtId'];
    delete this.param['streetId'];
    delete this.param['cityId'];
    if (this.searchRequest.cityId) {
      this.param = { ...this.param, cityId: this.searchRequest.cityId };
    } else {
      delete this.param['districtId'];
      delete this.param['streetId'];
      delete this.param['cityId'];
    }
    this.navigateWithParam();
    this.pageChange(1);
  }

  onSuburbChange(districtId) {
    super.onSuburbChange(districtId);
    this.isFiltered = true;
    delete this.param['streetId'];
    delete this.param['districtId'];
    if (this.searchRequest.districtId) {
      this.param = { ...this.param, districtId: this.searchRequest.districtId };
    } else {
      delete this.param['streetId'];
      delete this.param['districtId'];
    }
    this.navigateWithParam();
    this.pageChange(1);
  }

  onStreetChange(streetId) {
    super.onStreetChange(streetId);
    this.isFiltered = true;
    delete this.param['streetId'];
    if (this.searchRequest.streetId) {
      this.param = { ...this.param, streetId: this.searchRequest.streetId };
    } else {
      delete this.param['streetId'];

    }
    this.navigateWithParam();
    this.pageChange(1);
  }

  selectRecord(event: any, openEditDialog: boolean, addToSelected: boolean, jump?: boolean, forward?: boolean) {
    super.selectRecord(event, openEditDialog, addToSelected, jump, forward);
  }

  appendParameterToSearchRequest() {
    const params = this.route.snapshot.queryParams;
    if (params['cityId']) {
      this.searchRequest.cityId = Number(params['cityId']);
    }
    if (params['districtId']) {
      this.searchRequest.districtId = Number(params['districtId']);
    }
    if (params['streetId']) {
      this.searchRequest.streetId = Number(params['streetId']);
    }
    if (params['postcode']) {
      this.searchRequest.postcode = params['postcode'];
    }
    if (params['selectedMonth']) {
      this.searchRequest.dobMonth = Number(params['selectedMonth']);
    }
    if (params['selectedDay']) {
      this.searchRequest.dobDay = Number(params['selectedDay']);
    }
  }

  preLoadAddressForFilter() {
    if (this.route.snapshot.queryParamMap.keys.length === 0) {
      return;
    }
    const postcode = Number(this.route.snapshot.queryParamMap.get('postcode'));
    const districtId = this.route.snapshot.queryParamMap.get('districtId');
    if (postcode) {
      this.localService.districtByPostcode(postcode).subscribe(result => {
        this.filteredDistricts = result;
      });
    }
    if (districtId) {
      this.localService.streets(Number(districtId)).subscribe(result => {
        this.filteredStreets = result;
      });
    }
  }

  preLoadSelectedEnqTime() {
    if (this.route.snapshot.queryParamMap.keys.length === 0) {
      return;
    }
    const selectedMonth = this.route.snapshot.queryParamMap.get('selectedMonth');
    const selectedDay = this.route.snapshot.queryParamMap.get('selectedDay');
    if (selectedMonth) {
      this.selectedMonth = Number(selectedMonth);
    }
    if (selectedDay) {
      this.selectedDay = Number(selectedDay);
    }
  }

  onSearchByPostcode(value: number) {
    super.onSearchByPostcode(value);
    if (this.searchRequest.postcode) {
      this.param = { ...this.param, postcode: this.searchRequest.postcode };
    } else {
      delete this.param['postcode'];
    }
    this.navigateWithParam();
    if (this.searchRequest.postcode === null) {
      delete this.param['postcode'];
    }
    this.pageChange(1);
  }
}
