import {Component, Input, OnInit} from '@angular/core';
import {ViewCell} from 'ng2-smart-table';
import {WrapBuyer, WrapBuyerType} from './buyer-utils';

@Component({
  template: `
    {{renderValue}}
  `,
  styles: [],
})
export class BuyerListStatusCellComponent implements ViewCell, OnInit {

  renderValue: string;

  @Input() value: string | number;
  @Input() rowData: WrapBuyer;

  ngOnInit() {
    this.setValue();
  }

  setValue(): any {
    if (!this.rowData) {
      this.renderValue = this.value ? this.value.toString() : '';
      return;
    }
    this.renderValue = '';
    if (this.rowData.type === WrapBuyerType.ITEM) {
      const data = this.rowData.data;
      if (data.isActive === 'ACTIVE') {
        if (data.isCommon) {
          this.renderValue = `${this.renderValue}C`;
        }
        if (data.isAddress) {
          this.renderValue = `${this.renderValue}A`;
        }
        if (data.isNumber) {
          this.renderValue = `${this.renderValue}N`;
        }
        if (data.isInspection) {
          this.renderValue = `${this.renderValue}I`;
        }
      } else {
        if (data?.address?.districtId) {
          this.renderValue = `${this.renderValue}S`;
        }
        if (data?.address?.streetId) {
          this.renderValue = `${this.renderValue}S`;
        }
        if (data?.address?.numberFrom) {
          this.renderValue = `${this.renderValue}N`;
        }
      }
      // if (data.buyerCode?.trim() === 'VB' || data.buyerCode?.trim() === 'B' || data.buyerCode?.trim() === 'T') {
      //   if (data.isCommon) {
      //     this.renderValue = `${this.renderValue}C`;
      //   }
      //   if (data.isAddress) {
      //     this.renderValue = `${this.renderValue}A`;
      //   }
      //   if (data.isNumber) {
      //     this.renderValue = `${this.renderValue}N`;
      //   }
      //   if (data.isInspection) {
      //     this.renderValue = `${this.renderValue}I`;
      //   }
      // } else if (data.buyerCode?.trim() === 'S') {
      //   if (data.address?.districtId) {
      //     this.renderValue = `${this.renderValue}S`;
      //   }
      //   if (data.address?.streetId) {
      //     this.renderValue = `${this.renderValue}S`;
      //   }
      //   if (data.address?.numberFrom) {
      //     this.renderValue = `${this.renderValue}N`;
      //   }
      // }

      if (data.rate) {
        this.renderValue = ` (${data.rate}) ${this.renderValue}`;
      }
    }
  }

}
