import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Street } from '../../../../shared/models/response/address/street';
import { District } from '../../../../shared/models/response/address/district';
import { City } from '../../../../shared/models/response/address/city';
import { NbDatepickerComponent, NbDialogRef, NbDialogService } from '@nebular/theme';
import { FormService } from '../../../../shared/services/form.service';
import { ProspectService } from '../../../../shared/services/prospect.service';
import { CommonService } from '../../../../shared/services/common.service';
import { StreetService } from '../../../../shared/services/street.service';
import { ProspectShortcutRequest } from '../../../../shared/models/request/prospect/prospect-shortcut-request';
import { Constant } from '../../../../shared/common/constant';
import { Company } from '../../../../shared/models/response/prospect/company';
import { PropertyRequest } from '../../../../shared/models/request/prospect/property-request';
import { AddressRequest } from '../../../../shared/models/request/address/address-request';
import { CustomerRequest } from '../../../../shared/models/request/prospect/customer-request';
import { LocalService } from '../../../../shared/services/local.service';
import { CustomerSearchRequest } from '../../../../shared/models/request/prospect/customer-search-request';
import { CustomerDuplicatePhone } from '../../../../shared/models/response/prospect/customer-duplicate-phone';
import { CustomerService } from '../../../../shared/services/customer.service';
import { Prospect } from '../../../../shared/models/response/prospect/prospect';
import { Observable, of } from 'rxjs';
import { Category } from '../../../../shared/models/response/category';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { map, mergeMap } from 'rxjs/operators';
import { SearchRequest } from '../../../../shared/models/request/search-request';
import { Triple } from '../../../../shared/models/request/triple';
import { CategoryService } from '../../../../shared/services/category.service';
import { Office } from '../../../../shared/models/response/office';
import { TaskMiniDialogComponent } from '../../task/task-mini-dialog/task-mini-dialog.component';
import { TaskType } from '../../../../shared/models/response/task-type';
import { TaskStatus } from '../../../../shared/models/response/task-status';
import { RestResult } from '../../../../shared/models/response/rest-result';
import { NationalityNoteService } from '../../../../shared/services/nationality.note.service';
import { NationalityNote } from '../../../../shared/models/response/nationality-note';
import { Postcode } from '../../../../shared/models/response/postcode';
import { ProspectSearchRequest } from '../../../../shared/models/request/prospect/prospect-search-request';
import { User } from '../../../../shared/models/response/user';
import { UserService } from '../../../../shared/services/user.service';

@Component({
  selector: 'ngx-prospect-shortcut',
  templateUrl: './prospect-shortcut.component.html',
  styleUrls: ['./prospect-shortcut.component.scss'],
})
export class ProspectShortcutComponent implements OnInit {
  @Input() taskTypes: TaskType[];
  @Input() taskStatuses: TaskStatus[];
  @Output() editDone = new EventEmitter();
  prospectForm: FormGroup;
  filteredStreets: Street[];
  streets: Street[];
  filteredDistricts: District[];
  districts: District[];
  cities: City[];
  companies: Company[];
  streetId: number;
  districtId: number;
  cityId: number;
  isUpdating = false;
  isSubmitted = false;
  isMotive = false;
  showMarket = false;
  showContact = false;
  showMotive = false;
  showPerson = false;

  customerRelationShip = Constant.CUSTOMER_RELATIONSHIP;
  customerMotivate = Constant.CUSTOMER_MOTIVATE;
  propertyType = Constant.PROPERTY_TYPE;
  propertyDefaultSpec = Constant.PROPERTY_DEFAULT_SPEC;
  defaultCurrencyIcon = Constant.DEFAULT_CURRENCY_ICON;
  dateFormat = 'DD/MM/YYYY';
  bedValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  phoneDuplicate: CustomerDuplicatePhone;
  mobiPhoneDuplicate: CustomerDuplicatePhone;
  data: Prospect;
  categoryName: string;
  filterCategory$: Observable<Category[]>;
  selectedCategories: Category[] = [];
  officeName: string;
  selectedOffice: Office;
  filterOffice$: Observable<Office[]>;
  onClose: any;
  dobText = '';
  languages = Constant.LANGUAGES;
  religions = Constant.RELIGIONS;
  nationalities = Constant.COUNTRIES;
  currentNationalityNote: NationalityNote;
  nationalityNoteForm: FormGroup;
  postcode: number;
  filterPostcode$: Observable<Postcode[]>;
  filterUser$: Observable<User[]>;
  filterByPostcode = false;
  searchRequest = new ProspectSearchRequest();
  userName: string;
  rateOptions = [
    {value: 0, label: '0'},
    {value: 1, label: '1'},
    {value: 2, label: '2'},
    {value: 3, label: '3'},
    {value: 4, label: '4'},
    {value: 5, label: '5'},
    {value: 6, label: '6'},
    {value: 7, label: '7'},
  ];

  constructor(private dialogRef: NbDialogRef<ProspectShortcutComponent>,
              private formService: FormService,
              private prospectService: ProspectService,
              private commonService: CommonService,
              private customerService: CustomerService,
              private streetService: StreetService,
              private categoryService: CategoryService,
              private dialogService: NbDialogService,
              private nationalityNoteService: NationalityNoteService,
              private localService: LocalService,
              private userService: UserService) {
  }

  ngOnInit(): void {
    this.prospectForm = new FormGroup({
      unit: new FormControl(null),
      numberFrom: new FormControl(null, [
        Validators.required,
      ]),
      numberFromLetter: new FormControl(null),
      numberTo: new FormControl(),
      numberToLetter: new FormControl(),
      number: new FormControl(),
      streetId: new FormControl(this.streetId),
      additionalInfo: new FormControl(),

      call: new FormControl(),
      sms: new FormControl(),
      door: new FormControl(),
      mail: new FormControl(),
      // ===
      sell: new FormControl(),
      rent: new FormControl(),
      otm: new FormControl(),
      vac: new FormControl(),
      // ===
      isDirector: new FormControl(),
      isInfluencer: new FormControl(),
      isSocialble: new FormControl(true),
      isConcensious: new FormControl(),
      // ===
      motivateFinancialUp: new FormControl(),
      motivateFinancialDown: new FormControl(),
      motivateLifestyleUp: new FormControl(),
      motivateLifestyleDown: new FormControl(),

      // ===
      phone: new FormControl(null, [
        Validators.maxLength(20),
      ]),
      mobilePhone: new FormControl(null, [
        Validators.maxLength(20),
      ]),
      email: new FormControl(null, [
        Validators.maxLength(200),
      ]),
      facebook: new FormControl(null, [
        Validators.maxLength(500),
      ]),
      instagram: new FormControl(null, [
        Validators.maxLength(500),
      ]),
      // ===
      relationship: new FormControl(null, [
        Validators.maxLength(1),
      ]),
      motivate: new FormControl(null, [
        Validators.maxLength(1),
      ]),
      // ===
      bed: new FormControl(),
      bath: new FormControl(),
      car: new FormControl(),
      land: new FormControl(),
      type: new FormControl(),
      sellPrice: new FormControl(),
      rentPrice: new FormControl(),
      askingPrice: new FormControl(),
      otmDate: new FormControl(''),
      vacDate: new FormControl(''),
      purchaseDate: new FormControl(''),
      sellCompanyId: new FormControl(),
      rentCompanyId: new FormControl(),
      rate: new FormControl(0),
      name: new FormControl(null, [
        Validators.maxLength(200),
      ]),
      surname: new FormControl(null, [
        Validators.maxLength(200),
      ]),
      note: new FormControl(null, [
        Validators.maxLength(2000),
      ]),
      motiveNote: new FormControl(null, [
        Validators.maxLength(2000),
      ]),
    });
    if (this.postcode) {
      this.filterByPostcode = true;
      // this.searchByPostcode(this.postcode);
    }
    // if (this.districtId) {
    //   this.localService.streets(this.districtId).subscribe(result => {
    //     this.filteredStreets = result;
    //   });
    // }
    this.filterCategory$ = new Observable((observer: any) => {
      observer.next(this.categoryName);
    }).pipe(
      mergeMap((query: string) => this.searchCategory(query)),
    );
    this.initAutocomplete();
  }

  close() {
    this.dialogRef.close();
  }

  // address
  onSuburbChange(event: any) {
    if (!event) {
      this.districtId = undefined;
    } else {
      this.districtId = Number(event);
    }
    this.streetId = undefined;
    this.prospectForm.controls.streetId.setValue(null);
    if (this.districtId) {
      this.localService.streets(this.districtId).subscribe(result => {
        this.filteredStreets = result;
      });
    } else {
      this.districtId = undefined;
    }
  }

  // onCityChange(cityId?: number) {
  //   this.districtId = undefined;
  //   this.prospectForm.controls.streetId.setValue(null);
  //   if (cityId) {
  //     this.localService.districts(cityId).subscribe(result => {
  //       this.filteredDistricts = result;
  //     });
  //   } else {
  //     this.cityId = undefined;
  //   }
  // }

  onStreetChange(event: any) {
    if (!event) {
      this.streetId = undefined;
    } else {
      this.streetId = Number(event);
    }
    if (this.streetId) {
      this.prospectForm.controls.streetId.setValue(this.streetId);
    } else {
      this.prospectForm.controls.streetId.setValue(null);
      this.streetId = undefined;
    }
  }

  // submit
  submit() {
    this.isUpdating = true;
    this.formService.extractErrorMessage(this.prospectForm);
    if (this.prospectForm.invalid) {
      this.isUpdating = false;
      this.isSubmitted = true;
      return;
    }
    this.updateProspect();
  }

  updateProspect() {
    const prospectRequest: ProspectShortcutRequest = { ...this.prospectForm.value };
    prospectRequest.property = { ...this.prospectForm.value };
    prospectRequest.customer = { ...this.prospectForm.value };
    prospectRequest.address = { ...this.prospectForm.value };
    this.trimObject(prospectRequest, Object.keys(ProspectShortcutRequest.getDummy()));
    if (prospectRequest.customer) {
      this.trimObject(prospectRequest.customer, Object.keys(CustomerRequest.getDummy()));
    }
    if (prospectRequest.property) {
      this.trimObject(prospectRequest.property, Object.keys(PropertyRequest.getDummy()));
    }
    if (prospectRequest.address) {
      this.trimObject(prospectRequest.address, Object.keys(AddressRequest.getDummy()));
      if (this.districtId) {
        prospectRequest.address.districtId = this.districtId;
      }
    }
    this.prospectService.shortcutUpdate(prospectRequest).subscribe(
      result => {
        this.commonService.info('Update success.');
        this.dialogRef.close(result);
        this.isUpdating = false;
      },
      error => {
        this.commonService.warningHtml(error.message);
        this.isUpdating = false;
      },
    );
  }

  // form
  setSquareValue(controlName: string) {
    if (this.prospectForm.get(controlName).value !== true) {
      this.prospectForm.get(controlName).setValue(true);
    } else {
      this.prospectForm.get(controlName).setValue(false);
    }
    this.autoSetSORV();
  }

  autoSetSORV() {
    const sell = this.prospectForm.controls.sell.value;
    const rent = this.prospectForm.controls.rent.value;
    const otm = this.prospectForm.controls.otm.value;
    const vac = this.prospectForm.controls.vac.value;
    if (!sell && !rent && !otm && !vac) {
      this.prospectForm.controls.type.setValue('HOUSE_COM');
      this.onPropertyTypeChange();
      return;
    }
    if (vac) {
      this.prospectForm.controls.rent.setValue(true);
      return;
    }
  }

  onPropertyTypeChange() {
    const type = this.prospectForm.controls.type.value;
    if (type) {
      const defaultSpec = this.propertyDefaultSpec[type];

      if (defaultSpec &&
        !this.prospectForm.controls.bed.value &&
        !this.prospectForm.controls.bath.value &&
        !this.prospectForm.controls.car.value &&
        !this.prospectForm.controls.land.value) {
        this.prospectForm.controls.bed.setValue(defaultSpec.bed);
        this.prospectForm.controls.bath.setValue(defaultSpec.bath);
        this.prospectForm.controls.car.setValue(defaultSpec.car);
        this.prospectForm.controls.land.setValue(defaultSpec.land);
      }
      if (type === 'HOUSE_COM') {
        this.prospectForm.controls.sell.setValue(false);
        this.prospectForm.controls.rent.setValue(false);
        this.prospectForm.controls.otm.setValue(false);
        this.prospectForm.controls.vac.setValue(false);
      } else if (type) {
        this.prospectForm.controls.sell.setValue(true);
      }
    }
  }

  searchSameCustomer(type: string) {
    if (type === 'PHONE') {
      this.lookupPhone(this.prospectForm.value.phone, type);
    } else {
      this.lookupPhone(this.prospectForm.value.mobilePhone, type);
    }
  }

  selectPhone(type: string) {
    let phone;
    if (type === 'PHONE') {
      phone = this.prospectForm.value.phone;
    } else {
      phone = this.prospectForm.value.mobilePhone;
    }
    window.open(
      '/pages/dashboard/search/phone?phone=' + phone + '&isMatched=true',
      '_blank',
    );
  }

  lookupPhone(phone: string, type: string) {
    if (type === 'PHONE') {
      this.phoneDuplicate = null;
    } else {
      this.mobiPhoneDuplicate = null;
    }
    if (phone) {
      const customerSearchRequest = new CustomerSearchRequest();
      customerSearchRequest.phone = phone;
      customerSearchRequest.isMatched = true;
      this.customerService.searchDuplicatePhone(customerSearchRequest).subscribe(
        result => {
          if (result.data && result.data.length > 0) {
            if (type === 'PHONE') {
              this.phoneDuplicate = new CustomerDuplicatePhone();
              result.data.forEach(response => {
                this.phoneDuplicate.phone = response.phone;
                if (response.tableType === 'BUYER') {
                  this.phoneDuplicate.totalCustomer += response.total;
                } else {
                  this.phoneDuplicate.totalClient += response.total;
                }
              });
            } else {
              this.mobiPhoneDuplicate = new CustomerDuplicatePhone();
              result.data.forEach(response => {
                this.mobiPhoneDuplicate.phone = response.phone;
                if (response.tableType === 'BUYER') {
                  this.mobiPhoneDuplicate.totalCustomer += response.total;
                } else {
                  this.mobiPhoneDuplicate.totalClient += response.total;
                }
              });
            }
          }
        }, error => {
          this.commonService.warning(error.message);
        },
      );
    }
  }

  // OThers

  trimObject(obj: any, properties: string[]): any {
    const curentProperties: string[] = Object.keys(obj);
    for (const name of curentProperties) {
      if (properties.indexOf(name) === -1) {
        delete obj[name];
      }
    }
  }

  showError(name: string): string {
    return this.prospectForm.controls[name]?.errors
    && (this.prospectForm.controls[name].dirty
      || this.prospectForm.controls[name].touched
      || this.isSubmitted) ? 'has-error' : '';
  }

  keepOrder(a, b) {
    return 1;
  }

  triggerDatePicker(datePickerComponent: NbDatepickerComponent<any>) {
    datePickerComponent?.show();
  }

  selectCategory(type: TypeaheadMatch) {
    this.categoryName = '';
    const selectedCategory: Category = type.item;
    if (this.selectedCategories.filter(category => category.categoryId === selectedCategory.categoryId).length > 0) {
      return;
    }
    this.selectedCategories.push(selectedCategory);
  }

  removeCategory(removedCategory: Category) {
    if (removedCategory) {
      this.selectedCategories = this.selectedCategories.filter
      (category => category.categoryId !== removedCategory.categoryId);
    }
  }

  searchCategory(name: string): Observable<Category[]> {
    if (!name || name === '') {
      return of([]);
    }
    const searchRequest = new SearchRequest();
    searchRequest.conditions = new Array();

    searchRequest.conditions.push(new Triple('name', 'like', name));
    searchRequest.conditions.push(new Triple('type', '=', 'PROSPECT'));
    return this.categoryService.search(searchRequest).pipe(
      map(result => result.data),
    );
  }

  selectOffice(type: TypeaheadMatch) {
    this.officeName = type.item.name;
    this.selectedOffice = type.item;
    // this.prospectForm.controls.officeId.patchValue(type.item.officeId);
  }

  //
  // createTask(type: 'BIRTHDAY_CALL' | 'NEXT_CALL' | 'APPOINTMENT') {
  //   const defaultData = {
  //     description: `Make phone call to ${this.data?.customer?.name}, `
  //       + `phone number: ${this.data?.customer?.phone} or ${this.data?.customer?.mobilePhone}`,
  //     isCallback: type === 'NEXT_CALL',
  //     isDob: type === 'BIRTHDAY_CALL',
  //     isAppointment: type === 'APPOINTMENT',
  //     callbackDate: this.prospectForm.controls.callbackDate.value,
  //     dob: this.prospectForm.controls.dob.value,
  //   };
  //   this.dialogService.open(TaskMiniDialogComponent, {
  //     hasScroll: true,
  //     context: {
  //       defaultData,
  //       taskTypes: this.taskTypes,
  //       taskStatuses: this.taskStatuses,
  //       selectedProspect: this.data,
  //     },
  //   })
  //     .onClose.subscribe(res => {
  //     if (this.isSuccessResponse(res)) {
  //       this.close();
  //     }
  //   });
  // }
  // isSuccessResponse(response) {
  //   return response && (response.status === 'success');
  // }

  toggleDisplayContact() {
    this.showContact = !this.showContact;
  }

  toggleDisplayMotive() {
    this.showMotive = !this.showMotive;
  }

  toggleDisplayPerson() {
    this.showPerson = !this.showPerson;
  }

  toggleDisplayMarket() {
    this.showMarket = !this.showMarket;
  }

  // onNationalityChange() {
  //   // this.prospectForm.controls.otmDate
  //   if (this.prospectForm.value.nationality) {
  //     const currentCountry = this.nationalities
  //       .find(nationality => nationality.country_name === this.prospectForm.value.nationality);
  //     this.initNationalityNote();
  //     if (currentCountry && currentCountry.lang_code) {
  //       this.prospectForm.controls.language.setValue(currentCountry.lang_code);
  //     } else {
  //       this.prospectForm.controls.language.setValue(null);
  //     }
  //     if (this.currentNationalityNote && this.currentNationalityNote.note) {
  //       this.prospectForm.controls.nationalityNote.patchValue(this.currentNationalityNote.note);
  //     } else {
  //       this.prospectForm.controls.nationalityNote.patchValue(null);
  //     }
  //   }
  // }
  //
  // initNationalityNote() {
  //   if (this.prospectForm.value.nationality) {
  //     const currentCountry = this.nationalities
  //       .find(nationality => nationality.country_name === this.prospectForm.value.nationality);
  //     const sr = new SearchRequest;
  //     sr.conditions = [];
  //     sr.conditions.push(new Triple('countryCodeName', '=', currentCountry.country_code_name));
  //     this.nationalityNoteService.search(sr).subscribe(result => {
  //       this.currentNationalityNote = result.data[0];
  //       this.prospectForm.controls.nationalityNote.patchValue(this.currentNationalityNote.note);
  //       this.nationalityNoteForm = new FormGroup({
  //         nationalityNoteId: new FormControl(this.currentNationalityNote.nationalityNoteId),
  //         note: new FormControl(this.currentNationalityNote.note),
  //       });
  //     });
  //   }
  // }

  selectPostcode(type: TypeaheadMatch) {
    this.postcode = Number(type.item.code);
    this.searchByPostcode(this.postcode);
  }

  searchByPostcode(value: number) {
    this.filterByPostcode = true;
    this.searchRequest.districtId = undefined;
    this.searchRequest.postcode = value;
    this.searchRequest.streetId = undefined;
    if (value && value !== 0) {
      this.localService.districtByPostcode(value).subscribe(result => {
        this.filteredDistricts = result;
      });
    }
  }

  initAutocomplete() {
    this.filterCategory$ = new Observable((observer: any) => {
      observer.next(this.categoryName);
    }).pipe(
      mergeMap((query: string) => this.searchCategory(query)),
    );
    this.filterUser$ = new Observable((observer: any) => {
      observer.next(this.userName);
    }).pipe(
      mergeMap((query: string) => this.searchUser(query)),
    );
    this.filterPostcode$ = new Observable((observer: any) => {
      observer.next(this.postcode);
    }).pipe(
      map((query: string) => {
        this.postcode = Number(query);
        this.searchByPostcode(this.postcode);
        return null;
      }),
    );
  }

  searchUser(keyword: string): Observable<User[]> {
    // this.refreshUserFilter();
    const searchRequest = new SearchRequest();
    searchRequest.conditions = new Array();
    searchRequest.conditions.push(
      {
        left: 'email',
        middle: 'like',
        right: keyword,
      },
    );
    searchRequest.conditions.push(
      {
        left: 'username',
        middle: 'like',
        right: keyword,
      },
    );
    searchRequest.conditions.push(
      {
        left: 'name',
        middle: 'like',
        right: keyword,
      },
    );
    searchRequest.conditionType = Constant.CONDITION_TYPE.OR;
    searchRequest.offset = 0;
    searchRequest.limit = 10;
    return this.userService.search(searchRequest).pipe(
      map(result => result.data.filter(user => user.status === 'ACTIVE')),
    );
  }

  onSelectRate(event: any) {
    const rate = Number(event.target.value);
    if (rate === 2) {
      this.prospectForm.controls.relationship.setValue(2);
      this.prospectForm.controls.motivate.setValue(2);
    }
  }
}
