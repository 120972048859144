import { Component, Inject, Input, OnInit, Sanitizer } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { StringUtils } from '../../../common/string-utils';
import { Prospect } from '../../../models/response/prospect/prospect';
import { ComponentPermission } from '../../../../component.permission';
import { User } from '../../../models/response/user';
import { SearchRequest } from '../../../models/request/search-request';
import { Template } from '../../../models/response/template';
import { TemplateService } from '../../../services/template.service';
import { NbDialogRef, NbPosition, NbTrigger } from '@nebular/theme';
import { ProspectMobileWrapper } from '../../../models/response/prospect/prospect-mobile-wrapper';
import { BuyerMobileWrapper } from '../../../models/response/buyer/buyer-mobile-wrapper';
import { SmsContent } from '../../../models/enum/sms-content';
import { DeviceDetectionService } from '../../../services/device-detection.service';

@Component({
  selector: 'ngx-editing-dialog',
  templateUrl: './editing-dialog.component.html',
  styleUrls: ['./editing-dialog.component.scss'],
})
export class EditingDialogComponent implements OnInit {
  prospect: Prospect;
  currentUser: User;
  static confirmOk: string = 'CONFIRM_OK';
  adminNumber: string;
  templates: Template[] = [];
  selectedTemplate: Template;
  trigger = NbTrigger.CLICK;
  position = NbPosition.END;
  sendToTenant = false;
  exportType: string;
  smsContent = SmsContent;

  constructor(private sanitizer: DomSanitizer,
              private templateService: TemplateService,
              protected ref: NbDialogRef<EditingDialogComponent>,
              private deviceDetection: DeviceDetectionService) {
  }

  ngOnInit(): void {
    if (this.templates) {
      if (!this.selectedTemplate) {
        this.selectedTemplate = this.templates[0];
      }
    }
    this.currentUser = ComponentPermission.currentUser();
  }

  // generateHref(): SafeUrl {
  // const temp = this.prospect?.customer?.mobilePhone;
  // if (!StringUtils.isNotEmpty(temp)) {
  //   return;
  // }
  // let href;
  // const phone = temp.replace(/\s/g, '');
  // let lowerAskingPriceForSale = this.prospect?.property?.askingPrice - (this.prospect?.property?.askingPrice * 5 / 100);
  // lowerAskingPriceForSale = ((parseInt(((Math.ceil(lowerAskingPriceForSale / 10000)) * 10000).toString(), 0)));
  // let higherAskingPriceForSale = this.prospect?.property?.askingPrice + (this.prospect?.property?.askingPrice * 5 / 100);
  // higherAskingPriceForSale = ((parseInt(((Math.ceil(higherAskingPriceForSale / 10000)) * 10000).toString(), 0)));
  // let lowerAskingPrice = this.prospect?.property?.askingPrice - (this.prospect?.property?.askingPrice * 5 / 100);
  // lowerAskingPrice = ((parseInt(((Math.ceil(lowerAskingPrice / 5000)) * 5000).toString(), 0)));
  // let higherAskingPrice = this.prospect?.property?.askingPrice + (this.prospect?.property?.askingPrice * 5 / 100);
  // higherAskingPrice = ((parseInt(((Math.ceil(higherAskingPrice / 5000)) * 5000).toString(), 0)));
  // let lowerRentPrice = this.prospect?.property?.rentPrice - (this.prospect?.property?.rentPrice * 5 / 100);
  // lowerRentPrice = Math.ceil(lowerRentPrice / 10) * 10;
  // let higherRentPrice = this.prospect?.property?.rentPrice + (this.prospect?.property?.rentPrice * 5 / 100);
  // higherRentPrice = Math.ceil(higherRentPrice / 10) * 10;
  // const name = (this.prospect?.customer?.name ?  this.prospect?.customer?.name + ' ' : '') +
  //   (this.prospect?.customer?.surname ? this.prospect?.customer?.surname : '');
  // const address = StringUtils.getFullAddress(this.prospect?.address).replace(/,/g, '');
  // const agentInfo = this.currentUser?.position ? this.currentUser?.position + ', ' : '' +
  // this.currentUser?.office?.name ? this.currentUser?.office?.name : '';
  // const body = this.template?.message?.replace(/(\[CLIENT_NAME])/g, name)
  //   .replace(/(\[CLIENT_ADDRESS])/g, address)
  //   .replace(/(\[ASKING_PRICE_FROM])/g, (this.currentUser?.position?.toLowerCase().includes('sale') ?
  //     lowerAskingPriceForSale.toString() : lowerAskingPrice.toString()))
  //   .replace(/(\[ASKING_PRICE_TO])/g, (this.currentUser?.position?.toLowerCase().includes('sale') ?
  //     higherAskingPriceForSale.toString() : higherAskingPrice.toString()))
  //   .replace(/(\[RENT_PRICE_FROM])/g, (lowerRentPrice.toString()))
  //   .replace(/(\[RENT_PRICE_TO])/g, (higherRentPrice.toString()))
  //   .replace(/(\[AGENT_NAME])/g, this.currentUser?.name)
  //   .replace(/(\[AGENT_TITLE])/g, agentInfo)
  //   .replace(/(\[AGENT_PHONE])/g, this.currentUser?.mobile ? this.currentUser?.mobile : '');
  // href = 'sms://open?addresses=' + phone + '&body=' + encodeURIComponent(body);
  // return this.sanitizer.bypassSecurityTrustUrl(href);
  // }

  generateHrefWithoutBody(): SafeUrl {
    const temp = this.prospect?.customer?.mobilePhone;
    if (!StringUtils.isNotEmpty(temp)) {
      return;
    }
    let href;
    const phone = temp.replace(/\s/g, '');
    href = 'sms://open?addresses=' + phone;
    return this.sanitizer.bypassSecurityTrustUrl(href);
  }

  onSelectTemplate(event: any) {
    this.templates.find(temp => {
      if (temp.templateId === Number(event)) {
        this.selectedTemplate = temp;
      }
    });
  }

  cancel() {
    this.ref.close();
  }

  generateHref(): SafeUrl {
    const temp = this.prospect?.customer?.mobilePhone;
    if (!StringUtils.isNotEmpty(temp)) {
      return;
    }
    let href;
    const phone = temp.replace(/\s/g, '');
    let lowerAskingPriceForSale = this.prospect?.property?.askingPrice - (this.prospect?.property?.askingPrice * 5 / 100);
    lowerAskingPriceForSale = ((parseInt(((Math.ceil(lowerAskingPriceForSale / 10000)) * 10000).toString(), 0)));
    let higherAskingPriceForSale = this.prospect?.property?.askingPrice + (this.prospect?.property?.askingPrice * 5 / 100);
    higherAskingPriceForSale = ((parseInt(((Math.ceil(higherAskingPriceForSale / 10000)) * 10000).toString(), 0)));
    let lowerAskingPrice = this.prospect?.property?.askingPrice - (this.prospect?.property?.askingPrice * 5 / 100);
    lowerAskingPrice = ((parseInt(((Math.ceil(lowerAskingPrice / 5000)) * 5000).toString(), 0)));
    let higherAskingPrice = this.prospect?.property?.askingPrice + (this.prospect?.property?.askingPrice * 5 / 100);
    higherAskingPrice = ((parseInt(((Math.ceil(higherAskingPrice / 5000)) * 5000).toString(), 0)));
    let lowerRentPrice = this.prospect?.property?.rentPrice - (this.prospect?.property?.rentPrice * 5 / 100);
    lowerRentPrice = Math.ceil(lowerRentPrice / 10) * 10;
    let higherRentPrice = this.prospect?.property?.rentPrice + (this.prospect?.property?.rentPrice * 5 / 100);
    higherRentPrice = Math.ceil(higherRentPrice / 10) * 10;
    const name = (this.prospect?.customer?.name ? this.prospect?.customer?.name + ' ' : '') +
      (this.prospect?.customer?.surname ? this.prospect?.customer?.surname : '');
    const address = StringUtils.getFullAddress(this.prospect?.address).replace(/,/g, '');
    const agentInfo = this.currentUser?.position ? this.currentUser?.position + ', ' : '' +
    this.currentUser?.office?.name ? this.currentUser?.office?.name : '';
    if (this.selectedTemplate.code === 'BSMS.body') {
      const body = this.selectedTemplate?.message?.replace(/(\[CLIENT_NAME])/g, name)
        .replace(/(\[CLIENT_ADDRESS])/g, address)
        .replace(/(\[ASKING_PRICE_FROM])/g, (this.currentUser?.position?.toLowerCase().includes('sale') ?
          lowerAskingPriceForSale.toString() : lowerAskingPrice.toString()))
        .replace(/(\[ASKING_PRICE_TO])/g, (this.currentUser?.position?.toLowerCase().includes('sale') ?
          higherAskingPriceForSale.toString() : higherAskingPrice.toString()))
        .replace(/(\[RENT_PRICE_FROM])/g, (lowerRentPrice.toString()))
        .replace(/(\[RENT_PRICE_TO])/g, (higherRentPrice.toString()))
        .replace(/(\[AGENT_NAME])/g, this.currentUser?.name)
        .replace(/(\[AGENT_TITLE])/g, agentInfo)
        .replace(/(\[AGENT_PHONE])/g, this.currentUser?.mobile ? this.currentUser?.mobile : '');
      if (this.deviceDetection.isIOS()) {
        href = 'sms://open?addresses=' + phone + '&body=' + encodeURIComponent(body);
      }
      if (this.deviceDetection.isAndroid()) {
        href = 'sms:' + phone + '?body=' + encodeURIComponent(body);
      }
      return this.sanitizer.bypassSecurityTrustUrl(href);
    } else {
      if (this.deviceDetection.isAndroid()) {
        href = 'sms:' + phone + '?body=' + encodeURIComponent(this.selectedTemplate?.message);
      } else {
        href = 'sms://open?addresses=' + phone + '&body=' + encodeURIComponent(this.selectedTemplate?.message);
      }
      return this.sanitizer.bypassSecurityTrustUrl(href);
    }
  }
}
