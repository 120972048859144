import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SecuredDirective} from './secured-directive';
import {PermissionCheckDirective} from './permission-check-directive';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    SecuredDirective,
    PermissionCheckDirective,
  ],
  exports: [
    SecuredDirective,
    PermissionCheckDirective,
  ],
})
export class DirectivesModule {
}
