export class Constant {

  static PAGE_SIZE = 50;

  static CLIENT_CATEGORY_LIST = 'CLIENT_CATEGORY_LIST';
  static CUSTOMER_CATEGORY_LIST = 'CUSTOMER_CATEGORY_LIST';
  static ADMIN_NUMBER = '0467353918';

  static STATUS = [
    'ACTIVE',
    'DEACTIVE',
  ];

  static CATEGORY_TYPE = [
    'BUYER',
    'PROSPECT',
    'BOTH',
  ];

  static TEMPLATE_TYPE = [
    'SMS',
    'EMAIL',
    'TRAINING',
  ];

  static ACTIVE_TYPE = [
    'ACTIVE',
    'INACTIVE',
    'BOTH',
  ];

  static LS_CURRENT_USER_KEY = 'currentUser';
  static LS_ACCESS_TOKEN_KEY = 'accessToken';
  static LS_AUTHORITIES_KEY = 'authorities';
  static LS_ASSIGNED_CITIES = 'assignedCities';
  static LS_ASSIGNED_SUBURB = 'assignedSuburb';
  static LS_USER_DAILY_GOAL = 'userDailyGoal';

  static CITY = 'cities';
  static STREET = 'streets';
  static DISTRICT = 'districts';

  static TASK_STATUS = {
    DONE: 'Done',
    PENDING: 'Pending',
    NOT_COMPLETED: 'Not completed',
    BLOCKED: 'Blocked',
    CANCEL: 'Cancel',
    IN_PROGRESS: 'Inprogress',
  };

  static CONDITION_TYPE = {
    AND: 'AND',
    OR: 'OR',
  };

  static CUSTOMER_RELATIONSHIP = {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
  };

  static CUSTOMER_MOTIVATE = {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
  };

  static CUSTOMER_MOTIVATE_TYPE = {
    FINANCIAL_UP: 'F+',
    FINANCIAL_DOWN: 'F-',
    LIFE_STYLE_UP: 'SL+',
    LIFE_STYLE_DOWN: 'SL-',
  };

  static PROPERTY_TYPE = {
    LAND_ONLY: 'L',
    UNIT: 'U',
    VILLA: 'V',
    PENTHOUSE: 'P',
    TOWN_HOUSE: 'T',
    HOUSE: 'H',
    HOUSE_GF: 'HGF',
    DUPLEX: 'D',
    HOUSE_COM: 'HC',
    COMMERCIAL: 'COM',
    OTHER: 'O',
  };

  static BUYER_TYPE = [
    {
      key: 'UNIT',
      value: 'UNIT',
    },
    {
      key: 'VILLA',
      value: 'VILLA',
    },
    {
      key: 'TOWN_HOUSE',
      value: 'T.house',
    },
    {
      key: 'PENTHOUSE',
      value: 'P.house',
    },
    {
      key: 'LAND_ONLY',
      value: 'Land',
    },
    {
      key: 'DUPLEX',
      value: 'D.house',
    },
    {
      key: 'HOUSE',
      value: 'House',
    },
    {
      key: 'HOUSE_GF',
      value: 'House GF',
    },
    {
      key: 'R3',
      value: 'R3',
    },
    {
      key: 'TEN_B',
      value: '10 B',
    },
  ];

  static PRICE_TYPE = [
    {
      key: 350000,
      value: '350-400k',
    },
    {
      key: 400000,
      value: '400-450k',
    },
    {
      key: 450000,
      value: '450-500k',
    },
    {
      key: 500000,
      value: '500-550k',
    },
    {
      key: 550000,
      value: '550-600k',
    },
    {
      key: 600000,
      value: '600-650k',
    },
    {
      key: 650000,
      value: '650-700k',
    },
    {
      key: 700000,
      value: '700-750k',
    },
    {
      key: 750000,
      value: '750-800k',
    },
    {
      key: 800000,
      value: '800-850k',
    },
    {
      key: 850000,
      value: '850-900k',
    },
    {
      key: 900000,
      value: '900-950k',
    },
    {
      key: 950000,
      value: '950-1M',
    },
    {
      key: 1000000,
      value: '1-1.2M',
    },
    {
      key: 1200000,
      value: '1.2-1.5M',
    },
    {
      key: 1500000,
      value: '1.5-2M',
    },
    {
      key: 2000000,
      value: '2-3M',
    },
    {
      key: 3000000,
      value: '3-5M',
    },
    {
      key: 5000000,
      value: '5-10M',
    },
    {
      key: 10000000,
      value: '10M+',
    },

    {
      key: 0,
      value: 'Unknown',
    },
  ];

  static PROPERTY_DEFAULT_SPEC = {
    LAND_ONLY: {
      bed: 0,
      bath: 0,
      car: 0,
      land: 500,
    },
    UNIT: {
      bed: 2,
      bath: 1,
      car: 1,
      land: 0,
    },
    VILLA: {
      bed: 2,
      bath: 1,
      car: 1,
      land: 0,
    },
    TOWN_HOUSE: {
      bed: 3,
      bath: 1,
      car: 1,
      land: 0,
    },
    DUPLEX: {
      bed: 3,
      bath: 1,
      car: 1,
      land: 300,
    },
    HOUSE: {
      bed: 3,
      bath: 1,
      car: 1,
      land: 500,
    },
    HOUSE_GF: {
      bed: 3,
      bath: 1,
      car: 1,
      land: 650,
    },
    PENTHOUSE: {
      bed: 3,
      bath: 1,
      car: 1,
      land: 0,
    },
    HOUSE_COM: {
      bed: 0,
      bath: 0,
      car: 0,
      land: 0,
    },
    COMMERCIAL: {
      bed: 3,
      bath: 1,
      car: 1,
      land: 1,
    },
    OTHER: {
      bed: 3,
      bath: 1,
      car: 1,
      land: 1,
    },
  };

  static DEFAULT_CURRENCY_ICON = '$';

  static SEARCH_REQUEST_DATA_TYPE = {
    STRING: 'STRING',
    LONG: 'LONG',
    DOUBLE: 'DOUBLE',
    TIMESTAMP: 'TIMESTAMP',
  };


  static PATH = {
    PART1: 'THE PATH OF DUTY',
    PART2: 'THE PATH OF KNOWLEDGE',
    PART3: 'THE PATH OF DEVOTION',
  };

  static GOAL = {
    YESTERDAY: 'Summary of yesterday ',
    TODAY: 'Goal for today ',
    WEEK: 'Goal for this week ',
    MONTH: 'Goal for this month ',
    QUARTER: 'Goal for this quarter ',
    YEAR: 'Goal for this year ',
    YEAR3: 'Goal for the next 3 years ',
    YEAR5: 'Goal for the next 5 years ',
    YEAR10: 'Goal for the next 10 years ',
  };
  static WEEK = [
    'Wednesday ',
    'Thursday ',
    'Friday ',
    'Saturday ',
    'Sunday ',
    'Monday ',
    'Tuesday ',
  ];

  static MONTH = [
    'Jan ',
    'Feb ',
    'Mar ',
    'Apr ',
    'May ',
    'Jun ',
    'Jul ',
    'Aug ',
    'Sep ',
    'Oct ',
    'Nov ',
    'Dec ',
  ];
  static QUARTER = [
    '1st Quarter, Jan-Mar (W1-W13) ',
    '2nd Quarter, Apr-Jun (W14-W26) ',
    '3th Quarter, Jul-Sep (W27-W39) ',
    '4th Quarter, Oct-Dec (W40-W52) ',
  ];


  static MIN_PRICE = [
    {
      key: 350000,
      value: '350k',
    },
    {
      key: 400000,
      value: '400k',
    },
    {
      key: 450000,
      value: '450k',
    },
    {
      key: 500000,
      value: '500k',
    },
    {
      key: 550000,
      value: '550k',
    },
    {
      key: 600000,
      value: '600k',
    },
    {
      key: 650000,
      value: '650k',
    },
    {
      key: 700000,
      value: '700k',
    },
    {
      key: 750000,
      value: '750k',
    },
    {
      key: 800000,
      value: '800k',
    },
    {
      key: 850000,
      value: '850k',
    },
    {
      key: 900000,
      value: '900k',
    },
    {
      key: 950000,
      value: '950k',
    },
    {
      key: 1000000,
      value: '1M',
    },
    {
      key: 1200000,
      value: '1.2M',
    },
    {
      key: 1500000,
      value: '1.5M',
    },
    {
      key: 2000000,
      value: '2M',
    },
    {
      key: 3000000,
      value: '3M',
    },
    {
      key: 5000000,
      value: '5M',
    },
    {
      key: 10000000,
      value: '10M+',
    },

    {
      key: 0,
      value: 'Unknown',
    },
  ];

  static RENTAL_MIN_PRICE = [
    {
      key: 200,
      value: '200pw',
    },
    {
      key: 250,
      value: '250pw',
    },
    {
      key: 300,
      value: '300pw',
    },
    {
      key: 350,
      value: '350pw',
    },
    {
      key: 400,
      value: '400pw',
    },
    {
      key: 450,
      value: '450pw',
    },
    {
      key: 500,
      value: '500pw',
    },
    {
      key: 550,
      value: '550pw',
    },
    {
      key: 600,
      value: '600pw',
    },
    {
      key: 650,
      value: '650pw',
    },
    {
      key: 700,
      value: '700pw',
    },
    {
      key: 750,
      value: '750pw',
    },
    {
      key: 800,
      value: '800pw',
    },
    {
      key: 0,
      value: 'Unknown',
    },
  ];

  static RELIGIONS = [
    {
      'code': 'Christianity',
      'name': 'Christianity',
    },
    {
      'code': 'Islam',
      'name': 'Islam',
    },
    {
      'code': 'Nonreligious/Atheist',
      'name': 'Nonreligious/Atheist',
    },
    {
      'code': 'Hinduism',
      'name': 'Hinduism',
    },
    {
      'code': 'Buddhism',
      'name': 'Buddhism',
    },
    {
      'code': 'Other',
      'name': 'Other',
    },
  ];

  static LANGUAGES = [
    {
      'code': 'ab',
      'name': 'Abkhaz',
    },
    {
      'code': 'aa',
      'name': 'Afar',
    },
    {
      'code': 'af',
      'name': 'Afrikaans',
    },
    {
      'code': 'ak',
      'name': 'Akan',
    },
    {
      'code': 'sq',
      'name': 'Albanian',
    },
    {
      'code': 'am',
      'name': 'Amharic',
    },
    {
      'code': 'ar',
      'name': 'Arabic',
    },
    {
      'code': 'an',
      'name': 'Aragonese',
    },
    {
      'code': 'hy',
      'name': 'Armenian',
    },
    {
      'code': 'as',
      'name': 'Assamese',
    },
    {
      'code': 'av',
      'name': 'Avaric',
    },
    {
      'code': 'ae',
      'name': 'Avestan',
    },
    {
      'code': 'ay',
      'name': 'Aymara',
    },
    {
      'code': 'az',
      'name': 'Azerbaijani',
    },
    {
      'code': 'bm',
      'name': 'Bambara',
    },
    {
      'code': 'ba',
      'name': 'Bashkir',
    },
    {
      'code': 'eu',
      'name': 'Basque',
    },
    {
      'code': 'be',
      'name': 'Belarusian',
    },
    {
      'code': 'bn',
      'name': 'Bengali; Bangla',
    },
    {
      'code': 'bh',
      'name': 'Bihari',
    },
    {
      'code': 'bi',
      'name': 'Bislama',
    },
    {
      'code': 'bs',
      'name': 'Bosnian',
    },
    {
      'code': 'br',
      'name': 'Breton',
    },
    {
      'code': 'bg',
      'name': 'Bulgarian',
    },
    {
      'code': 'my',
      'name': 'Burmese',
    },
    {
      'code': 'ca',
      'name': 'Catalan; Valencian',
    },
    {
      'code': 'ch',
      'name': 'Chamorro',
    },
    {
      'code': 'ce',
      'name': 'Chechen',
    },
    {
      'code': 'ny',
      'name': 'Chichewa; Chewa; Nyanja',
    },
    {
      'code': 'zh',
      'name': 'Chinese',
    },
    {
      'code': 'cv',
      'name': 'Chuvash',
    },
    {
      'code': 'kw',
      'name': 'Cornish',
    },
    {
      'code': 'co',
      'name': 'Corsican',
    },
    {
      'code': 'cr',
      'name': 'Cree',
    },
    {
      'code': 'hr',
      'name': 'Croatian',
    },
    {
      'code': 'cs',
      'name': 'Czech',
    },
    {
      'code': 'da',
      'name': 'Danish',
    },
    {
      'code': 'dv',
      'name': 'Divehi; Dhivehi; Maldivian;',
    },
    {
      'code': 'nl',
      'name': 'Dutch',
    },
    {
      'code': 'dz',
      'name': 'Dzongkha',
    },
    {
      'code': 'en',
      'name': 'English',
    },
    {
      'code': 'eo',
      'name': 'Esperanto',
    },
    {
      'code': 'et',
      'name': 'Estonian',
    },
    {
      'code': 'ee',
      'name': 'Ewe',
    },
    {
      'code': 'fo',
      'name': 'Faroese',
    },
    {
      'code': 'fj',
      'name': 'Fijian',
    },
    {
      'code': 'fi',
      'name': 'Finnish',
    },
    {
      'code': 'fr',
      'name': 'French',
    },
    {
      'code': 'ff',
      'name': 'Fula; Fulah; Pulaar; Pular',
    },
    {
      'code': 'gl',
      'name': 'Galician',
    },
    {
      'code': 'ka',
      'name': 'Georgian',
    },
    {
      'code': 'de',
      'name': 'German',
    },
    {
      'code': 'el',
      'name': 'Greek, Modern',
    },
    {
      'code': 'gn',
      'name': 'GuaranÃ­',
    },
    {
      'code': 'gu',
      'name': 'Gujarati',
    },
    {
      'code': 'ht',
      'name': 'Haitian; Haitian Creole',
    },
    {
      'code': 'ha',
      'name': 'Hausa',
    },
    {
      'code': 'he',
      'name': 'Hebrew (modern)',
    },
    {
      'code': 'hz',
      'name': 'Herero',
    },
    {
      'code': 'hi',
      'name': 'Hindi',
    },
    {
      'code': 'ho',
      'name': 'Hiri Motu',
    },
    {
      'code': 'hu',
      'name': 'Hungarian',
    },
    {
      'code': 'ia',
      'name': 'Interlingua',
    },
    {
      'code': 'id',
      'name': 'Indonesian',
    },
    {
      'code': 'ie',
      'name': 'Interlingue',
    },
    {
      'code': 'ga',
      'name': 'Irish',
    },
    {
      'code': 'ig',
      'name': 'Igbo',
    },
    {
      'code': 'ik',
      'name': 'Inupiaq',
    },
    {
      'code': 'io',
      'name': 'Ido',
    },
    {
      'code': 'is',
      'name': 'Icelandic',
    },
    {
      'code': 'it',
      'name': 'Italian',
    },
    {
      'code': 'iu',
      'name': 'Inuktitut',
    },
    {
      'code': 'ja',
      'name': 'Japanese',
    },
    {
      'code': 'jv',
      'name': 'Javanese',
    },
    {
      'code': 'kl',
      'name': 'Kalaallisut, Greenlandic',
    },
    {
      'code': 'kn',
      'name': 'Kannada',
    },
    {
      'code': 'kr',
      'name': 'Kanuri',
    },
    {
      'code': 'ks',
      'name': 'Kashmiri',
    },
    {
      'code': 'kk',
      'name': 'Kazakh',
    },
    {
      'code': 'km',
      'name': 'Khmer',
    },
    {
      'code': 'ki',
      'name': 'Kikuyu, Gikuyu',
    },
    {
      'code': 'rw',
      'name': 'Kinyarwanda',
    },
    {
      'code': 'ky',
      'name': 'Kyrgyz',
    },
    {
      'code': 'kv',
      'name': 'Komi',
    },
    {
      'code': 'kg',
      'name': 'Kongo',
    },
    {
      'code': 'ko',
      'name': 'Korean',
    },
    {
      'code': 'ku',
      'name': 'Kurdish',
    },
    {
      'code': 'kj',
      'name': 'Kwanyama, Kuanyama',
    },
    {
      'code': 'la',
      'name': 'Latin',
    },
    {
      'code': 'lb',
      'name': 'Luxembourgish, Letzeburgesch',
    },
    {
      'code': 'lg',
      'name': 'Ganda',
    },
    {
      'code': 'li',
      'name': 'Limburgish, Limburgan, Limburger',
    },
    {
      'code': 'ln',
      'name': 'Lingala',
    },
    {
      'code': 'lo',
      'name': 'Lao',
    },
    {
      'code': 'lt',
      'name': 'Lithuanian',
    },
    {
      'code': 'lu',
      'name': 'Luba-Katanga',
    },
    {
      'code': 'lv',
      'name': 'Latvian',
    },
    {
      'code': 'gv',
      'name': 'Manx',
    },
    {
      'code': 'mk',
      'name': 'Macedonian',
    },
    {
      'code': 'mg',
      'name': 'Malagasy',
    },
    {
      'code': 'ms',
      'name': 'Malay',
    },
    {
      'code': 'ml',
      'name': 'Malayalam',
    },
    {
      'code': 'mt',
      'name': 'Maltese',
    },
    {
      'code': 'mi',
      'name': 'MÄori',
    },
    {
      'code': 'mr',
      'name': 'Marathi (MarÄá¹­hÄ«)',
    },
    {
      'code': 'mh',
      'name': 'Marshallese',
    },
    {
      'code': 'mn',
      'name': 'Mongolian',
    },
    {
      'code': 'na',
      'name': 'Nauru',
    },
    {
      'code': 'nv',
      'name': 'Navajo, Navaho',
    },
    {
      'code': 'nb',
      'name': 'Norwegian BokmÃ¥l',
    },
    {
      'code': 'nd',
      'name': 'North Ndebele',
    },
    {
      'code': 'ne',
      'name': 'Nepali',
    },
    {
      'code': 'ng',
      'name': 'Ndonga',
    },
    {
      'code': 'nn',
      'name': 'Norwegian Nynorsk',
    },
    {
      'code': 'no',
      'name': 'Norwegian',
    },
    {
      'code': 'ii',
      'name': 'Nuosu',
    },
    {
      'code': 'nr',
      'name': 'South Ndebele',
    },
    {
      'code': 'oc',
      'name': 'Occitan',
    },
    {
      'code': 'oj',
      'name': 'Ojibwe, Ojibwa',
    },
    {
      'code': 'cu',
      'name': 'Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic',
    },
    {
      'code': 'om',
      'name': 'Oromo',
    },
    {
      'code': 'or',
      'name': 'Oriya',
    },
    {
      'code': 'os',
      'name': 'Ossetian, Ossetic',
    },
    {
      'code': 'pa',
      'name': 'Panjabi, Punjabi',
    },
    {
      'code': 'pi',
      'name': 'PÄli',
    },
    {
      'code': 'fa',
      'name': 'Persian (Farsi)',
    },
    {
      'code': 'pl',
      'name': 'Polish',
    },
    {
      'code': 'ps',
      'name': 'Pashto, Pushto',
    },
    {
      'code': 'pt',
      'name': 'Portuguese',
    },
    {
      'code': 'qu',
      'name': 'Quechua',
    },
    {
      'code': 'rm',
      'name': 'Romansh',
    },
    {
      'code': 'rn',
      'name': 'Kirundi',
    },
    {
      'code': 'ro',
      'name': 'Romanian, [])',
    },
    {
      'code': 'ru',
      'name': 'Russian',
    },
    {
      'code': 'sa',
      'name': 'Sanskrit (Saá¹ská¹›ta)',
    },
    {
      'code': 'sc',
      'name': 'Sardinian',
    },
    {
      'code': 'sd',
      'name': 'Sindhi',
    },
    {
      'code': 'se',
      'name': 'Northern Sami',
    },
    {
      'code': 'sm',
      'name': 'Samoan',
    },
    {
      'code': 'sg',
      'name': 'Sango',
    },
    {
      'code': 'sr',
      'name': 'Serbian',
    },
    {
      'code': 'gd',
      'name': 'Scottish Gaelic; Gaelic',
    },
    {
      'code': 'sn',
      'name': 'Shona',
    },
    {
      'code': 'si',
      'name': 'Sinhala, Sinhalese',
    },
    {
      'code': 'sk',
      'name': 'Slovak',
    },
    {
      'code': 'sl',
      'name': 'Slovene',
    },
    {
      'code': 'so',
      'name': 'Somali',
    },
    {
      'code': 'st',
      'name': 'Southern Sotho',
    },
    {
      'code': 'az',
      'name': 'South Azerbaijani',
    },
    {
      'code': 'es',
      'name': 'Spanish; Castilian',
    },
    {
      'code': 'su',
      'name': 'Sundanese',
    },
    {
      'code': 'sw',
      'name': 'Swahili',
    },
    {
      'code': 'ss',
      'name': 'Swati',
    },
    {
      'code': 'sv',
      'name': 'Swedish',
    },
    {
      'code': 'ta',
      'name': 'Tamil',
    },
    {
      'code': 'te',
      'name': 'Telugu',
    },
    {
      'code': 'tg',
      'name': 'Tajik',
    },
    {
      'code': 'th',
      'name': 'Thai',
    },
    {
      'code': 'ti',
      'name': 'Tigrinya',
    },
    {
      'code': 'bo',
      'name': 'Tibetan Standard, Tibetan, Central',
    },
    {
      'code': 'tk',
      'name': 'Turkmen',
    },
    {
      'code': 'tl',
      'name': 'Tagalog',
    },
    {
      'code': 'tn',
      'name': 'Tswana',
    },
    {
      'code': 'to',
      'name': 'Tonga (Tonga Islands)',
    },
    {
      'code': 'tr',
      'name': 'Turkish',
    },
    {
      'code': 'ts',
      'name': 'Tsonga',
    },
    {
      'code': 'tt',
      'name': 'Tatar',
    },
    {
      'code': 'tw',
      'name': 'Twi',
    },
    {
      'code': 'ty',
      'name': 'Tahitian',
    },
    {
      'code': 'ug',
      'name': 'Uyghur, Uighur',
    },
    {
      'code': 'uk',
      'name': 'Ukrainian',
    },
    {
      'code': 'ur',
      'name': 'Urdu',
    },
    {
      'code': 'uz',
      'name': 'Uzbek',
    },
    {
      'code': 've',
      'name': 'Venda',
    },
    {
      'code': 'vi',
      'name': 'Vietnamese',
    },
    {
      'code': 'vo',
      'name': 'VolapÃ¼k',
    },
    {
      'code': 'wa',
      'name': 'Walloon',
    },
    {
      'code': 'cy',
      'name': 'Welsh',
    },
    {
      'code': 'wo',
      'name': 'Wolof',
    },
    {
      'code': 'fy',
      'name': 'Western Frisian',
    },
    {
      'code': 'xh',
      'name': 'Xhosa',
    },
    {
      'code': 'yi',
      'name': 'Yiddish',
    },
    {
      'code': 'yo',
      'name': 'Yoruba',
    },
    {
      'code': 'za',
      'name': 'Zhuang, Chuang',
    },
    {
      'code': 'zu',
      'name': 'Zulu',
    },
  ];

  static COUNTRIES = [{
    'country_name': 'Afghanistan',
    'country_code': '93',
    'lang_name': 'pashto',
    'country_code_name': 'af',
    'lang_code': 'ps',
  }, {
    'country_name': 'Albania',
    'country_code': '355',
    'lang_name': 'albanian',
    'country_code_name': 'al',
    'lang_code': 'sq',
  }, {
    'country_name': 'Algeria',
    'country_code': '213',
    'lang_name': 'tamazight (latin)',
    'country_code_name': 'dz',
    'lang_code': 'tzm',
  }, {
    'country_code': '1684',
    'country_code_name': 'as',
    'country_name': 'American Samoa',
  }, {
    'country_code': '376',
    'country_code_name': 'ad',
    'country_name': 'Andorra',
  }, {
    'country_code': '244',
    'country_code_name': 'ao',
    'country_name': 'Angola',
  }, {
    'country_code': '1264',
    'country_code_name': 'ai',
    'country_name': 'Anguilla',
  }, {
    'country_code': '1268',
    'country_code_name': 'ag',
    'country_name': 'Antigua and Barbuda',
  }, {
    'country_name': 'Argentina',
    'country_code': '54',
    'lang_name': 'spanish',
    'country_code_name': 'ar',
    'lang_code': 'es',
  }, {
    'country_name': 'Armenia',
    'country_code': '374',
    'lang_name': 'armenian',
    'country_code_name': 'am',
    'lang_code': 'hy',
  }, {
    'country_code': '297',
    'country_code_name': 'aw',
    'country_name': 'Aruba',
  }, {
    'country_name': 'Australia',
    'country_code': '61',
    'lang_name': 'english',
    'country_code_name': 'au',
    'lang_code': 'en',
  }, {
    'country_name': 'Austria',
    'country_code': '43',
    'lang_name': 'german',
    'country_code_name': 'at',
    'lang_code': 'de',
  }, {
    'country_name': 'Azerbaijan',
    'country_code': '994',
    'lang_name': 'azeri (latin)',
    'country_code_name': 'az',
    'lang_code': 'az',
  }, {
    'country_code': '1242',
    'country_code_name': 'bs',
    'country_name': 'Bahamas',
  }, {
    'country_name': 'Bahrain',
    'country_code': '973',
    'lang_name': 'arabic',
    'country_code_name': 'bh',
    'lang_code': 'ar',
  }, {
    'country_name': 'Bangladesh',
    'country_code': '880',
    'lang_name': 'bengali',
    'country_code_name': 'bd',
    'lang_code': 'bn',
  }, {
    'country_code': '1246',
    'country_code_name': 'bb',
    'country_name': 'Barbados',
  }, {
    'country_name': 'Belarus',
    'country_code': '375',
    'lang_name': 'belarusian',
    'country_code_name': 'by',
    'lang_code': 'be',
  }, {
    'country_name': 'Belgium',
    'country_code': '32',
    'lang_name': 'french',
    'country_code_name': 'be',
    'lang_code': 'fr',
  }, {
    'country_name': 'Belize',
    'country_code': '501',
    'lang_name': 'english',
    'country_code_name': 'bz',
    'lang_code': 'en',
  }, {
    'country_code': '229',
    'country_code_name': 'bj',
    'country_name': 'Benin',
  }, {
    'country_code': '1441',
    'country_code_name': 'bm',
    'country_name': 'Bermuda',
  }, {
    'country_code': '975',
    'country_code_name': 'bt',
    'country_name': 'Bhutan',
  }, {
    'country_name': 'Bolivia',
    'country_code': '591',
    'lang_name': 'spanish',
    'country_code_name': 'bo',
    'lang_code': 'es',
  }, {
    'country_name': 'Bosnia and Herzegovina',
    'country_code': '387',
    'lang_name': 'serbian (latin)',
    'country_code_name': 'ba',
    'lang_code': 'sr',
  }, {
    'country_code': '267',
    'country_code_name': 'bw',
    'country_name': 'Botswana',
  }, {
    'country_name': 'Brazil',
    'country_code': '55',
    'lang_name': 'portuguese',
    'country_code_name': 'br',
    'lang_code': 'pt',
  }, {
    'country_code': '246',
    'country_code_name': 'io',
    'country_name': 'British Indian Ocean Territory',
  }, {
    'country_code': '1284',
    'country_code_name': 'vg',
    'country_name': 'British Virgin Islands',
  }, {
    'country_name': 'Brunei',
    'country_code': '673',
    'lang_name': 'malay',
    'country_code_name': 'bn',
    'lang_code': 'ms',
  }, {
    'country_name': 'Bulgaria',
    'country_code': '359',
    'lang_name': 'bulgarian',
    'country_code_name': 'bg',
    'lang_code': 'bg',
  }, {
    'country_code': '226',
    'country_code_name': 'bf',
    'country_name': 'Burkina Faso',
  }, {
    'country_code': '95',
    'country_code_name': 'mm',
    'country_name': 'Burma-Myanmar',
  }, {
    'country_code': '257',
    'country_code_name': 'bi',
    'country_name': 'Burundi',
  }, {
    'country_name': 'Cambodia',
    'country_code': '855',
    'lang_name': 'khmer',
    'country_code_name': 'kh',
    'lang_code': 'km',
  }, {
    'country_code': '237',
    'country_code_name': 'cm',
    'country_name': 'Cameroon',
  }, {
    'country_name': 'Canada',
    'country_code': '1',
    'lang_name': 'mohawk',
    'country_code_name': 'ca',
    'lang_code': 'moh',
  }, {
    'country_code': '238',
    'country_code_name': 'cv',
    'country_name': 'Cape Verde',
  }, {
    'country_code': '1345',
    'country_code_name': 'ky',
    'country_name': 'Cayman Islands',
  }, {
    'country_code': '236',
    'country_code_name': 'cf',
    'country_name': 'Central African Republic',
  }, {
    'country_code': '235',
    'country_code_name': 'td',
    'country_name': 'Chad',
  }, {
    'country_name': 'Chile',
    'country_code': '56',
    'lang_name': 'spanish',
    'country_code_name': 'cl',
    'lang_code': 'es',
  }, {
    'country_name': 'China',
    'country_code': '86',
    'lang_name': 'yi',
    'country_code_name': 'cn',
    'lang_code': 'ii',
  }, {
    'country_code': '6189',
    'country_code_name': 'cx',
    'country_name': 'Christmas Island',
  }, {
    'country_name': 'Colombia',
    'country_code': '57',
    'lang_name': 'spanish',
    'country_code_name': 'co',
    'lang_code': 'es',
  }, {
    'country_code': '269',
    'country_code_name': 'km',
    'country_name': 'Comoros',
  }, {
    'country_code': '242',
    'country_code_name': 'cg',
    'country_name': 'Congo',
  }, {
    'country_code': '243',
    'country_code_name': 'cd',
    'country_name': 'Congo (The Democratic Republic)',
  }, {
    'country_code': '682',
    'country_code_name': 'ck',
    'country_name': 'Cook Islands',
  }, {
    'country_name': 'Costa Rica',
    'country_code': '506',
    'lang_name': 'spanish',
    'country_code_name': 'cr',
    'lang_code': 'es',
  }, {
    'country_name': 'Croatia',
    'country_code': '385',
    'lang_name': 'croatian',
    'country_code_name': 'hr',
    'lang_code': 'hr',
  }, {
    'country_code': '53',
    'country_code_name': 'cu',
    'country_name': 'Cuba',
  }, {
    'country_code': '357',
    'country_code_name': 'cy',
    'country_name': 'Cyprus',
  }, {
    'country_name': 'Czech Republic',
    'country_code': '420',
    'lang_name': 'czech',
    'country_code_name': 'cz',
    'lang_code': 'cs',
  }, {
    'country_name': 'Denmark',
    'country_code': '45',
    'lang_name': 'danish',
    'country_code_name': 'dk',
    'lang_code': 'da',
  }, {
    'country_code': '253',
    'country_code_name': 'dj',
    'country_name': 'Djibouti',
  }, {
    'country_code': '1767',
    'country_code_name': 'dm',
    'country_name': 'Dominica',
  }, {
    'country_name': 'Dominican Republic',
    'country_code': '1849',
    'lang_name': 'spanish',
    'country_code_name': 'do',
    'lang_code': 'es',
  }, {
    'country_name': 'Dominican Republic',
    'country_code': '1829',
    'lang_name': 'spanish',
    'country_code_name': 'do',
    'lang_code': 'es',
  }, {
    'country_name': 'Dominican Republic',
    'country_code': '1809',
    'lang_name': 'spanish',
    'country_code_name': 'do',
    'lang_code': 'es',
  }, {
    'country_code': '670',
    'country_code_name': 'tl',
    'country_name': 'East Timor',
  }, {
    'country_name': 'Ecuador',
    'country_code': '593',
    'lang_name': 'spanish',
    'country_code_name': 'ec',
    'lang_code': 'es',
  }, {
    'country_name': 'Egypt',
    'country_code': '20',
    'lang_name': 'arabic',
    'country_code_name': 'eg',
    'lang_code': 'ar',
  }, {
    'country_name': 'El Salvador',
    'country_code': '503',
    'lang_name': 'spanish',
    'country_code_name': 'sv',
    'lang_code': 'es',
  }, {
    'country_code': '240',
    'country_code_name': 'gq',
    'country_name': 'Equatorial Guinea',
  }, {
    'country_code': '291',
    'country_code_name': 'er',
    'country_name': 'Eritrea',
  }, {
    'country_name': 'Estonia',
    'country_code': '372',
    'lang_name': 'estonian',
    'country_code_name': 'ee',
    'lang_code': 'et',
  }, {
    'country_name': 'Ethiopia',
    'country_code': '251',
    'lang_name': 'amharic',
    'country_code_name': 'et',
    'lang_code': 'am',
  }, {
    'country_name': 'Faroe Islands',
    'country_code': '298',
    'lang_name': 'faroese',
    'country_code_name': 'fo',
    'lang_code': 'fo',
  }, {
    'country_code': '679',
    'country_code_name': 'fj',
    'country_name': 'Fiji',
  }, {
    'country_name': 'Finland',
    'country_code': '358',
    'lang_name': 'swedish',
    'country_code_name': 'fi',
    'lang_code': 'sv',
  }, {
    'country_name': 'France',
    'country_code': '33',
    'lang_name': 'occitan',
    'country_code_name': 'fr',
    'lang_code': 'oc',
  }, {
    'country_code': '594',
    'country_code_name': 'gf',
    'country_name': 'French Guiana',
  }, {
    'country_code': '689',
    'country_code_name': 'pf',
    'country_name': 'French Polynesia',
  }, {
    'country_code': '241',
    'country_code_name': 'ga',
    'country_name': 'Gabon',
  }, {
    'country_code': '220',
    'country_code_name': 'gm',
    'country_name': 'Gambia',
  }, {
    'country_name': 'Georgia',
    'country_code': '995',
    'lang_name': 'georgian',
    'country_code_name': 'ge',
    'lang_code': 'ka',
  }, {
    'country_name': 'Germany',
    'country_code': '49',
    'lang_name': 'upper sorbian',
    'country_code_name': 'de',
    'lang_code': 'de',
  }, {
    'country_code': '233',
    'country_code_name': 'gh',
    'country_name': 'Ghana',
  }, {
    'country_code': '350',
    'country_code_name': 'gi',
    'country_name': 'Gibraltar',
  }, {
    'country_name': 'Greece',
    'country_code': '30',
    'lang_name': 'greek',
    'country_code_name': 'gr',
    'lang_code': 'el',
  }, {
    'country_name': 'Greenland',
    'country_code': '299',
    'lang_name': 'greenlandic',
    'country_code_name': 'gl',
    'lang_code': 'kl',
  }, {
    'country_code': '1473',
    'country_code_name': 'gd',
    'country_name': 'Grenada',
  }, {
    'country_code': '590',
    'country_code_name': 'gp',
    'country_name': 'Guadeloupe',
  }, {
    'country_code': '1671',
    'country_code_name': 'gu',
    'country_name': 'Guam',
  }, {
    'country_name': 'Guatemala',
    'country_code': '502',
    'lang_name': 'spanish',
    'country_code_name': 'gt',
    'lang_code': 'es',
  }, {
    'country_code': '224',
    'country_code_name': 'gn',
    'country_name': 'Guinea',
  }, {
    'country_code': '245',
    'country_code_name': 'gw',
    'country_name': 'Guinea-Bissau',
  }, {
    'country_code': '592',
    'country_code_name': 'gy',
    'country_name': 'Guyana',
  }, {
    'country_code': '509',
    'country_code_name': 'ht',
    'country_name': 'Haiti',
  }, {
    'country_name': 'Honduras',
    'country_code': '504',
    'lang_name': 'spanish',
    'country_code_name': 'hn',
    'lang_code': 'es',
  }, {
    'country_name': 'Hong Kong',
    'country_code': '852',
    'lang_name': 'chinese (traditional) legacy',
    'country_code_name': 'hk',
    'lang_code': 'zh',
  }, {
    'country_name': 'Hungary',
    'country_code': '36',
    'lang_name': 'hungarian',
    'country_code_name': 'hu',
    'lang_code': 'hu',
  }, {
    'country_name': 'Iceland',
    'country_code': '354',
    'lang_name': 'icelandic',
    'country_code_name': 'is',
    'lang_code': 'is',
  }, {
    'country_name': 'India',
    'country_code': '91',
    'lang_name': 'telugu',
    'country_code_name': 'in',
    'lang_code': 'te',
  }, {
    'country_name': 'Indonesia',
    'country_code': '62',
    'lang_name': 'indonesian',
    'country_code_name': 'id',
    'lang_code': 'id',
  }, {
    'country_name': 'Iran',
    'country_code': '98',
    'lang_name': 'persian',
    'country_code_name': 'ir',
    'lang_code': 'fa',
  }, {
    'country_name': 'Iraq',
    'country_code': '964',
    'lang_name': 'arabic',
    'country_code_name': 'iq',
    'lang_code': 'ar',
  }, {
    'country_name': 'Ireland',
    'country_code': '353',
    'lang_name': 'irish',
    'country_code_name': 'ie',
    'lang_code': 'ga',
  }, {
    'country_name': 'Israel',
    'country_code': '972',
    'lang_name': 'hebrew',
    'country_code_name': 'il',
    'lang_code': 'he',
  }, {
    'country_name': 'Italy',
    'country_code': '39',
    'lang_name': 'italian',
    'country_code_name': 'it',
    'lang_code': 'it',
  }, {
    'country_code': '225',
    'country_code_name': 'ci',
    'country_name': 'Ivory Coast',
  }, {
    'country_name': 'Jamaica',
    'country_code': '1876',
    'lang_name': 'english',
    'country_code_name': 'jm',
    'lang_code': 'en',
  }, {
    'country_name': 'Japan',
    'country_code': '81',
    'lang_name': 'japanese',
    'country_code_name': 'jp',
    'lang_code': 'ja',
  }, {
    'country_name': 'Jordan',
    'country_code': '962',
    'lang_name': 'arabic',
    'country_code_name': 'jo',
    'lang_code': 'ar',
  }, {
    'country_name': 'Kazakhstan',
    'country_code': '7',
    'lang_name': 'kazakh',
    'country_code_name': 'kz',
    'lang_code': 'kk',
  }, {
    'country_name': 'Kenya',
    'country_code': '254',
    'lang_name': 'kiswahili',
    'country_code_name': 'ke',
    'lang_code': 'sw',
  }, {
    'country_code': '686',
    'country_code_name': 'ki',
    'country_name': 'Kiribati',
  }, {
    'country_name': 'Kuwait',
    'country_code': '965',
    'lang_name': 'arabic',
    'country_code_name': 'kw',
    'lang_code': 'ar',
  }, {
    'country_name': 'Kyrgyzstan',
    'country_code': '996',
    'lang_name': 'kyrgyz',
    'country_code_name': 'kg',
    'lang_code': 'ky',
  }, {
    'country_name': 'Laos',
    'country_code': '856',
    'lang_name': 'lao',
    'country_code_name': 'la',
    'lang_code': 'lo',
  }, {
    'country_name': 'Latvia',
    'country_code': '371',
    'lang_name': 'latvian',
    'country_code_name': 'lv',
    'lang_code': 'lv',
  }, {
    'country_name': 'Lebanon',
    'country_code': '961',
    'lang_name': 'arabic',
    'country_code_name': 'lb',
    'lang_code': 'ar',
  }, {
    'country_code': '266',
    'country_code_name': 'ls',
    'country_name': 'Lesotho',
  }, {
    'country_code': '231',
    'country_code_name': 'lr',
    'country_name': 'Liberia',
  }, {
    'country_name': 'Libya',
    'country_code': '218',
    'lang_name': 'arabic',
    'country_code_name': 'ly',
    'lang_code': 'ar',
  }, {
    'country_name': 'Liechtenstein',
    'country_code': '423',
    'lang_name': 'german',
    'country_code_name': 'li',
    'lang_code': 'de',
  }, {
    'country_name': 'Lithuania',
    'country_code': '370',
    'lang_name': 'lithuanian',
    'country_code_name': 'lt',
    'lang_code': 'lt',
  }, {
    'country_name': 'Luxembourg',
    'country_code': '352',
    'lang_name': 'luxembourgish',
    'country_code_name': 'lu',
    'lang_code': 'lb',
  }, {
    'country_name': 'Macau',
    'country_code': '853',
    'lang_name': 'chinese (traditional) legacy',
    'country_code_name': 'mo',
    'lang_code': 'zh',
  }, {
    'country_name': 'Macedonia',
    'country_code': '389',
    'lang_name': 'macedonian (fyrom)',
    'country_code_name': 'mk',
    'lang_code': 'mk',
  }, {
    'country_code': '261',
    'country_code_name': 'mg',
    'country_name': 'Madagascar',
  }, {
    'country_code': '265',
    'country_code_name': 'mw',
    'country_name': 'Malawi',
  }, {
    'country_name': 'Malaysia',
    'country_code': '60',
    'lang_name': 'malay',
    'country_code_name': 'my',
    'lang_code': 'ms',
  }, {
    'country_name': 'Maldives',
    'country_code': '960',
    'lang_name': 'divehi',
    'country_code_name': 'mv',
    'lang_code': 'dv',
  }, {
    'country_code': '223',
    'country_code_name': 'ml',
    'country_name': 'Mali',
  }, {
    'country_name': 'Malta',
    'country_code': '356',
    'lang_name': 'maltese',
    'country_code_name': 'mt',
    'lang_code': 'mt',
  }, {
    'country_code': '692',
    'country_code_name': 'mh',
    'country_name': 'Marshall Islands',
  }, {
    'country_code': '596',
    'country_code_name': 'mq',
    'country_name': 'Martinique',
  }, {
    'country_code': '222',
    'country_code_name': 'mr',
    'country_name': 'Mauritania',
  }, {
    'country_code': '230',
    'country_code_name': 'mu',
    'country_name': 'Mauritius',
  }, {
    'country_code': '262',
    'country_code_name': 'yt',
    'country_name': 'Mayotte',
  }, {
    'country_name': 'Mexico',
    'country_code': '52',
    'lang_name': 'spanish',
    'country_code_name': 'mx',
    'lang_code': 'es',
  }, {
    'country_code': '373',
    'country_code_name': 'md',
    'country_name': 'Moldova',
  }, {
    'country_name': 'Monaco',
    'country_code': '377',
    'lang_name': 'french',
    'country_code_name': 'mc',
    'lang_code': 'fr',
  }, {
    'country_name': 'Mongolia',
    'country_code': '976',
    'lang_name': 'mongolian (cyrillic)',
    'country_code_name': 'mn',
    'lang_code': 'mn',
  }, {
    'country_name': 'Montenegro',
    'country_code': '382',
    'lang_name': 'serbian (latin)',
    'country_code_name': 'me',
    'lang_code': 'sr',
  }, {
    'country_code': '1664',
    'country_code_name': 'ms',
    'country_name': 'Montserrat',
  }, {
    'country_name': 'Morocco',
    'country_code': '212',
    'lang_name': 'arabic',
    'country_code_name': 'ma',
    'lang_code': 'ar',
  }, {
    'country_code': '258',
    'country_code_name': 'mz',
    'country_name': 'Mozambique',
  }, {
    'country_code': '264',
    'country_code_name': 'na',
    'country_name': 'Namibia',
  }, {
    'country_code': '674',
    'country_code_name': 'nr',
    'country_name': 'Nauru',
  }, {
    'country_name': 'Nepal',
    'country_code': '977',
    'lang_name': 'nepali',
    'country_code_name': 'np',
    'lang_code': 'ne',
  }, {
    'country_name': 'Netherlands',
    'country_code': '31',
    'lang_name': 'frisian',
    'country_code_name': 'nl',
    'lang_code': 'fy',
  }, {
    'country_code': '599',
    'country_code_name': 'cw',
    'country_name': 'Cura\u00e7ao',
  }, {
    'country_code': '687',
    'country_code_name': 'nc',
    'country_name': 'New Caledonia',
  }, {
    'country_name': 'New Zealand',
    'country_code': '64',
    'lang_name': 'maori',
    'country_code_name': 'nz',
    'lang_code': 'mi',
  }, {
    'country_name': 'Nicaragua',
    'country_code': '505',
    'lang_name': 'spanish',
    'country_code_name': 'ni',
    'lang_code': 'es',
  }, {
    'country_code': '227',
    'country_code_name': 'ne',
    'country_name': 'Niger',
  }, {
    'country_name': 'Nigeria',
    'country_code': '234',
    'lang_name': 'yoruba',
    'country_code_name': 'ng',
    'lang_code': 'yo',
  }, {
    'country_code': '683',
    'country_code_name': 'nu',
    'country_name': 'Niue',
  }, {
    'country_code': '672',
    'country_code_name': 'nf',
    'country_name': 'Norfolk Island',
  }, {
    'country_code': '1670',
    'country_code_name': 'mp',
    'country_name': 'Northern Mariana Islands',
  }, {
    'country_code': '850',
    'country_code_name': 'kp',
    'country_name': 'North Korea',
  }, {
    'country_name': 'Norway',
    'country_code': '47',
    'lang_name': 'sami (southern)',
    'country_code_name': 'no',
    'lang_code': 'sma',
  }, {
    'country_name': 'Oman',
    'country_code': '968',
    'lang_name': 'arabic',
    'country_code_name': 'om',
    'lang_code': 'ar',
  }, {
    'country_name': 'Pakistan',
    'country_code': '92',
    'lang_name': 'urdu',
    'country_code_name': 'pk',
    'lang_code': 'ur',
  }, {
    'country_code': '680',
    'country_code_name': 'pw',
    'country_name': 'Palau',
  }, {
    'country_code': '970',
    'country_code_name': 'ps',
    'country_name': 'Palestine',
  }, {
    'country_name': 'Panama',
    'country_code': '507',
    'lang_name': 'spanish',
    'country_code_name': 'pa',
    'lang_code': 'es',
  }, {
    'country_code': '675',
    'country_code_name': 'pg',
    'country_name': 'Papua New Guinea',
  }, {
    'country_name': 'Paraguay',
    'country_code': '595',
    'lang_name': 'spanish',
    'country_code_name': 'py',
    'lang_code': 'es',
  }, {
    'country_name': 'Peru',
    'country_code': '51',
    'lang_name': 'spanish',
    'country_code_name': 'pe',
    'lang_code': 'es',
  }, {
    'country_name': 'Philippines',
    'country_code': '63',
    'lang_name': 'english',
    'country_code_name': 'ph',
    'lang_code': 'en',
  }, {
    'country_code': '870',
    'country_code_name': 'pn',
    'country_name': 'Pitcairn Islands',
  }, {
    'country_name': 'Poland',
    'country_code': '48',
    'lang_name': 'polish',
    'country_code_name': 'pl',
    'lang_code': 'pl',
  }, {
    'country_name': 'Portugal',
    'country_code': '351',
    'lang_name': 'portuguese',
    'country_code_name': 'pt',
    'lang_code': 'pt',
  }, {
    'country_name': 'Puerto Rico',
    'country_code': '1787',
    'lang_name': 'spanish',
    'country_code_name': 'pr',
    'lang_code': 'es',
  }, {
    'country_name': 'Qatar',
    'country_code': '974',
    'lang_name': 'arabic',
    'country_code_name': 'qa',
    'lang_code': 'ar',
  }, {
    'country_code': '262',
    'country_code_name': 're',
    'country_name': 'R\u00e9union',
  }, {
    'country_name': 'Romania',
    'country_code': '40',
    'lang_name': 'romanian',
    'country_code_name': 'ro',
    'lang_code': 'ro',
  }, {
    'country_name': 'Russia',
    'country_code': '7',
    'lang_name': 'yakut',
    'country_code_name': 'ru',
    'lang_code': 'sah',
  }, {
    'country_name': 'Rwanda',
    'country_code': '250',
    'lang_name': 'kinyarwanda',
    'country_code_name': 'rw',
    'lang_code': 'rw',
  }, {
    'country_code': '290',
    'country_code_name': 'sh',
    'country_name': 'Saint Helena',
  }, {
    'country_code': '1869',
    'country_code_name': 'kn',
    'country_name': 'Saint Kitts and Nevis',
  }, {
    'country_code': '1758',
    'country_code_name': 'lc',
    'country_name': 'Saint Lucia',
  }, {
    'country_code': '1599',
    'country_code_name': 'mf',
    'country_name': 'Saint Martin',
  }, {
    'country_code': '508',
    'country_code_name': 'pm',
    'country_name': 'Saint Pierre and Miquelon',
  }, {
    'country_code': '1784',
    'country_code_name': 'vc',
    'country_name': 'Saint Vincent and the Grenadines',
  }, {
    'country_code': '685',
    'country_code_name': 'ws',
    'country_name': 'Samoa',
  }, {
    'country_code': '378',
    'country_code_name': 'sm',
    'country_name': 'San Marino',
  }, {
    'country_code': '239',
    'country_code_name': 'st',
    'country_name': 'S\u00e3o Tom\u00e9 and Pr\u00edncipe',
  }, {
    'country_name': 'Saudi Arabia',
    'country_code': '966',
    'lang_name': 'arabic',
    'country_code_name': 'sa',
    'lang_code': 'ar',
  }, {
    'country_name': 'Senegal',
    'country_code': '221',
    'lang_name': 'wolof',
    'country_code_name': 'sn',
    'lang_code': 'wo',
  }, {
    'country_name': 'Serbia',
    'country_code': '381',
    'lang_name': 'serbian (latin)',
    'country_code_name': 'rs',
    'lang_code': 'sr',
  }, {
    'country_code': '248',
    'country_code_name': 'sc',
    'country_name': 'Seychelles',
  }, {
    'country_code': '500',
    'country_code_name': 'fk',
    'country_name': 'Falkland Islands',
  }, {
    'country_code': '232',
    'country_code_name': 'sl',
    'country_name': 'Sierra Leone',
  }, {
    'country_name': 'Singapore',
    'country_code': '65',
    'lang_name': 'english',
    'country_code_name': 'sg',
    'lang_code': 'en',
  }, {
    'country_name': 'Slovakia',
    'country_code': '421',
    'lang_name': 'slovak',
    'country_code_name': 'sk',
    'lang_code': 'sk',
  }, {
    'country_name': 'Slovenia',
    'country_code': '386',
    'lang_name': 'slovenian',
    'country_code_name': 'si',
    'lang_code': 'sl',
  }, {
    'country_code': '677',
    'country_code_name': 'sb',
    'country_name': 'Solomon Islands',
  }, {
    'country_code': '252',
    'country_code_name': 'so',
    'country_name': 'Somalia',
  }, {
    'country_name': 'South Africa',
    'country_code': '27',
    'lang_name': 'setswana',
    'country_code_name': 'za',
    'lang_code': 'tn',
  }, {
    'country_name': 'South Korea',
    'country_code': '82',
    'lang_name': 'korean',
    'country_code_name': 'kr',
    'lang_code': 'ko',
  }, {
    'country_code': '211',
    'country_code_name': 'ss',
    'country_name': 'South Sudan',
  }, {
    'country_name': 'Spain',
    'country_code': '34',
    'lang_name': 'spanish',
    'country_code_name': 'es',
    'lang_code': 'es',
  }, {
    'country_name': 'Sri Lanka',
    'country_code': '94',
    'lang_name': 'sinhala',
    'country_code_name': 'lk',
    'lang_code': 'si',
  }, {
    'country_code': '249',
    'country_code_name': 'sd',
    'country_name': 'Sudan',
  }, {
    'country_code': '597',
    'country_code_name': 'sr',
    'country_name': 'Suriname',
  }, {
    'country_code': '268',
    'country_code_name': 'sz',
    'country_name': 'Swaziland',
  }, {
    'country_name': 'Sweden',
    'country_code': '46',
    'lang_name': 'swedish',
    'country_code_name': 'se',
    'lang_code': 'sv',
  }, {
    'country_name': 'Switzerland',
    'country_code': '41',
    'lang_name': 'romansh',
    'country_code_name': 'ch',
    'lang_code': 'rm',
  }, {
    'country_name': 'Syria',
    'country_code': '963',
    'lang_name': 'syriac',
    'country_code_name': 'sy',
    'lang_code': 'syr',
  }, {
    'country_name': 'Taiwan',
    'country_code': '886',
    'lang_name': 'chinese (traditional) legacy',
    'country_code_name': 'tw',
    'lang_code': 'zh',
  }, {
    'country_name': 'Tajikistan',
    'country_code': '992',
    'lang_name': 'tajik (cyrillic)',
    'country_code_name': 'tj',
    'lang_code': 'tg',
  }, {
    'country_code': '255',
    'country_code_name': 'tz',
    'country_name': 'Tanzania',
  }, {
    'country_name': 'Thailand',
    'country_code': '66',
    'lang_name': 'thai',
    'country_code_name': 'th',
    'lang_code': 'th',
  }, {
    'country_code': '228',
    'country_code_name': 'tg',
    'country_name': 'Togo',
  }, {
    'country_code': '690',
    'country_code_name': 'tk',
    'country_name': 'Tokelau',
  }, {
    'country_code': '676',
    'country_code_name': 'to',
    'country_name': 'Tonga',
  }, {
    'country_name': 'Trinidad and Tobago',
    'country_code': '1868',
    'lang_name': 'english',
    'country_code_name': 'tt',
    'lang_code': 'en',
  }, {
    'country_name': 'Tunisia',
    'country_code': '216',
    'lang_name': 'arabic',
    'country_code_name': 'tn',
    'lang_code': 'ar',
  }, {
    'country_name': 'Turkey',
    'country_code': '90',
    'lang_name': 'turkish',
    'country_code_name': 'tr',
    'lang_code': 'tr',
  }, {
    'country_name': 'Turkmenistan',
    'country_code': '993',
    'lang_name': 'turkmen',
    'country_code_name': 'tm',
    'lang_code': 'tk',
  }, {
    'country_code': '1649',
    'country_code_name': 'tc',
    'country_name': 'Turks and Caicos Islands',
  }, {
    'country_code': '688',
    'country_code_name': 'tv',
    'country_name': 'Tuvalu',
  }, {
    'country_code': '256',
    'country_code_name': 'ug',
    'country_name': 'Uganda',
  }, {
    'country_name': 'United Kingdom',
    'country_code': '44',
    'lang_name': 'welsh',
    'country_code_name': 'gb',
    'lang_code': 'cy',
  }, {
    'country_name': 'Ukraine',
    'country_code': '380',
    'lang_name': 'ukrainian',
    'country_code_name': 'ua',
    'lang_code': 'uk',
  }, {
    'country_name': 'United Arab Emirates',
    'country_code': '971',
    'lang_name': 'arabic',
    'country_code_name': 'ae',
    'lang_code': 'ar',
  }, {
    'country_name': 'Uruguay',
    'country_code': '598',
    'lang_name': 'spanish',
    'country_code_name': 'uy',
    'lang_code': 'es',
  }, {
    'country_name': 'United States',
    'country_code': '1',
    'lang_name': 'english',
    'country_code_name': 'us',
    'lang_code': 'en',
  }, {
    'country_name': 'Uzbekistan',
    'country_code': '998',
    'lang_name': 'uzbek (latin)',
    'country_code_name': 'uz',
    'lang_code': 'uz',
  }, {
    'country_code': '678',
    'country_code_name': 'vu',
    'country_name': 'Vanuatu',
  }, {
    'country_name': 'Venezuela',
    'country_code': '58',
    'lang_name': 'spanish',
    'country_code_name': 've',
    'lang_code': 'es',
  }, {
    'country_name': 'Vietnam',
    'country_code': '84',
    'lang_name': 'vietnamese',
    'country_code_name': 'vn',
    'lang_code': 'vi',
  }, {
    'country_code': '1340',
    'country_code_name': 'vi',
    'country_name': 'Virgin Islands',
  }, {
    'country_code': '681',
    'country_code_name': 'wf',
    'country_name': 'Wallis and Futuna',
  }, {
    'country_name': 'Yemen',
    'country_code': '967',
    'lang_name': 'arabic',
    'country_code_name': 'ye',
    'lang_code': 'ar',
  }, {
    'country_code': '260',
    'country_code_name': 'zm',
    'country_name': 'Zambia',
  }, {
    'country_name': 'Zimbabwe',
    'country_code': '263',
    'lang_name': 'english',
    'country_code_name': 'zw',
    'lang_code': 'en',
  }];

  static VENDOR_TYPE = [
    'Husband',
    'Wife',
    'Son',
    'Daughter',
    'Brother',
    'Sister',
    'Partner'
  ];

  static METHOD_OF_SALE = [
    'Auction',
    'Private Treaty',
    'Create New'
  ];

  static COMPANY_TYPE = [
    'BUY',
    'RENT',
    'SOLICITOR',
  ];

  static LISTING_STATUS = [
    'For sale',
    'For rent',
    'Both',
  ];

  static AGREEMENT_TYPE = [
    'Exclusive',
    'Open Listing',
    'Option',
  ];

  static AGENT_TITLE_SALE = [
    'Lead Generation',
    'Listing Agent',
    'Sale Agent',
  ];

  static AGENT_TITLE_RENT = [
    'Lead Generation',
    'Property Manager',
  ];

  static ATTACHMENT_TYPE = {
    CITY: 'CITY',
    DISTRICT: 'DISTRICT',
    PROSPECT: 'PROSPECT',
    BUYER: 'BUYER',
    SOS_ANSWER: 'SOS_ANSWER',
    USER: 'USER',
  };

  static AGENT_NAME = 'Red Earth Management Pty Ltd';
  static LICENSE_NUMBER = '20058377';
  static ABN_NUMBER = '167 417 969';
  static OFFICE_NAME = 'Wiseberry Campbelltown';
  static OFFICE_ADDRESS = '1/11-13 Dumaresq St Campbelltown NSW';
  static OFFICE_FAX = '02 4610 1140';
  static OFFICE_EMAIL = 'campbelltown@wiseberry.com.au';


  static ARREAR_OPTION = [
    {
      label: '0-7',
      value: 1
    },
    {
      label: '7-14',
      value: 2
    },
    {
      label: '14+',
      value: 3
    }
  ];

  static CLIENT_RATE_OPTIONS = [
    'S.1', 'SO.1', 'SR.1', 'SRV.1',
    'S.2H', 'S.2M', 'SO.2H', 'SO.2M', 'SR.2H', 'SR.2M', 'SRV.2',
    'S.3', 'SO.3', 'SR.3', 'SRV.3'
  ];
}
