import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { WrapProspect } from './prospect-utils';
import { StringUtils } from '../../../../shared/common/string-utils';
import { Agent } from '../../../../shared/models/response/prospect/agent';
import { Property } from '../../../../shared/models/response/prospect/property';
import { AgentGroup } from '../../../../shared/models/response/prospect/agent-group';

@Component({
  template: `
    {{renderValue}}
  `,
  styles: [],
})
export class VisualBoardAgentCellComponent implements ViewCell, OnInit {

  renderValue: string;

  @Input() value: any;
  @Input() rowData: WrapProspect;
  StringUtils = StringUtils;
  groupTitle: string;

  ngOnInit() {
    if (this.rowData?.data?.property?.agentGroups) {
      this.setValue();
    }
  }

  setValue(): any {
    if (!this.rowData) {
      this.renderValue = this.value ? this.value.toString() : '';
      return;
    }
    const property = this.rowData?.data?.property;
    this.renderValue = '';
    if (property.agentGroups.length === 0 || !property.agentGroups) {
      return;
    }
    const totalCommission = this.calculateAgentSplit(property);
    property.agentGroups.forEach(group => {
      if (this.groupTitle === 'BDM') {
        this.groupTitle = 'Lead Generation';
      }
      if (this.groupTitle === 'PM') {
        this.groupTitle = 'Sale Agents';
      }
      if (group.title === this.groupTitle) {
        group.agents.forEach(agent => {
          this.renderValue += this.generateRenderValue(agent, group, totalCommission);
        });
      }
    });
  }

  generateRenderValue(agent: Agent, group: AgentGroup, totalCommission: number): string {
    return StringUtils.shortenUserName(agent.user) + ' - $' + this.calculateSplit(group.split, agent.agentSplit, totalCommission).toString();
  }

  calculateAgentSplit(property: Property): number {
    let agentPrice: number;
    let fee: number;
    if (property.agentEstimatePriceFrom && property.agentEstimatePriceTo) {
      agentPrice = this.average(property.agentEstimatePriceFrom, property.agentEstimatePriceTo);
    } else {
      agentPrice = null;
    }
    if (property.commissionRate && agentPrice !== null) {
      const rate = this.extractCommissionRate(property.commissionRate);
      fee = rate * agentPrice / 100;
    } else {
      fee = null;
    }
    if (agentPrice !== null && fee !== null) {
      return fee - property.adjustment;
    } else {
      return null;
    }
  }

  validateCommissionRate(s: string): boolean {
    const split = s.split('%');
    return split.length > 0;
  }

  extractCommissionRate(s: string): number {
    if (this.validateCommissionRate(s)) {
      const split = s.split('%');
      return Number(split[0]);
    }
  }

  calculateSplit(groupSplit: number, agentSplit: number, totalCommission: number): number {
    return Math.round(totalCommission * groupSplit / 100 * agentSplit / 100);
  }

  average(a: number, b: number): number {
    a = Number(a);
    b = Number(b);
    return ((a + b) / 2);
  }

}
