import { Injectable } from "@angular/core";
import {
  NbComponentStatus,
  NbGlobalPhysicalPosition,
  NbGlobalPosition,
  NbToastrConfig,
  NbToastrService,
} from "@nebular/theme";

@Injectable({providedIn: 'root'})
export class ToastService {
  constructor(private toastrService: NbToastrService) {
  }

  config: NbToastrConfig;
  destroyByClick = true;
  duration = 2000;
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  preventDuplicates = false;
  types: NbComponentStatus[] = [
    'primary',
    'success',
    'info',
    'warning',
    'danger',
  ];

  makeToast(type: NbComponentStatus, title: string, content: string) {
    this.showToast(type, title, content);
  }

  success(content: string) {
    this.showToast('success', 'Success', content);
  }

  error(content: string) {
    this.showToast('danger', 'Error', content);
  }

  warn(content: string) {
    this.showToast('warning', 'Warning', content);
  }

  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: this.destroyByClick,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
    };

    this.toastrService.show(
      body,
      title,
      config);
  }
}
