export class TaskRequest {
  taskId: number;
  title: string;
  name: string;
  description: string;
  finishedDate?: number;
  fromDate?: number;
  toDate?: number;
  status?: string;
  taskTypeId?: number;
  fromUserId?: number;
  toUserId?: number;
  ordinal?: number;
  subTasks?: TaskRequest[];
  buyerId?: number;
  prospectId?: number;
  sendEmail?: boolean;
  note?: string;
  message?: string;
  isSp?: boolean;
  isS4?: boolean;
  isCallback?: boolean;
  isDob?: boolean;
  callbackDate?: number;
  isAppointment?: boolean;
  isBi?: boolean;
  isB4?: boolean;
  officeId: number;
}
