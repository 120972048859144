import {TaskTypeCategory} from './task-type-category';
import {TaskTypeGroup} from './task-type-group';

export class TaskType {
  taskTypeId?: number;
  name?: string;
  description?: string;
  color?: string;
  category?: TaskTypeCategory;
  group?: TaskTypeGroup;
}
