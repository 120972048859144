import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Path } from '../common/path';
import { RestResult } from '../models/response/rest-result';
import { CommonService } from './common.service';
import { SearchRequest } from '../models/request/search-request';
import { CustomerRequest } from '../models/request/prospect/customer-request';
import { CustomerSearchRequest } from '../models/request/prospect/customer-search-request';
import { MultipleSelectedRequest } from '../models/common/multiple-selected-request';
import { CustomerLinkRequest } from '../models/request/prospect/customer-link-request';
import { CustomerAdditionalInfoRequest } from '../models/request/prospect/customer-additional-info-request';

@Injectable()
export class CustomerService {

  constructor(protected http: HttpClient,
              protected commonService: CommonService) {
  }

  getOne(customerId: number): Observable<RestResult> {
    return this.http.get<any>(
      Path.API_ENDPOINT +
      Path.CUSTOMER +
      Path.CUSTOMER_ID.replace(':customerId', customerId.toString()),
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }


  search(request: SearchRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.CUSTOMER +
      Path.SEARCH, request,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  create(request: CustomerRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.CUSTOMER, request).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  remove(customerId: number): Observable<RestResult> {
    return this.http.delete<any>(
      Path.API_ENDPOINT +
      Path.CUSTOMER +
      Path.CUSTOMER_ID.replace(':customerId', customerId.toString()),
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  update(request: CustomerRequest): Observable<RestResult> {
    return this.http.put<any>(
      Path.API_ENDPOINT +
      Path.CUSTOMER, request).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  searchDuplicatePhone(request: CustomerSearchRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.CUSTOMER +
      Path.SEARCH +
      Path.PHONE, request).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  linkCustomer(request: CustomerLinkRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.CUSTOMER +
      Path.LINK, request).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  fetchAllLinkedGroup(customerId: number): Observable<RestResult> {
    return this.http.get<any>(
      Path.API_ENDPOINT +
      Path.CUSTOMER +
      Path.LINK +
      Path.GROUP +
      Path.CUSTOMER_ID.replace(':customerId', customerId.toString()),
    ).pipe(
      map(
        result => {
          return result;
        },
      ),
      catchError(this.commonService.handleError),
    );
  }

  searchCustom(request: CustomerSearchRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.CUSTOMER +
      Path.SEARCH + Path.CUSTOMER, request,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }
}
