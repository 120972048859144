import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Constant } from '../../common/constant';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../services/common.service';
import { NbDatepickerComponent, NbDialogService } from '@nebular/theme';
import { ToastService } from '../../services/toast.service';
import { FormService } from '../../services/form.service';
import { ProspectService } from '../../services/prospect.service';
import { Prospect } from '../../models/response/prospect/prospect';
import { ProspectRequest } from '../../models/request/prospect/prospect-request';
import { Company } from '../../models/response/prospect/company';
import { ConfirmDialogComponent } from '../../../modules/pages/confirm-dialog/confirm-dialog.component';
import { Task } from '../../models/response/task';
import { CustomerRequest } from '../../models/request/prospect/customer-request';
import { PropertyRequest } from '../../models/request/prospect/property-request';
import { AddressRequest } from '../../models/request/address/address-request';
import { ProspectLog } from '../../models/response/prospect/prospect-log';
import { TaskMiniDialogComponent } from '../../../modules/pages/task/task-mini-dialog/task-mini-dialog.component';
import { ProspectContactRequest } from '../../models/request/prospect/prospect-contact-request';
import { TaskType } from '../../models/response/task-type';
import { TaskStatus } from '../../models/response/task-status';
import * as moment from 'moment';
import { StringUtils } from '../../common/string-utils';
import { Category } from '../../models/response/category';
import { Observable, of } from 'rxjs';
import { SearchRequest } from '../../models/request/search-request';
import { Triple } from '../../models/request/triple';
import { map, mergeMap } from 'rxjs/operators';
import { CategoryService } from '../../services/category.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { User } from '../../models/response/user';
import { AuthService } from '../../services/auth.service';
import { NationalityNoteService } from '../../services/nationality.note.service';
import { NationalityNote } from '../../models/response/nationality-note';
import { Office } from '../../models/response/office';
import { OfficeService } from '../../services/office.service';
import { Address } from '../../models/response/address/address';
import { Buyer } from '../../models/response/buyer/buyer';
import { BuyerCreateComponent } from '../buyer-create/buyer-create.component';
import { CustomerLinkGroup } from '../../models/response/customer-link-group';
import { CustomerService } from '../../services/customer.service';
import { ComponentPermission } from '../../../component.permission';
import {
  ProspectListBasicComponent,
} from '../../../modules/pages/prospect/prospect-list-basic/prospect-list-basic.component';
import { ProspectSearchRequest } from '../../models/request/prospect/prospect-search-request';
import { SalesDetailsComponent } from './sales-details/sales-details.component';
import { ListingDetailsRequest } from '../../models/request/prospect/listing-details-request';
import { CustomerSearchRequest } from '../../models/request/prospect/customer-search-request';
import { CustomerDuplicatePhone } from '../../models/response/prospect/customer-duplicate-phone';
import { CompanyService } from '../../services/company.service';
import { AttachmentComponent } from '../attachment/attachment.component';
import { RestResult } from '../../models/response/rest-result';
import { Router } from '@angular/router';
import { RentalDetailsComponent } from './rental-details/rental-details.component';
import { CustomerAdditionalInfo } from '../../models/response/prospect/customer-additional-info';
import { VCard } from 'ngx-vcard';
import { AttachmentRequest } from '../../models/request/attachment-request';
import { AttachmentService } from '../../services/attachment.service';
import { DomSanitizer } from '@angular/platform-browser';
import { resultList, RxSpeechRecognitionService } from '@kamiazya/ngx-speech-recognition';

@Component({
  selector: 'ngx-prospect-dialog',
  templateUrl: './prospect-dialog.component.html',
  styleUrls: ['./prospect-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})

export class ProspectDialogComponent implements OnInit, OnDestroy {
  @Output() editDone = new EventEmitter();
  @Output() navigateProspectEvent = new EventEmitter();
  @Input() companies: Company[];
  @Input() taskTypes: TaskType[];
  @Input() taskStatuses: TaskStatus[];
  @Input() prospects: Prospect[];
  @Input() showBottomButtons = true;
  @Input() showFooter?: boolean;
  @Input() isModal = false;
  onClose: any;
  defaultProspect: Prospect;
  // isMotive = false;
  // showTenant = false;
  showMarket = false;
  showContact = false;
  showMotive = false;
  showPerson = false;
  showUnit = false;

  prospectForm: FormGroup;
  nationalityNoteForm: FormGroup;

  customerRelationShip = Constant.CUSTOMER_RELATIONSHIP;
  customerMotivate = Constant.CUSTOMER_MOTIVATE;
  propertyType = Constant.PROPERTY_TYPE;
  propertyDefaultSpec = Constant.PROPERTY_DEFAULT_SPEC;
  currentUser: User;

  rateOptions = [
    { value: 0, label: '0' },
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
  ];
  defaultCurrencyIcon = Constant.DEFAULT_CURRENCY_ICON;
  data: Prospect;
  prospectId: number;
  tasks: Task[];
  filterCategory$: Observable<Category[]>;
  categoryName: string;
  selectedCategories: Category[] = [];
  linkedGroup: CustomerLinkGroup[];
  totalLinked = 0;
  listingRequest: ListingDetailsRequest;
  formattedAddress: string[];

  setProspect(value) {
    if (value) {
      if (!value.isCopy) {
        this.prospectService.getGroupItemDetails(value.prospectId).subscribe(result => {
          const prospect = result.data;
          // this.setProspect(result.data);
          this.setProspectData(prospect);
        }, error => {
          this.commonService.warning(error.message);
        });
      } else {
        this.setProspectData(value);
      }
    }
    if (this.attachmentComponent && value?.prospectId) {
      this.attachmentComponent.reloadImages(value.prospectId);
    }
  }

  @Input()
  set selectedProspect(value) {
    if (value) {
      this.prospectService.getGroupItemDetails(value.prospectId).subscribe(result => {
        const prospect = result.data;
        this.setProspectData(prospect);
      }, error => {
        this.commonService.warning(error.message);
      });
    }
    if (this.attachmentComponent && value?.prospectId) {
      this.attachmentComponent.reloadImages(value.prospectId);
    }
  }

  otmDays: any;
  vacDays: any;
  purchaseDays: any;
  purchaseDurationText: string;
  vacDurationText: string;
  otmDurationText: string;
  dateFormat = 'DD/MM/YYYY';
  address = '';
  street: number;
  isSubmitted = false;
  isUpdating = false;
  bedValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  contactLogType = {
    NO_ANSWER: 'NO_ANSWER',
    CALLED: 'CALLED',
    SMS: 'SMS',
    DOOR_NOCKED: 'DOOR_NOCKED',
  };
  lastCalledPhoneNumber = '';
  isNewNote = false;
  dobText = '';
  languages = Constant.LANGUAGES;
  religions = Constant.RELIGIONS;
  nationalities = Constant.COUNTRIES;
  currentNationalityNote: NationalityNote;
  filterOffice$: Observable<Office[]>;
  officeName: string;
  selectedOffice: Office;
  isEditing = true;
  ComponentPermission = ComponentPermission;
  phoneDuplicate: CustomerDuplicatePhone;
  mobiPhoneDuplicate: CustomerDuplicatePhone;
  tenantForm: FormGroup;
  infoVisible;
  @ViewChild(AttachmentComponent) attachmentComponent: AttachmentComponent;
  additionalPhone: CustomerAdditionalInfo[] = [];
  additionalMobilePhone: CustomerAdditionalInfo[] = [];
  isRecording = false;
  recordedTime: any;
  blobUrl: any;
  file: File;
  files: File[] = [];
  name = Constant.ATTACHMENT_TYPE.PROSPECT;
  request: AttachmentRequest = new AttachmentRequest();

  listening = false;
  subscription;
  isNotCount = true;

  listen(): void {
    this.listening = true;

    this.subscription = this.service
      .listen()
      .pipe(resultList)
      .subscribe((list: SpeechRecognitionResultList) => {
        this.listening = false;

        let message = list.item(0).item(0).transcript;

        if (message === 'apagar' || message === 'limpar') {
          message = '';
        }
        if (this.prospectForm.controls.note.value == null) {
          this.prospectForm.controls.note.setValue('');
        }
        this.prospectForm.controls.note.setValue(this.prospectForm.controls.note.value + ' ' + message);
      });
  }

  constructor(private toastService: ToastService,
              public service: RxSpeechRecognitionService,
              private prospectService: ProspectService,
              private categoryService: CategoryService,
              private formService: FormService,
              private commonService: CommonService,
              private authService: AuthService,
              private dialogService: NbDialogService,
              private nationalityNoteService: NationalityNoteService,
              private officeService: OfficeService,
              private customerService: CustomerService,
              private companyService: CompanyService,
              private router: Router,
              private attachmentService: AttachmentService,
              private sanitizer: DomSanitizer,
  ) {
  }

  ngOnInit(): void {
    this.currentUser = this.authService.currentUser;
    // this.infoVisible = this.currentUser.office?.officeProperty?.infoVisible;
    this.infoVisible = true;
    this.initForm();
    this.filterCategory$ = new Observable((observer: any) => {
      observer.next(this.categoryName);
    }).pipe(
      mergeMap((query: string) => this.searchCategory(query)),
    );
    this.initNationalityNote();
    this.initAutoComplete();
    this.fetchAllLinkedCustomer(this.data?.customer?.customerId);
  }


  ngOnDestroy(): void {
  }

  searchSameCustomer(type: string) {
    if (type === 'PHONE') {
      this.lookupPhone(this.prospectForm.value.phone, type);
    } else {
      this.lookupPhone(this.prospectForm.value.mobilePhone, type);
    }
  }

  selectPhone(type: string) {
    let phone;
    if (type === 'PHONE') {
      phone = this.prospectForm.value.phone;
    } else {
      phone = this.prospectForm.value.mobilePhone;
    }
    window.open(
      '/pages/dashboard/search/phone?phone=' + phone + '&isMatched=true',
      '_blank',
    );
  }

  lookupPhone(phone: string, type: string) {
    if (type === 'PHONE') {
      this.phoneDuplicate = null;
    } else {
      this.mobiPhoneDuplicate = null;
    }
    if (phone) {
      const customerSearchRequest = new CustomerSearchRequest();
      customerSearchRequest.phone = phone;
      customerSearchRequest.isMatched = true;
      this.customerService.searchDuplicatePhone(customerSearchRequest).subscribe(
        result => {
          if (result.data && result.data.length > 0) {
            if (type === 'PHONE') {
              this.phoneDuplicate = new CustomerDuplicatePhone();
              result.data.forEach(response => {
                this.phoneDuplicate.phone = response.phone;
                if (response.tableType === 'BUYER') {
                  this.phoneDuplicate.totalCustomer += response.total;
                } else {
                  this.phoneDuplicate.totalClient += response.total;
                }
              });
            } else {
              this.mobiPhoneDuplicate = new CustomerDuplicatePhone();
              result.data.forEach(response => {
                this.mobiPhoneDuplicate.phone = response.phone;
                if (response.tableType === 'BUYER') {
                  this.mobiPhoneDuplicate.totalCustomer += response.total;
                } else {
                  this.mobiPhoneDuplicate.totalClient += response.total;
                }
              });
            }
          }
        }, error => {
          this.commonService.warning(error.message);
        },
      );
    }
  }

  fetchAllLinkedCustomer(customerId: number) {
    if (customerId) {
      this.customerService.fetchAllLinkedGroup(customerId).subscribe(
        result => {
          if (result.data) {
            this.linkedGroup = result.data;
            this.setTotalLinked();
          }
        }, error => {
          this.commonService.warning(error.message);
        },
      );
    }
  }

  setTotalLinked() {
    this.totalLinked = 0;
    this.linkedGroup.forEach(group => {
      if (group.prospects) {
        this.totalLinked += group.prospects.length;
      }
      if (group.buyers) {
        this.totalLinked += group.buyers.length;
      }
    });
  }

  // === autocomplete ===

  // openMotive() {
  //   if (this.data) {
  //     this.isMotive = !this.isMotive;
  //   }
  // }

  initForm() {
    this.lastCalledPhoneNumber = this.data?.customer?.phone;
    this.isSubmitted = false;
    this.isNewNote = false;
    this.formattedAddress = StringUtils.formatAddress(this.data?.address);
    this.tasks = this.data?.tasks;
    this.prospectForm = new FormGroup({
      call: new FormControl(this.data?.call),
      sms: new FormControl(this.data?.sms),
      door: new FormControl(this.data?.door),
      mail: new FormControl(this.data?.mail),
      // ===
      sell: new FormControl(this.data?.property?.sell),
      rent: new FormControl(this.data?.property?.rent),
      otm: new FormControl(this.data?.property?.otm),
      vac: new FormControl(this.data?.property?.vac),
      // ===
      isDirector: new FormControl(this.data?.customer?.isDirector),
      isInfluencer: new FormControl(this.data?.customer?.isInfluencer),
      isSocialble: new FormControl(this.data?.customer?.isSocialble),
      isConcensious: new FormControl(this.data?.customer?.isConcensious),
      // ===
      motivateFinancialUp: new FormControl(this.data?.customer?.motivateFinancialUp),
      motivateFinancialDown: new FormControl(this.data?.customer?.motivateFinancialDown),
      motivateLifestyleUp: new FormControl(this.data?.customer?.motivateLifestyleUp),
      motivateLifestyleDown: new FormControl(this.data?.customer?.motivateLifestyleDown),
      // ===
      phone: new FormControl(this.data?.customer?.phone, [
        Validators.maxLength(20),
      ]),
      mobilePhone: new FormControl(this.data?.customer?.mobilePhone, [
        Validators.maxLength(20),
      ]),
      email: new FormControl(this.data?.customer?.email, [
        Validators.maxLength(200),
      ]),
      facebook: new FormControl(this.data?.customer?.facebook, [
        Validators.maxLength(500),
      ]),
      instagram: new FormControl(this.data?.customer?.instagram, [
        Validators.maxLength(500),
      ]),
      // ===
      relationship: new FormControl(this.data?.customer?.relationship, [
        Validators.maxLength(1),
      ]),
      motivate: new FormControl(this.data?.customer?.motivate, [
        Validators.maxLength(1),
      ]),
      // ===
      bed: new FormControl(this.data?.property?.bed, [
        Validators.required,
      ]),
      bath: new FormControl(this.data?.property?.bath, [
        Validators.required,
      ]),
      car: new FormControl(this.data?.property?.car, [
        Validators.required,
      ]),
      land: new FormControl(this.data?.property?.land, [
        Validators.required,
      ]),
      type: new FormControl(this.data?.property?.type, [
        Validators.required,
      ]),
      sellPrice: new FormControl(this.data?.property?.sellPrice),
      rentPrice: new FormControl(this.data?.property?.rentPrice),
      askingPrice: new FormControl(this.data?.property?.askingPrice),
      otmDate: new FormControl(this.data?.property?.otmDate ? new Date(this.data?.property?.otmDate) : ''),
      vacDate: new FormControl(this.data?.property?.vacDate ? new Date(this.data?.property?.vacDate) : ''),
      purchaseDate:
        new FormControl(this.data?.property?.purchaseDate ? new Date(this.data?.property?.purchaseDate) : ''),
      sellCompanyId: new FormControl(this.data?.property?.sellCompany?.companyId),
      rentCompanyId: new FormControl(this.data?.property?.rentCompany?.companyId),
      name: new FormControl(this.data?.customer?.name, [
        Validators.maxLength(200),
      ]),
      surname: new FormControl(this.data?.customer?.surname, [
        Validators.maxLength(200),
      ]),
      // ===
      number: new FormControl(this.data?.address?.number),
      numberFrom: new FormControl(this.data?.address?.numberFrom),
      numberFromLetter: new FormControl(this.data?.address?.numberFromLetter),
      numberTo: new FormControl(this.data?.address?.numberTo),
      numberToLetter: new FormControl(this.data?.address?.numberToLetter),
      numberUnit: new FormControl(this.data?.address?.numberUnit),
      fromNumber: new FormControl(null),
      toNumber: new FormControl(null),
      unit: new FormControl(this.data?.address?.unit),
      additionalInfo: new FormControl(this.data?.address?.additionalInfo),
      cityId: new FormControl(this.data?.address?.street?.district?.city?.cityId),
      zoneId: new FormControl(this.data?.address?.street?.district?.zone?.zoneId),
      districtId: new FormControl(this.data?.address?.street?.district?.districtId),
      farmId: new FormControl(this.data?.address?.street?.farm?.farmId),
      streetId: new FormControl(this.data?.address?.street?.streetId),
      addressOfficeId: new FormControl(this.data?.address?.office?.officeId),
      propertyOfficeId: new FormControl(this.data?.property?.office?.officeId),
      // ===
      rate: new FormControl(typeof this.data?.rate !== 'undefined' && this.data?.rate !== null ? this.data?.rate : 1),
      // ===
      note: new FormControl(null, [
        Validators.maxLength(2000),
      ]),
      motiveNote: new FormControl(this.data?.motiveNote, [
        Validators.maxLength(2000),
      ]),
      dob: new FormControl(this.data?.customer?.dob ? new Date(this.data?.customer?.dob) : ''),
      callbackDate: new FormControl(''),
      sold: new FormControl(this.data?.property?.sold),
      underContract: new FormControl(this.data?.property?.underContract),
      leased: new FormControl(this.data?.property?.leased),
      anniversaryDay:
        new FormControl(this.data?.customer?.anniversaryDay ? new Date(this.data?.customer?.anniversaryDay) : ''),
      nationality: new FormControl(this.data?.customer?.nationality),
      customerNote: new FormControl(this.data?.customer?.note),
      religion: new FormControl(this.data?.customer?.religion),
      profesion: new FormControl(this.data?.customer?.profesion),
      language: new FormControl(this.data?.customer?.language),
      nationalityNote: new FormControl(this.data?.nationalityNote),
      officeId: new FormControl(this.data?.office?.officeId),
      link: new FormControl(this.data?.property?.link),
    });
    if (this.data?.customer?.motivate === 0 || this.data?.customer?.motivate === null) {
      this.prospectForm.controls.motivate.setValue(undefined);
    }
    if (this.data?.customer?.relationship === 0 || this.data?.customer?.relationship === null) {
      this.prospectForm.controls.relationship.setValue(undefined);
    }
    // this.autoSetProspectRate();
    if (this.prospectForm.controls.mail.value == null) {
      this.prospectForm.controls.mail.setValue(false);
    }
    if (this.prospectForm.controls.door.value == null) {
      this.prospectForm.controls.door.setValue(false);
    }
    if (this.prospectForm.controls.sms.value == null) {
      this.prospectForm.controls.sms.setValue(false);
    }
    if (this.prospectForm.controls.call.value == null) {
      this.prospectForm.controls.call.setValue(false);
    }
    if (this.prospectForm.controls.isSocialble.value == null) {
      this.prospectForm.controls.isSocialble.setValue(true);
    }
    this.otmDays = this.getDayDiff(this.prospectForm.controls.otmDate.value);
    this.vacDays = this.getDayDiff(this.prospectForm.controls.vacDate.value);
    this.purchaseDays = this.getDayDiff(this.prospectForm.controls.purchaseDate.value);
    this.dobText = this.getDobText(this.prospectForm.controls.dob.value);
    this.updateDurationText();
    // auto set prospect level
    this.prospectForm.controls.type.valueChanges.subscribe(value => {
      this.autoSetProspectRate();
    });

    this.prospectForm.controls.name.valueChanges.subscribe(value => {
      this.generateVCard();
    });
    this.prospectForm.controls.surname.valueChanges.subscribe(value => {
      this.generateVCard();
    });
    this.prospectForm.controls.email.valueChanges.subscribe(value => {
      this.generateVCard();
    });
    this.prospectForm.controls.phone.valueChanges.subscribe(value => {
      this.autoSetProspectRate();
      this.generateVCard();
    });
    this.prospectForm.controls.mobilePhone.valueChanges.subscribe(value => {
      this.autoSetProspectRate();
      this.generateVCard();
    });
    this.prospectForm.controls.relationship.valueChanges.subscribe(value => {
      this.autoSetProspectRate();
    });
    this.prospectForm.controls.motivate.valueChanges.subscribe(value => {
      this.autoSetProspectRate();
    });

    // calculate duration
    this.prospectForm.controls.otmDate.valueChanges.subscribe(value => {
      this.otmDays = this.getDayDiff(value);
      this.updateDurationText();
    });

    this.prospectForm.controls.vacDate.valueChanges.subscribe(value => {
      this.vacDays = this.getDayDiff(value);
      this.updateDurationText();
    });

    this.prospectForm.controls.dob.valueChanges.subscribe(value => {
      this.dobText = this.getDobText(value);
      this.generateVCard();
    });

    this.prospectForm.controls.purchaseDate.valueChanges.subscribe(value => {
      this.purchaseDays = this.getDayDiff(value);
      this.updateDurationText();
    });
    this.officeName = this.data?.office?.name;
    if (this.data?.property?.link) {
      this.isEditing = false;
    }
    this.generateVCard();
  }

  generateVCard() {
    this.vCard = {
      name: {
        firstNames: this.prospectForm.controls.name.value,
        lastNames: this.prospectForm.controls.surname.value,
      },
      birthday: this.prospectForm.controls.dob.value,
      email: [this.prospectForm.controls.email.value],
      telephone: [
        this.prospectForm.controls.mobilePhone.value,
        this.prospectForm.controls.phone.value,
      ],
      address: [
        {
          extendedAddress: StringUtils.getShortAddress(this.data?.address),
          // street: this.data?.address.streetName,
          // postalCode: this.data?.address.postCode,
          // region: this.data?.address.districtName,
          countryName: 'Australia',
        },
      ],
    };
  }

  public vCard: VCard;

  initNationalityNote() {
    if (!this.prospectForm.value.nationality || this.prospectForm.value.nationality === '') {
      return;
    }
    const currentCountry = this.nationalities
      .find(nationality => nationality.country_name === this.prospectForm.value.nationality);
    if (!currentCountry) {
      return;
    }
    const sr = new SearchRequest;
    sr.conditions = [];
    sr.conditions.push(new Triple('countryCodeName', '=', currentCountry.country_code_name));
    this.nationalityNoteService.search(sr).subscribe(result => {
      this.currentNationalityNote = result.data[0];
      this.prospectForm.controls.nationalityNote.patchValue(this.currentNationalityNote.note);
      this.nationalityNoteForm = new FormGroup({
        nationalityNoteId: new FormControl(this.currentNationalityNote.nationalityNoteId),
        note: new FormControl(this.currentNationalityNote.note),
      });
    });
  }

  setProspectData(prospect) {
    this.isEditing = !prospect.property?.link;
    this.data = prospect;
    this.prospectId = prospect.prospectId;
    // this.hideProspectInfo();
    this.selectedCategories = prospect.categories;
    this.initForm();
    this.initNationalityNote();
    if (prospect.customer) {
      this.fetchAllLinkedCustomer(prospect.customer?.customerId);
      this.lookupPhone(this.data?.customer?.phone, 'PHONE');
      this.lookupPhone(this.data?.customer?.mobilePhone, 'MPHONE');
    }
    this.initAdditionalPhoneLists();
  }

  submit(callback?: any) {
    this.isUpdating = true;
    this.formService.extractErrorMessage(this.prospectForm);
    if (this.prospectForm.invalid) {
      this.isSubmitted = true;
      this.isUpdating = false;
      return;
    }
    const prospectRequest = this.getRequest();
    if (this.data && this.data.prospectId) {
      prospectRequest.prospectId = this.data.prospectId;
      prospectRequest.userId = this.currentUser.userId;
      if (this.nationalityNoteForm) {
        this.nationalityNoteForm.controls.note.patchValue(this.prospectForm.value.nationalityNote);
      }
      this.prospectService.update(prospectRequest).subscribe(
        result => {
          this.formattedAddress = StringUtils.formatAddress(result.data?.address);
          if (this.currentNationalityNote) {
            this.nationalityNoteService.update(this.nationalityNoteForm.value).subscribe();
          }
          // this.attachmentComponent.uploadBatch(this.data.prospectId, Constant.ATTACHMENT_TYPE.PROSPECT, true);
          this.commonService.info('Updated prospect success.');
          this.additionalPhone = result.data?.customer?.customerAdditionalInfo?.filter(c => c.phoneNumberType === 'PHONE');
          this.additionalMobilePhone = result.data?.customer?.customerAdditionalInfo?.filter(c => c.phoneNumberType === 'MPHONE');
          this.isUpdating = false;
          if (callback) {
            callback(result);
          } else {
            this.close(result, false);
          }
          // window.location.reload();
        },
        error => {
          this.isUpdating = false;
          this.commonService.warningHtml(error.message);
        },
      );
    }
  }

  getRequest(): ProspectRequest {
    if (this.prospectForm.controls.otmDate.value && this.prospectForm.controls.otmDate.value !== ''
      && isNaN(Number(this.prospectForm.controls.otmDate.value))) {
      this.prospectForm.controls.otmDate
        .setValue(this.commonService.parseDate(this.prospectForm.controls.otmDate.value, this.dateFormat));
    }
    if (this.prospectForm.controls.vacDate.value && this.prospectForm.controls.vacDate.value !== ''
      && isNaN(Number(this.prospectForm.controls.vacDate.value))
    ) {
      this.prospectForm.controls.vacDate
        .setValue(this.commonService.parseDate(this.prospectForm.controls.vacDate.value, this.dateFormat));
    }
    if (this.prospectForm.controls.dob.value && this.prospectForm.controls.dob.value !== ''
      && isNaN(Number(this.prospectForm.controls.dob.value))
    ) {
      this.prospectForm.controls.dob
        .setValue(this.commonService.parseDate(this.prospectForm.controls.dob.value, this.dateFormat));
    }
    if (this.prospectForm.controls.otmDate.value && this.prospectForm.controls.otmDate.value !== ''
      && isNaN(Number(this.prospectForm.controls.otmDate.value))) {
      this.prospectForm.controls.otmDate
        .setValue(this.commonService.parseDate(this.prospectForm.controls.otmDate.value, this.dateFormat));
    }
    if (this.prospectForm.controls.vacDate.value && this.prospectForm.controls.vacDate.value !== ''
      && isNaN(Number(this.prospectForm.controls.vacDate.value))
    ) {
      this.prospectForm.controls.vacDate
        .setValue(this.commonService.parseDate(this.prospectForm.controls.vacDate.value, this.dateFormat));
    }
    if (this.prospectForm.controls.dob.value && this.prospectForm.controls.dob.value !== ''
      && isNaN(Number(this.prospectForm.controls.dob.value))
    ) {
      this.prospectForm.controls.dob
        .setValue(this.commonService.parseDate(this.prospectForm.controls.dob.value, this.dateFormat));
    }
    let additionalProspectRequest = new ProspectRequest();
    let additionalPropertyRequest = new PropertyRequest();
    if (this.listingRequest) {
      additionalProspectRequest = this.listingRequest.prospectRequest;
      additionalPropertyRequest = this.listingRequest.propertyRequest;
    }
    const prospectRequest: ProspectRequest = {
      ...this.data,
      ...this.prospectForm.value,
      ...additionalProspectRequest,
    };
    prospectRequest.customer = { ...prospectRequest.customer, ...this.prospectForm.value };
    prospectRequest.customer.customerAdditionalInfo = [...this.additionalPhone, ...this.additionalMobilePhone];
    prospectRequest.property = { ...prospectRequest.property, ...this.prospectForm.value, ...additionalPropertyRequest };
    prospectRequest.address = {
      ...prospectRequest.address,
      ...this.prospectForm.value,
    };
    this.trimObject(prospectRequest, Object.keys(ProspectRequest.getDummy()));
    this.trimObject(prospectRequest.customer, Object.keys(CustomerRequest.getDummy()));
    this.trimObject(prospectRequest.property, Object.keys(PropertyRequest.getDummy()));
    this.trimObject(prospectRequest.address, Object.keys(AddressRequest.getDummy()));
    if (prospectRequest.customer) {
      prospectRequest.customer.note = this.prospectForm.controls.customerNote.value;
    }
    if (this.selectedCategories.length > 0) {
      prospectRequest.categoryIds = this.selectedCategories.map((category) => category.categoryId);
    }
    if (this.selectedOffice) {
      prospectRequest.officeId = this.selectedOffice.officeId;
      prospectRequest.property.officeId = this.selectedOffice.officeId;
      prospectRequest.address.officeId = this.selectedOffice.officeId;
    } else {
      prospectRequest.officeId = this.currentUser.office?.officeId;
      prospectRequest.property.officeId = this.currentUser.office?.officeId;
      prospectRequest.address.officeId = this.currentUser.office?.officeId;
    }
    // if (prospectRequest.rate === 2) {
    //   prospectRequest.customer.motivate = 2;
    //   prospectRequest.customer.relationship = 2;
    // }
    return prospectRequest;
  }

  delete() {
    if (!this.data) {
      return;
    }
    this.dialogService.open(ConfirmDialogComponent, {
      context: {
        content: 'Do you really want to delete?',
      },
    })
      .onClose.subscribe(res => {
      if (res === ConfirmDialogComponent.confirmOk) {
        this.prospectService.remove(this.data.prospectId).subscribe(
          result => {
            this.toastService.success('Delete successfully');
            this.close();
          }, error => {
            this.toastService.error(error.message);
          });
        this.ngOnInit();
      }
    });
  }

  close(result?: RestResult, next?: boolean) {
    if (this.onClose) {
      this.onClose(result);
    } else {
      this.editDone.emit({ result: result, next: next });
    }
  }

  // ==== property type ===
  onPropertyTypeChange() {
    const type = this.prospectForm.controls.type.value;
    if (type) {
      const defaultSpec = this.propertyDefaultSpec[type];
      if (defaultSpec) {
        if (!this.prospectForm.controls.bed.value) {
          this.prospectForm.controls.bed.setValue(defaultSpec.bed);
        }
        if (!this.prospectForm.controls.bath.value) {
          this.prospectForm.controls.bath.setValue(defaultSpec.bath);
        }
        if (!this.prospectForm.controls.car.value) {
          this.prospectForm.controls.car.setValue(defaultSpec.car);
        }
        if (!this.prospectForm.controls.land.value) {
          this.prospectForm.controls.land.setValue(defaultSpec.land);
        }
      }
      if (type === 'HOUSE_COM') {
        this.prospectForm.controls.sell.setValue(false);
        this.prospectForm.controls.rent.setValue(false);
        this.prospectForm.controls.otm.setValue(false);
        this.prospectForm.controls.vac.setValue(false);
        this.autoSetProspectRate();
      } else if (type) {
        this.prospectForm.controls.sell.setValue(true);
      }
    }
  }

  autoSetProspectRate() {
    const phone = this.prospectForm.controls.phone.value;
    const mobilePhone = this.prospectForm.controls.mobilePhone.value;
    const type = this.prospectForm.controls.type.value;
    let relationship = Number(this.prospectForm.controls.relationship.value);
    let motivate = Number(this.prospectForm.controls.motivate.value);
    if (type === 'HOUSE_COM') {
      this.prospectForm.controls.rate.setValue(0);
      return;
    }

    if (this.tasks && this.tasks.length > 0) {
      if (relationship < 3) {
        this.prospectForm.controls.relationship.setValue(3);
        relationship = Number(this.prospectForm.controls.relationship.value);
      }
      if (motivate < 3) {
        this.prospectForm.controls.motivate.setValue(3);
        motivate = Number(this.prospectForm.controls.motivate.value);
      }
    }

    if (
      ((phone && phone !== '') || (mobilePhone && mobilePhone !== ''))
      && relationship >= 3
      && motivate >= 3
    ) {
      if (this.isNotCount) {
        const temp = Math.ceil((relationship + motivate) / 2);
        this.prospectForm.controls.rate.setValue(temp > 7 ? 7 : temp);
      }
      return;
    }

    if ((phone && phone !== '') || (mobilePhone && mobilePhone !== '')) {
      this.prospectForm.controls.rate.setValue(2);
      return;
    } else {
      this.prospectForm.controls.rate.setValue(1);
      return;
    }
  }

  autoSetSORV() {
    const sell = this.prospectForm.controls.sell.value;
    const rent = this.prospectForm.controls.rent.value;
    const otm = this.prospectForm.controls.otm.value;
    const vac = this.prospectForm.controls.vac.value;
    if (!sell && !rent && !otm && !vac) {
      this.prospectForm.controls.type.setValue('HOUSE_COM');
      this.onPropertyTypeChange();
      return;
    }
    if (vac) {
      this.prospectForm.controls.rent.setValue(true);
      this.prospectForm.controls.vacDate.setValue(new Date());
      return;
    }
  }

  navigateProspect(forward: boolean) {
    this.navigateProspectEvent?.emit(forward);
  }

  setSquareValue(controlName: string) {
    if (this.prospectForm.get(controlName).value !== true) {
      this.prospectForm.get(controlName).setValue(true);
    } else {
      this.prospectForm.get(controlName).setValue(false);
    }
    this.autoSetSORV();
  }

  logContactStatus(type: string) {
    const contactLogRequest: ProspectContactRequest = new ProspectContactRequest();
    switch (type) {
      case this.contactLogType.CALLED:
        contactLogRequest.called = true;
        contactLogRequest.calledPhoneNumber = this.lastCalledPhoneNumber;
        break;
      case this.contactLogType.SMS:
        contactLogRequest.sms = true;
        contactLogRequest.smsPhoneNumber = this.data?.customer?.mobilePhone;
        break;
      case this.contactLogType.DOOR_NOCKED:
        contactLogRequest.doorNocked = true;
        break;
      case this.contactLogType.NO_ANSWER:
        contactLogRequest.noAnswer = true;
        break;
    }
    contactLogRequest.note = this.prospectForm.controls.note?.value?.trim();
    if ((type === this.contactLogType.CALLED || type === this.contactLogType.DOOR_NOCKED)
      && (!contactLogRequest.note || contactLogRequest.note === '')) {
      this.commonService.warning('Please fill in note');
      return;
    }
    this.submit((submitResult) => {
      this.prospectService.logContactStatus(this.data.prospectId, contactLogRequest).subscribe(
        result => {
          this.commonService.info('Logged success.');
          if (
            type === this.contactLogType.NO_ANSWER
            || type === this.contactLogType.CALLED
            || type === this.contactLogType.DOOR_NOCKED
          ) {
            // this.navigateProspect(true);
            this.close(result, true);
          } else if (!this.onClose) {
            this.close(submitResult);
          }
        },
        error => {
          this.commonService.warningHtml(error.message);
        },
      );
    });
  }

  createTask(type: 'BIRTHDAY_CALL' | 'NEXT_CALL' | 'APPOINTMENT') {
    const defaultData = {
      description: `Make phone call to ${this.data?.customer?.name}, `
        + `phone number: ${this.data?.customer?.phone} or ${this.data?.customer?.mobilePhone}`,
      isCallback: type === 'NEXT_CALL',
      isDob: type === 'BIRTHDAY_CALL',
      isAppointment: type === 'APPOINTMENT',
      callbackDate: this.prospectForm.controls.callbackDate.value,
      dob: this.prospectForm.controls.dob.value,
    };
    this.dialogService.open(TaskMiniDialogComponent, {
      hasScroll: true,
      context: {
        defaultData,
        taskTypes: this.taskTypes,
        taskStatuses: this.taskStatuses,
        selectedProspect: this.data,
      },
    })
      .onClose.subscribe(res => {
      if (this.isSuccessResponse(res)) {
        this.close(res);
      }
    });
  }

  newNote() {
    this.isNewNote = true;
    this.prospectForm.controls.note.setValue('');
  }

  cancelNewNote() {
    this.isNewNote = false;
    this.prospectForm.controls.note.setValue(this.data?.note);
  }

  // === others ====
  private isSuccessResponse(response) {
    return response && (response.status === 'success');
  }

  getDayDiff(value: Date): number {
    if (!value) {
      return null;
    }
    const from = moment(value);
    const to = moment();
    return moment.preciseDiff(from, to, true);
  }

  keepOrder(a, b) {
    return 1;
  }

  trimObject(obj: any, properties: string[]): any {
    const curentProperties: string[] = Object.keys(obj);
    for (const name of curentProperties) {
      if (properties.indexOf(name) === -1) {
        delete obj[name];
      }
    }
  }

  sortHistoryList() {
    return this.data?.logs?.sort((a: ProspectLog, b: ProspectLog) => {
      return b.createdDate - a.createdDate;
    });
  }

  formatLog(log: ProspectLog) {
    if (!log.fullname) {
      log.fullname = 'Vu Le';
    }
    let result = '';
    result = `<b>${this.commonService.formatDate(log.createdDate, 'DD/MM/YYYY HH:mm')}</b>`
      + `: ${this.getShortName(log.fullname)}: ${log.actionType}:\n`;
    if (log.actionType === 'BULK_LOG') {
      result += log.description;
    } else {
      try {
        const desObj = JSON.parse(log?.description?.trim());
        if (desObj) {
          for (const key of Object.keys(desObj)) {
            const keyArr = key?.split(':');
            result += `- ${keyArr.length === 2 ? keyArr[1] : key}: ${desObj[key]} \n`;
          }
        }
      } catch (ignore) {
      }
    }
    return result.trim();
  }

  showError(name: string): string {
    return this.prospectForm.controls[name].errors
    && (this.prospectForm.controls[name].dirty
      || this.prospectForm.controls[name].touched
      || this.isSubmitted) ? 'input-error' : '';
  }

  updateDurationText() {
    if (this.purchaseDays) {
      this.purchaseDurationText = (this.purchaseDays['years'] > 0 ? this.purchaseDays['years'] + 'y' : '')
        + (this.purchaseDays['months'] > 0 ? this.purchaseDays['months'] + 'm' : ''
          + (this.purchaseDays['days'] > 0 ? this.purchaseDays['days'] + 'd' : ''));
    } else {
      this.purchaseDurationText = null;
    }
    if (this.otmDays !== null) {
      this.otmDurationText = (this.otmDays['years'] > 0 ? this.otmDays['years'] + 'y' : '') + (this.otmDays['months'] > 0 ? this.otmDays['months'] + 'm' : ''
        + (this.otmDays['days'] >= 0 ? this.otmDays['days'] + 'd' : ''));
    } else {
      this.otmDurationText = null;
    }
    if (this.vacDays !== null) {
      this.vacDurationText = (this.vacDays['years'] > 0 ? this.vacDays['years'] + 'y' : '') + (this.vacDays['months'] > 0 ? this.vacDays['months'] + 'm' : ''
        + (this.vacDays['days'] >= 0 ? this.vacDays['days'] + 'd' : ''));
    } else {
      this.vacDurationText = null;
    }
  }

  triggerDatePicker(datePickerComponent: NbDatepickerComponent<any>) {
    datePickerComponent?.show();
  }

  getShortName(name: string) {
    const items: string[] = name?.replace(/[\s]+/g, ' ').trim()?.split(' ');
    let result = '';
    for (const item of items) {
      result += item[0]?.toUpperCase();
    }
    return result;
  }

  getDobText(value: any): string {
    if (!value || value === '') {
      return '';
    }
    let time = 0;
    if (value instanceof Date) {
      time = (value as Date).getTime();
    } else {
      time = this.commonService.parseDate(value, this.dateFormat);
    }
    // calculate years
    const yearText = this.getTimeDiffText(time, new Date().getTime());
    // calculate how long to dob date in this year
    const remainingText = this.getTimeDiffText(new Date().getTime(),
      moment(time).year(new Date().getFullYear()).valueOf());
    const result = [];
    if (yearText) {
      result.push(yearText);
    }
    if (remainingText) {
      result.push(remainingText);
    }
    return result.join(' - ');
  }

  // years, months, days
  getTimeDiffText(from: number, to: number) {
    const years = moment(to).diff(moment(from), 'years');
    const months = moment(to).diff(moment(from), 'months');
    const remainingMonths = moment(to).subtract(years, 'years').diff(moment(from), 'months');
    const remainingDays = moment(to).subtract(months, 'months').diff(moment(from), 'days');
    let result = '';
    if (years > 0) {
      result += years + ' years' + ' ';
    }
    if (remainingMonths > 0) {
      result += remainingMonths + ' months' + ' ';
    }
    if (remainingDays > 0) {
      result += remainingDays + ' days' + ' ';
    }
    return result;
  }

  onNationalityChange() {
    // this.prospectForm.controls.otmDate
    if (this.prospectForm.value.nationality) {
      const currentCountry = this.nationalities
        .find(nationality => nationality.country_name === this.prospectForm.value.nationality);
      this.initNationalityNote();
      if (currentCountry && currentCountry.lang_code) {
        this.prospectForm.controls.language.setValue(currentCountry.lang_code);
      } else {
        this.prospectForm.controls.language.setValue(null);
      }
      if (this.currentNationalityNote && this.currentNationalityNote.note) {
        this.prospectForm.controls.nationalityNote.patchValue(this.currentNationalityNote.note);
      } else {
        this.prospectForm.controls.nationalityNote.patchValue(null);
      }
    }
  }

  searchCategory(name: string): Observable<Category[]> {
    if (!name || name === '') {
      return of([]);
    }
    const searchRequest = new SearchRequest();
    searchRequest.conditions = new Array();
    searchRequest.conditions.push(new Triple('name', 'like', name));
    const subCon = new SearchRequest();
    subCon.conditionType = 'OR';
    subCon.conditions = [];
    subCon.conditions.push(new Triple('type', '=', 'PROSPECT'));
    subCon.conditions.push(new Triple('type', '=', 'BOTH'));
    searchRequest.subConditions.push(subCon);
    return this.categoryService.search(searchRequest).pipe(
      map(result => result.data),
    );
  }

  selectCategory(type: TypeaheadMatch) {
    this.categoryName = '';
    const selectedCategory: Category = type.item;
    if (this.selectedCategories.filter(category => category.categoryId === selectedCategory.categoryId).length > 0) {
      return;
    }
    this.selectedCategories.push(selectedCategory);
  }

  removeCategory(removedCategory: Category) {
    if (removedCategory) {
      this.selectedCategories = this.selectedCategories.filter
      (category => category.categoryId !== removedCategory.categoryId);
    }
  }

  selectOffice(type: TypeaheadMatch) {
    this.officeName = type.item.name;
    this.selectedOffice = type.item;
    // this.prospectForm.controls.officeId.patchValue(type.item.officeId);
  }

  initAutoComplete() {
    this.filterOffice$ = new Observable((observer: any) => {
      observer.next(this.officeName);
    }).pipe(
      mergeMap((query: string) => this.searchOffice(query)),
    );
  }

  searchOffice(keyword: String): Observable<Office[]> {
    const searchRequest = new SearchRequest();
    searchRequest.conditions = [];
    searchRequest.conditions.push(
      {
        left: 'name',
        middle: 'like',
        right: keyword,
      },
    );
    searchRequest.offset = 0;
    searchRequest.limit = 10;
    return this.officeService.search(searchRequest).pipe(
      map(result => result.data),
    );
  }

  onSelectBuyer(buyer: Buyer) {
    return this.dialogService.open(BuyerCreateComponent, {
      hasScroll: true,
      context: {
        data: buyer,
      },
    }).onClose;
  }

  onSelectProspect(prospect: Prospect) {
    return this.dialogService.open(ProspectDialogComponent, {
      hasScroll: true,
      context: {
        data: prospect,
      },
    }).onClose;
  }

  formatAddress(address: Address): string {
    return StringUtils.getFullAddress(address);
  }

  displayStreet() {
    const sr = new ProspectSearchRequest();
    // sr.otm = false;
    sr.isOpenStreetMarket = true;
    sr.streetId = this.data.address.streetId;
    sr.disableItemCountEachGroup = true;
    sr.prospectId = this.data.prospectId;
    this.dialogService.open(ProspectListBasicComponent, {
      context: {
        searchRequest: sr,
        isDialog: true,
        reducedMode: true,
      },
      // dialogClass: 'model-full',
    });
  }

  displaySalesDetails() {
    this.dialogService.open(SalesDetailsComponent, {
      context: {
        data: this.data,
      },
    }).onClose.subscribe(res => {
      if (res) {
        this.listingRequest = res;
        if (this.listingRequest.propertyRequest.askingPriceFrom && this.listingRequest.propertyRequest.askingPriceTo) {
          const from = Number(this.listingRequest.propertyRequest.askingPriceFrom);
          const to = Number(this.listingRequest.propertyRequest.askingPriceTo);
          this.prospectForm.controls.askingPrice.patchValue((from + to) / 2);
          this.data.property.askingPrice = (from + to) / 2;
        }
        this.submit();
      }
    });
  }


  // toggleDisplayTenant() {
  //   if (this.data) {
  //     this.showTenant = !this.showTenant;
  //   }
  // }
  toggleDisplayMarket() {
    if (this.data) {
      this.showMarket = !this.showMarket;
    }
  }

  toggleDisplayUnit() {
    if (this.data) {
      this.showUnit = !this.showUnit;
    }
  }

  toggleDisplayContact() {
    if (this.data) {
      this.showContact = !this.showContact;
    }
  }

  toggleDisplayMotive() {
    if (this.data) {
      this.showMotive = !this.showMotive;
    }
  }

  toggleDisplayPerson() {
    if (this.data) {
      this.showPerson = !this.showPerson;
    }
  }

  validateTenantFormValue(): boolean {
    const values = this.tenantForm.value;
    for (const value of Object.values(values)) {
      if (value !== null) {
        return value !== '';
      }
    }
    return false;
  }

  private hideProspectInfo() {
    if (!this.currentUser.office ||
      !this.currentUser.office.officeProperty ||
      !this.currentUser.office.officeProperty.infoVisible) {
      const districtId = this.data.address.districtId;
      const districts = this.authService.assignedSuburb;
      const district = districts.find(d => d.districtId === districtId);
      if (district === undefined || district.infoVisible === false) {
        // this.data.rate = null;
        this.data.logs = null;
        this.data.office = null;
        if (this.data.customer) {
          this.hideCustomer();
        }
        if (this.data.property) {
          this.hideProperty();
        }
      } else {
        this.infoVisible = true;
      }
    } else {
      this.infoVisible = true;
    }
  }

  private hideCustomer() {
    if (this.data.customer.name) {
      this.data.customer.name = '******';
    }
    if (this.data.customer.surname) {
      this.data.customer.surname = '******';
    }
    if (this.data.customer.phone) {
      this.data.customer.phone = '******';
    }
    if (this.data.customer.mobilePhone) {
      this.data.customer.mobilePhone = '******';
    }
    if (this.data.customer.email) {
      this.data.customer.email = '******';
    }
    if (this.data.customer.facebook) {
      this.data.customer.facebook = '******';
    }
    if (this.data.customer.instagram) {
      this.data.customer.instagram = '******';
    }
    // if (this.data.customer.dob) {
    //   this.data.customer.dob = null;
    // }
    // if (this.data.customer.nationality) {
    //   this.data.customer.nationality = '******';
    // }
    // if (this.data.customer.anniversaryDay) {
    //   this.data.customer.anniversaryDay = null;
    // }
    // if (this.data.customer.religion) {
    //   this.data.customer.religion = '******';
    // }
    if (this.data.customer.profesion) {
      this.data.customer.profesion = '******';
    }
    // if (this.data.customer.language) {
    //   this.data.customer.language = '******';
    // }
    if (this.data.customer.note) {
      this.data.customer.note = '******';
    }
    // if (this.data.customer.callbackDate) {
    //   this.data.customer.callbackDate = null;
    // }
    if (this.data.property.link) {
      this.data.property.link = '******';
    }
    if (this.data.customer.motivate) {
      this.data.customer.motivate = 0;
    }
    if (this.data.customer.relationship) {
      this.data.customer.relationship = 0;
    }
  }

  private hideProperty() {
    if (this.data.property.type) {
      this.data.property.type = 'OTHER';
    }
    if (this.data.property.bed) {
      this.data.property.bed = 0;
    }
    if (this.data.property.bath) {
      this.data.property.bath = 0;
    }
    if (this.data.property.car) {
      this.data.property.car = 0;
    }
    if (this.data.property.land) {
      this.data.property.land = 0;
    }
    if (this.data.property.sellPrice) {
      this.data.property.sellPrice = -1;
    }
    if (this.data.property.rentPrice) {
      this.data.property.rentPrice = -1;
    }
    if (this.data.property.askingPrice) {
      this.data.property.askingPrice = -1;
    }
    // if (this.data.property.otmDate) {
    //   this.data.property.otmDate = null;
    // }
    // if (this.data.property.vacDate) {
    //   this.data.property.vacDate = null;
    // }
    // if (this.data.property.purchaseDate) {
    //   this.data.property.purchaseDate = null;
    // }
    // if (this.data.property.firstEnqDate) {
    //   this.data.property.firstEnqDate = null;
    // }
    // if (this.data.property.enqDate) {
    //   this.data.property.enqDate = null;
    // }
    if (this.data.property.rentCompany) {
      this.data.property.rentCompany = null;
    }
    if (this.data.property.sellCompany) {
      this.data.property.sellCompany = null;
    }
  }

  openGoogleMap() {
    // let code: string;
    // if (this.data.address.street.district.code) {
    //   const fullCode = this.data.address.street.district.code;
    //   code = fullCode.substr(0, 4);
    // }
    let url = StringUtils.getShortAddress(this.data?.address);
    url = url.replace(' ', '+').replace('/', '%2F').replace('&', '%26');
    // url += '+' + code;
    window.open('https://www.google.com/maps/search/' + url, '_blank');
  }

  hasSROV() {
    return this.data.property.otm || this.data.property.rent || this.data.property.vac || this.data.property.sell;
  }

  openHome(prospectId) {
    this.router.navigate(['/pages/buyer/open-home'], { queryParams: { prospectId: prospectId } });
  }

  onSelectRate(event: any) {
    const rate = Number(event.target.value);
    if (rate === 2) {
      this.prospectForm.controls.relationship.setValue(2);
      this.prospectForm.controls.motivate.setValue(2);
    }
    if (rate === 1) {
      this.prospectForm.controls.relationship.setValue(1);
      this.prospectForm.controls.motivate.setValue(1);
    }
    if (rate === 0) {
      this.prospectForm.controls.relationship.setValue(1);
      this.prospectForm.controls.motivate.setValue(1);
    }
  }

  searchAddress() {
    const key = 'realestate';
    if (this.data.address) {
      let urlAddress = StringUtils.getFullAddress(this.data.address);
      urlAddress = urlAddress.replace(' ', '+').replace('/', '%2F').replace('&', '%26');
      urlAddress = urlAddress + '+' + key;
      window.open('https://www.google.com/search?q=' + urlAddress, '_blank');
    }
  }

  displayRentalDetails() {
    this.dialogService.open(RentalDetailsComponent, {
      context: {
        data: this.data,
      },
    }).onClose.subscribe(res => {
      if (res) {
        this.prospectForm.controls.rentPrice.patchValue(res.property.rentPrice);
        this.submit();
      }
    });
  }

  initAdditionalPhoneLists() {
    this.additionalPhone = [];
    this.additionalMobilePhone = [];
    if (this.data?.customer?.customerAdditionalInfo && this.data?.customer?.customerAdditionalInfo?.length > 0) {
      this.additionalPhone = this.data.customer.customerAdditionalInfo.filter(info => info.phoneNumberType === 'PHONE');
      this.additionalMobilePhone = this.data.customer.customerAdditionalInfo.filter(info => info.phoneNumberType === 'MPHONE');
    }
  }

  addPhoneNumber(type: string) {
    const info = new CustomerAdditionalInfo();
    info.phoneNumberType = type;
    info.customerId = this.data.customer.customerId;
    if (type === 'PHONE') {
      this.additionalPhone.push(info);
    } else {
      this.additionalMobilePhone.push(info);
    }
  }

  removePhoneNumber(info: CustomerAdditionalInfo, index: number) {
    if (info.customerAdditionalInfoId) {
      info.isDeleted = true;
    } else {
      if (info.phoneNumberType === 'PHONE') {
        this.additionalPhone.splice(index, 1);
      } else {
        this.additionalMobilePhone.splice(index, 1);
      }
    }
  }

  setPrimary(info: CustomerAdditionalInfo, index: number) {
    const name = this.prospectForm.controls.name.value;
    const surname = this.prospectForm.controls.surname.value;
    if (info.name && info.name.trim() !== '') {
      const nameParts = info.name.trim()?.split(' ');
      if (nameParts.length > 1) {
        this.prospectForm.controls.name.patchValue(nameParts[0]);
        this.prospectForm.controls.surname.patchValue(nameParts[1]);
      } else {
        this.prospectForm.controls.name.patchValue(nameParts[0]);
        this.prospectForm.controls.surname.patchValue(null);
      }
    } else {
      this.prospectForm.controls.name.patchValue(null);
      this.prospectForm.controls.surname.patchValue(null);
    }
    if (!name && !surname) {
      info.name = null;
    } else {
      info.name = (name + ' ' + surname).trim();
    }
    if (info.phoneNumberType === 'PHONE') {
      const phone = this.prospectForm.controls.phone.value;
      this.prospectForm.controls.phone.patchValue(info.phoneNumber);
      if (StringUtils.isNotEmpty(phone)) {
        info.phoneNumber = phone;
      } else {
        this.removePhoneNumber(info, index);
      }
      if (StringUtils.isNotEmpty(this.prospectForm.controls.mobilePhone.value)) {
        const temp = this.prospectForm.controls.mobilePhone.value;
        this.prospectForm.controls.mobilePhone.patchValue(null);
        const newInfo = new CustomerAdditionalInfo();
        newInfo.name = (name + ' ' + surname).trim();
        newInfo.phoneNumber = temp;
        newInfo.phoneNumberType = 'MPHONE';
        newInfo.customerId = this.data.customer.customerId;
        this.additionalMobilePhone.push(newInfo);
      }
    } else {
      const phone = this.prospectForm.controls.mobilePhone.value;
      this.prospectForm.controls.mobilePhone.patchValue(info.phoneNumber);
      if (StringUtils.isNotEmpty(phone)) {
        info.phoneNumber = phone;
      } else {
        this.removePhoneNumber(info, index);
      }
      if (StringUtils.isNotEmpty(this.prospectForm.controls.phone.value)) {
        const temp = this.prospectForm.controls.phone.value;
        this.prospectForm.controls.phone.patchValue(null);
        const newInfo = new CustomerAdditionalInfo();
        newInfo.name = (name + ' ' + surname).trim();
        newInfo.phoneNumber = temp;
        newInfo.phoneNumberType = 'PHONE';
        newInfo.customerId = this.data.customer.customerId;
        this.additionalPhone.push(newInfo);
      }
    }
  }

  isChangeRate() {
    if (this.data?.rate === 3 && this.data?.tasks && this.data.tasks.length > 0) {
      this.isNotCount = !this.isNotCount;
      if (this.isNotCount) {
        this.autoSetProspectRate();
      }
    }
  }
}
