import { Component, EventEmitter, OnDestroy, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { ProspectService } from '../../../../shared/services/prospect.service';
import { RestResult } from '../../../../shared/models/response/rest-result';
import { ClientBoard } from '../../../../shared/models/response/prospect/client-board';
import { CityService } from '../../../../shared/services/city.service';
import { District } from '../../../../shared/models/response/address/district';
import { Street } from '../../../../shared/models/response/address/street';
import { City } from '../../../../shared/models/response/address/city';
import { SearchRequest } from '../../../../shared/models/request/search-request';
import { SuburbService } from '../../../../shared/services/suburb.service';
import { StreetService } from '../../../../shared/services/street.service';
import { Farm } from '../../../../shared/models/response/address/farm';
import { Zone } from '../../../../shared/models/response/address/zone';
import { Company } from '../../../../shared/models/response/prospect/company';
import { TaskType } from '../../../../shared/models/response/task-type';
import { TaskTypeService } from '../../../../shared/services/task-type.service';
import { CompanyService } from '../../../../shared/services/company.service';
import { ProspectSearchRequest } from '../../../../shared/models/request/prospect/prospect-search-request';
import { ProspectListBasicComponent } from '../prospect-list-basic/prospect-list-basic.component';
import { LocalService } from '../../../../shared/services/local.service';
import { Constant } from '../../../../shared/common/constant';
import { AuthService } from '../../../../shared/services/auth.service';
import { User } from '../../../../shared/models/response/user';
import { UserUtils } from '../../../../shared/common/user-utils';
import { ComponentPermission } from '../../../../component.permission';
import { CommonService } from '../../../../shared/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DateUtils } from '../../../../shared/common/date-utils';
import { EmailGroupRequest } from '../../../../shared/models/request/email-group-request';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'app-client-board',
  templateUrl: './client-board.component.html',
  styleUrls: ['./client-board.component.scss'],
})
export class ClientBoardComponent implements OnInit {
  @ViewChild(ProspectListBasicComponent) prospectListBasicComponent: ProspectListBasicComponent;
  @Output() emailGroupRequest = new EventEmitter();
  ComponentPermission = ComponentPermission;
  districts: District[];
  streets: Street[];
  cities: City[];
  farms: Farm[];
  zones: Zone[];
  companies: Company[];
  taskTypes: TaskType[];
  isDisplayed = false;
  selectedGroup: boolean;

  isDisplay: boolean;
  clientBoard: ClientBoard;
  cacheClientBoard = new ClientBoard();
  totalTypeScores: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  cacheTotalTypeScores: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  levelLabels: string[] = [];
  searchRequest = new ProspectSearchRequest();
  filteredDistricts: District[] = [];
  filteredZones: Zone[] = [];
  filteredStreets: Street[] = [];
  filteredFarms: Farm[] = [];
  categoryIds: number[] = [];
  currentUser: User;
  userUtils = UserUtils;
  isPercentageView = false;
  isLowLevelUser = false;
  icon = 'plus-outline';
  additionalColumns = false;
  additionalLabels = ['T10-S3', '1M', '3M', '6M'];
  rateColspan = 10;
  saleColspan = 5;
  potentialCommission: number;
  potentialRentalValue: number;
  param = {};
  isFiltered = false;
  rentalTooltip: string;
  filteredPostcode: string[] = [];
  fromPurchaseDate: Date;
  toPurchaseDate: Date;

  constructor(private cityService: CityService,
              private prospectService: ProspectService,
              private suburbService: SuburbService,
              private taskTypeService: TaskTypeService,
              private localService: LocalService,
              private streetService: StreetService,
              private companyService: CompanyService,
              private authService: AuthService,
              private commonService: CommonService,
              private route: ActivatedRoute,
              private router: Router,
              @Optional() protected ref: NbDialogRef<ClientBoardComponent>) {
  }

  ngOnInit(): void {
    this.param = this.route.snapshot.queryParams;
    this.currentUser = this.authService.currentUser;
    if (!this.userUtils.userIsAdmin(this.currentUser)) {
      this.onSelectUser(this.currentUser.userId);
    }
    this.initCategory();
    this.initFilter();
    this.preLoadAddressForFilter();
    this.search();
    this.getTaskTypes();
    this.initHorizontalLabel();
  }

  initCategory() {
    this.categoryIds = [];
    // if (localStorage.getItem(Constant.CLIENT_CATEGORY_LIST)) {
    //   const categories = JSON.parse(localStorage.getItem(Constant.CLIENT_CATEGORY_LIST));
    //   categories.forEach(category => {
    //     this.categoryIds.push(category.categoryId);
    //   });
    // }
  }

  getTaskTypes() {
    const taskTypeSearchRequest: SearchRequest = new SearchRequest();
    taskTypeSearchRequest.orders = [];
    taskTypeSearchRequest.orders.push(
      {
        left: 'name',
        right: 'asc',

      },
    );
    this.taskTypeService.search(taskTypeSearchRequest).subscribe((result: RestResult) => {
      this.taskTypes = result.data;
    });
  }

  initHorizontalLabel() {
    if (ComponentPermission.isAccess(ComponentPermission.USER_LOW_LEVEL)) {
      for (let i = 0; i <= 4; i++) {
        if (i < 2) {
          this.levelLabels.push('level ' + i);
        } else if (i === 2) {
          this.levelLabels.push('email');
        } else if (i === 3) {
          this.levelLabels.push('hphone');
        } else if (i === 4) {
          this.levelLabels.push('mphone');
        }
      }
    } else {
      for (let i = 0; i <= 9; i++) {
        if (i < 2) {
          this.levelLabels.push('level ' + i);
        } else if (i === 2) {
          this.levelLabels.push('email');
        } else if (i === 3) {
          this.levelLabels.push('hphone');
        } else if (i === 4) {
          this.levelLabels.push('mphone');
        } else {
          this.levelLabels.push('level ' + (i - 2));
        }
      }
    }
  }

  initFilter() {
    const searchRequest = new SearchRequest();
    searchRequest.offset = 0;
    searchRequest.limit = 10000;
    searchRequest.orders = new Array();
    searchRequest.orders.push(
      {
        left: 'name',
        right: 'asc',

      },
    );
    this.companyService.search(searchRequest).subscribe((result: RestResult) => {
      this.companies = result?.data;
    });
    searchRequest.conditionType = 'AND';
    searchRequest.conditions = [
      {
        left: 'status',
        middle: '=',
        right: 'ACTIVE',
      },
    ];
    const self = this;
    this.cityService.search(searchRequest).subscribe((result: RestResult) => {
      self.cities = result.data;
    });
  }

  onCityChange(cityId: number) {
    this.searchRequest.cityId = cityId;
    this.searchRequest.zoneId = undefined;
    this.searchRequest.districtId = undefined;
    this.searchRequest.farmId = undefined;
    this.searchRequest.streetId = undefined;
    if (cityId) {
      this.localService.districts(cityId).subscribe(result => {
        this.filteredDistricts = result;
        this.filteredPostcode = this.localService.postcodes(this.filteredDistricts);
      });
      // this.localService.zones(cityId).subscribe(result => {
      //   this.filteredZones = result;
      // });
    }
  }

  // onZoneChange(zoneId: number) {
  //   this.searchRequest.zoneId = zoneId;
  //   this.searchRequest.districtId = undefined;
  //   this.searchRequest.streetId = undefined;
  //   this.searchRequest.farmId = undefined;
  //   if (zoneId) {
  //     this.localService.districtsByZone(zoneId).subscribe(result => {
  //       this.filteredDistricts = result;
  //     });
  //   } else {
  //     this.localService.districts(this.searchRequest.cityId).subscribe(result => {
  //       this.filteredDistricts = result;
  //     });
  //   }
  // }

  // onPostcodeChange(postcode: string) {
  //   this.searchRequest.postcode = postcode;
  //   this.searchRequest.districtId = undefined;
  //   this.searchRequest.streetId = undefined;
  //   this.searchRequest.farmId = undefined;
  //   if (postcode) {
  //     this.localService.districtByPostcode(postcode).subscribe(result => {
  //       this.filteredDistricts = result;
  //     });
  //   } else {
  //     this.localService.districts(this.searchRequest.cityId).subscribe(result => {
  //       this.filteredDistricts = result;
  //     });
  //   }
  // }

  onSuburbChange(districtId: number) {
    this.searchRequest.districtId = districtId;
    this.searchRequest.streetId = undefined;
    this.searchRequest.farmId = undefined;
    if (districtId) {
      this.localService.streets(districtId).subscribe(result => {
        this.filteredStreets = result;
      });
      this.localService.farms(districtId).subscribe(result => {
        this.filteredFarms = result;
      });
    }
  }

  onFarmChange(farmId: number) {
    this.searchRequest.farmId = farmId;
    this.searchRequest.streetId = undefined;
    if (farmId) {
      this.localService.streetsByFarm(farmId).subscribe(result => {
        this.filteredStreets = result;
      });
    } else {
      this.localService.streets(this.searchRequest.districtId).subscribe(result => {
        this.filteredStreets = result;
      });
    }
  }

  onStreetChange(streetId: number) {

  }

  loadStreets() {

  }

  onSearchButtonClick() {
    this.isFiltered = true;
    if (this.param['selectedProspectId']) {
      delete this.param['selectedProspectId'];
    }
    if (this.param['selectedStreetId']) {
      delete this.param['selectedStreetId'];
    }
    if (this.param['i']) {
      delete this.param['i'];
    }
    if (this.param['type']) {
      delete this.param['type'];
    }
    this.appendFilterToParameter();
    this.navigateWithParam();
    this.search();
  }

  search() {
    // const self = this;
    if (this.isFiltered === false) {
      this.appendParameterToSearchRequest();
    }
    this.isDisplayed = false;
    // this.searchRequest.categoryIds = this.categoryIds;
    this.searchRequest.officeId = this.currentUser.office?.officeId;
    this.prospectService.searchClientBoard(this.searchRequest).subscribe((result: RestResult) => {
      if (result && result.data) {
        this.clientBoard = result.data;
        if (ComponentPermission.isAccess(ComponentPermission.USER_LOW_LEVEL)) {
          this.totalTypeScores = [0, 0, 0, 0, 0];
          const values = Object.values(this.clientBoard.typeScores);
          for (let j = 0; j < values.length; j++) {
            values[j].splice(5, 9);
          }
          const valuesS = Object.values(this.clientBoard.statusScores);
          for (let j = 0; j < valuesS.length; j++) {
            valuesS[j].splice(5, 9);
          }
          this.isLowLevelUser = true;
        } else {
          this.totalTypeScores = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        }
        const keys = Object.keys(this.clientBoard.typeScores);
        if (keys.length > 0) {
          keys.forEach((key) => {
            const value = this.clientBoard.typeScores[key];
            for (let i = 0; i < this.totalTypeScores.length; i++) {
              this.totalTypeScores[i] += value[i];
            }
          });
        }
        this.restoreSelectedState();
        this.cacheTotalTypeScores = JSON.parse(JSON.stringify(this.totalTypeScores));
        this.cacheClientBoard = JSON.parse(JSON.stringify(this.clientBoard));
        this.switchToNumber();
      }
    });
  }

  resetRequest() {
    this.searchRequest.rate = null;
    this.searchRequest.type = null;
    this.searchRequest.hasEmail = null;
    this.searchRequest.hasPhone = null;
    this.searchRequest.hasMobilePhone = null;
    this.searchRequest.relationship = null;
    this.searchRequest.motivate = null;
    this.searchRequest.sell = null;
    this.searchRequest.rent = null;
    this.searchRequest.otm = null;
    this.searchRequest.vac = null;
    this.searchRequest.categoryIds = [];
  }

  loadProspect(i: number, type: string, scoreType?: string) {
    if (scoreType === 'statusScore') {
      if (this.clientBoard.statusScores[type.toUpperCase()][i] <= 0) {
        this.isDisplayed = true;
        this.prospectListBasicComponent.groupWrapper = null;
        this.potentialCommission = 0;
        this.potentialRentalValue = 0;
        this.prospectListBasicComponent.source.load([]);
        return;
      }
    } else if (scoreType === 'typeScore') {
      if (this.clientBoard.typeScores[type.toUpperCase()][i] <= 0) {
        this.isDisplayed = true;
        this.prospectListBasicComponent.groupWrapper = null;
        this.potentialCommission = 0;
        this.potentialRentalValue = 0;
        this.prospectListBasicComponent.source.load([]);
        return;
      }
    } else if (scoreType === 'total') {
      if (this.totalTypeScores[i] <= 0) {
        this.isDisplayed = true;
        this.prospectListBasicComponent.groupWrapper = null;
        this.potentialCommission = 0;
        this.potentialRentalValue = 0;
        this.prospectListBasicComponent.source.load([]);
        return;
      }
    }
    if (!ComponentPermission.isAccess(ComponentPermission.CLIENT_BOARD_CLICK)) {
      this.commonService.warning('You do not have permission to perform this action');
      return;
    }
    this.resetRequest();
    // this.appendSelectedCellToParameter(i, type);
    // this.navigateWithParam();
    this.isDisplayed = true;
    this.searchRequest.categoryIds = this.categoryIds;
    // set rate and phone
    if (i < 2) {
      this.searchRequest.rate = i;
    } else if (i === 2) {
      this.searchRequest.rate = 2;
      this.searchRequest.hasEmail = true;
    } else if (i === 3) {
      this.searchRequest.rate = 2;
      this.searchRequest.hasPhone = true;
    } else if (i === 4) {
      this.searchRequest.rate = 2;
      this.searchRequest.hasMobilePhone = true;
    } else if (i >= 5 && i <= 9) {
      this.searchRequest.rate = 3;
      if (i > 5) {
        this.searchRequest.categoryIds = [];
        switch (i) {
          case 6:
            this.searchRequest.categoryIds.push(1);
            break;
          case 7:
            this.searchRequest.categoryIds.push(5);
            break;
          case 8:
            this.searchRequest.categoryIds.push(6);
            break;
          case 9:
            this.searchRequest.categoryIds.push(7);
            break;
        }
      }
    } else if (i > 9) {
      this.searchRequest.rate = i - 6;
    }

    // set property
    if (type === 'sell') {
      this.searchRequest.sell = true;
    } else if (type === 'rent') {
      this.searchRequest.rent = true;
    } else if (type === 'otm') {
      this.searchRequest.otm = true;
    } else if (type === 'vac') {
      this.searchRequest.vac = true;
    } else {
      this.searchRequest.type = type;
    }

    this.searchRequest.sortType = 'ASC';
    this.searchRequest.offset = 0;
    this.searchRequest.limit = 20;
    if (this.selectedGroup === true) {
      this.ref.close(JSON.stringify(this.searchRequest));
      return;
    }
    this.prospectListBasicComponent.filterCondition(this.searchRequest);
    if (i >= 2) {
      this.prospectService.getPotentialCommission(this.searchRequest).subscribe(result => {
        this.potentialCommission = result.data;
      });
    } else {
      this.potentialCommission = null;
    }
    if (type === 'rent' || type === 'vac') {
      this.prospectService.getPotentialRentalValue(this.searchRequest).subscribe(result => {
        const rate = this.currentUser.office.officeProperty.managementRate ? this.currentUser.office.officeProperty.managementRate : 5;
        this.rentalTooltip = 'Potential Rental Value = Total Rental Value * 52 weeks * ' + rate + '% (Management Rate)';
        this.potentialRentalValue = result.data * rate / 100;
      });
    } else {
      this.potentialRentalValue = null;
    }
  }

  switchToPercentage() {
    //  Store level 3 value to calculate ratio of additional columns (t10-s3, 1m, 3m, 6m)
    let cloneTotalTypeScoreLv3: number;
    if (!this.isLowLevelUser) {
      cloneTotalTypeScoreLv3 = this.totalTypeScores[5];
    }
    if (this.totalTypeScores[1] === 0) {
      this.totalTypeScores[2] = 0;
      this.totalTypeScores[3] = 0;
      this.totalTypeScores[4] = 0;
      if (!this.isLowLevelUser) {
        this.totalTypeScores[5] = 0;
        this.totalTypeScores[10] = 0;
      }
    } else {
      this.totalTypeScores[2] = parseFloat((this.totalTypeScores[2] / this.totalTypeScores[1] * 100).toFixed(2));
      this.totalTypeScores[3] = parseFloat((this.totalTypeScores[3] / this.totalTypeScores[1] * 100).toFixed(2));
      this.totalTypeScores[4] = parseFloat((this.totalTypeScores[4] / this.totalTypeScores[1] * 100).toFixed(2));
      if (!this.isLowLevelUser) {
        this.totalTypeScores[5] = parseFloat((this.totalTypeScores[5] / this.totalTypeScores[1] * 100).toFixed(2));
        this.totalTypeScores[10] = parseFloat((this.totalTypeScores[10] / this.totalTypeScores[1] * 100).toFixed(2));
      }
    }
    if (!this.isLowLevelUser) {
      if (cloneTotalTypeScoreLv3 === 0) {
        this.totalTypeScores[6] = 0;
        this.totalTypeScores[7] = 0;
        this.totalTypeScores[8] = 0;
        this.totalTypeScores[9] = 0;
      } else {
        this.totalTypeScores[6] = parseFloat((this.totalTypeScores[6] / cloneTotalTypeScoreLv3 * 100).toFixed(2));
        this.totalTypeScores[7] = parseFloat((this.totalTypeScores[7] / cloneTotalTypeScoreLv3 * 100).toFixed(2));
        this.totalTypeScores[8] = parseFloat((this.totalTypeScores[8] / cloneTotalTypeScoreLv3 * 100).toFixed(2));
        this.totalTypeScores[9] = parseFloat((this.totalTypeScores[9] / cloneTotalTypeScoreLv3 * 100).toFixed(2));
      }
    }
    const keysTS = Object.keys(this.clientBoard.typeScores);
    if (keysTS.length > 0) {
      keysTS.forEach((key) => {
        const value = this.clientBoard.typeScores[key];
        //  Store level 3 value to calculate ratio of additional columns (t10-s3, 1m, 3m, 6m)
        let cloneValueLevel3: number;
        if (!this.isLowLevelUser) {
          cloneValueLevel3 = value[5];
        }
        //  Calculate ratio of lv2-3-4 with lv1
        if (value[1] === 0) {
          value[2] = 0;
          value[3] = 0;
          value[4] = 0;
          if (!this.isLowLevelUser) {
            value[5] = 0;
            value[10] = 0;
          }
        } else {
          value[2] = parseFloat((value[2] / value[1] * 100).toFixed(2));
          value[3] = parseFloat((value[3] / value[1] * 100).toFixed(2));
          value[4] = parseFloat((value[4] / value[1] * 100).toFixed(2));
          if (!this.isLowLevelUser) {
            value[5] = parseFloat((value[5] / value[1] * 100).toFixed(2));
            value[10] = parseFloat((value[10] / value[1] * 100).toFixed(2));
          }
        }
        //  Calculate ratio of additional columns with lv3
        if (!this.isLowLevelUser) {
          if (cloneValueLevel3 === 0) {
            value[6] = 0;
            value[7] = 0;
            value[8] = 0;
            value[9] = 0;
          } else {
            value[6] = parseFloat((value[6] / cloneValueLevel3 * 100).toFixed(2));
            value[7] = parseFloat((value[7] / cloneValueLevel3 * 100).toFixed(2));
            value[8] = parseFloat((value[8] / cloneValueLevel3 * 100).toFixed(2));
            value[9] = parseFloat((value[9] / cloneValueLevel3 * 100).toFixed(2));
          }
        }
      });
    }
    const keysSS = Object.keys(this.clientBoard.statusScores);
    if (keysSS.length > 0) {
      keysSS.forEach((key) => {
        const value = this.clientBoard.statusScores[key];
        //  Store level 3 value to calculate ratio of additional columns (t10-s3, 1m, 3m, 6m)
        let cloneValueLevel3: number;
        if (!this.isLowLevelUser) {
          cloneValueLevel3 = value[5];
        }
        if (value[1] === 0) {
          value[2] = 0;
          value[3] = 0;
          value[4] = 0;
          if (!this.isLowLevelUser) {
            value[5] = 0;
            value[10] = 0;
          }
        } else {
          value[2] = parseFloat((value[2] / value[1] * 100).toFixed(2));
          value[3] = parseFloat((value[3] / value[1] * 100).toFixed(2));
          value[4] = parseFloat((value[4] / value[1] * 100).toFixed(2));
          if (!this.isLowLevelUser) {
            value[5] = parseFloat((value[5] / value[1] * 100).toFixed(2));
            value[10] = parseFloat((value[10] / value[1] * 100).toFixed(2));
          }
        }
        if (!this.isLowLevelUser) {
          if (cloneValueLevel3 === 0) {
            value[6] = 0;
            value[7] = 0;
            value[8] = 0;
            value[9] = 0;
          } else {
            value[6] = parseFloat((value[6] / cloneValueLevel3 * 100).toFixed(2));
            value[7] = parseFloat((value[7] / cloneValueLevel3 * 100).toFixed(2));
            value[8] = parseFloat((value[8] / cloneValueLevel3 * 100).toFixed(2));
            value[9] = parseFloat((value[9] / cloneValueLevel3 * 100).toFixed(2));
          }
        }
      });
    }
    this.isPercentageView = true;
  }

  switchToNumber() {
    this.clientBoard = JSON.parse(JSON.stringify(this.cacheClientBoard));
    this.totalTypeScores = JSON.parse(JSON.stringify(this.cacheTotalTypeScores));
    this.isPercentageView = false;
  }


  onRemoveCategory(categoryId: number) {
    // if (!this.categoryIds) {
    //   this.categoryIds = [];
    // }
    const index = this.searchRequest.categoryIds.indexOf(categoryId);
    if (index !== -1) {
      this.searchRequest.categoryIds.splice(index, 1);
    }
  }

  onSelectCategory(categoryId: number) {
    if (!this.searchRequest.categoryIds) {
      this.searchRequest.categoryIds = [];
    }
    const index = this.searchRequest.categoryIds.indexOf(categoryId);
    if (index === -1) {
      this.searchRequest.categoryIds.push(categoryId);
    }
  }

  onRemoveUser(userId: number) {
    this.searchRequest.userId = null;
  }

  onSelectUser(userId: number) {
    this.searchRequest.userId = userId;
  }

  // onFromPurchaseDateChange(fromPurchaseDate: number) {
  //   this.searchRequest.fromPurchaseDate = fromPurchaseDate;
  // }
  //
  // onToPurchaseDateChange(toPurchaseDate: number) {
  //   this.searchRequest.toPurchaseDate = toPurchaseDate;
  // }

  onMotivateTypeChange(motivateType: string) {
    this.searchRequest.motivateType = motivateType;
  }

  onReligionChange(religion: string) {
    this.searchRequest.religion = religion;
  }

  onNationalityChange(nationality: string) {
    this.searchRequest.nationality = nationality;
  }

  onAgeChange(condition: any) {
    this.searchRequest.age = condition.age;
    this.searchRequest.ageIsOver = condition.ageIsOver;
  }

  onFromPurchaseDateChange(date: Date) {
    const d = date.getDate();
    const m = date.getMonth();
    const y = date.getFullYear();
    this.searchRequest.fromPurchaseDate = DateUtils.getStartOfDate(d, m, y).valueOf();
    this.fromPurchaseDate = date;
  }

  onToPurchaseDateChange(date: Date) {
    const d = date.getDate();
    const m = date.getMonth();
    const y = date.getFullYear();
    this.searchRequest.toPurchaseDate = DateUtils.getEndOfDate(d, m, y).valueOf();
    this.toPurchaseDate = date;
  }

  toggleAdditionalLevel3Columns() {
    if (this.additionalColumns === false) {
      this.additionalColumns = true;
      this.icon = 'minus-outline';
      this.rateColspan = 14;
      this.saleColspan = 9;
      for (let i = 0; i < this.additionalLabels.length; i++) {
        const index = i + 6;
        this.levelLabels.splice(index, 0, this.additionalLabels[i]);
      }
    } else {
      this.additionalColumns = false;
      this.icon = 'plus-outline';
      this.rateColspan = 10;
      this.saleColspan = 5;
      this.levelLabels.splice(6, 4);
    }
  }

  preLoadAddressForFilter() {
    if (this.route.snapshot.queryParamMap.keys.length === 0) {
      return;
    }
    // const cityId = this.route.snapshot.queryParamMap.get('cityId');
    const postcode = Number(this.route.snapshot.queryParamMap.get('postcode'));
    const districtId = this.route.snapshot.queryParamMap.get('districtId');
    const farmId = this.route.snapshot.queryParamMap.get('farmId');
    // if (cityId) {
    //   this.localService.districts(Number(cityId)).subscribe(result => {
    //     this.filteredDistricts = result;
    //     this.filteredPostcode = this.localService.postcodes(this.filteredDistricts);
    //   });
    //   // this.localService.zones(Number(cityId)).subscribe(result => {
    //   //   this.filteredZones = result;
    //   // });
    // }
    if (postcode) {
      this.localService.districtByPostcode(postcode).subscribe(r => {
        this.filteredDistricts = r;
      });
    }
    if (districtId) {
      this.localService.streets(Number(districtId)).subscribe(result => {
        this.filteredStreets = result;
      });
      this.localService.farms(Number(districtId)).subscribe(result => {
        this.filteredFarms = result;
      });
    }
    if (farmId) {
      this.localService.streetsByFarm(Number(farmId)).subscribe(result => {
        this.filteredStreets = result;
      });
    }
  }

  appendFilterToParameter() {
    // if (this.searchRequest.cityId) {
    //   this.param = {...this.param, cityId: this.searchRequest.cityId};
    // } else {
    //   delete this.param['cityId'];
    //   delete this.param['postcode'];
    //   delete this.param['districtId'];
    // }
    // if (this.searchRequest.zoneId) {
    //   this.param = {...this.param, zoneId: this.searchRequest.zoneId};
    // } else {
    //   delete this.param['zoneId'];
    // }
    if (this.searchRequest.postcode) {
      this.param = { ...this.param, postcode: this.searchRequest.postcode };
    } else {
      delete this.param['postcode'];
      delete this.param['districtId'];
      delete this.param['streetId'];
    }
    if (this.searchRequest.districtId) {
      this.param = { ...this.param, districtId: this.searchRequest.districtId };
    } else {
      delete this.param['districtId'];
      delete this.param['farmId'];
      delete this.param['streetId'];
    }
    if (this.searchRequest.farmId) {
      this.param = { ...this.param, farmId: this.searchRequest.farmId };
    } else {
      delete this.param['farmId'];
    }
    if (this.searchRequest.streetId) {
      this.param = { ...this.param, streetId: this.searchRequest.streetId };
    } else {
      delete this.param['streetId'];
    }
    if (this.searchRequest.categoryIds && this.searchRequest.categoryIds.length > 0) {
      this.param = { ...this.param, categoryIds: this.searchRequest.categoryIds };
    } else {
      delete this.param['categoryIds'];
    }
    if (this.searchRequest.userId) {
      this.param = { ...this.param, userId: this.searchRequest.userId };
    } else {
      delete this.param['userId'];
    }
    if (this.searchRequest.age) {
      this.param = { ...this.param, age: this.searchRequest.age };
    } else {
      delete this.param['age'];
    }
    if (this.searchRequest.ageIsOver === true || this.searchRequest.ageIsOver === false) {
      this.param = { ...this.param, ageIsOver: this.searchRequest.ageIsOver };
    } else {
      delete this.param['ageIsOver'];
    }
    if (this.searchRequest.nationality) {
      this.param = { ...this.param, nationality: this.searchRequest.nationality };
    } else {
      delete this.param['nationality'];
    }
    if (this.searchRequest.fromPurchaseDate) {
      this.param = { ...this.param, fromPurchaseDate: this.searchRequest.fromPurchaseDate };
    } else {
      delete this.param['fromPurchaseDate'];
    }
    if (this.searchRequest.toPurchaseDate) {
      this.param = { ...this.param, toPurchaseDate: this.searchRequest.toPurchaseDate };
    } else {
      delete this.param['toPurchaseDate'];
    }
    if (this.searchRequest.motivateType) {
      this.param = { ...this.param, motivateType: this.searchRequest.motivateType };
    } else {
      delete this.param['motivateType'];
    }
    if (this.searchRequest.religion) {
      this.param = { ...this.param, religion: this.searchRequest.religion };
    } else {
      delete this.param['religion'];
    }
  }

  //  push param to current url
  navigateWithParam() {
    this.router.navigate([], {
      queryParams: this.param,
    });
  }

  appendParameterToSearchRequest() {
    const params = this.route.snapshot.queryParams;
    if (params['cityId']) {
      this.searchRequest.cityId = Number(params['cityId']);
    }
    if (params['zoneId']) {
      this.searchRequest.zoneId = Number(params['zoneId']);
    }
    if (params['postcode']) {
      this.searchRequest.postcode = params['postcode'];
    }
    if (params['districtId']) {
      this.searchRequest.districtId = Number(params['districtId']);
    }
    if (params['farmId']) {
      this.searchRequest.farmId = Number(params['farmId']);
    }
    if (params['streetId']) {
      this.searchRequest.streetId = Number(params['streetId']);
    }
    if (params['categoryIds']) {
      const categoryIds = params['categoryIds'];
      if (typeof categoryIds === 'string') {
        this.searchRequest.categoryIds = [];
        this.searchRequest.categoryIds.push(Number(categoryIds));
      } else {
        this.searchRequest.categoryIds = categoryIds.map(id => {
          return Number(id);
        });
      }
    }
    if (params['userId']) {
      this.searchRequest.userId = Number(params['userId']);
    }
    if (params['fromPurchaseDate']) {
      this.searchRequest.fromPurchaseDate = Number(params['fromPurchaseDate']);
      this.fromPurchaseDate = new Date(Number(params['fromPurchaseDate']));
    }
    if (params['toPurchaseDate']) {
      this.searchRequest.toPurchaseDate = Number(params['toPurchaseDate']);
      this.toPurchaseDate = new Date(Number(params['toPurchaseDate']));
    }
    if (params['age']) {
      this.searchRequest.age = Number(params['age']);
    }
    if (params['ageIsOver']) {
      this.searchRequest.ageIsOver = params['ageIsOver'] === 'true';
    }
    if (params['nationality']) {
      this.searchRequest.nationality = params['nationality'];
    }
    if (params['religion']) {
      this.searchRequest.religion = params['religion'];
    }
    if (params['motivateType']) {
      this.searchRequest.motivateType = params['motivateType'];
    }
  }

  appendSelectedCellToParameter(i: number, type: string) {
    if (this.isDisplayed) {
      if (this.param['selectedStreetId']) {
        delete this.param['selectedStreetId'];
      }
      if (this.param['selectedProspectId']) {
        delete this.param['selectedProspectId'];
      }
      if (this.param['tab']) {
        delete this.param['tab'];
      }
    }
    this.param = { ...this.param, i: i };
    this.param = { ...this.param, type: type };
  }

  restoreSelectedState() {
    const param = this.route.snapshot.queryParams;
    if (param['i'] && param['type']) {
      if (param['i'] >= 6 && param['i'] <= 9) {
        this.toggleAdditionalLevel3Columns();
      }
      this.loadProspect(Number(param['i']), param['type']);
    }
  }

  onListSelect(type: string, id: number) {
    if (type === 'prospect') {
      this.param = { ...this.param, selectedProspectId: id };
    }
    if (type === 'street') {
      this.param = { ...this.param, selectedStreetId: id };
      if (this.param['selectedProspectId']) {
        delete this.param['selectedProspectId'];
      }
    }
    this.navigateWithParam();
  }

  onSearchByPostcode(value: number) {
    if (value === 0) {
      this.searchRequest.postcode = undefined;
    } else {
      this.searchRequest.postcode = Number(value);
    }
    this.searchRequest.districtId = undefined;
    this.searchRequest.streetId = undefined;
    if (value && value !== 0) {
      this.localService.districtByPostcode(value).subscribe(result => {
        this.filteredDistricts = result;
      });
    }
  }

  onEmitDisplay(event: any) {
    this.isDisplay = event;
  }
}
