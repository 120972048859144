import {Component, Input, OnInit} from '@angular/core';
import {ViewCell} from 'ng2-smart-table';
import {WrapBuyer, WrapBuyerType} from './buyer-utils';
import * as moment from 'moment';
import {Task} from '../../../../shared/models/response/task';
import {StringUtils} from '../../../../shared/common/string-utils';
import {BuyerLog} from '../../../../shared/models/response/buyer/buyer-log';

@Component({
  template: `
    {{renderValue}}
  `,
  styles: [],
})
export class BuyerListCalledDateCellComponent implements ViewCell, OnInit {

  renderValue: string;

  @Input() value: string | number;
  @Input() rowData: WrapBuyer;

  ngOnInit() {
    this.setValue();
  }

  setValue(): any {
    if (!this.rowData) {
      this.renderValue = this.value ? this.value.toString() : '';
      return;
    }
    this.renderValue = '';
    if (this.rowData.type === WrapBuyerType.ITEM) {
      const data = this.rowData.data;
      if (data.lastCalledDate) {
        this.renderValue += moment(Number(data.lastCalledDate)).format('DD/MM/YYYY');
      }
      const sortedLogs = data?.logs?.sort((a: BuyerLog, b: BuyerLog) => {
        return b.createdDate - a.createdDate;
      });
      if (sortedLogs && sortedLogs.length > 0) {
        const latestCalledLog = sortedLogs.findIndex(x => x.actionType === 'CALL');
        if (latestCalledLog > -1) {
          this.renderValue += `(${StringUtils.getShortName(sortedLogs[latestCalledLog]?.fullname)})`;
        }
      }
    }
  }

}
