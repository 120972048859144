<div class="buyer-container" *appPermissionCheck="ComponentPermission.GLOBAL_PHONE">
  <nb-tab tabTitle="ALL" [active]="true">
    <div class="row">
      <div class="col-md-6 col-sm-12 left-panel mb-0 table-container"
           [ngClass]="{'popup-hide': isDisplay}"
      >
        <nb-card>
          <nb-card-header>
            <p class="title">Search result for : {{this.parameter}}</p>

            <div class="row mt-2">
              <div class="col-12 ml-3">
                <button nbButton size="tiny" hero status="danger"
                        class="mr-2"
                        [hidden]="!multipleSelectedRequest.selectedIds || multipleSelectedRequest.selectedIds.length <= 1"
                        (click)="openMergePopup()"
                        nbTooltip="Merge multiple selected customers into one, after merge other will be deleted"
                        *appPermissionCheck='ComponentPermission.GLOBAL_PHONE_MERGE'
                >
                  Merge
                </button>
                <button nbButton size="tiny" hero status="success"
                        class="mr-2"
                        [hidden]="!multipleSelectedRequest.selectedIds || multipleSelectedRequest.selectedIds.length <= 1"
                        (click)="openLinkPopup()"
                        nbTooltip="Link related client/customer into a group"
                        *appPermissionCheck='ComponentPermission.GLOBAL_PHONE_LINK'
                >
                  Link
                </button>
                <button nbButton size="tiny" hero status="info"
                        class="mr-2"
                        [hidden]="!multipleSelectedRequest.selectedIds || multipleSelectedRequest.selectedIds.length <= 1"
                        (click)="openCopyPopup()"
                        nbTooltip="Copy information from one to another (Both will have same information"
                        *appPermissionCheck='ComponentPermission.GLOBAL_PHONE_COPY'
                >
                  Copy
                </button>
                <button nbButton size="tiny" hero status="primary"
                        class="mr-2"
                        [hidden]="!multipleSelectedRequest.selectedIds || multipleSelectedRequest.prospect != 2"
                        (click)="openMovePopup()"
                        nbTooltip="Move information of one into another (first one will be emptied after move)"
                        *appPermissionCheck='ComponentPermission.GLOBAL_PHONE_MOVE'
                >
                  Move
                </button>
              </div>
            </div>

          </nb-card-header>
          <nb-card-body>
            <nb-accordion class='mb-2'>
              <nb-accordion-item>
                <nb-accordion-item-header>Filter</nb-accordion-item-header>
                <nb-accordion-item-body>
                  <div class='row'>
                    <div class="col-2 override-width">
                      <nb-select fullWidth size="tiny"
                                 placeholder="All streets"
                                 [(ngModel)]="searchRequest.streetId"
                                 [disabled]="!searchRequest?.districtId"
                      >
                        <nb-option [value]="">All streets</nb-option>
                        <nb-option *ngFor="let key of streets"
                                   [ngClass]="key.status === 'DEACTIVE' ? 'deactive-status' : ''"
                                   [value]="key.streetId">{{key.name}}</nb-option>
                      </nb-select>
                    </div>
                    <div class="col-2 override-width">
                      <nb-select fullWidth size="tiny"
                                 placeholder="All farms"
                                 [(ngModel)]="searchRequest.farmId"
                                 [disabled]="!searchRequest?.districtId">
                        <nb-option [value]="" (click)="onFarmSelectChange()">All farms</nb-option>
                        <nb-option *ngFor="let key of farms"
                                   [ngClass]="key.status === 'DEACTIVE' ? 'deactive-status' : ''"
                                   [value]="key.farmId"
                                   (click)="onFarmSelectChange()"
                        >{{key.name}}</nb-option>
                      </nb-select>
                    </div>
                    <div class="col-2 override-width">
                      <nb-select fullWidth size="tiny"
                                 placeholder="All suburbs"
                                 [(ngModel)]="searchRequest.districtId"
                                 [disabled]="!searchRequest?.cityId">
                        <nb-option [value]="" (click)="onSuburbSelectChange()">All suburbs</nb-option>
                        <nb-option *ngFor="let key of districts"
                                   [ngClass]="key.status === 'DEACTIVE' ? 'deactive-status' : ''"
                                   [value]="key.districtId"
                                   (click)="onSuburbSelectChange()"
                        >{{key.name}}</nb-option>
                      </nb-select>
                    </div>
                    <div class="col-2 override-width">
                      <nb-select fullWidth size="tiny"
                                 placeholder="All zones"
                                 [(ngModel)]="searchRequest.zoneId"
                                 [disabled]="!searchRequest?.cityId">
                        <nb-option [value]="" (click)="onZoneSelectChange()">All zones</nb-option>
                        <nb-option *ngFor="let key of zones"
                                   [ngClass]="key.status === 'DEACTIVE' ? 'deactive-status' : ''"
                                   [value]="key.zoneId"
                                   (click)="onZoneSelectChange()"
                        >{{key.name}}</nb-option>
                      </nb-select>
                    </div>
                    <div class="col-2 override-width">
                      <nb-select fullWidth size="tiny"
                                 placeholder="All cities"
                                 [(ngModel)]="searchRequest.cityId">
                        <nb-option [value]="" (click)="onCitySelectChange()">All cities</nb-option>
                        <nb-option *ngFor="let key of cities"
                                   [ngClass]="key.status === 'DEACTIVE' ? 'deactive-status' : ''"
                                   [value]="key.cityId"
                                   (click)="onCitySelectChange()"
                        >{{key.name}}</nb-option>
                      </nb-select>
                    </div>
                  </div>
                  <div class='row mt-2'>
                    <div class='col-12'>
                      <button nbButton status='primary' size='tiny' class='pull-right' (click)='filter()'>Search</button>
                      <button nbButton status='primary' size='tiny' class='pull-right mr-2' (click)='reset()'>Reset</button>
                    </div>
                  </div>
                </nb-accordion-item-body>
              </nb-accordion-item>
            </nb-accordion>
            <ng2-smart-table [settings]="settings"
                             [source]="source"
                             (userRowSelect)="selectRecord($event)"
            >
            </ng2-smart-table>
          </nb-card-body>
        </nb-card>
      </div>
      <div class="col-md-6 col-sm-12 right-panel"
           [ngClass]="{
       'popup-detail': isDisplay,
       'hide-detail': !isDisplay
       }"
      >
        <ngx-prospect-dialog
          [hidden]="!selectedProspect"
          [selectedProspect]="selectedProspect"
          [prospects]="selectedProspects"
          [companies]="companies"
          [taskTypes]="taskTypes"
          [taskStatuses]="taskStatuses"
          (editDone)="editDone($event)"
        ></ngx-prospect-dialog>
        <ngx-buyer-create
          [hidden]="!selectedBuyer"
          [cities]="cities"
          [taskTypes]="taskTypes"
          [taskStatuses]="taskStatuses"
          (editDone)="editDone($event)"
          (newDone)="newDone($event)"
          (reserveBuyer)="reserveBuyer($event)"
          (ownSet)="ownSet($event)"
        ></ngx-buyer-create>
        <div class="scroll-mobile" *ngIf="isDisplay">
          <button (click)="cancel()" nbButton size="tiny" status="warning">Cancel</button>
        </div>
      </div>
    </div>
  </nb-tab>
</div>
