<div class='row' *appPermissionCheck='ComponentPermission.CLIENT_MARKET'>
  <div class='col-md-7 col-sm-12 left-panel table-container'
       [ngClass]="{'popup-hide': isDisplay}">
    <nb-card>
      <nb-card-body class='scroll-advance'>
        <ngx-prospect-list-filter-market class='sticky-filter'
                                         *ngIf='isFilter'
                                         [phone]='searchRequest.phone'
                                         [districts]='filteredDistricts'
                                         [districtId]='searchRequest?.districtId'
                                         [streets]='filteredStreets'
                                         [streetId]='searchRequest?.streetId'
                                         [cities]='cities'
                                         [cityId]='searchRequest?.cityId'
                                         [sortTypesText]='otmSortTypesText'
                                         [sortType]='searchRequest?.sortType'
                                         [total]='groupWrapper?.totalItems'
                                         [totalOtm]='groupWrapper?.totalOtm'
                                         [totalVac]='groupWrapper?.totalVac'
                                         [totalOtmActive]='groupWrapper?.totalOtmActive'
                                         [totalOtmExpired]='groupWrapper?.totalOtmExpired'
                                         [totalVacActive]='groupWrapper?.totalVacActive'
                                         [totalVacExpired]='groupWrapper?.totalVacExpired'
                                         [otmStatuses]='otmStatuses'
                                         [otmStatus]='isOTMMode ? searchRequest?.otmStatus : searchRequest?.vacStatus'
                                         [isOTMMode]='isOTMMode'
                                         [isVACMode]='isVACMode'
                                         [farm]='selectedProspect?.address?.street?.farm?.name'
                                         [zone]='selectedProspect?.address?.street?.district?.zone?.name'
                                         [sellCompanyId]='searchRequest?.sellCompanyId'
                                         [rentCompanyId]='searchRequest?.rentCompanyId'
                                         [fromSellPrice]='searchRequest?.fromSellPrice'
                                         [toSellPrice]='searchRequest?.toSellPrice'
                                         [fromRentPrice]='searchRequest?.fromRentPrice'
                                         [toRentPrice]='searchRequest?.toRentPrice'
                                         [propertyType]='searchRequest?.type'
                                         [bed]='searchRequest?.bed'
                                         [bath]='searchRequest?.bath'
                                         [car]='searchRequest?.car'
                                         [companies]='companies'
                                         [rate]='searchRequest?.rate'
                                         [selectedYear]='selectedYear'
                                         [selectedMonth]='selectedMonth'
                                         [selectedDay]='selectedDay'
                                         [postcode]='searchRequest.postcode'
                                         (onSuburbChange)='onSuburbChange($event)'
                                         (onStreetChange)='onStreetChange($event)'
                                         (onCityChange)='onCityChange($event)'
                                         (onSortTypeChange)='onSortTypeChange($event)'
                                         (onOtmStatusChange)='onOtmStatusChange($event)'
                                         (onAddNewProspect)='addRecord()'
                                         (onOTMVACMode)='onOTMVACModeChange($event)'
                                         (onOTMModeChange)='onOTMModeChange($event)'
                                         (onVACModeChange)='onVACModeChange($event)'
                                         (onOpenShortcut)='openShortcut()'
                                         (onSellCompanyChange)='onSellCompanyChange($event)'
                                         (onRentCompanyChange)='onRentCompanyChange($event)'
                                         (onFromSellPriceChange)='onFromSellPriceChange($event)'
                                         (onToSellPriceChange)='onToSellPriceChange($event)'
                                         (onFromRentPriceChange)='onFromRentPriceChange($event)'
                                         (onToRentPriceChange)='onToRentPriceChange($event)'
                                         (onPropertyTypeChange)='onPropertyTypeChange($event)'
                                         (onBedChange)='onBedTypeChange($event)'
                                         (onBathChange)='onBathTypeChange($event)'
                                         (onCarChange)='onCarTypeChange($event)'
                                         (onSearch)='onSearch($event)'
                                         (onYearChange)='onYearChange($event)'
                                         (onMonthChange)='onMonthChange($event)'
                                         (onDayChange)='onDayChange($event)'
                                         (onSelectRate)='onSelectRate($event)'
                                         (exportMobile)='exportMobile()'
                                         (exportMail)='exportEmail()'
                                         (bulkSave)='saveBulk($event)'
                                         (onSearchByPostcode)='onSearchByPostcode($event)'
        >
        </ngx-prospect-list-filter-market>
        <div class='freeze-body-new mt-2'>
          <div class='row d-flex'>
            <div class='mt-2'>
              <button class='mr-2 ml-3' nbButton size='tiny' hero status='primary'
                      (click)='clearSelected()'
                      [hidden]='willBeDeletedProspectIds.length <= 0 && willBeDeletedGroupIds.length <= 0'>
                Clear Selected
              </button>
            </div>
            <div class='mt-2' *appPermissionCheck='ComponentPermission.CLIENT_DELETE'>
              <button nbButton size='tiny' hero status='danger' class='mr-2'
                      [hidden]='willBeDeletedProspectIds.length <= 0 && willBeDeletedGroupIds.length <= 0'
                      (click)='removeSelected()'>Remove selected
              </button>
            </div>
          </div>
          <div class='mt-2'>
            <ng2-smart-table *ngIf='!viewMap'
                             [settings]='settings'
                             [source]='source'
                             (userRowSelect)='selectRecord($event, false, true);'
            >
            </ng2-smart-table>

            <ngx-prospect-map
              *ngIf='viewMap === true'
              [prospects]='prospects'
              [prospectDisplayType]='selectedMarket'
              (selectProspect)='selectProspectFromMapView($event)'
            ></ngx-prospect-map>
          </div>
        </div>
      </nb-card-body>
    </nb-card>
  </div>
  <div class='col-md-5 col-sm-12 right-panel table-container'
       [ngClass]="{
       'popup-detail': isDisplay,
       'hide-detail': !isDisplay}">
    <ngx-prospect-dialog
      [selectedProspect]='selectedProspect'
      [prospects]='selectedProspects'
      [companies]='companies'
      [taskTypes]='taskTypes'
      [taskStatuses]='taskStatuses'
      (editDone)='editDone($event)'
      (navigateProspectEvent)='navigateProspect($event)'
    ></ngx-prospect-dialog>
  </div>

  <div class='scroll-mobile left-54' *ngIf='selectedProspect && isDisplay'>
    <button (click)='cancel()' size='tiny' nbButton status='warning'>Cancel</button>
  </div>

</div>

