import {User} from './shared/models/response/user';
import {Constant} from './shared/common/constant';
import { UserUtils } from './shared/common/user-utils';

export class ComponentPermission {

  static currentUser(): User {
    const dataStr = localStorage.getItem(Constant.LS_CURRENT_USER_KEY);
    return JSON.parse(dataStr);
  }

  static getNavigation(): any[] {
    let navItems = ComponentPermission.navItems;
    navItems.forEach(navItem => {
      if (navItem.permission !== ComponentPermission.HOMEPAGE) {
        navItem.display = false;
        navItem.class = 'firstMenu';
        if (navItem.children) {
          navItem.children.forEach(child => {
            child.display = false;
            child.class = 'secondMenu';
          });
        }
      }
    });
    const aclActions = this.currentUser().aclActions;
    if (aclActions) {
      aclActions.forEach(aclAction => {
        const uiComponents = JSON.parse(aclAction.uiComponent);
        uiComponents.forEach(component => {
          navItems.forEach(navItem => {
            if (navItem.permission && navItem.permission === component) {
              navItem.display = true;
            }
            if (navItem.children) {
              navItem.children.forEach(child => {
                if (child.permission && child.permission === component) {
                  child.display = true;
                  navItem.display = true;
                }
              });
            }
          });
        });
      });
    }
    navItems = navItems.filter(navItem => navItem.display);
    navItems.forEach(navItem => {
      if (navItem.children) {
        navItem.children = navItem.children.filter(child => child.display);
      }
    });
    return navItems;
  }

  static isAccess(permission: string): boolean {
    const aclActions = this.currentUser().aclActions;
    let isAccess = false;
    if (aclActions) {
      aclActions.forEach(aclAction => {
        const uiComponents = JSON.parse(aclAction.uiComponent);
        uiComponents.forEach(component => {
          if (component === permission) {
            isAccess = true;
          }
        });
      });
    }
    return isAccess;
  }

  static HOMEPAGE = 'homepage';

  // ANSWER
  static ANSWER_CREATE = 'answer.create';
  static ANSWER_DELETE = 'answer.delete';
  static ANSWER_EDIT = 'answer.edit';
  static ANSWER_LIST = 'answer.list';
  // CITY
  static CITY_CREATE = 'city.create';
  static CITY_DELETE = 'city.delete';
  static CITY_EDIT = 'city.edit';
  static CITY_LIST = 'city.list';
  // CLIENT
  static CLIENT_BOARD = 'client.board';
  static CLIENT_BOARD_CLICK = 'client.board.click';
  static CLIENT_BOARD_SEARCH = 'client.board.search';
  static CLIENT_CREATE = 'client.create';
  static CLIENT_CREATE_BATCH = 'client.create.batch';
  static CLIENT_CREATE_SHORTCUT = 'client.create.shortcut';
  static CLIENT_DELETE = 'client.delete';
  static CLIENT_EDIT = 'client.edit';
  static CLIENT_ALL = 'client.all';
  static CLIENT_INDOOR = 'client.indoor';
  static CLIENT_LIST = 'client.list';
  static CLIENT_MARKET = 'client.market';
  static CLIENT_OUTDOOR = 'client.outdoor';
  static CLIENT_SEARCH = 'client.search';
  static CLIENT_LISTING_DETAILS = 'client.listing.details';
  static CLIENT_RENTAL_DETAILS = 'client.rental.details';
  // COMPANY
  static COMPANY_BOARD = 'company.board';
  static COMPANY_BOARD_CLICK = 'company.board.click';
  static COMPANY_CREATE = 'company.create';
  static COMPANY_DELETE = 'company.delete';
  static COMPANY_EDIT = 'company.edit';
  static COMPANY_LIST = 'company.list';
  // CUSTOMER
  static CUSTOMER_BOARD = 'customer.board';
  static CUSTOMER_BOARD_CLICK = 'customer.board.click';
  static CUSTOMER_CREATE = 'customer.create';
  static CUSTOMER_DELETE = 'customer.delete';
  static CUSTOMER_EDIT = 'customer.edit';
  static CUSTOMER_LIST = 'customer.list';
  static CUSTOMER_MERGE = 'customer.merge';
  static CUSTOMER_SEARCH = 'customer.search';
  static CUSTOMER_ACCESS_ANYWHERE = 'customer.access.anywhere';
  static CUSTOMER_OPEN_HOME = 'customer.open.home';
  // DASHBOARD
  static DASHBOARD_CALLBACK = 'dashboard.callback';
  static DASHBOARD_DOB = 'dashboard.dob';
  static DASHBOARD_DUPLICATE = 'dashboard.duplicate';
  static DASHBOARD_OVERVIEW = 'dashboard.overview';
  static DASHBOARD_OVERVIEW_CLICK = 'dashboard.overview.click';
  static DASHBOARD_PURCHASE = 'dashboard.purchase';
  static DASHBOARD_DATA_REPORT = 'dashboard.data.report';
  static DASHBOARD_ONE_ON_ONE = 'dashboard.one.on.one';
  static DASHBOARD_RATIO = 'dashboard.ratio';
  static DASHBOARD_OVERVIEW_TEAM = 'dashboard.overview.team';
  static DASHBOARD_VISUAL_BOARD = 'dashboard.visual.board';
  static DASHBOARD_RENTAL_BOARD = 'dashboard.rental.board';
  static DASHBOARD_RENTAL_TEAM = 'dashboard.rental.team';
  static DASHBOARD_RENTAL_CLICK = 'dashboard.rental.click';
  // FARM
  static FARM_CREATE = 'farm.create';
  static FARM_DELETE = 'farm.delete';
  static FARM_EDIT = 'farm.edit';
  static FARM_LIST = 'farm.list';
  // GLOBAL
  static GLOBAL_ADDRESS = 'global.address';
  static GLOBAL_PHONE = 'global.phone';
  static GLOBAL_PHONE_COPY = 'global.phone.copy';
  static GLOBAL_PHONE_LINK = 'global.phone.link';
  static GLOBAL_PHONE_MERGE = 'global.phone.merge';
  static GLOBAL_PHONE_MOVE = 'global.phone.move';
  static GLOBAL_FILTER_USER = 'global.filter.user';
  // GROUP
  static GROUP_CREATE = 'group.create';
  static GROUP_DELETE = 'group.delete';
  static GROUP_EDIT = 'group.edit';
  static GROUP_LIST = 'group.list';
  // OFFICE
  static OFFICE_CREATE = 'office.create';
  static OFFICE_DELETE = 'office.delete';
  static OFFICE_EDIT = 'office.edit';
  static OFFICE_LIST = 'office.list';
  static OFFICE_PROPERTY = 'office.property';
  static OFFICE_ASSIGN = 'office.assign';
  static OFFICE_MANAGEMENT_RATE = 'office.management.rate';
  // PERMISSION
  static PERMISSION_CREATE = 'permission.create';
  static PERMISSION_DELETE = 'permission.delete';
  static PERMISSION_EDIT = 'permission.edit';
  static PERMISSION_LIST = 'permission.list';
  // QUESTION
  static QUESTION_CREATE = 'question.create';
  static QUESTION_DELETE = 'question.delete';
  static QUESTION_EDIT = 'question.edit';
  static QUESTION_LIST = 'question.list';
  // SOS
  static SOS_DAILY = 'sos.daily';
  // BLOG
  static BLOG_LIST = 'blog.list';
  static BLOG_CREATE = 'blog.create';
  static BLOG_DELETE = 'blog.delete';
  static BLOG_EDIT = 'blog.edit';
  // STREET
  static STREET_CREATE = 'street.create';
  static STREET_DELETE = 'street.delete';
  static STREET_EDIT = 'street.edit';
  static STREET_LIST = 'street.list';
  // SUBURB
  static SUBURB_CREATE = 'suburb.create';
  static SUBURB_DELETE = 'suburb.delete';
  static SUBURB_EDIT = 'suburb.edit';
  static SUBURB_LIST = 'suburb.list';
  // TAG
  static TAG_CREATE = 'tag.create';
  static TAG_DELETE = 'tag.delete';
  static TAG_EDIT = 'tag.edit';
  static TAG_LIST = 'tag.list';
  // TASK
  static TASK_CALENDAR = 'task.calendar';
  static TASK_CREATE = 'task.create';
  static TASK_DELETE = 'task.delete';
  static TASK_EDIT = 'task.edit';
  static TASK_STATUS_CREATE = 'task.status.create';
  static TASK_STATUS_DELETE = 'task.status.delete';
  static TASK_STATUS_EDIT = 'task.status.edit';
  static TASK_STATUS_LIST = 'task.status.list';
  static TASK_TYPE_CREATE = 'task.type.create';
  static TASK_TYPE_DELETE = 'task.type.delete';
  static TASK_TYPE_EDIT = 'task.type.edit';
  static TASK_TYPE_LIST = 'task.type.list';
  // TEMPLATE
  static TEMPLATE_CREATE = 'template.create';
  static TEMPLATE_DELETE = 'template.delete';
  static TEMPLATE_EDIT = 'template.edit';
  static TEMPLATE_LIST = 'template.list';
  // USER
  static USER_CREATE = 'user.create';
  static USER_DELETE = 'user.delete';
  static USER_EDIT = 'user.edit';
  static USER_LIST = 'user.list';
  static USER_LOW_LEVEL = 'user.low.level';
  static USER_UPDATE_DAILY_GOAL = 'user.update.daily.goal';
  // ZONE
  static ZONE_CREATE = 'zone.create';
  static ZONE_DELETE = 'zone.delete';
  static ZONE_EDIT = 'zone.edit';
  static ZONE_LIST = 'zone.list';
  // RENTAL
  static RENTAL_PROPERTY_MANAGER = 'rental.property.manager';
  static RENTAL_LEVEL4_DETAILS = 'rental.level4.details';

  //  MANAGEMENT

  static MANAGEMENT_MARKETING_SCHEDULE = 'management.marketing.schedule';

  static navItems: any[] = [
    {
      name: 'CRM',
      url: '/pages/dashboard/index',
      class: 'homepage-icon',
      display: true,
      permission: ComponentPermission.HOMEPAGE,
    },
    {
      name: 'Dashboard',
      // url: '/pages/dashboard/overview',
      icon: 'icon-speedometer',
      // permission: ComponentPermission.MENU_DASHBOARD,
      children: [
        {
          name: 'Overview',
          url: '/pages/dashboard/overview',
          icon: 'icon-speedometer',
          permission: ComponentPermission.DASHBOARD_OVERVIEW,
        },
        {
          name: 'Callback Date',
          url: '/pages/dashboard/callback-date',
          icon: 'icon-phone',
          permission: ComponentPermission.DASHBOARD_CALLBACK,
        },
        {
          name: 'Purchase Date',
          url: '/pages/prospect/purchase-date',
          icon: 'icon-wallet',
          permission: ComponentPermission.DASHBOARD_PURCHASE,
        },
        {
          name: 'Date of birth',
          url: '/pages/dashboard/dob',
          icon: 'icon-event',
          permission: ComponentPermission.DASHBOARD_DOB,
        },
        {
          name: 'Duplicate Phone',
          url: '/pages/dashboard/search/phone/duplicate',
          icon: 'icon-phone',
          permission: ComponentPermission.DASHBOARD_DUPLICATE,
        },
        {
          name: 'Data Report',
          url: '/pages/dashboard/data-report',
          icon: 'icon-check',
          permission: ComponentPermission.DASHBOARD_DATA_REPORT,
        },
        {
          name: 'Visual Board',
          url: '/pages/dashboard/visual-board',
          icon: 'icon-menu',
          permission: ComponentPermission.DASHBOARD_VISUAL_BOARD,
        },
        {
          name: 'Rental Board',
          url: '/pages/dashboard/rental-board',
          icon: 'icon-menu',
          permission: ComponentPermission.DASHBOARD_VISUAL_BOARD,
        }
      ]
    },
    {
      name: 'User Management',
      // url: '/notifications',
      icon: 'icon-user',
      // permission: ComponentPermission.MENU_USER_MANAGEMENT,
      children: [
        {
          name: 'User',
          url: '/pages/user-management/user',
          icon: 'icon-user',
          permission: ComponentPermission.USER_LIST,
        },
        {
          name: 'Group',
          url: '/pages/user-management/group',
          icon: 'icon-people',
          permission: ComponentPermission.GROUP_LIST,
        },
        {
          name: 'Permission',
          url: '/pages/user-management/permission',
          icon: 'icon-check',
          permission: ComponentPermission.PERMISSION_LIST,
        }
      ]
    },
    {
      name: 'SOS',
      // url: '/notifications',
      icon: 'icon-heart',
      // permission: ComponentPermission.MENU_SOS,
      children: [
        {
          name: 'Question',
          url: '/pages/sos/question',
          icon: 'icon-question',
          permission: ComponentPermission.QUESTION_LIST,
        },
        {
          name: 'Answer',
          url: '/pages/sos/answer',
          icon: 'icon-note',
          permission: ComponentPermission.ANSWER_LIST,
        },
        {
          name: 'Daily',
          url: '/pages/sos/daily',
          icon: 'icon-notebook',
          permission: ComponentPermission.SOS_DAILY,
        },
        {
          name: 'Blog',
          url: '/pages/sos/blog',
          icon: 'icon-notebook',
          permission: ComponentPermission.BLOG_LIST,
        }
      ]
    },
    {
      name: 'Task',
      // url: '/notifications',
      icon: 'icon-calendar',
      // permission: ComponentPermission.MENU_TASK,
      children: [
        {
          name: 'Task Calendar',
          url: '/pages/task/calendar',
          icon: 'icon-calendar',
          permission: ComponentPermission.TASK_CALENDAR,
        },
        {
          name: 'Task Type',
          url: '/pages/task/type/list',
          icon: 'icon-options-vertical',
          permission: ComponentPermission.TASK_TYPE_LIST,
        },
        {
          name: 'Task Status',
          url: '/pages/task/status/list',
          icon: 'icon-screen-tablet',
          permission: ComponentPermission.TASK_STATUS_LIST,
        },
        // {
        //   name: 'Task Create',
        //   url: '/pages/task/create-task',
        //   icon: 'fa fa-plus',
        //   permission: ComponentPermission.TASK_CREATE,
        // },
      ],
    },
    {
      name: 'Client',
      // url: '/notifications',
      icon: 'icon-home',
      // permission: ComponentPermission.MENU_CLIENT,
      children: [
        {
          name: 'Client List',
          url: '/pages/prospect/list',
          icon: 'icon-layers',
          permission: ComponentPermission.CLIENT_LIST,
        },
        {
          name: 'Client Board',
          url: '/pages/prospect/client-board',
          icon: 'icon-menu',
          permission: ComponentPermission.CLIENT_BOARD,
        },
        {
          name: 'Company Board',
          url: '/pages/prospect/company-board',
          icon: 'icon-menu',
          permission: ComponentPermission.COMPANY_BOARD,
        },
        {
          name: 'Company List',
          url: '/pages/prospect/company/list',
          icon: 'icon-menu',
          permission: ComponentPermission.COMPANY_LIST,
        },
        {
          name: 'Client List',
          url: '/pages/prospect/client-list',
          icon: 'icon-diamond',
          permission: ComponentPermission.CLIENT_LIST,
        }
      ],
    },
    {
      name: 'Customer',
      // url: '/notifications',
      icon: 'icon-diamond',
      // permission: ComponentPermission.MENU_CUSTOMER,
      children: [
        {
          name: 'Customer List',
          url: '/pages/buyer/list',
          icon: 'icon-energy',
          permission: ComponentPermission.CUSTOMER_LIST,
        },
        {
          name: 'Customer Board',
          url: '/pages/buyer/board',
          icon: 'icon-menu',
          permission: ComponentPermission.CUSTOMER_BOARD,
        },
        {
          name: 'Open Home',
          url: '/pages/buyer/open-home',
          icon: 'icon-home',
          permission: ComponentPermission.CUSTOMER_BOARD,
        },
      ],
    },
    {
      name: 'Address',
      // url: '/notifications',
      icon: 'icon-map',
      // permission: ComponentPermission.MENU_ADDRESS,
      children: [
        // {
        //   name: 'City',
        //   url: '/pages/address/city',
        //   icon: 'icon-map',
        //   permission: ComponentPermission.CITY_LIST,
        // },
        // {
        //   name: 'Zone',
        //   url: '/pages/address/zone',
        //   icon: 'icon-directions',
        //   permission: ComponentPermission.ZONE_LIST,
        // },
        {
          name: 'Suburb',
          url: '/pages/address/suburb',
          icon: 'icon-direction',
          permission: ComponentPermission.SUBURB_LIST,
        },
        // {
        //   name: 'Farm',
        //   url: '/pages/address/farm',
        //   icon: 'icon-menu',
        //   permission: ComponentPermission.FARM_LIST,
        // },
        {
          name: 'Street',
          url: '/pages/address/street',
          icon: 'icon-pin',
          permission: ComponentPermission.STREET_LIST,
        },
      ],
    },
    {
      name: 'Other',
      // url: '/other',
      icon: 'icon-star',
      // permission: ComponentPermission.MENU_OTHER,
      children: [
        {
          name: 'Tag',
          url: '/pages/other/category',
          icon: 'icon-map',
          permission: ComponentPermission.TAG_LIST,
        },
        {
          name: 'Template',
          url: '/pages/other/template',
          icon: 'icon-menu',
          permission: ComponentPermission.TEMPLATE_LIST,
        },
        {
          name: 'Office',
          url: '/pages/other/office',
          icon: 'icon-map',
          permission: ComponentPermission.OFFICE_LIST,
        },
      ],
    },
    {
      name: 'Management',
      // url: '/other',
      icon: 'icon-star',
      // permission: ComponentPermission.MENU_OTHER,
      children: [
        {
          name: 'Marketing',
          url: '/pages/management/marketing-schedule',
          icon: 'icon-map',
          permission: ComponentPermission.MANAGEMENT_MARKETING_SCHEDULE,
        }
      ],
    }
  ];
}
