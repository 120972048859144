import {Injectable} from '@angular/core';
import {AbstractControl, FormGroup, ValidationErrors} from '@angular/forms';
import {CommonService} from './common.service';

@Injectable({providedIn: 'root'})
export class FormService {

  // error typies: some are default of form control validators and some are custom validators
  static ERROR_TYPE = {
    required: 'required',
    pattern: 'pattern',
    email: 'email',
    minlength: 'minlength',
    areEqual: 'areEqual',
    matchPassword: 'matchPassword',
    agreement: 'agreement',
    maxlength: 'maxlength',
  };

  constructor(private commonService: CommonService) {
  }

  /**
   * extract error from form group
   */
  extractErrorMessage(form: FormGroup): AllValidationErrors[] {
    if (!form) {
      return null;
    }
    const errors = this.getFormValidationErrors(form.controls);
    this.updateFormByErrors(errors);
    return errors;
  }

  /**
   * create error messages from form group controls
   */
  private getFormValidationErrors(controls: FormGroupControls): AllValidationErrors[] {
    let errors: AllValidationErrors[] = [];
    Object.keys(controls).forEach(key => {
      const control = controls[key];
      if (control instanceof FormGroup) {
        errors = errors.concat(this.getFormValidationErrors(control.controls));
      }
      const controlErrors: ValidationErrors = controls[key].errors;
      if (controlErrors !== null) {
        Object.keys(controlErrors).forEach(keyError => {
          errors.push({
            control_name: key,
            error_name: keyError,
            error_value: controlErrors[keyError],
            error_message: null
          });
        });
      }
    });
    if (errors) {
      for (let i = 0; i < errors.length; i++) {
        const error: AllValidationErrors = errors[i];
        let msg = '';
        const controlName = this.trimControlName(error.control_name);
        switch (error.error_name) {
          case FormService.ERROR_TYPE.required:
            msg = this.commonService.translate(`FIELD_${controlName}`) + this.commonService.translate('FORM_IS_REQUIRED');
            break;
          case FormService.ERROR_TYPE.pattern:
            msg = this.commonService.translate(`FIELD_${controlName}`) + this.commonService.translate('FORM_PATTERN');
            break;
          case FormService.ERROR_TYPE.email:
            msg = this.commonService.translate(`FIELD_${controlName}`) + this.commonService.translate('FORM_EMAIL');
            break;
          case FormService.ERROR_TYPE.minlength:
            msg = this.commonService.translate(`FIELD_${controlName}`) + this.commonService.translate('FORM_MIN_LENGTH');
            break;
          case FormService.ERROR_TYPE.maxlength:
            msg = this.commonService.translate(`FIELD_${controlName}`) + this.commonService.translate('FORM_MAX_LENGTH');
            break;
          case FormService.ERROR_TYPE.areEqual:
            msg = this.commonService.translate(`FIELD_${controlName}`) + ' ' + this.commonService.translate('FORM_EQUAL');
            break;
          case FormService.ERROR_TYPE.matchPassword:
            msg = this.commonService.translate(`FIELD_${controlName}`) + ' ' + this.commonService.translate('FORM_MATCH_PASSWORD');
            break;
          case FormService.ERROR_TYPE.agreement:
            msg = this.commonService.translate(`FIELD_${controlName}`) + ' ' + this.commonService.translate('FORM_AGREEMENT');
            break;
          default:
            msg = `${controlName}: ${error.error_name}: ${error.error_value}`;
        }
        error.error_message = msg;
      }
    }
    return errors;
  }

  /**
   * show error popup notification and add red border to error input then scroll to error input
   */
  private updateFormByErrors(errors: AllValidationErrors[]) {
    let message = '';
    for (const error of errors) {
      if (error.error_message) {
        message += '<li>' + error.error_message + '</li>';
      }
    }

    if (message) {
      this.commonService.warningHtml(message);
    }
  }

  private trimControlName(controlName: string): string {
    if (!controlName) {
      return '';
    }
    return controlName.trim().replace('-', ' ').replace('_', ' ');
  }

}

export class AllValidationErrors {
  control_name: string;
  error_name: string;
  error_value: any;
  error_message?: string;
}

export class FormGroupControls {
  [key: string]: AbstractControl;
}
