import {Component, Input, OnInit} from '@angular/core';
import {ViewCell} from 'ng2-smart-table';
import {WrapBuyer} from './buyer-utils';
import {Buyer} from '../../../../shared/models/response/buyer/buyer';
import {Task} from '../../../../shared/models/response/task';

@Component({
  template: `
    {{renderValue}}
  `,
  styles: [
  ],
})
export class BuyerListAgentCellComponent implements ViewCell, OnInit {

  renderValue: string;

  @Input() value: string | number;
  @Input() rowData: WrapBuyer;

  ngOnInit() {
    this.setValue();
  }

  setValue(): any {
    if (!this.rowData) {
      this.renderValue = this.value ? this.value.toString() : '';
      return;
    }
    switch (this.rowData.type) {
      case 'ITEM':
        const item: Buyer = this.rowData.data;
        let tasks = item?.tasks;
        if (tasks) {
          tasks = tasks.sort((a: Task, b: Task) => {
            return b.createdDate = a.createdDate;
          });
          this.renderValue = tasks[0]?.fromUser?.name;
        }
        break;
    }
  }

}
