import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionService } from '../services/permission.service';

@Directive({ selector: '[appPermissionCheck]' })
export class PermissionCheckDirective {

  constructor(
      private templateRef: TemplateRef<any>,
      private viewContainer: ViewContainerRef,
      private permissionService: PermissionService,
  ) {
  }

  @Input() set appPermissionCheck(componentId: string) {
    if (componentId) {
      this.permissionService.checkAccess(componentId).subscribe(
          result => {
            if (result.data) {
              this.viewContainer.createEmbeddedView(this.templateRef);
            } else {
              this.viewContainer.clear();
            }
          },
          error => this.viewContainer.clear(),
      );
    } else {
      this.viewContainer.clear();
    }
  }
}
