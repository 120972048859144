export class CustomerBoard {
  houseType: string;
  rate: number;
  total: number;
  price: number;
  has_common: boolean;
  has_address: boolean;
  has_number: boolean;
  has_district_id: boolean;
  has_street_id: boolean;
  has_number_id: boolean;
  has_first_home: boolean;
  has_investor: boolean;
  has_sell: boolean;
  has_rent: boolean;
  is_active: String;
}
