<form [formGroup]='prospectForm'>
  <nb-card class='border-0 overflow-auto background-color-v2'>
    <nb-card-header class='header-card'>
      <div class='row m-0'>
        <div class='col-12'>
          <!--          <span class='mobile-view' (click)='cancel()'>< Back</span>-->
          <button
            nbButton fullWidth size='large'
            class='large max-right mobile-view pull-left'
            (click)='cancel()'>
            <nb-icon icon='arrow-back-outline' pack='eva'></nb-icon>
          </button>
          <!--          <button [hidden]='!this.data' (click)='toggleDisplayDKBar()' nbButton fullWidth-->
          <!--                  size='large'-->
          <!--                  class='middle large'-->
          <!--                  [status]='showDKBar ? "primary" : "basic"'>-->
          <!--            <nb-icon icon='sun-outline' pack='eva'></nb-icon>-->
          <!--          </button>-->
          <button
            nbButton fullWidth size='large'
            class='large max-right fa-pull-right pull-right'
            (click)='edit()' *ngIf='!isEdit && this.data'>
            <nb-icon icon='edit-outline' pack='eva'></nb-icon>
          </button>
          <button
            nbButton fullWidth size='large'
            class='large max-right fa-pull-right pull-right'
            (click)='quickEditDone()' *ngIf='isEdit'>
            <nb-icon icon='checkmark-circle-outline' pack='eva'></nb-icon>
          </button>

          <div *ngIf='!isEdit' class='name-line mt-2'>
            {{prospectForm.controls?.name?.value}} {{prospectForm.controls?.surname?.value}}
          </div>
          <div *ngIf='isEdit' class="name-dialog-header">
            <input nbTooltip='Name' *ngIf='isEdit' type='text' class='no-border-input mr-1' formControlName='name'
                   nbInput fieldSize='medium'>
            <input nbTooltip='Surname' *ngIf='isEdit' type='text' class='no-border-input' formControlName='surname'
                   nbInput fieldSize='medium'>
          </div>
          <!--          <span class='fa-pull-right' (click)='edit()' *ngIf='!isEdit'>Edit</span>-->
          <!--          <span class='fa-pull-right' (click)='quickEditDone()' *ngIf='isEdit'>Done</span>-->
          <!--          <button-->
          <!--            [hidden]='!this.data'-->
          <!--            nbButton fullWidth size='large'-->
          <!--            class='large max-right fa-pull-right'-->
          <!--            [vcdDownloadVCard]='vCard'>-->
          <!--            <nb-icon icon='save-outline' pack='eva'></nb-icon>-->
          <!--          </button>-->
          <!--          <button-->
          <!--            [hidden]='!this.data'-->
          <!--            (click)='openGoogleMap()'-->
          <!--            nbButton fullWidth size='large'-->
          <!--            class='large max-right fa-pull-right'-->
          <!--          >-->
          <!--            <i class='fa fa-map gmap'></i>-->
          <!--          </button>-->
        </div>
        <div class='col-12 text-center' *ngIf="this.data">
          <div>
            {{fullAddress}}
          </div>
          <div *ngIf='isEdit' class='row m-0 justify-content-center edit-address'>
            <input type='text' nbInput fieldSize='tiny' fullWidth name='unit' placeholder='Unit'
                   formControlName='unit' class='no-border-input mr-1'>
            &nbsp;/&nbsp;
            <input type='number' nbInput fieldSize='tiny' fullWidth name='numberFrom' placeholder='Number'
                   formControlName='numberFrom' class='no-border-input mr-1'>
            <input type='text' nbInput fieldSize='tiny' fullWidth name='additionalInfo' placeholder='Additional Info'
                   formControlName='additionalInfo' class='no-border-input mr-1'>
            <!--            <div>{{streetWithoutNumberFrom}}</div>-->
          </div>
        </div>
      </div>
    </nb-card-header>
    <nb-card-body class='padding-override'>
      <div class="row m-0">
        <div class="col-4 m-0">
          <button
            [hidden]='!this.data'
            nbButton fullWidth size='large'
            class='large max-right fa-pull-right pull-left'
            [vcdDownloadVCard]='vCard'>
            <nb-icon icon='save-outline' pack='eva'></nb-icon>
          </button>
        </div>
        <div class="col-4 m-0">
          <button [hidden]='!this.data' (click)='toggleDisplayDKBar()' nbButton fullWidth
                  size='large'
                  class='middle large'
                  [status]='showDKBar ? "primary" : "basic"'>
            <nb-icon icon='sun-outline' pack='eva'></nb-icon>
          </button>
        </div>
        <div class="col-4 m-0 d-flex">
          <button nbButton size='small' status='primary' class="margin-auto" (click)='displaySalesDetails()'
                  *ngIf='data?.rate >= 4 && (data?.property?.sell === true || data?.property?.otm === true)
                && ComponentPermission.isAccess(ComponentPermission.CLIENT_LISTING_DETAILS)'>Sales Details
          </button>
          <button
            [hidden]='!this.data'
            (click)='openGoogleMap()'
            nbButton fullWidth size='large'
            class='large max-right fa-pull-right pull-right margin-auto'
          >
            <i class='fa fa-map gmap'></i>
          </button>
        </div>
      </div>
      <!--      <div class='row m-0 mt-2'>-->
      <!--        <div class='col-12 text-center mb-2'>-->
      <!--          <div *ngIf='!isEdit' class='name-line'>-->
      <!--            {{prospectForm.controls?.name?.value}} {{prospectForm.controls?.surname?.value}}-->
      <!--          </div>-->
      <!--          <input nbTooltip='Name' *ngIf='isEdit' type='text' class='no-border-input mr-1' formControlName='name' nbInput-->
      <!--                 fieldSize='medium'>-->
      <!--          <input nbTooltip='Surname' *ngIf='isEdit' type='text' class='no-border-input' formControlName='surname'-->
      <!--                 nbInput-->
      <!--                 fieldSize='medium'>-->
      <!--        </div>-->
      <!--        <div class='col-12 text-center'>-->
      <!--          <div *ngIf='!isEdit'>-->
      <!--            {{prospectForm.controls?.numberFrom?.value}}{{streetWithoutNumberFrom}}-->
      <!--          </div>-->
      <!--          <div *ngIf='isEdit' class='row m-0 justify-content-center'>-->
      <!--            <input nbTooltip='Number From' type='text' class='no-border-input input-max-width'-->
      <!--                   formControlName='numberFrom' nbInput-->
      <!--                   fieldSize='tiny'>-->
      <!--            <div>{{streetWithoutNumberFrom}}</div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class='row m-0 mt-2'>
        <div class='col-12 d-flex justify-content-center p-1'>
          <div class='col-3 text-center'>
            <button nbButton fullWidth size='small' status='danger'
                    (click)='navigateProspect(false)'>
              < Back
            </button>
          </div>
          <div class='col-3 text-center'>
            <button class='button-details' nbButton fullWidth size='small'
                    (click)='logContactStatus(contactLogType.NO_ANSWER)'>NA
            </button>
          </div>
          <div class='col-3 text-center'>
            <button class='button-details' nbButton fullWidth size='small' (click)="createTask('APPOINTMENT')">APP
            </button>
          </div>
          <div class='col-3 text-center'>
            <button nbButton fullWidth size='small' status='success'
                    (click)='navigateProspect(true)'>Next >
            </button>
          </div>
        </div>
        <div class='col-12 mt-2' [hidden]='!showDKBar'>
          <div class='row p-1'>
            <div class='col-3'>
              <button nbTooltip='Sell' nbButton size='small' class='w-100 border'
                      [status]='prospectForm.controls.sell.value ? "info" : "basic"' (click)="setSquareValue('sell')">
                Sell
              </button>
            </div>
            <div class='col-3'>
              <button nbTooltip='Rent' nbButton size='small' class='w-100 border'
                      [status]='prospectForm.controls.rent.value ? "info" : "basic"' (click)="setSquareValue('rent')">
                Rent
              </button>
            </div>
            <div class='col-3'>
              <button nbTooltip='On The Market' nbButton size='small' class='w-100 border'
                      [status]='prospectForm.controls.otm.value ? "info" : "basic"' (click)="setSquareValue('otm')"
                      [disabled]='!prospectForm.controls?.otmDate.value'>Otm
              </button>
            </div>
            <div class='col-3'>
              <button nbTooltip='VAC' nbButton size='small' class='w-100 border'
                      [status]='prospectForm.controls.vac.value ? "info" : "basic"' (click)="setSquareValue('vac')"
                      [disabled]='!prospectForm.controls?.vacDate.value'>Vac
              </button>
            </div>
          </div>
          <div class='row p-1'>
            <div class='col-3'>
              <button nbTooltip='Mail?' nbButton size='small' class='w-100 border'
                      (click)="setSquareValue('mail')">
                <span [ngClass]='prospectForm.controls.mail.value ? "line-through" : ""'>Mail</span>
              </button>
            </div>
            <div class='col-3'>
              <button nbTooltip='Door?' nbButton size='small' class='w-100 border' (click)="setSquareValue('door')">
                <span [ngClass]='prospectForm.controls.door.value ? "line-through" : ""'>Door</span>
              </button>
            </div>
            <div class='col-3'>
              <button nbTooltip='Call?' nbButton size='small' class='w-100 border' (click)="setSquareValue('call')">
                <span [ngClass]='prospectForm.controls.call.value ? "line-through" : ""'>Call</span>
              </button>
            </div>
            <div class='col-3'>
              <button nbTooltip='SMS?' nbButton size='small' class='w-100 border' (click)="setSquareValue('sms')">
                <span [ngClass]='prospectForm.controls.sms.value ? "line-through" : ""'>Sms</span>
              </button>
            </div>
          </div>
          <div class='row p-1'>
            <div class='col-3'>
              <button nbTooltip='Financial Up' nbButton size='small' class='w-100 border'
                      [status]='prospectForm.controls.motivateFinancialUp.value ? "info" : "basic"'
                      (click)="setSquareValue('motivateFinancialUp')"><i class='fa fa-usd'></i>+
              </button>
            </div>
            <div class='col-3'>
              <button nbTooltip='Financial Down' nbButton size='small' class='w-100 border'
                      [status]='prospectForm.controls.motivateFinancialDown.value ? "info" : "basic"'
                      (click)="setSquareValue('motivateFinancialDown')"><i class='fa fa-usd'></i>-
              </button>
            </div>
            <div class='col-3'>
              <button nbTooltip='Life style Up' nbButton size='small' class='w-100 border'
                      [status]='prospectForm.controls.motivateLifestyleUp.value ? "info" : "basic"'
                      (click)="setSquareValue('motivateLifestyleUp')"><i class='fa fa-heart'></i>+
              </button>
            </div>
            <div class='col-3'>
              <button nbTooltip='Life style Down' nbButton size='small' class='w-100 border'
                      [status]='prospectForm.controls.motivateLifestyleDown.value ? "info" : "basic"'
                      (click)="setSquareValue('motivateLifestyleDown')"><i class='fa fa-heart'></i>-
              </button>
            </div>
          </div>
          <div class='row p-1'>
            <div class='col-3'>
              <button nbTooltip='Director' nbButton size='small' class='w-100 border'
                      [status]='prospectForm.controls.isDirector.value ? "info" : "basic"'
                      (click)="setSquareValue('isDirector')">D
              </button>
            </div>
            <div class='col-3'>
              <button nbTooltip='Influencer' nbButton size='small' class='w-100 border'
                      [status]='prospectForm.controls.isInfluencer.value ? "info" : "basic"'
                      (click)="setSquareValue('isInfluencer')">I
              </button>
            </div>
            <div class='col-3'>
              <button nbTooltip='Concensious' nbButton size='small' class='w-100 border'
                      [status]='prospectForm.controls.isConcensious.value ? "info" : "basic"'
                      (click)="setSquareValue('isConcensious')">C
              </button>
            </div>
            <div class='col-3'>
              <button nbTooltip='Socialble' nbButton size='small' class='w-100 border'
                      [status]='prospectForm.controls.isSocialble.value ? "info" : "basic"'
                      (click)="setSquareValue('isSocialble')">S
              </button>
            </div>
          </div>
          <div class='row m-0 mt-2 p-2 custom-area'>
            <div class='col-12'>
              <span>Note</span>
              <div class='app-speech-input fa-pull-right' [class.app-speech-input-listening]='listening'>
                <button class='app-speech-input-btn speech-color' [disabled]='listening' (click)='listen()'></button>
              </div>
            </div>
            <div class='col-12 mt-1'>
                  <textarea class='no-border-input' draggable='false' rows='2' nbInput fullWidth
                            formControlName='note'></textarea>
            </div>
          </div>
        </div>
        <hr class='m-2 w-100'>
        <div class='col-12 d-flex justify-content-center p-1 mt-2'>
          <div class='col-3 text-center'>
            <button (click)='toggleDisplayProperty()' nbButton fullWidth size='small'
                    class='border'
                    [status]='showProperty ? "primary" : "basic"'>
              <nb-icon icon='home-outline' pack='eva'></nb-icon>
            </button>
          </div>
          <div class='col-3 text-center'>
            <button (click)='toggleDisplayContact()' nbButton fullWidth size='small'
                    class='border'
                    [status]='showContact ? "primary" : "basic"'>
              <nb-icon icon='people-outline' pack='eva'></nb-icon>
            </button>
          </div>
          <div class='col-3 text-center'>
            <button (click)='toggleDisplayMotive()' nbButton fullWidth size='small'
                    class='border'
                    [status]='showMotive ? "primary" : "basic"'>
              <nb-icon icon='heart-outline' pack='eva'></nb-icon>
            </button>
          </div>
          <div class='col-3 text-center'>
            <button (click)='toggleDisplayHistory()' nbButton fullWidth size='small'
                    class='border'
                    [status]='showHistory ? "primary" : "basic"'>
              <nb-icon icon='clock-outline' pack='eva'></nb-icon>
            </button>
          </div>
        </div>

        <!--      BEGIN MAIN VIEW BLOCK-->
        <div class='col-12 p-0' [hidden]='!showMainView'>
          <div class='row m-1 custom-area mt-2'>
            <div class='col-12'>
              <div class='row m-0 p-2'>
                <div class='col-12'>Mobile</div>
                <div class='col-12 mt-1'>
                  <a *ngIf='!isEdit'
                     href='tel:{{prospectForm.controls?.mobilePhone.value}}'>{{prospectForm.controls?.mobilePhone.value}}</a>
                  <input *ngIf='isEdit' type='text' class='no-border-input' formControlName='mobilePhone' nbInput
                         fullWidth fieldSize='tiny'>
                  <button *ngIf='prospectForm.controls?.mobilePhone.value' nbButton status='primary' size='tiny'
                          class='fa-pull-right' (click)="openDialog(data)">
                    <!--                    <a [href]='generateHref()' class='white-font' id='white'>SMS</a>-->
                    SMS
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class='row custom-area m-1 mt-2'>
            <div class='col-12'>
              <div class='row m-0 p-2'>
                <div class='col-12'>Email</div>
                <div class='col-12 mt-1'>
                  <a *ngIf='!isEdit'
                     href='mailto:{{prospectForm.controls?.email.value}}'>{{prospectForm.controls?.email.value}}</a>
                  <input *ngIf='isEdit' type='text' class='no-border-input' formControlName='email' nbInput fullWidth
                         fieldSize='tiny'>
                </div>
              </div>
            </div>
          </div>
          <div class='row custom-area m-1 mt-2' *ngIf='data'>
            <div class='col-10 p-2'>
              <div class='row m-0'>
                <div class='col-3 text-center'>{{propertyType[prospectForm.controls?.type.value]}}</div>
                <div class='col-9 d-flex justify-content-center p-0'>
                  <div class='mr-1'><i
                    class='fa fa-bed mr-1'></i>{{prospectForm.controls?.bed.value}}</div>
                  <div class='mr-1'><i
                    class='fa fa-bath mr-1'></i>{{prospectForm.controls?.bath.value}}</div>
                  <div class='mr-1'><i
                    class='fa fa-car mr-1'></i>{{prospectForm.controls?.car.value}}</div>
                  <div class='mr-1'>
                    <i class='fa fa-arrows mr-1'></i>{{stringUtils.getLand(prospectForm.controls?.land.value)[0]}}<span
                    [innerHTML]='stringUtils.getLand(prospectForm.controls?.land.value)[1]'></span></div>
                </div>
              </div>
              <div class='row m-0 mt-1' *ngIf='prospectForm?.controls?.purchaseDate?.value'>
                <div class='col-4 p-0 text-center purchase-date-container'>
                  <div class='purchase-color'>{{prospectForm.controls?.purchaseDate.value | date: 'dd/MM/yyyy'}}</div>
                </div>
                <div class='col-4 text-center'>
                  <div>{{purchaseDurationText}}</div>
                </div>
                <div class='col-4 text-center'>
                  <div>{{formatMoney(prospectForm.controls?.sellPrice.value)}}</div>
                </div>
              </div>
              <div class='blank-row' *ngIf='!prospectForm?.controls?.purchaseDate?.value'>

              </div>
              <div class='row m-0 mt-1'>
                <div class='col-4 p-0 text-center'>
                  <div *ngIf='data'>
                    <span *ngIf='prospectForm.controls.sell.value'>S</span>
                    <span *ngIf='prospectForm.controls.otm.value'>O</span>
                  </div>
                </div>
                <div class='col-4 p-0 text-center'>
                  <div *ngIf='prospectForm?.controls?.otmDate?.value'>{{otmDurationText}}</div>
                </div>
                <div class='col-4 p-0 text-center'>
                  <div
                    *ngIf='prospectForm?.controls?.askingPrice?.value'>{{formatMoney(prospectForm.controls?.askingPrice.value)}}</div>
                </div>
              </div>
              <div class='row m-0 mt-1'>
                <div class='col-4 p-0 text-center'>
                  <div *ngIf='data'>
                    <span *ngIf='prospectForm.controls.rent.value'>R</span>
                    <span *ngIf='prospectForm.controls.vac.value'>V</span>
                  </div>
                </div>
                <div class='col-4 p-0 text-center'>
                  <div *ngIf='prospectForm?.controls?.vacDate?.value'>{{vacDurationText}}</div>
                </div>
                <div class='col-4 p-0 text-center'>
                  <div
                    *ngIf='prospectForm?.controls?.rentPrice?.value'>{{formatMoney(prospectForm.controls?.rentPrice.value, true)}}</div>
                </div>
              </div>
            </div>
            <div class='col-2 text-center p-2'>
              <div *ngIf='data' class='col-12 mt-2 p-0'>
                <img class='custom-image' src='/assets/images/google_icon.png' (click)='openGoogleMap()'>
              </div>
              <div *ngIf='data' class='col-12 mt-3 p-0'>
                <img class='custom-image' src='/assets/images/avatar.png' (click)='searchAddress()'>
              </div>
            </div>
          </div>
          <div class='row custom-area m-1 mt-2'>
            <div class='col-12'>
              <div class='row m-0 p-2'>
                <div class='col-12'>Note</div>
                <div class='col-12 mt-1'>
                  <textarea class='no-border-input' draggable='false' rows='2' nbInput fullWidth
                            formControlName='note'></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class='row custom-area m-1 mt-2' *ngIf='data'>
            <div class='col-12'>
              <div class='row p-2'>
                <div class='col-5 pl-3'>DOB:</div>
                <div class='col-7 text-right'>
                  <div
                    *ngIf='prospectForm.controls?.dob.value'>{{prospectForm.controls?.dob.value | date: 'dd MMM yyyy'}}
                    {{getAge(prospectForm.controls?.dob.value)}}
                  </div>
                </div>
              </div>
              <hr class='m-0'>
              <div class='row mt-2 p-2'>
                <div class='col-5 pl-3'>Callback Date:</div>
                <div class='col-7 text-right'>
                  <div
                    *ngIf='prospectForm.controls?.callbackDate.value'>{{prospectForm.controls?.callbackDate.value | date: 'dd MMM yyyy'}}
                    {{getCallbackDate(prospectForm.controls?.callbackDate.value)}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--      END MAIN VIEW BLOCK-->

        <!--      START PROPERTY BLOCK-->
        <div class='col-12 mt-2' [hidden]='!showProperty'>
          <div class='row p-1' [hidden]='showDKBar'>
            <div class='col-3'>
              <button nbTooltip='Sell' nbButton size='small' class='w-100 border'
                      [status]='prospectForm.controls.sell.value ? "info" : "basic"' (click)="setSquareValue('sell')">
                Sell
              </button>
            </div>
            <div class='col-3'>
              <button nbTooltip='Rent' nbButton size='small' class='w-100 border'
                      [status]='prospectForm.controls.rent.value ? "info" : "basic"' (click)="setSquareValue('rent')">
                Rent
              </button>
            </div>
            <div class='col-3'>
              <button nbTooltip='On The Market' nbButton size='small' class='w-100 border'
                      [status]='prospectForm.controls.otm.value ? "info" : "basic"' (click)="setSquareValue('otm')"
                      [disabled]='!prospectForm.controls?.otmDate.value'>Otm
              </button>
            </div>
            <div class='col-3'>
              <button nbTooltip='VAC' nbButton size='small' class='w-100 border'
                      [status]='prospectForm.controls.vac.value ? "info" : "basic"' (click)="setSquareValue('vac')"
                      [disabled]='!prospectForm.controls?.vacDate.value'>Vac
              </button>
            </div>
          </div>

          <div class='row m-0 mt-2'>
            <div class='col-2'>
              <select nbTooltip='Type' formControlName='type'
                      [ngClass]="showError('type')"
                      (change)='onPropertyTypeChange()'
                      class='property-spec-select select-no-arrow border-1 text-center'>
                <option value='' disabled>Type</option>
                <option *ngFor='let item of propertyType | keyvalue:keepOrder'
                        value='{{item.key}}'
                >{{item.value}}</option>
              </select>
            </div>
            <div class='col-2'>
              <select nbTooltip='Bed' formControlName='bed'
                      [ngClass]="showError('bed')"
                      class='property-spec-select select-no-arrow border-1 text-center'

              >
                <option value='' disabled>Bed</option>
                <option *ngFor='let item of bedValues' [value]='item'>{{item}}</option>
              </select>
            </div>
            <div class='col-2'>
              <select nbTooltip='Bath' formControlName='bath'
                      [ngClass]="showError('bath')" class='property-spec-select border-1 select-no-arrow text-center'

              >
                <option value='' disabled>Bath</option>
                <option *ngFor='let item of bedValues' [value]='item'>{{item}}</option>
              </select>
            </div>
            <div class='col-2'>
              <select nbTooltip='Car' formControlName='car'
                      [ngClass]="showError('car')" class='property-spec-select border-1 select-no-arrow text-center'

              >
                <option value='' disabled>Car</option>
                <option *ngFor='let item of bedValues' [value]='item'>{{item}}</option>
              </select>
            </div>
            <div class='col-4'>
              <input nbTooltip='Land' type='text' nbInput fieldSize='tiny'
                     class='landInput border-1'
                     fullWidth name='land'
                     formControlName='land' placeholder='Land' [ngClass]="showError('land')"
              />
            </div>
          </div>

          <div class='row p-2 mt-2 property-price-container'>
            <div class='col-12'>
              <nb-form-field>
                <input class='no-border-input padding-prefix' nbInput fullWidth fieldSize='tiny'
                       placeholder='Sold Price'
                       formControlName='sellPrice'>
                <span class='font-size-icon' nbPrefix>$</span>
              </nb-form-field>
            </div>
            <hr class='m-2 w-100'>
            <div class='col-12'>
              <nb-form-field>
                <input class='no-border-input' nbInput fullWidth fieldSize='tiny' placeholder='Purchase Date'
                       formControlName='purchaseDate' [nbDatepicker]='datepickerPurchaseDate'>
                <div *ngIf='purchaseDurationText' class="purchase-color suffix-font pr-4" nbSuffix>
                  {{purchaseDurationText}}
                </div>
              </nb-form-field>
              <nb-datepicker #datepickerPurchaseDate></nb-datepicker>
            </div>
            <!--            <hr class='m-2 w-100' *ngIf='purchaseDurationText'>-->
            <!--            <div class='col-12'>-->
            <!--              {{purchaseDurationText}}-->
            <!--            </div>-->
          </div>

          <div class='row p-2 mt-2 property-price-container'>
            <div class='col-12'>
              <nb-form-field>
                <input class='no-border-input padding-prefix' nbInput fullWidth fieldSize='tiny'
                       placeholder='Asking Price'
                       formControlName='askingPrice'>
                <span class='font-size-icon' nbPrefix>$</span>
              </nb-form-field>
            </div>
            <hr class='m-2 w-100'>
            <div class='col-12'>
              <nb-form-field>
                <input class='no-border-input' nbInput fullWidth fieldSize='tiny' placeholder='Otm Date'
                       formControlName='otmDate' [nbDatepicker]='datepickerOtmDate'>
                <div *ngIf='otmDurationText' class="purchase-color suffix-font pr-4" nbSuffix>
                  {{otmDurationText}}
                </div>
              </nb-form-field>
              <nb-datepicker #datepickerOtmDate></nb-datepicker>
            </div>
            <!--            <hr class='m-2 w-100' *ngIf='otmDurationText'>-->
            <!--            <div class='col-12'>-->
            <!--              {{otmDurationText}}-->
            <!--          </div>-->
          </div>
          <div class='row p-2 mt-2 property-price-container'>
            <div class='col-12 p-0'>
              <select formControlName='sellCompanyId' class='form-select select-custom'>
                <option [value]='null' disabled>Sell Company</option>
                <option [value]='null'>Empty</option>
                <option *ngFor='let item of companies'
                        [value]='item.companyId'>{{item.name}}</option>
              </select>
            </div>
          </div>
          <div class='row p-2 mt-2 property-price-container'>
            <div class='col-12'>
              <nb-form-field>
                <input class='no-border-input padding-prefix' nbInput fullWidth fieldSize='tiny'
                       placeholder='Rent Price'
                       formControlName='rentPrice'>
                <span class='font-size-icon' nbPrefix>$/w</span>
              </nb-form-field>
            </div>
            <hr class='m-2 w-100'>
            <div class='col-12'>
              <nb-form-field>
                <input class='no-border-input' nbInput fullWidth fieldSize='tiny' placeholder='Vac Date'
                       formControlName='vacDate' [nbDatepicker]='datepickerVacDate'>
                <div *ngIf='vacDurationText' class="purchase-color suffix-font pr-4" nbSuffix>
                  {{vacDurationText}}
                </div>
              </nb-form-field>
              <nb-datepicker #datepickerVacDate></nb-datepicker>
            </div>
            <!--            <hr class='m-2 w-100' *ngIf='vacDurationText'>-->
            <!--            <div class='col-12'>-->
            <!--              {{vacDurationText}}-->
            <!--            </div>-->
          </div>
          <div class='row p-2 mt-2 property-price-container'>
            <div class='col-12 p-0'>
              <select formControlName='rentCompanyId' class='form-select select-custom'>
                <option [value]='null' disabled>Rent Company</option>
                <option [value]='null'>Empty</option>
                <option *ngFor='let item of companies'
                        [value]='item.companyId'>{{item.name}}</option>
              </select>
            </div>
          </div>
        </div>


        <!--      END PROPERTY BLOCK-->

        <!--      START CONTACT BLOCK-->
        <div class='col-12 mt-2' [hidden]='!showContact'>
          <div class='row p-1' [hidden]='showDKBar'>
            <div class='col-3'>
              <button nbTooltip='Mail?' nbButton size='small' class='w-100 border'
                      (click)="setSquareValue('mail')">
                <span [ngClass]='prospectForm.controls.mail.value ? "line-through" : ""'>Mail</span>
              </button>
            </div>
            <div class='col-3'>
              <button nbTooltip='Door?' nbButton size='small' class='w-100 border' (click)="setSquareValue('door')">
                <span [ngClass]='prospectForm.controls.door.value ? "line-through" : ""'>Door</span>
              </button>
            </div>
            <div class='col-3'>
              <button nbTooltip='Call?' nbButton size='small' class='w-100 border' (click)="setSquareValue('call')">
                <span [ngClass]='prospectForm.controls.call.value ? "line-through" : ""'>Call</span>
              </button>
            </div>
            <div class='col-3'>
              <button nbTooltip='SMS?' nbButton size='small' class='w-100 border' (click)="setSquareValue('sms')">
                <span [ngClass]='prospectForm.controls.sms.value ? "line-through" : ""'>Sms</span>
              </button>
            </div>
          </div>

          <div class='row m-0 mt-2 p-2 custom-area'>
            <div class='col-12'>First Name</div>
            <div class='col-12 mt-1'>
              <input nbInput fieldSize='tiny' class='no-border-input' formControlName='name' fullWidth>
            </div>
          </div>

          <div class='row m-0 mt-2 p-2 custom-area'>
            <div class='col-12'>Last Name</div>
            <div class='col-12 mt-1'>
              <input nbInput fieldSize='tiny' class='no-border-input' formControlName='surname' fullWidth>
            </div>
          </div>

          <div class='row m-0 mt-2 p-2 custom-area'>
            <div class='col-12'>Mobile</div>
            <div class='col-12 mt-1 d-flex'>
              <nb-form-field class='w-100'>
                <input nbInput fieldSize='tiny' class='no-border-input' formControlName='mobilePhone' fullWidth>
                <span class='phone-button' nbSuffix nbTooltip='Add Mobile Phone'
                      (click)='addPhoneNumber("MPHONE")'>+</span>
              </nb-form-field>
              <a nbButton size='tiny' class='btn-call' hero
                 [disabled]="!prospectForm.controls.mobilePhone.value || prospectForm.controls.mobilePhone.value === '' || prospectForm?.controls?.rate?.value > 3"
                 href='tel:{{prospectForm.controls.mobilePhone.value}}'
                 (click)='lastCalledPhoneNumber = prospectForm.controls.mobilePhone.value'>CALL</a>
            </div>
          </div>

          <div class='row m-0 mt-2 p-2 custom-area' *ngIf='additionalMobilePhone.length > 0'>
            <div class='col-12'>Alternative Mobile</div>
            <div *ngFor='let additionalInfo of additionalMobilePhone, index as index' class='col-12 mt-1'>
              <div class='col-12 d-flex' *ngIf='!additionalInfo.isDeleted'>
                <nb-form-field class='w-100'>
                  <input placeholder='Name' nbInput fieldSize='tiny' class='no-border-input' fullWidth
                         [(ngModel)]='additionalInfo.name'
                         [ngClass]="{'phone-inactive': additionalInfo.call}"
                         [ngModelOptions]='{standalone: true}'>
                </nb-form-field>
                <button nbButton size='tiny' status='danger' class='ml-1'
                        (click)='removePhoneNumber(additionalInfo, index)'> -
                </button>
                <button nbButton size='tiny' status='primary' class='ml-1' (click)='setPrimary(additionalInfo, index)'>
                  Primary
                </button>
              </div>

              <div class='col-12 d-flex' *ngIf='!additionalInfo.isDeleted'>
                <nb-form-field class='w-100 mt-1'>
                  <input placeholder='Phone Number' nbInput fieldSize='tiny' class='no-border-input' fullWidth
                         [(ngModel)]='additionalInfo.phoneNumber'
                         [ngClass]="{'phone-inactive': additionalInfo.call}"
                         [ngModelOptions]='{standalone: true}'>
                </nb-form-field>
              </div>
              <hr class='m-1 w-100' *ngIf='index !== additionalMobilePhone.length - 1'>
            </div>
          </div>

          <div class='row m-0 mt-2 p-2 custom-area'>
            <div class='col-12'>Phone</div>
            <div class='col-12 mt-1 d-flex'>
              <nb-form-field class='w-100'>
                <input nbInput fieldSize='tiny' class='no-border-input' formControlName='phone' fullWidth>
                <span class='phone-button' nbSuffix nbTooltip='Add Mobile Phone'
                      (click)='addPhoneNumber("PHONE")'>+</span>
              </nb-form-field>
              <a nbButton nbSuffix size='tiny' class='btn-call' hero
                 [disabled]="!prospectForm.controls.phone.value || prospectForm.controls.phone.value === '' || prospectForm?.controls?.rate?.value > 3"
                 href='tel:{{prospectForm.controls.phone.value}}'
                 (click)='lastCalledPhoneNumber = prospectForm.controls.phone.value'>CALL</a>
            </div>
          </div>

          <div class='row m-0 mt-2 p-2 custom-area' *ngIf='additionalPhone.length > 0'>
            <div class='col-12'>Alternative Phone</div>
            <div *ngFor='let additionalInfo of additionalPhone, index as index' class='mt-1'>
              <div class='col-12 d-flex' *ngIf='!additionalInfo.isDeleted'>
                <nb-form-field class='w-100'>
                  <input placeholder='Name' nbInput fieldSize='tiny' class='no-border-input' fullWidth
                         [(ngModel)]='additionalInfo.name'
                         [ngClass]="{'phone-inactive': additionalInfo.call}"
                         [ngModelOptions]='{standalone: true}'>
                </nb-form-field>
                <button nbButton size='tiny' status='danger' class='ml-1'
                        (click)='removePhoneNumber(additionalInfo, index)'> -
                </button>
                <button nbButton size='tiny' status='primary' class='ml-1' (click)='setPrimary(additionalInfo, index)'>
                  Primary
                </button>
              </div>
              <div class='col-12 d-flex' *ngIf='!additionalInfo.isDeleted'>
                <nb-form-field class='w-100 mt-1'>
                  <input placeholder='Phone Number' nbInput fieldSize='tiny' class='no-border-input' fullWidth
                         [(ngModel)]='additionalInfo.phoneNumber'
                         [ngClass]="{'phone-inactive': additionalInfo.call}"
                         [ngModelOptions]='{standalone: true}'>
                </nb-form-field>
              </div>
              <hr class='m-1 w-100' *ngIf='index !== additionalPhone.length - 1'>
            </div>
          </div>

          <div class='row m-0 mt-2 p-2 custom-area'>
            <div class='col-12'>Email</div>
            <div class='col-12 mt-1'>
              <input nbInput fieldSize='tiny' class='no-border-input' formControlName='email' fullWidth>
            </div>
          </div>

          <nb-accordion class='mt-2'>
            <nb-accordion-item class='custom-area'>
              <nb-accordion-item-header>
                Extra Info
              </nb-accordion-item-header>
              <nb-accordion-item-body>
                <div class='row m-0 mt-2 p-2 custom-area'>
                  <div class='col-12'>Facebook</div>
                  <div class='col-12 mt-1'>
                    <input nbInput fieldSize='tiny' class='no-border-input' formControlName='facebook' fullWidth>
                  </div>
                </div>

                <div class='row m-0 mt-2 p-2 custom-area'>
                  <div class='col-12'>Instagram</div>
                  <div class='col-12 mt-1'>
                    <input nbInput fieldSize='tiny' class='no-border-input' formControlName='instagram' fullWidth>
                  </div>
                </div>

                <div class='row m-0 mt-2'>
                  <div class='col-6'>
                    <select size='tiny' formControlName='nationality' class='select-custom'
                            (change)='onNationalityChange()'>
                      <option [value]='null' disabled>Nationality</option>
                      <option *ngFor='let item of nationalities'
                              [value]='item.country_name'>{{item.country_name}}</option>
                    </select>
                  </div>
                  <div class='col-6'>
                    <select size='tiny' formControlName='religion' class='select-custom'>
                      <option [value]='null' disabled>Religion</option>
                      <option *ngFor='let item of religions'
                              [value]='item.name'>{{item.name}}</option>
                    </select>
                  </div>
                </div>

                <div class='row m-0 mt-2'>
                  <div class='col-12'>
                    <select size='tiny' formControlName='language' class='select-custom'>
                      <option [value]='null' disabled>Language</option>
                      <option *ngFor='let item of languages'
                              [value]='item.code'>{{item.name}}</option>
                    </select>
                  </div>
                </div>

                <div class='row m-0 mt-2 p-2 custom-area'>
                  <div class='col-12'>Profession</div>
                  <div class='col-12'>
                    <input type='text' nbInput fieldSize='tiny'
                           fullWidth name='profesion'
                           formControlName='profesion'
                           [ngClass]="showError('profesion')"
                           class='no-border-input'
                    />
                  </div>
                </div>
                <div class='row m-0 mt-2 p-2 custom-area'>
                  <div class='col-12'>Anniversary Day</div>
                  <div class='col-12'>
                    <input [nbDatepicker]='datepickerAnniversaryDay' nbInput fieldSize='tiny'
                           fullWidth formControlName='anniversaryDay' class='no-border-input'/>
                    <nb-datepicker #datepickerAnniversaryDay></nb-datepicker>
                  </div>
                </div>
                <div class='row m-0 mt-2 p-2 custom-area'>
                  <div class='col-12'>Nationality Note</div>
                  <div class='col-12'>
                  <textarea rows='2' class='no-border-input'
                            nbInput fullWidth
                            formControlName='nationalityNote'
                            [ngClass]="showError('nationalityNote')"></textarea>
                  </div>
                </div>
              </nb-accordion-item-body>
            </nb-accordion-item>
          </nb-accordion>
        </div>

        <!--      END CONTACT BLOCK-->

        <!--      START MOTIVE BLOCK-->
        <div class='col-12 mt-2' [hidden]='!showMotive'>
          <div class='row p-1' [hidden]='showDKBar'>
            <div class='col-3'>
              <button nbTooltip='Financial Up' nbButton size='small' class='w-100 border'
                      [status]='prospectForm.controls.motivateFinancialUp.value ? "info" : "basic"'
                      (click)="setSquareValue('motivateFinancialUp')"><i class='fa fa-usd'></i>+
              </button>
            </div>
            <div class='col-3'>
              <button nbTooltip='Financial Down' nbButton size='small' class='w-100 border'
                      [status]='prospectForm.controls.motivateFinancialDown.value ? "info" : "basic"'
                      (click)="setSquareValue('motivateFinancialDown')"><i class='fa fa-usd'></i>-
              </button>
            </div>
            <div class='col-3'>
              <button nbTooltip='Life style Up' nbButton size='small' class='w-100 border'
                      [status]='prospectForm.controls.motivateLifestyleUp.value ? "info" : "basic"'
                      (click)="setSquareValue('motivateLifestyleUp')"><i class='fa fa-heart'></i>+
              </button>
            </div>
            <div class='col-3'>
              <button nbTooltip='Life style Down' nbButton size='small' class='w-100 border'
                      [status]='prospectForm.controls.motivateLifestyleDown.value ? "info" : "basic"'
                      (click)="setSquareValue('motivateLifestyleDown')"><i class='fa fa-heart'></i>-
              </button>
            </div>
          </div>
          <div class='row mt-2 p-1'>
            <div class='col-6'>
              <select size='tiny' formControlName='relationship' class='select-custom'>
                <option [value]='null' disabled>Relationship</option>
                <option *ngFor='let item of customerRelationShip | keyvalue:keepOrder'
                        [value]='item.value'>{{item.value}}</option>
              </select>
            </div>
            <div class='col-6'>
              <select size='tiny' formControlName='motivate' class='select-custom'>
                <option [value]='null' disabled>Motivate</option>
                <option *ngFor='let item of customerMotivate | keyvalue:keepOrder'
                        [value]='item.value'>{{item.value}}</option>
              </select>
            </div>
          </div>
          <div class='row m-0 mt-2 p-2 custom-area'>
            <div class='col-4'>Date of Birth</div>
            <div class='col-8'>
              <nb-form-field>
                <input class='no-border-input' nbInput fieldSize='tiny' fullWidth formControlName='dob'
                       [nbDatepicker]='datepickerDob'>
                <nb-datepicker #datepickerDob></nb-datepicker>
                <button nbSuffix nbButton ghost (click)="createTask('BIRTHDAY_CALL')">
                  <nb-icon [icon]="'plus-outline'"
                           pack='eva'>
                  </nb-icon>
                </button>
              </nb-form-field>
            </div>
          </div>
          <div class='row m-0 mt-2 p-2 custom-area'>
            <div class='col-4'>Callback date</div>
            <div class='col-8'>
              <nb-form-field>
                <input class='no-border-input' nbInput fieldSize='tiny' fullWidth formControlName='callbackDate'
                       [nbDatepicker]='datepickerCallbackDate'>
                <nb-datepicker #datepickerCallbackDate></nb-datepicker>
                <button nbSuffix nbButton ghost (click)="createTask('NEXT_CALL')">
                  <nb-icon [icon]="'plus-outline'"
                           pack='eva'>
                  </nb-icon>
                </button>
              </nb-form-field>
            </div>
          </div>
          <div class='row m-0 mt-2 p-2 custom-area'>
            <div class='col-12'>Motive Note</div>
            <div class='col-12 mt-1'>
                  <textarea class='no-border-input' draggable='false' rows='2' nbInput fullWidth
                            formControlName='motiveNote'></textarea>
            </div>
          </div>
        </div>

        <!--      END MOTIVE BLOCK-->

        <!--      START HISTORY BLOCK-->
        <div class='col-12 mt-2' [hidden]='!showHistory'>
          <div class='row p-1' [hidden]='showDKBar'>
            <div class='col-3'>
              <button nbTooltip='Director' nbButton size='small' class='w-100 border'
                      [status]='prospectForm.controls.isDirector.value ? "info" : "basic"'
                      (click)="setSquareValue('isDirector')">D
              </button>
            </div>
            <div class='col-3'>
              <button nbTooltip='Influencer' nbButton size='small' class='w-100 border'
                      [status]='prospectForm.controls.isInfluencer.value ? "info" : "basic"'
                      (click)="setSquareValue('isInfluencer')">I
              </button>
            </div>
            <div class='col-3'>
              <button nbTooltip='Concensious' nbButton size='small' class='w-100 border'
                      [status]='prospectForm.controls.isConcensious.value ? "info" : "basic"'
                      (click)="setSquareValue('isConcensious')">C
              </button>
            </div>
            <div class='col-3'>
              <button nbTooltip='Socialble' nbButton size='small' class='w-100 border'
                      [status]='prospectForm.controls.isSocialble.value ? "info" : "basic"'
                      (click)="setSquareValue('isSocialble')">S
              </button>
            </div>
          </div>
          <div class='row m-0 mt-2 p-2 custom-area'>
            <div class='col-12'>
              <span>Note</span>
              <div class='app-speech-input fa-pull-right' [class.app-speech-input-listening]='listening'>
                <button class='app-speech-input-btn speech-color' [disabled]='listening' (click)='listen()'></button>
              </div>
            </div>
            <div class='col-12 mt-1'>
                  <textarea class='no-border-input' draggable='false' rows='2' nbInput fullWidth
                            formControlName='note'></textarea>
            </div>
          </div>
          <div class='row m-0 mt-2'>
            <div class='col-12'>
              <button *ngIf='!isNewNote' nbButton size='tiny' class='fa-pull-right' status='info' hero
                      (click)='newNote()'>New
              </button>
              <button *ngIf='isNewNote' nbButton size='tiny' class='fa-pull-right' hero
                      (click)='cancelNewNote()'>Cancel
              </button>
            </div>
          </div>
          <nb-card class='log-view mt-2 custom-area'>
            <nb-list>
              <nb-list-item *ngFor='let log of sortHistoryList()'>
                <div [innerHTML]='formatLog(log)'></div>
              </nb-list-item>
            </nb-list>
          </nb-card>
        </div>
      </div>
      <!--      END HISTORY BLOCK-->

    </nb-card-body>
    <nb-card-footer class='mb-2 p-0 mt-2 border-top-0'>
      <div class='col-12 d-inline-flex justify-content-center mt-2'>
        <div class='override-width p-1 text-center'>
          <button nbButton fullWidth size='medium' class='custom-button-footer d-block p-0 text-center button-details'
                  [nbPopover]='tag'>
            <nb-icon icon='pricetags-outline' pack='eva' class='m-0'></nb-icon>
            <div class='text-center mt-2'>Tag</div>
          </button>
        </div>
        <div class='override-width p-1 text-center'>
          <button (click)='logContactStatus(contactLogType.DOOR_NOCKED)' nbButton fullWidth size='medium'
                  class='custom-button-footer d-block p-0 text-center button-details'>
            <nb-icon icon='bell-outline' pack='eva' class='m-0'></nb-icon>
            <div class='text-center mt-2'>Door</div>
          </button>

        </div>
        <div class='override-width p-1 text-center'>
          <button (click)='logContactStatus(contactLogType.CALLED)'
                  [disabled]='prospectForm?.controls?.rate?.value > 3' nbButton fullWidth size='medium'
                  class='custom-button-footer d-block p-0 text-center button-details'>
            <nb-icon icon='phone-call-outline' pack='eva' class='m-0'></nb-icon>
            <div class='text-center mt-2'>Call</div>
          </button>

        </div>
        <div class='override-width p-1 text-center'>
          <button (click)='logContactStatus(contactLogType.SMS)' nbButton fullWidth size='medium'
                  class='custom-button-footer d-block p-0 text-center button-details'>
            <nb-icon icon='message-circle-outline' pack='eva' class='m-0'></nb-icon>
            <div class='text-center mt-2'>Sms</div>
          </button>
        </div>
        <div class='override-width p-1 text-center'>
          <button (click)='submit()' nbButton fullWidth size='medium'
                  class='custom-button-footer d-block p-0 text-center button-details'>
            <nb-icon icon='save-outline' pack='eva' class='m-0'></nb-icon>
            <div class='text-center mt-2'>Save</div>
          </button>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</form>

<ng-template #tag>
  <div class='row m-0 p-2'>
    <div class='col-12'>
      <input
        [(ngModel)]='categoryName'
        [ngModelOptions]='{standalone: true}'
        [typeahead]='filterCategory$'
        [typeaheadAsync]='true'
        (typeaheadOnSelect)='selectCategory($event)'
        typeaheadOptionField='name'
        typeaheadWaitMs='1000'
        placeholder='Search Tag'
        nbInput fieldSize='tiny' fullWidth>
    </div>
  </div>
  <div class='row m-0 p-2'>
    <div class='col-12'>
      <div *ngFor='let category of selectedCategories' class='category-block mt-2'>
        {{category.name}}
        <button class='btn btn-danger btn-sm' (click)='removeCategory(category)'>
          <i class='fa fa-times' aria-hidden='true'></i>
        </button>
      </div>
    </div>
  </div>
</ng-template>
