<nb-card class='container'>
  <nb-card-body>
    <div class="mt-2">
      <ng2-smart-table [settings]="settings"
                       [source]="source"
      >
      </ng2-smart-table>
<!--      <div *ngIf='!data.customer'>-->
<!--        No prospect found !-->
<!--      </div>-->
    </div>
  </nb-card-body>
  <nb-card-footer>
    <div>
      <button nbButton status='primary' size='tiny' class='mr-4' (click)='copy()'>Copy</button>
      <button nbButton status='primary' size='tiny' (click)='link()'>Link</button>
    </div>
  </nb-card-footer>
</nb-card>
