import {CustomerRequest} from './customer-request';
import {PropertyRequest} from './property-request';
import {TaskRequest} from '../task-request';
import { NationalityNoteRequest } from '../nationality-note-request';
import { OfficeRequest } from '../office-request';
import { VendorRequest } from './vendor-request';
import { ListingRepresentativeRequest } from './listing-representative-request';
import { Company } from '../../response/prospect/company';
import { CompanyRequest } from './company-request';
import { ContractChecklist } from '../../response/contract-checklist';
import { ContractChecklistRequest } from '../contract-checklist-request';
import { MarketingRequest } from './marketing-request';
import { TenantRequest } from './tenant-request';
import {AddressRequest} from '../address/address-request';

export class ProspectRequest {
  prospectId?: number;
  note?: string;
  motiveNote?: string;
  call?: boolean;
  sms?: boolean;
  door?: boolean;
  mail?: boolean;
  rate?: number;
  createdBy?: string;
  createdDate?: number;
  customer?: CustomerRequest;
  property?: PropertyRequest;
  userId?: number;
  categoryIds?: number[];
  nationalityNote?: NationalityNoteRequest;
  office?: OfficeRequest;
  officeId?: number;
  vendors?: VendorRequest[];
  listingRepresentative?: ListingRepresentativeRequest;
  solicitorCompanyId?: number;
  historyNote?: string;
  contractRequestDate?: number;
  contractReceivedDate?: number;
  contractChecklist?: ContractChecklistRequest[];
  marketing?: MarketingRequest;
  tenant?: TenantRequest;
  addressId?: number;
  address?: AddressRequest;

  multipleAddress?: AddressRequest[];

  static getDummy = () => {
    return {
      prospectId: '',
      note: '',
      motiveNote: '',
      call: '',
      sms: '',
      door: '',
      mail: '',
      rate: '',
      createdBy: '',
      createdDate: '',
      customer: '',
      property: '',
      address: '',
      nationalityNote: '',
      office: '',
      vendors: '',
      listingRepresentative: '',
      solicitorCompanyId: '',
      historyNote: '',
      contractRequestDate: '',
      contractReceivedDate: '',
      contractChecklist: '',
      marketing: '',
      tenant: '',
    };
  }
}
