import {NgModule} from '@angular/core';
import {
  NbAccordionModule,
  NbActionsModule,
  NbAutocompleteModule,
  NbButtonModule,
  NbCalendarModule,
  NbCalendarRangeModule,
  NbCardModule,
  NbCheckboxModule,
  NbDatepickerModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule, NbListModule,
  NbSelectModule, NbTooltipModule,
} from '@nebular/theme';
import {Ng2SmartTableModule} from 'ng2-smart-table';

import {ThemeModule} from '../../../@theme/theme.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {routedComponents, TaskRoutingModule} from './task-routing.module';
import {CalendarModule} from 'angular-calendar';
import {ContextMenuModule} from 'ngx-contextmenu';
import {SharedModule} from '../../../shared/module/shared.module';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {DirectivesModule} from '../../../shared/directive/directives.module';


@NgModule({
  imports: [
    NbCardModule,
    NbIconModule,
    NbInputModule,
    ThemeModule,
    TaskRoutingModule,
    Ng2SmartTableModule,
    NbButtonModule,
    NbCheckboxModule,
    FormsModule,
    NbActionsModule,
    NbIconModule,
    NbCalendarModule,
    NbCalendarRangeModule,
    NbDatepickerModule,
    NbSelectModule,
    ReactiveFormsModule,
    NbFormFieldModule,
    NbAutocompleteModule,
    CalendarModule,
    NbLayoutModule,
    ContextMenuModule.forRoot({
      useBootstrap4: true,
    }),
    NbListModule,
    SharedModule,
    NbTooltipModule,
    NbAccordionModule,
    DragDropModule,
    DirectivesModule,
  ],
  declarations: [
    ...routedComponents,
  ],
})
export class TaskModule {
}
