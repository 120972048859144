import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  NbAccordionModule,
  NbAutocompleteModule, NbButtonModule,
  NbCardModule,
  NbCheckboxModule, NbDatepickerModule, NbDialogModule, NbFormFieldModule,
  NbInputModule,
  NbListModule,
  NbSelectModule,
  NbTabsetModule, NbTooltipModule,
} from '@nebular/theme';
import {FormsModule} from '@angular/forms';
import {ProspectModule} from '../prospect/prospect.module';
import {BuyerModule} from '../buyer/buyer.module';
import {DirectivesModule} from '../../../shared/directive/directives.module';
import {ChartsModule} from 'ng2-charts';
import {OverviewRoutingModule, routedComponents} from './overview-routing.module';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {SharedModule} from '../../../shared/module/shared.module';
import {Ng2SmartTableModule} from 'ng2-smart-table';
import { TotalImportComponent } from './total-import/total-import.component';
import { RatioComponent } from './ratio/ratio.component';
import { DobOverviewComponent } from './dob-overview/dob-overview.component';
import { TotalMessageListComponent } from './total-message-list/total-message-list.component';
import { VisualBoardComponent } from './visual-board/visual-board.component';
import { RentalBoardComponent } from './rental-board/rental-board.component';


@NgModule({
  declarations: [
    ...routedComponents,
    TotalImportComponent,
    RatioComponent,
    DobOverviewComponent,
    TotalMessageListComponent,
    VisualBoardComponent,
    RentalBoardComponent,
  ], imports: [
    CommonModule,
    NbCardModule,
    NbSelectModule,
    NbButtonModule,
    NbCheckboxModule,
    NbListModule,
    FormsModule,
    Ng2SmartTableModule,
    TypeaheadModule,
    NbTooltipModule,
    ChartsModule,
    NbAutocompleteModule,
    NbInputModule,
    ProspectModule,
    BuyerModule,
    DirectivesModule,
    OverviewRoutingModule,
    NbTabsetModule,
    SharedModule,
    NbDatepickerModule,
    NbAccordionModule,
    NbDialogModule,
    NbFormFieldModule,
  ],
})
export class OverviewModule {
}
