<nb-card>
  <nb-card-body>
    <div *ngIf='!selectedUsers'>
      Please select an user
    </div>
    <div *ngIf='selectedUsers'>
      <!--      <div class='row ml-3 mb-2'>-->
      <!--        User: {{ selectedUser && selectedUser.name ? selectedUser.name + ' (' + stringUtils.shortenUserName(selectedUser) + ')' : '' }}-->
      <!--      </div>-->
      <div class='col-12 mb-2' *ngIf='lastMonthStats'>
        <nb-select size='tiny' [(ngModel)]='selectedType'>
          <nb-option *ngFor='let type of types' [value]='type'
                     (click)='onTypeChange(type)'>{{ type }}</nb-option>
        </nb-select>
        <nb-select [(ngModel)]='selectedNum' size='tiny' class='ml-1' *ngIf='selectedType !== "year"'>
          <nb-option *ngFor='let num of nums' [value]='num'
                     (click)='onNumChange(num)'>{{ num }}</nb-option>
        </nb-select>
        <nb-select [(ngModel)]='selectedYear' size='tiny' class='ml-1'>
          <nb-option *ngFor='let year of years' [value]='year'
                     (click)='onYearChange(year)'>{{ year }}</nb-option>
        </nb-select>
      </div>
      <div class='col-12 mt-2 mb-1'>
        <span class='ml-2 font-weight-bold'>{{ dateFrom | date: 'd/M/yy' }}</span>
        <span class='font-weight-bold'> - </span>
        <span class='font-weight-bold'>{{ dateTo | date: 'd/M/yy' }}</span>
      </div>
    </div>
    <div class='col-12 flex' *ngIf='lastMonthStats'>
      <div class='ratio'>
        <nb-accordion>
          <nb-accordion-item>
            <nb-accordion-item-header class='prospect-ratio d-flex'>
              <div class='col-2'>
                SN : SC
              </div>
              <div class='col-3'>
                {{lastMonthStats.totalSN}} : {{lastMonthStats.totalSC}}
              </div>
              <div class='col-3'>
                {{reducedRatio(lastMonthStats.totalSN, lastMonthStats.totalSC)}}
              </div>
              <div class='col-4'>
                {{precisionRatio(SNOnSC)}}
              </div>
            </nb-accordion-item-header>
            <nb-accordion-item-body>
              SN : {{lastMonthStats.totalSN}}
              <br>
              SC : {{lastMonthStats.totalSC}}
            </nb-accordion-item-body>
          </nb-accordion-item>
          <nb-accordion-item>
            <nb-accordion-item-header class='prospect-ratio mt-2 d-flex'>
              <div class='col-2'>
                SC : SA
              </div>
              <div class='col-3'>
                {{lastMonthStats.totalSC}} : {{lastMonthStats.totalSA}}
              </div>
              <div class='col-3'>
                {{reducedRatio(lastMonthStats.totalSC, lastMonthStats.totalSA)}}
              </div>
              <div class='col-4'>
                {{precisionRatio(SCOnSA)}}
              </div>
            </nb-accordion-item-header>
            <nb-accordion-item-body>
              SC : {{lastMonthStats.totalSC}}
              <br>
              SA : {{lastMonthStats.totalSA}}
            </nb-accordion-item-body>
          </nb-accordion-item>
          <nb-accordion-item>
            <nb-accordion-item-header class='prospect-ratio mt-2 d-flex'>
              <div class='col-2'>
                SA : SP
              </div>
              <div class='col-3'>
                {{lastMonthStats.totalSA}} : {{lastMonthStats.totalSP}}
              </div>
              <div class='col-3'>
                {{reducedRatio(lastMonthStats.totalSA, lastMonthStats.totalSP)}}
              </div>
              <div class='col-4'>
                {{precisionRatio(SAOnSP)}}
              </div>
            </nb-accordion-item-header>
            <nb-accordion-item-body>
              SA : {{lastMonthStats.totalSA}}
              <br>
              SP : {{lastMonthStats.totalSP}}
            </nb-accordion-item-body>
          </nb-accordion-item>
          <nb-accordion-item>
            <nb-accordion-item-header class='prospect-ratio mt-2 d-flex'>
              <div class='col-2'>
                SP : S4
              </div>
              <div class='col-3'>
                {{lastMonthStats.totalSP}} : {{lastMonthStats.totalS4}}
              </div>
              <div class='col-3'>
                {{reducedRatio(lastMonthStats.totalSP, lastMonthStats.totalS4)}}
              </div>
              <div class='col-4'>
                {{precisionRatio(SPOnS4)}}
              </div>
            </nb-accordion-item-header>
            <nb-accordion-item-body>
              SP : {{lastMonthStats.totalSP}}
              <br>
              S4 : {{lastMonthStats.totalS4}}
            </nb-accordion-item-body>
          </nb-accordion-item>
          <nb-accordion-item>
            <nb-accordion-item-header class='prospect-ratio mt-2 d-flex'>
              <div class='col-2'>
                S4 : O4
              </div>
              <div class='col-3'>
                {{lastMonthStats.totalS4}} : {{lastMonthStats.totalO4}}
              </div>
              <div class='col-3'>
                {{reducedRatio(lastMonthStats.totalS4, lastMonthStats.totalO4)}}
              </div>
              <div class='col-4'>
                {{precisionRatio(S4OnO4)}}
              </div>
            </nb-accordion-item-header>
            <nb-accordion-item-body>
              S4 : {{lastMonthStats.totalS4}}
              <br>
              O4 : {{lastMonthStats.totalO4}}
            </nb-accordion-item-body>
          </nb-accordion-item>
          <nb-accordion-item>
            <nb-accordion-item-header class='buyer-ratio mt-2 d-flex'>
              <div class='col-2'>
                BN : BC
              </div>
              <div class='col-3'>
                {{lastMonthStats.totalBN}} : {{lastMonthStats.totalBC}}
              </div>
              <div class='col-3'>
                {{reducedRatio(lastMonthStats.totalBN, lastMonthStats.totalBC)}}
              </div>
              <div class='col-4'>
                {{precisionRatio(BNOnBC)}}
              </div>
            </nb-accordion-item-header>
            <nb-accordion-item-body>
              BN : {{lastMonthStats.totalBN}}
              <br>
              BC : {{lastMonthStats.totalBC}}
            </nb-accordion-item-body>
          </nb-accordion-item>
          <nb-accordion-item>
            <nb-accordion-item-header class='buyer-ratio mt-2 d-flex'>
              <div class='col-2'>
                BC : BA
              </div>
              <div class='col-3'>
                {{lastMonthStats.totalBC}} : {{lastMonthStats.totalBA}}
              </div>
              <div class='col-3'>
                {{reducedRatio(lastMonthStats.totalBC, lastMonthStats.totalBA)}}
              </div>
              <div class='col-4'>
                {{precisionRatio(BCOnBA)}}
              </div>
            </nb-accordion-item-header>
            <nb-accordion-item-body>
              BC : {{lastMonthStats.totalBC}}
              <br>
              BA : {{lastMonthStats.totalBA}}
            </nb-accordion-item-body>
          </nb-accordion-item>
          <nb-accordion-item>
            <nb-accordion-item-header class='buyer-ratio mt-2 d-flex'>
              <div class='col-2'>
                BA : BI
              </div>
              <div class='col-3'>
                {{lastMonthStats.totalBA}} : {{lastMonthStats.totalBI}}
              </div>
              <div class='col-3'>
                {{reducedRatio(lastMonthStats.totalBA, lastMonthStats.totalBI)}}
              </div>
              <div class='col-4'>
                {{precisionRatio(BAOnBI)}}
              </div>
            </nb-accordion-item-header>
            <nb-accordion-item-body>
              BA : {{lastMonthStats.totalBA}}
              <br>
              BI : {{lastMonthStats.totalBI}}
            </nb-accordion-item-body>
          </nb-accordion-item>
          <nb-accordion-item>
            <nb-accordion-item-header class='buyer-ratio mt-2 d-flex'>
              <div class='col-2'>
                BI : B4
              </div>
              <div class='col-3'>
                {{lastMonthStats.totalBI}} : {{lastMonthStats.totalB4}}
              </div>
              <div class='col-3'>
                {{reducedRatio(lastMonthStats.totalBI, lastMonthStats.totalB4)}}
              </div>
              <div class='col-4'>
                {{precisionRatio(BIOnB4)}}
              </div>
            </nb-accordion-item-header>
            <nb-accordion-item-body>
              BI : {{lastMonthStats.totalBI}}
              <br>
              B4 : {{lastMonthStats.totalB4}}
            </nb-accordion-item-body>
          </nb-accordion-item>
          <nb-accordion-item>
            <nb-accordion-item-header class='buyer-ratio mt-2 d-flex'>
              <div class='col-2'>
                B4 : B5
              </div>
              <div class='col-3'>
                {{lastMonthStats.totalB4}} : {{lastMonthStats.totalB5}}
              </div>
              <div class='col-3'>
                {{reducedRatio(lastMonthStats.totalB4, lastMonthStats.totalB5)}}
              </div>
              <div class='col-4'>
                {{precisionRatio(B4OnB5)}}
              </div>
            </nb-accordion-item-header>
            <nb-accordion-item-body>
              B4 : {{lastMonthStats.totalB4}}
              <br>
              B5 : {{lastMonthStats.totalB5}}
            </nb-accordion-item-body>
          </nb-accordion-item>
          <nb-accordion-item>
            <nb-accordion-item-header class='buyer-ratio mt-2 d-flex'>
              <div class='col-2'>
                B5 : B6
              </div>
              <div class='col-3'>
                {{lastMonthStats.totalB5}} : {{lastMonthStats.totalB6}}
              </div>
              <div class='col-3'>
                {{reducedRatio(lastMonthStats.totalB5, lastMonthStats.totalB6)}}
              </div>
              <div class='col-4'>
                {{precisionRatio(B5OnB6)}}
              </div>
            </nb-accordion-item-header>
            <nb-accordion-item-body>
              B5 : {{lastMonthStats.totalB5}}
              <br>
              B6 : {{lastMonthStats.totalB6}}
            </nb-accordion-item-body>
          </nb-accordion-item>
        </nb-accordion>
      </div>
      <div class='caculate mt-2'>
        <nb-card class='h-100'>
          <nb-card-header>
            Calculate goal for {{currentMonth}}
          </nb-card-header>
          <nb-card-body>
            <div>
              <input nbInput fullWidth size='tiny' [(ngModel)]='desiredListings' placeholder='Desired Listings'>
            </div>
            <div class='mt-2 mb-2'>
              <input nbInput fullWidth size='tiny' [(ngModel)]='desiredSales' placeholder='Desired Sales'>
            </div>
            <button nbButton status='primary' size='small' (click)='calculateGoal()' class='pull-right'>Calculate
            </button>
            <div *ngIf='showGoal' class='mt-5'>
              <table class='table table-bordered'>
                <tr>
                  <th>Listings</th>
                  <th>{{_desiredListings}}</th>
                  <th>Sales</th>
                  <th>{{_desiredSales}}</th>
                </tr>
                <tr>
                  <th>S4</th>
                  <td>{{_desiredListings}} * <span
                    [ngStyle]='{"color": (precisionRatio(S4OnO4) !== "N/A") ? "black" : "red"}'
                    [nbTooltip]='checkS4OnO4Tooltip()'
                    [nbTooltipTrigger]='checkS4OnO4TooltipStatus()'>{{checkS4OnO4()}}</span> = {{s4}}</td>
                  <th>B5</th>
                  <td>{{_desiredSales}} * <span
                    [ngStyle]='{"color": (precisionRatio(B5OnB6) !== "N/A") ? "black" : "red"}'
                    [nbTooltip]='checkB5OnB6Tooltip()'
                    [nbTooltipTrigger]='checkB5OnB6TooltipStatus()'>{{checkB5OnB6()}}</span> = {{b5}}</td>
                </tr>
                <tr>
                  <th>SP</th>
                  <td>{{s4}} * <span
                    [ngStyle]='{"color": (precisionRatio(SPOnS4) !== "N/A") ? "black" : "red"}'
                    [nbTooltip]='checkSpOnS4Tooltip()'
                    [nbTooltipTrigger]='checkSpOnS4TooltipStatus()'>{{checkSpOnS4()}}</span> = {{sp}}</td>
                  <th>B4</th>
                  <td>{{b5}} * <span
                    [ngStyle]='{"color": (precisionRatio(B4OnB5) !== "N/A") ? "black" : "red"}'
                    [nbTooltip]='checkB4OnB5Tooltip()'
                    [nbTooltipTrigger]='checkB4OnB5TooltipStatus()'>{{checkB4OnB5()}}</span> = {{b4}}</td>
                </tr>
                <tr>
                  <th>SA</th>
                  <td>{{sp}} * <span
                    [ngStyle]='{"color": (precisionRatio(SAOnSP) !== "N/A") ? "black" : "red"}'
                    [nbTooltip]='checkSaOnSpTooltip()'
                    [nbTooltipTrigger]='checkSaOnSpTooltipStatus()'>{{checkSaOnSp()}}</span> = {{sa}}</td>
                  <th>BI</th>
                  <td>{{b4}} * <span
                    [ngStyle]='{"color": (precisionRatio(BIOnB4) !== "N/A") ? "black" : "red"}'
                    [nbTooltip]='checkBiOnB4Tooltip()'
                    [nbTooltipTrigger]='checkBiOnB4TooltipStatus()'>{{checkBiOnB4()}}</span> = {{bi}}</td>
                </tr>
                <tr>
                  <th>SC</th>
                  <td>{{sa}} * <span
                    [ngStyle]='{"color": (precisionRatio(SCOnSA) !== "N/A") ? "black" : "red"}'
                    [nbTooltip]='checkScOnSaTooltip()'
                    [nbTooltipTrigger]='checkScOnSaTooltipStatus()'>{{checkScOnSa()}}</span> = {{sc}}</td>
                  <th>BA</th>
                  <td>{{bi}} * <span
                    [ngStyle]='{"color": (precisionRatio(BAOnBI) !== "N/A") ? "black" : "red"}'
                    [nbTooltip]='checkBaOnBiTooltip()'
                    [nbTooltipTrigger]='checkBaOnBiTooltipStatus()'>{{checkBaOnBi()}}</span> = {{ba}}</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <th>BC</th>
                  <td>{{ba}} * <span
                    [ngStyle]='{"color": (precisionRatio(BCOnBA) !== "N/A") ? "black" : "red"}'
                    [nbTooltip]='checkBcOnBaTooltip()'
                    [nbTooltipTrigger]='checkBcOnBaTooltipStatus()'>{{checkBcOnBa()}}</span> = {{bc}}</td>
                </tr>
                <tr>
                  <th>SN</th>
                  <td>{{_desiredListings}} * <span
                    [ngStyle]='{"color": (precisionRatio(SNOnSC) !== "N/A") ? "black" : "red"}'
                    [nbTooltip]='checkSnOnScTooltip()' [nbTooltipTrigger]='checkSnOnScTooltipStatus()'>{{checkSnOnSc()}}</span> = {{sn}}</td>
                  <th>BN</th>
                  <td>{{_desiredSales}} * <span
                    [ngStyle]='{"color": (precisionRatio(BNOnBC) !== "N/A") ? "black" : "red"}'
                    [nbTooltip]='checkBnOnBcTooltip()' [nbTooltipTrigger]='checkBnOnBcTooltipStatus()'>{{checkBnOnBc()}}</span> = {{bn}}</td>
                </tr>
              </table>
            </div>
          </nb-card-body>
        </nb-card>
      </div>
    </div>
  </nb-card-body>
</nb-card>
