import {Zone} from './zone';
import {City} from './city';

export class District {
  districtId?: number;
  name?: string;
  code?: string;
  zoneId: number;
  zone?: Zone;
  cityId?: number;
  city?: City;
  status: string;
  createdBy: string;
  createdDate: number;
  totalStreet: number;
  color?: string;
  infoVisible: boolean;
  totalSms?: number;
}
