import {Component, OnInit} from '@angular/core';
import {LocalDataSource} from 'ng2-smart-table';
import {RestResult} from '../../../../shared/models/response/rest-result';
import {NbDialogService} from '@nebular/theme';
import {ToastService} from '../../../../shared/services/toast.service';
import {ConfirmDialogComponent} from '../../confirm-dialog/confirm-dialog.component';
import {SearchRequest} from '../../../../shared/models/request/search-request';
import {TaskService} from '../../../../shared/services/task.service';
import {Task} from '../../../../shared/models/response/task';
import {TaskListDialogComponent} from './dialog/task-list.dialog.component';
import {Constant} from '../../../../shared/common/constant';
import {StatusUtils} from '../../buyer/common/status-utils';

@Component({
  selector: 'ngx-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss'],
})
export class TaskListComponent implements OnInit {
  searchRequest = new SearchRequest();
  tasks: Task[];
  change: any;
  source: LocalDataSource = new LocalDataSource();
  pageSize = Constant.PAGE_SIZE;

  settings = {
    mode: 'external',
    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
    },
    pager: {
      display: true,
      perPage: this.pageSize
    },
    columns: {
      taskId: {
        title: 'ID',
        type: 'number',
      },
      name: {
        title: 'Name',
        type: 'string',
      },
      status: {
        title: 'status',
        type: 'string',
      }
    },
  };

  constructor(private dialogService: NbDialogService,
              private toastService: ToastService,
              private taskService: TaskService) {

  }

  ngOnInit() {
    this.source.onChanged().subscribe((change) => {
      this.change = change;
      if (change.action === 'refresh') {
        this.pageChange(1);
        // this.pageChange(change.paging.page);
      }
      if (change.action === 'page') {
        // this.source.load(new Array());
        this.pageChange(change.paging.page);
      }
      if (change.action === 'sort') {
        this.source.load(new Array());
        this.pageChange(1);
      }
      if (change.action === 'filter') {
        this.source.load(new Array());
        this.pageChange(1);
      }
    });
  }

  pageChange(pageIndex) {
    const loadedCount = this.source.count();
    const pageSizeIndex = pageIndex * this.pageSize;

    if (loadedCount <= pageSizeIndex) {
      const searchRequest = new SearchRequest();
      searchRequest.orders = new Array();
      if (this.change.sort && this.change.sort.length > 0) {
        this.change.sort.forEach(sort => {
          searchRequest.orders.push(
            {
              left: sort.field,
              right: sort.direction

            }
          );
        });
      } else {
        searchRequest.orders.push(
          {
            left: 'taskId',
            right: 'desc'

          }
        );
      }

      searchRequest.conditions = new Array();
      if (this.change.filter && this.change.filter.filters.length > 0) {
        this.change.filter.filters.forEach(filter => {
          if (filter.search && filter.search.trim() !== '') {
            searchRequest.conditions.push(
              {
                left: filter.field,
                middle: 'like',
                right: filter.search
              }
            );
          }
        });
      }
      searchRequest.offset = loadedCount === 0 ? 0 : loadedCount + 1;
      searchRequest.limit = this.pageSize + 100; // extra 100 records improves UX.

      this.taskService.search(searchRequest).subscribe((result: RestResult) => {
        if (this.source.count() > 0) {
          result.data.forEach(d => this.source.add(d));
          this.source.getAll()
          .then(d => {
            this.source.load(d);
          });
        } else {
          this.source.load(result.data);
        }
      });
    }
  }


  loadRequest() {
    this.taskService.search(this.searchRequest).subscribe((result: RestResult) => {
      if (result && result.data) {
        this.tasks = result.data;
        this.source.load(this.tasks);
      }
    });
  }

  deleteRecord(event: any) {
    this.dialogService.open(ConfirmDialogComponent, {
      context: {
        content: 'Do you really want to delete?',
      },
    })
    .onClose.subscribe(res => {
      if (res === ConfirmDialogComponent.confirmOk) {
        this.taskService.remove(event.data.userId).subscribe(delResponse => {
          if (StatusUtils.isSuccessResponse(delResponse)) {
            this.loadRequest();
            this.toastService.success('Delete user successfully');
          } else {
            this.toastService.error('Delete user error');
          }
        });
      }
    });
  }


  addRecord(event: any) {
    this.dialogService.open(TaskListDialogComponent)
    .onClose.subscribe(res => {
      if (StatusUtils.isSuccessResponse(res)) {
        this.loadRequest();
        this.toastService.success('Create user successfully');
      }
    });
  }

  editRecord(event: any) {
    this.dialogService.open(TaskListDialogComponent, {
      context: {
        data: event.data,
      },
    })
    .onClose.subscribe(res => {
      if (StatusUtils.isSuccessResponse(res)) {
        this.loadRequest();
        this.toastService.success('Update user successfully');
      }
    });
  }

  private isSuccessResponse(response) {
    return (response.status === 'success');
  }
}
