import {Component, OnInit, ViewChild} from '@angular/core';
import {DashboardService} from '../../../../shared/services/dashboard.service';
import {DateUtils} from '../../../../shared/common/date-utils';
import {RestResult} from '../../../../shared/models/response/rest-result';
import {Observable, of} from 'rxjs';
import {User} from '../../../../shared/models/response/user';
import {SearchRequest} from '../../../../shared/models/request/search-request';
import {Constant} from '../../../../shared/common/constant';
import {map, mergeMap} from 'rxjs/operators';
import {UserService} from '../../../../shared/services/user.service';
import {AuthService} from '../../../../shared/services/auth.service';
import {Company} from '../../../../shared/models/response/prospect/company';
import {TaskType} from '../../../../shared/models/response/task-type';
import {TaskTypeService} from '../../../../shared/services/task-type.service';
import {CompanyService} from '../../../../shared/services/company.service';
import {ProspectListBasicComponent} from '../../prospect/prospect-list-basic/prospect-list-basic.component';
import {ProspectStatisticRequest} from '../../../../shared/models/request/prospect/prospect-statistic-request';
import {ProspectSearchRequest} from '../../../../shared/models/request/prospect/prospect-search-request';
import {BuyerListComponent} from '../../buyer/buyer-list/buyer-list.component';
import {BuyerSearchRequest} from '../../../../shared/models/request/buyer/buyer-search-request';
import {ProspectStatistic} from '../../../../shared/models/response/prospect-statistic';
import {BaseChartDirective} from 'ng2-charts';
import {StringUtils} from '../../../../shared/common/string-utils';
import {Category} from '../../../../shared/models/response/category';
import {Triple} from '../../../../shared/models/request/triple';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead';
import {CategoryService} from '../../../../shared/services/category.service';
import {UserUtils} from '../../../../shared/common/user-utils';
import {ComponentPermission} from '../../../../component.permission';
import {CommonService} from '../../../../shared/services/common.service';
import {NbDialogService} from '@nebular/theme';
import {TotalMessageListComponent} from '../total-message-list/total-message-list.component';
import {SuburbService} from '../../../../shared/services/suburb.service';
import {ProspectService} from '../../../../shared/services/prospect.service';
import {ProspectStatisticWrapper} from '../../../../shared/models/response/prospect-statistic-wrapper';
import {ActivatedRoute, Router} from '@angular/router';
import {ArrayUtils} from '../../../../shared/common/array-utils';

@Component({
  selector: 'ngx-prospect-overview',
  templateUrl: './prospect-overview.component.html',
  styleUrls: ['./prospect-overview.component.scss'],
})
export class ProspectOverviewComponent implements OnInit {
  @ViewChild(BaseChartDirective)
  public chart: BaseChartDirective;

  loadClient = false;
  loadCustomer = false;
  loadRatio = false;
  loadStatistic = false;
  ComponentPermission = ComponentPermission;
  headers = ['SM', 'SN', 'SC', 'SA', 'SP', 'S4', 'O4', 'S5', 'S6', 'S7',
    'B6', 'B5', 'B4', 'BI', 'BA', 'BC', 'BN', 'BM'];
  firstColLabel = 'Day';
  firstCols: any[] = [];
  lineChartLabels: any[] = [];
  types = ['Week', 'Quarter'];
  selectedType = 'Week';
  nums = [];
  selectedNum = 1;
  selectedYear: number;
  years: number[];
  prospectStatistics: any;
  currentUser: User;
  startFrom: number;
  endTo: number;
  companies: Company[];
  taskTypes: TaskType[];
  selectedX = -1;
  selectedY = -1;
  @ViewChild(ProspectListBasicComponent) prospectListBasicComponent: ProspectListBasicComponent;
  @ViewChild(BuyerListComponent) buyerListComponent: BuyerListComponent;
  timeCache: {
    from: number;
    to: number;
  }[] = [];
  param = {};
  isFiltered = false;
  searchRequest = new ProspectSearchRequest();

  // lineChart
  public lineChartData: Array<any>;
  public lineChartOptions: any = {
    animation: false,
    responsive: true,
  };
  public lineChartColours: Array<any> = [
    { // first
      backgroundColor: 'transparent',
      borderColor: '#f193a7',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    },
    { // second
      backgroundColor: 'transparent',
      borderColor: '#78b9e5',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)',
    },
    { // third
      backgroundColor: 'transparent',
      borderColor: '#c9c690',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    },
    { // fourth
      backgroundColor: 'transparent',
      borderColor: '#85fb3b',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    },
  ];

  public pieChartColors: Array<any> = [
    {
      backgroundColor: ['#f193a7', '#78b9e5', '#c9c690', '#85fb3b'],
      borderColor: 'rgba(135,206,250,1)',
    },
  ];

  public lineChartLegend = true;
  public lineChartType = 'line';

  // Pie
  public pieChartLabels: string[] = ['SC', 'SA', 'SP', 'S4'];
  public pieChartData: number[];
  public pieChartType = 'pie';

  stringUtils = StringUtils;

  filterCategory$: Observable<Category[]>;
  categoryName: string;
  activityCategories: Category[] = [];
  selectedCategoryIds: number[];

  filterUser$: Observable<User[]>;
  userName: string;
  selectedUsers: User[] = [];
  selectedUserId: number[];
  userUtils = UserUtils;
  prospectSearchRequest = new ProspectSearchRequest();
  buyerSearchRequest = new BuyerSearchRequest();
  prospectTagButtons = [
    {
      key: 'T10-S3',
      value: 1,
      selected: true,
    },
    {
      key: '1M',
      value: 5,
      selected: false,
    },
    {
      key: '3M',
      value: 6,
      selected: false,

    },
    {
      key: '6M',
      value: 7,
      selected: false,
    },
  ];
  buyerTagButtons = [
    {
      key: 'T10-BA',
      value: 2,
      selected: true,
    },
    {
      key: 'B4',
      value: 26,
      selected: false,
    },
    {
      key: 'B5',
      value: 25,
      selected: false,
    },
    {
      key: 'B6',
      value: 24,
      selected: false,
    },
    {
      key: 'B7',
      value: 23,
      selected: false,
    },
  ];
  UserUtils = UserUtils;
  isTeamView = false;
  statisticTeam: ProspectStatisticWrapper[];

  constructor(private dashboardService: DashboardService,
              private userService: UserService,
              private authService: AuthService,
              private categoryService: CategoryService,
              private taskTypeService: TaskTypeService,
              private companyService: CompanyService,
              private commonService: CommonService,
              private dialogService: NbDialogService,
              private districtService: SuburbService,
              private prospectService: ProspectService,
              private route: ActivatedRoute,
              private router: Router,
  ) {
    this.selectedType = 'Week';
    this.nums = [];
    for (let i = 1; i <= 53; i++) {
      this.nums.push(i);
    }
    this.years = [];
    for (let i = 2000; i <= 2100; i++) {
      this.years.push(i);
    }
    this.selectedYear = new Date().getFullYear();
    this.selectedNum = DateUtils.getCurrentWeekFromTimestamp(new Date().getTime());
  }

  ngOnInit(): void {
    this.param = {...this.route.snapshot.queryParams};
    this.currentUser = this.authService.currentUser;
    if (!this.userUtils.userIsAdmin(this.currentUser)) {
      this.selectedUsers.push(this.currentUser);
    } else {
      this.selectedUsers = [];
    }
    this.mapUserId();
    // init filter Category
    this.filterCategory$ = new Observable((observer: any) => {
      observer.next(this.categoryName);
    }).pipe(
      mergeMap((query: string) => this.searchCategory(query)),
    );
    // init filter User
    this.filterUser$ = new Observable((observer: any) => {
      observer.next(this.userName);
    }).pipe(
      mergeMap((query: string) => this.searchUser(query)),
    );
    this.loadLabel();
    this.loadForFilter();
    this.getProspectStatistic();
    this.prepareSearchRequest();
    this.loadAssignedSuburb();
    this.preLoadSelectedEnqTime();
    this.preloadOtherToFilter();
    this.preloadSelectMetric();
    this.appendParameterToSearchRequest();
  }

  loadLabel() {
    const configDate = DateUtils.getConfigDate();
    this.lineChartLabels = [...DateUtils.SIMPLE_DAY_LABEL];
    const index = this.lineChartLabels.findIndex(label => configDate.includes(label));
    ArrayUtils.rotate(this.lineChartLabels, -index);
    this.firstCols = ['TOTAL', ...this.lineChartLabels];
  }

  loadAssignedSuburb() {
    if (!localStorage.getItem('assignedSuburb')) {
      this.districtService.fetchDistrictBySelectedOffice(this.currentUser.office.officeId).subscribe(c => {
        localStorage.setItem('assignedSuburb', JSON.stringify(c.data));
      });
    }
  }

  loadForFilter() {
    const searchRequest = new SearchRequest();
    searchRequest.offset = 0;
    searchRequest.limit = 10000;
    searchRequest.orders = new Array();
    searchRequest.orders.push(
      {
        left: 'name',
        right: 'asc',

      },
    );
    this.taskTypeService.search(searchRequest).subscribe((result: RestResult) => {
      this.taskTypes = result.data;
    });
    this.companyService.search(searchRequest).subscribe((result: RestResult) => {
      this.companies = result?.data;
    });
  }

  onTypeChange(value: string) {
    this.selectedType = value;
    if (this.selectedType) {
      this.param = {...this.param, selectedType: this.selectedType};
    } else {
      delete this.param['selectedType'];
      delete this.param['selectedNum'];
    }
    this.selectedNum = 1;
    this.param = {...this.param, selectedNum: this.selectedNum};
    this.navigateWithParam();
    this.generateFirstCols();
    this.getProspectStatistic();
  }

  onNumChange(value: number) {
    this.selectedNum = value;
    if (this.selectedNum) {
      this.param = {...this.param, selectedNum: this.selectedNum};
    } else {
      delete this.param['selectedNum'];
    }
    this.navigateWithParam();
    this.generateFirstCols();
    this.getProspectStatistic();
  }

  onYearChange(value: number) {
    this.selectedYear = value;
    if (this.selectedYear) {
      this.param = {...this.param, selectedYear: this.selectedYear};
    } else {
      delete this.param['selectedYear'];
    }
    this.navigateWithParam();
    this.getProspectStatistic();
  }

  generateFirstCols() {
    this.firstCols = [];
    this.nums = [];
    switch (this.selectedType) {
      case 'Week':
        this.firstColLabel = 'Day';
        this.firstCols = ['TOTAL', ...this.lineChartLabels];
        for (let i = 1; i <= 53; i++) {
          this.nums.push(i);
        }
        break;
      case 'Quarter':
        this.firstColLabel = 'Week';
        for (let i = 1; i <= 4; i++) {
          this.nums.push(i);
        }
        this.firstCols.push('TOTAL');
        for (let i = 1; i <= 13; i++) {
          this.firstCols.push(i + (this.selectedNum - 1) * 13);
        }
        break;
    }
  }

  getProspectStatistic() {
    if (!this.selectedType
      || !this.selectedNum
      || !this.selectedYear
    ) {
      return;
    }
    this.lineChartData = [
      {data: [], label: 'SC', pointRadius: 5},
      {data: [], label: 'SA', pointRadius: 5},
      {data: [], label: 'SP', pointRadius: 5},
      {data: [], label: 'S4', pointRadius: 5},
    ];
    this.pieChartData = [0, 0, 0, 0];
    this.prospectStatistics = {};
    this.timeCache = [];
    this.mapCategoryId();
    this.prospectStatistics['TOTAL'] = new ProspectStatistic();
    switch (this.selectedType) {
      case 'Week':
        const dayMoments = DateUtils.getAllDaysOfWeek(this.selectedNum, this.selectedYear);
        this.startFrom = dayMoments[0]?.startOf('day').valueOf();
        this.endTo = dayMoments[dayMoments.length - 1]?.endOf('day').valueOf();
        for (let i = 0; i < dayMoments.length; i++) {
          const dayMoment = dayMoments[i];
          const from = dayMoment.startOf('day').valueOf();
          const to = dayMoment.endOf('day').valueOf();
          this.timeCache.push({from, to});
          this.dashboardService.fetchProspectStatistic(
            this.currentUser.office?.officeId,
            from,
            to,
            this.selectedUserId,
            this.selectedCategoryIds,
          ).subscribe((result: RestResult) => {
            if (result && result.data) {
              this.prospectStatistics[this.firstCols[dayMoments.indexOf(dayMoment) + 1]] = result.data;
              for (const header of this.headers) {
                this.prospectStatistics['TOTAL']['total' + header] += result.data['total' + header];
              }
              this.prepareLineData(this.firstCols[dayMoments.indexOf(dayMoment) + 1], result.data);
              this.preparePieData(result.data);
            }
          });
        }
        break;
      case 'Quarter':
        for (let i = 1; i <= 13; i++) {
          const week = i + (this.selectedNum - 1) * 13;
          const startDayOfWeek = DateUtils.getFirstDayOfWeek(week, this.selectedYear);
          const endDayOfWeek = DateUtils.getLastDayOfWeek(week, this.selectedYear);
          if (i === 1) {
            this.startFrom = startDayOfWeek.valueOf();
          } else if (i === 13) {
            this.endTo = endDayOfWeek.valueOf();
          }
          const from = startDayOfWeek.startOf('day').valueOf();
          const to = endDayOfWeek.endOf('day').valueOf();
          this.timeCache.push({from, to});
          this.dashboardService.fetchProspectStatistic(
            this.currentUser.office?.officeId,
            from,
            to,
            this.selectedUserId,
            this.selectedCategoryIds,
          ).subscribe((result: RestResult) => {
            if (result && result.data) {
              this.prospectStatistics[this.firstCols[i]] = result.data;
              for (const header of this.headers) {
                this.prospectStatistics['TOTAL']['total' + header] += result.data['total' + header];
              }
              this.prepareLineData(this.firstCols[i], result.data);
              this.preparePieData(result.data);
            }
          });
        }
        break;
    }
  }

  prepareLineData(line: string, data: ProspectStatistic) {
    this.lineChartData[0].data[this.firstCols.indexOf(line) - 1] = data.totalSC;
    this.lineChartData[1].data[this.firstCols.indexOf(line) - 1] = data.totalSA;
    this.lineChartData[2].data[this.firstCols.indexOf(line) - 1] = data.totalSP;
    this.lineChartData[3].data[this.firstCols.indexOf(line) - 1] = data.totalS4;
    this.lineChartData = [
      this.lineChartData[0],
      this.lineChartData[1],
      this.lineChartData[2],
      this.lineChartData[3],
    ];
  }

  preparePieData(data: ProspectStatistic) {
    this.pieChartData[0] += data.totalSC;
    this.pieChartData[1] += data.totalSA;
    this.pieChartData[2] += data.totalSP;
    this.pieChartData[3] += data.totalS4;
    // SC SA SP S4
    this.pieChartData = [this.pieChartData[0], this.pieChartData[1], this.pieChartData[2], this.pieChartData[3]];
  }

  selectMetric(x, y) {
    if (!ComponentPermission.isAccess(ComponentPermission.DASHBOARD_OVERVIEW_CLICK)) {
      this.commonService.warning('You do not have permission to perform this action');
      return;
    }
    this.selectedX = x;
    this.selectedY = y;
    this.param = {...this.param, selectedX: x};
    this.param = {...this.param, selectedY: y};
    this.navigateWithParam();
    if (!this.headers[x]) {
      return;
    }
    let time: {
      from: number;
      to: number;
    };
    if (y === 0) {
      time = {from: this.startFrom, to: this.endTo};
    } else {
      time = this.timeCache[y - 1];
    }
    if (this.headers[x] === 'BM') {
      return;
    }
    if (this.headers[x] === 'SM') {
      let noData: boolean = false;
      const sr = new ProspectSearchRequest();
      sr.dateFrom = time.from;
      sr.dateTo = time.to;
      if (this.isTeamView === false) {
        if (this.prospectStatistics[this.firstCols[y]].totalSM === 0) {
          noData = true;
        }
      } else {
        if (this.statisticTeam[y].statistic.totalSM === 0) {
          noData = true;
        } else {
          sr.dateFrom = this.startFrom;
          sr.dateTo = this.endTo;
        }
      }
      this.dialogService.open(TotalMessageListComponent, {
        context: {
          prospectSearchRequest: sr,
          noData: noData,
        },
      }).onClose.subscribe(r => {
        delete this.param['selectedX'];
        delete this.param['selectedY'];
        this.navigateWithParam();
      });
      return;
    }
    const statisticRequest: ProspectStatisticRequest = new ProspectStatisticRequest();
    statisticRequest.multipleUser = this.selectedUserId;
    statisticRequest.fromDate = time.from;
    statisticRequest.toDate = time.to;
    statisticRequest.statisticType = this.headers[x];
    if (this.isTeamView === true) {
      const selectedUser = this.selectedUsers[y];
      statisticRequest.multipleUser = statisticRequest.multipleUser.filter(id => (id === selectedUser.userId));
      statisticRequest.fromDate = this.startFrom;
      statisticRequest.toDate = this.endTo;
    }
    if (this.headers[x]?.startsWith('B')) {
      // this.displayProspect = false;
      // this.displayBuyer = true;
      const buyerSearch: BuyerSearchRequest = new BuyerSearchRequest();
      buyerSearch.statisticRequest = statisticRequest;
      buyerSearch.categoryIds = this.activityCategories.map((category) => category.categoryId);
      // this.buyerListComponent.filterCondition(buyerSearch);
      this.dialogService.open(BuyerListComponent, {
        context: {
          searchRequest: buyerSearch,
          isDialog: true,
        },
      }).onClose.subscribe(r => {
        delete this.param['selectedX'];
        delete this.param['selectedY'];
        this.navigateWithParam();
      });
    } else {
      // this.displayBuyer = false;
      // this.displayProspect = true;
      // const prospectSearch: ProspectSearchRequest = new ProspectSearchRequest();
      // prospectSearch.statisticRequest = statisticRequest;
      // prospectSearch.sortType = 'ASC';
      // prospectSearch.offset = 0;
      // prospectSearch.limit = 20;
      // prospectSearch.categoryIds = this.activityCategories.map((category) => category.categoryId);
      // this.prospectListBasicComponent.filterCondition(prospectSearch);
      this.dialogService.open(ProspectListBasicComponent, {
        context: {
          isDialog: true,
          statisticRequest: statisticRequest,
          categoryIds: this.activityCategories.map((category) => category.categoryId),
          _selectedMode: 'OUTDOOR',
        },
      }).onClose.subscribe(r => {
        delete this.param['selectedX'];
        delete this.param['selectedY'];
        this.navigateWithParam();
      });
    }
  }

  searchCategory(name: string): Observable<Category[]> {
    if (!name || name === '') {
      return of([]);
    }
    const searchRequest = new SearchRequest();
    searchRequest.conditions = new Array();

    searchRequest.conditions.push(new Triple('name', 'like', name));
    // searchRequest.conditions.push(new Triple('type', '=', 'BUYER'));
    return this.categoryService.search(searchRequest).pipe(
      map(result => result.data),
    );

  }

  selectCategory(type: TypeaheadMatch) {
    this.categoryName = '';
    const selectedCategory: Category = type.item;
    if (this.activityCategories.filter(category => category.categoryId === selectedCategory.categoryId).length > 0) {
      return;
    }
    this.activityCategories.push(selectedCategory);
    if (!this.searchRequest.categoryIds) {
      this.searchRequest.categoryIds = [];
    }
    this.searchRequest.categoryIds.push(selectedCategory.categoryId);
    if (this.searchRequest.categoryIds && this.searchRequest.categoryIds.length > 0) {
      this.param = {...this.param, categoryIds: this.searchRequest.categoryIds};
    }
    this.navigateWithParam();
    this.getProspectStatistic();
  }

  removeCategory(removedCategory: Category) {
    if (removedCategory) {
      this.activityCategories = this.activityCategories.filter
      (category => category.categoryId !== removedCategory.categoryId);
      if (this.searchRequest.categoryIds) {
        this.searchRequest.categoryIds.splice(this.searchRequest.categoryIds.indexOf(removedCategory.categoryId), 1);
        if (this.searchRequest.categoryIds.length > 0) {
          this.param = {...this.param, categoryIds: this.searchRequest.categoryIds};
        }
        this.navigateWithParam();
      }
      this.getProspectStatistic();
    }
  }

  searchUser(keyword: string): Observable<User[]> {
    // this.refreshUserFilter();
    const searchRequest = new SearchRequest();
    searchRequest.conditions = new Array();
    searchRequest.conditions.push(
      {
        left: 'email',
        middle: 'like',
        right: keyword,
      },
    );
    searchRequest.conditions.push(
      {
        left: 'username',
        middle: 'like',
        right: keyword,
      },
    );
    searchRequest.conditions.push(
      {
        left: 'name',
        middle: 'like',
        right: keyword,
      },
    );
    searchRequest.conditionType = Constant.CONDITION_TYPE.OR;
    searchRequest.offset = 0;
    searchRequest.limit = 10;
    return this.userService.search(searchRequest).pipe(
      map(result => result.data.filter(user => user.status === 'ACTIVE')),
    );
  }

  selectUser(type: TypeaheadMatch) {
    this.userName = '';
    const selectedUser: User = type.item;
    this.selectedUsers.push(selectedUser);
    this.selectedUsers = this.selectedUsers.slice();
    this.mapUserId();
    if (this.isTeamView === false) {
      this.getProspectStatistic();
    }
    if (this.isTeamView === true) {
      this.firstCols.push(StringUtils.shortenUserName(type.item));
      this.getTeamStatistic();
    }
    if (!this.searchRequest.multipleUser) {
      this.searchRequest.multipleUser = [];
    }
    this.searchRequest.multipleUser.push(selectedUser.userId);
    if (this.searchRequest.multipleUser && this.searchRequest.multipleUser.length > 0) {
      this.param = {...this.param, multipleUser: this.searchRequest.multipleUser};
    }
    this.navigateWithParam();
    this.prospectSearchRequest.multipleUser = this.selectedUserId;
    this.buyerSearchRequest.multipleUser = this.selectedUserId;
    if (this.loadClient) {
      this.prospectListBasicComponent.filterCondition(this.prospectSearchRequest);
    }
    if (this.loadCustomer) {
      this.buyerListComponent.filterCondition(this.buyerSearchRequest);
    }
  }

  removeUser(userId: number) {
    const index = this.selectedUserId.indexOf(userId);
    this.selectedUsers = this.selectedUsers.filter(u => (u.userId !== userId));
    // if(this.param['selectedUserId'] === userId){
    //   delete this.param['selectedUserId'];
    // }
    this.mapUserId();
    if (this.isTeamView === false) {
      this.getProspectStatistic();
    }
    if (this.isTeamView === true) {
      this.statisticTeam.splice(index, 1);
      this.firstCols.splice(index, 1);
    }
    if (this.searchRequest.multipleUser) {
      this.searchRequest.multipleUser.splice(this.searchRequest.multipleUser.indexOf(userId), 1);
      if (this.searchRequest.multipleUser.length > 0) {
        this.param = {...this.param, multipleUser: this.searchRequest.multipleUser};
      }
      this.navigateWithParam();
    }
    this.prospectSearchRequest.multipleUser = this.selectedUserId;
    this.buyerSearchRequest.multipleUser = this.selectedUserId;
    if (this.loadClient) {
      this.prospectListBasicComponent.filterCondition(this.prospectSearchRequest);
    }
    if (this.loadCustomer) {
      this.buyerListComponent.filterCondition(this.buyerSearchRequest);
    }
  }

  mapUserId() {
    this.selectedUserId = this.selectedUsers.map((user) => user.userId);
  }

  mapCategoryId() {
    this.selectedCategoryIds = this.activityCategories.map((category) => category.categoryId);
  }

  selectProspectTag(value: number) {
    this.prospectTagButtons.forEach(tag => {
      tag.selected = tag.value === value;
    });
    this.prospectSearchRequest.categoryIds = [];
    this.prospectSearchRequest.categoryIds.push(value);
    this.prospectSearchRequest.sortType = 'ASC';
    this.prospectSearchRequest.offset = 0;
    this.prospectSearchRequest.limit = 20;
    this.prospectListBasicComponent.filterCondition(this.prospectSearchRequest);
  }

  selectBuyerTag(value: number) {
    this.buyerTagButtons.forEach(tag => {
      tag.selected = tag.value === value;
    });
    this.buyerSearchRequest.categoryIds = [];
    this.buyerSearchRequest.categoryIds.push(value);
    this.buyerSearchRequest.offset = 0;
    this.buyerSearchRequest.limit = 20;
    this.buyerListComponent.filterCondition(this.buyerSearchRequest);
  }

  prepareSearchRequest() {
    // prospect
    this.prospectSearchRequest.categoryIds = [];
    this.prospectSearchRequest.categoryIds.push(1);
    this.prospectSearchRequest.sortType = 'ASC';
    this.prospectSearchRequest.offset = 0;
    this.prospectSearchRequest.limit = 20;
    this.prospectSearchRequest.multipleUser = this.selectedUserId;
    // buyer
    this.buyerSearchRequest.categoryIds = [];
    this.buyerSearchRequest.categoryIds.push(2);
    this.buyerSearchRequest.offset = 0;
    this.buyerSearchRequest.limit = 20;
    this.buyerSearchRequest.multipleUser = this.selectedUserId;
  }

  switchToTeamView() {
    this.isTeamView = true;
    if (this.param['isTeamView']) {
      delete this.param['isTeamView'];
    }
    this.param = {...this.param, isTeamView: this.isTeamView};
    this.navigateWithParam();
    this.firstColLabel = 'User';
    this.firstCols = ['TOTAL'];
    if (this.selectedUsers.length > 0) {
      for (const user of this.selectedUsers) {
        this.firstCols.push(StringUtils.shortenUserName(user));
      }
      this.getTeamStatistic();
    }
  }

  switchToNormal() {
    this.isTeamView = false;
    if (this.param['isTeamView']) {
      delete this.param['isTeamView'];
    }
    this.param = {...this.param, isTeamView: this.isTeamView};
    this.navigateWithParam();
    if (this.param['selectedType']) {
      this.selectedType = this.param['selectedType'];
    }
    if (this.param['selectedNum']) {
      this.selectedNum = (Number)(this.param['selectedNum']);
    }
    if (this.param['selectedYear']) {
      this.selectedYear = (Number)(this.param['selectedYear']);
    }
    this.generateFirstCols();
  }

  getTeamStatistic() {
    this.mapCategoryId();
    this.dashboardService.fetchProspectStatisticTeam(this.currentUser.office?.officeId,
      this.startFrom,
      this.endTo,
      this.selectedUserId,
      this.selectedCategoryIds).subscribe(result => {
      if (result.data && result) {
        this.statisticTeam = result.data;
        const totalStatisticTeam = new ProspectStatisticWrapper();
        totalStatisticTeam.userId = 0;
        totalStatisticTeam.statistic = new ProspectStatistic();
        for (const header of this.headers) {
          for (let i = 0; i < this.statisticTeam.length; i++) {
            totalStatisticTeam.statistic['total' + header] += this.statisticTeam[i].statistic['total' + header];
          }
        }
        this.statisticTeam.unshift(totalStatisticTeam);
      }
    });
  }

  private getCurrentDayIndex(): number {
    const day = new Date().getUTCDay();
    switch (day) {
      case 0:
        return 4;
      case 1:
        return 5;
      case 2:
        return 6;
      case 3:
        return 0;
      case 4:
        return 1;
      case 5:
        return 2;
      case 6:
        return 3;
    }
  }

  private validateDayMoment(dayMoment): boolean {
    const now = new Date();
    const fromToday = DateUtils.getStartOfDate(now.getDate(), now.getMonth(), now.getFullYear()).valueOf();
    const toToday = DateUtils.getEndOfDate(now.getDate(), now.getMonth(), now.getFullYear()).valueOf();
    const fromTarget = dayMoment.startOf('day').valueOf();
    const toTarget = dayMoment.endOf('day').valueOf();
    return fromToday === fromTarget && toToday === toTarget;
  }

  navigateWithParam() {
    this.router.navigate([], {
      queryParams: this.param,
    });
  }

  preLoadSelectedEnqTime() {
    if (this.route.snapshot.queryParamMap.keys.length === 0) {
      return;
    }
    const selectedYear = this.route.snapshot.queryParamMap.get('selectedYear');
    const selectedType = this.route.snapshot.queryParamMap.get('selectedType');
    const selectedNum = this.route.snapshot.queryParamMap.get('selectedNum');
    if (selectedYear) {
      this.selectedYear = Number(selectedYear);
      this.getProspectStatistic();
    }
    if (selectedType) {
      this.selectedType = selectedType;
      this.generateFirstCols();
      this.getProspectStatistic();
    }
    if (selectedNum) {
      this.selectedNum = Number(selectedNum);
      this.generateFirstCols();
      this.getProspectStatistic();
    }
  }

  preloadOtherToFilter() {
    if (this.route.snapshot.queryParamMap.keys.length === 0) {
      return;
    }
    const params = this.route.snapshot.queryParams;
    if (params['categoryIds']) {
      const categoryIds = params['categoryIds'];
      if (typeof categoryIds === 'string') {
        this.categoryService.getOne((Number)(categoryIds)).subscribe(result => {
          if (result) {
            this.activityCategories.push(result.data);
          }
        });
      } else {
        for (let i = 0; i < categoryIds.length; i++) {
          this.categoryService.getOne((Number)(categoryIds[i])).subscribe(result => {
            if (result) {
              this.activityCategories.push(result.data);
            }
          });
        }
      }
    }
    if (params['multipleUser']) {
      const multipleUser = params['multipleUser'];
      if (typeof multipleUser === 'string') {
        this.userService.getOne((Number)(multipleUser)).subscribe(result => {
          if (result) {
            this.selectedUsers.push(result.data);
            this.preloadTeamView();
          }
        });
      } else {
        for (let i = 0; i < multipleUser.length; i++) {
          this.userService.getOne((Number)(Number(multipleUser[i]))).subscribe(result => {
            if (result) {
              this.selectedUsers.push(result.data);
              this.preloadTeamView();
            }
          });
        }
      }
    }
  }

  preloadTeamView() {
    const isTeamView = this.route.snapshot.queryParamMap.get('isTeamView');
    if (isTeamView) {
      if (isTeamView === 'true') {
        this.switchToTeamView();
      } else if (isTeamView === 'false') {
        this.isTeamView = false;
      }
    }
  }

  appendParameterToSearchRequest() {
    const params = this.route.snapshot.queryParams;
    if (params['categoryIds']) {
      const categoryIds = params['categoryIds'];
      if (typeof categoryIds === 'string') {
        this.searchRequest.categoryIds = [];
        this.searchRequest.categoryIds.push(Number(categoryIds));

      } else {
        this.searchRequest.categoryIds = categoryIds.map(id => {
          return Number(id);
        });
      }
    }
    if (params['multipleUser']) {
      const multipleUser = params['multipleUser'];
      if (typeof multipleUser === 'string') {
        this.searchRequest.multipleUser = [];
        this.searchRequest.multipleUser.push(Number(multipleUser));
      } else {
        this.searchRequest.multipleUser = multipleUser.map(id => {
          return Number(id);
        });
      }
    }
  }

  preloadSelectMetric() {
    const selectedX = this.route.snapshot.queryParamMap.get('selectedX');
    const selectedY = this.route.snapshot.queryParamMap.get('selectedY');
    if (selectedX && selectedY) {
      this.getProspectStatistic();
      this.selectMetric((Number)(selectedX), (Number)(selectedY));
    }
  }
}
