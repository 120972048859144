import { CustomerAdditionalInfoRequest } from './customer-additional-info-request';

export class CustomerRequest {
  customerId?: number;
  name?: string;
  surname?: string;
  phone?: string;
  mobilePhone?: string;
  relationship?: number;
  motivate?: number;
  note?: string;
  isDirector?: boolean;
  isInfluencer?: boolean;
  isSocialble?: boolean;
  isConcensious?: boolean;
  motivateFinancialUp?: boolean;
  motivateFinancialDown?: boolean;
  motivateLifestyleUp?: boolean;
  motivateLifestyleDown?: boolean;
  email?: string;
  facebook?: string;
  instagram?: string;
  dob?: number;
  nationality?: string;
  anniversaryDay?: number;
  religion?: string;
  profesion?: string;
  language?: string;
  link?: string;
  parentType?: string;
  abnNumber?: string;
  gstRegistered?: boolean;
  customerAdditionalInfo?: CustomerAdditionalInfoRequest[];

  static getDummy = () => {
    return {
      customerId: '',
      name: '',
      surname: '',
      phone: '',
      mobilePhone: '',
      relationship: '',
      motivate: '',
      note: '',
      isDirector: '',
      isInfluencer: '',
      isSocialble: '',
      isConcensious: '',
      motivateFinancialUp: '',
      motivateFinancialDown: '',
      motivateLifestyleUp: '',
      motivateLifestyleDown: '',
      email: '',
      facebook: '',
      instagram: '',
      dob: '',
      nationality: '',
      anniversaryDay: '',
      religion: '',
      profesion: '',
      language: '',
      link: '',
      parentType: '',
      abnNumber: '',
      gstRegistered: '',
      customerAdditionalInfo: '',
    };
  }
}
