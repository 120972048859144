<nb-card>
  <nb-card-header>
    <span>Shortcut</span>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]='prospectForm'>
      <div class='col-12 d-flex mt-3 justify-content-end'>
        <div class='col-2 mt-1 float-right'>
          <select size='tiny' class='float-right' formControlName='rate' (change)='onSelectRate($event)'>
            <option [value]='undefined' disabled>Rate</option>
            <option *ngFor='let option of rateOptions' [value]='option.value'
            >{{option.label}}</option>
          </select>
          <!--        <div class='float-right justify-content-center mt-1 mr-2' *ngIf='data && linkedGroup?.length !== 0'>-->
          <!--          {{totalLinked}}-->
          <!--          <nb-icon [icon]="'people-outline'" pack='eva'></nb-icon>-->
          <!--        </div>-->
        </div>
      </div>
      <div class='col-12 d-flex'>
        <div class='col-4'>
          <div class='form-group'>
            <label class='label'>Street</label>
            <nb-select fullWidth size='tiny' class='mt-1' [disabled]='!districtId' [(ngModel)]='streetId' [ngModelOptions]='{standalone: true}'
                       (selectedChange)='onStreetChange($event)'>
              <nb-option [value]='undefined'>All Streets</nb-option>
              <nb-option *ngFor='let key of filteredStreets'
                         [value]='key.streetId'
              >{{key.name}}</nb-option>
            </nb-select>
          </div>
        </div>
        <div class='col-4'>
          <div class='form-group' [ngClass]="showError('districtId')">
            <label class='label'>Suburb</label>
            <nb-select
              class='mt-1'
              fullWidth size='tiny' [(ngModel)]='districtId' [disabled]='!filterByPostcode' [ngModelOptions]='{standalone: true}'
              (selectedChange)='onSuburbChange($event)'>
              <nb-option [value]='undefined'>All Suburbs</nb-option>
              <nb-option *ngFor='let key of filteredDistricts'
                         [value]='key.districtId'
              >{{key.name}}</nb-option>
            </nb-select>
          </div>
        </div>
        <div class='col-4'>
          <div class='form-group' [ngClass]="showError('cityId')">
            <label class='label'>PostCode</label>
            <!--          <nb-select-->
            <!--            class='mt-1'-->
            <!--            fullWidth size='tiny' [(ngModel)]='cityId'>-->
            <!--            <nb-option [value]='undefined' (click)='onCityChange()'>All Cities</nb-option>-->
            <!--            <nb-option *ngFor='let key of cities'-->
            <!--                       [ngClass]="key.status === 'DEACTIVE' ? 'deactive-status' : ''"-->
            <!--                       [value]='key.cityId'-->
            <!--                       (click)='onCityChange(key.cityId)'-->
            <!--            >{{key.name}}</nb-option>-->
            <!--          </nb-select>-->
            <nb-form-field>
              <input nbInput fullWidth placeholder='Postcode' fieldSize='tiny' class='override-nbinput-height'
                     [(ngModel)]='postcode'
                     [typeahead]='filterPostcode$'
                     [ngModelOptions]='{standalone: true}'
                     [typeaheadAsync]='true'
                     (typeaheadOnSelect)='selectPostcode($event)'
                     typeaheadOptionField='code'
                     typeaheadMinLength='3'
                     typeaheadWaitMs='1000'>
              <!--      <span nbSuffix (click)='searchSuburbByPostcode()'><i class='fa fa-search'></i></span>-->
            </nb-form-field>
          </div>
        </div>
      </div>


      <div class='col-12 d-flex'>
        <div class='col-12 property-type-container'>
          <input type='text' nbInput fieldSize='tiny'
                 placeholder='Unit'
                 fullWidth name='unit' formControlName='unit' />
          &nbsp;/&nbsp;
          <input type='number' nbInput fieldSize='tiny'
                 placeholder='Number From'
                 fullWidth name='numberFrom' formControlName='numberFrom' />
          <input type='text' nbInput fieldSize='tiny'
                 placeholder='Additional Info'
                 fullWidth name='additionalInfo' formControlName='additionalInfo' />
          <!--          <input type='text' nbInput fieldSize='tiny'-->
          <!--                 placeholder='Letter'-->
          <!--                 fullWidth name='numberFromLetter' formControlName='numberFromLetter' />-->
          <!--          &nbsp;-&nbsp;-->
          <!--          <input type='number' nbInput fieldSize='tiny'-->
          <!--                 placeholder='Number To'-->
          <!--                 fullWidth name='numberTo' formControlName='numberTo' />-->
          <!--          <input type='text' nbInput fieldSize='tiny'-->
          <!--                 placeholder='Letter'-->
          <!--                 fullWidth name='numberToLetter' formControlName='numberToLetter' />-->
        </div>
      </div>
      <div class='col-12 d-flex mt-2'>
        <div class='col-12'>
          <input type='text' nbInput fieldSize='tiny' fullWidth name='number' placeholder='Address Note'
                 formControlName='number'>
        </div>
      </div>

      <div class='row p-3 mt-3'>
        <div class='col-3 text-center square-container square-market'>
          <label (click)='toggleDisplayMarket()' class='openExpand'>Property
            <nb-icon class='ml-2' [icon]="'home-outline'" pack='eva'></nb-icon>
          </label>

          <div class='col-12 p-0'>
            <button nbButton size='tiny'
                    class="col-6 {{prospectForm.controls?.sell?.value === true ? 'market-active' : 'market-inactive'}}"
                    hero (click)="setSquareValue('sell')">S
            </button>
            <button nbButton size='tiny'
                    class="col-6 {{prospectForm.controls?.rent?.value === true ? 'market-active' : 'market-inactive'}}"
                    hero (click)="setSquareValue('rent')">R
            </button>
            <button nbButton size='tiny' [disabled]='!prospectForm.controls.otmDate.value'
                    class="col-6 {{prospectForm.controls?.otm?.value === true ? 'market-active' : 'market-inactive'}}"
                    hero (click)="setSquareValue('otm')">O
            </button>
            <button nbButton size='tiny' [disabled]='!prospectForm.controls.vacDate.value'
                    class="col-6 {{prospectForm.controls?.vac?.value === true ? 'market-active' : 'market-inactive'}}"
                    hero (click)="setSquareValue('vac')">V
            </button>
          </div>
        </div>
        <div class='col-3 square-container text-center square-contact'>
          <label (click)='toggleDisplayContact()' class='openExpand'>Contact
            <nb-icon class='ml-2' [icon]="'people-outline'" pack='eva'></nb-icon>
          </label>
          <div class='col-12 p-0'>
            <button nbButton size='tiny'
                    class="col-6 {{prospectForm.controls?.mail?.value === true ? 'contact-active' : 'contact-inactive'}}"
                    hero (click)="setSquareValue('mail')">M
            </button>
            <button nbButton size='tiny'
                    class="col-6 {{prospectForm.controls?.door?.value === true ? 'contact-active' : 'contact-inactive'}}"
                    hero (click)="setSquareValue('door')">D
            </button>
            <button nbButton size='tiny'
                    class="col-6 {{prospectForm.controls?.call?.value === true ? 'contact-active' : 'contact-inactive'}}"
                    hero (click)="setSquareValue('call')">C
            </button>
            <button nbButton size='tiny'
                    class="col-6 {{prospectForm.controls?.sms?.value === true ? 'contact-active' : 'contact-inactive'}}"
                    hero (click)="setSquareValue('sms')">S
            </button>
          </div>
        </div>
        <div class='col-3 square-container text-center square-motive'>
          <label (click)='toggleDisplayMotive()' class='openExpand'>Motive
            <nb-icon class='ml-2' [icon]="'heart-outline'" pack='eva'></nb-icon>
          </label>
          <div class='col-12 p-0'>
            <button nbButton size='tiny'
                    class="col-6 {{prospectForm.controls?.motivateFinancialUp?.value === true ? 'motive-active' : 'motive-inactive'}}"
                    hero status='basic' (click)="setSquareValue('motivateFinancialUp')">F+
            </button>
            <button nbButton size='tiny'
                    class="col-6 {{prospectForm.controls?.motivateLifestyleUp?.value === true ? 'motive-active' : 'motive-inactive'}}"
                    hero status='basic' (click)="setSquareValue('motivateLifestyleUp')">LS+
            </button>
            <button nbButton size='tiny'
                    class="col-6 {{prospectForm.controls?.motivateFinancialDown?.value === true ? 'motive-active' : 'motive-inactive'}}"
                    hero status='basic' (click)="setSquareValue('motivateFinancialDown')">F-
            </button>
            <button nbButton size='tiny'
                    class="col-6 {{prospectForm.controls?.motivateLifestyleDown?.value === true ? 'motive-active' : 'motive-inactive'}}"
                    hero status='basic' (click)="setSquareValue('motivateLifestyleDown')">LS-
            </button>
          </div>
        </div>
        <div class='col-3 square-container text-center square-person'>
          <label (click)='toggleDisplayPerson()' class='openExpand'>History
            <nb-icon class='ml-2' [icon]="'clock-outline'" pack='eva'></nb-icon>
          </label>
          <div class='col-12 p-0'>
            <button nbButton size='tiny'
                    class="col-6 {{prospectForm.controls?.isDirector?.value === true ? 'person-active' : 'person-inactive'}}"
                    hero (click)="setSquareValue('isDirector')">D
            </button>
            <button nbButton size='tiny'
                    class="col-6 {{prospectForm.controls?.isInfluencer?.value === true ? 'person-active' : 'person-inactive'}}"
                    hero (click)="setSquareValue('isInfluencer')">I
            </button>
            <button nbButton size='tiny'
                    class="col-6 {{prospectForm.controls?.isConcensious?.value === true ? 'person-active' : 'person-inactive'}}"
                    hero (click)="setSquareValue('isConcensious')">C
            </button>
            <button nbButton size='tiny'
                    class="col-6 {{prospectForm.controls?.isSocialble?.value === true ? 'person-active' : 'person-inactive'}}"
                    hero (click)="setSquareValue('isSocialble')">S
            </button>
          </div>
        </div>
      </div>
      <div class='row p-3 mt-2' *ngIf='isMotive'>
        <div class='col-12'>
          <textarea rows='2' nbInput fullWidth shape='round' placeholder='Motive'
                    class='text-area-without-border-radius'
                    formControlName='motiveNote' [ngClass]="showError('motiveNote')">
          </textarea>
        </div>
      </div>

      <div class='row p-3 mt-4 market-section' [hidden]='!showMarket'>
        <div class='col-12'>
          <div class='row p-2'>
            <div class='col-2'>
              <select formControlName='type'
                      [ngClass]="showError('type')"
                      (change)='onPropertyTypeChange()'
                      class='property-spec-select'>
                <option value='' disabled>Type</option>
                <option *ngFor='let item of propertyType | keyvalue:keepOrder'
                        value='{{item.key}}'
                >{{item.value}}</option>
              </select>
            </div>
            <div class='col-2'>
              <select formControlName='bed'
                      [ngClass]="showError('bed')"
                      class='property-spec-select'

              >
                <option value='' disabled>Bed</option>
                <option *ngFor='let item of bedValues' [value]='item'>{{item}}</option>
              </select>
            </div>
            <div class='col-2'>
              <select formControlName='bath'
                      [ngClass]="showError('bath')" class='property-spec-select'

              >
                <option value='' disabled>Bath</option>
                <option *ngFor='let item of bedValues' [value]='item'>{{item}}</option>
              </select>
            </div>
            <div class='col-2'>
              <select formControlName='car'
                      [ngClass]="showError('car')" class='property-spec-select'

              >
                <option value='' disabled>Car</option>
                <option *ngFor='let item of bedValues' [value]='item'>{{item}}</option>
              </select>
            </div>
            <div class='col-4 p-0'>
              <input type='text' nbInput fieldSize='tiny'
                     class='landInput'
                     fullWidth name='land'
                     formControlName='land' placeholder='Land' [ngClass]="showError('land')"
              />
            </div>
          </div>
        </div>
        <div class='col-sm-12 mt-3'>
          <div class='row'>
            <div class='col-4 p-0'>

              <div class='col-12 p-0 property-type-container'>
                <nb-form-field class='w-100'>
                  <input type='text' nbInput fieldSize='tiny' fullWidth name='askingPrice'
                         formControlName='askingPrice' placeholder='Asking price'>
                  <span nbSuffix>{{defaultCurrencyIcon}}</span>
                </nb-form-field>
              </div>

              <div class='w-100 form-group mt-3 property-type-container mb-1'>
                <button nbButton ghost fullWidth class='date-picker-button'
                        (click)='triggerDatePicker(datepickerOtmDate)'>
                  <input [nbDatepicker]='datepickerOtmDate' nbInput fieldSize='tiny'
                         fullWidth formControlName='otmDate' placeholder='Otm date' disabled />
                </button>
                <nb-datepicker #datepickerOtmDate></nb-datepicker>
              </div>

              <div class='w-100 property-type-container mt-5'>
                <div class='form-group'>
                  <div>
                    <select size='tiny' formControlName='sellCompanyId' class='full-width radius'>
                      <option [value]='null' disabled>Sell Company</option>
                      <option [value]=''>Empty</option>
                      <option *ngFor='let item of companies' [value]='item.companyId'>{{item.name}}</option>
                    </select>
                  </div>
                  <a href='/pages/prospect/company/list'>Create new</a>
                  <nb-datepicker #datepickerPurchaseDate></nb-datepicker>
                </div>
              </div>
            </div>
            <div class='col-8 d-flex p-0'>
              <div class='col-6 p-0'>

                <div class='col-12 p-0 property-type-container'>
                  <nb-form-field class='w-100'>
                    <input type='text' nbInput fieldSize='tiny' fullWidth name='sellPrice' formControlName='sellPrice'
                           placeholder='Sell price'>
                    <span nbSuffix>{{defaultCurrencyIcon}}</span>
                  </nb-form-field>
                </div>

                <div class='mt-3'>
                  <div class='col-12 p-0 property-type-container'>
                    <nb-form-field class='w-100'>
                      <div class='form-group'>
                        <button nbButton ghost fullWidth class='date-picker-button'
                                (click)='triggerDatePicker(datepickerPurchaseDate)'>
                          <input
                            [nbDatepicker]='datepickerPurchaseDate'
                            nbInput fieldSize='tiny'
                            fullWidth formControlName='purchaseDate' placeholder='Purchase date' disabled />
                        </button>
                      </div>
                    </nb-form-field>
                  </div>
                </div>
              </div>
              <div class='col-6 p-0'>
                <div class='col-12 p-0 property-type-container'>
                  <nb-form-field class='w-100'>
                    <input type='text' nbInput fieldSize='tiny' fullWidth name='rentPrice' formControlName='rentPrice'
                           placeholder='Rent price'>
                    <span nbSuffix>{{defaultCurrencyIcon}}</span>
                  </nb-form-field>
                </div>

                <div class='mt-3'>
                  <div class='col-sm-12 w-100 p-0 property-type-container text-center'>
                    <div class='form-group w-100'>
                      <button nbButton ghost fullWidth class='date-picker-button'
                              (click)='triggerDatePicker(datepickerVacDate)'>
                        <input [nbDatepicker]='datepickerVacDate' nbInput fieldSize='tiny'
                               fullWidth formControlName='vacDate' placeholder='Vac date' disabled />
                      </button>
                      <nb-datepicker #datepickerVacDate></nb-datepicker>
                    </div>
                  </div>
                </div>

                <div class='mt-5'>
                  <div class='col-12 p-0 property-type-container'>
                    <div class='form-group'>
                      <div class='w-100'>
                        <select size='tiny' formControlName='rentCompanyId' class='full-width radius'>
                          <option [value]='null' disabled>Rent Company</option>
                          <option [value]=''>Empty</option>
                          <option *ngFor='let item of companies' [value]='item.companyId'>{{item.name}}</option>
                        </select>
                      </div>
                      <a href='/pages/prospect/company/list'>Create new</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class='col-12 mt-2'>
              <ngx-attachment
                objectType='PROSPECT'
              ></ngx-attachment>
            </div>
          </div>
        </div>
      </div>

      <div class='row p-3 mt-3 contact-section' [hidden]='!showContact'>
        <div class='col-6'>
          <div class='w-100'>
            <input type='text' nbInput fieldSize='tiny' fullWidth name='name' placeholder='Name'
                   formControlName='name' [ngClass]="showError('name')">
          </div>
        </div>
        <div class='col-6'>
          <div class='w-100'>
            <input type='text' nbInput fieldSize='tiny' fullWidth name='surname' placeholder='Surname'
                   formControlName='surname' [ngClass]="showError('surname')">
          </div>
        </div>
        <div class='col-12 mt-2'>
          <div class='row pointer w-100 same-phone' (click)="selectPhone('PHONE')" *ngIf='phoneDuplicate'>
            <div class='col-6'>
              <p *ngIf='phoneDuplicate.totalClient !== 0'>Found Clients : {{phoneDuplicate.totalClient}}</p>
            </div>
            <div class='col-6'>
              <p *ngIf='phoneDuplicate.totalCustomer !== 0'>Found Customers : {{phoneDuplicate.totalCustomer}}</p>
            </div>
          </div>
        </div>
        <div class='col-12'>
          <div class='row pointer w-100 same-phone' (click)="selectPhone('MPHONE')" *ngIf='mobiPhoneDuplicate'>
            <div class='col-6'>
              <p *ngIf='mobiPhoneDuplicate.totalClient !== 0'>Found Clients : {{mobiPhoneDuplicate.totalClient}}</p>
            </div>
            <div class='col-6'>
              <p *ngIf='mobiPhoneDuplicate.totalCustomer !== 0'>Found Customers
                : {{mobiPhoneDuplicate.totalCustomer}}</p>
            </div>
          </div>
        </div>
        <div class='col-12 property-type-container'>
          <input type='text' nbInput fieldSize='tiny'
                 class="{{prospectForm.controls.call.value === true ? 'phone-inactive' : 'phone-active'}}"
                 fullWidth name='phone' formControlName='phone' placeholder='Phone' [ngClass]="showError('phone')">
          <span class='ml-1 status-search'
                (click)='searchSameCustomer("PHONE")'>
                                <nb-icon [icon]="'search-outline'"
                                         pack='eva'>
                                </nb-icon>
                              </span>
        </div>
        <div class='col-12 mt-2 property-type-container'>
          <input type='text' nbInput fieldSize='tiny'
                 class="{{(prospectForm.controls.call.value === true || prospectForm.controls.sms.value === true) ? 'phone-inactive' : 'phone-active'}}"
                 fullWidth name='mobilePhone' formControlName='mobilePhone' placeholder='Mobile Phone'
                 [ngClass]="showError('mobilePhone')">
          <span class='ml-1 status-search'
                (click)='searchSameCustomer("MPHONE")'>
                                <nb-icon [icon]="'search-outline'"
                                         pack='eva'>
                                </nb-icon>
                              </span>
        </div>
        <div class='col-12 mt-2 property-type-container'>
          <input type='text' nbInput fieldSize='tiny' fullWidth name='email' formControlName='email' placeholder='Email'
                 [ngClass]="showError('email')">
        </div>
        <div class='col-6 mt-2 property-type-container'>
          <input type='text' nbInput fieldSize='tiny' fullWidth name='facebook' formControlName='facebook'
                 placeholder='Facebook' [ngClass]="showError('facebook')">
        </div>
        <div class='col-6 mt-2 property-type-container'>
          <input type='text' nbInput fieldSize='tiny' fullWidth name='instagram' formControlName='instagram'
                 placeholder='Instagram' [ngClass]="showError('instagram')">
        </div>
        <div class='col-6 mt-2 property-type-container'>
          <input
            [(ngModel)]='categoryName'
            [ngModelOptions]='{standalone: true}'
            [typeahead]='filterCategory$'
            [typeaheadAsync]='true'
            (typeaheadOnSelect)='selectCategory($event)'
            typeaheadOptionField='name'
            typeaheadWaitMs='1000'
            placeholder='Tag'
            nbInput fieldSize='tiny' fullWidth>
        </div>
        <div class='col-6 property-type-container'>
          <div *ngFor='let category of selectedCategories' class='category-block mt-2'>
            {{category.name}}
            <button class='btn btn-danger btn-sm' (click)='removeCategory(category)'>
              <i class='fa fa-times' aria-hidden='true'></i>
            </button>
          </div>
        </div>
        <!--        <div class='row mt-2'>-->
        <!--          <nb-accordion>-->
        <!--            <nb-accordion-item>-->
        <!--              <nb-accordion-item-header>Extra info</nb-accordion-item-header>-->
        <!--              <nb-accordion-item-body>-->
        <!--                <div class='row'>-->
        <!--                  <div class='form-group width50'>-->
        <!--                    <select size='tiny' formControlName='nationality' class='full-width'-->
        <!--                            (change)='onNationalityChange()'>-->
        <!--                      <option [value]='null' disabled>Nationality</option>-->
        <!--                      <option *ngFor='let item of nationalities'-->
        <!--                              [value]='item.country_name'>{{item.country_name}}</option>-->
        <!--                    </select>-->
        <!--                  </div>-->
        <!--                  <div class='form-group width50'>-->
        <!--                    <select size='tiny' formControlName='religion' class='full-width'>-->
        <!--                      <option [value]='null' disabled>Religion</option>-->
        <!--                      <option *ngFor='let item of religions'-->
        <!--                              [value]='item.name'>{{item.name}}</option>-->
        <!--                    </select>-->
        <!--                  </div>-->
        <!--                  <div class='form-group width50'>-->
        <!--                    <input type='text' nbInput fieldSize='tiny'-->
        <!--                           fullWidth name='profesion'-->
        <!--                           formControlName='profesion' placeholder='Profesion'-->
        <!--                           [ngClass]="showError('profesion')"-->
        <!--                    />-->
        <!--                  </div>-->
        <!--                  <div class='form-group width50'>-->
        <!--                    <select size='tiny' formControlName='language' class='full-width'>-->
        <!--                      <option [value]='null' disabled>Language</option>-->
        <!--                      <option *ngFor='let item of languages'-->
        <!--                              [value]='item.code'>{{item.name}}</option>-->
        <!--                    </select>-->
        <!--                  </div>-->
        <!--                  <div class='form-group width50'>-->
        <!--                    <button nbButton ghost fullWidth class='date-picker-button'-->
        <!--                            (click)='triggerDatePicker(datepickerAnniversaryDay)'>-->
        <!--                      <input [nbDatepicker]='datepickerAnniversaryDay' nbInput fieldSize='tiny'-->
        <!--                             fullWidth formControlName='anniversaryDay'-->
        <!--                             placeholder='Anniversary Day' disabled />-->
        <!--                    </button>-->
        <!--                    <nb-datepicker #datepickerAnniversaryDay></nb-datepicker>-->
        <!--                  </div>-->
        <!--                  <textarea rows='5' class='mt-2 text-area-without-border-radius'-->
        <!--                            nbInput fullWidth shape='round' placeholder='Note'-->
        <!--                            formControlName='nationalityNote'-->
        <!--                            [ngClass]="showError('nationalityNote')"></textarea>-->
        <!--                </div>-->
        <!--                <div class='row mt-3'>-->
        <!--                  <div class='col-sm-4 property-type-container text-center'>-->
        <!--                    <div class='form-group w-100'>-->
        <!--                      <label class='label'>Office</label>-->
        <!--                      <input-->
        <!--                        [(ngModel)]='officeName'-->
        <!--                        [ngModelOptions]='{standalone: true}'-->
        <!--                        [typeahead]='filterOffice$'-->
        <!--                        [typeaheadAsync]='true'-->
        <!--                        (typeaheadOnSelect)='selectOffice($event)'-->
        <!--                        typeaheadOptionField='name'-->
        <!--                        typeaheadWaitMs='1000'-->
        <!--                        placeholder='Office'-->
        <!--                        nbInput fullWidth-->
        <!--                      />-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--                <div class='row mt-3'>-->
        <!--                  <div class='col-sm-12 property-type-container text-center'>-->
        <!--                    <nb-checkbox formControlName='sold'>Sold</nb-checkbox>-->
        <!--                    <nb-checkbox formControlName='underContract' class='ml-2'>Under Contract-->
        <!--                    </nb-checkbox>-->
        <!--                    <nb-checkbox formControlName='leased' class='ml-2'>Leased</nb-checkbox>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--              </nb-accordion-item-body>-->
        <!--            </nb-accordion-item>-->
        <!--          </nb-accordion>-->
        <!--        </div>-->
      </div>

      <div class='row p-3 mt-4 motive-section' [hidden]='!showMotive'>
        <div class='col-12 property-type-container'>
          <select size='tiny' formControlName='relationship' class='full-width'>
            <option [value]='null' disabled>Relationship</option>
            <option *ngFor='let item of customerRelationShip | keyvalue:keepOrder'
                    [value]='item.value'>{{item.value}}</option>
          </select>
          <select size='tiny' formControlName='motivate' class='full-width'>
            <option [value]='null' disabled>Motivate</option>
            <option *ngFor='let item of customerMotivate | keyvalue:keepOrder'
                    [value]='item.value'>{{item.value}}</option>
          </select>
        </div>
        <!--        <div class='col-12 property-type-container mt-2'>-->
        <!--          <div class='form-group width50'>-->
        <!--            <nb-form-field>-->
        <!--              <button nbButton ghost fullWidth class='date-picker-button'-->
        <!--                      (click)='triggerDatePicker(datepickerDOB)'>-->
        <!--                <input [nbDatepicker]='datepickerDOB' nbInput fieldSize='tiny'-->
        <!--                       fullWidth placeholder='D.O.B' formControlName='dob' disabled>-->
        <!--              </button>-->
        <!--              <button nbSuffix nbButton ghost (click)="createTask('BIRTHDAY_CALL')">-->
        <!--                <nb-icon [icon]="'plus-outline'"-->
        <!--                         pack='eva'>-->
        <!--                </nb-icon>-->
        <!--              </button>-->
        <!--            </nb-form-field>-->
        <!--            <nb-datepicker #datepickerDOB></nb-datepicker>-->
        <!--            <span [hidden]="!dobText || dobText === ''">{{dobText}}</span>-->
        <!--          </div>-->
        <!--          <div class='form-group width50'>-->
        <!--            <nb-form-field>-->
        <!--              <button nbButton ghost fullWidth class='date-picker-button'-->
        <!--                      (click)='triggerDatePicker(datepickerCallback)'>-->
        <!--                <input [nbDatepicker]='datepickerCallback' nbInput fieldSize='tiny'-->
        <!--                       fullWidth placeholder='Call back date' formControlName='callbackDate'-->
        <!--                       disabled>-->
        <!--              </button>-->
        <!--              <button nbSuffix nbButton ghost (click)="createTask('NEXT_CALL')">-->
        <!--                <nb-icon [icon]="'plus-outline'"-->
        <!--                         pack='eva'>-->
        <!--                </nb-icon>-->
        <!--              </button>-->
        <!--            </nb-form-field>-->
        <!--            <nb-datepicker #datepickerCallback></nb-datepicker>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class='full-width mt-2'>
          <div class='col-12'>
          <textarea rows='2' nbInput fullWidth shape='round' placeholder='Motive'
                    class='text-area-without-border-radius'
                    formControlName='motiveNote' [ngClass]="showError('motiveNote')">
          </textarea>
          </div>
        </div>
      </div>

      <div class='row p-3 mt-3 person-section' [hidden]='!showPerson'>
        <textarea rows='5' class='mt-2 text-area-without-border-radius'
                  nbInput fullWidth shape='round' placeholder='Note'
                  formControlName='note' [ngClass]="showError('note')"></textarea>
      </div>
    </form>

  </nb-card-body>
  <nb-card-footer class='dialog-footer'>
    <div class='row'>
      <div class='col-12'>
        <button nbButton status='success' (click)='submit()'
                class='ml-2 fa-pull-right'
                size='small' [disabled]='isUpdating === true'>Save
        </button>
        <button nbButton (click)='close()' size='small' class='fa-pull-right'>Cancel</button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
