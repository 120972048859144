import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {RestResult} from '../models/response/rest-result';
import {HttpClient} from '@angular/common/http';
import {catchError, concatMap, finalize, map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Constant} from '../common/constant';
import {Permission} from '../models/response/permission';
import {User} from '../models/response/user';
import {PermissionEnum} from '../models/enum/authorities';
import {CommonService} from './common.service';
import {ResetPasswordRequest} from '../models/request/reset-password-request';
import {Path} from '../common/path';
import {City} from '../models/response/address/city';
import {District} from '../models/response/address/district';
import {UserUtils} from '../common/user-utils';

@Injectable()
export class AuthService {

  get authorities(): Permission[] {
    const dataStr = localStorage.getItem(Constant.LS_AUTHORITIES_KEY);
    return JSON.parse(dataStr);
  }

  get currentUser(): User {
    const dataStr = localStorage.getItem(Constant.LS_CURRENT_USER_KEY);
    return JSON.parse(dataStr);
  }

  get assignedSuburb(): District[] {
    const dataStr = localStorage.getItem(Constant.LS_ASSIGNED_SUBURB);
    return JSON.parse(dataStr);
  }

  constructor(
    private router: Router,
    private http: HttpClient,
    private commonService: CommonService,
  ) {
  }

  get accessToken(): string {
    return localStorage.getItem(Constant.LS_ACCESS_TOKEN_KEY);
  }

  clearAccessToken(): void {
    localStorage.removeItem(Constant.LS_ACCESS_TOKEN_KEY);
    this.clearCurrentUser();
  }

  login(username: string, password: string): Observable<RestResult> {
    const request = {
      clientId: 'future',
      grantType: 'password',
      username,
      password,
    };
    return this.http.post<any>(Path.API_ENDPOINT + '/user/login', request).pipe(
      concatMap(
        result => {
          localStorage.setItem(Constant.LS_ACCESS_TOKEN_KEY, result.data.access_token);
          return this.loadCurrentUser();
        }),
      catchError(this.commonService.handleError),
    );
  }

  logout(): Observable<RestResult> {
    return this.http.post<any>(Path.API_ENDPOINT + '/user/logout', {}).pipe(
      map(result => {
        return result;
      }),
      catchError(this.commonService.handleError),
      finalize(() => {
        this.clearAccessToken();
        this.router.navigate(['/auth/login']);
      }),
    );
  }

  checkPermission(permissions: string | string[]) {
    let authorized = false;
    if (!Array.isArray(permissions)) {
      authorized = this.hasPermission(permissions);
    } else {
      for (const p of permissions) {
        authorized = this.hasPermission(p);
        if (authorized) {
          break;
        }
      }
    }
    return authorized;
  }

  hasPermission(permission: PermissionEnum | string): boolean {
    if (!this.authorities) {
      return false;
    }
    for (const userPermission of this.authorities) {
      if (userPermission.permissionName.valueOf() === PermissionEnum.ALL_PERMISSION.valueOf()
        || userPermission.permissionName === permission.valueOf()) {
        return true;
      }
    }
    return false;
  }

  loadCurrentUser(): Observable<RestResult> {
    return this.http.get<any>(Path.API_ENDPOINT + '/me').pipe(
      map(
        result => {
          const currentUser: User = result;
          localStorage.setItem(Constant.LS_CURRENT_USER_KEY, JSON.stringify(currentUser));
          if (currentUser.permissions) {
            localStorage.setItem(Constant.LS_AUTHORITIES_KEY, JSON.stringify(currentUser.permissions));
          }
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  clearCurrentUser(): void {
    localStorage.removeItem(Constant.LS_CURRENT_USER_KEY);
    this.clearAssignedAddress();
  }

  clearAssignedAddress(): void {
    localStorage.removeItem(Constant.LS_ASSIGNED_SUBURB);
  }

  resetPassword(request: ResetPasswordRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.USER +
      Path.PASSWORD + '/reset', request).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }
}
