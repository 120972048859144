export class AttachmentRequest {
  file: File;
  attachmentId: number;
  path: String;
  name: String;
  isPublic: boolean;
  objectId: number;
  objectType: string;
  status: string;
  createdBy: string;
  createdDate: number;
  files: File[];
}
