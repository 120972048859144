<div class='row mb-2 pr-2'>
  <div class='col-4'>
    <span>{{farm}}</span>
  </div>
  <div class='col-4 text-center'>
    <span>{{zone}}</span>
  </div>
  <div class='col-4'>
    <!--    <button nbTooltip='CA' nbButton size='tiny' hero status="{{isCAMode === true ? 'primary' : 'basic'}}"-->
    <!--            class='fa-pull-right' (click)='onCaModeChange()'>CA-->
    <!--    </button>-->
    <!--    <button nbTooltip='Door Knock' nbButton size='tiny' hero status="{{isDkMode === true ? 'primary' : 'basic'}}"-->
    <!--            class='fa-pull-right mr-1' (click)='onDkModeChange()'>DK-->
    <!--    </button>-->
  </div>
</div>
<div class='row mt-2 pl-2 pr-2'>
  <div class='col-lg-3 col-12 override-width override-width-md mt-2'>
    <select fullWidth size='tiny'
            [(ngModel)]='sortType'
            class='full-width select-custom'
            (change)='onSortTypeSelectChane()'
    >
      <option *ngFor='let item of sortTypesText | keyvalue'
              value='{{item.key}}'
      >{{item.value}}</option>
    </select>
  </div>
  <div class='col-lg-3 col-12 override-width override-width-md mt-2'>
    <select fullWidth size='tiny'
            [(ngModel)]='streetId'
            [disabled]='!districtId'
            class='full-width select-custom'
            (change)='onStreetSelectChange()'
    >
      <option [value]='undefined'>All streets</option>
      <option *ngFor='let key of streets'
              [ngClass]="key.status === 'DEACTIVE' ? 'deactive-status' : ''"
              [value]='key.streetId'
      >{{key.name}}</option>
    </select>
  </div>
  <div class='col-lg-3 col-12 override-width override-width-md mt-2'>
    <select size='tiny'
            [(ngModel)]='districtId'
            [disabled]='!filterByPostcode'
            class='full-width select-custom'
            (change)='onSuburbSelectChange()'
    >
      <option [value]='undefined'>All suburbs</option>
      <option *ngFor='let key of districts'
              [ngClass]="key.status === 'DEACTIVE' ? 'deactive-status' : ''"
              [value]='key.districtId'
      >{{key.name}}</option>
    </select>
  </div>
  <div class='col-lg-3 col-12 override-width override-width-md mt-2'>
    <!--    <select size='tiny'-->
    <!--            [(ngModel)]='cityId'-->
    <!--            class='full-width select-custom'-->
    <!--            (change)='onCitySelectChange()'-->
    <!--    >-->
    <!--      <option [value]='undefined'>All cities</option>-->
    <!--      <option *ngFor='let key of cities'-->
    <!--              [ngClass]="key.status === 'DEACTIVE' ? 'deactive-status' : ''"-->
    <!--              [value]='key.cityId'-->
    <!--      >{{key.name}}</option>-->
    <!--    </select>-->
    <nb-form-field>
      <input nbInput fullWidth placeholder='Postcode' fieldSize='tiny' class='override-nbinput-height'
             [(ngModel)]='postcode'
             [typeahead]='filterPostcode$'
             [ngModelOptions]='{standalone: true}'
             [typeaheadAsync]='true'
             (typeaheadOnSelect)='selectPostcode($event)'
             typeaheadOptionField='code'
             typeaheadMinLength='3'
             typeaheadWaitMs='1000'>
      <!--      <span nbSuffix (click)='searchSuburbByPostcode()'><i class='fa fa-search'></i></span>-->
    </nb-form-field>
  </div>
</div>
<div class='col-12 advance mt-3'>
  <button nbButton size='tiny' hero status='primary'
          class='pull-right'
          nbTooltip='Trigger Search'
          (click)='onSearchButtonClick()'>
    Search
  </button>
  <button nbButton size='tiny' class='mr-1 pull-right' hero status='primary' (click)='addNewProspect()'
          nbTooltip='Create Prospect'
          *appPermissionCheck='ComponentPermission.CLIENT_CREATE'>
    New
  </button>
  <button nbButton size='tiny' class='pull-right mr-1' hero status='primary' (click)='openShortcut()'
          nbTooltip='Quick Create Prospect'
          *appPermissionCheck='ComponentPermission.CLIENT_CREATE_SHORTCUT'>
    Shortcut
  </button>
  <button nbButton size='tiny' hero status='primary'
          class='mr-1 pull-right'
          nbTooltip='Expand Advance Search'
          (click)='toggleAdvance()'>
    Advance
  </button>
</div>
<div class='row full-width pl-3' *ngIf='expandAdvance === true'>
  <div class='row full-width mt-2 pl-1'>
    <div class='col-3 property-type-container'>
      <input
        [(ngModel)]='categoryName'
        [ngModelOptions]='{standalone: true}'
        [typeahead]='filterCategory$'
        [typeaheadAsync]='true'
        (typeaheadOnSelect)='selectCategory($event)'
        typeaheadOptionField='name'
        typeaheadWaitMs='1000'
        placeholder='Tag'
        nbInput fieldSize='tiny' fullWidth>
    </div>
    <div class='col-9 mt-1'>
      <div class='category-block' *ngFor='let category of selectedCategories'>
        {{category.name}}
        <button class='btn btn-danger btn-sm' (click)='removeCategory(category)'>
          <i class='fa fa-times' aria-hidden='true'></i>
        </button>
      </div>
    </div>
  </div>
  <div class='row full-width mt-2 pl-1'>
    <div class='col-3 property-type-container'>
      <input
        *appPermissionCheck='ComponentPermission.GLOBAL_FILTER_USER'
        [(ngModel)]='userName'
        [ngModelOptions]='{standalone: true}'
        [typeahead]='filterUser$'
        [typeaheadAsync]='true'
        (typeaheadOnSelect)='selectUser($event)'
        typeaheadOptionField='name'
        typeaheadWaitMs='1000'
        placeholder='Search User'
        nbInput fieldSize='tiny' fullWidth>
    </div>
    <div class='col-9 mt-1'>
      <div class='category-block' *ngIf='selectedUser'>
        {{ selectedUser && selectedUser.name ? selectedUser.name + ' (' + stringUtils.shortenUserName(selectedUser) + ')' : '' }}
        <button class='btn btn-danger btn-sm' (click)='removeUser()'
                *appPermissionCheck='ComponentPermission.GLOBAL_FILTER_USER'>
          <i class='fa fa-times' aria-hidden='true'></i>
        </button>
      </div>
    </div>
  </div>
  <div class='row full-width mt-2 pl-1'>
    <div class='col-3'>
      <input type='text' nbInput fieldSize='tiny' fullWidth name='phone' placeholder='Phone / Mobile'
             [(ngModel)]='phone' (change)="onNumberChange()"/>
    </div>
  </div>
  <div class='row full-width mt-2'>
    <div class='col-4 pl-2'>
      <nb-select fullWidth size='tiny' [(ngModel)]='rate' (selectedChange)='onRateSelectChange($event)'>
        <nb-option class='select-option' [value]='undefined'>Rate</nb-option>
        <nb-option class='select-option' *ngFor='let item of rateOptions'
                   [value]='item.value'>{{item.label}}</nb-option>
      </nb-select>
      <p class='text-center mt-2'>{{total}}</p>
    </div>
    <div class='col-8 text-center'>
      <div class='form-group fa-pull-right mr-1 mb-0'>
        <p nbTooltip='SMS' class='label'>S</p>
        <p>{{totalSms}}</p>
      </div>
      <div class='form-group fa-pull-right mr-4 mb-0'>
        <p nbTooltip='Call' class='label'>C</p>
        <p>{{totalCall}}</p>
      </div>
      <div class='form-group fa-pull-right mr-4 mb-0'>
        <p nbTooltip='Door' class='label'>D</p>
        <p>{{totalDoor}}</p>
      </div>
      <div class='form-group fa-pull-right mr-4 mb-0'>
        <p nbTooltip='Mail' class='label'>M</p>
        <p>{{totalMail}}</p>
      </div>
    </div>
  </div>
</div>
