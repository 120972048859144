<nb-card>
  <nb-card-header>
    You can move or copy information from customer to customer
  </nb-card-header>

  <nb-card-body>
    <div class="row">
      <div class="col-lg-6 col-sm-12" *ngFor="let selectedProspect of prospects">
        <button nbButton size="tiny" hero status="info"
                class="m-2"
                nbTooltip="Copy information from this to all other"
                (click)="copyThisToAll(selectedProspect.prospectId, selectedProspect.customer.customerId, 'PROSPECT')"
        >
          Copy This To All
        </button>
        <ngx-prospect-dialog
          #prospectChild
          [selectedProspect]="selectedProspect"
          [showFooter]="true"
          [prospects]="prospects"
          [companies]="companies"
          [taskTypes]="taskTypes"
          [taskStatuses]="taskStatuses"
          (editDone)="editDone($event)"
        ></ngx-prospect-dialog>
      </div>
      <div class="col-lg-6 col-sm-12" *ngFor="let selectedBuyer of buyers">
        <button nbButton size="tiny" hero status="info"
                class="m-2"
                nbTooltip="Copy information from this to all other"
                (click)="copyThisToAll(selectedBuyer.buyerId, selectedBuyer.customerId, 'BUYER')"
        >
          Copy This To All
        </button>
        <ngx-buyer-create
          #buyerChild
          [selectedBuyer]="selectedBuyer"
          [showFooter]="true"
          [cities]="cities"
          [taskTypes]="taskTypes"
          [taskStatuses]="taskStatuses"
          (navigateBuyerEvent)="navigateProspect($event)"
          (editDone)="editDone($event)"
          (newDone)="newDone($event)"
          (reserveBuyer)="reserveBuyer($event)"
          (ownSet)="ownSet($event)"
        ></ngx-buyer-create>
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer class="dialog-footer">
    <button class="cancel" size="tiny" nbButton status="primary" (click)="save()">Save All</button>
    <button class="cancel" size="tiny" nbButton status="warning" (click)="cancel()">Cancel</button>
  </nb-card-footer>
</nb-card>
