import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from './common.service';
import { ErrorService } from './error.service';
import { Observable } from 'rxjs';
import { RestResult } from '../models/response/rest-result';
import { Path } from '../common/path';
import { catchError, map } from 'rxjs/operators';
import { SearchRequest } from '../models/request/search-request';
import { OfficePropertyRequest } from '../models/request/office-property-request';

@Injectable()
export class OfficePropertyService {
  constructor(protected http: HttpClient,
              protected commonService: CommonService,
              protected errorService: ErrorService) {
  }

  getOne(officePropertyId: number): Observable<RestResult> {
    return this.http.get<any>(
      Path.API_ENDPOINT +
      Path.OFFICE_PROPERTY +
      Path.OFFICE_PROPERTY_ID.replace(':officePropertyId', officePropertyId.toString()),
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }


  search(request: SearchRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.OFFICE_PROPERTY +
      Path.SEARCH, request,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  create(request: OfficePropertyRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.OFFICE_PROPERTY, request).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  remove(officePropertyId: number): Observable<RestResult> {
    return this.http.delete<any>(
      Path.API_ENDPOINT +
      Path.OFFICE_PROPERTY +
      Path.OFFICE_PROPERTY_ID.replace(':officePropertyId', officePropertyId.toString()),
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  update(request: OfficePropertyRequest): Observable<RestResult> {
    return this.http.put<any>(
      Path.API_ENDPOINT +
      Path.OFFICE_PROPERTY, request).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }
}
