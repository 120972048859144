import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Prospect } from '../../../../shared/models/response/prospect/prospect';
import { StringUtils } from '../../../../shared/common/string-utils';
import { CategoryService } from '../../../../shared/services/category.service';
import { Category } from '../../../../shared/models/response/category';
import { ProspectSearchRequest } from '../../../../shared/models/request/prospect/prospect-search-request';
import { Constant } from '../../../../shared/common/constant';
import { ProspectService } from '../../../../shared/services/prospect.service';
import { RestResult } from '../../../../shared/models/response/rest-result';
import { CommonService } from '../../../../shared/services/common.service';
import { ProspectDatasource } from '../common/prospect-datasource';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { ProspectDialogV2Component } from '../../../../shared/module/prospect-dialog-v2/prospect-dialog-v2.component';
import { TaskType } from '../../../../shared/models/response/task-type';
import { TaskStatus } from '../../../../shared/models/response/task-status';
import { District } from '../../../../shared/models/response/address/district';
import { City } from '../../../../shared/models/response/address/city';
import { Company } from '../../../../shared/models/response/prospect/company';
import { SearchRequest } from '../../../../shared/models/request/search-request';
import { ExportDialogComponent } from '../../buyer/export-dialog/export-dialog.component';
import { User } from '../../../../shared/models/response/user';
import { AuthService } from '../../../../shared/services/auth.service';
import { TemplateService } from '../../../../shared/services/template.service';
import { ExportAddressComponent } from '../../../../shared/module/export-address/export-address.component';
import { CompanyService } from '../../../../shared/services/company.service';
import { ProspectShortcutComponent } from '../prospect-shortcut/prospect-shortcut.component';
import { ComponentPermission } from '../../../../component.permission';
import { ProspectCreateDialogComponent } from '../prospect-create-dialog/prospect-create-dialog.component';
import { Street } from '../../../../shared/models/response/address/street';
import { Observable, of } from 'rxjs';
import { Postcode } from '../../../../shared/models/response/postcode';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { LocalService } from '../../../../shared/services/local.service';
import { map, mergeMap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { WrapItem, WrapperUtils } from '../common/wrapper-utils';
import { StatusUtils } from '../../buyer/common/status-utils';
import {DateUtils} from '../../../../shared/common/date-utils';
import { Triple } from '../../../../shared/models/request/triple';

@Component({
  selector: 'ngx-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss'],
})

export class ClientListComponent implements OnInit {
  @ViewChild(ProspectDialogV2Component) prospectDialog: ProspectDialogV2Component;
  @Output() reloadStreet: EventEmitter<any> = new EventEmitter<any>();
  @Output() isEdit = new EventEmitter();
  @Output() onSelectProspect = new EventEmitter();
  @Output() isDisplayed = new EventEmitter();
  @Output() onSelectCategories = new EventEmitter();
  @Input() taskTypes: TaskType[];
  @Input() taskStatuses: TaskStatus[];
  @Input() districts: District[];
  @Input() cities: City[];
  @Input() companies: Company[];
  data: Prospect[] = [];
  searchRequest = new ProspectSearchRequest();
  reducedMode = false;
  selectedProspects: Prospect[];
  stringUtils = StringUtils;
  classificationOption: string;
  clientRateOptions = Constant.CLIENT_RATE_OPTIONS;
  propertyType = Constant.PROPERTY_TYPE;
  bedValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  displayList = false;
  source: ProspectDatasource;
  isDisplay: boolean;
  selectedCategories: Category[] = [];
  selectedProspect: Prospect;
  selectedRow: WrapItem;
  _selectedMode: string;
  dialogFormRef: NbDialogRef<ProspectDialogV2Component>;
  ComponentPermission = ComponentPermission;

  WrapperUtils = WrapperUtils;

  prospectTagButtons = [
    {
      key: 'T10',
      value: 1,
      selected: false,
    },
    {
      key: '1M',
      value: 5,
      selected: false,
    },
    {
      key: '3M',
      value: 6,
      selected: false,

    },
    {
      key: '6M',
      value: 7,
      selected: false,
    },
  ];
  rateOptions = [
    { value: 0, label: '0' },
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
  ];
  sortTypesText = {
    ASC: 'A+',
    DESC: 'A-',
    ODD_ASC: 'O+',
    ODD_DESC: 'O-',
    EVEN_ASC: 'E+',
    EVEN_DESC: 'E-',
  };
  currentPage = 1;
  pageSize = Constant.PAGE_SIZE;
  pages = [];
  totalPages: number;
  isEditing = false;
  newSearch = true;
  showAdvance = false;
  adminNumber: string;
  currentUser: User;
  log: string;
  totalRecords: number;
  streetId: number;
  districtId: number;
  postcode: number;
  filterByPostcode = false;
  filteredStreets: Street[];
  filteredDistricts: District[];
  filterPostcode$: Observable<Postcode[]>;
  allChecked = false;
  wrapItems: WrapItem[] = [];
  indexParent: number;
  indexChild: number;
  prospectIdCheckedList = [];
  streetIdCheckedList = [];
  fromPurchaseDate: Date;
  toPurchaseDate: Date;
  categoryName: string;
  filterCategory$: Observable<Category[]>;

  constructor(private categoryService: CategoryService,
              private prospectService: ProspectService,
              private authService: AuthService,
              private templateService: TemplateService,
              private dialogService: NbDialogService,
              private commonService: CommonService,
              private companyService: CompanyService,
              private localService: LocalService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.currentUser = this.authService.currentUser;
    this.searchRequest.sortType = 'ASC';
    this.searchRequest.countUnits = true;
    this.adminNumber = this.currentUser.office.phone;
    this.initFilter();
    this.filterPostcode$ = new Observable((observer: any) => {
      observer.next(this.postcode);
    }).pipe(
      map((query: string) => {
        this.postcode = Number(query);
        this.searchSuburbByPostcode();
        return null;
      }),
    );
    this.filterCategory$ = new Observable((observer: any) => {
      observer.next(this.categoryName);
    }).pipe(
      mergeMap((query: string) => this.searchCategory(query)),
    );
  }

  initFilter() {
    const searchRequest = new SearchRequest();
    searchRequest.offset = 0;
    searchRequest.limit = 10000;
    searchRequest.orders = new Array();
    searchRequest.orders.push(
      {
        left: 'name',
        right: 'asc',

      },
    );
    this.companyService.search(searchRequest).subscribe((result: RestResult) => {
      this.companies = result?.data;
    });
  }

  search(pageIndex: number, callback?: any, clearIds?: boolean) {
    if (this.newSearch) {
      this.pages = [];
    }
    if (clearIds) {
      this.prospectIdCheckedList = [];
      this.streetIdCheckedList = [];
    }
    this.currentPage = pageIndex;
    this.searchRequest.offset = (pageIndex - 1) * this.pageSize;
    this.searchRequest.limit = this.pageSize;
    if (this.fromPurchaseDate) {
      const d = this.fromPurchaseDate.getDate();
      const m = this.fromPurchaseDate.getMonth();
      const y = this.fromPurchaseDate.getFullYear();
      this.searchRequest.fromPurchaseDate = DateUtils.getStartOfDate(d, m, y).valueOf();
    }
    if (this.toPurchaseDate) {
      const d = this.toPurchaseDate.getDate();
      const m = this.toPurchaseDate.getMonth();
      const y = this.toPurchaseDate.getFullYear();
      this.searchRequest.toPurchaseDate = DateUtils.getEndOfDate(d, m, y).valueOf();
    }
    this.prospectService.getClientList(this.searchRequest).subscribe(result => {
      this.wrapItems = WrapperUtils.convertToNewList(result.data);
      this.totalRecords = result.metaData.total;
      this.totalPages = Math.ceil(result.metaData.totalPage / this.pageSize);
      this.generatePagination(pageIndex);
      this.displayList = true;
      if (callback) {
        callback();
      }
    });
  }

  onSearch() {
    this.newSearch = true;
    this.searchRequest.addressQuery =
      this.searchRequest.addressQuery
        .replace(/,/g, ' ')
        .replace(/\s\s/g, ' ');
    this.search(1);
  }

  generatePagination(start: number) {
    if (this.pages.length !== 0 && start !== this.pages[this.pages.length - 1]) {
      return;
    }
    this.pages = [];
    let end;
    if (start + 2 <= this.totalPages) {
      end = start + 2;
    } else {
      end = this.totalPages;
    }
    for (let i = start; i <= end; i++) {
      this.pages.push(i);
    }
  }

  loadPage(page?: number, next?: boolean, jump?: boolean) {
    this.allChecked = false;
    this.newSearch = false;
    let newPage = this.currentPage;
    if (next) {
      newPage++;
    } else {
      newPage--;
    }
    if (page) {
      newPage = page;
    }
    if (newPage === 0 || newPage > this.totalPages) {
      return;
    } else {
      this.currentPage = newPage;
    }
    let callback = null;
    if (jump) {
      callback = () => {
        this.selectedRow = null;
        this.navigateProspect(next);
      };
    }
    this.search(this.currentPage, callback, true);
  }

  selectProspectTag(button: any) {
    if (button.selected) {
      button.selected = false;
      this.searchRequest.categoryIds = [];
      // this.search(1);
      return;
    }
    this.prospectTagButtons.forEach(tag => {
      tag.selected = tag.value === button.value;
    });
    this.searchRequest.categoryIds = [];
    this.searchRequest.categoryIds.push(button.value);
    this.newSearch = true;
    // this.search(1);
  }

  onSelectCategory(categoryId: number) {
    if (!this.searchRequest.categoryIds) {
      this.searchRequest.categoryIds = [];
    }
    const index = this.searchRequest.categoryIds.indexOf(categoryId);
    if (index !== -1) {
      this.searchRequest.categoryIds.splice(index, 1);
    }
    this.searchRequest.categoryIds.push(categoryId);
  }

  keepOrder(a, b) {
    return 1;
  }

  onSelectClassificationOption() {
    // if (this.searchRequest.addressQuery === null || this.searchRequest.addressQuery === undefined || this.searchRequest.addressQuery === '') {
    //   this.displayList = false;
    // }
    // if (!this.displayList) {
    //   return;
    // }
    this.newSearch = true;
    if (!this.classificationOption || this.classificationOption === 'undefined') {
      this.searchRequest.sell = null;
      this.searchRequest.otm = null;
      this.searchRequest.rent = null;
      this.searchRequest.vac = null;
      this.searchRequest.hasPhone = null;
      this.searchRequest.hasMobilePhone = null;
      this.searchRequest.rate = null;
      // if (this.displayList) {
      //   this.search(1);
      // }
      return;
    }
    this.searchRequest.sell = this.classificationOption.includes('S') ? true : null;
    this.searchRequest.otm = this.classificationOption.includes('O') ? true : null;
    this.searchRequest.rent = this.classificationOption.includes('R') ? true : null;
    this.searchRequest.vac = this.classificationOption.includes('V') ? true : null;
    this.searchRequest.hasPhone = this.classificationOption.includes('H') ? true : null;
    this.searchRequest.hasMobilePhone = this.classificationOption.includes('M') ? true : null;
    const parts = this.classificationOption.split('.');
    this.searchRequest.rate = Number(parts[1].charAt(0));
    // if (this.displayList) {
    //   this.search(1);
    // }
  }

  onSelectSpec(field: string) {
    // if (this.searchRequest.addressQuery === null || this.searchRequest.addressQuery === undefined || this.searchRequest.addressQuery === '') {
    //   this.displayList = false;
    // }
    // if (!this.displayList) {
    //   return;
    // }
    if (this.searchRequest[field] === 'undefined') {
      this.searchRequest[field] = undefined;
    }
    this.newSearch = true;
    // if (this.displayList) {
    //   this.search(1);
    // }
  }

  clearQuery() {
    this.searchRequest.addressQuery = undefined;
    this.displayList = false;
  }

  selectRow(wrapItem: WrapItem, openFirst?: boolean) {
    if (!wrapItem.isGrouped) {
      if (!this.selectedRow || this.selectedRow.data.prospectId !== wrapItem.data.prospectId) {
        if (this.selectedRow) {
          this.selectedRow.isSelected = false;
          if (this.prospectIdCheckedList && this.prospectIdCheckedList.length > 0) {
            this.prospectIdCheckedList.splice(this.prospectIdCheckedList.indexOf(this.selectedRow?.data?.prospectId), 1);
            this.streetIdCheckedList.splice(this.streetIdCheckedList.indexOf(this.selectedRow?.data?.address?.streetId), 1);
          }
          if (this.streetIdCheckedList && this.streetIdCheckedList.length > 0) {
            this.streetIdCheckedList = [];
          }
        }
        wrapItem.isSelected = true;
        this.selectedRow = wrapItem;
        this.selectedProspect = wrapItem.data;
        this.isDisplay = true;
        this.onSelectProspect.emit(wrapItem.data);
        this.prospectIdCheckedList.push(wrapItem.data?.prospectId);
        this.streetIdCheckedList.push(wrapItem.data?.address?.streetId);
      } else {
        wrapItem.isSelected = false;
        this.selectedRow = null;
        this.selectedProspect = null;
        this.prospectDialog.data = null;
        this.prospectDialog.initForm();
        this.prospectIdCheckedList.splice(this.prospectIdCheckedList.indexOf(wrapItem.data?.prospectId), 1);
        this.streetIdCheckedList.splice(this.streetIdCheckedList.indexOf(wrapItem.data?.address?.streetId), 1);
      }
    } else {
      if (wrapItem.isExpanded && !openFirst) {
        wrapItem.isExpanded = false;
        wrapItem.childs = [];
      } else {
        const unitSearchRequest = new ProspectSearchRequest();
        unitSearchRequest.prospectIds = wrapItem.childIds;
        this.prospectService.getClientList(unitSearchRequest).subscribe(result => {
          wrapItem.isExpanded = true;
          wrapItem.childs = WrapperUtils.convertToNewList(result.data, wrapItem.index);
          if (openFirst) {
            this.selectRow(wrapItem.childs[0]);
          }
        });
      }
    }
  }

  cancel(event: any) {
    if (event) {
      this.isDisplay = false;
    }
  }

  toggleAdvance() {
    this.showAdvance = !this.showAdvance;
  }

  onRateSelectChange() {
    // if (this.searchRequest.addressQuery === null || this.searchRequest.addressQuery === undefined || this.searchRequest.addressQuery === '' ||
    //   this.postcode === null || this.postcode === undefined || this.postcode === '') {
    //   this.displayList = false;
    // }
    // if (!this.displayList) {
    //   return;
    // }
    // @ts-ignore
    if (this.searchRequest.rate === 'undefined') {
      this.searchRequest.rate = undefined;
    }
    this.newSearch = true;
    // if (this.displayList) {
    //   this.search(1);
    // }
  }
  onMotivateSelectChange() {
    // if (this.searchRequest.addressQuery === null || this.searchRequest.addressQuery === undefined || this.searchRequest.addressQuery === '' ||
    //   this.postcode === null || this.postcode === undefined || this.postcode === '') {
    //   this.displayList = false;
    // }
    // if (!this.displayList) {
    //   return;
    // }
    // @ts-ignore
    if (this.searchRequest.motivateNumber === 'undefined') {
      this.searchRequest.motivateNumber = undefined;
    }
    this.newSearch = true;
    // if (this.displayList) {
    //   this.search(1);
    // }
  }

  onSortTypeSelectChange() {
    // if (this.searchRequest.addressQuery === null || this.searchRequest.addressQuery === undefined || this.searchRequest.addressQuery === '') {
    //   this.displayList = false;
    // }
    // if (!this.displayList) {
    //   return;
    // }
    this.newSearch = true;
    // if (this.displayList) {
    //   this.search(1);
    // }
  }

  exportMobile() {
    if (!this.displayList) {
      return;
    }
    const prospectSearchRequest = JSON.parse(JSON.stringify(this.searchRequest));
    prospectSearchRequest.limit = this.pageSize;
    if (this.prospectIdCheckedList.length > 0) {
      prospectSearchRequest.offset = 0;
    } else {
      prospectSearchRequest.offset = (this.currentPage - 1) * this.pageSize;
    }
    // prospectSearchRequest.offset = null;
    // prospectSearchRequest.limit = null;
    if (this.prospectIdCheckedList.length > 0 || this.streetIdCheckedList.length > 0) {
      prospectSearchRequest.prospectIds = this.prospectIdCheckedList;
    }
    this.prospectService.searchGroupForPhone(prospectSearchRequest).subscribe((result: RestResult) => {
      if (result.data) {
        const searchRequest = new SearchRequest();
        searchRequest.conditions = [];
        searchRequest.conditions.push({
          left: 'type',
          middle: '=',
          right: 'SMS',
        });
        this.templateService.search(searchRequest).subscribe(r => {
          this.dialogService.open(ExportDialogComponent, {
            context: {
              prospectMobile: result.data,
              templates: r.data,
              adminNumber: this.adminNumber,
              exportType: 'MOBILE',
            },
          }).onClose.subscribe(
            onCloseRes => {
              if (onCloseRes) {
                this.saveBulk(onCloseRes.log, onCloseRes.ids);
              }
            },
          );
        });
      }
    });
  }

  exportAddress() {
    if (!this.displayList) {
      return;
    }
    const prospectSearchRequest = JSON.parse(JSON.stringify(this.searchRequest));
    prospectSearchRequest.limit = this.pageSize;
    if (this.prospectIdCheckedList.length > 0 || this.streetIdCheckedList.length > 0) {
      prospectSearchRequest.offset = 0;
    } else {
      prospectSearchRequest.offset = (this.currentPage - 1) * this.pageSize;
    }
    // prospectSearchRequest.offset = null;
    // prospectSearchRequest.limit = null;
    if (this.prospectIdCheckedList.length > 0) {
      prospectSearchRequest.prospectIds = this.prospectIdCheckedList;
    }
    this.prospectService.getClientList(prospectSearchRequest).subscribe(result => {
      this.dialogService.open(ExportAddressComponent, {
        context: {
          data: result.data,
          classification: this.classificationOption,
        },
      });
    });
  }

  saveBulk(log?: string, prospectIds?: number[]) {
    if (log) {
      this.log = log;
    }
    if (this.log === null) {
      this.commonService.warning('Log must not be empty');
      return;
    }
    if (this.searchRequest.rate >= 4) {
      this.commonService.warning('You can not send bulk to Client with rate of 4 or higher');
      return;
    }
    if (prospectIds) {
      this.searchRequest.prospectIds = prospectIds;
    }
    this.searchRequest.log = this.log;
    this.prospectService.bulkSaveProspectLog(this.searchRequest).subscribe(
      result => {
        this.searchRequest.log = null;
        this.searchRequest.prospectIds = null;
        this.commonService.info('Bulk add log success');
      },
      error => {
        this.commonService.warning(error.message);
      },
    );

  }

  navigateProspect(forward: boolean) {
    // default first record
    if (!this.selectedRow) {
      if (!this.wrapItems) {
        this.commonService.warning('No available item to navigate');
      }
      this.selectRow(this.wrapItems[forward ? 0 : this.wrapItems.length - 1], true);
      return;
    }

    let index = this.selectedRow.index;
    let parentIndex = this.selectedRow.parentIndex;
    // check if child
    if (parentIndex) {
      index += forward === true ? 1 : -1;
      // if incorrect index, then jump parent instead
      if (index === -1 || index === this.wrapItems[parentIndex].childs.length) {
        index = null;
        parentIndex += forward === true ? 1 : -1;
      }
    } else {
      parentIndex = index;
      index = null;
      parentIndex += forward === true ? 1 : -1;
    }
    // let newIndex = this.formatIndex(index);
    // const jump = newIndex !== index;
    // TODO skip if called
    // if (ProspectUtils.isCalled(this.wrapItems[index].data)) {
    //   newIndex += forward === true ? 1 : -1;
    //   newIndex = this.formatIndex(newIndex);
    // }

    // if (jump) {
    //   this.loadPage(null, forward, jump);
    // } else {
    if (index != null) {
      this.selectRow(this.wrapItems[parentIndex].childs[index]);
    } else {
      if (parentIndex === -1 || parentIndex === this.wrapItems.length) {
        this.loadPage(null, forward, true);
      } else {
        if (this.wrapItems[parentIndex].isGrouped) {
          this.selectRow(this.wrapItems[parentIndex], true);
        } else {
          this.selectRow(this.wrapItems[parentIndex]);
        }
      }
    }
    // }
    // this.navigateWithParam();
  }

  formatIndex(index) {
    if (index >= this.wrapItems.length) {
      index = this.wrapItems.length - 1;
    } else if (index <= 0) {
      index = 0;
    }
    return index;
  }

  editDone(value: { result: any, next: boolean }) {
    if (StatusUtils.isSuccessResponse(value?.result)) {
      this.selectedRow.data = value.result.data;
      if (value.next) {
        this.navigateProspect(value.next);
      } else {
        this.search(this.currentPage);
        this.prospectDialog.setProspectData(value.result.data);
      }
    }
    if (value?.result && value?.result['reloadStreet'] === true) {
      this.reloadStreet.emit();
    }
  }

  openShortcut() {
    this.dialogService.open(ProspectShortcutComponent, {
      context: {
        streetId: this.searchRequest.streetId,
        districtId: this.searchRequest.districtId,
        postcode: this.searchRequest.postcode,
        filteredDistricts: this.filteredDistricts,
        filteredStreets: this.filteredStreets,
      },
    }).onClose.subscribe(res => {
      this.addDone(res);
    });
  }

  addNewProspect() {
    this.dialogService.open(ProspectCreateDialogComponent, {
      context: {
        streetId: this.searchRequest.streetId,
        districtId: this.searchRequest.districtId,
        postcode: this.searchRequest.postcode,
        filteredDistricts: this.filteredDistricts,
        filteredStreets: this.filteredStreets,
      },
    }).onClose.subscribe(res => {
      this.addDone(res);
    });
  }

  addDone(result: any) {
    if (result && StatusUtils.isSuccessResponse(result) && this.wrapItems && this.wrapItems.length > 0) {
      this.loadPage(this.currentPage);
    }
    // if (result && result['reloadStreet'] === true) {
    //   this.reloadStreet.emit();
    // }
  }

  selectPostcode(type: TypeaheadMatch) {
    this.filteredDistricts = [];
    this.filteredStreets = [];
    this.streetId = undefined;
    this.districtId = undefined;
    this.postcode = Number(type.item.code);
    this.searchSuburbByPostcode();
  }

  searchSuburbByPostcode() {
    this.filteredStreets = [];
    this.streetId = undefined;
    if (this.postcode === null) {
      return;
    }
    this.filterByPostcode = true;
    this.searchRequest.postcode = Number(this.postcode);
    this.searchRequest.districtId = undefined;
    this.searchRequest.streetId = undefined;
    if (this.postcode && this.postcode !== 0) {
      this.localService.districtByPostcode(this.postcode).subscribe(result => {
        this.filteredDistricts = result;
      });
    }
  }

  onSuburbSelectChange(event: any) {
    if (event.target.value === 'undefined') {
      this.districtId = undefined;
    } else {
      this.districtId = Number(event.target.value);
    }
    this.searchRequest.districtId = this.districtId;
    this.searchRequest.streetId = undefined;
    if (this.districtId) {
      this.localService.streets(this.districtId).subscribe(result => {
        this.filteredStreets = result;
      });
    }
  }

  onStreetSelectChange(event: any) {
    if (event.target.value === 'undefined') {
      this.streetId = undefined;
    } else {
      this.streetId = Number(event.target.value);
    }
    this.searchRequest.streetId = this.streetId;
  }

  onSearchButtonClick() {
    this.newSearch = true;
    if (this.prospectIdCheckedList.length > 0) {
      this.prospectIdCheckedList = [];
    }
    if (this.streetIdCheckedList.length > 0) {
      this.prospectIdCheckedList = [];
    }
    this.search(1);
  }

  checkAll(event: any) {
    if (event === true) {
      this.allChecked = true;
      this.wrapItems.forEach(x => {
        x.isSelected = true;
        this.prospectIdCheckedList.push(x.data.prospectId);
        this.streetIdCheckedList.push(x.data.address?.streetId);
      });
    } else {
      this.allChecked = false;
      this.wrapItems.forEach(x => {
        x.isSelected = false;
        this.prospectIdCheckedList = [];
        this.streetIdCheckedList = [];
      });
    }
  }

  onCheck(event: any, wrapperData: any) {
    // const data = this.wrapItems.find(x => {
    //   if (x.isGrouped === true) {
    //     return x.childIds.find(y => y === wrapperData?.data?.prospectId);
    //   }
    //   return x.data.prospectId === wrapperData?.data?.prospectId;
    // });
    // if (data) {
    if (event) {
      wrapperData.isSelected = true;
      if (!wrapperData.data?.prospectId && wrapperData.unitProspectIds && wrapperData.unitProspectIds.length > 0) {
        wrapperData.unitProspectIds.forEach(x => {
          this.prospectIdCheckedList.push(x);
        });
      }
      if (wrapperData.data?.prospectId) {
        this.prospectIdCheckedList.push(wrapperData?.data?.prospectId);
        this.streetIdCheckedList.push(wrapperData?.data?.address?.streetId);
      }
    } else {
      wrapperData.isSelected = false;
      this.prospectIdCheckedList.splice(this.prospectIdCheckedList.indexOf(wrapperData?.data?.prospectId), 1);
      this.streetIdCheckedList.splice(this.streetIdCheckedList.indexOf(wrapperData?.data?.address?.streetId), 1);
    }
    // }
  }

  clearAllSelected() {
    this.allChecked = false;
    this.wrapItems.forEach(x => {
      x.isSelected = false;
    });
    this.prospectIdCheckedList = [];
    this.streetIdCheckedList = [];
  }

  removeSelected() {
    if (this.prospectIdCheckedList.length <= 0
      && this.streetIdCheckedList.length <= 0) {
      return;
    }
    this.dialogService.open(ConfirmDialogComponent, {
      context: {
        content: 'Do you really want to delete this prospects?',
      },
    })
      .onClose.subscribe(res => {
      if (res === ConfirmDialogComponent.confirmOk) {
        this.prospectService.removeProspectList(this.prospectIdCheckedList)
          .subscribe(
            result => {
              this.commonService.info('Remove prospect list successfully');
              this.search(1, null, true);
            }, error => {
              this.commonService.warning(error.message);
            });
      }
    });
  }

  selectCategory(type: TypeaheadMatch) {
    this.categoryName = '';
    const selectedCategory: Category = type.item;
    if (this.selectedCategories.filter(category => category.categoryId === selectedCategory.categoryId).length > 0) {
      return;
    }
    this.selectedCategories.push(selectedCategory);
    if (!this.searchRequest.categoryIds) {
      this.searchRequest.categoryIds = [];
    }
    const index = this.searchRequest.categoryIds.indexOf(selectedCategory.categoryId);
    if (index === -1) {
      this.searchRequest.categoryIds.push(selectedCategory.categoryId);
    }
    this.search(1);
  }

  removeCategory(removedCategory: Category) {
    if (removedCategory) {
      this.selectedCategories = this.selectedCategories.filter
      (category => category.categoryId !== removedCategory.categoryId);
      if (!this.searchRequest.categoryIds) {
        this.searchRequest.categoryIds = [];
      }
      const index = this.searchRequest.categoryIds.indexOf(removedCategory.categoryId);
      if (index !== -1) {
        this.searchRequest.categoryIds.splice(index, 1);
        this.search(1);
      }
    }
  }

  searchCategory(name: string): Observable<Category[]> {
    if (!name || name === '') {
      return of([]);
    }
    const searchRequest = new SearchRequest();
    searchRequest.conditions = [];
    searchRequest.conditionType = 'AND';
    searchRequest.subConditions = [];
    searchRequest.conditions.push(new Triple('name', 'like', name));
    const subCon = new SearchRequest();
    subCon.conditionType = 'OR';
    subCon.conditions = [];
    subCon.conditions.push(new Triple('type', '=', 'PROSPECT'));
    subCon.conditions.push(new Triple('type', '=', 'BOTH'));
    searchRequest.subConditions.push(subCon);
    return this.categoryService.search(searchRequest).pipe(
      map(result => result.data),
    );
  }
}
