<div class="col-12 d-flex mt-2">
<!--  <div class="col-2">-->
<!--    <nb-select placeholder="All streets" fullWidth size="tiny" [(ngModel)]="searchRequest.streetId"-->
<!--               [disabled]="!searchRequest?.districtId && !searchRequest?.farmId">-->
<!--      <nb-option [value]="" (click)="onStreetSelectChange()">All streets</nb-option>-->
<!--      <nb-option *ngFor="let key of streets"-->
<!--                 [value]="key.streetId"-->
<!--                 (click)="onStreetSelectChange()">{{key.name}}</nb-option>-->
<!--    </nb-select>-->
<!--  </div>-->
<!--  <div class="col-2">-->
<!--    <nb-select placeholder="All farms" fullWidth size="tiny" [(ngModel)]="searchRequest.farmId"-->
<!--               [disabled]="!searchRequest?.districtId">-->
<!--      <nb-option [value]="" (click)="onFarmSelectChange()">All farms</nb-option>-->
<!--      <nb-option *ngFor="let key of farms"-->
<!--                 [value]="key.farmId"-->
<!--                 (click)="onFarmSelectChange()">{{key.name}}</nb-option>-->
<!--    </nb-select>-->
<!--  </div>-->
<!--  <div class="col-2">-->
<!--    <nb-select placeholder="All suburbs" fullWidth size="tiny" [(ngModel)]="searchRequest.districtId"-->
<!--               [disabled]="!searchRequest?.cityId && !searchRequest?.zoneId">-->
<!--      <nb-option [value]="" (click)="onSuburbSelectChange()">All suburbs</nb-option>-->
<!--      <nb-option *ngFor="let key of districts"-->
<!--                 [value]="key.districtId"-->
<!--                 (click)="onSuburbSelectChange()">{{key.name}}</nb-option>-->
<!--    </nb-select>-->
<!--  </div>-->
<!--  <div class="col-2">-->
<!--    <nb-select placeholder="All zones" fullWidth size="tiny" [(ngModel)]="searchRequest.zoneId"-->
<!--               [disabled]="!searchRequest?.cityId">-->
<!--      <nb-option [value]="" (click)="onZoneSelectChange()">All zones</nb-option>-->
<!--      <nb-option *ngFor="let key of zones"-->
<!--                 [value]="key.zoneId"-->
<!--                 (click)="onZoneSelectChange()">{{key.name}}</nb-option>-->
<!--    </nb-select>-->
<!--  </div>-->
<!--  <div class="col-2">-->
<!--    <nb-select placeholder="All cities" fullWidth size="tiny" [(ngModel)]="searchRequest.cityId">-->
<!--      <nb-option [value]="" (click)="onCitySelectChange()">All cities</nb-option>-->
<!--      <nb-option *ngFor="let key of cities"-->
<!--                 [value]="key.cityId"-->
<!--                 (click)="onCitySelectChange()">{{key.name}}</nb-option>-->
<!--    </nb-select>-->
<!--  </div>-->
<!--  <div class="col-2">-->
<!--    <button nbButton size="tiny" hero status="info" class="fa-pull-right" (click)="search()">Search</button>-->
<!--  </div>-->
  <div class="col-12">
    <div class="col-12">
      <button nbButton size="tiny" hero status="{{otmTab === true ? 'primary' : 'basic'}}" class="fa-pull-right" (click)="otm()">
        Top OTM
      </button>
      <button nbButton size="tiny" hero status="{{rentTab === true ? 'primary' : 'basic'}}" class="fa-pull-right mr-1" (click)="rent()">
        Top Rent
      </button>
    </div>
    <div class="col-12">

    </div>
  </div>
</div>
