import { ProspectRequest } from './prospect-request';
import { PropertyRequest } from './property-request';
import { VendorRequest } from './vendor-request';
import { VpaRequest } from './vpa-request';
import { ContractChecklistRequest } from '../contract-checklist-request';
import { ListingRepresentativeRequest } from './listing-representative-request';
import { MarketingRequest } from './marketing-request';

export class ListingDetailsRequest {
  prospectRequest: ProspectRequest;
  propertyRequest: PropertyRequest;
}
