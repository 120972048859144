import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {CommonService} from '../../../services/common.service';
import {NbDialogRef, NbDialogService} from '@nebular/theme';
import {RestResult} from '../../../models/response/rest-result';
import {SearchRequest} from '../../../models/request/search-request';
import {BuyerService} from '../../../services/buyer.service';
import {MultipleSelectedRequest} from '../../../models/common/multiple-selected-request';
import {Buyer} from '../../../models/response/buyer/buyer';
import {Prospect} from '../../../models/response/prospect/prospect';
import {City} from '../../../models/response/address/city';
import {Company} from '../../../models/response/prospect/company';
import {TaskType} from '../../../models/response/task-type';
import {TaskStatus} from '../../../models/response/task-status';
import {ProspectService} from '../../../services/prospect.service';
import {BuyerCreateComponent} from '../buyer-create.component';
import {ProspectDialogComponent} from '../../prospect-dialog/prospect-dialog.component';
import {CustomerRequest} from '../../../models/request/prospect/customer-request';
import {Customer} from '../../../models/response/prospect/customer';


@Component({
  selector: 'ngx-move-customers',
  templateUrl: './move-customers.component.html',
  styleUrls: ['./move-customers.component.scss'],
})

export class MoveCustomersComponent implements OnInit {
  @ViewChildren('buyerChild') buyerChildList: QueryList<BuyerCreateComponent>;
  @ViewChildren('prospectChild') prospectChildList: QueryList<ProspectDialogComponent>;
  multipleSelectedRequest: MultipleSelectedRequest;
  static SAVE_SUCCESS: string = 'SAVE_SUCCESS';

  cities: City[];
  companies: Company[];
  taskTypes: TaskType[];
  taskStatuses: TaskStatus[];
  searchRequest = new SearchRequest();
  buyers: Buyer[];
  prospects: Prospect[];


  constructor(private dialogService: NbDialogService,
              protected ref: NbDialogRef<MoveCustomersComponent>,
              private commonService: CommonService,
              private prospectService: ProspectService,
              private buyerService: BuyerService) {

  }

  ngOnInit() {
    this.loadRequest(1);
  }

  loadRequest(pageIndex) {
    if (!this.multipleSelectedRequest || !this.multipleSelectedRequest.selectedIds) {
      return;
    }
    this.searchRequest.offset = 0;
    this.searchRequest.limit = this.multipleSelectedRequest.selectedIds.length;
    this.prepareRequest();
    this.prospectService.searchDefault(this.searchRequest).subscribe((result: RestResult) => {
      this.prospects = result.data;
    });
  }

  prepareRequest() {
    // init conditions
    if (!this.searchRequest.conditions) {
      this.searchRequest.conditions = new Array();
    }
    this.searchRequest.conditions = new Array();
    this.searchRequest.conditions.push(
      {
        left: 'customerId',
        middle: 'in',
        right: this.multipleSelectedRequest.selectedIds,
        dataType: 'LONG',
      }
    );
    // init orders
    this.searchRequest.orders = new Array();
    this.searchRequest.orders.push(
      {
        left: 'createdDate',
        right: 'desc'
      }
    );
  }

  cancel() {
    this.ref.close();
  }

  save() {
    if (this.prospects) {
      this.prospectChildList.forEach(item => {
        item.submit();
      });
    }
    this.ref.close(MoveCustomersComponent.SAVE_SUCCESS);
  }

  // navigateProspect(value: { currentBuyerId: number, forward: boolean }) {
  // }

  editDone(result: any) {
  }

  copyOtherData(request: any, entity: any) {
    entity.call = request.call;
    entity.sms = request.sms;
    entity.door = request.door;
    entity.mail = request.mail;
  }

  /**
   * move other to selected id
   */
  moveOtherToThis(selectedId: number, customerId: number, type: string) {
    let prospectRequest;
    let customerRequest;
    this.prospectChildList.forEach(item => {
      if (item.prospectId !== selectedId) {
        prospectRequest = item.getRequest();
        customerRequest = prospectRequest.customer;
      }
    });
    if (this.prospects) {
      this.prospectChildList.forEach(item => {
        // get prospect appropriate with child component
        const prospect = this.prospects.find(x => x.prospectId === item.prospectId);
        if (item.prospectId === selectedId) {
          Customer.copyFromRequest(customerRequest, prospect.customer);
          this.copyOtherData(prospectRequest, prospect);
          item.setProspect(prospect);
        } else {
          Customer.copyFromRequest(CustomerRequest.getDummy(), prospect.customer);
          this.copyOtherData({
            call: null,
            sms: null,
            door: null,
            mail: null
          }, prospect);
          item.setProspect(prospect);
        }
      });
    }
  }
}
