<ngx-buyer-list-filter
  *ngIf='isFilter && ComponentPermission.isAccess(ComponentPermission.CUSTOMER_SEARCH)'
  [streets]='filteredStreets'
  [districts]='filteredDistricts'
  [zones]='filteredZones'
  [cities]='cities'
  [postcodes]='filteredPostcodes'
  [total]='groupWrapper?.totalItems'
  [selectedCityId]='searchRequest?.cityId'
  [selectedZoneId]='searchRequest?.zoneId'
  [postcode]='searchRequest?.postcode'
  [selectedDistrictId]='searchRequest?.districtId'
  [selectedStreetId]='searchRequest?.streetId'
  [isBuyerMode]='searchRequest?.isBuyerMode'
  [isTenantMode]='searchRequest?.isTenantMode'
  [selectedStatus]='searchRequest?.isActive'
  [selectedPropertyType]='searchRequest?.propertyType'
  [selectedBed]='searchRequest?.bed'
  [selectedBath]='searchRequest?.bath'
  [selectedCar]='searchRequest?.car'
  [fromMinBuyPrice]='searchRequest?.fromMinBuyPrice'
  [toMaxBuyPrice]='searchRequest?.toMaxBuyPrice'
  [phone]='searchRequest?.phone'
  [code]='searchRequest?.code'
  [rate]='searchRequest?.rate'
  [selectedYear]='selectedYear'
  [selectedQuarter]='selectedQuarter'
  [selectedMonth]='selectedMonth'
  [selectedWeek]='selectedWeek'
  [selectedDay]='selectedDay'
  [isSelected]='isSelected'
  (onYearChange)='onYearChange($event)'
  (onMonthChange)='onMonthChange($event)'
  (onQuarterChange)='onQuarterChange($event)'
  (onWeekChange)='onWeekChange($event)'
  (onDayChange)='onDayChange($event)'
  (onCityChange)='onCityChange($event)'
  (onSuburbChange)='onSuburbChange($event)'
  (onStreetChange)='onStreetChange($event)'
  (onZoneChange)='onZoneChange($event)'
  (onPostcodeChange)='onPostcodeChange($event)'
  (onPhoneChange)='onPhoneChange($event)'
  (onRateChange)='onRateChange($event)'
  (onCodeChange)='onCodeChange($event)'
  (onSelectCategory)='onSelectCategory($event)'
  (onRemoveCategory)='onRemoveCategory($event)'
  (onSelectUser)='onSelectUser($event)'
  (onRemoveUser)='onRemoveUser($event)'
  (onExportEmail)='exportEmail()'
  (onExportMobile)='exportMobile()'
  (onClearSelected)='clearSelected()'
  (onFromMinBuyPriceChange)='onFromMinBuyPriceChange($event)'
  (onToMaxBuyPriceChange)='onToMaxBuyPriceChange($event)'
  (onSearch)='onSearchButtonClick()'
  (onBuyerMode)='onBuyerModeChange($event)'
  (onTenantMode)='onTenantModeChange($event)'
  (onStatusChange)='onStatusChange($event)'
  (onPropertyTypeChange)='onPropertyTypeChange($event)'
  (onBedChange)='onBedChange($event)'
  (onBathChange)='onBathChange($event)'
  (onCarChange)='onCarChange($event)'
  (saveBulk)='saveBulk($event)'
  (onSearchByPostcode)='onSearchByPostcode($event)'
  [hidden]='oneOnOneCall'
></ngx-buyer-list-filter>
<div class='row mt-2' *appPermissionCheck='ComponentPermission.CUSTOMER_MERGE'>
  <div class='col-12'>
    <button nbButton size='tiny' hero status='primary' class='mr-2'
            (click)='clearSelected()'
            [hidden]='!multipleSelectedRequest.selectedIds || multipleSelectedRequest.selectedIds.length <= 0'>Clear
      Selected
    </button>
    <button nbButton size='tiny' hero status='danger' class='mr-2'
            [hidden]='!multipleSelectedRequest.selectedIds || multipleSelectedRequest.selectedIds.length <= 1'
            (click)='openMergePopup()'>
      Merge selected
    </button>
    <button nbButton size='tiny' hero status='danger'
            [hidden]='!multipleSelectedRequest.selectedIds || multipleSelectedRequest.selectedIds.length < 1'
            (click)='deleteSelected()'>
      Delete selected
    </button>
  </div>
</div>
<div class='col-12 d-flex mb-2 mt-1' *ngIf='!isFilter'>
  <div class='col-4'>
    {{groupWrapper?.totalItems}}
  </div>
  <div class='col-8'>
    <button nbButton size='tiny' hero status='primary' class='fa-pull-right mr-1' (click)='exportEmail()'>
      <i class='fa fa-download mr-1'></i> Email
    </button>
    <button nbButton size='tiny' hero status='primary' class='fa-pull-right mr-1' (click)='exportMobile()'>
      <i class='fa fa-download mr-1'></i> Mobile
    </button>
  </div>
</div>
<!--<div class = 'row d-flex mt-2' *appPermissionCheck='ComponentPermission.CUSTOMER_MERGE'>-->
<!--  <div class = 'col-4'>-->
<!--    <button nbButton size="tiny" hero status="primary" class="fa-pull-left w-100" (click)="exportMobile()"-->
<!--            [hidden]="!multipleSelectedRequest.selectedIds || multipleSelectedRequest.selectedIds.length <= 0">Export Selected Phone</button>-->
<!--  </div>-->
<!--  <div class = 'col-4'>-->
<!--    <button nbButton size="tiny" hero status="primary" class="fa-pull-left w-100" (click)="exportEmail()"-->
<!--    [hidden]="!multipleSelectedRequest.selectedIds || multipleSelectedRequest.selectedIds.length <= 0">Export Selected Email</button>-->
<!--  </div>-->
<!--</div>-->
<!--<div class='row col-2' *appPermissionCheck='ComponentPermission.CUSTOMER_MERGE'>-->
<!--  <button nbButton size='tiny' hero status='primary' class='fa-pull-left w-100' (click)='clearSelected()'-->
<!--          [hidden]='!multipleSelectedRequest.selectedIds || multipleSelectedRequest.selectedIds.length <= 0'>Clear-->
<!--    Selected-->
<!--  </button>-->
<!--</div>-->
<div class='row'>
  <div class='col-12'>
    <ng2-smart-table [settings]='settings'
                     [source]='source'
                     (userRowSelect)='selectRecord($event)'
    >
    </ng2-smart-table>
  </div>
</div>
<div class='row daily'>
  <div class='daily-pc'>
    <div class='set-height d-flex'>
      <div class='ml-3'>
        <button nbButton status='primary' size='tiny' class='sticky-button'
                [nbPopover]='templateRef'
                (click)='getDailyStatistic()'>Daily
        </button>
      </div>
      <ng-template #templateRef>
        <p class='mb-0'>BN: {{dailyBuyerStatistic?.totalBN}}</p>
        <p class='mb-0'>BC: {{dailyBuyerStatistic?.totalBC}}</p>
      </ng-template>
    </div>
  </div>
</div>
