import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {ViewCell} from 'ng2-smart-table';
import {WrapBuyer} from './buyer-utils';
import {CustomRow} from '../../../../shared/models/common/custom-row';

@Component({
  template: `
    <nb-checkbox [(ngModel)]="rowData.selected" (click)="handleClick($event)"
                 (ngModelChange)="handleSelectChange()"></nb-checkbox>
  `,
  styles: [],
})
export class SmartTableCustomSelectComponent implements ViewCell, OnInit {

  @Input() value: string | number;
  @Input() rowData: CustomRow;
  onSelectRow = new EventEmitter<any>();

  ngOnInit() {
  }

  handleSelectChange() {
    if (this.onSelectRow) {
      this.onSelectRow.emit(this.rowData);
    }
  }

  handleClick($event) {
    $event.stopPropagation();
  }
}
