<nb-card *appPermissionCheck='ComponentPermission.DASHBOARD_DATA_REPORT'>
  <nb-card-header>
    Data Report
  </nb-card-header>
  <nb-card-body>
    <div class='d-flex'>
      <div class='col-6 w-50'>
        <nb-accordion *ngFor='let wrapper of data'>
          <nb-accordion-item>
            <nb-accordion-item-header class='city mb-1'>
              Postcode: {{wrapper.postCode? wrapper.postCode : null}} - Total: {{wrapper.total}}
            </nb-accordion-item-header>
            <nb-accordion-item-body>
              <table>
                <tr class='suburb'>
                  <td class='name-column'>Suburb</td>
                  <td>Total</td>
                  <td>Phone</td>
                  <td>MPhone</td>
                  <td class='color'>Color</td>
                  <td class='name-column'>Import Last Update</td>
                </tr>
                <tr *ngFor='let response of wrapper.responses'>
                  <td>{{response.districtName}}</td>
                  <td class='load-prospect' (click)='listImportedProspect(response.districtId)'>{{response.total}}</td>
                  <td>{{response.totalPhone}}</td>
                  <td>{{response.totalMobilePhone}}</td>
                  <td [ngStyle]="{'background-color': response.color}"></td>
                  <td></td>
                </tr>
              </table>
            </nb-accordion-item-body>
          </nb-accordion-item>
        </nb-accordion>
      </div>
      <div class='col-6 ml-3'>
        <div class='mb-1'>
          <input [nbDatepicker]='datepickerFrom' nbInput fieldSize='tiny' [(ngModel)]='dateFrom'>
          <nb-datepicker #datepickerFrom></nb-datepicker>
          -
          <input [nbDatepicker]='datepickerTo' nbInput fieldSize='tiny' [(ngModel)]='dateTo'>
          <nb-datepicker #datepickerTo></nb-datepicker>
          <button nbButton size='small' status='primary' class='ml-2' (click)='onSearch()'>Search</button>
        </div>
        <nb-accordion>
          <nb-accordion-item *ngFor='let wrapper of marketData'>
            <nb-accordion-item-header class='city mb-1'>{{wrapper.postCode ? wrapper.postCode : "null"}}</nb-accordion-item-header>
            <nb-accordion-item-body>
              <table>
                <tr class='suburb'>
                  <td class='name-column'>Suburb</td>
                  <td class='name-column'>Just Listed</td>
                  <td class='name-column'>Just Sold</td>
                  <td class='name-column'>VAC</td>
                </tr>
                <tr *ngFor='let response of wrapper.responses'>
                  <td>{{response.districtName}}</td>
                  <td class='load-prospect'
                      (click)='listMarket("BUY", response.districtId)'>{{response?.buy ? response?.buy : 0 }}</td>
                  <td class='load-prospect'
                      (click)='listMarket("SOLD", response.districtId)'>{{response?.sold ? response?.sold : 0 }}</td>
                  <td class='load-prospect'
                      (click)='listMarket("RENT", response.districtId)'>{{response?.rent ? response?.rent : 0 }}</td>
                </tr>
              </table>
            </nb-accordion-item-body>
          </nb-accordion-item>
        </nb-accordion>
        <div *ngIf='!marketData || marketData.length === 0'>
          No Data Imported !
        </div>
      </div>
    </div>
  </nb-card-body>
</nb-card>

<div class='fake-tab content-active' [hidden]='!showProspectList'>
  <ngx-prospect-list-basic
    [isFilter]=false
    [isLoad]=false
    [districts]='districts'
    [cities]='cities'
    [streets]='streets'
    [companies]='companies'
    [taskTypes]='taskTypes'
    (reloadStreet)='loadStreets()'
  ></ngx-prospect-list-basic>
</div>
