import {Component, Input, OnInit} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import {ToastService} from '../../../../../shared/services/toast.service';
import {TaskService} from '../../../../../shared/services/task.service';
import {TaskRequest} from '../../../../../shared/models/request/task-request';

@Component({
  selector: 'ngx-task-list-dialog',
  templateUrl: './task-list.dialog.component.html',
  styleUrls: ['./task-list.dialog.component.scss'],
})
export class TaskListDialogComponent implements OnInit {
  name: string;
  username: string;
  email: string;
  phone: string;
  isUpdate: boolean;

  @Input()
  data: any;

  constructor(protected ref: NbDialogRef<TaskListDialogComponent>,
              private taskService: TaskService,
              private toastService: ToastService) {
  }

  ngOnInit(): void {
    if (this.data) {
      this.isUpdate = true;
      this.name = this.data.name;
      this.username = this.data.username;
      this.phone = this.data.phone;
      this.email = this.data.email;
    }
  }

  submit() {
    const taskRequest = new TaskRequest();


    if (this.isUpdate) {
      taskRequest.taskId = this.data.taskId;
      this.taskService.update(taskRequest).subscribe(response => {
        this.ref.close(response);
      }, error => {
        this.toastService.error(error.messages.join(','));
      });
    } else {
      this.taskService.create(taskRequest).subscribe(response => {
        this.ref.close(response);
      }, error => {
        this.toastService.error(error.messages.join(','));
      });
    }
  }

  cancel() {
    this.ref.close();
  }
}
