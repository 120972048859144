import { ProspectStatisticRequest } from './prospect-statistic-request';

export class ProspectSearchRequest {
  streetId?: number;
  districtId?: number;
  cityId?: number;
  farmId?: number;
  zoneId?: number;
  number?: string;
  numberFrom?: string;
  numberTo?: string;
  numberFromLetter?: string;
  numberToLetter?: string;
  userId?: number;
  sortType?: string;
  name?: string;
  surname?: string;
  phone?: string;
  email?: string;
  rate?: number;
  rateCondition?: number;
  isCaMode?: boolean;
  isDkMode?: boolean;
  isBuyerMode?: boolean;
  isTenantMode?: boolean;
  isOpenStreetMarket?: boolean;
  streetIds?: number[];
  mode?: string;
  otm?: boolean;
  vac?: boolean;
  sell?: boolean;
  rent?: boolean;
  bed?: number;

  bath?: number;
  car?: number;
  rentCompanyId?: number;
  sellCompanyId?: number;
  fromSellPrice?: number;
  toSellPrice?: number;
  fromRentPrice?: number;
  toRentPrice?: number;
  fromOtmDate?: number;
  toOtmDate?: number;
  fromVacDate?: number;
  toVacDate?: number;
  hasPurchaseDate?: boolean;
  hasCallbackDate?: boolean;
  hasDob?: boolean;
  fromCallbackDate?: number;
  toCallbackDate?: number;
  purchaseDateDay?: number;
  purchaseDateMonth?: number;
  purchaseDateYear?: number;
  dobDay?: number;
  dobMonth?: number;
  log: string;
  isAutoImport: boolean;
  dateFrom?: number;
  dateTo?: number;
  importType?: string;
  postcode?: number;
  // support client board filter


  hasEmail?: boolean;
  hasPhone?: boolean;
  hasMobilePhone?: boolean;
  motivate?: number;
  relationship?: number;
  type?: string;
  motivateType?: string;
  religion?: string;
  nationality?: string;
  age?: number;
  ageIsOver?: boolean;
  fromPurchaseDate?: number;
  toPurchaseDate?: number;
  // === offset, limit of group

  offset?: number;
  limit?: number;
  loadOtmExtra?: boolean;

  otmStatus?: string;
  vacStatus?: string;
  disableItemCountEachGroup?: boolean;

  // === for statistic

  statisticRequest?: ProspectStatisticRequest;
  categoryIds: number[];
  multipleUser: number[];

  // === filter by office
  officeId: number;

  //  support for open street
  prospectId?: number;

  //  support for bulk save
  prospectIds?: number[];

  //  support for open home
  isOpenHome?: boolean;

  //  support for rental
  isRental?: boolean;

  //  support for visual board
  isO4Board?: boolean;
  isS4Board?: boolean;
  isR4Board?: boolean;

  //  support for client list v2
  addressQuery?: string;

  countUnits?: boolean;
  fromLand?: number;
  toLand?: number;
  motivateNumber?: number;
  purchaseDate?: number;
}
