import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Path} from '../common/path';
import {RestResult} from '../models/response/rest-result';
import {CommonService} from './common.service';
import {SearchRequest} from '../models/request/search-request';
import {InterestDistrictRequest} from '../models/request/buyer/interest-district-request';
import {InterestDistrict} from '../models/response/buyer/interest-district';

@Injectable()
export class InterestDistrictService {

  constructor(protected http: HttpClient,
              protected commonService: CommonService) {
  }

  getOne(interestDistrictId: number): Observable<RestResult> {
    return this.http.get<any>(
      Path.API_ENDPOINT +
      Path.INTEREST_DISTRICT +
      Path.INTEREST_DISTRICT_ID.replace(':interestDistrictId', interestDistrictId.toString()),
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }


  search(request: SearchRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.INTEREST_DISTRICT +
      Path.SEARCH, request,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  create(request: InterestDistrictRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.INTEREST_DISTRICT, request).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  remove(interestDistrictId: number): Observable<RestResult> {
    return this.http.delete<any>(
      Path.API_ENDPOINT +
      Path.INTEREST_DISTRICT +
      Path.INTEREST_DISTRICT_ID.replace(':interestDistrictId', interestDistrictId.toString()),
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  update(request: InterestDistrictRequest): Observable<RestResult> {
    return this.http.put<any>(
      Path.API_ENDPOINT +
      Path.INTEREST_DISTRICT, request).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  createOrUpdate(buyerId: number, requests: InterestDistrict[]): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.INTEREST_DISTRICT +
      Path.MULTIPLE +
      Path.BUYER_ID.replace(':buyerId', buyerId.toString()), requests).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }
}
