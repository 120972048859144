import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { City } from '../../../../../shared/models/response/address/city';
import { District } from '../../../../../shared/models/response/address/district';
import { Street } from '../../../../../shared/models/response/address/street';
import { ClientBoardSearchRequest } from '../../../../../shared/models/request/prospect/client-board-search-request';
import { Farm } from '../../../../../shared/models/response/address/farm';
import { Zone } from '../../../../../shared/models/response/address/zone';
import { NbDatepickerComponent } from '@nebular/theme';
import { Observable, of } from 'rxjs';
import { Category } from '../../../../../shared/models/response/category';
import { CategoryService } from '../../../../../shared/services/category.service';
import { map, mergeMap } from 'rxjs/operators';
import { SearchRequest } from '../../../../../shared/models/request/search-request';
import { Triple } from '../../../../../shared/models/request/triple';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { User } from '../../../../../shared/models/response/user';
import { StringUtils } from '../../../../../shared/common/string-utils';
import { UserService } from '../../../../../shared/services/user.service';
import { Constant } from '../../../../../shared/common/constant';
import { AuthService } from '../../../../../shared/services/auth.service';
import { UserUtils } from '../../../../../shared/common/user-utils';
import { DateUtils } from '../../../../../shared/common/date-utils';
import { CommonService } from '../../../../../shared/services/common.service';
import { ComponentPermission } from '../../../../../component.permission';
import { ProspectSearchRequest } from '../../../../../shared/models/request/prospect/prospect-search-request';
import { ActivatedRoute } from '@angular/router';
import { Postcode } from '../../../../../shared/models/response/postcode';
import { PostcodeService } from '../../../../../shared/services/postcode.service';

@Component({
  selector: 'ngx-client-board-filter-advance',
  templateUrl: './client-board-filter-advance.component.html',
  styleUrls: ['./client-board-filter-advance.component.scss'],
})
export class ClientBoardFilterAdvanceComponent implements OnInit {
  @Input() districts: District[];
  @Input() streets: Street[];
  @Input() cities: City[];
  @Input() farms: Farm[];
  @Input() zones: Zone[];
  @Input() postcodes: string[];
  @Output() onSuburbChange = new EventEmitter();
  @Output() onCityChange = new EventEmitter();
  @Output() onZoneChange = new EventEmitter();
  @Output() onFarmChange = new EventEmitter();
  @Output() onStreetChange = new EventEmitter();
  @Output() onSearch = new EventEmitter();
  @Output() onSelectCategory = new EventEmitter();
  @Output() onRemoveCategory = new EventEmitter();
  @Output() onSelectUser = new EventEmitter();
  @Output() onRemoveUser = new EventEmitter();
  @Output() onFromPurchaseDateChange = new EventEmitter();
  @Output() onToPurchaseDateChange = new EventEmitter();
  @Output() onMotivateTypeChange = new EventEmitter();
  @Output() onReligionChange = new EventEmitter();
  @Output() onNationalityChange = new EventEmitter();
  @Output() onAgeChange = new EventEmitter();
  @Output() onPostcodeChange = new EventEmitter();
  filterCategory$: Observable<Category[]>;
  categoryName: string;
  selectedCategories: Category[] = [];
  filterUser$: Observable<User[]>;
  userName: string;
  selectedUser: User;
  stringUtils = StringUtils;
  customerMotivate = ['F+', 'F-', 'LS+', 'LS-'];
  @Input() fromPurchaseDate: any = null;
  @Input() toPurchaseDate: any = null;
  @Input() motivateType: string;
  userUtils = UserUtils;
  currentUser: User;
  @Input() religion: string;
  @Input() nationality: string;
  @Input() age: number = null;
  @Input() ageIsOver = true;
  ComponentPermission = ComponentPermission;
  prospectTagButtons = [
    {
      key: 'T10-S3',
      value: 1,
      selected: false,
    },
    {
      key: '1M',
      value: 5,
      selected: false,
    },
    {
      key: '3M',
      value: 6,
      selected: false,

    },
    {
      key: '6M',
      value: 7,
      selected: false,
    },
  ];

  @Input() searchRequest: ProspectSearchRequest;
  @Input() selectedCategoryIds: number[] = [];
  @Input() postcode: number;
  @Output() onSearchByPostcode = new EventEmitter();
  filterByPostcode = false;
  filterPostcode$: Observable<Postcode[]>;

  constructor(private categoryService: CategoryService,
              private authService: AuthService,
              private userService: UserService,
              private commonService: CommonService,
              private postcodeService: PostcodeService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.currentUser = this.authService.currentUser;
    this.initAutoComplete();
    if (this.selectedCategoryIds && this.selectedCategoryIds.length > 0) {
      this.appendCategoryFromParameter();
    }
    const userId = this.route.snapshot.queryParams['userId'];
    if (userId) {
      this.userService.getOne(userId).subscribe(result => {
        this.selectedUser = result.data;
      });
    }
    if (this.route.snapshot.queryParams['postcode']) {
      this.filterByPostcode = true;
    }
  }

  initAutoComplete() {
    if (!this.userUtils.userIsAdmin(this.currentUser)) {
      this.selectedUser = this.currentUser;
    } else {
      this.selectedUser = undefined;
    }
    this.filterCategory$ = new Observable((observer: any) => {
      observer.next(this.categoryName);
    }).pipe(
      mergeMap((query: string) => this.searchCategory(query)),
    );

    this.filterUser$ = new Observable((observer: any) => {
      observer.next(this.userName);
    }).pipe(
      mergeMap((query: string) => this.searchUser(query)),
    );
    this.filterPostcode$ = new Observable((observer: any) => {
      observer.next(this.postcode);
    }).pipe(
      map((query: string) => {
        this.postcode = Number(query);
        this.searchSuburbByPostcode();
        return null;
      }),
    );

    // if (localStorage.getItem(Constant.CLIENT_CATEGORY_LIST)) {
    //   this.selectedCategories = JSON.parse(localStorage.getItem(Constant.CLIENT_CATEGORY_LIST));
    //   this.selectedCategories.forEach(category => {
    //     this.prospectTagButtons.find(tag => {
    //       if (tag.value === category.categoryId) {
    //         tag.selected = true;
    //       }
    //     });
    //   });
    // }
  }

  get RELIGIONS() {
    return Constant.RELIGIONS;
  }

  get COUNTRIES() {
    return Constant.COUNTRIES;
  }

  onSuburbSelectChange() {
    this.onSuburbChange.emit(this.searchRequest.districtId);
  }

  onCitySelectChange() {
    this.onCityChange.emit(this.searchRequest.cityId);
  }

  onFarmSelectChange() {
    this.onFarmChange.emit(this.searchRequest.farmId);
  }

  onZoneSelectChange() {
    this.onZoneChange.emit(this.searchRequest.zoneId);
  }

  onStreetSelectChange() {
    this.onStreetChange.emit(this.searchRequest.streetId);
  }

  onPostcodeSelectChange() {
    this.onPostcodeChange.emit(this.searchRequest.postcode);
  }

  search() {
    if (this.age !== null && this.ageIsOver !== null) {
      this.onAgeChange.emit({ age: this.age, ageIsOver: this.ageIsOver });
    }
    if ((this.fromPurchaseDate !== null && this.toPurchaseDate === null)
      || (this.fromPurchaseDate === null && this.toPurchaseDate !== null)) {
      this.commonService.warning('Must have both From and To Purchase Date');
    } else if (this.age !== null && (this.age < 1 || this.age > 150)) {
      this.commonService.warning('Please enter a valid Age');
    } else {
      this.onSearch.emit();
    }
  }

  searchCategory(name: string): Observable<Category[]> {
    if (!name || name === '') {
      return of([]);
    }
    const searchRequest = new SearchRequest();
    searchRequest.conditions = new Array();
    searchRequest.conditionType = 'AND';
    searchRequest.subConditions = [];
    searchRequest.conditions.push(new Triple('name', 'like', name));
    const subCon = new SearchRequest();
    subCon.conditionType = 'OR';
    subCon.conditions = [];
    subCon.conditions.push(new Triple('type', '=', 'PROSPECT'));
    subCon.conditions.push(new Triple('type', '=', 'BOTH'));
    searchRequest.subConditions.push(subCon);
    return this.categoryService.search(searchRequest).pipe(
      map(result => result.data),
    );
  }

  selectCategory(type: TypeaheadMatch) {
    this.categoryName = '';
    const selectedCategory: Category = type.item;
    if (this.selectedCategories.filter(category => category.categoryId === selectedCategory.categoryId).length > 0) {
      return;
    }
    this.selectedCategories.push(selectedCategory);
    localStorage.setItem(Constant.CLIENT_CATEGORY_LIST, JSON.stringify(this.selectedCategories));
    this.onSelectCategory.emit(selectedCategory.categoryId);
  }

  removeCategory(removedCategory: Category) {
    if (removedCategory) {
      this.prospectTagButtons.find(tag => {
        if (tag.value === removedCategory.categoryId) {
          tag.selected = false;
        }
      });
      this.selectedCategories = this.selectedCategories.filter
      (category => category.categoryId !== removedCategory.categoryId);
      localStorage.setItem(Constant.CLIENT_CATEGORY_LIST, JSON.stringify(this.selectedCategories));
      this.onRemoveCategory.emit(removedCategory.categoryId);
    }
  }

  searchUser(keyword: string): Observable<User[]> {
    // this.refreshUserFilter();
    const searchRequest = new SearchRequest();
    searchRequest.conditions = new Array();
    searchRequest.conditions.push(
      {
        left: 'email',
        middle: 'like',
        right: keyword,
      },
    );
    searchRequest.conditions.push(
      {
        left: 'username',
        middle: 'like',
        right: keyword,
      },
    );
    searchRequest.conditions.push(
      {
        left: 'name',
        middle: 'like',
        right: keyword,
      },
    );
    searchRequest.conditionType = Constant.CONDITION_TYPE.OR;
    searchRequest.offset = 0;
    searchRequest.limit = 10;
    return this.userService.search(searchRequest).pipe(
      map(result => result.data.filter(user => user.status === 'ACTIVE')),
    );
  }

  selectUser(type: TypeaheadMatch) {
    this.userName = '';
    this.selectedUser = type.item;
    this.onSelectUser.emit(this.selectedUser.userId);
  }

  selectSelf() {
    this.selectedUser = this.currentUser;
    this.onSelectUser.emit(this.selectedUser.userId);
  }

  removeUser() {
    this.onRemoveUser.emit(this.selectedUser.userId);
    this.selectedUser = null;
  }

  triggerDatePicker(datePickerComponent: NbDatepickerComponent<any>) {
    datePickerComponent?.show();
  }

  onFromPurchaseDateSelectChange() {
    this.onFromPurchaseDateChange.emit(this.fromPurchaseDate);
  }

  onToPurchaseDateSelectChange() {
    this.onToPurchaseDateChange.emit(this.toPurchaseDate);
  }

  onMotiveSelectChange() {
    this.searchRequest.motivateType = this.motivateType;
    this.onMotivateTypeChange.emit(this.motivateType);
  }

  onReligionSelectChange() {
    this.searchRequest.religion = this.religion;
    this.onReligionChange.emit(this.religion);
  }

  onNationalitySelectChange() {
    this.searchRequest.nationality = this.nationality;
    this.onNationalityChange.emit(this.nationality);
  }

  selectProspectTag(categoryId: number) {
    this.prospectTagButtons.find(tag => {
      if (tag.value === categoryId) {
        tag.selected = true;
      }
    });
    this.categoryService.getOne(categoryId).subscribe(result => {
      this.selectedCategories.push(result.data);
      this.onSelectCategory.emit(categoryId);
      // localStorage.setItem(Constant.CLIENT_CATEGORY_LIST, JSON.stringify(this.selectedCategories));
    });
  }

  appendCategoryFromParameter() {
    this.selectedCategoryIds.forEach((c: number) => {
      this.selectProspectTag(c);
    });
  }

  searchPostcode(keyword: string): Observable<Postcode[]> {
    const searchRequest = new SearchRequest();
    searchRequest.conditions = [];
    searchRequest.orders = [];
    searchRequest.conditions.push(
      {
        left: 'code',
        middle: '=',
        right: Number(keyword),
      },
    );
    searchRequest.orders.push(
      {
        left: 'code',
        right: 'asc'
      }
    );
    searchRequest.offset = 0;
    searchRequest.limit = 10;
    return this.postcodeService.search(searchRequest).pipe(
      map(result => result.data),
    );
  }

  selectPostcode(type: TypeaheadMatch) {
    this.postcode = Number(type.item.code);
    this.searchSuburbByPostcode();
  }

  searchSuburbByPostcode() {
    if (this.postcode === null) {
      return;
    }
    this.filterByPostcode = true;
    this.onSearchByPostcode.emit(this.postcode);
  }
}
