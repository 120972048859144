import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {CommonService} from '../../../services/common.service';
import {NbDialogRef, NbDialogService} from '@nebular/theme';
import {City} from '../../../models/response/address/city';
import {RestResult} from '../../../models/response/rest-result';
import {SearchRequest} from '../../../models/request/search-request';
import {LocalDataSource} from 'ng2-smart-table';
import {Zone} from '../../../models/response/address/zone';
import {CheckboxCustomCellComponent} from '../../../../modules/pages/address/childs/checkbox-custom-cell-component';
import {
  NestPropertyCustomCellComponent
} from '../../../../modules/pages/address/childs/nest-property-custom-cell-component';
import {SuburbService} from '../../../services/suburb.service';
import {ZoneService} from '../../../services/zone.service';
import {CityService} from '../../../services/city.service';
import {Router} from '@angular/router';
import {ComponentPermission} from '../../../../component.permission';
import {ConfirmDialogComponent} from '../../../../modules/pages/confirm-dialog/confirm-dialog.component';
import {DistrictCreateComponent} from '../../../../modules/pages/address/suburb/create/district-create.component';
import {DistrictUpdateComponent} from '../../../../modules/pages/address/suburb/update/district-update.component';
import {Constant} from '../../../common/constant';


@Component({
  selector: 'ngx-district-pick',
  templateUrl: './district-pick.component.html',
  styleUrls: ['./district-pick.component.scss'],
})

export class DistrictPickComponent implements OnInit {
  cityId = null;
  zoneId = null;
  selectedZoneIds: number[];
  searchRequest = new SearchRequest();
  source: LocalDataSource = new LocalDataSource();
  change: any;
  pageSize = Constant.PAGE_SIZE;
  cities: City[];
  filteredZones: Zone[];
  zones: Zone[];

  defaultColumns = {
    districtId: {
      title: 'ID',
      type: 'number',
      filter: false,
    },
    name: {
      title: 'Name',
      type: 'string',
      filter: false,
    },
    code: {
      title: 'code',
      type: 'string',
      filter: false,
    },
    city: this.createColumn('City', ['city.name'], false),
    zone: this.createColumn('Zone', ['zone.name'], false),
  };

  settings = {
    mode: 'external',
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    pager: {
      display: true,
      perPage: this.pageSize,
    },
    columns: this.defaultColumns,
  };

  isGroupMode = false;
  checkBoxColumn = {
    type: 'custom',
    filter: false,
    width: '10px',
    renderComponent: CheckboxCustomCellComponent,
  };

  filter: {
    name: string,
    code: string,
    city: string,
    zone: string,
  } = {
    name: '',
    code: '',
    city: '',
    zone: '',
  };

  createColumn(title: string, propertyPaths: string[],
               filter: boolean, valueType?: string,
               separator?: string) {
    return {
      title: title,
      type: 'custom',
      filter: filter,
      renderComponent: NestPropertyCustomCellComponent,
      onComponentInitFunction: (instance: NestPropertyCustomCellComponent) => {
        instance.config = {
          propertyPaths: propertyPaths,
          valueType: valueType,
          separator,
        };
      },
    };
  }

  constructor(protected ref: NbDialogRef<DistrictPickComponent>,
              private dialogService: NbDialogService,
              private commonService: CommonService,
              private suburbService: SuburbService,
              private zoneService: ZoneService,
              private cityService: CityService,
              private router: Router,
              private cd: ChangeDetectorRef) {

  }

  ngOnInit() {
    this.loadParent();
    this.source.onChanged().subscribe((change) => {
      this.change = change;
      if (change.action === 'refresh') {
        this.loadRequest(1);
        // this.pageChange(change.paging.page);
      }
      if (change.action === 'page') {
        // this.source.load(new Array());
        this.loadRequest(change.paging.page);
      }
      if (change.action === 'sort') {
        this.source.load(new Array());
        this.loadRequest(1);
      }
      if (change.action === 'filter') {
        // this.source.load(new Array());
        this.loadRequest(1);
      }
    });
  }

  get CheckPermission() {
    return ComponentPermission;
  }

  search() {
    this.loadRequest(1);
  }

  loadParent() {
    const searchRequest = new SearchRequest();
    searchRequest.offset = 0;
    searchRequest.limit = 100;
    searchRequest.conditionType = 'AND';
    searchRequest.orders = new Array();
    searchRequest.orders = [{
      left: 'cityId',
      right: 'asc',
    }];

    this.cityService.search(searchRequest).subscribe((result: RestResult) => {
      this.cities = result.data;
    });

    searchRequest.orders = [{
      left: 'zoneId',
      right: 'asc',
    }];

    this.zoneService.search(searchRequest).subscribe((result: RestResult) => {
      this.zones = result.data;
    });
  }

  loadRequest(pageIndex) {
    this.prepareRequest();
    this.suburbService.search(this.searchRequest).subscribe((result: RestResult) => {
      this.source.load(result.data);
    });
  }

  prepareRequest() {
    this.searchRequest = new SearchRequest();
    // init conditions
    if (!this.searchRequest.conditions) {
      this.searchRequest.conditions = new Array();
    }
    if (this.cityId && (!this.selectedZoneIds || this.selectedZoneIds.length === 0)) {
      this.searchRequest.conditions.push({
        left: 'cityId',
        middle: '=',
        right: this.cityId,
      });
    }
    if (this.selectedZoneIds && this.selectedZoneIds.length > 0) {
      this.searchRequest.conditions.push({
        left: 'zoneId',
        middle: 'in',
        right: this.selectedZoneIds,
        dataType: 'LONG',
      });
    }

    if (this.filter.name && this.filter.name !== '') {
      this.searchRequest.conditions.push(
        {
          left: 'name',
          middle: 'like',
          right: this.filter.name,
          dataType: 'STRING',
        },
      );
    }

    if (this.filter.code && this.filter.code !== '') {
      this.searchRequest.conditions.push(
        {
          left: 'code',
          middle: '=',
          right: this.filter.code,
          dataType: 'Integer',
        },
      );
    }

    if (this.filter.city && this.filter.city !== '') {
      this.searchRequest.conditions.push(
        {
          left: 'city.name',
          middle: 'like',
          right: this.filter.city,
          dataType: 'STRING',
        },
      );
    }

    if (this.filter.zone && this.filter.zone !== '') {
      this.searchRequest.conditions.push(
        {
          left: 'zone.name',
          middle: 'like',
          right: this.filter.zone,
          dataType: 'STRING',
        },
      );
    }

    // init orders
    this.searchRequest.orders = new Array();
    if (this.change.sort && this.change.sort.length > 0) {
      this.change.sort.forEach(sort => {
        this.searchRequest.orders.push(
          {
            left: sort.field,
            right: sort.direction,

          },
        );
      });
    } else {
      this.searchRequest.orders.push(
        {
          left: 'districtId',
          right: 'desc',

        },
      );
    }
  }

  deleteRecord(event: any) {
    this.dialogService.open(ConfirmDialogComponent, {
      context: {
        content: 'Do you really want to delete?',
      },
    })
    .onClose.subscribe(res => {
      if (res === ConfirmDialogComponent.confirmOk) {
        this.suburbService.remove(event.data.districtId).subscribe(
          result => {
            this.commonService.info('Delete successfully');
            this.loadRequest(this.change.paging.page);
          }, error => {
            this.commonService.warning(error.message);
          });
      }
    });
  }


  addRecord(event: any) {
    this.dialogService.open(DistrictCreateComponent, {
      context: {
        cities: this.cities,
      },
    })
    .onClose.subscribe(result => {
      if (result) {
        this.loadRequest(this.change.paging.page);
      }
    });
  }

  editRecord(event: any) {
    this.dialogService.open(DistrictUpdateComponent, {
      context: {
        data: event.data,
        cities: this.cities,
      },
    })
    .onClose.subscribe(result => {
      if (result) {
        this.loadRequest(this.change.paging.page);
      }
    });
  }

  deactiveGroupMode() {
    const setting = {...this.settings};
    setting.columns = {...this.defaultColumns};
    this.settings = setting;
    this.isGroupMode = false;
  }

  select(event: any) {
    this.ref.close(event.data);
  }
}
