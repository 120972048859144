import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CalendarMonthViewComponent} from 'angular-calendar';
import * as moment from 'moment';
import {DateUtils} from '../../../../../../shared/common/date-utils';
import {TaskStatistic} from '../../../../../../shared/models/response/task-statistic';
import {TaskType} from '../../../../../../shared/models/response/task-type';
import {WeekStatisticRequest, WeekStatisticResponse} from '../../../common/custom-model';

@Component({
  selector: 'ngx-custom-month-view',
  templateUrl: './custom-month-view.component.html',
  styleUrls: ['./custom-month-view.component.scss'],
})
export class CustomMonthViewComponent extends CalendarMonthViewComponent implements OnInit {

  // constructor(
  //   cdr: ChangeDetectorRef, utils: CalendarUtils, locale: string, dateAdapter: DateAdapter,
  // ) {
  //   super(cdr, utils, locale, dateAdapter);
  // }

  _statistics: WeekStatisticResponse[];
  _taskTypes: TaskType[];
  types = ['SC', 'BC', 'RC', 'TC', 'SA', 'BA', 'RA',
    'TA', 'EXC', 'DEP', 'RM', 'TAP', 'LS', 'S', 'RR', 'TEN'];
  typeIds = [];

  @Output() passWeek: EventEmitter<WeekStatisticRequest> = new EventEmitter<WeekStatisticRequest>();
  @Input() set weekStatistics(value: WeekStatisticResponse[]) {
    this._statistics = value;
  }

  @Input() set taskTypes(value: TaskType[]) {
    this._taskTypes = value;
    this.typeIds = this._taskTypes?.filter(x => this.types.indexOf(x.name?.trim()) > -1)?.map(x => x.taskTypeId);
  }

  ngOnInit(): void {
    const totalDayInWeek = this.view.totalDaysVisibleInWeek;
    const days = this.view.days;
    for (let rowIndex = 0; rowIndex < days.length; rowIndex += totalDayInWeek) {
      const daysOfWeek = days.slice(rowIndex, rowIndex + totalDayInWeek);
      if (daysOfWeek && daysOfWeek.length > 0) {
        const startDate = daysOfWeek[0]?.date;
        startDate?.setHours(0, 0, 0, 0);
        const endDate = daysOfWeek[daysOfWeek.length - 1]?.date;
        endDate?.setHours(23, 59, 59, 999);
        const week: WeekStatisticRequest = {
          rowIndex: rowIndex,
          fromDate: startDate?.getTime(),
          toDate: endDate?.getTime(),
        };
        this.passWeek.emit(week);
      }
    }
  }

  getNoWeek(date: any) {
    try {
      const dateMoment = moment(date.getTime());
      return 'W' + DateUtils.getCurrentWeek(dateMoment);
    } catch (ignore) {}
    return '';
  }

  getFirstDateOfWeek(rowIndex, totalDaysVisibleInWeek, days) {
    const daysOfWeek = days.slice(rowIndex, rowIndex + totalDaysVisibleInWeek);
    return daysOfWeek[0]?.date;
  }

  filterStatistic(rowIndex, type: string): number {
    const typeObject = this._taskTypes.find(x => x.name?.trim() === type);
    const statisticList: TaskStatistic[] = this._statistics?.find(x => x.rowIndex === rowIndex)?.taskStatistics;
    return statisticList?.find(x => x.taskTypeId === typeObject?.taskTypeId)?.total | 0;
  }

}
