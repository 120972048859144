import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from './common.service';
import { Observable } from 'rxjs';
import { RestResult } from '../models/response/rest-result';
import { Path } from '../common/path';
import { catchError, map } from 'rxjs/operators';
import { SearchRequest } from '../models/request/search-request';
import { ProspectRequest } from '../models/request/prospect/prospect-request';

@Injectable()
export class PostcodeService {
  constructor(protected http: HttpClient,
              protected commonService: CommonService) {
  }

  search(request: SearchRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.POSTCODE +
      Path.SEARCH, request,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }
}
