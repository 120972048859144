import {Component, Input, OnInit} from '@angular/core';
import {ViewCell} from 'ng2-smart-table';
import {WrapBuyer} from './buyer-utils';

@Component({
  template: `
    {{renderValue}}
  `,
  styles: [
  ],
})
export class BuyerListClassificationCellComponent implements ViewCell, OnInit {

  renderValue: string;

  @Input() value: string | number;
  @Input() rowData: WrapBuyer;

  ngOnInit() {
    this.setValue();
  }

  setValue(): any {
    if (!this.rowData) {
      this.renderValue = this.value ? this.value.toString() : '';
      return;
    }
    let value = '';
    switch (this.rowData.type) {
      case 'ITEM':
        const buyer = this.rowData.data;
        if (buyer.isFirstHome) {
          value += 'F';
        }
        if (buyer.isInvestor) {
          value += 'I';
        }
        if (buyer.isValueBuyer) {
          value += 'VB';
        }
        if (buyer.isDeveloper) {
          value += 'D';
        }
        break;
    }
    this.renderValue = value;
  }

}
