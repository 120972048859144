import {Component, Input, OnInit} from '@angular/core';
import {ViewCell} from 'ng2-smart-table';

@Component({
  template: `
      <p *ngIf="rowData.data.customer?.dob">
        <span>
          {{getYearsDiff(rowData.data.customer?.dob)}}
        </span>
      </p>
  `,
  styles: ['.active {color: green} .expired {color: #7d4439}'],
})
export class ProspectDobAgeCellComponent implements ViewCell, OnInit {

  @Input() value: string | number;
  @Input() rowData: any;

  ngOnInit() {
  }

  getYearsDiff(value: number) {
    if (!value) {
      return null;
    }
    return Math.floor((new Date().getTime() - value) / (1000 * 3600 * 24 * 365));
  }
}
