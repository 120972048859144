export class CustomerSearchRequest {
  phone?: string;
  offset?: number;
  limit?: number;
  officeId?: number;
  filterByAddress?: boolean;
  isDuplicate?: boolean;
  cityId?: number;
  zoneId?: number;
  districtId?: number;
  farmId?: number;
  streetId?: number;
  postcode?: number;
  isMatched?: boolean;
}
