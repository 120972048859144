<div class="buyer-container"  *appPermissionCheck="ComponentPermission.GLOBAL_ADDRESS">
  <nb-tab tabTitle="ALL" [active]="true">
    <div class="row">
      <div class="col-md-6 col-sm-12 left-panel mb-0 table-container"
           [ngClass]="{'popup-hide': isDisplay}"
      >
        <nb-card>
          <nb-card-header>
            <p class="title">Search result for : {{this.parameter}}</p>
          </nb-card-header>
          <nb-card-body>
            <ng2-smart-table [settings]="settings"
                             [source]="source"
                             (userRowSelect)="selectRecord($event)"
            >
            </ng2-smart-table>
          </nb-card-body>
        </nb-card>
      </div>
      <div class="col-md-6 col-sm-12 right-panel"
           [ngClass]="{
       'popup-detail': isDisplay,
       'hide-detail': !isDisplay
       }"
      >
        <ngx-prospect-dialog
          [hidden]="!selectedProspect"
          [selectedProspect]="selectedProspect"
          [prospects]="selectedProspects"
          [companies]="companies"
          [taskTypes]="taskTypes"
          [taskStatuses]="taskStatuses"
          (editDone)="editDone($event)"
        ></ngx-prospect-dialog>
        <ngx-buyer-create
          [hidden]="!selectedBuyer"
          [cities]="cities"
          [taskTypes]="taskTypes"
          [taskStatuses]="taskStatuses"
          (editDone)="editDone($event)"
          (newDone)="newDone($event)"
          (reserveBuyer)="reserveBuyer($event)"
          (ownSet)="ownSet($event)"
        ></ngx-buyer-create>
        <div class="scroll-mobile" *ngIf="isDisplay">
          <button (click)="cancel()" nbButton size="tiny" status="warning">Cancel</button>
        </div>
      </div>
    </div>
  </nb-tab>
</div>
