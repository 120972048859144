import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { City } from '../../../../shared/models/response/address/city';
import { CityService } from '../../../../shared/services/city.service';
import { SuburbService } from '../../../../shared/services/suburb.service';
import { StreetService } from '../../../../shared/services/street.service';
import { SearchRequest } from '../../../../shared/models/request/search-request';
import { RestResult } from '../../../../shared/models/response/rest-result';
import { Buyer } from '../../../../shared/models/response/buyer/buyer';
import { CompanyService } from '../../../../shared/services/company.service';
import { TaskType } from '../../../../shared/models/response/task-type';
import { TaskTypeService } from '../../../../shared/services/task-type.service';
import { Company } from '../../../../shared/models/response/prospect/company';
import { BuyerListAdvanceComponent } from './buyer-list/buyer-list-advance.component';
import { TaskStatus } from '../../../../shared/models/response/task-status';
import { TaskStatusService } from '../../../../shared/services/task-status.service';
import { LocalService } from '../../../../shared/services/local.service';
import { ComponentPermission } from '../../../../component.permission';
import { BuyerSearchRequest } from '../../../../shared/models/request/buyer/buyer-search-request';
import { BuyerCreateComponent } from '../../../../shared/module/buyer-create/buyer-create.component';
import { UserUtils } from '../../../../shared/common/user-utils';
import { ProspectService } from '../../../../shared/services/prospect.service';
import { DateUtils } from '../../../../shared/common/date-utils';
import { User } from '../../../../shared/models/response/user';
import { UserDailyGoal } from '../../../../shared/models/response/user-daily-goal';
import { MultipleSelectedRequest } from '../../../../shared/models/common/multiple-selected-request';
import { ActivatedRoute, Router } from '@angular/router';
import { WrapProspectType } from '../../prospect/common/prospect-utils';
import { BuyerUtils } from '../common/buyer-utils';
import { BuyerService } from '../../../../shared/services/buyer.service';
import {StatusUtils} from '../common/status-utils';

@Component({
  selector: 'ngx-buyer-list',
  templateUrl: './buyer-list.component.html',
  styleUrls: ['./buyer-list.component.scss'],
})
export class BuyerListComponent implements OnInit {
  ComponentPermission = ComponentPermission;
  multipleSelectedRequest: MultipleSelectedRequest;
  cities: City[];
  buyers: Buyer[];
  companies: Company[];
  taskTypes: TaskType[];
  taskStatuses: TaskStatus[];

  selectedBuyer: Buyer;
  selectedBuyers: Buyer[];
  @ViewChild(BuyerListAdvanceComponent) buyerList: BuyerListAdvanceComponent;
  @ViewChild(BuyerCreateComponent) buyerCreate: BuyerCreateComponent;
  isDisplay = false;
  @Input() oneOnOneCall: boolean;
  searchRequest = new BuyerSearchRequest();
  isDialog = false;
  currentUser: User;
  daily: UserDailyGoal;
  passDailyGoal = null;
  remainingCall: number;
  haveDailyGoalOrPermission = null;
  @Input() isCustomerBoard: boolean;
  isLoad: boolean;
  isNotShowDetail: boolean;

  constructor(
    private localService: LocalService,
    private cityService: CityService,
    private taskStatusService: TaskStatusService,
    private suburbService: SuburbService,
    private streetService: StreetService,
    private companyService: CompanyService,
    private taskTypeService: TaskTypeService,
    private prospectService: ProspectService,
    private route: ActivatedRoute,
    private router: Router,
    private buyerService: BuyerService) {
  }

  ngOnInit(): void {
    this.currentUser = ComponentPermission.currentUser();
    this.isLoad = this.isCustomerBoard !== true;
    const date = new Date();
    const fromDate = DateUtils.getStartOfDate(date.getDate(), date.getMonth(), date.getFullYear()).valueOf();
    const toDate = DateUtils.getEndOfDate(date.getDate(), date.getMonth(), date.getFullYear()).valueOf();
    const userId = [];
    userId.push(this.currentUser.userId);
    if (UserUtils.userOverrideDailyGoal(this.currentUser) === false) {
      if (typeof this.currentUser.dailyGoal === 'undefined') {
        this.haveDailyGoalOrPermission = false;
      } else {
        this.haveDailyGoalOrPermission = true;
        this.prospectService.countCurrentDailyGoal(fromDate, toDate, userId).subscribe(result => {
          this.daily = UserUtils.setDailyGoal(result.data);
          if (this.daily.isCompleted) {
            this.initCustomerList();
          } else {
            this.passDailyGoal = false;
            this.remainingCall = this.currentUser.dailyGoal - this.daily.totalCall;
          }
        });
      }
    } else {
      this.initCustomerList();
    }
  }

  initCustomerList() {
    this.passDailyGoal = true;
    this.loadForFilter();
    this.getTaskTypes();
    this.getTaskStatus();
  }

  loadForFilter() {
    const searchRequest = new SearchRequest();
    searchRequest.offset = 0;
    searchRequest.limit = 10000;
    searchRequest.orders = new Array();
    searchRequest.orders.push(
      {
        left: 'name',
        right: 'asc',

      },
    );
    this.localService.cities().subscribe((result: City[]) => {
      this.cities = result;
    });
    this.companyService.search(searchRequest).subscribe((result: RestResult) => {
      this.companies = result?.data;
    });
  }

  getTaskTypes() {
    const taskTypeSearchRequest: SearchRequest = new SearchRequest();
    taskTypeSearchRequest.orders = [];
    taskTypeSearchRequest.orders.push(
      {
        left: 'name',
        right: 'asc',

      },
    );
    this.taskTypeService.search(taskTypeSearchRequest).subscribe((result: RestResult) => {
      this.taskTypes = result.data;
    });
  }

  // onChangeTab(event) {
  //   this.currentTab = event.tabTitle;
  // }

  handleSelectedBuyerChange(value: Buyer) {
    if (!this.isNotShowDetail) {
      this.isDisplay = true;
    }
    this.selectedBuyer = value;
    this.buyerCreate.setBuyer(this.selectedBuyer);
    this.isNotShowDetail = false;
  }

  handleSelectedBuyersChange(value: Buyer[]) {
    this.selectedBuyers = value;
    this.buyerCreate.setBuyers(this.selectedBuyers);
  }

  handleSelectedCustomerIds() {
    this.buyerList.searchRequest.customerIds = this.buyerList.multipleSelectedRequest.selectedIds;
  }

  navigateProspect(value: { currentBuyerId: number, forward: boolean }) {
    if (!value) {
      return;
    }
    const param = {...this.route.snapshot.queryParams};
    let group;
    if (this.selectedBuyer.buyerId === value.currentBuyerId) {
      const day = Number(param['day']);
      const month = Number(param['month']);
      const year = Number(param['year']);
      let index = 0;
      const convertResult = BuyerUtils.convertGroupToWrapperData(this.buyerList.groupWrapper.groups);
      index = convertResult.findIndex(x => x.groupData?.day === day && x.groupData?.month === month && x.groupData?.year === year);
      if (index === convertResult.length - 1) {
        if (param['page']) {
          this.buyerList.pageChange(((Number)(param['page']) + 1), true);
        } else {
          this.buyerList.pageChange(2, true);
        }
      } else {
        group = convertResult[index + 1];
        const itemSearchRequest = {...this.searchRequest};
        itemSearchRequest.year = group.groupData.year;
        itemSearchRequest.month = group.groupData.month;
        itemSearchRequest.day = group.groupData.day;
        this.buyerService.searchItemsSimple(itemSearchRequest).subscribe(result => {
          this.selectedBuyer = result?.data[0];
          delete param['selectedBuyerId'];
          delete param['day'];
          delete param['month'];
          delete param['year'];
          const newParam = {
            year: itemSearchRequest.year,
            month: itemSearchRequest.month,
            day: itemSearchRequest.day,
            selectedBuyerId: this.selectedBuyer.buyerId,
          };
          this.router.navigate([], {
            queryParams: {
              ...param,
              year: itemSearchRequest.year,
              month: itemSearchRequest.month,
              day: itemSearchRequest.day,
              selectedBuyerId: this.selectedBuyer.buyerId,
            },
          });
          this.buyerCreate.setBuyers(result.data);
          this.buyerCreate.setBuyer(this.selectedBuyer);
          this.selectedBuyers = result.data;
        });
      }
    } else {
      this.selectedBuyer = this.selectedBuyers?.find(x => x.buyerId === value.currentBuyerId);
      this.buyerCreate.setBuyer(this.selectedBuyer);
      delete param['selectedBuyerId'];
      this.router.navigate([], {queryParams: {...param, selectedBuyerId: this.selectedBuyer.buyerId}});
    }
  }

  getTaskStatus() {
    const taskStatusSearchRequest: SearchRequest = new SearchRequest();
    taskStatusSearchRequest.orders = [];
    taskStatusSearchRequest.orders.push(
      {
        left: 'name',
        right: 'asc',

      },
    );
    this.taskStatusService.search(taskStatusSearchRequest).subscribe((result: RestResult) => {
      this.taskStatuses = result.data;
    });
  }

  editDone(result: any) {
    if (StatusUtils.isSuccessResponse(result)) {
      // this.selectedBuyer = result;
      // this.buyerList.selectedBuyer = result;
      // this.buyerList.restoreState();
      this.buyerList.reloadList();
    }
  }

  cancel() {
    this.isDisplay = false;
  }

  // new() {
  //   this.isDisplay = true;
  //   this.buyerCreate.new();
  // }

  newDone(result: any) {
    if (StatusUtils.isSuccessResponse(result)) {
      this.buyerList.selectedBuyer = result.data;
      this.buyerList.reloadList(result.data);
      // this.buyerList.restoreState();
    }
  }

  ownSet(buyer: Buyer) {
    this.isDisplay = true;
    this.handleSelectedBuyerChange(buyer);
    // remove selectedBuyer from buyerList
    this.buyerList.selectedBuyer = buyer;
    this.buyerList.highlightRow(buyer ? buyer.buyerId : null);
  }

  reserveBuyer(buyer: Buyer) {
    // unset selected buyer, add default buyer to buyerCreate
    this.selectedBuyer = null;
    this.buyerCreate.setBuyer(buyer);
    // unset select buyer, unset highlightRow
    this.buyerList.selectedBuyer = null;
    this.buyerList.highlightRow(null);
  }

  // for dashboard filtering
  filterCondition(searchRequest: BuyerSearchRequest) {
    this.buyerList?.filterCondition(searchRequest);
  }

  handleDisplayDetail() {
    this.isNotShowDetail = true;
  }
}
