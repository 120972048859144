import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { Prospect } from '../../../../shared/models/response/prospect/prospect';
import { differenceInCalendarDays, getMonth } from 'date-fns';
import { DateUtils } from '../../../../shared/common/date-utils';
import LatLngBounds = google.maps.LatLngBounds;
import LatLngLiteral = google.maps.LatLngLiteral;
import LatLng = google.maps.LatLng;
import MarkerOptions = google.maps.MarkerOptions;

@Component({
  selector: 'ngx-prospect-map',
  templateUrl: './prospect-map.component.html',
  styleUrls: ['./prospect-map.component.scss'],
})
export class ProspectMapComponent implements OnInit, AfterViewInit {
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;

  @Input()
  prospects: any[];
  @Input()
  prospectDisplayType: 'O' | 'V' | 'R' | 'S' | undefined;
  @Output()
  selectProspect: EventEmitter<any> = new EventEmitter();

  centerPosition: LatLngLiteral;
  zoom: number = 12;
  markers = [];
  selectMarkerInfo: any;
  bounds: LatLngBounds;

  ngOnInit() {
    if (this.prospects && this.prospects.length > 0) {
      this.markers = this.prospects
        .filter(
          (p) =>
            p.property &&
            p.address &&
            p.address?.latitude !== null &&
            p.address?.longitude !== null,
        )
        .map((p) => {
          return {
            detail: {
              id: p.prospectId,
              address: p.address?.formattedAddress,
              phone: p.customer?.phone,
              mobilePhone: p.customer?.mobilePhone,
            },
            latlng: new LatLng(
              Number(p.address?.latitude),
              Number(p.address?.longitude),
            ),
            options: this.generateOptions(p),
          };
        });
      this.centerPosition = {
        lat: this.markers[0].latlng.lat(),
        lng: this.markers[0].latlng.lng(),
      };
    } else {
      this.centerPosition = {
        lat: -33.8884737,
        lng: 151.2060502,
      };
    }
  }

  ngAfterViewInit(): void {
    this.getBounds();
  }

  openInfoWindow(marker: MapMarker, index: number) {
    this.selectMarkerInfo = this.markers[index].detail;
    this.infoWindow.open(marker);
    if (this.prospects.length > 0) {
      const selectedProspect = this.prospects.find(
        (p) => p.prospectId === this.selectMarkerInfo.id,
      );
      if (selectedProspect) {
        this.selectProspect.emit(selectedProspect);
      }
    }
  }

  private getBounds() {
    if (this.markers && this.markers.length > 0) {
      let maxLat = this.markers[0].latlng.lat();
      let minLat = maxLat;
      let maxLng = this.markers[0].latlng.lng();
      let minLng = maxLng;
      for (let i = 1; i < this.markers.length; i++) {
        const pos = this.markers[i].latlng;
        if (maxLat < pos.lat()) {
          maxLat = pos.lat();
        }
        if (minLat > pos.lat()) {
          minLat = pos.lat();
        }
        if (maxLng < pos.lng()) {
          maxLng = pos.lng();
        }
        if (minLng > pos.lng()) {
          minLng = pos.lng();
        }
      }
      this.map.fitBounds(
        new LatLngBounds(
          {
            lat: minLat,
            lng: minLng,
          },
          {
            lat: maxLat,
            lng: maxLng,
          },
        ),
      );
    }
  }

  private generateOptions(prospect: Prospect): MarkerOptions {
    const property = prospect.property;
    if (this.prospectDisplayType) {
      if (this.prospectDisplayType === 'V' && property.vac) {
        return this.generateVacMarker(prospect);
      } else if (this.prospectDisplayType === 'O' && property.otm) {
        return this.generateOtmMarker(prospect);
      } else if (this.prospectDisplayType === 'S' && property.sell) {
        return this.generateSellMarker(prospect);
      } else if (this.prospectDisplayType === 'R' && property.rent) {
        return this.generateRentMarker(prospect);
      }
    }
    return {};
  }

  private generateSellMarker(prospect: Prospect): MarkerOptions {
    return {
      label: {
        color: '#000000',
        text: prospect.customer?.relationship
          ? prospect.customer?.relationship.toString()
          : '',
      },
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 5,
        fillColor: '#FF0000',
        strokeColor: '#FF0000',
        strokeWeight: 10,
      },
    };
  }

  private generateRentMarker(prospect: Prospect): MarkerOptions {
    if (prospect.property?.rentCompany?.color) {
      return {
        label: {
          color: prospect.property?.rentCompany?.textColor
            ? prospect.property?.rentCompany?.textColor
            : '#FFFFFF',
          text: prospect.customer?.relationship
            ? prospect.customer?.relationship.toString()
            : '?',
        },
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 5,
          fillColor: '#FF0000',
          strokeColor: '#FF0000',
          strokeWeight: 10,
        },
      };
    }
    return {};
  }

  private generateOtmMarker(prospect: Prospect): MarkerOptions {
    const options: MarkerOptions = {
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 5,
        fillColor: '#9a9a9a',
        strokeColor: '#9a9a9a',
        strokeWeight: 10,
      },
    };
    if (prospect.property?.otmDate) {
      const currentDate = new Date();
      const otmDate = new Date(prospect.property.otmDate);
      const diff = differenceInCalendarDays(currentDate, otmDate);
      if (diff >= 0 && diff < 90) {
        return {
          ...options,
          label: {
            color: '#FFFFFF',
            text: prospect.rate ? prospect.rate.toString() : '',
          },
        };
      }
    }
    return options;
  }

  private generateVacMarker(prospect: Prospect): MarkerOptions {
    if (prospect.property?.vacDate) {
      const currentDate = new Date();
      const vacDate = new Date(prospect.property.vacDate);
      const diff = differenceInCalendarDays(currentDate, vacDate);
      const color =
        diff > 21 * 7 ? '#8080FF' : diff >= 0 ? '#FF0000' : '#000000';
      return {
        label: {
          color: '#FFFFFF',
          text: DateUtils.getCurrentWeekFromTimestamp(
            prospect.property.vacDate,
          ).toString(),
        },
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 5,
          fillColor: color,
          strokeColor: color,
          strokeWeight: 10,
        },
      };
    }
    return {};
  }
}
