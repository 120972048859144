import { Agent } from './agent';

export class AgentGroup {
  agentGroupId?: number;
  propertyId?: number;
  split?: number;
  title?: string;
  agents?: Agent[];
  type?: string;
}
