import {Component, OnInit} from '@angular/core';
import {DashboardService} from '../../../../shared/services/dashboard.service';
import {UserService} from '../../../../shared/services/user.service';
import {AuthService} from '../../../../shared/services/auth.service';
import {TaskTypeService} from '../../../../shared/services/task-type.service';
import {CompanyService} from '../../../../shared/services/company.service';
import {CategoryService} from '../../../../shared/services/category.service';
import {CommonService} from '../../../../shared/services/common.service';

@Component({
  selector: 'ngx-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
})
export class IndexComponent implements OnInit {
  constructor(private dashboardService: DashboardService,
              private userService: UserService,
              private authService: AuthService,
              private categoryService: CategoryService,
              private taskTypeService: TaskTypeService,
              private companyService: CompanyService,
              private commonService: CommonService) {

  }

  currentUser: any;

  ngOnInit(): void {
    this.currentUser = this.authService.currentUser;
  }

}
