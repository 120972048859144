import {Component, OnInit} from '@angular/core';
import {SearchRequest} from '../../../../shared/models/request/search-request';
import {LocalDataSource} from 'ng2-smart-table';
import {NbDialogService} from '@nebular/theme';
import {CommonService} from '../../../../shared/services/common.service';
import {ComponentPermission} from '../../../../component.permission';
import {RestResult} from '../../../../shared/models/response/rest-result';
import {ConfirmDialogComponent} from '../../confirm-dialog/confirm-dialog.component';
import {CompanyService} from '../../../../shared/services/company.service';
import {ProspectCompanyUpdateComponent} from './update/prospect-company-update.component';
import {ProspectCompanyCreateComponent} from './create/prospect-company-create.component';
import {Constant} from '../../../../shared/common/constant';
import * as moment from 'moment/moment';
import { DateUtils } from '../../../../shared/common/date-utils';

@Component({
  selector: 'ngx-prospect-company',
  templateUrl: './prospect-company.component.html',
  styleUrls: ['./prospect-company.component.scss'],
})
export class ProspectCompanyComponent implements OnInit {
  ComponentPermission = ComponentPermission;
  searchRequest = new SearchRequest();
  source: LocalDataSource = new LocalDataSource();
  change: any;
  pageSize = Constant.PAGE_SIZE;

  settings = {
    mode: 'external',
    actions: {
      add: ComponentPermission.isAccess(ComponentPermission.COMPANY_CREATE),
      edit: ComponentPermission.isAccess(ComponentPermission.COMPANY_EDIT),
      delete: ComponentPermission.isAccess(ComponentPermission.COMPANY_DELETE),
    },
    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
    },
    pager: {
      display: true,
      perPage: this.pageSize,
    },
    columns: {
      companyId: {
        title: 'ID',
        type: 'number',
        width: '40px',
      },
      name: {
        title: 'Name',
        type: 'string',
      },
      address: {
        title: 'Address',
        type: 'string',
      },
      phone: {
        title: 'Phone',
        type: 'string',
      },
      email: {
        title: 'Email',
        type: 'string',
      },
      type: {
        title: 'Type',
        type: 'string',
      },
      serviceCount: {
        title: 'Service Count',
        type: 'number',
        width: '40px',
      },
    },
  };

  constructor(private dialogService: NbDialogService,
              private commonService: CommonService,
              private companyService: CompanyService) {

  }

  ngOnInit() {
    this.source.onChanged().subscribe((change) => {
      this.change = change;
      if (change.action === 'refresh') {
        this.loadRequest(1);
        // this.pageChange(change.paging.page);
      }
      if (change.action === 'page') {
        // this.source.load(new Array());
        this.loadRequest(change.paging.page);
      }
      if (change.action === 'sort') {
        this.source.load(new Array());
        this.loadRequest(1);
      }
      if (change.action === 'filter') {
        this.source.load(new Array());
        this.loadRequest(1);
      }
    });
  }

  get CheckPermission() {
    return ComponentPermission;
  }

  search() {
    this.loadRequest(1);
  }

  loadRequest(pageIndex) {
    this.prepareRequest();
    this.companyService.search(this.searchRequest).subscribe((result: RestResult) => {
      this.source.load(result.data);
    });
  }

  prepareRequest() {
    // init conditions
    if (!this.searchRequest.conditions) {
      this.searchRequest.conditions = new Array();
    }
    this.searchRequest.conditions = new Array();
    if (this.change.filter && this.change.filter.filters.length > 0) {
      this.change.filter.filters.forEach(filter => {
        if (filter.search && filter.search.trim() !== '') {
          if (filter.field.includes('Id') || filter.field === 'serviceCount' && isFinite(filter.search)) {
            this.searchRequest.conditions.push(
              {
                left: filter.field,
                middle: '=',
                right: Number(filter.search),
              },
            );
          } else if (filter.field === 'createdDate') {
            if (moment(filter.search, 'DD/MM/YYYY', true).isValid()) {
              const date = new Date(moment(filter.search, 'DD/MM/YYYY', true).valueOf());
              const from = DateUtils.getStartOfDate(date.getDate(), date.getMonth(), date.getFullYear()).valueOf();
              const to = DateUtils.getEndOfDate(date.getDate(), date.getMonth(), date.getFullYear()).valueOf();
              this.searchRequest.conditions.push(
                {
                  left: filter.field,
                  middle: '>=',
                  right: from,
                },
                {
                  left: filter.field,
                  middle: '<=',
                  right: to,
                },
              );
            }
          } else {
            this.searchRequest.conditions.push(
              {
                left: filter.field,
                middle: 'like',
                right: filter.search,
              },
            );
          }
        }
      });
    }

    // init orders
    this.searchRequest.orders = new Array();
    if (this.change.sort && this.change.sort.length > 0) {
      this.change.sort.forEach(sort => {
        this.searchRequest.orders.push(
          {
            left: sort.field,
            right: sort.direction,
          },
        );
      });
    } else {
      this.searchRequest.orders.push(
        {
          left: 'companyId',
          right: 'desc',

        },
      );
    }
  }

  deleteRecord(event: any) {
    this.dialogService.open(ConfirmDialogComponent, {
      context: {
        content: 'Do you really want to delete?',
      },
    })
      .onClose.subscribe(res => {
      if (res === ConfirmDialogComponent.confirmOk) {
        this.companyService.remove(event.data.sosCityId).subscribe(
          result => {
            this.commonService.info('Delete successfully');
            this.loadRequest(this.change.paging.page);
          }, error => {
            this.commonService.warning(error.message);
          });
      }
    });
  }


  addRecord(event: any) {
    this.dialogService.open(ProspectCompanyCreateComponent)
      .onClose.subscribe(result => {
      if (result) {
        this.loadRequest(this.change.paging.page);
      }
    });
  }

  editRecord(event: any) {
    this.dialogService.open(ProspectCompanyUpdateComponent, {
      context: {
        data: event.data,
      },
    })
      .onClose.subscribe(result => {
      if (result) {
        this.loadRequest(this.change.paging.page);
      }
    });
  }

}
