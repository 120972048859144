import { Component, OnInit } from '@angular/core';
import { ProspectSearchRequest } from '../../../../shared/models/request/prospect/prospect-search-request';
import { ProspectService } from '../../../../shared/services/prospect.service';
import { CommonService } from '../../../../shared/services/common.service';
import { BulkLogTotalMessage } from '../../../../shared/models/response/bulk-log-total-message';
import { DateUtils } from '../../../../shared/common/date-utils';
import { StringUtils } from '../../../../shared/common/string-utils';
import { Timestamp } from 'rxjs/internal-compatibility';
import { Router } from '@angular/router';
import { BulkLog } from '../../../../shared/models/response/bulk-log';
import { ProspectListBasicComponent } from '../../prospect/prospect-list-basic/prospect-list-basic.component';
import { NbDialogRef, NbDialogService } from '@nebular/theme';

@Component({
  selector: 'ngx-total-message-list',
  templateUrl: './total-message-list.component.html',
  styleUrls: ['./total-message-list.component.scss'],
})
export class TotalMessageListComponent implements OnInit {

  prospectSearchRequest: ProspectSearchRequest;
  noData: boolean;
  data: BulkLogTotalMessage[];
  stringUtils = StringUtils;

  constructor(private prospectService: ProspectService,
              private commonService: CommonService,
              private router: Router,
              private dialogService: NbDialogService,
              private ref: NbDialogRef<TotalMessageListComponent>) {
  }

  ngOnInit(): void {
    if (this.noData === false) {
      this.prospectService.getTotalSm(this.prospectSearchRequest).subscribe(result => {
        this.data = result.data;
      });
    }
  }

  getDateByTimestamp(timestamp: number) {
    return new Date(timestamp);
  }

  traceBackSM(bulkLog: BulkLog) {
    this.ref.close();
    this.dialogService.open(ProspectListBasicComponent, {
      context: {
        isDialog: true,
        bulkLog: bulkLog
      },
    });
  }
}
