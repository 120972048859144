import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from './common.service';
import { Observable } from 'rxjs';
import { RestResult } from '../models/response/rest-result';
import { Path } from '../common/path';
import { catchError, map } from 'rxjs/operators';
import { SearchRequest } from '../models/request/search-request';
import { AttachmentRequest } from '../models/request/attachment-request';
import {AttachmentCountRequest} from '../models/request/attachment-count-request';

@Injectable()
export class AttachmentService {
  constructor(protected http: HttpClient,
              protected commonService: CommonService) {
  }

  getOne(attachmentId: number): Observable<RestResult> {
    return this.http.get<any>(
      Path.API_ENDPOINT +
      Path.ATTACHMENT +
      Path.ATTACHMENT_ID.replace(':attachmentId', attachmentId.toString()),
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }


  search(request: SearchRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.ATTACHMENT +
      Path.SEARCH, request,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  countAttachment(request: AttachmentCountRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.ATTACHMENT +
      Path.COUNT, request,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  remove(attachmentId: number): Observable<RestResult> {
    return this.http.delete<any>(
      Path.API_ENDPOINT +
      Path.ATTACHMENT +
      Path.ATTACHMENT_ID.replace(':attachmentId', attachmentId.toString()),
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  upload(request: AttachmentRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.ATTACHMENT +
      Path.UPLOAD, request,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  uploadBatch(request: AttachmentRequest): Observable<RestResult> {
    const form = this.requestToFormData(request);
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.ATTACHMENT +
      Path.UPLOAD + Path.MULTIPLE, form,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  private requestToFormData(request: AttachmentRequest): FormData {
    const formData = new FormData();
    formData.append('objectId', JSON.stringify(request.objectId));
    formData.append('objectType', request.objectType);
    formData.append('isPublic', JSON.stringify(request.isPublic));
    if (request.files) {
      for (let i = 0; i < request.files.length; i++) {
        formData.append('files', request.files[i]);
      }
    }
    if (request.file) {
      formData.append('file', request.file);
    }
    return formData;
  }
}
