<nb-card>
  <nb-card-header>
    Rental Details
  </nb-card-header>

  <nb-card-body>
    <nb-accordion multi>
      <nb-accordion-item>
        <nb-accordion-item-header>Particulars</nb-accordion-item-header>
        <nb-accordion-item-body>
          <div class='row'>
            <div class='col-6'>
              <input nbInput fullWidth placeholder='Principal 1 First Name' fieldSize='tiny'
                     [(ngModel)]='data.customer.name' nbTooltip='Principal First Name'>
            </div>
            <div class='col-6'>
              <input nbInput fullWidth placeholder='Principal 1 Last Name' fieldSize='tiny'
                     [(ngModel)]='data.customer.surname' nbTooltip='Principal Last Name'>
            </div>
          </div>
          <div class='row mt-2'>
            <div class='col-12'>
              <input nbInput fullWidth placeholder='ABN/ACN' fieldSize='tiny' nbTooltip='ABN/ACN'
                     [(ngModel)]='data.customer.abnNumber'>
            </div>
          </div>
          <div class='row mt-2'>
            <div class='col-6 d-flex'>
              GST Registered?
              <nb-checkbox class='ml-2' [(ngModel)]='data.customer.gstRegistered'></nb-checkbox>
            </div>
          </div>
          <div class='row mt-2'>
            <div class='col-9' nbTooltip='Address'>
              <input [class]='disable? "override-disabled" : null' nbInput fullWidth placeholder='Address'
                     [disabled]="disable ? true : null"
                     [value]='stringUtil.getFullAddress(data?.address)' fieldSize='tiny'>
            </div>
            <div class='col-3' nbTooltip='Postcode'>
              <input [class]='disable? "override-disabled" : null' nbInput fullWidth fieldSize='tiny'
                     [value]='data?.address?.street?.district?.code'
                     [disabled]="disable ? true : null">
            </div>
          </div>
          <div class='row mt-2'>
            <div class='col-6'>
              <input nbInput fullWidth placeholder='Phone' fieldSize='tiny' [(ngModel)]='data.customer.phone'
                     nbTooltip='Phone'>
            </div>
            <div class='col-6'>
              <input nbInput fullWidth placeholder='Mobile Phone' fieldSize='tiny'
                     [(ngModel)]='data.customer.mobilePhone' nbTooltip='Mobile Phone'>
            </div>
            <div class='col-12 mt-2'>
              <input nbInput fullWidth placeholder='Email' fieldSize='tiny' [(ngModel)]='data.customer.email'
                     nbTooltip='Email'>
            </div>
          </div>
          <hr>
          <div class='row mt-2'>
            <div class='col-12' nbTooltip='Agent'>
              <input [class]='disable? "override-disabled" : null' nbInput fullWidth fieldSize='tiny' [value]='agentName'
                     [disabled]="disable ? true : null">
            </div>
          </div>
          <div class='row mt-2'>
            <div class='col-6' nbTooltip="Licensee's License Number">
              <input [class]='disable? "override-disabled" : null' nbInput fullWidth fieldSize='tiny' [value]='licenseNumber'
                     [disabled]="disable ? true : null">
            </div>
            <div class='col-6' nbTooltip='ABN/ACN'>
              <input [class]='disable? "override-disabled" : null' nbInput fullWidth fieldSize='tiny' [value]='abn'
                     [disabled]="disable ? true : null">
            </div>
          </div>
          <div class='row mt-2'>
            <div class='col-6 d-flex'>
              GST Registered?
              <nb-checkbox class='ml-2' [value]='true'></nb-checkbox>
            </div>
          </div>
          <div class='row mt-2'>
            <div class='col-12' nbTooltip='Trading As'>
              <input [class]='disable? "override-disabled" : null' nbInput fullWidth placeholder='Trading As' [disabled]="disable ? true : null"
                     [value]='officeName' fieldSize='tiny'>
            </div>
          </div>
          <div class='row mt-2'>
            <div class='col-9' nbTooltip='Address'>
              <input [class]='disable? "override-disabled" : null' nbInput fullWidth placeholder='Address' [disabled]="disable ? true : null"
                     [value]='officeAddress' fieldSize='tiny'>
            </div>
            <div class='col-3' nbTooltip='Postcode'>
              <input [class]='disable? "override-disabled" : null' nbInput fullWidth fieldSize='tiny' value='2560' [disabled]="disable ? true : null">
            </div>
          </div>
          <div class='row mt-2'>
            <div class='col-6' nbTooltip='Phone'>
              <input nbInput fullWidth placeholder='Phone' fieldSize='tiny' [value]='officeFax'
                     [disabled]="disable ? true : null" [class]='disable? "override-disabled" : null'>
            </div>
            <div class='col-6' nbTooltip='Mobile Phone'>
              <input nbInput fullWidth placeholder='Mobile Phone' fieldSize='tiny'
                     [disabled]="disable ? true : null" [class]='disable? "override-disabled" : null'>
            </div>
            <div class='col-12 mt-2' nbTooltip='Email'>
              <input nbInput fullWidth placeholder='Email' fieldSize='tiny' [(ngModel)]='officeEmail'
                     [disabled]="disable ? true : null" [class]='disable? "override-disabled" : null'>
            </div>
          </div>
          <hr>
          <div class='row mt-2'>
            <div class='col-9' nbTooltip='Premises to be leased'>
              <input [class]='disable? "override-disabled" : null' nbInput fullWidth placeholder='Premises to be leased' [disabled]="disable ? true : null"
                     [value]='stringUtil.getFullAddress(data?.address)' fieldSize='tiny'>
            </div>
            <div class='col-3' nbTooltip='Postcode'>
              <input [class]='disable? "override-disabled" : null' nbInput fullWidth fieldSize='tiny'
                     [value]='data?.address?.street?.district?.code'
                     [disabled]="disable ? true : null">
            </div>
          </div>
          <div class='row mt-2'>
            <div class='col-lg-6 col-sm-12 d-flex'>
              <nb-checkbox [(ngModel)]='data.property.beingFurnished'>Being Furnished</nb-checkbox>
            </div>
            <div class='col-lg-6 col-sm-12 d-flex'>
              <nb-checkbox [value]='isCar()'>Garage/Car Space included</nb-checkbox>
            </div>
          </div>
        </nb-accordion-item-body>
      </nb-accordion-item>
      <nb-accordion-item>
        <nb-accordion-item-header>Agreement</nb-accordion-item-header>
        <nb-accordion-item-body>
          <div class='row'>
            <div class='col-lg-6 col-sm-6 p-0'>
              <input nbInput fullWidth fieldSize='tiny' [(ngModel)]='data.property.rentPrice' placeholder='Rent Price'
                     nbTooltip='Rent Price'>
            </div>
            <div class='col-lg-6 col-sm-6 p-0'>
              <nb-form-field>
                <input nbInput fullWidth fieldSize='tiny' [(ngModel)]='data.property.managementFee'
                       placeholder='Management Fee' nbTooltip='Management Fee'>
                <span nbSuffix>%</span>
              </nb-form-field>
            </div>
          </div>
          <div class="row mt-2">
            <div class='col-lg-6 col-sm-6 p-0'>
              <nb-form-field>
                <input nbInput fullWidth fieldSize='tiny' [(ngModel)]='data.property.administrationFee'
                       placeholder='Administration Fee'
                       nbTooltip='Administration Fee'>
                <span class='mr-4' nbSuffix>/month</span>
              </nb-form-field>
            </div>
            <div class='col-lg-6 col-sm-6 p-0'>
              <nb-form-field>
                <input nbInput fullWidth fieldSize='tiny' [(ngModel)]='data.property.weeklyCommission'
                       placeholder='Weekly Commission'
                       nbTooltip='Weekly Commission' disabled>
                <span class='mr-4' nbSuffix>/week</span>
              </nb-form-field>
            </div>
          </div>
        </nb-accordion-item-body>
      </nb-accordion-item>
      <nb-accordion-item>
        <nb-accordion-item-header>Tenant Info</nb-accordion-item-header>
        <nb-accordion-item-body>
          <div>
            <div class='row'>
              <div class='col-lg-4 col-sm-6 p-0'>
                <input nbInput fieldSize='tiny' fullWidth placeholder='Name' [(ngModel)]='data.tenant.name'
                       nbTooltip='Name'>
              </div>
              <div class='col-lg-4 col-sm-6 p-0'>
                <input nbInput fieldSize='tiny' fullWidth placeholder='Surname' [(ngModel)]='data.tenant.surname'
                       nbTooltip='Surname'>
              </div>
            </div>
            <div class='row mt-2'>
              <div class='col-lg-4 col-sm-6 p-0'>
                <input nbInput fieldSize='tiny' fullWidth placeholder='Phone' [(ngModel)]='data.tenant.phone'
                       nbTooltip='Phone'>
              </div>
              <div class='col-lg-4 col-sm-6 p-0'>
                <nb-form-field>
                  <input nbInput fieldSize='tiny' fullWidth placeholder='Mobile Phone'
                         [(ngModel)]='data.tenant.mobilePhone' nbTooltip='Mobile Phone'>
                  <button nbSuffix nbButton size='tiny' status='info' class='mr-4' (click)='sendSMS()'>SMS</button>
                </nb-form-field>
              </div>
              <div class='col-lg-4 col-sm-12 p-0'>
                <input nbInput fieldSize='tiny' fullWidth placeholder='Email' [(ngModel)]='data.tenant.email'
                       nbTooltip='Email'>
              </div>
            </div>
            <div class='row mt-2'>
              <div class='col-6'>
                <input [nbDatepicker]='datepickerFrom' nbInput fullWidth fieldSize='tiny'
                       [(ngModel)]='data.tenant.startDate'
                       placeholder='Start Date' nbTooltip='Start Date'>
                <nb-datepicker #datepickerFrom></nb-datepicker>
              </div>
              <div class='col-6'>
                <input [nbDatepicker]='datePickerTo' nbInput fullWidth fieldSize='tiny'
                       [(ngModel)]='data.tenant.endDate'
                       placeholder='End Date' nbTooltip='End Date'>
                <nb-datepicker #datePickerTo></nb-datepicker>
              </div>
            </div>
            <div class='row mt-2'>
              <div class='col-12'>
                <nb-checkbox [(ngModel)]='data.tenant.isArrear' (change)='checkArrearStatus()'>Arrear</nb-checkbox>
              </div>
            </div>
            <div class='row mt-2' *ngIf='data?.tenant?.isArrear'>
              <div class='col-6'>
                <nb-select fullWidth size='tiny' [(ngModel)]='data.tenant.arrearWeekCount'>
                  <nb-option *ngFor='let option of ARREAR_OPTION' [value]='option.value'
                             (click)='calculateArrearAmount()'>{{option.label}}</nb-option>
                </nb-select>
              </div>
              <div class='col-6'>
                <nb-form-field nbTooltip='Arrear Amount'>
                  <input nbInput fullWidth fieldSize='tiny' class='override-disabled'
                         disabled placeholder='Arrear Amount' [(ngModel)]='data.tenant.arrearAmount'>
                  <span nbSuffix>{{defaultCurrencyIcon}}</span>
                </nb-form-field>
                <nb-checkbox class='mt-1' [(ngModel)]='data.tenant.notificationSent'>Notification Sent Out
                </nb-checkbox>
              </div>
            </div>
            <!--            <div class='row mt-2'>-->
            <!--              <div class='col-6'></div>-->
            <!--              <div class='col-6'>-->
            <!--                <nb-checkbox [(ngModel)]='data.tenant.notificationSent'>Notification Sent Out?</nb-checkbox>-->
            <!--              </div>-->
            <!--            </div>-->
            <div class='row mt-2'>
              <div class='col-12'>
                <nb-checkbox [(ngModel)]='data.tenant.isMaintenance'>Maintenance</nb-checkbox>
              </div>
            </div>
            <div class='row mt-2' *ngIf='data?.tenant?.isMaintenance'>
              <div class='col-12'>
                <textarea nbInput fullWidth rows='3' placeholder='Maintenance Note'
                          nbTooltip='Maintenance Note'></textarea>
              </div>
            </div>
          </div>
        </nb-accordion-item-body>
      </nb-accordion-item>
      <nb-accordion-item>
        <nb-accordion-item-header>Agents</nb-accordion-item-header>
        <nb-accordion-item-body>
          <div class='row mt-2 justify-content-center' *ngIf='agentGroupList.length === 0 '>
            <div class='text-center'>
              No Agents
            </div>
          </div>
          <div class='row mt-2' *ngFor='let group of agentGroupList, index as index'>
            <hr class='agent-create-hr' *ngIf='index > 0'>
            <div class='d-flex w-100'>
              <div class='col-3'>
                <h6>{{group.title}}</h6>
              </div>
              <!--              <div class='col-4'>-->
              <!--                <nb-form-field>-->
              <!--                  <input nbInput fullWidth fieldSize='tiny' [(ngModel)]='group.split' type='number' min='1' max='100'-->
              <!--                         step='1'>-->
              <!--                  <span nbSuffix>%</span>-->
              <!--                </nb-form-field>-->
              <!--              </div>-->
            </div>
            <div class='d-flex w-100 mt-2' *ngFor='let agent of group.agents, index as index'>
              <!--              <div class='col-3'>-->
              <!--                <nb-form-field>-->
              <!--                  <input class='override-disabled' nbInput fieldSize='tiny' fullWidth-->
              <!--                         [value]='calculateSplit(group.split, agent.agentSplit)' disabled>-->
              <!--                  <span nbSuffix>{{defaultCurrencyIcon}}</span>-->
              <!--                </nb-form-field>-->
              <!--              </div>-->
              <!--              <div class='col-4'>-->
              <!--                <nb-form-field>-->
              <!--                  <input nbInput fieldSize='tiny' fullWidth-->
              <!--                         [(ngModel)]='agent.agentSplit' type='number' min='1' max='100' step='1' pattern='[0-9]'-->
              <!--                  >-->
              <!--                  <span nbSuffix>%</span>-->
              <!--                </nb-form-field>-->
              <!--              </div>-->
              <div class='col-5 pr-3'>
                {{ agent.user && agent.user.name ? agent.user.name + ' (' + stringUtils.shortenUserName(agent.user) + ')' : '' }}
                <button nbButton status='success' size='tiny' class='fa-pull-right'
                        (click)='deleteAgent(agent, group, index)'>
                  Delete
                </button>
              </div>
            </div>
          </div>
          <div class='row mt-2' *ngIf='isEditing === false'>
            <div class='col-12'>
              <button nbButton status='primary' size='tiny' class='fa-pull-right' (click)='createNewAgent()'>Add Agent
              </button>
            </div>
          </div>
          <div class='row mt-2' *ngIf='isEditing === true'>
            <hr class='agent-create-hr'>
            <div class='new-agent'>
              <div class='row'>
                <div class='col-4'>
                  <nb-select size='tiny' fullWidth [(ngModel)]='selectedTitle'>
                    <nb-option [value]='null'>Title</nb-option>
                    <nb-option *ngFor='let title of AGENT_TITLE' [value]='title'>{{title}}</nb-option>
                  </nb-select>
                </div>
                <!--                <div class='col-4'>-->
                <!--                  <nb-form-field>-->
                <!--                    <input nbInput fieldSize='tiny' fullWidth placeholder='Agent split'-->
                <!--                           [(ngModel)]='newAgent.agentSplit' type='number' min='1' max='100' step='1'-->
                <!--                           nbTooltip='Split in % of this agent in this group, leave blank if this group has only 1 agent'>-->
                <!--                    <span nbSuffix>%</span>-->
                <!--                  </nb-form-field>-->
                <!--                </div>-->
                <div class='col-4'>
                  <input [(ngModel)]='newAgentName'
                         [ngModelOptions]='{standalone: true}'
                         [typeahead]='filterUser$'
                         [typeaheadAsync]='true'
                         (typeaheadOnSelect)='selectUser($event)'
                         typeaheadOptionField='name'
                         typeaheadWaitMs='1000'
                         placeholder='Search User'
                         nbInput fieldSize='tiny' fullWidth>
                </div>
              </div>
              <div class='row mt-2'>
                <div class='col-12'>
                  <button class='fa-pull-right' nbButton status='primary' size='tiny' (click)='editDone()'>Done</button>
                  <button class='fa-pull-right mr-2' nbButton status='basic' size='tiny' (click)='cancelEdit()'>Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </nb-accordion-item-body>

      </nb-accordion-item>
    </nb-accordion>
  </nb-card-body>
  <nb-card-footer>
    <div class='row'>
      <div class='col-12'>
        <button nbButton size='tiny' status='primary' class='fa-pull-right' (click)='save()'>Save</button>
        <button nbButton size='tiny' status='primary' class='fa-pull-right mr-2' (click)='close()'>Close</button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
