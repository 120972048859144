import { Component, OnInit, Optional } from '@angular/core';
import { ProspectListCommonComponent } from '../common/prospect-list-common.component';
import { ProspectUtils, WrapProspect, WrapProspectType } from '../common/prospect-utils';
import { ProspectListAddressCellComponent } from '../common/prospect-list-address-cell-component';
import { Prospect } from '../../../../shared/models/response/prospect/prospect';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { ToastService } from '../../../../shared/services/toast.service';
import { ProspectService } from '../../../../shared/services/prospect.service';
import { CommonService } from '../../../../shared/services/common.service';
import { RestResult } from '../../../../shared/models/response/rest-result';
import { ProspectShortcutComponent } from '../prospect-shortcut/prospect-shortcut.component';
import { LocalService } from '../../../../shared/services/local.service';
import { ProspectListCustomCellComponent } from '../common/prospect-list-custom-cell-component';
import { ComponentPermission } from '../../../../component.permission';
import { ProspectPurchaseYearsCellComponent } from '../common/prospect-purchase-years-cell-component';
import { SearchRequest } from '../../../../shared/models/request/search-request';
import { City } from '../../../../shared/models/response/address/city';
import { AuthService } from '../../../../shared/services/auth.service';
import { TemplateService } from '../../../../shared/services/template.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSourceUtil } from '../../../../shared/models/utils/datasource-util';
import { ProspectListBasicComponent } from '../prospect-list-basic/prospect-list-basic.component';
import { StringUtils } from '../../../../shared/common/string-utils';

@Component({
  selector: 'ngx-prospect-list-purchasedate',
  templateUrl: './prospect-list-purchasedate.component.html',
  styleUrls: ['./prospect-list-purchasedate.component.scss'],
})
export class ProspectListPurchasedateComponent extends ProspectListCommonComponent implements OnInit {
  ComponentPermission = ComponentPermission;
  isDialog = false;
  contactOptionReplacement = (replace: string) => {
    return {
      true: replace,
      false: '',
    };
  }

  columnsDefinition = {
    address: {
      enable: true,
      value: ProspectUtils.createColumn('Address',
        null, null, ', ', null, ProspectListAddressCellComponent),
      name: 'Address',
    },
    name: {
      enable: true,
      value: ProspectUtils.createColumn('Name', ['customer.name', 'customer.surname'], null, null, null),
      name: 'Name',
    },
    purchaseDate: {
      enable: true,
      value: ProspectUtils.createColumn('Purchase Date', ['property.purchaseDate'],
        ProspectListCustomCellComponent.VALUE_TYPES.DATE, null, null),
      name: 'Purchase Date',
    },
    purchaseYears: {
      enable: true,
      value: this.createPurchaseYearsColumn('Purchase Years'),
      name: 'Purchase Years',
    },
  };

  settings = {
    mode: 'external',
    hideSubHeader: true,
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    pager: {
      display: true,
      perPage: 10000,
    },
    columns: this.createColumns(),
    rowClassFunction: (row) => {
      return ProspectUtils.highlightProspectRow(row?.data);
    },
  };

  createColumns() {
    const columns = {};
    for (const key of Object.keys(this.columnsDefinition)) {
      if (this.columnsDefinition[key].enable === true) {
        columns[key] = this.columnsDefinition[key].value;
      }
    }
    return columns;
  }

  createPurchaseYearsColumn(title: string) {
    return {
      title: title,
      type: 'custom',
      filter: false,
      renderComponent: ProspectPurchaseYearsCellComponent,
    };
  }

  viewMap = false;
  prospects: Prospect[];
  selectedMonth: number;
  selectedDay: number;
  adminNumber: string;
  param = {};

  get CheckPermission() {
    return ComponentPermission;
  }

  constructor(@Optional() private ref: NbDialogRef<ProspectListBasicComponent>,
              private _dialogService: NbDialogService,
              private _toastService: ToastService,
              private _prospectService: ProspectService,
              private _commonService: CommonService,
              private _localService: LocalService,
              private _authService: AuthService,
              private _templateService: TemplateService,
              private _router: Router,
              private _route: ActivatedRoute) {
    super(_dialogService, _toastService, _prospectService, _commonService, _localService, _authService, _router, _route, _templateService);
  }

  ngOnInit() {
    this.reducedMode = true;
    this.currentUser = this.authService.currentUser;
    this.source.onChanged().subscribe((change) => {
      this.change = change;
      if (change.action === 'refresh') {
        this.pageChange(1);
      }
      if (change.action === 'page') {
        this.param = { ...this.param, page: change.paging.page };
        this.navigateWithParam();
        this.pageChange(change.paging.page);
      }
    });
    this.loadForFilter();
    if (this.currentUser.office.phone) {
      this.adminNumber = this.currentUser.office.phone;
    }
    // this.onMonthChange(new Date().getMonth() + 1);
    this.preLoadAddressForFilter();
    this.preLoadSelectedPurchaseDate();
  }

  loadForFilter() {
    const searchRequest = new SearchRequest();
    searchRequest.offset = 0;
    searchRequest.limit = 10000;
    searchRequest.orders = new Array();
    searchRequest.orders.push(
      {
        left: 'name',
        right: 'asc',

      },
    );
    this.localService.cities().subscribe((result: City[]) => {
      this.cities = result;
    });
  }

  pageChange(pageIndex) {
    if (this.route.snapshot.queryParams['page']) {
      pageIndex = Number(this.route.snapshot.queryParams['page']);
    }
    this.willBeDeletedProspectIds = [];
    this.willBeDeletedGroupIds = [];
    this.currentPage = pageIndex;
    this.searchRequest.mode = this._selectedMode;
    this.searchRequest.offset = (pageIndex - 1) * this.pageSize;
    if (this.searchRequest.offset < 0) {
      this.searchRequest.offset = 0;
    }
    this.searchRequest.limit = this.pageSize;
    this.searchRequest.officeId = this.currentUser.office?.officeId;
    this.searchRequest.loadOtmExtra = true;
    this.searchRequest.disableItemCountEachGroup = true;
    this.searchRequest.hasPurchaseDate = true;
    this.searchRequest.sortType = 'PURCHASE_DATE_DESC';
    if (this.isFiltered) {
      // this.param = {};
      this.appendFilterToParameter();
      this.navigateWithParam();
    }
    if (!this.isFiltered) {
      this.appendParameterToSearchRequest();
    }
    this.prospectService.searchGroups(this.searchRequest).subscribe((result: RestResult) => {
      this.groupWrapper = result.data;
      this.totalPage = Math.ceil(this.groupWrapper.totalGroups / this.pageSize);
      const prospectId = Number(this.route.snapshot.queryParams['selectedProspectId']);
      if (prospectId) {
        this.prospectService.getOne(prospectId).subscribe(p => {
          this.selectedProspect = p.data;
        });
      }
    });
    this.getData(pageIndex, this.pageSize);

    // this.prospectService.getGroupItemsSimple(this.searchRequest).subscribe((result: RestResult) => {
    //   // const searchResult: SearchResult<Prospect> = result.data;
    //   // this.totalPage = Math.ceil(searchResult.recordsTotal / this.pageSize);
    //   this.selectedProspects = result.data;
    //   if (this.selectedProspects && this.selectedProspects.length !== 0) {
    //     const wrapProspectItem = ProspectUtils.convertItemToWrapperData(this.selectedProspects[0]);
    //     this.wrapProspects = ProspectUtils.convertGroupItemToWrapperData(result.data);
    //     this.selectRecord({data: wrapProspectItem}, false, false);
    //   }
    //   // const convertResult = ProspectUtils.convertGroupItemToWrapperData(searchResult.data);
    //   // this.buildData(this.pageSize, convertResult, () => {
    //   //   this.restoreState();
    //   // });
    //   // this.onProspectChange(this.prospectChange);
    //   DataSourceUtil.reloadDataProspectListing(this.source, pageIndex, this.pageSize, result);
    // });
  }

  onSearch() {
    this.pageChange(1);
  }

  onMonthChange(value: number) {
    if (value !== 0) {
      this.selectedMonth = value;
      this.searchRequest.purchaseDateMonth = value;
      this.param = { ...this.param, purchaseDateMonth: this.searchRequest.purchaseDateMonth };
      this.isFiltered = true;
      this.navigateWithParam();
    } else {
      this.searchRequest.purchaseDateMonth = null;
    }
    this.pageChange(1);
  }

  onDayChange(value: number) {
    if (value && !this.selectedMonth) {
      return;
    }
    if (value !== null) {
      this.searchRequest.purchaseDateDay = value;
      this.selectedDay = value;
      this.param = { ...this.param, purchaseDateDay: this.searchRequest.purchaseDateDay };
      this.isFiltered = true;
      this.navigateWithParam();
    } else {
      this.searchRequest.purchaseDateDay = null;
    }
    this.pageChange(1);
  }

  openShortcut() {
    this.dialogService.open(ProspectShortcutComponent, {
      context: {
        streets: this._streets,
        districts: this.districts,
        cities: this.cities,
        streetId: this.searchRequest.streetId,
        districtId: this.searchRequest.districtId,
        cityId: this.searchRequest.cityId,
        companies: this.companies,
      },
    }).onClose.subscribe(res => {
      this.editDone(res);
    });
  }

  onRateChange(rate: number) {
    if (rate === -1) {
      this.searchRequest.rate = null;
    } else {
      this.searchRequest.rate = rate;
      this.param = { ...this.param, rate: this.searchRequest.rate };
      this.isFiltered = true;
      this.navigateWithParam();
    }
    this.pageChange(1);
  }

  onCityChange(cityId) {
    super.onCityChange(cityId);
    this.isFiltered = true;
    this.pageChange(1);
  }

  onSuburbChange(districtId) {
    super.onSuburbChange(districtId);
    this.isFiltered = true;
    this.pageChange(1);
  }

  onStreetChange(streetId) {
    super.onStreetChange(streetId);
    this.isFiltered = true;
    this.pageChange(1);
  }

  preLoadAddressForFilter() {
    if (this.route.snapshot.queryParamMap.keys.length === 0) {
      return;
    }
    const postcode = Number(this.route.snapshot.queryParamMap.get('postcode'));
    const districtId = this.route.snapshot.queryParamMap.get('districtId');
    if (postcode) {
      this.localService.districtByPostcode(postcode).subscribe(result => {
        this.filteredDistricts = result;
      });
    }
    if (districtId) {
      this.localService.streets(Number(districtId)).subscribe(result => {
        this.filteredStreets = result;
      });
    }
  }

  preLoadSelectedPurchaseDate() {
    if (this.route.snapshot.queryParamMap.keys.length === 0) {
      return;
    }
    const selectedMonth = this.route.snapshot.queryParamMap.get('purchaseDateMonth');
    const selectedDay = this.route.snapshot.queryParamMap.get('purchaseDateDay');
    if (selectedMonth) {
      this.selectedMonth = Number(selectedMonth);
    }
    if (selectedDay) {
      this.selectedDay = Number(selectedDay);
    }
  }

  appendFilterToParameter() {
    super.appendFilterToParameter();
    if (this.searchRequest.purchaseDateMonth) {
      this.param = { ...this.param, purchaseDateMonth: this.searchRequest.purchaseDateMonth };
    } else {
      delete this.param['purchaseDateMonth'];
      delete this.param['purchaseDateDay'];
    }
    if (this.searchRequest.purchaseDateDay) {
      this.param = { ...this.param, purchaseDateDay: this.searchRequest.purchaseDateDay };
    } else {
      delete this.param['purchaseDateDay'];
    }
    if (this.searchRequest.rate) {
      this.param = { ...this.param, rate: this.searchRequest.rate };
    } else {
      delete this.param['rate'];
    }
  }

  selectRecord(event: any, openEditDialog: boolean, addToSelected: boolean, jump?: boolean, forward?: boolean) {
    this.isFiltered = true;
    const wrapProspect: WrapProspect = event.data;
    let streetId = null;
    if (wrapProspect?.type === WrapProspectType.ITEM) {
      this.selectedProspect = wrapProspect?.data;
      this.highlightRow(this.selectedProspect?.prospectId);
      streetId = this.selectedProspect?.address?.street?.streetId;
      this.param = { ...this.param, selectedProspectId: this.selectedProspect.prospectId };
      if (this.isFiltered) {
        this.navigateWithParam();
      }
      // this.onSelectProspect.emit(this.selectedProspect.prospectId);
    } else if (wrapProspect?.type === WrapProspectType.GROUP) {
      this.currentWrapProspect = wrapProspect;
      if (this.param['selectedStreetId'] && this.currentWrapProspect.groupData.streetId !== this.param['selectedStreetId']) {
        delete this.param['selectedProspectId'];
      }
      this.param = { ...this.param, selectedStreetId: this.currentWrapProspect.groupData.streetId };
      if (this.noFilter === false) {
        this.navigateWithParam();
      }
      streetId = wrapProspect?.groupData?.streetId;
      // this.onSelectStreet.emit(this.currentWrapProspect.groupData.streetId);
    }

    // set selected prospects
    if (streetId && addToSelected) {
      this.source.getAll().then(d => {
        const wrapProspects: WrapProspect[] = d;
        this.selectedProspects = wrapProspects.filter(x => {
          return x.type === WrapProspectType.ITEM && x?.data?.address?.street?.streetId === streetId;
        })?.map(x => x.data);
      });
    }
  }
    closeDialog() {
    this.ref.close();
  }

  selectRow(event: any, el: HTMLElement) {
    const wrapProspect: WrapProspect = event.data;
    if (wrapProspect?.type === WrapProspectType.ITEM) {
      this.isDisplay = true;
    }
  }

  onSearchByPostcode(value: number) {
    super.onSearchByPostcode(value);
    if (this.searchRequest.postcode) {
      this.param = { ...this.param, postcode: this.searchRequest.postcode };
    } else {
      delete this.param['postcode'];
    }
    this.navigateWithParam();
    if (this.searchRequest.postcode === null) {
      delete this.param['postcode'];
    }
    this.pageChange(1);
  }
}
