<div class='row mb-3 p-2 width100'>
  <div class='col-5'>
    <input
      *appPermissionCheck='ComponentPermission.GLOBAL_FILTER_USER'
      [(ngModel)]='userName'
      [ngModelOptions]='{standalone: true}'
      [typeahead]='filterUser$'
      [typeaheadAsync]='true'
      (typeaheadOnSelect)='selectUser($event)'
      typeaheadOptionField='name'
      typeaheadWaitMs='1000'
      placeholder='Search User'
      nbInput fieldSize='tiny' fullWidth>
  </div>
  <div class='col-7' *ngIf='selectedUsers'>
    <div class='category-block' *ngFor='let user of selectedUsers'>
      {{ user && user.name ? user.name + ' (' + stringUtils.shortenUserName(user) + ')' : '' }}
      <button class='btn btn-danger btn-sm' (click)='removeUser(user.userId)'
              *appPermissionCheck='ComponentPermission.GLOBAL_FILTER_USER'>
        <i class='fa fa-times' aria-hidden='true'></i>
      </button>
    </div>
  </div>
</div>

<!--<nb-autocomplete #auto (selectedChange)="onUserFilterSelectionChange($event)">-->

<!--  <nb-option *ngFor="let user of filteredUsers | async" [value]="user.username">-->
<!--    {{ user.username + (user.name ? ' - ' + stringUtils.shortenUserName(user) : '') }}-->
<!--  </nb-option>-->

<!--</nb-autocomplete>-->
<!--{{ selectedUser && selectedUser.name ? selectedUser.name + ' (' + stringUtils.shortenUserName(selectedUser) + ')' : '' }}-->
<nb-accordion multi>
  <nb-accordion-item expanded>
    <nb-accordion-item-header>
      Activity
    </nb-accordion-item-header>
    <nb-accordion-item-body>
      <nb-card>
        <nb-card-header>
          <div>
            <div class='col-12 d-flex ml-2'>
              <div class='col-6'>
                <div class='col-12'>
                  <input [(ngModel)]='categoryName'
                         [ngModelOptions]='{standalone: true}'
                         [typeahead]='filterCategory$'
                         [typeaheadAsync]='true'
                         (typeaheadOnSelect)='selectCategory($event)'
                         typeaheadOptionField='name'
                         typeaheadWaitMs='1000'
                         placeholder='Tag'
                         nbInput fieldSize='tiny' fullWidth>
                </div>
              </div>
              <div class='col-6'>
                <div class='col-12'>
                  <div *ngFor='let category of activityCategories' class='category-block mt-2 mr-2'>
                    {{category.name}}
                    <button class='btn btn-danger btn-sm' (click)='removeCategory(category)'>
                      <i class='fa fa-times' aria-hidden='true'></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class='col-12 d-flex mt-2'>
              <div class='col-lg-6 col-sm-12'>
                <nb-select size='tiny' class='mt-1 ml-2' [(ngModel)]='selectedType'>
                  <nb-option *ngFor='let type of types' [value]='type'
                             (click)='onTypeChange(type)'>{{ type }}</nb-option>
                </nb-select>
                <nb-select size='tiny' [(ngModel)]='selectedNum' class='ml-2 mt-1'>
                  <nb-option *ngFor='let num of nums' [value]='num'
                             (click)='onNumChange(num)'>{{ num }}</nb-option>
                </nb-select>
                <nb-select size='tiny' class='mt-1 ml-2' [(ngModel)]='selectedYear'>
                  <nb-option *ngFor='let year of years' [value]='year'
                             (click)='onYearChange(year)'>{{ year }}</nb-option>
                </nb-select>
              </div>
              <div class='col-lg-6 col-sm-12'>
                <button class='fa-pull-right mt-2' nbButton status='primary' size='tiny' (click)='switchToTeamView()'
                        *ngIf='isTeamView === false'>
                  Switch to team view
                </button>
                <button class='fa-pull-right mt-2' nbButton status='primary' size='tiny' (click)='switchToNormal()'
                        *ngIf='isTeamView === true'>
                  Switch to normal view
                </button>
              </div>
            </div>
            <div class='col-12 d-flex mt-2 ml-3'>
              <span class='ml-2'>{{ startFrom | date: 'd/M/yy' }}</span>
              <span> - </span>
              <span>{{ endTo | date: 'd/M/yy' }}</span>
            </div>
          </div>
        </nb-card-header>

        <nb-card-body>
          <nb-list *ngIf='firstCols && prospectStatistics && isTeamView === false'>
            <nb-list-item class='item text-center'>
              <b class='item-header-1 text-center mr-3'>{{firstColLabel}}</b>
              <b class='item-header text-center' *ngFor='let item of headers'>{{ item }}</b>
            </nb-list-item>
            <nb-list-item class='item text-center' *ngFor='let firstCol of firstCols; let y = index;'>
              <b class='item-header-1 text-center mr-3'>{{firstCol}}</b>
              <span *ngFor='let header of headers let x = index;' (click)='selectMetric(x, y)'
                    class='metric text-center item-header'
                    [ngClass]="{
        'metric-active': selectedX === x && selectedY === y,
        'metric-inactive': selectedX !== x || selectedY !== y,
        'metric-client': x <= 9,
        'metric-customer': x > 9
        }">
          {{ prospectStatistics[firstCol] && prospectStatistics[firstCol]['total' + header] !== 0 ? prospectStatistics[firstCol]['total' + header] : "-"}}
              </span>
            </nb-list-item>
          </nb-list>

          <nb-list *ngIf='firstCols && statisticTeam && isTeamView === true'>
            <nb-list-item class='item text-center'>
              <b class='item-header-1 mr-1'>{{firstColLabel}}</b>
              <b class='item-header' *ngFor='let item of headers'>{{ item }}</b>
            </nb-list-item>
            <nb-list-item class='item text-center' *ngFor='let firstCol of firstCols; let y = index;'>
              <b class='item-header-1 mr-3'>{{firstCol}}</b>
              <span *ngFor='let header of headers let x = index;' (click)='selectMetric(x, y)'
                    class='metric text-center item-header'
                    [ngClass]="{
        'metric-active': selectedX === x && selectedY === y,
        'metric-inactive': selectedX !== x || selectedY !== y,
        'metric-client': x <= 9,
        'metric-customer': x > 9
        }">
          {{ statisticTeam[y] && statisticTeam[y].statistic['total' + header] !== 0 ? statisticTeam[y].statistic['total' + header] : "-"}}
              </span>
            </nb-list-item>
          </nb-list>
          <div class='d-flex mt-2' *ngIf='selectedUsers.length === 0 && isTeamView === true'>
            <div class='col-12 text-center'>
              Please select at least 1 user
            </div>
          </div>
        </nb-card-body>

      </nb-card>

      <div class='card-columns cols-2' *ngIf='isTeamView === false'>
        <div class='card' *ngIf='lineChartData'>
          <div class='card-body'>
            <div class='chart-wrapper'>
              <canvas baseChart class='chart'
                      [datasets]='lineChartData'
                      [labels]='lineChartLabels'
                      [options]='lineChartOptions'
                      [colors]='lineChartColours'
                      [legend]='lineChartLegend'
                      [chartType]='lineChartType'></canvas>
            </div>
          </div>
        </div>
        <div class='card' *ngIf='pieChartData && pieChartData.length > 1'>
          <div class='card-body'>
            <div class='chart-wrapper'>
              <canvas baseChart class='chart'
                      [data]='pieChartData'
                      [colors]='pieChartColors'
                      [labels]='pieChartLabels'
                      [chartType]='pieChartType'></canvas>
            </div>
          </div>
        </div>
      </div>
    </nb-accordion-item-body>
  </nb-accordion-item>
  <nb-accordion-item>
    <nb-accordion-item-header (click)="loadClient = !loadClient">
      Client
    </nb-accordion-item-header>
    <nb-accordion-item-body>
      <div class='mt-1 mb-2'>
        <div class='mt-2'>
          <div>
            <button *ngFor='let button of prospectTagButtons' nbButton [status]="button.selected? 'primary' : 'basic'"
                    size='tiny' value='{{button.value}}'
                    (click)='selectProspectTag(button.value)' class='mr-1'>{{button.key}}
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="loadClient">
        <ngx-prospect-list-basic
          [isFilter]=false
          [isLoad]=false
          [companies]='companies'
          [taskTypes]='taskTypes'
          [oneOnOneCall]='true'
        ></ngx-prospect-list-basic>
      </div>
    </nb-accordion-item-body>
  </nb-accordion-item>
  <nb-accordion-item>
    <nb-accordion-item-header (click)="loadCustomer = !loadCustomer">
      Customer
    </nb-accordion-item-header>
    <nb-accordion-item-body>
      <div class='mt-1 mb-2'>
        <div class='mt-2'>
          <div>
            <button *ngFor='let button of buyerTagButtons' nbButton [status]="button.selected? 'primary' : 'basic'"
                    size='tiny' value='{{button.value}}'
                    (click)='selectBuyerTag(button.value)' class='mr-1'>{{button.key}}
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="loadCustomer">
        <ngx-buyer-list
          [oneOnOneCall]=true
        >
        </ngx-buyer-list>
      </div>
    </nb-accordion-item-body>
  </nb-accordion-item>
  <nb-accordion-item *appPermissionCheck='ComponentPermission.DASHBOARD_RATIO'>
    <nb-accordion-item-header (click)="loadRatio = !loadRatio">
      Ratio
    </nb-accordion-item-header>
    <nb-accordion-item-body>
      <div *ngIf="loadRatio">
        <ngx-ratio
          [selectedUsers]='selectedUsers'
          [selectedCategories]='activityCategories'
        ></ngx-ratio>
      </div>
    </nb-accordion-item-body>
  </nb-accordion-item>
</nb-accordion>

