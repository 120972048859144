export class StatusUtils {

  // highlight by condition
  static highlightRow(data: any) {
    let result = '';
    if (data.status === 'INACTIVE' || data.status === 'DEACTIVE') {
      result += ' wot';
    }
    return result;
  }

  static isSuccessResponse(response) {
    return (response && response.status === 'success');
  }
}
