import { Component, EventEmitter, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { Constant } from '../../../shared/common/constant';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../services/common.service';
import { NbDatepickerComponent, NbDialogRef, NbDialogService } from '@nebular/theme';
import { ToastService } from '../../services/toast.service';
import { FormService } from '../../services/form.service';
import { TaskMiniDialogComponent } from '../../../modules/pages/task/task-mini-dialog/task-mini-dialog.component';
import { TaskType } from '../../models/response/task-type';
import { Buyer } from '../../models/response/buyer/buyer';
import { BuyerService } from '../../services/buyer.service';
import { BuyerRequest } from '../../models/request/buyer/buyer-request';
import { Property } from '../../models/response/prospect/property';
import { Customer } from '../../models/response/prospect/customer';
import { District } from '../../models/response/address/district';
import { Street } from '../../models/response/address/street';
import { City } from '../../models/response/address/city';
import { ProspectPickComponent } from './prospect-pick/prospect-pick.component';
import { TaskStatus } from '../../models/response/task-status';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map, mergeMap } from 'rxjs/operators';
import { BuyerTaskService } from '../../services/buyer-task.service';
import { BuyerTask } from '../../models/response/buyer/buyer-task';
import { SearchRequest } from '../../models/request/search-request';
import { StringUtils } from '../../../shared/common/string-utils';
import { BuyerContactRequest } from '../../models/request/buyer/buyer-contact-request';
import { InterestDistrict } from '../../models/response/buyer/interest-district';
import { InterestZone } from '../../models/response/buyer/interest-zone';
import { DistrictPickComponent } from './district-pick/district-pick.component';
import { ZonePickComponent } from './zone-pick/zone-pick.component';
import { BuyerDeposit } from '../../models/response/buyer/buyer-deposit';
import { BuyerDepositService } from '../../services/buyer-deposit.service';
import { InterestZoneService } from '../..//services/interest-zone.service';
import { InterestDistrictService } from '../../services/interest-district.service';
import { BuyerLog } from '../../models/response/buyer/buyer-log';
import { ProspectService } from '../../services/prospect.service';
import { LocalService } from '../../services/local.service';
import { CustomerPickComponent } from './customer-pick/customer-pick.component';
import { SuburbService } from '../../services/suburb.service';
import { RestResult } from '../../models/response/rest-result';
import { Address } from '../../models/response/address/address';
import { Zone } from '../../models/response/address/zone';
import * as moment from 'moment';
import { CategoryService } from '../../services/category.service';
import { Category } from '../../models/response/category';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Triple } from '../../models/request/triple';
import { User } from '../../models/response/user';
import { AuthService } from '../../services/auth.service';
import { Office } from '../../models/response/office';
import { OfficeService } from '../../services/office.service';
import { CustomerLinkGroup } from '../../models/response/customer-link-group';
import { CustomerService } from '../../services/customer.service';
import { Prospect } from '../../models/response/prospect/prospect';
import { ProspectDialogComponent } from '../prospect-dialog/prospect-dialog.component';
import { ComponentPermission } from '../../../component.permission';
import { BuyerLookupComponent } from './buyer-lookup/buyer-lookup.component';
import { CustomerDuplicatePhone } from '../../models/response/prospect/customer-duplicate-phone';
import { CustomerSearchRequest } from '../../models/request/prospect/customer-search-request';
import { AttachmentComponent } from '../attachment/attachment.component';
import { ProspectSearchRequest } from '../../models/request/prospect/prospect-search-request';
import {
  ProspectListBasicComponent,
} from '../../../modules/pages/prospect/prospect-list-basic/prospect-list-basic.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerAdditionalInfo } from '../../models/response/prospect/customer-additional-info';
import { Postcode } from '../../models/response/postcode';
import {resultList, RxSpeechRecognitionService} from '@kamiazya/ngx-speech-recognition';
import {OfficePropertyService} from '../../services/office-property.service';

@Component({
  selector: 'ngx-buyer-create',
  templateUrl: './buyer-create.component.html',
  styleUrls: ['./buyer-create.component.scss'],
})

export class BuyerCreateComponent implements OnInit {
  @Input() cities: City[];
  @Input() showFooter?: boolean;
  @Output() editDone = new EventEmitter();
  @Output() newDone = new EventEmitter();
  @Output() reserveBuyer = new EventEmitter();
  @Output() ownSet = new EventEmitter();
  @Output() navigateBuyerEvent = new EventEmitter();
  @Input() taskTypes: TaskType[];
  @Input() taskStatuses: TaskStatus[];
  @Input() isInjected = false;
  onClose: any;
  dobText = '';
  buyerId: number;
  showCommon = false;
  showAddress = false;
  showNumber = false;
  showInspection = false;
  showENQ = true;
  showHistory = false;
  filterByPostcode = false;
  postcode: number;
  filterPostcode$: Observable<Postcode[]>;

  @Input()
  set selectedBuyer(value) {
    if (value) {
      this.buyerId = value.buyerId;
      this.setBuyer(value);
    }
  }

  // filtered when search
  filteredStreets: Street[];
  filteredDistricts: District[];
  buyers: Buyer[];

  propertyType = Constant.PROPERTY_TYPE;
  propertyDefaultSpec = Constant.PROPERTY_DEFAULT_SPEC;
  defaultCurrencyIcon = Constant.DEFAULT_CURRENCY_ICON;
  buyerForm: FormGroup;
  buyerTasks: BuyerTask[];
  buyerDeposits: BuyerDeposit[];
  interestDistricts: InterestDistrict[];
  interestZones: InterestZone[];
  data: Buyer;
  code1: string;
  code2: string;
  code3: string;
  code4: string;
  dateFormat = 'DD/MM/YYYY';
  address = '';
  isSubmitted = false;
  isUpdating = false;
  isDeposit = false;
  bedValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  getAddressName = StringUtils.getFullAddress;
  getShortName = StringUtils.getShortName;
  selectedAddress: string;
  selectedTaskId: number;
  activeType = Constant.ACTIVE_TYPE;
  weekCount = 0;

  filterCategory$: Observable<Category[]>;
  categoryName: string;
  selectedCategories: Category[] = [];
  currentUser: User;

  filterOffice$: Observable<Office[]>;
  officeName: string;
  selectedOffice: Office;
  minBuyValue: number;
  maxBuyValue: number;
  minRentValue: number;
  maxRentValue: number;
  isEditing = true;
  linkedGroup: CustomerLinkGroup[] = [];
  phoneDuplicate: CustomerDuplicatePhone;
  mobiPhoneDuplicate: CustomerDuplicatePhone;
  ComponentPermission = ComponentPermission;
  @ViewChild(AttachmentComponent) attachmentComponent: AttachmentComponent;
  additionalInfo: CustomerAdditionalInfo[] = [];
  interestPostcode: string;

  new() {
    this.ownSet.emit(null);
    this.isEditing = true;
  }

  outdoor() {
    const buyer = new Buyer();
    buyer.isFirstHome = true;
    buyer.property = { ...buyer.property, ...this.buyerForm.value };
    buyer.address = {
      ...buyer.address,
      ...this.buyerForm.value,
    };
    if (buyer.property) {
      buyer.property.propertyId = null;
      buyer.property.noHouse = 0;
      buyer.property.noWeek = 0;
      buyer.property.dayOut = 0;
      buyer.property.monthIn = 0;
      buyer.property.yearIn = 0;
      buyer.property.buyer = true;
      if (buyer.address) {
        buyer.address.addressId = null;
      }
    }
    const prospectId = this.data.prospectId;
    buyer.prospectId = prospectId;
    buyer.customer = new Customer();
    this.mergeDistrict(buyer);
    this.mergeZones(buyer);
    this.reserveBuyer.emit(buyer);
  }

  setBuyers(buyers: Buyer[]) {
    this.buyers = buyers;
  }

  setBuyer(value: Buyer) {
    this.resetData();
    if (!value) {
      this.initBuyerDeposit(false);
      this.initForm();
    } else {
      this.buyerService.getOne(value.buyerId).subscribe(result => {
        value = result.data;
        this.isEditing = !value?.customer?.link;
        this.data = value;
        this.selectedCategories = value.categories;
        this.initForm();
        this.loadBuyerTask();
        this.loadBuyerDeposit();
        this.loadInterestZone();
        this.loadInterestDistrict();
        this.filterAddress();
        this.setBuyerCode();
        if (value.customer) {
          this.fetchAllLinkedCustomer(value.customer?.customerId);
          this.lookupPhone(this.data?.customer?.phone, 'PHONE');
          this.lookupPhone(this.data?.customer?.mobilePhone, 'MPHONE');
        }
        if (this.data.customer.customerAdditionalInfo && this.data.customer.customerAdditionalInfo.length > 0) {
          this.additionalInfo = this.data.customer.customerAdditionalInfo;
        }
        this.postcode = this.data?.address?.postCode;
        this.searchSuburbByPostcode(false);
      });
    }
    if (this.attachmentComponent) {
      this.attachmentComponent.reloadImages(value.buyerId);
    }
  }

  contactLogType = {
    NO_ANSWER: 'NO_ANSWER',
    CALLED: 'CALLED',
    SMS: 'SMS',
    DOOR_NOCKED: 'DOOR_NOCKED',
  };
  lastCalledPhoneNumber = '';
  totalLinked = 0;
  isDialog: boolean = false;
  isProspect = false;

  constructor(private toastService: ToastService,
              private buyerService: BuyerService,
              private buyerDepositService: BuyerDepositService,
              private prospectService: ProspectService,
              private interestZoneService: InterestZoneService,
              private categoryService: CategoryService,
              private interestDistrictService: InterestDistrictService,
              private buyerTaskService: BuyerTaskService,
              private formService: FormService,
              private commonService: CommonService,
              private dialogService: NbDialogService,
              private localService: LocalService,
              private suburbService: SuburbService,
              public service: RxSpeechRecognitionService,
              private authService: AuthService,
              private officeService: OfficeService,
              private customerService: CustomerService,
              private route: ActivatedRoute,
              private router: Router,
              @Optional() private ref: NbDialogRef<BuyerCreateComponent>,
              private officePropertyService: OfficePropertyService
  ) {
  }

  ngOnInit(): void {
    this.currentUser = this.authService.currentUser;
    this.filterCategory$ = new Observable((observer: any) => {
      observer.next(this.categoryName);
    }).pipe(
      mergeMap((query: string) => this.searchCategory(query)),
    );
    this.filterOffice$ = new Observable((observer: any) => {
      observer.next(this.officeName);
    }).pipe(
      mergeMap((query: string) => this.searchOffice(query)),
    );
    this.setDefaultPriceValue();
    this.setBuyer(this.data ? this.data : null);
    this.filterPostcode$ = new Observable((observer: any) => {
      observer.next(this.postcode);
    }).pipe(
      map((query: string) => {
        this.postcode = Number(query);
        this.searchSuburbByPostcode(true);
        return null;
      }),
    );
  }
  listening = false;
  subscription;
  listen(): void {
    this.listening = true;

    this.subscription = this.service
      .listen()
      .pipe(resultList)
      .subscribe((list: SpeechRecognitionResultList) => {
        this.listening = false;

        let message = list.item(0).item(0).transcript;

        if (message === 'apagar' || message === 'limpar') {
          message = '';
        }
        if (this.buyerForm.controls.note.value == null) {
          this.buyerForm.controls.note.setValue('');
        }
        this.buyerForm.controls.note.setValue(this.buyerForm.controls.note.value + ' ' + message);
      });
  }

  setDefaultPriceValue() {
    if (this.currentUser.office && this.currentUser.office.officeProperty) {
      this.minBuyValue = this.currentUser.office.officeProperty.minBuyPrice;
      this.maxBuyValue = this.currentUser.office.officeProperty.maxBuyPrice;
      this.minRentValue = this.currentUser.office.officeProperty.minRentalPrice;
      this.maxRentValue = this.currentUser.office.officeProperty.maxRentalPrice;
    }
  }

  filterAddress() {
    if (this.buyerForm.get('cityId').value) {
      this.onCitySelectChange(this.buyerForm.get('cityId').value);
    }

    if (this.buyerForm.get('districtId').value) {
      this.onSuburbSelectChange(this.buyerForm.get('districtId').value, true);
    }
  }

  loadBuyerTask() {
    this.buyerTasks = null;
    if (this.data.buyerId) {
      const searchRequest = new SearchRequest();
      searchRequest.conditions = new Array();
      searchRequest.conditions.push({
        left: 'buyerId',
        middle: '=',
        right: this.data?.buyerId,
      });
      this.buyerTaskService.search(searchRequest).subscribe(result => {
        if (this.isSuccessResponse(result)) {
          this.buyerTasks = result.data;
          this.buyerTasks.forEach(buyerTask => {
            if (buyerTask.isExc) {
              buyerTask.isExc = true;
              this.selectedAddress = this.getAddressName(buyerTask.task?.prospect?.address);
              this.isDeposit = true;
              this.selectedTaskId = buyerTask.taskId;
              this.autoSetBuyerRate();
            }
          });
        }
      });
    }
  }

  initForm() {
    this.isSubmitted = false;
    this.buyerForm = new FormGroup({
      // === Buyer Code
      rate: new FormControl(typeof this.data?.rate !== 'undefined' && this.data?.rate !== null ? this.data?.rate : 0),
      buyerCode: new FormControl(this.data?.buyerCode ? this.data?.buyerCode : 'B'),
      note: new FormControl(this.data?.note, [
        Validators.maxLength(2000),
      ]),
      call: new FormControl(this.data?.call),
      sms: new FormControl(this.data?.sms),
      door: new FormControl(this.data?.door),
      mail: new FormControl(this.data?.mail),
      isCommon: new FormControl(this.data?.isCommon),
      isAddress: new FormControl(this.data?.isAddress),
      isNumber: new FormControl(this.data?.isNumber),
      isInspection: new FormControl(this.data?.isInspection),
      isDeposit: new FormControl(this.data?.isDeposit),
      isActive: new FormControl(this.data?.isActive),
      isFirstHome: new FormControl(this.data?.isFirstHome),
      isInvestor: new FormControl(this.data?.isInvestor),
      isValueBuyer: new FormControl(this.data?.isValueBuyer),
      isDeveloper: new FormControl(this.data?.isDeveloper),
      clientTenant: new FormControl(this.data?.clientTenant),
      clientSeller: new FormControl(this.data?.clientSeller),
      enq: new FormControl(this.data?.enq),
      isLocal: new FormControl(this.data?.isLocal),
      isWot: new FormControl(this.data?.isWot),
      lastApp: new FormControl(this.data?.lastApp),
      lastContact: new FormControl(this.data?.lastContact),
      enqAddress: new FormControl(''),
      prospectId: new FormControl(this.data?.prospectId),
      // === Customer
      name: new FormControl(this.data?.customer?.name, [
        Validators.maxLength(200), Validators.required,
      ]),
      surname: new FormControl(this.data?.customer?.surname, [
        Validators.maxLength(200),
      ]),
      phone: new FormControl(this.data?.customer?.phone, [
        Validators.maxLength(20),
      ]),
      mobilePhone: new FormControl(this.data?.customer?.mobilePhone, [
        Validators.maxLength(20),
      ]),
      email: new FormControl(this.data?.customer?.email, [
        Validators.maxLength(200),
      ]),
      dob: new FormControl(this.data?.customer?.dob ? new Date(this.data?.customer?.dob) : ''),
      // === Common
      sell: new FormControl(this.data?.property?.sell),
      rent: new FormControl(this.data?.property?.rent),
      otm: new FormControl(this.data?.property?.otm),
      vac: new FormControl(this.data?.property?.vac),
      buyer: new FormControl(this.data?.property?.buyer),
      tenant: new FormControl(this.data?.property?.tenant),
      enqDate: new FormControl(this.data?.property?.enqDate ? new Date(this.data?.property?.enqDate) : new Date()),
      enqDate1: new FormControl(this.data?.property?.enqDate ? new Date(this.data?.property?.enqDate) : new Date()),
      enqDate2: new FormControl(this.data?.property?.enqDate ? new Date(this.data?.property?.enqDate) : new Date()),
      firstEnqDate: new FormControl(
        this.data?.property?.firstEnqDate ? new Date(this.data?.property?.firstEnqDate) : new Date(),
      ),
      minBuyPrice: new FormControl(this.data?.property?.minBuyPrice ? this.data?.property?.minBuyPrice : 0),
      maxBuyPrice: new FormControl(this.data?.property?.maxBuyPrice ? this.data?.property?.maxBuyPrice : 0),
      bed: new FormControl(this.data?.property?.bed),
      bath: new FormControl(this.data?.property?.bath),
      car: new FormControl(this.data?.property?.car),
      land: new FormControl(this.data?.property?.land),
      type: new FormControl(this.data?.property?.type),
      yearIn: new FormControl(this.data?.property?.yearIn),
      monthIn: new FormControl(this.data?.property?.monthIn),
      weekOut: new FormControl(this.data?.property?.weekOut),
      dayOut: new FormControl(this.data?.property?.dayOut),
      propertyOfficeId: new FormControl(this.data?.property?.office?.officeId),
      // === Number
      noWeek: new FormControl(this.data?.property?.noWeek),
      noHouse: new FormControl(this.data?.property?.noHouse),
      // === Address
      unit: new FormControl(this.data?.address?.unit),
      numberFrom: new FormControl(this.data?.address?.numberFrom),
      numberFromLetter: new FormControl(this.data?.address?.numberFromLetter),
      numberTo: new FormControl(this.data?.address?.numberTo),
      numberToLetter: new FormControl(this.data?.address?.numberToLetter),
      cityId: new FormControl(this.data?.address?.cityId),
      zoneId: new FormControl(this.data?.address?.street?.district?.zone?.zoneId),
      districtId: new FormControl(this.data?.address?.districtId),
      farmId: new FormControl(this.data?.address?.street?.farm?.farmId),
      streetId: new FormControl(this.data?.address?.streetId),
      addressOfficeId: new FormControl(this.data?.address?.office?.officeId),
      callbackDate: new FormControl(this.data?.customer?.callbackDate),
      officeId: new FormControl(this.data?.office?.officeId),
      link: new FormControl(this.data?.customer?.link),
    });
    this.officeName = this.data?.office?.name;
    this.dobText = this.getDobText(this.buyerForm.controls.dob.value);

    if (this.data?.prospectId) {
      this.prospectService.getOne(this.data.prospectId).subscribe(result => {
        if (this.isSuccessResponse(result)) {
          this.buyerForm.get('enqAddress').setValue(StringUtils.getFullAddress(result.data.address));
          this.syncPostCode();
        }
      });
    }

    if (this.buyerForm.controls.buyer.value) {
      if (!this.buyerForm.get('minBuyPrice').value) {
        this.buyerForm.controls.minBuyPrice.setValue(this.minBuyValue);
      }
      if (!this.buyerForm.get('maxBuyPrice').value) {
        this.buyerForm.controls.maxBuyPrice.setValue(this.maxBuyValue);
      }
    }

    if (!this.buyerForm.get('type').value) {
      const defaultSpec = this.propertyDefaultSpec['HOUSE'];
      this.buyerForm.controls.type.setValue('HOUSE');
      this.buyerForm.controls.bed.setValue(defaultSpec.bed);
      this.buyerForm.controls.bath.setValue(defaultSpec.bath);
      this.buyerForm.controls.car.setValue(defaultSpec.car);
      this.buyerForm.controls.land.setValue(defaultSpec.land);
    }

    if (!this.buyerForm.get('firstEnqDate').value) {
      this.weekCount = this.getWeekCount(this.buyerForm.get('firstEnqDate').value);
    }
    // auto set buyer level
    this.buyerForm.controls.numberFrom.valueChanges.subscribe(value => {
      this.setBuyerCode();
    });
    this.buyerForm.controls.enqDate1.valueChanges.subscribe(value => {
      this.buyerForm.get('enqDate').setValue(value);
      this.buyerForm.get('enqDate2').setValue(value, { emitEvent: false });
    });
    this.buyerForm.controls.enqDate2.valueChanges.subscribe(value => {
      this.buyerForm.get('enqDate').setValue(value);
      this.buyerForm.get('enqDate1').setValue(value, { emitEvent: false });
    });
    this.buyerForm.controls.firstEnqDate.valueChanges.subscribe(value => {
      this.weekCount = this.getWeekCount(value);
    });
    this.buyerForm.controls.isActive.valueChanges.subscribe(value => {
      this.changeCode(value);
    });
    this.buyerForm.controls.dob.valueChanges.subscribe(value => {
      this.dobText = this.getDobText(value);
    });
    if (this.data?.customer?.link) {
      this.isEditing = false;
    }
  }

  getDobText(value: any): string {
    if (!value || value === '') {
      return '';
    }
    let time = 0;
    if (value instanceof Date) {
      time = (value as Date).getTime();
    } else {
      time = this.commonService.parseDate(value, this.dateFormat);
    }
    // calculate years
    const yearText = this.getTimeDiffText(time, new Date().getTime());
    // calculate how long to dob date in this year
    const remainingText = this.getTimeDiffText(new Date().getTime(),
      moment(time).year(new Date().getFullYear()).valueOf());
    const result = [];
    if (yearText) {
      result.push(yearText);
    }
    if (remainingText) {
      result.push(remainingText);
    }
    return result.join(' - ');
  }

  getTimeDiffText(from: number, to: number) {
    const years = moment(to).diff(moment(from), 'years');
    const months = moment(to).diff(moment(from), 'months');
    const remainingMonths = moment(to).subtract(years, 'years').diff(moment(from), 'months');
    const remainingDays = moment(to).subtract(months, 'months').diff(moment(from), 'days');
    let result = '';
    if (years > 0) {
      result += years + ' years' + ' ';
    }
    if (remainingMonths > 0) {
      result += remainingMonths + ' months' + ' ';
    }
    if (remainingDays > 0) {
      result += remainingDays + ' days' + ' ';
    }
    return result;
  }

  submit(callback?: any) {
    this.isUpdating = true;
    this.formService.extractErrorMessage(this.buyerForm);
    if (this.buyerForm.invalid) {
      this.isSubmitted = true;
      this.isUpdating = false;
      return;
    }

    const buyerRequest = this.getRequest();
    if (this.data && this.data.buyerId) {
      buyerRequest.buyerId = this.data.buyerId;
      this.buyerService.update(buyerRequest).subscribe(
        result => {
          if (result.data.rate === 7) {
            this.updateProspectInfoS3();
          }
          this.commonService.info('Updated buyer success.');
          if (this.attachmentComponent) {
            this.attachmentComponent.uploadBatch(this.data.buyerId, Constant.ATTACHMENT_TYPE.BUYER, true);
          }
          this.isUpdating = false;
          this.data = result.data;
          this.postcode = this.data?.address?.postCode;
          this.synInterestZone();
          this.syncInterestDistrict();
          this.syncBuyerTask();
          this.syncBuyerDeposit();
          this.loadAddress();
          if (callback) {
            callback();
          } else {
            this.close(result);
          }
          if (this.isDialog) {
            this.ref.close(this.data);
          }
        },
        error => {
          this.isUpdating = false;
          this.commonService.warningHtml(error.message);
        },
      );
    } else {
      this.buyerService.create(buyerRequest).subscribe(
        result => {
          this.commonService.info('Create Customer success.');
          this.isUpdating = false;
          this.data = result.data;
          this.synInterestZone();
          this.syncInterestDistrict();
          this.syncBuyerTask();
          this.syncBuyerDeposit();
          if (callback) {
            callback();
          } else {
            this.closeNew(result);
          }
          if (this.isDialog) {
            this.setBuyer(result.data);
          }
        },
        error => {
          this.isUpdating = false;
          this.commonService.warningHtml(error.message);
        },
      );
    }
  }

  getRequest(): BuyerRequest {
    if (!this.buyerForm.value.surname) {
      this.buyerForm.value.surname = '*';
      this.buyerForm.get('surname').setValue('*');
    }
    if (this.buyerForm.controls.dob.value &&
      this.buyerForm.controls.dob.value !== '' &&
      isNaN(Number(this.buyerForm.controls.dob.value))
    ) {
      this.buyerForm.controls.dob
        .setValue(this.commonService.parseDate(this.buyerForm.controls.dob.value, this.dateFormat));
    }

    const buyerRequest: BuyerRequest = {
      ...this.data,
      ...this.buyerForm.value,
    };
    buyerRequest.userId = this.currentUser.userId;
    buyerRequest.customer.customerAdditionalInfo = this.additionalInfo;
    buyerRequest.customer = { ...buyerRequest.customer, ...this.buyerForm.value };
    buyerRequest.property = { ...buyerRequest.property, ...this.buyerForm.value };
    buyerRequest.address = {
      ...buyerRequest.address,
      ...this.buyerForm.value,
    };
    if (this.postcode) {
      buyerRequest.address.postCode = this.postcode;
    }
    if (this.selectedCategories?.length > 0) {
      buyerRequest.categoryIds = this.selectedCategories.map((category) => category.categoryId);
    }
    // this.trimObject(buyerRequest, Object.keys(BuyerRequest.getDummy()));
    // this.trimObject(buyerRequest.customer, Object.keys(CustomerRequest.getDummy()));
    // this.trimObject(buyerRequest.property, Object.keys(PropertyRequest.getDummy()));
    // this.trimObject(buyerRequest.address, Object.keys(AddressRequest.getDummy()));
    if (this.selectedOffice) {
      buyerRequest.officeId = this.selectedOffice.officeId;
      buyerRequest.property.officeId = this.selectedOffice.officeId;
      buyerRequest.address.officeId = this.selectedOffice.officeId;
    } else {
      buyerRequest.officeId = this.currentUser.office?.officeId;
      buyerRequest.property.officeId = this.currentUser.office?.officeId;
      buyerRequest.address.officeId = this.currentUser.office?.officeId;
    }
    if (buyerRequest.rate === 7) {
      buyerRequest.isActive = 'INACTIVE';
    }
    return buyerRequest;
  }

  searchCategory(name: string): Observable<Category[]> {
    if (!name || name === '') {
      return of([]);
    }
    const searchRequest = new SearchRequest();
    searchRequest.conditions = new Array();

    searchRequest.conditions.push(new Triple('name', 'like', name));
    const subCon = new SearchRequest();
    subCon.conditionType = 'OR';
    subCon.conditions = [];
    subCon.conditions.push(new Triple('type', '=', 'BUYER'));
    subCon.conditions.push(new Triple('type', '=', 'BOTH'));
    searchRequest.subConditions.push(subCon);
    return this.categoryService.search(searchRequest).pipe(
      map(result => result.data),
    );
  }

  selectCategory(type: TypeaheadMatch) {
    this.categoryName = '';
    const selectedCategory: Category = type.item;
    if (this.selectedCategories.filter(category => category.categoryId === selectedCategory.categoryId).length > 0) {
      return;
    }
    this.selectedCategories.push(selectedCategory);
  }

  removeCategory(removedCategory: Category) {
    if (removedCategory) {
      this.selectedCategories = this.selectedCategories.filter
      (category => category.categoryId !== removedCategory.categoryId);
    }
  }

  searchOffice(keyword: String): Observable<Office[]> {
    const searchRequest = new SearchRequest();
    searchRequest.conditions = [];
    searchRequest.conditions.push(
      {
        left: 'name',
        middle: 'like',
        right: keyword,
      },
    );
    searchRequest.offset = 0;
    searchRequest.limit = 10;
    return this.officeService.search(searchRequest).pipe(
      map(result => result.data),
    );
  }

  selectOffice(type: TypeaheadMatch) {
    this.officeName = type.item.name;
    this.selectedOffice = type.item;
    this.buyerForm.controls.officeId.patchValue(type.item.officeId);
  }

  syncBuyerTask() {
    if (this.buyerTasks && this.buyerTasks.length > 0) {
      this.buyerTasks.forEach(buyerTask => {
        buyerTask.buyerId = this.data.buyerId;
      });
      this.buyerTaskService.createOrUpdate(this.buyerTasks).subscribe(
        result => {
          this.isUpdating = false;
          // this.buyerTasks = result.data;
        },
        error => {
          this.isUpdating = false;
          this.commonService.warningHtml(error.message);
        },
      );
    }
  }

  syncBuyerDeposit() {
    if (this.buyerDeposits && this.buyerDeposits.length > 0) {
      this.buyerDeposits.forEach(buyerDeposit => {
        buyerDeposit.buyerId = this.data.buyerId;
      });
      this.buyerDepositService.createOrUpdate(this.buyerDeposits).subscribe(
        result => {
          this.isUpdating = false;
          this.buyerDeposits = result.data;
        },
        error => {
          this.isUpdating = false;
          this.commonService.warningHtml(error.message);
        },
      );
    }
  }

  syncInterestDistrict() {
    if (this.interestDistricts && this.interestDistricts.length > 0) {
      this.interestDistricts.forEach(interestDistrict => {
        interestDistrict.buyerId = this.data.buyerId;
      });
      this.interestDistrictService.createOrUpdate(this.data.buyerId, this.interestDistricts).subscribe(
        result => {
          result.data.forEach(element => {
            const index = this.interestDistricts.findIndex(temp => temp.districtId === element.districtId);
            if (index >= 0) {
              this.interestDistricts[index].interestDistrictId = element.interestDistrictId;
            }
          });
          this.isUpdating = false;
        },
        error => {
          this.isUpdating = false;
          this.commonService.warningHtml(error.message);
        },
      );
    }
  }

  synInterestZone() {
    if (this.interestZones && this.interestZones.length > 0) {
      this.interestZones.forEach(interestZone => {
        interestZone.buyerId = this.data.buyerId;
      });
      this.interestZoneService.createOrUpdate(this.data.buyerId, this.interestZones).subscribe(
        result => {
          result.data.forEach(element => {
            const index = this.interestZones.findIndex(temp => temp.zoneId === element.zoneId);
            if (index >= 0) {
              this.interestZones[index].interestZoneId = element.interestZoneId;
            }
          });
          this.isUpdating = false;
        },
        error => {
          this.isUpdating = false;
          this.commonService.warningHtml(error.message);
        },
      );
    }
  }

  delete() {
    if (!this.data) {
      return;
    }
    // this.dialogService.open(ConfirmDialogComponent, {
    //   context: {
    //     content: 'Do you really want to delete?',
    //   },
    // })
    // .onClose.subscribe(res => {
    //   if (res === ConfirmDialogComponent.confirmOk) {
    //     this.buyerService.remove(this.data.buyerId).subscribe(
    //       result => {
    //         this.toastService.success('Delete successfully');
    //         this.close();
    //       }, error => {
    //         this.toastService.error(error.message);
    //       });
    //   }
    // });
  }

  close(result?: any) {
    if (this.onClose) {
      this.onClose(result);
    } else {
      this.editDone.emit(result);
    }
  }

  closeNew(result?: any) {
    if (this.onClose) {
      this.onClose(result);
    } else {
      this.newDone.emit(result);
    }
  }

  // ==== property type ===
  onPropertyTypeChange() {
    const type = this.buyerForm.controls.type.value;
    if (type) {
      const defaultSpec = this.propertyDefaultSpec[type];
      if (defaultSpec) {
        this.buyerForm.controls.bed.setValue(defaultSpec.bed);
        this.buyerForm.controls.bath.setValue(defaultSpec.bath);
        this.buyerForm.controls.car.setValue(defaultSpec.car);
        this.buyerForm.controls.land.setValue(defaultSpec.land);
      }
      if (type === 'HOUSE_COM') {
        this.buyerForm.controls.sell.setValue(false);
        this.buyerForm.controls.rent.setValue(false);
        this.buyerForm.controls.otm.setValue(false);
        this.buyerForm.controls.vac.setValue(false);
      }
    }
  }

  checkIsActive(first: string, second: string) {
    if (this.buyerForm.get(first).value !== true) {
      this.buyerForm.get('isActive').setValue(true);
    } else {
      if (this.buyerForm.get(second).value === true) {
        return;
      } else {
        this.buyerForm.get('isActive').setValue(false);
      }
    }
  }

  setWot(controlName: string) {
    if (this.buyerForm.get(controlName).value !== true) {
      this.buyerForm.get(controlName).setValue(true);
    } else {
      this.buyerForm.get(controlName).setValue(false);
    }
  }

  setSquareValue(controlName: string) {
    // if (controlName === 'buyer') {
    //   this.checkIsActive('buyer', 'tenant');
    // } else if (controlName === 'tenant') {
    //   this.checkIsActive('tenant', 'buyer');
    // }
    if (this.buyerForm.get(controlName).value !== true) {
      this.buyerForm.get(controlName).setValue(true);
      if (controlName === 'buyer') {
        this.buyerForm.get('isFirstHome').setValue(true);
      }
    } else {
      this.buyerForm.get(controlName).setValue(false);
      if (controlName === 'buyer') {
        this.buyerForm.get('isFirstHome').setValue(false);
      }
    }

    this.setBuyerCode();
  }

  setBuyerType(controlName: string) {
    if (this.buyerForm.get(controlName).value !== true) {
      this.buyerForm.get(controlName).setValue(true);
    } else {
      this.buyerForm.get(controlName).setValue(false);
    }

    if (controlName === 'isInvestor' || controlName === 'isFirstHome') {
      if (this.buyerForm.get('isLocal').value === true) {
        if (this.buyerForm.get('isFirstHome').value === true) {
          this.buyerForm.get('clientTenant').setValue(true);
        } else {
          this.buyerForm.get('clientTenant').setValue(false);
        }

        if (this.buyerForm.get('isInvestor').value === true) {
          this.buyerForm.get('clientSeller').setValue(true);
        } else {
          this.buyerForm.get('clientSeller').setValue(false);
        }
      } else {
        if (this.buyerForm.get('clientTenant').value === true) {
          this.buyerForm.get('clientTenant').setValue(false);
        }
        if (this.buyerForm.get('clientSeller').value === true) {
          this.buyerForm.get('clientSeller').setValue(false);
        }
      }
    }

    this.setBuyerCode();
  }

  setBuyerAttribute(controlName: string) {
    if (this.buyerForm.get(controlName).value !== true) {
      this.buyerForm.get(controlName).setValue(true);
    } else {
      this.buyerForm.get(controlName).setValue(false);
    }

    if (controlName === 'isLocal') {
      if (this.buyerForm.get('isLocal').value === true) {
        if (this.buyerForm.get('isFirstHome').value === true) {
          this.buyerForm.get('clientTenant').setValue(true);
        }
        if (this.buyerForm.get('isInvestor').value === true) {
          this.buyerForm.get('clientSeller').setValue(true);
        }
      } else {
        if (this.buyerForm.get('clientTenant').value === true) {
          this.buyerForm.get('clientTenant').setValue(false);
        }
        if (this.buyerForm.get('clientSeller').value === true) {
          this.buyerForm.get('clientSeller').setValue(false);
        }
      }
    }

    this.setBuyerCode();
  }

  setBuyerCode() {
    const form = this.buyerForm.value;
    if (form.buyer) {
      this.initBuyerDeposit(false);
      this.buyerForm.get('buyerCode').setValue('B');
      if (form.isValueBuyer) {
        this.buyerForm.get('buyerCode').setValue('VB');
      }
      if (this.data) {
        this.changeCode(form.isActive);
      }
      if (!this.buyerForm.get('minBuyPrice').value) {
        this.buyerForm.controls.minBuyPrice.setValue(this.minBuyValue);
      }
      if (!this.buyerForm.get('maxBuyPrice').value) {
        this.buyerForm.controls.maxBuyPrice.setValue(this.maxBuyValue);
      }
    } else if (form.tenant) {
      this.initBuyerDeposit(true);
      this.buyerForm.get('buyerCode').setValue('T');
      if (this.data) {
        this.changeCode(form.isActive);
      }
      if (!this.buyerForm.get('minBuyPrice').value) {
        this.buyerForm.controls.minBuyPrice.setValue(this.minRentValue);
      }
      if (!this.buyerForm.get('maxBuyPrice').value) {
        this.buyerForm.controls.maxBuyPrice.setValue(this.maxRentValue);
      }
    } else {
      if (this.data) {
        this.changeCode(form.isActive);
      }
      if (form.sell) {
        this.buyerForm.get('buyerCode').setValue('S');
      }
      if (form.sell && form.rent) {
        this.buyerForm.get('buyerCode').setValue('R');
      }
      if (form.sell && form.otm) {
        this.buyerForm.get('buyerCode').setValue('O');
      }
      if (form.sell && form.vac && form.rent) {
        this.buyerForm.get('buyerCode').setValue('V');
      }
    }
    this.autoSetBuyerRate();
  }

  changeCode(status: string) {
    if (status === 'ACTIVE') {
      this.code1 = this.buyerForm.controls?.isCommon?.value === true ? 'C' : '';
      this.code2 = this.buyerForm.controls?.isAddress?.value === true ? 'A' : '';
      this.code3 = this.buyerForm.controls?.isNumber?.value === true ? 'N' : '';
      this.code4 = this.buyerForm.controls?.isInspection?.value === true ? 'I' : '';
    } else {
      this.code1 = this.buyerForm.controls?.districtId?.value ? 'S' : '';
      this.code2 = this.buyerForm.controls?.streetId?.value ? 'S' : '';
      this.code3 = this.buyerForm.controls?.numberFrom?.value ? 'N' : '';
      this.code4 = '';
    }
  }

  autoSetBuyerRate() {
    const form = this.buyerForm.value;
    let rate = 0;
    if (form.buyer) {
      if (form.isCommon) {
        rate++;
      }
      if (form.isAddress) {
        rate++;
      }
      if (form.isNumber) {
        rate++;
      }

      if (this.buyerTasks) {
        this.buyerTasks.forEach(buyerTask => {
          if (buyerTask.isOff || buyerTask.isDep) {
            rate++;
          }
          if (buyerTask.isExc) {
            rate++;
          }
        });
      }

      if (this.buyerDeposits) {
        const cooloff = this.buyerDeposits.find(buyerDeposit => buyerDeposit.name === 'COOL_OFF');
        if (cooloff?.isChecked) {
          rate++;
        }

        const settle = this.buyerDeposits.find(buyerDeposit => buyerDeposit.name === 'SETTLE');
        if (settle?.isChecked) {
          rate++;
        }
      }
    } else if (form.tenant) {
      if (form.isCommon) {
        rate++;
      }
      if (form.isAddress) {
        rate++;
      }
      if (form.isNumber) {
        rate++;
      }
    } else {
      if (form.districtId) {
        rate++;
      }
      if (form.streetId) {
        rate++;
      }
      if (form.numberFrom) {
        rate++;
      }
    }
    this.buyerForm.get('rate').setValue(rate);
  }

  fetchAllLinkedCustomer(customerId: number) {
    if (customerId) {
      this.customerService.fetchAllLinkedGroup(customerId).subscribe(
        result => {
          if (result.data) {
            this.linkedGroup = result.data;
            this.setTotalLinked();
          }
        }, error => {
          this.commonService.warning(error.message);
        },
      );
    }
  }

  setTotalLinked() {
    this.totalLinked = 0;
    this.linkedGroup.forEach(group => {
      if (group.prospects) {
        this.totalLinked += group.prospects.length;
      }
      if (group.buyers) {
        this.totalLinked += group.buyers.length;
      }
    });
  }

  // === others ====
  private isSuccessResponse(response) {
    return response && (response.status === 'success');
  }

  trimObject(obj: any, properties: string[]): any {
    const curentProperties: string[] = Object.keys(obj);
    for (const name of curentProperties) {
      if (properties.indexOf(name) === -1) {
        delete obj[name];
      }
    }
  }

  showError(name: string): string {
    return this.buyerForm.controls[name].errors
    && (this.buyerForm.controls[name].dirty
      || this.buyerForm.controls[name].touched
      || this.isSubmitted) ? 'input-error' : '';
  }

  keepOrder(a, b) {
    return 1;
  }

  onSuburbSelectChange(districtId: number, first?: boolean) {
    this.filteredStreets = [];
    if (districtId) {
      this.localService.streets(districtId).subscribe(result => {
        this.filteredStreets = result;
        if (!first) {
          this.buyerForm.controls.streetId.setValue(null);
        }
      });
    }
    this.setBuyerCode();
  }

  onStreetSelectChange(streetId: number) {
    this.setBuyerCode();
  }

  onCitySelectChange(cityId: number) {
    this.filteredDistricts = [];
    if (cityId) {
      this.localService.districts(cityId).subscribe(result => {
        this.filteredDistricts = result;
      });
    }
  }

  selectAddress() {
    this.searchProspect().subscribe(result => {
      if (result) {
        this.buyerForm.get('enqAddress').setValue(StringUtils.getFullAddress(result.address));
        this.buyerForm.get('prospectId').setValue(result.prospectId);
        this.buyerForm.get('bed').setValue(result.property?.bed);
        this.buyerForm.get('bath').setValue(result.property?.bath);
        this.buyerForm.get('car').setValue(result.property?.car);
        this.buyerForm.get('land').setValue(result.property?.land);
        this.buyerForm.get('type').setValue(result.property?.type);
        if (result.property?.vac) {
          this.buyerForm.controls.minBuyPrice.patchValue(result.property?.office?.officeProperty?.minRentalPrice);
          this.buyerForm.controls.maxBuyPrice.patchValue(result.property?.office?.officeProperty?.maxRentalPrice);
        }
        if (result.property?.otm) {
          this.buyerForm.controls.minBuyPrice.patchValue(result.property?.office?.officeProperty?.minBuyPrice);
          this.buyerForm.controls.maxBuyPrice.patchValue(result.property?.office?.officeProperty?.maxBuyPrice);
        }
        this.syncPostCode();
        this.autoAddInterest(result.address);
      }
    });
  }

  autoAddInterest(address: Address) {
    if (address) {
      this.addInterestDistrict(address.street.district);
      this.addInterestZone(address.street.district.zone);
    }
  }

  addInterestZone(zone: Zone) {
    if (!this.interestZones) {
      this.interestZones = [];
    }
    const index = this.interestZones.findIndex(temp => temp.zoneId === zone.zoneId);
    if (index < 0) {
      const interestZone = new InterestZone();
      interestZone.buyerId = this.data.buyerId;
      interestZone.zoneId = zone.zoneId;
      interestZone.zone = zone;
      interestZone.status = 'ACTIVE';
      this.interestZones.push(interestZone);
      this.autoFillSuburbsAfterPickingZone(zone.zoneId);
    }
  }

  addInterestDistrict(district: District) {
    if (!this.interestDistricts) {
      this.interestDistricts = [];
    }
    const index = this.interestDistricts.findIndex(temp => temp.districtId === district.districtId);
    if (index < 0) {
      const interestDistrict = new InterestDistrict();
      interestDistrict.buyerId = this.data.buyerId;
      interestDistrict.districtId = district.districtId;
      interestDistrict.district = district;
      interestDistrict.status = 'ACTIVE';
      this.interestDistricts.push(interestDistrict);
    }
  }

  searchProspect(): Observable<any> {
    return this.dialogService.open(ProspectPickComponent, {
      context: {
        isBuyerMode: this.buyerForm.controls.buyer.value,
        isTenantMode: this.buyerForm.controls.tenant.value,
        cities: this.cities,
        filteredDistricts: this.filteredDistricts,
        filteredStreets: this.filteredStreets,
      },
    })
      .onClose;
  }

  selectBuyer() {
    this.searchBuyer().subscribe(result => {
      if (result) {
        this.overrideBuyer(result);
      }
    });
  }

  overrideBuyer(buyer: Buyer) {
    let enqDate;
    if (buyer.property) {
      enqDate = buyer.property.enqDate;
    }
    buyer.property = { ...buyer.property, ...this.buyerForm.value };
    buyer.address = {
      ...buyer.address,
      ...this.buyerForm.value,
    };
    if (buyer.property) {
      buyer.property.enqDate = enqDate;
    }
    buyer.prospectId = this.buyerForm.value.prospectId;
    this.mergeDistrict(buyer);
    this.mergeZones(buyer);
    this.ownSet.emit(buyer);
  }

  /**
   * save current interestDistrict tobuyer
   */
  mergeZones(buyer: Buyer) {
    buyer.interestZones = this.interestZones;
    if (buyer.interestZones) {
      buyer.interestZones.forEach(zone => {
        zone.buyerId = buyer.buyerId;
        zone.interestZoneId = null;
      });
    }
  }

  /**
   * save current interestDistrict tobuyer
   */
  mergeDistrict(buyer: Buyer) {
    buyer.interestDistricts = this.interestDistricts;
    if (buyer.interestDistricts) {
      buyer.interestDistricts.forEach(district => {
        district.buyerId = buyer.buyerId;
        district.interestDistrictId = null;
      });
    }
  }

  searchBuyer(): Observable<any> {
    return this.dialogService.open(CustomerPickComponent, {
      context: {
        phone: this.buyerForm.controls.mobilePhone.value,
      },
    })
      .onClose;
  }

  searchDistrict(): Observable<any> {
    return this.dialogService.open(DistrictPickComponent, {
      hasScroll: true,
      context: {
        cityId: this.buyerForm.get('cityId').value,
        selectedZoneIds: this.interestZones?.map(x => x.zoneId),
      },
    })
      .onClose;
  }

  pickDistrict() {
    this.searchDistrict().subscribe(result => {
      if (result) {
        this.addInterestDistrict(result);
      }
    });
  }

  searchZone(): Observable<any> {
    return this.dialogService.open(ZonePickComponent, {
      hasScroll: true,
      context: {
        cityId: this.buyerForm.get('cityId').value,
      },
    })
      .onClose;
  }

  pickZone() {
    this.searchZone().subscribe(result => {
      if (result) {
        this.addInterestZone(result);
      }
    });
  }

  autoFillSuburbsAfterPickingZone(zoneId: number) {
    const searchRequest = new SearchRequest();
    searchRequest.conditions = new Array();
    searchRequest.conditions.push({
      left: 'zoneId',
      middle: '=',
      right: zoneId,
      dataType: 'LONG',
    });
    searchRequest.orders = [{
      left: 'districtId',
      right: 'desc',
    }];
    this.suburbService.search(searchRequest).subscribe((result: RestResult) => {
      if (result.data) {
        if (!this.interestDistricts) {
          this.interestDistricts = [];
        }
        for (const suburb of result.data) {
          const oldInterestDistrictIndex = this.interestDistricts
            .findIndex(temp => temp.districtId === suburb.districtId);
          if (oldInterestDistrictIndex < 0) {
            const interestDistrict = new InterestDistrict();
            interestDistrict.buyerId = this.data.buyerId;
            interestDistrict.districtId = suburb.districtId;
            interestDistrict.district = suburb;
            interestDistrict.status = 'ACTIVE';
            this.interestDistricts.push(interestDistrict);
          }
        }
      }
    });
  }

  navigate(forward: boolean) {
    let index = this.buyers.findIndex(x => x.buyerId === this.data.buyerId);
    index = forward === true ? index + 1 : index - 1;
    index = this.formatIndex(index);
    this.navigateBuyerEvent.emit({
      currentBuyerId: this.buyers[index]?.buyerId,
      forward,
    });
  }

  formatIndex(index) {
    if (index >= this.buyers.length) {
      index = this.buyers.length - 1;
    } else if (index <= 0) {
      index = 0;
    }
    return index;
  }

  createTask() {
    this.searchProspect().subscribe(prospect => {
      if (prospect) {
        const defaultData = {
          description: `Make phone call to ${this.data?.customer?.name}, `
            + `phone number: ${this.data?.customer?.phone} or ${this.data?.customer?.mobilePhone}`,
        };
        this.dialogService.open(TaskMiniDialogComponent, {
          hasScroll: true,
          context: {
            defaultData,
            taskTypes: this.taskTypes.filter(x => x.category?.name === 'CUSTOMER'),
            taskStatuses: this.taskStatuses,
            selectedProspect: prospect,
            selectedBuyer: this.data,
          },
        })
          .onClose.pipe(
          concatMap(
            res => {
              if (this.isSuccessResponse(res)) {
                const buyerTask = new BuyerTask();
                buyerTask.buyerId = this.data.buyerId;
                buyerTask.taskId = res.data.taskId;
                buyerTask.task = res.data;
                return this.buyerTaskService.create(buyerTask);
              } else {
                return of(null);
              }
            }),
          concatMap(
            res => {
              if (this.isSuccessResponse(res)) {
                return this.buyerTaskService.getOne(res.data.buyerTaskId);
              } else {
                return of(null);
              }
            }),
          catchError(this.commonService.handleError),
        ).subscribe(res => {
          if (this.isSuccessResponse(res)) {
            if (!this.buyerTasks) {
              this.buyerTasks = [];
            }
            this.buyerTasks.push(res.data);
            // this.buyerTasks.map(temp => temp.taskId === res.data.taskId || res.data);
          }
        });
      }
    });
  }

  logContactStatus(type: string) {
    const contactLogRequest: BuyerContactRequest = new BuyerContactRequest();
    switch (type) {
      case this.contactLogType.CALLED:
        contactLogRequest.called = true;
        contactLogRequest.calledPhoneNumber = this.lastCalledPhoneNumber;
        break;
      case this.contactLogType.DOOR_NOCKED:
        contactLogRequest.doorNocked = true;
        break;
      case this.contactLogType.NO_ANSWER:
        contactLogRequest.noAnswer = true;
        break;
    }
    contactLogRequest.note = this.buyerForm.controls.note?.value?.trim();
    if ((type === this.contactLogType.CALLED || type === this.contactLogType.DOOR_NOCKED)
      && (!contactLogRequest.note || contactLogRequest.note === '')) {
      this.commonService.warning('Please fill in note');
      return;
    }
    this.buyerService.logContactStatus(this.data.buyerId, contactLogRequest).subscribe(
      result => {
        this.commonService.info('Logged success.');
        if (
          type === this.contactLogType.NO_ANSWER
          || type === this.contactLogType.CALLED
          || type === this.contactLogType.DOOR_NOCKED
        ) {
          this.navigate(true);
        }
        if (!this.onClose) {
          this.close(result);
        }
      },
      error => {
        this.commonService.warningHtml(error.message);
      },
    );
  }

  removeInterestDistrict(districtId: number) {
    this.interestDistricts = this.interestDistricts.filter(district => district.districtId !== districtId);
  }

  removeInterestZone(zoneId: number) {
    this.interestZones = this.interestZones.filter(zone => zone.zoneId !== zoneId);
  }

  selectOffOrDep(buyerTask: BuyerTask, field: string) {
    buyerTask[field] = !buyerTask[field];
    this.autoSetBuyerRate();
  }

  selectDeposit(buyerTask: BuyerTask) {
    if (!this.selectedTaskId || this.selectedTaskId === buyerTask.taskId) {
      if (!this.selectedTaskId) {
        buyerTask.isExc = true;
        this.selectedAddress = this.getAddressName(buyerTask.task?.prospect?.address);
        this.isDeposit = true;
        this.selectedTaskId = buyerTask.taskId;
        this.buyerForm.get('enqAddress').setValue(
          StringUtils.getFullAddress(buyerTask.task.prospect.address));
        this.buyerForm.get('prospectId').setValue(buyerTask.task.prospect.prospectId);
      } else {
        buyerTask.isExc = false;
        this.selectedAddress = null;
        this.isDeposit = false;
        this.selectedTaskId = null;
        this.buyerDeposits.forEach(dep => {
          if (dep.name === 'COOL_OFF' || dep.name === 'SETTLE') {
            dep.isChecked = false;
          }
        });
      }
      this.autoSetBuyerRate();
    } else {
      this.commonService.warning('Only one appointment can be checked as EXC');
    }
  }

  initBuyerDeposit(isTenant: boolean) {
    let isFilled = false;
    if (this.buyerDeposits && this.buyerDeposits.length > 0) {
      this.buyerDeposits.forEach(buyerDeposit => {
        if (buyerDeposit.isTenant === isTenant) {
          isFilled = true;
        }
      });
    }
    if (!isFilled) {
      this.buyerDeposits = [];
      if (!isTenant) {
        this.buyerDeposits.push(this.generateBuyerDeposit('EXC', 'EXC', isTenant));
        this.buyerDeposits.push(this.generateBuyerDeposit('0.25%', 'PERCENT', isTenant));
        this.buyerDeposits.push(this.generateBuyerDeposit('Bank Val', 'BANK_VAL', isTenant));
        this.buyerDeposits.push(this.generateBuyerDeposit('B-insp', 'BINSP', isTenant));
        this.buyerDeposits.push(this.generateBuyerDeposit('P-insp', 'PINSP', isTenant));
        this.buyerDeposits.push(this.generateBuyerDeposit('Cool Off', 'COOL_OFF', isTenant));
        this.buyerDeposits.push(this.generateBuyerDeposit('F-insp', 'FINSP', isTenant));
        this.buyerDeposits.push(this.generateBuyerDeposit('Settle', 'SETTLE', isTenant));
      } else {
        this.buyerDeposits.push(this.generateBuyerDeposit('2x pay slip', 'PAYSLIP', isTenant));
        this.buyerDeposits.push(this.generateBuyerDeposit('$ Statement', 'STATEMENT', isTenant));
        this.buyerDeposits.push(this.generateBuyerDeposit('2 Bills', 'BILL', isTenant));
        this.buyerDeposits.push(this.generateBuyerDeposit('picture ID', 'PICTURE', isTenant));
        this.buyerDeposits.push(this.generateBuyerDeposit('rental elger ', 'RENTAL', isTenant));
        this.buyerDeposits.push(this.generateBuyerDeposit('Tenancy Agg', 'TENANCY', isTenant));
        this.buyerDeposits.push(this.generateBuyerDeposit('Deposit', 'DEPOSIT', isTenant));
        this.buyerDeposits.push(this.generateBuyerDeposit('Bond', 'BOND', isTenant));
        this.buyerDeposits.push(this.generateBuyerDeposit('Approval', 'APPROVAL', isTenant));
      }
    }
  }

  loadBuyerDeposit() {
    if (this.data.buyerId) {
      const searchRequest = new SearchRequest();
      searchRequest.conditions = new Array();
      searchRequest.orders = new Array();
      searchRequest.conditions.push({
        left: 'buyerId',
        middle: '=',
        right: this.data?.buyerId,
      });
      searchRequest.orders.push({
        left: 'buyerDepositId',
        right: 'asc',
      });
      this.buyerDepositService.search(searchRequest).subscribe(result => {
        if (this.isSuccessResponse(result)) {
          this.buyerDeposits = result.data;
          this.autoSetBuyerRate();
        }
      });

    } else {
      this.initBuyerDeposit(false);
    }
  }

  loadInterestDistrict() {
    if (this.data && this.data.interestDistricts) {
      this.interestDistricts = this.data.interestDistricts;
    }
    if (this.data.buyerId) {
      const searchRequest = new SearchRequest();
      searchRequest.conditions = new Array();
      searchRequest.orders = new Array();
      searchRequest.conditions.push({
        left: 'buyerId',
        middle: '=',
        right: this.data.buyerId,
      });
      searchRequest.orders.push({
        left: 'interestDistrictId',
        right: 'asc',
      });
      this.interestDistrictService.search(searchRequest).subscribe(result => {
        if (this.isSuccessResponse(result)) {
          if (result.data) {
            if (this.interestDistricts) {
              this.interestDistricts.forEach(element => {
                const index = result.data.findIndex(temp => temp.districtId === element.districtId);
                if (index < 0) {
                  result.data.push(element);
                }
              });
            }
            this.interestDistricts = result.data;
          }
        }
      });
    }
  }

  loadInterestZone() {
    if (this.data && this.data.interestZones) {
      this.interestZones = this.data.interestZones;
    }
    if (this.data.buyerId) {
      const searchRequest = new SearchRequest();
      searchRequest.conditions = new Array();
      searchRequest.orders = new Array();
      searchRequest.conditions.push({
        left: 'buyerId',
        middle: '=',
        right: this.data.buyerId,
      });
      searchRequest.orders.push({
        left: 'interestZoneId',
        right: 'asc',
      });
      this.interestZoneService.search(searchRequest).subscribe(result => {
        if (this.isSuccessResponse(result)) {
          if (result.data) {
            if (this.interestZones) {
              this.interestZones.forEach(element => {
                const index = result.data.findIndex(temp => temp.zoneId === element.zoneId);
                if (index < 0) {
                  result.data.push(element);
                }
              });
            }
            this.interestZones = result.data;
          }
        }
      });
    }
  }

  generateBuyerDeposit(label: string, name: string, isTenant: boolean): BuyerDeposit {
    const buyerDeposit = new BuyerDeposit();
    buyerDeposit.name = name;
    buyerDeposit.label = label;
    buyerDeposit.status = 'ACTIVE';
    buyerDeposit.buyerId = this.data.buyerId;
    buyerDeposit.isTenant = isTenant;
    return buyerDeposit;
  }

  checkBuyerDeposit(buyerDeposit: BuyerDeposit) {
    buyerDeposit.isChecked = !buyerDeposit.isChecked;
    if (buyerDeposit.name === 'COOL_OFF' || buyerDeposit.name === 'SETTLE') {
      this.autoSetBuyerRate();
    }
  }

  sortHistoryList() {
    return this.data?.logs?.sort((a: BuyerLog, b: BuyerLog) => {
      return b.createdDate - a.createdDate;
    });
  }

  formatLog(log: BuyerLog) {
    let result = '';
    result = `<b>${this.commonService.formatDate(log.createdDate, 'DD/MM/YYYY HH:mm')}</b>`
      + `: ${StringUtils.getShortName(log.fullname)}: ${log.actionType}:\n`;
    if (log.actionType === 'BULK_LOG') {
      result += log.description;
    } else {
      try {
        const desObj = JSON.parse(log?.description?.trim());
        if (desObj) {
          for (const key of Object.keys(desObj)) {
            const keyArr = key?.split(':');
            result += `- ${keyArr.length === 2 ? keyArr[1] : key}: ${desObj[key]} \n`;
          }
        }
      } catch (ignore) {
      }
    }
    return result.trim();
  }

  BUYER_PRICE() {
    if (this.buyerForm.get('buyer').value) {
      return Constant.MIN_PRICE;
    } else {
      return Constant.RENTAL_MIN_PRICE;
    }
  }

  onMinPriceChange(minPrice: number) {
    if (minPrice) {
      const plus = this.buyerForm.get('buyer').value ? 50000 : 50;
      this.buyerForm.get('maxBuyPrice').setValue(Number(minPrice) + plus);
    }
  }

  onMaxPriceChange(maxPrice: number) {
    const max = Number(maxPrice);
    const min = Number(this.buyerForm.get('minBuyPrice').value);
    if (max < min) {
      this.commonService.warning('Max Buy Price must higher than Min Buyer Price.');
      const plus = this.buyerForm.get('buyer').value ? 50000 : 50;
      this.buyerForm.get('maxBuyPrice').setValue(min + plus);
    }
  }

  resetData() {
    this.data = new Buyer();
    this.data.property = new Property();
    this.data.property.tenant = true;
    this.data.isFirstHome = true;
    this.data.isAddress = true;
    this.data.isCommon = false;
    this.data.isNumber = false;
    this.data.isInspection = false;
    this.data.customer = new Customer();
    this.buyerDeposits = null;
    this.buyerTasks = null;
    this.interestDistricts = null;
    this.interestZones = null;
    this.address = '';
    this.isSubmitted = false;
    this.isUpdating = false;
    this.isDeposit = false;
    this.code1 = '';
    this.code2 = '';
    this.code3 = '';
    this.code4 = '';
    this.filteredDistricts = null;
    this.filteredStreets = null;
    this.selectedAddress = null;
    this.selectedTaskId = null;
    this.categoryName = null;
    this.selectedCategories = [];
    this.additionalInfo = [];
  }

  triggerDatePicker(datePickerComponent: NbDatepickerComponent<any>) {
    datePickerComponent?.show();
  }

  getWeekCount(firstEnqDate: Date) {
    let weekCount = 0;
    if (firstEnqDate) {
      const a = moment(firstEnqDate);
      const b = moment();
      const days = b.diff(a, 'week');
      weekCount = days;
    }
    if (weekCount >= 8 && weekCount <= 12) {
      this.buyerForm.get('isNumber').setValue(true);
    } else if (weekCount > 12) {
      this.buyerForm.get('isNumber').setValue(false);
    }
    return weekCount;
  }

  createDOBTask(type: 'BIRTHDAY_CALL' | 'NEXT_CALL' | 'APPOINTMENT') {
    const defaultData = {
      description: `Make phone call to ${this.data?.customer?.name}, `
        + `phone number: ${this.data?.customer?.phone} or ${this.data?.customer?.mobilePhone}`,
      isCallback: type === 'NEXT_CALL',
      isDob: type === 'BIRTHDAY_CALL',
      isAppointment: type === 'APPOINTMENT',
      callbackDate: this.buyerForm.controls.callbackDate.value,
      dob: this.buyerForm.controls.dob.value,
    };
    this.dialogService.open(TaskMiniDialogComponent, {
      hasScroll: true,
      context: {
        defaultData,
        taskTypes: this.taskTypes,
        taskStatuses: this.taskStatuses,
        selectedBuyer: this.data,
      },
    })
      .onClose.subscribe(res => {
      if (this.isSuccessResponse(res)) {
        this.close(res);
      }
    });
  }

  onSelectBuyer(buyer: Buyer) {
    return this.dialogService.open(BuyerCreateComponent, {
      hasScroll: true,
      context: {
        data: buyer,
      },
    }).onClose;
  }

  onSelectProspect(prospect: Prospect) {
    return this.dialogService.open(ProspectDialogComponent, {
      hasScroll: true,
      context: {
        data: prospect,
      },
    }).onClose;
  }

  formatAddress(address: Address): string {
    return StringUtils.getFullAddress(address);
  }

  lookupAddress() {
    if (!this.data?.address || this.noInfoAddress(this.data?.address)) {
      this.commonService.warning('This user does not have associated address');
    } else {
      const request = this.getRequest();
      this.dialogService.open(BuyerLookupComponent, {
        context: {
          address: this.data.address,
          buyerCustomer: request.customer,
        },
      });
    }
  }

  noInfoAddress(address: Address): boolean {
    return !address.cityId && !address.streetId && !address.districtId && !address.numberFrom;
  }

  lookupPhone(phone: string, type: string) {
    if (type === 'PHONE') {
      this.phoneDuplicate = null;
    } else {
      this.mobiPhoneDuplicate = null;
    }
    if (phone) {
      const customerSearchRequest = new CustomerSearchRequest();
      customerSearchRequest.phone = phone;
      customerSearchRequest.isMatched = true;
      this.customerService.searchDuplicatePhone(customerSearchRequest).subscribe(
        result => {
          if (result.data && result.data.length > 0) {
            if (type === 'PHONE') {
              this.phoneDuplicate = new CustomerDuplicatePhone();
              result.data.forEach(response => {
                this.phoneDuplicate.phone = response.phone;
                if (response.tableType === 'BUYER') {
                  this.phoneDuplicate.totalCustomer += response.total;
                } else {
                  this.phoneDuplicate.totalClient += response.total;
                }
              });
            } else {
              this.mobiPhoneDuplicate = new CustomerDuplicatePhone();
              result.data.forEach(response => {
                this.mobiPhoneDuplicate.phone = response.phone;
                if (response.tableType === 'BUYER') {
                  this.mobiPhoneDuplicate.totalCustomer += response.total;
                } else {
                  this.mobiPhoneDuplicate.totalClient += response.total;
                }
              });
            }
          }
        }, error => {
          this.commonService.warning(error.message);
        },
      );
    }
  }

  selectPhone(type: string) {
    let phone;
    if (type === 'PHONE') {
      phone = this.buyerForm.value.phone;
    } else {
      phone = this.buyerForm.value.mobilePhone;
    }
    window.open(
      '/pages/dashboard/search/phone?phone=' + phone + '&isMatched=true',
      '_blank',
    );
  }

  findStreet() {
    const date = new Date(this.data.property.enqDate);
    const sr = new ProspectSearchRequest();
    if (this.data?.address?.districtId) {
      sr.districtId = this.data.address.districtId;
    }
    if (this.data?.address?.streetId) {
      sr.streetId = this.data.address.streetId;
    }
    sr.purchaseDateMonth = date.getMonth() + 1;
    sr.purchaseDateYear = date.getFullYear();
    this.dialogService.open(ProspectListBasicComponent, {
      context: {
        searchRequest: sr,
        isDialog: true,
        reducedMode: true,
      },
      dialogClass: 'model-full',
    });
  }

  toggleDisplayCommon() {
    this.showCommon = !this.showCommon;
  }

  toggleDisplayAddress() {
    this.showAddress = !this.showAddress;
  }

  toggleDisplayNumber() {
    this.showNumber = !this.showNumber;
  }

  toggleDisplayInspection() {
    this.showInspection = !this.showInspection;
  }

  toggleDisplayENQ() {
    this.showENQ = !this.showENQ;
  }

  toggleDisplayHistory() {
    this.showHistory = !this.showHistory;
  }

  closeDialog() {
    this.ref.close();
  }

  createTaskForCurrentAddress() {
    this.dialogService.open(TaskMiniDialogComponent, {
      hasScroll: true,
      context: {
        taskTypes: this.taskTypes.filter(x => x.category?.name === 'CUSTOMER'),
        taskStatuses: this.taskStatuses,
        selectedBuyer: this.data,
        isSpecial: true,
      },
    })
      .onClose.pipe(
      concatMap(
        res => {
          if (this.isSuccessResponse(res)) {
            const buyerTask = new BuyerTask();
            buyerTask.buyerId = this.data.buyerId;
            buyerTask.taskId = res.data.taskId;
            buyerTask.task = res.data;
            return this.buyerTaskService.create(buyerTask);
          } else {
            return of(null);
          }
        }),
      concatMap(
        res => {
          if (this.isSuccessResponse(res)) {
            return this.buyerTaskService.getOne(res.data.buyerTaskId);
          } else {
            return of(null);
          }
        }),
      catchError(this.commonService.handleError),
    ).subscribe(res => {
      if (this.isSuccessResponse(res)) {
        if (!this.buyerTasks) {
          this.buyerTasks = [];
        }
        this.buyerTasks.push(res.data);
        // this.buyerTasks.map(temp => temp.taskId === res.data.taskId || res.data);
      }
    });
  }

  updateProspectInfoS3() {
    this.prospectService.getOne(this.data.prospectId).subscribe(result => {
      if (result) {
        const prospectRequest = result.data;
        this.copyToRequest(prospectRequest.customer, this.data.customer);
        prospectRequest.rate = 3;
        prospectRequest.property.sell = true;
        prospectRequest.property.vac = false;
        prospectRequest.property.rent = false;
        prospectRequest.property.otm = false;
        this.prospectService.update(prospectRequest).subscribe(r => {
          this.commonService.info('Updated corresponding to client s3');
        });
      }
    });
  }

  copyToRequest(customerRequest: any, customer: Customer) {
    if (this.data.customer) {
      customerRequest.name = customer.name;
      customerRequest.surname = customer.surname;
      customerRequest.phone = customer.phone;
      customerRequest.mobilePhone = customer.mobilePhone;
      customerRequest.relationship = customer.relationship;
      customerRequest.motivate = customer.motivate;
      customerRequest.isDirector = customer.isDirector;
      customerRequest.isInfluencer = customer.isInfluencer;
      customerRequest.isSocialble = customer.isSocialble;
      customerRequest.isConcensious = customer.isConcensious;
      customerRequest.motivateFinancialUp = customer.motivateFinancialUp;
      customerRequest.motivateFinancialDown = customer.motivateFinancialDown;
      customerRequest.motivateLifestyleUp = customer.motivateLifestyleUp;
      customerRequest.motivateLifestyleDown = customer.motivateLifestyleDown;
      customerRequest.email = customer.email;
      customerRequest.facebook = customer.facebook;
      customerRequest.instagram = customer.instagram;
      customerRequest.dob = customer.dob;
      customerRequest.nationality = customer.nationality;
      customerRequest.anniversaryDay = customer.anniversaryDay;
      customerRequest.religion = customer.religion;
      customerRequest.profesion = customer.profesion;
      customerRequest.language = customer.language;
      customerRequest.note = customer.note;
      // customer.callbackDate = customerRequest.callbackDate;
      customerRequest.link = customer.link;
    }
  }

  searchDuplicatePhone(type: string) {
    let commands;
    if (type === 'Client') {
      commands = ['/pages/prospect/list'];
    } else {
      commands = ['/pages/buyer/list'];
    }
    const url = this.router.createUrlTree(commands, { queryParams: { phone: this.data.customer.mobilePhone } });
    window.open(url.toString(), '_blank');
  }

  addPhoneNumber(type: string) {
    const info = new CustomerAdditionalInfo();
    info.phoneNumberType = type;
    info.customerId = this.data.customer.customerId;
    this.additionalInfo.push(info);
  }

  removePhoneNumber(info: CustomerAdditionalInfo, index: number) {
    if (info.customerAdditionalInfoId) {
      info.isDeleted = true;
    } else {
      this.additionalInfo.splice(index, 1);
    }
  }

  setPrimary(info: CustomerAdditionalInfo, index: number) {
    this.buyerForm.controls.mobilePhone.patchValue(info.phoneNumber);
    this.removePhoneNumber(info, index);
  }

  selectPostcode(type: TypeaheadMatch) {
    this.postcode = Number(type.item.code);
    this.searchSuburbByPostcode(true);
  }

  searchSuburbByPostcode(init: boolean) {
    if (this.postcode === null) {
      return;
    }
    this.filterByPostcode = true;
    if (this.postcode && this.postcode !== 0) {
      if (init) {
        this.filteredDistricts = [];
        this.filteredStreets = [];
        this.buyerForm.controls.districtId.setValue(null);
        this.buyerForm.controls.streetId.setValue(null);
      }
      this.localService.districtByPostcode(this.postcode).subscribe(result => {
        this.filteredDistricts = result;
      });
    }
  }

  syncPostCode() {
    const fullEnqAddress = this.buyerForm.get('enqAddress').value;
    const addressParts = fullEnqAddress?.split(' ');
    this.interestPostcode = addressParts[addressParts.length - 1];
    this.buyerForm.get('enq').setValue(fullEnqAddress);
  }

  loadAddress() {
    this.filterPostcode$ = new Observable((observer: any) => {
      observer.next(this.postcode);
    }).pipe(
      map((query: string) => {
        this.postcode = Number(query);
        this.searchSuburbByPostcode(true);
        return null;
      }),
    );
  }
}
