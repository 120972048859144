import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../../shared/services/auth.service';
import {CommonService} from '../../../shared/services/common.service';
import {User} from '../../../shared/models/response/user';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  form = {
    username: '',
    password: '',
  };
  error: string;
  currentUser: User;

  constructor(private router: Router,
              private authService: AuthService,
              private commonService: CommonService) {
  }

  ngOnInit() {
    this.currentUser = this.authService.currentUser;
    if (this.currentUser) {
      this.router.navigate(['/pages']);
    }
  }

  routeForgot() {
    this.router.navigate(['/auth/forgot']);
  }

  login() {
    if (!this.form.username || !this.form.password) {
      this.commonService.warning('Username and Password cannot be empty.');
      return;
    }

    this.error = null;
    this.authService.login(this.form.username, this.form.password).subscribe(
      success => {
        let previousUrl = localStorage.getItem('previousUrl');
        if (previousUrl) {
          localStorage.removeItem('previousUrl');
        } else {
          previousUrl = '/pages';
        }
        window.location.href = previousUrl;
        // this.router.navigate([previousUrl]);
      },
      error => {
        this.commonService.warningHtml(error.message);
      },
    );
  }
}
