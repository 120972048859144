import {Component, Input, OnInit, Optional, ViewChild} from '@angular/core';
import {Constant} from '../../../../shared/common/constant';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {User} from '../../../../shared/models/response/user';
import {autocomplete, CommonService} from '../../../../shared/services/common.service';
import {NbDialogRef, NbDialogService} from '@nebular/theme';
import {ToastService} from '../../../../shared/services/toast.service';
import {TaskService} from '../../../../shared/services/task.service';
import {FormService} from '../../../../shared/services/form.service';
import {TaskRequest} from '../../../../shared/models/request/task-request';
import {TaskType} from '../../../../shared/models/response/task-type';
import {BehaviorSubject, Observable} from 'rxjs';
import {SearchRequest} from '../../../../shared/models/request/search-request';
import {map} from 'rxjs/operators';
import {UserService} from '../../../../shared/services/user.service';
import {Task} from '../../../../shared/models/response/task';
import {TaskStatus} from '../../../../shared/models/response/task-status';
import * as moment from 'moment';
import {Prospect} from '../../../../shared/models/response/prospect/prospect';
import {ProspectService} from '../../../../shared/services/prospect.service';
import {RestResult} from '../../../../shared/models/response/rest-result';
import {ProspectSearchRequest} from '../../../../shared/models/request/prospect/prospect-search-request';
import {Company} from '../../../../shared/models/response/prospect/company';
import {ConfirmDialogComponent} from '../../confirm-dialog/confirm-dialog.component';
import {Buyer} from '../../../../shared/models/response/buyer/buyer';
import {BuyerService} from '../../../../shared/services/buyer.service';
import {BuyerSearchRequest} from '../../../../shared/models/request/buyer/buyer-search-request';
import {City} from '../../../../shared/models/response/address/city';
import {StringUtils} from '../../../../shared/common/string-utils';
import {AuthService} from '../../../../shared/services/auth.service';
import {ComponentPermission} from '../../../../component.permission';
import {LocalService} from '../../../../shared/services/local.service';
import {TaskTypeService} from '../../../../shared/services/task-type.service';
import {ExportDialogComponent} from '../../buyer/export-dialog/export-dialog.component';
import {TemplateService} from '../../../../shared/services/template.service';
import {EmailEvent} from '../../../../shared/models/response/email-event';

@Component({
  selector: 'ngx-task-mini-dialog',
  templateUrl: './task-mini-dialog.component.html',
  styleUrls: ['./task-mini-dialog.component.scss'],
})
export class TaskMiniDialogComponent implements OnInit {

  ComponentPermission = ComponentPermission;
  defaultData: {
    isCallback?: boolean;
    isDob?: boolean;
    isAppointment?: boolean;
    callbackDate?: number;
    dob?: number;
    description?: string;
    task?: Task;
  };
  companies: Company[];
  cities: City[];
  taskTypes: TaskType[];
  taskStatuses: TaskStatus[];
  form: FormGroup;
  timeRange = [];
  minuteRange = [];
  currentUser: User;
  selectedUser: User;
  isSubmitted = false;
  hourTypes = ['AM', 'PM'];
  durationTypes = ['Minutes', 'Hours', 'Days'];
  durations = [1, 2, 3, 4, 5, 10, 15, 30, 45, 60];

  autoCompleteBehavior = new BehaviorSubject<string>('');
  filteredUsers: Observable<User[]> = this.autoCompleteBehavior.pipe(
    autocomplete(500, (keyword => this.searchUser(keyword))),
  );
  filteredProspects: Prospect[];
  selectedProspect: Prospect;
  selectedBuyer: Buyer;
  selectedType: TaskType;
  @ViewChild('prospectForm') prospectDialog: any;
  @ViewChild('buyerForm') buyerDialog: any;
  prospectOffset = 0;
  prospectPageSize = 10;
  prospectPhone = '';
  StringUtils = StringUtils;
  selectedBuyerId: number;
  isSpecial = false;
  isMarketingSchedule = false;
  emailEvent: EmailEvent;
  isHidden = false;
  maxResult = 0;
  newSearch = true;

  constructor(@Optional() private ref: NbDialogRef<TaskMiniDialogComponent>,
              private toastService: ToastService,
              private taskTypeService: TaskTypeService,
              private taskService: TaskService,
              private formService: FormService,
              private commonService: CommonService,
              private userService: UserService,
              private prospectService: ProspectService,
              private dialogService: NbDialogService,
              private buyerService: BuyerService,
              private authService: AuthService,
              private localService: LocalService,
              private templateService: TemplateService) {

  }

  ngOnInit(): void {
    this.currentUser = this.authService.currentUser;
    if (this.isSpecial) {
      this.prospectService.getOne(this.selectedBuyer.prospectId).subscribe(result => {
        this.selectedProspect = result.data;
      });
    }
    for (let i = 1; i <= 12; i++) {
      this.timeRange.push(i < 10 ? `0${i}` : i);
    }
    for (let i = 0; i < 60; i += 5) {
      this.minuteRange.push(i < 10 ? `0${i}` : i);
    }
    this.initForm();
    this.localService.cities().subscribe(result => {
      this.cities = result;
    });
    this.getTaskTypes();
  }

  initForm() {
    this.form = new FormGroup({
      taskTypeId: new FormControl(this.defaultData?.task?.type?.taskTypeId, [Validators.required]),
      description: new FormControl(this.defaultData?.description),
      status: new FormControl(this.defaultData?.task?.status ? this.defaultData?.task?.status : 'NOT_COMPLETED',
        [Validators.required]),
      startDate: new FormControl('', [Validators.required]),
      startHour: new FormControl('', [Validators.required]),
      startMinute: new FormControl('', [Validators.required]),
      hourType: new FormControl('AM', [Validators.required]),
      duration: new FormControl(0, [Validators.required]),
      durationType: new FormControl('Minutes', [Validators.required]),
      toUserId: new FormControl(this.defaultData?.task?.toUserId ? this.defaultData?.task?.toUserId : null
        , [Validators.required]),
      fromUserId: new FormControl(this.defaultData?.task?.fromUserId
        ? this.defaultData?.task?.fromUserId : this.currentUser?.userId),
      sendEmail: new FormControl(false),
      prospectId: new FormControl(this.selectedProspect?.prospectId),
      note: new FormControl(this.defaultData?.task?.note),
      message: new FormControl(this.defaultData?.task?.message),
      isSp: new FormControl(this.defaultData?.task?.isSp),
      isS4: new FormControl(this.defaultData?.task?.isS4),
      isBi: new FormControl(this.defaultData?.task?.isBi),
      isB4: new FormControl(this.defaultData?.task?.isB4),
      isCallback: new FormControl(this.defaultData?.isCallback),
      isDob: new FormControl(this.defaultData?.isDob),
      isAppointment: new FormControl(this.defaultData?.isAppointment),
      title: new FormControl(this.defaultData?.task?.title),
      officeId: new FormControl(this.defaultData?.task?.office.officeId),
    });

    // update form inputs if modifying task
    if (this.defaultData?.task?.fromDate) {
      const fromDateMoment = moment(this.defaultData?.task?.fromDate);
      let hour = fromDateMoment.hour();
      const minute = fromDateMoment.minutes();
      if (hour < 12) {
        this.form.controls.hourType.setValue('AM');
      } else {
        this.form.controls.hourType.setValue('PM');
        hour -= 12;
      }
      if (hour <= 0) {
        hour = 12;
      }
      this.form.controls.startDate.setValue(new Date(this.defaultData?.task?.fromDate));
      this.form.controls.startHour.setValue(hour < 10 ? `0${hour}` : hour);
      this.form.controls.startMinute.setValue(minute < 10 ? `0${minute}` : minute);
      if (this.defaultData?.task?.toDate) {
        const timeDif = this.defaultData?.task?.toDate - this.defaultData?.task?.fromDate;
        if (timeDif > 60000 && timeDif <= 60000 * 60) {
          this.form.controls.duration.setValue(Math.floor(timeDif / 60000));
          this.form.controls.durationType.setValue(this.durationTypes[0]);
        }
        if (timeDif > 60000 * 60 && timeDif <= 60000 * 60 * 60) {
          this.form.controls.duration.setValue(Math.floor(timeDif / (60000 * 60)));
          this.form.controls.durationType.setValue(this.durationTypes[1]);
        }
        if (timeDif > 60000 * 60 * 60) {
          this.form.controls.duration.setValue(Math.floor(timeDif / (60000 * 60 * 24)));
          this.form.controls.durationType.setValue(this.durationTypes[2]);
        }
      }

      if (this.defaultData?.task?.type) {
        this.selectedType = this.defaultData?.task?.type;
      }

      if (this.selectedType?.category?.name === 'CLIENT' && this.defaultData?.task?.prospect?.prospectId) {
        this.prospectService.getOne(this.defaultData?.task?.prospect?.prospectId)
          .subscribe((result: RestResult) => {
            this.selectedProspect = result.data;
          });
      }

      if (this.selectedType?.category?.name === 'CUSTOMER' && this.defaultData?.task?.buyer?.buyerId) {
        this.buyerService.getOne(this.defaultData?.task?.buyer?.buyerId)
          .subscribe((result: RestResult) => {
            this.selectedBuyer = result.data;
            this.buyerDialog.setBuyer(this.selectedBuyer);
          });
      }

      if (this.defaultData.task.type.name === 'Automatic Message') {
        this.isMarketingSchedule = true;
        this.taskService.getEmailEventOfTask(this.defaultData.task.taskId).subscribe(result => {
          if (result.data) {
            this.emailEvent = result.data;
            this.prospectService.getAllEmailOfEvent(this.emailEvent.emailEventId).subscribe(r => {
              if (r.data) {
                const filtered = r.data.filter(function (el) {
                  return el != null;
                });
                const note = filtered.join(';');
                this.form.controls.note.setValue(note);
              }
            });
          }
        });
      }

    }
    if (this.defaultData?.isCallback && this.defaultData?.callbackDate) {
      this.form.controls.startDate.setValue(new Date(this.defaultData?.callbackDate));
    } else if (this.defaultData?.isDob && this.defaultData?.dob) {
      const dobThisYear = new Date(this.defaultData?.dob);
      dobThisYear.setFullYear(new Date().getFullYear());
      this.form.controls.startDate.setValue(dobThisYear);
    }
    this.selectedUser = this.defaultData?.task?.toUser;
  }

  // user auto complete
  onUserFilterSelectionChange($event) {
    if (!$event || $event === '' || !this.filteredUsers) {
      return;
    }
    this.filteredUsers.subscribe(users => {
      const user = users.find(x => x.username === $event);
      if (user) {
        this.selectedUser = user;
        this.form.controls.toUserId.setValue(user.userId);
      }
    });
  }

  searchUser(keyword: string): Observable<User[]> {
    const searchRequest = new SearchRequest();
    searchRequest.conditions = new Array();
    searchRequest.conditions.push(
      {
        left: 'email',
        middle: 'like',
        right: keyword,
      },
    );
    searchRequest.conditions.push(
      {
        left: 'username',
        middle: 'like',
        right: keyword,
      },
    );
    searchRequest.conditions.push(
      {
        left: 'name',
        middle: 'like',
        right: keyword,
      },
    );
    searchRequest.conditionType = Constant.CONDITION_TYPE.OR;
    searchRequest.offset = 0;
    searchRequest.limit = 10;
    return this.userService.search(searchRequest).pipe(
      map(result => result.data),
    );
  }

  submit() {
    const form = this.form.value;
    this.formService.extractErrorMessage(this.form);
    if (this.form.invalid) {
      this.isSubmitted = true;
      return;
    }
    const request: TaskRequest = {...form};
    try {
      const hour = Number(form.startHour);
      if (form.hourType === 'PM' && hour < 12) {
        form.startDate.setHours(hour + 12);
      } else if (form.hourType === 'PM' && hour === 12) {
        form.startDate.setHours(12);
      } else if (form.hourType === 'AM' && hour < 12) {
        form.startDate.setHours(hour);
      } else if (form.hourType === 'AM' && hour === 12) {
        form.startDate.setHours(0);
      }
      form.startDate.setMinutes(Number(form.startMinute));
      request.fromDate = form.startDate.getTime();
    } catch (ignore) {
    }
    let durationMiliseconds = 1000;
    switch (form.durationType) {
      case 'Minutes':
        durationMiliseconds *= 60;
        break;
      case  'Hours':
        durationMiliseconds *= 3600;
        break;
      case 'Days':
        durationMiliseconds *= 86400;
        break;
    }
    request.toDate = request.fromDate + form.duration * durationMiliseconds;
    if (this.defaultData?.task) {
      request.taskId = this.defaultData?.task?.taskId;
    }
    request.officeId = this.currentUser.office?.officeId;
    if (this.defaultData?.task) {
      this.taskService.update(request).subscribe(
        result => {
          this.commonService.info('Update task success.');
          this.ref.close(result);
        },
        error => {
          this.commonService.warningHtml(error.message);
        },
      );
    } else {
      this.taskService.create(request).subscribe(
        result => {
          this.commonService.info('Created task success.');
          this.ref.close(result);
        },
        error => {
          this.commonService.warningHtml(error.message);
        },
      );
    }
    this.form.reset();
    this.selectedUser = null;
  }

  cancel() {
    this.ref.close();
  }

  showErrorInput(name: string): string {
    return this.form.controls[name].errors
    && (this.form.controls[name].dirty
      || this.form.controls[name].touched
      || this.isSubmitted) ? 'input-error' : '';
  }

  setProspectPhone(event) {
    this.newSearch = true;
    this.prospectPhone = event?.currentTarget?.value;
  }

  searchProspect(newSearch?: boolean) {
    const searchRequest: ProspectSearchRequest = new ProspectSearchRequest();
    if (!newSearch) {
      this.isHidden = false;
      searchRequest.offset = this.prospectOffset + this.prospectPageSize;
      if (this.maxResult < 10) {
        return;
      }
    }
    if (newSearch) {
      this.prospectOffset = 0;
      this.prospectPageSize = 10;
      this.filteredProspects = [];
      searchRequest.offset = this.prospectOffset;
    }
    if (!this.prospectPhone || this.prospectPhone === '' || this.prospectPhone.length <= 0) {
      return;
    }
    searchRequest.phone = this.prospectPhone;
    searchRequest.limit = this.prospectPageSize;
    this.prospectOffset = searchRequest.offset;
    this.prospectService.getAllByPhoneOrMPhone(searchRequest).subscribe((result: RestResult) => {
      this.filteredProspects.push(...result.data);
      this.maxResult = this.filteredProspects.length;
      if (this.maxResult < this.prospectPageSize) {
        this.newSearch = true;
      } else {
        this.newSearch = false;
      }
    });
  }

  selectProspect(prospect: Prospect) {
    this.selectedProspect = prospect;
    this.form.controls.prospectId.setValue(prospect?.prospectId);
    this.isHidden = true;
  }

  selectType(taskType: TaskType) {
    this.selectedType = taskType;
    if (this.selectedBuyer) {
      this.buyerDialog.setBuyer(this.selectedBuyer);
    }
  }

  saveProspect() {
    if (this.prospectDialog) {
      this.prospectDialog.submit(() => {
        this.prospectService.getOne(this.selectedProspect?.prospectId)
          .subscribe((result: RestResult) => {
            this.selectedProspect = result.data;
          });
      });
    }
  }

  removeTask() {
    if (!this.defaultData?.task) {
      return;
    }
    this.dialogService.open(ConfirmDialogComponent, {
      context: {
        content: 'Are you sure?',
      },
    })
      .onClose.subscribe(res => {
      if (res === ConfirmDialogComponent.confirmOk) {
        this.taskService.remove(this.defaultData?.task?.taskId)
          .subscribe(
            result => {
              this.commonService.info('Removed task successfully');
              this.ref.close(result);
            }, error => {
              this.commonService.warning(error.message);
            });
      }
    });
  }

  getTaskTypes() {
    const taskTypeSearchRequest: SearchRequest = new SearchRequest();
    taskTypeSearchRequest.orders = [];
    taskTypeSearchRequest.orders.push(
      {
        left: 'name',
        right: 'asc',

      },
    );
    this.taskTypeService.search(taskTypeSearchRequest).subscribe((result: RestResult) => {
      this.taskTypes = result.data;
    });
  }

  exportEmail() {
    this.prospectService.getAllEmailOfEvent(this.emailEvent.emailEventId).subscribe((result: RestResult) => {
      if (result.data) {
        const filtered = result.data.filter(function (el) {
          return el != null;
        });
        const searchRequest = new SearchRequest();
        searchRequest.conditions = [];
        searchRequest.conditions.push({
          left: 'type',
          middle: '=',
          right: 'EMAIL',
        });
        this.templateService.search(searchRequest).subscribe(r => {
          this.dialogService.open(ExportDialogComponent, {
            context: {
              data: filtered.join(';'),
              templates: r.data,
            },
          });
        });
      }
    });
  }

  exportMobile() {
    this.prospectService.getAllPhoneOfEvent(this.emailEvent.emailEventId).subscribe((result: RestResult) => {
      if (result.data) {
        const searchRequest = new SearchRequest();
        searchRequest.conditions = [];
        searchRequest.conditions.push({
          left: 'type',
          middle: '=',
          right: 'SMS',
        });
        this.templateService.search(searchRequest).subscribe(r => {
          this.dialogService.open(ExportDialogComponent, {
            context: {
              prospectMobile: result.data,
              templates: r.data,
            },
          }).onClose.subscribe(
            onCloseRes => {
              if (onCloseRes) {
                // this.saveBulk(onCloseRes.log, onCloseRes.ids);
              }
            },
          );
        });
      }
    });
  }
}
