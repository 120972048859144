<div *appPermissionCheck="ComponentPermission.TASK_CALENDAR">
  <nb-card class="calendar-block">
    <nb-card-body>

      <div class="row">
        <div class="col-12">
          <!--            <nb-checkbox class="ml-4" [(ngModel)]="isSp">-->
          <!--              SP-->
          <!--            </nb-checkbox>-->
          <!--            <nb-checkbox class="ml-4" [(ngModel)]="isS4">-->
          <!--              S4-->
          <!--            </nb-checkbox>-->
          <!--            <nb-checkbox class="ml-4" [value]="onlyFilterOfficeStaff" (change)="setOnlyFilterOfficeStaff()">-->
          <!--              Officers Tasks-->
          <!--            </nb-checkbox>-->
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12">
          <nb-accordion>
            <nb-accordion-item>
              <nb-accordion-item-header>Advance search</nb-accordion-item-header>
              <nb-accordion-item-body>
                <div class="row">
                  <div class="col-12"><b>Status</b></div>
                </div>
                <div class="row mt-2">
                  <div class="col-sm-2" *ngFor="let item of taskStatuses">
                    <nb-checkbox
                      [value]="filterStatus[item.name]"
                      (valueChange)="statusCheckboxChanged(item.name, $event)"
                    >
                      {{item.name}}
                    </nb-checkbox>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12"><b>Type</b></div>
                </div>
                <div class="row mt-2" *ngFor="let group of taskTypeWrappers">
                  <div class="col-12">{{group.group?.name}}</div>
                  <div class="col-sm-2" *ngFor="let item of group?.types">
                    <nb-checkbox
                      [value]="filterType[item.name]"
                      (valueChange)="typeCheckboxChanged(item.taskTypeId, $event)"
                    >
                      {{item.name}}
                    </nb-checkbox>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-6 col-lg-6 col-sm-12">
                    <div class="form-group mt-2">
                      <span class="label mr-2">Assign to</span>
                      <input #autoInput
                             nbInput
                             class="w-75"
                             type="text"
                             (input)="autoCompleteBehavior.next(autoInput.value)"
                             [value]="filterUser?.username"
                             [nbAutocomplete]="auto"
                      />
                      <nb-checkbox class="ml-4" [value]="onlyFilterMySelf" (change)="setOnlyFilterMySelf()">
                        My Tasks
                      </nb-checkbox>

                      <nb-autocomplete #auto (selectedChange)="onUserFilterSelectionChange($event)">

                        <nb-option *ngFor="let user of (filteredUsers | async)?.data" [value]="user.username">
                          {{ user.username }}
                        </nb-option>

                      </nb-autocomplete>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-6 col-sm-10 button-container">
                    <button class="float-right col-lg-3 col-md-3 col-sm-3 mb-1" nbButton status="primary" (click)="openTaskDialog(null)" *appPermissionCheck='ComponentPermission.TASK_CREATE'>New task</button>
                    <button class="float-right col-lg-3 col-md-3 col-sm-3 mb-1" nbButton status="success" (click)="filterTasks()">Search</button>
                    <button class="float-right col-lg-3 col-md-4 col-sm-3" nbButton (click)="clearFilter()">Clear filter</button>
                  </div>
                </div>
              </nb-accordion-item-body>
            </nb-accordion-item>
          </nb-accordion>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 text-center">
          <span [hidden]="!taskStatistics || taskStatistics.length <= 0">Total |</span>
          <span *ngFor="let item of taskStatistics">
            {{ item['name'] }}: {{ item.total }} |
          </span>
        </div>
      </div>


      <ng-template #loading>
        <div class="text-center">
          <i class="fas fa-spin fa-spinner fa-5x"></i> <br/>
          Loading tasks...
        </div>
      </ng-template>
      <div class="calendar-container" [ngClass]="{'popup-hide': isDisplay}">

        <div class="row text-center">
          <div class="col-md-4">
            <div class="btn-group">
              <div
                class="btn btn-primary"
                mwlCalendarPreviousView
                [view]="view"
                [(viewDate)]="viewDate"
                (viewDateChange)="closeOpenMonthViewDay()"
              >
                Previous
              </div>
              <div
                class="btn btn-outline-secondary"
                mwlCalendarToday
                [(viewDate)]="viewDate"
                (viewDateChange)="closeOpenMonthViewDay()"
              >
                Today
              </div>
              <div
                class="btn btn-primary"
                mwlCalendarNextView
                [view]="view"
                [(viewDate)]="viewDate"
                (viewDateChange)="closeOpenMonthViewDay()"
              >
                Next
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <h3 class="mb-0">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
          </div>
          <div class="col-md-4">
            <div class="btn-group">
              <div
                class="btn btn-primary"
                (click)="setView(CalendarView.Month)"
                [class.active]="view === CalendarView.Month"
              >
                Month
              </div>
              <div
                class="btn btn-primary"
                (click)="setView(CalendarView.Week)"
                [class.active]="view === CalendarView.Week"
              >
                Week
              </div>
              <div
                class="btn btn-primary"
                (click)="setView(CalendarView.Day)"
                [class.active]="view === CalendarView.Day"
              >
                Day
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="events | async; else loading; let events" class="cal-wrap-container">
          <div [ngSwitch]="view" class="cal-container" *ngIf='taskTypes'>
            <ngx-custom-month-view
              *ngSwitchCase="CalendarView.Month"
              [viewDate]="viewDate"
              [events]="events"
              [refresh]="refresh"
              [weekStartsOn]="weekStartOn"
              [cellTemplate]="monthCellTemplate"
              [weekStatistics]="weekStatistics"
              [taskTypes]="taskTypes"
              (passWeek)="getWeekStatistic($event)"
              (eventTimesChanged)="eventTimesChanged($event)"
            >
            </ngx-custom-month-view>
            <ngx-custom-week-view
              *ngSwitchCase="CalendarView.Week"
              [viewDate]="viewDate"
              [events]="events"
              [refresh]="refresh"
              [hourSegmentTemplate]="weekViewHourSegment"
              [eventTemplate]="weekViewEventTemplate"
              [weekStartsOn]="weekStartOn"
              [headerTemplate]="weekHeaderTemplate"
              (eventTimesChanged)="eventTimesChanged($event)"
            >
            </ngx-custom-week-view>
            <mwl-calendar-day-view
              *ngSwitchCase="CalendarView.Day"
              [viewDate]="viewDate"
              [events]="events"
              [refresh]="refresh"
              [eventTemplate]="weekViewEventTemplate"
              [hourSegmentTemplate]="dayHourSegmentTemplate"
              (eventClicked)="editTask($event.event)"
              (eventTimesChanged)="eventTimesChanged($event)"
            >
            </mwl-calendar-day-view>
          </div>
        </div>

      </div>
    </nb-card-body>
  </nb-card>

  <!-- cell templates-->

  <ng-template
    #monthCellTemplate
    let-day="day"
    let-openDay="openDay"
    let-locale="locale"
    let-tooltipPlacement="tooltipPlacement"
    let-highlightDay="highlightDay"
    let-unhighlightDay="unhighlightDay"
    let-eventClicked="eventClicked"
  >
    <div
      class="fill-height"
    >
      <div class="cal-cell-top">
        <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
        <span class="cal-day-number">
        {{ day.date | calendarDate:'monthViewDayNumber':locale }}
      </span>
      </div>
      <div *ngFor="let event of day.events"
           [ngStyle]="{'background-color': event?.meta?.type?.color ? event?.meta?.type?.color : 'gray'}"
           class="event-container"
           (click)="editTask(event)"
           mwlDraggable
           [class.cal-draggable]="event.draggable"
           dragActiveClass="cal-drag-active"
           [dropData]="{event: event}"
           [dragAxis]="{x: event.draggable, y: event.draggable}"
      >
        <div class="row w-100 m-0">
          <div class="col-6 p-0">
            <b class="element-time">{{event?.start | date: 'shortTime'}} </b>
            <span [ngClass]="isSpOrS4(event?.meta) ? 'bold-text' : ''">{{shortenUserName(event?.meta?.toUser) + (event?.meta?.title ?  (' - ' +  event?.meta?.title) : '')}} </span>
          </div>
          <div class="col-6 p-0">
            <nb-checkbox class="sp-checkbox mr-2 float-right" [checked]="event?.meta?.isS4"
                         (click)="updateTaskSPS4($event, event?.meta, 's4')"
                         [hidden]="checkToHideS4Options(event?.meta)"
            >
              S4
            </nb-checkbox>
            <nb-checkbox class="sp-checkbox mr-2 float-right"
                         [checked]="event?.meta?.isSp"
                         [hidden]="checkToHideSPOptions(event?.meta)"
                         (click)="updateTaskSPS4($event, event?.meta, 'sp')">
              SP
            </nb-checkbox>
            <nb-checkbox class="sp-checkbox mr-2 float-right" [checked]="event?.meta?.isB4"
                         (click)="updateTaskBiB4($event, event?.meta, 'b4')"
                         [hidden]="checkToHideBOptions(event?.meta)"
            >
              B4
            </nb-checkbox>
            <nb-checkbox class="sp-checkbox mr-2 float-right" [checked]="event?.meta?.isBi"
                         (click)="updateTaskBiB4($event, event?.meta, 'bi')"
                         [hidden]="checkToHideBOptions(event?.meta)"
            >
              BI
            </nb-checkbox>
          </div>
        </div>
        <b *ngIf="event?.meta?.toUser?.phone">Phone: {{event?.meta?.toUser?.phone}} </b>
        <p nbTooltip="{{getAddressText(event?.meta?.prospect)}}"
           [ngClass]="isSpOrS4(event?.meta) ? 'bold-text' : ''">
          {{getAddressShortText(event?.meta?.prospect)}}
        </p>
        <p *ngIf="event?.meta?.type?.category?.name === 'CLIENT'"
           [ngClass]="isSpOrS4(event?.meta) ? 'bold-text' : ''">
        <span *ngIf="event?.meta?.prospect?.customer?.name">
        <nb-icon class="sp-icon"  [icon]="'person-outline'"
                 pack="eva">
        </nb-icon>
          {{event?.meta?.prospect?.customer?.name}}
        </span>
          <span *ngIf="event?.meta?.prospect?.customer?.mobilePhone">
          <nb-icon class="sp-icon" [icon]="'smartphone-outline'"
                   pack="eva">
          </nb-icon>
            {{event?.meta?.prospect?.customer?.mobilePhone}}
        </span>
        </p>

        <p *ngIf="event?.meta?.type?.category?.name === 'CUSTOMER'">
        <span *ngIf="event?.meta?.buyer?.customer?.name">
        <nb-icon class="sp-icon"  [icon]="'person-outline'"
                 pack="eva">
        </nb-icon>
          {{event?.meta?.buyer?.customer?.name}}
        </span>
          <span *ngIf="event?.meta?.buyer?.customer?.mobilePhone">
          <nb-icon class="sp-icon" [icon]="'smartphone-outline'"
                   pack="eva">
          </nb-icon>
            {{event?.meta?.buyer?.customer?.mobilePhone}}
        </span>
        </p>
      </div>
    </div>
  </ng-template>

  <ng-template
    #weekHeaderTemplate
    let-days="days"
    let-locale="locale"
    let-dayHeaderClicked="dayHeaderClicked"
  >
    <div class="cal-day-headers">
      <div
        class="cal-header"
        *ngFor="let day of days; let i = index"
        [class.cal-past]="day.isPast"
        [class.cal-today]="day.isToday"
        [class.cal-future]="day.isFuture"
        [class.cal-weekend]="day.isWeekend"
        (click)="dayHeaderClicked.emit({day: day})"
      >
        <div>
          <span *ngIf="i === 0">({{getNoWeek(day.date)}}) </span>
          <b>{{ day.date | calendarDate:'weekViewColumnHeader':locale }}</b><br/>
          <span
          >{{ day.date | calendarDate:'weekViewColumnSubHeader':locale }}</span
          >
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template
    #dayHourSegmentTemplate
    let-segment="segment"
    let-locale="locale"
    let-segmentHeight="segmentHeight"
  >
    <div *ngIf="(segment.date | calendarDate: 'dayViewHour':locale) === '12 AM' && segment.isStart">{{getNoWeek(segment.date)}}</div>
    <div
      class="cal-hour-segment"
      [style.height.px]="segmentHeight"
      [class.cal-hour-start]="segment.isStart"
      [class.cal-after-hour-start]="!segment.isStart"
      [ngClass]="segment.cssClass"
    >
      <div class="cal-time">
        {{ segment.date | calendarDate: 'dayViewHour':locale }}
      </div>
    </div>
  </ng-template>

  <ng-template
    #weekViewHourSegment
    let-segment="segment"
    let-locale="locale"
    let-segmentHeight="segmentHeight"
    let-isTimeLabel="isTimeLabel"
  >
    <div
      class="cal-hour-segment"
      [style.height.px]="segmentHeight"
      [class.cal-hour-start]="segment.isStart"
      [class.cal-after-hour-start]="!segment.isStart"
      [ngClass]="segment.cssClass"
    >
      <div class="cal-time" *ngIf="isTimeLabel">
        {{ segment.date | calendarDate: 'weekViewHour':locale }}
      </div>
    </div>
  </ng-template>

  <!--event template for week view-->
  <ng-template
    #weekViewEventTemplate
    let-weekEvent="weekEvent"
    let-tooltipPlacement="tooltipPlacement"
    let-eventClicked="eventClicked"
    let-tooltipTemplate="tooltipTemplate"
    let-tooltipAppendToBody="tooltipAppendToBody"
    let-tooltipDisabled="tooltipDisabled">
    <div
      class="cal-event"
      [style.backgroundColor]="weekEvent.event.color?.secondary"
      [style.borderColor]="weekEvent.event.color?.primary"
      [mwlCalendarTooltip]="!tooltipDisabled ? (weekEvent.event.title | calendarEventTitle:'weekTooltip':weekEvent.event) : ''"
      [tooltipPlacement]="tooltipPlacement"
      [tooltipEvent]="weekEvent.event"
      [tooltipTemplate]="tooltipTemplate"
      [tooltipAppendToBody]="tooltipAppendToBody"
      (mwlClick)="eventClicked.emit()"
      style="padding: 0; height: 110px; word-break: break-word; white-space: pre-wrap; -moz-white-space: pre-wrap;"
      (click)="editTask(weekEvent?.event)"
    >
      <div class="event-container-day-view"
           [ngStyle]="{'background-color': weekEvent?.event?.meta?.type?.color ? weekEvent?.event?.meta?.type?.color : 'gray'}"
      >
        <div class="row w-100 m-0">
          <div class="col-6 p-0">
            <b class="element-time">{{weekEvent?.event?.start | date: 'shortTime'}} </b>
            <span [ngClass]="isSpOrS4(weekEvent?.event?.meta) ? 'bold-text' : ''">{{shortenUserName(weekEvent?.event?.meta?.toUser) + (weekEvent?.event?.meta?.title ?  (' - ' +  weekEvent?.event?.meta?.title) : '')}} </span>
          </div>
          <div class="col-6 p-0">
            <nb-checkbox class="sp-checkbox mr-2 float-right" [checked]="weekEvent?.event?.meta?.isS4"
                         (click)="updateTaskSPS4($event, weekEvent?.event?.meta, 's4')"
                         [hidden]="checkToHideS4Options(weekEvent?.event?.meta)"
            >
              S4
            </nb-checkbox>
            <nb-checkbox class="sp-checkbox mr-2 float-right" [checked]="weekEvent?.event?.meta?.isSp"
                         (click)="updateTaskSPS4($event, weekEvent?.event?.meta, 'sp')"
                         [hidden]="checkToHideSPOptions(weekEvent?.event?.meta)"
            >
              SP
            </nb-checkbox>
            <nb-checkbox class="sp-checkbox mr-2 float-right" [checked]="weekEvent?.event?.meta?.isB4"
                         (click)="updateTaskBiB4($event, weekEvent?.event?.meta, 'b4')"
                         [hidden]="checkToHideBOptions(weekEvent?.event?.meta)"
            >
              B4
            </nb-checkbox>
            <nb-checkbox class="sp-checkbox mr-2 float-right" [checked]="weekEvent?.event?.meta?.isBi"
                         (click)="updateTaskBiB4($event, weekEvent?.event?.meta, 'bi')"
                         [hidden]="checkToHideBOptions(weekEvent?.event?.meta)"
            >
              BI
            </nb-checkbox>
          </div>
        </div>
        <b class="element-time">{{weekEvent?.event?.start | date: 'shortTime'}} </b>ssss
        <span [ngClass]="isSpOrS4(weekEvent?.event?.meta) ? 'bold-text' : ''">{{shortenUserName(weekEvent?.event?.meta?.toUser)}} </span>
        <b *ngIf="weekEvent?.event?.meta?.toUser?.phone">Phone: {{weekEvent?.event?.meta?.toUser?.phone}} </b>
        <p nbTooltip="{{getAddressText(weekEvent?.event?.meta?.prospect)}}"
           [ngClass]="isSpOrS4(weekEvent?.event?.meta) ? 'bold-text' : ''"
        >
          {{getAddressShortText(weekEvent?.event?.meta?.prospect)}}
        </p>
        <p *ngIf="weekEvent?.event?.meta?.type?.category?.name === 'CLIENT'" [ngClass]="isSpOrS4(weekEvent?.event?.meta) ? 'bold-text' : ''">
        <span *ngIf="weekEvent?.event?.meta?.prospect?.customer?.name">
          <nb-icon class="sp-icon" [icon]="'person-outline'"
                   pack="eva">
        </nb-icon>
          {{weekEvent?.event?.meta?.prospect?.customer?.name}}
        </span>
          <span *ngIf="weekEvent?.event?.meta?.prospect?.customer?.mobilePhone">
          <nb-icon class="sp-icon" [icon]="'smartphone-outline'"
                   pack="eva">
          </nb-icon>
            {{weekEvent?.event?.meta?.prospect?.customer?.mobilePhone}}
        </span>
        </p>
        <p *ngIf="weekEvent?.event?.meta?.type?.category?.name === 'CUSTOMER'">
        <span *ngIf="weekEvent?.event?.meta?.buyer?.customer?.name">
          <nb-icon class="sp-icon" [icon]="'person-outline'"
                   pack="eva">
        </nb-icon>
          {{weekEvent?.event?.meta?.buyer?.customer?.name}}
        </span>
          <span *ngIf="weekEvent?.event?.meta?.buyer?.customer?.mobilePhone">
          <nb-icon class="sp-icon" [icon]="'smartphone-outline'"
                   pack="eva">
          </nb-icon>
            {{weekEvent?.event?.meta?.buyer?.customer?.mobilePhone}}
        </span>
        </p>
      </div>
    </div>
  </ng-template>

</div>
