export class CustomerAdditionalInfo {
  customerAdditionalInfoId: number;
  customerId: number;
  name: string;
  phoneNumber: string;
  phoneNumberType: string;
  createdBy: string;
  createdDate: number;
  isDeleted?: boolean;
  call: boolean;
}
