import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { City } from '../../../../../shared/models/response/address/city';
import { District } from '../../../../../shared/models/response/address/district';
import { Street } from '../../../../../shared/models/response/address/street';
import { CustomerBoardSearchRequest } from '../../../../../shared/models/request/buyer/customer-board-search-request';
import { Farm } from '../../../../../shared/models/response/address/farm';
import { Zone } from '../../../../../shared/models/response/address/zone';
import { Observable, of } from 'rxjs';
import { Category } from '../../../../../shared/models/response/category';
import { SearchRequest } from '../../../../../shared/models/request/search-request';
import { Triple } from '../../../../../shared/models/request/triple';
import { map, mergeMap } from 'rxjs/operators';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { CategoryService } from '../../../../../shared/services/category.service';
import { User } from '../../../../../shared/models/response/user';
import { Constant } from '../../../../../shared/common/constant';
import { StringUtils } from '../../../../../shared/common/string-utils';
import { UserService } from '../../../../../shared/services/user.service';
import { UserUtils } from '../../../../../shared/common/user-utils';
import { AuthService } from '../../../../../shared/services/auth.service';
import {ComponentPermission} from '../../../../../component.permission';
import { Postcode } from '../../../../../shared/models/response/postcode';
import { ActivatedRoute } from '@angular/router';
import { PostcodeService } from '../../../../../shared/services/postcode.service';

@Component({
  selector: 'ngx-customer-board-filter-advance',
  templateUrl: './customer-board-filter-advance.component.html',
  styleUrls: ['./customer-board-filter-advance.component.scss'],
})
export class CustomerBoardFilterAdvanceComponent implements OnInit {
  @Input() districts: District[];
  @Input() streets: Street[];
  @Input() cities: City[];
  @Input() farms: Farm[];
  @Input() zones: Zone[];
  @Input() postcodes: string[];
  @Output() onSuburbChange = new EventEmitter();
  @Output() onCityChange = new EventEmitter();
  @Output() onZoneChange = new EventEmitter();
  @Output() onFarmChange = new EventEmitter();
  @Output() onStreetChange = new EventEmitter();
  @Output() onSearch = new EventEmitter();
  @Output() onSelectCategory = new EventEmitter();
  @Output() onRemoveCategory = new EventEmitter();
  @Output() onSelectUser = new EventEmitter();
  @Output() onRemoveUser = new EventEmitter();
  @Output() onRateChange = new EventEmitter();
  @Output() onPostcodeChange = new EventEmitter();

  @Input() searchRequest: CustomerBoardSearchRequest;

  filterCategory$: Observable<Category[]>;
  categoryName: string;
  selectedCategories: Category[] = [];
  filterUser$: Observable<User[]>;
  userName: string;
  selectedUser: User;
  stringUtils = StringUtils;
  userUtils = UserUtils;
  ComponentPermission = ComponentPermission;
  currentUser: User;
  rateOptions = [
    { value: 0, label: '0' },
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
  ];
  buyerTagButtons = [
    {
      key: 'T10-BA',
      value: 2,
      selected: false,
    },
    {
      key: 'B4',
      value: 26,
      selected: false,
    },
    {
      key: 'B5',
      value: 25,
      selected: false,
    },
    {
      key: 'B6',
      value: 24,
      selected: false,
    },
    {
      key: 'B7',
      value: 23,
      selected: false,
    },
  ];
  @Input() postcode: number;
  @Output() onSearchByPostcode = new EventEmitter();
  filterByPostcode = false;
  filterPostcode$: Observable<Postcode[]>;

  constructor(private categoryService: CategoryService,
              private authService: AuthService,
              private userService: UserService,
              private postcodeService: PostcodeService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.currentUser = this.authService.currentUser;
    this.initAutoComplete();
    if (this.route.snapshot.queryParams['postcode']) {
      this.filterByPostcode = true;
    }
  }

  initAutoComplete() {
    if (!this.userUtils.userIsAdmin(this.currentUser)) {
      this.selectedUser = this.currentUser;
    } else {
      this.selectedUser = undefined;
    }
    this.filterCategory$ = new Observable((observer: any) => {
      observer.next(this.categoryName);
    }).pipe(
      mergeMap((query: string) => this.searchCategory(query)),
    );

    this.filterUser$ = new Observable((observer: any) => {
      observer.next(this.userName);
    }).pipe(
      mergeMap((query: string) => this.searchUser(query)),
    );
    this.filterPostcode$ = new Observable((observer: any) => {
      observer.next(this.postcode);
    }).pipe(
      map((query: string) => {
        this.postcode = Number(query);
        this.searchSuburbByPostcode();
        return null;
      }),
    );

    if (localStorage.getItem(Constant.CUSTOMER_CATEGORY_LIST)) {
      this.selectedCategories = JSON.parse(localStorage.getItem(Constant.CUSTOMER_CATEGORY_LIST));
      this.selectedCategories.forEach(category => {
        this.buyerTagButtons.find(tag => {
          if (tag.value === category.categoryId) {
            tag.selected = true;
          }
        });
      });
    }
  }

  onSuburbSelectChange() {
    this.onSuburbChange.emit(this.searchRequest.districtId);
  }

  onCitySelectChange() {
    this.onCityChange.emit(this.searchRequest.cityId);
  }

  onFarmSelectChange() {
    this.onFarmChange.emit(this.searchRequest.farmId);
  }

  onZoneSelectChange() {
    this.onZoneChange.emit(this.searchRequest.zoneId);
  }

  onPostcodeSelectChange() {
    this.onPostcodeChange.emit(this.searchRequest.postcode);
  }

  onStreetSelectChange() {
    this.onStreetChange.emit(this.searchRequest.streetId);
  }

  onRateSelectChange() {
    this.onRateChange.emit(this.searchRequest.rate);
  }

  search() {
    this.onSearch.emit();
  }

  searchCategory(name: string): Observable<Category[]> {
    if (!name || name === '') {
      return of([]);
    }
    const searchRequest = new SearchRequest();
    searchRequest.conditions = new Array();
    searchRequest.conditionType = 'AND';
    searchRequest.subConditions = [];
    searchRequest.conditions.push(new Triple('name', 'like', name));
    const subCon = new SearchRequest();
    subCon.conditionType = 'OR';
    subCon.conditions = [];
    subCon.conditions.push(new Triple('type', '=', 'BUYER'));
    subCon.conditions.push(new Triple('type', '=', 'BOTH'));
    searchRequest.subConditions.push(subCon);
    return this.categoryService.search(searchRequest).pipe(
      map(result => result.data),
    );
  }

  selectCategory(type: TypeaheadMatch) {
    this.categoryName = '';
    const selectedCategory: Category = type.item;
    if (this.selectedCategories.filter(category => category.categoryId === selectedCategory.categoryId).length > 0) {
      return;
    }
    this.selectedCategories.push(selectedCategory);
    localStorage.setItem(Constant.CUSTOMER_CATEGORY_LIST, JSON.stringify(this.selectedCategories));
    this.onSelectCategory.emit(selectedCategory.categoryId);
  }

  removeCategory(removedCategory: Category) {
    if (removedCategory) {
      this.buyerTagButtons.find(tag => {
        if (tag.value === removedCategory.categoryId) {
          tag.selected = false;
        }
      });
      this.selectedCategories = this.selectedCategories.filter
      (category => category.categoryId !== removedCategory.categoryId);
      localStorage.setItem(Constant.CUSTOMER_CATEGORY_LIST, JSON.stringify(this.selectedCategories));
      this.onRemoveCategory.emit(removedCategory.categoryId);
    }
  }

  searchUser(keyword: string): Observable<User[]> {
    // this.refreshUserFilter();
    const searchRequest = new SearchRequest();
    searchRequest.conditions = new Array();
    searchRequest.conditions.push(
      {
        left: 'email',
        middle: 'like',
        right: keyword,
      },
    );
    searchRequest.conditions.push(
      {
        left: 'username',
        middle: 'like',
        right: keyword,
      },
    );
    searchRequest.conditions.push(
      {
        left: 'name',
        middle: 'like',
        right: keyword,
      },
    );
    searchRequest.conditionType = Constant.CONDITION_TYPE.OR;
    searchRequest.offset = 0;
    searchRequest.limit = 10;
    return this.userService.search(searchRequest).pipe(
      map(result => result.data.filter(user => user.status === 'ACTIVE')),
    );
  }

  selectSelf() {
    this.selectedUser = this.currentUser;
    this.onSelectUser.emit(this.selectedUser.userId);
  }

  selectUser(type: TypeaheadMatch) {
    this.userName = '';
    this.selectedUser = type.item;
    this.onSelectUser.emit(this.selectedUser.userId);
  }

  removeUser() {
    this.onRemoveUser.emit(this.selectedUser.userId);
    this.selectedUser = null;
  }

  selectBuyerTag(categoryId: number) {
    this.buyerTagButtons.find(tag => {
      if (tag.value === categoryId) {
        tag.selected = true;
      }
    });
    this.categoryService.getOne(categoryId).subscribe(result => {
      this.selectedCategories.push(result.data);
      this.onSelectCategory.emit(categoryId);
      localStorage.setItem(Constant.CLIENT_CATEGORY_LIST, JSON.stringify(this.selectedCategories));
    });
  }

  searchPostcode(keyword: string): Observable<Postcode[]> {
    const searchRequest = new SearchRequest();
    searchRequest.conditions = [];
    searchRequest.orders = [];
    searchRequest.conditions.push(
      {
        left: 'code',
        middle: '=',
        right: keyword,
      },
    );
    searchRequest.orders.push(
      {
        left: 'code',
        right: 'asc'
      }
    );
    searchRequest.offset = 0;
    searchRequest.limit = 10;
    return this.postcodeService.search(searchRequest).pipe(
      map(result => result.data),
    );
  }

  selectPostcode(type: TypeaheadMatch) {
    this.postcode = Number(type.item.code);
    this.searchSuburbByPostcode();
  }

  searchSuburbByPostcode() {
    if (this.postcode === null) {
      return;
    }
    this.filterByPostcode = true;
    this.onSearchByPostcode.emit(this.postcode);
  }
}
