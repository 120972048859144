import {AfterViewInit, Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Constant} from '../../../../../shared/common/constant';
import {DefaultEditor} from 'ng2-smart-table';

@Component({
  selector: 'ngx-task-calendar-status-editor',
  template: `
    <nb-select selected="" [disabled]="!cell.isEditable()"
               (selectedChange)="handleSelectedValueChanged($event)">
      <nb-option value="">Empty</nb-option>
      <nb-option *ngFor="let item of taskStatuses | keyvalue" value="{{item.key}}">{{item.value}}</nb-option>
    </nb-select>
    <div [hidden]="true" [innerHTML]="cell.getValue()" #htmlValue></div>
  `,
})
export class TaskCalendarStatusEditorComponent extends DefaultEditor implements AfterViewInit {
  @ViewChild('htmlValue') htmlValue: ElementRef;

  taskStatuses = Constant.TASK_STATUS;
  filterStatus = {
    DONE: false,
    PENDING: false,
    NOT_COMPLETED: false,
    BLOCKED: false,
    CANCEL: false,
    IN_PROGRESS: false,
  };

  constructor() {
    super();
  }

  ngAfterViewInit() {
    // if (this.cell.newValue !== '') {
    //   this.name.nativeElement.value = this.getUrlName();
    //   this.url.nativeElement.value = this.getUrlHref();
    // }
  }

  handleSelectedValueChanged($event) {
    this.cell.newValue = $event;
  }
}
