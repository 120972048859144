import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { City } from '../../../../../../shared/models/response/address/city';
import { District } from '../../../../../../shared/models/response/address/district';
import { Street } from '../../../../../../shared/models/response/address/street';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { CategoryService } from '../../../../../../shared/services/category.service';
import { Category } from '../../../../../../shared/models/response/category';
import { SearchRequest } from '../../../../../../shared/models/request/search-request';
import { Triple } from '../../../../../../shared/models/request/triple';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { User } from '../../../../../../shared/models/response/user';
import { Constant } from '../../../../../../shared/common/constant';
import { StringUtils } from '../../../../../../shared/common/string-utils';
import { UserService } from '../../../../../../shared/services/user.service';
import { UserUtils } from '../../../../../../shared/common/user-utils';
import { AuthService } from '../../../../../../shared/services/auth.service';
import { ComponentPermission } from '../../../../../../component.permission';
import { ActivatedRoute } from '@angular/router';
import { ProspectSearchRequest } from '../../../../../../shared/models/request/prospect/prospect-search-request';
import { Postcode } from '../../../../../../shared/models/response/postcode';
import { PostcodeService } from '../../../../../../shared/services/postcode.service';

@Component({
  selector: 'ngx-prospect-list-filter',
  templateUrl: './prospect-list-filter.component.html',
  styleUrls: ['./prospect-list-filter.component.scss'],
})
export class ProspectListFilterComponent implements OnInit {
  @Input() districts: District[];
  @Input() districtId: number;
  @Input() streets: Street[];
  @Input() streetId: number;
  @Input() cities: City[];
  @Input() cityId: number;
  @Input() sortTypesText: any;
  @Input() sortType: string;
  @Input() total: number;
  @Input() totalMail: number;
  @Input() totalDoor: number;
  @Input() totalCall: number;
  @Input() totalSms: number;
  @Input() isDkMode: boolean = false;
  @Input() isCAMode: boolean = false;
  @Input() farm: string;
  @Input() zone: string;
  @Output() onSuburbChange = new EventEmitter();
  @Output() onStreetChange = new EventEmitter();
  @Output() onCityChange = new EventEmitter();
  @Output() onSortTypeChange = new EventEmitter();
  @Output() onAddNewProspect = new EventEmitter();
  @Output() onDkMode = new EventEmitter();
  @Output() onCaMode = new EventEmitter();
  @Output() onOpenShortcut = new EventEmitter();
  @Output() onSelectCategory = new EventEmitter();
  @Output() onRemoveCategory = new EventEmitter();
  @Output() onSelectUser = new EventEmitter();
  @Output() onRemoveUser = new EventEmitter();
  @Output() onSearch = new EventEmitter();
  @Output() onSelectRate = new EventEmitter();
  @Output() onPhoneNumberChange = new EventEmitter();
  @Input() selectedCategories: Category[] = [];
  @Input() rate: number;
  filterCategory$: Observable<Category[]>;
  categoryName: string;
  filterUser$: Observable<User[]>;
  userName: string;
  selectedUser: User;
  stringUtils = StringUtils;
  userUtils = UserUtils;
  currentUser: User;
  ComponentPermission = ComponentPermission;
  expandAdvance: boolean = false;
  @Input() phone: string;
  @Input() postcode: number;
  @Output() onSearchByPostcode = new EventEmitter();
  filterByPostcode = false;
  filterPostcode$: Observable<Postcode[]>;


  rateOptions = [
    { value: 0, label: '0' },
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
  ];

  constructor(private categoryService: CategoryService,
              private authService: AuthService,
              private userService: UserService,
              private route: ActivatedRoute,
              private postcodeService: PostcodeService) {
  }

  ngOnInit(): void {
    this.currentUser = this.authService.currentUser;
    if (!this.userUtils.userIsAdmin(this.currentUser)) {
      this.selectedUser = this.currentUser;
    } else {
      this.selectedUser = undefined;
    }
    this.initAutocomplete();
    this.appendSelectedCategoryFromParameter();
    this.appendSelectedUserFromParameter();
    if (this.route.snapshot.queryParams['postcode']) {
      this.filterByPostcode = true;
    }
  }

  initAutocomplete() {
    this.filterCategory$ = new Observable((observer: any) => {
      observer.next(this.categoryName);
    }).pipe(
      mergeMap((query: string) => this.searchCategory(query)),
    );
    this.filterUser$ = new Observable((observer: any) => {
      observer.next(this.userName);
    }).pipe(
      mergeMap((query: string) => this.searchUser(query)),
    );
    this.filterPostcode$ = new Observable((observer: any) => {
      observer.next(this.postcode);
    }).pipe(
      map((query: string) => {
        this.postcode = Number(query);
        this.searchSuburbByPostcode();
        return null;
      }),
    );
  }

  onSuburbSelectChange() {
    this.onSuburbChange.emit(isNaN(Number(this.districtId)) ? undefined : Number(this.districtId));
  }

  onStreetSelectChange() {
    this.onStreetChange.emit(isNaN(Number(this.streetId)) ? undefined : Number(this.streetId));
  }

  onCitySelectChange() {
    this.onCityChange.emit(isNaN(Number(this.cityId)) ? undefined : Number(this.cityId));
  }

  onSortTypeSelectChane() {
    this.onSortTypeChange.emit(this.sortType);
  }
  onNumberChange() {
    this.onPhoneNumberChange.emit(this.phone);
  }

  addNewProspect() {
    this.onAddNewProspect.emit();
  }

  onDkModeChange() {
    this.onDkMode.emit(!this.isDkMode);
  }

  onCaModeChange() {
    this.onCaMode.emit(!this.isCAMode);
  }

  openShortcut() {
    this.onOpenShortcut.emit();
  }

  searchCategory(name: string): Observable<Category[]> {
    if (!name || name === '') {
      return of([]);
    }
    const searchRequest = new SearchRequest();
    searchRequest.conditions = [];
    searchRequest.conditionType = 'AND';
    searchRequest.subConditions = [];
    searchRequest.conditions.push(new Triple('name', 'like', name));
    const subCon = new SearchRequest();
    subCon.conditionType = 'OR';
    subCon.conditions = [];
    subCon.conditions.push(new Triple('type', '=', 'PROSPECT'));
    subCon.conditions.push(new Triple('type', '=', 'BOTH'));
    searchRequest.subConditions.push(subCon);
    return this.categoryService.search(searchRequest).pipe(
      map(result => result.data),
    );
  }

  selectCategory(type: TypeaheadMatch) {
    this.categoryName = '';
    const selectedCategory: Category = type.item;
    if (this.selectedCategories.filter(category => category.categoryId === selectedCategory.categoryId).length > 0) {
      return;
    }
    this.selectedCategories.push(selectedCategory);
    this.onSelectCategory.emit(selectedCategory.categoryId);
  }

  removeCategory(removedCategory: Category) {
    if (removedCategory) {
      this.selectedCategories = this.selectedCategories.filter
      (category => category.categoryId !== removedCategory.categoryId);
      this.onRemoveCategory.emit(removedCategory.categoryId);
    }
  }

  searchUser(keyword: string): Observable<User[]> {
    // this.refreshUserFilter();
    const searchRequest = new SearchRequest();
    searchRequest.conditions = new Array();
    searchRequest.conditions.push(
      {
        left: 'email',
        middle: 'like',
        right: keyword,
      },
    );
    searchRequest.conditions.push(
      {
        left: 'username',
        middle: 'like',
        right: keyword,
      },
    );
    searchRequest.conditions.push(
      {
        left: 'name',
        middle: 'like',
        right: keyword,
      },
    );
    searchRequest.conditionType = Constant.CONDITION_TYPE.OR;
    searchRequest.offset = 0;
    searchRequest.limit = 10;
    return this.userService.search(searchRequest).pipe(
      map(result => result.data.filter(user => user.status === 'ACTIVE')),
    );
  }

  selectUser(type: TypeaheadMatch) {
    this.userName = '';
    this.selectedUser = type.item;
    this.onSelectUser.emit(this.selectedUser.userId);
  }

  removeUser() {
    this.onRemoveUser.emit(this.selectedUser.userId);
    this.selectedUser = null;
  }

  searchPostcode(keyword: string): Observable<Postcode[]> {
    const searchRequest = new SearchRequest();
    searchRequest.conditions = [];
    searchRequest.orders = [];
    searchRequest.conditions.push(
      {
        left: 'code',
        middle: '=',
        right: keyword,
      },
    );
    searchRequest.orders.push(
      {
        left: 'code',
        right: 'asc'
      }
    );
    searchRequest.offset = 0;
    searchRequest.limit = 10;
    return this.postcodeService.search(searchRequest).pipe(
      map(result => result.data),
    );
  }

  selectPostcode(type: TypeaheadMatch) {
    this.postcode = Number(type.item.code);
    this.searchSuburbByPostcode();
  }

  onSearchButtonClick() {
    this.onSearch.emit(true);
  }

  onRateSelectChange(event: any) {
    if (event === null) {
      this.rate = undefined;
    } else {
      this.rate = Number(event);
    }
    this.onSelectRate.emit(this.rate);
  }

  toggleAdvance() {
    this.expandAdvance = !this.expandAdvance;
  }

  appendSelectedCategoryFromParameter() {
    if (!this.route.snapshot.queryParams['categoryIds']) {
      return;
    }
    let categoryIds = [];
    if (typeof this.route.snapshot.queryParams['categoryIds'] === 'string') {
      categoryIds.push(Number(this.route.snapshot.queryParams['categoryIds']));
    } else {
      categoryIds = this.route.snapshot.queryParams['categoryIds'].map(id => {
        return Number(id);
      });
    }
    this.searchCategoryById(categoryIds);
  }

  searchCategoryById(categoryIds: number[]) {
    const searchRequest = new SearchRequest();
    searchRequest.conditions = [];
    searchRequest.conditions.push(
      {
        left: 'categoryId',
        middle: 'in',
        right: categoryIds
      }
    );
    this.categoryService.search(searchRequest).subscribe(result => {
      this.selectedCategories = result.data;
    });
  }

  appendSelectedUserFromParameter() {
    if (!this.route.snapshot.queryParams['userId']) {
      return;
    }
    const userId = Number(this.route.snapshot.queryParams['userId']);
    this.userService.getOne(userId).subscribe(result => {
      this.selectedUser = result.data;
    });
  }

  searchSuburbByPostcode() {
    if (this.postcode === null) {
      return;
    }
    this.filterByPostcode = true;
    this.onSearchByPostcode.emit(this.postcode);
  }
}
