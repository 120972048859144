import {Buyer} from '../../../../shared/models/response/buyer/buyer';
import {BuyerGroup} from '../../../../shared/models/response/buyer/buyer-group';
import {BuyerListCustomCellComponent} from './buyer-list-custom-cell-component';
import {BuyerPickCustomCellComponent} from './buyer-pick-custom-cell-component';

export enum WrapBuyerType {
  GROUP = 'GROUP',
  ITEM = 'ITEM',
}

export class WrapBuyer {
  type: WrapBuyerType;
  isExpanded?: boolean;
  isSelected = false; // for user click to select
  selected = false; // for using checkbox
  data: Buyer;
  groupData: BuyerGroup;
}

export class BuyerUtils {

  // support for table
  static createColumn(title: string, propertyPaths: string[],
               valueType?: string, separator?: string,
               valueReplacement?: any, renderComponent?: any) {
    return {
      title: title,
      type: 'custom',
      filter: false,
      renderComponent: renderComponent ? renderComponent : BuyerListCustomCellComponent,
      onComponentInitFunction: (instance: any) => {
        if (instance instanceof BuyerListCustomCellComponent) {
          instance.config = {
            propertyPaths: propertyPaths,
            valueType: valueType,
            valueReplacement,
            separator,
          };
        }
      },
    };
  }

  static createColumns(columnsDefinition) {
    const columns = {};
    for (const key of Object.keys(columnsDefinition)) {
      if (columnsDefinition[key].enable === true) {
        columns[key] = columnsDefinition[key].value;
      }
    }
    return columns;
  }

  // support for table
  static createPickColumn(title: string, propertyPaths: string[],
                      valueType?: string, separator?: string,
                      valueReplacement?: any, renderComponent?: any) {
    return {
      title: title,
      type: 'custom',
      filter: false,
      renderComponent: renderComponent ? renderComponent : BuyerPickCustomCellComponent,
      onComponentInitFunction: (instance: any) => {
        if (instance instanceof BuyerPickCustomCellComponent) {
          instance.config = {
            propertyPaths: propertyPaths,
            valueType: valueType,
            valueReplacement,
            separator,
          };
        }
      },
    };
  }

  static createPickColumns(columnsDefinition) {
    const columns = {};
    for (const key of Object.keys(columnsDefinition)) {
      if (columnsDefinition[key].enable === true) {
        columns[key] = columnsDefinition[key].value;
      }
    }
    return columns;
  }

  static convertGroupItemToWrapperData(items: Buyer[]): WrapBuyer[] {
    if (!items) {
      return [];
    }
    const wrapProspects = [];
    for (const item of items) {
      wrapProspects.push({
        type: WrapBuyerType.ITEM,
        data: item,
      });
    }
    return wrapProspects;
  }

  static convertGroupToWrapperData(groups: BuyerGroup[]): WrapBuyer[] {
    if (!groups) {
      return [];
    }
    const wrapProspects = [];
    for (const group of groups) {
      wrapProspects.push({
        type: WrapBuyerType.GROUP,
        groupData: group,
        isExpanded: false,
      });
    }
    return wrapProspects;
  }

  // highlight by condition
  static highlightProspectRow(data: WrapBuyer) {
    let result = '';
    const buyer: Buyer = data.data;
    if (data.type === WrapBuyerType.GROUP) {
      result += ' row-type-group';
    } else {
      if (buyer?.isActive === 'INACTIVE') {
        result += ' inactive-row';
      }
    }
    if (buyer) {
      if (buyer.rate >= 4) {
        result += ' high-level';
      }
      if (buyer.isWot) {
        result += ' wot';
      }
    }
    if (data.isSelected === true) {
      result += ' is-selected';
    }
    return result;
  }
}
