import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../../shared/services/auth.service';
import {CommonService} from '../../../shared/services/common.service';
import {User} from '../../../shared/models/response/user';
import {ResetPasswordRequest} from '../../../shared/models/request/reset-password-request';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {

  done: boolean;
  email: string;
  error: string;
  currentUser: User;

  constructor(private router: Router,
              private authService: AuthService,
              private commonService: CommonService) {
  }

  ngOnInit() {
  }

  routeLogin() {
    this.router.navigate(['/auth/login']);
  }

  requestPass() {
    if (!this.email) {
      this.commonService.warning('Username and Password cannot be empty.');
      return;
    }

    if (this.email) {
      const request = new ResetPasswordRequest();
      request.email = this.email;
      request.method = 'EMAIL';
      this.authService.resetPassword(request).subscribe(
        success => {
          this.done = true;
        },
        error => {
          this.commonService.warningHtml(error.message);
        }
      );
    }
  }
}
