import {Component, OnInit} from '@angular/core';
import {NbDialogService, NbMenuService, NbSidebarService, NbThemeService} from '@nebular/theme';
import {AuthService} from '../../../shared/services/auth.service';
import {ComponentPermission} from '../../../component.permission';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {UserUtils} from '../../../shared/common/user-utils';
import {DashboardService} from '../../../shared/services/dashboard.service';
import {DailyBirthday} from '../../../shared/models/response/daily-birthday';
import {StringUtils} from '../../../shared/common/string-utils';
import {Path} from '../../../shared/common/path';
import {TaskMiniDialogComponent} from '../task/task-mini-dialog/task-mini-dialog.component';

@Component({
  selector: 'app-layout',
  styleUrls: ['layout.component.scss'],
  templateUrl: './layout.component.html',
})
export class LayoutComponent implements OnInit {
  public sidebarMinimized = true;
  public navItems = ComponentPermission.getNavigation();
  currentUser: any;
  phone: number;
  address: string;
  siteName = Path.PARTNER_NAME;
  dailyBirthday: DailyBirthday;
  total: number;
  shortFirstName: string;
  shortLastName: string;
  dark = false;

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private route: ActivatedRoute,
              public componentPermission: ComponentPermission,
              private authService: AuthService,
              private dashboardService: DashboardService,
              private themeService: NbThemeService,
              private dialogService: NbDialogService) {
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  ngOnInit() {
    this.dark = false;
    this.currentUser = this.authService.currentUser;
    this.shortFirstName = StringUtils.getShortName(this.currentUser?.firstName);
    this.shortLastName = StringUtils.getShortName(this.currentUser?.lastName);

    this.route.queryParams.subscribe(params => {
      const address = params['address'];
      if (address) {
        this.address = address;
      }

      const phone = params['phone'];
      if (phone) {
        this.phone = phone;
      }
    });
    this.dashboardService.countDailyBirthday().subscribe(result => {
      this.dailyBirthday = result.data;
      this.total = this.dailyBirthday.prospectCount + this.dailyBirthday.buyerCount;
      this.generateTooltip();
    });

    // if (!UserUtils.getDailyGoal() || !UserUtils.getDailyGoal().isCompleted) {
    //   if (this.getCurrentDayIndex() === dayMoments.indexOf(dayMoment) && this.selectedUserId.length === 1
    //     && this.selectedUserId[0] === this.currentUser.userId) {
    //     if (this.validateDayMoment(dayMoment)) {
    //       this.prospectService.getDailySms(from, to, this.selectedUserId).subscribe(r => {
    //         if (r.data === null) {
    //           r.data = 0;
    //         }
    //         const data = result.data;
    //         const total = data.totalSC + data.totalSM + data.totalDK + r.data;
    //         UserUtils.setDailyGoal(total);
    //       });
    //     }
    //   }
    // }
  }

  searchPhone() {
    window.location.href = '/pages/dashboard/search/phone?phone=' + this.phone;
  }

  searchAddress() {
    window.location.href = '/pages/dashboard/search/address?address=' + this.address.replace('/', '%2F');
  }

  logout() {
    this.authService.logout().subscribe();
  }

  listBirthdayToday() {
    window.location.href = '/pages/dashboard/dob?birthdayToday=true';
  }

  generateTooltip(): string {
    let tooltip: string;
    if (this.dailyBirthday) {
      tooltip = this.dailyBirthday.prospectCount + ' client(s) and ' + this.dailyBirthday.buyerCount + ' customer(s) has birthday today';
    }
    return tooltip;
  }

  changeThemeColor() {
    if (this.dark) {
      this.dark = false;
      this.themeService.changeTheme('corporate');
    } else {
      this.dark = true;
      this.themeService.changeTheme('dark');
    }
  }

  createTask() {
    this.dialogService.open(TaskMiniDialogComponent);
  }
}
