import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {BuyerComponent} from './buyer.component';
import {BuyerListAdvanceComponent} from './buyer-list/buyer-list/buyer-list-advance.component';
import {BuyerListComponent} from './buyer-list/buyer-list.component';
import {BuyerListFilterComponent} from './buyer-list/buyer-list/filter/buyer-list-filter.component';
import {BuyerListCustomCellComponent} from './common/buyer-list-custom-cell-component';
import {BuyerListStatusCellComponent} from './common/buyer-list-status-cell-component';
import {BuyerListAddressCellComponent} from './common/buyer-list-address-cell-component';
import {BuyerListAgentCellComponent} from './common/buyer-list-agent-cell-component';
import {CustomerBoardComponent} from './customer-board/customer-board.component';
import {CustomerBoardFilterAdvanceComponent} from './customer-board/filter/customer-board-filter-advance.component';
import {BuyerListClassificationCellComponent} from './common/buyer-list-classification-cell-component';
import {BuyerListCalledDateCellComponent} from './common/buyer-list-called-date-cell-component';
import {BuyerPickCustomCellComponent} from './common/buyer-pick-custom-cell-component';
import {BuyerSelectComponent} from './common/buyer-select-component';
import {BuyerListCallbackdateComponent} from './buyer-list/buyer-list-callbackdate/buyer-list-callbackdate.component';
import {
  BuyerListFilterCallbackdateComponent,
} from './buyer-list/buyer-list-callbackdate/filter/buyer-list-filter-callbackdate.component';
import {ExportDialogComponent} from './export-dialog/export-dialog.component';
import { OpenHomeComponent } from './open-home/open-home.component';

const routes: Routes = [{
  path: '',
  component: BuyerComponent,
  children: [
    {
      path: 'list',
      component: BuyerListComponent,
    },
    {
      path: 'board',
      component: CustomerBoardComponent,
    },
    {
      path: 'open-home',
      component: OpenHomeComponent,
    },
  ],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BuyerRoutingModule {
}

export const routedComponents = [
  BuyerComponent,
  BuyerListAdvanceComponent,
  BuyerListComponent,
  BuyerListFilterComponent,
  BuyerListCustomCellComponent,
  BuyerPickCustomCellComponent,
  BuyerListStatusCellComponent,
  BuyerListAddressCellComponent,
  BuyerListAgentCellComponent,
  ExportDialogComponent,
  CustomerBoardComponent,
  CustomerBoardFilterAdvanceComponent,
  BuyerListClassificationCellComponent,
  BuyerListCalledDateCellComponent,
  BuyerSelectComponent,
  BuyerListCallbackdateComponent,
  BuyerListFilterCallbackdateComponent,
  OpenHomeComponent,
];
