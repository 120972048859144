import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestResult } from '../models/response/rest-result';
import { HttpClient } from '@angular/common/http';
import { catchError, concatMap, map } from 'rxjs/operators';
import { Path } from '../common/path';
import { CommonService } from './common.service';

@Injectable()
export class PermissionService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  getUsersInGroup(groupId: number): Observable<RestResult> {
    return this.http
      .get<RestResult>(
        Path.API_ENDPOINT +
          Path.ACL +
          Path.GROUP +
          '/' +
          groupId.toString() +
          Path.USER,
      )
      .pipe(
        map((result) => result),
        catchError(this.commonService.handleError),
      );
  }

  searchGroups(request: any): Observable<RestResult> {
    return this.http
      .post<RestResult>(
        Path.API_ENDPOINT + Path.ACL + Path.GROUP + Path.SEARCH,
        request,
      )
      .pipe(
        map((result) => result),
        catchError(this.commonService.handleError),
      );
  }

  searchActions(request: any): Observable<RestResult> {
    return this.http
      .post<RestResult>(
        Path.API_ENDPOINT + Path.ACL + Path.ACTION + Path.SEARCH,
        request,
      )
      .pipe(
        map((result) => result),
        catchError(this.commonService.handleError),
      );
  }

  search(request: any): Observable<RestResult> {
    return this.http
      .post<RestResult>(
        Path.API_ENDPOINT + Path.ACL + Path.PERMISSION + Path.SEARCH,
        request,
      )
      .pipe(
        map((result) => result),
        catchError(this.commonService.handleError),
      );
  }

  putPermission(
    groupId: number,
    permissionId: number,
    checked: boolean,
  ): Observable<RestResult> {
    return this.http
      .put<RestResult>(
        Path.API_ENDPOINT +
          Path.ACL +
          Path.GROUP +
          '/' +
          groupId.toString() +
          Path.PERMISSION +
          '/' +
          permissionId.toString() +
          '?checked=' +
          checked,
        {},
      )
      .pipe(
        map((result) => result),
        catchError(this.commonService.handleError),
      );
  }

  putAction(
    permissionId: number,
    actionId: number,
    checked: boolean,
  ): Observable<RestResult> {
    return this.http
      .put<RestResult>(
        Path.API_ENDPOINT +
          Path.ACL +
          Path.PERMISSION +
          '/' +
          permissionId.toString() +
          Path.ACTION +
          '/' +
          actionId.toString() +
          '?checked=' +
          checked,
        {},
      )
      .pipe(
        map((result) => result),
        catchError(this.commonService.handleError),
      );
  }

  putUserAclGroup(
    userId: number,
    groupId: number,
    checked: boolean,
  ): Observable<RestResult> {
    return this.http
      .put<RestResult>(
        Path.API_ENDPOINT +
          Path.USER +
          '/' +
          userId.toString() +
          Path.ACL +
          Path.GROUP +
          '/' +
          groupId.toString() +
          '?checked=' +
          checked,
        {},
      )
      .pipe(
        map((result) => result),
        catchError(this.commonService.handleError),
      );
  }

  putUserAclPermission(
    userId: number,
    permissionId: number,
    checked: boolean,
  ): Observable<RestResult> {
    return this.http
      .put<RestResult>(
        Path.API_ENDPOINT +
          Path.USER +
          '/' +
          userId.toString() +
          Path.ACL +
          Path.PERMISSION +
          '/' +
          permissionId.toString() +
          '?checked=' +
          checked,
        {},
      )
      .pipe(
        map((result) => result),
        catchError(this.commonService.handleError),
      );
  }

  checkAccess(componentId: string): Observable<RestResult> {
    return this.http
      .get<RestResult>(
        Path.API_ENDPOINT + Path.USER + Path.ACL + '?component=' + componentId,
      )
      .pipe(
        map((result) => result),
        catchError(this.commonService.handleError),
      );
  }

  createGroup(request: any): Observable<RestResult> {
    return this.http
      .post<RestResult>(Path.API_ENDPOINT + Path.ACL + Path.GROUP, request)
      .pipe(
        map((result) => result),
        catchError(this.commonService.handleError),
      );
  }

  updateGroup(request: any): Observable<RestResult> {
    return this.http
      .put<RestResult>(Path.API_ENDPOINT + Path.ACL + Path.GROUP, request)
      .pipe(
        map((result) => result),
        catchError(this.commonService.handleError),
      );
  }

  createPermission(request: any): Observable<RestResult> {
    return this.http
      .post<RestResult>(Path.API_ENDPOINT + Path.ACL + Path.PERMISSION, request)
      .pipe(
        map((result) => result),
        catchError(this.commonService.handleError),
      );
  }

  updatePermission(request: any): Observable<RestResult> {
    return this.http
      .put<RestResult>(Path.API_ENDPOINT + Path.ACL + Path.PERMISSION, request)
      .pipe(
        map((result) => result),
        catchError(this.commonService.handleError),
      );
  }

  deleteGroup(groupId: number): Observable<RestResult> {
    return this.http
      .delete<RestResult>(
        Path.API_ENDPOINT + Path.ACL + Path.GROUP + '/' + groupId.toString(),
      )
      .pipe(
        map((result) => result),
        catchError(this.commonService.handleError),
      );
  }

  deletePermission(permissionId: number): Observable<RestResult> {
    return this.http
      .delete<RestResult>(
        Path.API_ENDPOINT +
          Path.ACL +
          Path.PERMISSION +
          '/' +
          permissionId.toString(),
      )
      .pipe(
        map((result) => result),
        catchError(this.commonService.handleError),
      );
  }
}
