import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { WrapProspect } from './prospect-utils';
import { StringUtils } from '../../../../shared/common/string-utils';
import * as moment from 'moment/moment';

@Component({
  template: `
    {{renderValue}}
  `,
  styles: [],
})
export class VisualBoardOtmDateCellComponent implements ViewCell, OnInit {

  renderValue: string;

  @Input() value: any;
  @Input() rowData: WrapProspect;
  StringUtils = StringUtils;
  dateFormat = 'DD/MM/YYYY';

  ngOnInit() {
    if (this.rowData?.data?.property?.otmDate) {
      this.setValue();
    }
  }

  setValue(): any {
    if (!this.rowData) {
      this.renderValue = this.value ? this.value.toString() : '';
      return;
    }
    const otmDate = this.rowData?.data?.property?.otmDate;
    if (!otmDate) {
      this.renderValue = '';
      return;
    }
    this.renderValue = this.getDayDiff(new Date(otmDate)).toString();
  }

  getDayDiff(value: any) {
    if (!value) {
      return null;
    }
    let time = 0;
    if (value instanceof Date) {
      time = (value as Date).getTime();
    } else {
      time = moment(value, this.dateFormat).valueOf();
    }
    return Math.floor((new Date().getTime() - time) / (1000 * 3600 * 24));
  }
}
