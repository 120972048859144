import { Component, OnInit, Optional } from '@angular/core';
import { ProspectListCommonComponent } from '../common/prospect-list-common.component';
import { ProspectUtils, WrapProspect, WrapProspectType } from '../common/prospect-utils';
import { ProspectListAddressCellComponent } from '../common/prospect-list-address-cell-component';
import { Prospect } from '../../../../shared/models/response/prospect/prospect';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { ToastService } from '../../../../shared/services/toast.service';
import { ProspectService } from '../../../../shared/services/prospect.service';
import { CommonService } from '../../../../shared/services/common.service';
import { RestResult } from '../../../../shared/models/response/rest-result';
import { LocalService } from '../../../../shared/services/local.service';
import { BuyerDateUtils } from '../../buyer/common/buyer-date-utils';
import { DateUtils } from '../../../../shared/common/date-utils';
import { ProspectListCustomCellComponent } from '../common/prospect-list-custom-cell-component';
import { ComponentPermission } from '../../../../component.permission';
import { ProspectSelectComponent } from '../common/prospect-select-component';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { SearchRequest } from '../../../../shared/models/request/search-request';
import { City } from '../../../../shared/models/response/address/city';
import { AuthService } from '../../../../shared/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TemplateService } from '../../../../shared/services/template.service';
import { District } from '../../../../shared/models/response/address/district';
import { Zone } from '../../../../shared/models/response/address/zone';

import { BuyerUtils } from '../../buyer/common/buyer-utils';
import { ProspectListBasicComponent } from '../prospect-list-basic/prospect-list-basic.component';
import { StringUtils } from '../../../../shared/common/string-utils';
@Component({
  selector: 'ngx-prospect-list-callbackdate',
  templateUrl: './prospect-list-callbackdate.component.html',
  styleUrls: ['./prospect-list-callbackdate.component.scss'],
})
export class ProspectListCallbackdateComponent extends ProspectListCommonComponent implements OnInit {
  isDisplay: boolean;
  isDialog = false;
  contactOptionReplacement = (replace: string) => {
    return {
      true: replace,
      false: '',
    };
  }

  columnsDefinition = {
    checkbox: {
      enable: true,
      value: this.createSelectColumn(''),
    },
    address: {
      enable: true,
      value: ProspectUtils.createColumn('',
        null, null, ', ', null, ProspectListAddressCellComponent),
      name: 'Address',
    },
    callbackDate: {
      enable: true,
      value: ProspectUtils.createColumn('Callback Date', ['customer.callbackDate'],
        ProspectListCustomCellComponent.VALUE_TYPES.DATE, null, null),
      name: 'Callback Date',
    },
    name: {
      enable: true,
      value: ProspectUtils.createColumn('Name', ['customer.name', 'customer.surname'], null, null, null),
      name: 'Name',
    },
    motive: {
      enable: true,
      value: ProspectUtils.createColumn('Motive', ['motiveNote'], null, null, null),
      name: 'Motive',
    },
  };

  settings = {
    mode: 'external',
    hideSubHeader: true,
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    pager: {
      display: true,
      perPage: 10000,
    },
    columns: this.createColumns(),
    rowClassFunction: (row) => {
      return ProspectUtils.highlightProspectRow(row?.data);
    },
  };

  createColumns() {
    const columns = {};
    for (const key of Object.keys(this.columnsDefinition)) {
      if (this.columnsDefinition[key].enable === true) {
        columns[key] = this.columnsDefinition[key].value;
      }
    }
    return columns;
  }

  createSelectColumn(title: string) {
    return {
      title: title,
      type: 'custom',
      filter: false,
      renderComponent: ProspectSelectComponent,
      onComponentInitFunction: (instance: ProspectSelectComponent) => {
        instance.onSelectProspect.subscribe((value: WrapProspect) => {
          this.checkProspectToMarkAsCalled(value);
        });
      },
    };
  }
  closeDialog() {
    this.ref.close();
  }

  viewMap = false;
  prospects: Prospect[];
  selectedYear: number;
  selectedMonth: number;
  selectedDay: number;
  filteredZones: Zone[];

  get CheckPermission() {
    return ComponentPermission;
  }

  selectRow(event: any, el: HTMLElement) {
    const wrapProspect: WrapProspect = event.data;
    if (wrapProspect?.type === WrapProspectType.ITEM) {
      this.isDisplay = true;
    }
  }
  selectedProspectIds = [];

  constructor(@Optional() private ref: NbDialogRef<ProspectListBasicComponent>,
              private _dialogService: NbDialogService,
              private _toastService: ToastService,
              private _prospectService: ProspectService,
              private _commonService: CommonService,
              private _localService: LocalService,
              private _authService: AuthService,
              private _templateService: TemplateService,
              private _router: Router,
              private _route: ActivatedRoute) {
    super(_dialogService, _toastService, _prospectService, _commonService, _localService, _authService, _router, _route, _templateService);
  }

  ngOnInit() {
    this.param = { ...this.route.snapshot.queryParams };
    this.currentUser = this.authService.currentUser;
    this.source.onChanged().subscribe((change) => {
      this.change = change;
      if (change.action === 'refresh') {
        this.pageChange(1);
      }
      if (change.action === 'page') {
        this.param = { ...this.param, page: change.paging.page };
        this.navigateWithParam();
        this.pageChange(change.paging.page);
      }
    });
    this.loadForFilter();
    this.preLoadAddressForFilter();
    this.preLoadSelectedEnqTime();
  }

  loadForFilter() {
    const searchRequest = new SearchRequest();
    searchRequest.offset = 0;
    searchRequest.limit = 10000;
    searchRequest.orders = new Array();
    searchRequest.orders.push(
      {
        left: 'name',
        right: 'asc',

      },
    );
    this.localService.cities().subscribe((result: City[]) => {
      this.cities = result;
    });
  }

  pageChange(pageIndex) {
    this.willBeDeletedProspectIds = [];
    this.willBeDeletedGroupIds = [];
    this.currentPage = pageIndex;
    this.searchRequest.mode = this._selectedMode;
    this.searchRequest.offset = (pageIndex - 1) * this.pageSize;
    if (this.searchRequest.offset < 0) {
      this.searchRequest.offset = 0;
    }
    this.searchRequest.limit = this.pageSize;
    this.searchRequest.officeId = this.currentUser.office?.officeId;
    if (!this.isFiltered) {
      this.appendParameterToSearchRequest();
    }
    this.searchRequest.loadOtmExtra = true;
    // this.searchRequest.disableItemCountEachGroup = true;
    this.searchRequest.hasCallbackDate = true;
    this.searchRequest.sortType = 'CALL_BACK_DATE_DESC';

    this.prospectService.searchGroups(this.searchRequest).subscribe((result: RestResult) => {
      this.groupWrapper = result.data;
      this.totalPage = Math.ceil(this.groupWrapper.totalGroups / this.pageSize);
      const prospectId = Number(this.route.snapshot.queryParams['selectedProspectId']);
      if (prospectId) {
        this.prospectService.getOne(prospectId).subscribe(r => {
          this.totalPage = Math.ceil(this.groupWrapper.totalGroups / this.pageSize);
          if (prospectId) {
            this.prospectService.getOne(prospectId).subscribe(p => {
              this.selectedProspect = p.data;
              this.getData(pageIndex, this.pageSize);
            });
          }
        });
      }
    });

    this.getData(pageIndex, this.pageSize);
    // this.prospectService.getGroupItemsSimple(this.searchRequest).subscribe((result: RestResult) => {
    //   // const searchResult: SearchResult<Prospect> = result.data;
    //   // this.totalPage = Math.ceil(searchResult.recordsTotal / this.pageSize);
    //   this.selectedProspects = result.data;
    //   if (this.selectedProspects && this.selectedProspects.length !== 0) {
    //     const wrapProspectItem = ProspectUtils.convertItemToWrapperData(this.selectedProspects[0]);
    //     this.wrapProspects = ProspectUtils.convertGroupItemToWrapperData(result.data);
    //     this.selectRecord({data: wrapProspectItem}, false, false);
    //   }
    //   // const convertResult = ProspectUtils.convertGroupItemToWrapperData(searchResult.data);
    //   // this.buildData(this.pageSize, convertResult, () => {
    //   //   this.restoreState();
    //   // });
    //   // this.onProspectChange(this.prospectChange);
    //   DataSourceUtil.reloadDataProspectListing(this.source, pageIndex, this.pageSize, result);
    // });
  }

  onSearch() {
    this.pageChange(1);
  }

  onYearChange(value: number) {
    if (value) {
      this.isFiltered = true;
      this.selectedYear = value;
      const firstDay = BuyerDateUtils.getFirstDayOfYear(value);
      const lastDay = BuyerDateUtils.getLastDayOfYear(value);
      this.searchRequest.fromCallbackDate = firstDay.valueOf();
      this.searchRequest.toCallbackDate = lastDay.valueOf();
      if (this.searchRequest.fromCallbackDate) {
        this.param = { ...this.param, fromCallbackDate: this.searchRequest.fromCallbackDate };
      } else {
        delete this.param['fromCallbackDate'];
      }
      if (this.searchRequest.toCallbackDate) {
        this.param = { ...this.param, toCallbackDate: this.searchRequest.toCallbackDate };
      } else {
        delete this.param['toCallbackDate'];
      }
      this.param = { ...this.param, selectedYear: this.selectedYear };
      this.navigateWithParam();
      this.pageChange(1);
    } else {
      this.searchRequest.fromCallbackDate = null;
      this.searchRequest.toCallbackDate = null;
    }
    if (this.param['fromCallbackDate']) {
      delete this.param['fromCallbackDate'];
    }
    if (this.param['toCallbackDate']) {
      delete this.param['toCallbackDate'];
    }
    if (this.param['selectedYear']) {
      delete this.param['selectedYear'];
    }
    if (this.param['selectedQuarter']) {
      delete this.param['selectedQuarter'];
    }
    if (this.param['selectedMonth']) {
      delete this.param['selectedMonth'];
    }
    if (this.param['selectedWeek']) {
      delete this.param['selectedWeek'];
    }
  }

  onMonthChange(value: number) {
    if (value && !this.selectedYear) {
      return;
    }
    if (value) {
      this.selectedMonth = value;
      this.isFiltered = true;
      const firstDay = DateUtils.getFirstDayOfMonth(value - 1, this.selectedYear);
      const lastDay = DateUtils.getLastDayOfMonth(value - 1, this.selectedYear);
      this.searchRequest.fromCallbackDate = firstDay.valueOf();
      this.searchRequest.toCallbackDate = lastDay.valueOf();
      if (this.searchRequest.fromCallbackDate) {
        this.param = { ...this.param, fromCallbackDate: this.searchRequest.fromCallbackDate };
      } else {
        delete this.param['fromCallbackDate'];
      }
      if (this.searchRequest.toCallbackDate) {
        this.param = { ...this.param, toCallbackDate: this.searchRequest.toCallbackDate };
      } else {
        delete this.param['toCallbackDate'];
      }
      this.param = { ...this.param, selectedMonth: this.selectedMonth, selectedYear: this.selectedYear };
      this.navigateWithParam();
      if (this.selectedMonth === undefined) {
        delete this.param['selectedMonth'];
      }
      this.pageChange(1);
    } else if (this.selectedYear) {
      this.onYearChange(this.selectedYear);
      return;
    }
  }

  onDayChange(value: number) {
    if (value && (!this.selectedYear || !this.selectedMonth)) {
      return;
    }
    if (value) {
      this.isFiltered = true;
      this.selectedDay = value;
      const firstDay = DateUtils.getStartOfDate(value, this.selectedMonth, this.selectedYear);
      const lastDay = DateUtils.getEndOfDate(value, this.selectedMonth, this.selectedYear);
      this.searchRequest.fromCallbackDate = firstDay.valueOf();
      this.searchRequest.toCallbackDate = lastDay.valueOf();
      if (this.searchRequest.fromCallbackDate) {
        this.param = { ...this.param, fromCallbackDate: this.searchRequest.fromCallbackDate };
      } else {
        delete this.param['fromCallbackDate'];
      }
      if (this.searchRequest.toCallbackDate) {
        this.param = { ...this.param, toCallbackDate: this.searchRequest.toCallbackDate };
      } else {
        delete this.param['toCallbackDate'];
      }
      this.param = { ...this.param, selectedDay: this.selectedDay };
      this.navigateWithParam();
      if (this.selectedDay === undefined) {
        delete this.param['selectedDay'];
      }
      this.pageChange(1);
    } else if (this.selectedMonth) {
      this.onMonthChange(this.selectedMonth);
      return;
    } else if (this.selectedYear) {
      this.onYearChange(this.selectedYear);
      return;
    }
  }

  checkProspectToMarkAsCalled(value: WrapProspect) {
    const prospectId = value.data?.prospectId;
    if (value.selected === true) {
      this.selectedProspectIds.push(prospectId);
    } else {
      const prospectIdIndex = this.selectedProspectIds.findIndex(x => x === prospectId);
      if (prospectIdIndex > -1) {
        this.selectedProspectIds.splice(prospectIdIndex, 1);
      }
    }
  }

  markSelectedAsCalled() {
    if (this.selectedProspectIds.length <= 0) {
      return;
    }
    this.dialogService.open(ConfirmDialogComponent, {
      context: {
        content: 'Are you sure?',
      },
    })
      .onClose.subscribe(res => {
      if (res === ConfirmDialogComponent.confirmOk) {
        this.prospectService.markProspectsAsCalled(this.selectedProspectIds)
          .subscribe(
            result => {
              this.commonService.info('Success');
              this.selectedProspectIds = [];
              this.pageChange(1);
            }, error => {
              this.commonService.warning(error.message);
            });
      }
    });
  }

  appendFilterToParameter() {
    //  Temporary disable append to parameter in overview dashboard
    if (this.searchRequest.cityId) {
      this.param = { ...this.param, cityId: this.searchRequest.cityId };
    } else {
      delete this.param['cityId'];
      delete this.param['districtId'];
      delete this.param['streetId'];
    }
    if (this.searchRequest.districtId) {
      this.param = { ...this.param, districtId: this.searchRequest.districtId };
    } else {
      delete this.param['districtId'];
      delete this.param['streetId'];
    }
    if (this.searchRequest.streetId) {
      this.param = { ...this.param, streetId: this.searchRequest.streetId };
    } else {
      delete this.param['streetId'];
    }
    if (this.selectedYear) {
      this.param = { ...this.param, selectedYear: this.selectedYear };
    } else {
      delete this.param['selectedYear'];
    }
    if (this.selectedMonth) {
      this.param = { ...this.param, selectedMonth: this.selectedMonth };
    } else {
      delete this.param['selectedMonth'];
    }
    if (this.selectedDay) {
      this.param = { ...this.param, selectedDay: this.selectedDay };
    } else {
      delete this.param['selectedDay'];
    }
  }

  appendParameterToSearchRequest() {
    const params = this.route.snapshot.queryParams;
    if (params['postcode']) {
      this.searchRequest.postcode = params['postcode'];
    }
    if (params['districtId']) {
      this.searchRequest.districtId = Number(params['districtId']);
    }
    if (params['streetId']) {
      this.searchRequest.streetId = Number(params['streetId']);
    }
    if (params['fromCallbackDate']) {
      this.searchRequest.fromCallbackDate = Number(params['fromCallbackDate']);
      this.searchRequest.hasCallbackDate = true;
    }
    if (params['toCallbackDate']) {
      this.searchRequest.toCallbackDate = Number(params['toCallbackDate']);
      this.searchRequest.hasCallbackDate = true;
    }
  }

  preLoadAddressForFilter() {
    if (this.route.snapshot.queryParamMap.keys.length === 0) {
      return;
    }
    const postcode = Number(this.route.snapshot.queryParamMap.get('postcode'));
    const districtId = this.route.snapshot.queryParamMap.get('districtId');
    if (postcode) {
      this.localService.districtByPostcode(postcode).subscribe(result => {
        this.filteredDistricts = result;
      });
    }
    if (districtId) {
      this.localService.streets(Number(districtId)).subscribe(result => {
        this.filteredStreets = result;
      });
    }
  }

  preLoadSelectedEnqTime() {
    if (this.route.snapshot.queryParamMap.keys.length === 0) {
      return;
    }
    const selectedYear = this.route.snapshot.queryParamMap.get('selectedYear');
    const selectedMonth = this.route.snapshot.queryParamMap.get('selectedMonth');
    const selectedDay = this.route.snapshot.queryParamMap.get('selectedDay');
    if (selectedYear) {
      this.selectedYear = Number(selectedYear);
    }
    if (selectedMonth) {
      this.selectedMonth = Number(selectedMonth);
    }
    if (selectedDay) {
      this.selectedDay = Number(selectedDay);
    }
  }

  onCityChange(cityId) {
    super.onCityChange(cityId);
    this.isFiltered = true;
    if (this.searchRequest.cityId) {
      this.param = { ...this.param, cityId: this.searchRequest.cityId };
    } else {
      delete this.param['cityId'];
      delete this.param['districtId'];
      delete this.param['streetId'];
    }
    this.navigateWithParam();
    if (this.searchRequest.cityId === undefined) {
      delete this.param['cityId'];
    }
    this.pageChange(1);
  }

  onSuburbChange(districtId) {
    super.onSuburbChange(districtId);
    this.isFiltered = true;
    if (this.searchRequest.districtId) {
      this.param = { ...this.param, districtId: this.searchRequest.districtId };
    } else {
      delete this.param['districtId'];
      delete this.param['streetId'];
    }
    this.navigateWithParam();
    if (this.searchRequest.districtId === undefined) {
      delete this.param['districtId'];
    }
    this.pageChange(1);
  }

  onStreetChange(streetId) {
    super.onStreetChange(streetId);
    this.isFiltered = true;
    if (this.searchRequest.streetId) {
      this.param = { ...this.param, streetId: this.searchRequest.streetId };
    } else {
      delete this.param['streetId'];
    }
    this.navigateWithParam();
    if (this.searchRequest.streetId === undefined) {
      delete this.param['streetId'];
    }
    this.pageChange(1);
  }

  onSearchByPostcode(value: number) {
    super.onSearchByPostcode(value);
    if (this.searchRequest.postcode) {
      this.param = { ...this.param, postcode: this.searchRequest.postcode };
    } else {
      delete this.param['postcode'];
    }
    this.navigateWithParam();
    if (this.searchRequest.postcode === null) {
      delete this.param['postcode'];
    }
    this.pageChange(1);
  }
}

