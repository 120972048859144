import {NbDialogRef, NbDialogService} from '@nebular/theme';
import {ToastService} from '../../../../shared/services/toast.service';
import {RestResult} from '../../../../shared/models/response/rest-result';
import {Prospect} from '../../../../shared/models/response/prospect/prospect';
import {ProspectService} from '../../../../shared/services/prospect.service';
import {CommonService} from '../../../../shared/services/common.service';
import {ProspectDatasource} from '../common/prospect-datasource';
import {ProspectUtils, WrapProspect, WrapProspectType} from '../common/prospect-utils';
import {ProspectSearchRequest} from '../../../../shared/models/request/prospect/prospect-search-request';
import {ProspectGroupWrapper} from '../../../../shared/models/response/prospect/prospect-group-wrapper';
import {EventEmitter, Input, Output} from '@angular/core';
import {ConfirmDialogComponent} from '../../confirm-dialog/confirm-dialog.component';
import {District} from '../../../../shared/models/response/address/district';
import {City} from '../../../../shared/models/response/address/city';
import {Company} from '../../../../shared/models/response/prospect/company';
import {Street} from '../../../../shared/models/response/address/street';
import {ProspectDialogComponent} from '../../../../shared/module/prospect-dialog/prospect-dialog.component';
import {ProspectCreateDialogComponent} from '../prospect-create-dialog/prospect-create-dialog.component';
import {TaskType} from '../../../../shared/models/response/task-type';
import {TaskStatus} from '../../../../shared/models/response/task-status';
import {ProspectShortcutComponent} from '../prospect-shortcut/prospect-shortcut.component';
import {LocalService} from '../../../../shared/services/local.service';
import {Constant} from '../../../../shared/common/constant';
import {User} from '../../../../shared/models/response/user';
import {AuthService} from '../../../../shared/services/auth.service';
import {ComponentPermission} from '../../../../component.permission';
import {DataSourceUtil} from '../../../../shared/models/utils/datasource-util';
import {ActivatedRoute, Router} from '@angular/router';
import {SearchRequest} from '../../../../shared/models/request/search-request';
import {ExportDialogComponent} from '../../buyer/export-dialog/export-dialog.component';
import {TemplateService} from '../../../../shared/services/template.service';
import {StatusUtils} from '../../buyer/common/status-utils';

export class ProspectListCommonComponent {
  @Input() districts: District[];
  @Input() cities: City[];
  @Input() companies: Company[];
  @Input() taskTypes: TaskType[];
  @Input() taskStatuses: TaskStatus[];

  @Input() set streets(value: Street[]) {
    this._streets = value;
    if (this.searchRequest.districtId) {
      this.filteredStreets = this._streets.filter(x => x?.district?.districtId === this.searchRequest.districtId);
    }
  }

  @Input() set selectedMode(value: string) {
    this._selectedMode = value;
  }

  @Input() oneOnOneCall = false;
  currentWrapProspect: WrapProspect;
  wrapProspects: WrapProspect[];

  // @Output() prospectChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() reloadStreet: EventEmitter<any> = new EventEmitter<any>();
  //  for client board filter
  @Output() onSelectStreet = new EventEmitter();
  @Output() onSelectProspect = new EventEmitter();

  // for client list
  @Output() onSelectProspectIds = new EventEmitter();
  @Input() selectProspectIds = [];
  viewMode = {
    INDOOR: 'INDOOR',
    OUTDOOR: 'OUTDOOR',
    ALL: 'ALL',
  };

  columnGroup = {
    PROPERTY: 'Property',
    CUSTOMER: 'Customer',
    CONTACT: 'Contact configuration',
    ADDRESS: 'Address',
    OTHERS: 'Others',
  };

  sortTypesText = {
    ASC: 'A+',
    DESC: 'A-',
    ODD_ASC: 'O+',
    ODD_DESC: 'O-',
    EVEN_ASC: 'E+',
    EVEN_DESC: 'E-',
  };

  sortTypes = {
    ASC: 'ASC',
    DESC: 'DESC',
    ODD_ASC: 'ODD_ASC',
    ODD_DESC: 'ODD_DESC',
    EVEN_ASC: 'EVEN_ASC',
    EVEN_DESC: 'EVEN_DESC',
  };

  otmSortTypesText = {
    OTM_DATE_DESC: 'OTM Date (Newest - Oldest)',
    OTM_DATE_ASC: 'OTM Date (Oldest - Newest)',
    VAC_DATE_DESC: 'VAC Date (Newest - Oldest)',
    VAC_DATE_ASC: 'VAC Date (Oldest - Newest)',
    SELL_PRICE_ASC: 'Price (Lowest - Highest)',
    SELL_PRICE_DESC: 'Price (Highest - Lowest)',
  };

  propertyTypeReplacement = {
    LAND_ONLY: 'L',
    UNIT: 'U',
    VILLA: 'V',
    PENTHOUSE: 'P',
    TOWN_HOUSE: 'T',
    HOUSE: 'H',
    HOUSE_GF: 'HG',
    DUPLEX: 'D',
    HOUSE_COM: 'HC',
  };
  otmStatuses = {
    EXPIRED: 'EXPIRED',
    ACTIVE: 'ACTIVE',
  };
  pageSize = Constant.PAGE_SIZE;
  currentPage = 1;
  searchRequest = new ProspectSearchRequest();
  change: any;
  source: ProspectDatasource;
  totalPage = 0;
  willBeDeletedProspectIds = [];
  willBeDeletedGroupIds = [];
  selectedProspect: Prospect;
  selectedProspects: Prospect[];
  groupWrapper: ProspectGroupWrapper;
  dialogFormRef: NbDialogRef<ProspectDialogComponent>;
  filteredDistricts: District[] = [];
  _streets: Street[];
  filteredStreets: Street[] = [];
  dialogService: NbDialogService;
  toastService: ToastService;
  prospectService: ProspectService;
  commonService: CommonService;
  localService: LocalService;
  authService: AuthService;
  templateService: TemplateService;
  router: Router;
  route: ActivatedRoute;
  _selectedMode: string;
  currentUser: User;
  log: string = null;
  reducedMode = false;
  ComponentPermission = ComponentPermission;
  param = {};
  isFiltered = false;
  noFilter = false;
  adminNumber: string;
  isDisplay: boolean;

  constructor(dialogService: NbDialogService,
              toastService: ToastService,
              prospectService: ProspectService,
              commonService: CommonService,
              localService: LocalService,
              authService: AuthService,
              router: Router,
              route: ActivatedRoute,
              templateService: TemplateService) {
    this.dialogService = dialogService;
    this.toastService = toastService;
    this.prospectService = prospectService;
    this.templateService = templateService;
    this.commonService = commonService;
    this.localService = localService;
    this.authService = authService;
    this.searchRequest.sortType = this.sortTypes.ASC;
    this.source = new ProspectDatasource();
    this.router = router;
    this.route = route;
  }

  pageChange(pageIndex, isPaging?: boolean) {
    if (this.route.snapshot.queryParams['page'] && !isPaging) {
      pageIndex = Number(this.route.snapshot.queryParams['page']);
    }
    this.clearSelected();
    this.currentPage = pageIndex;
    this.searchRequest.mode = this._selectedMode;
    this.searchRequest.offset = (pageIndex - 1) * this.pageSize;
    if (this.searchRequest.offset < 0) {
      this.searchRequest.offset = 0;
    }
    this.searchRequest.limit = this.pageSize;
    this.searchRequest.officeId = this.currentUser.office?.officeId;
    if (this.isFiltered) {
      // this.param = {};
      if (this._selectedMode === 'ALL') {
        this.appendAdditionalFilterToParameter();
      }
      this.appendFilterToParameter();
      this.navigateWithParam();
    }
    if (!this.isFiltered) {
      this.appendParameterToSearchRequest();
    }

    this.getData(pageIndex, this.pageSize);
  }

  getData(pageIndex: number, pageSize: number) {
    if (this.reducedMode === true) {
      this.prospectService.getGroupItemsSimple(this.searchRequest).subscribe(result => {
        this.selectedProspects = result.data;
        if (!ComponentPermission.isAccess(ComponentPermission.RENTAL_LEVEL4_DETAILS)) {
          if (this.searchRequest.rate === 4) {
            this.commonService.warning('Part of result maybe missing because you do not have permission to view R4 Landlords');
          }
        }
        DataSourceUtil.reloadDataProspectListing(this.source, pageIndex, pageSize, result);
        // @ts-ignore
        this.wrapProspects = this.source.data;
        this.restoreState();
      });
    } else {
      this.prospectService.searchGroups(this.searchRequest).subscribe((result: RestResult) => {
        this.groupWrapper = result.data;
        if (!ComponentPermission.isAccess(ComponentPermission.RENTAL_LEVEL4_DETAILS)) {
          if (this.searchRequest.rate === 4) {
            this.commonService.warning('Part of result maybe missing because you do not have permission to view R4 Landlords');
          }
        }
        this.totalPage = Math.ceil(this.groupWrapper.totalGroups / pageSize);
        const prospectId = Number(this.route.snapshot.queryParams['selectedProspectId']);
        const streetId = Number(this.route.snapshot.queryParams['selectedStreetId']);
        if (prospectId) {
          this.prospectService.getOne(prospectId).subscribe(p => {
            this.selectedProspect = p.data;
            this.selectedProspects = [];
            this.selectedProspects.push(this.selectedProspect);
            const convertResult = ProspectUtils.convertGroupToWrapperData(this.groupWrapper.groups);
            this.buildData(pageSize, convertResult, () => {
              this.restoreState();
            });
            this.source.getAll().then(d => {
              const wrapProspects: WrapProspect[] = d;
              this.currentWrapProspect = wrapProspects.find(wp => (wp.groupData.streetId === streetId));
            });
            if (this._selectedMode === 'ALL') {
              this.editRecord(this.selectedProspect);
            }
          });
        } else {
          const convertResult = ProspectUtils.convertGroupToWrapperData(this.groupWrapper.groups);
          this.buildData(pageSize, convertResult, () => {
            this.restoreState();
          });
          if (streetId) {
            const expand = convertResult.find(r => r.groupData.streetId === streetId);
            this.expandGroup(expand);
          }
        }
        // this.onProspectChange(this.prospectChange);
      });
    }
  }

  addRecord() {
    this.dialogService.open(ProspectCreateDialogComponent, {
      context: {
        streetId: this.searchRequest.streetId,
        districtId: this.searchRequest.districtId,
        postcode: this.searchRequest.postcode,
        filteredDistricts: this.filteredDistricts,
        filteredStreets: this.filteredStreets
      },
    }).onClose.subscribe(res => {
      this.addDone(res);
    });
  }

  addProspectsByUnitRange(wrapProspect: WrapProspect) {
    if (!wrapProspect) {
      return;
    }
    this.dialogService.open(ProspectCreateDialogComponent, {
      context: {
        prospectGroup: wrapProspect?.data,
      },
    }).onClose.subscribe(res => {
      this.addDone(res);
    });
  }

  openShortcut() {
    this.dialogService.open(ProspectShortcutComponent, {
      context: {
        streetId: this.searchRequest.streetId,
        districtId: this.searchRequest.districtId,
        postcode: this.searchRequest.postcode,
        filteredDistricts: this.filteredDistricts,
        filteredStreets: this.filteredStreets
      },
    }).onClose.subscribe(res => {
      this.addDone(res);
    });
  }

  closeDialogForm(result: any) {
    if (this.dialogFormRef) {
      this.dialogFormRef.close(result);
    }
  }

  checkProspectToRemove(value: WrapProspect) {
    if (value.type === WrapProspectType.GROUP) {
      const streetId = value.groupData?.streetId;
      if (value.selected === true) {
        this.willBeDeletedGroupIds.push(streetId);
      } else {
        const streetIdIndex = this.willBeDeletedGroupIds.findIndex(x => x === streetId);
        if (streetIdIndex > -1) {
          this.willBeDeletedGroupIds.splice(streetIdIndex, 1);
        }
      }
    } else if (value.type === WrapProspectType.ITEM) {
      const prospectId = value.data?.prospectId;
      if (value.selected === true) {
        this.willBeDeletedProspectIds.push(prospectId);
      } else {
        const prospectIdIndex = this.willBeDeletedProspectIds.findIndex(x => x === prospectId);
        if (prospectIdIndex > -1) {
          this.willBeDeletedProspectIds.splice(prospectIdIndex, 1);
        }
      }
    }
  }

  removeSelected() {
    if (this.willBeDeletedProspectIds.length <= 0
      && this.willBeDeletedGroupIds.length <= 0) {
      return;
    }
    this.dialogService.open(ConfirmDialogComponent, {
      context: {
        content: 'Do you really want to delete this prospects?',
      },
    })
      .onClose.subscribe(res => {
      if (res === ConfirmDialogComponent.confirmOk) {
        this.prospectService.removeList(this.willBeDeletedProspectIds, this.willBeDeletedGroupIds)
          .subscribe(
            result => {
              this.commonService.info('Remove prospect list successfully');
              this.pageChange(1);
            }, error => {
              this.commonService.warning(error.message);
            });
      }
    });
  }

  /**
   * refresh list after added new
   */
  addDone(result: any) {
    if (StatusUtils.isSuccessResponse(result)) {
      this.pageChange(this.currentPage);
    }
    if (result && result['reloadStreet'] === true) {
      this.reloadStreet.emit();
    }
  }

  /**
   * no refresh, only apply to current
   */
  editDone(value: { result: any, next: boolean }) {
    if (StatusUtils.isSuccessResponse(value?.result)) {
      const prospect = value.result.data;
      // this.pageChange(this.currentPage);
      const wrapProspect = ProspectUtils.convertItemToWrapperData(prospect);
      const itemIndex = this.wrapProspects.findIndex(x => x.data?.prospectId === prospect.prospectId);
      if (itemIndex !== -1) {
        this.wrapProspects[itemIndex] = wrapProspect;
      }
      const prospectIndex = this.selectedProspects.findIndex(x => x.prospectId === prospect.prospectId);
      if (prospectIndex !== -1) {
        this.selectedProspects[prospectIndex] = prospect;
      }
      if (value.next) {
        this.navigateProspect(value.next);
      } else {
        this.source.load(this.wrapProspects);
      }
    }
    if (value?.result && value?.result['reloadStreet'] === true) {
      this.reloadStreet.emit();
    }
  }

  navigateProspect(forward: boolean) {
    let index = this.selectedProspects.findIndex(x => x.prospectId === this.selectedProspect.prospectId);
    index = forward === true ? index + 1 : index - 1;
    let newIndex = this.formatIndex(index);
    const jump = newIndex !== index;
    // skip if called
    if (ProspectUtils.isCalled(this.selectedProspects[index])) {
      newIndex = forward === true ? newIndex + 1 : newIndex - 1;
      newIndex = this.formatIndex(newIndex);
    }

    if (jump) {
      if (this.wrapProspects) {
        this.navigateGroup(forward);
      }
    } else {
      const newId = this.selectedProspects[newIndex].prospectId;
      this.selectedProspect = this.selectedProspects?.find(x => x.prospectId === newId);
      this.highlightRow(newId);
      if (this.param['selectedProspectId']) {
        this.param['selectedProspectId'] = newId;
      }
    }
    this.navigateWithParam();
  }

  formatIndex(index) {
    if (index >= this.selectedProspects.length) {
      index = this.selectedProspects.length - 1;
    } else if (index <= 0) {
      index = 0;
    }
    return index;
  }

  navigateGroup(forward: boolean) {
    const wrapProspects = this.wrapProspects.filter(wrapProspect => wrapProspect.type === 'GROUP');
    let currentIndex = wrapProspects.findIndex(
      wrapProspect =>
        wrapProspect.groupData &&
        wrapProspect.groupData.streetId === this.currentWrapProspect.groupData.streetId,
    );
    if (forward) {
      currentIndex++;
    } else {
      currentIndex--;
    }
    const next = wrapProspects[currentIndex];
    if (next) {
      if (!next.isExpanded) {
        next.type = WrapProspectType.GROUP;
        const event = {
          data: next,
        };
        this.selectRecord(event, false, true, true, forward);
      } else {
        this.currentWrapProspect = next;
        this.jumpSource(next, forward);
      }
    }
  }

  selectRecord(event: any, openEditDialog: boolean, addToSelected: boolean, jump?: boolean, forward?: boolean) {
    if (this.reducedMode === true) {
      this.selectedProspect = event.data.data;
      if (this.selectedProspect) {
        this.param = {...this.param, selectedProspectId: this.selectedProspect.prospectId};
      } else {
        delete this.param['selectedProspect'];
      }
      this.navigateWithParam();
      this.highlightRow(this.selectedProspect?.prospectId);
    } else {
      const wrapProspect: WrapProspect = event.data;
      let streetId = null;
      if (wrapProspect?.type === WrapProspectType.ITEM) {
        this.selectedProspect = wrapProspect?.data;
        this.highlightRow(this.selectedProspect?.prospectId);
        streetId = this.selectedProspect?.address?.street?.streetId;
        this.param = {...this.param, selectedProspectId: this.selectedProspect.prospectId};
        if (this.noFilter === false) {
          this.navigateWithParam();
        }
        if (openEditDialog) {
          this.editRecord(this.selectedProspect);
        }
        // this.onSelectProspect.emit(this.selectedProspect.prospectId);
      } else if (wrapProspect?.type === WrapProspectType.GROUP) {
        this.currentWrapProspect = wrapProspect;
        if (this.param['selectedStreetId'] && this.currentWrapProspect.groupData.streetId !== this.param['selectedStreetId']) {
          delete this.param['selectedProspectId'];
        }
        this.param = {...this.param, selectedStreetId: this.currentWrapProspect.groupData.streetId};
        if (this.noFilter === false) {
          this.navigateWithParam();
        }
        this.expandGroup(wrapProspect, () => {
          if (jump) {
            this.jumpSource(wrapProspect, forward);
          }
        });
        streetId = wrapProspect?.groupData?.streetId;
        // this.onSelectStreet.emit(this.currentWrapProspect.groupData.streetId);
      }

      // set selected prospects
      if (streetId && addToSelected && !jump) {
        this.source.getAll().then(d => {
          const wrapProspects: WrapProspect[] = d;
          this.selectedProspects = wrapProspects.filter(x => {
            return x.type === WrapProspectType.ITEM && x?.data?.address?.street?.streetId === streetId;
          })?.map(x => x.data);
        });
      }
    }
  }

  jumpSource(wrapProspect: WrapProspect, forward?: boolean) {
    this.source.getAll().then(d1 => {
      const newWrapProspects: WrapProspect[] = d1;
      if (newWrapProspects) {
        let currentIndex = newWrapProspects.findIndex(
          temp =>
            temp.groupData &&
            temp.groupData.streetId === wrapProspect.groupData.streetId,
        );
        currentIndex++;
        let prospect = null;
        while (
          newWrapProspects.length >= currentIndex &&
          newWrapProspects[currentIndex].type === WrapProspectType.ITEM) {
          if (newWrapProspects[currentIndex].type === WrapProspectType.ITEM) {
            prospect = newWrapProspects[currentIndex];
          }
          if (!forward) {
            currentIndex++;
          } else {
            break;
          }
        }

        if (prospect) {
          this.selectedProspect = prospect.data;
          this.highlightRow(this.selectedProspect.prospectId);
          this.param['selectedProspectId'] = this.selectedProspect.prospectId;
          this.navigateWithParam();
          this.selectedProspects = newWrapProspects.filter(x => {
            return x.type === WrapProspectType.ITEM &&
              x?.data?.address?.street?.streetId === wrapProspect.groupData.streetId;
          })?.map(x => x.data);
        }
      }
    });
  }

  editRecord(prospect: Prospect) {
    this.dialogFormRef = this.dialogService.open(ProspectDialogComponent, {
      context: {
        selectedProspect: prospect,
        taskTypes: this.taskTypes,
        taskStatuses: this.taskStatuses,
        companies: this.companies,
        isModal: true,
        onClose: (result) => {
          this.closeDialogForm(result);
        },
      },
      dialogClass: 'modal-pop-up',
    });
    this.dialogFormRef.onClose.subscribe(res => {
      this.editDone({result: res, next: false});
      delete this.param['selectedProspectId'];
      this.navigateWithParam();
    });
  }

  saveBulk(log?: string, prospectIds?: number[]) {
    if (log) {
      this.log = log;
    }
    if (this.log === null) {
      this.commonService.warning('Log must not be empty');
    } else {
      if (this.searchRequest.rate >= 4) {
        this.commonService.warning('You can not send bulk to Client with rate of 4 or higher');
        return;
      }
      if (prospectIds) {
        this.searchRequest.prospectIds = prospectIds;
      }
      this.searchRequest.log = this.log;
      this.prospectService.bulkSaveProspectLog(this.searchRequest).subscribe(
        result => {
          this.searchRequest.log = null;
          this.searchRequest.prospectIds = null;
          this.commonService.info('Bulk add log success');
          this.pageChange(1);
        },
        error => {
          this.commonService.warning(error.message);
        },
      );
    }
  }

  protected expandGroup(group: WrapProspect, callback?: any) {
    group.isExpanded = !group.isExpanded;
    // load current source
    this.source.getAll()
      .then(d => {
        let wrapProspects: WrapProspect[] = d;
        const groupIndex = wrapProspects.findIndex(x => x.groupData?.streetId === group.groupData.streetId);
        const firstPart: WrapProspect[] = wrapProspects.slice(0, groupIndex + 1);
        // if is expanding
        if (group.isExpanded) {
          const cloneSR = {...this.searchRequest};
          cloneSR.streetId = group.groupData.streetId;
          // load all sub prospects
          this.prospectService.getGroupItemsSimple(cloneSR)
            .subscribe((result: RestResult) => {
              const temp = ProspectUtils.convertGroupItemToWrapperData(result.data);
              const itemWrappers = [];
              temp.forEach(thing => {
                const itemIndex = wrapProspects.findIndex(x => x.data?.prospectId === thing.data.prospectId);
                if (itemIndex === -1) {
                  itemWrappers.push(thing);
                }
              });
              const secondPart: WrapProspect[] = wrapProspects.slice(groupIndex + 1);
              wrapProspects = [...firstPart, ...itemWrappers, ...secondPart];
              const realData = wrapProspects.filter(x => x.type);
              // rebuild to display data
              this.buildData(realData.length, realData, () => {
                if (callback) {
                  callback();
                }
              });
            });
        } else {
          // if is not expanding, remove inside
          const secondPart: WrapProspect[] = wrapProspects.slice(groupIndex + 1 + group.groupData.totalItems);
          wrapProspects = [...firstPart, ...secondPart];
          const realData = wrapProspects.filter(x => x.type);
          this.buildData(realData.length, realData, () => {
            if (callback) {
              callback();
            }
          });
        }
      });
  }

  /**
   * calculate and display data in list
   */
  protected buildData(pageSize: number, realData: WrapProspect[], callback?: any) {
    this.source.total = this.totalPage * pageSize;
    this.source.setPaging(this.currentPage, pageSize, false);
    const data = [];
    for (let i = 0; i <= (this.currentPage - 1) * pageSize - 1; i++) {
      data.push({});
    }
    realData.forEach(x => {
      // restore checkbox status
      if (x.type === WrapProspectType.GROUP && this.willBeDeletedGroupIds.indexOf(x?.groupData?.streetId) > -1) {
        x.selected = true;
      } else if (x.type === WrapProspectType.ITEM && this.willBeDeletedProspectIds.indexOf(x?.data?.prospectId) > -1) {
        x.selected = true;
      }
      data.push(x);
    });
    this.wrapProspects = data;
    this.source.load(data).then(() => {
      if (callback) {
        callback();
      }
    });
  }

  protected highlightRow(prospectId: number) {
    // this.source.getAll().then(d => {
    // });
    const wrapProspects: WrapProspect[] = this.wrapProspects;
    for (const wrapProspect of wrapProspects) {
      wrapProspect.isSelected = wrapProspect.data?.prospectId === prospectId;
    }
    this.source.load(wrapProspects);
  }

  protected restoreState() {
    if (!this.selectedProspect) {
      return;
    }
    const selectProspectId = this.selectedProspect?.prospectId;
    this.source.getAll().then(d => {
      const wrapProspects: WrapProspect[] = d;
      const group = wrapProspects.find(x =>
        x?.groupData?.streetId === this.selectedProspect?.address?.street?.streetId);
      if (group) {
        this.expandGroup(group, () => {
          this.source.getAll().then(d1 => {
            const newWrapProspects: WrapProspect[] = d1;
            this.selectedProspect = newWrapProspects.find(x => x?.data?.prospectId === selectProspectId)?.data;
            this.highlightRow(selectProspectId);
          });
        });
      } else {
        this.selectedProspect = wrapProspects.find(x => x?.data?.prospectId === selectProspectId)?.data;
      }
    });
  }

  appendFilterToParameter() {
    //  Temporary disable append to parameter in overview dashboard
    if (this.oneOnOneCall) {
      return;
    }
    if (this.searchRequest.cityId) {
      this.param = {...this.param, cityId: this.searchRequest.cityId};
    } else {
      delete this.param['cityId'];
      delete this.param['districtId'];
    }
    if (this.searchRequest.districtId) {
      this.param = {...this.param, districtId: this.searchRequest.districtId};
    } else {
      delete this.param['districtId'];
      delete this.param['streetId'];
    }
    if (this.searchRequest.streetId) {
      this.param = {...this.param, streetId: this.searchRequest.streetId};
    } else {
      delete this.param['streetId'];
    }
    if (this.searchRequest.sortType) {
      this.param = {...this.param, sortType: this.searchRequest.sortType};
    } else {
      delete this.param['sortType'];
    }
    if (this.searchRequest.rate) {
      this.param = {...this.param, rate: this.searchRequest.rate};
    } else {
      delete this.param['rate'];
    }
    if (this.searchRequest.sell) {
      this.param = {...this.param, sell: this.searchRequest.sell};
    } else {
      delete this.param['sell'];
    }
    if (this.searchRequest.otm) {
      this.param = {...this.param, otm: this.searchRequest.otm};
    } else {
      delete this.param['otm'];
    }
    if (this.searchRequest.rent) {
      this.param = {...this.param, rent: this.searchRequest.rent};
    } else {
      delete this.param['rent'];
    }
    if (this.searchRequest.vac) {
      this.param = {...this.param, vac: this.searchRequest.vac};
    } else {
      delete this.param['vac'];
    }
    if (this.searchRequest.categoryIds) {
      this.param['categoryIds'] = this.searchRequest.categoryIds;
    } else {
      delete this.param['categoryIds'];
    }
    if (this.searchRequest.userId) {
      this.param = {...this.param, userId: this.searchRequest.userId};
    } else {
      delete this.param['userId'];
    }
    if (this.searchRequest.type) {
      this.param = {...this.param, type: this.searchRequest.type};
    } else {
      delete this.param['type'];
    }
    if (this.searchRequest.bed) {
      this.param = {...this.param, bed: this.searchRequest.bed};
    } else {
      delete this.param['bed'];
    }
    if (this.searchRequest.bath) {
      this.param = {...this.param, bath: this.searchRequest.bath};
    } else {
      delete this.param['bath'];
    }
    if (this.searchRequest.car) {
      this.param = {...this.param, car: this.searchRequest.car};
    } else {
      delete this.param['car'];
    }
    if (this.searchRequest.otmStatus) {
      this.param = {...this.param, otmStatus: this.searchRequest.otmStatus};
    } else {
      delete this.param['otmStatus'];
    }
    if (this.searchRequest.vacStatus) {
      this.param = {...this.param, vacStatus: this.searchRequest.vacStatus};
    } else {
      delete this.param['vacStatus'];
    }
    if (this.searchRequest.fromSellPrice) {
      this.param = {...this.param, fromSellPrice: this.searchRequest.fromSellPrice};
    } else {
      delete this.param['fromSellPrice'];
    }
    if (this.searchRequest.toSellPrice) {
      this.param = {...this.param, toSellPrice: this.searchRequest.toSellPrice};
    } else {
      delete this.param['toSellPrice'];
    }
    if (this.searchRequest.fromRentPrice) {
      this.param = {...this.param, fromRentPrice: this.searchRequest.fromRentPrice};
    } else {
      delete this.param['fromRentPrice'];
    }
    if (this.searchRequest.toRentPrice) {
      this.param = {...this.param, toRentPrice: this.searchRequest.toRentPrice};
    } else {
      delete this.param['toRentPrice'];
    }
    if (this.searchRequest.sellCompanyId) {
      this.param = {...this.param, sellCompanyId: this.searchRequest.sellCompanyId};
    } else {
      delete this.param['sellCompanyId'];
    }
    if (this.searchRequest.rentCompanyId) {
      this.param = {...this.param, rentCompanyId: this.searchRequest.rentCompanyId};
    } else {
      delete this.param['rentCompanyId'];
    }
    if (this.searchRequest.postcode) {
      this.param = {...this.param, postcode: this.searchRequest.postcode};
    } else {
      delete this.param['postcode'];
    }
  }

  appendParameterToSearchRequest() {
    const params = this.route.snapshot.queryParams;
    if (params['cityId']) {
      this.searchRequest.cityId = Number(params['cityId']);
    }
    if (params['districtId']) {
      this.searchRequest.districtId = Number(params['districtId']);
    }
    if (params['streetId']) {
      this.searchRequest.streetId = Number(params['streetId']);
    }
    if (params['rate']) {
      this.searchRequest.rate = Number(params['rate']);
    }
    if (params['sortType']) {
      this.searchRequest.sortType = params['sortType'];
    }
    if (params['sell']) {
      this.searchRequest.sell = params['sell'];
    }
    if (params['otm']) {
      this.searchRequest.otm = params['otm'];
    }
    if (params['rent']) {
      this.searchRequest.rent = params['rent'];
    }
    if (params['vac']) {
      this.searchRequest.vac = params['vac'];
    }
    if (params['categoryIds'] && this.searchRequest.categoryIds.length === 0) {
      const categoryIds = params['categoryIds'];
      if (typeof categoryIds === 'string') {
        this.searchRequest.categoryIds = [];
        this.searchRequest.categoryIds.push(Number(categoryIds));
      } else {
        this.searchRequest.categoryIds = categoryIds.map(id => {
          return Number(id);
        });
      }
    }
    if (params['userId']) {
      this.searchRequest.userId = Number(params['userId']);
    }
    if (params['name']) {
      this.searchRequest.name = params['name'];
    }
    if (params['surname']) {
      this.searchRequest.surname = params['surname'];
    }
    if (params['email']) {
      this.searchRequest.email = params['email'];
    }
    if (params['phone']) {
      this.searchRequest.phone = params['phone'];
    }
    if (params['type']) {
      this.searchRequest.type = params['type'];
    }
    if (params['bed']) {
      this.searchRequest.bed = params['bed'];
    }
    if (params['bath']) {
      this.searchRequest.bath = params['bath'];
    }
    if (params['car']) {
      this.searchRequest.car = params['car'];
    }
    if (params['vac']) {
      this.searchRequest.vac = params['vac'];
    }
    if (params['sellCompanyId']) {
      this.searchRequest.sellCompanyId = Number(params['sellCompanyId']);
    }
    if (params['fromSellPrice']) {
      this.searchRequest.fromSellPrice = params['fromSellPrice'];
    }
    if (params['toSellPrice']) {
      this.searchRequest.toSellPrice = params['toSellPrice'];
    }
    if (params['rentCompanyId']) {
      this.searchRequest.rentCompanyId = Number(params['rentCompanyId']);
    }
    if (params['fromRentPrice']) {
      this.searchRequest.fromRentPrice = params['fromRentPrice'];
    }
    if (params['toRentPrice']) {
      this.searchRequest.toRentPrice = params['toRentPrice'];
    }
    if (params['otmStatus']) {
      this.searchRequest.otmStatus = params['otmStatus'];
    }
    if (params['vacStatus']) {
      this.searchRequest.vacStatus = params['vacStatus'];
    }
    if (params['purchaseDateMonth']) {
      this.searchRequest.purchaseDateMonth = Number(params['purchaseDateMonth']);
    }
    if (params['purchaseDateDay']) {
      this.searchRequest.purchaseDateDay = Number(params['purchaseDateDay']);
    }
    if (params['dobDay']) {
      this.searchRequest.dobDay = Number(params['dobDay']);
    }
    if (params['dobMonth']) {
      this.searchRequest.dobMonth = Number(params['dobMonth']);
    }
    if (params['age']) {
      this.searchRequest.age = Number(params['age']);
    }
    if (params['postcode']) {
      this.searchRequest.postcode = params['postcode'];
    }
  }

  //  push param to current url
  navigateWithParam() {
    this.router.navigate([], {
      queryParams: this.param,
    });
  }

  appendAdditionalFilterToParameter() {
    if (this.searchRequest.name) {
      this.param = {...this.param, name: this.searchRequest.name};
    } else {
      delete this.param['name'];
    }
    if (this.searchRequest.surname) {
      this.param = {...this.param, surname: this.searchRequest.surname};
    } else {
      delete this.param['surname'];
    }
    if (this.searchRequest.email) {
      this.param = {...this.param, email: this.searchRequest.email};
    } else {
      delete this.param['email'];
    }
    if (this.searchRequest.phone) {
      this.param = {...this.param, phone: this.searchRequest.phone};
    } else {
      delete this.param['phone'];
    }
  }

  // protected onProspectChange(prospectChange: EventEmitter<any>) {
  //   const request = { ...this.searchRequest };
  //   this.source.getAll().then(d => {
  //     const wrapProspects: WrapProspect[] = d;
  //     request.streetIds = wrapProspects.filter(x => x.groupData).map(x => x.groupData.streetId);
  //     prospectChange.emit(request);
  //   });
  // }

  exportEmail() {
    const prospectSearchRequest = JSON.parse(JSON.stringify(this.searchRequest));
    prospectSearchRequest.limit = this.pageSize;
    if (this.willBeDeletedProspectIds.length > 0 || this.willBeDeletedGroupIds.length > 0) {
      prospectSearchRequest.offset = 0;
    } else {
      prospectSearchRequest.offset = (this.currentPage - 1) * this.pageSize;
    }
    if (this.willBeDeletedProspectIds.length > 0) {
      prospectSearchRequest.prospectIds = this.willBeDeletedProspectIds;
      this.onSelectProspectIds.emit(prospectSearchRequest.prospectIds);
    }
    if (this.willBeDeletedGroupIds.length > 0) {
      prospectSearchRequest.streetIds = this.willBeDeletedGroupIds;
    }
    this.prospectService.searchGroupForEmail(prospectSearchRequest).subscribe((result: RestResult) => {
      if (result.data) {
        const filtered = result.data.filter(function (el) {
          return el != null;
        });
        const searchRequest = new SearchRequest();
        searchRequest.conditions = [];
        searchRequest.conditions.push({
          left: 'type',
          middle: '=',
          right: 'EMAIL',
        });
        this.templateService.search(searchRequest).subscribe(r => {
          this.dialogService.open(ExportDialogComponent, {
            context: {
              data: filtered.join(';'),
              templates: r.data,
              exportType: 'EMAIL'
            },
          });
        });
      }
    });
  }

  exportMobile() {
    const prospectSearchRequest = JSON.parse(JSON.stringify(this.searchRequest));
    if (this.willBeDeletedProspectIds.length > 0 || this.willBeDeletedGroupIds.length > 0) {
      prospectSearchRequest.offset = 0;
    } else {
      prospectSearchRequest.offset = (this.currentPage - 1) * this.pageSize;
    }
    prospectSearchRequest.limit = this.pageSize;
    if (this.willBeDeletedProspectIds.length > 0) {
      prospectSearchRequest.prospectIds = this.willBeDeletedProspectIds;
    }
    if (this.willBeDeletedGroupIds.length > 0) {
      prospectSearchRequest.streetIds = this.willBeDeletedGroupIds;
    }
    this.prospectService.searchGroupForPhone(prospectSearchRequest).subscribe((result: RestResult) => {
      if (result.data) {
        const searchRequest = new SearchRequest();
        searchRequest.conditions = [];
        searchRequest.conditions.push({
          left: 'type',
          middle: '=',
          right: 'SMS',
        });
        this.templateService.search(searchRequest).subscribe(r => {
          this.dialogService.open(ExportDialogComponent, {
            context: {
              prospectMobile: result.data,
              templates: r.data,
              adminNumber: this.adminNumber,
              exportType: 'MOBILE'
            },
          }).onClose.subscribe(
            onCloseRes => {
              if (onCloseRes) {
                this.saveBulk(onCloseRes.log, onCloseRes.ids);
              }
            },
          );
        });
      }
    });
  }

  clearSelected() {
    this.willBeDeletedProspectIds = [];
    this.willBeDeletedGroupIds = [];
    this.source.getAll().then(d => {
      const wrapProspects: WrapProspect[] = d;
      for (const wrapProspect of wrapProspects) {
        wrapProspect.selected = false;
      }
      this.source.load(wrapProspects);
    });
  }

  cancel() {
    this.isDisplay = false;
  }

  onSelectRate(rate: number) {
    this.searchRequest.rate = rate;
  }

  onSuburbChange(districtId) {
    this.searchRequest.districtId = districtId;
    this.searchRequest.streetId = undefined;
    if (districtId) {
      this.localService.streets(districtId).subscribe(result => {
        this.filteredStreets = result;
      });
    }
    // this.pageChange(1);
  }

  onStreetChange(streetId) {
    this.searchRequest.streetId = streetId;
    // this.pageChange(1);
  }

  onCityChange(cityId) {
    this.searchRequest.cityId = cityId;
    this.searchRequest.districtId = undefined;
    this.searchRequest.streetId = undefined;
    if (cityId) {
      this.localService.districts(cityId).subscribe(result => {
        this.filteredDistricts = result;
      });
    }
    // this.pageChange(1);
  }

  onSortTypeChange(type: string) {
    this.searchRequest.sortType = type;
    // this.pageChange(1);
  }

  onSellCompanyChange(value) {
    this.searchRequest.sellCompanyId = value;
  }

  onRentCompanyChange(value) {
    this.searchRequest.rentCompanyId = value;
  }

  onFromSellPriceChange(value) {
    this.searchRequest.fromSellPrice = value;
  }

  onToSellPriceChange(value) {
    this.searchRequest.toSellPrice = value;
  }

  onFromRentPriceChange(value) {
    this.searchRequest.fromRentPrice = value;
  }

  onToRentPriceChange(value) {
    this.searchRequest.toRentPrice = value;
  }

  onPropertyTypeChange(value) {
    this.searchRequest.type = value;
  }

  onBedTypeChange(value) {
    this.searchRequest.bed = value;
  }

  onBathTypeChange(value) {
    this.searchRequest.bath = value;
  }

  onCarTypeChange(value) {
    this.searchRequest.car = value;
  }

  selectProspectFromMapView(prospect: any) {
    this.selectedProspect = prospect;
  }

  onSearchByPostcode(value: number) {
    this.searchRequest.postcode = value;
    this.searchRequest.districtId = undefined;
    this.searchRequest.streetId = undefined;
    if (value && value !== 0) {
      this.localService.districtByPostcode(value).subscribe(result => {
        this.filteredDistricts = result;
      });
    }
  }

  preLoadAddressForFilter() {
    if (this.route.snapshot.queryParamMap.keys.length === 0) {
      return;
    }
    const postcode = Number(this.route.snapshot.queryParamMap.get('postcode'));
    const districtId = this.route.snapshot.queryParamMap.get('districtId');
    if (postcode) {
      this.localService.districtByPostcode(postcode).subscribe(result => {
        this.filteredDistricts = result;
      });
    }
    if (districtId) {
      this.localService.streets(Number(districtId)).subscribe(result => {
        this.filteredStreets = result;
      });
    }
  }
}
