<div class='col-12 d-flex mb-2'>
  <div class='col-4'>
    <span>{{farm}}</span>
  </div>
  <div class='col-4'>
    <span>{{zone}}</span>
  </div>
  <div class='col-4'>
    <button nbButton size='tiny' hero status="{{isVACMode === true ? 'primary' : 'basic'}}" class='fa-pull-right'
            (click)='onVACModeActive()'>VAC
    </button>
    <button nbButton size='tiny' hero status="{{isOTMMode === true ? 'primary' : 'basic'}}" class='fa-pull-right mr-1'
            (click)='onOTMModeActive()'>OTM
    </button>
  </div>
</div>
<div class='col-12 d-flex mt-2 row'>
  <div class='col-lg-3 col-12 override-width override-width-md mt-2'>
    <select fullWidth size='tiny'
            [(ngModel)]='sortType'
            class='full-width select-custom'
            (change)='onSortTypeSelectChange()'>
      <option [value]='undefined'>Sort by</option>
      <option *ngFor='let item of sortTypesText | keyvalue'
              value='{{item.key}}'
      >{{item.value}}</option>
    </select>
  </div>
  <div class='col-lg-3 col-12 override-width override-width-md mt-2'>
    <select fullWidth size='tiny'
            [(ngModel)]='streetId'
            [disabled]='!districtId'
            class='full-width select-custom'
            (change)='onStreetSelectChange()'
    >
      <option [value]='undefined'>All streets</option>
      <option *ngFor='let key of streets'
              [ngClass]="key.status === 'DEACTIVE' ? 'deactive-status' : ''"
              [value]='key.streetId'
      >{{key.name}}</option>
    </select>
  </div>
  <div class='col-lg-3 col-12 override-width override-width-md mt-2'>
    <select size='tiny'
            [(ngModel)]='districtId'
            [disabled]='!filterByPostcode'
            class='full-width select-custom'
            (change)='onSuburbSelectChange()'
    >
      <option [value]='undefined'>All suburbs</option>
      <option *ngFor='let key of districts'
              [ngClass]="key.status === 'DEACTIVE' ? 'deactive-status' : ''"
              [value]='key.districtId'
      >{{key.name}}</option>
    </select>
  </div>
  <div class='col-lg-3 col-12 override-width override-width-md mt-2'>
    <!--    <select size='tiny'-->
    <!--            [(ngModel)]='cityId'-->
    <!--            class='full-width select-custom'-->
    <!--            (change)='onCitySelectChange()'-->
    <!--    >-->
    <!--      <option [value]='undefined'>All cities</option>-->
    <!--      <option *ngFor='let key of cities'-->
    <!--              [ngClass]="key.status === 'DEACTIVE' ? 'deactive-status' : ''"-->
    <!--              [value]='key.cityId'-->
    <!--      >{{key.name}}</option>-->
    <!--    </select>-->
    <nb-form-field>
      <input nbInput fullWidth placeholder='Postcode' fieldSize='tiny' class='override-nbinput-height'
             [(ngModel)]='postcode'
             [typeahead]='filterPostcode$'
             [ngModelOptions]='{standalone: true}'
             [typeaheadAsync]='true'
             (typeaheadOnSelect)='selectPostcode($event)'
             typeaheadOptionField='code'
             typeaheadMinLength='3'
             typeaheadWaitMs='1000'>
      <!--      <span nbSuffix (click)='searchSuburbByPostcode()'><i class='fa fa-search'></i></span>-->
    </nb-form-field>
  </div>
</div>
<div class='col-12 d-flex mt-2 row'>
  <div class='col-6'>
    <input type='text' nbInput fieldSize='tiny' fullWidth name='phone' placeholder='Phone / Mobile'
           [(ngModel)]='phone' />
  </div>
</div>
<div class='col-12 d-flex mt-2 row'>
  <div class='col-6'>
    <nb-select fullWidth size='tiny' [(ngModel)]='rate' (selectedChange)='onRateSelectChange($event)'>
      <nb-option [value]='undefined'>All rates</nb-option>
      <nb-option *ngFor='let item of rateOptions'
                 [value]='item.value'>{{item.label}}</nb-option>
    </nb-select>
  </div>
  <div class='col-6'>
    <button nbButton size='tiny' hero status='primary' class='fa-pull-right' (click)='addNewProspect()'
            *appPermissionCheck='ComponentPermission.CLIENT_CREATE'>New
    </button>
    <button nbButton size='tiny' hero status='primary' class='fa-pull-right mr-1' (click)='openShortcut()'
            *appPermissionCheck='ComponentPermission.CLIENT_CREATE_SHORTCUT'>Shortcut
    </button>
  </div>
</div>
<div class='col-12 d-flex mt-2 row'>
  <div class='col-6' *ngIf='isOTMMode'>
    <span nbTooltip='Total OTM'>{{totalOtm}}</span>
    -
    <span class='otm-expired' nbTooltip='Total OTM Expired'>{{totalOtmExpired}}</span>
    -
    <span class='otm-active' nbTooltip='Total OTM Active'>{{totalOtmActive}}</span>
  </div>
  <div class='col-6' *ngIf='isVACMode'>
    <span nbTooltip='Total VAC'>{{totalVac}}</span>
    -
    <span class='otm-expired' nbTooltip='Total VAC Expired'>{{totalVacExpired}}</span>
    -
    <span class='otm-active' nbTooltip='Total VAC Active'>{{totalVacActive}}</span>
  </div>
  <div class='col-6'>
    <!--    <nb-form-field>-->
    <!--      <input type='text' nbInput fullWidth fieldSize='tiny' placeholder='Text' [(ngModel)]='log'>-->
    <!--      <button nbButton nbSuffix size='tiny' hero status='primary' class='mr-4' (click)='onBulkSave()'>Save</button>-->
    <!--    </nb-form-field>-->
    <button nbButton size='tiny' hero status='primary' class='fa-pull-right' (click)='onExportEmail()'>
      <i class='fa fa-download mr-1'></i>
      Email
    </button>
    <button nbButton size='tiny' hero status='primary' class='fa-pull-right mr-1' (click)='onExportMobile()'>
      <i class='fa fa-download mr-1'></i>
      Mobile
    </button>
  </div>
</div>
<nb-accordion class='mt-2 row'>
  <nb-accordion-item>
    <nb-accordion-item-header>
        <div class='col-sm-4 col-12 mt-2'>
          <span class='text-center'>Advance search</span>
        </div>
    </nb-accordion-item-header>
    <nb-accordion-item-body>
      <div class='col-12 d-flex mt-2 row'>
        <div class='col-3 mt-2'>
          <select fullWidth size='tiny'
                  [(ngModel)]='otmStatus'
                  class='full-width'
                  (change)='onOtmStatusSelectChange()'
                  (click)='$event.stopPropagation()'
          >
            <option [value]='undefined'>ALL</option>
            <option *ngFor='let item of otmStatuses | keyvalue'
                    value='{{item.key}}'
            >{{item.value}}</option>
          </select>
        </div>
        <div class='col-3 mt-2'>
          <select fullWidth size='tiny'
                  [(ngModel)]='selectedDay'
                  class='full-width'
                  [disabled]='!selectedMonth'
                  (change)='onDaySelectChange()'
          >
            <option [value]='undefined'>Day</option>
            <option *ngFor='let item of days'
                    [value]='item'
            >{{item}}</option>
          </select>
        </div>
        <div class='col-3 mt-2'>
          <select fullWidth size='tiny'
                  [(ngModel)]='selectedMonth'
                  class='full-width'
                  [disabled]='!selectedYear'
                  (change)='onMonthSelectChange()'
          >
            <option [value]='undefined'>Month</option>
            <option *ngFor='let item of months'
                    [value]='item + 1'
            >{{item + 1}}</option>
          </select>
        </div>
        <div class='col-3 mt-2'>
          <select fullWidth size='tiny'
                  [(ngModel)]='selectedYear'
                  class='full-width'
                  (change)='onYearSelectChange()'
          >
            <option [value]='undefined'>Year</option>
            <option *ngFor='let item of years'
                    [value]='item'
            >{{item}}</option>
          </select>
        </div>
      </div>
      <div class='col-12 mt-2 row' *ngIf='isOTMMode'>
        <div class='col-12 col-md-3'>
          <select fullWidth size='tiny'
                  [(ngModel)]='sellCompanyId'
                  class='full-width'
                  (change)='onSellCompanySelectChange()'>
            <option [value]='undefined'>Sell company</option>
            <option *ngFor='let item of companies'
                    value='{{item.companyId}}'
            >{{item.name}}</option>
          </select>
        </div>
        <div class='col-6 col-md-3 margin-top-sm'>
          <input nbInput fieldSize='tiny'
                 fullWidth placeholder='From sell price'
                 [(ngModel)]='fromSellPrice'
                 (change)='onFromSellPriceInputChange()' type='number' min='0' step='0.01'
          >
        </div>
        <div class='col-6 col-md-3 margin-top-sm'>
          <input nbInput fieldSize='tiny'
                 fullWidth placeholder='To sell price'
                 [(ngModel)]='toSellPrice'
                 (change)='onToSellPriceInputChange()' type='number' min='0' step='0.01'
          >
        </div>
      </div>

      <div class='col-12 d-flex mt-2' *ngIf='isVACMode'>
        <div class='col-3'>
          <select fullWidth size='tiny'
                  [(ngModel)]='rentCompanyId'
                  class='full-width'
                  (change)='onRentCompanySelectChange()'>
            <option [value]='undefined'>Rent company</option>
            <option *ngFor='let item of companies'
                    value='{{item.companyId}}'
            >{{item.name}}</option>
          </select>
        </div>
        <div class='col-3'>
          <input nbInput fieldSize='tiny'
                 fullWidth placeholder='From rent price'
                 [(ngModel)]='fromRentPrice'
                 (change)='onFromRentPriceInputChange()' type='number' min='0' step='0.01'
          >
        </div>
        <div class='col-3'>
          <input nbInput fieldSize='tiny'
                 fullWidth placeholder='To rent price'
                 [(ngModel)]='toRentPrice'
                 (change)='onToRentPriceInputChange()' type='number' min='0' step='0.01'
          >
        </div>
      </div>

      <div class='col-12 d-flex mt-2 row'>
        <div class='col-3'>
          <select
            fullWidth size='tiny'
            [(ngModel)]='propertyType'
            class='full-width'
            (change)='onPropertyTypeSelectChange()'>
            <option [value]='undefined' disabled>Type</option>
            <option *ngFor='let item of propertyTypes | keyvalue:keepOrder'
                    value='{{item.key}}'
            >{{item.value}}</option>
          </select>
        </div>
        <div class='col-3'>
          <select
            fullWidth size='tiny'
            [(ngModel)]='bed'
            class='full-width'
            (change)='onBedSelectChange()'>
            >
            <option [value]='undefined'>Bed</option>
            <option *ngFor='let item of bedValues' [value]='item'>{{item}}</option>
          </select>
        </div>
        <div class='col-3'>
          <select
            fullWidth size='tiny'
            [(ngModel)]='bath'
            class='full-width'
            (change)='onBathSelectChange()'>
            >
            <option [value]='undefined'>Bath</option>
            <option *ngFor='let item of bedValues' [value]='item'>{{item}}</option>
          </select>
        </div>
        <div class='col-3'>
          <select
            fullWidth size='tiny'
            [(ngModel)]='car'
            class='full-width'
            (change)='onCarSelectChange()'>
            >
            <option [value]='undefined'>Car</option>
            <option *ngFor='let item of bedValues' [value]='item'>{{item}}</option>
          </select>
        </div>
      </div>

    </nb-accordion-item-body>
  </nb-accordion-item>
</nb-accordion>
<div class='col-12 d-flex mt-2'>
  <div class='col-12'>
    <button nbButton size='tiny' hero status='primary' class='fa-pull-right' (click)='onSearchButtonClick()'>Search
    </button>
  </div>
</div>
