import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import * as moment from 'moment';
import {TranslateService} from '@ngx-translate/core';
import {NbComponentStatus, NbGlobalPhysicalPosition, NbGlobalPosition} from '@nebular/theme';
import {throwError} from 'rxjs';
import {RestResult} from '../models/response/rest-result';
import {ToastrService} from 'ngx-toastr';
import {debounceTime, skip, switchMap, takeUntil} from 'rxjs/operators';

@Injectable()
export class CommonService {
  destroyByClick = true;
  duration = 500;
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  preventDuplicates = false;
  types: NbComponentStatus[] = [
    'primary',
    'success',
    'info',
    'warning',
    'danger',
  ];

  constructor(private http: HttpClient,
              private translator: TranslateService,
              private toastr: ToastrService) {
  }

  translate(key: string): string {
    let text = '';
    this.translator.get(key).subscribe(res => text = res);
    return (!text || text === '') ? key : text;
  }

  info(messageKey: string) {
    this.toastr.info(messageKey);
  }

  warning(messageKey: string) {
    this.toastr.warning(messageKey);
  }

  warningHtml(messageKey: string) {
    this.toastr.warning(messageKey, '', {
      enableHtml: true,
    });
  }

  error(message: string) {
    this.toastr.error(message);
  }

  parseDate(dateStr: string, dateFormat: string): number {
    return moment(dateStr, dateFormat).valueOf();
  }

  formatDate(millis: number, dateFormat: string): string {
    return moment(millis).format(dateFormat);
  }

  handleError(httpError: HttpErrorResponse) {
    let message = '';
    let error;
    if (httpError.error && httpError.error.status) {
      if (httpError.error.messages.length > 1) {
        for (const errorMessage of httpError.error.messages) {
          if (errorMessage) {
            message += '<li>' + errorMessage + '</li>';
          }
        }
      } else {
        message = httpError.error.messages[0];
      }
      if (message === '') {
        message = this.getDefaultMessage(httpError.error.status);
      }
      error = httpError.error;
    } else {
      error = new RestResult();
      error.status = 'error';
      message = this.getDefaultMessage(httpError.status);
    }
    error.message = message;
    return throwError(error);
  }

  getDefaultMessage(status: number): string {
    switch (status) {
      case 500:
        return 'Unexpected error, please contact administrator for help.';
      case 403:
        return 'You don\'t have permission to access this resource';
      case 401:
        return 'Please login or logout and try login again to process';
      case 400:
        return 'Please check your request and retry';
      default:
        return 'Unexpected error, please contact administrator for help.';
    }
  }
}

// reference: https://blog.strongbrew.io/building-a-safe-autocomplete-operator-with-rxjs/
export const autocomplete = (time, selector) => (source$) =>
  source$.pipe(
    debounceTime(time),
    switchMap((...args: any[]) =>
      selector(...args)
      .pipe(
        takeUntil(
          source$
          .pipe(
            skip(1),
          ),
        ),
      ),
    ),
  );
