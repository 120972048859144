import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {AuthService} from '../services/auth.service';

@Directive({
  selector: '[appSecured]'
})
export class SecuredDirective implements OnInit {
  @Input('show') showRequiredAuthorities: string | string[];
  @Input('hide') hideRequiredAuthorities: string | string[];
  @Input('enable') enableRequiredAuthorities: string | string[];

  constructor(private el: ElementRef, private authService: AuthService) {
  }

  ngOnInit(): void {
    if (this.showRequiredAuthorities) {
      this.authorizeShowPermission(this.showRequiredAuthorities);
    }
    if (this.enableRequiredAuthorities) {
      this.authorizeEnablePermission(this.enableRequiredAuthorities);
    }
    if (this.hideRequiredAuthorities) {
      this.authorizeHidePermission(this.hideRequiredAuthorities);
    }
  }

  private authorizeShowPermission(permissions: string | string[]) {
    const authorized = this.authService.checkPermission(permissions);
    this.el.nativeElement.style.display = authorized ? 'block' : 'none';
  }


  private authorizeEnablePermission(permissions: string | string[]) {
    this.el.nativeElement.disabled = !this.authService.checkPermission(permissions);
  }

  private authorizeHidePermission(permissions: string | string[]) {
    const authorized = this.authService.checkPermission(permissions);
    this.el.nativeElement.style.display = authorized ? 'none' : 'block';
  }
}
