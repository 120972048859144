<nb-card *appPermissionCheck='ComponentPermission.COMPANY_EDIT'>
  <nb-card-header>
    <span>Update company</span>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="form">

      <div class="form-group" [ngClass]="showError('name')">
        <label class="label">Name</label>
        <input type="text" nbInput fullWidth formControlName="name" name="name">
      </div>

      <div class="form-group" [ngClass]="showError('code')">
        <label class="label">Address</label>
        <input type="text" nbInput fullWidth formControlName="address" name="address">
      </div>

      <div class="form-group" [ngClass]="showError('phone')">
        <label class="label">Phone</label>
        <input type="text" nbInput fullWidth formControlName="phone" name="phone">
      </div>

      <div class="form-group" [ngClass]="showError('email')">
        <label class="label">Email</label>
        <input type="text" nbInput fullWidth formControlName="email" name="email">
      </div>
      <div class="form-group" [ngClass]="showError('type')">
        <label class="label">Type</label>
        <nb-select formControlName="type" name="type" fullWidth>
          <nb-option *ngFor='let type of COMPANY_TYPE' [value]='type'>{{type}}</nb-option>
        </nb-select>
      </div>


    </form>
  </nb-card-body>
  <nb-card-footer class="dialog-footer">
    <button class="cancel" nbButton status="danger" (click)="cancel()">Cancel</button>
    <button nbButton status="success" (click)="submit()">Save</button>
  </nb-card-footer>
</nb-card>
