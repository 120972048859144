<nb-card>
  <nb-card-header>
    You can move or copy information from customer to customer
  </nb-card-header>

  <nb-card-body>
    <div class="row">
      <div class="col-6" *ngFor="let selectedProspect of prospects">
        <button nbButton size="tiny" hero status="primary"
                class="m-2"
                nbTooltip="Move information of other to this one"
                [hidden]="!multipleSelectedRequest.selectedIds || multipleSelectedRequest.selectedIds.length != 2"
                (click)="moveOtherToThis(selectedProspect.prospectId, selectedProspect.customer.customerId, 'PROSPECT')"
        >
          Move Other To This
        </button>
        <ngx-prospect-dialog
          #prospectChild
          [selectedProspect]="selectedProspect"
          [showFooter]="true"
          [prospects]="prospects"
          [companies]="companies"
          [taskTypes]="taskTypes"
          [taskStatuses]="taskStatuses"
          (editDone)="editDone($event)"
        ></ngx-prospect-dialog>
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer class="dialog-footer">
    <button class="cancel" size="tiny" nbButton status="primary" (click)="save()">Save All</button>
    <button class="cancel" size="tiny" nbButton status="warning" (click)="cancel()">Cancel</button>
  </nb-card-footer>
</nb-card>
