import * as moment from 'moment';
import {Constant} from './constant';

export class DateUtils {

  static getConfigDate(): string {
    const dataStr = localStorage.getItem(Constant.LS_CURRENT_USER_KEY);
    if (dataStr) {
      const user = JSON.parse(dataStr);
      if (user.office?.officeProperty?.configDate) {
        return user.office.officeProperty.configDate;
      }
    }
    return 'WEDNESDAY';
  }

  static SIMPLE_DAY_LABEL = ['WED', 'THU', 'FRI', 'SAT', 'SUN', 'MON', 'TUE'];
  static QUARTER_MONTH = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [9, 10, 11]
  ];

  static MONTHS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  /**
   * return - day if start in previous year
   * return + day if start in same year
   */
  static getMissingDay(year: number): number {
    year = Number(year);
    const firstDayOfYear = DateUtils.getFirstDayOfYear(year);
    let missingDay = 0;
    if (firstDayOfYear.year() < year) {
      const indexOfFirstDay = firstDayOfYear.dayOfYear();
      if (firstDayOfYear.isLeapYear()) {
        missingDay = indexOfFirstDay - 367;
      } else {
        missingDay = indexOfFirstDay - 366;
      }
    } else {
      missingDay = firstDayOfYear.dayOfYear() - 1;
    }

    return missingDay;
  }

  static dayOfYear(viewDate: any): number {
    const dayOfYear = viewDate.dayOfYear();
    const missingDay = DateUtils.getMissingDay(viewDate.year());
    // start of the year in this year
    let currentIndex = dayOfYear - missingDay;
    if (currentIndex < 0) {
      if (viewDate.isLeapYear()) {
        currentIndex = (366 - currentIndex);
      } else {
        currentIndex = (365 - currentIndex);
      }
    }
    return currentIndex;
  }

/////////////////////////// BUSINESS METHOD ////////////////////////////////////////////

  /**
   *
   * @param timestamp
   * @returns {number}
   */
  static getCurrentWeekFromTimestamp(timestamp: number): number {
    return this.getCurrentWeek(moment(timestamp));
  }

  /**
   *
   * @param viewDate moment type
   * @returns {number}
   */
  static getCurrentWeek(viewDate: any): number {
    return Math.ceil(DateUtils.dayOfYear(viewDate) / 7);
  }

  /**
   * return first day of business year
   * month start from 0
   */
  static getFirstDayOfYear(year: number): any {
    year = Number(year);
    const day = moment().year(year).month(0).date(1).day(DateUtils.getConfigDate());
    return this.getStartOfDateMoment(day);
  }

  /**
   * return last day of business year (tuesday)
   *
   * @param {number} i
   * @returns {any}
   */
  static getLastDayOfYear(year: number): any {
    year = Number(year);
    const day = DateUtils.getFirstDayOfYear(year + 1);
    const lastDay = day.subtract(1, 'day');
    return this.getEndOfDateMoment(lastDay);
  }

  /**
   * get start day of week (1 - 53) first configdate of week
   */
  static getFirstDayOfWeek(week: number, year: number): any {
    year = Number(year);
    week = Number(week);
    const day = this.getFirstDayOfYear(year);
    const firstDay = day.add((week - 1) * 7, 'day');
    return this.getStartOfDateMoment(firstDay);
  }

  /**
   * get last day of week (1-53) last tuesday of week
   * @param week
   * @param year
   */
  static getLastDayOfWeek(week: number, year: number): any {
    year = Number(year);
    week = Number(week);
    const day = this.getFirstDayOfYear(year);
    const endDay = day.add(((week - 1) * 7) + 6, 'day');
    return this.getEndOfDateMoment(endDay);
  }

  /**
   * get first day of month (1-12) first configDate of month
   * @param month
   * @param year
   */
  static getFirstDayOfMonth(month: number, year: number): any {
    year = Number(year);
    month = Number(month);
    const firstDay = moment().year(year).month(month).date(1).day(DateUtils.getConfigDate());
    return this.getStartOfDateMoment(firstDay);
  }

  /**
   * get last day of month (1-12) last tuesday of month
   * @param month
   * @param year
   */
  static getLastDayOfMonth(month: number, year: number): any {
    year = Number(year);
    month = Number(month);
    const day = DateUtils.getFirstDayOfMonth(month + 1, year);
    const lastDay = day.subtract(1, 'day');
    return this.getEndOfDateMoment(lastDay);
  }

  /**
   * get start day of quarter (quarter start from 0 - 3)
   */
  static getFirstDayOfQuarter(quarter: number, year: number): any {
    year = Number(year);
    quarter = Number(quarter);
    const month = DateUtils.QUARTER_MONTH[quarter];
    return this.getFirstDayOfMonth(month[0], year);
  }

  /**
   * get last day of quarter (quarter start from 0 - 3)
   */
  static getLastDayOfQuarter(quarter: number, year: number): any {
    year = Number(year);
    quarter = Number(quarter);
    const month = DateUtils.QUARTER_MONTH[quarter];
    return this.getLastDayOfMonth(month[2], year);
  }

  // weekOfYear: 1 - 53
  static getAllDaysOfWeek(weekOfYear: number, year: number): any[] {
    year = Number(year);
    weekOfYear = Number(weekOfYear);
    const result = [];
    const firstDayOfWeek = DateUtils.getFirstDayOfWeek(weekOfYear, year);
    for (let i = 0; i < 7; i++) {
      result.push(moment(firstDayOfWeek, 'DD-MM-YYYY').add(i, 'day'));
    }
    return result;
  }


////////////////////////////////////////////////////////////////////////////

  /**
   * return 00:00:00 of day
   */
  static getStartOfDate(date: number, month: number, year: number) {
    year = Number(year);
    month = Number(month);
    date = Number(date);
    return moment().date(date).year(year).month(month).startOf('day');
  }

  /**
   * return 23:59:59 of day
   */
  static getEndOfDate(date: number, month: number, year: number) {
    year = Number(year);
    month = Number(month);
    date = Number(date);
    return moment().date(date).year(year).month(month).endOf('day');
  }

  static getStartOfDateMoment(viewDate: any) {
    return viewDate.startOf('day');
  }

  static getEndOfDateMoment(viewDate: any) {
    return viewDate.endOf('day');
  }

  ///////////////////////////////////////////////////////////////

  /**
   * month start from 0
   * @param month
   * @param year
   */
  static getFirstWeekOfMonth(month: number, year: number) {
    year = Number(year);
    month = Number(month);
    const firstDay = moment().year(year).month(month).startOf('month');
    const dayOfWeek = firstDay.day();
    let week = 0;
    week = Math.ceil(DateUtils.dayOfYear(firstDay) / 7);
    if (dayOfWeek < 3) {
      week += 1;
    }
    return week;
  }

  /**
   * month start from zero
   * @param month
   * @param year
   */
  static getLastWeekOfMonth(month: number, year: number) {
    year = Number(year);
    month = Number(month);
    const lastDay = moment().year(year).month(month).endOf('month');
    const dayOfWeek = lastDay.day();
    let week = 0;
    const x = DateUtils.dayOfYear(lastDay);
    const y = lastDay.dayOfYear();
    week = Math.ceil(DateUtils.dayOfYear(lastDay) / 7);
    // if last day failed to 4 5 6, not counted
    if (6 > dayOfWeek && dayOfWeek > 2) {
      week -= 1;
    }
    return week;
  }
}
