import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { District } from '../models/response/address/district';
import { Street } from '../models/response/address/street';
import { City } from '../models/response/address/city';
import { CityService } from './city.service';
import { StreetService } from './street.service';
import { SearchRequest } from '../models/request/search-request';
import { SuburbService } from './suburb.service';
import { catchError, concatMap } from 'rxjs/operators';
import { CommonService } from './common.service';
import { ZoneService } from './zone.service';
import { Zone } from '../models/response/address/zone';
import { FarmService } from './farm.service';
import { Farm } from '../models/response/address/farm';

@Injectable()
export class LocalService {
  EXPIRY_DURATION_IN_MILLISECOND = 86400000; // 1 day

  constructor(
    private cityService: CityService,
    private commonService: CommonService,
    private streetService: StreetService,
    private districtService: SuburbService,
    private farmService: FarmService,
    private zoneService: ZoneService,
  ) {
  }

  cities(): Observable<City[]> {
    const searchRequest = new SearchRequest();
    searchRequest.offset = 0;
    searchRequest.limit = 10000;
    searchRequest.orders = new Array();
    searchRequest.orders.push(
      {
        left: 'name',
        right: 'asc',
      },
    );
    searchRequest.conditionType = 'AND';
    searchRequest.conditions = [
      {
        left: 'status',
        middle: '=',
        right: 'ACTIVE',
      },
    ];
    return this.cityService.search(searchRequest).pipe(
      concatMap(
        result => {
          return of(result.data);
        }),
      catchError(this.commonService.handleError),
    );
  }

  streets(districtId?: number): Observable<Street[]> {
    const searchRequest = new SearchRequest();
    searchRequest.offset = 0;
    searchRequest.limit = 10000;
    searchRequest.orders = new Array();
    searchRequest.orders.push(
      {
        left: 'name',
        right: 'asc',

      },
    );
    if (districtId) {
      searchRequest.conditionType = 'AND';
      searchRequest.conditions = [
        {
          left: 'districtId',
          middle: '=',
          right: districtId,
        },
        {
          left: 'status',
          middle: '=',
          right: 'ACTIVE',
        },
      ];
    }
    return this.streetService.search(searchRequest).pipe(
      concatMap(
        result => {
          return of(result.data);
        }),
      catchError(this.commonService.handleError),
    );
  }

  streetsByFarm(farmId?: number): Observable<Street[]> {
    const searchRequest = new SearchRequest();
    searchRequest.offset = 0;
    searchRequest.limit = 10000;
    searchRequest.orders = new Array();
    searchRequest.orders.push(
      {
        left: 'name',
        right: 'asc',

      },
    );
    if (farmId) {
      searchRequest.conditionType = 'AND';
      searchRequest.conditions = [
        {
          left: 'farmId',
          middle: '=',
          right: farmId,
        },
        {
          left: 'status',
          middle: '=',
          right: 'ACTIVE',
        },
      ];
    }
    return this.streetService.search(searchRequest).pipe(
      concatMap(
        result => {
          return of(result.data);
        }),
      catchError(this.commonService.handleError),
    );
  }

  farms(districtId?: number): Observable<Farm[]> {
    const searchRequest = new SearchRequest();
    searchRequest.offset = 0;
    searchRequest.limit = 10000;
    searchRequest.orders = new Array();
    searchRequest.orders.push(
      {
        left: 'name',
        right: 'asc',

      },
    );
    if (districtId) {
      searchRequest.conditionType = 'AND';
      searchRequest.conditions = [
        {
          left: 'districtId',
          middle: '=',
          right: districtId,
        },
        {
          left: 'status',
          middle: '=',
          right: 'ACTIVE',
        },
      ];
    }
    return this.farmService.search(searchRequest).pipe(
      concatMap(
        result => {
          return of(result.data);
        }),
      catchError(this.commonService.handleError),
    );
  }

  districts(cityId?: number): Observable<District[]> {
    const searchRequest = new SearchRequest();
    searchRequest.offset = 0;
    searchRequest.limit = 10000;
    searchRequest.orders = new Array();
    searchRequest.orders.push(
      {
        left: 'name',
        right: 'asc',
      },
    );
    if (cityId) {
      searchRequest.conditionType = 'AND';
      searchRequest.conditions = [
        {
          left: 'cityId',
          middle: '=',
          right: cityId,
        },
        {
          left: 'status',
          middle: '=',
          right: 'ACTIVE',
        },
      ];
    }
    return this.districtService.search(searchRequest).pipe(
      concatMap(
        result => {
          return of(result.data);
        }),
      catchError(this.commonService.handleError),
    );
  }

  districtsByZone(zoneId?: number): Observable<District[]> {
    const searchRequest = new SearchRequest();
    searchRequest.offset = 0;
    searchRequest.limit = 10000;
    searchRequest.orders = new Array();
    searchRequest.orders.push(
      {
        left: 'name',
        right: 'asc',
      },
    );
    if (zoneId) {
      searchRequest.conditionType = 'AND';
      searchRequest.conditions = [
        {
          left: 'zoneId',
          middle: '=',
          right: zoneId,
        },
        {
          left: 'status',
          middle: '=',
          right: 'ACTIVE',
        },
      ];
    }
    return this.districtService.search(searchRequest).pipe(
      concatMap(
        result => {
          return of(result.data);
        }),
      catchError(this.commonService.handleError),
    );
  }

  zones(cityId?: number): Observable<Zone[]> {
    const searchRequest = new SearchRequest();
    searchRequest.offset = 0;
    searchRequest.limit = 10000;
    searchRequest.orders = new Array();
    searchRequest.orders.push(
      {
        left: 'name',
        right: 'asc',
      },
    );
    if (cityId) {
      searchRequest.conditionType = 'AND';
      searchRequest.conditions = [
        {
          left: 'cityId',
          middle: '=',
          right: cityId,
        },
        {
          left: 'status',
          middle: '=',
          right: 'ACTIVE',
        },
      ];
    }
    return this.zoneService.search(searchRequest).pipe(
      concatMap(
        result => {
          return of(result.data);
        }),
      catchError(this.commonService.handleError),
    );
  }

  districtByPostcode(postcode?: number) {
    const searchRequest = new SearchRequest();
    searchRequest.offset = 0;
    searchRequest.limit = 10000;
    searchRequest.orders = new Array();
    searchRequest.orders.push(
      {
        left: 'name',
        right: 'asc',
      },
    );
    if (postcode) {
      searchRequest.conditionType = 'AND';
      searchRequest.conditions = [
        {
          left: 'code',
          middle: '=',
          right: postcode,
        },
        {
          left: 'status',
          middle: '=',
          right: 'ACTIVE',
        },
      ];
    }
    return this.districtService.search(searchRequest).pipe(
      concatMap(
        result => {
          return of(result.data);
        }),
      catchError(this.commonService.handleError),
    );
  }

  postcodes(districts: District[]): string[] {
    const postcodes = districts.map(d => (d.code.trim().split('.')[0]));
    return [...new Set(postcodes.sort())];
  }
}
