import {Customer} from '../prospect/customer';
import {Property} from '../prospect/property';
import {BuyerDeposit} from './buyer-deposit';
import {BuyerLog} from './buyer-log';
import {Prospect} from '../prospect/prospect';
import {Task} from '../task';
import {InterestDistrict} from './interest-district';
import {InterestZone} from './interest-zone';
import {Category} from '../category';
import {Office} from '../office';
import {Address} from '../address/address';

export class Buyer {
  buyerId?: number;
  buyerCode?: string;
  call?: boolean;
  sms?: boolean;
  door?: boolean;
  mail?: boolean;
  customerId?: number;
  prospectId?: number;
  propertyId?: number;
  rate?: number;
  isActive = 'ACTIVE';
  isCommon?: boolean;
  isLocal?: boolean;
  isAddress?: boolean;
  isNumber?: boolean;
  isInspection?: boolean;
  isDeposit?: boolean;
  isFirstHome?: boolean;
  isInvestor?: boolean;
  isValueBuyer?: boolean;
  isDeveloper?: boolean;
  note?: string;
  enq?: string;
  isWot?: boolean;
  clientTenant?: boolean;
  clientSeller?: boolean;
  lastApp?: string;
  lastContact?: string;
  lastCalledDate?: number;
  customer?: Customer;
  property?: Property;
  buyerDeposit: BuyerDeposit;
  logs: BuyerLog[];
  prospect: Prospect;
  tasks?: Task[];
  interestDistricts: InterestDistrict[];
  interestZones: InterestZone[];
  categories: Category[];
  office?: Office;
  addressId?: number;
  address?: Address;
}
