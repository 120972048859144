<nb-card *appPermissionCheck='ComponentPermission.TASK_STATUS_CREATE'>
  <nb-card-header>
    <span>Add new task status</span>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="form">
      <div class="form-group" [ngClass]="showError('name')">
        <label class="label">Name</label>
        <input type="text" nbInput fullWidth formControlName="name" name="name">
      </div>

      <div class="form-group">
        <label class="label">Description</label>
        <textarea rows="5" nbInput
                  fullWidth shape="round"  placeholder="Text Area" formControlName="description"></textarea>
      </div>
    </form>
  </nb-card-body>
  <nb-card-footer class="dialog-footer">
    <button class="cancel" nbButton status="danger" (click)="cancel()">Cancel</button>
    <button nbButton status="success" (click)="submit()">Save</button>
  </nb-card-footer>
</nb-card>
