import {Component, Input, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {CommonService} from '../../services/common.service';
import {AttachmentRequest} from '../../models/request/attachment-request';
import {AttachmentService} from '../../services/attachment.service';
import {Attachment} from '../../models/response/attachment';
import {ConfirmDialogComponent} from '../../../modules/pages/confirm-dialog/confirm-dialog.component';
import {NbDialogService} from '@nebular/theme';
import {SearchRequest} from '../../models/request/search-request';
import {Triple} from '../../models/request/triple';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {SosAnswerService} from '../../services/sos-answer.service';

@Component({
  selector: 'ngx-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss']
})
export class AttachmentComponent implements OnInit, OnDestroy {
  index = 0;
  displayFull = false;

  // file: File;
  files: File[] = [];
  request: AttachmentRequest = new AttachmentRequest();
  images: Attachment[] = [];
  isModal: boolean;
  onClose: any;

  @Input() objectId: number;
  @Input() objectType: string;
  @Input() displayLabel: boolean;
  modalRef: BsModalRef;

  constructor(private commonService: CommonService,
              private attachmentService: AttachmentService,
              private dialogService: NbDialogService,
              private sosAnswerService: SosAnswerService,
              private modalService: BsModalService
  ) {
  }

  ngOnDestroy() {
    this.close(this.images?.length);
  }

  openModal(template: TemplateRef<any>, index: number) {
    this.modalRef = this.modalService.show(template);
    this.index = index;
  }

  ngOnInit(): void {
    if (this.objectId && this.objectType) {
      this.getImages();
    }
  }

  displaySlide(index: number) {
    this.index = index;
    this.displayFull = true;
  }

  getImages() {
    const sr = new SearchRequest();
    sr.conditions = [];
    sr.conditions.push(new Triple('objectId', '=', this.objectId));
    sr.conditions.push(new Triple('objectType', '=', this.objectType));
    this.attachmentService.search(sr).subscribe(result => {
      if (result.data) {
        this.images = result.data;
      }
    });
  }

  reloadImages(id: number) {
    this.objectId = id;
    this.getImages();
  }

  // upload() {
  //   if (!this.file) {
  //     return;
  //   }
  //   this.request.file = this.file;
  //   this.request.isPublic = true;
  //   this.attachmentService.upload(this.request).subscribe(result => {
  //     this.commonService.info('Uploaded images');
  //   }, error => {
  //     this.commonService.warningHtml(error.message);
  //   });
  // }

  uploadBatch(id?: number, type?: string, reload?: boolean) {
    if (id && type) {
      this.request.objectId = id;
      this.request.objectType = type;
    } else {
      this.request.objectId = this.objectId;
      this.request.objectType = this.objectType;
    }
    if (!this.objectId || !this.objectType) {
      // this.commonService.warning('Empty type, cannot upload');
      return;
    }
    if (this.files.length === 0) {
      // this.commonService.warning('Please upload file');
      return;
    }
    this.request.files = this.files;
    this.request.isPublic = true;
    this.attachmentService.uploadBatch(this.request).subscribe(result => {
      this.commonService.info('Uploaded images');
      this.files = [];
      if (reload === true) {
        this.getImages();
      }
    }, error => {
      this.commonService.warningHtml(error.message);
    });
  }

  onSelect(event) {
    if (event.rejectedFiles && event.rejectedFiles.length > 0) {
      event.rejectedFiles.forEach(file => {
        if (file.reason === 'size') {
          this.commonService.warning('File maximum size exceeded');
        } else {
          this.commonService.warning('Error when selecting file : ' + file.reason);
        }
      });
    }
    this.files.push(...event.addedFiles);
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  deleteImage(id: number, index: number) {
    this.dialogService.open(ConfirmDialogComponent, {
      context: {
        content: 'Do you really want to delete?',
      },
    })
    .onClose.subscribe(res => {
      if (res === ConfirmDialogComponent.confirmOk) {
        this.attachmentService.remove(id).subscribe(
          result => {
            this.images.splice(index, 1);
            this.commonService.info('Deleted image');
          }, error => {
            this.commonService.warning(error.message);
          });
      }
    });
  }

  submit() {
    if (this.objectId && this.objectType) {
      this.uploadBatch(this.objectId, this.objectType, true);
    }
  }

  close(result?: any) {
    if (this.onClose) {
      this.onClose(result);
    }
  }
}
