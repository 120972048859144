import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from './common.service';
import { Observable } from 'rxjs';
import { RestResult } from '../models/response/rest-result';
import { Path } from '../common/path';
import { catchError, map } from 'rxjs/operators';
import { SearchRequest } from '../models/request/search-request';
import { ProspectRequest } from '../models/request/prospect/prospect-request';

@Injectable()
export class MarketingService {
  constructor(protected http: HttpClient,
              protected commonService: CommonService) {
  }

  getOne(marketingId: number): Observable<RestResult> {
    return this.http.get<any>(
      Path.API_ENDPOINT +
      Path.MARKETING +
      Path.MARKETING_ID.replace(':marketingId', marketingId.toString()),
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }


  search(request: SearchRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.MARKETING +
      Path.SEARCH, request,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  create(request: ProspectRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.MARKETING, request).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  remove(marketingId: number): Observable<RestResult> {
    return this.http.delete<any>(
      Path.API_ENDPOINT +
      Path.MARKETING +
      Path.MARKETING_ID.replace(':marketingId', marketingId.toString()),
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  update(request: ProspectRequest): Observable<RestResult> {
    return this.http.put<any>(
      Path.API_ENDPOINT +
      Path.MARKETING, request).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  deleteNote(noteId: number): Observable<RestResult> {
    return this.http.delete<any>(
      Path.API_ENDPOINT +
      Path.MARKETING +
      Path.NOTE +
      Path.MARKETING_NOTE_ID.replace(':marketingNoteId', noteId.toString()),
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }
}
