import {NgModule} from '@angular/core';
import {
  NbAccordionModule,
  NbActionsModule,
  NbAutocompleteModule,
  NbButtonModule,
  NbCalendarModule,
  NbCalendarRangeModule,
  NbCardModule,
  NbCheckboxModule,
  NbDatepickerModule,
  NbDialogModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbListModule,
  NbPopoverModule,
  NbRadioModule,
  NbSelectModule,
  NbTabsetModule,
  NbTooltipModule,
} from '@nebular/theme';
import {Ng2SmartTableModule} from 'ng2-smart-table';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CalendarModule} from 'angular-calendar';
import {ContextMenuModule} from 'ngx-contextmenu';
import {ProspectDialogComponent} from './prospect-dialog/prospect-dialog.component';
import {ThemeModule} from '../../@theme/theme.module';
import {CommonModule} from '@angular/common';
import {ColorPaletteComponent} from './color-palette/color-palette.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {BuyerCreateComponent} from './buyer-create/buyer-create.component';
import {CustomerPickComponent} from './buyer-create/customer-pick/customer-pick.component';
import {ProspectPickComponent} from './buyer-create/prospect-pick/prospect-pick.component';
import {DistrictPickComponent} from './buyer-create/district-pick/district-pick.component';
import {ZonePickComponent} from './buyer-create/zone-pick/zone-pick.component';
import {MergeCustomersComponent} from './buyer-create/merge-customers/merge-customers.component';
import {
  ProspectPickFilterAdvanceComponent,
} from './buyer-create/prospect-pick/filter/prospect-pick-filter-advance.component';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {MoveCustomersComponent} from './buyer-create/move-customers/move-customers.component';
import {DirectivesModule} from '../directive/directives.module';
import {CopyCustomersComponent} from './buyer-create/copy-customers/copy-customers.component';
import {BuyerLookupComponent} from './buyer-create/buyer-lookup/buyer-lookup.component';
import {SalesDetailsComponent} from './prospect-dialog/sales-details/sales-details.component';
import {AttachmentComponent} from './attachment/attachment.component';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {CarouselModule} from 'ngx-bootstrap/carousel';
import {RentalDetailsComponent} from './prospect-dialog/rental-details/rental-details.component';
import {ProspectDialogV2Component} from './prospect-dialog-v2/prospect-dialog-v2.component';
import {ExportAddressComponent} from './export-address/export-address.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {TextFieldModule} from '@angular/cdk/text-field';
import {NgxVcardModule} from 'ngx-vcard';
import {MatDialogModule} from "@angular/material/dialog";
import { EditingDialogComponent } from './prospect-dialog-v2/editing-dialog/editing-dialog.component';

@NgModule({
  declarations: [
    ProspectDialogComponent,
    ColorPaletteComponent,
    BuyerCreateComponent,
    CustomerPickComponent,
    ProspectPickComponent,
    DistrictPickComponent,
    DistrictPickComponent,
    ZonePickComponent,
    MoveCustomersComponent,
    CopyCustomersComponent,
    MergeCustomersComponent,
    ProspectPickFilterAdvanceComponent,
    BuyerLookupComponent,
    SalesDetailsComponent,
    AttachmentComponent,
    RentalDetailsComponent,
    ProspectDialogV2Component,
    ExportAddressComponent,
    EditingDialogComponent,
  ],
  imports: [
    NgxVcardModule,
    NbCardModule,
    NbIconModule,
    NbInputModule,
    ThemeModule,
    Ng2SmartTableModule,
    CarouselModule,
    NbButtonModule,
    NbCheckboxModule,
    FormsModule,
    NbActionsModule,
    NbIconModule,
    NbCalendarModule,
    NbCalendarRangeModule,
    NbDatepickerModule,
    NbSelectModule,
    ReactiveFormsModule,
    NbFormFieldModule,
    NbAutocompleteModule,
    CalendarModule,
    NbLayoutModule,
    CommonModule,
    ContextMenuModule.forRoot({
      useBootstrap4: true,
    }),
    NbRadioModule,
    NbAccordionModule,
    NbTabsetModule,
    TypeaheadModule,
    NbListModule,
    NbTooltipModule,
    DragDropModule,
    DirectivesModule,
    NgxDropzoneModule,
    NbDialogModule,
    ClipboardModule,
    NbPopoverModule,
    TextFieldModule,
    NgxVcardModule,
    MatDialogModule,
  ],
  exports: [
    ProspectDialogComponent,
    ColorPaletteComponent,
    BuyerCreateComponent,
    ProspectPickFilterAdvanceComponent,
    AttachmentComponent,
    ProspectDialogV2Component,
  ],
})
export class SharedModule {
}
