import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Task} from '../../../../shared/models/response/task';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {LocalDataSource} from 'ng2-smart-table';
import {TaskCalendarStatusEditorComponent} from '../task-calendar/custom/task-calendar-status-editor.component';
import {Constant} from '../../../../shared/common/constant';

@Component({
  selector: 'ngx-task-childs',
  templateUrl: './task-childs.component.html',
  styleUrls: ['./task-childs.component.scss'],
})
export class TaskChildsComponent implements OnInit {

  source: LocalDataSource = new LocalDataSource();
  pageSize = Constant.PAGE_SIZE;

  settings = {
    mode: 'inline',
    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmCreate: true,
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmSave: true,
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
    },
    pager: {
      display: true,
      perPage: this.pageSize,
    },
    columns: {
      name: {
        title: 'Name',
        type: 'string',
      },
      description: {
        title: 'Description',
        type: 'string',
      },
      status: {
        title: 'Status',
        type: 'html',
        editor: {
          type: 'custom',
          component: TaskCalendarStatusEditorComponent,
        },
      },
    },
  };

  form: FormArray = new FormArray([]);
  count = 0;

  @Input()
  set subTasks(val: Task[]) {
    this.syncCurrentSubTaskToForm(val);
  }

  constructor() { }

  ngOnInit(): void {
  }

  syncCurrentSubTaskToForm(subTasks: Task[]) {
    if (!subTasks || subTasks.length <= 0) {
      return;
    }
    this.form = new FormArray([]);
    subTasks.sort((a: Task, b: Task) => {
      if (a.ordinal && b.ordinal) {
        return b.ordinal - a.ordinal;
      }
      return 0;
    }).forEach(task => {
      this.addForm(true, task);
    });
    this.source.load(this.form.value);
  }

  addForm(isSync: boolean, task?: Task) {
    this.form.push(new FormGroup({
      formId: new FormControl(this.count++),
      name: new FormControl(task?.name, [
        Validators.required,
        Validators.maxLength(200),
      ]),
      description: new FormControl(task?.description, [
        Validators.required,
      ]),
      status: new FormControl(task?.status, [
        Validators.required,
      ]),
    }));
    if (!isSync) {
      this.source.load(this.form.value);
    }
  }

  deleteRecord(event: any) {
    for (const control of this.form.controls) {
      if (control instanceof FormGroup) {
        if (control.controls.formId.value === event.data.formId) {
          this.form.removeAt(this.form.controls.indexOf(control));
        }
      }
    }
    this.source.load(this.form.value);
  }

  editRecord(event: any) {
    const newData = event.newData;
    for (const control of this.form.controls) {
      if (control instanceof FormGroup) {
        if (control.controls.formId.value === newData.formId) {
          control.setValue(newData);
        }
      }
    }
    event.confirm.resolve(newData);
  }

  addRecord(event: any) {
    const newData = event.newData;
    event.confirm.resolve(newData);
    this.addForm(true, newData);
  }
}
