import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {ViewCell} from 'ng2-smart-table';

@Component({
  template: `
      <p *ngIf="displayOtm">
        <span class="{{rowData?.data?.otmDayDiff < 90 ? 'active' : 'expired'}}">
          {{rowData?.data?.otmDayDiff ? rowData?.data?.otmDayDiff + " d" : ""}}
        </span>
      </p>
      <p *ngIf="displayVac">
        <span class="{{rowData?.data?.vacDayDiff < 21 ? 'active' : 'expired'}}">
          {{rowData?.data?.vacDayDiff ? rowData?.data?.vacDayDiff + " d" : ""}}
        </span>
      </p>
  `,
  styles: ['.active {color: green} .expired {color: #7d4439}'],
})
export class ProspectMarketCellComponent implements ViewCell, OnInit {

  @Input() value: string | number;
  @Input() rowData: any;
  displayOtm = false;
  displayVac = false;

  ngOnInit() {
  }
}
