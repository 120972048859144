import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { ProspectService } from '../../../services/prospect.service';
import {
  ProspectUtils,
  WrapProspect,
  WrapProspectType,
} from '../../../../modules/pages/prospect/common/prospect-utils';
import {
  ProspectListCustomCellComponent,
} from '../../../../modules/pages/prospect/common/prospect-list-custom-cell-component';
import { ProspectSelectComponent } from '../../../../modules/pages/prospect/common/prospect-select-component';
import { ToastService } from '../../../services/toast.service';
import { District } from '../../../models/response/address/district';
import { City } from '../../../models/response/address/city';
import { Street } from '../../../models/response/address/street';
import { ProspectSearchRequest } from '../../../models/request/prospect/prospect-search-request';
import { ProspectDatasource } from '../../../../modules/pages/prospect/common/prospect-datasource';
import { Prospect } from '../../../models/response/prospect/prospect';
import { ProspectGroupWrapper } from '../../../models/response/prospect/prospect-group-wrapper';
import { ProspectDialogComponent } from '../../prospect-dialog/prospect-dialog.component';
import { RestResult } from '../../../models/response/rest-result';
import { ProspectGroup } from '../../../models/response/prospect/prospect-group';
import { Constant } from '../../../common/constant';
import { AuthService } from '../../../services/auth.service';
import { User } from '../../../models/response/user';
import { DataSourceUtil } from '../../../models/utils/datasource-util';
import { CustomDatasource } from '../../../models/utils/custom-datasource';
import { CustomRow } from '../../../models/common/custom-row';
import { LocalService } from '../../../services/local.service';


@Component({
  selector: 'ngx-prospect-pick',
  templateUrl: './prospect-pick.component.html',
  styleUrls: ['./prospect-pick.component.scss'],
})

export class ProspectPickComponent implements OnInit {
  @Input() districts: District[];
  @Input() cities: City[];
  @Input() isBuyerMode: boolean;
  @Input() isTenantMode: boolean;
  @Output() reloadStreet: EventEmitter<any> = new EventEmitter<any>();

  // @Output() prospectChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() set streets(value: Street[]) {
    this._streets = value;
    if (this.searchRequest.districtId) {
      this.filteredStreets = this._streets.filter(x => x?.district?.districtId === this.searchRequest.districtId);
    }
  }

  @Input() set selectedMode(value: string) {
    this._selectedMode = value;
  }

  pickType: string;

  columnGroup = {
    PROPERTY: 'Property',
    CUSTOMER: 'Customer',
    CONTACT: 'Contact configuration',
    ADDRESS: 'Address',
    OTHERS: 'Others',
  };

  sortTypes = {
    ASC: 'ASC',
    DESC: 'DESC',
    ODD_ASC: 'ODD_ASC',
    ODD_DESC: 'ODD_DESC',
    EVEN_ASC: 'EVEN_ASC',
    EVEN_DESC: 'EVEN_DESC',
  };

  propertyTypeReplacement = {
    LAND_ONLY: 'L',
    UNIT: 'U',
    VILLA: 'V',
    PENTHOUSE: 'P',
    TOWN_HOUSE: 'T',
    HOUSE: 'H',
    HOUSE_GF: 'HG',
    DUPLEX: 'D',
    HOUSE_COM: 'HC',
  };
  otmStatuses = {
    EXPIRED: 'EXPIRED',
    ACTIVE: 'ACTIVE',
  };
  pageSize = Constant.PAGE_SIZE;
  currentPage = 1;
  searchRequest = new ProspectSearchRequest();
  change: any;
  source: ProspectDatasource;
  totalPage = 0;
  willBeDeletedProspectIds = [];
  willBeDeletedGroupIds = [];
  selectedProspect: Prospect;
  selectedProspects: Prospect[];
  groupWrapper: ProspectGroupWrapper;
  dialogFormRef: NbDialogRef<ProspectDialogComponent>;
  filteredDistricts: District[] = [];
  _streets: Street[];
  filteredStreets: Street[] = [];
  dialogService: NbDialogService;
  toastService: ToastService;
  prospectService: ProspectService;
  commonService: CommonService;
  authService: AuthService;
  _selectedMode: string;
  currentUser: User;

  indoorColumnsDefinition = {
    checkbox: {
      enable: true,
      value: this.createSelectColumn(''),
    },
    unit: {
      enable: true,
      value: ProspectUtils.createColumn('Unit', ['address.unit']),
      group: this.columnGroup.ADDRESS,
      name: 'Unit',
    },
    number: {
      enable: true,
      value: ProspectUtils.createColumn('Number', ['address.numberFrom']),
      group: this.columnGroup.ADDRESS,
      name: 'Number',
    },
    street: {
      enable: true,
      value: ProspectUtils.createColumn('Street', ['address.street.name']),
      group: this.columnGroup.ADDRESS,
      name: 'Street',
    },
    farm: {
      enable: false,
      value: ProspectUtils.createColumn('Farm', ['address.street.farm.name']),
      group: this.columnGroup.ADDRESS,
      name: 'Farm',
    },
    suburb: {
      enable: true,
      value: ProspectUtils.createColumn('Suburb', ['address.street.district.name']),
      group: this.columnGroup.ADDRESS,
      name: 'Suburb',
    },
    zone: {
      enable: false,
      value: ProspectUtils.createColumn('Zone', ['address.street.district.zone.name']),
      group: this.columnGroup.ADDRESS,
      name: 'Zone',
    },
    city: {
      enable: true,
      value: ProspectUtils.createColumn('Postcode', ['address.street.district.code']),
      group: this.columnGroup.ADDRESS,
      name: 'Postcode',
    },
    propertyType: {
      enable: false,
      value: ProspectUtils.createColumn('Type', ['property.type']),
      group: this.columnGroup.PROPERTY,
      name: 'Type',
    },
    propertyBed: {
      enable: false,
      value: ProspectUtils.createColumn('Bed', ['property.bed']),
      group: this.columnGroup.PROPERTY,
      name: 'Bed',
    },
    propertyBath: {
      enable: false,
      value: ProspectUtils.createColumn('Bath', ['property.bath']),
      group: this.columnGroup.PROPERTY,
      name: 'Bath',
    },
    propertyCar: {
      enable: false,
      value: ProspectUtils.createColumn('Car', ['property.car']),
      group: this.columnGroup.PROPERTY,
      name: 'Car',
    },
    propertyLand: {
      enable: false,
      value: ProspectUtils.createColumn('Land', ['property.land']),
      group: this.columnGroup.PROPERTY,
      name: 'Land',
    },
    propertySell: {
      enable: true,
      value: ProspectUtils.createColumn('Sell', ['property.sell']),
      group: this.columnGroup.PROPERTY,
      name: 'Sell',
    },
    propertySellPrice: {
      enable: true,
      value: ProspectUtils.createColumn('Sell Price', ['property.sellPrice'],
        ProspectListCustomCellComponent.VALUE_TYPES.CURRENCY),
      group: this.columnGroup.PROPERTY,
      name: 'Sell Price',
    },
    propertyRent: {
      enable: true,
      value: ProspectUtils.createColumn('Rent', ['property.rent']),
      group: this.columnGroup.PROPERTY,
      name: 'Rent',
    },
    propertyRentPrice: {
      enable: true,
      value: ProspectUtils.createColumn('Rent Price', ['property.rentPrice'],
        ProspectListCustomCellComponent.VALUE_TYPES.CURRENCY),
      group: this.columnGroup.PROPERTY,
      name: 'Rent Price',
    },
    propertyOtm: {
      enable: true,
      value: ProspectUtils.createColumn('Otm', ['property.otm']),
      group: this.columnGroup.PROPERTY,
      name: 'Otm',
    },
    propertyOtmDate: {
      enable: true,
      value: ProspectUtils.createColumn('Otm Date', ['property.otmDate'],
        ProspectListCustomCellComponent.VALUE_TYPES.DATE),
      group: this.columnGroup.PROPERTY,
      name: 'Otm Date',
    },
    propertyVac: {
      enable: true,
      value: ProspectUtils.createColumn('Vac', ['property.vac']),
      group: this.columnGroup.PROPERTY,
      name: 'Vac',
    },
    propertyVacDate: {
      enable: true,
      value: ProspectUtils.createColumn('Vac Date', ['property.vacDate'],
        ProspectListCustomCellComponent.VALUE_TYPES.DATE),
      group: this.columnGroup.PROPERTY,
      name: 'Vac Date',
    },
    customerName: {
      enable: true,
      value: ProspectUtils.createColumn('Name', ['customer.name']),
      group: this.columnGroup.CUSTOMER,
      name: 'Name',
    },
    customerSName: {
      enable: true,
      value: ProspectUtils.createColumn('Surname', ['customer.surname']),
      group: this.columnGroup.CUSTOMER,
      name: 'Surname',
    },
    phoneInfo: {
      enable: true,
      value: ProspectUtils.createColumn('Phone', ['customer.phone']),
      group: this.columnGroup.CONTACT,
      name: 'Phone',
    },
    mPhoneInfo: {
      enable: true,
      value: ProspectUtils.createColumn('Mobile Phone', ['customer.mobilePhone']),
      group: this.columnGroup.CONTACT,
      name: 'Mobile Phone',
    },
    callAllow: {
      enable: true,
      value: ProspectUtils.createColumn('Call', ['call']),
      group: this.columnGroup.CONTACT,
      name: 'Call',
    },
    smsAllow: {
      enable: true,
      value: ProspectUtils.createColumn('SMS', ['sms']),
      group: this.columnGroup.CONTACT,
      name: 'Sms',
    },
    doorAllow: {
      enable: true,
      value: ProspectUtils.createColumn('Door', ['door']),
      group: this.columnGroup.CONTACT,
      name: 'Door',
    },
    mailAllow: {
      enable: true,
      value: ProspectUtils.createColumn('Mail', ['mail']),
      group: this.columnGroup.CONTACT,
      name: 'Mail',
    },
    rate: {
      enable: true,
      value: ProspectUtils.createColumn('Rate', ['rate']),
      group: this.columnGroup.OTHERS,
      name: 'Rate',
    },
  };

  columnsDefinition: any = this.indoorColumnsDefinition;

  settings = {
    mode: 'external',
    hideSubHeader: true,
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    pager: {
      display: true,
      perPage: this.pageSize,
    },
    columns: this.createColumns(),
    rowClassFunction: (row) => {
      return this.highlightRow(row?.data);
    },
  };

  createSelectColumn(title: string) {
    return {
      title: title,
      type: 'custom',
      filter: false,
      renderComponent: ProspectSelectComponent,
      onComponentInitFunction: (instance: ProspectSelectComponent) => {
        instance.onSelectProspect.subscribe((value: WrapProspect) => {
          // this.checkProspectToRemove(value);
        });
      },
    };
  }

  createColumns() {
    const columns = {};
    for (const key of Object.keys(this.columnsDefinition)) {
      if (this.columnsDefinition[key].enable === true) {
        columns[key] = this.columnsDefinition[key].value;
      }
    }
    return columns;
  }

  isOpenHome: boolean;
  filteredPostcode: number;

  constructor(protected ref: NbDialogRef<ProspectPickComponent>,
              dialogService: NbDialogService,
              toastService: ToastService,
              prospectService: ProspectService,
              commonService: CommonService,
              authService: AuthService,
              private localService: LocalService) {
    this.dialogService = dialogService;
    this.toastService = toastService;
    this.prospectService = prospectService;
    this.commonService = commonService;
    this.authService = authService;
    this.searchRequest.sortType = this.sortTypes.ASC;
    this.source = new ProspectDatasource();
  }

  ngOnInit() {
    this.currentUser = this.authService.currentUser;
    this.source.onChanged().subscribe((change) => {
      this.change = change;
      if (change.action === 'refresh') {
        this.pageChange(1);
      }
      if (change.action === 'page') {
        this.pageChange(change.paging.page);
      }
      if (change.action === 'sort') {
        change.sort.forEach(s => {
          if (s.field === 'suburb') {
            this.pageChange(1);
          }
        });
      }
    });
  }

  select(event: any) {
    this.ref.close(event);
  }

  cancel() {
    this.ref.close();
  }

  // ==== table ====
  onColumnDisplayOptionCheckedChange(columnsDefinition: any) {
    if (columnsDefinition) {
      this.columnsDefinition = columnsDefinition;
      const setting = { ...this.settings };
      setting.columns = this.createColumns();
      this.settings = setting;
    }
  }

  onSuburbChange(districtId: number) {
    this.searchRequest.districtId = districtId;
    this.searchRequest.streetId = null;
    this.localService.streets(districtId).subscribe(result => {
      this.filteredStreets = result;
    });
  }

  onCityChange(cityId: number) {
    this.searchRequest.cityId = cityId;
    this.searchRequest.districtId = null;
    this.searchRequest.streetId = null;
    this.localService.districts(cityId).subscribe(result => {
      this.filteredDistricts = result;
    });
  }

  search() {
    this.pageChange(1);
  }

  pageChange(pageIndex) {
    if (this.isBuyerMode) {
      this.searchRequest.isBuyerMode = true;
    }
    if (this.isTenantMode) {
      this.searchRequest.isTenantMode = true;
    }
    if (this.isOpenHome) {
      this.searchRequest.isOpenHome = true;
    }
    this.willBeDeletedProspectIds = [];
    this.willBeDeletedGroupIds = [];
    this.currentPage = pageIndex;
    this.searchRequest.mode = this._selectedMode;
    this.searchRequest.offset = (pageIndex - 1) * this.pageSize;
    if (this.searchRequest.offset < 0) {
      this.searchRequest.offset = 0;
    }
    this.searchRequest.limit = this.pageSize;
    this.searchRequest.officeId = this.currentUser.office?.officeId;
    if (this.change.action === 'sort') {
      this.change.sort.forEach(s => {
        if (s.field === 'suburb') {
          if (s.direction === 'asc') {
            this.searchRequest.sortType = 'SUBURB_NAME_ASC';
          } else {
            this.searchRequest.sortType = 'SUBURB_NAME_DESC';
          }
        }
      });
    }

    this.prospectService.getGroupItemsSimple(this.searchRequest).subscribe((result: RestResult) => {
      this.selectedProspects = result.data;
      DataSourceUtil.reloadDataProspectListing(this.source, pageIndex, this.pageSize, result);
    });
  }

  selectRecord(event: any) {
    this.selectedProspect = event.data.data;
    this.select(this.selectedProspect);

  }

  highlightRow(data: any) {
    let result = '';
    if (data?.data?.property?.otm === true) {
      result += ' otm';
    } else if (data?.data?.property?.vac === true || data?.data?.property?.rent === true) {
      result += ' vac-rent';
    }
    result += ' called ';
    return result;
  }

  onSearchByPostcode(value: number) {
    if (value === 0) {
      this.searchRequest.postcode = undefined;
    } else {
      this.searchRequest.postcode = Number(value);
    }
    this.searchRequest.districtId = undefined;
    this.searchRequest.streetId = undefined;
    if (value && value !== 0) {
      this.localService.districtByPostcode(value).subscribe(result => {
        this.filteredDistricts = result;
      });
    }
  }
}
