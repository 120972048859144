import {User} from '../models/response/user';
import {ComponentPermission} from '../../component.permission';
import {UserDailyGoal} from '../models/response/user-daily-goal';
import {Constant} from './constant';

export class UserUtils {

  static currentUser(): User {
    const dataStr = localStorage.getItem(Constant.LS_CURRENT_USER_KEY);
    return JSON.parse(dataStr);
  }

  public static userIsAdmin(user: User): boolean {
    return user.aclActions
      && user.aclActions.findIndex(x => x.name === 'GLOBAL_FILTER_USER') >= 0;
  }

  public static userOverrideDailyGoal(user: User): boolean {
    return user.aclActions
      && user.aclActions.findIndex(x => x.name === 'CUSTOMER_ACCESS_ANYWHERE') >= 0;
  }

  public static setDailyGoal(totalCall?: number): UserDailyGoal {
    const currentUser = ComponentPermission.currentUser();
    const daily = new UserDailyGoal();
    const date = new Date();
    date.setHours(23, 0, 0, 0);
    daily.expireTime = date.getTime();
    daily.userId = currentUser.userId;
    daily.totalCall = totalCall ? totalCall : 0;
    if (UserUtils.userOverrideDailyGoal(ComponentPermission.currentUser())) {
      daily.isCompleted = true;
    } else {
      if (typeof currentUser.dailyGoal === 'undefined' || (currentUser.dailyGoal !== 0 && totalCall < currentUser.dailyGoal)) {
        daily.isCompleted = false;
      } else {
        daily.isCompleted = true;
      }
    }
    localStorage.setItem(Constant.LS_USER_DAILY_GOAL, JSON.stringify(daily));
    return daily;
  }

  public static getDailyGoal(): UserDailyGoal {
    const userDailyGoalString = localStorage.getItem(Constant.LS_USER_DAILY_GOAL);
    let userDailyGoal = null;
    if (!userDailyGoalString) {
      userDailyGoal = UserUtils.setDailyGoal();
    } else {
      userDailyGoal = JSON.parse(userDailyGoalString);
    }
    // reset if userId is not match
    if (userDailyGoal.userId !== UserUtils.currentUser().userId) {
      userDailyGoal = UserUtils.setDailyGoal();
    }
    return userDailyGoal;
  }

  public static clearDailyGoal(): void {
    localStorage.removeItem(Constant.LS_USER_DAILY_GOAL);
  }

  public static resetDailyGoal(isCompleted: boolean): void {
    const daily = UserUtils.getDailyGoal();
    daily.isCompleted = isCompleted;
    localStorage.setItem(Constant.LS_USER_DAILY_GOAL, JSON.stringify(daily));
  }
}
