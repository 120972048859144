<nb-card>
  <nb-card-header>
    <span>Export Data</span>
  </nb-card-header>
  <nb-card-body class='custom-padding'>
    <div>
      <div>
        <label class='label'>Recipients</label>
        <textarea nbInput fullWidth readonly [(ngModel)]='prospect.customer.mobilePhone' *ngIf='prospect?.customer?.mobilePhone !== ""'>
        </textarea>
<!--        <div class='text-center' *ngIf='data === ""'>-->
<!--          No data-->
<!--        </div>-->
<!--        <div class='d-flex justify-content-center mt-1'>-->
<!--          <button nbButton size='tiny' [cdkCopyToClipboard]='data' *ngIf='data !== ""' nbTooltip='Copied to clipboard'-->
<!--                  [nbTooltipTrigger]='trigger' [nbTooltipPlacement]='position'>Copy to clipboard-->
<!--          </button>-->
<!--        </div>-->
      </div>
      <hr>
      <div *ngIf='templates.length > 0' class='mt-2'>
        <label class='label'>Content</label>
        <nb-select fullWidth [(ngModel)]='selectedTemplate' (selectedChange)='onSelectTemplate($event)'>
          <nb-option *ngFor='let template of templates' [value]='template'>{{template.title}}</nb-option>
        </nb-select>
        <textarea nbInput fullWidth [(ngModel)]='selectedTemplate.message' rows='20'></textarea>
<!--        <div class='d-flex justify-content-center mt-1'>-->
<!--          <button nbButton size='tiny' [cdkCopyToClipboard]='selectedTemplate.message' nbTooltip='Copied to clipboard'-->
<!--                  [nbTooltipTrigger]='trigger' [nbTooltipPlacement]='position'>Copy to clipboard-->
<!--          </button>-->
<!--        </div>-->
      </div>
<!--      <div *ngIf='templates.length === 0'>-->
<!--        <div class='text-center'>-->
<!--          There is no template available. Do you want to <span class='create-one'-->
<!--                                                               (click)='createTemplate()'>create one?</span>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </nb-card-body>
  <nb-card-footer class='dialog-footer'>
    <button nbButton size='tiny' status='danger' (click)='cancel()' class='fa-pull-right'>Cancel</button>
    <button nbButton status='success' size='tiny' class='fa-pull-right mr-2'
            [disabled]='templates.length === 0'>
      <div class='no-css'>
        <a [href]='generateHref()'>
          Send
        </a>
      </div>
    </button>
  </nb-card-footer>
</nb-card>
