<div class="buyer-container">
  <nb-tab tabTitle="ALL" [active]="true">
    <div class="row">
      <div class="col-md-6 col-sm-12 left-panel mb-0 table-container"
           [ngClass]="{'popup-hide': isDisplay}">
        <nb-card>
          <nb-card-body>
            <ngx-buyer-list-filter-callbackdate class="z-index"
              [districts]="filteredDistricts"
              [zones]="filteredZones"
              [cities]="cities"
              [total]="groupWrapper?.totalItems"
              [selectedCityId]='searchRequest.cityId'
              [selectedZoneId]='searchRequest.zoneId'
              [selectedDistrictId]='searchRequest.districtId'
              [selectedYear]='selectedYear'
              [selectedMonth]='selectedMonth'
              [selectedQuarter]='selectedQuarter'
              [selectedWeek]='selectedWeek'
              [postcode]='searchRequest.postcode'
              (onYearChange)="onYearChange($event)"
              (onMonthChange)="onMonthChange($event)"
              (onQuarterChange)="onQuarterChange($event)"
              (onWeekChange)="onWeekChange($event)"
              (onCityChange)="onCityChange($event)"
              (onSuburbChange)="onSuburbChange($event)"
              (onZoneChange)="onZoneChange($event)"
              (onSearchByPostcode)='onSearchByPostcode($event)'
            ></ngx-buyer-list-filter-callbackdate>
            <div class="row mt-3">
              <div class="col-12">
                <ng2-smart-table [settings]="settings"
                                 [source]="source"
                                 (userRowSelect)="selectRecord($event); selectRow($event, buyerDialog)"
                >
                </ng2-smart-table>
              </div>
            </div>
          </nb-card-body>
        </nb-card>
      </div>
      <div class="col-md-6 col-sm-12 right-panel table-container"
           [ngClass]="{
       'popup-detail': isDisplay,
       'hide-detail': !isDisplay
       }">
        <ngx-buyer-create
          [cities]="cities"
          [taskTypes]="taskTypes"
          [taskStatuses]="taskStatuses"
          (navigateBuyerEvent)="navigateProspect($event)"
          (editDone)="editDone($event)"
          (newDone)="newDone($event)"
          (reserveBuyer)="reserveBuyer($event)"
          (ownSet)="ownSet($event)"
        ></ngx-buyer-create>
      </div>
      <div class='d-flex col-12'>
        <button *ngIf='isDialog'
                nbButton size='tiny' hero status='primary' class='fa-pull-right ml-2'
                (click)='closeDialog()'>Cancel
        </button>
      </div>
      <div class='scroll-mobile left-54' *ngIf='selectedBuyer && isDisplay'>
        <button (click)='cancel()' size='tiny' nbButton status='warning'>Cancel</button>
      </div>
    </div>
  </nb-tab>
</div>
