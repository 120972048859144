<div *appPermissionCheck="ComponentPermission.DASHBOARD_CALLBACK">
  <div class="col-12">
    <div class="col-12">
      <b>Callback Date View</b>
    </div>
  </div>
  <nb-tabset (changeTab)='onChangeTab($event)' fullWidth>
    <nb-tab tabTitle="CLIENT" [active]="currentTab === tabs.CLIENT">
      <ngx-prospect-list-callbackdate
      *ngIf='currentTab === tabs.CLIENT'></ngx-prospect-list-callbackdate>
    </nb-tab>
    <nb-tab tabTitle="CUSTOMER" [active]="currentTab === tabs.CUSTOMER">
      <ngx-buyer-list-callbackdate
        *ngIf='currentTab === tabs.CUSTOMER'></ngx-buyer-list-callbackdate>
    </nb-tab>
  </nb-tabset>
</div>
