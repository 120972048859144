<div class="cal-month-view">
  <ngx-custom-month-view-header
    [days]="columnHeaders"
    [locale]="locale"
    [customTemplate]="headerTemplate">
  </ngx-custom-month-view-header>
  <div class="cal-days">
    <div *ngFor="let rowIndex of view.rowOffsets; trackBy: trackByRowOffset">
      <div class="cal-cell-row">
        <div class="week-no">
          <div class="col-12 text-center">
            <b class="no-week-text">{{getNoWeek(getFirstDateOfWeek(rowIndex, view.totalDaysVisibleInWeek, view.days))}}</b>
          </div>
          <div class="col-12 text-center">
            <table class="statistic-table">
              <tr>
                <td class="td-sc">SC: {{filterStatistic(rowIndex, 'SC')}}</td>
                <td class="td-bc">BC: {{filterStatistic(rowIndex, 'BC')}}</td>
                <td class="td-rc">RC: {{filterStatistic(rowIndex, 'RC')}}</td>
                <td class="td-tc">TC: {{filterStatistic(rowIndex, 'TC')}}</td>
              </tr>
              <tr>
                <td class="td-sa">SA: {{filterStatistic(rowIndex, 'SA')}}</td>
                <td class="td-ba">BA: {{filterStatistic(rowIndex, 'BA')}}</td>
                <td class="td-ra">RA: {{filterStatistic(rowIndex, 'RA')}}</td>
                <td class="td-ta">TA: {{filterStatistic(rowIndex, 'TA')}}</td>
              </tr>
              <tr>
                <td class="td-exc">EXC: {{filterStatistic(rowIndex, 'EXC')}}</td>
                <td class="td-dep">DEP: {{filterStatistic(rowIndex, 'DEP')}}</td>
                <td class="td-rm">RM: {{filterStatistic(rowIndex, 'RM')}}</td>
                <td class="td-tap">TAP: {{filterStatistic(rowIndex, 'TAP')}}</td>
              </tr>
              <tr>
                <td class="td-ls">LS: {{filterStatistic(rowIndex, 'LS')}}</td>
                <td class="td-s">S: {{filterStatistic(rowIndex, 'S')}}</td>
                <td class="td-rr">RR: {{filterStatistic(rowIndex, 'RR')}}</td>
                <td class="td-ten">TEN: {{filterStatistic(rowIndex, 'TEN')}}</td>
              </tr>
            </table>
          </div>
        </div>
        <mwl-calendar-month-cell
          class="custom-cell"
          *ngFor="let day of (view.days | slice : rowIndex : rowIndex + (view.totalDaysVisibleInWeek)); trackBy:trackByDate"
          [ngClass]="day?.cssClass"
          [day]="day"
          [openDay]="openDay"
          [locale]="locale"
          [tooltipPlacement]="tooltipPlacement"
          [tooltipAppendToBody]="tooltipAppendToBody"
          [tooltipTemplate]="tooltipTemplate"
          [customTemplate]="cellTemplate"
          (mwlClick)="dayClicked.emit({ day: day })"
          (highlightDay)="toggleDayHighlight($event.event, true)"
          (unhighlightDay)="toggleDayHighlight($event.event, false)"
          mwlDroppable
          dragOverClass="cal-drag-over"
          (drop)="eventDropped(day, $event.dropData.event, $event.dropData.draggedFrom)"
          (eventClicked)="eventClicked.emit({event: $event.event})">
        </mwl-calendar-month-cell>
      </div>
      <mwl-calendar-open-day-events
        [isOpen]="openRowIndex === rowIndex"
        [events]="openDay?.events"
        [customTemplate]="openDayEventsTemplate"
        [eventTitleTemplate]="eventTitleTemplate"
        [eventActionsTemplate]="eventActionsTemplate"
        (eventClicked)="eventClicked.emit({event: $event.event})"
        mwlDroppable
        dragOverClass="cal-drag-over"
        (drop)="eventDropped(openDay, $event.dropData.event, $event.dropData.draggedFrom)">
      </mwl-calendar-open-day-events>
    </div>
  </div>
</div>
