<nb-card *appPermissionCheck="ComponentPermission.COMPANY_BOARD">
  <nb-card-header>
    <ngx-company-board-filter-advance
      [otmTab]="isOTM"
      [rentTab]="isRent"
      (onRent)="onRent()"
      (onOTM)="onOTM()"
    ></ngx-company-board-filter-advance>
  </nb-card-header>
  <nb-card-body>
    <table *ngIf="companyBoards != null">
      <tr>
        <td class="tdValue" *ngFor="let label of levelLabels">{{label}}</td>
      </tr>
      <tr *ngFor="let companyBoard of companyBoards; let i = index">
        <td class="tdValue">{{i + 1}}</td>
        <td class="tdValue">{{companyBoard.name}}</td>
        <td class="load-prospect"
            (click)="loadProspect(1, companyBoard.companyId)"
        >
          {{companyBoard.rateLevel1 ? companyBoard.rateLevel1 : 0}}
        </td>
        <td class="load-prospect"
            (click)="loadProspect(2, companyBoard.companyId)"
        >
          {{companyBoard.rateLevel2 ? companyBoard.rateLevel2 : 0}}
        </td>
        <td class="load-prospect"
            (click)="loadProspect(3, companyBoard.companyId)"
        >
          {{companyBoard.rateLevel3 ? companyBoard.rateLevel3 : 0}}
        </td>
        <td class="load-prospect"
            (click)="loadProspect(99, companyBoard.companyId)"
        >
          {{companyBoard.total}}
        </td>
      </tr>
    </table>
  </nb-card-body>
</nb-card>

<div class="fake-tab content-active" [hidden]="!isDisplayed">
  <ngx-prospect-list-basic
    [isFilter]=false
    [isLoad]=false
    [districts]="districts"
    [cities]="cities"
    [streets]="streets"
    [companies]="companies"
    [taskTypes]="taskTypes"
    (reloadStreet)="loadStreets()"
  ></ngx-prospect-list-basic>
</div>
