<nb-tabset (changeTab)='onChangeTab($event)' fullWidth *appPermissionCheck='ComponentPermission.CLIENT_LIST'>
  <nb-tab tabTitle='{{tabs.OUTDOOR}}' [active]='currentTab === tabs.OUTDOOR'
          *appPermissionCheck='ComponentPermission.CLIENT_OUTDOOR'>
    <ngx-prospect-list-basic
      *ngIf='currentTab === tabs.OUTDOOR'
      [selectedMode]="'OUTDOOR'"
      [isFilter]=true
      [isLoad]=true
      [cities]='cities'
      [companies]='companies'
      [taskTypes]='taskTypes'
      [taskStatuses]='taskStatuses'
      (reloadStreet)='loadStreets()'
    ></ngx-prospect-list-basic>
  </nb-tab>
  <nb-tab tabTitle='{{tabs.MARKET}}' [active]='currentTab === tabs.MARKET'
          *appPermissionCheck='ComponentPermission.CLIENT_MARKET'>
    <ngx-prospect-list-market
      *ngIf='currentTab === tabs.MARKET'
      [selectedMode]="'MARKET'"
      [isFilter]=true
      [isLoad]=true
      [districts]='districts'
      [cities]='cities'
      [streets]='streets'
      [companies]='companies'
      [taskTypes]='taskTypes'
      [taskStatuses]='taskStatuses'
      (reloadStreet)='loadStreets()'
    ></ngx-prospect-list-market>
  </nb-tab>
  <nb-tab tabTitle='{{tabs.ALL}}' [active]='currentTab === tabs.ALL'
          *appPermissionCheck='ComponentPermission. CLIENT_ALL'>
    <ngx-prospect-list-advance
      *ngIf='currentTab === tabs.ALL'
      [selectedMode]="'ALL'"
      [cities]='cities'
      [companies]='companies'
      [taskTypes]='taskTypes'
      [taskStatuses]='taskStatuses'
      (reloadStreet)='loadStreets()'
    ></ngx-prospect-list-advance>
  </nb-tab>
</nb-tabset>
<div class='row daily'>
  <div class='daily-pc'>
    <div class='set-height d-flex'>
      <div class='ml-3'>
        <button nbButton status='primary' size='tiny' class='sticky-button'
                [nbPopover]='templateRef' [nbPopoverTrigger]='noop'
                (click)='getDailyStatistic()'>Daily
        </button>
      </div>
      <ng-template #templateRef>
        <p class='mb-0'>SN: {{dailyStatistic?.totalSN}}</p>
        <p class='mb-0'>SC: {{dailyStatistic?.totalSC}}</p>
      </ng-template>
    </div>
  </div>
</div>

