import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { City } from '../../../../../shared/models/response/address/city';
import { District } from '../../../../../shared/models/response/address/district';
import { Street } from '../../../../../shared/models/response/address/street';
import { Company } from '../../../../../shared/models/response/prospect/company';
import { Constant } from '../../../../../shared/common/constant';
import { DateUtils } from '../../../../../shared/common/date-utils';
import { Observable } from 'rxjs';
import { Postcode } from '../../../../../shared/models/response/postcode';
import { PostcodeService } from '../../../../../shared/services/postcode.service';
import { ActivatedRoute } from '@angular/router';
import { SearchRequest } from '../../../../../shared/models/request/search-request';
import { map, mergeMap } from 'rxjs/operators';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { StringUtils } from '../../../../../shared/common/string-utils';

@Component({
  selector: 'ngx-prospect-list-filter-purchasedate',
  templateUrl: './prospect-list-filter-purchasedate.component.html',
  styleUrls: ['./prospect-list-filter-purchasedate.component.scss'],
})
export class ProspectListFilterPurchasedateComponent implements OnInit {
  @Input() districts: District[];
  @Input() districtId: number;
  @Input() streets: Street[];
  @Input() streetId: number;
  @Input() cities: City[];
  @Input() cityId: number;
  @Input() total: number;
  @Input() farm: string;
  @Input() zone: string;
  @Output() onSuburbChange = new EventEmitter();
  @Output() onStreetChange = new EventEmitter();
  @Output() onCityChange = new EventEmitter();
  @Output() onAddNewProspect = new EventEmitter();
  @Output() onOpenShortcut = new EventEmitter();
  @Output() onBedChange = new EventEmitter();
  @Output() onBathChange = new EventEmitter();
  @Output() onCarChange = new EventEmitter();
  @Output() onSearch = new EventEmitter();
  @Output() onMonthChange = new EventEmitter();
  @Output() onDayChange = new EventEmitter();
  @Output() onRateChange = new EventEmitter();

  propertyTypes = Constant.PROPERTY_TYPE;
  bedValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  months = DateUtils.MONTHS;
  days: number[];
  @Input() selectedMonth: any;
  @Input() selectedDay: any;
  @Input() rate: any;
  rateValue = [0, 1, 2, 3, 4, 5, 6, 7];
  @Input() postcode: number;
  @Output() onSearchByPostcode = new EventEmitter();
  filterPostcode$: Observable<Postcode[]>;
  filterByPostcode = false;

  constructor(private postcodeService: PostcodeService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.initPurchaseDate();
    this.filterPostcode$ = new Observable((observer: any) => {
      observer.next(this.postcode);
    }).pipe(
      map((query: string) => {
        this.postcode = Number(query);
        this.searchSuburbByPostcode();
        return null;
      }),
    );
    if (this.route.snapshot.queryParams['postcode']) {
      this.filterByPostcode = true;
    }
  }

  onSuburbSelectChange() {
    this.onSuburbChange.emit(isNaN(Number(this.districtId)) ? undefined : Number(this.districtId));
  }

  onStreetSelectChange() {
    this.onStreetChange.emit(isNaN(Number(this.streetId)) ? undefined : Number(this.streetId));
  }

  onCitySelectChange() {
    this.onCityChange.emit(isNaN(Number(this.cityId)) ? undefined : Number(this.cityId));
  }

  onMonthSelectChange() {
    this.selectedDay = null;
    if (this.selectedMonth === 'undefined') {
      this.onMonthChange.emit(0);
    } else {
      const year = new Date().getFullYear();
      this.days = [];
      const startDay = 1;
      const endDay = new Date(year, this.selectedMonth, 0).getDate();
      for (let i = startDay; i <= endDay; i++) {
        this.days.push(i);
      }
      this.onMonthChange.emit(this.selectedMonth);
    }
  }

  onDaySelectChange() {
    if (this.selectedDay === 'undefined') {
      this.onDayChange.emit(0);
    } else {
      this.onDayChange.emit(this.selectedDay);
    }
  }

  onRateSelectChange() {
    if (this.rate !== 'undefined') {
      this.onRateChange.emit(this.rate);
    } else {
      this.onRateChange.emit(-1);
    }
  }

  // =======
  keepOrder(a, b) {
    return 1;
  }

  initPurchaseDate() {
    if (this.selectedMonth) {
      const year = new Date().getFullYear();
      this.days = [];
      const firstDay = 1;
      const endDay = new Date(year, this.selectedMonth, 0).getDate();
      for (let i = firstDay; i < endDay; i++) {
        this.days.push(i);
      }
    }
  }

  searchPostcode(keyword: string): Observable<Postcode[]> {
    const searchRequest = new SearchRequest();
    searchRequest.conditions = [];
    searchRequest.orders = [];
    searchRequest.conditions.push(
      {
        left: 'code',
        middle: '=',
        right: keyword,
      },
    );
    searchRequest.orders.push(
      {
        left: 'code',
        right: 'asc'
      }
    );
    searchRequest.offset = 0;
    searchRequest.limit = 10;
    return this.postcodeService.search(searchRequest).pipe(
      map(result => result.data),
    );
  }

  selectPostcode(type: TypeaheadMatch) {
    this.postcode = Number(type.item.code);
    this.searchSuburbByPostcode();
  }

  searchSuburbByPostcode() {
    if (this.postcode === null) {
      return;
    }
    this.filterByPostcode = true;
    this.onSearchByPostcode.emit(this.postcode);
  }
}
