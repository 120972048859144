<google-map [center]="centerPosition" [zoom]="zoom" width="100%" height="70vh">
  <map-marker
    #marker
    *ngFor="let markerPosition of markers; let i = index"
    [position]="markerPosition.latlng"
    [options]="markerPosition.options"
    (mapClick)="openInfoWindow(marker, i)"
  ></map-marker>
  <map-info-window>
    <div *ngIf="selectMarkerInfo">
      <p><strong>{{selectMarkerInfo.address}}</strong></p>
      <div class="row">
        <div class="col">
          Phone:
        </div>
        <div class="col">
          {{selectMarkerInfo.phone}}
        </div>
      </div>
      <div class="row">
        <div class="col">
          Mobile phone:
        </div>
        <div class="col">
          {{selectMarkerInfo.mobilePhone}}
        </div>
      </div>
    </div>
  </map-info-window>
</google-map>
