<div class="row mt-2">
<!--  <div class="col-3">-->
<!--    <nb-select fullWidth size="tiny" [(ngModel)]="searchRequest.sortType">-->
<!--      <nb-option *ngFor="let item of sortTypesText | keyvalue"-->
<!--                 value="{{item.key}}">{{item.value}}</nb-option>-->
<!--    </nb-select>-->
<!--  </div>-->
  <div class="col-3">
    <nb-select fullWidth size="tiny"
               placeholder="All streets"
               [(ngModel)]="searchRequest.streetId"
               [disabled]="!searchRequest?.districtId"
    >
      <nb-option [value]="">All streets</nb-option>
      <nb-option *ngFor="let key of streets"
                 [ngClass]="key.status === 'DEACTIVE' ? 'deactive-status' : ''"
                 [value]="key.streetId">{{key.name}}</nb-option>
    </nb-select>
  </div>
  <div class="col-3">
    <nb-select fullWidth size="tiny"
               placeholder="All suburbs"
               [(ngModel)]="searchRequest.districtId"
               [disabled]="!postcode">
      <nb-option [value]="" (click)="onSuburbSelectChange()">All suburbs</nb-option>
      <nb-option *ngFor="let key of districts"
                 [ngClass]="key.status === 'DEACTIVE' ? 'deactive-status' : ''"
                 [value]="key.districtId"
                 (click)="onSuburbSelectChange()"
      >{{key.name}}</nb-option>
    </nb-select>
  </div>
  <div class="col-3">
    <nb-form-field>
      <input nbInput fullWidth placeholder='Postcode' fieldSize='tiny' class='override-nbinput-height'
             [(ngModel)]='postcode'
             [typeahead]='filterPostcode$'
             [ngModelOptions]='{standalone: true}'
             [typeaheadAsync]='true'
             (typeaheadOnSelect)='selectPostcode($event)'
             typeaheadOptionField='code'
             typeaheadMinLength='0'
             typeaheadWaitMs='1000'>
      <!--      <span nbSuffix (click)='searchSuburbByPostcode()'><i class='fa fa-search'></i></span>-->
    </nb-form-field>
  </div>
  <div class="col-3">
    <input type="text" nbInput fieldSize="tiny" fullWidth name="phone" placeholder="Phone / Mobile" [(ngModel)]="searchRequest.phone"/>
  </div>
</div>

<div class="row mt-2">
  <div class="col-12">
    <button nbButton size="tiny" hero status="info" class="fa-pull-right" (click)="search()" >Search</button>
  </div>
</div>
<div class="row mt-2">
  <div class="col-sm-12">
    <nb-accordion>
      <nb-accordion-item>
        <nb-accordion-item-header>Display options</nb-accordion-item-header>
        <nb-accordion-item-body>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <p class="label">Address</p>
                <nb-checkbox
                  *ngFor="let item of columnsDefinition | keyvalue"
                  [hidden]="item?.value?.group !== columnGroup.ADDRESS"
                  class="diplay-option mr-1 mb-2"
                  [checked]="item?.value?.enable"
                  (checkedChange)="onColumnDisplayOptionCheckedChange($event, item.key)"
                >{{item?.value?.name}}</nb-checkbox>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <p class="label">Property</p>
                <nb-checkbox
                  class="diplay-option mr-1 mb-2"
                  *ngFor="let item of columnsDefinition | keyvalue"
                  [hidden]="item?.value?.group !== columnGroup.PROPERTY"
                  [checked]="item?.value?.enable"
                  (checkedChange)="onColumnDisplayOptionCheckedChange($event, item.key)"
                >{{item?.value?.name}}</nb-checkbox>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <p class="label">Customer</p>
                <nb-checkbox
                  class="diplay-option mr-1 mb-2"
                  *ngFor="let item of columnsDefinition | keyvalue"
                  [hidden]="item?.value?.group !== columnGroup.CUSTOMER"
                  [checked]="item?.value?.enable"
                  (checkedChange)="onColumnDisplayOptionCheckedChange($event, item.key)"
                >{{item?.value?.name}}</nb-checkbox>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <p class="label">Contact</p>
                <nb-checkbox
                  class="diplay-option mr-1 mb-2"
                  *ngFor="let item of columnsDefinition | keyvalue"
                  [hidden]="item?.value?.group !== columnGroup.CONTACT"
                  [checked]="item?.value?.enable"
                  (checkedChange)="onColumnDisplayOptionCheckedChange($event, item.key)"
                >{{item?.value?.name}}</nb-checkbox>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <p class="label">Others</p>
                <nb-checkbox
                  class="diplay-option mr-1"
                  *ngFor="let item of columnsDefinition | keyvalue"
                  [hidden]="item?.value?.group !== columnGroup.OTHERS"
                  [checked]="item?.value?.enable"
                  (checkedChange)="onColumnDisplayOptionCheckedChange($event, item.key)"
                >{{item?.value?.name}}</nb-checkbox>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <button nbButton size="tiny" hero status="primary" class="fa-pull-right" (click)="applyColumnDisplayOptionChange()">Apply</button>
            </div>
          </div>
        </nb-accordion-item-body>
      </nb-accordion-item>
    </nb-accordion>
  </div>
</div>
<div class="row mt-3 pl-4 pr-4">
  <div class="col-4">
    <p class="label">Total</p>
    <p>{{total}}</p>
  </div>
</div>
