export class AddressRequest {
  addressId?: number;
  number?: string;
  unit?: string;
  units?: string;
  streetId?: number;
  longitude?: number;
  latitude?: number;
  formattedAddress?: string;
  numberFrom?: string;
  numberFromLetter?: string;
  numberTo?: string;
  numberToLetter?: string;
  numberUnit?: string;
  officeId?: number;
  additionalInfo?: string;
  postCode?: number;
  districtId?: number;

  static getDummy = () => {
    return {
      addressId: '',
      number: '',
      numberFrom: '',
      numberFromLetter: '',
      numberTo: '',
      numberToLetter: '',
      numberUnit: '',
      unit: '',
      streetId: '',
      longitude: '',
      latitude: '',
      formattedAddress: '',
      additionalInfo: '',
    };
  }
}
