import {Component, OnInit} from '@angular/core';
import {Address} from '../../../models/response/address/address';
import {ProspectSearchRequest} from '../../../models/request/prospect/prospect-search-request';
import {AuthService} from '../../../services/auth.service';
import {User} from '../../../models/response/user';
import {RestResult} from '../../../models/response/rest-result';
import {ProspectService} from '../../../services/prospect.service';
import {Prospect} from '../../../models/response/prospect/prospect';
import {SmartTableUtils} from '../../../../modules/pages/buyer/common/smart-table-utils';
import {Constant} from '../../../common/constant';
import {LocalDataSource} from 'ng2-smart-table';
import {CustomRow} from '../../../models/common/custom-row';
import {CustomerService} from '../../../services/customer.service';
import {CustomerRequest} from '../../../models/request/prospect/customer-request';
import {CommonService} from '../../../services/common.service';
import {CustomerLinkRequest} from '../../../models/request/prospect/customer-link-request';
import {NbDialogRef} from '@nebular/theme';

@Component({
  selector: 'ngx-buyer-lookup',
  templateUrl: './buyer-lookup.component.html',
  styleUrls: ['./buyer-lookup.component.scss']
})
export class BuyerLookupComponent implements OnInit {

  address: Address;
  searchRequest = new ProspectSearchRequest();
  currentUser: User;
  buyerCustomer: CustomerRequest;
  target: Prospect;
  source: LocalDataSource = new LocalDataSource();
  pageSize = Constant.PAGE_SIZE;
  currentPage = 1;
  columnsDefinition = {
    prospectId: {
      enable: true,
      value: SmartTableUtils.createColumn('Id', ['prospectId']),
    },
    name: {
      enable: true,
      value: SmartTableUtils.createColumn('Name', ['customer.name']),
    },
    surname: {
      enable: true,
      value: SmartTableUtils.createColumn('SName', ['customer.surname']),
    },
    mobilePhone: {
      enable: true,
      value: SmartTableUtils.createColumn('Mobile', ['customer.mobilePhone']),
    },
    phone: {
      enable: true,
      value: SmartTableUtils.createColumn('Phone', ['customer.phone']),
    },
    email: {
      enable: true,
      value: SmartTableUtils.createColumn('Email', ['customer.email']),
    },
    dob: {
      enable: true,
      value: SmartTableUtils.createColumn('DOB', ['customer.dob']),
    },
  };

  settings = {
    selectedRowIndex: -1,
    mode: 'external',
    hideSubHeader: true,
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    pager: {
      display: true,
      perPage: this.pageSize,
    },
    columns: SmartTableUtils.createColumns(this.columnsDefinition),
  };

  constructor(private authService: AuthService,
              private prospectService: ProspectService,
              private customerService: CustomerService,
              private commonService: CommonService,
              private ref: NbDialogRef<any>) {
  }

  ngOnInit(): void {
    this.currentUser = this.authService.currentUser;
    this.loadData();
  }

  loadData() {
    this.searchRequest.officeId = this.currentUser.office?.officeId;
    this.searchRequest.streetId = this.address.streetId;
    this.searchRequest.districtId = this.address.districtId;
    this.searchRequest.cityId = this.address.cityId;
    if (this.address.numberFrom && !this.address.number) {
      this.address.number = this.address.numberFrom;
    }
    this.searchRequest.number = this.address.number;
    this.prospectService.searchByAddress(this.searchRequest).subscribe((result: RestResult) => {
      if (result.data) {
        this.target = result.data;
        const data = [];
        data.push(new CustomRow(result.data, false, false));
        this.source.load(data);
      }
    });
  }

  copy() {
    const request = new CustomerRequest();
    request.name = this.buyerCustomer.name;
    request.surname = this.buyerCustomer.surname;
    request.phone = this.buyerCustomer.phone;
    request.mobilePhone = this.buyerCustomer.mobilePhone;
    request.email = this.buyerCustomer.email;
    request.dob = this.buyerCustomer.dob;
    if (this.target.customer) {
      request.customerId = this.target.customer.customerId;
    }
    this.prospectService.createCustomer(this.target.prospectId, request).subscribe(result => {
      this.commonService.info('Copied info to prospect!');
      this.ref.close();
    });
  }

  trimObject(obj: any, properties: string[]): any {
    const curentProperties: string[] = Object.keys(obj);
    for (const name of curentProperties) {
      if (properties.indexOf(name) === -1) {
        delete obj[name];
      }
    }
  }

  link() {
    const request = new CustomerLinkRequest();
    let ids = ';';
    ids += this.buyerCustomer.customerId.toString();
    ids += ';';
    ids += this.target.customer.customerId.toString();
    ids += ';';
    request.linkedIds = ids;
    this.customerService.linkCustomer(request).subscribe(result => {
      this.commonService.info('Linked to prospect !');
      this.ref.close();
    });
  }
}
