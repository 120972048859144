import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { WrapProspect } from './prospect-utils';
import { StringUtils } from '../../../../shared/common/string-utils';
import { Prospect } from '../../../../shared/models/response/prospect/prospect';
import { ProspectDialogComponent } from '../../../../shared/module/prospect-dialog/prospect-dialog.component';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { Router } from '@angular/router';

@Component({
  template: `
    <div>{{renderValue}}</div>
  `,
  styles: [],
})
export class VisualBoardAddressCellComponent implements ViewCell, OnInit {

  renderValue: string;
  selectedProspect: Prospect;
  param: {};
  dialogFormRef: NbDialogRef<ProspectDialogComponent>;

  constructor(private router: Router,
              private dialogService: NbDialogService) {
  }

  @Input() value: string | number;
  @Input() rowData: WrapProspect;
  StringUtils = StringUtils;

  ngOnInit() {
    this.setValue();
  }

  setValue(): any {
    if (!this.rowData) {
      this.renderValue = this.value ? this.value.toString() : '';
      return;
    }
    switch (this.rowData.type) {
      case 'GROUP':
        const prospectGroup = this.rowData.groupData;
        this.renderValue = `(${prospectGroup?.totalItems}) ${prospectGroup?.name}`;
        break;
      case 'ITEM':
        const prospectItem = this.rowData.data;
        // this.renderValue = `${prospectItem?.address?.unit ? prospectItem?.address?.unit + '/' : ''}`
        //  + ` ${prospectItem?.address?.number}`
        //  + `, ${prospectItem?.address?.street?.name}`;
        this.renderValue = `${StringUtils.getShortAddress(prospectItem?.address)}`;
        break;
    }
  }


  // selectRecord(openEditDialog: boolean) {
  //   this.selectedProspect = this.rowData.data;
  //   // this.param = { ...this.param, selectedProspectId: this.selectedProspect.prospectId };
  //   // this.navigateWithParam();
  //   if (openEditDialog) {
  //     this.editRecord(this.selectedProspect);
  //   }
  // }
  //
  // navigateWithParam() {
  //   this.router.navigate([], {
  //     queryParams: this.param,
  //   });
  // }

  // editRecord(prospect: Prospect) {
  //   this.dialogFormRef = this.dialogService.open(ProspectDialogComponent, {
  //     context: {
  //       selectedProspect: prospect,
  //       taskTypes: this.taskTypes,
  //       taskStatuses: this.taskStatuses,
  //       companies: this.companies,
  //       isModal: true,
  //       onClose: (result) => {
  //         if (this.dialogFormRef) {
  //           this.dialogFormRef.close(result);
  //         }
  //       },
  //     },
  //     dialogClass: 'modal-pop-up',
  //   });
  // }
}
