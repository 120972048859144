import {Component, Input, OnInit} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Constant} from '../../../../../shared/common/constant';
import {FormService} from '../../../../../shared/services/form.service';
import {CommonService} from '../../../../../shared/services/common.service';
import {Zone} from '../../../../../shared/models/response/address/zone';
import {SuburbService} from '../../../../../shared/services/suburb.service';
import {City} from '../../../../../shared/models/response/address/city';
import {ComponentPermission} from '../../../../../component.permission';

@Component({
  selector: 'ngx-district-create',
  templateUrl: './district-create.component.html',
  styleUrls: ['./district-create.component.scss'],
})
export class DistrictCreateComponent implements OnInit {
  ComponentPermission = ComponentPermission;
  form: FormGroup;
  isSubmitted = false;
  color: any;

  @Input()
  cities: City[];

  constructor(protected ref: NbDialogRef<DistrictCreateComponent>,
              private suburbService: SuburbService,
              private commonService: CommonService,
              private formService: FormService) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.form = new FormGroup({
      name: new FormControl('', [
        Validators.required,
      ]),
      code: new FormControl('', [
        Validators.required,
      ]),
      // cityId: new FormControl('', [
      //   Validators.required,
      // ]),
      color: new FormControl(''),
      status: new FormControl('ACTIVE'),
    });
  }

  submit() {
    const self = this;
    const form = this.form.value;
    this.formService.extractErrorMessage(this.form);
    if (this.form.invalid) {
      this.isSubmitted = true;
      return;
    }
    form.createdDate = this.commonService.parseDate(form.createdDate, 'DD/MM/YYYY');
    this.suburbService.create(form).subscribe(
      result => {
        this.commonService.info('Created success.');
        this.ref.close(result);
      },
      error => {
        this.commonService.warningHtml(error.message);
      },
    );
  }

  cancel() {
    this.ref.close();
  }


  get STATUS() {
    return Constant.STATUS;
  }

  showError(name: string): string {
    return this.form.controls[name].errors
    && (this.form.controls[name].dirty
      || this.form.controls[name].touched
      || this.isSubmitted) ? 'has-error' : '';
  }

  colorChange(colorValue: string) {
    this.form.controls.color.setValue(colorValue);
  }
}
