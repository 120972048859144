export enum SmsContent {
  CLIENT_NAME = '[CLIENT_NAME]',
  CLIENT_ADDRESS = '[CLIENT_ADDRESS]',
  ASKING_PRICE_FROM = '[ASKING_PRICE_FROM]',
  RENT_PRICE_FROM = '[RENT_PRICE_FROM]',
  ASKING_PRICE_TO = '[ASKING_PRICE_TO]',
  RENT_PRICE_TO = '[RENT_PRICE_TO]',
  EMPLOYEE_NAME = '[AGENT_NAME]',
  EMPLOYEE_TITLE = '[AGENT_TITLE]',
  EMPLOYEE_PHONE = '[AGENT_PHONE]',
}
