<nb-card *appPermissionCheck="ComponentPermission.TASK_TYPE_CREATE">
  <nb-card-header>
    <span>Add new task type</span>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="form">
      <div class="form-group" [ngClass]="showError('name')">
        <label class="label">Name</label>
        <input type="text" nbInput fullWidth formControlName="name" name="name">
      </div>

      <div class="form-group" [ngClass]="showError('code')">
        <label class="label">Description</label>
        <textarea rows="5" nbInput
                  fullWidth shape="round"  placeholder="Text Area" formControlName="description"></textarea>
      </div>
      <div class="form-group">
        <label class="label">Category</label>
        <nb-select formControlName="taskTypeCategoryId" fullWidth placeholder="">
          <nb-option [value]="">Empty</nb-option>
          <nb-option *ngFor="let item of categories" [value]="item.taskTypeCategoryId">{{item.name}}</nb-option>
        </nb-select>
      </div>
      <div class="form-group">
        <label class="label">Color</label>
        <div class="col-12 mt-2 text-center">
          <ngx-color-palette
            [defaultColor]="form.controls?.color?.value"
            (colorChange)="colorChange($event)"
          ></ngx-color-palette>
        </div>
      </div>
    </form>
  </nb-card-body>
  <nb-card-footer class="dialog-footer">
    <button class="cancel" nbButton status="danger" (click)="cancel()">Cancel</button>
    <button nbButton status="success" (click)="submit()">Save</button>
  </nb-card-footer>
</nb-card>
