import {NgModule} from '@angular/core';
import {
  NbButtonModule,
  NbCardModule,
  NbMenuModule,
  NbPopoverModule,
  NbToggleModule
} from '@nebular/theme';

import {ThemeModule} from '../../@theme/theme.module';
import {PagesRoutingModule} from './pages-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {LoginModule} from './login/login.module';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import {PhoneDialogComponent} from './phone-dialog/phone-dialog.component';
import {OverviewModule} from './overview/overview.module';
import {AppSidebarModule} from '@coreui/angular';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { MarketingScheduleComponent } from './management/marketing-schedule/marketing-schedule.component';
import { ManagementComponent } from './management/management.component';

@NgModule({
  imports: [
    PagesRoutingModule,
    ThemeModule,
    NbMenuModule,
    NbToggleModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    FormsModule,
    NbPopoverModule,
    LoginModule,
    ReactiveFormsModule,
    NbCardModule,
    NbButtonModule,
    OverviewModule,
    AppSidebarModule,
    DragDropModule,
  ],
  declarations: [
    ConfirmDialogComponent,
    PhoneDialogComponent,
    ManagementComponent,
  ],
})
export class PagesModule {
}
