<div class="container">
  <div class="body">
    <h1 class="error-code">{{title}}</h1>
    <button class="btn btn-sm btn-start mb-5" (click)="backClicked()">
      Back
    </button>
    <hr/>
    <img class="error-image" src="./assets/images/{{errorCode}}.png">
  </div>
</div>
