<form [formGroup]='buyerForm' class='custom-form no-scroll-x' *ngIf='buyerForm'>
  <nb-card class='buyer-block'>
    <!-- Rate Section-->
    <div class='rate-block-level top-nav-background'>
      <ul class='left-rate-block clearfix mb-0'>
        <li class='top-header-block top-header-button'>
          <button nbTooltip='Tenant' nbButton size='tiny'
                  class="col-6 market-t {{buyerForm.controls?.tenant?.value === true ? 'market-active' : 'market-inactive'}}"
                  hero (click)="setSquareValue('tenant')">T
          </button>
        </li>
        <li class='top-header-block top-header-button'>
          <button nbTooltip='Buyer' nbButton size='tiny'
                  class="col-6 market-b {{buyerForm.controls?.buyer?.value === true ? 'market-active' : 'market-inactive'}}"
                  hero (click)="setSquareValue('buyer')">B
          </button>
        </li>
        <li class='top-header-block top-header-status'>
          <select size='tiny'
                  formControlName='isActive'
                  class='width100'
          >
            <option *ngFor='let key of activeType'
                    [value]='key'
            >{{key}}</option>
          </select>
        </li>

        <li class='top-header-block top-header-wot'>
          <span nbTooltip='Waste of Time' class='status-state'>WOT</span>
          <span nbTooltip='Waste of Time' class='status-active'
                (click)="setWot('isWot')"
                *ngIf='buyerForm.controls?.isWot.value'>
                  <nb-icon [icon]="'checkmark-outline'"
                           pack='eva'>
                  </nb-icon>
                </span>
          <span nbTooltip='Waste of Time' class='status-active'
                (click)="setWot('isWot')"
                *ngIf='!buyerForm.controls.isWot.value'></span>
        </li>

      </ul>
      <ul class='right-rate-block clearfix mb-0'
          [ngClass]="{
         'buyer-blue': buyerForm.controls?.buyerCode.value === 'S',
         'buyer-green': buyerForm.controls?.buyerCode.value === 'R',
         'buyer-blue2': buyerForm.controls?.buyerCode.value === 'O',
         'buyer-green2': buyerForm.controls?.buyerCode.value === 'V',
         'buyer-black': buyerForm.controls?.buyerCode.value === 'T'
         }">
        <li>
          <p nbButton size='tiny'>
            {{buyerForm.controls?.buyerCode.value}}
          </p>
        </li>
        <li>
          <p nbButton size='tiny'>{{buyerForm.controls?.rate?.value}}</p>
        </li>
        <li>
          <p nbButton size='tiny'>
            {{code1}}
          </p>
        </li>
        <li>
          <p nbButton size='tiny'>
            {{code2}}
          </p>
        </li>
        <li>
          <p nbButton size='tiny'>
            {{code3}}
          </p>
        </li>
        <li>
          <p nbButton size='tiny'>
            {{code4}}
          </p>
        </li>
        <li class='total-link pt-1' *ngIf='linkedGroup?.length !== 0'>
          Total linked: {{totalLinked}}
        </li>
      </ul>
    </div>

    <!-- Customer Section-->
    <div class='row f-body m-0'>
      <div class='col-4 p-0'>
        <div class='row buyer-count-block market-block'
             *ngIf='buyerForm.controls?.tenant?.value !== true'>
          <button nbTooltip='Is Value Buyer' nbButton size='tiny'
                  class="col-6 market-t {{buyerForm.controls?.isValueBuyer?.value === true ? 'buyer-activate' : 'buyer-inactive'}}"
                  hero (click)="setBuyerType('isValueBuyer')">VB
          </button>
          <button nbTooltip='Is Investor' nbButton size='tiny'
                  class="col-6 market-t {{buyerForm.controls?.isInvestor?.value === true ? 'buyer-activate' : 'buyer-inactive'}}"
                  hero (click)="setBuyerType('isInvestor')">I
          </button>
          <button nbTooltip='Is First Home' nbButton size='tiny'
                  class="col-6 market-t {{buyerForm.controls?.isFirstHome?.value === true ? 'buyer-activate' : 'buyer-inactive'}}"
                  hero (click)="setBuyerType('isFirstHome')">F
          </button>
          <button nbTooltip='Is Developer' nbButton size='tiny'
                  class="col-6 market-t {{buyerForm.controls?.isDeveloper?.value === true ? 'buyer-activate' : 'buyer-inactive'}}"
                  hero (click)="setBuyerType('isDeveloper')">D
          </button>
        </div>
      </div>
      <div class='col-2 p-0 square-container square-market'>
        <div class='col-12 p-0'>
          <p nbTooltip='Common'
             class="h-50 margin buyer-button non-select {{buyerForm.controls?.isCommon?.value === true ? 'common-active' : 'buyer-inactive'}}"
             (click)="setBuyerAttribute('isCommon')">C</p>
        </div>
        <div class='col-12 p-0 non-select' (click)='toggleDisplayCommon()'>
          <div
            class='h-50 margin buyer-details-button'>
            Details
          </div>
        </div>
      </div>
      <div class='col-2 p-0 square-container square-market'>
        <div class='col-12 p-0'>
          <p nbTooltip='Address'
             class="h-50 margin buyer-button non-select {{buyerForm.controls?.isAddress?.value === true ? 'address-active' : 'buyer-inactive'}}"
             (click)="setBuyerAttribute('isAddress')">A</p>
        </div>
        <div class='col-12 p-0 non-select' (click)='toggleDisplayAddress()'>
          <div
            class='h-50 margin buyer-details-button'>
            Details
          </div>
        </div>
      </div>
      <div class='col-2 p-0 square-container square-market'>
        <div class='col-12 p-0'>
          <p nbTooltip='Number'
             class="h-50 margin buyer-button non-select {{buyerForm.controls?.isNumber?.value === true ? 'number-active' : 'buyer-inactive'}}"
             (click)="setBuyerAttribute('isNumber')">N</p>
        </div>
        <div class='col-12 p-0 non-select' (click)='toggleDisplayNumber()'>
          <div
            class='h-50 margin buyer-details-button'>
            Details
          </div>
        </div>
      </div>
      <div class='col-2 p-0 square-container square-market'>
        <div class='col-12 p-0'>
          <p nbTooltip='Inspection'
             class="h-50 margin buyer-button non-select {{buyerForm.controls?.isInspection?.value === true ? 'inspection-active' : 'buyer-inactive'}}"
             (click)="setBuyerAttribute('isInspection')">I</p>
        </div>
        <div class='col-12 p-0 non-select' (click)='toggleDisplayInspection()'>
          <div
            class='h-50 margin buyer-details-button'>
            Details
          </div>
        </div>
      </div>
      <!--Right Block-->
      <!--            <div class='col-4 square-container square-market'>-->
      <!--              <p class='buyer-button enq-active'>ENQ</p>-->
      <!--            </div>-->
    </div>
    <div class='row f-body m-0 text-center'>
      <div class='col-12'>
        <div class='row buyer-count-block market-block mt-2 mb-2'>
          <!--             *ngIf='buyerForm.controls?.tenant?.value !== true'>-->
          <div class='col-6'>
            <div class='row'>
              <div class='col-4'>
                <button nbTooltip='Mail?' nbButton size='tiny'
                        class="col-6 market-t {{buyerForm.controls?.mail?.value === true ? 'buyer-activate' : 'buyer-inactive'}}"
                        hero (click)="setSquareValue('mail')">M
                </button>
              </div>
              <div class='col-4'>
                <button nbTooltip='Call?' nbButton size='tiny'
                        class="col-6 market-t {{buyerForm.controls?.call?.value === true ? 'buyer-activate' : 'buyer-inactive'}}"
                        hero (click)="setSquareValue('call')">C
                </button>
              </div>
              <div class='col-4'>
                <button nbTooltip='Sms?' nbButton size='tiny'
                        class="col-6 market-t {{buyerForm.controls?.sms?.value === true ? 'buyer-activate' : 'buyer-inactive'}}"
                        hero (click)="setSquareValue('sms')">S
                </button>
              </div>
            </div>
          </div>
          <div class='col-6'>
            <div class='row'>
              <div class='col-8'></div>
              <div class='col-4 square-container square-market'>
                <div class='row'>
                  <div nbTooltip='ENQ Details'
                       class='buyer-details-button custom-details-button buyer-button-extend'
                       (click)='toggleDisplayENQ()'>
                    <div class='mt-1 non-select'>ENQ</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class='row m-0 pl-2 pr-2 enq-freeze no-scroll-x f-body' [hidden]='!showENQ'>
      <!--Left Block-->
      <div class='col-12 mt-2'>
        <!--        <div class='row'>-->
        <!--          <div class='col-6'>-->
        <!--            <input-->
        <!--              [(ngModel)]='officeName'-->
        <!--              [ngModelOptions]='{standalone: true}'-->
        <!--              [typeahead]='filterOffice$'-->
        <!--              [typeaheadAsync]='true'-->
        <!--              (typeaheadOnSelect)='selectOffice($event)'-->
        <!--              typeaheadOptionField='name'-->
        <!--              typeaheadWaitMs='1000'-->
        <!--              placeholder='Office'-->
        <!--              nbInput fullWidth-->
        <!--            />-->
        <!--          </div>-->
        <!--        </div>-->
        <div class='row mt-2'>
          <div class='col-6'>
            <input type='text' nbInput fieldSize='tiny' fullWidth name='name'
                   placeholder='Name' formControlName='name'
                   [ngClass]="showError('name')">
          </div>
          <div class='col-6'>
            <input type='text' nbInput fieldSize='tiny' fullWidth name='surname'
                   placeholder='Surname' formControlName='surname'
                   [ngClass]="showError('surname')">
          </div>
          <!--          <div class='col-1'>-->
          <!--            <span class='ml-1 status-search' (click)='selectBuyer()'>-->
          <!--              <nb-icon class='mt-1' [icon]="'search-outline'" pack='eva'></nb-icon>-->
          <!--            </span>-->
          <!--          </div>-->
        </div>
        <div class='row mt-2'>
          <!--          <div class='col-6 pl-3'>-->
          <!--            <div class='row pointer same-phone' (click)="selectPhone('PHONE')" *ngIf='phoneDuplicate'>-->
          <!--              <div class='col-6'>-->
          <!--                <p *ngIf='phoneDuplicate.totalClient !== 0'>Clients : {{phoneDuplicate.totalClient}}</p>-->
          <!--              </div>-->
          <!--              <div class='col-6'>-->
          <!--                <p *ngIf='phoneDuplicate.totalCustomer !== 0'>Customers-->
          <!--                  : {{phoneDuplicate.totalCustomer}}</p>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <div class='col-10 property-type-container'>-->
          <!--            <input type='text' nbInput fieldSize='tiny'-->
          <!--                   fullWidth name='phone' formControlName='phone' placeholder='Phone'-->
          <!--                   [ngClass]="showError('phone')">-->
          <!--            <a nbButton size='tiny' class='btn-call ml-1' hero-->
          <!--               [disabled]="!buyerForm.controls.phone.value || buyerForm.controls.phone.value === '' || buyerForm?.controls?.rate?.value > 3"-->
          <!--               href='tel:{{buyerForm.controls.phone.value}}'-->
          <!--               (click)='lastCalledPhoneNumber = buyerForm.controls.phone.value'-->
          <!--            >-->
          <!--              CALL-->
          <!--            </a>-->
          <!--          </div>-->
          <div class='col-md-6 col-xl-6 d-flex'>
            <nb-form-field class='w-100'>
              <input type='text' nbInput fieldSize='tiny'
                     fullWidth name='mobilePhone' formControlName='mobilePhone'
                     placeholder='Mobile Phone'
                     [ngClass]="{
         'text-danger': buyerForm.controls?.call?.value !== false,
         'text-dark': buyerForm.controls?.call?.value !== true,
         'showErrow': showError('mobilePhone')
         }">
              <span class='phone-button' nbSuffix nbTooltip='Add Phone' (click)='addPhoneNumber("MPHONE")'>+</span>
            </nb-form-field>

            <a *ngIf='buyerForm.controls?.call?.value !== true' nbButton size='tiny' class='btn-call ml-1' hero
               [disabled]="!buyerForm.controls.mobilePhone.value || buyerForm.controls.mobilePhone.value === '' || buyerForm?.controls?.rate?.value > 3"
               href='tel:{{buyerForm.controls.mobilePhone.value}}'
               (click)='lastCalledPhoneNumber = buyerForm.controls.mobilePhone.value'>
              CALL
            </a>
            <div class='col-1 p-0 pr-4'>
            <span class='status-search' (click)='selectBuyer()'>
              <nb-icon class='mt-1 ml-2' [icon]="'search-outline'" pack='eva'></nb-icon>
            </span>
            </div>
          </div>
          <div class='col-xl-6 col-md-6 col-5 text-center'>
            <div class='row' *ngIf='mobiPhoneDuplicate'>
              <div class='col-6'>
                <div class='mt-1 pointer' nbTooltip='Client' *ngIf='mobiPhoneDuplicate.totalClient !== 0'>
                  <i (click)="searchDuplicatePhone('Client')" class='fa fa-home'></i>
                  : {{mobiPhoneDuplicate.totalClient}}</div>
              </div>
              <div class='col-6'>
                <div class='mt-1 pointer' nbTooltip='Customers' *ngIf='mobiPhoneDuplicate.totalCustomer !== 0'>
                  <i (click)="searchDuplicatePhone('Customer')" class='fa fa-user'></i>
                  : {{mobiPhoneDuplicate.totalCustomer}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class='row' *ngIf='additionalInfo.length > 0'>
          <div class='col-lg-6 col-sm-12 p-1'>
            <div class='w-100 mt-2' *ngFor='let info of additionalInfo, index as index'>
              <div class='d-flex w-100' *ngIf='!info.isDeleted'>
                <nb-form-field class='w-100'>
                  <input [(ngModel)]='info.phoneNumber' nbInput fullWidth fieldSize='tiny'
                         placeholder='Sub Mobile Number'
                         [ngModelOptions]='{standalone: true}' *ngIf='!info.isDeleted'>
                  <span class='phone-button' nbSuffix nbTooltip='Remove Number'
                        (click)='removePhoneNumber(info, index)'>-</span>
                </nb-form-field>
                <button nbButton size='tiny' status='primary' (click)='setPrimary(info, index)'>Primary</button>
              </div>
            </div>
          </div>
        </div>
        <div class='row mt-2'>
          <div class='col-12 property-type-container'>
            <input type='text' nbInput fieldSize='tiny' fullWidth name='email'
                   class='col-8 col-lg-8 col-xl-6'
                   formControlName='email'
                   placeholder='Email' [ngClass]="{
         'text-danger': buyerForm.controls?.mail?.value !== false,
         'text-dark': buyerForm.controls?.mail?.value !== true,
         'showErrow': showError('email')
         }">
            <a *ngIf='buyerForm.controls?.mail?.value !== true' nbButton size='tiny' class='btn-call ml-1' hero
               [disabled]="!buyerForm.controls.email.value || buyerForm.controls.email.value === '' || buyerForm?.controls?.rate?.value > 3"
               href='mailTo:{{buyerForm.controls.email.value}}'
               (click)='lastCalledPhoneNumber = buyerForm.controls.email.value'>
              MAIL
            </a>
          </div>
          <!--          <div class='col-2 square-container square-market'>-->

          <!--                              <div class='row market-block' *ngIf='buyerForm.controls?.tenant?.value !== true'>-->
          <!--                                <div>-->
          <!--                                  <button nbTooltip='On The Market' nbButton size='tiny'-->
          <!--                                          class="col-6  market-o {{buyerForm.controls?.otm?.value === true ? 'market-active' : 'market-left-inactive'}}"-->
          <!--                                          hero (click)="setSquareValue('otm')">O-->
          <!--                                  </button>-->
          <!--                                  <button nbTooltip='VAC' nbButton size='tiny'-->
          <!--                                          class="col-6 market-v {{buyerForm.controls?.vac?.value === true ? 'market-active' : 'market-right-inactive'}}"-->
          <!--                                          hero (click)="setSquareValue('vac')">V-->
          <!--                                  </button>-->
          <!--                                  <button nbTooltip='Sell' nbButton size='tiny'-->
          <!--                                          class="col-6 market-s {{buyerForm.controls?.sell?.value === true ? 'market-active' : 'market-left-inactive'}}"-->
          <!--                                          hero (click)="setSquareValue('sell')">S-->
          <!--                                  </button>-->
          <!--                                  <button nbTooltip='Rent' nbButton size='tiny'-->
          <!--                                          class="col-6 market-r {{buyerForm.controls?.rent?.value === true ? 'market-active' : 'market-right-inactive'}}"-->
          <!--                                          hero (click)="setSquareValue('rent')">R-->
          <!--                                  </button>-->
          <!--                                </div>-->
          <!--                              </div>-->
          <!--            <div class='row buyer-count-block mt-2 market-block'-->
          <!--                 *ngIf='buyerForm.controls?.tenant?.value !== true'>-->
          <!--              <button nbTooltip='Mail?' nbButton size='tiny'-->
          <!--                      class="col-3 market-t {{buyerForm.controls?.mail?.value === true ? 'contact-active' : 'contact-inactive'}}"-->
          <!--                      hero (click)="setSquareValue('mail')">M-->
          <!--              </button>-->
          <!--              <button nbTooltip='Door?' nbButton size='tiny'-->
          <!--                      class="col-3 market-t {{buyerForm.controls?.door?.value === true ? 'contact-active' : 'contact-inactive'}}"-->
          <!--                      hero (click)="setSquareValue('door')">D-->
          <!--              </button>-->
          <!--              <button nbTooltip='Call?' nbButton size='tiny'-->
          <!--                      class="col-3 market-t {{buyerForm.controls?.call?.value === true ? 'contact-active' : 'contact-inactive'}}"-->
          <!--                      hero (click)="setSquareValue('call')">C-->
          <!--              </button>-->
          <!--              <button nbTooltip='SMS?' nbButton size='tiny'-->
          <!--                      class="col-3 market-t {{buyerForm.controls?.sms?.value === true ? 'contact-active' : 'contact-inactive'}}"-->
          <!--                      hero (click)="setSquareValue('sms')">S-->
          <!--              </button>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
        <div class='row mt-2'>
          <div class='col-8 property-type-container'>
            <input type='text' nbInput fieldSize='tiny' fullWidth
                   formControlName='enqAddress'
                   (click)='selectAddress()'
                   placeholder='Enq Address'>
          </div>
          <div class='col-4 property-type-container'>
            <nb-form-field>
              <input nbTooltip="Enq Date : {{buyerForm.controls?.enqDate1?.value | date:'dd/MM/yyyy'}}"
                     [nbDatepicker]='datepickerEnqDate' nbInput fieldSize='tiny'
                     fullWidth formControlName='enqDate1' placeholder='Enq date'/>
              <nb-datepicker #datepickerEnqDate></nb-datepicker>
            </nb-form-field>
          </div>
        </div>
        <div class='row mt-2'>
          <div class='col-12 p-0 property-type-container'>
            <div class='col-xl-6 col-12'>
              <input
                [(ngModel)]='categoryName'
                [ngModelOptions]='{standalone: true}'
                [typeahead]='filterCategory$'
                [typeaheadAsync]='true'
                (typeaheadOnSelect)='selectCategory($event)'
                typeaheadOptionField='name'
                typeaheadWaitMs='1000'
                placeholder='Tag'
                nbInput fieldSize='tiny' fullWidth>
            </div>
            <!--            <button nbButton fullWidth class='date-picker-button'-->
            <!--                    (click)='triggerDatePicker(callbackDate)'>-->
            <!--              <input [nbDatepicker]='callbackDate' nbInput fieldSize='tiny'-->
            <!--                     fullWidth formControlName='callbackDate' placeholder='Callback Date'-->
            <!--                     disabled />-->
            <!--            </button>-->
            <!--            <nb-datepicker #callbackDate></nb-datepicker>-->

          </div>
          <div class='col-lg-6 col-12 d-flex'>
            <div *ngFor='let category of selectedCategories' class='category-block row mr-1'>
              {{category.name}}
              <button class='btn btn-danger btn-sm' (click)='removeCategory(category)'>
                <i class='fa fa-times' aria-hidden='true'></i>
              </button>
            </div>
          </div>
          <div class='col-12 mt-2'>
            <!--            <span class="textarea resize-textarea text-area-without-border-radius" role="textbox" contenteditable-->
            <!--                  nbInput-->
            <!--                  formControlName='note' [ngClass]="showError('note')"></span>-->
            <textarea contenteditable rows='3' class='resize-textarea text-area-without-border-radius'
                      nbInput cdkTextareaAutosize cdkAutosizeMinRows="1"
                      cdkAutosizeMaxRows="5" fullWidth shape='round' placeholder='Note'
                      formControlName='note' [ngClass]="showError('note')"></textarea>
            <div class="app-speech-input" [class.app-speech-input-listening]="listening">
              <button class="app-speech-input-btn" [disabled]="listening" (click)="listen()"></button>
            </div>
          </div>
        </div>
        <div class='row p-0 mt-2 mb-2' [hidden]='!data || !data.buyerId'>
          <div class='col w-20 text-center'>
            <button nbTooltip='Call' nbButton size='tiny' class='btn-c color' hero
                    (click)='logContactStatus(contactLogType.CALLED)'>CALL
            </button>
          </div>
          <div class='col w-20 text-center'>
            <button nbTooltip='Not Available' nbButton size='tiny' class='btn-na' hero
                    (click)='logContactStatus(contactLogType.NO_ANSWER)'>NA
            </button>
          </div>
          <div class='col w-20 text-center'>
            <button nbTooltip='SMS' nbButton size='tiny' class='btn-dkaa' hero
                    (click)='logContactStatus(contactLogType.SMS)'>SMS
            </button>
          </div>
          <div class='col w-20 text-center'>
            <button nbTooltip='Appointment' nbButton size='tiny' class='btn-app' hero
                    (click)='createTask()'>APP
            </button>
          </div>
          <div class='col w-20 text-center'>
            <button nbTooltip='History' nbButton size='tiny' class='btn-log' hero
                    (click)='toggleDisplayHistory()'>History
            </button>
          </div>
        </div>
        <!--          <div class='col-4 property-type-container'>-->
        <!--            <nb-form-field>-->
        <!--              <input nbTooltip="First Enq Date : {{buyerForm.controls?.firstEnqDate?.value | date:'dd/MM/yyyy'}}"-->
        <!--                     [nbDatepicker]='datepickerFirstEnqDate' nbInput fieldSize='tiny'-->
        <!--                     fullWidth formControlName='firstEnqDate' placeholder='First Enq date' />-->
        <!--              <nb-datepicker #datepickerFirstEnqDate></nb-datepicker>-->
        <!--            </nb-form-field>-->
        <!--          </div>-->
      </div>
      <!--        <div class='row mt-2'>-->
      <!--          <div class='col-12 property-type-container mt-2'>-->
      <!--            <div class='form-group width100'>-->
      <!--              <nb-form-field>-->
      <!--                <button nbButton ghost fullWidth class='date-picker-button'-->
      <!--                        (click)='triggerDatePicker(datepickerDOB)'>-->
      <!--                  <input [nbDatepicker]='datepickerDOB' nbInput fieldSize='tiny'-->
      <!--                         fullWidth placeholder='D.O.B' formControlName='dob' disabled>-->
      <!--                </button>-->
      <!--                <button *ngIf='data.buyerId' nbSuffix nbButton ghost (click)="createDOBTask('BIRTHDAY_CALL')">-->
      <!--                  <nb-icon [icon]="'plus-outline'"-->
      <!--                           pack='eva'>-->
      <!--                  </nb-icon>-->
      <!--                </button>-->
      <!--              </nb-form-field>-->
      <!--              <nb-datepicker #datepickerDOB></nb-datepicker>-->
      <!--              <span [hidden]="!dobText || dobText === ''">{{dobText}}</span>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div class='row'>-->
      <!--          <div class='col-12 property-type-container text-center'>-->
      <!--            <div class='w-100' *ngIf='isEditing'>-->
      <!--              <input type='text' nbInput fieldSize='tiny' fullWidth name='link'-->
      <!--                     placeholder='Link' formControlName='link' [ngClass]="showError('link')" *ngIf='isEditing'>-->
      <!--            </div>-->
      <!--            <div class='w-100' *ngIf='!isEditing'>-->
      <!--              <a href='{{data.customer.link}}' *ngIf='!isEditing' target='_blank'>{{data.customer.link}}</a>-->
      <!--            </div>-->
      <!--            <button type='button' class='btn btn-info btn-sm mr-2 float-right' (click)='isEditing = !isEditing'-->
      <!--                    *ngIf='!isEditing'>Edit-->
      <!--            </button>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--Non Buyer-->
      <div *ngIf='buyerForm.controls.tenant.value'>
        <!-- City-->
        <div class='row w-100 mt-2'>
          <div class="col-4 property-type-container">
            <nb-form-field class="w-100">
              <input nbInput fullWidth placeholder='Postcode' fieldSize='tiny' class='override-nbinput-height'
                     [(ngModel)]='postcode'
                     [typeahead]='filterPostcode$'
                     [ngModelOptions]='{standalone: true}'
                     [typeaheadAsync]='true'
                     (typeaheadOnSelect)='selectPostcode($event)'
                     typeaheadOptionField='code'
                     typeaheadMinLength='3'
                     typeaheadWaitMs='1000'
                     [value]="postcode? postcode: null">
              <!--      <span nbSuffix (click)='searchSuburbByPostcode()'><i class='fa fa-search'></i></span>-->
            </nb-form-field>
          </div>
          <!-- SUBURB -->
          <div class='col-4 property-type-container'>
            <select size='tiny'
                    formControlName='districtId'
                    [disabled]='!postcode'
                    class='width100'
                    (change)='onSuburbSelectChange(buyerForm.controls?.districtId?.value)'
            >
              <option [ngValue]='null'>All Suburbs</option>
              <option *ngFor='let key of filteredDistricts'
                      [value]='key.districtId'
              >{{key.name}}</option>
            </select>
          </div>
          <!-- STREET -->
          <div class='col-4 property-type-container'>
            <select fullWidth
                    formControlName='streetId'
                    [disabled]='!buyerForm.controls?.districtId?.value'
                    (change)='onStreetSelectChange(buyerForm.controls?.streetId?.value)'
                    class='width100'
            >
              <option [ngValue]='null'>All Streets</option>
              <option *ngFor='let key of filteredStreets'
                      [value]='key.streetId'
              >{{key.name}}</option>
            </select>
          </div>
        </div>
        <div class='row mt-2'>
          <!-- NUMBER -->
          <div class='col-12 property-type-container'>
            <input type='text' nbInput fieldSize='tiny' name='unit' placeholder='Unit'
                   formControlName='unit' class='mr-1'>
            &nbsp;/&nbsp;
            <input type='number' nbInput fieldSize='tiny' name='numberFrom' placeholder='From'
                   formControlName='numberFrom' class='mr-1'>
            <input type='text' nbInput fieldSize='tiny' fullWidth name='numberFromLetter' placeholder='Letter'
                   formControlName='numberFromLetter'>
            &nbsp;-&nbsp;
            <input type='text' nbInput fieldSize='tiny' name='numberTo' placeholder='To'
                   formControlName='numberTo' class='mr-1'>
            <input type='text' nbInput fieldSize='tiny' fullWidth name='numberToLetter' placeholder='Letter'
                   formControlName='numberToLetter'>
          </div>
        </div>
      </div>
      <!--Right Block-->
      <!--                <div class='col-2 square-container square-market'>-->

      <!--                  <div class='row market-block' *ngIf='buyerForm.controls?.tenant?.value !== true'>-->
      <!--                    <div>-->
      <!--                      <button nbTooltip='On The Market' nbButton size='tiny'-->
      <!--                              class="col-6  market-o {{buyerForm.controls?.otm?.value === true ? 'market-active' : 'market-left-inactive'}}"-->
      <!--                              hero (click)="setSquareValue('otm')">O-->
      <!--                      </button>-->
      <!--                      <button nbTooltip='VAC' nbButton size='tiny'-->
      <!--                              class="col-6 market-v {{buyerForm.controls?.vac?.value === true ? 'market-active' : 'market-right-inactive'}}"-->
      <!--                              hero (click)="setSquareValue('vac')">V-->
      <!--                      </button>-->
      <!--                      <button nbTooltip='Sell' nbButton size='tiny'-->
      <!--                              class="col-6 market-s {{buyerForm.controls?.sell?.value === true ? 'market-active' : 'market-left-inactive'}}"-->
      <!--                              hero (click)="setSquareValue('sell')">S-->
      <!--                      </button>-->
      <!--                      <button nbTooltip='Rent' nbButton size='tiny'-->
      <!--                              class="col-6 market-r {{buyerForm.controls?.rent?.value === true ? 'market-active' : 'market-right-inactive'}}"-->
      <!--                              hero (click)="setSquareValue('rent')">R-->
      <!--                      </button>-->
      <!--                    </div>-->
      <!--                  </div>-->
      <!--                  <div class='row buyer-count-block mt-2 market-block'-->
      <!--                       *ngIf='buyerForm.controls?.tenant?.value !== true'>-->
      <!--                    <button nbTooltip='Mail?' nbButton size='tiny'-->
      <!--                            class="col-3 market-t {{buyerForm.controls?.mail?.value === true ? 'contact-active' : 'contact-inactive'}}"-->
      <!--                            hero (click)="setSquareValue('mail')">M-->
      <!--                    </button>-->
      <!--                    <button nbTooltip='Door?' nbButton size='tiny'-->
      <!--                            class="col-3 market-t {{buyerForm.controls?.door?.value === true ? 'contact-active' : 'contact-inactive'}}"-->
      <!--                            hero (click)="setSquareValue('door')">D-->
      <!--                    </button>-->
      <!--                    <button nbTooltip='Call?' nbButton size='tiny'-->
      <!--                            class="col-3 market-t {{buyerForm.controls?.call?.value === true ? 'contact-active' : 'contact-inactive'}}"-->
      <!--                            hero (click)="setSquareValue('call')">C-->
      <!--                    </button>-->
      <!--                    <button nbTooltip='SMS?' nbButton size='tiny'-->
      <!--                            class="col-3 market-t {{buyerForm.controls?.sms?.value === true ? 'contact-active' : 'contact-inactive'}}"-->
      <!--                            hero (click)="setSquareValue('sms')">S-->
      <!--                    </button>-->
      <!--                  </div>-->
      <!--                </div>-->
    </div>

    <!--            <div class='person-section mt-2' [hidden]='!data || !data.buyerId'>-->
    <!--              <div class='accordion-item person-section'>-->
    <!--                <div class='accordion-header'>-->
    <!--                  Note Expand-->
    <!--                </div>-->
    <!--                <div class='accordion-body'>-->
    <!--                  <div class='row'>-->
    <!--                    <div class='col-6'>-->
    <!--                      <input type='text' nbInput fieldSize='tiny' fullWidth name='lastApp'-->
    <!--                             placeholder='Last App' formControlName='lastApp'-->
    <!--                             [ngClass]="showError('lastApp')">-->
    <!--                    </div>-->
    <!--                    <div class='col-6'>-->
    <!--                      <input type='text' nbInput fieldSize='tiny' fullWidth name='lastContact'-->
    <!--                             placeholder='Last Contact' formControlName='lastContact'-->
    <!--                             [ngClass]="showError('lastContact')">-->
    <!--                    </div>-->
    <!--                    <div class='col-12'>-->
    <!--                    -->
    <!--                  </div>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->

    <div class='custom-freeze-body no-scroll-x'>
      <div *ngIf='data?.buyerId && (buyerForm.controls.buyer.value || buyerForm.controls.tenant.value)'>
        <!-- Common Section-->
        <div class='mt-2 common-section' [hidden]='!showCommon'>
          <div class='p-2'>
            <div class=''>
              <div class='row'>
                <div class='col-8'>
                </div>
                <!--Right Block-->
              </div>
            </div>
            <div class=''>
              <!--              <div class='row'>-->
              <!--                <div class='col-6'>-->
              <!--                  <p class='text-center'>Cash</p>-->
              <!--                </div>-->
              <!--                <div class='col-6'>-->
              <!--                  <p class='text-center'>Common</p>-->
              <!--                </div>-->
              <!--              </div>-->
              <div class='col-12 mt-3'>
                <div class='row'>
                  <div class='col-2'>
                    <select formControlName='type'
                            [ngClass]="showError('type')"
                            (change)='onPropertyTypeChange()'
                            class='property-spec-select select-no-arrow text-center border-1 select-height mt-1'
                    >
                      <option value='' disabled>Type</option>
                      <option *ngFor='let item of propertyType | keyvalue:keepOrder'
                              value='{{item.key}}'
                      >{{item.value}}
                      </option>
                    </select>
                  </div>
                  <div class='col-2'>
                    <select formControlName='bed'
                            [ngClass]="showError('bed')"
                            class='property-spec-select select-no-arrow text-center border-1 select-height mt-1'>
                      <option value='' disabled>Bed</option>
                      <option *ngFor='let item of bedValues' [value]='item'>{{item}}</option>
                    </select>
                  </div>
                  <div class='col-2'>
                    <select formControlName='bath'
                            [ngClass]="showError('bath')"
                            class='property-spec-select select-no-arrow text-center border-1 select-height mt-1'>
                      <option value='' disabled>Bath</option>
                      <option *ngFor='let item of bedValues' [value]='item'>{{item}}</option>
                    </select>
                  </div>
                  <div class='col-2'>
                    <select formControlName='car'
                            [ngClass]="showError('car')"
                            class='property-spec-select select-no-arrow text-center border-1 select-height mt-1'>
                      <option value='' disabled>Car</option>
                      <option *ngFor='let item of bedValues' [value]='item'>{{item}}</option>
                    </select>
                  </div>
                  <div class='col-4'>
                    <input type='number' nbInput fieldSize='tiny'
                           class='landInput border-1 size-tiny shape-rectangle'
                           fullWidth name='land'
                           formControlName='land' placeholder='Land'
                           [ngClass]="showError('land')"/>
                  </div>
                </div>
                <div class='col-6 mt-2'>
                  <div class='row'>
                    <div class='col-6 p-0'>
                      <select size='tiny'
                              formControlName='minBuyPrice'
                              class='width100'
                              (change)='onMinPriceChange(buyerForm.controls?.minBuyPrice?.value)'
                      >
                        <option value='0'>$min</option>
                        <option *ngFor='let price of BUYER_PRICE()'
                                [value]='price.key'
                        >{{price.value}}</option>
                      </select>
                    </div>
                    <div class='col-6'>
                      <select size='tiny'
                              formControlName='maxBuyPrice'
                              class='width100'
                              (change)='onMaxPriceChange(buyerForm.controls?.maxBuyPrice?.value)'
                      >
                        <option value='0'>$max</option>
                        <option *ngFor='let price of BUYER_PRICE()'
                                [value]='price.key'
                        >{{price.value}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Area Section-->
        <div class='mt-2 col-12 area-section' [hidden]='!showAddress'>
          <div class='p-2'>
            <div class=''>
              <div class='row'>
                <div class='col-8'>

                </div>
                <!--Right Block-->

              </div>
            </div>
            <div class=''>
              <!--              <div class='row mb-2'>-->
              <!--                <div class='col-3'>-->
              <!--                  <span>Local</span>-->
              <!--                  <span-->
              <!--                    (click)="setBuyerAttribute('isLocal')"-->
              <!--                    *ngIf='!buyerForm.controls?.isLocal?.value'-->
              <!--                    class='status-active status-active-null' style='vertical-align: bottom'>-->
              <!--                </span>-->
              <!--                  <span-->
              <!--                    (click)="setBuyerAttribute('isLocal')"-->
              <!--                    *ngIf='buyerForm.controls?.isLocal?.value'-->
              <!--                    class='status-active'>-->
              <!--                  <nb-icon [icon]="'checkmark-outline'"-->
              <!--                           pack='eva'>-->
              <!--                  </nb-icon>-->
              <!--                </span>-->
              <!--                </div>-->
              <!--                <div class='col-3'>-->
              <!--                  <span>Tenant</span>-->
              <!--                  <span-->
              <!--                    (click)="setBuyerAttribute('clientTenant')"-->
              <!--                    *ngIf='!buyerForm.controls?.clientTenant?.value'-->
              <!--                    class='status-active status-active-null' style='vertical-align: bottom'>-->
              <!--                </span>-->
              <!--                  <span-->
              <!--                    (click)="setBuyerAttribute('clientTenant')"-->
              <!--                    *ngIf='buyerForm.controls?.clientTenant?.value'-->
              <!--                    class='status-active'>-->
              <!--                  <nb-icon [icon]="'checkmark-outline'"-->
              <!--                           pack='eva'>-->
              <!--                  </nb-icon>-->
              <!--                </span>-->
              <!--                </div>-->
              <!--                <div class='col-3'>-->
              <!--                  <span>Seller</span>-->
              <!--                  <span-->
              <!--                    (click)="setBuyerAttribute('clientSeller')"-->
              <!--                    *ngIf='!buyerForm.controls?.clientSeller?.value'-->
              <!--                    class='status-active status-active-null' style='vertical-align: bottom'>-->
              <!--                </span>-->
              <!--                  <span-->
              <!--                    (click)="setBuyerAttribute('clientSeller')"-->
              <!--                    *ngIf='buyerForm.controls?.clientSeller?.value'-->
              <!--                    class='status-active'>-->
              <!--                  <nb-icon [icon]="'checkmark-outline'"-->
              <!--                           pack='eva'>-->
              <!--                  </nb-icon>-->
              <!--                </span>-->
              <!--                </div>-->
              <!--                <div class='col-3' *ngIf='data.isActive === "INACTIVE" && data.address?.districtId !== null'>-->
              <!--                  <button nbButton size='tiny' status='primary' (click)='findStreet()'>Find Street</button>-->
              <!--                </div>-->
              <!--              </div>-->

              <p class='text-center mt-2' *ngIf="buyerForm.controls.isActive.value !== 'INACTIVE'">
                AREA LIVE IN</p>
              <p class='text-center mt-2' *ngIf="buyerForm.controls.isActive.value === 'INACTIVE'">
                AREA BOUGHT IN</p>
              <div class='row w-100'>
                <div class='col-6'>
                  <nb-form-field class="w-100">
                    <input nbInput fullWidth placeholder='Postcode' fieldSize='tiny' class='override-nbinput-height'
                           [(ngModel)]='postcode'
                           [typeahead]='filterPostcode$'
                           [ngModelOptions]='{standalone: true}'
                           [typeaheadAsync]='true'
                           (typeaheadOnSelect)='selectPostcode($event)'
                           typeaheadOptionField='code'
                           typeaheadMinLength='3'
                           typeaheadWaitMs='1000'
                           [value]="postcode? postcode: null">
                    <!--      <span nbSuffix (click)='searchSuburbByPostcode()'><i class='fa fa-search'></i></span>-->
                  </nb-form-field>
                </div>
                <div class='col-6'>
                  <select size='tiny'
                          formControlName='districtId'
                          [disabled]='!postcode'
                          class='width100'
                          (change)='onSuburbSelectChange(buyerForm.controls?.districtId?.value)'
                  >
                    <option [ngValue]='null'>All Suburbs</option>
                    <option *ngFor='let key of filteredDistricts'
                            [value]='key.districtId'
                    >{{key.name}}</option>
                  </select>
                </div>
              </div>
              <div class='row mt-2'>
                <div class='col-lg-6 col-md-12 col-sm-12 col-12 mt-1'>
                  <select fullWidth
                          formControlName='streetId'
                          [disabled]='!buyerForm.controls?.districtId?.value'
                          class='width100'
                  >
                    <option [ngValue]='null'>All Streets</option>
                    <option *ngFor='let key of filteredStreets'
                            [value]='key.streetId'
                    >{{key.name}}</option>
                  </select>
                </div>
                <div class='col-lg-6 col-md-12 col-sm-12 col-12 d-flex mt-1'>
                  <div class='col-xl-3 col-md-4 col-sm-6 col-4 p-0 pl-1 mr-2 mb-2 mt-1'>
                    <input type='number' nbInput fieldSize='tiny' name='numberFrom' placeholder='From'
                           formControlName='numberFrom' class='mr-1'>
                  </div>
                  <div class='col-xl-3 col-md-4 col-sm-6 col-4 p-0 pl-1 mr-2 mb-2 mt-1'>
                    <input type='text' nbInput fieldSize='tiny' name='numberTo' placeholder='To'
                           formControlName='numberTo' class='mr-1'>
                  </div>
                  <div class='col-lg-4 col-md-8 col-sm-4 col-4 ml-2 mt-1'>
                    <div class='row' [hidden]='!data || !data.buyerId'>
                      <button nbButton class='mt-1' status='primary' size='tiny' (click)='lookupAddress()'>Look up
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!--              <div class='row mt-2'>-->
              <!--                <div class='col-12 property-type-container'>-->
              <!--                  <input type='text' nbInput fieldSize='tiny' name='unit' placeholder='Unit'-->
              <!--                         formControlName='unit' class='mr-1'>-->
              <!--                  &nbsp;/&nbsp;-->

              <!--                  <input type='text' nbInput fieldSize='tiny' fullWidth name='numberFromLetter' placeholder='Letter'-->
              <!--                         formControlName='numberFromLetter'>-->
              <!--                  &nbsp;-&nbsp;-->

              <!--                  <input type='text' nbInput fieldSize='tiny' fullWidth name='numberToLetter' placeholder='Letter'-->
              <!--                         formControlName='numberToLetter'>-->
              <!--                </div>-->
              <!--              </div>-->
              <div class='row mt-2'>
                <div class='col-12'>
                  <input type='text' nbInput fieldSize='tiny' fullWidth name='Enq'
                         placeholder='Enq' formControlName='enq'
                         [ngClass]="showError('enq')">
                </div>
              </div>
              <div class='row mt-2 mb-2'>
                <div class='col-6 border-right'>
                  <span class='interest-label'>PostCode</span>
                  <!--                  <span class='status-active checked-button' (click)='pickZone()'>-->
                  <!--                    <nb-icon [icon]="'plus-outline'" pack='eva'></nb-icon>-->
                  <!--                  </span>-->
                  <div class='mt-1'>
                    {{interestPostcode}}
                    <!--                    <span class='ml-2 remove-interest' (click)='removeInterestZone(interestZone.zoneId)'> X</span>-->
                    <!--                    <span class='ml-2'>{{interestZone.zone.name}}</span>-->
                  </div>
                </div>

                <!--                <div class='col-6 border-left'>-->
                <!--                  <span class='interest-label'>District</span>-->
                <!--                  <span class='status-active checked-button' (click)='pickDistrict()'>-->
                <!--                    <nb-icon [icon]="'plus-outline'" pack='eva'></nb-icon>-->
                <!--                  </span>-->
                <!--                  <div class='mt-1' *ngFor='let interestDistrict of interestDistricts'>-->
                <!--                    <span class='ml-2 remove-interest'-->
                <!--                          (click)='removeInterestDistrict(interestDistrict.districtId)'> X</span>-->
                <!--                    <span class='ml-2'>{{interestDistrict.district.name}}</span>-->
                <!--                  </div>-->
                <!--                </div>-->
              </div>

            </div>
          </div>
        </div>

        <!-- Number Section-->
        <div class='mt-2 overflow-hidden' [hidden]='!showNumber'>
          <div class='accordion-item number-section p-2'>
            <div class='accordion-header'>
              <div class='row'>
                <div class='col-8'>
                </div>
                <!--Right Block-->

              </div>
            </div>
            <div class='accordion-body'>
              <div class='row mb-2'>
                <div class='col-6 text-center'>
                  First Enq Date :  {{buyerForm.controls?.enqDate2?.value | date:'dd/MM/yyyy'}}
                </div>

                <div class='col-6 text-center'>
                  Week Count : {{weekCount}}
                </div>
              </div>
              <div class='row mb-2 p-2'>
                <div class='col-12'>
                  <nb-form-field>
                    <input nbTooltip="Enq Date : {{buyerForm.controls?.enqDate2?.value | date:'dd/MM/yyyy'}}"
                           [nbDatepicker]='datepickerEnqDate2' nbInput fieldSize='tiny'
                           fullWidth formControlName='enqDate2' placeholder='Enq date'/>
                    <nb-datepicker #datepickerEnqDate2></nb-datepicker>
                  </nb-form-field>
                </div>
              </div>
              <div class='row'>
                <div class='col-12'>
                  <div class='row fit-screen'>
                    <div class='col-6'>
                      <div class='row p-2'>
                        <div class='col-2 mt-1'>
                          NW
                        </div>
                        <div class='col-10'>
                          <nb-form-field>
                            <input type='text' nbInput fieldSize='tiny' fullWidth name='noWeek'
                                   formControlName='noWeek' placeholder=''>
                          </nb-form-field>
                        </div>
                      </div>
                    </div>
                    <div class='col-6'>
                      <div class='row p-2'>
                        <div class='col-2 mt-1'>
                          NH
                        </div>
                        <div class='col-10'>
                          <nb-form-field>
                            <input type='text' nbInput fieldSize='tiny' fullWidth name='noHouse'
                                   formControlName='noHouse' placeholder=''>
                          </nb-form-field>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf='buyerForm.controls.tenant.value'>
                    <p class='mt-2'>how long rented there ?</p>
                    <div class='row'>
                      <div class='col-6'>
                        <nb-form-field>
                          <input type='text' nbInput fieldSize='tiny' fullWidth name='noWeek'
                                 formControlName='yearIn' placeholder='year in'>
                        </nb-form-field>
                      </div>
                      <div class='col-6'>
                        <nb-form-field>
                          <input type='text' nbInput fieldSize='tiny' fullWidth name='noHouse'
                                 formControlName='monthIn' placeholder='month in'>
                        </nb-form-field>
                      </div>
                    </div>
                    <p class='mt-2'>when you have to move by</p>
                    <div class='row'>
                      <div class='col-6'>
                        <nb-form-field>
                          <input type='text' nbInput fieldSize='tiny' fullWidth name='noWeek'
                                 formControlName='weekOut' placeholder='week out'>
                        </nb-form-field>
                      </div>
                      <div class='col-6'>
                        <nb-form-field>
                          <input type='text' nbInput fieldSize='tiny' fullWidth name='noHouse'
                                 formControlName='dayOut' placeholder='day out'>
                        </nb-form-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Inspection Section-->
        <div class='mt-2' *ngIf='buyerForm.controls.buyer.value' [hidden]='!showInspection'>
          <div class='accordion-item inspection-section p-2'>
            <div class='accordion-header'>
              <div class='row'>
                <div class='col-8'>

                </div>
                <!--Right Block-->

              </div>
            </div>
            <div class='accordion-body'>
              <div class='row ml-1 mr-1'>
                <div class='col-8'>
                  <span class='text-center appointment-header'>Buyer Appointment</span>
                </div>
                <div class='col-2 text-center'>
                <span class='status-active checked-button ml-1'>
                <nb-icon [icon]="'plus-outline'"
                         (click)='createTask()'
                         pack='eva'>
                </nb-icon>
              </span>
                </div>
                <div class='col-2 text-center'>
                <span class='status-active checked-button ml-1'>
                <nb-icon nbTooltip='Create for Current Address' [icon]="'plus-outline'"
                         (click)='createTaskForCurrentAddress()'
                         pack='eva'>
                </nb-icon>
              </span>
                </div>
                <div class='row pr-3 pl-3' *ngFor='let buyerTask of buyerTasks'>
                  <div class='col-12'>
                    <div class='row w-100'>
                      <div class='col-9 appointment-without-border task-address pl-3'
                           nbTooltip='{{getAddressName(buyerTask.task?.prospect?.address)}}'>
                        {{getAddressName(buyerTask.task?.prospect?.address)}}
                      </div>
                      <div class='col-3 appointment-without-border task-address'
                           nbTooltip="{{buyerTask.task?.toDate | date:'dd/MM/yyyy hh:mm'}}">
                        {{buyerTask.task?.toDate | date:'dd/MM/yyyy hh:mm'}}
                      </div>
                    </div>
                  </div>
                  <div class='row w-100 ml-1 mt-2 text-center'>
                    <div class='col-12'>
                      <div class='row'>
                        <div class='col-1 p-0'>
                          BA
                        </div>
                        <div class='col-1 p-0'>
                          BI
                        </div>
                        <div class='col-2 p-0'>
                          Rate
                        </div>
                        <div class='col-2 p-0'>
                          Price
                        </div>
                        <div class='col-2 p-0'>
                          OFF
                        </div>
                        <div class='col-2 p-0'>
                          DEP
                        </div>
                        <div class='col-2 p-0'>
                          EXC
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class='row w-100 ml-1 mt-1'>
                    <div class='col-12'>
                      <div class='row'>
                        <div class='col-1  appointment-border'>
                          {{getShortName(buyerTask.task?.fromUser?.name)}}
                        </div>
                        <div class='col-1 appointment-border'>
                          {{getShortName(buyerTask.task?.toUser?.name)}}
                        </div>
                        <div class='col-2 appointment-border'>
                          <input type='appointment-input'
                                 style='padding:2px; border: none; background: transparent; border-radius: 0;'
                                 [(ngModel)]='buyerTask.rate' [ngModelOptions]='{standalone: true}'>
                        </div>
                        <div class='col-2 appointment-border'>
                          <input type='appointment-input'
                                 nbTooltip='{{buyerTask.price}}'
                                 style='padding:2px; border: none; background: transparent; border-radius: 0;'
                                 [(ngModel)]='buyerTask.price' [ngModelOptions]='{standalone: true}'>
                        </div>
                        <div class='col-2 appointment-border'>
                <span
                  (click)='selectOffOrDep(buyerTask, "isOff")'
                  *ngIf='!buyerTask.isOff'
                  class='status-active status-active-null'>

                </span>
                          <span
                            (click)='selectOffOrDep(buyerTask, "isOff")'
                            *ngIf='buyerTask.isOff'
                            class='status-active'>
                  <nb-icon [icon]="'checkmark-outline'"
                           pack='eva'>
                  </nb-icon>
                </span>
                        </div>
                        <div class='col-2 appointment-border'>
                <span
                  (click)='selectOffOrDep(buyerTask, "isDep")'
                  *ngIf='!buyerTask.isDep'
                  class='status-active status-active-null'>

                </span>
                          <span
                            (click)='selectOffOrDep(buyerTask, "isDep")'
                            *ngIf='buyerTask.isDep'
                            class='status-active'>
                  <nb-icon [icon]="'checkmark-outline'"
                           pack='eva'>
                  </nb-icon>
                </span>
                        </div>
                        <div class='col-2 appointment-border'>
                <span
                  *ngIf='!buyerTask.isExc'
                  (click)='selectDeposit(buyerTask)'
                  class='status-active status-active-null'>

                </span>
                          <span *ngIf='buyerTask.isExc'
                                (click)='selectDeposit(buyerTask)'
                                class='status-active'>
                  <nb-icon [icon]="'checkmark-outline'"
                           pack='eva'>
                  </nb-icon>
                </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Deposit Section-->

                <div class='mt-2'
                     *ngIf='buyerForm.controls.buyer.value && isDeposit'>
                  <div class='accordion-item deposit-section p-2'>
                    <div class='accordion-header'>
                      <div class='row'>
                        <div class='col-5'
                             nbTooltip='{{selectedAddress}}'>
                          {{selectedAddress}}
                        </div>
                        <div class='col-3'>
                          Link To Client
                        </div>
                        <!--Right Block-->
                        <div class='col-4 square-container square-market'>
                          <div class='row'>
                            <p nbTooltip='Deposit'
                               class="buyer-button buyer-button-extend {{buyerForm.controls?.isDeposit?.value === true ? 'deposit-active' : 'buyer-inactive'}}"
                               (click)="setBuyerAttribute('isDeposit')">D</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='accordion-body'>
                      <div *ngFor='let buyerDeposit of buyerDeposits; let i = index'>
                        <div class='row py-2'
                             *ngIf='!buyerDeposit.isTenant'
                             [ngClass]="{'buyer-lv7': i > 4}">
                          <div class='col-2'>

                          </div>
                          <div class='col-2'>
                            {{buyerDeposit.label}}
                          </div>
                          <div class='col-3'>
                            <nb-form-field>
                              <input [nbDatepicker]='datepickerEnqDate' nbInput fieldSize='tiny'
                                     fullWidth
                                     [(ngModel)]='buyerDeposit.setDate'
                                     [ngModelOptions]='{standalone: true}'
                                     placeholder='Date'/>
                              <nb-datepicker #datepickerEnqDate></nb-datepicker>
                            </nb-form-field>
                          </div>
                          <div class='col-3'>
                            <input type='text' nbInput fieldSize='tiny' fullWidth
                                   [(ngModel)]='buyerDeposit.price' [ngModelOptions]='{standalone: true}'>
                          </div>
                          <!--Right Block-->
                          <div class='col-2'>
            <span
              (click)='checkBuyerDeposit(buyerDeposit)'
              *ngIf='!buyerDeposit.isChecked'
              class='status-active status-active-null'>

                </span>
                            <span
                              (click)='checkBuyerDeposit(buyerDeposit)'
                              *ngIf='buyerDeposit.isChecked'
                              class='status-active'>
                  <nb-icon [icon]="'checkmark-outline'"
                           pack='eva'>
                  </nb-icon>
            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!--         Person Section-->
        <div [hidden]='!showHistory' class='mt-2'>
          <div class='accordion-item person-section p-2'>
            <!--            <div class='accordion-header'>-->
            <!--              <div class='row'>-->
            <!--                <div class='col-8'>-->

            <!--                </div>-->
            <!--                <div class='col-4 square-container square-market'>-->
            <!--                  <p class='buyer-button enq-active'>Note</p>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
            <div class='accordion-body'>
              <nb-card class='log-view mt-2'>
                <nb-list>
                  <nb-list-item *ngFor='let log of sortHistoryList()'>
                    <div [innerHTML]='formatLog(log)'></div>
                  </nb-list-item>
                </nb-list>
              </nb-card>
            </div>
          </div>
        </div>

        <!-- Linked Section-->
        <div class='mt-2' *ngIf='linkedGroup.length !== 0'>
          <div class='accordion-item market-section p-2'>
            <div class='accordion-header'>
              <div class='row'>
                <div class='col-8'>

                </div>
                <div class='col-4 square-container square-market'>
                  <p class='buyer-button enq-active'>Link</p>
                </div>
              </div>
            </div>
            <div class='accordion-body'>
              <div>
                <div *ngFor='let group of linkedGroup, index as index'>
                  <div>
                    <div>
                      Group {{index + 1}}
                    </div>
                  </div>
                  <div>
                    <nb-list>
                      <nb-list-item *ngFor='let buyer of group.buyers' class='buyer-item mt-1'
                                    (click)='onSelectBuyer(buyer)'>
                        Name: {{buyer?.customer?.name}} <br>
                        Surname: {{buyer?.customer?.surname}} <br>
                        Phone: {{buyer?.customer?.phone}} <br>
                        Mobile Phone: {{buyer?.customer?.mobilePhone}} <br>
                        Address: {{formatAddress(buyer?.address)}}
                      </nb-list-item>
                      <nb-list-item *ngFor='let prospect of group.prospects' class='prospect-item mt-1'
                                    (click)='onSelectProspect(prospect)'>
                        Name: {{prospect?.customer?.name}} <br>
                        Surname: {{prospect?.customer?.surname}} <br>
                        Phone: {{prospect?.customer?.phone}} <br>
                        Mobile Phone: {{prospect?.customer?.mobilePhone}} <br>
                        Address: {{formatAddress(prospect?.address)}}
                      </nb-list-item>
                    </nb-list>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--        <div class='mt-2' *ngIf='data'>-->
        <!--          <div class='accordion-item market-section p-2'>-->
        <!--            <div class='accordion-header'>-->
        <!--              <div class='row'>-->
        <!--                <div class='col-8'>-->

        <!--                </div>-->
        <!--                <div class='col-4 square-container square-market'>-->
        <!--                  <p class='buyer-button enq-active'>Images</p>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--            <div class='accordion-body'>-->
        <!--              <div class='col-12'>-->
        <!--                <ngx-attachment [objectId]='data.buyerId'-->
        <!--                                objectType='BUYER'-->
        <!--                                [displayLabel]='false'-->
        <!--                ></ngx-attachment>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
    <nb-card-footer class='dialog-footer' *ngIf='!showFooter'>
      <button class='float-button float-new mr-2' size='tiny' nbButton status='info'
              (click)='closeDialog()' *ngIf='isDialog'>Cancel
      </button>
      <button class='float-button float-new mr-2' size='tiny' nbButton status='info'
              (click)='new()' *ngIf='!isDialog'>New
      </button>
      <button class='float-button float-outdoor mr-2' size='tiny' nbButton status='success' (click)='outdoor()'
              *ngIf='!isInjected && !isDialog'
              [disabled]='isUpdating === true'>
        Outdoor
      </button>
      <button class='float-button float-create' size='tiny' nbButton status='success' (click)='submit()'
              [disabled]='isUpdating === true'
              *ngIf='(!data && ComponentPermission.isAccess(ComponentPermission.CUSTOMER_CREATE)) || (data && ComponentPermission.isAccess(ComponentPermission.CUSTOMER_EDIT))'>
        Save
      </button>
    </nb-card-footer>
  </nb-card>
</form>
