<nb-card class="container">
  <nb-card-body>
    <div class="row">
      <div class="col-12">
        <ngx-prospect-pick-filter-advance
          [columnsDefinition]="columnsDefinition"
          [columnGroup]="columnGroup"
          [districts]="filteredDistricts"
          [streets]="filteredStreets"
          [cities]="cities"
          [searchRequest]="searchRequest"
          [farm]="selectedProspect?.address?.street?.farm?.name"
          [zone]="selectedProspect?.address?.street?.district?.zone?.name"
          [total]="groupWrapper?.totalItems"
          (onColumnDisplayOptionChange)="onColumnDisplayOptionCheckedChange($event)"
          (onSuburbChange)="onSuburbChange($event)"
          (onCityChange)="onCityChange($event)"
          (onSearch)="search()"
          [postcode]='searchRequest.postcode'
          (onSearchByPostcode)='onSearchByPostcode($event)'
        >
        </ngx-prospect-pick-filter-advance>
        <div class="mt-2">
          <ng2-smart-table [settings]="settings"
                           [source]="source"
                           (userRowSelect)="selectRecord($event)"
          >
          </ng2-smart-table>
        </div>
      </div>
    </div>
  </nb-card-body>
</nb-card>

