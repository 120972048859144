<nb-tabset (changeTab)='onChangeTab($event)' fullWidth *appPermissionCheck='ComponentPermission.DASHBOARD_DOB'>
  <nb-tab tabTitle='CLIENT' [active]='currentTab === tabs.CLIENT'>
    <ngx-prospect-list-dob *ngIf='currentTab === tabs.CLIENT'
                           [birthdayToday] = birthdayToday
    ></ngx-prospect-list-dob>
  </nb-tab>
  <nb-tab tabTitle='CUSTOMER' [active]='currentTab === tabs.CUSTOMER'>
    <ngx-buyer-list-dob *ngIf='currentTab === tabs.CUSTOMER'
                        [birthdayToday] = birthdayToday
    ></ngx-buyer-list-dob>
  </nb-tab>
</nb-tabset>
