import {Component, OnInit, ViewChild} from '@angular/core';
import {ProspectService} from '../../../../shared/services/prospect.service';
import {RestResult} from '../../../../shared/models/response/rest-result';
import {CityService} from '../../../../shared/services/city.service';
import {SearchRequest} from '../../../../shared/models/request/search-request';
import {SuburbService} from '../../../../shared/services/suburb.service';
import {StreetService} from '../../../../shared/services/street.service';
import {FarmService} from '../../../../shared/services/farm.service';
import {ZoneService} from '../../../../shared/services/zone.service';
import {Company} from '../../../../shared/models/response/prospect/company';
import {TaskType} from '../../../../shared/models/response/task-type';
import {TaskTypeService} from '../../../../shared/services/task-type.service';
import {CompanyService} from '../../../../shared/services/company.service';
import {ProspectSearchRequest} from '../../../../shared/models/request/prospect/prospect-search-request';
import {ProspectListBasicComponent} from '../prospect-list-basic/prospect-list-basic.component';
import {LocalService} from '../../../../shared/services/local.service';
import {CompanyBoard} from '../../../../shared/models/response/prospect/company-board';
import {District} from '../../../../shared/models/response/address/district';
import {Street} from '../../../../shared/models/response/address/street';
import {City} from '../../../../shared/models/response/address/city';
import { AuthService } from '../../../../shared/services/auth.service';
import { User } from '../../../../shared/models/response/user';
import {ComponentPermission} from '../../../../component.permission';
import { CommonService } from '../../../../shared/services/common.service';

@Component({
  selector: 'app-company-board',
  templateUrl: './company-board.component.html',
  styleUrls: ['./company-board.component.scss'],
})
export class CompanyBoardComponent implements OnInit {
  @ViewChild(ProspectListBasicComponent) prospectListBasicComponent: ProspectListBasicComponent;

  ComponentPermission = ComponentPermission;
  districts: District[];
  streets: Street[];
  cities: City[];
  // farms: Farm[];
  // zones: Zone[];
  companies: Company[];
  taskTypes: TaskType[];
  isDisplayed = false;
  isRent = false;
  isOTM = false;

  companyBoards = new Array<CompanyBoard>();
  // totalTypeScores: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  levelLabels: string[] = [];
  searchRequest = new ProspectSearchRequest();
  // filteredDistricts: District[] = [];
  // filteredZones: Zone[] = [];
  // filteredStreets: Street[] = [];
  // filteredFarms: Farm[] = [];
  currentUser: User;

  constructor(private cityService: CityService,
              private prospectService: ProspectService,
              private suburbService: SuburbService,
              private taskTypeService: TaskTypeService,
              private localService: LocalService,
              private streetService: StreetService,
              private companyService: CompanyService,
              private farmService: FarmService,
              private zoneService: ZoneService,
              private authService: AuthService,
              private commonService: CommonService) {
  }

  ngOnInit(): void {
    this.currentUser = this.authService.currentUser;
    this.initFilter();
    this.onRent();
    this.getTaskTypes();
    this.initHorizontalLabel();
  }

  getTaskTypes() {
    const taskTypeSearchRequest: SearchRequest = new SearchRequest();
    taskTypeSearchRequest.orders = [];
    taskTypeSearchRequest.orders.push(
      {
        left: 'name',
        right: 'asc',

      },
    );
    this.taskTypeService.search(taskTypeSearchRequest).subscribe((result: RestResult) => {
      this.taskTypes = result.data;
    });
  }

  initHorizontalLabel() {
    this.levelLabels.push('Number');
    this.levelLabels.push('Company Name');
    this.levelLabels.push('Level 1');
    this.levelLabels.push('Level 2');
    this.levelLabels.push('Level 3');
    this.levelLabels.push('Total');
  }

  initFilter() {
    const searchRequest = new SearchRequest();
    searchRequest.offset = 0;
    searchRequest.limit = 10000;
    searchRequest.orders = new Array();
    searchRequest.orders.push(
      {
        left: 'name',
        right: 'asc',

      },
    );
    // const self = this;
    // this.cityService.search(searchRequest).subscribe((result: RestResult) => {
    //   self.cities = result.data;
    // });
    this.companyService.search(searchRequest).subscribe((result: RestResult) => {
      this.companies = result?.data;
    });
  }

  // onCityChange(cityId: number) {
  //   this.searchRequest.cityId = cityId;
  //   this.searchRequest.districtId = null;
  //   this.searchRequest.zoneId = null;
  //   this.searchRequest.farmId = null;
  //   this.searchRequest.streetId = null;
  //   if (cityId) {
  //     this.localService.districts(cityId).subscribe(result => {
  //       this.filteredDistricts = result;
  //     });
  //     this.localService.zones(cityId).subscribe(result => {
  //       this.filteredZones = result;
  //     });
  //   }
  // }

  // onZoneChange(zoneId: number) {
  //   this.searchRequest.zoneId = zoneId;
  //   // reset id
  //   this.searchRequest.streetId = null;
  //   this.searchRequest.farmId = null;
  //   this.searchRequest.districtId = null;
  //   // reset filter
  //   if (zoneId) {
  //     this.localService.districtsByZone(zoneId).subscribe(result => {
  //       this.filteredDistricts = result;
  //     });
  //   } else {
  //     this.localService.districts(this.searchRequest.cityId).subscribe(result => {
  //       this.filteredDistricts = result;
  //     });
  //   }
  // }

  // onSuburbChange(districtId: number) {
  //   this.searchRequest.districtId = districtId;
  //   this.searchRequest.streetId = null;
  //   this.searchRequest.farmId = null;
  //   if (districtId) {
  //     this.localService.streets(districtId).subscribe(result => {
  //       this.filteredStreets = result;
  //     });
  //     this.localService.farms(districtId).subscribe(result => {
  //       this.filteredFarms = result;
  //     });
  //   }
  // }

  // onFarmChange(farmId: number) {
  //   this.searchRequest.farmId = farmId;
  //   this.searchRequest.streetId = null;
  //   if (farmId) {
  //     this.localService.streetsByFarm(farmId).subscribe(result => {
  //       this.filteredStreets = result;
  //     });
  //   } else {
  //     this.localService.streets(this.searchRequest.districtId).subscribe(result => {
  //       this.filteredStreets = result;
  //     });
  //   }
  // }

  onStreetChange(streetId: number) {

  }

  loadStreets() {

  }

  onRent() {
    this.isRent = true;
    this.isOTM = false;
    this.searchRequest.rent = true;
    this.searchRequest.otm = false;
    this.search();
  }

  onOTM() {
    this.isRent = false;
    this.isOTM = true;
    this.searchRequest.rent = false;
    this.searchRequest.otm = true;
    this.search();
  }

  search() {
    this.searchRequest.officeId = this.currentUser.office?.officeId;
    this.prospectService.searchCompanyBoard(this.searchRequest).subscribe((result: RestResult) => {
      if (result && result.data) {
        this.initBoard(result.data);
      }
    });
    this.isDisplayed = false;
  }

  initBoard(data: any[]) {
    this.companyBoards = new Array<CompanyBoard>();
    if (data && data.length > 0) {
      data.forEach(companyBoard => {
        let row = this.companyBoards.find(temp => temp.name === companyBoard[0]);
        if (!row) {
          row = new CompanyBoard();
          row.total = 0;
          this.companyBoards.push(row);
        }
        row.name = companyBoard.companyName;
        row.companyId = companyBoard.companyId;
        row.total += companyBoard.total;
        switch (companyBoard.rate) {
          case 1: {
            row.rateLevel1 = companyBoard.rate;
            break;
          }
          case 2: {
            row.rateLevel2 = companyBoard.rate;
            break;
          }
          case 3: {
            row.rateLevel3 = companyBoard.rate;
            break;
          }
          default: {
            break;
          }
        }
      });

      this.companyBoards.sort((a, b) =>
        (a.total < b.total) ? 1 : (a.total === b.total) ?
          ((a.companyId < b.companyId) ? 1 : -1) : -1);
      this.companyBoards = this.companyBoards.slice(0, 25);
    }
  }

  resetRequest() {
    this.searchRequest.rate = null;
    this.searchRequest.type = null;
    this.searchRequest.hasEmail = null;
    this.searchRequest.hasPhone = null;
    this.searchRequest.hasMobilePhone = null;
    this.searchRequest.relationship = null;
    this.searchRequest.motivate = null;
    this.searchRequest.sell = null;
    this.searchRequest.rent = null;
    this.searchRequest.otm = null;
    this.searchRequest.vac = null;
  }

  loadProspect(i: number, companyId: number) {
    if (ComponentPermission.isAccess(ComponentPermission.COMPANY_BOARD_CLICK)) {
      this.resetRequest();
      this.isDisplayed = true;
      // set rate and phone
      if (i !== 99) {
        this.searchRequest.rate = i;
      } else {
        this.searchRequest.rateCondition = 4;
      }
      if (this.isRent) {
        this.searchRequest.rent = true;
        this.searchRequest.rentCompanyId = companyId;
      } else {
        this.searchRequest.otm = true;
        this.searchRequest.sellCompanyId = companyId;
      }

      this.searchRequest.sortType = 'ASC';
      this.searchRequest.offset = 0;
      this.searchRequest.limit = 20;
      this.prospectListBasicComponent.filterCondition(this.searchRequest);
    } else {
      this.commonService.warning('You do not have permission to perform this action');
    }
  }

}
