import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { WrapProspect } from './prospect-utils';
import { StringUtils } from '../../../../shared/common/string-utils';
import { ComponentPermission } from '../../../../component.permission';

@Component({
  template: `
    {{renderValue}}
  `,
  styles: [],
})
export class VisualBoardPriceCellComponent implements ViewCell, OnInit {

  renderValue: string;

  isCommissionPrice: boolean;
  @Input() value: any;
  @Input() rowData: WrapProspect;
  StringUtils = StringUtils;
  ComponentPermission = ComponentPermission;

  ngOnInit() {
    if (this.rowData?.data?.property?.agentEstimatePriceFrom && this.rowData?.data?.property?.agentEstimatePriceTo) {
      this.setValueAgentPrice();
    }
    if (this.isCommissionPrice === true) {
      this.setValueCommissionPrice();
    }
  }

  setValueAgentPrice(): any {
    if (!this.rowData) {
      this.renderValue = this.value ? this.value.toString() : '';
      return;
    }
    const property = this.rowData.data.property;
    if (!property.agentEstimatePriceFrom || !property.agentEstimatePriceTo) {
      this.renderValue = '';
      return;
    }
    const avePrice = (property.agentEstimatePriceFrom + property.agentEstimatePriceTo) / 2;
    this.renderValue = avePrice.toString();
  }

  setValueCommissionPrice(): any {
    if (!this.rowData.data.property.askingPrice) {
      this.renderValue = '';
      return;
    }
    let defaultRate = this.ComponentPermission.currentUser().office.officeProperty.managementRate;
    if (!defaultRate) {
      defaultRate = 2.5 / 100;
    }
    const price = this.rowData.data.property.askingPrice * defaultRate;
    this.renderValue = price.toString();
  }
}
