<div class='row m-0'>
  <div class='col-md-7 col-sm-12 left-panel table-container'
       [ngClass]="{'popup-hide': isDisplay}">
    <nb-card>
      <nb-card-body>

        <!--        BEGIN FILTER-->
        <div class='row'>
          <div class='col-lg-12 col-xs-12 mt-2'>
            <nb-form-field>
              <input nbInput fullWidth fieldSize='small' placeholder='Address' [(ngModel)]='searchRequest.addressQuery'
                     (keydown.enter)='onSearch()'>
              <span nbSuffix *ngIf='stringUtils.isNotEmpty(searchRequest.addressQuery)' (click)='clearQuery()'>
            <i class='fa fa-times-circle custom-icon'></i></span>
              <span nbSuffix class='ml-2 mr-4' *ngIf='stringUtils.isNotEmpty(searchRequest.addressQuery)'
                    (click)='onSearch()'>
            <i class='fa fa-search custom-icon'></i></span>
            </nb-form-field>
          </div>
        </div>
        <div class='row mt-2'>
          <div class='col-sm-12 col-lg-4 mb-2'>
            <select class='select-custom' [(ngModel)]='classificationOption' size='tiny'
                    (change)='onSelectClassificationOption()'>
              <option [value]='undefined'>All</option>
              <option *ngFor='let option of clientRateOptions' [value]='option'>
                {{ option }}
              </option>
            </select>
          </div>
          <div class='col-lg-8 col-sm-12 p-0'>
            <div class='row m-0'>
              <div class='col-2'>
                <select class='select-custom' [(ngModel)]='searchRequest.type' size='tiny'
                        (change)='onSelectSpec("type")'>
                  <option [value]='undefined'><span>Type</span></option>
                  <option *ngFor='let type of propertyType | keyvalue:keepOrder'
                          [value]='type.key'>{{ type.value }}
                  </option>
                </select>
              </div>
              <div class='col-2'>
                <select class='select-custom' [(ngModel)]='searchRequest.bed' size='tiny'
                        (change)='onSelectSpec("bed")'>
                  <option [value]='undefined'><span>Bed</span></option>
                  <option *ngFor='let item of bedValues' [value]='item'>{{ item }}</option>
                </select>
              </div>
              <div class='col-2'>
                <select class='select-custom' [(ngModel)]='searchRequest.bath' size='tiny'
                        (change)='onSelectSpec("bath")'>
                  <option [value]='undefined'><span>Bath</span></option>
                  <option *ngFor='let item of bedValues' [value]='item'>{{ item }}</option>
                </select>
              </div>
              <div class='col-2'>
                <select class='select-custom' [(ngModel)]='searchRequest.car' size='tiny'
                        (change)='onSelectSpec("car")'>
                  <option [value]='undefined'><span>Car</span></option>
                  <option *ngFor='let item of bedValues' [value]='item'>{{ item }}</option>
                </select>
              </div>
              <div class='col-2'>
                <input nbInput fullWidth type="number" fieldSize='tiny' placeholder="From Land"
                       [(ngModel)]="searchRequest.fromLand">
              </div>
              <div class='col-2'>
                <input nbInput fullWidth type="number" fieldSize='tiny' placeholder="To Land"
                       [(ngModel)]="searchRequest.toLand">
              </div>
            </div>
          </div>
        </div>
        <div class='row mt-2'>
          <div class='col-12'>
            <button nbButton size='tiny' hero status='primary'
                    class='fa-pull-right'
                    nbTooltip='Trigger Search'
                    (click)='onSearchButtonClick()'>
              Search
            </button>
            <button nbButton size='tiny' hero status='primary' class='fa-pull-right mr-1' (click)='addNewProspect()'
                    *appPermissionCheck='ComponentPermission.CLIENT_CREATE'>New
            </button>
            <button nbButton size='tiny' hero status='primary' class='fa-pull-right mr-1' (click)='openShortcut()'
                    *appPermissionCheck='ComponentPermission.CLIENT_CREATE_SHORTCUT'>Shortcut
            </button>
            <button nbButton class='fa-pull-right mr-2' status='primary' size='tiny' (click)='toggleAdvance()'>Advance
            </button>
            <div class='fa-pull-left ml-3'>{{ totalRecords }}</div>
          </div>
        </div>
        <div class='row mt-3' *ngIf='showAdvance'>
          <div class='col-lg-4 col-12 override-width override-width-md mb-2'>
            <select size='tiny'
                    [(ngModel)]='streetId'
                    [disabled]='!districtId'
                    class='full-width select-custom'
                    (change)='onStreetSelectChange($event)'
            >
              <option [value]='undefined'>All streets</option>
              <option *ngFor='let key of filteredStreets'
                      [ngClass]="key.status === 'DEACTIVE' ? 'deactive-status' : ''"
                      [value]='key.streetId'
              >{{ key.name }}
              </option>
            </select>
          </div>
          <div class='col-lg-4 col-12 override-width override-width-md mb-2'>
            <select size='tiny'
                    [(ngModel)]='districtId'
                    [disabled]='!filterByPostcode'
                    class='full-width select-custom'
                    (change)='onSuburbSelectChange($event)'
            >
              <option [value]='undefined'>All suburbs</option>
              <option *ngFor='let key of filteredDistricts'
                      [ngClass]="key.status === 'DEACTIVE' ? 'deactive-status' : ''"
                      [value]='key.districtId'
              >{{ key.name }}
              </option>
            </select>
          </div>
          <div class='col-lg-4 col-12 override-width override-width-md mb-2'>
            <nb-form-field>
              <input nbInput fullWidth placeholder='Postcode' fieldSize='tiny' class='override-nbinput-height'
                     [(ngModel)]='postcode'
                     [typeahead]='filterPostcode$'
                     [ngModelOptions]='{standalone: true}'
                     [typeaheadAsync]='true'
                     (typeaheadOnSelect)='selectPostcode($event)'
                     typeaheadOptionField='code'
                     typeaheadMinLength='3'
                     typeaheadWaitMs='1000'>
            </nb-form-field>
          </div>
          <div class='w-20'>
            <select class='select-custom' size='tiny'
                    [(ngModel)]='searchRequest.sortType' (change)='onSortTypeSelectChange()'>
              <option *ngFor='let item of sortTypesText | keyvalue'
                      value='{{item.key}}'
              >{{ item.value }}
              </option>
            </select>
          </div>
          <div class='w-20'>
            <select class='select-custom' size='tiny' [(ngModel)]='searchRequest.rate' (change)='onRateSelectChange()'>
              <option class='select-option' [value]='undefined'>Rate</option>
              <option class='select-option' *ngFor='let item of rateOptions'
                      [value]='item.value'>{{ item.label }}
              </option>
            </select>
          </div>
          <div class='w-20'>
            <select class='select-custom' size='tiny' [(ngModel)]='searchRequest.motivateNumber'
                    (change)="onMotivateSelectChange()">
              <option class='select-option' [value]='undefined'>Motive</option>
              <option class='select-option' *ngFor='let item of rateOptions'
                      [value]='item.value'>{{ item.label }}
              </option>
            </select>
          </div>
          <div class='w-20'>
            <nb-form-field>
              <input [nbDatepicker]='fromDate' nbInput fieldSize='tiny'
                     fullWidth [(ngModel)]='fromPurchaseDate' placeholder='From Purchase Date' />
              <nb-datepicker #fromDate></nb-datepicker>
            </nb-form-field>
          </div>
          <div class='w-20'>
            <nb-form-field>
              <input [nbDatepicker]='toDate' nbInput fieldSize='tiny'
                     fullWidth [(ngModel)]='toPurchaseDate' placeholder='To Purchase Date' />
              <nb-datepicker #toDate></nb-datepicker>
            </nb-form-field>
          </div>
          <div class='col-3 mt-2 p-0 property-type-container'>
            <input
              [(ngModel)]='categoryName'
              [ngModelOptions]='{standalone: true}'
              [typeahead]='filterCategory$'
              [typeaheadAsync]='true'
              (typeaheadOnSelect)='selectCategory($event)'
              typeaheadOptionField='name'
              typeaheadWaitMs='1000'
              placeholder='Tag'
              nbInput fieldSize='tiny'>
          </div>
          <div class="col-6 mt-2 p-0">
            <div class='category-block' *ngFor='let category of selectedCategories'>
              {{ category.name }}
              <button class='btn btn-danger btn-sm' (click)='removeCategory(category)'>
                <i class='fa fa-times' aria-hidden='true'></i>
              </button>
            </div>
          </div>
          <div class="col-3 mt-2 p-0 text-end">
            <button class='mr-1 mt-2' nbButton status='primary' size='tiny' (click)='exportMobile()'><i
              class='fa fa-download mr-1 fa-pull-right'></i> Mobile
            </button>
            <button class='mr-1 mt-2' nbButton status='primary' size='tiny' (click)='exportAddress()'><i
              class='fa fa-download mr-1 fa-pull-right'></i> Address
            </button>
          </div>
        </div>


        <!--        END FILTER-->
        <div class='text-center mt-5 mb-4' *ngIf='!displayList'>
          Please enter address
        </div>
        <div class='text-center mt-5 mb-4' *ngIf='displayList && wrapItems.length === 0'>
          We can not find any address match your criteria
        </div>
        <div *ngIf='displayList && wrapItems.length > 0'>
          <table class='table freeze mt-2'>
            <tr>
              <th class='border-top-0 w-6'>
                <nb-checkbox id='all' [(ngModel)]='allChecked' [ngModelOptions]=' {standalone: true}'
                             (checkedChange)='checkAll($event)'></nb-checkbox>
              </th>
              <th class='border-top-0'>
                <div class='row m-0'>
                  <button *ngIf='prospectIdCheckedList.length > 0' nbButton size='tiny' status='primary'
                          (click)='clearAllSelected()'>Clear All Selected
                  </button>
                  <div *appPermissionCheck='ComponentPermission.CLIENT_DELETE'>
                    <button nbButton class='ml-2' size='tiny' hero status='danger'
                            *ngIf='prospectIdCheckedList.length > 0'
                            (click)='removeSelected()'>Remove Selected
                    </button>
                  </div>
                </div>
              </th>
            </tr>
            <tr *ngFor='let wrapItem of wrapItems, let index = index'>
              <td colspan="2" class="p-0">
                <table class="table m-0" [ngClass]='{"is-expanding" : wrapItem?.isExpanded}'>
                  <!-- display parent row -->
                  <tr class="row-prospect"
                      [ngClass]='{"expand-unit" : wrapItem?.isGrouped}'
                      [class]="WrapperUtils.highlightProspectRow(wrapItem)">
                    <td class='w-6'>
                      <nb-checkbox id='{{wrapItem?.data.prospectId}}' [(ngModel)]='wrapItem.isSelected'
                                   (checkedChange)='onCheck($event, wrapItem.data)'></nb-checkbox>
                    </td>
                    <td (click)='selectRow(wrapItem)' class='pointer'>
                      <div class='row'>
                        <div class='col-8'>
                          <div class='col-12 mb-1'>
                            <div class='font-weight-bold name-line text-overflow-ellipsis'
                                 [ngClass]='stringUtils.getFullName(wrapItem?.data) === " " ? "blank-space" : ""'>{{ stringUtils.getFullName(wrapItem?.data) }}
                            </div>
                          </div>
                          <div class='col-12'>
                            <div
                              class='address-line text-overflow-ellipsis'>{{ stringUtils.getShortAddress(wrapItem?.data.address) }}
                            </div>
                          </div>
                        </div>
                        <div class='col-4 text-right' *ngIf="wrapItem?.data.totalUnit === 1">
                          <div class='col-12 mb-1'>
                            <div
                              class='font-weight-bold classification-line'>{{ stringUtils.getClassification(wrapItem?.data) }}
                            </div>
                          </div>
                          <div class='col-12'>
                            <div class='spec-line'>{{ stringUtils.getSpec(wrapItem?.data) }}</div>
                          </div>
                        </div>
                        <div class='col-4 text-right' *ngIf="wrapItem?.data.totalUnit > 1">
                          <div class='col-12 mb-1'>
                            <div class='font-weight-bold classification-line'>
                              {{ wrapItem?.data.totalUnit }} (units)
                            </div>
                          </div>
                          <div class='col-12'>
                            <div class='spec-line'>
                              <nb-icon class='ml-2' [icon]="'expand-outline'" pack='eva'></nb-icon>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <!-- display childs -->
                  <tr *ngFor='let subWrapItem of wrapItem.childs, let index = index'
                      class="row-prospect"
                      [ngClass]='subWrapItem?.isGrouped ? "expand-unit" : ""'
                      [class]="WrapperUtils.highlightProspectRow(subWrapItem)">
                    <td class='w-6'>
                      <nb-checkbox id='{{subWrapItem?.data.prospectId}}' [(ngModel)]='subWrapItem.isSelected'
                                   (checkedChange)='onCheck($event, subWrapItem)'></nb-checkbox>
                    </td>
                    <td (click)='selectRow(subWrapItem)'
                        class='pointer'>
                      <div class='row'>
                        <div class='col-8'>
                          <div class='col-12 mb-1'>
                            <div class='font-weight-bold name-line text-overflow-ellipsis'
                                 [ngClass]='stringUtils.getFullName(subWrapItem?.data) === " " ? "blank-space" : ""'>{{ stringUtils.getFullName(subWrapItem?.data) }}
                            </div>
                          </div>
                          <div class='col-12'>
                            <div
                              class='address-line text-overflow-ellipsis'>{{ stringUtils.getShortAddress(subWrapItem?.data.address) }}
                            </div>
                          </div>
                        </div>
                        <div class='col-4 text-right'>
                          <div class='col-12 mb-1'>
                            <div
                              class='font-weight-bold classification-line'>{{ stringUtils.getClassification(subWrapItem?.data) }}
                            </div>
                          </div>
                          <div class='col-12'>
                            <div class='spec-line'>{{ stringUtils.getSpec(subWrapItem?.data) }}</div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <div class='mt-3'>
            <nav>
              <ul class='pagination justify-content-center'>
                <li class='page-item pointer' [ngClass]='this.currentPage === 1 ? "disabled" : ""'>
                  <span class='page-link' (click)='loadPage(null, false)'>Previous</span>
                </li>
                <li class='page-item pointer' *ngFor='let page of pages'
                    [ngClass]='this.currentPage === page ? "active" : ""'>
                  <span class='page-link' (click)='loadPage(page, null)'>{{ page }}</span>
                </li>
                <li class='page-item pointer' [ngClass]='this.currentPage === this.totalPages ? "disabled" : ""'>
                  <span class='page-link' (click)='loadPage(null, true)'>Next</span>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </nb-card-body>
      <nb-card-footer class='p-0 mt-2 mb-2 border-top-0'>
        <div class='row m-0 text-center' *ngIf='displayList'>
          <div *ngFor='let button of prospectTagButtons' class='col p-0 d-flex justify-content-center'>
            <button nbButton class='custom-button d-block p-0'
                    (click)='selectProspectTag(button)' [status]='button.selected === true ? "warning" : "basic"'
                    value='{{button.value}}'>
              <div><i class='fa fa-dollar'></i></div>
              <div>{{ button.key }}</div>
            </button>
          </div>
          <!--          <div class='col p-0 d-flex justify-content-center'>-->
          <!--            <button class='custom-button d-block p-0' nbButton>-->
          <!--              <div>1M</div>-->
          <!--              <div>Search</div>-->
          <!--            </button>-->
          <!--          </div>-->
        </div>
      </nb-card-footer>
    </nb-card>
  </div>
  <div class='col-md-5 pl-0 col-sm-12 right-panel table-right-container'
       [ngClass]="{
       'popup-detail': isDisplay,
       'hide-detail': !isDisplay
       }">
    <ngx-prospect-dialog-v2
      [companies]="companies"
      [selectedProspect]='selectedProspect'
      (navigateProspectEvent)='navigateProspect($event)'
      (editDone)='editDone($event)'
      (onCloseDialog)='cancel($event)'
    >
    </ngx-prospect-dialog-v2>
    <!--      </nb-card-body>-->
    <!--    </nb-card>-->
  </div>
</div>
