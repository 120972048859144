<div class='col-12 d-flex ml-1'>
  <div class='col-12'>
    <button nbButton size='tiny' hero status="{{isTenantMode === true ? 'primary' : 'basic'}}" class='fa-pull-right'
            (click)='onTenantModeChange()'>Tenant
    </button>
    <button nbButton size='tiny' hero status="{{isBuyerMode === true ? 'primary' : 'basic'}}" class='fa-pull-right mr-1'
            (click)='onBuyerModeChange()'>Buyer
    </button>
  </div>
</div>
<div class='col-12 row d-flex mt-3 ml-1'>
  <div class='col-3'>
    <select fullWidth size='tiny'
            class='full-width'
            [(ngModel)]='fromMinBuyPrice'
            (change)='onFromMinBuyPriceInputChange()'
    >
      <option [value]='0 || null || undefined'>min$</option>
      <option *ngFor='let price of BUYER_PRICE()'
              [value]='price.key'
      >{{price.value}}</option>
    </select>
  </div>
  <div class='col-3'>
    <select fullWidth size='tiny'
            class='full-width'
            [(ngModel)]='toMaxBuyPrice'
            (change)='onToMaxBuyPriceInputChange()'
    >
      <option [value]='0 || null || undefined'>max$</option>
      <option *ngFor='let price of BUYER_PRICE()'
              [value]='price.key'
      >{{price.value}}</option>
    </select>
  </div>
  <div class='col-3'>
    <select size='tiny'
            [(ngModel)]='selectedStatus'
            class='full-width'
            (change)='onStatusSelectChange()'
    >
      <option [value]='undefined' disabled>Status</option>
      <option *ngFor='let key of activeType'
              [value]='key'
      >{{key}}</option>
    </select>
  </div>
  <div class='col-3'>
    <select size='tiny'
            [(ngModel)]='code'
            class='full-width'
            (change)='onCodeInputChange()'
    >
      <option [value]='undefined'>Class</option>
      <option *ngFor='let key of classes'
              [value]='key'
      >{{key}}</option>
    </select>
  </div>
</div>
<div class='col-12 row d-flex mt-2 ml-1'>
  <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-3 mb-1'>
    <select [(ngModel)]='selectedPropertyType'
            class='full-width'
            (change)='onPropertyTypeSelectChange()'>
      <option [value]='undefined'>Type</option>
      <option *ngFor='let item of propertyType | keyvalue:keepOrder'
              value='{{item.key}}'
      >{{item.value}}
      </option>
    </select>
  </div>
  <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-3 mb-1'>
    <select [(ngModel)]='selectedBed'
            class='full-width'
            (change)='onBedSelectChange()'>
      <option [value]='undefined'>Bed</option>
      <option *ngFor='let item of bedValues' [value]='item'>{{item}}</option>
    </select>
  </div>
  <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-3 mb-1'>
    <select [(ngModel)]='selectedBath'
            class='full-width'
            (change)='onBathSelectChange()'>
      <option [value]='undefined'>Bath</option>
      <option *ngFor='let item of bedValues' [value]='item'>{{item}}</option>
    </select>
  </div>
  <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-3'>
    <select [(ngModel)]='selectedCar'
            class='full-width'
            (change)='onCarSelectChange()'>
      <option [value]='undefined'>Car</option>
      <option *ngFor='let item of bedValues' [value]='item'>{{item}}</option>
    </select>
  </div>
  <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-6 margin-top-sm'>
    <input type='text' nbInput fieldSize='tiny'
           fullWidth placeholder='Phone'
           [(ngModel)]='phone'
           (change)='onPhoneInputChange()'
    >
  </div>
  <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-6 margin-top-sm'>
    <input type='number' nbInput fieldSize='tiny'
           fullWidth placeholder='Rate'
           [(ngModel)]='rate'
           (change)='onRateInputChange()'
    >
  </div>
</div>
<div class='col-12 row d-flex mt-2 ml-1'>
  <div class='col-xl-5 col-lg-5 col-md-6 col-sm-6 col-6 mb-1 property-type-container'>
    <input
      [(ngModel)]='categoryName'
      [ngModelOptions]='{standalone: true}'
      [typeahead]='filterCategory$'
      [typeaheadAsync]='true'
      (typeaheadOnSelect)='selectCategory($event)'
      typeaheadOptionField='name'
      typeaheadWaitMs='1000'
      placeholder='Tag'
      nbInput fieldSize='tiny' fullWidth>
    <div *ngFor='let category of selectedCategories' class='category-block mt-2'>
      {{category.name}}
      <button class='btn btn-danger btn-sm' (click)='removeCategory(category)'>
        <i class='fa fa-times' aria-hidden='true'></i>
      </button>
    </div>
  </div>
  <div class='col-xl-5 col-lg-5 col-md-6 col-sm-6 col-6 property-type-container'>
    <input
      *appPermissionCheck='ComponentPermission.GLOBAL_FILTER_USER'
      [(ngModel)]='userName'
      [ngModelOptions]='{standalone: true}'
      [typeahead]='filterUser$'
      [typeaheadAsync]='true'
      (typeaheadOnSelect)='selectUser($event)'
      typeaheadOptionField='name'
      typeaheadWaitMs='1000'
      placeholder='Search User'
      nbInput fieldSize='tiny' fullWidth>

    <div class='category-block mt-2' *ngIf='selectedUser'>
      {{ selectedUser && selectedUser.name ? selectedUser.name + ' (' + stringUtils.shortenUserName(selectedUser) + ')' : '' }}
      <button class='btn btn-danger btn-sm' (click)='removeUser()'
              *appPermissionCheck='ComponentPermission.GLOBAL_FILTER_USER'>
        <i class='fa fa-times' aria-hidden='true'></i>
      </button>
    </div>
  </div>
  <div class='col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mt-1'>
    <button nbButton size='tiny' hero status='primary' class='fa-pull-right'
            (click)='onSearchButtonClick()'>Search
    </button>
  </div>
</div>
<div class='col-12 row d-flex mt-2 ml-1'>
  <div class='col-md-4 col-6'>
    <select size='tiny'
            [(ngModel)]='selectedStreetId'
            [disabled]='!selectedDistrictId'
            class='full-width'
            (change)='onStreetSelectChange()'
    >
      <option [value]='undefined'>All streets</option>
      <option *ngFor='let key of streets'
              [ngClass]="key.status === 'DEACTIVE' ? 'deactive-status' : ''"
              [value]='key.streetId'
      >{{key.name}}</option>
    </select>
  </div>
  <div class='col-md-4 col-6'>
    <select size='tiny'
            [(ngModel)]='selectedDistrictId'
            [disabled]='!filterByPostcode'
            class='full-width'
            (change)='onSuburbSelectChange()'
    >
      <option [value]='undefined'>All suburbs</option>
      <option *ngFor='let key of districts'
              [ngClass]="key.status === 'DEACTIVE' ? 'deactive-status' : ''"
              [value]='key.districtId'
      >{{key.name}}</option>
    </select>
  </div>
  <div class='col-md-4 col-12 margin-top-sm'>
    <nb-form-field>
      <input nbInput fullWidth placeholder='Postcode' fieldSize='tiny' class='override-nbinput-height'
             [(ngModel)]='postcode'
             [typeahead]='filterPostcode$'
             [ngModelOptions]='{standalone: true}'
             [typeaheadAsync]='true'
             (typeaheadOnSelect)='selectPostcode($event)'
             typeaheadOptionField='code'
             typeaheadMinLength='3'
             typeaheadWaitMs='1000'>
      <!--      <span nbSuffix (click)='searchSuburbByPostcode()'><i class='fa fa-search'></i></span>-->
    </nb-form-field>
  </div>
<!--  <div class='col-3'>-->
<!--    <select size='tiny'-->
<!--            [(ngModel)]='selectedCityId'-->
<!--            class='full-width'-->
<!--            (change)='onCitySelectChange()'-->
<!--    >-->
<!--      <option [value]='undefined'>All cities</option>-->
<!--      <option *ngFor='let key of cities'-->
<!--              [ngClass]="key.status === 'DEACTIVE' ? 'deactive-status' : ''"-->
<!--              [value]='key.cityId'-->
<!--      >{{key.name}}</option>-->
<!--    </select>-->
<!--  </div>-->
</div>

<div class='col-12 row d-flex mt-2 ml-1'>
  <div class='col-2 override-width'>
    <select fullWidth size='tiny'
            [(ngModel)]='selectedDay'
            class='full-width'
            (change)='onDaySelectChange()'
            [disabled]='!selectedWeek'
    >
      <option [ngValue]='undefined'>Day</option>
      <option *ngFor='let item of days'
              [value]='item'
      >{{item}}</option>
    </select>
  </div>
  <div class='col-2 override-width'>
    <select fullWidth size='tiny'
            [(ngModel)]='selectedWeek'
            class='full-width'
            (change)='onWeekSelectChange()'
            [disabled]='!selectedMonth'
    >
      <option [ngValue]='undefined'>Week</option>
      <option *ngFor='let item of weeks'
              [value]='item'
      >{{item}}</option>
    </select>
  </div>
  <div class='col-2 override-width'>
    <select fullWidth size='tiny'
            [(ngModel)]='selectedMonth'
            class='full-width'
            (change)='onMonthSelectChange()'
            [disabled]='!selectedQuarter'
    >
      <option [ngValue]='undefined'>Month</option>
      <option *ngFor='let item of months'
              [value]='item'
      >{{item}}</option>
    </select>
  </div>
  <div class='col-2 override-width'>
    <select fullWidth size='tiny'
            [(ngModel)]='selectedQuarter'
            class='full-width'
            (change)='onQuarterSelectChange()'
            [disabled]='!selectedYear'
    >
      <option [ngValue]='undefined'>Quarter</option>
      <option *ngFor='let item of quarters'
              [value]='item'>{{item}}</option>
    </select>
  </div>
  <div class='col-2 override-width'>
    <select fullWidth size='tiny'
            [(ngModel)]='selectedYear'
            class='full-width'
            (change)='onYearSelectChange()'
    >
      <option [ngValue]='undefined'>Year</option>
      <option *ngFor='let item of years'
              [value]='item'
      >{{item}}</option>
    </select>
  </div>
</div>
<div class='col-12 d-flex mt-2'>
  <div class='col-4'>
    {{total}}
  </div>
  <div class='col-8'>
    <!--    <nb-form-field>-->
    <!--      <input type='text' nbInput fullWidth fieldSize='tiny' placeholder='Text' [(ngModel)]='log'>-->
    <!--      <button nbButton nbSuffix size='tiny' hero status='primary' class='mr-4' (click)='bulkSave()'>Save</button>-->
    <!--    </nb-form-field>-->
    <button nbButton size='tiny' hero status='primary' class='fa-pull-right mr-2 mb-1' (click)='exportEmail()'><i
      class='fa fa-download mr-1'></i>Email
    </button>
    <button nbButton size='tiny' hero status='primary' class='fa-pull-right mr-2' (click)='exportMobile()'><i
      class='fa fa-download mr-1'></i>Mobile
    </button>
  </div>
</div>
