import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, DatePipe, LocationStrategy, PathLocationStrategy, registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { IconModule, IconSetModule, IconSetService } from '@coreui/icons-angular';
import { AppComponent } from './app.component';
// Import containers

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppFooterModule,
  AppHeaderModule,
  AppSidebarModule,
} from '@coreui/angular';
// Import routing module
import { AppRoutingModule } from './app.routing';
// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { LoadingService } from './shared/services/loading.service';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { RequestInterceptor } from './shared/interceptor/request.interceptor';
import { ResponseInterceptor } from './shared/interceptor/response.interceptor';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import vi from '@angular/common/locales/vi';
import { AuthGuardService } from './shared/services/auth.guard.service';
import { AuthService } from './shared/services/auth.service';
import { CommonService } from './shared/services/common.service';
import { ErrorService } from './shared/services/error.service';
import { UserService } from './shared/services/user.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LocalService } from './shared/services/local.service';
import { BuyerTaskService } from './shared/services/buyer-task.service';
import { BuyerDepositService } from './shared/services/buyer-deposit.service';
import { InterestZoneService } from './shared/services/interest-zone.service';
import { InterestDistrictService } from './shared/services/interest-district.service';
import { TaskService } from './shared/services/task.service';
import { SosQuestionService } from './shared/services/sos-question.service';
import { SosAnswerService } from './shared/services/sos-answer.service';
import { CityService } from './shared/services/city.service';
import { SuburbService } from './shared/services/suburb.service';
import { FarmService } from './shared/services/farm.service';
import { StreetService } from './shared/services/street.service';
import { ZoneService } from './shared/services/zone.service';
import { PermissionService } from './shared/services/permission.service';
import { ProspectService } from './shared/services/prospect.service';
import { BuyerService } from './shared/services/buyer.service';
import { CompanyService } from './shared/services/company.service';
import { TaskTypeService } from './shared/services/task-type.service';
import { TaskStatusService } from './shared/services/task-status.service';
import { DashboardService } from './shared/services/dashboard.service';
import { ToastrModule } from 'ngx-toastr';
import {
  NbButtonModule,
  NbChatModule,
  NbDatepickerModule,
  NbDialogModule,
  NbFormFieldModule, NbIconModule, NbInputModule,
  NbLayoutModule,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule, NbToggleModule, NbTooltipModule,
  NbWindowModule,
} from '@nebular/theme';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxLoadingModule } from 'ngx-loading';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import { DirectivesModule } from './shared/directive/directives.module';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { PagesModule } from './modules/pages/pages.module';
import { ThemeModule } from './@theme/theme.module';
import { CoreModule } from './@core/core.module';
import { LayoutComponent } from './modules/pages/layout';
import { ErrorComponent } from './modules/pages/error/error.component';
import { CategoryService } from './shared/services/category.service';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { NationalityNoteService } from './shared/services/nationality.note.service';
import { CustomerService } from './shared/services/customer.service';
import { TemplateService } from './shared/services/template.service';
import { AddressService } from './shared/services/address.service';
import { OfficeService } from './shared/services/office.service';
import { OfficePropertyService } from './shared/services/office-property.service';
import { ComponentPermission } from './component.permission';
import { VendorService } from './shared/services/vendor.service';
import { VpaService } from './shared/services/vpa.service';
import { ContractChecklistService } from './shared/services/contract-checklist.service';
import { MarketingService } from './shared/services/marketing.service';
import { AgentService } from './shared/services/agent.service';
import { AgentGroupService } from './shared/services/agent.group.service';
import { AttachmentService } from './shared/services/attachment.service';
import { TextFieldModule } from '@angular/cdk/text-field';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { BlogService } from './shared/services/blog.service';
import { ClientLayoutComponent } from './modules/client/client-layout';
import { BulkLogService } from './shared/services/bulk.log.service';
import { EmailEventService } from './shared/services/email-event.service';
import { EmailGroupService } from './shared/services/email-group.service';
import { PostcodeService } from './shared/services/postcode.service';
import * as moment from 'moment';
import 'moment-precise-range-plugin';
import { NgxVcardModule } from 'ngx-vcard';
import {
  RxSpeechRecognitionService,
  SpeechRecognitionModule,
  SpeechRecognitionService
} from '@kamiazya/ngx-speech-recognition';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

const APP_CONTAINERS = [
  ErrorComponent,
  LayoutComponent,
  ClientLayoutComponent,
];

declare module 'moment' {
  function preciseDiff(start: string | Date | moment.Moment, end: string | Date |
    moment.Moment, convertToObject?: boolean): any;
}

registerLocaleData(vi);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  imports: [
    TranslateModule.forRoot(
      {
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      },
    ),
    NgxVcardModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NgxLoadingModule,
    TextFieldModule,
    SpeechRecognitionModule.withConfig({
      lang: 'en-AU',
      interimResults: false,
      maxAlternatives: 1
    }),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDateFnsDateModule.forRoot({ format: 'dd/MM/yyyy' }),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 400,
      positionClass: 'toast-center-center',
    }),
    NbChatModule.forRoot({
      messageGoogleMapKey: 'AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY',
    }),
    CoreModule.forRoot(),
    ThemeModule.forRoot(),
    PagesModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    DirectivesModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    CarouselModule,
    AppFooterModule,
    AppHeaderModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    IconModule,
    IconSetModule.forRoot(),
    NbDialogModule.forRoot(),
    TypeaheadModule.forRoot(),
    NbLayoutModule,
    NbFormFieldModule,
    NbInputModule,
    NbButtonModule,
    NbTooltipModule,
    NbIconModule,
    NbToggleModule,
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'en' },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      multi: true,
    },
    ComponentPermission,
    AuthGuardService,
    AuthService,
    ErrorService,
    SpeechRecognitionService,
    RxSpeechRecognitionService,
    CommonService,
    UserService,
    BsModalService,
    LoadingService,
    AuthService,
    CategoryService,
    LocalService,
    UserService,
    BuyerTaskService,
    CustomerService,
    BuyerDepositService,
    InterestZoneService,
    InterestDistrictService,
    TaskService,
    SosQuestionService,
    SosAnswerService,
    CityService,
    SuburbService,
    AddressService,
    FarmService,
    StreetService,
    ZoneService,
    PermissionService,
    ProspectService,
    BuyerService,
    CompanyService,
    TaskTypeService,
    TaskStatusService,
    DashboardService,
    IconSetService,
    NationalityNoteService,
    TemplateService,
    OfficeService,
    OfficePropertyService,
    VendorService,
    VpaService,
    ContractChecklistService,
    MarketingService,
    AgentService,
    AgentGroupService,
    AttachmentService,
    BlogService,
    BulkLogService,
    EmailEventService,
    EmailGroupService,
    DatePipe,
    PostcodeService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
