import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ProspectComponent} from './prospect.component';
import {ProspectListBasicComponent} from './prospect-list-basic/prospect-list-basic.component';
import {ProspectListCustomCellComponent} from './common/prospect-list-custom-cell-component';
import {ProspectCompanyComponent} from './prospect-company/prospect-company.component';
import {ProspectCompanyCreateComponent} from './prospect-company/create/prospect-company-create.component';
import {ProspectCompanyUpdateComponent} from './prospect-company/update/prospect-company-update.component';
import {ProspectMapComponent} from './prospect-map/prospect-map.component';
import {ProspectListFilterComponent} from './prospect-list-basic/childs/filter/prospect-list-filter.component';
import {ProspectCreateDialogComponent} from './prospect-create-dialog/prospect-create-dialog.component';
import {ProspectListAddressCellComponent} from './common/prospect-list-address-cell-component';
import {ProspectActionCellComponent} from './common/prospect-action-cell-component';
import {ProspectCreateUnitDialogComponent} from './prospect-create-unit-dialog/prospect-create-unit-dialog.component';
import {ProspectListAdvanceComponent} from './prospect-list-advance/prospect-list-advance.component';
import {ProspectListComponent} from './prospect-list/prospect-list.component';
import {ProspectListFilterAdvanceComponent} from './prospect-list-advance/filter/prospect-list-filter-advance.component';
import {ClientBoardComponent} from './client-board/client-board.component';
import {ClientBoardFilterAdvanceComponent} from './client-board/filter/client-board-filter-advance.component';
import {ProspectSelectComponent} from './common/prospect-select-component';
import {ProspectListMarketComponent} from './prospect-list-market/prospect-list-market.component';
import {ProspectListFilterMarketComponent} from './prospect-list-market/filter/prospect-list-filter-market.component';
import {ProspectShortcutComponent} from './prospect-shortcut/prospect-shortcut.component';
import {ProspectMarketCellComponent} from './common/prospect-market-cell-component';
import {ProspectMarketPropertyStatusComponent} from './common/prospect-market-property-status-component';
import {ProspectMarketPriceComponent} from './common/prospect-market-price-component';
import {ProspectMarketCompanyComponent} from './common/prospect-market-company-component';
import {ProspectListCallbackdateComponent} from './prospect-list-callbackdate/prospect-list-callbackdate.component';
import {ProspectListFilterCallbackdateComponent} from './prospect-list-callbackdate/filter/prospect-list-filter-callbackdate.component';
import {ProspectListPurchasedateComponent} from './prospect-list-purchasedate/prospect-list-purchasedate.component';
import {ProspectListFilterPurchasedateComponent} from './prospect-list-purchasedate/filter/prospect-list-filter-purchasedate.component';
import {ProspectPurchaseYearsCellComponent} from './common/prospect-purchase-years-cell-component';
import {ProspectListDobComponent} from './prospect-list-dob/prospect-list-dob.component';
import {ProspectListFilterDobComponent} from './prospect-list-dob/filter/prospect-list-filter-dob.component';
import {ProspectDobAgeCellComponent} from './common/prospect-dob-age-cell-component';
import {CompanyBoardComponent} from './company-board/company-board.component';
import {CompanyBoardFilterAdvanceComponent} from './company-board/filter/company-board-filter-advance.component';
import { VisualBoardAgentCellComponent } from './common/visual-board-agent-cell-component';
import { VisualBoardPriceCellComponent } from './common/visual-board-price-cell-component';
import { VisualBoardOtmDateCellComponent } from './common/visual-board-otm-date-cell-component';
import {ClientListComponent} from "./client-list/client-list.component";

const routes: Routes = [{
  path: '',
  component: ProspectComponent,
  children: [
    {
      path: 'list',
      component: ProspectListComponent,
    },
    {
      path: 'company/list',
      component: ProspectCompanyComponent,
    },
    {
      path: 'client-board',
      component: ClientBoardComponent,
    },
    {
      path: 'company-board',
      component: CompanyBoardComponent,
    },
    {
      path: 'purchase-date',
      component: ProspectListPurchasedateComponent,
    },
    {
      path: 'client-list',
      component: ClientListComponent,
    }
    // {
    //   path: 'dob',
    //   component: ProspectListDobComponent,
    // },
  ],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProspectRoutingModule {
}

export const routedComponents = [
  ProspectComponent,
  ProspectListBasicComponent,
  ProspectListCustomCellComponent,
  ProspectCompanyComponent,
  ProspectCompanyCreateComponent,
  ProspectCompanyUpdateComponent,
  ProspectMapComponent,
  ProspectListFilterComponent,
  ProspectCreateDialogComponent,
  ProspectListAddressCellComponent,
  ProspectActionCellComponent,
  ProspectCreateUnitDialogComponent,
  ProspectListAdvanceComponent,
  ProspectListComponent,
  ProspectListFilterAdvanceComponent,
  ClientBoardComponent,
  ClientBoardFilterAdvanceComponent,
  CompanyBoardComponent,
  CompanyBoardFilterAdvanceComponent,
  ProspectSelectComponent,
  ProspectListMarketComponent,
  ProspectListFilterMarketComponent,
  ProspectShortcutComponent,
  ProspectMarketCellComponent,
  ProspectMarketPropertyStatusComponent,
  ProspectMarketPriceComponent,
  ProspectMarketCompanyComponent,
  ProspectListCallbackdateComponent,
  ProspectListFilterCallbackdateComponent,
  ProspectListPurchasedateComponent,
  ProspectListFilterPurchasedateComponent,
  ProspectPurchaseYearsCellComponent,
  ProspectListDobComponent,
  ProspectListFilterDobComponent,
  ProspectDobAgeCellComponent,
  VisualBoardAgentCellComponent,
  VisualBoardPriceCellComponent,
  VisualBoardOtmDateCellComponent,
];
