<nb-card *appPermissionCheck="ComponentPermission.SUBURB_EDIT">
  <nb-card-header>
    <span>Update</span>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="form">

      <div class="form-group" [ngClass]="showError('name')">
        <label class="label">Name</label>
        <input type="text" nbInput fullWidth formControlName="name" name="name">
      </div>

      <div class="form-group" [ngClass]="showError('code')">
        <label class="label">Postcode</label>
        <input type="text" nbInput fullWidth formControlName="code" name="code">
      </div>


<!--      <div class="form-group" [ngClass]="showError('cityId')">-->
<!--        <label class="label">City</label>-->
<!--        <nb-select fullWidth formControlName="cityId">-->
<!--          <nb-option *ngFor="let key of cities"-->
<!--                     [ngClass]="key.status === 'DEACTIVE' ? 'deactive-status' : ''"-->
<!--                     value={{key.cityId}}>{{key.name}}-->
<!--          </nb-option>-->
<!--        </nb-select>-->
<!--      </div>-->

      <div class="form-group">
        <label class="label">Created Date</label>
        <input nbInput
               fullWidth
               placeholder="Created Date"
               formControlName="createdDate"
               [nbDatepicker]="ngCreatedDate">
        <nb-datepicker #ngCreatedDate></nb-datepicker>
      </div>

      <div class="form-group">
        <label class="label">Status</label>
        <nb-select formControlName="status">
          <nb-option *ngFor="let key of STATUS" value={{key}} selected>{{key | translate}}</nb-option>
        </nb-select>
      </div>

      <div class="form-group" [ngClass]="showError('color')">
        <label class="label">Color</label>
        <ngx-color-palette
          [defaultColor]="form.controls?.color?.value"
          (colorChange)="colorChange($event)"
        ></ngx-color-palette>
      </div>
    </form>
  </nb-card-body>
  <nb-card-footer class="dialog-footer">
    <button class="cancel" nbButton status="danger" (click)="cancel()">Cancel</button>
    <button nbButton status="success" (click)="submit()">Save</button>
  </nb-card-footer>
</nb-card>
