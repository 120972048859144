import { Component, Input, OnInit } from '@angular/core';
import { ProspectListCommonComponent } from '../common/prospect-list-common.component';
import { ProspectUtils, WrapProspect, WrapProspectType } from '../common/prospect-utils';
import { ProspectListAddressCellComponent } from '../common/prospect-list-address-cell-component';
import { ProspectActionCellComponent } from '../common/prospect-action-cell-component';
import { ProspectSelectComponent } from '../common/prospect-select-component';
import { Prospect } from '../../../../shared/models/response/prospect/prospect';
import { NbDialogService } from '@nebular/theme';
import { ToastService } from '../../../../shared/services/toast.service';
import { ProspectService } from '../../../../shared/services/prospect.service';
import { CommonService } from '../../../../shared/services/common.service';
import { RestResult } from '../../../../shared/models/response/rest-result';
import { SearchResult } from '../../../../shared/models/response/search-result';
import { ProspectShortcutComponent } from '../prospect-shortcut/prospect-shortcut.component';
import { LocalService } from '../../../../shared/services/local.service';
import { ProspectMarketCellComponent } from '../common/prospect-market-cell-component';
import { BuyerDateUtils } from '../../buyer/common/buyer-date-utils';
import { DateUtils } from '../../../../shared/common/date-utils';
import { ProspectMarketPropertyStatusComponent } from '../common/prospect-market-property-status-component';
import { ProspectListCustomCellComponent } from '../common/prospect-list-custom-cell-component';
import { ProspectMarketPriceComponent } from '../common/prospect-market-price-component';
import { ProspectMarketCompanyComponent } from '../common/prospect-market-company-component';
import { ExportDialogComponent } from '../../buyer/export-dialog/export-dialog.component';
import { Constant } from '../../../../shared/common/constant';
import { AuthService } from '../../../../shared/services/auth.service';
import { ComponentPermission } from '../../../../component.permission';
import { CustomRow } from '../../../../shared/models/common/custom-row';
import { DataSourceUtil } from '../../../../shared/models/utils/datasource-util';
import { SearchRequest } from '../../../../shared/models/request/search-request';
import { TemplateService } from '../../../../shared/services/template.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Postcode } from '../../../../shared/models/response/postcode';
import { map } from 'rxjs/operators';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

@Component({
  selector: 'ngx-prospect-list-market',
  templateUrl: './prospect-list-market.component.html',
  styleUrls: ['./prospect-list-market.component.scss'],
})
export class ProspectListMarketComponent extends ProspectListCommonComponent implements OnInit {
  ComponentPermission = ComponentPermission;
  @Input() isFilter: boolean;
  @Input() isLoad: boolean;

  isDisplay: boolean;
  viewMap = false;
  prospects: Prospect[];
  selectedMarket: string;
  markets = ['S', 'R', 'O', 'V'];
  marketRequest = {
    S: 'sell',
    R: 'rent',
    O: 'otm',
    V: 'vac',
  };
  isOTMMode: boolean = true;
  isVACMode: boolean = false;
  selectedYear: number;
  selectedMonth: number;
  selectedDay: number;

  contactOptionReplacement = (replace: string) => {
    return {
      true: replace,
      false: '',
    };
  }

  columnsDefinition = {
    checkbox: {
      enable: true,
      value: this.createSelectColumn(''),
    },
    propertyType: {
      enable: true,
      value: ProspectUtils.createColumn('', ['property.type'],
        null, null, this.propertyTypeReplacement),
      name: 'Type',
    },
    address: {
      enable: true,
      value: ProspectUtils.createColumn('',
        null, null, ', ', null, ProspectListAddressCellComponent),
      name: 'Address',
    },
    mail: {
      enable: true,
      value: ProspectUtils.createColumn('', ['mail'], null, null, this.contactOptionReplacement('M')),
      name: 'M',
    },
    door: {
      enable: true,
      value: ProspectUtils.createColumn('', ['door'], null, null, this.contactOptionReplacement('D')),
      name: 'D',
    },
    call: {
      enable: true,
      value: ProspectUtils.createColumn('', ['call'], null, null, this.contactOptionReplacement('C')),
      name: 'C',
    },
    sms: {
      enable: true,
      value: ProspectUtils.createColumn('', ['sms'], null, null, this.contactOptionReplacement('S')),
      name: 'S',
    },
    company: {
      enable: true,
      value: this.createMarketCompanyColumn('Company'),
      name: 'Company',
    },
    price: {
      enable: true,
      value: this.createMarketPriceColumn('Price'),
      name: 'Price',
    },
    propertyStatus: {
      enable: true,
      value: this.createMarketPropertyStatusColumn('Property status'),
      name: 'Property status',
    },
    otmDays: {
      enable: true,
      value: this.createMarketColumn('Otm days', true, false),
      name: 'Otm days',
    },
    vacDays: {
      enable: true,
      value: this.createMarketColumn('Vac days', false, true),
      name: 'Vac days',
    },
    action: {
      enable: true,
      value: this.createActionColumn('Action'),
      name: 'Action',
    },
  };

  settings = {
    mode: 'external',
    hideSubHeader: true,
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    pager: {
      display: true,
      perPage: 10000,
    },
    columns: this.createColumns(),
    rowClassFunction: (row) => {
      return ProspectUtils.highlightProspectRow(row?.data);
    },
  };

  createActionColumn(title: string) {
    return {
      title: title,
      type: 'custom',
      filter: false,
      renderComponent: ProspectActionCellComponent,
      onComponentInitFunction: (instance: ProspectActionCellComponent) => {
        instance.displayOtm = false;
        instance.onAddProspect.subscribe(value => {
          this.addProspectsByUnitRange(value);
        });
      },
    };
  }
  createSelectColumn(title: string) {
    return {
      title: title,
      type: 'custom',
      filter: false,
      renderComponent: ProspectSelectComponent,
      onComponentInitFunction: (instance: ProspectSelectComponent) => {
        instance.onSelectProspect.subscribe((value: WrapProspect) => {
          this.checkProspectToRemove(value);
        });
      },
    };
  }

  createMarketColumn(title: string, displayOtm: boolean, displayVac: boolean) {
    return {
      title: title,
      type: 'custom',
      filter: false,
      renderComponent: ProspectMarketCellComponent,
      onComponentInitFunction: (instance: ProspectMarketCellComponent) => {
        instance.displayOtm = displayOtm;
        instance.displayVac = displayVac;
      },
    };
  }

  createColumns() {
    const columns = {};
    for (const key of Object.keys(this.columnsDefinition)) {
      if (this.columnsDefinition[key].enable === true) {
        columns[key] = this.columnsDefinition[key].value;
      }
    }
    return columns;
  }

  createMarketPropertyStatusColumn(title: string) {
    return {
      title: title,
      type: 'custom',
      filter: false,
      renderComponent: ProspectMarketPropertyStatusComponent,
      onComponentInitFunction: (instance: any) => {
        if (instance instanceof ProspectMarketPropertyStatusComponent) {
          instance.isOTMMode = this.isOTMMode;
          instance.isVACMode = this.isVACMode;
        }
      },
    };
  }

  createMarketPriceColumn(title: string) {
    return {
      title: title,
      type: 'custom',
      filter: false,
      renderComponent: ProspectMarketPriceComponent,
      onComponentInitFunction: (instance: any) => {
        if (instance instanceof ProspectMarketPriceComponent) {
          instance.isOTMMode = this.isOTMMode;
          instance.isVACMode = this.isVACMode;
        }
      },
    };
  }

  createMarketCompanyColumn(title: string) {
    return {
      title: title,
      type: 'custom',
      filter: false,
      renderComponent: ProspectMarketCompanyComponent,
      onComponentInitFunction: (instance: any) => {
        if (instance instanceof ProspectMarketCompanyComponent) {
          instance.isOTMMode = this.isOTMMode;
          instance.isVACMode = this.isVACMode;
        }
      },
    };
  }

  constructor(private _dialogService: NbDialogService,
              private _toastService: ToastService,
              private _prospectService: ProspectService,
              private _commonService: CommonService,
              private _localService: LocalService,
              private _authService: AuthService,
              private _templateService: TemplateService,
              private _router: Router,
              private _route: ActivatedRoute) {
    super(_dialogService, _toastService, _prospectService, _commonService, _localService, _authService, _router, _route, _templateService);
  }

  ngOnInit() {
    this.reducedMode = true;
    this.param = this.route.snapshot.queryParams;
    if (this.param['vac']) {
      this.isVACMode = true;
      this.isOTMMode = undefined;
    }
    if (this.param['page']) {
      this.currentPage = this.param['page'];
    }
    this.currentUser = this.authService.currentUser;
    this.searchRequest.sortType = undefined;
    this.source.onChanged().subscribe((change) => {
      this.change = change;
      if (change.action === 'refresh' && this.isLoad) {
        this.pageChange(1);
      }
      if (change.action === 'page') {
        delete this.param['selectedProspectId'];
        this.param = { ...this.param, page: change.paging.page };
        this.navigateWithParam();
        this.pageChange(change.paging.page, true);
      }
    });
    this.param = {...this.param, tab: this._selectedMode };
    if (this.currentUser.office.phone) {
      this.adminNumber = this.currentUser.office.phone;
    }
    this.preLoadAddressForFilter();
  }

  pageChange(pageIndex, isPaging?: boolean) {
    if (this.route.snapshot.queryParams['page'] && !isPaging) {
      pageIndex = Number(this.route.snapshot.queryParams['page']);
    }
    this.currentPage = pageIndex;
    this.searchRequest.mode = this._selectedMode;
    this.searchRequest.offset = (pageIndex - 1) * this.pageSize;
    if (this.searchRequest.offset < 0) {
      this.searchRequest.offset = 0;
    }
    this.searchRequest.limit = this.pageSize;
    if (this.isOTMMode) {
      this.searchRequest.otm = true;
    } else {
      this.searchRequest.otm = null;
    }
    if (this.isVACMode) {
      this.searchRequest.vac = true;
    } else {
      this.searchRequest.vac = null;
    }
    if (this.isFiltered) {
      this.appendFilterToParameter();
      this.appendMarketFilterToParameter();
      this.navigateWithParam();
    }
    if (!this.isFiltered) {
      this.appendParameterToSearchRequest();
      this.appendMarketParameterToSearchRequest();
    }
    this.searchRequest.loadOtmExtra = true;
    this.searchRequest.disableItemCountEachGroup = true;
    this.searchRequest.officeId = this.currentUser.office?.officeId;

    this.prospectService.searchGroups(this.searchRequest).subscribe((result: RestResult) => {
      this.groupWrapper = result.data;
    });

    this.getData(pageIndex, this.pageSize);

    // this.prospectService.getGroupItemsSimple(this.searchRequest).subscribe((result: RestResult) => {
    //   // const searchResult: SearchResult<Prospect> = result.data;
    //   // this.totalPage = Math.ceil(searchResult.recordsTotal / this.pageSize);
    //   this.selectedProspects = result.data;
    //   let wrapProspectItem;
    //   if (this.selectedProspects && this.selectedProspects.length !== 0) {
    //     if (this.route.snapshot.queryParams['selectedProspectId']) {
    //       const id = Number(this.route.snapshot.queryParams['selectedProspectId']);
    //       const item = this.selectedProspects.find(p => p.prospectId === id);
    //       wrapProspectItem = ProspectUtils.convertItemToWrapperData(item);
    //     } else {
    //       wrapProspectItem = ProspectUtils.convertItemToWrapperData(this.selectedProspects[0]);
    //     }
    //     this.wrapProspects = ProspectUtils.convertGroupItemToWrapperData(result.data);
    //     this.selectRecord({ data: wrapProspectItem }, false, false);
    //   }
    //   // const convertResult = ProspectUtils.convertGroupItemToWrapperData(searchResult.data);
    //   // this.buildData(this.pageSize, convertResult, () => {
    //   //   this.restoreState();
    //   // });
    //   // this.onProspectChange(this.prospectChange);
    //   DataSourceUtil.reloadDataProspectListing(this.source, pageIndex, this.pageSize, result);
    // });
  }

  onOtmStatusChange(status: string) {
    let value;
    if (status && status !== '' && status !== 'undefined') {
      value = status;
    }
    if (this.isVACMode) {
      this.searchRequest.vacStatus = value;
    } else if (this.isOTMMode) {
      this.searchRequest.otmStatus = value;
    }
  }

  onSearch(filtered: boolean) {
    this.isFiltered = filtered;
    if (this.param['selectedStreetId']) {
      delete this.param['selectedStreetId'];
    }
    if (this.param['selectedProspectId']) {
      delete this.param['selectedProspectId'];
    }
    if (this.param['page']) {
      delete this.param['page'];
    }
    this.pageChange(1);
  }

  onOTMVACModeChange(value: {
    isVACMode: boolean;
    isOTMMode: boolean;
  }) {
    this.isOTMMode = value.isOTMMode;
    this.isVACMode = value.isVACMode;
    this.searchRequest.vacStatus = undefined;
    this.searchRequest.otmStatus = undefined;
    this.searchRequest.fromOtmDate = undefined;
    this.searchRequest.toOtmDate = undefined;
    this.searchRequest.fromVacDate = undefined;
    this.searchRequest.toVacDate = undefined;
    this.pageChange(1);
  }

  onVACModeChange(enable?: boolean) {
    this.isVACMode = enable;
    this.isOTMMode = undefined;
    this.searchRequest.otmStatus = undefined;
    this.searchRequest.fromOtmDate = undefined;
    this.searchRequest.toOtmDate = undefined;
    this.searchRequest.fromSellPrice = undefined;
    this.searchRequest.toSellPrice = undefined;
    this.searchRequest.sellCompanyId = undefined;
    this.isFiltered = true;
    this.pageChange(1);
  }

  onOTMModeChange(enable?: boolean) {
    this.isOTMMode = enable;
    this.isVACMode = undefined;
    this.searchRequest.vacStatus = undefined;
    this.searchRequest.fromVacDate = undefined;
    this.searchRequest.toVacDate = undefined;
    this.searchRequest.fromRentPrice = undefined;
    this.searchRequest.toRentPrice = undefined;
    this.searchRequest.rentCompanyId = undefined;
    this.isFiltered = true;
    this.pageChange(1);
  }

  // filter
  onYearChange(value: number) {
    if (value) {
      this.selectedYear = value;
      const firstDay = BuyerDateUtils.getFirstDayOfYear(value);
      const lastDay = BuyerDateUtils.getLastDayOfYear(value);
      if (this.isOTMMode) {
        this.searchRequest.fromOtmDate = firstDay.valueOf();
        this.searchRequest.toOtmDate = lastDay.valueOf();
      } else {
        this.searchRequest.fromVacDate = firstDay.valueOf();
        this.searchRequest.toVacDate = lastDay.valueOf();
      }
    }
  }

  onMonthChange(value: number) {
    if (value && !this.selectedYear) {
      return;
    }
    if (value) {
      this.selectedMonth = value;
      const firstDay = DateUtils.getFirstDayOfMonth(value - 1, this.selectedYear);
      const lastDay = DateUtils.getLastDayOfMonth(value - 1, this.selectedYear);
      if (this.isOTMMode) {
        this.searchRequest.fromOtmDate = firstDay.valueOf();
        this.searchRequest.toOtmDate = lastDay.valueOf();
      } else {
        this.searchRequest.fromVacDate = firstDay.valueOf();
        this.searchRequest.toVacDate = lastDay.valueOf();
      }
    } else if (this.selectedYear) {
      this.onYearChange(this.selectedYear);
      return;
    }
  }

  onDayChange(value: number) {
    if (value && (!this.selectedYear || !this.selectedMonth)) {
      return;
    }
    if (value) {
      this.selectedDay = value;
      const firstDay = DateUtils.getStartOfDate(value, this.selectedMonth, this.selectedYear);
      const lastDay = DateUtils.getEndOfDate(value, this.selectedMonth, this.selectedYear);
      if (this.isOTMMode) {
        this.searchRequest.fromOtmDate = firstDay.valueOf();
        this.searchRequest.toOtmDate = lastDay.valueOf();
      } else {
        this.searchRequest.fromVacDate = firstDay.valueOf();
        this.searchRequest.toVacDate = lastDay.valueOf();
      }
    } else if (this.selectedMonth) {
      this.onMonthChange(this.selectedMonth);
      return;
    } else if (this.selectedYear) {
      this.onYearChange(this.selectedYear);
      return;
    }
  }

  appendMarketFilterToParameter() {
    if (this.selectedYear) {
      this.param = { ...this.param, selectedYear: this.selectedYear };
    } else {
      delete this.param['selectedYear'];
    }
    if (this.selectedMonth) {
      this.param = { ...this.param, selectedMonth: this.selectedMonth };
    } else {
      delete this.param['selectedMonth'];
    }
    if (this.selectedDay) {
      this.param = { ...this.param, selectedDay: this.selectedDay };
    } else {
      delete this.param['selectedDay'];
    }
  }

  appendMarketParameterToSearchRequest() {
    const params = this.route.snapshot.queryParams;
    if (params['selectedYear']) {
      this.onYearChange(params['selectedYear']);
    }
    if (params['selectedMonth']) {
      this.onMonthChange(params['selectedMonth']);
    }
    if (params['selectedDay']) {
      this.onDayChange(params['selectedDay']);
    }
  }
}
