import { Address } from '../models/response/address/address';
import { User } from '../models/response/user';
import { Constant } from './constant';
import * as moment from 'moment';
import {
  ProspectListCustomCellComponent,
} from '../../modules/pages/prospect/common/prospect-list-custom-cell-component';
import { Prospect } from '../models/response/prospect/prospect';

export class StringUtils {

  public static getFullAddress(address: Address): string {
    let result = '';
    if (address) {
      const formatted = [];
      if (address.numberFrom || address.numberTo) {
        let number = '';
        number += address.numberFrom ? address.numberFrom : '';
        number += address.numberFromLetter ? address.numberFromLetter : '';
        number += address.numberFrom && address.numberTo ? ' - ' : '';
        number += address.numberTo ? address.numberTo : '';
        number += address.numberToLetter ? address.numberToLetter : '';
        formatted.push(number);
      }
      if (address.street) {
        formatted.push(address.street.name);
        if (address.street.district) {
          formatted.push(address.street.district.name);
          if (address.street.district.code) {
            // const parts = address.street.district.code.split('.');
            // formatted.push(parts[0].toString());
            // abc
            formatted.push(address.street.district.code.toString());
          }
          // if (address.street.district.city) {
          //   formatted.push(address.street.district.city.name);
          // }
        }
      }
      result += formatted.map(s => s.trim()).join(', ');
      if (address.unit) {
        result = address.unit + '/' + result;
      }
    }
    return result;
  }

  public static getShortAddress(address: Address): string {
    let result = '';
    if (address) {
      const formatted = [];
      if (address.numberFrom) {
        let number = '';
        number += address.numberFrom ? address.numberFrom : '';
        // number += address.numberFromLetter ? address.numberFromLetter : '';
        // number += address.numberFrom && address.numberTo ? ' - ' : '';
        // number += address.numberTo ? address.numberTo : '';
        // number += address.numberToLetter ? address.numberToLetter : '';

        //  check if additional info have letter from or just number to
        if (address.additionalInfo && address.additionalInfo !== '') {
          if (address.additionalInfo.includes('-')) {
            number += address.additionalInfo;
          } else {
            number += '-' + address.additionalInfo;
          }
        }
        formatted.push(number);
      }
      if (address.street) {
        formatted.push(address.street.name);
        if (address.street.district) {
          formatted.push(address.street.district.name);
        }
        if (address.street.district.code) {
          // const parts = address.street.district.code.toString().split('.');
          // formatted.push(parts[0].toString());
          formatted.push(address.street.district.code.toString());
        }
      }
      result += formatted.map(s => s.trim()).join(', ');
      if (address.unit) {
        result = address.unit + '/' + result;
      }
    }
    return result;
  }

  public static formatAddress(address: Address): string[] {
    const result = [];
    result.push(this.getUnitAddress(address));
    result.push(this.getFromNumber(address));
    result.push(this.getRemainingAddress(address));
    return result;
  }

  public static getFromNumber(address: Address): string {
    let result = '';
    if (address) {
      const formatted = [];
      if (address.numberFrom || address.numberTo) {
        let number = '';
        number += address.numberFrom ? address.numberFrom : '';
        formatted.push(number);
      }
      result += formatted.map(s => s.trim());
    }
    return result;
  }

  public static getRemainingAddress(address: Address): string {
    let result = '';
    if (address) {
      const formatted = [];
      if (address.numberFrom || address.numberTo) {
        let number = '';
        // number += address.numberFromLetter ? address.numberFromLetter : '';
        // number += address.numberFrom && address.numberTo ? ' - ' : '';
        // number += address.numberTo ? address.numberTo : '';
        // number += address.numberToLetter ? address.numberToLetter : '';
        //  check if additional info have letter from or just number to
        if (address.additionalInfo && address.additionalInfo !== '') {
          if (address.additionalInfo.includes('-')) {
            number += address.additionalInfo;
          } else {
            number += '-' + address.additionalInfo;
          }
        }
        formatted.push(number);
      }
      if (address.street) {
        formatted.push(address.street.name);
        if (address.street.district) {
          formatted.push(address.street.district.name);
        }
      }
      result += formatted.map(s => s.trim());
    }
    return result;
  }

  public static getUnitAddress(address: Address): string {
    let result = '';
    if (address) {
      if (address.unit) {
        result += address.unit + ' / ' + result;
      } else {
        result = '';
      }
    }
    return result;
  }

  public static shortenUserName(user: User) {
    if (!user) {
      return '';
    }
    if (user.firstName || user.lastName) {
      return user?.firstName[0]?.toUpperCase() + user?.lastName[0]?.toUpperCase();
    }
    if (user.name) {
      return this.getShortName(user.name);
    }
    return this.getShortName(user.username);
  }

  static getShortName(name: string) {
    const items: string[] = name?.replace(/[\s]+/g, ' ').trim()?.split(' ');
    let result = '';
    for (const item of items) {
      result += item[0]?.toUpperCase();
    }
    return result;
  }

  static formatNumber(value: number): string {
    let unitNumber = 0;
    let unit = '';
    if (value >= 1000000000) {
      unitNumber = 1000000000;
      unit = 'B';
    } else if (value >= 1000000) {
      unitNumber = 1000000;
      unit = 'M';
    } else if (value >= 1000) {
      unitNumber = 1000;
      unit = 'K';
    } else {
      unitNumber = 1;
      unit = 'pw';
    }
    return StringUtils.formatFormatNumberResult(value / unitNumber) + unit;
  }

  static formatFormatNumberResult(value: number) {
    if ((value * 10) % 10 === 0) {
      return Math.round(value);
    }
    return value.toFixed(1);
  }

  static getFormattedValue(valueType: string, value: string): string {
    if (value) {
      switch (valueType) {
        case ProspectListCustomCellComponent.VALUE_TYPES.CURRENCY:
          value = new Intl.NumberFormat('en-IE').format(Number(value)).replace(',', '     ');
          // value = value.replace('.', ' ');
          value = `${value}${Constant.DEFAULT_CURRENCY_ICON}`;
          break;
        case ProspectListCustomCellComponent.VALUE_TYPES.DATE:
          value = moment(Number(value)).format('DD/MM/YYYY');
          break;
        default:
          break;
      }
    }
    return value;
  }

  static getNestedByPath(object: any, path: string): string {
    if (path && path.length > 0 && object) {
      const properties: string[] = path.split('.');
      if (properties && properties.length > 0) {
        const subObject = object[properties[0]];
        if (!subObject) {
          return '';
        }
        if (properties.length > 2) {
          properties.shift();
          return StringUtils.getNestedByPath(subObject, properties.join('.'));
        } else {
          return subObject[properties[1]];
        }
      } else {
        return object[path];
      }
    }
    return '';
  }

  static isNotEmpty(input: string): boolean {
    if (!input) {
      return false;
    }
    return input !== '';
  }

  static isEmpty(input: string): boolean {
    if (!input) {
      return true;
    }
    return input === '';
  }


  public static getClassification(prospect: Prospect): string {
    let result = '';
    if (prospect?.property?.sell) {
      result += 'S';
    }
    if (prospect?.property?.otm) {
      result += 'O';
    }
    if (prospect?.property?.rent) {
      result += 'R';
    }
    if (prospect?.property?.vac) {
      result += 'V';
    }
    result += '.';
    result += prospect?.rate;
    return result;
  }

  public static getFullName(prospect: Prospect): string {
    if (StringUtils.isEmpty(prospect?.customer?.name?.trim()) && StringUtils.isEmpty(prospect?.customer?.surname?.trim())) {
      return ' ';
    }
    let result = '';
    if (StringUtils.isNotEmpty(prospect?.customer?.name)) {
      result += prospect?.customer?.name;
    }
    if (StringUtils.isNotEmpty(prospect?.customer?.surname)) {
      result += ' ';
      result += prospect?.customer?.surname;
    }
    return result.trim();
  }

  public static getSpec(prospect: Prospect): string {
    let result = '';
    if (prospect?.property?.type && prospect?.property?.type.includes('_')) {
      const parts = prospect?.property?.type.split('_');
      for (let i = 0; i < parts.length; i++) {
        if (i === parts.length - 1) {
          if (parts[i].length === 2) {
            result += parts[i];
          } else {
            result += parts[i].substr(0, 1);
          }
        } else {
          result += parts[i].substr(0, 1);
        }
      }
    } else {
      result += prospect?.property?.type?.substr(0, 1);
    }
    result += '.';
    result += prospect?.property?.bed;
    result += '.';
    result += prospect?.property?.bath;
    result += '.';
    result += prospect?.property?.car;
    result += ' - ';
    result += prospect?.property?.land ? prospect?.property?.land : 0;
    return result;
  }

  static getLand(input: number): string[] {
    const result = [];
    if (!input) {
      result.push('0');
      result.push('m<sup>2</sup>');
    }
    if (input >= 10000) {
      result.push((input.toFixed(2).toString()));
      result.push('ha');
    } else {
      result.push(input);
      result.push('m<sup>2</sup>');
    }
    return result;
  }
}
