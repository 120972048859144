<form [formGroup]='prospectForm'>
  <nb-card class='dialog-container'>
    <nb-card-body>
      <div class='col-12 text-center'>
        <span class='mt-1'>{{formattedAddress[0]}}</span>
        <span class='mt-1 bigger-font'>{{formattedAddress[1]}}</span>
        <span class='mt-1'> {{formattedAddress[2]}}</span>
        <i class='fa fa-map ml-2 gmap' (click)='openGoogleMap()' *ngIf='data'></i>
        <i class='fa fa-search ml-2 search-address' (click)='searchAddress()' *ngIf='data'></i>
      </div>
      <div class='row mt-2'>
        <div class='col-2'>
          <div class='clearfix d-flex'>
            <button nbButton class='float-left' ghost size='small' status='danger'
                    (click)='navigateProspect(false)'>
              <nb-icon icon='arrow-ios-back-outline'></nb-icon>
            </button>
            <button nbButton class='float-left' ghost size='small' status='danger'
                    (click)='navigateProspect(true)'>
              <nb-icon icon='arrow-ios-forward-outline'></nb-icon>
            </button>
          </div>
        </div>
        <div class='col-3'>
          <button nbButton size='tiny' status='primary' class='ml-3 mt-1' (click)='displayStreet()'
                  *ngIf='data?.property?.otm'>Open Street
          </button>
          <button nbButton size='tiny' status='primary' class='ml-3 mt-1' (click)='openHome(data?.prospectId)'
                  *ngIf='data?.rate === 4 && hasSROV()'>Open Home
          </button>
        </div>
        <div class='col-2' *ngIf='data'>
          <button nbButton size='tiny' status='primary' class='mt-1' (click)='toggleDisplayUnit()'>Unit</button>
        </div>
        <div class='col-3' *ngIf='data'>
          <button *ngIf="data?.rate === 3 && data?.tasks && data?.tasks?.length > 0" nbButton size="tiny" [status]="isNotCount ? 'danger' : 'primary'"  class="mr-1 mt-1 fa-pull-right" (click)="isChangeRate()">Change Rate</button>
          <nb-icon icon='save-outline' pack='eva'
                   nbButton fullWidth size='large'
                   class="large max-right fa-pull-right mt-2"
                   [vcdDownloadVCard]="vCard"></nb-icon>
        </div>
        <div class='col-2 mt-1 float-right'>
          <select size='tiny' class='float-right' formControlName='rate' (change)='onSelectRate($event)'>
            <option [value]='null' disabled>Rate</option>
            <option *ngFor='let option of rateOptions' [value]='option.value'
            >{{option.label}}</option>
          </select>
          <div class='float-right justify-content-center mt-1 mr-2' *ngIf='data && linkedGroup?.length !== 0'>
            {{totalLinked}}
            <nb-icon [icon]="'people-outline'" pack='eva'></nb-icon>
          </div>
        </div>
      </div>
      <div class='row mt-3'>
        <button nbButton size='small' status='primary' (click)='displaySalesDetails()'
                *ngIf='data?.rate >= 4 && (data?.property?.sell === true || data?.property?.otm === true)
                && ComponentPermission.isAccess(ComponentPermission.CLIENT_LISTING_DETAILS)'>Sales Details
        </button>
        <button nbButton size='small' status='success' (click)='displayRentalDetails()' class='ml-2'
                *ngIf='data?.rate >= 4 && (data?.property?.rent === true || data?.property?.vac === true)
                && ComponentPermission.isAccess(ComponentPermission.CLIENT_LISTING_DETAILS)'>Rental Details
        </button>
      </div>
      <div class='row mt-3' [hidden]='!showUnit'>
        <div class='col-12 property-type-container'>
          <input type='text' nbInput fieldSize='tiny' fullWidth name='unit' placeholder='Unit'
                 formControlName='unit' class='mr-1'>
          &nbsp;/&nbsp;
          <input type='number' nbInput fieldSize='tiny' fullWidth name='numberFrom' placeholder='Number'
                 formControlName='numberFrom' class='mr-1'>
          <!--          <input type='text' nbInput fieldSize='tiny' fullWidth name='numberFromLetter' placeholder='Letter'-->
          <!--                 formControlName='numberFromLetter'>-->
          <!--          &nbsp;-&nbsp;-->
          <!--          <input type='text' nbInput fieldSize='tiny' fullWidth name='numberTo' placeholder='To'-->
          <!--                 formControlName='numberTo' class='mr-1'>-->
          <!--          <input type='text' nbInput fieldSize='tiny' fullWidth name='numberToLetter' placeholder='Letter'-->
          <!--                 formControlName='numberToLetter'>-->
          <input type='text' nbInput fieldSize='tiny' fullWidth name='additionalInfo' placeholder='Additional Info'
                 formControlName='additionalInfo' class='mr-1'>
        </div>
        <!--        <div class='col-12 property-type-container mt-1'>-->
        <!--          <input type='text' nbInput fieldSize='tiny' fullWidth name='number' placeholder='Address Note'-->
        <!--                 formControlName='number'>-->
        <!--          <label>{{data?.address?.street?.farm?.name}}</label>-->
        <!--          <label>{{data?.address?.street?.district?.zone?.name}}</label>-->
        <!--        </div>-->
      </div>
      <div class='row action-button mt-3' [hidden]='infoVisible === false'>
        <div class='col-2'>
          <button nbTooltip='Door Knock' nbButton size='tiny' class='btn-dk' hero
                  (click)='logContactStatus(contactLogType.DOOR_NOCKED)'>DK
          </button>
        </div>
        <div class='col-2'>
          <button nbTooltip='Call' nbButton size='tiny' class='btn-call' hero
                  (click)='logContactStatus(contactLogType.CALLED)'
                  [disabled]='prospectForm?.controls?.rate?.value > 3'
          >CALL
          </button>
        </div>
        <div class='col-2'>
          <button nbTooltip='SMS' nbButton size='tiny' class='btn-sms' hero
                  (click)='logContactStatus(contactLogType.SMS)'>SMS
          </button>
        </div>
        <div class='col-2'>
          <button nbTooltip='Appointment' nbButton size='tiny' class='btn-app' hero
                  (click)="createTask('APPOINTMENT')">APP
          </button>
        </div>
        <div class='col-2'>
          <button nbTooltip='Not Available' nbButton size='tiny' class='btn-na' hero
                  (click)='logContactStatus(contactLogType.NO_ANSWER)'>NA
          </button>
        </div>
        <div class='col-2'>
          <button nbTooltip='Save' nbButton size='tiny' status='success' (click)='submit()'
                  [disabled]='isUpdating === true'>Save
          </button>
        </div>
      </div>
      <div class='row action-button mt-3' [hidden]='infoVisible === true'>
        <div class='col-12'>
          This address is restricted, please contact super administrator to unlock.
        </div>
      </div>
      <div class='row mt-3'>
        <div class='col-3 square-container square-market'>
          <div class='col-12 d-flex text-center'>
            <label (click)='toggleDisplayMarket()' class='col-7 openExpand text-right non-select'>Property</label>
            <nb-icon class='ml-2' [icon]="'home-outline'" pack='eva'></nb-icon>
          </div>
          <div class='row'>
            <button nbTooltip='Sell' nbButton size='tiny'
                    class="col-6 {{prospectForm.controls?.sell?.value === true ? 'market-active' : 'market-inactive'}}"
                    hero (click)="setSquareValue('sell')">S
            </button>
            <button nbTooltip='Rent' nbButton size='tiny'
                    class="col-6 {{prospectForm.controls?.rent?.value === true ? 'market-active' : 'market-inactive'}}"
                    hero (click)="setSquareValue('rent')">R
            </button>
            <button nbTooltip='On The Market' nbButton size='tiny'
                    class="col-6 {{prospectForm.controls?.otm?.value === true ? 'market-active' : 'market-inactive'}}"
                    hero (click)="setSquareValue('otm')" [disabled]='!prospectForm.controls?.otmDate.value'>O
            </button>
            <button nbTooltip='VAC' nbButton size='tiny'
                    class="col-6 {{prospectForm.controls?.vac?.value === true ? 'market-active' : 'market-inactive'}}"
                    hero (click)="setSquareValue('vac')" [disabled]='!prospectForm.controls?.vacDate.value'>V
            </button>
          </div>
        </div>
        <div class='col-3 square-container square-contact'>
          <div class='col-12 d-flex text-center'>
            <label (click)='toggleDisplayContact()' class='col-7 openExpand text-right non-select'>Contact</label>
            <nb-icon class='ml-2' [icon]="'people-outline'" pack='eva'></nb-icon>
          </div>
          <div class='row'>
            <button nbTooltip='Mail?' nbButton size='tiny'
                    class="col-6 {{prospectForm.controls?.mail?.value === true ? 'contact-active' : 'contact-inactive'}}"
                    hero (click)="setSquareValue('mail')">M
            </button>
            <button nbTooltip='Door?' nbButton size='tiny'
                    class="col-6 {{prospectForm.controls?.door?.value === true ? 'contact-active' : 'contact-inactive'}}"
                    hero (click)="setSquareValue('door')">D
            </button>
            <button nbTooltip='Call?' nbButton size='tiny'
                    class="col-6 {{prospectForm.controls?.call?.value === true ? 'contact-active' : 'contact-inactive'}}"
                    hero (click)="setSquareValue('call')">C
            </button>
            <button nbTooltip='SMS?' nbButton size='tiny'
                    class="col-6 {{prospectForm.controls?.sms?.value === true ? 'contact-active' : 'contact-inactive'}}"
                    hero (click)="setSquareValue('sms')">S
            </button>
          </div>
        </div>
        <div class='col-3 square-container square-motive'>
          <div class='col-12 d-flex text-center'>
            <label (click)='toggleDisplayMotive()' class='col-7 openExpand text-right non-select'>Motive</label>
            <nb-icon class='ml-2' [icon]="'heart-outline'" pack='eva'></nb-icon>
          </div>
          <div class='row'>
            <button nbTooltip='Financial Up' nbButton size='tiny'
                    class="col-6 {{prospectForm.controls?.motivateFinancialUp?.value === true ? 'motive-active' : 'motive-inactive'}}"
                    hero status='basic' (click)="setSquareValue('motivateFinancialUp')">F+
            </button>
            <button nbTooltip='Life style Up' nbButton size='tiny'
                    class="col-6 {{prospectForm.controls?.motivateLifestyleUp?.value === true ? 'motive-active' : 'motive-inactive'}}"
                    hero status='basic' (click)="setSquareValue('motivateLifestyleUp')">LS+
            </button>
            <button nbTooltip='Financial Down' nbButton size='tiny'
                    class="col-6 {{prospectForm.controls?.motivateFinancialDown?.value === true ? 'motive-active' : 'motive-inactive'}}"
                    hero status='basic' (click)="setSquareValue('motivateFinancialDown')">F-
            </button>
            <button nbTooltip='Life style Down' nbButton size='tiny'
                    class="col-6 {{prospectForm.controls?.motivateLifestyleDown?.value === true ? 'motive-active' : 'motive-inactive'}}"
                    hero status='basic' (click)="setSquareValue('motivateLifestyleDown')">LS-
            </button>
          </div>
        </div>
        <div class='col-3 square-container square-person'>
          <div class='col-12 d-flex text-center'>
            <label (click)='toggleDisplayPerson()' class='col-7 openExpand text-right non-select'>History</label>
            <nb-icon class='ml-2' [icon]="'clock-outline'" pack='eva'></nb-icon>
          </div>
          <div class='row'>
            <button nbTooltip='Director' nbButton size='tiny'
                    class="col-6 {{prospectForm.controls?.isDirector?.value === true ? 'person-active' : 'person-inactive'}}"
                    hero (click)="setSquareValue('isDirector')">D
            </button>
            <button nbTooltip='Influencer' nbButton size='tiny'
                    class="col-6 {{prospectForm.controls?.isInfluencer?.value === true ? 'person-active' : 'person-inactive'}}"
                    hero (click)="setSquareValue('isInfluencer')">I
            </button>
            <button nbTooltip='Concensious' nbButton size='tiny'
                    class="col-6 {{prospectForm.controls?.isConcensious?.value === true ? 'person-active' : 'person-inactive'}}"
                    hero (click)="setSquareValue('isConcensious')">C
            </button>
            <button nbTooltip='Socialble' nbButton size='tiny'
                    class="col-6 {{prospectForm.controls?.isSocialble?.value === true ? 'person-active' : 'person-inactive'}}"
                    hero (click)="setSquareValue('isSocialble')">S
            </button>
          </div>
        </div>
      </div>
      <div class='col-12 mt-3'>
        <div class='row'>
          <div class='col-2 mt-1'>
            <select nbTooltip='Type' formControlName='type'
                    [ngClass]="showError('type')"
                    (change)='onPropertyTypeChange()'
                    class='property-spec-select select-no-arrow border-1 text-center'>
              <option value='' disabled>Type</option>
              <option *ngFor='let item of propertyType | keyvalue:keepOrder'
                      value='{{item.key}}'
              >{{item.value}}</option>
            </select>
          </div>
          <div class='col-2 mt-1'>
            <select nbTooltip='Bed' formControlName='bed'
                    [ngClass]="showError('bed')"
                    class='property-spec-select select-no-arrow border-1 text-center'

            >
              <option value='' disabled>Bed</option>
              <option *ngFor='let item of bedValues' [value]='item'>{{item}}</option>
            </select>
          </div>
          <div class='col-2 mt-1'>
            <select nbTooltip='Bath' formControlName='bath'
                    [ngClass]="showError('bath')" class='property-spec-select border-1 select-no-arrow text-center'

            >
              <option value='' disabled>Bath</option>
              <option *ngFor='let item of bedValues' [value]='item'>{{item}}</option>
            </select>
          </div>
          <div class='col-2 mt-1'>
            <select nbTooltip='Car' formControlName='car'
                    [ngClass]="showError('car')" class='property-spec-select border-1 select-no-arrow text-center'

            >
              <option value='' disabled>Car</option>
              <option *ngFor='let item of bedValues' [value]='item'>{{item}}</option>
            </select>
          </div>
          <div class='col-4'>
            <input nbTooltip='Land' type='text' nbInput fieldSize='tiny'
                   class='landInput border-1'
                   fullWidth name='land'
                   formControlName='land' placeholder='From Land' [ngClass]="showError('land')"
            />
          </div>
        </div>
      </div>
      <div class='freeze-new-body max-height-body' *ngIf='data'>
        <div class='row mt-2 market-section' [hidden]='!showMarket'>
          <div class='col-sm-12 mt-3'>
            <div class='row'>
              <div class='col-4'>
                <div class='row'>
                  <nb-form-field class='width100'>
                    <input nbInput fieldSize='tiny' fullWidth name='askingPrice'
                           formControlName='askingPrice' placeholder='Asking price' type='number' min='0' step='0.01'>
                    <span nbSuffix>{{defaultCurrencyIcon}}</span>
                  </nb-form-field>
                  <div class='form-group form-height row mt-3 w-100'>
                    <button nbButton ghost fullWidth class='height date-picker-button'
                            (click)='triggerDatePicker(datepickerOtmDate)'>
                      <input [nbDatepicker]='datepickerOtmDate' nbInput fieldSize='tiny'
                             fullWidth formControlName='otmDate' placeholder='Otm date'/>
                    </button>
                    <div class='middle'>
                    <span [hidden]='!otmDays || otmDays < 0'
                          class="{{otmDays > 90 ? 'duration-warning' : 'duration-normal'}}">{{otmDurationText}}</span>
                      <nb-datepicker #datepickerOtmDate></nb-datepicker>
                    </div>
                  </div>
                  <div class='form-group width100'>
                    <div>
                      <select size='tiny' formControlName='sellCompanyId' class='select-custom'>
                        <option [value]='null' disabled>Sell Company</option>
                        <option [value]='null'>Empty</option>
                        <option *ngFor='let item of companies'
                                [value]='item.companyId'>{{item.name}}</option>
                      </select>
                    </div>
                    <a href='/pages/prospect/company/list'>Create new</a>
                  </div>
                </div>
              </div>
              <div class='col-4'>
                <div class='row'>
                  <div class='property-type-container w-100'>
                    <nb-form-field class='width100'>
                      <input nbInput fieldSize='tiny' fullWidth name='sellPrice'
                             formControlName='sellPrice' placeholder='Sell price' type='number' min='0' step='0.01'>
                      <span nbSuffix>{{defaultCurrencyIcon}}</span>
                    </nb-form-field>
                  </div>
                  <div class='form-group form-height row mt-3 w-100'>
                    <button nbButton ghost fullWidth class='height date-picker-button'
                            (click)='triggerDatePicker(datepickerPurchaseDate)'>
                      <input
                        [nbDatepicker]='datepickerPurchaseDate'
                        nbInput fieldSize='tiny'
                        fullWidth formControlName='purchaseDate' placeholder='Purchase date'
                        />
                    </button>
                    <div class='middle'>
                    <span [hidden]='!purchaseDays || purchaseDays < 30'
                          class="{{purchaseDays > 5 * 365 ? 'duration-warning' : 'duration-normal'}}">{{purchaseDurationText}}</span>
                      <nb-datepicker #datepickerPurchaseDate></nb-datepicker>
                    </div>
                  </div>
                </div>
              </div>
              <div class='col-4'>
                <div class='row'>
                  <div class='col-12 property-type-container'>
                    <div class='form-group width100'>
                      <nb-form-field class='width100'>
                        <input nbInput fieldSize='tiny' fullWidth name='rentPrice'
                               formControlName='rentPrice' placeholder='Rent price' type='number' min='0' step='0.01'>
                        <span nbSuffix>{{defaultCurrencyIcon}}</span>
                      </nb-form-field>
                      <div class='form-group form-height row mt-3'>
                        <button nbButton ghost fullWidth class='height date-picker-button'
                                (click)='triggerDatePicker(datepickerVacDate)'>
                          <input [nbDatepicker]='datepickerVacDate' nbInput fieldSize='tiny'
                                 fullWidth formControlName='vacDate' placeholder='Vac date'/>
                        </button>
                        <nb-datepicker #datepickerVacDate></nb-datepicker>
                        <div class='middle'>
                      <span [hidden]='!vacDays || vacDays < 0'
                            class="{{vacDays > 21 ? 'duration-warning' : 'duration-normal'}}">{{vacDurationText}}</span>
                        </div>
                      </div>
                      <div class='mt-3'>
                        <select size='tiny' formControlName='rentCompanyId' class='select-custom'>
                          <option [value]='null' disabled>Rent Company</option>
                          <option [value]='null'>Empty</option>
                          <option *ngFor='let item of companies'
                                  [value]='item.companyId'>{{item.name}}</option>
                        </select>
                      </div>
                      <a href='/pages/prospect/company/list'>Create new</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class='row'>
              <div class='col-12 property-type-container text-center'>
                <div class='w-100 overflow-hidden' [hidden]='!isEditing'>
                  <input type='text' nbInput fieldSize='tiny' fullWidth name='link'
                         placeholder='Link' formControlName='link' [ngClass]="showError('link')" *ngIf='isEditing'>
                </div>
                <div class='w-100 overflow-hidden' [hidden]='isEditing'>
                  <a href='{{data?.property?.link}}' [hidden]='isEditing' target='_blank'>{{data?.property?.link}}</a>
                </div>
                <button type='button' class='btn btn-info btn-sm mr-2 float-right' (click)='isEditing = !isEditing'
                        *ngIf='!isEditing'>Edit
                </button>
              </div>
            </div>
            <div class='col-12 mt-2' *ngIf='data'>
              <ngx-attachment [objectId]='data.prospectId'
                              objectType='PROSPECT'
              ></ngx-attachment>
            </div>
          </div>
        </div>

        <div class='row contact-section' [hidden]='!showContact'>
          <div class='row'>
            <div class='col-6 mt-2 property-type-container'>
              <input type='text' nbInput fieldSize='tiny' fullWidth name='name'
                     placeholder='Name' formControlName='name' [ngClass]="showError('name')">
            </div>
            <div class='col-6 mt-2 property-type-container'>
              <input type='text' nbInput fieldSize='tiny' fullWidth name='surname'
                     placeholder='Surname' formControlName='surname'
                     [ngClass]="showError('surname')">
            </div>
            <div class='row w-100'>
              <div class='col-12 primary-background'>
                <div class='row pointer same-phone' *ngIf='mobiPhoneDuplicate'>
                  <div class='col-6 text-center'>
                    <p (click)="selectPhone('MPHONE')" *ngIf='mobiPhoneDuplicate.totalClient !== 0'
                       nbTooltip='Found Clients'><i
                      class='fa fa-user'></i>: {{mobiPhoneDuplicate.totalClient}}</p>
                  </div>
                  <div class='col-6 text-center'>
                    <p (click)="selectPhone('MPHONE')" *ngIf='mobiPhoneDuplicate.totalCustomer !== 0'
                       nbTooltip='Found Customers'><i
                      class='fa fa-user-o'></i>: {{mobiPhoneDuplicate.totalCustomer}}</p>
                  </div>
                </div>
              </div>
              <div class='col-12 pt-2 pb-2 property-type-container primary-background'>
                <nb-form-field class='w-100'>
                  <input type='text' nbInput fieldSize='tiny'
                         class="{{(prospectForm.controls.call.value === true || prospectForm.controls.sms.value === true) ? 'phone-inactive' : 'phone-active'}}"
                         fullWidth name='mobilePhone' formControlName='mobilePhone'
                         placeholder='Mobile Phone' [ngClass]="showError('mobilePhone')">
                  <span class='phone-button' nbSuffix nbTooltip='Add Phone' (click)='addPhoneNumber("MPHONE")'>+</span>
                </nb-form-field>
                <a nbButton size='tiny' class='btn-call' hero
                   [disabled]="!prospectForm.controls.mobilePhone.value || prospectForm.controls.mobilePhone.value === '' || prospectForm?.controls?.rate?.value > 3"
                   href='tel:{{prospectForm.controls.mobilePhone.value}}'
                   (click)='lastCalledPhoneNumber = prospectForm.controls.mobilePhone.value'
                >CALL</a>
                <span class='ml-1 status-search'
                      (click)='searchSameCustomer("MPHONE")'>
                                <nb-icon [icon]="'search-outline'"
                                         pack='eva'>
                                </nb-icon>
                              </span>
              </div>
              <div class='col-12' *ngFor='let additionalInfo of additionalMobilePhone, index as index'>
                <div class='row'>
                  <div class='col-12 mt-2 property-type-container' *ngIf='!additionalInfo.isDeleted'>
                    <nb-form-field class='w-100'>
                      <input type='text' [(ngModel)]='additionalInfo.name' nbInput fullWidth fieldSize='tiny'
                             placeholder='Alternative Name'
                             [ngClass]="{'phone-inactive': additionalInfo.call}"
                             [ngModelOptions]='{standalone: true}'>
                    </nb-form-field>
                    <button nbButton size='tiny' status='danger' class='ml-1'
                            (click)='removePhoneNumber(additionalInfo, index)'> -
                    </button>
                    <button nbButton size='tiny' status='primary' class='ml-1'
                            (click)='setPrimary(additionalInfo, index)'>
                      Primary
                    </button>
                  </div>

                  <div class='col-12 mt-2 mb-2 property-type-container' *ngIf='!additionalInfo.isDeleted'>
                    <nb-form-field class='w-100'>
                      <input [(ngModel)]='additionalInfo.phoneNumber' nbInput fullWidth fieldSize='tiny'
                             placeholder='Alternative Phone Number'
                             [ngClass]="{'phone-inactive': additionalInfo.call}"
                             [ngModelOptions]='{standalone: true}'>
                    </nb-form-field>
                    <a nbButton size='tiny' class='btn-call' hero
                       [disabled]='!additionalInfo.phoneNumber'
                       href='tel:{{additionalInfo.phoneNumber}}'
                    >CALL</a>
                  </div>
                </div>
              </div>
              <div class='col-12 primary-background'>
                <div class='row pointer same-phone' *ngIf='phoneDuplicate'>
                  <div class='col-6 text-center'>
                <span (click)="selectPhone('PHONE')" *ngIf='phoneDuplicate.totalClient !== 0' nbTooltip='Found Clients'><i
                  class='fa fa-user'></i>: {{phoneDuplicate.totalClient}}</span>
                  </div>
                  <div class='col-6 text-center'>
                <span (click)="selectPhone('PHONE')" *ngIf='phoneDuplicate.totalCustomer !== 0'
                      nbTooltip='Found Customers'><i class='fa fa-user-o'></i>
                  : {{phoneDuplicate.totalCustomer}}</span>
                  </div>
                </div>
              </div>
              <div class='col-12 pt-2 pb-2 property-type-container primary-background'>
                <nb-form-field class='w-100'>
                  <input type='text' nbInput fieldSize='tiny'
                         class="{{prospectForm.controls.call.value === true ? 'phone-inactive' : 'phone-active'}}"
                         fullWidth name='phone' formControlName='phone' placeholder='Phone'
                         [ngClass]="showError('phone')">
                  <span class='phone-button' nbSuffix nbTooltip='Add Phone' (click)='addPhoneNumber("PHONE")'>+</span>
                </nb-form-field>
                <a nbButton size='tiny' class='btn-call' hero
                   [disabled]="!prospectForm.controls.phone.value || prospectForm.controls.phone.value === '' || prospectForm?.controls?.rate?.value > 3"
                   href='tel:{{prospectForm.controls.phone.value}}'
                   (click)='lastCalledPhoneNumber = prospectForm.controls.phone.value'
                >CALL</a>
                <span class='ml-1 status-search'
                      (click)='searchSameCustomer("PHONE")'>
                                <nb-icon [icon]="'search-outline'"
                                         pack='eva'>
                                </nb-icon>
                              </span>
              </div>
              <div class='col-12 mt-2' *ngFor='let additionalInfo of additionalPhone, index as index'>
                <div class='row' [class]=''>
                  <div class='col-12 mt-2 property-type-container' *ngIf='!additionalInfo.isDeleted'>
                    <nb-form-field class='w-100'>
                      <input type='text' [(ngModel)]='additionalInfo.name' nbInput fullWidth fieldSize='tiny'
                             placeholder='Alternative Name'
                             [ngClass]="{'phone-inactive': additionalInfo.call}"
                             [ngModelOptions]='{standalone: true}'>
                    </nb-form-field>
                    <button nbButton size='tiny' status='danger' class='ml-1'
                            (click)='removePhoneNumber(additionalInfo, index)'> -
                    </button>
                    <button nbButton size='tiny' status='primary' class='ml-1'
                            (click)='setPrimary(additionalInfo, index)'>
                      Primary
                    </button>
                  </div>

                  <div class='col-12 mt-2 mb-2 property-type-container' *ngIf='!additionalInfo.isDeleted'>
                    <nb-form-field class='w-100'>
                      <input [(ngModel)]='additionalInfo.phoneNumber' nbInput fullWidth fieldSize='tiny'
                             placeholder='Alternative Phone Number'
                             [ngClass]="{'phone-inactive': additionalInfo.call}"
                             [ngModelOptions]='{standalone: true}'>
                    </nb-form-field>
                    <a nbButton size='tiny' class='btn-call' hero
                       [disabled]='!additionalInfo.phoneNumber'
                       href='tel:{{additionalInfo.phoneNumber}}'
                    >CALL</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class='col-12 mt-2 property-type-container'>
            <input type='text' nbInput fieldSize='tiny' fullWidth name='email'
                   formControlName='email' placeholder='Email' [ngClass]="showError('email')">
          </div>
          <div class='col-6 mt-2 property-type-container'>
            <input type='text' nbInput fieldSize='tiny' fullWidth name='facebook'
                   formControlName='facebook' placeholder='Facebook'
                   [ngClass]="showError('facebook')">
          </div>
          <div class='col-6 mt-2 property-type-container'>
            <input type='text' nbInput fieldSize='tiny' fullWidth name='instagram'
                   formControlName='instagram' placeholder='Instagram'
                   [ngClass]="showError('instagram')">
          </div>
          <div class='col-6 mt-2 property-type-container'>
            <input
              [(ngModel)]='categoryName'
              [ngModelOptions]='{standalone: true}'
              [typeahead]='filterCategory$'
              [typeaheadAsync]='true'
              (typeaheadOnSelect)='selectCategory($event)'
              typeaheadOptionField='name'
              typeaheadWaitMs='1000'
              placeholder='Tag'
              nbInput fieldSize='tiny' fullWidth>
          </div>
          <div class='col-6 property-type-container'>
            <div *ngFor='let category of selectedCategories' class='category-block mt-2'>
              {{category.name}}
              <button class='btn btn-danger btn-sm' (click)='removeCategory(category)'>
                <i class='fa fa-times' aria-hidden='true'></i>
              </button>
            </div>
          </div>
          <div class='row mt-2'>
            <nb-accordion>
              <nb-accordion-item>
                <nb-accordion-item-header>Extra info</nb-accordion-item-header>
                <nb-accordion-item-body>
                  <div class='row'>
                    <div class='form-group width50'>
                      <select size='tiny' formControlName='nationality' class='full-width'
                              (change)='onNationalityChange()'>
                        <option [value]='null' disabled>Nationality</option>
                        <option *ngFor='let item of nationalities'
                                [value]='item.country_name'>{{item.country_name}}</option>
                      </select>
                    </div>
                    <div class='form-group width50'>
                      <select size='tiny' formControlName='religion' class='full-width'>
                        <option [value]='null' disabled>Religion</option>
                        <option *ngFor='let item of religions'
                                [value]='item.name'>{{item.name}}</option>
                      </select>
                    </div>
                    <div class='form-group width50'>
                      <input type='text' nbInput fieldSize='tiny'
                             fullWidth name='profesion'
                             formControlName='profesion' placeholder='Profession'
                             [ngClass]="showError('profesion')"
                      />
                    </div>
                    <div class='form-group width50'>
                      <select size='tiny' formControlName='language' class='full-width'>
                        <option [value]='null' disabled>Language</option>
                        <option *ngFor='let item of languages'
                                [value]='item.code'>{{item.name}}</option>
                      </select>
                    </div>
                    <div class='form-group width50'>
                      <button nbButton ghost fullWidth class='date-picker-button'
                              (click)='triggerDatePicker(datepickerAnniversaryDay)'>
                        <input [nbDatepicker]='datepickerAnniversaryDay' nbInput fieldSize='tiny'
                               fullWidth formControlName='anniversaryDay'
                               placeholder='Anniversary Day' disabled />
                      </button>
                      <nb-datepicker #datepickerAnniversaryDay></nb-datepicker>
                    </div>
                    <textarea rows='5' class='mt-2 text-area-without-border-radius'
                              nbInput fullWidth shape='round' placeholder='Note'
                              formControlName='nationalityNote'
                              [ngClass]="showError('nationalityNote')"></textarea>
                  </div>
                  <div class='row mt-3'>
                    <div class='col-sm-4 property-type-container text-center'>
                      <div class='form-group w-100'>
                        <label class='label'>Office</label>
                        <input
                          [(ngModel)]='officeName'
                          [ngModelOptions]='{standalone: true}'
                          [typeahead]='filterOffice$'
                          [typeaheadAsync]='true'
                          (typeaheadOnSelect)='selectOffice($event)'
                          typeaheadOptionField='name'
                          typeaheadWaitMs='1000'
                          placeholder='Office'
                          nbInput fullWidth
                        />
                      </div>
                    </div>
                  </div>
                  <div class='row mt-3'>
                    <div class='col-sm-12 property-type-container text-center'>
                      <nb-checkbox formControlName='sold'>Sold</nb-checkbox>
                      <nb-checkbox formControlName='underContract' class='ml-2'>Under Contract
                      </nb-checkbox>
                      <nb-checkbox formControlName='leased' class='ml-2'>Leased</nb-checkbox>
                    </div>
                  </div>
                </nb-accordion-item-body>
              </nb-accordion-item>
            </nb-accordion>
          </div>
        </div>

        <div class='row mt-2 motive-section' [hidden]='!showMotive'>
          <div class='col-12 property-type-container'>
            <select size='tiny' formControlName='relationship' class='full-width'>
              <option [value]='undefined' disabled>Relationship</option>
              <option *ngFor='let item of customerRelationShip | keyvalue:keepOrder'
                      [value]='item.value'>{{item.value}}</option>
            </select>
            <select size='tiny' formControlName='motivate' class='full-width'>
              <option [value]='undefined' disabled>Motivate</option>
              <option *ngFor='let item of customerMotivate | keyvalue:keepOrder'
                      [value]='item.value'>{{item.value}}</option>
            </select>
          </div>
          <div class='col-12 property-type-container mt-2'>
            <div class='form-group width50'>
              <nb-form-field>
                <button nbButton ghost fullWidth class='date-picker-button'
                        (click)='triggerDatePicker(datepickerDOB)'>
                  <input [nbDatepicker]='datepickerDOB' nbInput fieldSize='tiny'
                         fullWidth placeholder='D.O.B' formControlName='dob' disabled>
                </button>
                <button nbSuffix nbButton ghost (click)="createTask('BIRTHDAY_CALL')">
                  <nb-icon [icon]="'plus-outline'"
                           pack='eva'>
                  </nb-icon>
                </button>
              </nb-form-field>
              <nb-datepicker #datepickerDOB></nb-datepicker>
              <span [hidden]="!dobText || dobText === ''">{{dobText}}</span>
            </div>
            <div class='form-group width50'>
              <nb-form-field>
                <button nbButton ghost fullWidth class='date-picker-button'
                        (click)='triggerDatePicker(datepickerCallback)'>
                  <input [nbDatepicker]='datepickerCallback' nbInput fieldSize='tiny'
                         fullWidth placeholder='Call back date' formControlName='callbackDate'
                         disabled>
                </button>
                <button nbSuffix nbButton ghost (click)="createTask('NEXT_CALL')">
                  <nb-icon [icon]="'plus-outline'"
                           pack='eva'>
                  </nb-icon>
                </button>
              </nb-form-field>
              <nb-datepicker #datepickerCallback></nb-datepicker>
            </div>
          </div>
          <div class='row full-width mt-2'>
            <div class='col-12'>
          <textarea rows='2' nbInput fullWidth shape='round' placeholder='Motive'
                    class='text-area-without-border-radius'
                    formControlName='motiveNote' [ngClass]="showError('motiveNote')">
          </textarea>
            </div>
          </div>
        </div>

        <div class='row mt-2 person-section' [hidden]='!showPerson'>
          <textarea rows='5' class='mt-2 text-area-without-border-radius'
                    nbInput fullWidth shape='round' placeholder='Note'
                    formControlName='note' [ngClass]="showError('note')"></textarea>
          <div class='col-12 mt-2 mb-3'>
            <div class="app-speech-input" [class.app-speech-input-listening]="listening">
              <button class="app-speech-input-btn" [disabled]="listening" (click)="listen()"></button>
            </div>
            <button *ngIf='!isNewNote' nbButton size='tiny' class='fa-pull-right' status='info' hero
                    (click)='newNote()'>New
            </button>
            <button *ngIf='isNewNote' nbButton size='tiny' class='fa-pull-right' hero
                    (click)='cancelNewNote()'>Cancel
            </button>
          </div>
          <nb-card class='mt-2'>
            <nb-list>
              <nb-list-item *ngFor='let log of sortHistoryList()'>
                <div [innerHTML]='formatLog(log)'></div>
              </nb-list-item>
            </nb-list>
          </nb-card>
        </div>

        <div class='row mt-3' *ngIf='linkedGroup'>
          <!-- Linked Section-->
          <div class='col-12'>
            <nb-accordion>
              <nb-accordion-item *ngFor='let group of linkedGroup, index as index'>
                <nb-accordion-item-header>
                  Group {{index + 1}}
                </nb-accordion-item-header>
                <nb-accordion-item-body class='accordion-body'>
                  <nb-list>
                    <nb-list-item *ngFor='let buyer of group.buyers' class='buyer-item mt-1'
                                  (click)='onSelectBuyer(buyer)'>
                      Name: {{buyer?.customer?.name}} <br>
                      Surname: {{buyer?.customer?.surname}} <br>
                      Phone: {{buyer?.customer?.phone}} <br>
                      Mobile Phone: {{buyer?.customer?.mobilePhone}} <br>
                      Address: {{formatAddress(buyer?.address)}}
                    </nb-list-item>
                    <nb-list-item *ngFor='let prospect of group.prospects' class='prospect-item mt-1'
                                  (click)='onSelectProspect(prospect)'>
                      Name: {{prospect?.customer?.name}} <br>
                      Surname: {{prospect?.customer?.surname}} <br>
                      Phone: {{prospect?.customer?.phone}} <br>
                      Mobile Phone: {{prospect?.customer?.mobilePhone}} <br>
                      Address: {{formatAddress(prospect?.address)}}
                    </nb-list-item>
                  </nb-list>
                </nb-accordion-item-body>
              </nb-accordion-item>
            </nb-accordion>
          </div>
        </div>


        <!--        Attachment section-->

      </div>
    </nb-card-body>
    <nb-card-footer class='dialog-footer' *ngIf='!showFooter'>
      <div *ngIf='data && data.prospectId && isModal && showBottomButtons'>
        <button class='cancel float-button float-delete mr-2' size='tiny' nbButton status='danger' (click)='delete()'
                *appPermissionCheck='ComponentPermission.CLIENT_DELETE'>
          Delete
        </button>
        <button *ngIf='onClose' class='cancel float-button float-cancel ' size='tiny' nbButton (click)='close()'>
          Close
        </button>
        <button nbButton status='success' class='float-button float-save' size='tiny' (click)='submit()'
                [disabled]='isUpdating === true'
                [hidden]='infoVisible === false'
                *appPermissionCheck='ComponentPermission.CLIENT_EDIT'
        >
          Save
        </button>
      </div>
      <div>
        <button class='cancel float-delete float-button mr-2' size='tiny' nbButton status='danger'
                (click)='delete()'
                *ngIf='data && data.prospectId && !isModal && showBottomButtons && ComponentPermission.isAccess(ComponentPermission.CLIENT_DELETE)'
        >Delete
        </button>
        <button nbButton status='success' class='float-button float-save' size='tiny' (click)='submit()'
                [disabled]='isUpdating === true'
                [hidden]='infoVisible === false'
                *ngIf='data && data.prospectId && !isModal && showBottomButtons && ComponentPermission.isAccess(ComponentPermission.CLIENT_EDIT)'
        >
          Save
        </button>
      </div>
    </nb-card-footer>
  </nb-card>
</form>
