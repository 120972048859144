import { Component, Input, OnInit } from '@angular/core';
import { Prospect } from '../../models/response/prospect/prospect';
import { StringUtils } from '../../common/string-utils';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-export-address',
  templateUrl: './export-address.component.html',
  styleUrls: ['./export-address.component.scss']
})
export class ExportAddressComponent implements OnInit {

  @Input() data: Prospect[];
  classification: string;
  generatedString = [];
  dataString: string;

  constructor(private ref: NbDialogRef<ExportAddressComponent>) { }

  ngOnInit(): void {
    if (this.data) {
      this.generateDataString();
    }
  }

  generateDataString() {
    for (const prospect of this.data) {
      const temp = [];
      if (StringUtils.isNotEmpty(this.getName(prospect))) {
        temp.push(this.getName(prospect));
      }
      if (this.classification) {
        temp.push(this.classification);
      }
      if (prospect?.address) {
        temp.push(StringUtils.getShortAddress(prospect.address));
      }
      if (StringUtils.isNotEmpty(StringUtils.getSpec(prospect))) {
        temp.push(StringUtils.getSpec(prospect));
      }
      if (StringUtils.isNotEmpty(prospect?.customer?.mobilePhone)) {
        temp.push(prospect.customer.mobilePhone);
      }
      this.generatedString.push(temp.join(', '));
    }
    this.dataString = this.generatedString.join('\n');
  }

  getName(prospect: Prospect): string {
    if (StringUtils.isNotEmpty(prospect?.customer?.name) && StringUtils.isNotEmpty(prospect?.customer?.surname)) {
      return StringUtils.getFullName(prospect).trim();
    }
    if (StringUtils.isNotEmpty(prospect?.customer?.name)) {
      return prospect.customer.name.trim();
    }
    if (StringUtils.isNotEmpty(prospect?.customer?.surname)) {
      return prospect.customer.surname.trim();
    }
    return '';
  }

  cancel() {
    this.ref.close();
  }
}
