import { Component, OnInit } from '@angular/core';
import { ComponentPermission } from '../../../../component.permission';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'ngx-callbackdate-overview',
  templateUrl: './callbackdate-overview.component.html',
  styleUrls: ['./callbackdate-overview.component.scss'],
})
export class CallbackdateOverviewComponent implements OnInit {
  ComponentPermission = ComponentPermission;
  tabs = {
    CLIENT: 'CLIENT',
    CUSTOMER: 'CUSTOMER',
  };
  param = {};
  currentTab = '';

  constructor(private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.param = { ...this.route.snapshot.queryParams };
    const selectedTab = this.route.snapshot?.queryParamMap?.get('tab');
    if (selectedTab && selectedTab !== '') {
      this.currentTab = selectedTab;
    }
  }

  onChangeTab(event) {
    if (event.tabTitle !== this.currentTab) {
      delete this.param['selectedYear'];
      delete this.param['selectedQuarter'];
      delete this.param['selectedMonth'];
      delete this.param['selectedWeek'];
      delete this.param['selectedDay'];
      delete this.param['cityId'];
      delete this.param['zoneId'];
      delete this.param['districtId'];
      delete this.param['streetId'];
      delete this.param['selectedStreetId'];
      delete this.param['selectedSProspectId'];
      delete this.param['selectedBuyerId'];
      delete this.param['year'];
      delete this.param['month'];
      delete this.param['day'];
      delete this.param['fromCallbackDate'];
      delete this.param['toCallbackDate'];
      delete this.param['tab'];
      delete this.param['postcode'];
    }
    // else {
    //   if (event.tabTittle === 'CLIENT') {
    //     delete this.param['selectedQuarter'];
    //     delete this.param['selectedWeek'];
    //     delete this.param['zoneId'];
    //     delete this.param['fromCallbackDate'];
    //     delete this.param['toCallbackDate'];
    //     delete this.param['selectedBuyerId'];
    //     delete this.param['year'];
    //     delete this.param['month'];
    //     delete this.param['day'];
    //
    //   } else if (event.tabTittle === 'CUSTOMER') {
    //     delete this.param['streetId'];
    //     delete this.param['selectedDay'];
    //     delete this.param['selectedStreetId'];
    //   }
    // }
    this.currentTab = event.tabTitle;
    this.router.navigate(['/pages/dashboard/callback-date'], { queryParams: { ...this.param, tab: this.currentTab } });
  }
}
