import { Component, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { DateUtils } from '../../../../shared/common/date-utils';
import { RestResult } from '../../../../shared/models/response/rest-result';
import { ExportDialogComponent } from '../export-dialog/export-dialog.component';
import { Constant } from '../../../../shared/common/constant';
import { BuyerService } from '../../../../shared/services/buyer.service';
import { BuyerSearchRequest } from '../../../../shared/models/request/buyer/buyer-search-request';
import { BuyerGroupWrapper } from '../../../../shared/models/response/buyer/buyer-group-wrapper';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { AuthService } from '../../../../shared/services/auth.service';
import { User } from '../../../../shared/models/response/user';
import { BuyerDatasource } from '../common/buyer-datasource';
import { SearchResult } from '../../../../shared/models/response/search-result';
import { Prospect } from '../../../../shared/models/response/prospect/prospect';
import { ProspectUtils } from '../../prospect/common/prospect-utils';
import { DataSourceUtil } from '../../../../shared/models/utils/datasource-util';
import { CustomDatasource } from '../../../../shared/models/utils/custom-datasource';
import { CommonService } from '../../../../shared/services/common.service';
import { City } from '../../../../shared/models/response/address/city';
import { BuyerUtils, WrapBuyer, WrapBuyerType } from '../common/buyer-utils';
import { BuyerListAddressCellComponent } from '../common/buyer-list-address-cell-component';
import { BuyerListCalledDateCellComponent } from '../common/buyer-list-called-date-cell-component';
import { ProspectListAddressCellComponent } from '../../prospect/common/prospect-list-address-cell-component';
import { ProspectListCustomCellComponent } from '../../prospect/common/prospect-list-custom-cell-component';
import { ProspectDobAgeCellComponent } from '../../prospect/common/prospect-dob-age-cell-component';
import { TaskType } from '../../../../shared/models/response/task-type';
import { TaskStatus } from '../../../../shared/models/response/task-status';
import { Buyer } from '../../../../shared/models/response/buyer/buyer';
import { BuyerCreateComponent } from '../../../../shared/module/buyer-create/buyer-create.component';
import { District } from '../../../../shared/models/response/address/district';
import { Street } from '../../../../shared/models/response/address/street';
import { LocalService } from '../../../../shared/services/local.service';
import { SearchRequest } from '../../../../shared/models/request/search-request';
import { TemplateService } from '../../../../shared/services/template.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment/moment';
import { StringUtils } from '../../../../shared/common/string-utils';

@Component({
  selector: 'ngx-buyer-list-dob',
  templateUrl: './buyer-list-dob.component.html',
  styleUrls: ['./buyer-list-dob.component.scss'],
})
export class BuyerListDobComponent implements OnInit {
  cities: City[];
  filteredDistricts: District[] = [];
  filteredStreets: Street[] = [];
  isDisplay: boolean;
  isDialog = false;
  selectedBuyers: Buyer[];
  columnsDefinition = {
    name: {
      enable: true,
      value: BuyerUtils.createColumn('Name', ['customer.name', 'customer.surname'], null, null, null),
      name: 'Name',
    },
    dob: {
      enable: true,
      value: BuyerUtils.createColumn('DoB', ['customer.dob'],
        ProspectListCustomCellComponent.VALUE_TYPES.DATE, null, null),
      name: 'DoB',
    },
    age: {
      enable: true,
      value: this.createAgeColumn('Age'),
      name: 'Age',
    },
  };

  settings = {
    mode: 'external',
    hideSubHeader: true,
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    pager: {
      display: true,
      perPage: 10000,
    },
    columns: this.createColumns(),
    rowClassFunction: (row) => {
      return ProspectUtils.highlightProspectRow(row?.data);
    },
  };

  createColumns() {
    const columns = {};
    for (const key of Object.keys(this.columnsDefinition)) {
      if (this.columnsDefinition[key].enable === true) {
        columns[key] = this.columnsDefinition[key].value;
      }
    }
    return columns;
  }

  createAgeColumn(title: string) {
    return {
      title: title,
      type: 'custom',
      filter: false,
      renderComponent: ProspectDobAgeCellComponent,
    };
  }

  searchRequest = new BuyerSearchRequest();
  groupWrapper: BuyerGroupWrapper;
  adminNumber: string;
  currentUser: User;
  source = new CustomDatasource();
  change: any;
  currentPage = 1;
  pageSize = Constant.PAGE_SIZE;
  log: string;
  selectedBuyer: Buyer;
  @ViewChild(BuyerCreateComponent) buyerCreate: BuyerCreateComponent;
  @ViewChild('buyerForm') buyerDialog: any;
  selectedMonth: number;
  selectedDay: number;
  @Input() birthdayToday: boolean;
  param = {};
  isFiltered = false;

  // @Input() isDialog = false;

  constructor(@Optional() private ref: NbDialogRef<BuyerCreateComponent>,
              private buyerService: BuyerService,
              private dialogService: NbDialogService,
              private authService: AuthService,
              private commonService: CommonService,
              private localService: LocalService,
              private templateService: TemplateService,
              private route: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit(): void {
    this.currentUser = this.authService.currentUser;
    this.param = { ...this.route.snapshot.queryParams };
    this.source.onChanged().subscribe((change) => {
      this.change = change;
      if (change.action === 'refresh') {
        this.pageChange(1);
      }
      if (change.action === 'page') {
        this.param = { ...this.param, page: change.paging.page };
        this.navigateWithParam();
        this.pageChange(change.paging.page);
      }
    });
    if (this.currentUser.office.phone) {
      this.adminNumber = this.currentUser.office.phone;
    }
    this.loadForFilter();
    this.preLoadAddressForFilter();
    this.preLoadSelectedEnqTime();
  }

  loadForFilter() {
    const searchRequest = new SearchRequest();
    searchRequest.offset = 0;
    searchRequest.limit = 10000;
    searchRequest.orders = new Array();
    searchRequest.orders.push(
      {
        left: 'name',
        right: 'asc',

      },
    );
    this.localService.cities().subscribe((result: City[]) => {
      this.cities = result;
    });
  }

  pageChange(pageIndex) {
    this.currentPage = pageIndex;
    this.searchRequest.offset = (pageIndex - 1) * this.pageSize;
    if (this.searchRequest.offset < 0) {
      this.searchRequest.offset = 0;
    }
    this.searchRequest.limit = this.pageSize;
    this.searchRequest.officeId = this.currentUser.office?.officeId;
    this.searchRequest.hasDob = true;
    if (this.birthdayToday === true) {
      const now = new Date();
      this.searchRequest.dobDay = now.getDate();
      this.searchRequest.dobMonth = now.getMonth() + 1;
    }
    if (!this.isFiltered) {
      this.appendParameterToSearchRequest();
    }

    this.buyerService.searchGroups(this.searchRequest).subscribe((result: RestResult) => {
      this.groupWrapper = result.data;
      const buyerId = Number(this.route.snapshot.queryParams['selectedBuyerId']);
      if (buyerId) {
        this.buyerService.getOne(buyerId).subscribe(r => {
          this.selectedBuyer = r.data;
          this.buyerCreate.setBuyer(this.selectedBuyer);
          this.isDisplay = true;
        });
      }
    });

    this.buyerService.searchItems(this.searchRequest).subscribe((result: RestResult) => {
      DataSourceUtil.reloadDataCustomRow(this.source, pageIndex, this.pageSize, result);
    });
  }


  exportEmail() {
    const buyerSearchRequest = JSON.parse(JSON.stringify(this.searchRequest));
    buyerSearchRequest.offset = null;
    buyerSearchRequest.limit = null;
    if (!buyerSearchRequest.fromEnqDate || !buyerSearchRequest.fromEnqDate) {
      if (this.groupWrapper) {
        const end = this.groupWrapper.groups[0];
        const start = this.groupWrapper.groups[this.groupWrapper.groups.length - 1];
        const lastDay = DateUtils.getEndOfDate(end.day, end.month - 1, end.year);
        const firstDay = DateUtils.getStartOfDate(start.day, start.month - 1, start.year);
        buyerSearchRequest.toEnqDate = lastDay.valueOf();
        buyerSearchRequest.fromEnqDate = firstDay.valueOf();
      }
    }

    this.buyerService.getEmail(buyerSearchRequest).subscribe((result: RestResult) => {
      if (result.data) {
        const filtered = result.data.filter(function(el) {
          return el != null;
        });
        this.dialogService.open(ExportDialogComponent, {
          context: {
            data: filtered.join(';'),
          },
        });
      }
    });
  }

  exportMobile() {
    const buyerSearchRequest = JSON.parse(JSON.stringify(this.searchRequest));
    buyerSearchRequest.offset = null;
    buyerSearchRequest.limit = null;
    this.buyerService.getPhone(buyerSearchRequest).subscribe((result: RestResult) => {
      if (result.data) {
        const searchRequest = new SearchRequest();
        searchRequest.conditions = [];
        searchRequest.conditions.push({
          left: 'type',
          middle: '=',
          right: 'SMS',
        });
        this.templateService.search(searchRequest).subscribe(r => {
          this.dialogService.open(ExportDialogComponent, {
            context: {
              buyerMobile: result.data,
              templates: r.data,
              adminNumber: this.adminNumber,
            },
          }).onClose.subscribe(onCloseRes => {
            if (onCloseRes) {
              this.saveBulk(onCloseRes.log, onCloseRes.ids);
            }
          });
        });
      }
    });
  }

  saveBulk(log?: string, buyerIds?: number[]) {
    if (log === null) {
      this.commonService.warning('Log must not be empty');
    } else {
      if (buyerIds) {
        this.searchRequest.buyerIds = buyerIds;
      }
      this.searchRequest.log = log;
      this.buyerService.bulkSaveBuyerLog(this.searchRequest).subscribe(
        result => {
          this.commonService.info('Bulk add log success');
          log = null;
          this.pageChange(1);
        },
        error => {
          this.commonService.warning(error.message);
        },
      );
    }
  }

  selectRecord(event: any) {
    this.selectedBuyer = event.data.data;
    this.buyerCreate.setBuyer(this.selectedBuyer);
    this.buyerCreate.setBuyers(this.selectedBuyers);
    this.isDisplay = true;
    if (this.selectedBuyer) {
      this.param = { ...this.param, selectedBuyerId: this.selectedBuyer.buyerId };
    } else {
      delete this.param['selectedBuyerId'];
    }
    this.navigateWithParam();
  }

  onSuburbChange(districtId) {
    this.searchRequest.districtId = districtId;
    this.searchRequest.streetId = undefined;
    if (districtId) {
      this.localService.streets(districtId).subscribe(result => {
        this.filteredStreets = result;
      });
    }
    this.isFiltered = true;
    delete this.param['streetId'];
    delete this.param['districtId'];
    if (this.searchRequest.districtId) {
      this.param = { ...this.param, districtId: this.searchRequest.districtId };
    } else {
      delete this.param['streetId'];
      delete this.param['districtId'];
    }
    this.navigateWithParam();
    this.pageChange(1);
  }

  onStreetChange(streetId) {
    this.searchRequest.streetId = streetId;
    this.isFiltered = true;
    delete this.param['streetId'];
    if (this.searchRequest.streetId) {
      this.param = { ...this.param, streetId: this.searchRequest.streetId };
    } else {
      delete this.param['streetId'];
    }
    this.navigateWithParam();
    this.pageChange(1);
  }

  onCityChange(cityId) {
    this.searchRequest.cityId = cityId;
    this.searchRequest.districtId = undefined;
    this.searchRequest.streetId = undefined;
    if (cityId) {
      this.localService.districts(cityId).subscribe(result => {
        this.filteredDistricts = result;
      });
    }
    this.isFiltered = true;
    delete this.param['districtId'];
    delete this.param['streetId'];
    delete this.param['cityId'];
    if (this.searchRequest.cityId) {
      this.param = { ...this.param, cityId: this.searchRequest.cityId };
    } else {
      delete this.param['districtId'];
      delete this.param['streetId'];
      delete this.param['cityId'];
    }
    this.navigateWithParam();
    this.pageChange(1);
  }

  onMonthChange(value: number) {
    if (value !== 0) {
      this.selectedMonth = value;
      this.isFiltered = true;
      delete this.param['selectedMonth'];
      delete this.param['selectedDay'];
      delete this.param['selectedBuyerId'];
      this.param = { ...this.param, selectedMonth: this.selectedMonth };
      this.searchRequest.dobMonth = value;
      this.searchRequest.dobDay = null;
    } else {
      this.searchRequest.dobMonth = null;
      delete this.param['selectedMonth'];
      delete this.param['selectedDay'];
      delete this.param['selectedBuyerId'];
    }
    this.navigateWithParam();
    this.pageChange(1);
  }

  onDayChange(value: number) {
    if (value && !this.selectedMonth) {
      return;
    }
    if (value !== 0) {
      this.searchRequest.dobDay = value;
      this.selectedDay = value;
      this.isFiltered = true;
      delete this.param['selectedDay'];
      delete this.param['selectedBuyerId'];
      this.param = { ...this.param, selectedDay: this.selectedDay };
    } else {
      this.searchRequest.dobDay = null;
      delete this.param['selectedDay'];
      delete this.param['selectedBuyerId'];
    }
    this.navigateWithParam();
    this.pageChange(1);
  }

  closeDialog() {
    this.ref.close();
  }

  cancel() {
    this.isDisplay = false;
  }

  selectRow(event: any, el: HTMLElement) {
    const wrapBuyer: WrapBuyer = event.data;
    if (wrapBuyer?.type === WrapBuyerType.ITEM) {
      this.isDisplay = true;
    }
    this.isDisplay = true;
  }

  appendParameterToSearchRequest() {
    const params = this.route.snapshot.queryParams;
    if (params['cityId']) {
      this.searchRequest.cityId = Number(params['cityId']);
    }
    if (params['districtId']) {
      this.searchRequest.districtId = Number(params['districtId']);
    }
    if (params['streetId']) {
      this.searchRequest.streetId = Number(params['streetId']);
    }
    if (params['postcode']) {
      this.searchRequest.postcode = params['postcode'];
    }
    if (params['selectedMonth']) {
      this.searchRequest.dobMonth = Number(params['selectedMonth']);
    }
    if (params['selectedDay']) {
      this.searchRequest.dobDay = Number(params['selectedDay']);
    }
  }

  preLoadAddressForFilter() {
    if (this.route.snapshot.queryParamMap.keys.length === 0) {
      return;
    }
    const postcode = Number(this.route.snapshot.queryParamMap.get('postcode'));
    const districtId = this.route.snapshot.queryParamMap.get('districtId');
    if (postcode) {
      this.localService.districtByPostcode(postcode).subscribe(result => {
        this.filteredDistricts = result;
      });
    }
    if (districtId) {
      this.localService.streets(Number(districtId)).subscribe(result => {
        this.filteredStreets = result;
      });
    }
  }

  preLoadSelectedEnqTime() {
    if (this.route.snapshot.queryParamMap.keys.length === 0) {
      return;
    }
    const selectedMonth = this.route.snapshot.queryParamMap.get('selectedMonth');
    const selectedDay = this.route.snapshot.queryParamMap.get('selectedDay');
    if (selectedMonth) {
      this.selectedMonth = Number(selectedMonth);
    }
    if (selectedDay) {
      this.selectedDay = Number(selectedDay);
    }
  }

  navigateWithParam() {
    this.router.navigate([], {
      queryParams: this.param,
    });
  }

  onSearchByPostcode(value: number) {
    this.searchRequest.postcode = Number(value);
    this.searchRequest.districtId = undefined;
    this.searchRequest.streetId = undefined;
    if (value && value !== 0) {
      this.localService.districtByPostcode(value).subscribe(result => {
        this.filteredDistricts = result;
      });
    }
    if (this.searchRequest.postcode) {
      this.param = { ...this.param, postcode: this.searchRequest.postcode };
    } else {
      delete this.param['postcode'];
    }
    this.navigateWithParam();
    if (this.searchRequest.postcode === null) {
      delete this.param['postcode'];
    }
    this.pageChange(1);
  }
}
