export class CustomerBoardSearchRequest {
  streetId?: number;
  farmId?: number;
  districtId?: number;
  zoneId?: number;
  cityId?: number;
  postcode?: number;
  userId?: number;
  startDate?: number;
  endDate?: number;
  start?: any;
  end?: any;
  categoryIds: number[];
  officeId: number;
  rate: number;
}
