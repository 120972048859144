import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CalendarWeekViewComponent} from 'angular-calendar';

@Component({
  selector: 'ngx-custom-week-view',
  templateUrl: './custom-week-view.component.html',
  styleUrls: ['./custom-week-view.component.scss']
})
export class CustomWeekViewComponent extends CalendarWeekViewComponent implements OnInit {

  ngOnInit(): void {
  }

}
