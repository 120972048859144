<nb-card>
  <nb-card-header class='ml-2'>
    Open Home
  </nb-card-header>
  <nb-card-body class='no-scroll'>
    <div class='row'>
      <div class='col-lg-6 mb-2 col-sm-12'>
        <input nbInput fullWidth readonly fieldSize='tiny' placeholder='ENQ Address' [(ngModel)]='address'
               class='cursor-default' (click)='selectAddress()'>
      </div>
      <div class='col-lg-6 col-sm-12'>
        <div class='row mt-1' *ngIf='selectedProspect'>
          <div class='col-3 text-center'>
            Type: {{selectedProspect?.property?.type}}
          </div>
          <div class='col-1 p-0 text-center'>
            Bed: {{selectedProspect?.property?.bed}}
          </div>
          <div class='col-1 p-0 ml-2 text-center'>
            Bath: {{selectedProspect?.property?.bath}}
          </div>
          <div class='col-1 p-0 ml-2 text-center'>
            Car: {{selectedProspect?.property?.car}}
          </div>
          <div class='col-2 p-0 ml-2 text-center'>
            Land: {{selectedProspect?.property?.land}}
          </div>
          <div class='col-3 text-center'>
            <b>
              {{time | date:'dd/MM/yyyy'}}
            </b>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf='selectedProspect && address'>
      <form *ngFor='let form of forms, let index = index' [formGroup]='form'>
        <div class='row mt-4'>
          <div class='col-lg-6 p-1 col-sm-12'>
            <div class='row custom-padding'>
              <div class='col-6 mb-1'>
                <input nbInput fullWidth type='text' class='form-control margin' formControlName='name'
                       placeholder='Name'>
              </div>
              <div class='col-6 mb-1'>
                <input nbInput fullWidth type='text' class='form-control margin' formControlName='surname'
                       placeholder='Surname'>
              </div>
            </div>
          </div>

          <div class='col-lg-2 mb-1 custom-padding col-sm-12'>
            <nb-form-field>
              <input nbInput fullWidth type='text' class='form-control margin' formControlName='phone'
                     placeholder='Phone'>
              <button nbSuffix nbButton ghost
                      (click)='searchBuyer(form, index)'>
                <nb-icon [icon]="'search-outline'"
                         pack='eva'>
                </nb-icon>
              </button>
            </nb-form-field>
          </div>
          <div class='col-lg-4 col-sm-12'>
            <div class='row'>
              <div class='col-10'>
                <ngx-attachment objectType='PROSPECT'
                ></ngx-attachment>
              </div>
              <div class='col-2'>
                <button nbButton status='primary' size='tiny' class='mt-1 fa-pull-right'
                        (click)='showBuyerDetails(form.value.selectedBuyer, form)'
                        [disabled]='!form.value.showDetails'>
                  Details
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class='row mt-2' *ngIf='forms.length > 1'>
          <div class='col-12'>
            <button nbButton status='basic' size='tiny' (click)='removeCustomer(index)' class='fa-pull-right'>Remove
            </button>
          </div>
        </div>
        <hr class='tab-break'>
      </form>
      <div class='row mt-2'>
        <div class='col-12'>
          <button nbButton status='primary' size='tiny' class='mt-1 ml-2 fa-pull-left' (click)='addCustomer()'>Add buyer
          </button>
          <button nbButton status='primary' type='submit' size='tiny' class='mt-1 fa-pull-right'
                  (click)='saveAll()'>Save All
          </button>
        </div>
      </div>
    </div>
  </nb-card-body>
</nb-card>
