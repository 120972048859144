import {Component, Input, OnInit} from '@angular/core';
import {ViewCell} from 'ng2-smart-table';
import {Company} from '../../../../shared/models/response/prospect/company';

@Component({
  template: `
    <div>
      <p *ngIf="isVACMode && rowData.data && rowData.data.property?.rentCompany">
        {{rowData.data.property.rentCompany.name}}</p>
      <p *ngIf="isOTMMode && rowData.data && rowData.data.property?.sellCompany">
        {{rowData.data.property.sellCompany.name}}</p>
    </div>
  `,
})
export class ProspectMarketCompanyComponent implements ViewCell, OnInit {
  isOTMMode: boolean = true;
  isVACMode: boolean = false;

  @Input() value: string | number;
  @Input() rowData: any;

  ngOnInit() {
  }

}
