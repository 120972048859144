import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Prospect} from '../../../../shared/models/response/prospect/prospect';
import {NbDialogRef} from '@nebular/theme';
import {FormService} from '../../../../shared/services/form.service';
import {ProspectService} from '../../../../shared/services/prospect.service';
import {CommonService} from '../../../../shared/services/common.service';
import {ProspectRequest} from '../../../../shared/models/request/prospect/prospect-request';
import {PropertyRequest} from '../../../../shared/models/request/prospect/property-request';
import {Constant} from '../../../../shared/common/constant';
import {AddressRequest} from '../../../../shared/models/request/address/address-request';
import {StringUtils} from '../../../../shared/common/string-utils';

@Component({
  selector: 'ngx-prospect-create-unit-dialog',
  templateUrl: './prospect-create-unit-dialog.component.html',
  styleUrls: ['./prospect-create-unit-dialog.component.scss'],
})
export class ProspectCreateUnitDialogComponent implements OnInit {
  prospectForm: FormGroup;
  prospectGroup: Prospect;
  propertyType = Constant.PROPERTY_TYPE;
  propertyDefaultSpec = Constant.PROPERTY_DEFAULT_SPEC;
  chars = 'abcdefghijklmnopqrstuvwxwz';
  isUpdating = false;
  isSubmitted = false;
  StringUtils = StringUtils;

  constructor(private dialogRef: NbDialogRef<ProspectCreateUnitDialogComponent>,
              private formService: FormService,
              private prospectService: ProspectService,
              private commonService: CommonService) {
  }

  ngOnInit(): void {
    this.prospectForm = new FormGroup({
      unit: new FormControl(null, [
        Validators.required,
      ]),
      numberFrom: new FormControl(this.prospectGroup?.address?.numberFrom, [
        Validators.required,
      ]),
      numberTo: new FormControl(this.prospectGroup?.address?.numberTo),
      streetId: new FormControl(this.prospectGroup?.address?.street?.streetId, [
        Validators.required,
      ]),
      bed: new FormControl(null),
      bath: new FormControl(null),
      car: new FormControl(null),
      land: new FormControl(null),
      type: new FormControl(null),
    });
  }

  close() {
    this.dialogRef.close();
  }

  submit() {
    this.isUpdating = true;
    this.formService.extractErrorMessage(this.prospectForm);
    if (this.prospectForm.invalid) {
      this.isUpdating = false;
      this.isSubmitted = true;
      return;
    }
    let units = [];
    const unitString = this.prospectForm.controls.unit.value?.trim().replace(/\s/g, '');
    if (unitString.indexOf(':') > -1) {
      const arr = unitString.split(':');
      const fromUnit = Number(arr[0]);
      const toUnit = Number(arr[1]);
      if (!isNaN(fromUnit) && !isNaN(toUnit)) {
        for (let i = fromUnit; i <= toUnit; i++) {
          units.push(i);
        }
      } else {
        const filteredChars = this.chars.substring(this.chars.indexOf(arr[0].toLowerCase()),
          this.chars.indexOf(arr[1].toLowerCase()) + 1);
        for (const c of filteredChars) {
          if (arr[0].toLowerCase() ===  arr[0]) {
            units.push(c.toLowerCase());
          } else {
            units.push(c.toUpperCase());
          }
        }
      }
    } else if (unitString.indexOf(',') > -1) {
      units = unitString.split(',');
    } else {
      units.push(unitString);
    }
    if (units.length <= 0) {
      this.isUpdating = false;
      return;
    }
    const prospectRequest: ProspectRequest = new ProspectRequest();
    prospectRequest.property = {...this.prospectForm.value};
    prospectRequest.address = {...this.prospectForm.value};
    this.trimObject(prospectRequest, Object.keys(ProspectRequest.getDummy()));
    this.trimObject(prospectRequest.property, Object.keys(PropertyRequest.getDummy()));
    this.trimObject(prospectRequest.address, Object.keys(AddressRequest.getDummy()));
    // prospectRequest.address?.units = units;
    prospectRequest.address.unit = null;
    this.prospectService.create(prospectRequest).subscribe(
      result => {
        this.commonService.info('Created prospect success.');
        this.dialogRef.close(result);
        this.isUpdating = false;
      },
      error => {
        this.commonService.warningHtml(error.message);
        this.isUpdating = false;
      },
    );
  }

  onPropertyTypeChange () {
    if (this.prospectForm.controls.type.value) {
      const defaultSpect = this.propertyDefaultSpec[this.prospectForm.controls.type.value];
      if (defaultSpect) {
        this.prospectForm.controls.bed.setValue(defaultSpect.bed);
        this.prospectForm.controls.bath.setValue(defaultSpect.bath);
        this.prospectForm.controls.car.setValue(defaultSpect.car);
        this.prospectForm.controls.land.setValue(defaultSpect.land);
      }
    }
  }

  keepOrder(a, b) {
    return 1;
  }

  trimObject(obj: any, properties: string[]): any {
    const curentProperties: string[] = Object.keys(obj);
    for (const name of curentProperties) {
      if (properties.indexOf(name) === -1) {
        delete obj[name];
      }
    }
  }

  showError(name: string): string {
    return this.prospectForm.controls[name].errors
    && (this.prospectForm.controls[name].dirty
      || this.prospectForm.controls[name].touched
      || this.isSubmitted) ? 'has-error' : '';
  }

  showErrorInput(name: string): string {
    return this.prospectForm.controls[name].errors
    && (this.prospectForm.controls[name].dirty
      || this.prospectForm.controls[name].touched
      || this.isSubmitted) ? 'input-error' : '';
  }

}
