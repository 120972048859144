import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BuyerRoutingModule, routedComponents} from './buyer-routing.module';
import {
  NbAccordionModule,
  NbActionsModule,
  NbAutocompleteModule,
  NbButtonModule,
  NbCalendarModule,
  NbCalendarRangeModule,
  NbCardModule,
  NbCheckboxModule,
  NbDatepickerModule, NbDialogModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbListModule, NbPopoverModule,
  NbRadioModule,
  NbSelectModule,
  NbTabsetModule,
  NbTooltipModule,
} from '@nebular/theme';
import {ThemeModule} from '../../../@theme/theme.module';
import {Ng2SmartTableModule} from 'ng2-smart-table';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CalendarModule} from 'angular-calendar';
import {ContextMenuModule} from 'ngx-contextmenu';
import {GoogleMapsModule} from '@angular/google-maps';
import {TaskModule} from '../task/task.module';
import {BuyerListComponent} from './buyer-list/buyer-list.component';
import {BuyerListCallbackdateComponent} from './buyer-list/buyer-list-callbackdate/buyer-list-callbackdate.component';
import {SharedModule} from '../../../shared/module/shared.module';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import { DirectivesModule } from '../../../shared/directive/directives.module';
import { BuyerListDobComponent } from './buyer-list-dob/buyer-list-dob.component';
import { BuyerListFilterDobComponent } from './buyer-list-dob/filter/buyer-list-filter-dob.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { OpenHomeComponent } from './open-home/open-home.component';
import { AdjustTimeComponent } from '../overview/visual-board/adjust-time/adjust-time.component';
import { DatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
  declarations: [
    ...routedComponents,
    BuyerListDobComponent,
    BuyerListFilterDobComponent,
    OpenHomeComponent,
    AdjustTimeComponent,
  ],
  imports: [
    NbCardModule,
    NbIconModule,
    NbInputModule,
    ThemeModule,
    Ng2SmartTableModule,
    NbButtonModule,
    TypeaheadModule,
    NbCheckboxModule,
    FormsModule,
    NbActionsModule,
    NbIconModule,
    NbCalendarModule,
    NbPopoverModule,
    NbCalendarRangeModule,
    NbDatepickerModule,
    NbSelectModule,
    ReactiveFormsModule,
    NbFormFieldModule,
    NbAutocompleteModule,
    CalendarModule,
    NbLayoutModule,
    CommonModule,
    BuyerRoutingModule,
    ContextMenuModule.forRoot({
      useBootstrap4: true,
    }),
    GoogleMapsModule,
    NbRadioModule,
    NbAccordionModule,
    TaskModule,
    NbTabsetModule,
    NbTooltipModule,
    NbListModule,
    SharedModule,
    DirectivesModule,
    ClipboardModule,
    NbDialogModule,
    DatepickerModule,
  ],
  exports: [
    BuyerListComponent,
    BuyerListCallbackdateComponent,
    BuyerListDobComponent,
  ],
})
export class BuyerModule {
}
