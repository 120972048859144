import {Triple} from './triple';
import {Pair} from './pair';

export class SearchRequest {
  limit: number;
  offset: number;
  orders: Pair[] = new Array();
  conditions: Triple[] = new Array();
  conditionType: string;
  subConditions?: SearchRequest[] = new Array();
  joins?: Pair[] = new Array();
}
