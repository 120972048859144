export class ProspectStatistic {
  totalSC?: number;
  totalSA?: number;
  totalSP?: number;
  totalS4?: number;
  totalO4?: number;
  totalS5?: number;
  totalS6?: number;
  totalS7?: number;
  totalB6?: number;
  totalB5?: number;
  totalB4?: number;
  totalBI?: number;
  totalBA?: number;
  totalBC?: number;
  totalSM?: number;
  totalBM?: number;
  totalSN?: number;
  totalBN?: number;
  totalDK?: number;

  constructor() {
    this.totalSC = 0;
    this.totalSA = 0;
    this.totalSP = 0;
    this.totalS4 = 0;
    this.totalO4 = 0;
    this.totalS5 = 0;
    this.totalS6 = 0;
    this.totalS7 = 0;
    this.totalB6 = 0;
    this.totalB5 = 0;
    this.totalB4 = 0;
    this.totalBI = 0;
    this.totalBA = 0;
    this.totalBC = 0;
    this.totalSM = 0;
    this.totalBM = 0;
    this.totalSN = 0;
    this.totalBN = 0;
    this.totalDK = 0;
  }
}
