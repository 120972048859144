import {Company} from './company';
import {Office} from '../office';
import {PropertyRequest} from '../../request/prospect/property-request';
import {Vpa} from './vpa';
import {AgentGroup} from './agent-group';

export class Property {
  propertyId?: number;
  type?: string;
  bed?: number;
  bath?: number;
  car?: number;
  land?: number;
  sell?: boolean;
  rent?: boolean;
  otm?: boolean;
  vac?: boolean;
  buyer?: boolean;
  tenant?: boolean;
  sold?: boolean;
  underContract?: boolean;
  leased?: boolean;
  sellPrice?: number;
  minBuyPrice?: number;
  maxBuyPrice?: number;
  rentPrice?: number;
  askingPrice?: number;
  otmDate?: number;
  vacDate?: number;
  firstEnqDate?: number;
  enqDate?: number;
  rentCompany?: Company;
  sellCompany?: Company;
  purchaseDate?: number;
  noHouse?: number;
  noWeek?: number;
  yearIn?: number;
  monthIn?: number;
  weekOut?: number;
  dayOut?: number;
  office?: Office;
  askingPriceFrom?: number;
  askingPriceTo?: number;
  agentEstimatePriceFrom?: number;
  agentEstimatePriceTo?: number;
  inclusions?: string;
  link?: string;
  methodOfSale?: string;
  agreementDate?: number;
  agencyPeriodFrom?: number;
  agencyPeriodTo?: number;
  commissionRate?: string;
  vpaTotal?: number;
  vpaList?: Vpa[];
  listingStatus?: string;
  agreementType?: string;
  adjustment?: number;
  agentGroups?: AgentGroup[];
  openHomeTime?: number;
  beingFurnished?: boolean;
  managementFee?: number;
  administrationFee?: number;
  monthlyCommission?: number;
  weeklyCommission?: number;
  yearlyCommission?: number;
  rentRollValue?: number;

  static copyFromRequest(propertyRequest: PropertyRequest, property: Property) {
    property.type = propertyRequest.type;
    property.bed = propertyRequest.bed;
    property.bath = propertyRequest.bath;
    property.car = propertyRequest.car;
    property.land = propertyRequest.land;
    property.sell = propertyRequest.sell;
    property.rent = propertyRequest.rent;
    property.otm = propertyRequest.otm;
    property.vac = propertyRequest.vac;
    // property.buyer = propertyRequest.buyer;
    // property.tenant = propertyRequest.tenant;
    property.sold = propertyRequest.sold;
    property.underContract = propertyRequest.underContract;
    property.leased = propertyRequest.leased;
    property.sellPrice = propertyRequest.sellPrice;
    // property.minBuyPrice = propertyRequest.minBuyPrice;
    // property.maxBuyPrice = propertyRequest.maxBuyPrice;
    property.rentPrice = propertyRequest.rentPrice;
    property.otmDate = propertyRequest.otmDate;
    property.vacDate = propertyRequest.vacDate;
    // property.firstEnqDate = propertyRequest.firstEnqDate;
    // property.enqDate = propertyRequest.enqDate;
    // address ? : Address;
    // = propertyRequest.address ? : Address;
    property.purchaseDate = propertyRequest.purchaseDate;
    // property.noHouse = propertyRequest.noHouse;
    // property.noWeek = propertyRequest.noWeek;
    property.yearIn = propertyRequest.yearIn;
    property.monthIn = propertyRequest.monthIn;
    property.weekOut = propertyRequest.weekOut;
    property.dayOut = propertyRequest.dayOut;
  }
}
