<div class="col-12 d-flex mt-2 row">
  <div class="col-md-6 col-12 override-width override-width-md mt-2">
    <select size="tiny"
            [(ngModel)]="selectedDistrictId"
            [disabled]="!filterByPostcode"
            class="full-width"
            (change)="onSuburbSelectChange()"
    >
      <option [value]="undefined">All suburbs</option>
      <option *ngFor="let key of districts"
              [ngClass]="key.status === 'DEACTIVE' ? 'deactive-status' : ''"
              [value]="key.districtId"
      >{{key.name}}</option>
    </select>
  </div>
  <div class="col-md-6 col-12 override-width override-width-md mt-2">
<!--    <select size="tiny"-->
<!--            [(ngModel)]="selectedCityId"-->
<!--            class="full-width"-->
<!--            (change)="onCitySelectChange()"-->
<!--    >-->
<!--      <option [value]="undefined">All cities</option>-->
<!--      <option *ngFor="let key of cities"-->
<!--              [ngClass]="key.status === 'DEACTIVE' ? 'deactive-status' : ''"-->
<!--              [value]="key.cityId"-->
<!--      >{{key.name}}</option>-->
<!--    </select>-->
    <nb-form-field>
      <input nbInput fullWidth placeholder='Postcode' fieldSize='tiny' class='override-nbinput-height'
             [(ngModel)]='postcode'
             [typeahead]='filterPostcode$'
             [ngModelOptions]='{standalone: true}'
             [typeaheadAsync]='true'
             (typeaheadOnSelect)='selectPostcode($event)'
             typeaheadOptionField='code'
             typeaheadMinLength='3'
             typeaheadWaitMs='1000'>
      <!--      <span nbSuffix (click)='searchSuburbByPostcode()'><i class='fa fa-search'></i></span>-->
    </nb-form-field>
  </div>
</div>

<div class="col-12 d-flex mt-3 row">
  <div class="col-3">
    <select fullWidth size="tiny"
            [(ngModel)]="selectedWeek"
            class="full-width"
            (change)="onWeekSelectChange()"
            [disabled]="!selectedMonth"
    >
      <option [ngValue]="undefined">Week</option>
      <option *ngFor="let item of weeks"
              [value]="item"
      >{{item}}</option>
    </select>
  </div>
  <div class="col-3">
    <select fullWidth size="tiny"
            [(ngModel)]="selectedMonth"
            class="full-width"
            [disabled]="!selectedQuarter"
            (change)="onMonthSelectChange()"
    >
      <option [ngValue]="undefined">Month</option>
      <option *ngFor="let item of months"
              [value]="item"
      >{{item}}</option>
    </select>
  </div>
  <div class="col-3">
    <select fullWidth size="tiny"
            [(ngModel)]="selectedQuarter"
            class="full-width"
            [disabled]="!selectedYear"
            (change)="onQuarterSelectChange()"
    >
      <option [ngValue]="undefined">Quarter</option>
      <option *ngFor="let item of quarters"
              [value]="item"
      >{{item}}</option>
    </select>
  </div>
  <div class="col-3">
    <select fullWidth size="tiny"
            [(ngModel)]="selectedYear"
            class="full-width"
            (change)="onYearSelectChange()"
    >
      <option [ngValue]="undefined">Year</option>
      <option *ngFor="let item of years"
              [value]="item"
      >{{item}}</option>
    </select>
  </div>
</div>
<div class="col-12 d-flex mt-2">
  <div class="col-4">
    {{total}}
  </div>
</div>
