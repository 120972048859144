import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-buyer',
  template: `<router-outlet></router-outlet>`,
  styles: [':host {height: 100%;}'],
})
export class BuyerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
