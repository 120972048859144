<table>
  <tr *ngFor="let row of colors">
    <td *ngFor="let color of row"
        class="color-cell"
        [ngClass]="defaultColor === color ? 'selected' : ''"
        [ngStyle]="{'background-color': color}"
        (click)="selectColor(color)"
    ></td>
  </tr>
</table>
