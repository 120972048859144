export class ArrayUtils {
  static reverse(nums: any[], i: number, j: number) {
    let li = i;
    let ri = j;

    while (li < ri) {
      const temp = nums[li];
      nums[li] = nums[ri];
      nums[ri] = temp;

      li++;
      ri--;
    }
  }

  static rotate(nums: any[], k: number) {
    k = k % nums.length;
    if (k < 0) {
      k += nums.length;
    }
    ArrayUtils.reverse(nums, 0, nums.length - k - 1);
    ArrayUtils.reverse(nums, nums.length - k, nums.length - 1);
    ArrayUtils.reverse(nums, 0, nums.length - 1);
  }
}
