export class Triple {
  left: string;
  middle?: string;
  right?: any;
  dataType?: string;
  expression?: string;

  constructor(left: string, mid: string, right?: any) {
    this.left = left;
    this.middle = mid;
    this.right = right ? right : null;
  }
}
