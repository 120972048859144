import {Component, Input, OnInit} from '@angular/core';
import {ViewCell} from 'ng2-smart-table';
import * as moment from 'moment';
import {Constant} from '../../../../shared/common/constant';
import {StringUtils} from '../../../../shared/common/string-utils';

@Component({
  template: `
    {{renderValue}}
  `,
})
export class BuyerPickCustomCellComponent implements ViewCell, OnInit {

  renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;
  _valueReplacement?: any;
  _propertyPaths: string[];
  _valueType: string;
  _separator: string;
  public static VALUE_TYPES = {
    DEFAULT: 'DEFAULT',
    DATE: 'DATE',
    CURRENCY: 'CURRENCY',
  };

  groupColumns = ['address?.street.name'];
  StringUtils = StringUtils;

  set config(value: {
    valueReplacement?: any,  // {key : value}
    propertyPaths: string[],
    valueType: string,
    separator: string,
  }) {
    if (!value.propertyPaths) {
      return;
    }
    if (!value.valueType) {
      this._valueType = BuyerPickCustomCellComponent.VALUE_TYPES.DEFAULT;
    }
    this._propertyPaths = value.propertyPaths;
    this._valueType = value.valueType;
    this._separator = value.separator ? value.separator : ' ';
    this._valueReplacement = value.valueReplacement;
    this.renderValue = this.getValue();
  }

  ngOnInit() {
    if (this._propertyPaths) {
      this.renderValue = this.getValue();
    } else {
      this.renderValue = this.value ? this.value.toString() : '';
    }
  }

  getValue(): any {
    let result = '';
    this._propertyPaths.forEach(propertyPath => {
      const value = this.extractValueFromData(propertyPath);
      if (value && value !== '') {
        result = `${result}${this._separator}${value}`;
      }
    });
    return result.trim().replace(this._separator, '');
  }

  extractValueFromData(propertyPath: string): any {
    if (!this.rowData) {
      return '';
    }
    if (propertyPath && propertyPath.length > 0 && this.rowData) {
      const properties: string[] = propertyPath.split('.');
      if (properties && properties.length > 0) {
        let value = null;
        for (const x of properties) {
          if (!value) {
            if (x === 'cellClass') {
              value = this.getClassValue();
            } else if (x === 'cellStatus') {
              value = this.getStatusValue();
            } else if (x === 'cellAddress') {
              value = this.getAddressValue();
            } else {
              value = this.rowData?.[x];
            }
          } else if (value[x]) {
            value = value[x];
          } else {
            value = '';
            break;
          }
        }
        if (value && value !== '') {
          switch (this._valueType) {
            case BuyerPickCustomCellComponent.VALUE_TYPES.CURRENCY:
              value = `${value}${Constant.DEFAULT_CURRENCY_ICON}`;
              break;
            case BuyerPickCustomCellComponent.VALUE_TYPES.DATE:
              value = moment(Number(value)).format('DD/MM/YYYY');
              break;
            default:
              break;
          }
        }
        if (this._valueReplacement) {
          value = this._valueReplacement[value];
        }
        return value;
      }
    }
  }

  getClassValue(): string {
    let value = '';
    const buyer = this.rowData;
    if (buyer.isFirstHome) {
      value += 'F';
    }
    if (buyer.isInvestor) {
      value += 'I';
    }
    if (buyer.isValueBuyer) {
      value += 'VB';
    }
    if (buyer.isDeveloper) {
      value += 'D';
    }
    return value;
  }

  getAddressValue(): string {
    const prospect = this.rowData.prospect;
    let value = '';
    if (prospect) {
      value = `${StringUtils.getShortAddress(prospect?.address)}`;
      // value = `${prospect?.address?.unit ? prospect?.address?.unit + '/' : ''}`
      //   + `${prospect?.address?.number ? prospect?.address?.number + ', ' : ''}`
      //   + `${prospect?.address?.street?.name}`;
    }
    return value;
  }

  getStatusValue(): string {
    let value = '';
    const data = this.rowData;
    if (data.buyerCode?.trim() === 'VB' || data.buyerCode?.trim() === 'B' || data.buyerCode?.trim() === 'T') {
      if (data.isCommon) {
        value = `${value}C`;
      }
      if (data.isAddress) {
        value = `${value}A`;
      }
      if (data.isNumber) {
        value = `${value}N`;
      }
      if (data.isInspection) {
        value = `${value}I`;
      }
    } else if (data.buyerCode?.trim() === 'S') {
      if (data.address?.districtId) {
        value = `${value}S`;
      }
      if (data.address?.streetId) {
        value = `${value}S`;
      }
      if (data.address?.numberFrom) {
        value = `${value}N`;
      }
    }
    return value;
  }

}
