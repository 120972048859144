import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Prospect } from '../../../../shared/models/response/prospect/prospect';
import { WrapProspect } from './prospect-utils';
import { NbDialogService } from '@nebular/theme';
import { AdjustTimeComponent } from '../../overview/visual-board/adjust-time/adjust-time.component';
import { ProspectService } from '../../../../shared/services/prospect.service';
import { CommonService } from '../../../../shared/services/common.service';
import { ProspectRequest } from '../../../../shared/models/request/prospect/prospect-request';
import { PropertyRequest } from '../../../../shared/models/request/prospect/property-request';

@Component({
  template: `
    <div>
      <div (click)='navigateToOpenHome($event);' class='open-home row'>{{renderValue}}</div>
      <div class='row'>
        <button nbButton status='basic' size='tiny' (click)='editTime($event)'>
          <i class='fa fa-edit'></i>
        </button>
      </div>
    </div>
  `,
  styles: [],
})
export class VisualBoardOpenHomeComponent implements OnInit {
  renderValue: string;
  selectedProspect: Prospect;
  @Input() rowData: WrapProspect;

  constructor(private router: Router,
              private dialogService: NbDialogService,
              private prospectService: ProspectService,
              private commonService: CommonService) {
  }

  ngOnInit() {
    this.selectedProspect = this.rowData.data;
    if (!this.selectedProspect.property.openHomeTime) {
      this.renderValue = '11:30 AM - SAT';
    } else {
      this.generateRenderValue(new Date(this.selectedProspect.property.openHomeTime));
    }
  }

  navigateToOpenHome(event: any) {
    event.stopPropagation();
    const link = this.router.serializeUrl(this.router.createUrlTree(['/pages/buyer/open-home'],
      { queryParams: { prospectId: this.selectedProspect.prospectId } }));
    window.open(link, '_blank');
  }

  editTime(event: any) {
    event.stopPropagation();
    this.dialogService.open(AdjustTimeComponent, {
      context: {
        data: this.selectedProspect,
      }
    }).onClose.subscribe(result => {
      if (result) {
        const request = new PropertyRequest();
        request.openHomeTime = result;
        request.propertyId = this.selectedProspect.property.propertyId;
        this.prospectService.updateOpenHomeTime(request).subscribe(r => {
          this.generateRenderValue(result);
          this.commonService.info('Saved open home time success');
        });
      }
    });
  }

  generateRenderValue(date: Date) {
    const hour = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
    const minute = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    const hourType = hour >= 12 ? 'PM' : 'AM';
    const d = date.toLocaleString('en', {weekday: 'long'}).substr(0, 3).toUpperCase();
    this.renderValue = hour + ':' + minute + ' ' + hourType + ' - ' + d;
  }
}
