import {Component, Input, OnInit} from '@angular/core';
import {CommonService} from '../../../services/common.service';
import {NbDialogRef, NbDialogService} from '@nebular/theme';
import {RestResult} from '../../../models/response/rest-result';
import {SearchRequest} from '../../../models/request/search-request';
import {LocalDataSource} from 'ng2-smart-table';
import {WrapProspect} from '../../../../modules/pages/prospect/common/prospect-utils';
import {BuyerService} from '../../../services/buyer.service';
import {BuyerUtils} from '../../../../modules/pages/buyer/common/buyer-utils';
import { Buyer } from '../../../models/response/buyer/buyer';


@Component({
  selector: 'ngx-prospect-pick',
  templateUrl: './customer-pick.component.html',
  styleUrls: ['./customer-pick.component.scss'],
})

export class CustomerPickComponent implements OnInit {
  @Input() phone: number;

  data: Buyer[];
  searchRequest = new SearchRequest();
  change: any;
  source: LocalDataSource = new LocalDataSource();
  pageSize = 10;
  currentPage = 1;

  columnsDefinition = {
    buyerName: {
      enable: true,
      value: BuyerUtils.createPickColumn('Name', ['customer.name']),
      name: 'Name',
    },
    buyerSurname: {
      enable: true,
      value: BuyerUtils.createPickColumn('SName', ['customer.surname']),
      name: 'SName',
    },
    buyerPhone: {
      enable: true,
      value: BuyerUtils.createPickColumn('Phone', ['customer.mobilePhone']),
      name: 'Phone',
    },
    classification: {
      enable: true,
      value: BuyerUtils.createPickColumn('Class', ['cellClass']),
      name: 'Class',
    },
    buyerStatus: {
      enable: true,
      value: BuyerUtils.createPickColumn('Qualify', ['cellStatus']),
      name: 'Qualification',
    },
    buyerAddress: {
      enable: true,
      value: BuyerUtils.createPickColumn('Address', ['cellAddress']),
      name: 'Address',
    },
    buyerMinPrice: {
      enable: true,
      value: BuyerUtils.createPickColumn('Min price', ['property.minBuyPrice']),
      name: 'Min price',
    },
    buyerMaxPrice: {
      enable: true,
      value: BuyerUtils.createPickColumn('Max price', ['property.maxBuyPrice']),
      name: 'Max price',
    },
  };

  settings = {
    mode: 'external',
    hideSubHeader: true,
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    pager: {
      display: true,
      perPage: this.pageSize,
    },
    columns: BuyerUtils.createPickColumns(this.columnsDefinition),
  };

  constructor(private dialogService: NbDialogService,
              protected ref: NbDialogRef<CustomerPickComponent>,
              private commonService: CommonService,
              private buyerService: BuyerService) {

  }

  ngOnInit() {
    this.source.onChanged().subscribe((change) => {
      this.change = change;
      if (change.action === 'refresh') {
        this.loadRequest(1);
      }
      if (change.action === 'page') {
        this.loadRequest(change.paging.page);
      }
      if (change.action === 'sort') {
        this.source.load(new Array());
        this.loadRequest(1);
      }
      if (change.action === 'filter') {
        this.source.load(new Array());
        this.loadRequest(1);
      }
    });
  }

  loadRequest(pageIndex) {
    this.currentPage = pageIndex;
    this.searchRequest.offset = (pageIndex - 1) * this.pageSize;
    if (this.searchRequest.offset < 0) {
      this.searchRequest.offset = 0;
    }
    this.searchRequest.limit = this.pageSize;
    this.prepareRequest();
    if (this.data) {
      this.source.load(this.data);
    } else {
      this.buyerService.search(this.searchRequest).subscribe((result: RestResult) => {
        this.source.load(result.data);
      });
    }
  }

  prepareRequest() {
    // init conditions
    if (!this.searchRequest.conditions) {
      this.searchRequest.conditions = new Array();
    }
    this.searchRequest.conditions = new Array();
    if (this.phone) {
      this.searchRequest.conditions.push(
        {
          left: 'customer.mobilePhone',
          middle: 'like',
          right: this.phone
        }
      );
    }
    if (this.change.filter && this.change.filter.filters.length > 0) {
      this.change.filter.filters.forEach(filter => {
        if (filter.search && filter.search.trim() !== '') {
          this.searchRequest.conditions.push(
            {
              left: filter.field,
              middle: 'like',
              right: filter.search
            }
          );
        }
      });
    }

    // init orders
    this.searchRequest.orders = new Array();
    if (this.change.sort && this.change.sort.length > 0) {
      this.change.sort.forEach(sort => {
        this.searchRequest.orders.push(
          {
            left: sort.field,
            right: sort.direction

          }
        );
      });
    } else {
      this.searchRequest.orders.push(
        {
          left: 'buyerId',
          right: 'desc'

        }
      );
    }
  }

  selectRecord(event: any, openEditDialog: boolean, addToSelected: boolean) {
    const customer: WrapProspect = event.data;
    this.select(customer);
  }

  select(event: any) {
    this.ref.close(event);
  }

  cancel() {
    this.ref.close();
  }
}
