<div #plb class='row'>
  <div class='col-md-7 col-sm-12 left-panel table-container'
       [ngClass]="{'popup-hide': isDisplay}">
    <nb-card>
      <nb-card-body class='scroll-advance'>
        <ngx-prospect-list-filter class='sticky-filter index'
                                  *ngIf='isFilter'
                                  [phone]='searchRequest?.phone'
                                  [districts]='filteredDistricts'
                                  [districtId]='searchRequest?.districtId'
                                  [streets]='filteredStreets'
                                  [streetId]='searchRequest?.streetId'
                                  [cities]='cities'
                                  [cityId]='searchRequest?.cityId'
                                  [sortTypesText]='sortTypesText'
                                  [sortType]='searchRequest?.sortType'
                                  [total]='groupWrapper?.totalItems'
                                  [totalMail]='groupWrapper?.totalMail'
                                  [totalDoor]='groupWrapper?.totalDoor'
                                  [totalCall]='groupWrapper?.totalCall'
                                  [totalSms]='groupWrapper?.totalSms'
                                  [isCAMode]='searchRequest?.isCaMode'
                                  [isDkMode]='searchRequest?.isDkMode'
                                  [farm]='selectedProspect?.address?.street?.farm?.name'
                                  [zone]='selectedProspect?.address?.street?.district?.zone?.name'
                                  [rate]='searchRequest?.rate'
                                  [postcode]='searchRequest?.postcode'
                                  (onSuburbChange)='onSuburbChange($event)'
                                  (onStreetChange)='onStreetChange($event)'
                                  (onCityChange)='onCityChange($event)'
                                  (onSortTypeChange)='onSortTypeChange($event)'
                                  (onAddNewProspect)='addRecord()'
                                  (onCaMode)='onCaModeChange($event)'
                                  (onDkMode)='onDkModeChange($event)'
                                  (onSelectCategory)='onSelectCategory($event)'
                                  (onRemoveCategory)='onRemoveCategory($event)'
                                  (onSelectUser)='onSelectUser($event)'
                                  (onRemoveUser)='onRemoveUser($event)'
                                  (onOpenShortcut)='openShortcut()'
                                  (onSelectRate)='onSelectRate($event)'
                                  (onSearch)='onSearch($event)'
                                  (onSearchByPostcode)='onSearchByPostcode($event)'
                                  (onPhoneNumberChange)='onPhoneNumberChange($event)'
        >
        </ngx-prospect-list-filter>
        <div class='row mt-2 ml-2' *ngIf='!isFilter'>
          <div class='col-12'>
            {{groupWrapper?.totalItems}}
            <span *ngIf='potentialCommission'> - </span>
            <span nbTooltip='Potential Commission = Total Sell Value * 2.5%'
                  *ngIf='potentialCommission'
                  class='money-count commission'>{{ potentialCommission | currency:'USD' : 'symbol' : '1.0-0' }}</span>
            <span *ngIf='potentialRentalValue'> - </span>
            <span [nbTooltip]='rentalTooltip'
                  *ngIf='potentialRentalValue'
                  class='money-count rent'>{{ potentialRentalValue | currency:'USD' : 'symbol' : '1.0-0' }}</span>
          </div>
        </div>
        <div class='row mt-3 pl-2' *ngIf='isFilter'>
          <div class='col-2 col-sm-1'>
            <select size='tiny' [(ngModel)]='selectedMarket' class='full-width select-type'
                    (change)='onMarketChange()'>
              <option [value]='undefined'>All</option>
              <option *ngFor='let item of markets'
                      [value]='item'
              >{{item}}</option>
            </select>
          </div>
          <div class='col-2 col-sm-1'>
            <button nbButton size='tiny' hero status='info'
                    (click)='switchToMapView()'>
              {{ viewMap === true ? 'List' : 'Map' }}
            </button>
          </div>
          <div class='col-8 col-sm-4'>
            <button nbButton size='tiny' hero status='primary' class='fa-pull-right mr-2'
                    (click)='exportEmail()'>
              <i class='fa fa-download mr-1'></i> Email
            </button>
            <button nbButton size='tiny' hero status='primary' class='fa-pull-right mr-1'
                    (click)='exportMobile()'>
              <i class='fa fa-download mr-1'></i> Mobile
            </button>
          </div>
        </div>
        <div class='row mt-3 pl-2' *ngIf='!isFilter'>
          <div class='col-8 col-sm-4'>
            <button nbButton size='tiny' hero status='primary' class='fa-pull-right mr-2'
                    (click)='exportEmail()'>
              <i class='fa fa-download mr-1'></i> Email
            </button>
            <button nbButton size='tiny' hero status='primary' class='fa-pull-right mr-1'
                    (click)='exportMobile()'>
              <i class='fa fa-download mr-1'></i> Mobile
            </button>
          </div>
        </div>
        <div class='new-freeze-body'>
          <div class='d-flex'>
            <div class='mt-2'>
              <button nbButton size='tiny' hero status='primary' class='mr-2'
                      (click)='clearSelected()'
                      [hidden]='this.willBeDeletedProspectIds.length <= 0 &&
                       this.willBeDeletedGroupIds.length <= 0'>
                Clear Selected
              </button>
            </div>
            <div class='mt-2' *appPermissionCheck='ComponentPermission.CLIENT_DELETE'>
              <button nbButton size='tiny' hero status='danger' class='ml-2'
                      [hidden]='willBeDeletedProspectIds.length <= 0 && willBeDeletedGroupIds.length <= 0'
                      (click)='removeSelected()'>Remove selected
              </button>
            </div>
          </div>
          <div class='mt-2'>
            <ng2-smart-table *ngIf='!viewMap'
                             [settings]='settings'
                             [source]='source'
                             (userRowSelect)='selectRecord($event, false, true); selectRow($event, prospectDialog)'
            >
            </ng2-smart-table>
            <ngx-prospect-map
              *ngIf='viewMap === true'
              [prospects]='prospects'
              [prospectDisplayType]='selectedMarket'
              (selectProspect)='selectProspectFromMapView($event)'
            ></ngx-prospect-map>
          </div>
        </div>
      </nb-card-body>
    </nb-card>
  </div>
  <div class='col-md-5 col-sm-12 right-panel table-right-container'
       [ngClass]="{
       'popup-detail': isDisplay,
       'hide-detail': !isDisplay
       }">
    <ngx-prospect-dialog #pd
      [companies]='companies'
      [taskTypes]='taskTypes'
      [taskStatuses]='taskStatuses'
      (editDone)='editDone($event)'
      [selectedProspect]='selectedProspect'
      (navigateProspectEvent)='navigateProspect($event)'
    ></ngx-prospect-dialog>
  </div>
  <div class='d-flex col-12'>
    <button *ngIf='isDialog'
            nbButton size='tiny' hero status='primary' class='fa-pull-right ml-2'
            (click)='closeDialog()'>Cancel
    </button>
  </div>
  <div class='scroll-mobile left-54' *ngIf='selectedProspect && isDisplay'>
    <button (click)='cancel()' size='tiny' nbButton status='warning'>Cancel</button>
  </div>

</div>

