import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  path: string;
  errorCode: string;
  title: string;
  code: number;

  constructor(private route: ActivatedRoute,
              private _location: Location,
              private router: Router) {
  }

  ngOnInit() {
    const self = this;
    this.route.paramMap.subscribe(params => {
      this.errorCode = params.get('ma-loi');
      if (this.errorCode === 'khong-tim-thay') {
        this.code = 404;
        this.title = 'The requested URL was not found on our system.';
      } else if (this.errorCode === 'vuot-quyen') {
        this.code = 403;
        this.title = 'Your permission is not allowed to access this URL';
      } else if (this.errorCode === 'loi-he-thong') {
        this.code = 500;
        this.title = 'Exception is occurred during process the request.';
      } else if (this.errorCode === 'he-thong-nang-cap') {
        this.code = 43;
        this.title = 'System is upgrading. Please comeback later or contact Administrator.';
      } else {
        this.router.navigate(['/thong-bao/khong-tim-thay']);
      }
    });
  }

  backClicked() {
    this._location.back();
  }
}
