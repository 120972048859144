import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProspectRoutingModule, routedComponents} from './prospect-routing.module';
import {
  NbAccordionModule,
  NbActionsModule,
  NbAutocompleteModule,
  NbButtonModule,
  NbCalendarModule,
  NbCalendarRangeModule,
  NbCardModule,
  NbCheckboxModule,
  NbDatepickerModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule, NbPopoverModule,
  NbRadioModule,
  NbSelectModule,
  NbTabsetModule, NbToggleModule, NbTooltipModule,
} from '@nebular/theme';
import {ThemeModule} from '../../../@theme/theme.module';
import {Ng2SmartTableModule} from 'ng2-smart-table';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CalendarModule} from 'angular-calendar';
import {ContextMenuModule} from 'ngx-contextmenu';
import {GoogleMapsModule} from '@angular/google-maps';
import {TaskModule} from '../task/task.module';
import {SharedModule} from '../../../shared/module/shared.module';
import {ProspectListBasicComponent} from './prospect-list-basic/prospect-list-basic.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ProspectListCallbackdateComponent} from './prospect-list-callbackdate/prospect-list-callbackdate.component';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {DirectivesModule} from '../../../shared/directive/directives.module';
import { ProspectListDobComponent } from './prospect-list-dob/prospect-list-dob.component';
import { VisualBoardOpenHomeComponent } from './common/visual-board-open-home-component';
import { VisualBoardAddressCellComponent } from './common/visual-board-address-cell-component';
import { ClientListComponent } from './client-list/client-list.component';
import {MatTableModule} from "@angular/material/table";

@NgModule({
  declarations: [
    ...routedComponents,
    VisualBoardOpenHomeComponent,
    VisualBoardAddressCellComponent,
    ClientListComponent
  ],
  imports: [
    NbCardModule,
    NbIconModule,
    NbInputModule,
    ThemeModule,
    Ng2SmartTableModule,
    NbButtonModule,
    NbCheckboxModule,
    FormsModule,
    NbActionsModule,
    NbIconModule,
    TypeaheadModule,
    NbCalendarModule,
    NbPopoverModule,
    NbCalendarRangeModule,
    NbDatepickerModule,
    NbSelectModule,
    ReactiveFormsModule,
    NbToggleModule,
    NbFormFieldModule,
    NbAutocompleteModule,
    CalendarModule,
    NbLayoutModule,
    CommonModule,
    ProspectRoutingModule,
    ContextMenuModule.forRoot({
      useBootstrap4: true,
    }),
    GoogleMapsModule,
    NbRadioModule,
    NbAccordionModule,
    GoogleMapsModule,
    TaskModule,
    NbTabsetModule,
    NbTooltipModule,
    SharedModule,
    DragDropModule,
    DirectivesModule,
    MatTableModule,
  ],
  exports: [
    ProspectListBasicComponent,
    ProspectListCallbackdateComponent,
    ProspectListDobComponent,
  ],
})
export class ProspectModule { }
