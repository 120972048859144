<div class="app-body">
  <app-sidebar #appSidebar [fixed]="true" [display]="'lg'" [minimized]="sidebarMinimized"
               (minimizedChange)="toggleMinimize($event)" class='custom-sticky'>
    <app-sidebar-nav [navItems]="navItems" [perfectScrollbar] [disabled]="appSidebar.minimized"></app-sidebar-nav>
    <app-sidebar-minimizer></app-sidebar-minimizer>
  </app-sidebar>
  <!-- Main content -->
  <main class='main'>
    <app-header
      [navbarBrandRouterLink]="['/pages/dashboard']"
      [fixed]='true'
      [navbarBrandFull]="{src: 'assets/images/logo.PNG', width: 89, height: 25, alt: 'RedEarth', href:'/pages/dashboard'}"
      [navbarBrandMinimized]="{src: 'assets/images/logo.PNG', width: 30, height: 30, alt: 'RedEarth', href:'/pages/dashboard'}"
      [sidebarToggler]="'lg'"
      [asideMenuToggler]="'lg'">
      <ul class='nav navbar-nav ml-auto custom-title'>
        <li class='nav-item mr-2 site-name'>{{siteName}}</li>
        <li class='nav-item'>
          <span class='mr-1 default-color'>{{shortFirstName}} {{shortLastName}}</span>
        </li>
        <li class='nav-item'>
          <button nbTooltip="Create Task" nbButton status="primary" size="tiny" class="mr-1" (click)="createTask()"><i class="fa fa-plus-circle"></i></button>
        </li>
      </ul>
      <button nbButton size='tiny' status='basic' (click)='listBirthdayToday()' [nbTooltip]='generateTooltip()'>
        {{total}}
      </button>
    </app-header>
    <div class='container-fluid'>
      <nb-layout>
        <nb-layout-column class='one-column-content'>
          <router-outlet></router-outlet>
        </nb-layout-column>
      </nb-layout>
    </div>
  </main>
  <app-aside [fixed]='true' [display]='false' [ngClass]="'test'">
    <!--    <tabset>-->
    <!--      <tab>-->
    <div class='list-group list-group-accent p-2 global-search'>
      <nb-form-field>
        <input type='text' nbInput fullWidth fieldSize='tiny' placeholder='Address' [(ngModel)]='address'
               (mwlKeydownEnter)='searchAddress()'>
        <i nbSuffix class='fa fa-search pointer' (click)='searchAddress()'>
        </i>
      </nb-form-field>
      <nb-form-field>
        <input type='text' nbInput fullWidth fieldSize='tiny' placeholder='Phone' [(ngModel)]='phone'
               (mwlKeydownEnter)='searchPhone()' class='mt-2'>
        <i nbSuffix class='fa fa-search pointer' (click)='searchPhone()'>
        </i>
      </nb-form-field>
      <div class="row mt-2 justify-content-center">
        <label class="switch margin-center">
          <input type="checkbox" (click)="changeThemeColor()">
          <span class="slider round"></span>
        </label>
        <span class="margin-center ml-1">Dark</span>
      </div>
    </div>
    <!--      </tab>-->
    <div class='stay-bottom'>
      <!--        <tab class='stay-bottom'>-->
      <div class='list-group list-group-accent'>
        <div
          class='list-group-item list-group-item-accent-secondary bg-light text-center font-weight-bold text-muted text-uppercase small'>
          <a class='logout-button' (click)='logout()'><i class='fa fa-lock'></i> Logout</a>
        </div>
      </div>
      <!--        </tab>-->
    </div>
    <!--    </tabset>-->
  </app-aside>
</div>
<!--<app-footer>-->
<!--  <span style="color:red;">RedEarth &copy; 2021.</span>-->
<!--</app-footer>-->
