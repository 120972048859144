<h1 id="title" class="title">Forgot Password</h1>
<p class="sub-title" *ngIf="!done">Enter your email address and we’ll send a link to reset your password</p>

<form (ngSubmit)="requestPass()" #requestPassForm="ngForm" aria-labelledby="title">

  <div class="form-control-group">
    <p *ngIf="done">Password has been sent to your email, please check and use that password to
      login and change the password later.</p>
    <label
      *ngIf="!done"
      class="label"
      for="input-email">Enter your email address:</label>
    <input nbInput
           *ngIf="!done"
           [(ngModel)]="email"
           id="input-email"
           name="email"
           autofocus
           fullWidth
           fieldSize="large">
  </div>

  <button
    *ngIf="!done"
    nbButton
    fullWidth
    status="primary"
    size="large">
    Request password
  </button>
</form>

<section class="sign-in-or-up" aria-label="Sign in or sign up">
  <p><a class="text-link" (click)="routeLogin()">Back to Log In</a></p>
</section>
