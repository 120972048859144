import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'ngx-overview',
  template: `
    <router-outlet></router-outlet>`,
  styles: [':host {height: 100%;}'],
})
export class OverviewComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
