export class BuyerDeposit {
  buyerId: number;
  name: string;
  label: string;
  price: number;
  isChecked: boolean;
  isTenant: boolean;
  setDate: number;
  status: string;
}
