import {LocalDataSource} from 'ng2-smart-table';

export class BuyerDatasource extends LocalDataSource {
  protected lastRequestCount: number = 0;

  set total(value) {
    this.lastRequestCount = value;
  }

  constructor() {
    super();
  }

  count(): number {
    return this.lastRequestCount;
  }
}
