import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NbDialogRef} from '@nebular/theme';
import {CommonService} from '../../../../../shared/services/common.service';
import {FormService} from '../../../../../shared/services/form.service';
import {TaskStatusService} from '../../../../../shared/services/task-status.service';
import { ComponentPermission } from '../../../../../component.permission';

@Component({
  selector: 'ngx-task-status-create',
  templateUrl: './task-status-create.component.html',
  styleUrls: ['./task-status-create.component.scss'],
})
export class TaskStatusCreateComponent implements OnInit {

  ComponentPermission = ComponentPermission;
  form: FormGroup;
  isSubmitted = false;

  constructor(protected ref: NbDialogRef<TaskStatusCreateComponent>,
              private taskStatusService: TaskStatusService,
              private commonService: CommonService,
              private formService: FormService) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.form = new FormGroup({
      name: new FormControl('', [
        Validators.required,
      ]),
      description: new FormControl(''),
    });
  }

  submit() {
    const form = this.form.value;
    this.formService.extractErrorMessage(this.form);
    if (this.form.invalid) {
      this.isSubmitted = true;
      return;
    }
    this.taskStatusService.create(form).subscribe(
      result => {
        this.commonService.info('Created success.');
        this.ref.close(result);
      },
      error => {
        this.commonService.warningHtml(error.message);
      },
    );
  }

  cancel() {
    this.ref.close();
  }

  showError(name: string): string {
    return this.form.controls[name]
    && this.form.controls[name].errors
    && (this.form.controls[name].dirty
      || this.form.controls[name].touched
      || this.isSubmitted) ? 'has-error' : '';
  }

  colorChange(colorValue: string) {
    this.form.controls.color.setValue(colorValue);
  }

}
