import {Component, Input, OnInit} from '@angular/core';
import {ViewCell} from 'ng2-smart-table';

@Component({
  template: `
    <div>
      <p *ngIf="isOTMMode && rowData.data && rowData.data?.property?.sold">Sold</p>
      <p *ngIf="isOTMMode && rowData.data && rowData.data?.property?.underContract">
        Under Contract
      </p>
      <p *ngIf="isVACMode && rowData.data && rowData.data?.property?.leased">Leased</p>
    </div>
  `,
})
export class ProspectMarketPropertyStatusComponent implements ViewCell, OnInit {

  isOTMMode: boolean = true;
  isVACMode: boolean = false;

  @Input() value: string | number;
  @Input() rowData: any;

  ngOnInit() {
  }

}
