import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {OverviewComponent} from './overview.component';
import {CallbackdateOverviewComponent} from './callbackdate-overview/callbackdate-overview.component';
import {CommonOverviewComponent} from './common-overview/common-overview.component';
import {SearchCustomerComponent} from './search-customer/search-customer.component';
import {ProspectOverviewComponent} from './prospect-overview/prospect-overview.component';
import {SearchAddressComponent} from './search-address/search-address.component';
import { DuplicatePhoneComponent } from './duplicate-phone/duplicate-phone.component';
import {SmartTableCustomSelectComponent} from '../buyer/common/smart-table-custom-select-component';
import {SmartTableCustomCellComponent} from '../buyer/common/smart-table-custom-cell-component';
import {IndexComponent} from './index/index.component';
import { TotalImportComponent } from './total-import/total-import.component';
import { DobOverviewComponent } from './dob-overview/dob-overview.component';
import { VisualBoardComponent } from './visual-board/visual-board.component';
import { RentalBoardComponent } from './rental-board/rental-board.component';

const routes: Routes = [{
  path: '',
  component: OverviewComponent,
  children: [
    {
      path: 'index',
      component: IndexComponent,
    },
    {
      path: 'overview',
      component: CommonOverviewComponent,
    },
    {
      path: 'callback-date',
      component: CallbackdateOverviewComponent,
    },
    {
      path: 'search/phone',
      component: SearchCustomerComponent,
    },
    {
      path: 'search/address',
      component: SearchAddressComponent,
    },
    {
      path: 'search/phone/duplicate',
      component: DuplicatePhoneComponent,
    },
    {
      path: 'data-report',
      component: TotalImportComponent,
    },
    {
      path: 'dob',
      component: DobOverviewComponent,
    },
    {
      path: 'visual-board',
      component: VisualBoardComponent,
    },
    {
      path: 'rental-board',
      component: RentalBoardComponent,
    },
  ],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OverviewRoutingModule {
}

export const routedComponents = [
  IndexComponent,
  OverviewComponent,
  ProspectOverviewComponent,
  SmartTableCustomSelectComponent,
  SmartTableCustomCellComponent,
  SearchCustomerComponent,
  SearchAddressComponent,
  CallbackdateOverviewComponent,
  CommonOverviewComponent,
  DuplicatePhoneComponent,
];
