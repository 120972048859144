<nb-card *appPermissionCheck='ComponentPermission.CLIENT_BOARD'
         [ngClass]="isDisplay? 'filter-blur' : ''">
  <nb-card-header>
    <ngx-client-board-filter-advance
      [districts]='filteredDistricts'
      [streets]='filteredStreets'
      [cities]='cities'
      [zones]='filteredZones'
      [farms]='filteredFarms'
      [postcodes]='filteredPostcode'
      [searchRequest]='searchRequest'
      [selectedCategoryIds]='searchRequest.categoryIds'
      [motivateType]='searchRequest.motivateType'
      [nationality]='searchRequest.nationality'
      [religion]='searchRequest.religion'
      [age]='searchRequest.age'
      [ageIsOver]='searchRequest.ageIsOver'
      [fromPurchaseDate]='fromPurchaseDate'
      [toPurchaseDate]='toPurchaseDate'
      [postcode]='searchRequest.postcode'
      (onSuburbChange)='onSuburbChange($event)'
      (onCityChange)='onCityChange($event)'
      (onFarmChange)='onFarmChange($event)'
      (onStreetChange)='onStreetChange($event)'
      (onSelectCategory)='onSelectCategory($event)'
      (onRemoveCategory)='onRemoveCategory($event)'
      (onSelectUser)='onSelectUser($event)'
      (onRemoveUser)='onRemoveUser($event)'
      (onFromPurchaseDateChange)='onFromPurchaseDateChange($event)'
      (onToPurchaseDateChange)='onToPurchaseDateChange($event)'
      (onMotivateTypeChange)='onMotivateTypeChange($event)'
      (onReligionChange)='onReligionChange($event)'
      (onNationalityChange)='onNationalityChange($event)'
      (onAgeChange)='onAgeChange($event)'
      (onSearch)='onSearchButtonClick()'
      (onSearchByPostcode)='onSearchByPostcode($event)'
      *appPermissionCheck='ComponentPermission.CLIENT_BOARD_SEARCH'
    ></ngx-client-board-filter-advance>
  </nb-card-header>
  <nb-card-body>
    <table *ngIf='clientBoard != null'>
      <tr>
        <td colspan='2' class='topBorder'></td>
        <td colspan='2' class='tdValue'>NO BUSINESS</td>
        <td colspan='3' class='tdValue'>PROSPECTING</td>
        <td [attr.colspan]='saleColspan' class='tdValue' *ngIf='!isLowLevelUser'>SALES</td>
      </tr>
      <tr>
        <td rowspan='2' class='tdValue'>inactive</td>
        <td nbTooltip='Sell' class='tdLabel'>S</td>
        <td class='tdValue load-prospect'
            *ngFor="let score of clientBoard.statusScores['SELL']; let i = index"
            nbTooltip="{{
            (i === 0) ? 'S' + i :(
              (i > 9 ) ? 'S' + (i - 6) :  (
                 (i === 1) ? 'S' + i : (
                   i === 2 ? 'SE2' : (
                     (i === 3) ? 'S2H' :(
                      (i === 4 ) ? 'S2M' :(
                        (i === 5 ) ? 'S3' :(
                          (i === 6) ? 'T10-S3':(
                            (i === 7) ? '1 Month':(
                              (i === 8) ? '3 Month' : '6 Month'
                              )
                            )
                          )
                        )
                      )
                   )
                 )
               )
            )
            }}"
            (click)="loadProspect(i, 'sell', 'statusScore')"
            [hidden]='i >= 6 && i <= 9 && additionalColumns === false'
        >{{score}} <span *ngIf='isPercentageView && i >= 2 && i <= 10'>%</span>
        </td>
      </tr>
      <tr>
        <td nbTooltip='Rent' class='tdLabel'>R</td>
        <td class='tdValue load-prospect'
            *ngFor="let score of clientBoard.statusScores['RENT']; let i = index"
            nbTooltip="{{
            (i === 0) ? 'SR' + i :(
              (i > 9 ) ? 'SR' + (i - 6) :  (
                 (i === 1) ? 'SR' + i : (
                   i === 2 ? 'SRE2' : (
                     (i === 3) ? 'SR2H' :(
                      (i === 4 ) ? 'SR2M' :(
                       (i=== 5) ? 'SR3' :(
                          (i === 6) ? 'T10-S3':(
                            (i === 7) ? '1 Month':(
                              (i === 8) ? '3 Month' : '6 Month'
                            )
                          )
                        )
                      )
                     )
                   )
                 )
               )
            )
            }}"
            (click)="loadProspect(i, 'rent', 'statusScore')"
            [hidden]='i >= 6 && i <= 9 && additionalColumns === false'
        >{{score}} <span *ngIf='isPercentageView && i >= 2 && i <= 10'>%</span></td>
      </tr>
      <tr>
        <td rowspan='2'>active</td>
        <td nbTooltip='On The Market' class='tdLabel'>O</td>
        <td class='load-prospect' *ngFor="let score of clientBoard.statusScores['OTM']; let i = index"
            nbTooltip="{{
            (i === 0) ? 'SO' + i :(
              (i > 9 ) ? 'SO' + (i - 6) :  (
                 (i === 1) ? 'SO' + i : (
                   i === 2 ? 'SOE2' : (
                     (i === 3) ? 'SO2H' :(
                      (i === 4 ) ? 'SO2M' :(
                       (i === 5) ? 'SO3' :(
                          (i === 6) ? 'T10-S3':(
                            (i === 7) ? '1 Month':(
                              (i === 8) ? '3 Month' : '6 Month'
                            )
                          )
                        )
                       )
                     )
                   )
                 )
               )
            )
            }}"
            (click)="loadProspect(i, 'otm', 'statusScore')"
            [hidden]='i >= 6 && i <= 9 && additionalColumns === false'
        >{{score}} <span *ngIf='isPercentageView && i >= 2 && i <= 10'>%</span></td>
      </tr>
      <tr>
        <td nbTooltip='VAC' class='tdLabel'>V</td>
        <td class='load-prospect' *ngFor="let score of clientBoard.statusScores['VAC']; let i = index"
            nbTooltip="{{
            (i === 0) ? 'SRV' + i :(
              (i > 9 ) ? 'SRV' + (i - 6) :  (
                 (i === 1) ? 'SRV' + i : (
                   i === 2 ? 'SRV2' : (
                     (i === 3) ? 'SRV2H' :(
                      (i === 4 ) ? 'SRV2M' :(
                       (i === 5 ) ? 'SRV3' :(
                          (i === 6) ? 'T10-S3':(
                            (i === 7) ? '1 Month':(
                              (i === 8) ? '3 Month' : '6 Month'
                            )
                          )
                        )
                       )
                     )
                   )
                 )
               )
            )
            }}"
            (click)="loadProspect(i, 'vac', 'statusScore')"
            [hidden]='i >= 6 && i <= 9 && additionalColumns === false'
        >{{score}} <span *ngIf='isPercentageView && i >= 2 && i <= 10'>%</span></td>
      </tr>
      <tr>
        <td rowspan='3' colspan='2'></td>
        <td class='tdLabel2' [ngClass]='{"additional-label" : i >= 6 && i <= 9 && additionalColumns === true}'
            *ngFor='let levelLabel of levelLabels; let i = index'>{{levelLabel}}
          <span *ngIf='i === 5 && categoryIds.length === 0'><button nbButton size='tiny' status='primary'
                                                                    (click)='toggleAdditionalLevel3Columns()'>
            <nb-icon [icon]='icon'></nb-icon>
          </button></span>
        </td>
      </tr>
      <tr>
        <td class='tdLabel2' [attr.colspan]='rateColspan'>
          rate
        </td>
      </tr>
      <tr>
        <td class='tdLabel2' [ngClass]='{"additional-label" : i >= 6 && i <= 9 && additionalColumns === true}'
            *ngFor='let levelLabel of levelLabels; let i = index'>{{levelLabel}}
          <span *ngIf='i === 5 && categoryIds.length === 0'><button nbButton size='tiny' status='primary'
                                                                    (click)='toggleAdditionalLevel3Columns()'>
            <nb-icon [icon]='icon'></nb-icon>
          </button></span>
        </td>
      </tr>
      <tr>
        <td rowspan='5'>downside</td>
        <td class='tdLabel2'>total</td>
        <td class='load-prospect' *ngFor='let score of totalTypeScores; let i = index'
            (click)="loadProspect(i, 'ALL', 'total')"
            [hidden]='i >= 6 && i <= 9 && additionalColumns === false'
        >{{score}} <span *ngIf='isPercentageView && i >= 2 && i <= 10'>%</span></td>
      </tr>
      <tr>
        <td nbTooltip='Unit' class='tdLabel'>unit</td>
        <td class='load-prospect' *ngFor="let score of clientBoard.typeScores['UNIT']; let i = index"
            (click)="loadProspect(i, 'UNIT', 'typeScore')"
            [hidden]='i >= 6 && i <= 9 && additionalColumns === false'
        >{{score}} <span *ngIf='isPercentageView && i >= 2 && i <= 10'>%</span></td>
      </tr>
      <tr>
        <td nbTooltip='Villa' class='tdLabel'>villa</td>
        <td class='load-prospect' *ngFor="let score of clientBoard.typeScores['VILLA']; let i = index"
            (click)="loadProspect(i, 'VILLA', 'typeScore')"
            [hidden]='i >= 6 && i <= 9 && additionalColumns === false'
        >{{score}} <span *ngIf='isPercentageView && i >= 2 && i <= 10'>%</span></td>
      </tr>
      <tr>
        <td nbTooltip='Town House' class='tdLabel'>T.house</td>
        <td class='load-prospect' *ngFor="let score of clientBoard.typeScores['TOWN_HOUSE']; let i = index"
            (click)="loadProspect(i, 'TOWN_HOUSE', 'typeScore')"
            [hidden]='i >= 6 && i <= 9 && additionalColumns === false'
        >{{score}} <span *ngIf='isPercentageView && i >= 2 && i <= 10'>%</span></td>
      </tr>
      <tr>
        <td nbTooltip='Pent House' class='tdLabel'>P.house</td>
        <td class='load-prospect' *ngFor="let score of clientBoard.typeScores['PENTHOUSE']; let i = index"
            (click)="loadProspect(i, 'PENTHOUSE', 'typeScore')"
            [hidden]='i >= 6 && i <= 9 && additionalColumns === false'
        >{{score}} <span *ngIf='isPercentageView && i >= 2 && i <= 10'>%</span></td>
      </tr>
      <tr>
        <td class='tdValue' rowspan='3'>upside</td>
        <td nbTooltip='Land Only' class='tdLabel'>Land</td>
        <td class='tdValue load-prospect' *ngFor="let score of clientBoard.typeScores['LAND_ONLY']; let i = index"
            (click)="loadProspect(i, 'LAND_ONLY', 'typeScore')"
            [hidden]='i >= 6 && i <= 9 && additionalColumns === false'
        >{{score}} <span *ngIf='isPercentageView && i >= 2 && i <= 10'>%</span></td>
      </tr>
      <tr>
        <td nbTooltip='House' class='tdLabel'>house</td>
        <td class='tdValue load-prospect' *ngFor="let score of clientBoard.typeScores['HOUSE']; let i = index"
            (click)="loadProspect(i, 'HOUSE', 'typeScore')"
            [hidden]='i >= 6 && i <= 9 && additionalColumns === false'
        >{{score}} <span *ngIf='isPercentageView && i >= 2 && i <= 10'>%</span></td>
      </tr>
      <tr>
        <td nbTooltip='House GF' class='tdLabel'>house + GF</td>
        <td class='tdValue load-prospect' *ngFor="let score of clientBoard.typeScores['HOUSE_GF']; let i = index"
            (click)="loadProspect(i, 'HOUSE_GF', 'typeScore')"
            [hidden]='i >= 6 && i <= 9 && additionalColumns === false'
        >{{score}} <span *ngIf='isPercentageView && i >= 2 && i <= 10'>%</span></td>
      </tr>
    </table>
    <button size='tiny' class="mt-2" nbButton status='primary' (click)='switchToPercentage()' *ngIf='!isPercentageView && clientBoard'>
      Switch to
      percentage
    </button>
    <button size='tiny' class="mt-2" nbButton status='primary' (click)='switchToNumber()' *ngIf='isPercentageView && clientBoard'>Switch
      to number
    </button>
  </nb-card-body>
</nb-card>


<div class='fake-tab content-active' [hidden]='!isDisplayed'>
  <ngx-prospect-list-basic
    [selectedMode]="'OUTDOOR'"
    [isFilter]=false
    [isLoad]=false
    [districts]='districts'
    [cities]='cities'
    [streets]='streets'
    [companies]='companies'
    [taskTypes]='taskTypes'
    [potentialCommission]='potentialCommission'
    [potentialRentalValue]='potentialRentalValue'
    [rentalTooltip]='rentalTooltip'
    (reloadStreet)='loadStreets()'
    (onSelectProspect)='onListSelect("prospect", $event)'
    (onSelectStreet)='onListSelect("street", $event)'
    (emitDisplay)="onEmitDisplay($event)"
  ></ngx-prospect-list-basic>
</div>


