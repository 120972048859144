import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { WrapProspect } from './prospect-utils';
import { StringUtils } from '../../../../shared/common/string-utils';
import { Prospect } from '../../../../shared/models/response/prospect/prospect';
import { ProspectDialogComponent } from '../../../../shared/module/prospect-dialog/prospect-dialog.component';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { Router } from '@angular/router';

@Component({
  template: `
    {{renderValue}}
  `,
  styles: [],
})
export class ProspectListAddressCellComponent implements ViewCell, OnInit {

  renderValue: string;
  selectedProspect: Prospect;
  param: {};

  constructor() {
  }

  @Input() value: string | number;
  @Input() rowData: WrapProspect;
  StringUtils = StringUtils;

  ngOnInit() {
    this.setValue();
  }

  setValue(): any {
    if (!this.rowData) {
      this.renderValue = this.value ? this.value.toString() : '';
      return;
    }
    switch (this.rowData.type) {
      case 'GROUP':
        const prospectGroup = this.rowData.groupData;
        this.renderValue = `(${prospectGroup?.totalItems}) ${prospectGroup?.name}`;
        break;
      case 'ITEM':
        const prospectItem = this.rowData.data;
        // this.renderValue = `${prospectItem?.address?.unit ? prospectItem?.address?.unit + '/' : ''}`
        //  + ` ${prospectItem?.address?.number}`
        //  + `, ${prospectItem?.address?.street?.name}`;
        this.renderValue = `${StringUtils.getShortAddress(prospectItem?.address)}`;
        break;
    }
  }
}
