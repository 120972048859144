import { Component, OnInit, ViewChild } from '@angular/core';
import { ComponentPermission } from '../../../../component.permission';
import { Observable, of } from 'rxjs';
import { User } from '../../../../shared/models/response/user';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { StringUtils } from '../../../../shared/common/string-utils';
import { DateUtils } from '../../../../shared/common/date-utils';
import { RestResult } from '../../../../shared/models/response/rest-result';
import { BaseChartDirective } from 'ng2-charts';
import { Company } from '../../../../shared/models/response/prospect/company';
import { TaskType } from '../../../../shared/models/response/task-type';
import { ProspectListBasicComponent } from '../../prospect/prospect-list-basic/prospect-list-basic.component';
import { BuyerListComponent } from '../../buyer/buyer-list/buyer-list.component';
import { Category } from '../../../../shared/models/response/category';
import { ProspectSearchRequest } from '../../../../shared/models/request/prospect/prospect-search-request';
import { BuyerSearchRequest } from '../../../../shared/models/request/buyer/buyer-search-request';
import { UserUtils } from '../../../../shared/common/user-utils';
import { DashboardService } from '../../../../shared/services/dashboard.service';
import { UserService } from '../../../../shared/services/user.service';
import { AuthService } from '../../../../shared/services/auth.service';
import { CategoryService } from '../../../../shared/services/category.service';
import { TaskTypeService } from '../../../../shared/services/task-type.service';
import { CompanyService } from '../../../../shared/services/company.service';
import { CommonService } from '../../../../shared/services/common.service';
import { NbDialogService } from '@nebular/theme';
import { SuburbService } from '../../../../shared/services/suburb.service';
import { TotalMessageListComponent } from '../total-message-list/total-message-list.component';
import { map, mergeMap } from 'rxjs/operators';
import { SearchRequest } from '../../../../shared/models/request/search-request';
import { Triple } from '../../../../shared/models/request/triple';
import { Constant } from '../../../../shared/common/constant';
import { RentalStatisticWrapper } from '../../../../shared/models/response/prospect/rental-statistic-wrapper';
import { RentalStatisticRequest } from '../../../../shared/models/request/prospect/rental-statistic-request';
import { RentalStatistic } from '../../../../shared/models/response/rental-statistic';

@Component({
  selector: 'ngx-rental-board',
  templateUrl: './rental-board.component.html',
  styleUrls: ['./rental-board.component.scss'],
})
export class RentalBoardComponent implements OnInit {
  @ViewChild(BaseChartDirective)
  public chart: BaseChartDirective;

  ComponentPermission = ComponentPermission;
  headers = ['RM', 'RN', 'RC', 'RA', 'RP', 'R4', 'V4', 'TA', 'TC', 'TN', 'TM'];
  firstColLabel = 'Week';
  firstCols: any[] = ['TOTAL', 'WED', 'THU', 'FRI', 'SAT', 'SUN', 'MON', 'TUE'];
  types = ['Week', 'Quarter'];
  selectedType = 'Week';
  nums = [];
  selectedNum = 1;
  selectedYear: number;
  years: number[];
  rentalStatistics: any;
  currentUser: User;
  startFrom: number;
  endTo: number;
  companies: Company[];
  taskTypes: TaskType[];
  displayProspect = false;
  displayBuyer = false;
  selectedX = -1;
  selectedY = -1;
  @ViewChild(ProspectListBasicComponent) prospectListBasicComponent: ProspectListBasicComponent;
  @ViewChild(BuyerListComponent) buyerListComponent: BuyerListComponent;
  timeCache: {
    from: number;
    to: number;
  }[] = [];
  stringUtils = StringUtils;

  filterCategory$: Observable<Category[]>;
  categoryName: string;
  activityCategories: Category[] = [];
  selectedCategoryIds: number[];

  filterUser$: Observable<User[]>;
  userName: string;
  selectedUsers: User[] = [];
  selectedUserId: number[];
  userUtils = UserUtils;
  prospectSearchRequest = new ProspectSearchRequest();
  buyerSearchRequest = new BuyerSearchRequest();
  prospectTagButtons = [
    {
      key: 'T10-S3',
      value: 1,
      selected: true,
    },
    {
      key: '1M',
      value: 5,
      selected: false,
    },
    {
      key: '3M',
      value: 6,
      selected: false,

    },
    {
      key: '6M',
      value: 7,
      selected: false,
    },
  ];
  buyerTagButtons = [
    {
      key: 'T10-BA',
      value: 2,
      selected: true,
    },
    {
      key: 'T4',
      value: 26,
      selected: false,
    },
  ];
  UserUtils = UserUtils;
  isTeamView = false;
  statisticTeam: RentalStatisticWrapper[];

  constructor(private dashboardService: DashboardService,
              private userService: UserService,
              private authService: AuthService,
              private categoryService: CategoryService,
              private taskTypeService: TaskTypeService,
              private companyService: CompanyService,
              private commonService: CommonService,
              private dialogService: NbDialogService,
              private districtService: SuburbService) {
    this.currentUser = this.authService.currentUser;
    this.selectedType = 'Week';
    this.nums = [];
    for (let i = 1; i <= 52; i++) {
      this.nums.push(i);
    }
    this.years = [];
    for (let i = 2000; i <= 3000; i++) {
      this.years.push(i);
    }
    this.selectedYear = new Date().getFullYear();
    this.selectedNum = DateUtils.getCurrentWeekFromTimestamp(new Date().getTime());
  }

  ngOnInit(): void {
    this.currentUser = this.authService.currentUser;
    if (!this.userUtils.userIsAdmin(this.currentUser)) {
      this.selectedUsers.push(this.currentUser);
    } else {
      this.selectedUsers = [];
    }
    this.mapUserId();
    // init filter Category
    this.filterCategory$ = new Observable((observer: any) => {
      observer.next(this.categoryName);
    }).pipe(
      mergeMap((query: string) => this.searchCategory(query)),
    );
    // init filter User
    this.filterUser$ = new Observable((observer: any) => {
      observer.next(this.userName);
    }).pipe(
      mergeMap((query: string) => this.searchUser(query)),
    );
    this.loadForFilter();
    this.getRentalStatistic();
    this.prepareSearchRequest();
    this.loadAssignedSuburb();
  }

  selectUser(type: TypeaheadMatch) {
    this.userName = '';
    this.selectedUsers.push(type.item);
    this.selectedUsers = this.selectedUsers.slice();
    this.mapUserId();
    if (this.isTeamView === false) {
      this.getRentalStatistic();
    }
    if (this.isTeamView === true) {
      this.firstCols.push(StringUtils.shortenUserName(type.item));
      this.getTeamStatistic();
    }
    this.prospectSearchRequest.multipleUser = this.selectedUserId;
    this.buyerSearchRequest.multipleUser = this.selectedUserId;
    this.prospectListBasicComponent.filterCondition(this.prospectSearchRequest);
    this.buyerListComponent.filterCondition(this.buyerSearchRequest);
  }

  removeUser(userId: number) {
    const index = this.selectedUserId.indexOf(userId);
    this.selectedUsers = this.selectedUsers.filter(u => (u.userId !== userId));
    this.mapUserId();
    if (this.isTeamView === false) {
      this.getRentalStatistic();
    }
    if (this.isTeamView === true) {
      this.statisticTeam.splice(index, 1);
      this.firstCols.splice(index, 1);
    }
    this.prospectSearchRequest.multipleUser = this.selectedUserId;
    this.buyerSearchRequest.multipleUser = this.selectedUserId;
    this.prospectListBasicComponent.filterCondition(this.prospectSearchRequest);
    this.buyerListComponent.filterCondition(this.buyerSearchRequest);
  }

  mapUserId() {
    this.selectedUserId = this.selectedUsers.map((user) => user.userId);
  }

  getRentalStatistic() {
    if (!this.selectedType
      || !this.selectedNum
      || !this.selectedYear
    ) {
      return;
    }
    this.rentalStatistics = {};
    this.timeCache = [];
    this.mapCategoryId();
    this.rentalStatistics['TOTAL'] = new RentalStatistic();
    switch (this.selectedType) {
      case 'Week':
        const dayMoments = DateUtils.getAllDaysOfWeek(this.selectedNum, this.selectedYear);
        this.startFrom = dayMoments[0]?.startOf('day').valueOf();
        this.endTo = dayMoments[dayMoments.length - 1]?.endOf('day').valueOf();
        for (let i = 0; i < dayMoments.length; i++) {
          const dayMoment = dayMoments[i];
          const from = dayMoment.startOf('day').valueOf();
          const to = dayMoment.endOf('day').valueOf();
          this.timeCache.push({ from, to });
          this.dashboardService.fetchRentalStatistic(
            this.currentUser.office?.officeId,
            from,
            to,
            this.selectedUserId,
            this.selectedCategoryIds,
          ).subscribe((result: RestResult) => {
            if (result && result.data) {
              this.rentalStatistics[this.firstCols[dayMoments.indexOf(dayMoment) + 1]] = result.data;
              for (const header of this.headers) {
                this.rentalStatistics['TOTAL']['total' + header] += result.data['total' + header];
              }
            }
          });
        }
        break;
      case 'Quarter':
        for (let i = 1; i <= 13; i++) {
          const week = i + (this.selectedNum - 1) * 13;
          const startDayOfWeek = DateUtils.getFirstDayOfWeek(week, this.selectedYear);
          const endDayOfWeek = DateUtils.getLastDayOfWeek(week, this.selectedYear);
          if (i === 1) {
            this.startFrom = startDayOfWeek.valueOf();
          } else if (i === 13) {
            this.endTo = endDayOfWeek.valueOf();
          }
          const from = startDayOfWeek.startOf('day').valueOf();
          const to = endDayOfWeek.endOf('day').valueOf();
          this.timeCache.push({ from, to });
          this.dashboardService.fetchRentalStatistic(
            this.currentUser.office?.officeId,
            from,
            to,
            this.selectedUserId,
            this.selectedCategoryIds,
          ).subscribe((result: RestResult) => {
            if (result && result.data) {
              this.rentalStatistics[this.firstCols[i]] = result.data;
              for (const header of this.headers) {
                this.rentalStatistics['TOTAL']['total' + header] += result.data['total' + header];
              }
            }
          });
        }
        break;
    }
  }

  getTeamStatistic() {
    this.mapCategoryId();
    this.dashboardService.fetchRentalStatisticTeam(this.currentUser.office?.officeId,
      this.startFrom,
      this.endTo,
      this.selectedUserId,
      this.selectedCategoryIds).subscribe(result => {
      if (result.data && result) {
        this.statisticTeam = result.data;
        const totalStatisticTeam = new RentalStatisticWrapper();
        totalStatisticTeam.userId = 0;
        totalStatisticTeam.statistic = new RentalStatistic();
        for (const header of this.headers) {
          for (let i = 0; i < this.statisticTeam.length; i++) {
            totalStatisticTeam.statistic['total' + header] += this.statisticTeam[i].statistic['total' + header];
          }
        }
        this.statisticTeam.unshift(totalStatisticTeam);
      }
    });
  }

  mapCategoryId() {
    this.selectedCategoryIds = this.activityCategories.map((category) => category.categoryId);
  }

  selectMetric(x, y) {
    if (ComponentPermission.isAccess(ComponentPermission.DASHBOARD_OVERVIEW_CLICK)) {
      this.selectedX = x;
      this.selectedY = y;
      if (!this.headers[x]) {
        return;
      }
      let time: {
        from: number;
        to: number;
      };
      if (y === 0) {
        time = { from: this.startFrom, to: this.endTo };
      } else {
        time = this.timeCache[y - 1];
      }
      if (this.headers[x] === 'TM') {
        return;
      }
      if (this.headers[x] === 'RM') {
        let noData: boolean = false;
        const sr = new ProspectSearchRequest();
        sr.dateFrom = time.from;
        sr.dateTo = time.to;
        sr.isRental = true;
        if (this.isTeamView === false) {
          if (this.rentalStatistics[this.firstCols[y]].totalRM === 0) {
            noData = true;
          }
        } else {
          if (this.statisticTeam[y].statistic.totalRM === 0) {
            noData = true;
          } else {
            sr.dateFrom = this.startFrom;
            sr.dateTo = this.endTo;
          }
        }
        this.dialogService.open(TotalMessageListComponent, {
          context: {
            prospectSearchRequest: sr,
            noData: noData,
          },
        });
        return;
      }
      const statisticRequest: RentalStatisticRequest = new RentalStatisticRequest();
      statisticRequest.multipleUser = this.selectedUserId;
      statisticRequest.fromDate = time.from;
      statisticRequest.toDate = time.to;
      statisticRequest.statisticType = this.headers[x];
      if (this.isTeamView === true) {
        const selectedUser = this.selectedUsers[y];
        statisticRequest.multipleUser = statisticRequest.multipleUser.filter(id => (id === selectedUser.userId));
        statisticRequest.fromDate = this.startFrom;
        statisticRequest.toDate = this.endTo;
      }
      if (this.headers[x]?.startsWith('T')) {
        // this.displayProspect = false;
        // this.displayBuyer = true;
        const buyerSearch: BuyerSearchRequest = new BuyerSearchRequest();
        buyerSearch.statisticRequest = statisticRequest;
        buyerSearch.categoryIds = this.activityCategories.map((category) => category.categoryId);
        // this.buyerListComponent.filterCondition(buyerSearch);
        this.dialogService.open(BuyerListComponent, {
          context: {
            searchRequest: buyerSearch,
            isDialog: true,
          },
        });
      } else {
        this.dialogService.open(ProspectListBasicComponent, {
          context: {
            isDialog: true,
            statisticRequest: statisticRequest,
            categoryIds: this.activityCategories.map((category) => category.categoryId),
            _selectedMode: 'OUTDOOR',
          },
        });
      }
    } else {
      this.commonService.warning('You do not have permission to perform this action');
    }
  }

  searchCategory(name: string): Observable<Category[]> {
    if (!name || name === '') {
      return of([]);
    }
    const searchRequest = new SearchRequest();
    searchRequest.conditions = new Array();

    searchRequest.conditions.push(new Triple('name', 'like', name));
    // searchRequest.conditions.push(new Triple('type', '=', 'BUYER'));
    return this.categoryService.search(searchRequest).pipe(
      map(result => result.data),
    );
  }

  selectCategory(type: TypeaheadMatch) {
    this.categoryName = '';
    const selectedCategory: Category = type.item;
    if (this.activityCategories.filter(category => category.categoryId === selectedCategory.categoryId).length > 0) {
      return;
    }
    this.activityCategories.push(selectedCategory);
    this.getRentalStatistic();
  }

  removeCategory(removedCategory: Category) {
    if (removedCategory) {
      this.activityCategories = this.activityCategories.filter
      (category => category.categoryId !== removedCategory.categoryId);
      this.getRentalStatistic();
    }
  }

  searchUser(keyword: string): Observable<User[]> {
    // this.refreshUserFilter();
    const searchRequest = new SearchRequest();
    searchRequest.conditions = new Array();
    searchRequest.conditions.push(
      {
        left: 'email',
        middle: 'like',
        right: keyword,
      },
    );
    searchRequest.conditions.push(
      {
        left: 'username',
        middle: 'like',
        right: keyword,
      },
    );
    searchRequest.conditions.push(
      {
        left: 'name',
        middle: 'like',
        right: keyword,
      },
    );
    searchRequest.conditionType = Constant.CONDITION_TYPE.OR;
    searchRequest.offset = 0;
    searchRequest.limit = 10;
    return this.userService.search(searchRequest).pipe(
      map(result => result.data.filter(user => user.status === 'ACTIVE')),
    );
  }

  loadForFilter() {
    const searchRequest = new SearchRequest();
    searchRequest.offset = 0;
    searchRequest.limit = 10000;
    searchRequest.orders = new Array();
    searchRequest.orders.push(
      {
        left: 'name',
        right: 'asc',

      },
    );
    this.taskTypeService.search(searchRequest).subscribe((result: RestResult) => {
      this.taskTypes = result.data;
    });
    this.companyService.search(searchRequest).subscribe((result: RestResult) => {
      this.companies = result?.data;
    });
  }

  prepareSearchRequest() {
    // prospect
    this.prospectSearchRequest.categoryIds = [];
    this.prospectSearchRequest.categoryIds.push(1);
    this.prospectSearchRequest.sortType = 'ASC';
    this.prospectSearchRequest.offset = 0;
    this.prospectSearchRequest.limit = 20;
    this.prospectSearchRequest.multipleUser = this.selectedUserId;
    // buyer
    this.buyerSearchRequest.categoryIds = [];
    this.buyerSearchRequest.categoryIds.push(2);
    this.buyerSearchRequest.offset = 0;
    this.buyerSearchRequest.limit = 20;
    this.buyerSearchRequest.multipleUser = this.selectedUserId;
  }

  loadAssignedSuburb() {
    if (!localStorage.getItem('assignedSuburb')) {
      this.districtService.fetchDistrictBySelectedOffice(this.currentUser.office.officeId).subscribe(c => {
        localStorage.setItem('assignedSuburb', JSON.stringify(c.data));
      });
    }
  }

  onTypeChange(value: string) {
    this.selectedType = value;
    this.selectedNum = 1;
    this.generateFirstCols();
    this.getRentalStatistic();
  }

  onNumChange(value: number) {
    this.selectedNum = value;
    this.generateFirstCols();
    this.getRentalStatistic();
  }

  onYearChange(value: number) {
    this.selectedYear = value;
    this.getRentalStatistic();
  }

  switchToTeamView() {
    this.isTeamView = true;
    this.firstColLabel = 'User';
    this.firstCols = ['TOTAL'];
    if (this.selectedUsers.length > 0) {
      for (const user of this.selectedUsers) {
        this.firstCols.push(StringUtils.shortenUserName(user));
      }
      this.getTeamStatistic();
    }
  }

  switchToNormal() {
    this.isTeamView = false;
    this.firstColLabel = 'Week';
    this.firstCols = ['TOTAL', 'WED', 'THU', 'FRI', 'SAT', 'SUN', 'MON', 'TUE'];
  }

  generateFirstCols() {
    this.firstCols = [];
    this.nums = [];
    switch (this.selectedType) {
      case 'Week':
        this.firstColLabel = 'day';
        this.firstCols = ['TOTAL', 'WED', 'THU', 'FRI', 'SAT', 'SUN', 'MON', 'TUE'];
        for (let i = 1; i <= 52; i++) {
          this.nums.push(i);
        }
        break;
      case 'Quarter':
        this.firstColLabel = 'Week';
        for (let i = 1; i <= 4; i++) {
          this.nums.push(i);
        }
        this.firstCols.push('TOTAL');
        for (let i = 1; i <= 13; i++) {
          this.firstCols.push(i + (this.selectedNum - 1) * 13);
        }
        break;
    }
  }
}
