<form [formGroup]="prospectForm">
  <nb-card>
    <nb-card-body>
      <div class="row mt-3">
        <span>
          {{StringUtils.getFullAddress(prospectGroup?.address)}}
<!--          {{prospectGroup?.address?.number}}-->
<!--          , {{prospectGroup?.address?.street?.name}}-->
<!--          , {{prospectGroup?.address?.street?.district?.name}}-->
<!--          , {{prospectGroup?.address?.street?.district?.city?.name}}-->
        </span>
      </div>
      <div class="row mt-3">
        <div class="col-12" [ngClass]="showError('unit')">
          <input type="text" nbInput fieldSize="tiny"
                 class="unit-input"
                 fullWidth name="unit" formControlName="unit"
                 placeholder="Unit range or individuals: 1:10 or a:f or 1,a,3,f">
        </div>
      </div>

      <div class="row mt-4 market-section">
        <div class="col-sm-12 property-type-container">
          <nb-select size="tiny" selected="" formControlName="type" (selectedChange)="onPropertyTypeChange()" [ngClass]="showErrorInput('type')">
            <nb-option value="" disabled>Type</nb-option>
            <nb-option *ngFor="let item of propertyType | keyvalue:keepOrder" value="{{item.key}}">{{item.value}}</nb-option>
          </nb-select>
          <input type="text" nbInput fieldSize="tiny" fullWidth name="bed" formControlName="bed" placeholder="Bed" [ngClass]="showErrorInput('bed')">
          <input type="text" nbInput fieldSize="tiny" fullWidth name="bath" formControlName="bath" placeholder="Bath" [ngClass]="showErrorInput('bath')">
          <input type="text" nbInput fieldSize="tiny" fullWidth name="car" formControlName="car" placeholder="Car" [ngClass]="showErrorInput('car')">
          <input type="text" nbInput fieldSize="tiny" fullWidth name="land" formControlName="land" placeholder="Land" [ngClass]="showErrorInput('land')">
        </div>
      </div>

    </nb-card-body>
    <nb-card-footer class="dialog-footer">
      <button nbButton (click)="close()">Cancel</button>
      <button nbButton status="success" (click)="submit()" [disabled]="isUpdating === true">Save</button>
    </nb-card-footer>
  </nb-card>
</form>
