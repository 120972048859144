import {Injectable} from '@angular/core';
import {SmartTableDataGroup} from '../data/smart-table-group';

@Injectable()
export class SmartTableServiceGroup extends SmartTableDataGroup {

  data = [{
    id: 1,
    name: 'Administrator',
    code: 'ADMINISTRATOR',
    description: 'Highest user',
  }, {
    id: 2,
    name: 'Administrator Manager',
    code: 'ADMINISTRATOR _ASSISTANT_MANAGER',
    description: 'Administrator assist manager',
  },
    {
      id: 3,
      name: 'Administrator Assistant Manager',
      code: 'ADMINISTRATOR _ASSISTANT_IT_MANAGER',
      description: 'Administrator assist IT',
    },
    {
      id: 4,
      name: 'Office Leader',
      code: 'LEADER',
      description: 'ownership of the office(s) - more powerful than general manager(s)',
    },
    {
      id: 5,
      name: 'Office Manager',
      code: 'GENERAL_MANAGER',
      description: 'highest power in 1 offices only',
    },
    {
      id: 6,
      code: 'ASSISTANT_MANAGER',
      name: 'Assistant of office manager',
      description: 'helping general manager only',
    },
    {
      id: 7,
      name: 'Sale Manager',
      code: 'SALE_MANAGER',
      description: 'highest power in sale team',
    },
    {
      id: 7,
      name: 'Rental Manager',
      code: 'RENTAL_MANAGER',
      description: 'highest power in rental team',
    },
    {
      id: 8,
      name: 'Team Leader',
      code: 'TEAM_LEADER',
      description: 'power over team members under his command',
    },
    {
      id: 9,
      name: 'Sale Team',
      code: 'SALE',
      description: 'sale team member',
    },
    {
      id: 8,
      name: 'Senior Rental',
      code: 'SENIOR_RENTAL',
      description: '(power over team members under her command)',
    },
    {
      id: 9,
      name: 'Rental team',
      code: 'RENTAL',
      description: 'Rental user',
    }];

  getData() {
    return this.data;
  }
}
