import {Component, OnInit} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Street} from '../../../../shared/models/response/address/street';
import {District} from '../../../../shared/models/response/address/district';
import {CommonService} from '../../../../shared/services/common.service';
import {FormService} from '../../../../shared/services/form.service';
import {ProspectRequest} from '../../../../shared/models/request/prospect/prospect-request';
import {PropertyRequest} from '../../../../shared/models/request/prospect/property-request';
import {AddressRequest} from '../../../../shared/models/request/address/address-request';
import {ProspectService} from '../../../../shared/services/prospect.service';
import {City} from '../../../../shared/models/response/address/city';
import {StreetService} from '../../../../shared/services/street.service';
import {StreetRequest} from '../../../../shared/models/request/address/street-request';
import {RestResult} from '../../../../shared/models/response/rest-result';
import {LocalService} from '../../../../shared/services/local.service';
import {Constant} from '../../../../shared/common/constant';
import {Prospect} from '../../../../shared/models/response/prospect/prospect';
import {StringUtils} from '../../../../shared/common/string-utils';
import {AuthService} from '../../../../shared/services/auth.service';
import {User} from '../../../../shared/models/response/user';
import {ComponentPermission} from '../../../../component.permission';
import {Observable, of} from 'rxjs';
import {Postcode} from '../../../../shared/models/response/postcode';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead';
import {ProspectSearchRequest} from '../../../../shared/models/request/prospect/prospect-search-request';
import {map, mergeMap} from 'rxjs/operators';
import {Category} from '../../../../shared/models/response/category';
import {SearchRequest} from '../../../../shared/models/request/search-request';
import {Triple} from '../../../../shared/models/request/triple';
import {CategoryService} from '../../../../shared/services/category.service';
import {UserService} from '../../../../shared/services/user.service';

@Component({
  selector: 'ngx-prospect-create-dialog',
  templateUrl: './prospect-create-dialog.component.html',
  styleUrls: ['./prospect-create-dialog.component.scss'],
})
export class ProspectCreateDialogComponent implements OnInit {
  prospectGroup: Prospect;
  prospectForm: FormGroup;
  addressFrom: AddressRequest[];
  filteredStreets: Street[];
  streets: Street[];
  filteredDistricts: District[];
  districts: District[];
  cities: City[];
  streetId = null;
  districtId = null;
  cityId = null;
  isUpdating = false;
  isNewStreet = false;
  isSubmitted = false;
  propertyDefaultSpec = Constant.PROPERTY_DEFAULT_SPEC;
  propertyType = Constant.PROPERTY_TYPE;
  StringUtils = StringUtils;
  currentUser: User;
  ComponentPermission = ComponentPermission;
  postcode: number;
  filterPostcode$: Observable<Postcode[]>;
  searchRequest = new ProspectSearchRequest();
  filterByPostcode = false;
  filterCategory$: Observable<Category[]>;
  categoryName: string;
  filterUser$: Observable<User[]>;
  userName: string;

  constructor(private dialogRef: NbDialogRef<ProspectCreateDialogComponent>,
              private formService: FormService,
              private prospectService: ProspectService,
              private commonService: CommonService,
              private streetService: StreetService,
              private localService: LocalService,
              private authService: AuthService,
              private categoryService: CategoryService,
              private userService: UserService) {
  }

  ngOnInit(): void {
    this.currentUser = this.authService.currentUser;
    this.addressFrom = [];
    if (this.prospectGroup) {
      this.postcode = this.prospectGroup.address?.postCode;
      this.districtId = this.prospectGroup.address?.districtId;
      this.streetId = this.prospectGroup.address?.streetId;
      this.addressFrom.push(this.prospectGroup.address);
    } else {
      this.addressFrom.push(this.newAddressForm());
    }
    this.prospectForm = new FormGroup({
      streetId: new FormControl(this.streetId),
      districtId: new FormControl(this.districtId),
      // cityId: new FormControl(this.cityId),
      streetName: new FormControl(''),
      bed: new FormControl(this.prospectGroup?.property?.bed),
      bath: new FormControl(this.prospectGroup?.property?.bath),
      car: new FormControl(this.prospectGroup?.property?.car),
      land: new FormControl(this.prospectGroup?.property?.land),
      type: new FormControl(this.prospectGroup?.property?.type),
      officeId: new FormControl(this.prospectGroup?.office?.officeId),
    });
    if (this.postcode) {
      this.filterByPostcode = true;
      // this.searchByPostcode(this.postcode);
    }
    // if (this.districtId) {
    //   this.localService.streets(this.districtId).subscribe(result => {
    //     this.filteredStreets = result;
    //   });
    // }
    this.initAutocomplete();
  }

  newAddressForm(): AddressRequest {
    return new AddressRequest();
  }

  close() {
    this.dialogRef.close();
  }

  onSuburbChange(districtId) {
    this.prospectForm.controls.streetId.setValue(null);
    this.localService.streets(districtId).subscribe(result => {
      this.filteredStreets = result;
    });
  }

  onPropertyTypeChange() {
    if (this.prospectForm.controls.type.value) {
      const defaultSpect = this.propertyDefaultSpec[this.prospectForm.controls.type.value];

      if (defaultSpect &&
        !this.prospectForm.controls.bed.value &&
        !this.prospectForm.controls.bath.value &&
        !this.prospectForm.controls.car.value &&
        !this.prospectForm.controls.land.value) {
        this.prospectForm.controls.bed.setValue(defaultSpect.bed);
        this.prospectForm.controls.bath.setValue(defaultSpect.bath);
        this.prospectForm.controls.car.setValue(defaultSpect.car);
        this.prospectForm.controls.land.setValue(defaultSpect.land);
      }
    }
  }

  onCityChange(cityId) {
    this.prospectForm.controls.districtId.setValue(null);
    this.prospectForm.controls.streetId.setValue(null);
    this.localService.districts(cityId).subscribe(result => {
      this.filteredDistricts = result;
    });
  }

  onAddNewCity() {
    this.isNewStreet = true;
  }

  onAddNewNumber() {
    this.addressFrom.push(this.newAddressForm());
  }

  onSelectNumber() {
    if (this.addressFrom.length === 2) {
      if (!this.addressFrom[0].numberFrom || !this.addressFrom[1].numberFrom) {
        this.commonService.warning('Number From must be inputted to process multiple');
        return;
      }
      const from = Number(this.addressFrom[0].numberFrom);
      const to = Number(this.addressFrom[1].numberFrom);
      for (let i = from + 1; i < to; i++) {
        const newRequest = new AddressRequest();
        newRequest.unit = this.addressFrom[0].unit;
        newRequest.numberFrom = i.toString();
        newRequest.numberTo = this.addressFrom[0].numberTo;
        newRequest.numberFromLetter = this.addressFrom[0].numberFromLetter;
        newRequest.numberToLetter = this.addressFrom[0].numberToLetter;
        newRequest.streetId = this.addressFrom[0].streetId;
        this.addressFrom.splice(i - 1, 0, newRequest);
      }
    } else {
      this.commonService.warning('Need 2 rows only to process multiple number');
    }
  }

  onSelectUnit() {
    if (this.addressFrom.length === 2) {
      if (!this.addressFrom[0].numberFrom) {
        this.commonService.warning('Number From must be inputted to process multiple');
        return;
      }
      if (!this.addressFrom[0].unit || !this.addressFrom[1].unit) {
        this.commonService.warning('Unit must be inputted to process multiple');
        return;
      }
      const from = Number(this.addressFrom[0].unit);
      const to = Number(this.addressFrom[1].unit);
      // if (isNaN(from) || isNaN(to)) {
      //   this.commonService.warning('Multiple only apply for unit using number');
      // }
      this.addressFrom[1].numberFrom = this.addressFrom[0].numberFrom;
      this.addressFrom[1].numberTo = this.addressFrom[0].numberTo;
      this.addressFrom[1].numberFromLetter = this.addressFrom[0].numberFromLetter;
      this.addressFrom[1].numberToLetter = this.addressFrom[0].numberToLetter;
      this.addressFrom[1].streetId = this.addressFrom[0].streetId;
      for (let i = from + 1; i < to; i++) {
        const newRequest = new AddressRequest();
        newRequest.unit = i.toString();
        newRequest.numberFrom = this.addressFrom[0].numberFrom;
        newRequest.numberTo = this.addressFrom[0].numberTo;
        newRequest.numberFromLetter = this.addressFrom[0].numberFromLetter;
        newRequest.numberToLetter = this.addressFrom[0].numberToLetter;
        newRequest.streetId = this.addressFrom[0].streetId;
        this.addressFrom.splice(i - 1, 0, newRequest);
      }
    } else {
      this.commonService.warning('Need 2 rows only to process multiple number');
    }
  }

  onRemoveNewNumber(i: number) {
    this.addressFrom.splice(i, 1);
  }

  onCancelAddNewCity() {
    this.isNewStreet = false;
    this.prospectForm.controls.streetName.setValue('');
  }

  submit() {
    this.isUpdating = true;
    if (this.addressFrom) {
      for (const address of this.addressFrom) {
        if (address?.unit?.length > 20) {
          this.commonService.warning('Unit length must be less than 20');
          this.isUpdating = false;
          return;
        }
      }
    }
    this.formService.extractErrorMessage(this.prospectForm);
    if (this.prospectForm.invalid) {
      this.isUpdating = false;
      this.isSubmitted = true;
      return;
    }
    if (this.isNewStreet === true) {
      const streetName = this.prospectForm.controls.streetName.value;
      const districtId = this.prospectForm.controls.districtId.value;
      const code = 'AUTO_GENERATE';
      if (!streetName || streetName === '') {
        this.commonService.warning('Please enter street name');
        this.isUpdating = false;
        return;
      }
      const streetRequest = {
        name: streetName,
        code: code,
        districtId: districtId,
        status: 'ACTIVE',
      };
      this.streetService.getByName(streetRequest as StreetRequest).subscribe(r => {
        if (r.data) {
          this.commonService.warning('Street name is already existed');
          this.isUpdating = false;
          return;
        } else {
          this.streetService.create(streetRequest as StreetRequest).subscribe((result: RestResult) => {
            if (result && result.data) {
              const street: Street = result.data;
              this.prospectForm.controls.streetId.setValue(street.streetId);
              this.createProspect();
            }
          }, error => {
            this.commonService.warningHtml(error.message);
            this.isUpdating = false;
          });
        }
      });
    } else {
      const streetId = this.prospectForm.controls.streetId.value;
      if (!streetId) {
        this.commonService.warning('Please choose a street or create a new one');
        this.isUpdating = false;
        return;
      }
      this.createProspect();
    }
  }

  createProspect() {
    const prospectRequest: ProspectRequest = {...this.prospectForm.value};
    prospectRequest.property = {...this.prospectForm.value};
    prospectRequest.address = {...this.prospectForm.value};
    this.trimObject(prospectRequest, Object.keys(ProspectRequest.getDummy()));
    this.trimObject(prospectRequest.property, Object.keys(PropertyRequest.getDummy()));
    this.trimObject(prospectRequest.address, Object.keys(AddressRequest.getDummy()));

    const multipleRequest = [];
    let isRequired = false;
    this.addressFrom.forEach(request => {
      if (!request.numberFrom) {
        isRequired = true;
      }
      request.streetId = prospectRequest.address?.streetId;
      multipleRequest.push(request);
    });

    if (isRequired) {
      this.commonService.warning('Number From must be inputted');
      this.isUpdating = false;
      return;
    }
    prospectRequest.multipleAddress = multipleRequest;
    prospectRequest.officeId = this.currentUser.office.officeId;
    prospectRequest.property.officeId = this.currentUser.office.officeId;
    prospectRequest.address.officeId = this.currentUser.office.officeId;
    this.prospectService.create(prospectRequest).subscribe(
      result => {
        this.commonService.info('Created prospect success.');
        if (this.isNewStreet === true) {
          result['reloadStreet'] = true;
        }
        this.dialogRef.close(result);
        this.isUpdating = false;
      },
      error => {
        this.commonService.warningHtml(error.message);
        this.isUpdating = false;
      },
    );
  }

  trimObject(obj: any, properties: string[]): any {
    const curentProperties: string[] = Object.keys(obj);
    for (const name of curentProperties) {
      if (properties.indexOf(name) === -1) {
        delete obj[name];
      }
    }
  }

  showError(name: string): string {
    return this.prospectForm.controls[name].errors
    && (this.prospectForm.controls[name].dirty
      || this.prospectForm.controls[name].touched
      || this.isSubmitted) ? 'has-error' : '';
  }

  keepOrder(a, b) {
    return 1;
  }

  showErrorInput(name: string): string {
    return this.prospectForm.controls[name].errors
    && (this.prospectForm.controls[name].dirty
      || this.prospectForm.controls[name].touched
      || this.isSubmitted) ? 'input-error' : '';
  }

  selectPostcode(type: TypeaheadMatch) {
    this.postcode = Number(type.item.code);
    this.searchByPostcode(this.postcode);
  }

  searchByPostcode(value: number) {
    this.filterByPostcode = true;
    this.searchRequest.postcode = Number(value);
    this.searchRequest.districtId = undefined;
    this.searchRequest.streetId = undefined;
    if (value && value !== 0) {
      this.localService.districtByPostcode(value).subscribe(result => {
        this.filteredDistricts = result;
      });
    }
  }

  initAutocomplete() {
    this.filterCategory$ = new Observable((observer: any) => {
      observer.next(this.categoryName);
    }).pipe(
      mergeMap((query: string) => this.searchCategory(query)),
    );
    this.filterUser$ = new Observable((observer: any) => {
      observer.next(this.userName);
    }).pipe(
      mergeMap((query: string) => this.searchUser(query)),
    );
    this.filterPostcode$ = new Observable((observer: any) => {
      observer.next(this.postcode);
    }).pipe(
      map((query: string) => {
        this.postcode = Number(query);
        this.searchByPostcode(this.postcode);
        return null;
      }),
    );
  }

  searchCategory(name: string): Observable<Category[]> {
    if (!name || name === '') {
      return of([]);
    }
    const searchRequest = new SearchRequest();
    searchRequest.conditions = [];
    searchRequest.conditionType = 'AND';
    searchRequest.subConditions = [];
    searchRequest.conditions.push(new Triple('name', 'like', name));
    const subCon = new SearchRequest();
    subCon.conditionType = 'OR';
    subCon.conditions = [];
    subCon.conditions.push(new Triple('type', '=', 'PROSPECT'));
    subCon.conditions.push(new Triple('type', '=', 'BOTH'));
    searchRequest.subConditions.push(subCon);
    return this.categoryService.search(searchRequest).pipe(
      map(result => result.data),
    );
  }

  searchUser(keyword: string): Observable<User[]> {
    // this.refreshUserFilter();
    const searchRequest = new SearchRequest();
    searchRequest.conditions = new Array();
    searchRequest.conditions.push(
      {
        left: 'email',
        middle: 'like',
        right: keyword,
      },
    );
    searchRequest.conditions.push(
      {
        left: 'username',
        middle: 'like',
        right: keyword,
      },
    );
    searchRequest.conditions.push(
      {
        left: 'name',
        middle: 'like',
        right: keyword,
      },
    );
    searchRequest.conditionType = Constant.CONDITION_TYPE.OR;
    searchRequest.offset = 0;
    searchRequest.limit = 10;
    return this.userService.search(searchRequest).pipe(
      map(result => result.data.filter(user => user.status === 'ACTIVE')),
    );
  }
}
