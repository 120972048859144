import { CustomerAdditionalInfo } from './customer-additional-info';

export class Customer {
  customerId?: number;
  name?: string;
  surname?: string;
  phone?: string;
  mobilePhone?: string;
  relationship?: number;
  motivate?: number;
  isDirector?: boolean;
  isInfluencer?: boolean;
  isSocialble?: boolean;
  isConcensious?: boolean;
  motivateFinancialUp?: boolean;
  motivateFinancialDown?: boolean;
  motivateLifestyleUp?: boolean;
  motivateLifestyleDown?: boolean;
  email?: string;
  facebook?: string;
  instagram?: string;
  dob?: number;
  nationality?: string;
  anniversaryDay?: number;
  religion?: string;
  profesion?: string;
  language?: string;
  note?: string;
  callbackDate?: number;
  link?: string;
  parentType?: string;
  abnNumber?: string;
  gstRegistered?: boolean;
  customerAdditionalInfo?: CustomerAdditionalInfo[];

  static PROSPECT = 'PROSPECT';
  static BUYER = 'BUYER';

  static copyFromRequest(customerRequest: any, customer: Customer) {
    customer.name = customerRequest.name;
    customer.surname = customerRequest.surname;
    customer.phone = customerRequest.phone;
    customer.mobilePhone = customerRequest.mobilePhone;
    customer.relationship = customerRequest.relationship;
    customer.motivate = customerRequest.motivate;
    customer.isDirector = customerRequest.isDirector;
    customer.isInfluencer = customerRequest.isInfluencer;
    customer.isSocialble = customerRequest.isSocialble;
    customer.isConcensious = customerRequest.isConcensious;
    customer.motivateFinancialUp = customerRequest.motivateFinancialUp;
    customer.motivateFinancialDown = customerRequest.motivateFinancialDown;
    customer.motivateLifestyleUp = customerRequest.motivateLifestyleUp;
    customer.motivateLifestyleDown = customerRequest.motivateLifestyleDown;
    customer.email = customerRequest.email;
    customer.facebook = customerRequest.facebook;
    customer.instagram = customerRequest.instagram;
    customer.dob = customerRequest.dob;
    customer.nationality = customerRequest.nationality;
    customer.anniversaryDay = customerRequest.anniversaryDay;
    customer.religion = customerRequest.religion;
    customer.profesion = customerRequest.profesion;
    customer.language = customerRequest.language;
    customer.note = customerRequest.note;
    // customer.callbackDate = customerRequest.callbackDate;
    customer.link = customerRequest.link;
  }
}
