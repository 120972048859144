<nb-card>
  <nb-card-body>
    <div class='row m-0 mt-3' *ngIf='!prospectGroup'>
      <div class='col-2'>
        <div class='form-group'>
          <label class='label float-left'>
            <button nbButton status='info'
                    size='tiny' [hidden]='isNewStreet === true' (click)='onAddNewCity()'>New Street
            </button>
            <button nbButton status='basic'
                    size='tiny' [hidden]='!isNewStreet' (click)='onCancelAddNewCity()'>Cancel
            </button>
          </label>
        </div>
      </div>
    </div>
    <form [formGroup]='prospectForm' *ngIf='prospectForm'>
      <div class='row m-0 mt-2' *ngIf='!prospectGroup'>
        <div class='col-4'>
          <div class='form-group'>
            <nb-select fullWidth size='tiny' formControlName='streetId'
                       placeholder='All Streets'
                       [disabled]='!filterByPostcode'
                       [hidden]='isNewStreet === true'
            >
              <nb-option [value]='undefined'>All Streets</nb-option>
              <nb-option *ngFor='let key of filteredStreets'
                         [value]='key.streetId'
              >{{key.name}}</nb-option>
            </nb-select>
            <input type='text' nbInput fieldSize='tiny'
                   fullWidth name='prefix' formControlName='streetName'
                   placeholder='Type new street name here'
                   [hidden]='!isNewStreet'
            >
          </div>
        </div>
        <div class='col-4'>
          <div class='form-group' [ngClass]="showError('districtId')">
            <nb-select
              fullWidth size='tiny' formControlName='districtId'
              placeholder='All Suburbs'
              [disabled]='!filterByPostcode'>
              <nb-option [value]='null'>All Suburbs</nb-option>
              <nb-option *ngFor='let key of filteredDistricts'
                         [value]='key.districtId'
                         (click)='onSuburbChange(key.districtId)'
              >{{key.name}}</nb-option>
            </nb-select>
          </div>
        </div>
        <div class='col-4'>
          <!--            <nb-select-->
          <!--              formControlName="cityId"-->
          <!--              placeholder="All Cities"-->
          <!--              fullWidth size="tiny">-->
          <!--              <nb-option [value]="null">All Cities</nb-option>-->
          <!--              <nb-option *ngFor="let key of cities"-->
          <!--                         [ngClass]="key.status === 'DEACTIVE' ? 'deactive-status' : ''"-->
          <!--                         [value]="key.cityId"-->
          <!--                         (click)="onCityChange(key.cityId)"-->
          <!--              >{{key.name}}</nb-option>-->
          <!--            </nb-select>-->
          <nb-form-field>
            <input nbInput fullWidth placeholder='Postcode' fieldSize='tiny' class='override-nbinput-height'
                   [(ngModel)]='postcode'
                   [typeahead]='filterPostcode$'
                   [ngModelOptions]='{standalone: true}'
                   [typeaheadAsync]='true'
                   (typeaheadOnSelect)='selectPostcode($event)'
                   typeaheadOptionField='code'
                   typeaheadMinLength='3'
                   typeaheadWaitMs='1000'>
            <!--      <span nbSuffix (click)='searchSuburbByPostcode()'><i class='fa fa-search'></i></span>-->
          </nb-form-field>
        </div>
      </div>

      <div *ngIf='prospectGroup'>
        {{StringUtils.getFullAddress(prospectGroup?.address)}}
      </div>

      <div class='row m-0 mt-2 market-section'>
        <div class='col-sm-12 pl-1 pr-1 property-type-container'>
          <nb-select size='tiny' class='mr-1' selected='' formControlName='type'
                     (selectedChange)='onPropertyTypeChange()' [ngClass]="showErrorInput('type')">
            <nb-option value='' disabled>Type</nb-option>
            <nb-option *ngFor='let item of propertyType | keyvalue:keepOrder'
                       value='{{item.key}}'>{{item.value}}</nb-option>
          </nb-select>
          <input class='mr-1' type='text' nbInput fieldSize='tiny' fullWidth name='bed' formControlName='bed'
                 placeholder='Bed'
                 [ngClass]="showErrorInput('bed')">
          <input class='mr-1' type='text' nbInput fieldSize='tiny' fullWidth name='bath' formControlName='bath'
                 placeholder='Bath'
                 [ngClass]="showErrorInput('bath')">
          <input class='mr-1' type='text' nbInput fieldSize='tiny' fullWidth name='car' formControlName='car'
                 placeholder='Car'
                 [ngClass]="showErrorInput('car')">
          <input class='mr-1' type='text' nbInput fieldSize='tiny' fullWidth name='land' formControlName='land'
                 placeholder='Land'
                 [ngClass]="showErrorInput('land')">
        </div>
      </div>
    </form>

    <div *ngFor='let address of addressFrom; let i = index'>
      <div class='row m-0 mt-2'>
        <div class='col-2'>
          <input type='text' nbInput fieldSize='tiny' fullWidth name='unit' placeholder='Unit'
                 [(ngModel)]='address.unit'>
        </div>
        <div class='col-2'>
          <input type='number' nbInput fieldSize='tiny' fullWidth name='numberFrom' placeholder='Number From'
                 [(ngModel)]='address.numberFrom'>
        </div>
        <div class='col-6'>
          <input type='text' nbInput fieldSize='tiny' fullWidth name='additionalInfo' placeholder='Additional Info'
                 [(ngModel)]='address.additionalInfo'>
        </div>
        <!--        <div class="col-2">-->
        <!--          <input type="text" nbInput fieldSize="tiny" fullWidth name="numberFrom" placeholder="Letter"-->
        <!--                 [(ngModel)]="address.numberFromLetter">-->
        <!--        </div>-->
        <!--        <div class="col-2">-->
        <!--          <input type="number" nbInput fieldSize="tiny" fullWidth name="numberTo" placeholder="Number To"-->
        <!--                 [(ngModel)]="address.numberTo">-->
        <!--        </div>-->
        <!--        <div class="col-2">-->
        <!--          <input type="text" nbInput fieldSize="tiny" fullWidth name="numberTo" placeholder="Letter"-->
        <!--                 [(ngModel)]="address.numberToLetter">-->
        <!--        </div>-->
        <div class='col-2'>
          <div class='form-group' *ngIf='addressFrom && addressFrom.length > 1'>
            <label class='label'>
              <button nbButton status='warning' size='tiny' (click)='onRemoveNewNumber(i)'>Remove
              </button>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class='row m-0 mt-2'>
      <div class='col-4'>
        <div class='form-group'>
          <label class='label'>
            <button nbButton status='info' size='tiny' (click)='onSelectUnit()'>Multiple Unit</button>
          </label>
        </div>
      </div>
      <div class='col-4'>
        <div class='form-group'>
          <label class='label'>
            <button nbButton status='info' size='tiny' (click)='onSelectNumber()'>Multiple Number</button>
          </label>
        </div>
      </div>
      <div class='col-4' *appPermissionCheck='ComponentPermission.CLIENT_CREATE_BATCH'>
        <div class='form-group d-flex justify-content-end'>
          <label class='label'>
            <button nbButton status='info' size='tiny' class='fa-pull-right' (click)='onAddNewNumber()'>More Number</button>
          </label>
        </div>
      </div>
    </div>

  </nb-card-body>
  <nb-card-footer class='dialog-footer'>
    <div class='row'>
      <div class='col-12'>
        <button nbButton (click)='close()' size='small'>Cancel</button>
        <button nbButton status='success' (click)='submit()'
                class='ml-2'
                size='small' [disabled]='isUpdating === true'>Save
        </button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
