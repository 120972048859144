import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Path} from '../common/path';
import {RestResult} from '../models/response/rest-result';
import {CommonService} from './common.service';
import {SearchRequest} from '../models/request/search-request';
import {ProspectRequest} from '../models/request/prospect/prospect-request';
import {City} from '../models/response/address/city';
import {Constant} from '../common/constant';
import {Company} from '../models/response/prospect/company';
import { CompanyRequest } from '../models/request/prospect/company-request';

@Injectable()
export class CompanyService {

  constructor(protected http: HttpClient,
              protected commonService: CommonService) {
  }

  getOne(companyId: number): Observable<RestResult> {
    return this.http.get<any>(
      Path.API_ENDPOINT +
      Path.COMPANY +
      Path.COMPANY_ID.replace(':companyId', companyId.toString()),
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }


  search(request: SearchRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.COMPANY +
      Path.SEARCH, request,
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  create(request: ProspectRequest): Observable<RestResult> {
    return this.http.post<any>(
      Path.API_ENDPOINT +
      Path.COMPANY, request).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  remove(companyId: number): Observable<RestResult> {
    return this.http.delete<any>(
      Path.API_ENDPOINT +
      Path.COMPANY +
      Path.COMPANY_ID.replace(':companyId', companyId.toString()),
    ).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  update(request: ProspectRequest): Observable<RestResult> {
    return this.http.put<any>(
      Path.API_ENDPOINT +
      Path.COMPANY, request).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  increaseServiceCount(request: CompanyRequest): Observable<RestResult> {
    return this.http.put<any>(
      Path.API_ENDPOINT +
      Path.COMPANY + Path.SERVICE_COUNT, request).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  // ===== support for autocomplete ============
  searchByKeyword(keyword: string): Observable<Company[]> {
    const searchRequest = new SearchRequest();
    searchRequest.conditions = new Array();
    searchRequest.conditions.push(
      {
        left: 'name',
        middle: 'like',
        right: keyword,
      },
    );
    searchRequest.conditionType = Constant.CONDITION_TYPE.OR;
    searchRequest.offset = 0;
    searchRequest.limit = 10;
    searchRequest.orders = new Array();
    searchRequest.orders.push({left: 'companyId', right: 'DESC'});
    return this.search(searchRequest).pipe(
      map(result => result.data),
    );
  }
}
