<nb-card>
  <nb-card-header>{{title}}</nb-card-header>
  <nb-card-body>
    {{content}}
  </nb-card-body>
  <nb-card-footer>
    <button class="cancel" nbButton status="danger" (click)="cancel()">Cancel</button>
    <button nbButton status="success" (click)="submit()">Ok</button>
  </nb-card-footer>
</nb-card>
