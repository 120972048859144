import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Path} from '../common/path';
import {RestResult} from '../models/response/rest-result';
import {CommonService} from './common.service';

@Injectable()
export class DashboardService {

  constructor(protected http: HttpClient,
              protected commonService: CommonService) {
  }

  fetchProspectStatistic(officeId: number,
                         fromDate: number, toDate: number,
                         userIds?: number[],
                         categoryIds?: number[]): Observable<RestResult> {
    let params: HttpParams = new HttpParams();
    if (officeId) {
      params = params.append('officeId', officeId + '');
    }
    params = params.append('fromDate', fromDate + '');
    params = params.append('toDate', toDate + '');
    if (userIds) {
      params = params.append('userId', userIds.join(', '));
    }
    if (categoryIds) {
      params = params.append('categoryIds', categoryIds.join(', '));
    }
    return this.http.get<any>(
      Path.API_ENDPOINT +
      Path.PROSPECT_STATISTIC, { params }).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  fetchRentalStatistic(officeId: number,
                         fromDate: number, toDate: number,
                         userIds?: number[],
                         categoryIds?: number[]): Observable<RestResult> {
    let params: HttpParams = new HttpParams();
    if (officeId) {
      params = params.append('officeId', officeId + '');
    }
    params = params.append('fromDate', fromDate + '');
    params = params.append('toDate', toDate + '');
    if (userIds) {
      params = params.append('userId', userIds.join(', '));
    }
    if (categoryIds) {
      params = params.append('categoryIds', categoryIds.join(', '));
    }
    return this.http.get<any>(
      Path.API_ENDPOINT +
      Path.RENTAL_STATISTIC, { params }).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  countDailyBirthday(): Observable<RestResult> {
    return this.http.get<any>(
      Path.API_ENDPOINT +
      Path.BIRTHDAY).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  fetchProspectStatisticTeam(officeId: number,
                         fromDate: number, toDate: number,
                         userIds?: number[],
                         categoryIds?: number[]): Observable<RestResult> {
    let params: HttpParams = new HttpParams();
    if (officeId) {
      params = params.append('officeId', officeId + '');
    }
    params = params.append('fromDate', fromDate + '');
    params = params.append('toDate', toDate + '');
    if (userIds) {
      params = params.append('userId', userIds.join(', '));
    }
    if (categoryIds) {
      params = params.append('categoryIds', categoryIds.join(', '));
    }
    return this.http.get<any>(
      Path.API_ENDPOINT +
      Path.PROSPECT_STATISTIC_TEAM, { params }).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }

  fetchRentalStatisticTeam(officeId: number,
                             fromDate: number, toDate: number,
                             userIds?: number[],
                             categoryIds?: number[]): Observable<RestResult> {
    let params: HttpParams = new HttpParams();
    if (officeId) {
      params = params.append('officeId', officeId + '');
    }
    params = params.append('fromDate', fromDate + '');
    params = params.append('toDate', toDate + '');
    if (userIds) {
      params = params.append('userId', userIds.join(', '));
    }
    if (categoryIds) {
      params = params.append('categoryIds', categoryIds.join(', '));
    }
    return this.http.get<any>(
      Path.API_ENDPOINT +
      Path.RENTAL_STATISTIC_TEAM, { params }).pipe(
      map(
        result => {
          return result;
        }),
      catchError(this.commonService.handleError),
    );
  }
}
