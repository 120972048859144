import {CustomerRequest} from './customer-request';
import {PropertyRequest} from './property-request';
import {TaskRequest} from '../task-request';
import {AddressRequest} from '../address/address-request';

export class ProspectShortcutRequest {
  motiveNote?: string;
  note?: string;
  call?: boolean;
  sms?: boolean;
  door?: boolean;
  mail?: boolean;
  rate?: number;
  customer?: CustomerRequest;
  property?: PropertyRequest;
  address?: AddressRequest;

  static getDummy = () => {
    return {
      motiveNote: '',
      note: '',
      call: '',
      sms: '',
      door: '',
      mail: '',
      customer: '',
      property: '',
      address: '',
      rate: '',
    };
  }
}
