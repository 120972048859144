import {Component, OnInit, ViewChild} from '@angular/core';
import {RestResult} from '../../../../shared/models/response/rest-result';
import {User} from '../../../../shared/models/response/user';
import {SearchRequest} from '../../../../shared/models/request/search-request';
import {UserService} from '../../../../shared/services/user.service';
import {AuthService} from '../../../../shared/services/auth.service';
import {Company} from '../../../../shared/models/response/prospect/company';
import {TaskType} from '../../../../shared/models/response/task-type';
import {TaskTypeService} from '../../../../shared/services/task-type.service';
import {CompanyService} from '../../../../shared/services/company.service';
import {StringUtils} from '../../../../shared/common/string-utils';
import {ActivatedRoute} from '@angular/router';
import {TaskStatus} from '../../../../shared/models/response/task-status';
import {TaskStatusService} from '../../../../shared/services/task-status.service';
import {City} from '../../../../shared/models/response/address/city';
import {Buyer} from '../../../../shared/models/response/buyer/buyer';
import {BuyerCreateComponent} from '../../../../shared/module/buyer-create/buyer-create.component';
import {ProspectDialogComponent} from '../../../../shared/module/prospect-dialog/prospect-dialog.component';
import {Prospect} from '../../../../shared/models/response/prospect/prospect';
import {LocalService} from '../../../../shared/services/local.service';
import {LocalDataSource} from 'ng2-smart-table';
import {BuyerService} from '../../../../shared/services/buyer.service';
import {ProspectService} from '../../../../shared/services/prospect.service';
import {Constant} from '../../../../shared/common/constant';
import {AddressService} from '../../../../shared/services/address.service';
import {Address} from '../../../../shared/models/response/address/address';
import {AddressCustomCellComponent} from '../../address/childs/address-custom-cell-component';
import {Triple} from '../../../../shared/models/request/triple';
import {ComponentPermission} from '../../../../component.permission';

@Component({
  selector: 'ngx-search-address',
  templateUrl: './search-address.component.html',
  styleUrls: ['./search-address.component.scss'],
})
export class SearchAddressComponent implements OnInit {
  ComponentPermission = ComponentPermission;

  currentUser: User;
  cities: City[];
  companies: Company[];
  taskTypes: TaskType[];
  taskStatuses: TaskStatus[];
  @ViewChild(ProspectDialogComponent) prospectCreate: ProspectDialogComponent;
  @ViewChild(BuyerCreateComponent) buyerCreate: BuyerCreateComponent;

  stringUtils = StringUtils;
  selectedBuyer: Buyer;
  selectedProspect: Prospect;
  selectedProspects: Prospect[];

  type: string;
  parameter: string;
  pageSize = Constant.PAGE_SIZE;
  currentPage = 1;
  searchRequest = new SearchRequest();
  change: any;
  source: LocalDataSource = new LocalDataSource();
  isDisplay = false;

  constructor(private userService: UserService,
              private authService: AuthService,
              private companyService: CompanyService,
              private taskStatusService: TaskStatusService,
              private addressService: AddressService,
              private buyerService: BuyerService,
              private prospectService: ProspectService,
              private localService: LocalService,
              private taskTypeService: TaskTypeService,
              private route: ActivatedRoute) {
    this.currentUser = this.authService.currentUser;
  }

  columnsDefinition = {
    // checkbox: {
    //   enable: true,
    //   value: this.createSelectColumn(''),
    // },
    // unit: {
    //   title: 'Unit',
    // },
    // numberFrom: {
    //   title: 'Number From',
    // },
    // streetName: this.createColumn('Street', ['street.name']),
    // suburbName: this.createColumn('Suburb', ['street.district.name']),
    // zoneName: this.createColumn('Zone', ['street.district.zone.name']),
    // cityName: this.createColumn('City', ['street.district.city.name']),
    address: this.createColumn('Address', ['street.name']),
  };

  settings = {
    mode: 'external',
    hideSubHeader: true,
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    pager: {
      display: true,
      perPage: this.pageSize,
    },
    columns: this.columnsDefinition,
  };

  createColumn(title: string, propertyPaths: string[], valueType?: string, separator?: string) {
    return {
      title: title,
      type: 'custom',
      filter: false,
      renderComponent: AddressCustomCellComponent,
      onComponentInitFunction: (instance: AddressCustomCellComponent) => {
        instance.config = {
          propertyPaths: propertyPaths,
          valueType: valueType,
          separator,
        };
      },
    };
  }

  ngOnInit(): void {
    this.currentUser = this.authService.currentUser;
    this.loadForFilter();
    this.prepareRequest();
    this.route.queryParams.subscribe(params => {
      this.parameter = params['address'];
      if (this.parameter) {
        this.searchRequest.conditionType = Constant.CONDITION_TYPE.OR;
        this.searchRequest.conditions.push({
          left: 'keyword',
          middle: 'like',
          right: this.parameter,
        });
      }
    });
    this.source.onChanged().subscribe((change) => {
      this.change = change;
      if (change.action === 'refresh') {
        this.loadRequest(1);
      }
      if (change.action === 'page') {
        this.loadRequest(change.paging.page);
      }
    });
  }

  loadRequest(pageIndex) {
    if (this.currentUser.office.officeId !== null && this.currentUser.office.officeId !== 1) {
      this.searchRequest.conditions.push(new Triple('officeId', '=', this.currentUser.office.officeId));
    }
    this.addressService.search(this.searchRequest).subscribe((result: RestResult) => {
      this.source.load(result.data);
    });
  }

  prepareRequest() {
    this.searchRequest.offset = 1;
    this.searchRequest.limit = this.pageSize;
    // init conditions
    this.searchRequest.conditions = new Array();
  }

  selectRecord(event: any) {
    this.selectedBuyer = null;
    this.selectedProspect = null;
    const address: Address = event.data;
    this.isDisplay = true;
    this.buyerService.getByAddressId(address.addressId).subscribe((buyerResult: RestResult) => {
      if (!buyerResult.data) {
        this.prospectService.getByAddressId(address.addressId).subscribe((prospectResult: RestResult) => {
          this.selectedProspect = prospectResult.data;
        });
      } else {
        this.selectedBuyer = buyerResult.data;
        this.buyerCreate.setBuyer(this.selectedBuyer);
      }
    });
  }

  createSelectColumn(title: string) {
    // return {
    //   title: title,
    //   type: 'custom',
    //   filter: false,
    //   renderComponent: BuyerSelectComponent,
    //   onComponentInitFunction: (instance: BuyerSelectComponent) => {
    //     instance.onSelectBuyer.subscribe((value: WrapBuyer) => {
    //       this.pushToSelectedRequest(value);
    //     });
    //   },
    // };
  }

  cancel() {
    this.isDisplay = false;
  }

  loadForFilter() {
    const searchRequest = new SearchRequest();
    searchRequest.offset = 0;
    searchRequest.limit = 10000;
    searchRequest.orders = new Array();
    searchRequest.orders.push(
      {
        left: 'name',
        right: 'asc',

      },
    );
    this.taskTypeService.search(searchRequest).subscribe((result: RestResult) => {
      this.taskTypes = result.data;
    });
    this.companyService.search(searchRequest).subscribe((result: RestResult) => {
      this.companies = result?.data;
    });
    this.taskStatusService.search(searchRequest).subscribe((result: RestResult) => {
      this.taskStatuses = result.data;
    });
    this.localService.cities().subscribe((result: City[]) => {
      this.cities = result;
    });
  }

  // navigateProspect(value: { currentBuyerId: number, forward: boolean }) {
  //   if (!value) {
  //     return;
  //   }
  //   // this.selectedBuyer = this.selectedBuyers?.find(x => x.buyerId === value.currentBuyerId);
  //   // this.buyerCreate.setBuyer(this.selectedBuyer);
  //   // if (this.buyerList) {
  //   //   this.buyerList.selectedBuyer = this.selectedBuyer;
  //   //   this.buyerList.highlightRow(value.currentBuyerId);
  //   // }
  // }

  editDone(result: any) {
    // if (StatusUtils.isSuccessResponse(result)) {
    //   this.buyerList.reloadList();
    // }
  }

  newDone(result: any) {
    // if (StatusUtils.isSuccessResponse(result)) {
    //   this.buyerList.reloadList();
    //   this.buyerList.selectedBuyer = result.data;
    //   this.buyerList.restoreState();
    // }
  }

  reserveBuyer(buyer: Buyer) {
    // // unset selected buyer, add default buyer to buyerCreate
    // this.selectedBuyer = null;
    // this.buyerCreate.setBuyer(buyer);
    // // unset select buyer, unset highlightRow
    // this.buyerList.selectedBuyer = null;
    // this.buyerList.highlightRow(null);
  }

  ownSet(buyer: Buyer) {
    // this.isDisplay = true;
    // this.handleSelectedBuyerChange(buyer);
    // // remove selectedBuyer from buyerList
    // this.buyerList.selectedBuyer = buyer;
    // this.buyerList.highlightRow(buyer ? buyer.buyerId : null);
  }
}
