import { Component, OnInit, ViewChild } from '@angular/core';
import { ProspectService } from '../../../../shared/services/prospect.service';
import { AuthService } from '../../../../shared/services/auth.service';
import { ComponentPermission } from '../../../../component.permission';
import { TotalImportWrapper } from '../../../../shared/models/response/prospect/total-import-wrapper';
import { SuburbService } from '../../../../shared/services/suburb.service';
import { District } from '../../../../shared/models/response/address/district';
import { Street } from '../../../../shared/models/response/address/street';
import { City } from '../../../../shared/models/response/address/city';
import { Zone } from '../../../../shared/models/response/address/zone';
import { Company } from '../../../../shared/models/response/prospect/company';
import { TaskType } from '../../../../shared/models/response/task-type';
import { ProspectSearchRequest } from '../../../../shared/models/request/prospect/prospect-search-request';
import { ProspectListBasicComponent } from '../../prospect/prospect-list-basic/prospect-list-basic.component';
import { CityService } from '../../../../shared/services/city.service';
import { ProspectImportSearchRequest } from '../../../../shared/models/request/prospect/prospect-import-search-request';
import * as moment from 'moment';
import {DateUtils} from '../../../../shared/common/date-utils';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalService } from '../../../../shared/services/local.service';
import { SearchRequest } from '../../../../shared/models/request/search-request';
import { RestResult } from '../../../../shared/models/response/rest-result';
import { TaskTypeService } from '../../../../shared/services/task-type.service';
import { CompanyService } from '../../../../shared/services/company.service';
@Component({
  selector: 'ngx-total-import',
  templateUrl: './total-import.component.html',
  styleUrls: ['./total-import.component.scss'],
})
export class TotalImportComponent implements OnInit {

  ComponentPermission = ComponentPermission;
  data: TotalImportWrapper[];
  marketData: TotalImportWrapper[];
  showProspectList = false;
  districts: District[];
  streets: Street[];
  cities: City[];
  zones: Zone[];
  companies: Company[];
  taskTypes: TaskType[];
  // filteredStreets: Street[] = [];
  // filteredDistricts: District[] = [];
  marketImportSearchRequest = new ProspectImportSearchRequest();
  searchRequest = new ProspectSearchRequest();
  dateFrom = new Date();
  dateTo = new Date();
  param = {};
  isFiltered = false;

  @ViewChild(ProspectListBasicComponent) prospectListBasicComponent: ProspectListBasicComponent;

  constructor(private prospectService: ProspectService,
              private cityService: CityService,
              private authService: AuthService,
              private router: Router,
              private localService: LocalService,
              private districtService: SuburbService,
              private route: ActivatedRoute,
              private taskTypeService: TaskTypeService,
              private companyService: CompanyService) {
    this.router = router;
    this.route = route;

  }

  ngOnInit(): void {
    if (!this.isFiltered) {
      // this.param = { ...this.route.snapshot.queryParams };
      const params = this.route.snapshot.queryParams;
      if (params['dateFrom']) {
        // this.dateFrom = new Date(Number(params['dateFrom']));
        this.marketImportSearchRequest.dateFrom = Number(params['dateFrom']);
      }
      if (params['dateTo']) {
        // this.dateTo = new Date(Number(params['dateTo']));
        this.marketImportSearchRequest.dateTo = Number(params['dateTo']);
      }
    }
    this.dateFrom.setDate(this.dateFrom.getDate() - 1);
    this.prospectService.getTotalImport().subscribe(result => {
      if (result.data) {
        this.data = result.data;
        // this.initMarketImport();
        this.onSearch();
        this.setColor();
      }
    });
    this.preLoadSelectedDate();
    this.getTaskTypes();
    this.initFilter();
  }

  getTaskTypes() {
    const taskTypeSearchRequest: SearchRequest = new SearchRequest();
    taskTypeSearchRequest.orders = [];
    taskTypeSearchRequest.orders.push(
      {
        left: 'name',
        right: 'asc',

      },
    );
    this.taskTypeService.search(taskTypeSearchRequest).subscribe((result: RestResult) => {
      this.taskTypes = result.data;
    });
  }

  initFilter() {
    const searchRequest = new SearchRequest();
    searchRequest.offset = 0;
    searchRequest.limit = 10000;
    searchRequest.orders = new Array();
    searchRequest.orders.push(
      {
        left: 'name',
        right: 'asc',

      },
    );
    this.companyService.search(searchRequest).subscribe((result: RestResult) => {
      this.companies = result?.data;
    });
    searchRequest.conditionType = 'AND';
    searchRequest.conditions = [
      {
        left: 'status',
        middle: '=',
        right: 'ACTIVE',
      },
    ];
    const self = this;
    this.cityService.search(searchRequest).subscribe((result: RestResult) => {
      self.cities = result.data;
    });
  }

  loadStreets() {
  }

  onSearch() {
    this.isFiltered = true;
    this.dateFrom.setDate(this.dateFrom.getDate());
    this.dateTo.setDate(this.dateTo.getDate());
    if (this.isFiltered) {
      this.initMarketImport();
      this.appendFilterToParameter(this.marketImportSearchRequest);
      this.navigateWithParam();
    }
  }

  initMarketImport() {
    // const sr = new ProspectImportSearchRequest();
    const dateFrom = moment(this.dateFrom);
    const dateTo = moment(this.dateTo);
    // sr.dateFrom = DateUtils.getStartOfDateMoment(dateFrom).valueOf();
    // sr.dateTo = DateUtils.getEndOfDateMoment(dateTo).valueOf();
    this.marketImportSearchRequest.dateFrom = DateUtils.getStartOfDateMoment(dateFrom).valueOf();
    this.marketImportSearchRequest.dateTo = DateUtils.getEndOfDateMoment(dateTo).valueOf();

    this.prospectService.getMarketImportByDate(this.marketImportSearchRequest).subscribe(result => {
      if (result.data) {
        this.marketData = result.data;
      }
    });
  }

  resetRequest() {
    this.searchRequest.isAutoImport = null;
    this.searchRequest.districtId = null;
    this.searchRequest.sell = null;
    this.searchRequest.rent = null;
    this.searchRequest.otm = null;
    this.searchRequest.vac = null;
    this.searchRequest.importType = null;
    this.searchRequest.dateFrom = null;
    this.searchRequest.dateTo = null;
  }

  listImportedProspect(id: number) {
    this.resetRequest();
    this.showProspectList = true;
    this.searchRequest.isAutoImport = true;
    this.searchRequest.sortType = 'ASC';
    this.searchRequest.offset = 0;
    this.searchRequest.limit = 20;
    this.searchRequest.districtId = id;
    this.searchRequest.importType = 'CORE_LOGIC';
    this.prospectListBasicComponent.filterCondition(this.searchRequest);
  }

  listMarket(type: string, districtId: number) {
    this.resetRequest();
    this.showProspectList = true;
    this.searchRequest.isAutoImport = true;
    this.searchRequest.sortType = 'ASC';
    this.searchRequest.offset = 0;
    this.searchRequest.limit = 20;
    if (type === 'BUY') {
      this.searchRequest.otm = true;
    }
    if (type === 'SOLD') {
      this.searchRequest.otm = false;
    }
    if (type === 'RENT') {
      this.searchRequest.vac = true;
      this.searchRequest.rent = true;
    }
    this.searchRequest.districtId = districtId;
    this.searchRequest.importType = type;
    const dateFrom = moment(this.dateFrom);
    const dateTo = moment(this.dateTo);
    this.searchRequest.dateFrom = DateUtils.getStartOfDateMoment(dateFrom).valueOf();
    this.searchRequest.dateTo = DateUtils.getEndOfDateMoment(dateTo).valueOf();
    this.prospectListBasicComponent.filterCondition(this.searchRequest);
  }

  setColor() {
    if (!this.data) {
      return;
    }
    this.data.forEach(data => {
      data.responses.forEach(response => {
        this.districtService.getOne(response.districtId).subscribe(result => {
          response.color = result.data.color;
        });
      });
    });
  }

  preLoadSelectedDate() {
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length === 0) {
        return;
      }
      if (params['dateFrom']) {
        this.dateFrom = new Date(parseInt(params['dateFrom'] , 10));
        // this.dateFrom = new Date(Number(params['dateFrom']));
      }
      if (params['dateTo']) {
        this.dateTo = new Date(parseInt(params['dateTo'], 10));
        // this.dateTo = new Date(Number(params['dateTo']));
      }
    });
  }

  appendFilterToParameter(marketImportSearchRequest?) {
    if (marketImportSearchRequest.dateFrom) {
      this.param = { ...this.param, dateFrom: marketImportSearchRequest.dateFrom };
    } else {
      delete this.param['dateFrom'];
    }
    if (marketImportSearchRequest.dateTo) {
      this.param = { ...this.param, dateTo: marketImportSearchRequest.dateTo };
    } else {
      delete this.param['dateTo'];
    }
  }

  navigateWithParam() {
    this.router.navigate([], { queryParams: this.param });
  }
}
