import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateUtils } from '../../../../../../shared/common/date-utils';
import { District } from '../../../../../../shared/models/response/address/district';
import { City } from '../../../../../../shared/models/response/address/city';
import { Zone } from '../../../../../../shared/models/response/address/zone';
import { Constant } from '../../../../../../shared/common/constant';
import { Street } from '../../../../../../shared/models/response/address/street';
import { Observable, of } from 'rxjs';
import { Category } from '../../../../../../shared/models/response/category';
import { SearchRequest } from '../../../../../../shared/models/request/search-request';
import { Triple } from '../../../../../../shared/models/request/triple';
import { map, mergeMap } from 'rxjs/operators';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { CategoryService } from '../../../../../../shared/services/category.service';
import { User } from '../../../../../../shared/models/response/user';
import { UserService } from '../../../../../../shared/services/user.service';
import { StringUtils } from '../../../../../../shared/common/string-utils';
import { UserUtils } from '../../../../../../shared/common/user-utils';
import { AuthService } from '../../../../../../shared/services/auth.service';
import { ComponentPermission } from '../../../../../../component.permission';
import { ActivatedRoute } from '@angular/router';
import { Postcode } from '../../../../../../shared/models/response/postcode';
import { PostcodeService } from '../../../../../../shared/services/postcode.service';

@Component({
  selector: 'ngx-buyer-list-filter',
  templateUrl: './buyer-list-filter.component.html',
  styleUrls: ['./buyer-list-filter.component.scss'],
})
export class BuyerListFilterComponent implements OnInit {
  @Input() total: number;
  @Input() zones: Zone[];
  @Input() selectedZoneId: number;
  @Input() postcodes: string[];
  @Input() districts: District[];
  @Input() selectedDistrictId: number;
  @Input() streets: Street[];
  @Input() selectedStreetId: number;
  @Input() cities: City[];
  @Input() selectedCityId: number;
  @Input() isSelected = false;
  filterCategory$: Observable<Category[]>;
  categoryName: string;
  selectedCategories: Category[] = [];

  years: number[];
  quarters: number[];
  months: number[];
  weeks: number[];
  days: number[];
  firstDay?: number;
  lastDay?: number;

  @Input() selectedYear: number;
  @Input() selectedQuarter: number;
  @Input() selectedMonth: number;
  @Input() selectedWeek: number;
  @Input() selectedDay: number;

  @Output() onYearChange = new EventEmitter();
  @Output() onMonthChange = new EventEmitter();
  @Output() onWeekChange = new EventEmitter();
  @Output() onDayChange = new EventEmitter();
  @Output() onQuarterChange = new EventEmitter();
  @Output() onStreetChange = new EventEmitter();
  @Output() onFarmChange = new EventEmitter();
  @Output() onZoneChange = new EventEmitter();
  @Output() onSuburbChange = new EventEmitter();
  @Output() onCityChange = new EventEmitter();
  @Output() onExportEmail = new EventEmitter();
  @Output() onExportMobile = new EventEmitter();
  @Output() onSelectCategory = new EventEmitter();
  @Output() onClearSelected = new EventEmitter();
  @Output() onRemoveCategory = new EventEmitter();
  @Output() onInitCategory = new EventEmitter();
  @Output() onSelectUser = new EventEmitter();
  @Output() onRemoveUser = new EventEmitter();

  @Input() isBuyerMode: boolean = false;
  @Input() isTenantMode: boolean = false;
  @Output() onBuyerMode = new EventEmitter();
  @Output() onTenantMode = new EventEmitter();

  @Input() phone: string;
  @Input() rate: string;
  @Input() code: string;
  @Input() fromMinBuyPrice: number;
  toMinBuyPrice: number;
  fromMaxBuyPrice: number;
  @Input() toMaxBuyPrice: number;
  @Output() onPhoneChange = new EventEmitter();
  @Output() onRateChange = new EventEmitter();
  @Output() onCodeChange = new EventEmitter();
  @Output() onFromMinBuyPriceChange = new EventEmitter();
  @Output() onToMaxBuyPriceChange = new EventEmitter();
  @Output() onSearch = new EventEmitter();

  classes = [
    'F', 'I', 'VB', 'D',
  ];
  activeType = Constant.ACTIVE_TYPE;
  @Output() onStatusChange = new EventEmitter();
  @Input() selectedStatus: string;

  propertyType = Constant.PROPERTY_TYPE;
  bedValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  @Input() selectedPropertyType: string;
  @Input() selectedBed: number;
  @Input() selectedBath: number;
  @Input() selectedCar: number;
  @Output() onPropertyTypeChange = new EventEmitter();
  @Output() onBedChange = new EventEmitter();
  @Output() onBathChange = new EventEmitter();
  @Output() onCarChange = new EventEmitter();
  @Output() saveBulk = new EventEmitter();
  @Output() onPostcodeChange = new EventEmitter();

  filterUser$: Observable<User[]>;
  userName: string;
  selectedUser: User;
  stringUtils = StringUtils;
  userUtils = UserUtils;
  currentUser: User;
  ComponentPermission = ComponentPermission;
  log: string = null;
  @Input() postcode: number;
  @Output() onSearchByPostcode = new EventEmitter();
  filterByPostcode = false;
  filterPostcode$: Observable<Postcode[]>;

  constructor(private categoryService: CategoryService,
              private authService: AuthService,
              private userService: UserService,
              private postcodeService: PostcodeService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.currentUser = this.authService.currentUser;
    // this.selectedYear = null;
    // this.selectedQuarter = null;
    // this.selectedMonth = null;
    // this.selectedWeek = null;
    this.quarters = [];
    this.years = [];
    this.days = [];
    for (let i = 1; i <= 4; i++) {
      this.quarters.push(i);
    }
    for (let i = 2000; i <= 3000; i++) {
      this.years.push(i);
    }
    this.initAutoComplete();
    this.initEnqDate();
    this.appendSelectedUserFromParameter();
    this.appendSelectedCategoryFromParameter();
    if (this.route.snapshot.queryParams['postcode']) {
      this.filterByPostcode = true;
    }
  }

  initAutoComplete() {
    if (!this.userUtils.userIsAdmin(this.currentUser)) {
      this.selectedUser = this.currentUser;
    } else {
      this.selectedUser = undefined;
    }
    this.filterCategory$ = new Observable((observer: any) => {
      observer.next(this.categoryName);
    }).pipe(
      mergeMap((query: string) => this.searchCategory(query)),
    );

    this.filterUser$ = new Observable((observer: any) => {
      observer.next(this.userName);
    }).pipe(
      mergeMap((query: string) => this.searchUser(query)),
    );
    this.filterPostcode$ = new Observable((observer: any) => {
      observer.next(this.postcode);
    }).pipe(
      map((query: string) => {
        this.postcode = Number(query);
        this.searchSuburbByPostcode();
        return null;
      }),
    );
  }

  onYearSelectChange() {
    this.selectedQuarter = undefined;
    this.selectedMonth = undefined;
    this.selectedWeek = undefined;
    this.selectedDay = undefined;
    this.onYearChange.emit(this.selectedYear);
  }

  onQuarterSelectChange() {
    this.selectedMonth = undefined;
    this.selectedWeek = undefined;
    this.selectedDay = undefined;
    this.months = [];
    if (this.selectedQuarter) {
      for (let i = 1; i <= 3; i++) {
        this.months.push(3 * (this.selectedQuarter - 1) + i); // 1 2 3 4 5 6
      }
    }
    this.onQuarterChange.emit(this.selectedQuarter);
  }

  onMonthSelectChange() {
    this.selectedWeek = undefined;
    this.selectedDay = undefined;
    this.weeks = [];
    if (this.selectedMonth) {
      const firstWeek = DateUtils.getFirstWeekOfMonth(this.selectedMonth - 1, this.selectedYear);
      const lastWeek = DateUtils.getLastWeekOfMonth(this.selectedMonth - 1, this.selectedYear);
      for (let i = firstWeek; i <= lastWeek; i++) {
        this.weeks.push(i);
      }
    }
    this.onMonthChange.emit(this.selectedMonth);
  }

  onWeekSelectChange() {
    this.selectedDay = undefined;
    this.days = [];
    if (this.selectedWeek) {
      // get day of week
      const firstDayOfWeek = DateUtils.getFirstDayOfWeek(this.selectedWeek, this.selectedYear);
      const lastDayOfWeek = DateUtils.getLastDayOfWeek(this.selectedWeek, this.selectedYear);
      this.firstDay = firstDayOfWeek.valueOf();
      this.lastDay = lastDayOfWeek.valueOf();
      // normal case
      for (let m = this.firstDay; m <= this.lastDay; m += 86400000) {
        const day = new Date(m).getDate();
        this.days.push(day);
      }
    }
    this.onWeekChange.emit(this.selectedWeek);
  }

  onDaySelectChange() {
    this.onDayChange.emit(this.selectedDay);
  }

  onStreetSelectChange() {
    this.onStreetChange.emit(isNaN(Number(this.selectedStreetId)) ? undefined : Number(this.selectedStreetId));
  }

  onSuburbSelectChange() {
    this.onSuburbChange.emit(isNaN(Number(this.selectedDistrictId)) ? undefined : Number(this.selectedDistrictId));
  }

  onZoneSelectChange() {
    this.onZoneChange.emit(isNaN(Number(this.selectedZoneId)) ? undefined : Number(this.selectedZoneId));
  }

  onPostcodeSelectChange() {
    this.onPostcodeChange.emit(this.postcode);
  }

  onCitySelectChange() {
    this.onCityChange.emit(isNaN(Number(this.selectedCityId)) ? undefined : Number(this.selectedCityId));
  }

  onPhoneInputChange() {
    this.onPhoneChange.emit(this.phone);
  }

  onRateInputChange() {
    this.onRateChange.emit(this.rate);
  }

  onCodeInputChange() {
    this.onCodeChange.emit(this.code);
  }

  onFromMinBuyPriceInputChange() {
    this.onFromMinBuyPriceChange.emit(isNaN(Number(this.fromMinBuyPrice)) ? null : Number(this.fromMinBuyPrice));
  }

  onToMaxBuyPriceInputChange() {
    this.onToMaxBuyPriceChange.emit(isNaN(Number(this.toMaxBuyPrice)) ? null : Number(this.toMaxBuyPrice));
  }

  onSearchButtonClick() {
    this.onSearch.emit();
  }

  onBuyerModeChange() {
    this.onBuyerMode.emit(!this.isBuyerMode);
  }

  exportMobile() {
    this.onExportMobile.emit();
  }

  exportEmail() {
    this.onExportEmail.emit();
  }

  onTenantModeChange() {
    this.onTenantMode.emit(!this.isTenantMode);
  }

  onPropertyTypeSelectChange() {
    this.onPropertyTypeChange.emit(this.selectedPropertyType);
  }

  onBedSelectChange() {
    this.onBedChange.emit(isNaN(Number(this.selectedBed)) ? undefined : Number(this.selectedBed));
  }

  onBathSelectChange() {
    this.onBathChange.emit(isNaN(Number(this.selectedBath)) ? undefined : Number(this.selectedBath));
  }

  onCarSelectChange() {
    this.onCarChange.emit(isNaN(Number(this.selectedCar)) ? undefined : Number(this.selectedCar));
  }

  BUYER_PRICE() {
    if (this.isTenantMode) {
      return Constant.RENTAL_MIN_PRICE;
    } else {
      return Constant.MIN_PRICE;
    }
  }

  onStatusSelectChange() {
    this.onStatusChange.emit(this.selectedStatus);
  }

  keepOrder(a, b) {
    return 1;
  }

  searchCategory(name: string): Observable<Category[]> {
    if (!name || name === '') {
      return of([]);
    }
    const searchRequest = new SearchRequest();
    searchRequest.conditions = new Array();
    searchRequest.conditionType = 'AND';
    searchRequest.subConditions = [];
    searchRequest.conditions.push(new Triple('name', 'like', name));
    const subCon = new SearchRequest();
    subCon.conditionType = 'OR';
    subCon.conditions = [];
    subCon.conditions.push(new Triple('type', '=', 'BUYER'));
    subCon.conditions.push(new Triple('type', '=', 'BOTH'));
    searchRequest.subConditions.push(subCon);
    return this.categoryService.search(searchRequest).pipe(
      map(result => result.data),
    );
  }

  selectCategory(type: TypeaheadMatch) {
    this.categoryName = '';
    const selectedCategory: Category = type.item;
    if (this.selectedCategories.filter(category => category.categoryId === selectedCategory.categoryId).length > 0) {
      return;
    }
    this.selectedCategories.push(selectedCategory);
    localStorage.setItem(Constant.CLIENT_CATEGORY_LIST, JSON.stringify(this.selectedCategories));
    this.onSelectCategory.emit(selectedCategory.categoryId);
  }

  removeCategory(removedCategory: Category) {
    if (removedCategory) {
      this.selectedCategories = this.selectedCategories.filter
      (category => category.categoryId !== removedCategory.categoryId);
      localStorage.setItem(Constant.CLIENT_CATEGORY_LIST, JSON.stringify(this.selectedCategories));
      this.onRemoveCategory.emit(removedCategory.categoryId);
    }
  }

  searchUser(keyword: string): Observable<User[]> {
    // this.refreshUserFilter();
    const searchRequest = new SearchRequest();
    searchRequest.conditions = new Array();
    searchRequest.conditions.push(
      {
        left: 'email',
        middle: 'like',
        right: keyword,
      },
    );
    searchRequest.conditions.push(
      {
        left: 'username',
        middle: 'like',
        right: keyword,
      },
    );
    searchRequest.conditions.push(
      {
        left: 'name',
        middle: 'like',
        right: keyword,
      },
    );
    searchRequest.conditionType = Constant.CONDITION_TYPE.OR;
    searchRequest.offset = 0;
    searchRequest.limit = 10;
    return this.userService.search(searchRequest).pipe(
      map(result => result.data.filter(user => user.status === 'ACTIVE')),
    );
  }

  selectUser(type: TypeaheadMatch) {
    this.userName = '';
    this.selectedUser = type.item;
    this.onSelectUser.emit(this.selectedUser.userId);
  }

  removeUser() {
    this.onRemoveUser.emit(this.selectedUser.userId);
    this.selectedUser = null;
  }

  bulkSave() {
    this.saveBulk.emit(this.log);
  }

  initEnqDate() {
    if (this.selectedQuarter) {
      this.months = [];
      if (this.selectedQuarter) {
        for (let i = 1; i <= 3; i++) {
          this.months.push(3 * (this.selectedQuarter - 1) + i); // 1 2 3 4 5 6
        }
      }
    }
    if (this.selectedMonth) {
      this.weeks = [];
      if (this.selectedMonth) {
        const firstWeek = DateUtils.getFirstWeekOfMonth(this.selectedMonth - 1, this.selectedYear);
        const lastWeek = DateUtils.getLastWeekOfMonth(this.selectedMonth - 1, this.selectedYear);
        for (let i = firstWeek; i <= lastWeek; i++) {
          this.weeks.push(i);
        }
      }
    }
    if (this.selectedWeek) {
      this.days = [];
      if (this.selectedWeek) {
        // get day of week
        const firstDayOfWeek = DateUtils.getFirstDayOfWeek(this.selectedWeek, this.selectedYear);
        const lastDayOfWeek = DateUtils.getLastDayOfWeek(this.selectedWeek, this.selectedYear);
        this.firstDay = firstDayOfWeek.valueOf();
        this.lastDay = lastDayOfWeek.valueOf();
        // normal case
        for (let m = this.firstDay; m <= this.lastDay; m += 86400000) {
          const day = new Date(m).getDate();
          this.days.push(day);
        }
      }
    }
  }

  appendSelectedCategoryFromParameter() {
    if (!this.route.snapshot.queryParams['categoryIds']) {
      return;
    }
    let categoryIds = [];
    if (typeof this.route.snapshot.queryParams['categoryIds'] === 'string') {
      categoryIds.push(Number(this.route.snapshot.queryParams['categoryIds']));
    } else {
      categoryIds = this.route.snapshot.queryParams['categoryIds'].map(id => {
        return Number(id);
      });
    }
    this.searchCategoryById(categoryIds);
  }

  searchCategoryById(categoryIds: number[]) {
    const searchRequest = new SearchRequest();
    searchRequest.conditions = [];
    searchRequest.conditions.push(
      {
        left: 'categoryId',
        middle: 'in',
        right: categoryIds,
      },
    );
    this.categoryService.search(searchRequest).subscribe(result => {
      this.selectedCategories = result.data;
    });
  }

  appendSelectedUserFromParameter() {
    if (!this.route.snapshot.queryParams['userId']) {
      return;
    }
    const userId = Number(this.route.snapshot.queryParams['userId']);
    this.userService.getOne(userId).subscribe(result => {
      this.selectedUser = result.data;
    });
  }

  searchPostcode(keyword: string): Observable<Postcode[]> {
    const searchRequest = new SearchRequest();
    searchRequest.conditions = [];
    searchRequest.orders = [];
    searchRequest.conditions.push(
      {
        left: 'code',
        middle: '=',
        right: keyword,
      },
    );
    searchRequest.orders.push(
      {
        left: 'code',
        right: 'asc'
      }
    );
    searchRequest.offset = 0;
    searchRequest.limit = 10;
    return this.postcodeService.search(searchRequest).pipe(
      map(result => result.data),
    );
  }

  selectPostcode(type: TypeaheadMatch) {
    this.postcode = Number(type.item.code);
    this.searchSuburbByPostcode();
  }

  searchSuburbByPostcode() {
    if (this.postcode === null) {
      return;
    }
    this.filterByPostcode = true;
    this.onSearchByPostcode.emit(this.postcode);
  }
}
